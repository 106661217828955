<template>
  <div class="tags-settings">
    <tag-manager />
  </div>
</template>

<script>
import TagManager from '@/containers/tagManager';

export default {
  components: {
    TagManager
  }
};
</script>

<style lang="scss" scoped></style>
