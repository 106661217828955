import gql from 'graphql-tag';
import { roleFragment, notificationFragment } from '../fragments';

export const GET_ACTIVITIES = gql`
  query activities {
    activities {
      id
      name_en_GB
      name_fr_FR
    }
  }
`;

export const GET_ROLES = gql`
  query roles {
    roles {
      ...role
    }
  }
  ${roleFragment.role}
`;

export const NOTIFICATIONS_COUNTER = gql`
  query NotificationsNotDisplayed($workspaceId: String!) {
    NotificationsNotDisplayed(workspaceId: $workspaceId)
  }
`;

export const NOTIFICATIONS_LIST = gql`
  query NotificationsList($workspaceId: String!) {
    NotificationsList(workspaceId: $workspaceId, fromDate: 0) {
      displayed_at
      items {
        ...notification
      }
    }
  }
  ${notificationFragment.notification}
`;
