
<div class="toggle">
  <div
    class="toggle-item"
    v-for="(option, index) in options"
    :key="`toggle-${index}`"
    @click="toggleMenu(index)"
  >
    <div
      class="toggle-item-label"
      :class="index === selectedTabIndex ? 'toggle-item-enabled' : 'toggle-item-disabled'"
    >
      {{ option.label }}
    </div>
  </div>
</div>
