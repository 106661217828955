<template>
  <div class="artist" v-if="artistDetails && !error">
    <div :class="['artist-edition', editTracksMode ? 'artist-edition-open' : '']">
      <div class="artist-edition-wrapper">
        <edit-tracks-panel
          v-if="editTracksMode"
          :libraryFileIds="libraryFileIds"
          :trackList="trackList"
          @toggleEditMode="toggleEditTracksMode"
        />
      </div>
    </div>
    <div class="artist-content" :style="editTracksMode && 'width: calc(100% - 518px + 16px);'">
      <div class="artist-header">
        <div class="artist-header-left">
          <img v-if="isUnknownArtist()" :src="artistImgUrl" />
          <image-browser
            v-if="!isUnknownArtist()"
            :removeButton="displayDeleteImage"
            :imgUrl="artistImgUrl"
            @changeImgUrl="openCropperModal"
            @removeImage="deleteArtistImage"
          />
        </div>
        <div class="artist-header-right">
          <div class="artist-header-right-txt">
            <div class="artist-header-right-txt-title">
              <editable-text
                :value="artistName"
                size="large"
                :fieldModel.sync="artistName"
                @validate="updateArtistName"
                v-if="!isUnknownArtist()"
              />
              <p v-else>{{ artistDetails.name }}</p>
            </div>
            <div class="artist-header-right-txt-count">
              <span v-if="!isUnknownArtist()">
                {{ `${$tc('albumCount', artistDetails.supports_number)} • ` }}
              </span>
              {{
                artistDetails.tracks_length
                  ? `${getFormatDuration(artistDetails.tracks_length)} •`
                  : ''
              }}
              {{ `${$tc('trackCount', artistDetails.tracks_number)}` }}
            </div>
          </div>
        </div>
      </div>
      <div class="artist-tabs">
        <tabs :tabs="tabs" :activeTab="activeTab" haveCount />
      </div>
      <router-view
        :keepProjectDropdownExpand="keepProjectDropdownExpand"
        :uncheckTracks="uncheckTracks"
        :trackList="trackList"
        :artistFileIds="artistFileIds"
        :editMode="editTracksMode"
        :artistDetails="artistDetails"
        :artistTracks="artistTracks"
        :entityIds="[this.$route.params.id]"
        :entityName="artistName"
        @createProject="createProject"
        @openConfirmDeleteModal="openConfirmDeleteModal"
        @toggleEditMode="toggleEditTracksMode"
      ></router-view>
    </div>
  </div>
  <div v-else class="loader">
    <spinner-without-progress color="grey" :size="32" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash.get';
import clonedeep from 'lodash.clonedeep';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

import { ARTIST_DETAILS, LIBRARY_FILES_DETAILS_LIGHT } from '@/graphql/queries/library';
import {
  ARTIST_IMAGE_REMOVE,
  DELETE_LIBRARY_FILE,
  ARTIST_UPDATE
} from '@/graphql/mutations/library';
import { ME } from '@/graphql/queries/user';
import { get as getRoute } from '@/router/routes';
import { handleArtistImageUpload } from '@/utils/functions/upload';
import { getGraphQlErrorCode, generateDocumentTitle } from '@/utils/functions/global';
import generateTracklist from '@/utils/functions/tracklist';
import { formatDuration, FORMAT_DURATION } from '@/utils/functions/time';
import CreateProjectModal from '@/containers/modals/createProject';
import ConfirmDeleteModal from '@/containers/modals/confirmDelete';
import EditTracksPanel from '@/containers/editTracksPanel';
import { bus } from '@/utils/bus';
import { appConfig } from '@/utils/constants';
import CropperModal from '@/containers/modals/cropper';
import DeleteMultipleTracksModal from '@/containers/modals/deleteMultipleTracks';
import { apolloClient } from '@/main';

export default {
  components: {
    EditTracksPanel
  },
  apollo: {
    artistDetails: {
      query: ARTIST_DETAILS,
      variables() {
        return {
          libraryId: this.currentWorkspace.libraries[0].id,
          artistId: this.$route.params.id
        };
      },
      result(res) {
        if (this.artistDetails && this.artistDetails.id === 'unknown') {
          this.artistImgUrl = '/images/unknown-artist.svg';
          this.loadTracksWithoutAlbum(res.data.artistDetails.tracks_ids);
          return;
        }
        this.artistImgUrl = get(this.artistDetails, 'image.thumbnails[2]', null)
          ? this.artistDetails.image.thumbnails[2].url
          : '/images/default_artist.jpg';
        this.displayDeleteImage = res.data.artistDetails.image !== null;
        this.artistName = this.artistDetails.name;
        document.title = generateDocumentTitle([this.artistName]);

        const tracksIdPerAlbum = res.data.artistDetails.supports.map(support => support.tracks_ids);

        if (res.data && res.data.artistDetails) {
          const allTracks = res.data.artistDetails.tracks_ids;
          const tracksWithoutSupport = allTracks.filter(
            item => !tracksIdPerAlbum.flat().includes(item)
          );
          if (tracksIdPerAlbum.length > 0) {
            this.loadAlbumTracks(tracksIdPerAlbum, 0, tracksWithoutSupport);
          } else {
            this.loadTracksWithoutAlbum(tracksWithoutSupport);
          }
        }
      },
      error(error) {
        this.handleUnknownArtist(error);
      }
    }
  },
  data() {
    return {
      artistName: '',
      keepProjectDropdownExpand: false,
      artistImgUrl: null,
      libraryFileIds: [],
      uncheckTracks: false,
      backRouteName: 'tracks',
      editTracksMode: false,
      artistImageFile: null,
      error: null,
      displayDeleteImage: false,
      artistTracks: [],
      loadTrackForceStop: false,
      refreshAfterDelete: false
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'playlist', 'newUpload']),
    activeTab() {
      const active = this.tabs.find(tab => tab.name === this.$route.name);
      if (active.title === 'Sharing links') {
        document.title = generateDocumentTitle([this.artistName, 'Links']);
      } else if (active.title === 'Activity') {
        document.title = generateDocumentTitle([this.artistName, 'Activity']);
      } else {
        document.title = generateDocumentTitle([this.artistName]);
      }
      return this.tabs.indexOf(active);
    },
    tabs() {
      const isShared = this.$route.path.includes('shared');
      const { id } = this.$route.params;
      return [
        ...(!this.isUnknownArtist()
          ? [
              {
                title: this.$t('discography'),
                link: isShared
                  ? getRoute('sharedArtistAlbums', { id })
                  : getRoute('artistAlbums', { id }),
                name: 'artistAlbums',
                count: this.artistDetails.supports_number
              }
            ]
          : []),
        {
          title: this.$t('tracks'),
          link: isShared
            ? getRoute('sharedArtistTracks', { id })
            : getRoute('artistTracks', { id }),
          name: 'artistTracks',
          count: this.artistDetails.tracks_number
        },
        ...(!this.isUnknownArtist()
          ? [
              {
                title: this.$t('sharingLinks'),
                link: isShared
                  ? getRoute('sharedArtistSharings', { id })
                  : getRoute('artistSharings', { id }),
                name: 'artistSharings'
              }
            ]
          : []),
        ...(!this.isUnknownArtist()
          ? [
              {
                title: this.$t('sharingActivity'),
                link: isShared
                  ? getRoute('sharedArtistActivity', { id })
                  : getRoute('artistActivity', { id }),
                name: 'artistActivity'
              }
            ]
          : [])
      ];
    },
    artistFileIds() {
      return this.artistTracks.map(item => item.id);
    },
    trackList() {
      return generateTracklist(this.artistTracks, this.currentWorkspace, this.$i18n.locale);
    }
  },
  watch: {
    $route() {
      this.toggleEditTracksMode([]);
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const that = vm;
      if (from.name === 'artists') {
        that.backRouteName = 'artists';
      } else {
        that.backRouteName = 'tracks';
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    this.loadTrackForceStop = true;
    next();
  },
  mounted() {
    bus.$on('deleteTracksFromLibrary', trackToDelete => {
      this.moveUpdateQueries(trackToDelete);
    });
    if (this.newUpload && this.artistDetails) {
      this.$apollo.queries.artistDetails.refetch();
    }
  },
  updated() {
    if (this.artistDetails && this.artistDetails.tracks_number === 0) {
      this.$router.push(getRoute('library'));
    }
  },
  destroyed() {
    bus.$off('deleteTracksFromLibrary');
  },
  methods: {
    loadAlbumTracks(tracksIdPerAlbum, iteration, tracksWithoutSupport) {
      const last = tracksIdPerAlbum.length - 1;
      this.$apollo
        .query({
          query: LIBRARY_FILES_DETAILS_LIGHT,
          variables: {
            libraryId: this.currentWorkspace.libraries[0].id,
            libraryFilesIds: tracksIdPerAlbum[iteration]
          }
        })
        .then(response => {
          if (this.refreshAfterDelete) {
            this.artistTracks = [];
            this.refreshAfterDelete = false;
          }
          this.artistTracks = this.artistTracks.concat(response.data.LibraryFilesDetails);
          if (iteration < last && !this.loadTrackForceStop) {
            this.loadAlbumTracks(tracksIdPerAlbum, iteration + 1, tracksWithoutSupport);
          } else if (tracksWithoutSupport.length > 0) {
            this.loadTracksWithoutAlbum(tracksWithoutSupport);
          }
        });
    },
    loadTracksWithoutAlbum(tracks) {
      this.$apollo
        .query({
          query: LIBRARY_FILES_DETAILS_LIGHT,
          variables: {
            libraryId: this.currentWorkspace.libraries[0].id,
            libraryFilesIds: tracks
          }
        })
        .then(response => {
          if (this.refreshAfterDelete) {
            this.artistTracks = [];
            this.refreshAfterDelete = false;
          }
          this.artistTracks = this.artistTracks.concat(response.data.LibraryFilesDetails);
        });
    },
    errorArtistName() {
      return (
        !this.$v.artistName.required ||
        !this.$v.artistName.minLength ||
        !this.$v.artistName.maxLength
      );
    },
    updateArtistName() {
      if (this.$isWorkspaceLockedVerification(this)) {
        this.artistName = get(this.artistDetails, 'name', '');
        return;
      }
      if (this.artistDetails.name !== this.artistName && !this.errorArtistName()) {
        this.$apollo
          .mutate({
            mutation: ARTIST_UPDATE,
            variables: {
              workspaceId: this.currentWorkspace.id,
              artistId: this.$route.params.id,
              name: this.artistName
            },
            update: (store, { data: { ArtistUpdate } }) => {
              const cacheArtist = store.readQuery({
                query: ARTIST_DETAILS,
                variables: {
                  libraryId: this.currentWorkspace.libraries[0].id,
                  artistId: this.$route.params.id
                }
              });
              cacheArtist.artistDetails.name = ArtistUpdate.name;
              store.writeQuery({
                query: ARTIST_DETAILS,
                variables: {
                  libraryId: this.currentWorkspace.libraries[0].id,
                  artistId: this.$route.params.id
                },
                data: { artistDetails: cacheArtist.artistDetails }
              });
              // We manually update the playlis with the new artist
              const updatedPlaylist = [];
              Object.values(this.playlist).forEach(t => {
                const track = clonedeep(t);
                track.artists = track.artists.map(a => {
                  const artist = clonedeep(a);
                  if (artist.id === this.$route.params.id) {
                    artist.name = this.artistName;
                  }
                  return artist;
                });
                updatedPlaylist[track.id] = track;
              });
              this.$store.commit('replacePlaylist', updatedPlaylist);
            }
          })
          /* .then(() => { */
          /* this.$apollo.queries.artistDetails.refetch(); */
          /* }) */
          .catch(error => {
            this.mutationCatchError(error);
          });
      } else {
        this.artistName = get(this.artistDetails, 'name', '');
      }
    },
    isUnknownArtist() {
      return this.artistDetails && this.artistDetails.id === 'unknown';
    },
    goBack() {
      this.$router.push(getRoute(this.backRouteName));
    },
    openCropperModal(blob) {
      if (this.$isWorkspaceLockedVerification(this)) return;
      if (!blob) {
        return;
      }
      bus.$emit('displayModal', {
        component: CropperModal,
        title: this.$t('cropImage'),
        size: 'medium',
        onSubmit: this.changeArtistImage,
        onCancel: () => {},
        isVisible: true,
        props: [
          {
            name: 'blob',
            value: blob
          },
          {
            name: 'imageMaxWidth',
            value: appConfig.upload.artist.width
          },
          {
            name: 'imageMaxHeight',
            value: appConfig.upload.artist.height
          }
        ]
      });
    },
    mutationCatchError(error) {
      const key = getGraphQlErrorCode(error);
      bus.$emit('showAlert', {
        message: { key },
        style: 'danger',
        delay: 5000,
        error: true
      });
    },
    changeArtistImage(blob) {
      const file = new File([blob], 'newWorkspaceLogo', { type: 'image/jpeg' });
      handleArtistImageUpload(this, file, this.currentWorkspace.id, this.$route.params.id);
    },
    deleteArtistImage() {
      if (this.$isWorkspaceLockedVerification(this)) return;
      this.$apollo
        .mutate({
          mutation: ARTIST_IMAGE_REMOVE,
          variables: {
            artistId: this.$route.params.id,
            workspaceId: this.currentWorkspace.id
          }
        })
        .then(() => {
          this.artistChangesIsPending = false;
          this.$apollo.queries.artistDetails.refetch();
        })
        .catch(error => {
          this.mutationCatchError(error);
        });
    },
    createProject() {
      this.keepProjectDropdownExpand = true;
      bus.$emit('displayModal', {
        component: CreateProjectModal,
        title: this.$t('newProject'),
        size: 'medium',
        isVisible: true
      });
    },
    openConfirmDeleteModal(libraryFileIds) {
      this.libraryFileIds = libraryFileIds;
      if (libraryFileIds.length > 1) {
        bus.$emit('displayModal', {
          title: this.$t('deleteTracks'),
          size: 'medium',
          component: DeleteMultipleTracksModal,
          onSubmit: () => this.handleDeleteTrackMutation(libraryFileIds),
          isVisible: true,
          props: [{ name: 'tracksNumber', value: libraryFileIds.length }]
        });
        this.uncheckTracks = false;
        return;
      }
      bus.$emit('displayModal', {
        component: ConfirmDeleteModal,
        title: this.$t('deleteTracks'),
        size: 'medium',
        onSubmit: this.handleDeleteTrackMutation,
        msg: 'confirmDeleteHard',
        props: [
          {
            name: 'tracksName',
            value: this.trackList.find(el => el.id === libraryFileIds[0]).title
          }
        ],
        isVisible: true
      });
      this.uncheckTracks = false;
    },
    handleDeleteTrackMutation() {
      const libraryId = this.currentWorkspace.libraries[0].id;
      this.$apollo
        .mutate({
          mutation: DELETE_LIBRARY_FILE,
          variables: {
            libraryId,
            libraryFileIds: this.libraryFileIds
          },
          update: store => {
            this.libraryFileIds.forEach(id => {
              store.data.delete(`LibraryFile:${id}`);
              delete this.playlist[id];
            });
          },
          refetchQueries: [
            {
              query: ME
            }
          ]
        })
        .then(() => {
          this.refreshAfterDelete = true;
          this.$apollo.queries.artistDetails
            .refetch()
            .then(() => {
              bus.$emit('showAlert', {
                message: { key: 'deleteTracksSuccess', params: this.libraryFileIds.length },
                style: 'success',
                delay: 5000
              });
              this.libraryFileIds = [];
              this.uncheckTracks = true;
            })
            .catch(error => {
              bus.$emit('showAlert', {
                message: { key: 'deleteTracksSuccess', params: this.libraryFileIds.length },
                style: 'success',
                delay: 5000
              });
              this.handleUnknownArtist(error);
            });
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    moveUpdateQueries(ids) {
      ids.forEach(id => {
        apolloClient.cache.data.delete(`LibraryFile:${id}`);
        delete this.playlist[id];
      });
      this.refreshAfterDelete = true;
      this.$apollo.queries.artistDetails.refetch();
      this.uncheckTracks = true;
    },
    toggleEditTracksMode(libraryFileIds, isChecked) {
      if (isChecked && libraryFileIds.length > 0 && !this.editTracksMode) {
        this.$store.commit('setEditPanelOpened', false);
        return;
      }
      this.libraryFileIds = libraryFileIds;
      this.editTracksMode = this.libraryFileIds.length > 0;
      this.$store.commit('setEditPanelOpened', this.editTracksMode);
      this.editArtistMode = false;
    },
    toggleEditArtistMode(value) {
      this.$store.commit('setEditPanelOpened', value);
      this.editArtistMode = value;
      this.editTracksMode = false;
    },
    handleUnknownArtist(error) {
      this.error = error;
      if (this.$route.name !== 'trackList') {
        this.$router.push(getRoute('trackList'));
      }
    },
    getFormatDuration(timestamp) {
      return formatDuration(timestamp, FORMAT_DURATION.hmm);
    }
  },
  validations: {
    artistName: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(255)
    }
  }
};
</script>

<style lang="scss" scoped>
.artist {
  @include body-1;
  color: $color_neutral_100;
  margin: 0 0 80px 0;

  &-content {
    transition: width 0.15s ease;
    width: 100%;
  }
  &-back {
    padding: 40px 0 24px 40px;
  }

  &-header {
    display: flex;
    margin: 16px 0 40px 40px;

    &-left {
      display: flex;
      align-items: flex-end;

      img {
        width: 104px;
        height: 104px;
        border-radius: 50%;
      }
    }

    &-right {
      margin: 0 0 0 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
      &-txt {
        &-title {
          @include heading-4;
          margin-left: 8px;
        }

        &-count {
          @include body-2;
          color: $color_neutral_60;
          margin: 8px 0 0 12px;
        }
      }

      &-actions {
        display: flex;

        &-- {
          margin: 0 8px 0 0;
        }
      }
    }
  }

  &-tabs {
    padding: 16px 0 0 40px;
  }

  &-edition {
    z-index: map-get($z-index, side-panel);
    background-color: $color_neutral_0;
    position: absolute;
    top: 0;
    right: -520px;
    transition: right 0.15s ease;
    height: calc(100vh - 64px);
    overflow: auto;
    border-left: 1px solid $color_neutral_30;
    &-open {
      right: 0;
      @include shadow-down-01;
    }
    &-wrapper {
      overflow: auto;
      width: 518px;
      height: 100%;
    }
  }
}

.loader {
  height: calc(100vh - 56px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
