
<div class="container">
  <div :class="['header', { 'header-expanded': this.isExpanded }]">
    <div :class="[{ dots: status === 'processing' || status === 'pending' }]">
      {{ labels[status].header }}
    </div>
    <div>
      <div v-if="status === 'done' || status === 'error'" @click="closeManager" class="close">
        {{ labels.close }}
      </div>
      <i
        @click="toggleExpand"
        :class="isExpanded ? 'ri-arrow-down-s-line' : 'ri-arrow-up-s-line'"
      />
    </div>
  </div>
  <div v-if="isExpanded" class="body">
    <div :class="[{ 'body-error': status === 'error' }]">{{ labels[status].body }}</div>
    <div>
      <spinner v-if="status === 'pending' || status === 'processing'" color="grey" />
      <div class="icon" v-if="status === 'done' || status === 'error'">
        <i
          :class="[
            {
              'ri-check-fill icon-done': status === 'done',
              'ri-alert-fill icon-error': status === 'error'
            }
          ]"
        />
      </div>
    </div>
  </div>
</div>
