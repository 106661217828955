<template v-slot:body>
  <div class="change-billing-info-modal">
    <div class="change-billing-info-modal-body">
      <div class="section-address">
        <div class="formTitle">Address</div>
        <div class="inputLine multiInputLine">
          <select-box
            :options="getCountryList"
            placeholder=""
            label="Country"
            :selectedValue="getUserCountry"
            @setSelectBoxValue="selectCountry"
            large="large"
          />
          <text-field
            inputType="text"
            label="State"
            :value="state"
            :fieldModel.sync="state"
            :large="true"
            helper="(Optionnal)"
            autocomplete="off"
          />
        </div>
        <text-field
          inputType="text"
          label="Street address"
          :large="true"
          :value="address"
          autocomplete="off"
          :errorMessage="addressValidationMessage"
          :fieldModel.sync="$v.address.$model"
          class="inputLine"
        />
        <text-field
          inputType="text"
          label="Suite / Unit"
          :large="true"
          :value="addressSuite"
          :fieldModel.sync="addressSuite"
          helper="(Optionnal)"
          autocomplete="off"
          class="inputLine"
        />
        <div class="multiInputLine inputLine">
          <text-field
            inputType="text"
            label="City"
            :large="true"
            :value="city"
            :errorMessage="cityValidationMessage"
            :fieldModel.sync="$v.city.$model"
            autocomplete="off"
          />
          <text-field
            inputType="text"
            label="ZIP / Postal code"
            :large="true"
            :errorMessage="postalCodeValidationMessage"
            :fieldModel.sync="$v.postalCode.$model"
            :value="postalCode"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="section-company">
        <div class="formTitle">Company</div>
        <div class="section-company multiInputLine inputLine">
          <text-field
            inputType="text"
            label="Company name"
            :large="true"
            :value="companyName"
            autocomplete="off"
            :errorMessage="companyNameValidationMessage"
            :fieldModel.sync="$v.companyName.$model"
            class="inputLine"
          />
          <text-field
            ref="taxNumberField"
            inputType="text"
            label="Tax number"
            helper="(Optionnal)"
            autocomplete="off"
            :large="true"
            :value="taxNumber"
            :errorMessage="taxNumberValidationMessage"
            :fieldModel.sync="$v.taxNumber.$model"
            @handleInput="resetInvalidTaxNumber"
          />
        </div>
      </div>
    </div>
    <div class="change-billing-info-modal-buttons">
      <div class="change-billing-info-modal-buttons--">
        <submit-button
          btnStyle="tertiary"
          size="medium"
          :label="$t('cancel')"
          @submit="closeModal"
        />
      </div>
      <div class="change-billing-info-modal-buttons--">
        <submit-button
          btnStyle="primary"
          size="medium"
          :label="$t('save')"
          :pending="pending"
          @submit="save"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { countries } from 'countries-list';
import { required } from 'vuelidate/lib/validators';
import get from 'lodash.get';

import { bus } from '@/utils/bus';
import { getGraphQlErrorData } from '@/utils/functions/global';
import { saveBillingInformations } from '@/utils/actions/subscriptions';

import taxIds from '../../../assets/taxids.json';

export default {
  props: {
    defaultAddress: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    },
    defaultCompany: {
      type: String,
      required: false,
      default: ''
    },
    email: {
      type: String,
      required: false,
      default: ''
    },
    defaultVat: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      pending: false,
      address: get(this.defaultAddress, 'line1'),
      addressSuite: get(this.defaultAddress, 'line2'),
      city: get(this.defaultAddress, 'city'),
      country: get(this.defaultAddress, 'country'),
      postalCode: get(this.defaultAddress, 'postal_code'),
      state: get(this.defaultAddress, 'state'),
      companyName: this.defaultCompany,
      taxNumber: get(this.defaultVat, 'value'),
      errorWithSubmittedTaxNumber: false
    };
  },
  validations: {
    country: { required },
    address: { required },
    city: { required },
    postalCode: { required },
    companyName: { required },
    taxNumber: {}
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'userCountry']),
    getCountryList() {
      const countriesISO = Object.keys(countries);
      // Manually sort some items
      countriesISO.splice(countriesISO.indexOf('US'), 1);
      countriesISO.splice(0, 0, 'US');
      countriesISO.splice(countriesISO.indexOf('FR'), 1);
      countriesISO.splice(0, 0, 'FR');
      // Delete Kosovo as it use workspaceMemberCount temporary ISO code
      countriesISO.splice(countriesISO.indexOf('XK'), 1);
      return countriesISO.map(i => ({
        value: i,
        label: `${countries[i].emoji}  ${countries[i].name}`
      }));
    },
    getUserCountry() {
      const country = this.country || this.userCountry;
      return {
        label: `${countries[country].emoji}  ${countries[country].name}`,
        value: country
      };
    },
    addressValidationMessage() {
      return this.$v.address.required ? null : this.$t('subscription.form.addressRequired');
    },
    cityValidationMessage() {
      return this.$v.city.required ? null : this.$t('subscription.form.cityRequired');
    },
    postalCodeValidationMessage() {
      return this.$v.postalCode.required ? null : this.$t('subscription.form.postalCodeRequired');
    },
    companyNameValidationMessage() {
      return this.$v.companyName.required ? null : this.$t('subscription.form.companyNameRequired');
    },
    taxNumberValidationMessage() {
      return this.errorWithSubmittedTaxNumber
        ? this.$t('subscription.form.invalidTaxNumber')
        : null;
    },
    onError() {
      return (
        this.addressValidationMessage !== null ||
        this.cityValidationMessage !== null ||
        this.postalCodeValidationMessage !== null ||
        this.companyNameValidationMessage !== null
      );
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    resetInvalidTaxNumber() {
      this.errorWithSubmittedTaxNumber = false;
    },
    selectCountry(country) {
      this.country = country;
    },
    async save() {
      if (!this.onError) {
        this.pending = true;
        let errorCount = 0;
        const typeIds = taxIds.find(t => t.code === this.country).type;
        const variables = {
          workspaceId: this.currentWorkspace.id,
          billingInformation: {
            address: {
              line1: this.address,
              line2: this.addressSuite,
              city: this.city,
              postal_code: this.postalCode,
              country: this.country,
              state: this.state
            },
            company: this.companyName,
            email: this.email
          }
        };
        let submissionSuccessful = false;
        /* Important note :
        // Use this for loop in order to get sync promise resolution
        */
        for (const type of typeIds) { // eslint-disable-line
          if (submissionSuccessful) {
            // Once a submission is OK, we don't want to try other vat types
            continue; // eslint-disable-line
          }
          if (this.taxNumber && this.taxNumber !== '') {
            variables.billingInformation.vat = {
              type,
              value: this.taxNumber
            };
          }
          await saveBillingInformations(this, variables) // eslint-disable-line
            .then(() => { //eslint-disable-line
              submissionSuccessful = true;
              bus.$emit('refetchStripeCustomer');
              this.pending = false;
              this.$emit('closeModal');
              bus.$emit('showAlert', {
                message: { key: 'modificationSuccess' },
                style: 'success',
                delay: 5000
              });
            })
            .catch(error => { //eslint-disable-line
              const errorData = getGraphQlErrorData(error);
              if (errorData.includes('VAT error')) {
                errorCount += 1;
                if (errorCount === typeIds.length) {
                  this.pending = false;
                  this.errorWithSubmittedTaxNumber = true;
                  // Update component props directly to force error display
                  this.$refs.taxNumberField.showError = true;
                }
                return;
              }
              this.pending = false;
              bus.$emit('showAlert', {
                message: { key: 'invalidAddressValue' },
                style: 'danger',
                delay: 5000
              });
            });
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.change-billing-info-modal {
  &-body {
    max-height: calc(100vh - 41px - 56px - 57px - 24px - 57px);
    overflow-y: auto;
    padding: 24px;
  }
  &-buttons {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $color_neutral_30;
    padding: 8px 24px;
    box-sizing: border-box;

    &-- {
      &:first-child {
        margin: 0 16px 0 0;
      }
    }
  }
}
.formTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 120%;
  padding-bottom: 24px;
  height: 40px;
  display: flex;
  align-items: center;
}
.inputLine {
  margin-bottom: 24px;
}
.multiInputLine {
  display: flex;
  flex-direction: row;
  // @media screen and (max-width: 1080px) {
  //   flex-direction: column;
  // }
  & > * {
    flex: 1;
    &:first-child {
      margin-right: 16px;
    }
  }
}
.section {
  &-address {
    border-bottom: 1px solid $color_neutral_30;
    margin-bottom: 24px;
  }
}
</style>

<style lang="scss">
.change-billing-info-modal {
  &-body {
    .textfield {
      max-width: unset;
    }
  }
}
</style>
