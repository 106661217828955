
<div class="replace-audio-file-modal">
  <div class="replace-audio-file-modal-body">
    <div class="replace-audio-file-modal-body-description">
      {{ $t('modalReplaceAudioFile.content', { title: title }) }}
    </div>
  </div>
  <div class="replace-audio-file-modal-buttons">
    <div class="replace-audio-file-modal-buttons--">
      <submit-button
        btnStyle="tertiary"
        size="medium"
        :label="$t('cancel')"
        @submit="closeModal"
      />
    </div>
    <div class="replace-audio-file-modal-buttons--">
      <submit-button
        btnStyle="primary"
        size="medium"
        :label="$t('yesContinue')"
        @submit="submit"
      />
    </div>
  </div>
</div>
