
<ScrollShadow>
  <div class="sidebar">
    <div class="sidebar-options" v-for="group in groups" :key="group.title">
      <div class="sidebar-options-title" v-if="group.title">{{ group.title }}</div>
      <router-link
        v-for="option in group.options"
        :key="option.icon"
        :to="option.route"
        class="sidebar-options-item"
      >
        <div class="icon" :class="getIconClassName(option)">
          <div v-if="option.counter" class="icon-chip" />
        </div>
        <div class="sidebar-options-item-label">{{ option.label }}</div>
        <div v-if="option.counter" class="counter">
          {{ option.counter > 99 ? '99+' : option.counter }}
        </div>
        <div v-if="option.isNew" class="badge-new">
          <img src="/images/new-badge.svg" alt="new badge" />
        </div>
      </router-link>
    </div>
  </div>
</ScrollShadow>
