<template>
  <div class="main-container">
    <div
      :key="currentWorkspace.libraries[0].id"
      v-if="me"
      class="main"
      :class="{ isSettings: isSettings }"
      :style="gridStyle"
      @dragover.prevent="handleDragOver"
    >
      <div
        v-if="showDropZone && !isDraggingItemList"
        @dragleave.prevent="showDropZone = false"
        @drop.prevent.stop="handleDrop"
        class="main-dropzone"
      >
        <div class="main-dropzone-content">
          <div class="main-dropzone-content-icon">
            <i class="main-dropzone-content-icon-upload ri-upload-line" />
          </div>
          <div class="main-dropzone-content-helper">
            <div class="main-dropzone-content-helper-title">{{ $t('dropFiles') }}</div>
            <div class="main-dropzone-content-helper-desc">{{ $t('toUpload') }}</div>
          </div>
        </div>
        <div class="main-dropzone-background" />
      </div>
      <div class="main-banner" v-if="displayBanner">
        <maintenanceBanner v-if="displayMaintenanceBanner" />
        <workspace-full-banner v-if="displayWorkspaceFullBanner" :memberRole="memberRole" />
        <browser-banner v-if="displayBrowserBanner" @hideBrowserBanner="hideEmailBrowser" />
        <information-banner
          v-if="displayEmailBanner"
          :mailInfo="UserEmails"
          @hideEmailBanner="hideEmailBanner"
        />
        <auto-tag-banner v-if="displayAutoTagInfoBanner" @hideAutoTagBanner="hideAutoTagBanner" />
        <ai-upload-banner v-if="displayAiUploadBanner" @hideAIUploadBanner="hideAIUploadBanner" />
      </div>
      <div class="main-header">
        <main-header :me="me" />
      </div>
      <div
        v-if="displaySubHeader"
        class="main-sub-header"
        :class="{ 'subHeader-separator': isMainScrolling }"
      >
        <sub-header />
        <div v-if="displayLibraryContextActions" class="contextual-actions">
          <submit-button
            class="action mr-2"
            btnStyle="tertiary"
            size="small"
            :label="$t('fileUpload')"
            iconClass="ri-file-upload-fill"
            @submit="handleOpenFileInput()"
          />
          <submit-button
            class="action"
            btnStyle="tertiary"
            size="small"
            :label="$t('folderUpload')"
            iconClass="ri-folder-upload-fill"
            @submit="handleOpenFileInput(true)"
          />
        </div>
        <div v-if="displayProjectContextActions" class="contextual-actions ca-project">
          <submit-button
            class="action"
            btnStyle="tertiary"
            size="small"
            :label="$t('newProject')"
            iconClass="ri-folder-fill"
            @submit="handleCreateProjectRedirect"
          />
        </div>
        <div
          class="albums-sort"
          v-if="$route.name === 'albums' && this.currentWorkspace.total_supports > 0"
        >
          <sort-dropdown :options="albumSortOptions" :sort="albumSort" @sort="handleAlbumSort" />
        </div>
      </div>
      <div class="main-nav">
        <div class="main-nav-wrapper">
          <div class="main-nav-wrapper-workspace">
            <workspace-dropdown
              :currentWorkspaceId="currentWorkspace.id"
              :name="currentWorkspace.name"
              :initial="currentWorkspace.name.substring(0, 1).toUpperCase()"
              :pictureUrl="currentWorkspace.logo && currentWorkspace.logo.url"
              :headerOption="workspaceDropdown.header"
              :options="workspaceDropdown.options"
              :workspaces="workspaceDropdown.workspaceList"
              :hubs="workspaceDropdown.hubList"
              :footerOption="workspaceDropdown.footer"
              @handleWorkspaceClick="handleWorkspaceClick"
              @handleHubClick="handleHubClick"
            />
          </div>
          <div class="main-nav-wrapper-scroll">
            <sidebar :groups="sidebarGroups" />
          </div>
          <div v-if="isFreeTrialEnabled" class="main-nav-wrapper-banner-freetrial">
            <div class="main-nav-wrapper-banner-freetrial-container">
              <p class="main-nav-wrapper-banner-freetrial-title">
                {{ $t('freeTrialBanner.title') }}
              </p>
              <p
                v-html="
                  $t('freeTrialBanner.text', {
                    label: getFreeTrialDaysLeftLabel
                  })
                "
                class="main-nav-wrapper-banner-freetrial-text"
              />
            </div>
          </div>
          <div class="main-nav-wrapper-bottom">
            <div class="main-nav-wrapper-bottom-members">
              <div class="main-nav-wrapper-bottom-members-title" @click="redirectToMembersSettings">
                <i class="ri-parent-line"></i>
                {{ currentWorkspace.seats_used }}
                {{
                  currentWorkspace.subscription.plan.name === 'Free'
                    ? `/ ${currentWorkspace.subscription.seats}`
                    : ''
                }}
                {{ $t('members') }}
              </div>
              <submit-button
                class="inviteBtn"
                btnStyle="secondary"
                size="small"
                :label="$t('invite')"
                iconClass="ri-user-add-line"
                @submit="openAddMembersModal"
              />
            </div>
            <div class="main-nav-wrapper-bottom-storage">
              <div class="main-nav-wrapper-bottom-storage-title">
                <i class="ri-server-line"></i>
                {{ $t('storageTitle') }}
                <i
                  class="ri-error-warning-fill"
                  v-if="displayWarningIcon"
                  v-tooltip="{
                    content: $t('workspaceAlmostFull'),
                    classes: 'warning-tooltip',
                    position: 'top'
                  }"
                ></i>
              </div>
              <div class="main-nav-wrapper-bottom-storage-progress">
                <progress-bar
                  class="progress-bar"
                  :helper="getWorkspaceTracksCount"
                  :percent="progressBarTracksValue"
                  :color="progressBarTracksColor"
                  :size="137"
                />
              </div>
              <div class="main-nav-wrapper-bottom-storage-progress">
                <progress-bar
                  class="progress-bar"
                  :helper="
                    $t('storageUsed', { used: spaceUsedInOctets, total: totalSpaceInOctets })
                  "
                  :percent="progressBarStorageValue"
                  :color="progressBarStorageColor"
                  :size="137"
                />
              </div>
              <div v-tooltip="myRoleId === '2' ? 'Only admins can switch plans' : ''">
                <submit-button
                  class="upgradeBtn"
                  btnStyle="primary"
                  size="small"
                  :label="$t('upgrade')"
                  :disabled="myRoleId === '2'"
                  @submit="goToOffersSettings"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-view" ref="mainView" @scroll.passive="isMainScrollingSetter">
        <div class="main-view-wrapper">
          <router-view></router-view>
        </div>
      </div>
      <div
        :class="{
          'main-manager': Object.keys(playlist).length !== 0,
          'main-manager-hidden': Object.keys(playlist).length === 0,
          'main-manager-edit-panel': isEditPanelOpened
        }"
      >
        <download-manager
          v-if="isDownloadingArchive"
          :onClose="closeDownloadManager"
          :status="downloadArchiveStatus"
          :labels="{
            close: $t('close'),
            pending: {
              header: $t('downloadMessages.pending.header'),
              body: $t('downloadMessages.pending.body')
            },
            processing: {
              header: $t('downloadMessages.processing.header'),
              body: tracksToDownloadCounter
                ? $t('downloadMessages.processing.body', { tracks: tracksToDownloadCounter })
                : $t('downloadMessages.processingProject.body')
            },
            done: {
              header: $t('downloadMessages.done.header'),
              body: tracksToDownloadCounter
                ? $t('downloadMessages.done.body', { tracks: tracksToDownloadCounter })
                : $t('downloadMessages.doneProject.body')
            },
            error: {
              header: $t('downloadMessages.error.header'),
              body: $t('downloadMessages.error.body')
            }
          }"
        />
        <upload-manager
          v-if="!isUploadManagerClosed"
          :files="Object.values(this.trackUpload || {})"
          :isClosed.sync="isUploadManagerClosed"
          @addUploadToAProject="addUploadToAProject"
        />
      </div>
      <div
        v-if="Object.keys(playlist).length > 0"
        class="main-player"
        :class="{ isSettings: isSettings }"
      >
        <player-view
          :newProjectId="newProjectId"
          :keepProjectDropdownExpand="keepProjectDropdownExpand"
        />
      </div>
    </div>
    <div v-else class="loader">
      <spinner-without-progress color="grey" :size="32" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as Sentry from '@sentry/browser';
import get from 'lodash.get';
import { isToday } from 'date-fns';
import * as tus from 'tus-js-client';

import { bus } from '@/utils/bus';
import { identify, trackEvent } from '@/utils/functions/analytics';
import { get as getRoute } from '@/router/routes';
import {
  getLastWorkspaceIdFromStorage,
  getWorkspaceIdFromStorage,
  getConnectedAs,
  setWorkspaceIdInStorage,
  removeFromSession
} from '@/utils/storage';
import { COLORS, PAGINATOR_LIMIT, SEARCH_CONTEXT } from '@/utils/constants';
import {
  getGraphQlErrorCode,
  readEntryContentAsync,
  formatToNavigatorLanguage
} from '@/utils/functions/global';
import {
  isWorkspaceFull,
  getMemberRole,
  isWorkspaceAlmostFull,
  getStorageInGB,
  getMaximumStorage,
  getTotalTracks,
  getTracksLeft
} from '@/utils/functions/subscription'; // FIXME: move to utils/functions/workspace/
import { UPLOAD_TYPE, UPLOAD_STATUS } from '@/store/upload';
import {
  handleAddAttachmentsMutation,
  openUploadFileInput,
  handleAddTracksMutation,
  getFileId
} from '@/utils/functions/upload';
import { ME, USER_EMAILS, WORKSPACE } from '@/graphql/queries/user';
import { GET_ROLES } from '@/graphql/queries/workspace';
import { GET_DOWNLOAD_FILE_LINK } from '@/graphql/queries/library';

import { ARCHIVE_LIBRARY_TRACKS } from '@/graphql/mutations/library';
import { ADD_TRACK_TO_PROJECT } from '@/graphql/mutations/project';
import {
  PROJECT_DETAILS_TRACKS,
  PROJECT_LIST,
  PROJECT_DETAILS_INFO,
  PROJECT_TOTAL_ATTACHMENTS
} from '@/graphql/queries/project';
import { INBOX_LIST_SUBMISSIONS_UNREAD } from '@/graphql/queries/inbox';
import { JOIN_WORKSPACE } from '@/graphql/mutations/workspace';
import { sortUploadedFiles, getFileSize, getAllowedFileTypes } from '@/utils/functions/audio';

import {
  getWorkspaceTrackLimit,
  isFreeTrial,
  hasActiveSubscription,
  getFreeTrialDaysLeft
} from '@/utils/functions/workspace';

import { subscribeFreeTrial } from '@/utils/actions/subscriptions';

import {
  ProjectShareMutation,
  TracksShareMutation,
  SupportShareMutation,
  ArtistShareMutation
} from '@/utils/functions/share';
import PlayerView from '@/containers/player';
import FullSpaceModal from '@/containers/modals/fullSpace';
import LimitTracksModal from '@/containers/modals/limitTracksModal';
import CreateProjectModal from '@/containers/modals/createProject';
import ContinueEditingModal from '@/containers/modals/continueEditing';
import MoveToAddTo from '@/containers/modals/moveToAddTo';
import UploadToModal from '@/containers/modals/uploadTo';
import SubHeader from '@/containers/subHeader';
import MainHeader from '@/containers/mainHeader';
import informationBanner from '@/containers/informationBanner';
import AutoTagBanner from '@/containers/AIAutoTagBanner';
import AiUploadBanner from '@/containers/AIUploadBanner';
import browserBanner from '@/containers/browserBanner';
import maintenanceBanner from '@/containers/banners/maintenance';
import WorkspaceFullBanner from '@/containers/banners/workspaceFull';
import SortDropdown from '@/containers/dropdowns/sortBy';
import { displayAddMembersModal } from '@/utils/actions/modals';

const KEY_HIDE_EMAIL_BANNER = 'KEY_HIDE_EMAIL_BANNER';
const KEY_HIDE_BROWSER_BANNER = 'KEY_HIDE_BROWSER_BANNER';
const KEY_HIDE_BETA_WARNING_BANNER = 'KEY_HIDE_BETA_WARNING_BANNER';
const KEY_HIDE_AUTO_TAG_INFO_BANNER = 'KEY_HIDE_AUTO_TAG_INFO_BANNER';

const DEFAULT_UPLOAD_CONFIG = {
  mimetypes: [],
  extensions: []
};

export default {
  props: {
    isBrowserSupported: {
      type: Boolean,
      required: false
    }
  },
  components: {
    PlayerView,
    SubHeader,
    MainHeader,
    informationBanner,
    AutoTagBanner,
    AiUploadBanner,
    browserBanner,
    maintenanceBanner,
    WorkspaceFullBanner,
    SortDropdown
  },
  data() {
    return {
      uploadManagerItems: [],
      uploadPercent: 0,
      uploadSize: 0,
      uploadTrackMaxFileSize: get(
        this.$config,
        'uploads.library.libraryFile.track.max_file_size',
        314572800
      ),
      uploadManagerSizeCount: '',
      isUploadManagerClosed: true,
      isDownloadManagerOpened: false,
      tracksToDownloadCounter: 0,
      dropedAudioFileCount: 0,
      showDropZone: false,
      unreadSubmissions: null,
      keepProjectDropdownExpand: false,
      newProjectId: '',
      abortHandler: undefined,
      storeWorkspaceIdToGo: null,
      isMainScrolling: false,
      isHideEmailBanner: localStorage.getItem(KEY_HIDE_EMAIL_BANNER),
      isHideAutoTagBanner: localStorage.getItem(KEY_HIDE_AUTO_TAG_INFO_BANNER),
      isHideBrowserBanner: true,
      subscribingFreeTrial: false,
      isHideAiUploadBanner: true,
      documentsUploadConfig: get(
        this.$config,
        'uploads.project.attachment.allowed_files.documents',
        DEFAULT_UPLOAD_CONFIG
      ),
      imagesUploadConfig: get(
        this.$config,
        'uploads.project.attachment.allowed_files.images',
        DEFAULT_UPLOAD_CONFIG
      ),
      videosUploadConfig: get(
        this.$config,
        'uploads.project.attachment.allowed_files.videos',
        DEFAULT_UPLOAD_CONFIG
      ),
      musicUploadConfig: get(
        this.$config,
        'uploads.project.attachment.allowed_files.music',
        DEFAULT_UPLOAD_CONFIG
      ),
      fileSelectorId: 0,
      albumSortOptions: [
        { label: this.$t('name'), order: 'A-Z', filter: { orderBy: 'name', sortBy: 'asc' } },
        { label: this.$t('name'), order: 'Z-A', filter: { orderBy: 'name', sortBy: 'desc' } },
        { separator: true },
        {
          label: this.$t('releaseDate'),
          order: 'Newest',
          filter: { orderBy: 'release_date', sortBy: 'desc' }
        },
        {
          label: this.$t('releaseDate'),
          order: 'Oldest',
          filter: { orderBy: 'release_date', sortBy: 'asc' }
        }
      ],
      albumSort: {
        label: this.$t('name'),
        order: 'A-Z',
        filter: { orderBy: 'name', sortBy: 'asc' }
      },
      allowedMimeTypes: getAllowedFileTypes(this.$config)
    };
  },
  apollo: {
    me: {
      query: ME,
      result() {
        this.$store.commit('setMe', this.me);
        if (!this.me.workspaces || this.me.workspaces.length === 0) {
          this.$router.push(getRoute('createWorkspace'));
        }

        if (this.$route.query.wid) {
          setWorkspaceIdInStorage(this.$route.query.wid);
        } else {
          setWorkspaceIdInStorage(
            getWorkspaceIdFromStorage() ||
              getLastWorkspaceIdFromStorage() ||
              this.me.workspaces[0].id
          );
        }

        const currentWorkspaceId =
          getWorkspaceIdFromStorage() ||
          getLastWorkspaceIdFromStorage() ||
          this.me.workspaces[0].id;

        const currentWorkspace =
          this.getCurrentWorkspaceById(currentWorkspaceId) || this.me.workspaces[0];
        this.$store.commit('changeCurrentWorkspace', currentWorkspace);
        this.$store.commit('setIsWorkspaceLocked', currentWorkspace.is_locked);
        this.$store.commit('changeIsLibraryEmpty', get(currentWorkspace, 'total_tracks', 0) === 0);
        this.$store.commit('setUserPrimaryEmail', this.me.email);

        if (
          this.$route.query.subscription === 'freetrial' &&
          !hasActiveSubscription(this.currentWorkspace) &&
          !this.currentWorkspace.free_trial_used &&
          this.subscribingFreeTrial === false
        ) {
          this.subscribingFreeTrial = true;
          subscribeFreeTrial(this, this.me, this.currentWorkspace, this.$route.query.planId);
        }

        // Setup Sentry
        if (getConnectedAs() === false) {
          Sentry.configureScope(scope =>
            scope.setUser({
              id: this.me.id,
              email: this.me.email
            })
          );
        }
        // Adds scope for workspaceId
        Sentry.withScope(scope => {
          scope.setTag('workspaceId', currentWorkspaceId);
        });
        // Setup Segment
        identify(this.me.id, {
          email: this.me.email,
          lastname: this.me.lastname,
          firstname: this.me.firstname
        });
        if (!currentWorkspace) return;
        if (!currentWorkspace.subscription) {
          this.$router.push(getRoute('workspacePlans', { id: currentWorkspace.id }));
        }
      }
    },
    UserEmails: {
      query: USER_EMAILS
    },
    roles: {
      query: GET_ROLES
    },
    InboxList: {
      query: INBOX_LIST_SUBMISSIONS_UNREAD,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          workspaceId: this.currentWorkspace.id
        };
      },
      skip() {
        return !this.me;
      },
      result(res) {
        this.unreadSubmissions = get(res, 'data.InboxList[0].counters.submissions_unread', null);
      }
    }
  },
  computed: {
    ...mapGetters([
      'currentWorkspace',
      'playlist',
      'isDraggingItemList',
      'searchLibraryQueryParameters',
      'isDownloadingArchive',
      'isEditPanelOpened',
      'isEditPanelChanged',
      'downloadArchiveStatus',
      'trackUpload'
    ]),
    isFreeTrialEnabled() {
      return isFreeTrial(this.currentWorkspace);
    },
    getFreeTrialDaysLeftLabel() {
      const daysLeft = getFreeTrialDaysLeft(this.currentWorkspace);
      if (daysLeft > 1) {
        return `<br/>in ${daysLeft} days`;
      }
      const freeTrialEnd = get(
        this.currentWorkspace,
        'subscription.payment_information.free_trial.end'
      );
      return isToday(new Date(parseInt(freeTrialEnd, 10) * 1000)) ? 'today' : 'tomorrow';
    },
    freeTrialDaysLeft() {
      return getFreeTrialDaysLeft(this.currentWorkspace);
    },
    gridStyle() {
      // list of route where we want the player to be displayed in full
      const fullPlayer = ['projectDetailsBrowseList', 'projectDetailsBrowseSearch'];
      const template = [];
      const rows = [];
      // Information Banner
      if (this.displayBanner) {
        template.push("'nav infoBanner'");
        rows.push('auto');
      }
      // Header
      template.push("'nav header'");
      rows.push('56px');
      // SubHeader
      if (this.displaySubHeader) {
        template.push("'nav subHeader'");
        rows.push('auto');
      }
      // Main view
      template.push("'nav view'");
      rows.push('1fr');
      // Player
      if (Object.keys(this.playlist).length > 0) {
        template.push(fullPlayer.includes(this.$route.name) ? "'player player'" : "'nav player'");
        rows.push('64px');
      }
      return {
        'grid-template-areas': template.join(' '),
        'grid-template-rows': rows.join(' ')
      };
    },
    sidebarGroups() {
      return [
        {
          options: [
            {
              label: this.$t('library'),
              icon: 'ri-music-2-line',
              iconFill: 'ri-music-2-fill',
              route: getRoute('library')
            },
            {
              label: this.$t('projects'),
              icon: 'ri-folder-music-line',
              iconFill: 'ri-folder-music-fill',
              route: getRoute('projects')
            },
            {
              label: this.$t('shared'),
              icon: 'ri-share-forward-line',
              iconFill: 'ri-share-forward-fill',
              route: getRoute('shared')
            },
            {
              label: this.$t('received'),
              icon: 'ri-inbox-line',
              iconFill: 'ri-inbox-fill',
              route: `${getRoute('inbox')}?wid=${this.currentWorkspace.id}`,
              counter: this.unreadSubmissions
            }
          ]
        }
      ];
    },
    isSettings() {
      return this.$route.path.includes('/settings/');
    },
    displayMaintenanceBanner() {
      return false;
    },
    displayEmailBanner() {
      if (!this.UserEmails) {
        return false;
      }
      const user = this.UserEmails.find(item => item.is_primary);
      return get(user, 'is_verified') !== true && this.isHideEmailBanner === null;
    },
    displayBrowserBanner() {
      return !this.isBrowserSupported && !this.isHideBrowserBanner;
    },
    displayAutoTagInfoBanner() {
      return !this.isHideAutoTagBanner;
    },
    displayAiUploadBanner() {
      return !this.isHideAiUploadBanner && this.currentWorkspace.properties.auto_tagger;
    },
    displayBanner() {
      return (
        this.displayMaintenanceBanner ||
        this.displayEmailBanner ||
        this.displayBrowserBanner ||
        this.displayWorkspaceFullBanner ||
        this.displayAutoTagInfoBanner ||
        this.displayAiUploadBanner
      );
    },
    displaySubHeader() {
      const subHeader = [
        'trackList',
        'artists',
        'albums',
        'activeProjects',
        'archiveProjects',
        'trackSearch'
      ];
      return subHeader.includes(this.$route.name);
    },
    displayLibraryContextActions() {
      const contextActions = ['trackList', 'trackSearch', 'artists', 'albums'];
      return contextActions.includes(this.$route.name);
    },
    displayProjectContextActions() {
      const contextActions = ['activeProjects'];
      return contextActions.includes(this.$route.name);
    },
    totalSpace() {
      if (typeof this.currentWorkspace.subscription.storage === 'string')
        return parseInt(this.currentWorkspace.subscription.storage, 10) * 1000000;
      return this.currentWorkspace.subscription.storage * 1000000;
    },
    getWorkpaceTotalTracksCount() {
      return getTotalTracks(this.currentWorkspace) + getTracksLeft(this.currentWorkspace);
    },
    getWorkspaceTracksCount() {
      return `${formatToNavigatorLanguage(
        getTotalTracks(this.currentWorkspace)
      )} / ${formatToNavigatorLanguage(getWorkspaceTrackLimit(this.currentWorkspace))} Tracks`;
    },
    roleListInvit() {
      return this.roles.map(role => ({
        label: role.name,
        value: role.id,
        description: this.$t(`roles.${role.id}.description`)
      }));
    },
    myRoleId() {
      return this.currentWorkspace.members.find(m => m.user_id === this.me.id).role.id;
    },
    workspaceDropdown() {
      const planName = isFreeTrial(this.currentWorkspace)
        ? 'Free trial'
        : this.$t('planName', { name: this.currentWorkspace.subscription.plan.name });
      return {
        header: {
          title: this.currentWorkspace.name,
          subtitle: `${planName} - ${this.currentWorkspace.seats_used} ${this.$tc(
            'member',
            this.currentWorkspace.seats_used
          )}`
        },
        options: [
          {
            label: this.$t('workspaceSettings'),
            action: () => {
              this.$router.push(getRoute('generalSettings'));
            },
            icon: 'ri-settings-3-line'
          }
        ],
        workspaceList: this.me.workspaces.map(w => ({
          pictureUrl: w.logo && w.logo.url,
          name: w.name,
          id: w.id
        })),
        hubList: this.me.hubs,
        footer: {
          title: this.$t('createWorkspace'),
          action: this.goToWorkspaceCreation
        }
      };
    },
    spaceUsed() {
      return parseInt(this.currentWorkspace.space_used, 10);
    },
    progressBarStorageValue() {
      let value = 0;
      if (this.spaceUsed > 0) {
        value =
          Math.round((this.spaceUsed * 100) / this.totalSpace) > 1
            ? Math.round((this.spaceUsed * 100) / this.totalSpace)
            : 1;
      }
      return value;
    },
    progressBarTracksValue() {
      const value = Math.round(
        (getTotalTracks(this.currentWorkspace) / this.getWorkpaceTotalTracksCount) * 100
      );
      return value > 1 ? value : 1;
    },
    spaceIsFull() {
      return getStorageInGB(this.currentWorkspace) >= getMaximumStorage(this.currentWorkspace);
    },
    progressBarStorageColor() {
      return this.getProgressBarColor(this.progressBarStorageValue);
    },
    progressBarTracksColor() {
      return this.getProgressBarColor(this.progressBarTracksValue);
    },
    spaceUsedInOctets() {
      return getFileSize(this.spaceUsed);
    },
    totalSpaceInOctets() {
      return getFileSize(this.totalSpace);
    },
    filesToUpload() {
      return Object.values(this.trackUpload).filter(item => item.status === UPLOAD_STATUS.WAITING);
    },
    createProjectModal() {
      return {
        title: this.$t('newProject'),
        size: 'medium',
        isVisible: false,
        component: CreateProjectModal
      };
    },
    showSearch() {
      return this.$route.meta.showSearch;
    },
    hideFilter() {
      return this.$route.meta.hideFilter;
    },
    searchContext() {
      if (this.$route.path.includes('library')) {
        return SEARCH_CONTEXT.LIBRARY_SEARCH_CONTEXT;
      }
      if (
        this.$route.path.includes('projects/active') ||
        this.$route.path.includes('projects/archive')
      ) {
        return SEARCH_CONTEXT.PROJECT_SEARCH_CONTEXT;
      }
      return null;
    },
    memberRole() {
      return getMemberRole(this.currentWorkspace, this.me);
    },
    displayWorkspaceFullBanner() {
      return isWorkspaceFull(this.currentWorkspace);
    },
    displayWarningIcon() {
      return isWorkspaceAlmostFull(this.currentWorkspace);
    }
  },
  watch: {
    $route(next) {
      //  We reset the editPanel status when exiting page
      if (this.isEditPanelOpened) {
        this.$store.commit('setEditPanelOpened', false);
      }
      const toArtistOrAlbum = next.name === 'album' || next.name === 'artistAlbums';
      this.routeRedirection(toArtistOrAlbum);
      this.fetchInboxCounter();
    },
    isUploadManagerClosed() {
      // Reset all values when the manager is closed
      if (this.isUploadManagerClosed) {
        this.dropedAudioFileCount = 0;
        this.uploadManagerItems = [];
        this.uploadPercent = 0;
        this.uploadManagerSizeCount = '';

        // V2
        this.$store.commit('resetTrackUpload');
      }
    },
    isDraggingItemList() {
      if (!this.isDraggingItemList) {
        this.showDropZone = false;
      }
    },
    searchLibraryQueryParameters() {
      this.routeRedirection();
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.name === 'workspaceId') {
        const that = vm;
        const { id } = to.params;
        that.$apollo
          .mutate({
            mutation: JOIN_WORKSPACE,
            variables: {
              workspaceInvitationToken: id
            }
          })
          .then(response => {
            const newWorkspaceId = response.data.joinWorkspace.id;
            if (newWorkspaceId) {
              setWorkspaceIdInStorage(newWorkspaceId);
              window.location = getRoute('trackList');
            }
          })
          .catch(() => {
            window.location = getRoute('trackList');
          });
      }
    });
  },
  created() {
    bus.$on('addTracksToProject', params => {
      this.handleAddTracksToProjectMutation(params);
    });
    bus.$on('downloadTrack', tracks => {
      this.tracksToDownloadCounter = tracks.length;
      this.handleDownloadTrackMutation(tracks);
    });
    bus.$on('handleArtistShareMutation', param => {
      ArtistShareMutation(this, param, this.currentWorkspace.id);
    });
    bus.$on('prepareUploadForFiles', files => {
      this.prepareUploadForFiles(Array.from(files));
    });
    bus.$on('handleProjectShareMutation', params => {
      ProjectShareMutation(this, params, this.currentWorkspace.id);
    });
    bus.$on('handleSupportShareMutation', param => {
      SupportShareMutation(this, param, this.currentWorkspace.id);
    });
    bus.$on('handleTracksShareMutation', param => {
      TracksShareMutation(this, param, this.currentWorkspace.id);
    });
    bus.$on('openCreateProjectModal', () => {
      this.keepProjectDropdownExpand = true;
      bus.$emit('displayModal', { ...this.createProjectModal, isVisible: true });
    });
    bus.$on('setNewProjectId', newProjectId => {
      this.newProjectId = newProjectId;
    });
    bus.$on('changeCurrentWorkspace', (wid, redirectionRoute) => {
      this.handleWorkspaceClick(
        this.me.workspaces.findIndex(w => w.id === wid),
        redirectionRoute
      );
    });
    window.addEventListener('beforeunload', this.handleBeforeunload);
    const hideBrowserStr = localStorage.getItem(KEY_HIDE_BROWSER_BANNER);
    if (!hideBrowserStr) {
      this.isHideBrowserBanner = false;
    } else {
      const hideBrowser = JSON.parse(hideBrowserStr);
      const now = new Date();
      if (now.getTime() > hideBrowser.expire) {
        localStorage.removeItem(KEY_HIDE_BROWSER_BANNER);
        this.isHideBrowserBanner = false;
      } else {
        this.isHideBrowserBanner = hideBrowser.value;
      }
    }
  },
  destroyed() {
    bus.$off('addTracksToProject');
    bus.$off('downloadTrack');
    bus.$off('handleArtistShareMutation');
    bus.$off('prepareUploadForFiles');
    bus.$off('handleProjectShareMutation');
    bus.$off('handleSupportShareMutation');
    bus.$off('handleTracksShareMutation');
    bus.$off('openCreateProjectModal');
    bus.$off('setNewProjectId');
    window.removeEventListener('beforeunload', this.handleBeforeunload);
  },
  methods: {
    handleDragOver(e) {
      e.preventDefault();
      // Prevent repeating state modification to fix BRID-41934 for Firefox
      if (this.showDropZone || get(this.$route, 'meta.forbidDragAndDrop', false) === true) {
        window.addEventListener(
          'dragover',
          event => {
            event.preventDefault();
          },
          false
        );
        window.addEventListener(
          'drop',
          event => {
            event.preventDefault();
          },
          false
        );
        return;
      }
      const types = get(e, 'dataTransfer.types', null);
      if (types && types.includes('Files')) {
        this.showDropZone = true;
      }
    },
    handleCreateProjectRedirect() {
      trackEvent('New Project Click', {
        category: 'project',
        page_view: 'project list'
      });
      const modal = {
        title: this.$t('newProject'),
        size: 'medium',
        isVisible: false,
        component: CreateProjectModal,
        props: [{ name: 'redirectToProject', value: true }]
      };
      bus.$emit('displayModal', { ...modal, isVisible: true });
    },
    routeRedirection(toArtistOrAlbum) {
      // Redirection vers les routes en fonction des paramètres de recherches
      if (
        this.$route.path.includes('library/tracks') ||
        this.$route.path.includes('library/artists') ||
        this.$route.path.includes('library/albums')
      ) {
        if (
          this.searchLibraryQueryParameters &&
          this.$route.name !== 'trackSearch' &&
          this.$route.name !== 'sharedTracksDetails' &&
          this.$route.name !== 'sharedTracksSharings' &&
          this.$route.name !== 'sharedTracksActivity' &&
          !toArtistOrAlbum
        ) {
          this.$router.push(getRoute('trackSearch'));
        }
        if (!this.searchLibraryQueryParameters && this.$route.name === 'trackSearch') {
          this.$router.push(getRoute('trackList'));
        }
      }
      if (this.$route.path.includes('details/browse')) {
        if (
          this.searchLibraryQueryParameters &&
          this.$route.name !== 'projectDetailsBrowseSearch'
        ) {
          this.$router.push(getRoute('projectDetailsBrowseSearch', { id: this.$route.params.id }));
        }
        if (!this.searchLibraryQueryParameters && this.$route.name !== 'projectDetailsBrowseList') {
          this.$router.push(getRoute('projectDetailsBrowseList', { id: this.$route.params.id }));
        }
      }
    },
    addUploadToAProject(filesIds) {
      if (this.$isWorkspaceLockedVerification(this)) return;
      const fromSharedView = this.$route.path.includes('shared');
      let page_view;
      switch (this.$route.name) {
        case 'albums':
        case 'albumDetails':
          page_view = fromSharedView ? 'shared library album' : 'library album';
          break;
        case 'artists':
        case 'artistAlbums':
        case 'artistTracks':
          page_view = fromSharedView ? 'shared library artist' : 'library artist';
          break;
        case 'projectDetails':
          page_view = fromSharedView ? 'shared project details' : 'project details';
          break;
        case 'trackList':
        default:
          page_view = 'library track list';
          break;
      }
      trackEvent('Track AddTo Click', {
        category: 'addto',
        page_view,
        action_bar: false,
        upload_manager: true
      });
      bus.$emit('displayModal', {
        component: MoveToAddTo,
        isVisible: true,
        size: 'small',
        hideHeader: true,
        props: [{ name: 'filesIds', value: filesIds || this.selectedTracks }]
      });
    },
    closeDownloadManager() {
      this.isDownloadManagerOpened = false;
      this.$store.commit('setIsDownloadingArchive', false);
    },
    getCurrentWorkspaceById(id) {
      return this.me.workspaces.find(i => i.id === id);
    },
    handleOpenFileInput(isFolder = false) {
      // Generate a new ID before opening the file input
      const idMemory = this.fileSelectorId;
      while (idMemory === this.fileSelectorId) {
        this.fileSelectorId = Math.round(Math.random() * 1000);
      }
      openUploadFileInput(
        { id: this.fileSelectorId, isFolder, allowedMimeTypes: this.allowedMimeTypes },
        files => {
          this.prepareUploadForFiles(files);
        }
      );
    },
    async handleDrop(event) {
      this.showDropZone = false;
      const files = await Promise.all(
        [...event.dataTransfer.items].map(async item => {
          const entry = item.webkitGetAsEntry ? item.webkitGetAsEntry() : null;
          if (entry.isFile) {
            return item.getAsFile();
          }
          const entryContent = await readEntryContentAsync(entry);
          return entryContent;
        })
      );
      this.prepareUploadForFiles(files.flat());
    },
    prepareUploadForFiles(files) {
      if (this.$isWorkspaceLockedVerification(this)) return;
      const sortedFiles = sortUploadedFiles(files, this.allowedMimeTypes);
      const canUpload = this.checkWorkspaceLimitations(files, sortedFiles);
      if (!canUpload) {
        return;
      }
      const projectId = this.$route.name === 'projectDetails' && this.$route.params.id;
      if (!projectId && sortedFiles.attachmentFiles.length > 0) {
        // This modal allow to select on which project the files will be bound
        bus.$emit('displayModal', {
          isVisible: true,
          size: 'small',
          title: this.$t('uploadTo'),
          component: UploadToModal,
          onSubmit: this.executeUploadForFiles,
          props: [
            {
              name: 'sortedFiles',
              value: sortedFiles
            }
          ]
        });
      } else {
        this.executeUploadForFiles(sortedFiles, projectId);
      }
    },
    executeUploadForFiles(sortedFiles, projectId) {
      // if project, send the non audio file to the current project to be verify as attachement
      if (projectId && sortedFiles.attachmentFiles.length > 0) {
        this.$apollo
          .query({
            query: PROJECT_TOTAL_ATTACHMENTS,
            variables: {
              projectId
            }
          })
          .then(res => {
            const attachementsMaxFiles = get(
              this,
              '$config.uploads.project.attachment.max_files',
              50
            );
            if (
              get(res, 'data.ProjectDetails.total_attachments', 0) +
                sortedFiles.attachmentFiles.length >
              attachementsMaxFiles
            ) {
              bus.$emit('showAlert', {
                message: {
                  key: 'addAttachmentFail',
                  args: {
                    count: attachementsMaxFiles
                  }
                },
                style: 'danger',
                delay: 5000
              });
            } else {
              handleAddAttachmentsMutation(
                this,
                sortedFiles.attachmentFiles,
                projectId,
                this.currentWorkspace
              );
            }
          });
      }
      if (sortedFiles.audioFiles.length > 0) {
        this.initUploadManager(sortedFiles.audioFiles, projectId);
      }
    },
    checkWorkspaceLimitations(files, sortedFiles) {
      // To get the number of droped files filtered by audio type
      const memoryDroppedAudioFileCount = this.dropedAudioFileCount;
      this.dropedAudioFileCount += sortedFiles.audioFiles.length;
      if (this.dropedAudioFileCount > getTracksLeft(this.currentWorkspace)) {
        this.dropedAudioFileCount = memoryDroppedAudioFileCount;
        // empty setTimeout to change code order execution
        // close the previous modal if from "upload to" to avoid overlap
        // ( without it the close from the previous modal is called after the opening of the following )
        setTimeout(() => {
          bus.$emit('displayModal', {
            title: this.$t('trackLimit'),
            size: 'small',
            component: LimitTracksModal,
            onSubmit: this.upgradePlan,
            props: [{ name: 'isEditor', value: this.memberRole === 'Editor' }],
            isVisible: true
          });
        });
        return false;
      }
      // Calculate the size of all droped files
      let uploadSize = 0;
      files.forEach(file => {
        if (file.size < this.uploadTrackMaxFileSize) {
          uploadSize += file.size;
        }
      });

      // If the upload size is greater than the remaining space in the storage, display a modal and block the upload
      if (this.spaceIsFull || (uploadSize > 0 && uploadSize > this.totalSpace - this.spaceUsed)) {
        setTimeout(() => {
          bus.$emit('displayModal', {
            title: this.$t('storageFull'),
            size: 'small',
            component: FullSpaceModal,
            onSubmit: this.upgradePlan,
            props: [{ name: 'isEditor', value: this.memberRole === 'Editor' }],
            isVisible: true
          });
        }, 1);
        return false;
      }
      return true;
    },
    initUploadManager(files, projectId) {
      this.isUploadManagerClosed = false;
      files.forEach(file => {
        // Add file into store
        this.$store.commit('setNewUpload', {
          type: UPLOAD_TYPE.TRACK,
          id: getFileId(file),
          libraryFileId: null,
          message: null,
          progress: 0,
          name: file.name,
          size: file.size,
          droppedProjectId: projectId,
          file
        });
      });
      if (Object.keys(this.trackUpload).length > 0) {
        this.handleUploadFile();
        trackEvent('Files uploaded');
      }
    },
    handleUploadFile() {
      const itemToUpload = Object.values(this.trackUpload).find(i => {
        return i.status === UPLOAD_STATUS.WAITING;
      });
      const maxFileSize = get(this.$config, 'uploads.library.libraryFile.track.max_file_size');
      if (itemToUpload && itemToUpload.size > maxFileSize) {
        this.$store.commit('setUploadAsError', {
          type: UPLOAD_TYPE.TRACK,
          id: getFileId(itemToUpload.file),
          message: 'File is too big'
        });
        this.handleUploadFile();
        return;
      }
      if (!itemToUpload) {
        this.$apollo.query({
          query: WORKSPACE,
          variables: {
            workspaceId: this.currentWorkspace.id
          }
        });
        return;
      }
      handleAddTracksMutation(this, itemToUpload, this.currentWorkspace.libraries[0].id)
        .then(() => {
          this.handleUploadFile();
          this.isHideAiUploadBanner = false;
        })
        .catch(e => {
          this.$store.commit('setUploadAsError', {
            type: UPLOAD_TYPE.TRACK,
            id: getFileId(itemToUpload.file),
            message: 'File could not be uploaded, please try again'
          });
          // We retry once to upload the file then we continue
          if (!itemToUpload.retry) {
            tus.Upload.terminate(itemToUpload.uploadUrl)
              .then(() => {
                // FIXME
                console.log('DELETION SUCCESSFUL'); // eslint-disable-line
              })
              .catch(err => {
                // FIXME
                console.log('DELETION FAILED', err); // eslint-disable-line
              });
            const fingerprint = [
              'tus::tus',
              itemToUpload.name,
              itemToUpload.type,
              itemToUpload.size
            ].join('-');
            localStorage.removeItem(fingerprint);

            this.trackUpload[itemToUpload.id].status = UPLOAD_STATUS.WAITING;
            this.trackUpload[itemToUpload.id].retry = true;
          }
          // In case of unknown error while uploading, move to the next one
          this.handleUploadFile();
        });
    },
    handleBeforeunload(e) {
      let evt = e;
      const processedFileCount = Object.values(this.trackUpload).filter(
        i =>
          i.status === UPLOAD_STATUS.DONE ||
          i.status === UPLOAD_STATUS.ERROR ||
          i.status === UPLOAD_STATUS.CANCELED ||
          i.status === UPLOAD_STATUS.WARNING
      ).length;
      if (Object.keys(this.trackUpload).length !== processedFileCount) {
        if (!e) evt = window.event;
        evt.cancelBubble = true;
        evt.returnValue = this.$t('sureToLeave');
        if (evt.stopPropagation) {
          evt.stopPropagation();
          evt.preventDefault();
        }
      }
    },
    cancelEditPanelModifications() {
      this.$store.commit('changeIsEditPanelChanged', false);
      this.handleWorkspaceClick(this.storeWorkspaceIdToGo);
      this.storeWorkspaceIdToGo = null;
    },
    handleHubClick(index) {
      window.location = `/hub/${this.me.hubs[index].link}?wid=${this.currentWorkspace.id}`;
    },
    handleWorkspaceClick(index, route) {
      if (this.isEditPanelChanged && this.isEditPanelOpened) {
        this.storeWorkspaceIdToGo = index;
        bus.$emit('displayModal', {
          title: this.$t('saveYourChanges'),
          size: 'small',
          isVisible: true,
          component: ContinueEditingModal,
          onCancel: () => {
            this.cancelEditPanelModifications();
          }
        });
        return;
      }
      const newWorkspace = this.me.workspaces[index];
      this.$store.commit('setDisplayWorkspaceLockedAlert', false);
      this.$store.commit('changeCurrentWorkspace', newWorkspace);
      this.$store.commit('setIsWorkspaceLocked', newWorkspace.is_locked);
      // We restore player data to startup value
      this.$store.commit('changePlayingTrack', { id: undefined, play: false });
      this.$store.commit('changePlaylist', []);
      this.$store.commit('changeIsLibraryEmpty', get(newWorkspace, 'total_tracks', 0) === 0);
      // Close both download and upload manager when switching workspace
      this.isUploadManagerClosed = true;
      this.closeDownloadManager();
      removeFromSession(KEY_HIDE_BETA_WARNING_BANNER);
      setWorkspaceIdInStorage(newWorkspace.id);
      window.history.replaceState(null, null, window.location.pathname);
      // Remove search phrase and filters when switching workspace
      localStorage.removeItem('SEARCH_LIBRARY_QUERY_PARAMETERS');
      this.$store.commit('changeSearchLibraryQueryParameters', {});
      if (this.$router.currentRoute.name !== 'trackList') {
        this.$router.push(getRoute('trackList'));
      }
      if (!newWorkspace.subscription) {
        this.$router.push(getRoute('workspacePlans', { id: newWorkspace.id }));
      }
      if (route) {
        this.$router.push(getRoute(route.name, { id: route.id }));
      }
    },
    goToWorkspaceCreation() {
      this.$router.push(getRoute('createWorkspace'));
    },
    goToAccountSettings() {
      this.$router.push(getRoute('accountSettings'));
    },
    goToOffersSettings() {
      this.$router.push(getRoute('offersSettings'));
    },
    upgradePlan() {
      this.$router.push(getRoute('paymentSettings'));
    },
    handleDownloadTrackMutation(libraryFiles) {
      const libraryId = this.currentWorkspace.libraries[0].id;
      if (!libraryFiles || this.isDownloadingArchive) {
        return;
      }
      if (libraryFiles.length === 1) {
        this.$apollo.addSmartQuery('getDownloadFileLink', {
          query: GET_DOWNLOAD_FILE_LINK,
          manual: true,
          variables: {
            libraryId,
            libraryFileId: libraryFiles[0]
          },
          result(result) {
            const { url } = get(result, 'data.getDownloadFileLink', undefined);
            if (!url) return;
            this.closeDownloadManager();
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'track.mp3';
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
          },
          error() {
            this.$store.commit('setDownloadArchiveStatus', 'error');
          }
        });
      } else {
        this.$store.commit('setIsDownloadingArchive', true);
        this.$apollo
          .mutate({
            mutation: ARCHIVE_LIBRARY_TRACKS,
            manual: true,
            variables: {
              libraryId,
              tracksIds: libraryFiles,
              isOriginal: true
            }
          })
          .then(response => {
            const share = response.data.ArchiveLibraryTracks;
            const { url, status } = share;
            if (url && url.url) {
              this.closeDownloadManager();
              const anchor = document.createElement('a');
              anchor.href = url.url;
              document.body.appendChild(anchor);
              anchor.click();
              document.body.removeChild(anchor);
            }
            this.$store.commit('setDownloadArchiveStatus', status);
          })
          .catch(() => {
            this.$store.commit('setDownloadArchiveStatus', 'error');
          });
      }
    },
    handleAddTracksToProjectMutation(params) {
      if (this.$isWorkspaceLockedVerification(this)) return;
      this.$apollo
        .mutate({
          mutation: ADD_TRACK_TO_PROJECT,
          variables: {
            workspaceId: this.currentWorkspace.id,
            projectId: params.projectId,
            libraryFilesIds: params.libraryFilesIds
          },
          refetchQueries: [
            {
              query: PROJECT_DETAILS_INFO,
              variables: {
                projectId: params.projectId
              }
            },
            {
              query: PROJECT_LIST,
              variables: {
                workspaceId: this.currentWorkspace.id,
                status: 'active',
                page: 1,
                limit: PAGINATOR_LIMIT
              }
            }
          ]
        })
        .then(() => {
          this.$apollo
            .mutate({
              mutation: PROJECT_DETAILS_TRACKS,
              variables: {
                projectId: params.projectId
              }
            })
            .then(res => {
              if (params.noAlert) {
                return;
              }
              const projectName = get(res, 'data.ProjectDetailsTracks.name', 'project');
              const num = params.libraryFilesIds.length;
              bus.$emit('addToProjectEnded');
              bus.$emit('showAlert', {
                message: { key: 'addToProjectSuccess', params: num, args: { num, projectName } },
                actionLabel: 'Go to project',
                handleAction: () => {
                  this.$router.push(getRoute('project', { id: params.projectId }));
                },
                style: 'success',
                delay: 5000
              });
            })
            .catch(error => {
              const key = getGraphQlErrorCode(error);
              bus.$emit('showAlert', {
                message: { key },
                style: 'danger',
                delay: 5000,
                error: true
              });
            });
          bus.$emit('uncheckAllTracks');
        })
        .catch(error => {
          bus.$emit('addToProjectEnded');
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    closeCreateProjectModal() {
      this.keepProjectDropdownExpand = false;
      bus.$emit('displayModal', { ...this.createProjectModal, isVisible: false });
    },
    isMainScrollingSetter(e) {
      this.isMainScrolling = e.target.scrollTop > 24;
    },
    hideEmailBanner(saveChoice) {
      if (saveChoice) {
        localStorage.setItem(KEY_HIDE_EMAIL_BANNER, true);
      }
      this.isHideEmailBanner = true;
    },
    hideAutoTagBanner() {
      localStorage.setItem(KEY_HIDE_AUTO_TAG_INFO_BANNER, true);
      this.isHideAutoTagBanner = true;
    },
    hideAIUploadBanner() {
      this.isHideAiUploadBanner = true;
    },
    hideEmailBrowser(saveChoice) {
      if (saveChoice) {
        const fifteenDay = 1296000000;
        const now = new Date();
        const item = {
          value: true,
          expire: now.getTime() + fifteenDay
        };
        localStorage.setItem(KEY_HIDE_BROWSER_BANNER, JSON.stringify(item));
      }
      this.isHideBrowserBanner = true;
    },
    openAddMembersModal() {
      displayAddMembersModal(this, this.myRoleId);
      this.redirectToMembersSettings();
    },
    getProgressBarColor(value) {
      if (value < 75) {
        return COLORS.green;
      }
      return COLORS.orange;
    },
    redirectToMembersSettings() {
      this.$router.push(getRoute('membersSettings'));
    },
    handleAlbumSort(option) {
      this.$store.commit('changeAlbumSort', option.filter);
      this.albumSort = option;
    },
    fetchInboxCounter() {
      this.$apollo.queries.InboxList.refetch().then(res => {
        this.unreadSubmissions = get(res, 'data.InboxList[0].counters.submissions_unread', null);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  &-container {
    width: 100%;
    height: 100%;
  }
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 180px 1fr;
  // grid-template-areas & grid-template-rows are defined with gridStyle computed

  &-dropzone {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: map-get($z-index, dropzone);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-content {
      position: absolute;
      z-index: 11;
      display: flex;
      align-items: center;

      &-icon {
        width: 80px;
        height: 80px;
        background-color: $color_neutral_0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 12px 0 0;

        &-upload {
          color: $color_neutral_100;
          font-size: 24px;
        }
      }

      &-helper {
        @include subtitle-1;
        line-height: 20px;
        margin: 0 0 0 12px;

        &-title {
          color: $color_neutral_0;
        }

        &-desc {
          color: $color_neutral_40;
        }
      }
    }

    &-background {
      width: 100%;
      height: 100vh;
      @include ui-gradient-dark;
    }
  }
  &-banner {
    grid-area: infoBanner;
  }
  &-header {
    z-index: map-get($z-index, header);
    grid-area: header;
    width: 100%;
    background-color: $color_neutral_0;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    &-content {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 8px 24px 0 42px;
      width: 100%;

      &-search {
        width: 100%;
        position: relative;
        z-index: 7;
      }

      &-icons {
        display: flex;
        & > * {
          margin-left: 20px;
        }
      }
    }
  }
  &-sub-header {
    grid-area: subHeader;
    width: 100%;
    padding: 16px 40px 0 40px;
    box-sizing: border-box;
    z-index: map-get($z-index, sub-header);
    .contextual-actions {
      display: flex;
      margin-bottom: 8px;
      .mr-2 {
        margin-right: 8px;
      }
    }
    .albums-sort {
      display: flex;
      justify-content: end;
      margin-bottom: 8px;
    }
  }
  &-nav {
    z-index: map-get($z-index, nav);
    grid-area: nav;
    border-right: 1px solid $color_neutral_30;
    &-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      background-color: $color_neutral_5;
      &-workspace {
        display: flex;
        align-items: center;
        height: 56px;
        box-sizing: border-box;
        border-bottom: 1px solid $color_neutral_30;
        flex-shrink: 0;
      }
      &-import {
        padding: 12px 16px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        position: relative;
        &-separator {
          height: 1px;
          width: calc(100% - 32px);
          background-color: $color_neutral_40;
          position: absolute;
          bottom: 0;
        }
      }
      &-scroll {
        height: calc(100vh - 372px);
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        position: relative;
      }
      &-alert {
        width: 100%;
        max-width: 164px;
        box-sizing: border-box;
        border-radius: 8px;
        border: 1px solid $color_danger_100;
        background: $color_danger_10;
        margin: 24px auto;
        padding: 16px;
        color: $color_danger_110;

        &-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        &-icon-container {
          width: 24px;
          height: 24px;
          margin-bottom: 22px;
          background: rgba(255, 69, 51, 0.1);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &-icon {
          color: $color_danger_100;
        }
        &-title {
          font-size: 14px;
          line-height: 120%;
          font-weight: 600;
          text-align: center;
          margin-bottom: 8px;
        }
        &-text {
          font-size: 12px;
          line-height: 150%;
          text-align: center;
        }
        &-link {
          color: $color_danger_110;
          font-weight: 600;
          text-decoration: underline;
          font-size: 12px;
          &:hover {
            cursor: pointer;
          }
        }
      }
      &-banner-freetrial {
        width: 100%;
        max-width: 164px;
        box-sizing: border-box;
        border-radius: 8px;
        border: 1px solid $color_primary_20;
        background: #efecf9;
        margin: 24px auto;
        padding: 16px;
        color: $color_primary_100;

        &-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        &-title {
          font-size: 14px;
          line-height: 120%;
          font-weight: 600;
          margin-bottom: 8px;
        }
        &-text {
          font-size: 12px;
          line-height: 150%;
        }
        &-button {
          background: $color_primary_100 !important;
          &:hover {
            background: #b38aff !important;
          }
          &:active {
            box-shadow: 0px 0px 0px 1px $color_neutral_0, 0px 0px 0px 3px $color_neutral_40;
          }
        }
      }
      &-bottom {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        padding: 16px;
        border-top: 1px solid $color_neutral_30;
        &-storage {
          &-progress {
            margin-bottom: 16px;
            .progress-bar {
              margin-bottom: 4px;
            }
            .tracks-count {
              font-size: 12px;
              color: $color_neutral_60;
            }
          }
          &-title {
            @include subtitle-1;
            display: flex;
            align-items: flex-end;
            position: relative;
            .ri-server-line {
              font-weight: normal;
              font-size: 16px;
              margin-right: 4px;
            }
            .ri-error-warning-fill {
              font-weight: normal;
              font-size: 16px;
              color: $color_warning_100;
              position: absolute;
              right: 6px;
            }
            margin-bottom: 8px;
          }
        }
        &-members {
          margin-bottom: 16px;
          cursor: pointer;
          &-title {
            display: flex;
            align-items: flex-end;
            @include subtitle-1;
            margin-bottom: 8px;
            a:hover {
              text-decoration: none;
            }
            &-plus-link {
              height: 14px;
            }
            .ri-parent-line {
              font-size: 16px;
              font-weight: normal;
              margin-right: 4px;
            }
            .ri-add-box-line {
              font-size: 16px;
              font-weight: normal;
              color: $color_neutral_40;
              margin-left: 4px;
              cursor: pointer;
              &:hover {
                color: $color_primary_100;
              }
            }
          }
          &-seats {
            @include body-2;
            color: $color_neutral_60;
            font-variant-numeric: tabular-nums;
          }
        }
      }
    }
  }

  &-view {
    grid-area: view;
    overflow-y: auto;
    &-wrapper {
      margin-right: 40px;
    }
  }

  &-manager {
    position: absolute;
    bottom: 80px;
    right: 40px;
    z-index: map-get($z-index, manager);
    display: flex;
    align-items: flex-end;
    gap: 20px;
    flex-direction: column;

    &-hidden {
      position: absolute;
      bottom: 16px;
      right: 40px;
      z-index: 8;
    }

    &-edit-panel {
      position: absolute;
      right: 540px;
    }
  }

  &-player {
    @include shadow-up-01;
    grid-area: player;
    z-index: map-get($z-index, player);
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &-player.isSettings {
    z-index: map-get($z-index, settings);
  }
}
.main.isSettings {
  overflow: hidden;
}

.loader {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subHeader-separator {
  @include shadow-down-02;
}

.upload-to-library-btn {
  width: 100%;
}
</style>

<style lang="scss">
.upload-to-library-btn.button.primary.medium.exception-bis {
  padding: 0 12px;
}
.alert-link-tooltip.tooltip,
.warning-tooltip {
  max-width: 140px;
  .tooltip-inner {
    text-align: center;
  }
}
.main-nav-wrapper-bottom {
  .inviteBtn,
  .upgradeBtn {
    width: 100%;
  }
}

.upload-to-library-btn {
  .button.primary.medium.exception-bis {
    background-color: $color_primary_100;
    &:hover {
      background-color: $color_primary_90;
    }
  }
}
.contextual-actions .action.tertiary.small {
  font-size: 14px;
  color: $color_neutral_60;
}
.contextual-actions .action.tertiary.small .prefix i {
  color: $color_neutral_80;
}
.contextual-actions.ca-project .action.tertiary.small .prefix i {
  color: $color_yellow_100;
}
</style>
