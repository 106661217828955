
<div class="file-panel-body">
  <div class="file-panel-body-title">{{ $t('fileInformation') }}</div>
  <div class="file-panel-body-section">
    <div class="file-panel-body-section-- title">{{ $t('duration') }}</div>
    <div class="file-panel-body-section--">{{ duration }}</div>
  </div>
  <div class="file-panel-body-section">
    <div class="file-panel-body-section-- title">{{ $t('type') }}</div>
    <div class="file-panel-body-section--">{{ type }}</div>
  </div>
  <div class="file-panel-body-section">
    <div class="file-panel-body-section-- title">{{ $t('size') }}</div>
    <div class="file-panel-body-section--">{{ size }}</div>
  </div>
  <div class="file-panel-body-section">
    <div class="file-panel-body-section-- title">{{ $t('bitrate') }}</div>
    <div class="file-panel-body-section--">{{ bitRate }}</div>
  </div>
  <div class="file-panel-body-section">
    <div class="file-panel-body-section-- title">{{ $t('lastUpdated') }}</div>
    <div class="file-panel-body-section--">{{ lastUpdate }}</div>
  </div>
  <div class="file-panel-body-section">
    <div class="file-panel-body-section-- title">{{ $t('addedDate') }}</div>
    <div class="file-panel-body-section--">{{ addedDate }}</div>
  </div>
  <div class="file-panel-body-section">
    <div class="file-panel-body-section-- title">{{ $t('addedBy') }}</div>
    <div class="file-panel-body-section--">
      <div class="file-panel-body-section--avatar" v-if="isSameUploader">
        <div v-if="userName && userName !== ''" style="display: flex">
          <avatar :initial="initial" :pictureUrl="pictureUrl" pictureSize="workspaceSmall" />
          <div class="file-panel-body-section--avatar-username">{{ userName }}</div>
        </div>
        <div v-else>-</div>
      </div>
      <div v-else>{{ MULTIPLE_LABEL }}</div>
    </div>
  </div>
  <div class="file-panel-body-section" v-if="!isMultipleTrackEdition">
    <div class="file-panel-body-section-- file title">{{ $t('file') }}</div>
    <div v-if="!replacingPending" class="file-panel-body-section-- original-name">
      {{ originalName }}
    </div>
    <div v-else class="file-panel-body-section-- original-name uploading">
      {{ `${$t('uploadingDot')} ${uploadPercent}%` }}
    </div>
  </div>
  <div class="file-panel-body-button">
    <submit-button
      ref="uploadFileInput"
      btnStyle="secondary"
      size="small"
      :label="$t('replaceAudioFile')"
      iconClass="ri-repeat-line"
      browse
      :pending="replacingPending"
      cancelable
      :accept="allowedFileTypes"
      :disabled="!allowedFileTypes || allowedFileTypes === ''"
      @submit="checkFilesAndSpaceBeforeUpload"
      @cancel="cancelReplace"
    />
  </div>
</div>
