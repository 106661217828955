<template>
  <div class="artist-tracks">
    <project-list>
      <template v-slot:default="slotProps">
        <tracks
          v-if="trackList && trackList.length"
          :trackList="trackList"
          :projectList="slotProps.projectList"
          :noProjectSearchResult="slotProps.noProjectSearchResult"
          :showProjectObserver="slotProps.showProjectObserver"
          :loadingProjects="slotProps.loadingProjects"
          :fetchingMoreProjects="slotProps.fetchingMoreProjects"
          :keepProjectDropdownExpand="keepProjectDropdownExpand"
          :uncheckTracks="uncheckTracks"
          :editMode="editMode"
          withArtwork
          hasSelectAll
          @openConfirmDeleteModal="openConfirmDeleteModal"
          @createProject="createProject"
          @searchProject="slotProps.searchProject"
          @fetchMoreProjects="slotProps.fetchMoreProjects"
          @listProjects="slotProps.listProjects"
          @toggleEditMode="toggleEditMode"
        />
        <div v-else class="loader">
          <spinner-without-progress color="grey" :size="32" />
        </div>
      </template>
    </project-list>
  </div>
</template>

<script>
import ProjectList from '../../../../../containers/projectList';
import Tracks from '../../../../../containers/libraryTrackList';

export default {
  components: {
    ProjectList,
    Tracks
  },
  props: {
    keepProjectDropdownExpand: {
      type: Boolean,
      required: false,
      default: false
    },
    uncheckTracks: {
      type: Boolean,
      required: false,
      default: false
    },
    trackList: {
      type: Array,
      required: false,
      default: () => []
    },
    artistFileIds: {
      type: Array,
      required: false,
      default: () => []
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    openConfirmDeleteModal(libraryFileIds) {
      this.$emit('openConfirmDeleteModal', libraryFileIds);
    },
    createProject() {
      this.$emit('createProject');
    },
    toggleEditMode(libraryFileIds, isChecked) {
      this.$emit('toggleEditMode', libraryFileIds, isChecked);
    }
  }
};
</script>

<style lang="scss" scoped>
.artist-tracks {
  margin: 40px 0 0 12px;
}
.loader {
  display: flex;
  justify-content: center;
}
</style>
