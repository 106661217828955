
<div class="usage-panel-body">
  <div class="usage-panel-body-title">{{ $t('sharedOn') }}</div>
  <div v-if="isMultipleTrackEdition" class="usage-panel-body-multiple-information">
    <div class="usage-panel-body-multiple-information-icon">
      <i class="ri-information-fill" />
    </div>
    <div class="usage-panel-body-multiple-information-label">
      All selected tracks must meet minimum requirements to be shared on hubs. Make sure they all
      have an <span class="bold">artist, album, and artwork</span> saved.
    </div>
  </div>
  <div v-if="!isMultipleTrackEdition && !isTrackCompliant" class="usage-panel-body-compliancy">
    <div class="title">
      <div class="title-container">
        <i class="ri-file-list-2-line" />
        Make your track compliant with hub requirements
      </div>
      <span>{{ trackCompliancyProgress.current }} of {{ trackCompliancyProgress.total }}</span>
    </div>
    <div
      v-for="(criteria, index) in Object.keys(trackCompliancyCriteria)"
      :key="index"
      class="criterias"
    >
      <i
        class="ri-checkbox-circle-fill criterias-icon"
        v-if="trackCompliancyCriteria[criteria]"
      />
      <i v-else class="ri-checkbox-blank-circle-line criterias-icon" />
      {{ criteria }}
    </div>
  </div>
  <div v-for="(hub, index) in hubs" :key="index" class="usage-panel-body-hub">
    <div class="usage-panel-body-hub-container">
      <div class="usage-panel-body-hub-name-container">
        <avatar :initial="hub.name.charAt(0).toUpperCase()" :pictureUrl="hub.logo" square />
        <span class="usage-panel-body-hub-name">{{ hub.name }}</span>
      </div>
      <div class="usage-panel-body-hub-dropdown">
        <select-box
          ref="selectBoxRef"
          :options="options"
          large
          :selectedValue="{ label: choice[hub.id] }"
          @setSelectBoxValue="toggleTrackToHub($event, hub.id)"
          :placeholder="placeholder"
          :isMultipleSelection="isMultipleTrackEdition"
          :isFullDisabled="!isTrackCompliant"
        />
      </div>
    </div>
  </div>
</div>
