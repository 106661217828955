
<div>
  <infinite-scroll
    :listQuery="searchQuery"
    @handleQueryResult="handleQueryResult"
    @setLoading="setLoading"
  >
    <template v-slot:list>
      <project-list v-if="trackList">
        <template v-slot:default="slotProps">
          <div class="tracks-list">
            <div
              class="tracks-list-content"
              :style="editMode && 'width: calc(100% - 518px + 38px);'"
            >
              <tracks
                v-if="trackList.length > 0"
                :trackList="trackList"
                :keepProjectDropdownExpand="keepProjectDropdownExpand"
                :isFetchingMore="loading"
                :uncheckTracks="uncheckTracks"
                :projectList="slotProps.projectList"
                :noProjectSearchResult="slotProps.noProjectSearchResult"
                :showProjectObserver="slotProps.showProjectObserver"
                :loadingProjects="slotProps.loadingProjects"
                :fetchingMoreProjects="slotProps.fetchingMoreProjects"
                :editMode="editMode"
                withArtwork
                isSticky
                sortColumn
                hasSelectAll
                @openConfirmDeleteModal="openConfirmDeleteModal"
                @createProject="createProject"
                @searchProject="slotProps.searchProject"
                @fetchMoreProjects="slotProps.fetchMoreProjects"
                @listProjects="slotProps.listProjects"
                @sortTracks="sortTracks"
                @toggleEditMode="toggleEditMode"
              />
              <div v-else class="tracks-list-content-empty">
                {{ $t('noResult') }}
              </div>
            </div>
          </div>
        </template>
      </project-list>
    </template>
  </infinite-scroll>
</div>
