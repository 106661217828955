
<div class="project-dropdown" v-click-outside="{ hide, keepExpand }">
  <submit-button
    v-if="submitButton"
    :size="submitButton && submitButton.size ? submitButton.size : 'small'"
    :btnStyle="submitButton && submitButton.style ? submitButton.style : 'primary'"
    :label="submitButton && submitButton.label"
    :iconClass="submitButton && submitButton.icon"
    @submit="toggleExpand"
  />
  <icon-button
    v-if="iconButton"
    :icon="iconButton"
    :isHover="isExpanded"
    btnStyle="tertiary"
    size="small"
    @submit="toggleExpand"
  />
  <div class="project-dropdown-expand" v-show="isExpanded" v-dropdown-orientation="direction">
    <div class="project-dropdown-expand-search">
      <i class="ri-search-line" />
      <input
        ref="search"
        class="project-dropdown-expand-search-input"
        type="text"
        :placeholder="searchPlaceholder"
        @input="$emit('updateSearchValue', $event.target.value)"
      />
    </div>
    <div v-if="loadingProjects" class="project-dropdown-expand-options">
      <div class="project-dropdown-expand-options-spinner">
        <spinner color="grey" />
      </div>
    </div>
    <div v-if="dropdownOptions.length > 0" class="project-dropdown-expand-options">
      <div v-for="option in dropdownOptions" :key="option.id">
        <div
          class="project-dropdown-expand-options--"
          :class="selectedProjectId === option.id && 'project-dropdown-expand-options-active'"
          @click.stop="handleClickOption(option.id)"
          @dblclick.stop="handleClickAdd"
        >
          <i
            class="ri-folder-fill project-dropdown-expand-options-icon"
            :class="
              selectedProjectId === option.id && 'project-dropdown-expand-options-active-icon'
            "
          />
          <div class="project-dropdown-expand-options-label">{{ option.label }}</div>
        </div>
      </div>
      <div v-if="fetchingMoreProjects" class="project-dropdown-expand-options-skeleton">
        <skeleton />
      </div>
      <observer v-if="showObserver" :height="32" bottom @intersect="loadMore" />
    </div>
    <div
      v-if="dropdownOptions.length === 0 && !noSearchResult && !loadingProjects"
      class="project-dropdown-expand-options"
    >
      <div class="project-dropdown-expand-options-placeholder">
        <div class="project-dropdown-expand-options-placeholder-text">{{ placeholder.text }}</div>
        <submit-button
          size="small"
          :label="placeholder.buttonLabel"
          :iconClass="placeholder.buttonIcon"
          @submit="handleClickCreate"
        />
      </div>
    </div>
    <div
      v-if="dropdownOptions.length === 0 && noSearchResult && !loadingProjects"
      class="project-dropdown-expand-no-result"
    >
      {{ noResultLabel }}
    </div>
    <div class="project-dropdown-expand-footer">
      <div class="project-dropdown-expand-footer-left">
        <submit-button
          btnStyle="secondary"
          size="small"
          :iconClass="createButtonIcon"
          :label="$t('create')"
          @submit="handleClickCreate"
        />
      </div>
      <div class="project-dropdown-expand-footer-right">
        <div class="project-dropdown-expand-footer-right-btn">
          <submit-button
            btnStyle="secondary"
            size="small"
            :label="cancelButtonLabel"
            @submit="hide"
          />
        </div>
        <submit-button
          size="small"
          :disabled="!selectedProjectId"
          :label="addButtonLabel"
          @click.native.stop="handleClickAdd"
        />
      </div>
    </div>
  </div>
</div>
