<template>
  <div class="upgrade-plan-modal">
    <div class="upgrade-plan-modal-body">
      <div class="upgrade-plan-modal-body-title">
        {{ $t('modalUpgrade.titleFirstPart', { workspaceName: currentWorkspaceName }) }}
        <span class="upgrade-plan-modal-body-title-bold">{{ currentWorkspaceName }}</span
        >?
        {{ $t('modalUpgrade.titleSecondPart') }}
      </div>
      <div class="upgrade-plan-modal-body-args">
        {{ $t('storageArgs') }}
        <span class="upgrade-plan-modal-body-args-right">
          <span class="upgrade-plan-modal-body-args-right-current" v-if="!displayNextInvoiceDate">
            {{ currentSubscriptionTracks }} tracks / {{ currentSubscriptionStorage }} GB
          </span>
          <i class="ri-arrow-right-line" v-if="!displayNextInvoiceDate"></i>
          {{ upgradedPlanTracks }} tracks / {{ upgradedPlanStorage }}
        </span>
      </div>
      <div class="upgrade-plan-modal-body-args">
        {{ $t('members') }}
        <span class="upgrade-plan-modal-body-args-right">{{
          $t('modalUpgrade.extraMemberCost', { currency: currentSubscriptionCurrency })
        }}</span>
      </div>
      <div v-if="displayAnnualBill">
        <div class="upgrade-plan-modal-body-args">
          {{ $t('billingPeriod') }}
          <span class="upgrade-plan-modal-body-args-right">
            <span class="upgrade-plan-modal-body-args-right-current" v-if="hasPeriodChanged()">{{
              currentBillingSubscriptionFrequency
            }}</span>
            <i class="ri-arrow-right-line" v-if="hasPeriodChanged()"></i>
            <span class="upgrade-plan-modal-body-args-right">{{
              newBillingSubscriptionFrequency
            }}</span>
          </span>
        </div>
        <div class="upgrade-plan-modal-body-args">
          <div v-if="isYearly">
            <span>
              {{ $t('annualBill') }}
            </span>
            <span class="upgrade-plan-modal-body-args-right">
              <span class="upgrade-plan-modal-body-args-right-current">
                {{ getCurrentSubscriptionPrice() }}
              </span>
              <i class="ri-arrow-right-line"></i>
              {{ upgradedTotalPrice }}{{ currentSubscriptionCurrency }}
            </span>
          </div>
          <div v-else>
            <span>
              {{ $t('monthlyBill') }}
            </span>
            <span class="upgrade-plan-modal-body-args-right">
              <span class="upgrade-plan-modal-body-args-right-current">
                {{ getCurrentSubscriptionPrice() }}
              </span>
              <i class="ri-arrow-right-line"></i>
              {{ upgradedTotalPrice }}{{ currentSubscriptionCurrency }}
            </span>
          </div>
        </div>
      </div>
      <div class="upgrade-plan-modal-body-args" v-if="displayNextInvoiceDate">
        Next invoice date
        <span class="upgrade-plan-modal-body-args-right"> {{ subscriptionEndDate() }} </span>
      </div>
      <div class="upgrade-plan-modal-body-args-last">
        {{ getInvoiceLabel() }}
        <span class="upgrade-plan-modal-body-args-last-right"
          >{{ getInvoiceTotal() }}{{ currentSubscriptionCurrency }}
          <span v-if="displayFreeTrialFirstBillDate">&nbsp;on {{ getFreeTrialEndDate() }}</span>
        </span>
      </div>
      <div class="upgrade-plan-modal-body-link">
        {{ $t('needMoreInfo')
        }}<a href="mailto:hi@bridge.audio" class="upgrade-plan-modal-body-link--">{{
          $t('contactUs')
        }}</a>
      </div>
      <div class="upgrade-plan-modal-body-buttons">
        <div class="upgrade-plan-modal-body-buttons-first">
          <submit-button
            btnStyle="tertiary"
            size="medium"
            :label="$t('modalUpgrade.cancelButton')"
            @submit="closeModal"
          />
        </div>
        <submit-button
          btnStyle="primary"
          size="medium"
          :label="$t('modalUpgrade.confirmButton')"
          @submit="upgradePlan"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { appConfig } from '@/utils/constants';
import get from 'lodash.get';
import { format } from 'date-fns';

import { formatPrice } from '@/utils/functions/user';
import {
  isFreeTrial,
  getFreeTrialEndTimestamp,
  getSubscriptionEndDate,
  isSubscriptionCanceled
} from '@/utils/functions/workspace';

export default {
  props: {
    stripeInfos: {
      type: Object,
      required: true
    },
    upgradedPlanPrice: {
      type: Number,
      required: true
    },
    upgradedPlanTracks: {
      type: String,
      required: true
    },
    upgradedPlanStorage: {
      type: String,
      required: true
    },
    isYearly: {
      type: Boolean,
      required: true
    },
    reactivatePlan: {
      type: Boolean,
      required: false,
      default: false
    },
    onSubmit: {
      type: Function,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      upgradedTotalPrice: this.isYearly ? this.upgradedPlanPrice * 12 : this.upgradedPlanPrice,
      currentTotalPrice: undefined
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    currentWorkspaceName() {
      return this.currentWorkspace.name;
    },
    currentSubscriptionCurrency() {
      return this.currentWorkspace.subscription.payment_information.currency ===
        appConfig.currencies.EURO
        ? '€'
        : '$';
    },
    currentSubscriptionStorage() {
      return this.currentWorkspace.subscription.storage / 1000;
    },
    currentSubscriptionTracks() {
      return this.currentWorkspace.subscription.track_limit;
    },
    currentBillingSubscriptionFrequency() {
      return this.currentWorkspace.subscription.payment_information.period === 'yearly'
        ? this.$t('Annually')
        : this.$t('Monthly');
    },
    newBillingSubscriptionFrequency() {
      return this.isYearly ? this.$t('Annually') : this.$t('Monthly');
    },
    displayFreeTrialFirstBillDate() {
      return isFreeTrial(this.currentWorkspace);
    },
    displayAnnualBill() {
      return isSubscriptionCanceled(this.currentWorkspace) === false;
    },
    displayNextInvoiceDate() {
      return isSubscriptionCanceled(this.currentWorkspace);
    }
  },
  methods: {
    subscriptionEndDate() {
      return getSubscriptionEndDate(this.currentWorkspace);
    },
    hasPeriodChanged() {
      const newPeriod = this.isYearly ? 'yearly' : 'monthly';
      return newPeriod !== this.currentWorkspace.subscription.payment_information.period;
    },
    getFreeTrialEndDate() {
      const trialEndDate = getFreeTrialEndTimestamp(this.currentWorkspace);
      return format(new Date(trialEndDate), 'LLLL d, y');
    },
    getInvoiceLabel() {
      if (isFreeTrial(this.currentWorkspace) || isSubscriptionCanceled(this.currentWorkspace)) {
        return this.$t('nextInvoiceTotal');
      }
      return this.$t('dueToday');
    },
    upgradePlan() {
      this.onSubmit();
      this.$emit('closeModal');
    },
    closeModal() {
      this.$emit('closeModal');
    },
    getCurrentSubscriptionPrice() {
      const currentPeriod = get(this.currentWorkspace, 'subscription.billing_period');
      const currentPlan = get(this.currentWorkspace, 'subscription.plan');
      const invoicePrice =
        currentPeriod === 'yearly' ? currentPlan.annually_price : currentPlan.monthly_price;
      this.currentTotalPrice = invoicePrice / 100;
      const currency = get(this.currentWorkspace, 'subscription.payment_information.currency');
      return formatPrice(invoicePrice, currency);
    },
    getInvoiceTotal() {
      if (isFreeTrial(this.currentWorkspace)) {
        return this.upgradedTotalPrice;
      }
      return this.stripeInfos.due_today_amount / 100;
    }
  }
};
</script>
<style lang="scss" scoped>
.upgrade-plan-modal {
  &-body {
    padding: 24px 24px 0 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;

    &-title {
      margin-bottom: 21px;
      &-bold {
        font-weight: 600;
      }
    }

    &-args {
      padding: 16px 0;
      border-bottom: 1px solid $color_neutral_30;
      &-right {
        float: right;
        color: $color_neutral_100;
        font-weight: 600;
        &-current {
          color: $color_neutral_60;
          text-decoration-line: line-through;
          font-weight: 400;
        }
      }
      &-last {
        padding-top: 16px;
        padding-bottom: 0;
        border: none;
        &-right {
          float: right;
          color: $color_neutral_100;
          font-weight: 600;
        }
      }
    }
    &-link {
      margin-top: 37px;
      margin-bottom: 25px;
      &-- {
        margin-left: 2px;
      }
    }
    &-buttons {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid $color_neutral_30;
      padding: 8px 0;
      &-first {
        margin-right: 8px;
      }
    }
  }
}

.upgrade-plan-modal-body-args:last-child {
  border: none;
}
.ri-arrow-right-line {
  vertical-align: middle;
  margin: 3px 0;
}
</style>
