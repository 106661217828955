
<div v-if="me" class="account">
  <div v-if="blob" class="account-cropper">
    <image-cropper
      :blob="blob"
      :submitButtonLabel="$t('save')"
      :cancelButtonLabel="$t('cancel')"
      :imageWidth="cropperMaxWidth"
      :imageHeight="cropperMaxHeight"
      @cropImage="cropImage"
    />
  </div>
  <div v-if="!blob" class="account-form">
    <div class="account-form-section">
      <div class="account-form-section-title">{{ $t('accountInformation') }}</div>
      <div class="account-form-section-description">{{ $t('accountDescription') }}</div>
      <div class="account-form-section-information">
        <image-browser :imgUrl="imgUrl" @changeImgUrl="changeImgUrl" pictureSize="large" />
        <div class="account-form-section-name">
          <div class="account-form-section-information-textfield">
            <text-field
              :key="`firstname-${randomKey}`"
              inputType="text"
              :errorMessage="firstnameValidationMessage"
              :fieldModel.sync="$v.firstname.$model"
              :value="me.firstname"
              placeholder
              :label="$t('firstname')"
              large
              @handleInput="handleUserDisableStatus"
            />
          </div>
          <div class="account-form-section-information-textfield">
            <text-field
              :key="`lastname-${randomKey}`"
              inputType="text"
              :errorMessage="lastnameValidationMessage"
              :fieldModel.sync="$v.lastname.$model"
              :value="me.lastname"
              placeholder
              :label="$t('lastname')"
              large
              @handleInput="handleUserDisableStatus"
            />
          </div>
        </div>
      </div>
      <div class="account-form-section-button">
        <submit-button
          :label="$t('save')"
          :disabled="userDisableStatus"
          :pending="userSubmitStatus === 'PENDING'"
          @submit="updateUser"
        />
      </div>
    </div>
    <div class="account-form-section">
      <h2 class="account-form-section-title">{{ $t('emailAddress') }}</h2>
      <div v-if="UserEmails && UserEmails.length > 0">
        <div class="account-form-section-description">{{ $t('emailAddressDescription') }}</div>
      </div>
      <div class="account-form-section-description" v-else>No primary email to edit !</div>
      <div class="account-form-section-column">
        <div v-if="UserEmails && UserEmails.length > 0">
          <h3 class="account-form-section-column-title">{{ $t('primaryEmail') }}</h3>
          <div
            :class="{
              'account-form-section-column-text': true,
              'account-form-section-column-email-pending': !primaryEmail.is_verified
            }"
          >
            <div v-if="!displayChangePrimaryEmail" class="primary-email">
              {{ primaryEmail.email }}
              <span v-if="!primaryEmail.is_verified">
                {{ $t('pending') }}&nbsp;-&nbsp;
                <span
                  class="account-form-section-column-text-link"
                  @click="resendConfirmationEmail(primaryEmail)"
                >
                  {{ $t('resendVerificationEmail') }}
                </span>
                )
              </span>
              <span class="btn-edit">
                <icon-button
                  icon="ri-edit-line"
                  size="small"
                  btnStyle="secondary"
                  @submit="togglePrimaryEmailEdit(true)"
                  v-tooltip="$t('edit')"
                />
              </span>
            </div>
            <div v-else class="primary-email-change-form">
              <change-primary-email
                :primaryMail="primaryEmail"
                @togglePrimaryEmailEdit="togglePrimaryEmailEdit"
              />
            </div>
          </div>
          <h3 class="account-form-section-column-title">{{ $t('secondaryEmail') }}</h3>
          <div
            :key="item.id"
            v-for="(item, index) in UserEmails.filter(i => !i.is_primary)"
            class="account-form-section-column-email"
          >
            <div
              v-if="index !== verifyDeleteIndex"
              :class="{
                'account-form-section-column-email-pending': !item.is_verified
              }"
            >
              <span>{{ item.email }}</span>
              <span v-if="!item.is_verified">
                ({{ $t('pending') }}&nbsp;-&nbsp;
                <span
                  class="account-form-section-column-text-link"
                  @click="resendConfirmationEmail(item)"
                >
                  {{ $t('resendVerificationEmail') }}
                </span>
                )
              </span>
              <span class="account-form-section-column-email-action">
                <icon-button
                  icon="ri-delete-bin-line"
                  size="small"
                  btnStyle="secondary"
                  @submit="verifyDeleteAddress(index)"
                  v-tooltip="$t('remove')"
                />
              </span>
            </div>
            <div v-else>
              <span>{{ $t('remove') }}&nbsp;</span>
              <span class="account-form-section-column-email-bold">{{ item.email }}</span>
              <span>&nbsp;?&nbsp;</span>
              <span
                class="account-form-section-column-email-action account-form-section-column-email-action-visible"
              >
                <submit-button
                  :label="$t('remove')"
                  @submit="deleteEmailAddress(item)"
                  btnStyle="secondary"
                  size="small"
                />
                <submit-button
                  @submit="resetVerifyDeleteIndex"
                  :label="$t('cancel')"
                  btnStyle="secondary"
                  size="small"
                />
              </span>
            </div>
          </div>
          <div v-if="displaySecondaryEmailForm" class="emailForm">
            <text-field
              inputType="email"
              :large="false"
              :placeholder="$t('enterEmailAddress')"
              :inputWidth="300"
              :fieldModel.sync="$v.newEmail.$model"
              :errorMessage="addEmailAddressError"
              class="emailForm-textfield"
            />
            <submit-button :label="$t('add')" @submit="addEmailAddress" />
            <submit-button
              :label="$t('cancel')"
              @submit="toggleAddEmailForm(false)"
              btnStyle="secondary"
            />
          </div>
          <div v-else>
            <div v-if="UserEmails.length <= 5">
              <submit-button
                iconClass="ri-add-line"
                :label="$t('addEmailAddress')"
                btnStyle="tertiary"
                @submit="toggleAddEmailForm(true)"
                :exception="true"
              />
            </div>
            <div v-else>{{ $t('maximumFiveSecondaryEmails') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="account-form-section">
      <div class="account-form-section-title">{{ $t('password') }}</div>
      <div class="account-form-section-description">{{ $t('changePassword') }}</div>
      <div class="account-form-section-column">
        <div class="account-form-section-column-textfield">
          <text-field
            :key="`password-${randomKey}`"
            withVisibility
            inputType="password"
            :errorMessage="null"
            :fieldModel.sync="$v.currentPassword.$model"
            :placeholder="$t('currentPasswordPlaceholder')"
            :label="$t('currentPassword')"
            large
            @handleInput="handlePasswordDisableStatus"
          />
        </div>
        <div class="account-form-section-column-textfield">
          <text-field
            :key="`newpassword-${randomKey}`"
            withVisibility
            withCheck
            inputType="password"
            :errorMessage="newPasswordMessageValidation"
            :fieldModel.sync="$v.newPassword.$model"
            :placeholder="$t('differentPassword')"
            :label="$t('newPassword')"
            :securityLabels="securityLabels"
            large
            @handleInput="handlePasswordDisableStatus"
          />
        </div>
        <div class="account-form-section-column-textfield">
          <text-field
            :key="`newpassword2-${randomKey}`"
            withVisibility
            inputType="password"
            :errorMessage="confirmNewPasswordMessageValidation"
            :fieldModel.sync="$v.confirmNewPassword.$model"
            :placeholder="$t('confirmNewPasswordPlaceholder')"
            :label="$t('confirmNewPassword')"
            large
            @handleInput="handlePasswordDisableStatus"
          />
        </div>
      </div>
      <submit-button
        :label="$t('save')"
        :disabled="passwordDisableStatus"
        :pending="passwordSubmitStatus === 'PENDING'"
        @submit="updateUserPassword"
      />
    </div>
  </div>
</div>
