import gql from 'graphql-tag';
import { membersFragment } from '../fragments';

export const CHANGE_MEMBER_ROLE = gql`
  mutation changeMemberRole($workspaceId: ID!, $memberUserId: String!, $roleId: Int!) {
    changeMemberRole(workspaceId: $workspaceId, memberUserId: $memberUserId, roleId: $roleId) {
      ...members
    }
  }
  ${membersFragment.members}
`;

export default CHANGE_MEMBER_ROLE;
