<template>
  <div class="delete-projects-modal">
    <div class="delete-projects-modal-body">
      <div class="delete-projects-modal-body-description">
        {{ $t('deleteProjectDesc', { name: projectName }) }}
      </div>
      <text-field
        inputType="text"
        :fieldModel.sync="typeDelete"
        :label="$t('typeDelete')"
        focusOnLoad
        @keyup.enter.native="handleKeyUp"
      />
    </div>
    <div class="delete-projects-modal-buttons">
      <div class="delete-projects-modal-buttons--">
        <submit-button
          btnStyle="tertiary"
          size="medium"
          :label="$t('cancel')"
          @submit="closeModal"
        />
      </div>
      <div class="delete-projects-modal-buttons--">
        <submit-button
          btnStyle="secondary"
          size="medium"
          :label="$t('delete')"
          :disabled="disabledDeleteButton"
          danger
          @submit="submit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators';
import { trackEvent } from '@/utils/functions/analytics';

export default {
  props: {
    onSubmit: {
      type: Function,
      required: false,
      default: () => {}
    },
    projectName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      typeDelete: null,
      delete: 'DELETE'
    };
  },
  validations: {
    typeDelete: {
      required,
      sameAsDelete: sameAs('delete')
    }
  },
  computed: {
    disabledDeleteButton() {
      return !this.$v.typeDelete.sameAsDelete;
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    handleKeyUp() {
      if (!this.disabledDeleteButton) {
        this.submit();
      }
    },
    submit() {
      trackEvent('Delete project');
      this.$emit('closeModal');
      this.onSubmit();
    }
  }
};
</script>

<style lang="scss" scoped>
.delete-projects-modal {
  &-body {
    padding: 32px 40px;
    line-height: 150%;

    &-title {
      font-size: 18px;
      line-height: 22px;
      padding: 0 0 24px 0;
      text-align: center;
      word-break: break-word;
    }

    &-description {
      margin: 0 0 32px 0;
    }

    &-txtfield {
      margin: 0 0 40px 0;
    }
  }
  &-buttons {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $color_neutral_30;
    padding: 8px 32px;

    &-- {
      &:first-child {
        margin: 0 16px 0 0;
      }
    }
  }
}
</style>
