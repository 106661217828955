import gql from 'graphql-tag';
import { subscriptionFragment } from '../fragments';

export const SUBSCRIBE_TO_TRIAL_PLAN = gql`
  mutation subscribeToTrialPlan($workspaceId: ID!, $planSlug: String!) {
    subscribeToTrialPlan(workspaceId: $workspaceId, planSlug: $planSlug) {
      ...subscription
    }
  }
  ${subscriptionFragment.subscription}
`;

export const STRIPE_CREATE_SUBSCRIPTION = gql`
  mutation StripeCreateSubscription(
    $workspaceId: ID!
    $planVariantId: ID!
    $billingPeriod: BillingPeriod!
    $billingCurrency: Currency!
    $seats: Int!
    $storage: Int!
    $coupon: String
    $enableFreeTrial: Boolean
  ) {
    StripeCreateSubscription(
      workspaceId: $workspaceId
      planVariantId: $planVariantId
      billingPeriod: $billingPeriod
      billingCurrency: $billingCurrency
      seats: $seats
      storage: $storage
      coupon: $coupon
      enableFreeTrial: $enableFreeTrial
    ) {
      subscription_id
      client_secret
      billing_period
      billing_currency
      price {
        total
        subtotal
        discount
      }
      status
    }
  }
`;

export const STRIPE_UPDATE_SUBSCRIPTION = gql`
  mutation StripeUpdateSubscription(
    $subscriptionId: ID!
    $planVariantId: ID!
    $billingPeriod: BillingPeriod!
    $billingCurrency: Currency!
    $seats: Int!
    $coupon: String
  ) {
    StripeUpdateSubscription(
      subscriptionId: $subscriptionId
      planVariantId: $planVariantId
      billingPeriod: $billingPeriod
      billingCurrency: $billingCurrency
      seats: $seats
      coupon: $coupon
    ) {
      subscription_id
      client_secret
    }
  }
`;
export const STRIPE_CANCEL_SUBSCRIPTION = gql`
  mutation StripeCancelSubscription(
    $subscriptionId: ID!
    $workspaceId: ID!
    $userPassword: String!
  ) {
    StripeCancelSubscription(
      subscriptionId: $subscriptionId
      workspaceId: $workspaceId
      userPassword: $userPassword
    )
  }
`;

export const STRIPE_CREATE_CUSTOMER = gql`
  mutation StripeCustomer($workspaceId: ID!, $billingInformation: BillingInformationInput) {
    StripeCustomer(workspaceId: $workspaceId, billingInformation: $billingInformation) {
      customer_external_id
    }
  }
`;

export const STRIPE_SET_DEFAULT_PAYMENT_METHOD = gql`
  mutation StripeSetDefaultPaymentMethodeForSubscription(
    $subscriptionId: ID!
    $paymentMethodId: String!
  ) {
    StripeSetDefaultPaymentMethodeForSubscription(
      subscriptionId: $subscriptionId
      paymentMethodId: $paymentMethodId
    ) {
      status
    }
  }
`;

export const STRIPE_REACTIVATE_SUBSCRIPTION = gql`
  mutation ReactivateSubscription($subscriptionId: ID!) {
    ReactivateSubscription(subscriptionId: $subscriptionId)
  }
`;
