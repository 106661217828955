<template>
  <div class="project-dropdown" v-click-outside="{ hide, keepExpand }">
    <submit-button
      v-if="submitButton"
      :size="submitButton && submitButton.size ? submitButton.size : 'small'"
      :btnStyle="submitButton && submitButton.style ? submitButton.style : 'primary'"
      :label="submitButton && submitButton.label"
      :iconClass="submitButton && submitButton.icon"
      @submit="toggleExpand"
    />
    <icon-button
      v-if="iconButton"
      :icon="iconButton"
      :isHover="isExpanded"
      btnStyle="tertiary"
      size="small"
      @submit="toggleExpand"
    />
    <div class="project-dropdown-expand" v-show="isExpanded" v-dropdown-orientation="direction">
      <div class="project-dropdown-expand-search">
        <i class="ri-search-line" />
        <input
          ref="search"
          class="project-dropdown-expand-search-input"
          type="text"
          :placeholder="searchPlaceholder"
          @input="$emit('updateSearchValue', $event.target.value)"
        />
      </div>
      <div v-if="loadingProjects" class="project-dropdown-expand-options">
        <div class="project-dropdown-expand-options-spinner">
          <spinner color="grey" />
        </div>
      </div>
      <div v-if="dropdownOptions.length > 0" class="project-dropdown-expand-options">
        <div v-for="option in dropdownOptions" :key="option.id">
          <div
            class="project-dropdown-expand-options--"
            :class="selectedProjectId === option.id && 'project-dropdown-expand-options-active'"
            @click.stop="handleClickOption(option.id)"
            @dblclick.stop="handleClickAdd"
          >
            <i
              class="ri-folder-fill project-dropdown-expand-options-icon"
              :class="
                selectedProjectId === option.id && 'project-dropdown-expand-options-active-icon'
              "
            />
            <div class="project-dropdown-expand-options-label">{{ option.label }}</div>
          </div>
        </div>
        <div v-if="fetchingMoreProjects" class="project-dropdown-expand-options-skeleton">
          <skeleton />
        </div>
        <observer v-if="showObserver" :height="32" bottom @intersect="loadMore" />
      </div>
      <div
        v-if="dropdownOptions.length === 0 && !noSearchResult && !loadingProjects"
        class="project-dropdown-expand-options"
      >
        <div class="project-dropdown-expand-options-placeholder">
          <div class="project-dropdown-expand-options-placeholder-text">{{ placeholder.text }}</div>
          <submit-button
            size="small"
            :label="placeholder.buttonLabel"
            :iconClass="placeholder.buttonIcon"
            @submit="handleClickCreate"
          />
        </div>
      </div>
      <div
        v-if="dropdownOptions.length === 0 && noSearchResult && !loadingProjects"
        class="project-dropdown-expand-no-result"
      >
        {{ noResultLabel }}
      </div>
      <div class="project-dropdown-expand-footer">
        <div class="project-dropdown-expand-footer-left">
          <submit-button
            btnStyle="secondary"
            size="small"
            :iconClass="createButtonIcon"
            :label="$t('create')"
            @submit="handleClickCreate"
          />
        </div>
        <div class="project-dropdown-expand-footer-right">
          <div class="project-dropdown-expand-footer-right-btn">
            <submit-button
              btnStyle="secondary"
              size="small"
              :label="cancelButtonLabel"
              @submit="hide"
            />
          </div>
          <submit-button
            size="small"
            :disabled="!selectedProjectId"
            :label="addButtonLabel"
            @click.native.stop="handleClickAdd"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubmitButton from '@/components/buttons/submitButton';
import IconButton from '@/components/iconButton';
import Skeleton from '@/components/skeleton';
import Spinner from '@/components/loaders/spinnerWithoutProgress';
import Observer from '@/containers/observer';

import { clickOutside, dropdownOrientation } from '@/utils/directives';

export default {
  components: {
    SubmitButton,
    IconButton,
    Skeleton,
    Spinner,
    Observer
  },
  props: {
    dropdownOptions: {
      type: Array,
      required: true
    },
    showObserver: {
      type: Boolean,
      required: false
    },
    searchPlaceholder: {
      type: String,
      required: false,
      default: 'Search projects...'
    },
    submitButton: {
      type: Object,
      required: false
    },
    iconButton: {
      type: String,
      required: false
    },
    addButtonLabel: {
      type: String,
      required: false,
      default: 'Add'
    },
    cancelButtonLabel: {
      type: String,
      required: false,
      default: 'Cancel'
    },
    createButtonIcon: {
      type: String,
      required: false,
      default: 'ri-folder-add-line'
    },
    placeholder: {
      type: Object,
      required: false
    },
    defaultSelectedProjectId: {
      type: String,
      required: false,
      default: null
    },
    keepExpand: {
      type: Boolean,
      required: false,
      default: false
    },
    trackId: {
      type: String,
      required: false
    },
    noSearchResult: {
      type: Boolean,
      required: false,
      default: false
    },
    noResultLabel: {
      type: String,
      required: false,
      default: 'No results'
    },
    fetchingMoreProjects: {
      type: Boolean,
      required: false,
      default: false
    },
    loadingProjects: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isExpanded: false,
      selectedProjectId: this.defaultSelectedProjectId,
      direction: {
        top: 'toTop',
        bot: 'toBot',
        left: 'toLeft',
        right: 'toRight'
      }
    };
  },
  directives: {
    clickOutside,
    dropdownOrientation
  },
  watch: {
    isExpanded() {
      this.$emit('listProjects');
      this.$emit('setProjectDropdownState', { id: this.trackId, isExpanded: this.isExpanded });
    },
    defaultSelectedProjectId() {
      this.selectedProjectId = this.defaultSelectedProjectId;
      // for page with multiple instance of project dropdown, we verify if isExpanded is true before calling handleClickAdd ( to prevent sending the emit multiple time and with potentially wrong information from unconcerned instance )
      if (this.isExpanded) {
        this.handleClickAdd(true);
      }
    }
  },
  methods: {
    toggleExpand() {
      if (this.isExpanded) {
        this.$refs.search.value = '';
        this.$emit('updateSearchValue', '');
      }
      this.selectedProjectId = null;
      this.isExpanded = !this.isExpanded;
    },
    hide(force) {
      if ((this.isExpanded && this.keepExpand === false) || force) {
        this.$refs.search.value = '';
        this.$emit('updateSearchValue', '');
        this.selectedProjectId = null;
        this.isExpanded = false;
      }
    },
    handleClickOption(projectId) {
      this.selectedProjectId = projectId;
      this.$emit('selectItemFromDropdown', this.trackId);
    },
    handleClickCreate() {
      this.$emit('createProject');
      this.$emit('selectItemFromDropdown', this.trackId);
    },
    handleClickAdd(forceHide) {
      this.$emit('addTracksToProject', {
        projectId: this.selectedProjectId,
        trackId: this.trackId
      });
      this.hide(forceHide);
    },
    loadMore() {
      this.$emit('fetchMoreProjects');
    }
  }
};
</script>

<style lang="scss" scoped>
.project-dropdown {
  @include body-1;
  color: $color_neutral_100;
  user-select: none;
  width: fit-content;
  position: relative;

  &-expand {
    position: absolute;
    width: 300px;
    background-color: $color_neutral_0;
    @include shadow-down-03;
    overflow-y: auto;
    overscroll-behavior: contain;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid $color_neutral_30;
    z-index: 6;

    &-search {
      padding: 0 16px;
      height: 40px;
      border-bottom: 1px solid $color_neutral_30;
      font-size: 18px;
      display: flex;
      align-items: center;

      &-input {
        margin: 0 8px;
        width: 100%;
        box-sizing: border-box;
        height: 40px;
        color: $color_neutral_100;
        font-size: 14px;
        outline: none;
        border: none;
        filter: none;
        font-weight: 400;
      }
    }

    &-options {
      height: 224px;
      overflow-y: auto;
      position: relative;
      @media (max-height: 680px) {
        height: 150px;
      }

      &-skeleton {
        padding: 0 0 0 16px;
      }

      &-spinner {
        display: flex;
        padding: 40px 0;
        justify-content: center;
      }

      &-placeholder {
        height: 176px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 24px;
        white-space: normal;

        &-text {
          text-align: center;
          padding: 0 0 16px 0;
          line-height: 150%;
        }
      }

      &-- {
        height: 32px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        cursor: pointer;

        &:hover {
          background-color: $color_neutral_10;
        }
      }

      &-icon {
        color: $color_neutral_30;
        font-size: 18px;
        padding: 0 8px 0 0;
      }

      &-label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-active {
        background-color: $color_primary_100;
        color: $color_neutral_0;

        &-icon {
          color: $color_neutral_0;
        }

        &:hover {
          background-color: $color_primary_100;
        }
      }
    }

    &-no-result {
      padding: 32px;
      display: flex;
      justify-content: center;
      line-height: 150%;
      height: 176px;
      align-items: center;
    }

    &-footer {
      display: flex;
      height: 48px;
      @include shadow-up-01;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;

      &-right {
        display: flex;

        &-btn {
          margin: 0 8px 0 0;
        }
      }
    }
  }
}

.toTop {
  bottom: 38px;
}
.toBot {
  margin-top: 4px;
}
.toLeft {
  right: 0;
}
.toRight {
  left: 0;
}

::placeholder {
  color: $color_neutral_40;
  font-size: 14px;
  font-weight: 400;
}
</style>
