<template>
  <div class="toggle-btn">
    <div
      class="toggle-btn-item"
      v-for="(btn, index) in buttons"
      :key="`togbtn-${index}`"
      @click="toggleButtons(index)"
    >
      <div
        class="toggle-btn-item-text"
        :class="
          index === selectedButtonIndex ? 'toggle-btn-item-enabled' : 'toggle-btn-item-disabled'
        "
      >
        <div class="title">
          <span class="title-value">{{ btn.value }}</span>
          <span class="title-type">{{ btn.type }}</span>
        </div>
        <div class="description">{{ btn.description }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    buttons: {
      type: Array,
      required: true
    },
    defaultSelectedButtonIndex: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      selectedButtonIndex: this.defaultSelectedButtonIndex
    };
  },
  watch: {
    defaultSelectedButtonIndex() {
      this.selectedButtonIndex = this.defaultSelectedButtonIndex;
    }
  },
  methods: {
    toggleButtons(index) {
      this.selectedButtonIndex = index;
      this.$emit('setToggleButtonsIndex', index);
    }
  }
};
</script>

<style lang="scss" scoped>
.toggle-btn {
  display: flex;
  @include body-2;

  &-item {
    margin: 0 8px 0 0;
    cursor: pointer;

    &-text {
      width: 120px;
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      transition: all 200ms linear;

      .title {
        margin: 0 0 4px 0;
        @include heading-6;
      }

      .description {
        font-size: 12px;
        margin: 4px 0 0 0;
      }
    }

    &-enabled {
      background-color: $color_primary_100;
      border: 1px solid $color_primary_100;
      color: $color_neutral_0;
      box-shadow: 0px 1px 2px rgba(213, 87, 109, 0.1), 0px 2px 4px rgba(213, 87, 109, 0.1),
        0px 4px 8px rgba(213, 87, 109, 0.1), 0px 8px 16px rgba(213, 87, 109, 0.1);

      .description {
        opacity: 0.7;
      }

      .title {
        color: $color_neutral_0;
      }
    }

    &-disabled {
      border: 1px solid $color_neutral_30;

      .description {
        color: $color_neutral_60;
      }
    }
  }
}
</style>
