
<div class="full-storage-modal">
  <div class="full-storage-modal-body">
    <span>{{ $t('fullStorage') }}</span>
    <span v-if="isEditor" class="full-storage-modal-body-normal"
      >{{ $t('upgradeByAdmin.firstPart') }}
      <span class="full-storage-modal-body-bold">{{ $t('upgradeByAdmin.boldPart') }}</span>
      <span class="full-stroage-modal-body-normal">{{
        $t('upgradeByAdmin.thirdPartToKeepUploading')
      }}</span></span
    >
    <span v-else class="full-storage-modal-body-bold"
      >{{ $t('pleaseUpgrade') }}
      <span class="full-storage-modal-body-normal">{{ $t('keepUploading') }}</span></span
    >
  </div>
  <div class="full-storage-modal-buttons">
    <div class="full-storage-modal-buttons--">
      <submit-button
        btnStyle="tertiary"
        size="medium"
        :label="$t('cancel')"
        @submit="closeModal"
      />
    </div>
    <div
      class="full-storage-modal-buttons--"
      v-tooltip="{
        content: isEditor && $t('upgradePlanByAdmin'),
        classes: 'alert-editor-tooltip'
      }"
    >
      <submit-button
        btnStyle="primary"
        size="medium"
        :label="currentWorkspace.free_trial_used ? $t('upgradePlan') : $t('startFreeTrial')"
        @submit="submit"
        :disabled="isEditor"
      />
    </div>
  </div>
</div>
