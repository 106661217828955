
<div>
  <div class="content">
    <h4 class="header">{{ $t('billingDetails.paymentMethod') }}</h4>
    <div v-if="!card" class="loader">
      <spinner-without-progress color="grey" :size="32" />
    </div>
    <div v-else>
      <div v-if="hasCard()">
        <div class="cardInformations">
          <img class="card" :src="getCardTypeImage" />
          <div v-if="card.last4">**** **** **** {{ card.last4 }}</div>
        </div>
        <div class="cardExpiracy">
          <span class="cardExpiracyLabel">{{ $t('billingDetails.expires') }}</span>
          <span class="cardExpiracyMonth">{{ formatExpiracyMonth() }}</span
          ><span>/</span>
          <span class="cardExpiracyYear">{{ card.exp_year }}</span>
        </div>
        <submit-button
          class="button"
          btnStyle="tertiary"
          :exception="true"
          size="medium"
          :label="$t('billingDetails.changePaymentMethod')"
          @submit="changePaymentMethod"
        />
      </div>
      <div v-else>
        <p>No payment method saved.</p>
        <submit-button
          class="button"
          btnStyle="tertiary"
          :exception="true"
          size="medium"
          :label="$t('billingDetails.addPaymentMethod')"
          @submit="changePaymentMethod"
        />
      </div>
    </div>
  </div>
</div>
