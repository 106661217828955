<template>
  <div class="projectList">
    <div class="projectList-search">
      <search-bar
        key="search-bar-move-to"
        icon="ri-search-line"
        :fieldModel.sync="searchValue"
        :placeholder="$t('searchPlaceholder')"
      />
    </div>
    <div class="projectList-body" ref="projectListContainerScroll">
      <!-- LOADING -->
      <div v-if="loadingProjects" class="projectList-loader">
        <spinner-without-progress color="grey" />
      </div>
      <div v-else class="projectList-container">
        <!-- SEARCH NO RESULTS -->
        <div
          v-if="projects.length === 0 && noSearchResult && !displayNewProjectInput"
          class="projectList-centered"
        >
          No results
        </div>

        <!-- NO PROJECTS & NO SEARCH -->
        <div
          v-if="projects.length === 0 && !noSearchResult && !displayNewProjectInput"
          class="projectList-centered"
        >
          <div class="projectList-emptyLabel">{{ emptyPlaceholder.text }}</div>
          <submit-button
            size="small"
            :label="emptyPlaceholder.buttonLabel"
            :iconClass="emptyPlaceholder.buttonIcon"
            @submit="$emit('update:displayNewProjectInput', true)"
          />
        </div>
        <!-- LISTE PROJECT -->
        <div v-if="projects.length > 0 || displayNewProjectInput">
          <div
            v-if="displayProjectRequiredError"
            class="banner error"
            :class="displayProjectNameError ? 'banner-margin' : ''"
          >
            <div class="banner-icon error-icon"><i class="ri-alert-fill" /></div>
            <div>{{ $t('uploadToModal.projectRequired') }}</div>
          </div>
          <div v-if="displayProjectNameError" class="banner error">
            <div class="banner-icon error-icon"><i class="ri-alert-fill" /></div>
            <div>{{ displayProjectNameError }}</div>
          </div>
          <div v-if="displayNewProjectInput" class="projectItem new-project-input">
            <div class="projectItem-icon-container">
              <i class="ri-folder-fill projectItem-icon" />
            </div>
            <text-field
              inputType="text"
              :selectOnLoad="true"
              :fieldModel.sync="newProjectName"
              @handleBlur="handleBlur"
              @enterPress="handleBlur"
            />
          </div>
          <div
            v-for="project in projects"
            :key="project.id"
            class="projectItem"
            :class="{
              'projectItem-selected': selectedProjectId === project.id,
              'projectItem-disabled': isCurrentProject(project.id)
            }"
            v-tooltip="{
              content: isCurrentProject(project.id) && 'You cannot move file to current project'
            }"
            @click.stop="handleProjectItemClick(project.id, project.label)"
          >
            <div class="projectItem-icon-container">
              <i class="ri-folder-fill projectItem-icon" />
            </div>
            <div class="projectItem-label">{{ project.label }}</div>
            <div v-if="selectedProjectId === project.id" class="projectItem-check">
              <i class="ri-check-line" />
            </div>
          </div>
          <div v-if="fetchingMoreProjects" class="loader">
            <skeleton />
          </div>
          <observer v-if="showObserver" :height="32" bottom @intersect="loadMore" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Observer from '@/containers/observer';
import { trackEvent } from '@/utils/functions/analytics';

export default {
  components: {
    Observer
  },
  data() {
    this.$emit('listProjects');
    return {
      searchValue: '',
      selectedProjectId: undefined,
      selectedProjectName: undefined,
      newProjectName: '',
      displayProjectNameError: null
    };
  },
  props: {
    projects: {
      type: Array,
      required: false,
      default: () => []
    },
    emptyPlaceholder: {
      buttonLabel: {
        type: String,
        required: false
      },
      buttonIcon: {
        type: String,
        required: false
      },
      text: {
        type: String,
        required: false
      },
      default: () => ({
        buttonLabel: 'New project',
        buttonIcon: 'ri-add-fill',
        text: 'Create a new project to organize and share your work.'
      })
    },
    showObserver: {
      type: Boolean,
      required: false
    },
    fetchingMoreProjects: {
      type: Boolean,
      required: false,
      default: false
    },
    loadingProjects: {
      type: Boolean,
      required: false,
      default: false
    },
    displayNewProjectInput: {
      type: Boolean,
      required: false
    },
    displayProjectRequiredError: {
      type: Boolean,
      required: false
    },
    noSearchResult: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    searchValue() {
      this.$emit('updateSearchValue', this.searchValue);
    },
    displayNewProjectInput() {
      if (this.displayNewProjectInput) {
        this.selectedProjectId = undefined;
        this.selectedProjectName = undefined;
        this.$emit('setSelectedProjectId', this.selectedProjectId, this.selectedProjectName);
        trackEvent('New Project Click', {
          category: 'project',
          page_view: 'addto modal'
        });
      }
    }
  },
  methods: {
    isCurrentProject(projectId) {
      return (
        this.$route.path.includes('projects') &&
        this.$route.path.includes('details') &&
        this.$route.params.id === projectId
      );
    },
    handleProjectItemClick(projectId, projectName) {
      if (this.isCurrentProject(projectId)) {
        return;
      }
      if (this.selectedProjectId === projectId) {
        this.selectedProjectId = undefined;
        this.selectedProjectName = undefined;
      } else {
        this.selectedProjectId = projectId;
        this.selectedProjectName = projectName;
      }
      this.$emit('setSelectedProjectId', projectId, projectName);
    },
    loadMore() {
      this.$emit('fetchMoreProjects');
    },
    handleBlur() {
      this.displayProjectNameError = null;
      if (this.newProjectName === '') {
        setTimeout(() => {
          this.$emit('update:displayNewProjectInput', false);
        }, 100);
        return;
      }
      if (this.newProjectName.length < 3) {
        this.displayProjectNameError = '3 characters minimum';
        return;
      }
      if (this.newProjectName.length > 255) {
        this.displayProjectNameError = '255 characters maximum';
        return;
      }
      this.$emit('createProject', this.newProjectName);
      this.newProjectName = '';
    }
  }
};
</script>

<style lang="scss" scoped>
.projectPicker-container {
  position: absolute;
  width: 300px;
  background-color: $color_neutral_0;
  @include shadow-down-03;
  overflow-y: auto;
  overscroll-behavior: contain;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid $color_neutral_30;
  z-index: 5;
}

.projectList {
  height: fit-content;
  height: 332px;
  overflow-y: scroll;
  position: relative;
  font-size: 14px;
  &-search {
    padding: 16px 24px;
    border-bottom: 1px solid $color_neutral_30;
  }
  &-body {
    padding: 0 24px;
    height: 257px;
    // height: 211px;
    box-sizing: border-box;
    overflow: auto;
  }
  &-container {
    padding: 8px 0;
    height: 100%;
    box-sizing: border-box;
  }
}

.projectList-centered,
.projectList-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
}

.projectList-loader {
  padding: 24px 0;
}

.projectList-emptyLabel {
  width: 200px;
  margin-bottom: 16px;
}

.projectItem,
.libraryItem {
  margin-bottom: 4px;
  height: 32px;
  display: flex;
  border-radius: 4px;
  flex-direction: row;
  cursor: pointer;
  padding: 0 8px;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0);
  box-sizing: border-box;
  &:hover {
    background-color: $color_neutral_10;
  }
  &-check {
    font-size: 16px;
    height: 16px;
  }
}

.new-project-input:hover {
  background-color: $color_neutral_0;
}

.libraryItem {
  opacity: 0.6;
  &:hover {
    background-color: $color_neutral_0;
  }
}

.projectItem-selected {
  background-color: $color_primary_10;
  border: 1px solid $color_primary_100;
  color: $color_primary_100;
  &:hover {
    background-color: $color_primary_10;
  }
}
.projectItem-disabled {
  opacity: 30%;
}

.projectItem-icon,
.libraryItem-icon {
  flex-shrink: 0;
  color: $color_secondary_100;
  font-size: 16px;
  &-container {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: $color_secondary_10;
  }
}

.libraryItem-icon {
  color: $color_primary_100;
  &-container {
    background-color: $color_primary_10;
  }
}

.projectItem-label,
.libraryItem-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal;
  flex-grow: 1;
  padding-right: 8px;
  &-helper {
    font-size: 12px;
    opacity: 0.6;
  }
}

.loader {
  padding: 12px 0;
}

.footer {
  display: flex;
  height: 48px;
  @include shadow-up-01;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.footer-right {
  display: flex;

  & > :first-child {
    margin-right: 8px;
  }
}

.banner {
  width: calc(100% + 16px);
  line-height: 150%;
  margin-left: -8px;
  border-radius: 4px;
  padding: 8px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  &-icon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border-radius: 40px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
}

.warning {
  background-color: $color_warning_10;
  color: $color_warning_110;
  &-icon {
    background-color: rgba(255, 197, 51, 0.1);
    color: $color_warning_100;
  }
}
.error {
  background-color: $color_danger_10;
  color: $color_danger_110;
  &-icon {
    background-color: rgba(255, 69, 51, 0.1);
    color: $color_danger_100;
  }
}

.banner-margin {
  margin-bottom: 8px;
}
</style>

<style lang="scss">
.projectItem.new-project-input {
  .textfield-container {
    height: 28px;
    width: 294px;
    padding-left: 4px;
    padding-right: 4px;
    .textfield-password-input.textfield-password-input-large {
      height: 26px;
    }
  }
}
</style>
