
<div class="breadcrumb-container">
  <div
    v-for="(name, index) in path"
    :key="index"
    :class="{
      pathItem: true,
      link: index < path.length - 1
    }"
    @click="handleClick(index)"
  >
    <div class="icon" :class="iconStyle" v-if="iconClass && index === 0">
      <i :class="iconClass" />
    </div>
    <div class="name">
      {{ name }}
    </div>
  </div>
</div>
