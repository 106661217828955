
<div class="share-settings-modal">
  <div class="share-settings-modal-info">
    <div v-if="contactInfo" class="share-info">
      <div class="icon">
        <i class="ri-user-fill"></i>
      </div>
      <div class="share-name">
        {{ contactInfo }}
      </div>
    </div>
    <div v-else>
      <div v-if="!shareCustomName" class="share-info">
        <div class="icon">
          <i class="ri-global-line"></i>
        </div>
        <div class="share-name">
          {{ $t('quickLink') }}
        </div>
      </div>
    </div>
  </div>
  <share-security-section
    ref="sharePasswordModalSection"
    :displayPasswordInput="displaySharePassword"
    :displayLinkNameInput="true"
    :shareLinkName.sync="shareLinkName"
    :shareLinkNameErrorMessage="shareLinkNameErrorMessage"
    :securityPassword.sync="sharePassword"
    :securityPasswordErrorMessage="passwordErrorMessage"
    :shareCustomName="shareCustomName"
    :selectOnLoad="!sharePassword"
    :displayTitle="false"
    @toggleSwitch="toggleSharePassword"
  />
  <div class="share-settings-modal-buttons">
    <div class="share-settings-modal-buttons--">
      <submit-button
        btnStyle="tertiary"
        size="medium"
        :label="$t('cancel')"
        @submit="$emit('closeModal')"
      />
    </div>
    <div class="share-settings-modal-buttons--">
      <submit-button
        btnStyle="primary"
        size="medium"
        :label="$t('save')"
        :disabled="isButtonDisabled"
        :pending="pending"
        @submit="handleSubmit"
      />
    </div>
  </div>
</div>
