<template>
  <div class="container">
    <div v-if="status === 'success'" class="success">
      <i class="ri-checkbox-circle-line"></i>
    </div>
    <div v-if="status === 'fail'" class="fail">
      <i class="ri-close-circle-line"></i>
    </div>
    <div v-if="status === 'loading'" class="loading">
      <i class="ri-refresh-line"></i>
      Saving...
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
      default: 'loading'
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  .loading {
    border-radius: 32px;
    display: flex;
    align-items: center;
    @include body-1;
    font-size: 12px;
    height: 19px;
    width: fit-content;
    padding: 0 8px;
    background-color: $color_neutral_10;
    color: $color_neutral_60;
    .ri-refresh-line {
      animation: rotate 1.5s linear infinite;
      transform-origin: center;
      color: $color_neutral_40;
      margin-right: 4px;
      font-size: 16px;
    }
  }
  .success,
  .fail {
    width: 19px;
    height: 19px;
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
  }
  .success {
    color: $color_success_110;
    background-color: $color_success_10;
  }
  .fail {
    color: $color_danger_110;
    background-color: $color_danger_10;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
</style>
