
<div class="add-member">
  <div class="information-banner-container">
    <div class="information-banner">
      <div class="icon-info"><i class="ri-information-fill"></i></div>
      <div class="text">
        You are currently using <span class="mail">{{ bannierNumberOfSeats }}</span>
        <span v-if="!isFreePlan">(5 free seats)</span>
      </div>
      <submit-button
        v-if="isFreePlan"
        class="information-banner-action"
        label="Upgrade"
        btnStyle="tertiary"
        size="small"
        @submit="goToOffersSettings"
      />
      <submit-button
        v-else
        class="information-banner-action"
        label="More info"
        btnStyle="tertiary"
        iconClass="ri-external-link-line"
        size="small"
        @submit="goToMoreInfo"
      />
    </div>
  </div>
  <div class="add-member-content">
    <form @submit.prevent="submit" class="add-member-content-form">
      <div
        class="add-member-content-form-email"
        v-for="(invitation, index) in invitations"
        :key="`invitations-${invitation.id}`"
      >
        <div class="add-member-content-form-email-textfield">
          <text-field
            inputType="text"
            :errorMessage="getMemberValidationMessage(index)"
            :fieldModel.sync="invitation.email"
            :placeholder="$t('enterEmail')"
            :label="index === 0 ? $t('email') : null"
            :large="false"
            trimValue
          />
        </div>
        <div class="add-member-content-form-email-dropdown">
          <div v-if="index === 0" class="add-member-content-form-label-role">
            {{ $t('role') }}
          </div>
          <roles-dropdown
            :roles="roleListInvit"
            :userRole="roleListInvit.find(role => role.value === '2')"
            :userId="invitation.id"
            withBorder
            @setRole="setInvitationRole"
          />
        </div>
        <div :class="[index === 0 ? 'add-member-content-form-email-remove-first' : '']">
          <icon-button
            v-if="invitations.length > 1"
            icon="ri-delete-bin-line"
            btnStyle="tertiary"
            size="small"
            isDanger
            @submit="removeInvitation(index)"
          />
        </div>
      </div>
      <div class="add-member-content-form-add">
        <div class="add-member-btn">
          <v-popover
            trigger="hover"
            container="#addMemberButtonId"
            placement="top"
            :disabled="memberLimitPopoverDisabled"
          >
            <!-- This will be the popover target (for the events and position) -->
            <div id="addMemberButtonId" class="tooltip-target b3">
              <submit-button
                class="primary-colored-tertiary"
                btnStyle="tertiary"
                size="small"
                :label="$t('addMember')"
                :disabled="addMemberDisabled"
                iconClass="ri-add-line"
                @submit="addInvitation"
              />
            </div>
            <!-- This will be the content of the popover -->
            <template slot="popover">
              <div class="upgrade-popover">
                <div class="upgrade-popover-text">
                  You need to upgrade to a pro plan to invite more people
                </div>
                <submit-button
                  class="upgrade-popover-btn"
                  @click.native.stop
                  btnStyle="primary"
                  size="small"
                  :label="currentWorkspace.free_trial_used ? $t('upgrade') : $t('startFreeTrial')"
                  @submit="goToOffersSettings"
                />
              </div>
            </template>
          </v-popover>
        </div>
      </div>
      <div class="add-member-content-form-btn">
        <div v-if="!loading" class="copy-invitation-container">
          <submit-button
            v-if="inviteLink"
            class="primary-colored-tertiary mr-8"
            btnStyle="tertiary"
            :label="$t('copyInviteLink')"
            iconClass="ri-links-fill"
            @submit="copyInvitationLink"
          />
          <submit-button
            v-else
            class="primary-colored-tertiary"
            btnStyle="tertiary"
            :label="$t('createAndCopyInviteLink')"
            iconClass="ri-links-fill"
            @submit="createAndCopyInvitationLink"
          />
          <more-dropdown
            @click.native.prevent
            v-if="inviteLink"
            size="medium"
            class="primary-colored-tertiary"
            :items="invitationLinkMoreActions"
          />
        </div>
        <div v-else></div>
        <submit-button
          :label="$tc('sendInvites', invitations.length)"
          :disabled="submitDisabled"
          iconClass="ri-mail-send-line"
          @submit="submit"
          :pending="isSubmitted"
        />
      </div>
    </form>
  </div>
</div>
