import gql from 'graphql-tag';

export const userPictureFragment = {
  userPicture: gql`
    fragment userPicture on UserPicture {
      id
      url
    }
  `
};

export const meFragment = {
  me: gql`
    fragment me on User {
      id
      firstname
      lastname
      email
      profile_picture {
        ...userPicture
      }
    }
    ${userPictureFragment.userPicture}
  `
};

export const roleFragment = {
  role: gql`
    fragment role on Role {
      id
      name
    }
  `
};

export const invitationsFragment = {
  invitations: gql`
    fragment invitations on WorkspaceInvitation {
      id
      email
      role {
        ...role
      }
      status
    }
    ${roleFragment.role}
  `
};

export const streamablesFragment = {
  streamables: gql`
    fragment streamables on FileStreamable {
      waveform
      url
    }
  `
};

export const libraryFragment = {
  library: gql`
    fragment library on Library {
      id
    }
  `
};

export const membersFragment = {
  members: gql`
    fragment members on WorkspaceMember {
      user_id
      role {
        ...role
      }
      firstname
      lastname
      email
      profile_picture {
        ...userPicture
      }
    }
    ${roleFragment.role}
    ${userPictureFragment.userPicture}
  `
};

export const planFragment = {
  plan: gql`
    fragment plan on WorkspaceSubscriptionPlan {
      id
      name
      monthly_price
      annually_price
      variant_id
    }
  `
};

export const subscriptionFragment = {
  subscription: gql`
    fragment subscription on WorkspaceSubscription {
      id
      start_at
      end_at
      billing_period
      storage
      seats
      track_limit
      plan {
        ...plan
      }
      payment_information {
        cancel_at_period_end
        subscription_id
        price
        seats
        period
        status
        currency
        cancel_at_period_end
        free_trial {
          start
          end
          days_left
        }
      }
    }
    ${planFragment.plan}
  `
};

export const workspaceTemplate = `
  id
  name
  owner_id
  activity_id
  space_used
  logo {
    url
  }
  seats_used
  total_tracks
  total_supports
  total_artists
  plan_slug
  tracks_left
  invitations {
    ...invitations
    role {
      ...role
    }
  }
  libraries {
    ...library
  }
  members {
    ...members
    role {
      ...role
    }
  }
  subscription {
    ...subscription
  }
  properties {
    beta_cohort
    auto_tagger
  }
  is_locked
  free_trial_used
  hubs {
    id
    name
    logo
    link
  }

`;

export const libraryImageFragment = {
  libraryImage: gql`
    fragment libraryImage on LibraryImage {
      type
      thumbnails {
        url
        width
      }
    }
  `
};

export const labelsFragment = {
  labels: gql`
    fragment labels on LibraryLabel {
      id
      name
    }
  `
};

export const supportsFragment = {
  supports: gql`
    fragment supports on LibrarySupport {
      id
      name
      images {
        ...libraryImage
      }
      type
      upc
      release_date
      tracks_number
      tracks_total
      tracks_length
      track_position
      tracks_ids
    }
    ${libraryImageFragment.libraryImage}
  `
};

export const lightSupportsFragment = {
  supports: gql`
    fragment lightSupports on LibrarySupport {
      id
      name
      images {
        ...libraryImage
      }
      track_position
    }
    ${libraryImageFragment.libraryImage}
  `
};

export const artistsFragment = {
  artists: gql`
    fragment artists on LibraryArtist {
      id
      name
      image {
        ...libraryImage
      }
    }
    ${libraryImageFragment.libraryImage}
  `
};

export const lightArtistsFragment = {
  artists: gql`
    fragment lightArtists on LibraryArtist {
      id
      name
    }
  `
};

export const authorsFragment = {
  authors: gql`
    fragment authors on LibraryFileAuthor {
      id
      name
    }
  `
};

export const composersFragment = {
  composers: gql`
    fragment composers on LibraryFileComposer {
      id
      name
    }
  `
};

export const additionalMediaFragment = {
  additionalMedia: gql`
    fragment additionalMedia on LibraryFileInformation_AdditionalMedia {
      format
      size
      duration
      bit_rate
    }
  `
};

export const userBasicFragment = {
  userBasic: gql`
    fragment userBasic on UserBasic {
      id
      firstname
      lastname
      profile_picture {
        ...userPicture
      }
    }
    ${userPictureFragment.userPicture}
  `
};

export const fileInformationFragment = {
  fileInformation: gql`
    fragment fileInformation on LibraryFileInformation {
      original_name
      added_by
      added_by_info {
        ...userBasic
      }
      additional_media {
        ...additionalMedia
      }
    }
    ${additionalMediaFragment.additionalMedia}
    ${userBasicFragment.userBasic}
  `
};

export const rightHoldersFragment = {
  rightHolders: gql`
    fragment rightHolders on LibraryFileRightHolder {
      id
      track_right_holder_id
      name
      slug
      role
      publishing_percentage
      mastering_percentage
    }
  `
};

export const tagsFragment = {
  tags: gql`
    fragment tags on LibraryFileTag {
      id
      tag_context_id
      category_id
      family_id
      tag_id
      auto_tagged
    }
  `
};

export const hubsFragment = {
  hubs: gql`
    fragment hubs on Hub {
      id
      name
      logo
    }
  `
};

export const lightTracksFragment = {
  lightTracks: gql`
    fragment lightTracks on LibraryFile {
      id
      title
      created_at
      rating
      status_id3_extracted
      status_streamable_transcoded
      duration
      supports {
        ...lightSupports
      }
      artists {
        ...lightArtists
      }
    }
    ${lightSupportsFragment.supports}
    ${lightArtistsFragment.artists}
  `
};

export const tracksFragment = {
  tracks: gql`
    fragment tracks on LibraryFile {
      id
      title
      isrc
      iswc
      bpm
      created_at
      updated_at
      rating
      status_id3_extracted
      status_streamable_transcoded
      recording_date
      lyrics
      comment
      duration
      genre
      supports {
        ...supports
      }
      artists {
        ...artists
      }
      authors {
        ...authors
      }
      composers {
        ...composers
      }
      labels {
        ...labels
      }
      publishers {
        id
        name
      }
      file_information {
        ...fileInformation
      }
      right_holders {
        ...rightHolders
      }
      tags {
        ...tags
      }
      hubs {
        ...hubs
      }
    }
    ${supportsFragment.supports}
    ${artistsFragment.artists}
    ${authorsFragment.authors}
    ${composersFragment.composers}
    ${labelsFragment.labels}
    ${fileInformationFragment.fileInformation}
    ${rightHoldersFragment.rightHolders}
    ${tagsFragment.tags}
    ${hubsFragment.hubs}
  `
};

export const projectFragment = {
  project: gql`
    fragment project on Project {
      id
      name
      description
      owner_id
      status
      created_at
      updated_at
      total_tracks
      total_attachments
      share {
        base_link
        is_enabled
        email
      }
    }
  `
};

export const projectAttachmentFragment = {
  projectAttachment: gql`
    fragment projectAttachment on ProjectAttachment {
      id
      type
      file {
        id
        name
        extension
        original_name
        size
        preview_url
        download_url
        thumbnails {
          type
          width
          height
          url
        }
        created_at
        updated_at
      }
      position
      created_at
      updated_at
    }
  `
};

export const shareFragment = {
  share: gql`
    fragment share on Share {
      id
      workspace_id
      shared_by
      share_name
      custom_name
      email
      settings {
        access_rights
        access_password
      }
      status
      is_enabled
      created_at
      updated_at
      link
    }
  `
};

export const LibraryDictionariesFragment = {
  LibraryDictionaries: gql`
    fragment LibraryDictionaries on LibraryDictionaries {
      support_types
      right_holder_roles
    }
  `
};

export const WorkspaceDictionariesFragment = {
  WorkspaceDictionaries: gql`
    fragment WorkspaceDictionaries on WorkspaceDictionaries {
      activities {
        id
        position
        name_en_GB
        name_fr_FR
      }
      languages {
        iso_code
        name
      }
      roles {
        id
        name
        is_admin
        is_editor
      }
      library {
        ...LibraryDictionaries
      }
    }
    ${LibraryDictionariesFragment.LibraryDictionaries}
  `
};

export const ProjectDictionariesFragment = {
  ProjectDictionaries: gql`
    fragment ProjectDictionaries on ProjectDictionaries {
      attachment_types
      statuses
    }
  `
};

export const notificationFragment = {
  notification: gql`
    fragment notification on Notification {
      id
      workspace_id
      event_type
      variables {
        name
        value
      }
      created_at
      updated_at
      read_at
      display_at
    }
  `
};
