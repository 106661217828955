<template>
  <div class="project-activity">
    <ActivityView
      class="view"
      entityType="project"
      :entityIds="entityIds"
      :entityName="entityName"
    />
  </div>
</template>

<script>
import ActivityView from '@/containers/shareViews/activity';

export default {
  components: {
    ActivityView
  },
  props: {
    entityIds: {
      type: Array,
      required: true
    },
    entityName: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.project-activity {
  .view {
    height: 100%;
  }
}
</style>
