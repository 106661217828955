
<div class="albums">
  <div v-if="artistDetails.supports && artistDetails.supports.length > 0" class="albums-scroller">
    <horizontal-scroller :thumbnails="thumbnails" @handleClickThumbnail="handleClickThumbnail" />
  </div>
  <div class="albums-content" v-if="artistDetails.supports && artistDetails.supports.length > 0">
    <div
      class="albums-content-album"
      v-for="(support, index) in artistDetails.supports"
      :key="`album-${support.id}`"
      :id="support.id"
    >
      <artist-album
        :support="support"
        :trackList="getTracksByAlbum(support)"
        :keepProjectDropdownExpand="keepProjectDropdownExpand"
        :uncheckTracks="uncheckTracks"
        :editMode="editMode"
        @createProject="createProject"
        @openConfirmDeleteModal="openConfirmDeleteModal"
        @toggleEditMode="toggleEditMode"
        @setArtistAlbumSelectedTracks="
          tracks => {
            setArtistAlbumSelectedTracks(tracks, index);
          }
        "
      />
    </div>
    <div
      v-if="flatSelectedTracks.length"
      class="action-bar-container"
      :class="{ 'panel-open': isEditPanelOpened }"
    >
      <action-bar-list
        :trackList="fullTrackList"
        :selectedTracks="flatSelectedTracks"
        @toggleEditModeFromActionBar="preToggleEditMode"
        @deleteFromActionBar="
          () => {
            openConfirmDeleteModal(this.flatSelectedTracks);
          }
        "
        @createProject="createProject"
      />
    </div>
  </div>
  <div v-else class="empty-state">
    <div class="empty-state-content">
      <img
        src="/images/emptyState-artist_page-no_album.svg"
        class="empty-state-content-image"
        alt="no album image"
      />
      <p class="empty-state-content-title">{{ $t('emptyState.title') }}</p>
      <p class="empty-state-content-text">
        {{ $t('emptyState.text') }}
      </p>
      <router-link :to="trackTabUrl" class="empty-state-content-link"
        >{{ $t('emptyState.link') }}
      </router-link>
    </div>
  </div>
</div>
