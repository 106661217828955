<template>
  <div class="container">
    <h2 class="headline">{{ $t('subscription.headline.cancel') }}</h2>
    <div class="explanations">
      <p>
        {{ isCanceled ? 'Your plan is canceled' : 'If you cancel your subscription' }}, your
        workspace will be downgraded to a free Starter plan, and you will lose access to premium
        features at the end of your billing period : <b>{{ billingDate }}</b
        >.
      </p>
      <p v-if="!isCanceled">
        Otherwise, you can manually remove the content you want from your workspace until it fits
        the free plan limits, and <b>then downgrade your plan</b>.
      </p>
    </div>
    <div class="button" v-tooltip="!this.isOwner ? 'Only workspace owner can perform this action': null">
    <submit-button
      label="Cancel subscription"
      :danger="true"
      btnStyle="secondary"
      @submit="cancelSubscription"
      :disabled="isCanceled || isOwner === false"
    />
  </div>
  </div>
</template>

<script>
import { bus } from '@/utils/bus';
import SubscriptionCancelModal from '@/containers/modals/subscriptionCancel';

export default {
  props: {
    isCanceled: {
      type: Boolean,
      required: true
    },
    billingDate: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    isOwner: {
      type: Boolean,
      required: false,
      default:false
    }
  },
  methods: {
    cancelSubscription() {
      bus.$emit('displayModal', {
        component: SubscriptionCancelModal,
        title: this.$t('Are you sure you want to cancel your plan?'),
        isVisible: true,
        size: 'medium',
        onSubmit: password => {
          this.$emit('cancelSubscription', password);
        },
        props: [
          {
            name: 'email',
            value: this.email
          }
        ]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.headline {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 32px;
}

.explanations {
  width: 650px;
  margin-bottom: 32px;
  color: $color_neutral_60;
  font-size: 14px;
  line-height: 21px;

  & p {
    margin-bottom: 24px;
  }
}

.button {
  width: fit-content;
}
</style>
