
<infinite-scroll
  :listQuery="listTracksQuery"
  @handleQueryResult="handleQueryResult"
  @setLoading="setLoading"
>
  <template v-slot:list>
    <tracks
      :trackList="trackList"
      :isFetchingMore="loading"
      :uncheckTracks="uncheckTracks"
      hasSelectAll
      @setSelectedTracks="setSelectedTracks"
    />
  </template>
</infinite-scroll>
