
<div v-if="trackList && trackList.length > 0">
  <header-list
    :columns="columns"
    :sortColumn="false"
    :isSticky="isSticky"
    :stickyPosition="stickyPosition"
    :hasSelectAll="hasSelectAll"
    :itemsSelected="[]"
    :displayUnselect="selectedTracks.length > 0"
    @handleEmit="handleEmit"
    @uncheckAllCheckbox="uncheckAllCheckbox"
    @selectAll="checkAllCheckbox"
  />
  <div v-for="(track, idx) in trackList" :key="track.id">
    <list-row
      :item="track"
      :columns="columns"
      :rowIndex="idx"
      :height="48"
      :id="track.id"
      :playingTrack="playingTrack || {}"
      :selectedItems="selectedTracks"
      :disableSelection="isSelectionDisabled"
      @handleEmit="handleEmit"
      @setPlayingTrackFromRow="setPlayingTrackFromRow"
    />
  </div>
  <div v-if="isFetchingMore">
    <skeleton-row :columns="columns" :height="48" />
  </div>
  <div
    v-if="selectedTracks.length"
    class="action-bar-container"
    :class="{ 'panel-open': isEditPanelOpened }"
  >
    <action-bar
      :numberOfSelection="actionBarCount"
      :actions="actionBarBtns"
      :label="actionBarLabels"
      @selectAll="checkAllCheckbox"
      @unselect="uncheckAllCheckbox"
    />
  </div>
</div>
