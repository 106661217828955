
<div class="dropdown" v-click-outside="{ hide }">
  <div class="dropdown-button" @click="toggleExpand">
    <avatar :pictureUrl="pictureUrl" :initial="initial" />
  </div>
  <div
    class="dropdown-expanded"
    :class="!isExpanded && 'dropdown-close'"
    :style="`max-height: ${height}px;`"
  >
    <div class="dropdown-expanded-options" @click="hide" v-if="isExpanded">
      <div class="dropdown-expanded-options-header">
        <avatar
          class="dropdown-expanded-options-header-avatar"
          :pictureUrl="pictureUrl"
          :initial="initial"
        />
        <div>
          <div class="dropdown-expanded-options-header-title">{{ headerOption.title }}</div>
          <div class="dropdown-expanded-options-header-subtitle">{{ headerOption.subtitle }}</div>
        </div>
      </div>
      <div
        class="dropdown-expanded-options-item"
        v-for="(option, index) in options"
        :key="`opt-${index}`"
        @click="option.action"
      >
        <i v-if="option.icon" :class="option.icon" class="dropdown-expanded-options-item-icon" />
        <span class="dropdown-expanded-options-item-label">{{ option.label }}</span>
      </div>
    </div>
  </div>
</div>
