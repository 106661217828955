
<div class="hscroller">
  <div v-if="showGradient && withObserver" class="hscroller-gradient" />
  <div ref="thumbs" class="hscroller-content">
    <div
      class="hscroller-content-thumbnails"
      v-for="(thumbnail, index) in thumbnails"
      :key="thumbnail.id"
    >
      <div
        :id="`thumb${index}`"
        class="hscroller-content-thumbnails-thumb"
        @click="$emit('handleClickThumbnail', thumbnail.id)"
      >
        <div class="hscroller-content-thumbnails-thumb-cover">
          <img :src="thumbnail.imgSrc" :alt="thumbnail.title" />
        </div>
        <div class="hscroller-content-thumbnails-thumb-txt">
          <div class="hscroller-content-thumbnails-thumb-txt-title">{{ thumbnail.title }}</div>
          <div class="hscroller-content-thumbnails-thumb-txt-subtitle">
            {{ thumbnail.subtitle }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="withObserver" class="hscroller-content-thumbnails">
      <observer @intersect="handleScrollEnd" />
    </div>
  </div>
</div>
