<template>
  <svg
    width="500"
    height="820"
    viewBox="0 0 500 820"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g clip-path="url(#clip0_1877_263743)">
      <rect width="500" height="820" rx="30" fill="#E0E3FA" />
      <path
        d="M307.694 104.707C282.44 104.707 257.433 109.22 234.101 117.99C210.769 126.761 189.569 139.615 171.712 155.82C153.855 172.025 139.69 191.263 130.025 212.436C120.361 233.609 115.387 256.301 115.387 279.219H192.31C192.31 265.468 195.294 251.853 201.093 239.149C206.891 226.445 215.39 214.902 226.105 205.179C236.819 195.456 249.539 187.744 263.538 182.482C277.537 177.22 292.541 174.511 307.694 174.511V104.707Z"
        fill="url(#paint0_linear_1877_263743)"
        fill-opacity="0.2"
      />
      <path
        d="M307.693 174.512C332.947 174.512 357.954 169.998 381.286 161.228C404.617 152.458 425.817 139.604 443.675 123.399C461.532 107.194 475.697 87.9558 485.362 66.783C495.026 45.6102 500 22.9173 500 6.10352e-05L423.077 5.49326e-05C423.077 13.7504 420.093 27.3661 414.294 40.0698C408.496 52.7735 399.996 64.3163 389.282 74.0393C378.568 83.7623 365.848 91.4749 351.849 96.737C337.85 101.999 322.845 104.707 307.693 104.707L307.693 174.512Z"
        fill="url(#paint1_linear_1877_263743)"
        fill-opacity="0.2"
      />
      <path
        d="M0.00152663 453.732C25.2557 453.732 50.2625 449.219 73.5943 440.448C96.926 431.678 118.126 418.824 135.983 402.619C153.841 386.414 168.006 367.176 177.67 346.003C187.334 324.83 192.309 302.138 192.309 279.22L115.386 279.22C115.386 292.971 112.401 306.586 106.603 319.29C100.804 331.994 92.3049 343.537 81.5905 353.26C70.8761 362.982 58.1562 370.695 44.1572 375.957C30.1581 381.219 15.154 383.928 0.00155113 383.928L0.00152663 453.732Z"
        fill="url(#paint2_linear_1877_263743)"
        fill-opacity="0.2"
      />
      <g filter="url(#filter0_dd_1877_263743)">
        <g clip-path="url(#clip1_1877_263743)">
          <path
            d="M217 120C217 111.163 224.163 104 233 104H500V951C500 959.837 492.837 967 484 967H233C224.163 967 217 959.837 217 951V120Z"
            fill="white"
          />
          <mask id="path-6-inside-1_1877_263743" fill="white">
            <path d="M217 159.766H467.936V1414.45H217V159.766Z" />
          </mask>
          <path d="M217 159.766H467.936V1414.45H217V159.766Z" fill="#F9F9FB" />
          <rect
            x="239.305"
            y="260.141"
            width="206.325"
            height="55.7635"
            rx="5.57635"
            fill="#2F43DE"
          />
          <rect
            x="286.703"
            y="279.658"
            width="111.527"
            height="16.7291"
            rx="8.36453"
            fill="#B38AFF"
          />
          <g clip-path="url(#clip2_1877_263743)">
            <rect x="239" y="355" width="54" height="13" rx="6.5" fill="$color_neutral_30" />
            <g clip-path="url(#clip3_1877_263743)">
              <rect
                width="249.606"
                height="44.6108"
                transform="translate(217 377.241)"
                fill="#E0E3FA"
              />
              <rect x="217" y="377.241" width="2.78818" height="45" fill="#2F43DE" />
              <rect
                x="239.305"
                y="388.089"
                width="22.3054"
                height="22.3054"
                rx="11.1527"
                fill="#B4BBF3"
              />
              <rect
                x="267.188"
                y="390.877"
                width="111.527"
                height="16.7291"
                rx="8.36453"
                fill="#B4BBF3"
              />
            </g>
            <rect
              x="239.305"
              y="438.276"
              width="22.3054"
              height="22.3054"
              rx="11.1527"
              fill="$color_neutral_30"
            />
            <rect
              x="267.188"
              y="441.064"
              width="111.527"
              height="16.7291"
              rx="8.36453"
              fill="$color_neutral_30"
            />
            <rect x="239" y="493.999" width="72" height="13" rx="6.5" fill="$color_neutral_30" />
            <rect
              x="239.305"
              y="527.498"
              width="22.3054"
              height="22.3054"
              rx="11.1527"
              fill="$color_neutral_30"
            />
            <rect
              x="267.188"
              y="530.286"
              width="111.527"
              height="16.7291"
              rx="8.36453"
              fill="$color_neutral_30"
            />
          </g>
          <rect
            x="240.002"
            y="182.968"
            width="32.064"
            height="32.064"
            rx="2.09113"
            fill="#2F43DE"
          />
          <text
            fill="white"
            xml:space="preserve"
            style="white-space: pre"
            font-family="manrope"
            font-size="13.9409"
            font-weight="600"
            letter-spacing="0em"
          >
            <tspan x="248.447" y="204.339" v-if="!workspaceLogoUrl">
              {{ getWorkspaceInitial }}
            </tspan>
          </text>
          <rect
            x="240.002"
            y="182.968"
            width="32.064"
            height="32.064"
            rx="2.09113"
            stroke="$color_neutral_10"
            stroke-width="1.39409"
          />
          <rect
            x="240.002"
            y="182.968"
            width="32.064"
            height="32.064"
            rx="2.09113"
            fill="url(#pattern0)"
          />
          <rect
            x="240.002"
            y="182.968"
            width="32.064"
            height="32.064"
            rx="2.09113"
            stroke="$color_neutral_10"
            stroke-width="1.39409"
          />
          <g clip-path="url(#clip4_1877_263743)">
            <text
              fill="#000"
              xml:space="preserve"
              style="white-space: pre"
              font-family="manrope"
              font-size="19.5172"
              font-weight="600"
              letter-spacing="0em"
            >
              <tspan x="278.34" y="206.475">{{ getWorkspaceName }}</tspan>
            </text>
          </g>
          <path
            d="M467 236.606H217V239.394H467V236.606Z"
            fill="$color_neutral_30"
            mask="url(#path-21-inside-2_1877_263743)"
          />
          <path
            d="M466.542 159.766V1414.45H469.33V159.766H466.542Z"
            fill="$color_neutral_30"
            mask="url(#path-6-inside-1_1877_263743)"
          />
          <mask id="path-28-inside-3_1877_263743" fill="white">
            <path d="M217 104H596.192V159.764H217V104Z" />
          </mask>
          <path d="M217 104H596.192V159.764H217V104Z" fill="#F4F4F5" />
          <path
            d="M596.192 158.764H217V160.764H596.192V158.764Z"
            fill="$color_neutral_30"
            mask="url(#path-28-inside-3_1877_263743)"
          />
          <circle
            cx="250.458"
            cy="130.49"
            r="7.66749"
            stroke="$color_neutral_40"
            stroke-width="1.39409"
          />
          <circle
            cx="275.552"
            cy="130.49"
            r="7.66749"
            stroke="$color_neutral_40"
            stroke-width="1.39409"
          />
          <circle
            cx="300.646"
            cy="130.49"
            r="7.66749"
            stroke="$color_neutral_40"
            stroke-width="1.39409"
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_1877_263743"
        x="177"
        y="82"
        width="363"
        height="943"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="18" />
        <feGaussianBlur stdDeviation="20" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.34902 0 0 0 0 0 0 0 0 0 1 0 0 0 0.12 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1877_263743" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="9" />
        <feGaussianBlur stdDeviation="10" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.34902 0 0 0 0 0 0 0 0 0 1 0 0 0 0.06 0" />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_1877_263743"
          result="effect2_dropShadow_1877_263743"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1877_263743"
          result="shape"
        />
      </filter>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlink:href="#image0_1877_263743" transform="scale(0.00714286)" />
      </pattern>
      <linearGradient
        id="paint0_linear_1877_263743"
        x1="94.1512"
        y1="137.844"
        x2="273.675"
        y2="270.606"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6C00FF" />
        <stop offset="0.360554" stop-color="#6C00FF" stop-opacity="0.4" />
        <stop offset="0.549476" stop-color="#6C00FF" stop-opacity="0.25" />
        <stop offset="0.8094" stop-color="#6C00FF" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1877_263743"
        x1="521.236"
        y1="141.374"
        x2="341.712"
        y2="8.61236"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6C00FF" />
        <stop offset="0.360554" stop-color="#6C00FF" stop-opacity="0.4" />
        <stop offset="0.549476" stop-color="#6C00FF" stop-opacity="0.25" />
        <stop offset="0.8094" stop-color="#6C00FF" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1877_263743"
        x1="213.544"
        y1="420.595"
        x2="34.0201"
        y2="287.833"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6C00FF" />
        <stop offset="0.360554" stop-color="#6C00FF" stop-opacity="0.4" />
        <stop offset="0.549476" stop-color="#6C00FF" stop-opacity="0.25" />
        <stop offset="0.8094" stop-color="#6C00FF" stop-opacity="0" />
      </linearGradient>
      <clipPath id="clip0_1877_263743">
        <rect width="500" height="820" rx="30" fill="white" />
      </clipPath>
      <clipPath id="clip1_1877_263743">
        <path
          d="M217 120C217 111.163 224.163 104 233 104H500V951C500 959.837 492.837 967 484 967H233C224.163 967 217 959.837 217 951V120Z"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip2_1877_263743">
        <rect width="249.606" height="223.054" fill="white" transform="translate(217 338.207)" />
      </clipPath>
      <clipPath id="clip3_1877_263743">
        <rect width="249.606" height="44.6108" fill="white" transform="translate(217 377.241)" />
      </clipPath>
      <clipPath id="clip4_1877_263743">
        <rect width="166.355" height="27.8818" fill="white" transform="translate(278.34 185.059)" />
      </clipPath>
      <image
        v-if="workspaceLogoUrl"
        id="image0_1877_263743"
        width="140"
        height="140"
        :xlink:href="this.workspaceLogoUrl"
      />
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    workspaceName: {
      type: String,
      default: 'My workspace',
      required: false
    },
    workspaceLogoUrl: {
      type: String,
      default: null,
      required: false
    }
  },
  computed: {
    getWorkspaceName() {
      return this.workspaceName.length === 0 ? 'My workspace' : this.workspaceName;
    },
    getWorkspaceInitial() {
      return this.workspaceName.length === 0
        ? 'M'
        : this.workspaceName.substring(0, 1).toUpperCase();
    }
  }
};
</script>
