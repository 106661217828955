
<div class="artist" v-if="artistDetails && !error">
  <div :class="['artist-edition', editTracksMode ? 'artist-edition-open' : '']">
    <div class="artist-edition-wrapper">
      <edit-tracks-panel
        v-if="editTracksMode"
        :libraryFileIds="libraryFileIds"
        :trackList="trackList"
        @toggleEditMode="toggleEditTracksMode"
      />
    </div>
  </div>
  <div class="artist-content" :style="editTracksMode && 'width: calc(100% - 518px + 16px);'">
    <div class="artist-header">
      <div class="artist-header-left">
        <img v-if="isUnknownArtist()" :src="artistImgUrl" />
        <image-browser
          v-if="!isUnknownArtist()"
          :removeButton="displayDeleteImage"
          :imgUrl="artistImgUrl"
          @changeImgUrl="openCropperModal"
          @removeImage="deleteArtistImage"
        />
      </div>
      <div class="artist-header-right">
        <div class="artist-header-right-txt">
          <div class="artist-header-right-txt-title">
            <editable-text
              :value="artistName"
              size="large"
              :fieldModel.sync="artistName"
              @validate="updateArtistName"
              v-if="!isUnknownArtist()"
            />
            <p v-else>{{ artistDetails.name }}</p>
          </div>
          <div class="artist-header-right-txt-count">
            <span v-if="!isUnknownArtist()">
              {{ `${$tc('albumCount', artistDetails.supports_number)} • ` }}
            </span>
            {{
              artistDetails.tracks_length
                ? `${getFormatDuration(artistDetails.tracks_length)} •`
                : ''
            }}
            {{ `${$tc('trackCount', artistDetails.tracks_number)}` }}
          </div>
        </div>
      </div>
    </div>
    <div class="artist-tabs">
      <tabs :tabs="tabs" :activeTab="activeTab" haveCount />
    </div>
    <router-view
      :keepProjectDropdownExpand="keepProjectDropdownExpand"
      :uncheckTracks="uncheckTracks"
      :trackList="trackList"
      :artistFileIds="artistFileIds"
      :editMode="editTracksMode"
      :artistDetails="artistDetails"
      :artistTracks="artistTracks"
      :entityIds="[this.$route.params.id]"
      :entityName="artistName"
      @createProject="createProject"
      @openConfirmDeleteModal="openConfirmDeleteModal"
      @toggleEditMode="toggleEditTracksMode"
    ></router-view>
  </div>
</div>
<div v-else class="loader">
  <spinner-without-progress color="grey" :size="32" />
</div>
