
<div class="list-knob">
  <div v-if="order && !isHover && !isLoading">
    {{ order }}
  </div>
  <div :class="getKnobClass" v-if="!order || (isHover && !isLoading)">
    <div v-for="(circle, i) in 6" :key="`circle-${i}`" class="knob--" />
  </div>
  <div class="loader" v-if="isLoading">
    <spinner color="grey" :size="18" />
  </div>
</div>
