import Vue from 'vue';
import Router from 'vue-router';
import get from 'lodash.get';

import { trackPage } from '@/utils/functions/analytics';
import { get as getRoute } from './routes';
import { getAuthTokenFromStorage } from '../utils/storage';
import Workspace from '../views/workspace';
import CreateWorkspace from '../views/workspace/createWorkspace';
import WorkspaceInvitation from '../views/workspace/workspaceInvitation';
import Main from '../views/main';
import Library from '../views/main/library';
import Tracks from '../views/main/library/tracks';
import TrackList from '../views/main/library/tracks/list';
import TrackSearch from '../views/main/library/tracks/search';
import Artists from '../views/main/library/artists';
import Artist from '../views/main/library/artist';
import ArtistAlbums from '../views/main/library/artist/albums';
import ArtistTracks from '../views/main/library/artist/tracks';
import ArtistSharings from '../views/main/library/artist/sharings';
import ArtistActivity from '../views/main/library/artist/activity';
import Albums from '../views/main/library/albums';
import Album from '../views/main/library/album';
import AlbumDetails from '../views/main/library/album/details';
import AlbumSharings from '../views/main/library/album/sharings';
import AlbumActivity from '../views/main/library/album/activity';
import Projects from '../views/main/projects';
import ProjectList from '../views/main/projects/projectList';
import ActiveProjects from '../views/main/projects/projectList/activeProjects';
import Project from '../views/main/projects/projectDetails';
import ProjectDetails from '../views/main/projects/projectDetails/details';
import Browse from '../views/main/projects/projectDetails/browse';
import BrowseList from '../views/main/projects/projectDetails/browse/list';
import BrowseSearch from '../views/main/projects/projectDetails/browse/search';
import ProjectSharings from '../views/main/projects/projectDetails/sharings';
import ProjectStatistics from '../views/main/projects/projectDetails/statistics';
import ProjectActivity from '../views/main/projects/projectDetails/activity';
import Shared from '../views/main/shared';
import SharedList from '../views/main/shared/list';
import SharedTracks from '../views/main/shared/page/tracks';
import SharedTracksDetails from '../views/main/shared/page/tracks/details';
import SharedTracksSharings from '../views/main/shared/page/tracks/sharings';
import SharedTracksActivity from '../views/main/shared/page/tracks/activity';
import SharedSubmission from '../views/main/shared/page/submission';
import SharedSubmissionDetails from '../views/main/shared/page/submission/details';
import SharedSubmissionActivity from '../views/main/shared/page/submission/activity';
import Settings from '../views/main/settings';
import AccountSettings from '../views/main/settings/account';
import GeneralSettings from '../views/main/settings/general';
import MembersSettings from '../views/main/settings/members';
import BillingSettings from '../views/main/settings/billing';
import OffersSettings from '../views/main/settings/billing/offers';
import PaymentSettings from '../views/main/settings/billing/payment';
import TagsSettings from '../views/main/settings/tags';
import { generateDocumentTitle } from '../utils/functions/global';

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior(to) {
    if (to.hash) {
      return {
        behavior: 'smooth',
        selector: to.hash
      };
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: getRoute('workspaceId'),
      component: Main,
      name: 'workspaceId'
    },
    {
      path: getRoute('main'),
      component: Main,
      name: 'main',
      redirect: { name: 'trackList' },
      children: [
        {
          path: getRoute('settings'),
          component: Settings,
          name: 'settings',
          redirect: { name: 'accountSettings' },
          meta: {
            title: generateDocumentTitle(['Settings'])
          },
          children: [
            {
              path: getRoute('accountSettings'),
              component: AccountSettings,
              name: 'accountSettings',
              meta: {
                forbidDragAndDrop: true,
                title: generateDocumentTitle(['Settings'])
              }
            },
            {
              path: getRoute('generalSettings'),
              component: GeneralSettings,
              name: 'generalSettings',
              meta: {
                forbidDragAndDrop: true,
                title: generateDocumentTitle(['Settings'])
              }
            },
            {
              path: getRoute('membersSettings'),
              component: MembersSettings,
              name: 'membersSettings',
              meta: {
                forbidDragAndDrop: true,
                title: generateDocumentTitle(['Settings'])
              }
            },
            {
              path: getRoute('billingSettings'),
              component: BillingSettings,
              name: 'billingSettings',
              meta: {
                forbidDragAndDrop: true,
                title: generateDocumentTitle(['Settings'])
              }
            },
            {
              path: getRoute('paymentSettings'),
              component: PaymentSettings,
              name: 'paymentSettings',
              meta: {
                forbidDragAndDrop: true,
                title: generateDocumentTitle(['Settings'])
              }
            },
            {
              path: getRoute('offersSettings'),
              component: OffersSettings,
              name: 'offersSettings',
              meta: {
                forbidDragAndDrop: true,
                title: generateDocumentTitle(['Settings'])
              }
            },
            {
              path: getRoute('tagsSettings'),
              component: TagsSettings,
              name: 'tagsSettings',
              meta: {
                forbidDragAndDrop: true,
                title: generateDocumentTitle(['Settings'])
              }
            }
          ]
        },
        {
          path: getRoute('library'),
          component: Library,
          name: 'library',
          redirect: { name: 'trackList' },
          children: [
            {
              path: getRoute('tracks'),
              component: Tracks,
              name: 'tracks',
              redirect: { name: 'trackList' },
              meta: {
                showTabs: true,
                showSearch: true,
                title: generateDocumentTitle(['Tracks'])
              },
              children: [
                {
                  path: getRoute('trackList'),
                  component: TrackList,
                  name: 'trackList',
                  meta: {
                    showTabs: true,
                    showSearch: true,
                    resetScrollPosition: true,
                    title: generateDocumentTitle(['Tracks'])
                  }
                },
                {
                  path: getRoute('trackSearch'),
                  component: TrackSearch,
                  name: 'trackSearch',
                  meta: {
                    showTabs: true,
                    showSearch: true,
                    resetScrollPosition: true,
                    title: generateDocumentTitle(['Tracks'])
                  }
                }
              ]
            },

            {
              path: getRoute('artists'),
              component: Artists,
              name: 'artists',
              meta: {
                showTabs: true,
                showSearch: true,
                resetScrollPosition: true,
                title: generateDocumentTitle(['Artists'])
              }
            },
            {
              path: getRoute('albums'),
              component: Albums,
              name: 'albums',
              meta: {
                showTabs: true,
                showSearch: true,
                resetScrollPosition: true,
                title: generateDocumentTitle(['Albums'])
              }
            },
            {
              path: getRoute('artist'),
              component: Artist,
              name: 'artist',
              redirect: { name: 'artistAlbums' },
              meta: {
                title: generateDocumentTitle()
              },
              children: [
                {
                  path: getRoute('artistAlbums'),
                  component: ArtistAlbums,
                  name: 'artistAlbums',
                  alias: getRoute('sharedArtistAlbums'),
                  meta: {
                    resetScrollPosition: true,
                    title: generateDocumentTitle()
                  }
                },
                {
                  path: getRoute('artistTracks'),
                  component: ArtistTracks,
                  name: 'artistTracks',
                  alias: getRoute('sharedArtistTracks'),
                  meta: {
                    resetScrollPosition: true,
                    title: generateDocumentTitle()
                  }
                },
                {
                  path: getRoute('artistSharings'),
                  component: ArtistSharings,
                  name: 'artistSharings',
                  alias: getRoute('sharedArtistSharings'),
                  meta: {
                    resetScrollPosition: true,
                    title: generateDocumentTitle()
                  }
                },
                {
                  path: getRoute('artistActivity'),
                  component: ArtistActivity,
                  name: 'artistActivity',
                  alias: getRoute('sharedArtistActivity'),
                  meta: {
                    resetScrollPosition: true,
                    title: generateDocumentTitle()
                  }
                }
              ]
            },
            {
              path: getRoute('album'),
              component: Album,
              name: 'album',
              alias: getRoute('sharedAlbum'),
              redirect: { name: 'albumDetails' },
              meta: {
                resetScrollPosition: true,
                title: generateDocumentTitle()
              },
              children: [
                {
                  path: getRoute('albumDetails'),
                  component: AlbumDetails,
                  name: 'albumDetails',
                  alias: getRoute('sharedAlbumDetails'),
                  meta: {
                    resetScrollPosition: true,
                    title: generateDocumentTitle()
                  }
                },
                {
                  path: getRoute('albumSharings'),
                  component: AlbumSharings,
                  name: 'albumSharings',
                  alias: getRoute('sharedAlbumSharings'),
                  meta: {
                    resetScrollPosition: true,
                    title: generateDocumentTitle()
                  }
                },
                {
                  path: getRoute('albumActivity'),
                  component: AlbumActivity,
                  name: 'albumActivity',
                  alias: getRoute('sharedAlbumActivity'),
                  meta: {
                    resetScrollPosition: true,
                    title: generateDocumentTitle()
                  }
                }
              ]
            }
          ]
        },
        {
          path: getRoute('projects'),
          component: Projects,
          redirect: { name: 'activeProjects' },
          children: [
            {
              path: getRoute('projects'),
              component: ProjectList,
              name: 'projects',
              redirect: { name: 'activeProjects' },
              children: [
                {
                  path: getRoute('activeProjects'),
                  meta: {
                    showSearch: true,
                    hideFilter: true,
                    title: generateDocumentTitle(['Projects'])
                  },
                  component: ActiveProjects,
                  name: 'activeProjects'
                },
                {
                  path: getRoute('archiveProjects'),
                  redirect: { name: 'activeProjects' }
                }
              ]
            },
            {
              path: getRoute('project'),
              component: Project,
              name: 'project',
              redirect: { name: 'projectDetails' },
              meta: {
                title: generateDocumentTitle()
              },
              children: [
                {
                  path: getRoute('projectDetails'),
                  component: ProjectDetails,
                  name: 'projectDetails',
                  alias: getRoute('sharedProjectDetails'),
                  meta: {
                    title: generateDocumentTitle()
                  }
                },
                {
                  path: getRoute('projectDetailsBrowse'),
                  component: Browse,
                  name: 'projectDetailsBrowse',
                  redirect: { name: 'projectDetailsBrowseList' },
                  children: [
                    {
                      path: getRoute('projectDetailsBrowseList'),
                      component: BrowseList,
                      name: 'projectDetailsBrowseList',
                      alias: getRoute('sharedProjectDetailsBrowseList')
                    },
                    {
                      path: getRoute('projectDetailsBrowseSearch'),
                      component: BrowseSearch,
                      name: 'projectDetailsBrowseSearch',
                      alias: getRoute('sharedProjectDetailsBrowseSearch')
                    }
                  ]
                },
                {
                  path: getRoute('projectSharings'),
                  component: ProjectSharings,
                  name: 'projectSharings',
                  alias: getRoute('sharedProjectSharings'),
                  meta: {
                    title: generateDocumentTitle()
                  }
                },
                {
                  path: getRoute('projectStatistics'),
                  component: ProjectStatistics,
                  name: 'projectStatistics'
                },
                {
                  path: getRoute('projectActivity'),
                  component: ProjectActivity,
                  name: 'projectActivity',
                  alias: getRoute('sharedProjectActivity'),
                  meta: {
                    title: generateDocumentTitle()
                  }
                }
              ]
            }
          ]
        },
        {
          path: getRoute('shared'),
          component: Shared,
          name: 'shared',
          redirect: { name: 'sharedList' },
          meta: {
            title: generateDocumentTitle(['Shared'])
          },
          children: [
            {
              path: getRoute('sharedList'),
              component: SharedList,
              name: 'sharedList',
              meta: {
                title: generateDocumentTitle(['Shared'])
              }
            },
            {
              path: getRoute('sharedTracks'),
              component: SharedTracks,
              name: 'sharedTracks',
              redirect: { name: 'sharedTracksDetails' },
              children: [
                {
                  path: getRoute('sharedTracksDetails'),
                  component: SharedTracksDetails,
                  name: 'sharedTracksDetails',
                  meta: {
                    resetScrollPosition: true
                  }
                },
                {
                  path: getRoute('sharedTracksSharings'),
                  component: SharedTracksSharings,
                  name: 'sharedTracksSharings',
                  meta: {
                    resetScrollPosition: true
                  }
                },
                {
                  path: getRoute('sharedTracksActivity'),
                  component: SharedTracksActivity,
                  name: 'sharedTracksActivity',
                  meta: {
                    resetScrollPosition: true
                  }
                }
              ]
            },
            {
              path: getRoute('sharedSubmission'),
              component: SharedSubmission,
              name: 'sharedSubmission',
              redirect: { name: 'sharedSubmissionDetails' },
              children: [
                {
                  path: getRoute('sharedSubmissionDetails'),
                  component: SharedSubmissionDetails,
                  name: 'sharedSubmissionDetails',
                  meta: {
                    resetScrollPosition: true
                  }
                },
                {
                  path: getRoute('sharedSubmissionActivity'),
                  component: SharedSubmissionActivity,
                  name: 'sharedSubmissionActivity',
                  meta: {
                    resetScrollPosition: true
                  }
                }
              ]
            }
          ]
        },
        {
          path: getRoute('inbox'),
          beforeEnter() {
            if (window.location.hostname.includes('localhost')) {
              window.location = '//localhost:4001/inbox';
            } else {
              window.location = '/inbox';
            }
          },
          meta: {
            title: generateDocumentTitle(['Received'])
          }
        }
      ]
    },
    {
      path: getRoute('workspace'),
      component: Workspace,
      redirect: { name: 'createWorkspace' },
      children: [
        {
          path: getRoute('createWorkspace'),
          component: CreateWorkspace,
          name: 'createWorkspace'
        },
        {
          path: getRoute('workspaceInvitation'),
          component: WorkspaceInvitation,
          name: 'workspaceInvitation'
        }
      ]
    },
    {
      path: getRoute('signup'),
      component: Workspace,
      redirect: { name: 'createWorkspaceFromSignup' },
      children: [
        {
          path: getRoute('createWorkspaceFromSignup'),
          component: CreateWorkspace,
          name: 'createWorkspaceFromSignup',
          meta: {
            fromSignup: true
          }
        },
        {
          path: getRoute('workspaceInvitationFromSignup'),
          component: WorkspaceInvitation,
          name: 'workspaceInvitationFromSignup',
          meta: {
            fromSignup: true
          }
        }
      ]
    }
  ]
});

router.afterEach(to => {
  const resetScrollPosition = get(to, 'meta.resetScrollPosition', false);
  if (resetScrollPosition) {
    const app = document.getElementsByClassName('main-view')[0];
    if (app) {
      app.scrollTo(0, 0);
    }
  }
});

router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that we have defined
  // See further down below for how we setup this data
  const { title } = to.meta;
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = title;
  }
  const token = getAuthTokenFromStorage(document.cookie);
  if (!token) {
    window.location.replace(`${getRoute('account')}?returnUrl=${window.location.href}`);
    return;
  }
  trackPage(to.name);
  if (window && window.Appcues && window.Appcues.page) {
    window.Appcues.page();
  }
  next();
});

Vue.use(Router);

export default router;
