
<div class="circle">
  <div class="circle-text">{{ percent }}%</div>
  <svg class="circle-svg" height="42" width="42">
    <circle
      class="circle-svg-fill"
      cx="21"
      cy="21"
      r="20"
      fill-opacity="0"
      :stroke-dashoffset="dashoffset"
    />
  </svg>
  <svg class="circle-svg" height="42" width="42" style="z-index: -1">
    <circle class="circle-svg-above" cx="21" cy="21" r="20" fill-opacity="0" />
  </svg>
</div>
