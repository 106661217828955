
<div class="tabs" :class="{ secondary: isSecondary }">
  <div
    class="tabs-tab"
    :class="{ active: noRoute ? noRouteCurrentTab === i : currentTab === i }"
    v-for="(tab, i) in tabs"
    :key="`tab-${i}`"
    :ref="`tab${i}`"
  >
    <router-link
      v-if="!noRoute"
      class="tabs-tab-link"
      :to="tab.link"
      @click.native="setCurrentTab(i)"
    >
      <div class="tabs-tab-link-container">
        <i v-if="tab.icon" :class="tab.icon" class="tabs-tab-link-container-icon"></i>
        {{ tab.title }}
        <div v-if="haveCount && tab.count" class="tabs-tab-link-container-count">
          {{ formatCount(tab.count) }}
        </div>
      </div>
    </router-link>

    <div
      v-if="noRoute"
      class="tabs-tab-link"
      :class="{ active: i === noRouteCurrentTab }"
      @click="changeTab(tab.title, i)"
    >
      <div class="tabs-tab-link-container">
        <i v-if="tab.icon" :class="tab.icon" class="tabs-tab-link-container-icon"></i>
        {{ tab.title }}
        <div v-if="haveCount && tab.count" class="tabs-tab-link-container-count">
          {{ formatCount(tab.count) }}
        </div>
      </div>
    </div>

    <div v-if="tab.notification" class="tabs-tab-link-notification"></div>
  </div>
  <div
    v-if="!isSecondary"
    class="tabs-indicator"
    :style="[initialIndicatorPos, indicatorPos]"
  ></div>
</div>
