<template>
  <div class="player">
    <div class="player-time-indicator-mobile" :style="{ width: currentIndicatorWidth + '%' }"></div>
    <div class="player-info">
      <div class="player-info-img-container">
        <img class="player-info-img" :src="tracks[currentTrackIndex].artwork" />
      </div>
      <div class="player-info-container text-ellipsis">
        <div
          ref="titleContainer"
          class="player-info-title"
          :class="{ 'too-long': isTooLong }"
          @mouseover="titleMouseOverHandler"
        >
          <div
            class="player-info-title--"
            ref="displayedTrackTitle"
            @animationend="titleAnimationEndHandler"
          >
            <div ref="titleText" class="player-info-title--text">
              {{ tracks[currentTrackIndex].title }}
            </div>
            <div v-if="isTooLong" class="title-duplicate">
              {{ tracks[currentTrackIndex].title }}
            </div>
          </div>
        </div>
        <div class="player-info-artistAlbum">
          <div
            class="player-info-artistAlbum-item"
            v-for="(artist, index) in tracks[currentTrackIndex].artists"
            :key="`artist-${index}`"
          >
            <div
              class="player-info-artistAlbum-item--"
              @click="navigateTo('artist', artist.id)"
              v-if="artist.name"
            >
              <div class="link">{{ artist.name }}</div>
              <div
                v-if="
                  showArtistSeparator(
                    index,
                    tracks[currentTrackIndex].artists,
                    tracks[currentTrackIndex].supports
                  )
                "
              >
                &nbsp;•&nbsp;
              </div>
            </div>
          </div>
          <div
            class="player-info-artistAlbum-item"
            v-for="(support, index) in tracks[currentTrackIndex].supports"
            :key="`support-${index}`"
          >
            <div
              class="player-info-artistAlbum-item--"
              @click="navigateTo('album', support.id)"
              v-if="support.name"
            >
              <div class="link">{{ support.name }}</div>
              <div v-if="showSupportSeparator(index, tracks[currentTrackIndex].supports)">
                &nbsp;•&nbsp;
              </div>
            </div>
          </div>
          <div v-if="typeof currentArtists === 'string' && typeof currentSupports === 'string'">
            {{ currentArtists }}
            <span v-if="currentArtists && currentSupports">&nbsp;•&nbsp;</span>
            {{ currentSupports }}
          </div>
        </div>
      </div>
    </div>
    <div class="player-controler">
      <div class="player-controler-control">
        <i class="ri-skip-back-mini-fill player-controler-control-skip" @click="handlePrevious" />
        <div class="player-loading" v-if="isLoading">
          <div class="player-loading-container">
            <spinner-without-progress color="white" :size="20" />
          </div>
        </div>
        <i
          v-else
          class="player-controler-control-play"
          :class="play && !isOnError ? 'ri-pause-circle-fill' : 'ri-play-circle-fill'"
          @click="togglePlay"
        />
        <i class="ri-skip-forward-mini-fill player-controler-control-skip" @click="handleNext" />
        <icon-button
          v-if="!isRepeatOne"
          icon="ri-repeat-2-fill"
          btnStyle="tertiary"
          class="player-controler-action-repeat"
          @click.native="repeatClickHandler"
          :isSelect="isRepeat"
          v-tooltip="{
            content: isRepeat ? 'Repeat all' : 'Repeat off'
          }"
          size="small"
        />
        <icon-button
          v-else
          icon="ri-repeat-one-fill"
          btnStyle="tertiary"
          class="player-controler-action-repeat"
          @click.native="repeatAllClickHandler"
          :isSelect="true"
          v-tooltip="'Repeat one'"
          size="small"
        />
      </div>
      <div class="player-controler-seekbar-timer-container">
        <div class="player-controler-timer-current">{{ currentTime }}</div>
        <div class="player-controler-seekbar-container">
          <div v-if="emptyWave" class="player-empty-wave"></div>
          <div v-show="!emptyWave" id="waveform" />
        </div>
        <div class="player-controler-timer-total">{{ duration }}</div>
      </div>
      <div class="player-controler-action">
        <div class="player-controler-action-volume-container">
          <icon-button
            v-if="!isVolumeMute"
            icon="ri-volume-up-line"
            btnStyle="tertiary"
            class="player-controler-action-volume"
            @click.native="handleVolumeIconClick"
            size="small"
          />
          <icon-button
            v-if="isVolumeMute"
            icon="ri-volume-mute-line"
            btnStyle="tertiary"
            class="player-controler-action-volume"
            @click.native="handleVolumeIconClick"
            size="small"
          />
          <div
            class="player-controler-action-volume-seeker"
            @mouseenter="handleVolumeMouseHover(true)"
            @mouseleave="handleVolumeMouseHover(false)"
          >
            <div class="player-controler-action-volume-seeker--">
              <volume-control
                :volume="volumeValue"
                :isHovered="isVolumeHover"
                @setVolume="audioVolumeHandler"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="player-controler-control-mobile">
      <i
        class="player-controler-control-play"
        :class="play ? 'ri-pause-circle-fill' : 'ri-play-circle-fill'"
        @click="togglePlay"
      />
    </div>
  </div>
</template>

<script>
import WaveSurfer from 'wavesurfer.js';
import { toHHMMSS } from '@/utils/functions/global';
import IconButton from '../iconButton';
import VolumeControl from '../volumeControl';

const PLAYER_VOLUME_KEY = 'player:volume';

export default {
  components: {
    IconButton,
    VolumeControl
  },
  props: {
    tracks: {
      type: Array,
      required: true
    },
    playingTrack: {
      type: Object,
      required: true
    },
    audioSrcIsLoaded: {
      type: Boolean,
      required: false,
      default: false
    },
    isPublicSharing: {
      type: Boolean,
      required: false,
      default: false
    },
    isOnError: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      waveform: null,
      emptyWave: true,
      isTooLong: false,
      currentTrackIndex: 0,
      repeat: 'none',
      volumeValueMemory: 1,
      volumeValue: 1,
      isVolumeHover: false,
      play: this.playingTrack.play,
      duration: '0:00',
      currentTime: '0:00',
      currentPercent: 0,
      isLoading: false,
      playTo: 0
    };
  },
  computed: {
    isRepeat() {
      if (this.repeat === 'none') {
        return false;
      }
      return true;
    },
    isRepeatAll() {
      if (this.repeat === 'all') {
        return true;
      }
      return false;
    },
    isRepeatOne() {
      if (this.repeat === 'one') {
        return true;
      }
      return false;
    },
    isVolumeMute() {
      return this.volumeValue === 0;
    },
    currentIndicatorWidth() {
      return this.currentPercent;
    },
    currentArtists() {
      return this.tracks[this.currentTrackIndex].artists;
    },
    currentSupports() {
      return this.tracks[this.currentTrackIndex].supports;
    }
  },
  watch: {
    volumeValue() {
      localStorage.setItem(PLAYER_VOLUME_KEY, this.volumeValue);
    },
    isTooLong(val) {
      if (!val) {
        this.$refs.displayedTrackTitle.classList.remove('horizontalScrollAnimation');
      }
    },
    /* eslint-disable */
    'playingTrack.play': function (value) {
      if (value.play !== this.play) {
        this.play = value;
        this.handlePlayPause();
      }
    },
    'playingTrack.id': function () {
      this.isLoading = true;
      this.playStartupTimer = new Date().getTime();
      this.currentTrackIndex = this.tracks.findIndex(track => track.id === this.playingTrack.id);
    },
    /* eslint-enable */
    audioSrcIsLoaded() {
      if (this.audioSrcIsLoaded) {
        this.handleNewAudio();
      }
    }
  },
  created() {
    window.addEventListener('resize', this.handleIsTooLong);
    document.body.addEventListener('keyup', this.handleKeyEvent);
  },
  mounted() {
    const savedVolume = parseFloat(localStorage.getItem(PLAYER_VOLUME_KEY));
    this.volumeValue = savedVolume >= 0 ? savedVolume : 1;
    this.initializePlayer();
    this.init();
  },
  updated() {
    this.init();
  },
  destroyed() {
    // To prevent emit message when set the playing track to null
    this.setPlayingTrack(null);
    this.waveform.pause();
    window.removeEventListener('resize', this.handleIsTooLong);
    document.body.removeEventListener('keyup', this.handleKeyEvent);
  },
  methods: {
    initializePlayer() {
      if (!this.waveform) {
        this.waveform = WaveSurfer.create({
          container: '#waveform',
          waveColor: '#EDE9F7',
          progressColor: '#2F43DE',
          height: 40,
          cursorWidth: 0,
          barWidth: 1,
          responsive: true,
          closeAudioContext: true,
          fillParent: true,
          backend: 'MediaElement'
        });
        /* this.waveform.init(); */
        if (navigator.mediaSession) {
          navigator.mediaSession.setActionHandler('seekbackward', null);
          navigator.mediaSession.setActionHandler('seekforward', null);
          navigator.mediaSession.setActionHandler('play', () => {
            this.waveform.play();
          });
          navigator.mediaSession.setActionHandler('pause', () => {
            this.waveform.pause();
          });
          /* navigator.mediaSession.setActionHandler('seekto', () => {  }); */
          navigator.mediaSession.setActionHandler('previoustrack', () => {
            this.handlePrevious();
          });
          navigator.mediaSession.setActionHandler('nexttrack', () => {
            this.handleNext();
          });
        }
        this.waveform.on('finish', () => {
          this.handleEndAudio();
        });
        this.waveform.on('waveform-ready', () => {
          this.currentTime = '0:00';
          // There is bug with getDuration on ready so we set duration on audioprocess event
          // this.duration = this.waveform.getDuration();
          this.waveform.play(this.playTo);
          this.setPlayTo(0);
          this.waveform.setVolume(this.volumeValue);
        });
        this.waveform.on('audioprocess', () => {
          // There is a bug with wavesurfer that getDuration returns NaN on ready event
          if (this.duration === null && !Number.isNaN(this.waveform.getDuration())) {
            this.duration = toHHMMSS(this.waveform.getDuration());
          }
          this.currentPercent =
            (this.waveform.getCurrentTime() * 100) / this.waveform.getDuration();
          if (this.waveform.getCurrentTime() > 0 && this.playerTrackingExecuted === false) {
            this.playerTrackingExecuted = true;
            const startTime = new Date().getTime() - this.playStartupTimer;
            this.isLoading = false;
            console.debug('player start time :', startTime); /* eslint-disable-line */
          }
          this.currentTime = toHHMMSS(this.waveform.getCurrentTime());
        });
        this.waveform.on('seek', () => {
          this.currentTime = toHHMMSS(this.waveform.getCurrentTime());
        });
      }
      this.handleNewAudio();
    },
    generateMediaSession() {
      const track = this.tracks[this.currentTrackIndex];
      return {
        title: track.title,
        artist:
          typeof track.artists === 'string'
            ? track.artists
            : (track.artists && track.artists.map(i => i.name).join(', ')) || '',
        album: track.album,
        artwork: [
          {
            src: track.largeArtwork ? track.largeArtwork : track.artwork,
            sizes: '300x300',
            type: 'image/jpeg'
          }
        ]
      };
    },
    handleNewAudio() {
      this.playerTrackingExecuted = false;
      const audioUrl = this.tracks[this.currentTrackIndex].audioSrc;
      const audioWaveform = this.tracks[this.currentTrackIndex].waveform;
      if (audioUrl && audioWaveform) {
        this.currentTime = null;
        this.duration = null;
        this.emptyWave = false;
        // Use waveform from API
        this.waveform.load(audioUrl, audioWaveform, 'none');
      } else if (audioUrl) {
        this.currentTime = null;
        this.duration = null;
        this.emptyWave = false;
        // Let wavesurfer generate the waveform
        this.waveform.load(audioUrl);
      }
      if (window && window.navigator && 'mediaSession' in window.navigator) {
        navigator.mediaSession.metadata = new window.MediaMetadata(this.generateMediaSession());
      }
    },
    handleKeyEvent(event) {
      if (event.keyCode === 32 && event.target === document.body) {
        event.preventDefault();
        this.togglePlay();
      }
    },
    togglePlay() {
      this.play = !this.play;
      this.setPlayingTrack(this.play);
    },
    setPlayingTrack(play) {
      // When the player is being destroyed we don't emit track change
      if (play !== null) {
        this.$emit('setPlayingTrack', {
          id: this.tracks[this.currentTrackIndex].id,
          isInbox: this.tracks[this.currentTrackIndex].isInbox,
          play
        });
      }
    },
    handlePlayPause() {
      if (this.audioSrcIsLoaded) {
        if (this.play) {
          this.waveform.play();
        } else {
          this.waveform.pause();
        }
      }
    },
    handleEndAudio() {
      if (this.isRepeatOne) {
        this.waveform.play(0);
      } else if (this.currentTrackIndex < this.tracks.length - 1) {
        this.handleNext();
      } else if (this.isRepeatAll) {
        this.handleNext();
      } else {
        this.setPlayingTrack(false);
      }
    },
    handleNext() {
      this.$refs.displayedTrackTitle.classList.remove('horizontalScrollAnimation');
      const currentTrackRaised = this.currentTrackIndex + 1;
      if (currentTrackRaised === this.tracks.length) {
        this.currentTrackIndex = 0;
      } else {
        this.currentTrackIndex += 1;
      }
      this.setPlayingTrack(true);
    },
    handlePrevious() {
      this.$refs.displayedTrackTitle.classList.remove('horizontalScrollAnimation');
      if (this.waveform.getCurrentTime() > 3) {
        this.waveform.play(0);
      } else {
        if (this.currentTrackIndex === 0) {
          this.currentTrackIndex = this.tracks.length - 1;
        } else {
          this.currentTrackIndex -= 1;
        }
        this.setPlayingTrack(true);
      }
    },
    titleMouseOverHandler() {
      if (this.isTooLong) {
        this.$refs.displayedTrackTitle.classList.add('horizontalScrollAnimation');
      }
    },
    titleAnimationEndHandler() {
      this.$refs.displayedTrackTitle.classList.remove('horizontalScrollAnimation');
    },
    repeatClickHandler() {
      if (this.repeat === 'none') {
        this.repeat = 'all';
      } else {
        this.repeat = 'one';
      }
    },
    repeatAllClickHandler() {
      this.repeat = 'none';
    },
    audioVolumeHandler(volume) {
      this.volumeValue = volume / 100;
      this.waveform.setVolume(this.volumeValue);
    },
    handleVolumeMouseHover(action) {
      this.isVolumeHover = action;
    },
    handleVolumeIconClick() {
      const currentVolume = this.waveform.getVolume();
      if (currentVolume !== 0) {
        this.volumeValueMemory = currentVolume;
        this.volumeValue = 0;
      } else {
        this.volumeValue = this.volumeValueMemory;
      }
      this.waveform.setVolume(this.volumeValue);
    },
    handleIsTooLong() {
      const container = this.$refs.titleContainer && this.$refs.titleContainer.offsetWidth;
      const title = this.$refs.titleText && this.$refs.titleText.offsetWidth;
      this.isTooLong = title > container;
    },
    init() {
      if (this.isOnError) {
        this.isLoading = false;
      }
      this.handleIsTooLong();
      const el = this.$refs.displayedTrackTitle;
      const animationDurations = el.offsetWidth / 50;
      this.$refs.displayedTrackTitle.style.animationDuration = `${animationDurations}s`;
    },
    downloadtrack() {
      this.$emit('downloadTrack', this.tracks[this.currentTrackIndex].id);
    },
    navigateTo(name, id) {
      const param = id ? { name, id } : { name };
      this.$emit('navigateTo', param);
    },
    showArtistSeparator(index, artists, supports) {
      let show = false;
      if (index < artists.length - 1) {
        if (artists[index + 1] && artists[index + 1].name) {
          show = true;
        }
        if (supports.find(support => support.name)) {
          show = true;
        }
      }
      if (index === artists.length - 1) {
        if (supports.find(support => support.name)) {
          show = true;
        }
      }
      return show;
    },
    showSupportSeparator(index, supports) {
      let show = false;
      if (
        index < supports.length - 1 &&
        supports[supports.length - 1] &&
        supports[supports.length - 1].name
      ) {
        show = true;
      }
      return show;
    },
    setWaveformToZero() {
      this.waveform.play(0);
    },
    setPlayTo(value) {
      // method called in parent when changing quality
      this.playTo = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.player {
  position: relative;
  box-sizing: border-box;
  height: 64px;
  width: 100%;
  padding: 0 10px 0 40px;
  background: $color_neutral_0;
  display: flex;
  justify-content: center;
  font-weight: 400;
  &-container {
    width: 100%;
    height: 100%;
    display: flex;
  }
  &-audio {
    display: none;
  }
  &-time-indicator-mobile {
    display: none;
  }
  &-info {
    height: 100%;
    display: flex;
    align-items: center;
    width: 17%;
    min-width: 222px;
    &-container {
      height: 60%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &-title {
      @include subtitle-1;
      line-height: normal;
      color: $color_neutral_100;
      overflow: hidden;
      white-space: nowrap;
      &-- {
        width: fit-content;
        width: -moz-fit-content;
        display: flex;
      }
    }
    &-artistAlbum {
      @include body-2;
      line-height: normal;
      color: $color_neutral_60;
      display: flex;
      max-width: 20vw;
      &-item {
        display: flex;

        &-- {
          display: flex;
        }
      }

      .link {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        border-bottom: 1px dotted $color_neutral_0;
        max-width: 150px;

        &:last-child {
          margin: 0;
        }

        &:hover {
          border-bottom: 1px dotted $color_neutral_100;
        }
      }
    }
    &-img {
      width: 48px;
      height: 48px;
      border-radius: 2px;
      &-container {
        margin-right: 16px;
        height: 48px;
      }
    }
  }
  &-controler {
    height: 100%;
    width: 71%;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    &-control {
      display: flex;
      align-items: center;
      margin-right: 16px;
      margin-left: 8px;
      &-skip {
        font-size: 20px;
        color: $color_neutral_100;
        cursor: pointer;
      }
      .player-loading {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        &-container {
          width: 40px;
          height: 40px;
          background-color: $color_neutral_100;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &-play {
        font-size: 48px;
        color: $color_neutral_100;
        cursor: pointer;
      }
      &-mobile {
        display: none;
      }
    }
    &-seekbar {
      width: 100%;
      transition: all ease 0.2s;
      &-timer-container {
        display: flex;
        align-items: center;
        width: 65%;
        margin-right: 24px;
      }
      &-container {
        width: 100%;
        min-width: 72px;
        margin-right: 8px;
        cursor: pointer;
      }
    }
    &-timer {
      &-current {
        font-size: 14px;
        color: $color_primary_100;
        font-variant-numeric: tabular-nums;
        margin-right: 8px;
        text-align: center;
        width: 60px;
      }
      &-total {
        font-size: 14px;
        color: $color_neutral_100;
        font-variant-numeric: tabular-nums;
        text-align: center;
        width: 60px;
      }
    }
    &-action {
      width: fit-content;
      width: -moz-fit-content;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: $color_neutral_100;
      &-volume {
        margin-right: 4px;
        &-container {
          position: relative;
          display: flex;
          align-items: center;
        }
      }
      &-repeat {
        margin-left: 8px;
      }
    }
  }
  &-empty-wave {
    height: 1px;
    background-color: $color_primary_10;
    width: 100%;
  }
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.too-long {
  position: relative;
  max-width: 22vw;
  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 10px;
    z-index: 1;
    bottom: 0;
    right: 0;
    pointer-events: none;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 59%, rgba(255, 255, 255, 1) 100%);
  }
}
.title-duplicate {
  padding-right: 10px;
  padding-left: 10px;
}
.horizontalScrollAnimation {
  animation: horizontalScroll linear;
  animation-iteration-count: 1;
}
@keyframes horizontalScroll {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-50%);
  }
}

@media (max-width: 790px) {
  .player {
    padding: 0 16px;
    &-time-indicator-mobile {
      display: unset;
      position: absolute;
      height: 1px;
      background-color: $color_primary_100;
      left: 0;
    }
    &-info {
      min-width: 110px;
      margin-right: 16px;
      flex-basis: 80%;
    }
    &-controler {
      flex-basis: 20%;
      &-seekbar-container {
        display: none;
      }
      &-timer {
        width: fit-content;
        &-container {
          margin-right: 16px;
        }
        &-total {
          display: none;
        }
      }
      &-action {
        display: none;
      }
      &-control {
        display: none;
        &-mobile {
          display: flex;
          align-items: center;
        }
      }
    }
    &-action {
      display: none;
    }
  }
}
</style>
