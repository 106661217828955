
<div class="information-banner">
  <div class="icon-info"><i class="ri-information-fill"></i></div>
  <div class="text">
    Ai Auto-Tag is now live and activated by default. You can disable it in your
    <router-link :to="getRoute('tagsSettings')" class="text-link">
      workspace settings
    </router-link>
  </div>
  <icon-button
    class="icon-close-mail"
    btnStyle="tertiary"
    icon="ri-close-fill"
    size="small"
    @submit="hide"
  />
</div>
