<template>
  <div>
    <header-list
      :columns="columns"
      :headerCountLabel="headerCountLabel"
      :hasSelectAll="true"
      :itemsSelected="[]"
      :sortColumn="false"
      :displayUnselect="selectedTracks.length > 0"
      isSticky
      @handleEmit="handleEmit"
      @uncheckAllCheckbox="uncheckAllCheckbox"
      @selectAll="checkAllCheckbox"
    />
    <div v-for="(track, idx) in trackList" :key="track.id">
      <list-row
        :item="track"
        :columns="columns"
        :rowIndex="idx"
        :selectedItems="selectedTracks"
        :height="48"
        :id="track.id"
        :playingTrack="playingTrack || {}"
        @handleEmit="handleEmit"
      />
    </div>
    <div v-if="isFetchingMore">
      <skeleton-row :columns="columns" :height="48" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { get as getRoute } from '@/router/routes';
import { formatNumber } from '@/utils/functions/global';

export default {
  props: {
    trackList: {
      type: Array,
      required: false,
      default: () => []
    },
    isFetchingMore: {
      type: Boolean,
      required: false,
      default: false
    },
    uncheckTracks: {
      type: Boolean,
      required: false,
      default: false
    },
    hasSelectAll: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      selectedTracks: [],
      isHoverId: null
    };
  },
  computed: {
    ...mapGetters(['playlist', 'playingTrack']),
    columns() {
      return [
        {
          type: 'component',
          name: 'checkbox',
          path: 'checkbox',
          ref: 'checkbox',
          preventDefault: true,
          header: null,
          min: '28px',
          max: '28px',
          noBorder: true,
          isHover: true,
          events: ['toggleCheckbox', 'handleCheckboxShiftClick'],
          props: [
            {
              name: 'id',
              match: 'id',
              value: null
            },
            {
              name: 'index',
              match: 'index',
              value: null
            },
            {
              name: 'selectedItems',
              match: 'selectedTracks',
              value: this.selectedTracks
            }
          ]
        },
        {
          type: 'component',
          name: 'text-action',
          header: this.$t('track'),
          skeleton: 'line',
          min: '200px',
          max: '2fr',
          padding: [0, 8, 0, 8],
          noStop: true,
          containerWidth: true,
          emits: ['setPlayingTrack'],
          props: [
            {
              name: 'rowId',
              match: 'id',
              value: null
            },
            {
              name: 'isHoverId',
              match: 'isHoverId',
              value: this.isHoverId
            },
            {
              name: 'text',
              match: 'title',
              value: null
            },
            {
              name: 'artwork',
              match: 'imgSrc',
              value: null
            },
            {
              name: 'playingTrack',
              match: 'playingTrack',
              value: this.playingTrack || {}
            },
            {
              name: 'weightNormal',
              match: 'weightNormal',
              value: true
            }
          ]
        },
        {
          type: 'text',
          name: 'artists',
          header: this.$t('artist'),
          skeleton: 'line',
          min: '150px',
          max: '1fr',
          list: true,
          route: { name: 'artist', id: 'id' },
          padding: [0, 8, 0, 8]
        },
        {
          type: 'text',
          name: 'supports',
          header: this.$t('album'),
          skeleton: 'line',
          min: '150px',
          max: '1fr',
          list: true,
          route: { name: 'album', id: 'id' },
          padding: [0, 8, 0, 8]
        },
        {
          type: 'text',
          name: 'humanDuration',
          noTitle: true,
          headerPrefixIcon: 'ri-time-line',
          skeleton: 'line',
          min: '80px',
          max: '80px',
          padding: [0, 8, 0, 8]
        },
        {
          type: 'component',
          name: 'starsRating',
          path: 'starsRating',
          header: this.$t('rating'),
          skeleton: 'line',
          min: '130px',
          max: '130px',
          padding: [0, 8, 0, 8],
          props: [
            {
              name: 'disabled',
              match: 'disabled',
              value: true
            },
            {
              name: 'itemRating',
              match: 'rating',
              value: null
            }
          ]
        },
        {
          type: 'text',
          name: 'added',
          header: this.$t('added'),
          skeleton: 'line',
          min: '115px',
          max: '115px',
          padding: [0, 8, 0, 8]
        }
      ];
    },
    headerCountLabel() {
      const formatedNumber = formatNumber(this.selectedTracks && this.selectedTracks.length);
      return `${formatedNumber} ${this.$tc('trackSelected', this.selectedTracks.length)}`;
    }
  },
  created() {
    if (Object.keys(this.playlist).length === 0 && this.trackList.length > 0) {
      this.$store.commit('changePlaylist', this.trackList);
    }
  },
  methods: {
    handleEmit(params) {
      if (this[params.methodName]) this[params.methodName](params.param);
    },
    setIsHoverId(trackId) {
      this.isHoverId = trackId;
    },
    setPlayingTrack(track) {
      this.$store.commit('changePlaylist', this.trackList);
      this.$store.commit('changePlayingTrack', track);
    },
    setSelectedItems(param) {
      const { elementUID, checkbox, rowIndex } = param;
      this.fromTo = [];
      this.fromTo.push(rowIndex || 0);
      if (this.selectedTracks.includes(elementUID)) {
        const idx = this.selectedTracks.findIndex(id => id === elementUID);
        this.selectedTracks.splice(idx, 1);
        if (!checkbox) {
          this.selectedTracks = this.selectedTracks.length > 1 ? [elementUID] : [];
        }
      } else {
        this.selectedTracks.push(elementUID);
        if (!checkbox) {
          this.selectedTracks = [elementUID];
        }
      }
      this.$emit('setSelectedTracks', this.selectedTracks);
    },
    shiftSelectItems(param) {
      const { elementUID, rowIndex } = param;
      this.fromTo.splice(1, 1, rowIndex);
      if (this.selectedTracks.includes(elementUID)) {
        this.selectedTracks.splice(this.selectedTracks.indexOf(elementUID), 1);
        return;
      }
      if (this.fromTo.length === 2) {
        this.fromTo.sort((a, b) => a - b);
        for (let i = this.fromTo[0]; i <= this.fromTo[1]; i += 1) {
          if (this.trackList[i] && !this.selectedTracks.includes(this.trackList[i].id)) {
            this.selectedTracks.push(this.trackList[i].id);
          }
        }
      } else if (this.selectedTracks.includes(elementUID)) {
        this.selectedTracks.splice(this.selectedTracks.indexOf(elementUID), 1);
      } else {
        this.selectedTracks.push(elementUID);
      }
      this.$emit('setSelectedTracks', this.selectedTracks);
    },
    navigateTo(param) {
      const { id, name } = param;
      const route = id ? getRoute(name, { id }) : getRoute(name);
      if (this.$router.history.current.name !== name) this.$router.push(route);
    },
    uncheckAllCheckbox() {
      this.selectedTracks = [];
      this.$emit('setSelectedTracks', this.selectedTracks);
    },
    checkAllCheckbox() {
      const allItemsIds = this.trackList.map(el => el.id);
      this.selectedTracks = allItemsIds;
      this.$emit('setSelectedTracks', this.selectedTracks);
    }
  }
};
</script>
