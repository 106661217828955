import get from 'lodash.get';

export const getSubscription = workspace => get(workspace, 'subscription');

export const getPlan = workspace => get(workspace, 'subscription.plan');

export const getPlanName = workspace => get(workspace, 'subscription.plan.name').toLowerCase();

export const getMembersSeats = workspace => get(workspace, 'seats_used');

export const getMaximumMembersSeats = workspace => get(workspace, 'subscription.seats');

export const getUsedStorage = workspace => get(workspace, 'space_used');

export const getStorageInGB = workspace => get(workspace, 'space_used') / 1000000;

export const getMaximumStorage = workspace => get(workspace, 'subscription.storage');

export const getTotalTracks = workspace => get(workspace, 'total_tracks');

export const getTracksLeft = workspace => get(workspace, 'tracks_left');

export const isWorkspaceFull = workspace => {
  if (getTracksLeft(workspace) <= 0 || getStorageInGB(workspace) >= getMaximumStorage(workspace)) {
    return true;
  }
  return false;
};

export const isWorkspaceAlmostFull = workspace => {
  const totalTrackCount = getTotalTracks(workspace) + getTracksLeft(workspace);
  const maximumTrackStorage = getMaximumStorage(workspace);
  const tracksCount = getTotalTracks(workspace);
  const storageInGB = getStorageInGB(workspace);
  if (
    (tracksCount >= (totalTrackCount * 95) / 100 && tracksCount < totalTrackCount) ||
    (storageInGB >= (maximumTrackStorage * 95) / 100 && storageInGB < maximumTrackStorage)
  ) {
    return true;
  }
  return false;
};

export const getMembers = workspace => get(workspace, 'members');

export const getMemberRole = (workspace, me) => {
  const member = get(workspace, 'members', []).find(m => m.user_id === me.id);
  if (member && member.role && member.role.name) {
    return member.role.name;
  }
  return undefined;
};
