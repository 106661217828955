<template>
  <div class="container">
    <div class="icon"><i class="ri-folder-fill"></i></div>
    <div class="name" :class="[{ underline: isHover }]" :title="name">
      {{ name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    projectId: {
      type: String,
      required: true
    },
    isHoverId: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    isHover() {
      return this.isHoverId === this.projectId;
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  @include body-1;
  font-weight: 600;
  width: 100%;
}
.icon {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  margin-right: 12px;
  background-color: $color_secondary_10;
  color: $color_secondary_100;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.name {
  white-space: nowrap;
  width: calc(100% - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
}
.underline {
  color: $color_neutral_80;
  text-decoration: underline;
}
</style>
