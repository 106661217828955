
<div class="radio-button-container">
  <div
    v-for="(option, index) in options"
    class="radio-button-option"
    :key="`option.value-${index}`"
    @click="() => handleClick(option)"
  >
    <label :for="option.value" class="radio-button-label">
      {{ option.label }}
    </label>
    <input
      class="radio-button"
      :id="option.value"
      name="option"
      type="radio"
      :value="option.value"
      v-model="selected"
      @change.stop="handleClick(option)"
    />
  </div>
</div>
