
<dropdown ref="dropdown">
  <template #button="slotProps">
    <div :class="{ icon: true, opened: slotProps.isExpanded }" @click="loadData">
      <i class="ri-notification-3-fill" />
      <span v-show="getNotificationCount() && !slotProps.isExpanded" class="icon-badge">
        {{ getNotificationCount() }}
      </span>
    </div>
  </template>
  <div class="notifications">
    <div class="header">
      <span>{{ $t('notifications.header') }}</span>
      <i
        class="ri-checkbox-circle-line dismiss-all"
        @click="markAllAsRead"
        v-tooltip="$t('markAllAsRead')"
      ></i>
    </div>
    <ul v-if="notifications && notifications.length" class="notification-list">
      <li
        class="notification-item"
        :class="{ 'notification-item-unread': !item.read }"
        v-for="(item, index) in notifications"
        :key="item.ids[0]"
        @click="goToEntityPage(index)"
      >
        <div class="dot read-dot" v-if="!item.read" @click.stop="markAsRead(index)" />
        <i class="ri-voiceprint-line notification-icon"></i>
        <div class="notification-info">
          <p class="info-entity">
            <span>{{ $t('notifications.message') }} </span>
            <span>{{ item.typeDisplayed }} </span>
            <span class="entity-name">{{ item.shareName }}</span>
          </p>
          <div v-if="item.shareCustomName">
            <p class="info-entity">
              <span>{{ ` ${$t('notifications.from')} ` }}</span>
              <span class="entity-name">{{ item.shareCustomName }}</span>
            </p>
          </div>
          <p class="info-time">{{ item.time }}</p>
        </div>
      </li>
    </ul>
    <div v-else class="notifications-empty">
      <i class="ri-notification-3-fill bell-icon"></i>
      <div class="notifications-empty-msg">
        <div class="notifications-empty-title">{{ $t('notifications.empty') }}</div>
        <div>{{ $t('notifications.emptySubtext') }}</div>
      </div>
    </div>
  </div>
</dropdown>
