
<div class="container">
  <text-field
    class="mr-2 flex-grow"
    inputType="text"
    :errorMessage="mailError"
    :fieldModel.sync="$v.mail.$model"
    :value="$v.mail.$model"
    :placeholder="$t('newEmail')"
    :large="false"
  />
  <text-field
    class="mr-4"
    withVisibility
    inputType="password"
    :errorMessage="null"
    :fieldModel.sync="password"
    :placeholder="$t('password')"
    :large="false"
  />
  <submit-button
    :disabled="disableSave"
    class="mr-1"
    btnStyle="primary"
    size="medium"
    label="Update"
    @submit="handleMailChange"
  />
  <submit-button btnStyle="secondary" size="medium" :label="$t('cancel')" @submit="cancel" />
</div>
