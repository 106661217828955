<template>
  <canvas id="canvas" :width="canvasWidth" height="58" :aria-label="content" role="img">
    <p>{{ content }}</p>
  </canvas>
</template>

<script>
const DEFAULT_TEXT_HEIGHT = 58;
export default {
  props: {
    content: {
      type: String,
      required: false,
      default: 'text'
    },
    color: {
      type: Object,
      required: false,
      default: () => {
        return {
          stroke: '#2F43DE',
          fill: '#E0E3FA'
        };
      }
    }
  },
  data() {
    return {
      canvasWidth: 300
    };
  },
  mounted() {
    // waiting for font to load
    setTimeout(() => {
      this.draw();
    }, 50);
  },
  updated() {
    this.draw();
  },
  methods: {
    draw() {
      const txt = this.content.trim();
      const canvas = document.getElementById('canvas');
      canvas.width = this.canvasWidth * 2;
      canvas.height = DEFAULT_TEXT_HEIGHT * 2;
      canvas.style.width = `${this.canvasWidth}px`;
      canvas.style.height = `${DEFAULT_TEXT_HEIGHT}px`;
      const ctx = canvas.getContext('2d');
      ctx.scale(2, 2);
      const diametre = 25;
      const textPadding = 20;
      const borderWidth = 5;
      /* Initial Canvas config */
      ctx.strokeStyle = this.color.stroke;
      ctx.fillStyle = this.color.fill;
      ctx.font = 'bold 24px manrope';
      ctx.lineWidth = borderWidth;
      // TBD adjust position and size value according to font size
      const textWidth = Math.ceil(ctx.measureText(txt).width);
      this.canvasWidth = 2 * textPadding + textWidth;
      const numberOfCircle = Math.floor((this.canvasWidth - 2 * borderWidth) / diametre);
      const spaceBetweenCircles = (this.canvasWidth - 2 * borderWidth) / numberOfCircle;
      for (let i = 0; i < numberOfCircle - 1; i += 1) {
        const arcX = borderWidth + diametre + i * spaceBetweenCircles;
        const arcY = borderWidth + diametre;
        ctx.arc(arcX, arcY, diametre, 0, 2 * Math.PI, false);
        ctx.stroke();
        ctx.fill();
      }
      ctx.beginPath();
      ctx.fillStyle = this.color.stroke;
      ctx.fillText(txt, textPadding, 38);
      ctx.fill();
    }
  }
};
</script>
