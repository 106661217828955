
<dropdown ref="dropdown" dismissOnClick @setIsDropdownExpanded="setIsDropdownExpanded">
  <template #button>
    <submit-button
      class="project-cover-btns--"
      :btnStyle="btnProps.style"
      :size="btnProps.size"
      :label="$t(btnProps.label)"
      :iconClass="btnProps.icon"
      :isExpanded="isExpanded"
      :disabled="btnProps.disabled"
      :primaryException="btnProps.primaryException"
      :isDropdown="!btnProps.hideDropdown"
    />
  </template>
  <div class="menu">
    <div
      :class="[
        option.separator ? 'separator' : 'menu-item',
        option.subText ? 'menu-item-with-sub' : ''
      ]"
      v-for="(option, index) in options"
      :key="index"
      @click="handleOptionClick(option)"
    >
      <div class="item" v-if="!option.separator">
        <i :class="option.icon"></i>
        <span class="menu-item-label">
          {{ option.label }}
          <span v-if="option.info" class="info">{{ option.info }}</span>
          <span v-if="option.isNew" class="menu-item-label-new">
            <img src="/images/new-badge.svg" alt="new badge" />
          </span>
        </span>
      </div>
      <div v-if="option.subText" class="menu-item-sub">{{ option.subText }}</div>
    </div>
  </div>
</dropdown>
