<template>
  <div class="library">
    <div class="library-content">
      <router-view :albumSort="albumSort" @sortSearchTracks="setSearchSortTracks"></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { LIBRARY_TRACKS_UPDATE } from '@/graphql/mutations/library';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import { PAGINATOR_LIMIT } from '@/utils/constants';
import { bus } from '@/utils/bus';
import { get as getRoute } from '@/router/routes';
import { SEARCH_TRACKS } from '@/graphql/queries/search';
import clonedeep from 'lodash.clonedeep';

export default {
  data() {
    return {
      searchSort: {
        sortBy: 'desc',
        orderBy: 'added_at'
      }
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'searchLibraryQueryParameters', 'albumSort']),
    activeTab() {
      const active = this.tabs.find(tab => tab.name === this.$route.name);
      return this.tabs.indexOf(active);
    },
    tabs() {
      let totalTracks = this.currentWorkspace.total_tracks || 0;
      if (this.searchLibraryQueryParameters) totalTracks = this.currentWorkspace.searchCount || 0;
      const totalAlbums = this.currentWorkspace.total_supports || 0;
      const totalArtists = this.currentWorkspace.total_artists || 0;
      const searchTab = {
        title: this.$t('tracks'),
        link: getRoute('trackSearch'),
        name: 'trackSearch',
        count: totalTracks
      };
      const tracksTab = {
        title: this.$t('tracks'),
        link: getRoute('trackList'),
        name: 'trackList',
        count: totalTracks
      };
      const artistsTab = {
        title: this.$t('artists'),
        link: getRoute('artists'),
        name: 'artists',
        count: totalArtists
      };
      const albumsTab = {
        title: this.$t('albums'),
        link: getRoute('albums'),
        name: 'albums',
        count: totalAlbums
      };
      return this.searchLibraryQueryParameters ? [searchTab] : [tracksTab, artistsTab, albumsTab];
    }
  },
  created() {
    bus.$on('setTrackRating', params => {
      this.setTrackRating(params);
    });
  },
  destroyed() {
    bus.$off('setTrackRating');
  },
  methods: {
    setSearchSortTracks(sort) {
      this.searchSort = sort;
    },
    setTrackRating(input) {
      this.$apollo
        .mutate({
          mutation: LIBRARY_TRACKS_UPDATE,
          variables: {
            input
          },
          update: (store, response) => {
            if (this.$route.name === 'trackSearch') {
              const trackToUpdate = response.data.LibraryTracksUpdate.data[0];
              const searchVariables = {
                ...this.searchLibraryQueryParameters,
                version: 2,
                offset: 0,
                limit: PAGINATOR_LIMIT,
                sort: {
                  by: this.searchSort.orderBy,
                  dir: this.searchSort.sortBy
                }
              };
              const searchTracks = store.readQuery({
                query: SEARCH_TRACKS,
                key: 'SearchTracks',
                variables: searchVariables,
                pagination: 'offset'
              });
              const cloneTracks = clonedeep(searchTracks);
              cloneTracks.SearchTracks.data.tracks.find(el => el.id === trackToUpdate.id).rating =
                trackToUpdate.rating;
              store.writeQuery({
                query: SEARCH_TRACKS,
                data: cloneTracks,
                variables: searchVariables
              });
            }
          }
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.library {
  color: $color_neutral_100;
  font-size: 14px;

  &-content {
    background-color: $color_neutral_0;
  }
}
.separator {
  @include shadow-down-02;
}
</style>

<style>
.tabs.component-tabs.secondary {
  justify-content: flex-end;
}
</style>
