
<div class="modal-cropper">
  <image-cropper
    :blob="blob"
    :submitButtonLabel="$t('save')"
    :cancelButtonLabel="$t('cancel')"
    :imageWidth="imageMaxWidth"
    :imageHeight="imageMaxHeight"
    :isSquare="isSquare"
    @cropImage="cropImage"
  />
</div>
