<template>
  <div>
    <slot
      :items="items"
      :showObserver="showObserver"
      :isLoading="!values"
      :fetchingMoreItems="fetchingMoreItems"
      :fetchMoreItems="fetchMoreItems"
      :search="search"
      :fetch="fetch"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import { PAGINATOR_LIMIT } from '@/utils/constants';

export default {
  props: {
    dataLoaded: {
      type: Boolean,
      required: false,
      default: false
    },
    storeEvent: {
      type: String,
      required: true
    },
    dataType: {
      type: String,
      required: true
    },
    ignoreUnknown: {
      type: Boolean,
      required: false,
      default: false
    },
    queries: {
      items: {
        type: String,
        required: true
      },
      itemVariables: {
        type: Object,
        required: true
      },
      itemKey: {
        type: String,
        required: true
      },
      search: {
        type: String,
        required: true
      },
      searchKey: {
        type: String,
        required: true
      }
    }
  },
  data() {
    return {
      page: 1,
      limit: PAGINATOR_LIMIT,
      fetchingMoreItems: false,
      searchResult: null,
      searchTerm: '',
      isSearching: false
    };
  },
  apollo: {
    values: {
      query() {
        return this.queries.items;
      },
      fetchPolicy: 'cache-and-network',
      variables() {
        return this.queries.itemVariables;
      },
      watchLoading(loading) {
        this.fetchingMoreItems = loading;
      },
      update(data) {
        // Permet de faire le mapping GraphQL selon le type en paramètre
        return data[this.queries.itemKey];
      },
      skip() {
        return this.dataLoaded === false;
      }
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    items() {
      if (this.searchResult && this.searchTerm.length >= 1) {
        return this.searchResult.map(item => ({ value: item.name, id: item.id }));
      }
      const result = this.values
        ? this.values.data.map(item => ({ value: item.name, id: item.id }))
        : [];
      if (this.ignoreUnknown) {
        return result.filter(item => item.id !== 'Unknown');
      }
      return result;
    },
    showObserver() {
      return this.values && this.page < this.values.last_page && !this.fetchingMoreItems;
    }
  },
  methods: {
    fetch() {
      if (!this.dataLoaded) {
        this.$store.commit(this.storeEvent, true);
      }
      this.page = this.values ? Math.ceil(this.values.length / this.limit) : 1;
    },
    search: debounce(function executeSearch(name) {
      this.searchTerm = name;
      if (this.searchTerm.length >= 1 && !this.isSearching) {
        this.isSearching = true;
        this.$apollo
          .mutate({
            mutation: this.queries.search,
            variables: {
              workspaceId: this.currentWorkspace.id,
              libraryId: this.currentWorkspace.libraries[0].id,
              search: name,
              limit: 10
            }
          })
          .then(response => {
            this.searchResult = response.data[this.dataType];
            this.isSearching = false;
          });
      }
    }, 300),
    async fetchMoreItems() {
      this.page += 1;
      try {
        await this.$apollo.queries.values.fetchMore({
          variables: {
            workspaceId: this.currentWorkspace.id,
            libraryId: this.currentWorkspace.libraries[0].id,
            page: this.page,
            limit: this.limit,
            orderBy: 'name',
            sortBy: 'asc'
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newValues = fetchMoreResult[this.queries.itemKey].data;
            if (newValues.length === 0) {
              return {
                [this.queries.itemKey]: {
                  ...previousResult[this.queries.itemKey],
                  data: [...previousResult[this.queries.itemKey].data]
                }
              };
            }
            return {
              [this.queries.itemKey]: {
                ...previousResult[this.queries.itemKey],
                data: [...previousResult[this.queries.itemKey].data, ...newValues]
              }
            };
          }
        });
      } catch {} // eslint-disable-line
    }
  }
};
</script>
