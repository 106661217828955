<template>
  <div class="image-container">
    <img
      :src="setImageUrl()"
      alt="activityImg"
      v-if="activityId"
      loading="lazy"
      onload="this.style.opacity=1"
    />
  </div>
</template>

<script>
export default {
  props: {
    activityId: {
      required: false,
      default: null
    }
  },
  methods: {
    setImageUrl() {
      let imgUrl;
      switch (this.activityId) {
        case 10:
        case 14:
        case 26:
          imgUrl = '/images/activities/pic-Advertising Promotion PR@2x.png';
          break;
        case 5:
        case 13:
        case 15:
        case 16:
          imgUrl = '/images/activities/pic-Composer DJ Artist Band Other@2x.png';
          break;
        case 1:
        case 6:
        case 7:
        case 17:
        case 19:
          imgUrl =
            '/images/activities/pic-Label Music Library Music Supervision Publishing Sync Broker@2x.png';
          break;
        case 2:
        case 4:
        case 20:
          imgUrl = '/images/activities/pic-Management A&R Rights Management@2x.png';
          break;
        case 8:
        case 18:
        case 21:
        case 23:
          imgUrl = '/images/activities/pic-Podcast Voice Over Sound Design Radio@2x.png';
          break;
        case 22:
        case 24:
          imgUrl = '/images/activities/pic-Sound Engineer Production@2x.png';
          break;
        case 9:
        case 11:
        case 12:
          imgUrl = '/images/activities/pic-Tour Booking Venue Event Journalist@2x.png';
          break;
        default:
          imgUrl = null;
      }
      return imgUrl;
    }
  }
};
</script>

<style lang="scss" scoped>
.image-container {
  width: 500px;
  height: 820px;
  background: $color_primary_10;
  border-radius: 25px;
  img {
    opacity: 0;
    transition: all 2s ease;
    max-width: 100%;
    height: auto;
    border-radius: 25px;
  }
}
</style>
