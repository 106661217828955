<template>
  <div
    :class="[btnStyle, size, 'cancelable-btn']"
    v-if="cancelable && pending"
    @mouseenter="setIsHover(true)"
    @mouseleave="setIsHover(false)"
    @click="$emit('cancel')"
  >
    <div class="cancelable-icon">
      <i v-if="isHover" class="ri-close-line"></i>
      <i v-else class="btn-icon ri-loader-4-line loader" />
    </div>
  </div>
  <button :class="btnClass" type="button" :disabled="disabled" @click="handleClick" v-else>
    <div :class="iconPosition">
      <img v-if="iconA2mb" src="/images/bridge-add.svg" style="width: 16px; margin-right: 6px" />
      <i v-if="iconClass && !pending && !iconA2mb" :class="`btn-icon ${iconClass}`" />
      <i v-if="pending" class="btn-icon ri-loader-4-line loader" />
      <span>{{ label }}</span>
      <i
        v-if="isDropdown"
        :class="{
          'btn-arrow': true,
          'ri-arrow-drop-up-fill': isExpanded,
          'ri-arrow-drop-down-fill': !isExpanded
        }"
      />
    </div>
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Submit',
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    pending: {
      type: Boolean,
      default: false,
      required: false
    },
    btnStyle: {
      type: String,
      default: 'primary',
      required: false
    },
    exception: {
      type: Boolean,
      required: false
    },
    primaryException: {
      type: Boolean,
      required: false
    },
    size: {
      type: String,
      default: 'medium',
      required: false
    },
    iconClass: {
      type: String,
      required: false,
      default: undefined
    },
    iconPosition: {
      type: String,
      default: 'prefix',
      required: false
    },
    browse: {
      type: Boolean,
      required: false,
      default: false
    },
    multiple: {
      type: Boolean,
      required: false
    },
    accept: {
      type: String,
      required: false,
      default: undefined
    },
    danger: {
      type: Boolean,
      required: false
    },
    isDropdown: {
      type: Boolean,
      required: false,
      default: false
    },
    customEvent: {
      type: String,
      required: false,
      default: 'submit'
    },
    isExpanded: {
      type: Boolean,
      required: false,
      default: false
    },
    cancelable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isHover: false,
      fileSelectorId: 0
    };
  },
  computed: {
    btnClass() {
      const noIcon = this.iconClass ? '' : ' no-icon';
      const pending = this.pending ? ' pending' : '';
      const disabled = this.disabled ? ' disabled' : '';
      const exception = this.exception ? ' exception' : '';
      const primaryException = this.primaryException ? ' exception-bis' : '';
      const danger = this.danger ? ' danger' : '';
      return `button ${this.btnStyle}
      ${this.size}${noIcon}${pending}${disabled}${exception}${primaryException}${danger}`;
    },
    fileSelector() {
      if (!this.browse) return null;
      const id = this.fileSelectorId;
      const fileSelector = document.createElement('input');
      fileSelector.setAttribute('id', `file-selector-${id}`);
      fileSelector.setAttribute('type', 'file');
      if (this.multiple) fileSelector.setAttribute('multiple', 'multiple');
      if (this.accept) fileSelector.setAttribute('accept', this.accept);
      fileSelector.addEventListener('change', this.handleFileChange);
      return fileSelector;
    },
    iconA2mb() {
      return this.iconClass === 'a2mb';
    }
  },
  methods: {
    handleClick() {
      if (this.browse) {
        this.handleFileSelect();
      } else {
        this.$emit(this.customEvent);
      }
    },
    handleFileSelect() {
      this.fileSelector.click();
      const idMemory = this.fileSelectorId;
      while (idMemory === this.fileSelectorId) {
        this.fileSelectorId = Math.round(Math.random() * 100);
      }
    },
    handleFileChange(e) {
      this.$emit(this.customEvent, e.target.files);
    },
    setIsHover(bool) {
      this.isHover = bool;
    }
  }
};
</script>

<style lang="scss" scoped>
.button,
.cancelable-btn {
  font-family: 'manrope', sans-serif;
  font-weight: 600;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 200ms linear;
  border-radius: 4px;
}

.button:focus {
  box-shadow: 0 0 0 2px $color_neutral_40;
}

.btn-icon {
  font-weight: normal;
  font-variant: normal;
  -moz-font-variant: normal;
  -webkit-font-variant: normal;
}

.btn-arrow {
  width: 20px;
}

.disabled,
.pending {
  cursor: not-allowed;
}

.primary {
  background-color: $color_neutral_100;
  color: $color_neutral_0;
  border: none;
  border-radius: 4px;

  &:hover {
    background-color: $color_neutral_80;
  }
}

.primary.danger {
  background: $color_danger_100;
  color: $color_neutral_0;
}
.primary.danger.disabled {
  background: $color_danger_100;
  color: $color_neutral_0;
  opacity: 0.4;
}

.primary.exception {
  background-color: $color_neutral_0;
  color: $color_primary_100;

  &:hover {
    background-color: $color_neutral_10;
  }
}

.primary.exception-bis {
  width: 100%;
}

.secondary {
  background: none;
  color: $color_neutral_100;
  border: 1px solid $color_neutral_40;
  border-radius: 4px;

  &:hover {
    color: $color_neutral_80;
    border: 1px solid $color_neutral_60;
    background-color: $color_neutral_10;
  }
}

.secondary.exception {
  color: $color_neutral_0;
  border-color: $color_neutral_0;

  &:hover {
    background-color: $color_neutral_0;
    color: $color_neutral_100;
  }
}

.secondary.secon .tertiary {
  color: $color_neutral_100;
  border: none;
  background: none;
  border-radius: 4px;
  padding: 0 8px;

  &:hover {
    color: $color_neutral_80;
    background-color: $color_neutral_10;
  }
}

.secondary.danger {
  background: none;
  color: $color_danger_100;
  border: 1px solid $color_danger_100;

  &:hover {
    background-color: $color_danger_10;
  }
}

.secondary.danger.disabled {
  background: none;
  color: $color_danger_100;
  border: 1px solid $color_danger_100;
  opacity: 0.4;
}

.secondary.danger.pending {
  &:hover {
    background: none;
    color: $color_danger_100;
    border: 1px solid $color_danger_100;
  }
}

.tertiary {
  border: none;
  border-radius: 4px;
  padding: 8px;
  color: $color_neutral_100;
  background: none;
}

.tertiary.exception {
  color: $color_primary_100;
  &:hover {
    background-color: $color_primary_10;
  }
}

.tertiary.small {
  @include btn-small;
  padding: 6px;
  height: 32px;
}
.tertiary.medium {
  @include btn-medium;
  height: 40px;
}
.tertiary.large {
  @include btn-large;
  height: 48px;
}
.tertiary:hover {
  background-color: $color_neutral_10;
}

.small {
  @include btn-small;
  .btn-icon {
    font-size: 16px;
  }
  height: 32px;
}

.primary.small,
.secondary.small {
  padding: 0 16px;
}

.secondary.small.cancelable-btn {
  box-sizing: border-box;
  padding: 0 9px;
}

.medium {
  @include btn-medium;
  .btn-icon {
    font-size: 20px;
  }
  height: 40px;
}

.primary.medium,
.secondary.medium {
  padding: 0 24px;
}

.large {
  @include btn-large;
  .btn-icon {
    font-size: 20px;
  }
  height: 48px;
}

.primary.large,
.secondary.large {
  padding: 0 32px;
}

.prefix {
  display: flex;
  align-items: center;

  .btn-icon {
    margin-right: 6px;
  }
}

.cancelable-icon {
  display: flex;
  align-items: center;
}

.suffix {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  .btn-icon {
    margin-left: 6px;
  }
}

.primary.disabled {
  background-color: $color_neutral_100;
  opacity: 0.4;
}

.primary.exception.disabled {
  background-color: $color_neutral_0;

  &:hover {
    background-color: $color_neutral_0;
  }
}

.primary.pending {
  &:hover {
    background-color: $color_neutral_100;
  }
}

.secondary.disabled {
  color: $color_neutral_100;
  border: 1px solid $color_neutral_100;
  opacity: 0.4;
  background: none;
}

.secondary.pending {
  &:hover {
    background-color: $color_neutral_0;
    color: $color_neutral_100;
    border: 1px solid $color_neutral_100;
  }
}

.tertiary.disabled {
  color: $color_neutral_100;
  opacity: 0.4;
  background: none;
}

.tertiary.pending {
  &:hover {
    color: $color_neutral_100;
    background: none;
  }
}

.loader {
  animation-name: Rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.no-icon.small.pending {
  padding: 0 9px 0 9px;

  .prefix {
    .btn-icon {
      margin-right: 2px;
    }
  }

  .suffix {
    .btn-icon {
      margin-left: 2px;
    }
  }
}

.no-icon.medium.pending {
  padding: 0 15px 0 15px;

  .prefix {
    .btn-icon {
      margin-right: 4px;
    }
  }

  .suffix {
    .btn-icon {
      margin-left: 4px;
    }
  }
}

.no-icon.large.pending {
  padding: 0 22px 0 22px;

  .prefix {
    .btn-icon {
      margin-right: 4px;
    }
  }

  .suffix {
    .btn-icon {
      margin-left: 4px;
    }
  }
}

button {
  padding: 0;
}
</style>
