
<!-- the key here is to avoid any context issue when creating a new project from a project page -->
<div
  v-if="ProjectDetails"
  class="project"
  :class="{ 'project-with-only-att': projectWithOnlyAttachment }"
  :key="$route.params.id"
>
  <div class="project-browse" v-if="browse">
    <div class="project-browse-header">
      <div class="project-browse-header-search">
        <search
          :searchContext="librarySearchContext"
          margin="margin: 8px -48px 0 -130px; padding: 8px 130px;"
        />
      </div>
      <i @click="closeBrowser" class="project-browse-header-close ri-close-line" />
    </div>
    <div class="project-browse-content">
      <div class="project-browse-content-tracks">
        <div class="project-browse-content-tracks-title">{{ $t('selectTracks') }}</div>
        <div class="project-browse-content-tracks-list">
          <browse :uncheckTracks="uncheckTracks" @setSelectedTracks="setSelectedTracks" />
        </div>
      </div>
      <div class="project-browse-content-footer">
        <div class="project-browse-content-footer--">{{ selectedTrackLabel }}</div>
        <submit-button
          size="medium"
          :label="$t('addTracksToProject')"
          iconClass="ri-add-fill"
          :disabled="libraryFileIds.length < 1"
          @submit="addTracksToProject()"
        />
      </div>
    </div>
  </div>
  <div
    v-if="(projectCover || temporaryImgUrl) && !hideProjectCover"
    class="project-cover"
    @mouseenter="setIsCoverHover(true)"
    @mouseleave="setIsCoverHover(false)"
  >
    <div v-if="temporaryImgUrl" class="upload-pending">
      <div class="upload-pending-loader">
        <spinner-without-progress class="spinner" color="white" :size="12" />
        {{ `${uploadPercent}%` }}
      </div>
    </div>
    <img
      ref="projectCoverPicture"
      class="project-cover-img"
      :style="coverStyle"
      :src="temporaryImgUrl ? temporaryImgUrl : projectCover.url"
      alt="project cover"
    />
    <div
      v-if="(displayCoverBtn || isDropdownExpanded) && !temporaryImgUrl && !coverRepositionMode"
      class="project-cover-btns"
    >
      <btn-dropdown
        class="project-cover-btns-dropdown"
        :maxCoverSizeConfig="maxCoverSizeConfig"
        @setIsDropdownExpanded="setIsDropdownExpanded"
        @setIsCoverHover="setIsCoverHover(false)"
        @deleteProjectCover="deleteProjectCover"
        @handleFileSelect="handleFileSelect"
        :btnProps="{
          style: 'secondary',
          size: 'small',
          label: 'Change cover'
        }"
      />
      <submit-button
        class="project-cover-btns--"
        btnStyle="secondary"
        size="small"
        :label="$t('reposition')"
        @click.native.stop
        @submit="toggleReposition(true)"
      />
    </div>
    <div v-if="coverRepositionMode">
      <div
        class="project-cover-reposition"
        @mousedown="handleCoverMouseDown"
        v-click-outside="{ hide: handleClickOutsideReposition }"
      />
      <div class="project-cover-reposition-txt">
        {{ $t('dragImgToReposition') }}
      </div>
      <div class="project-cover-btns">
        <submit-button
          class="project-cover-btns-- btn-save-position"
          btnStyle="secondary"
          size="small"
          :label="$t('save')"
          @click.native.stop
          @submit="saveCoverPosition"
        />
        <submit-button
          class="project-cover-btns--"
          btnStyle="secondary"
          size="small"
          :label="$t('cancel')"
          @submit="handleCancelReposition"
        />
      </div>
    </div>
  </div>
  <div class="project-content" v-if="!browse">
    <tabs :tabs="tabs" :activeTab="activeTab" class="project-content-tabs" />
    <router-view
      class="project-content--"
      @closeShareModal="closeShareModal"
      @handleProjectUpdateMutation="handleProjectUpdateMutation"
      @addTracks="openBrowser"
      @openShareModal="openShareModal"
      @uploadProjectCover="uploadProjectCover"
      @setOnlyAttachmentStatus="setOnlyAttachmentStatus"
      :projectName="projectName"
      :entityIds="[this.$route.params.id]"
      :entityName="projectName"
    ></router-view>
  </div>
</div>
<div v-else class="loader">
  <div v-if="projectUnavailable" class="errorMessage">
    {{ $t('errorProjectUnauthorized') }}
    <submit-button label="Back to Projects" @submit="goToProjectListRoute" />
  </div>
  <spinner-without-progress v-else color="grey" :size="32" />
</div>
