<template>
  <div class="save-changes-modal">
    <div class="save-changes-modal-body">{{ $t('saveChanges') }}</div>
    <div class="save-changes-modal-buttons">
      <div class="save-changes-modal-buttons--">
        <submit-button btnStyle="tertiary" :label="$t('cancel')" @submit="closeModal" />
      </div>
      <div class="save-changes-modal-buttons--">
        <submit-button :label="$t('save')" @submit="saveChanges" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onSubmit: {
      type: Function,
      required: false,
      default: () => {}
    },
    onCancel: {
      type: Function,
      required: false,
      default: () => {}
    }
  },
  methods: {
    closeModal() {
      this.onCancel();
      this.$emit('closeModal');
    },
    saveChanges() {
      this.onSubmit();
      this.$emit('closeModal');
    }
  }
};
</script>

<style lang="scss" scoped>
.save-changes-modal {
  &-body {
    padding: 32px 40px 40px;
    line-height: 150%;
    text-align: center;
  }

  &-buttons {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $color_neutral_30;
    padding: 8px 32px;

    &-- {
      &:first-child {
        margin: 0 16px 0 0;
      }
    }
  }
}
</style>
