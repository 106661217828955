import get from 'lodash.get';
import * as timeago from 'timeago.js';

import { getUserInitials, getUserPicture, getUserNames } from '@/utils/functions/user';
import {
  formatDate,
  formatDuration,
  FORMAT_OPTIONS,
  newFormatDate,
  moreThanAYear
} from '@/utils/functions/time';
import { getFrontCoverUrl } from '@/utils/functions/image';

export default function generateTracklist(tracks, workspace, locale, isSearch) {
  if (!tracks) {
    return [];
  }
  return tracks
    .map((item, index) => {
      const user = workspace.members.find(u => u.user_id === item.added_by);
      const createdByUrl = item.added_by ? getUserPicture(user) : null;
      const createdByName = item.added_by ? getUserNames(user) : null;
      const createdByInitial = item.added_by ? getUserInitials(user) : null;
      return {
        index,
        id: item.id,
        // deletetId sert à la suppression d'une track à un projet
        // Il faudrait une mutation qui permette de supprimer une track d'un projet avec le libraryFileId et le projectId
        deleteId: item.delete_id,
        imgSrc: isSearch
          ? item.imageUrl || '/images/artwork.jpg'
          : getFrontCoverUrl(get(item, 'supports[0].images'), 1) || '/images/artwork.jpg',
        largeImgSrc: isSearch
          ? get(item, 'supports[0].images[3].url') || '/images/artwork.jpg'
          : getFrontCoverUrl(get(item, 'supports[0].images'), 3) || '/images/artwork.jpg',
        title: item.title,
        artists: item.artists && item.artists.map(artist => ({ name: artist.name, id: artist.id })),
        authors:
          item.authors &&
          item.authors.map(author => ({
            id: author.id,
            name: author.name
          })),
        composers:
          item.composers &&
          item.composers.map(composer => ({
            name: composer.name,
            id: composer.id
          })),
        labels:
          item.labels &&
          item.labels.map(label => ({
            name: label.name,
            id: label.id
          })),
        publishers:
          item.publishers &&
          item.publishers.map(publisher => ({
            name: publisher.name,
            id: publisher.id
          })),
        duration: item.duration,
        humanDuration: formatDuration(item.duration),
        rating: item.rating,
        isrc: item.isrc,
        iswc: item.iswc,
        bpm: item.bpm,
        lyrics: item.lyrics,
        comment: item.comment,
        genre:
          item.genre &&
          item.genre.map(i => ({
            id: i,
            name: i
          })),
        recordingDate: formatDate(locale, new Date(item.recording_date).getTime() / 1000),
        supports:
          (item.supports &&
            item.supports.map(support => ({
              name: support.name,
              id: support.id,
              releaseDate: support.release_date,
              total: support.tracks_total,
              position: support.track_position,
              type: support.type,
              upc: support.upc
            }))) ||
          [],
        supportTrackPosition: get(item, 'supports[0].track_position', null),
        userName: createdByName,
        userInitial: createdByInitial,
        userPicture: createdByUrl,
        added: isSearch
          ? formatDate(locale, item.addedAt, FORMAT_OPTIONS['DD/MM/YY'])
          : formatDate(locale, item.created_at, FORMAT_OPTIONS['DD/MM/YY']),
        updated_at: formatDate(locale, item.updated_at, FORMAT_OPTIONS['DD/MM/YY']),
        bit_rate:
          item.file_information &&
          item.file_information.additional_media &&
          item.file_information.additional_media.bit_rate,
        format:
          item.file_information &&
          item.file_information.additional_media &&
          item.file_information.additional_media.format,
        size:
          item.file_information &&
          item.file_information.additional_media &&
          item.file_information.additional_media.size,
        added_by: item.file_information && item.file_information.added_by,
        right_holders:
          item.right_holders &&
          item.right_holders.map(rightHolders => ({
            id: rightHolders.id,
            track_right_holder_id: rightHolders.track_right_holder_id,
            name: rightHolders.name,
            slug: rightHolders.slug,
            role: rightHolders.role,
            publishing_percentage: rightHolders.publishing_percentage,
            mastering_percentage: rightHolders.mastering_percentage
          })),
        position: item.position ? item.position : null,
        tags: item.tags
          ? item.tags.map(tag => ({
              id: tag.id,
              category_id: tag.category_id,
              family_id: tag.family_id,
              tag_id: tag.tag_id,
              auto_tagged: tag.auto_tagged
            }))
          : [],
        hubs: item.hubs
          ? item.hubs.map(hub => ({
              id: hub.id,
              logo: hub.logo,
              name: hub.name
            }))
          : [],
        original_name: get(item, 'file_information.original_name', '-'),
        createdAt: {
          relative: timeago.format(item.created_at * 1000),
          date: newFormatDate(item.created_at * 1000),
          displayDate: moreThanAYear(item.created_at * 1000)
        },
        createdBy: {
          url: createdByUrl,
          name: createdByName,
          initial: createdByInitial
        },
        isStreamableAvailable: item.status_streamable_transcoded
      };
    })
    .reduce((acc, current) => {
      const x = acc.find(item => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      }
      return acc;
    }, []);
}

export function generateInboxTracklist(tracks) {
  if (!tracks) {
    return [];
  }
  return tracks
    .map((item, index) => {
      return {
        index,
        id: item.id,
        deleted: item.details === null,
        fileId: item.library_file_id,
        imgSrc: (item.details && item.details.cover_url) || '/images/artwork.jpg',
        title: item.details && item.details.title,
        artists: item.details && item.details.artistNames.join(', '),
        duration: item.details && item.details.track_length,
        humanDuration: item.details && formatDuration(item.details.track_length),
        supports: item.details && item.details.supportName,
        position: item.position ? item.position : null,
        isStreamableAvailable: item.details && item.details.status_streamable_transcoded,
        isInbox: true
      };
    })
    .reduce((acc, current) => {
      const x = acc.find(item => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      }
      return acc;
    }, []);
}
