<template>
  <div class="artwork" @click.stop="togglePlay">
    <transition name="fade">
      <div v-if="isHover" class="artwork-hover">
        <div class="artwork-hover-circle">
          <i :class="play && !isPlayerOnError ? 'ri-pause-circle-fill' : 'ri-play-circle-fill'" />
        </div>
        <div class="artwork-hover-bkg" />
      </div>
    </transition>
    <img v-if="imgSrc" :src="imgSrc" />
    <div v-else-if="trackIndex || trackIndex === 0" class="artwork-number">
      {{ trackIndex + 1 }}
    </div>
    <div v-else class="artwork-number">
      {{ trackNumber }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imgSrc: {
      type: String,
      required: false,
      default: undefined
    },
    trackId: {
      type: String,
      required: true,
      default: ''
    },
    playingTrack: {
      type: Object,
      required: false,
      default: () => ({
        play: 'play',
        id: 'id'
      })
    },
    isHoverId: {
      type: String,
      required: false,
      default: null
    },
    trackIndex: {
      type: Number,
      required: false,
      default: undefined
    },
    trackNumber: {
      type: Number,
      required: false,
      default: undefined
    },
    isPlayerOnError: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      play: this.isPlayingTrack ? this.playingTrack.play : false
    };
  },
  computed: {
    isPlayingTrack() {
      return this.playingTrack ? this.trackId === this.playingTrack.id : false;
    },
    isHover() {
      return this.isPlayingTrack ? true : this.isHoverId === this.trackId;
    }
  },
  watch: {
    playingTrack(playingTrack) {
      this.play = this.isPlayingTrack ? playingTrack.play : false;
    }
  },
  created() {
    this.play = this.isPlayingTrack ? this.playingTrack.play : false;
  },
  methods: {
    togglePlay() {
      this.$emit('setPlayingTrack', { id: this.trackId, play: !this.play });
    }
  }
};
</script>

<style lang="scss" scoped>
.artwork {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  color: $color_neutral_100;

  &-hover {
    position: absolute;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-circle {
      position: absolute;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: $color_neutral_100;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-bkg {
      position: absolute;
      width: 40px;
      height: 40px;
      background-color: $color_neutral_100;
      opacity: 0.8;
      z-index: 1;
    }
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 2px;
  }

  &-number {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ri-pause-circle-fill,
.ri-play-circle-fill {
  color: $color_neutral_0;
  font-size: 38px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
