
<div v-if="formatedShares.length > 0">
  <header-list
    :columns="columns"
    actionUnavailable
    :sortColumn="false"
    :itemsSelected="[]"
    :isSticky="true"
    :stickyPosition="0"
  />
  <div v-for="(share, idx) in formatedShares" :key="share.id">
    <list-row
      class="row-container"
      :item="share"
      :columns="columns"
      :rowIndex="idx"
      :height="64"
      :id="share.id"
      disableSelection
      @click.native="handleRowClick($event, share.id, share.type)"
      @handleEmit="handleEmit"
    />
  </div>
  <skeleton-row v-if="loading" :columns="columns" :height="64" />
</div>
