<template>
  <div class="support-sharings">
    <Sharingsview entityType="support" :entityIds="entityIds" :entityName="entityName" />
  </div>
</template>

<script>
import Sharingsview from '../../../../../containers/shareViews/sharings';

export default {
  components: {
    Sharingsview
  },
  props: {
    entityIds: {
      type: Array,
      required: true
    },
    entityName: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.support-sharings {
  margin-top: 24px;
}
</style>
