
<div class="app">
  <div v-if="isOffline" class="app-unique-alert app-unique-alert-offline">
    <alert :message="$t('offlineAlert')" alertStyle="danger" :hideDismissible="true" />
  </div>
  <div v-if="hasNewVersionAvailable" class="app-unique-alert app-unique-alert-update">
    <alert
      :message="$t('newVersionAlertMessage')"
      alertStyle="warning"
      :hideDismissible="true"
      :actionLabel="$t('Refresh')"
      @handleAlertClick="refresh"
    />
  </div>
  <div v-if="displayMobileWarning" class="app-mobile">
    <div class="app-mobile-container">
      <div class="backToWebsite">
        <submit-button
          btnStyle="tertiary"
          size="medium"
          iconClass="ri-arrow-left-line"
          label="Back to website"
          @submit="goToWebsite"
        />
      </div>
      <div class="app-mobile-content">
        <div class="image-container">
          <img src="/images/illu-mobile_wip.svg" alt="wip picture" />
        </div>
        <h1 class="title">{{ $t('mobile.title') }}</h1>
        <div class="main">
          {{ $t('mobile.subtitle') }}
          <a
            href="https://www.notion.so/bridgeaudio/How-to-access-my-Bridge-workspace-on-mobile-2dfc4ab525fb49b7bfabc8a6ce8684c4"
            target="_blank"
            rel="noopener"
            >{{ $t('mobile.link1') }}
          </a>
        </div>
        <div class="main">
          {{ $t('mobile.core1') }}
          <a
            href="https://www.notion.so/bridgeaudio/Mobile-use-9c273491696048299b499da8cdc3c48b?pvs=4"
            target="_blank"
            rel="noopener"
            >{{ $t('mobile.link2') }}</a
          >
          {{ $t('mobile.core2') }}
        </div>
      </div>
      <div class="cta">
        <submit-button
          btnStyle="primary"
          class="cta-open"
          size="large"
          label="Open Bridge.audio anyway"
          @submit="hideMobileWarning"
        />
      </div>
      <div class="help-button">
        <a
          href="https://www.notion.so/bridgeaudio/Bridge-audio-Help-Center-0db362d99c4746c8bf6ebd8a08e3b549"
          target="_blank"
          rel="noopener"
        >
          <img src="/images/external-link-line.svg" alt="" />
          <span class="bold">{{ $t('mobile.link3') }}</span>
        </a>
      </div>
    </div>
  </div>
  <div v-if="alert.isVisible" class="app-alert">
    <div
      v-if="isBrowserSupported && displayAlertConfetti"
      ref="confettiAnimation"
      class="confetti-container"
    />
    <alert
      v-if="alert.isVisible"
      :message="alert.message"
      :alertStyle="alert.style"
      :isVisible="alert.isVisible"
      :actionLabel="alert.actionLabel"
      :hideDismissible="alert.hideDismissible"
      :actionCountdown="alert.actionCountdown"
      @handleAlertClick="alert.handleAction"
      @closeAlert="closeAlert"
    />
  </div>
  <div class="app-modal" :class="modalClass">
    <modal
      class="app-modal--"
      :isVisible="modal.isVisible"
      :title="modal.title"
      :size="modal.size"
      :isFixed="modal.isFixed ? modal.isFixed : false"
      :hideHeader="modal.hideHeader"
      @close="closeModalOutsideBody"
    >
      <template v-slot:body>
        <component
          ref="modalBody"
          :is="modal.component"
          :onSubmit="modal.onSubmit"
          :onCancel="modal.onCancel"
          :msg="modal.msg"
          v-bind="modalProps"
          @closeModal="closeModal"
        />
      </template>
    </modal>
  </div>
  <div class="app-bottom-alert" v-if="displayWorkspaceLockedAlert">
    <bottom-alert @closeAlert="closeBottomAlert" @handleActionClick="goToUpgrade" />
  </div>
  <router-view
    v-show="!displayMobileWarning"
    class="app-content"
    :isBrowserSupported="isBrowserSupported"
  ></router-view>
</div>
