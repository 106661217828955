
<div>
  <slot
    :projectList="projectList"
    :showProjectObserver="showObserver"
    :noProjectSearchResult="noSearchResult"
    :loadingProjects="!ProjectList"
    :fetchingMoreProjects="fetchingMoreProjects"
    :fetchMoreProjects="fetchMoreProjects"
    :searchProject="searchProject"
    :listProjects="listProjects"
  />
</div>
