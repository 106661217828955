
<div class="submission-details">
  <div
    :class="['submission-edition', editPanelTracks.length > 0 ? 'submission-edition-open' : '']"
  >
    <div class="submission-edition-wrapper">
      <edit-tracks-panel
        v-if="editPanelTracks.length > 0"
        ref="editTracksPanel"
        :libraryFileIds="editPanelTracks"
        :trackList="trackList"
        noPagination
        @toggleEditMode="toggleEditMode"
      />
    </div>
  </div>
  <div
    class="submission-content"
    :style="editPanelTracks.length > 0 && 'width: calc(100% - 518px + 38px);'"
  >
    <div v-if="InboxSubmission" class="submission-info">
      <div class="title">{{ this.title }}</div>
      <div class="message">{{ this.message }}</div>
    </div>
    <div class="tracks-list">
      <div class="tracks-list-content">
        <div v-show="!InboxSubmission" class="loader">
          <spinner-without-progress color="grey" :size="32" />
        </div>
        <tracks
          v-if="LibraryFilesDetails"
          :trackList="trackList"
          :stickyPosition="41"
          isSticky
          noAction
          @toggleEditMode="toggleEditMode"
        />
      </div>
    </div>
  </div>
</div>
