
<div class="submission-activity">
  <ActivityView
    class="submission-activity--"
    entityType="submission"
    :entityIds="entityIds"
    :entityName="entityName"
    :shareHash="shareHash"
    hideContactFilter
  />
</div>
