<template>
  <div class="artist-activity">
    <ActivityView entityType="artist" :entityIds="entityIds" :entityName="entityName" />
  </div>
</template>

<script>
import ActivityView from '../../../../../containers/shareViews/activity';

export default {
  components: {
    ActivityView
  },
  props: {
    entityIds: {
      type: Array,
      required: true
    },
    entityName: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.artist-activity {
  margin-top: 24px;
}
</style>
