<template>
  <div class="tags-settings" :key="randomKey">
    <div v-if="displayHeader" class="AI-section">
      <div class="tags-settings-title"><i class="ri-sparkling-2-fill"></i> Auto-Tagging</div>
      <div class="tags-settings-subtitle">
        Our homemade AI can automatically tag your tracks with genre, vocal, instruments, mood, and
        more.<br />This will allow you and your collaborators to do accurate searches, in an
        objectively described library.
      </div>
      <switch-button
        ref="aiSwitchBtn"
        class="AI-switch"
        :defaultValue="currentWorkspace.properties.auto_tagger"
        id="switch-AI"
        label="Enable AI on my library and future uploads"
        labelPrefix
        size="large"
        @click.native.stop
        @toggleSwitchButton="toggleAIModal"
      />
      <div class="help-center">
        Visit our
        <a
          href="https://bridgeaudio.notion.site/A-I-auto-tagging-8918b46e898e437085a235566053560f"
          target="_blank"
          rel="noopener noreferrer"
          >Help Center</a
        >
        for more information about Bridge AI.
      </div>
      <div class="section-separator" />
    </div>
    <div class="tags-settings-header" v-if="displayHeader">
      <div class="tags-settings-title">{{ $t('CustomTagsTitle') }}</div>
      <div class="tags-settings-subtitle">{{ $t('manageCustomTagsSubtitle') }}</div>
    </div>
    <div class="tags-settings-create">
      <div class="tags-settings-create--">
        <text-field
          inputType="text"
          :errorMessage="tagNameValidationMessage"
          :fieldModel.sync="$v.tagName.$model"
          :label="$t('createTag')"
          :inputWidth="319"
          :large="false"
          @keyup.enter.native="createCustomTag"
        />
      </div>
      <submit-button
        btnStyle="primary"
        size="medium"
        :label="$t('create')"
        :disabled="isSaveDisabled"
        @submit="createCustomTag"
      />
    </div>
    <div v-if="customTagList.length > 0" class="tags-settings-list">
      <div class="tags-settings-list-title">{{ $t('customTagList') }}</div>
      <ul class="tags-settings-list-content">
        <li class="tags-settings-list-row" v-for="(tag, index) in customTagList" :key="tag.id">
          <div class="tags-settings-list-row-item" v-if="!tag.isEdited">
            <div class="tags-settings-list-row-item-tag">
              {{ tag.name }}
            </div>
            <div class="tags-settings-list-row-item-btn">
              <icon-button
                icon="ri-edit-line"
                btnStyle="secondary"
                size="small"
                @submit="toggleEditMode(tag.id)"
              />
            </div>
            <div class="tags-settings-list-row-item-btn">
              <icon-button
                class="danger"
                icon="ri-delete-bin-2-line"
                btnStyle="secondary"
                size="small"
                @submit="deleteTag(tag.id)"
              />
            </div>
          </div>
          <div
            class="tags-settings-list-row-item"
            v-else
            :style="getTagNameValidationMessage(index) !== null && `margin-bottom: 24px;`"
          >
            <div class="tags-settings-list-row-item-input">
              <text-field
                inputType="text"
                :errorMessage="getTagNameValidationMessage(index)"
                :fieldModel.sync="tag.name"
                :value="tag.name"
                focusOnLoad
                :large="false"
                @keyup.enter.native="editCustomTag(tag)"
              />
            </div>
            <div class="tags-settings-list-row-item-save">
              <submit-button
                class="save-button"
                btnStyle="primary"
                size="medium"
                :label="$t('save')"
                :disabled="
                  getTagNameValidationMessage(index) !== null ||
                  !$v.customTagList.$each[index].name.required
                "
                @submit="editCustomTag(tag)"
              />
              <submit-button
                btnStyle="secondary"
                size="medium"
                :label="$t('cancel')"
                @submit="cancelEdit(tag.id)"
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
import { TAG_LIST } from '@/graphql/queries/tags';
import { ME } from '@/graphql/queries/user';
import { TAG_CREATE_CUSTOM, TAG_UPDATE, TAG_DELETE_CUSTOM } from '@/graphql/mutations/tags';
import { CONFIGURE_WORKSPACE_PROPERTIES } from '@/graphql/mutations/workspace';
import { bus } from '@/utils/bus';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import ConfirmToggleAI from '@/containers/modals/confirmToggleAI';

export default {
  props: {
    displayHeader: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data() {
    return {
      tagName: null,
      randomKey: 0,
      customTagList: []
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    tagNameValidationMessage() {
      if (!this.$v.tagName.maxLength) {
        return this.$t('maxLength', {
          label: this.$t('tagName'),
          nbChars: this.$v.tagName.$params.maxLength.max
        });
      }
      return null;
    },
    isSaveDisabled() {
      return this.tagNameValidationMessage || !this.$v.tagName.required;
    }
  },
  apollo: {
    TagList: {
      query: TAG_LIST,
      variables() {
        return {
          contextName: 'library',
          contextId: this.currentWorkspace.libraries[0].id
        };
      },
      result() {
        const customCategory =
          this.TagList &&
          this.TagList.categories.find(
            category => category.name && category.name.toLowerCase() === 'custom'
          );
        this.customTagList =
          customCategory && customCategory.families && customCategory.families[0]
            ? customCategory.families[0].tags.map(tag => ({
                ...tag,
                isEdited: false,
                categoryId: customCategory.id,
                familyId: customCategory.families[0].id
              }))
            : [];
      }
    }
  },
  validations: {
    tagName: {
      required,
      maxLength: maxLength(50)
    },
    customTagList: {
      $each: {
        name: {
          maxLength: maxLength(50),
          required
        }
      }
    }
  },
  methods: {
    createCustomTag() {
      this.$apollo
        .mutate({
          mutation: TAG_CREATE_CUSTOM,
          variables: {
            contextName: 'library',
            contextId: this.currentWorkspace.libraries[0].id,
            tagName: this.tagName
          }
        })
        .then(() => {
          this.$apollo.queries.TagList.refetch().then(() => {
            this.tagName = null;
            this.randomKey = Math.floor(Math.random() * 100000);
            bus.$emit('showAlert', {
              message: { key: 'createTagSuccess' },
              style: 'success',
              delay: 5000
            });
          });
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    toggleEditMode(tagId) {
      const previousEditedTagIndex = this.customTagList.findIndex(tag => tag.isEdited === true);
      const previousEditedTag = this.customTagList.find(tag => tag.isEdited === true);
      this.$set(this.customTagList, previousEditedTagIndex, {
        ...previousEditedTag,
        isEdited: false
      });
      const editedTagIndex = this.customTagList.findIndex(tag => tag.id === tagId);
      const editedTag = this.customTagList.find(tag => tag.id === tagId);
      this.$set(this.customTagList, editedTagIndex, {
        ...editedTag,
        isEdited: true,
        previousName: editedTag.name // Adds a backup of the filename so we can restore it on cancel
      });
    },
    cancelEdit(tagId) {
      const editedTagIndex = this.customTagList.findIndex(tag => tag.id === tagId);
      const editedTag = this.customTagList.find(tag => tag.id === tagId);
      editedTag.name = editedTag.previousName;
      delete editedTag.previousName;
      this.$set(this.customTagList, editedTagIndex, { ...editedTag, isEdited: false });
    },
    getTagNameValidationMessage(index) {
      if (!this.$v.customTagList.$each[index].name.maxLength) {
        return this.$t('maxChars', {
          nbChars: this.$v.customTagList.$each[index].name.$params.maxLength.max
        });
      }
      return null;
    },
    editCustomTag(tag) {
      this.$apollo
        .mutate({
          mutation: TAG_UPDATE,
          variables: {
            contextName: 'library',
            contextId: this.currentWorkspace.libraries[0].id,
            categoryId: tag.categoryId,
            familyId: tag.familyId,
            tagId: tag.id,
            tagNewName: tag.name
          }
        })
        .then(() => {
          this.$apollo.queries.TagList.refetch().then(() => {
            this.randomKey = Math.floor(Math.random() * 100000);
            bus.$emit('showAlert', {
              message: { key: 'modifyTagSuccess' },
              style: 'success',
              delay: 5000
            });
          });
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    deleteTag(tagId) {
      this.$apollo
        .mutate({
          mutation: TAG_DELETE_CUSTOM,
          variables: {
            contextName: 'library',
            contextId: this.currentWorkspace.libraries[0].id,
            tagId
          }
        })
        .then(() => {
          this.$apollo.queries.TagList.refetch().then(() => {
            this.randomKey = Math.floor(Math.random() * 100000);
            bus.$emit('showAlert', {
              message: { key: 'deleteTagSuccess' },
              style: 'success',
              delay: 5000
            });
          });
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    toggleAIModal(bool) {
      bus.$emit('displayModal', {
        component: ConfirmToggleAI,
        isVisible: true,
        size: 'small',
        title: bool ? 'Enable AI Auto-Tagging' : 'Disable AI tagging',
        onSubmit: confirm => {
          if (confirm) {
            this.toggleAi(bool);
          } else {
            this.$refs.aiSwitchBtn.onlyToggleDisplay();
          }
        },
        props: [
          {
            name: 'body',
            value: bool
              ? 'Your current library and future uploads will receive complete tag analysis. This choice will apply to all workspace members.'
              : 'AI generated tags will not be visible in your library anymore. New uploads will not be automatically tagged. This choice will apply to all workspace members.'
          }
        ]
      });
    },
    toggleAi(bool) {
      this.$apollo
        .mutate({
          mutation: CONFIGURE_WORKSPACE_PROPERTIES,
          variables: {
            workspaceId: this.currentWorkspace.id,
            properties: {
              auto_tagger: bool
            }
          },
          update: (store, { data }) => {
            const newVal = data.configureWorkspaceProperties.auto_tagger;
            const stored = store.readQuery({
              query: ME
            });
            stored.me.workspaces.find(
              el => el.id === this.currentWorkspace.id
            ).properties.auto_tagger = newVal;
            store.writeQuery({
              query: ME,
              data: stored
            });
          }
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.tags-settings {
  .section-separator {
    margin: 40px 0;
    height: 1px;
    background: $color_neutral_30;
  }
  .AI-section {
    .AI-switch {
      margin: 24px 0;
    }
    .help-center {
      font-size: 14px;
    }
  }
  &-header {
    margin-bottom: 32px;
  }
  &-title {
    @include heading-6;
    margin: 0 0 4px 0;
    .ri-sparkling-2-fill {
      color: $color_blue_10;
      margin-right: 4px;
    }
  }

  &-subtitle {
    @include body-2;
    color: $color_neutral_60;
  }

  &-create {
    display: flex;
    align-items: flex-end;
    margin: 0;

    &-- {
      position: relative;
      margin: 0 8px 0 0;
    }
  }

  &-list {
    margin: 24px 0 0 0;

    &-title {
      @include subtitle-1;
      margin: 0 0 16px 0;
    }

    &-row {
      margin: 8px 0 0 0;
      &-item {
        display: flex;
        align-items: center;

        &:hover {
          .tags-settings-list-row-item-btn {
            opacity: 1;
            transition: all 200ms linear;
          }
        }

        &-tag {
          @include body-1;
          width: fit-content;
          display: flex;
          align-items: center;
          padding: 0 12px;
          box-sizing: border-box;
          border-radius: 20px;
          height: 32px;
          margin: 0 8px 0 0;
          cursor: pointer;
          background-color: $color_tags_custom_10;
          color: $color_tags_custom_100;
          border: 1px solid $color_tags_custom_100;
        }

        &-btn {
          margin: 0 4px;
          opacity: 0;
        }

        &-save {
          margin: 0 0 0 8px;
          display: flex;
          .save-button {
            margin-right: 4px;
          }
        }

        &-input {
          position: relative;
          width: 200px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.tags-settings-list-row-item-btn {
  .secondary.danger {
    color: $color_danger_100;
    border-color: $color_danger_100;
    &:hover {
      color: $color_danger_100;
      border-color: $color_danger_100;
      background: $color_danger_10;
    }
  }
}
.AI-switch.switch-button {
  width: fit-content;
  .switch-button-green-on.switch-button-label.switch-button-label-right.switch-button-label-enabled {
    font-weight: normal;
    color: $color_neutral_100;
  }
  .switch-button-label.switch-button-label-right {
    flex: unset;
    font-size: 14px;
  }
}
</style>
