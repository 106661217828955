<template>
  <div class="circle">
    <div class="circle-text">{{ percent }}%</div>
    <svg class="circle-svg" height="42" width="42">
      <circle
        class="circle-svg-fill"
        cx="21"
        cy="21"
        r="20"
        fill-opacity="0"
        :stroke-dashoffset="dashoffset"
      />
    </svg>
    <svg class="circle-svg" height="42" width="42" style="z-index: -1">
      <circle class="circle-svg-above" cx="21" cy="21" r="20" fill-opacity="0" />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    percent: {
      type: Number,
      required: true
    }
  },
  computed: {
    dashoffset() {
      return ((100 - this.percent) * 126) / 100;
    }
  }
};
</script>

<style lang="scss" scoped>
.circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  &-text {
    position: absolute;
    font-weight: 400;
    font-size: 12px;
  }

  &-svg {
    position: absolute;
    transform: rotate(-90deg);

    &-fill {
      stroke: $color_success_100;
      stroke-width: 1;
      stroke-dasharray: 126;
    }

    &-above {
      stroke: $color_neutral_30;
      stroke-width: 1;
      stroke-dasharray: 126;
      stroke-dashoffset: 0;
    }
  }
}
</style>
