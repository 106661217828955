
<div class="wrapper">
  <div v-if="displayLoader" class="loader">
    <div v-if="loading">
      <spinner-without-progress color="grey" :size="32" />
    </div>
    <div class="error" v-else>
      <p class="error-label">
        <i class="error-icon ri-error-warning-line" />
        {{ $t('error') }}
      </p>
      <submit-button
        iconClass="ri-restart-line"
        :btnStyle="primary"
        @submit="reload"
        :label="$t('reload')"
      />
    </div>
  </div>
  <div
    class="edit-panel"
    v-if="
      isMultipleTrackEdition || (LibraryFilesDetails && tracksToEdit.length > 0 && !displayLoader)
    "
  >
    <div class="edit-panel-header">
      <div class="edit-panel-header-title">
        <div class="edit-panel-header-left">
          <div class="edit-panel-header-left-content">
            <div
              v-if="!noSupport"
              @click="openEditAlbum"
              @mouseover="toggleArtworkHover(true)"
              @mouseleave="toggleArtworkHover(false)"
              class="header-artwork-container"
              v-tooltip="tracksToEdit[0].supports[0].name"
            >
              <img :src="tracksToEdit[0].imgSrc" class="header-artwork" />
              <div v-if="isArtworkHover" class="header-artwork-icon">
                <div class="header-artwork-background">
                  <i class="ri-edit-fill"></i>
                </div>
              </div>
            </div>
            <div
              v-else
              class="header-artwork-container"
              @mouseover="toggleArtworkHover(true)"
              @mouseleave="toggleArtworkHover(false)"
              @click="goToDetailsCreateAlbum(!isMultipleTrackEdition && tracksToEdit[0].title)"
            >
              <img src="/images/artwork.jpg" />
              <div v-if="isArtworkHover" class="header-artwork-icon">
                <div class="header-artwork-background">
                  <i class="ri-edit-fill"></i>
                </div>
              </div>
            </div>
            <div class="edit-panel-header-left-content-text">
              <div class="edit-panel-header-left-content-text-title">
                {{
                  isMultipleTrackEdition
                    ? $tc('trackSelectedCount', libraryFileIds.length)
                    : tracksToEdit[0].title
                }}
              </div>
              <div v-if="!isMultipleTrackEdition" class="artists-container">
                <div
                  class="edit-panel-header-left-content-text-subtitle"
                  v-for="artist in LibraryFilesDetails[0].artists"
                  :key="artist.id"
                >
                  {{ artist.name }}
                </div>
              </div>
              <div v-else class="flex">
                <div class="edit-panel-header-left-content-text-subtitle">
                  {{ $t('multipleArtists') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="edit-panel-header-right">
          <div class="navigation-btn" v-if="!isMultipleTrackEdition && showArrows">
            <icon-button
              class="arrow"
              icon="ri-arrow-left-line"
              btnStyle="secondary"
              size="small"
              :disabled="!previousTrack"
              v-tooltip="$t('previousTrack')"
              @submit="changeTrack(false)"
            />
            <icon-button
              class="arrow"
              icon="ri-arrow-right-line"
              btnStyle="secondary"
              size="small"
              :disabled="(isCurrentTrackListLastPage || noPagination) && !nextTrack"
              v-tooltip="$t('nextTrack')"
              @submit="changeTrack(true)"
            />
            <div class="separator" />
          </div>
          <icon-button
            icon="ri-close-fill"
            btnStyle="tertiary"
            size="small"
            @submit="closeEditPanel"
          />
        </div>
      </div>
      <div class="edit-panel-header-tabs">
        <tabs :tabs="tabs" :noRouteCurrentTab="currentTabIndex" noRoute @changeTab="changeTab" />
        <div class="edit-panel-header-tabs-chip" v-if="!displaySaveBtn && autoSaveStatus">
          <auto-save-status :status="autoSaveStatus" />
        </div>
      </div>
    </div>
    <div class="edit-panel-body">
      <details-view
        ref="detailsView"
        v-if="currentTab === $t('details')"
        :tracksToEdit="tracksToEdit"
        :isMultipleTrackEdition="isMultipleTrackEdition"
        :formHasChangedParent.sync="formHasChanged"
        :isSaveButtonDisabledParent.sync="isSaveButtonDisabled"
        :openSupportModalOnLoad.sync="openSupportModalOnLoad"
        :pendingStatusParent.sync="pendingStatus"
        :isProject="isProject"
        @showModalForMultiEdition="showModalForMultiEdition"
        @refetchLibraryFilesDetails="reload"
        @autoSaveSuccess="setautoSaveSuccess"
      />
      <file-view
        v-if="currentTab === $t('file')"
        :tracksToEdit="tracksToEdit"
        :isMultipleTrackEdition="isMultipleTrackEdition"
      />
      <support-view
        v-if="currentTab === $t('album')"
        :tracksToEdit="tracksToEdit"
        :isMultipleTrackEdition="isMultipleTrackEdition"
        @goToDetailsCreateAlbum="goToDetailsCreateAlbum"
      />
      <right-view
        ref="rightView"
        v-if="currentTab === $t('credits')"
        :tracksToEdit="tracksToEdit"
        :isMultipleTrackEdition="isMultipleTrackEdition"
        :isSaveButtonDisabledParent.sync="isSaveButtonDisabled"
        :formHasChangedParent.sync="formHasChanged"
        :pendingStatusParent.sync="pendingStatus"
      />
      <tags-view
        ref="tagsView"
        v-if="currentTab === $t('tags')"
        :tracksToEdit="tracksToEdit"
        :formHasChangedParent.sync="formHasChanged"
        :isSaveButtonDisabledParent.sync="isSaveButtonDisabled"
        :pendingStatusParent.sync="pendingStatus"
        :isMultipleTrackEdition="isMultipleTrackEdition"
        @showModalForMultiTags="showModalForMultiTags"
        @autoSaveSuccess="setautoSaveSuccess"
      />
      <usage-view
        ref="usageView"
        v-if="currentTab === $t('usage')"
        :tracksToEdit="tracksToEdit"
        :hubs="this.currentWorkspace.hubs"
        :isMultipleTrackEdition="isMultipleTrackEdition"
        @autoSaveSuccess="setautoSaveSuccess"
      />
    </div>
    <div class="edit-panel-buttons" v-if="displaySaveBtn">
      <div class="edit-panel-buttons--">
        <submit-button
          btnStyle="tertiary"
          size="medium"
          :label="$t('cancel')"
          @submit="cancelChanges(true)"
        />
      </div>
      <submit-button
        btnStyle="primary"
        size="medium"
        :label="$t('save')"
        :disabled="isSaveButtonDisabled"
        :pending="pendingStatus"
        @submit="handleClickSaveButton"
      />
    </div>
  </div>
</div>
