<template>
  <div v-if="ActivitiesList">
    <div class="activity" v-if="isActivities" data-test="activityList">
      <div v-if="entityType === 'project'" class="activity-title">
        <div class="activity-title-main">{{ $t('allSharingActivity') }}</div>
        <div class="activity-title-sub">{{ $t('activitiesDesc') }}</div>
      </div>
      <div class="activity-filter">
        <filter-dropdown
          class="activity-filter--"
          filterIcon="ri-star-s-line"
          filterName="Activities"
          :filterList="activitiesFilterList"
          :activeFilter="filterActivities"
          @toggleFilter="toggleActivities"
        />
        <date-filter-dropdown
          class="activity-filter--"
          local="en"
          :txtDropdownOptions="txtDropdownOptions"
          @setDateFilter="setDateFilter"
          ref="dateFilter"
        />
        <filter-dropdown
          v-if="!hideContactFilter"
          class="activity-filter--"
          filterIcon="ri-user-line"
          filterName="Contact"
          :filterList="contactFilterList"
          :activeFilter="filterContact"
          @toggleFilter="toggleContact"
        />
      </div>
      <div class="activity-content" v-if="isFilterResult">
        <div
          v-for="(activities, index) in sortedActivities"
          :key="`activities-${index}`"
          class="activity-content-section"
        >
          <div class="activity-content-section-title">{{ activities.dateToDisplay }}</div>
          <div class="activity-content-section-item-container">
            <div
              v-for="(activity, i) in activities.activities"
              :key="`activity-${i}`"
              class="activity-content-section-item"
            >
              <activity-item
                :hour="getHour(activity.performedAt)"
                :action="formatAction(activity.action)"
                :itemName="activity.description"
                :mail="activity.mail || activity.customName || sendTo || 'Quick link'"
              />
            </div>
          </div>
        </div>
        <div v-if="displayLoadMore" class="activity-content-loadmore">
          <submit-button
            btnStyle="tertiary"
            size="medium"
            label="Load more"
            iconPosition="suffix"
            :pending="isPending"
            @submit="loadMore"
          />
        </div>
      </div>
      <div v-else class="activity-noresult">
        <i class="ri-emotion-unhappy-line"></i>
        <div class="activity-noresult-title">{{ $t('noResult') }}</div>
        <div class="activity-noresult-subtitle">
          {{ $t('tryAdjustFilter') }}
        </div>
      </div>
    </div>
    <div class="no-activity" v-else-if="entityType === 'project'">
      <empty-activity
        :title="$t('noActivityProjectTitle')"
        :description="$t('noActivityProjectSubtitle')"
        placeholderType="activity"
        :cloudedTextContent="$t('shareProject')"
        :cloudedTextColor="{
          stroke: '#FF9143',
          fill: '#FFF9EB'
        }"
      >
        <template v-slot:button>
          <btn-dropdown
            v-if="entityType !== 'submission'"
            class="upload-to-library-btn"
            :options="shareBtnOptions"
            :btnProps="{
              style: 'primary',
              size: 'medium',
              label: 'share',
              hideDropdown: true,
              icon: 'ri-share-forward-fill'
            }"
            @action="openShareModal"
          />
        </template>
      </empty-activity>
    </div>
    <div class="no-activity-container" v-else data-test="no-activity-container">
      <div class="no-activity-icon">
        <i class="ri-history-line"></i>
      </div>
      <div class="no-activity-title">
        {{ $t('noActivityTitle', { type: typeName }) }}
      </div>
      <div class="no-activity-subtitle">
        {{ $t('noActivitySubtitle', { type: typeName }) }}
      </div>
      <btn-dropdown
        v-if="entityType !== 'submission'"
        class="upload-to-library-btn"
        :options="shareBtnOptions"
        :btnProps="{
          style: 'primary',
          size: 'medium',
          label: 'share',
          hideDropdown: true,
          icon: 'ri-share-forward-fill'
        }"
        @action="openShareModal"
      />
    </div>
  </div>
  <div v-else class="loader"><spinner-without-progress color="grey" :size="32" /></div>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash.get';

import { bus } from '@/utils/bus';
import { ACTIVITIES_LIST, SHARE_LIST } from '@/graphql/queries/share';

import ShareModal from '@/containers/modals/share';
import BtnDropdown from '@/containers/dropdowns/submitBtn';
import EmptyActivity from '@/containers/emptyView';

export default {
  components: {
    BtnDropdown,
    EmptyActivity
  },
  props: {
    entityType: {
      type: String,
      required: false,
      default: 'project'
    },
    entityIds: {
      type: Array,
      required: true
    },
    entityName: {
      type: String,
      required: false,
      default: ''
    },
    shareHash: {
      type: String,
      required: false,
      default: undefined
    },
    hideContactFilter: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      shareList: [],
      sortedActivities: [],
      filterActivities: [],
      filterContact: [],
      sendTo: null,
      activitiesFilterList: [
        { name: 'Opened', key: 'opened' },
        { name: 'Streamed tracks', key: 'streamed' },
        { name: 'Downloaded tracks in original quality', key: 'download_original' },
        { name: 'Downloaded tracks in mp3', key: 'download_mp3' },
        ...(this.entityType === 'submission'
          ? [{ name: 'Downloaded entire submission', key: 'download_submission' }]
          : []),
        ...(this.entityType === 'project'
          ? [
              { name: 'Downloaded entire project', key: 'download_project' },
              { name: 'Downloaded media & documents', key: 'download_file' }
            ]
          : []),
        ...(this.entityType === 'artist'
          ? [{ name: 'Downloaded artist', key: 'download_artist' }]
          : [])
      ],
      contactFilterList: [],
      txtDropdownOptions: [
        {
          label: this.$t('before'),
          value: 'before'
        },
        {
          label: this.$t('after'),
          value: 'after'
        },
        {
          label: this.$t('on'),
          value: 'is'
        },
        {
          label: this.$t('between'),
          value: 'between'
        }
      ],
      today: Math.floor(parseInt(Date.now(), 10) / 1000),
      oneYearAgo: Math.floor(parseInt(Date.now(), 10) / 1000) - 31536000,
      displayLoadMore: true,
      page: 1,
      isPending: false,
      isActivities: false,
      isFilterResult: true,
      isFirstQuery: true,
      shareBtnOptions: [
        {
          label: this.$t('getQuickLink'),
          subText: this.$t('getQuickLinkSub'),
          icon: 'ri-flashlight-fill',
          action: 'openShareModal',
          params: {
            defaultTab: 0
          }
        },
        {
          separator: true
        },
        {
          label: this.$t('createIndividualLinks'),
          subText: this.$t('createIndividualLinksSub'),
          icon: 'ri-links-fill',
          action: 'openShareModal',
          params: {
            defaultTab: 1
          }
        },
        {
          separator: true
        },
        {
          label: this.$t('sendByEmail'),
          subText: this.$t('sendByEmailSub'),
          icon: 'ri-mail-line',
          action: 'openShareModal',
          params: {
            defaultTab: 2
          }
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    typeName() {
      let name;
      switch (this.entityType) {
        case 'project':
          name = 'project';
          break;
        case 'support':
          name = 'album';
          break;
        case 'artist':
          name = 'artist';
          break;
        case 'track':
          name = 'track';
          break;
        case 'tracks':
          name = 'tracks';
          break;
        case 'submission':
          name = 'submission';
          break;

        default:
          break;
      }
      return name;
    }
  },
  apollo: {
    ShareList: {
      query: SHARE_LIST,
      variables() {
        return {
          workspaceId: this.currentWorkspace.id,
          type: this.entityType,
          entities: this.entityIds,
          ...(this.shareHash !== undefined && { hash: this.shareHash })
        };
      },
      result() {
        const contactList = [];
        this.ShareList.forEach(sharing => {
          contactList.push({
            name: sharing.email || sharing.custom_name || 'Quick link',
            key: sharing.id
          });
        });
        this.contactFilterList = contactList;
        this.shareList = this.ShareList;
        const subtitleVariables = get(this.shareList, '[0].subtitle_variables');
        this.sendTo = subtitleVariables
          ? subtitleVariables.find(el => el.name === 'workspace_name').value
          : null;
      }
    },
    ActivitiesList: {
      query: ACTIVITIES_LIST,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          workspaceId: this.currentWorkspace.id,
          type: this.entityType,
          from: this.oneYearAgo,
          to: this.today,
          ...(this.shareHash !== undefined && { hash: this.shareHash }),
          ...(this.shareHash === undefined && { refsIds: this.entityIds }),
          page: 1
        };
      },
      skip() {
        // we need the sharelist to display the mail from where the activity was performed, so we skip query.ActivitiesList until query.ShareList is done
        return this.ShareList === undefined;
      },
      result() {
        // As we use cache-network policy we must handles the case on load where cache is empty
        if (!this.ActivitiesList) {
          return;
        }
        // The array structure is supposed to be like so :
        // const sortedActivities = [
        //   { activities: [{}, {}], dateToDisplay: 'Saturday 1 February 2020', date: 'Sat Feb 01 2020' },
        //   { activities: [{}, {}], dateToDisplay: 'Tuesday 9 April 2019', date: 'Tue Apr 09 2019' }
        // ];
        if (this.isFirstQuery) {
          this.isActivities = this.ActivitiesList.data.length > 0;
        } else {
          this.isFilterResult = this.ActivitiesList.data.length > 0;
        }
        this.isFirstQuery = false;
        this.isPending = false;
        this.displayLoadMore = this.ActivitiesList.data.length === 100;
        const sortedActivities = [];
        this.ActivitiesList.data.forEach(el => {
          const date = new Date(el.performedAt * 1000).toDateString();
          const spliceDate = {
            weekday: date.slice(0, 3),
            month: date.slice(4, 7),
            day: date.slice(8, 10),
            year: date.slice(11, 15)
          };
          const { weekday, month, day, year } = spliceDate;
          // eslint-disable-next-line prettier/prettier
          const dateToDisplay = `${this.$t(weekday)} ${parseInt(day, 10)} ${this.$t(
            month
          )} ${year}`;
          const shareLink = this.shareList.find(sharing => sharing.id === el.context.id);
          const newActivity = {
            performedAt: el.performedAt,
            action: el.action,
            description: el.entity.description,
            mail: shareLink ? shareLink.email : null,
            customName: shareLink ? shareLink.custom_name : null
          };
          const indexSameDate = sortedActivities.findIndex(activity => activity.date === date);
          if (indexSameDate !== -1) {
            sortedActivities[indexSameDate].activities.push(newActivity);
          } else {
            sortedActivities.push({
              activities: [newActivity],
              dateToDisplay,
              date
            });
          }
        });
        this.sortedActivities = sortedActivities;
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    /* WARNING : BRID-2649
     * We must reset every fields that could have been modified by users
     * For the case where a user is in a project and click on another project from notifications
     */
    this.ActivitiesList = undefined;
    // We execute the method clearDate into the component DateFilterDropdown #mindfuck
    this.$refs.dateFilter.clearDate(); // Reset date filter
    this.filterActivities = []; // Reset activity filter
    this.filterContact = []; // Reset contact filter
    // this.$apollo.queries.ActivitiesProjectList.options.variables = () => ({
    //   workspaceId: this.currentWorkspace.id,
    //   projectId: this.$route.params.id,
    //   from: this.oneYearAgo,
    //   to: this.today,
    //   shareIds: [],
    //   page: 1
    // });
    this.$apollo.queries.ActivitiesList.options.variables = () => ({
      workspaceId: this.currentWorkspace.id,
      type: this.entityType,
      from: this.oneYearAgo,
      to: this.today,
      refsIds: this.entityIds,
      page: 1
    });
    next();
  },
  methods: {
    getHour(timestamp) {
      const hour = new Date(timestamp * 1000).toTimeString().slice(0, 5);
      return hour;
    },
    formatAction(action) {
      const formatedAction = {
        raw: action,
        type: null,
        precision: null
      };
      switch (action) {
        case 'opened':
          formatedAction.type = 'Opened';
          break;
        case 'streamed':
          formatedAction.type = 'Streamed';
          break;
        case 'download_project':
          formatedAction.type = 'Downloaded';
          formatedAction.precision = 'entire project';
          break;
        case 'download_submission':
          formatedAction.type = 'Downloaded';
          formatedAction.precision = 'entire submission';
          break;
        case 'download_artist':
          formatedAction.type = 'Downloaded';
          formatedAction.precision = 'artist';
          break;
        case 'download_file':
          formatedAction.type = 'Downloaded';
          break;
        case 'download_original':
          formatedAction.type = 'Downloaded';
          formatedAction.precision = 'in original quality';
          break;
        case 'download_track':
          formatedAction.type = 'Downloaded';
          formatedAction.precision = 'track(s)';
          break;
        case 'download_support':
          formatedAction.type = 'Downloaded';
          formatedAction.precision = 'support';
          break;
        case 'download_mp3':
          formatedAction.type = 'Downloaded';
          formatedAction.precision = 'in mp3';
          break;
        default:
          formatedAction.type = 'Opened';
      }
      return formatedAction;
    },
    toggleActivities(bool, event) {
      if (bool) {
        this.filterActivities.push(event);
      } else {
        this.filterActivities = this.filterActivities.filter(el => el !== event);
      }
      const newVar = this.filterActivities
        ? { actions: this.filterActivities }
        : {
            actions: [
              'download_mp3',
              'download_original',
              'download_file',
              'download_project',
              'streamed',
              'opened'
            ]
          };
      this.$apollo.queries.ActivitiesList.refetch(newVar);
    },
    toggleContact(bool, event) {
      if (bool) {
        this.filterContact.push(event);
      } else {
        this.filterContact = this.filterContact.filter(el => el !== event);
      }
      const newVar = this.filterContact ? { shareIds: this.filterContact } : { shareIds: [] };
      this.$apollo.queries.ActivitiesList.refetch(newVar);
    },
    setDateFilter(temporality, firstDate, secondDate) {
      if (firstDate === null) {
        this.$apollo.queries.ActivitiesList.refetch({
          from: this.oneYearAgo,
          to: this.today
        });
        return;
      }
      if (temporality === 'between' && secondDate === null) {
        this.$apollo.queries.ActivitiesList.refetch({
          from: this.oneYearAgo,
          to: this.today
        });
        return;
      }
      const date = {
        first: {
          begin: firstDate / 1000 - 56820,
          end: firstDate / 1000 - 56820 + 86400
        },
        second: {
          begin: secondDate / 1000 - 56820,
          end: secondDate / 1000 - 56820 + 86400
        }
      };
      const newVar = {};
      switch (temporality) {
        case 'before':
          newVar.to = date.first.begin;
          newVar.from = this.oneYearAgo;
          break;
        case 'after':
          newVar.from = date.first.end;
          newVar.to = this.today;
          break;
        case 'between':
          newVar.from = date.first.begin;
          newVar.to = date.second.end;
          break;
        case 'is':
        default:
          newVar.from = date.first.begin;
          newVar.to = date.first.end;
          break;
      }
      this.$apollo.queries.ActivitiesList.refetch(newVar);
    },
    async loadMore() {
      this.isPending = true;
      this.page += 1;
      try {
        await this.$apollo.queries.ActivitiesList.fetchMore({
          variables: {
            workspaceId: this.currentWorkspace.id,
            projectId: this.$route.params.id,
            page: this.page
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newActivities =
              fetchMoreResult &&
              fetchMoreResult.ActivitiesList &&
              fetchMoreResult.ActivitiesList.data;
            if (newActivities && newActivities.length === 0) {
              this.displayLoadMore = false;
              return previousResult;
            }
            return {
              ActivitiesList: {
                ...previousResult.ActivitiesList,
                data: [...previousResult.ActivitiesList.data, ...newActivities],
                __typename: 'ActivityListResult' // eslint-disable-line
              }
            };
          }
        });
      } catch {} // eslint-disable-line
    },
    openShareModal(action, params) {
      const { defaultTab } = params;
      let modalTitle;
      const { entityType, entityIds } = this;

      switch (entityType) {
        case 'project':
          modalTitle = 'shareProjectName';
          break;
        case 'support':
          modalTitle = 'shareAlbumName';
          break;
        case 'artist':
          modalTitle = 'shareArtistName';
          break;
        case 'track':
          modalTitle = entityIds.length > 1 ? 'shareTracksName' : 'shareTrackName';
          break;

        default:
          break;
      }
      bus.$emit('displayModal', {
        size: 'medium',
        isVisible: true,
        component: ShareModal,
        props: [
          { name: 'entityName', value: this.entityName },
          { name: 'entityIds', value: entityIds },
          { name: 'entityType', value: entityType },
          defaultTab ? { name: 'defaultTab', value: defaultTab } : []
        ].filter(Boolean),
        title: this.$t(modalTitle, { name: this.entityName })
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.activity {
  padding: 0 0 6px 40px;
  &-title {
    margin: 24px 0;
    &-main {
      @include heading-6;
      margin-bottom: 16px;
    }
    &-sub {
      @include body-2;
      color: $color_neutral_60;
    }
  }
  &-filter {
    display: flex;
    margin-bottom: 16px;
    position: sticky;
    top: 0;
    padding-top: 16px;
    padding-bottom: 8px;
    width: 100%;
    background: white;
    &-- {
      width: fit-content;
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
  &-content {
    &-section {
      margin-bottom: 24px;
      &-title {
        @include subtitle-1;
        color: $color_primary_100;
        margin-bottom: 16px;
      }
      &-item:not(:last-child) {
        margin-bottom: 8px;
      }
      &-item-container {
        border-left: 1px solid $color_neutral_30;
        padding-left: 8px;
      }
    }
    &-loadmore {
      margin-bottom: 4px;
    }
  }
  &-noresult {
    // height is all screen minus all the other element (wich total height = 404px)
    // height: calc(99vh - 404px);
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    margin-top: 80px;
    .ri-emotion-unhappy-line {
      color: $color_neutral_40;
      font-size: 33px;
      margin-bottom: 24px;
    }
    &-title {
      @include heading-6;
      margin-bottom: 8px;
    }
    &-subtitle {
      @include body-1;
      color: $color_neutral_60;
      width: 345px;
      text-align: center;
    }
  }
}
.no-activity {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-title {
    @include heading-6;
    margin-bottom: 8px;
  }
  &-subtitle {
    @include body-1;
    color: $color_neutral_60;
    margin-bottom: 24px;
  }
  &-icon {
    font-size: 33px;
    color: $color_neutral_40;
    margin-bottom: 24px;
  }
}
.no-activity-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(99vh - 500px);
}

.loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
