import gql from 'graphql-tag';
import {
  meFragment,
  workspaceTemplate,
  invitationsFragment,
  roleFragment,
  libraryFragment,
  membersFragment,
  subscriptionFragment
} from '../fragments';

export const ME = gql`
  query me {
    me {
      ...me
      workspaces {
        ... on Workspace {
          ${workspaceTemplate}
        }
      }
      hubs {
        id
        name
        logo
        link
      }
    }
  }
  ${meFragment.me}
  ${invitationsFragment.invitations}
  ${roleFragment.role}
  ${libraryFragment.library}
  ${membersFragment.members}
  ${subscriptionFragment.subscription}
`;

export const WORKSPACES_SUBSCRIPTION_FEATURES = gql`
  query me {
    me {
      id
      workspaces {
        id
        subscription {
          id
          features {
            type
            is_available
            configuration {
              type
              options
            }
          }
        }
      }
    }
  }
`;

export const USER_EMAILS = gql`
  query ($status: EmailStatus) {
    UserEmails(status: $status) {
      id
      email
      is_primary
      is_verified
    }
  }
`;

export const BILLS_LIST = gql`
  query BillsList($workspaceId: ID!) {
    BillsList(workspaceId: $workspaceId) {
      id
      date
      subscription_id
      status
      invoice_link
      amount
      currency
      date
    }
  }
`;

export const ME_WORKSPACES = gql`
  query me {
    me {
      id
      workspaces {
        id
        subscription {
          id
          features {
            type
            configuration {
              type
              options
            }
          }
          payment_information {
            status
            price
          }
        }
      }
    }
  }
`;

export const WORKSPACE = gql`
  query Workspace($workspaceId: ID!) {
    Workspace(workspaceId: $workspaceId) {
      ${workspaceTemplate}
    }
  }
  ${invitationsFragment.invitations}
  ${roleFragment.role}
  ${libraryFragment.library}
  ${membersFragment.members}
  ${subscriptionFragment.subscription}
`;

export const ME_WORKSPACES_INFO = gql`
  query me {
    me {
      id
      workspaces {
        id
        name
        logo {
          id
          url
        }
        is_locked
      }
    }
  }
`;
