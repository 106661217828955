
<div class="security">
  <div @click="show = !show" v-if="displayTitle" class="security-title">
    <i class="ri-lock-2-fill"></i>
    <div class="title">{{ $t('security') }}</div>
    <i v-if="show" class="ri-arrow-drop-down-line"></i>
    <i v-else class="ri-arrow-drop-right-line"></i>
  </div>
  <div v-if="displayLinkNameInput && shareCustomName" class="security-link-name">
    <text-field
      class="share-link-name-input"
      inputType="text"
      :label="$t('linkName')"
      :large="false"
      :value="shareLinkName"
      :errorMessage="shareLinkNameErrorMessage"
      @handleInput="handleLinkNameInput"
      @handleBlur="handleLinkNameBlur"
    />
  </div>
  <div v-show="show" class="switch-share-password">
    <switch-button
      ref="switchButton"
      class="security-switch"
      :defaultValue="displayPasswordInput"
      id="sharePassword"
      label="Password protect"
      size="large"
      @click.native.stop
      @toggleSwitchButton="toggleSwitch"
    />
    <div class="password-info" v-tooltip="$t('passwordInfo')">
      <i class="ri-question-fill"></i>
    </div>
  </div>
  <div v-show="show" v-if="displayPasswordInput" class="security-input">
    <text-field
      class="share-password-input"
      inputType="text"
      :helper="$t('accessPasswordHelper')"
      :selectOnLoad="selectOnLoad"
      :large="false"
      :value="securityPassword"
      :errorMessage="securityPasswordErrorMessage"
      @handleInput="handlePasswordInput"
      @handleBlur="handlePasswordBlur"
    />
    <icon-button
      icon="ri-file-copy-line"
      btnStyle="tertiary"
      @submit="copyPassword"
      v-tooltip="$t('copyPassword')"
    />
  </div>
</div>
