<template>
  <div class="container">
    <div :class="['header', { 'header-expanded': this.isExpanded }]">
      <div :class="[{ dots: status === 'processing' || status === 'pending' }]">
        {{ labels[status].header }}
      </div>
      <div>
        <div v-if="status === 'done' || status === 'error'" @click="closeManager" class="close">
          {{ labels.close }}
        </div>
        <i
          @click="toggleExpand"
          :class="isExpanded ? 'ri-arrow-down-s-line' : 'ri-arrow-up-s-line'"
        />
      </div>
    </div>
    <div v-if="isExpanded" class="body">
      <div :class="[{ 'body-error': status === 'error' }]">{{ labels[status].body }}</div>
      <div>
        <spinner v-if="status === 'pending' || status === 'processing'" color="grey" />
        <div class="icon" v-if="status === 'done' || status === 'error'">
          <i
            :class="[
              {
                'ri-check-fill icon-done': status === 'done',
                'ri-alert-fill icon-error': status === 'error'
              }
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '../loaders/spinnerWithoutProgress';

export default {
  components: {
    Spinner
  },
  props: {
    status: {
      default: 'pending',
      type: String,
      required: true
    },
    onClose: {
      type: Function,
      required: true
    },
    labels: {
      type: Object,
      required: true,
      default: () => ({
        close: 'Close',
        pending: {
          header: 'pending',
          body: 'lpl'
        },
        processing: {
          header: 'processing',
          body: 'lpl'
        },
        done: {
          header: 'done',
          body: 'lpl'
        },
        error: {
          header: 'error',
          body: 'lpl'
        }
      })
    }
  },
  data() {
    return {
      isExpanded: true
    };
  },
  methods: {
    closeManager() {
      this.onClose();
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  @include body-1;
  width: 377px;
  user-select: none;
  @include shadow-down-03;
  border-radius: 4px;
}

.header {
  height: 54px;
  padding: 0 16px;
  color: $color_neutral_0;
  background-color: $color_neutral_100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
}

.header-expanded {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.close {
  font-weight: 600;
  color: $color_primary_50;
  display: inline-block;
  padding-right: 40px;
}

.body {
  height: 54px;
  padding: 0 16px;
  color: $color_neutral_100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $color_neutral_0;
}

.body-error {
  color: $color_danger_100;
}

.icon-done {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  font-weight: 600;
  color: $color_neutral_0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color_neutral_0;
  background-color: $color_success_100;
}

.icon-error {
  font-size: 24px;
  color: $color_danger_100;
}

.dots:after {
  animation: dots 2s linear infinite;
  content: '';
  font-weight: bold;
}

@keyframes dots {
  0%,
  20% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60% {
    content: '...';
  }
  90%,
  100% {
    content: '';
  }
}
</style>
