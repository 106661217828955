
<div class="information-banner">
  <div class="icon-info"><i class="ri-error-warning-fill"></i></div>
  <div class="text">
    {{ content }}
  </div>
  <div
    v-tooltip="{
      content: roleId !== 1 ? 'Only administrators can upgrade plan' : '',
      classes: 'upgrade-banner-tooltip',
      position: 'top'
    }"
  >
    <submit-button
      class="upgrade-now-btn"
      btnStyle="tertiary"
      size="small"
      :label="$t('upgradeNow')"
      :disabled="roleId !== 1"
      @submit="goToUpgrade"
    />
  </div>
</div>
