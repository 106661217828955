<template>
  <div class="container">
    <text-field
      class="mr-2 flex-grow"
      inputType="text"
      :errorMessage="mailError"
      :fieldModel.sync="$v.mail.$model"
      :value="$v.mail.$model"
      :placeholder="$t('newEmail')"
      :large="false"
    />
    <text-field
      class="mr-4"
      withVisibility
      inputType="password"
      :errorMessage="null"
      :fieldModel.sync="password"
      :placeholder="$t('password')"
      :large="false"
    />
    <submit-button
      :disabled="disableSave"
      class="mr-1"
      btnStyle="primary"
      size="medium"
      label="Update"
      @submit="handleMailChange"
    />
    <submit-button btnStyle="secondary" size="medium" :label="$t('cancel')" @submit="cancel" />
  </div>
</template>

<script>
import { USER_EMAIL_CHANGE } from '@/graphql/mutations/user';
import { required, email } from 'vuelidate/lib/validators';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import { bus } from '@/utils/bus';

export default {
  props: {
    primaryMail: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      mail: this.primaryMail.email,
      password: null
    };
  },
  validations: {
    mail: {
      required,
      email
    }
  },
  computed: {
    mailError() {
      if (!this.$v.mail.required) {
        return this.$t('required', { label: this.$t('email') });
      }
      if (!this.$v.mail.email) {
        return this.$t('invalidEmail');
      }
      return undefined;
    },
    disableSave() {
      return this.primaryMail.email === this.mail;
    }
  },
  methods: {
    handleMailChange() {
      this.$apollo
        .mutate({
          mutation: USER_EMAIL_CHANGE,
          variables: {
            id: this.primaryMail.id,
            email: this.$v.mail.$model,
            password: this.password
          }
        })
        .then(() => {
          bus.$emit('showAlert', {
            message: { key: 'sentVerificationEmailAlert', args: { mail: this.$v.mail.$model } },
            style: 'success',
            delay: 5000
          });
          this.$emit('togglePrimaryEmailEdit', false);
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    cancel() {
      this.$emit('togglePrimaryEmailEdit', false);
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  width: 100%;
}
.mr-1 {
  margin-right: 4px;
}
.mr-2 {
  margin-right: 8px;
}
.mr-4 {
  margin-right: 16px;
}
.flex-grow {
  flex-grow: 1;
}
</style>
