import gql from 'graphql-tag';

export const SEARCH_TRACKS = gql`
  query SearchTracks(
    $query: SearchTracksQueryInput!
    $filters: SearchTrackFiltersInput
    $sort: SearchSortInput
    $offset: Int
    $limit: Int
  ) {
    SearchTracks(query: $query, filters: $filters, sort: $sort, offset: $offset, limit: $limit) {
      ... on SearchTracksResults {
        total_tracks
        total_artists
        total_supports
        data {
          tracks {
            id
            imageUrl
            title
            addedAt
            artists {
              id
              name
            }
            supports {
              id
              name
              images {
                url
                width
                type
              }
            }
            duration
            rating
          }
        }
      }
    }
  }
`;

export default SEARCH_TRACKS;
