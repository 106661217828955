
<div class="container">
  <h2 class="headline">{{ $t('subscription.headline.cancel') }}</h2>
  <div class="explanations">
    <p>
      {{ isCanceled ? 'Your plan is canceled' : 'If you cancel your subscription' }}, your
      workspace will be downgraded to a free Starter plan, and you will lose access to premium
      features at the end of your billing period : <b>{{ billingDate }}</b
      >.
    </p>
    <p v-if="!isCanceled">
      Otherwise, you can manually remove the content you want from your workspace until it fits
      the free plan limits, and <b>then downgrade your plan</b>.
    </p>
  </div>
  <div class="button" v-tooltip="!this.isOwner ? 'Only workspace owner can perform this action': null">
  <submit-button
    label="Cancel subscription"
    :danger="true"
    btnStyle="secondary"
    @submit="cancelSubscription"
    :disabled="isCanceled || isOwner === false"
  />
</div>
</div>
