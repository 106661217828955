import gql from 'graphql-tag';
import {
  tracksFragment,
  supportsFragment,
  artistsFragment,
  libraryImageFragment,
  streamablesFragment,
  lightTracksFragment
} from '../fragments';

export const LIST_TRACKS = gql`
  query ($libraryId: ID!, $page: Int, $limit: Int, $orderBy: String, $sortBy: String) {
    listTracks(
      libraryId: $libraryId
      page: $page
      limit: $limit
      orderBy: $orderBy
      sortBy: $sortBy
    ) {
      last_page
      data {
        ...tracks
      }
    }
  }
  ${tracksFragment.tracks}
`;

export const LIST_TRACKS_LIGHT = gql`
  query listTrackLight(
    $libraryId: ID!
    $page: Int
    $limit: Int
    $orderBy: String
    $sortBy: String
  ) {
    listTracks(
      libraryId: $libraryId
      page: $page
      limit: $limit
      orderBy: $orderBy
      sortBy: $sortBy
    ) {
      last_page
      data {
        ...lightTracks
      }
    }
  }
  ${lightTracksFragment.lightTracks}
`;

export const SUPPORT_LIST = gql`
  query listSupports($libraryId: ID!, $page: Int, $limit: Int, $orderBy: String, $sortBy: String) {
    supportList(
      libraryId: $libraryId
      page: $page
      limit: $limit
      orderBy: $orderBy
      sortBy: $sortBy
    ) {
      last_page
      data {
        id
        name
        images {
          ...libraryImage
        }
        artists {
          id
          name
        }
      }
    }
  }
  ${libraryImageFragment.libraryImage}
`;

export const LIST_ARTISTS = gql`
  query listArtists($libraryId: ID!, $page: Int, $limit: Int, $orderBy: String, $sortBy: String) {
    listArtists(
      libraryId: $libraryId
      page: $page
      limit: $limit
      orderBy: $orderBy
      sortBy: $sortBy
    ) {
      last_page
      data {
        ...artists
      }
    }
  }
  ${artistsFragment.artists}
`;

export const LIST_AUTHORS = gql`
  query AuthorsList($workspaceId: ID!, $page: Int, $limit: Int, $sortBy: String, $sortDir: String) {
    AuthorsList(
      workspaceId: $workspaceId
      page: $page
      limit: $limit
      sortBy: $sortBy
      sortDir: $sortDir
    ) {
      data {
        id
        name
      }
    }
  }
`;

export const LIST_LABEL = gql`
  query LabelsList($workspaceId: ID!, $page: Int, $limit: Int, $sortBy: String, $sortDir: String) {
    LabelsList(
      workspaceId: $workspaceId
      page: $page
      limit: $limit
      sortBy: $sortBy
      sortDir: $sortDir
    ) {
      data {
        id
        name
      }
    }
  }
`;

export const LIST_COMPOSERS = gql`
  query ComposersList(
    $workspaceId: ID!
    $page: Int
    $limit: Int
    $sortBy: String
    $sortDir: String
  ) {
    ComposersList(
      workspaceId: $workspaceId
      page: $page
      limit: $limit
      sortBy: $sortBy
      sortDir: $sortDir
    ) {
      data {
        id
        name
      }
    }
  }
`;

export const LIST_PUBLISHERS = gql`
  query PublishersList(
    $workspaceId: ID!
    $page: Int
    $limit: Int
    $sortBy: String
    $sortDir: String
  ) {
    PublishersList(
      workspaceId: $workspaceId
      page: $page
      limit: $limit
      sortBy: $sortBy
      sortDir: $sortDir
    ) {
      data {
        id
        name
      }
    }
  }
`;

export const GET_DOWNLOAD_FILE_LINK = gql`
  query getDownloadFileLink($libraryId: ID!, $libraryFileId: ID!) {
    getDownloadFileLink(libraryId: $libraryId, libraryFileId: $libraryFileId) {
      url
      expire_at
    }
  }
`;

export const ARTIST_DETAILS = gql`
  query artistDetails($libraryId: ID!, $artistId: ID!) {
    artistDetails(libraryId: $libraryId, artistId: $artistId) {
      id
      name
      slug
      image {
        ...libraryImage
      }
      supports_number
      tracks_number
      tracks_length
      tracks_ids
      supports {
        ...supports
      }
    }
  }
  ${supportsFragment.supports}
  ${libraryImageFragment.libraryImage}
`;

export const SUPPORT_DETAILS = gql`
  query supportDetails($libraryId: ID!, $supportId: ID!) {
    supportDetails(libraryId: $libraryId, supportId: $supportId) {
      id
      name
      slug
      images {
        ...libraryImage
      }
      artists {
        ...artists
      }
      type
      upc
      release_date
      tracks_number
      tracks_total
      tracks_length
      tracks_ids
    }
  }
  ${libraryImageFragment.libraryImage}
  ${artistsFragment.artists}
`;

export const LIBRARY_PLAY_ASSETS = gql`
  query LibraryPlayAssets($libraryId: ID!, $libraryFileId: ID!, $audioQuality: AudioQuality) {
    LibraryPlayAssets(
      libraryId: $libraryId
      libraryFileId: $libraryFileId
      audioQuality: $audioQuality
    ) {
      ...streamables
    }
  }
  ${streamablesFragment.streamables}
`;

export const INBOX_PLAY_ASSETS = gql`
  query InboxPlayAsset($submissionTrackId: ID!, $audioQuality: AudioQuality) {
    InboxPlayAsset(submissionTrackId: $submissionTrackId, audioQuality: $audioQuality) {
      ...streamables
    }
  }
  ${streamablesFragment.streamables}
`;

export const LIBRARY_FILES_DETAILS = gql`
  query LibraryFilesDetails($libraryFilesIds: [ID!]) {
    LibraryFilesDetails(libraryFilesIds: $libraryFilesIds) {
      ...tracks
    }
  }
  ${tracksFragment.tracks}
`;

export const LIBRARY_FILES_DETAILS_LIGHT = gql`
  query LibraryFilesDetails($libraryFilesIds: [ID!]) {
    LibraryFilesDetails(libraryFilesIds: $libraryFilesIds) {
      ...lightTracks
    }
  }
  ${lightTracksFragment.lightTracks}
`;

export const LIBRARY_FILE_STREAMABLE = gql`
  query LibraryFilesDetails($libraryFilesIds: ID!) {
    LibraryFilesDetails(libraryFilesIds: [$libraryFilesIds]) {
      id
      status_streamable_transcoded
    }
  }
`;

export const ARTISTS_AUTOCOMPLETE = gql`
  query ArtistsAutocomplete($workspaceId: ID!, $search: String!, $limit: Int) {
    ArtistsAutocomplete(workspaceId: $workspaceId, search: $search, limit: $limit) {
      ...artists
    }
  }
  ${artistsFragment.artists}
`;

export const SUPPORTS_AUTOCOMPLETE = gql`
  query SupportsAutocomplete($libraryId: ID!, $workspaceId: ID!, $search: String!, $limit: Int) {
    SupportsAutocomplete(
      libraryId: $libraryId
      workspaceId: $workspaceId
      search: $search
      limit: $limit
    ) {
      ...supports
    }
  }
  ${supportsFragment.supports}
`;

export const AUTHORS_AUTOCOMPLETE = gql`
  query AuthorsAutocomplete($workspaceId: ID!, $search: String!, $limit: Int) {
    AuthorsAutocomplete(workspaceId: $workspaceId, search: $search, limit: $limit) {
      id
      name
      __typename
    }
  }
`;

export const COMPOSERS_AUTOCOMPLETE = gql`
  query ComposersAutocomplete($workspaceId: ID!, $search: String!, $limit: Int) {
    ComposersAutocomplete(workspaceId: $workspaceId, search: $search, limit: $limit) {
      id
      name
      __typename
    }
  }
`;

export const LABELS_AUTOCOMPLETE = gql`
  query LabelsAutocomplete($workspaceId: ID!, $search: String!, $limit: Int) {
    LabelsAutocomplete(workspaceId: $workspaceId, search: $search, limit: $limit) {
      id
      name
      __typename
    }
  }
`;

export const PUBLISHERS_AUTOCOMPLETE = gql`
  query PublishersAutocomplete($workspaceId: ID!, $search: String!, $limit: Int) {
    PublishersAutocomplete(workspaceId: $workspaceId, search: $search, limit: $limit) {
      id
      name
      __typename
    }
  }
`;
