
<div v-if="isVisible" :class="`alert ${alertStyle}`">
  <div class="alert-msg">
    <i v-if="iconClass" :class="`alert-msg-icon ${iconClass}`" />
    <div>{{ message }}</div>
  </div>
  <div v-if="actionLabel" class="alert-action">
    <div class="alert-action-separator" />
    <submit-button
      class="alert-action-button"
      :class="{ 'is-countdown': actionCountdown }"
      :style="`animationDuration: ${actionCountdown}ms; animationDelay: ${animationDelay}ms`"
      btnStyle="tertiary"
      size="small"
      :label="actionLabel"
      :disabled="actionDisabled"
      @submit="$emit('handleAlertClick')"
    />
  </div>
  <div class="alert-close" v-if="!hideDismissible">
    <div class="alert-action-separator" />
    <icon-button
      class="alert-close-btn"
      icon="ri-close-fill"
      btnStyle="tertiary"
      size="small"
      @submit="$emit('closeAlert', false)"
    />
  </div>
</div>
