<template>
  <div class="security">
    <div @click="show = !show" v-if="displayTitle" class="security-title">
      <i class="ri-lock-2-fill"></i>
      <div class="title">{{ $t('security') }}</div>
      <i v-if="show" class="ri-arrow-drop-down-line"></i>
      <i v-else class="ri-arrow-drop-right-line"></i>
    </div>
    <div v-if="displayLinkNameInput && shareCustomName" class="security-link-name">
      <text-field
        class="share-link-name-input"
        inputType="text"
        :label="$t('linkName')"
        :large="false"
        :value="shareLinkName"
        :errorMessage="shareLinkNameErrorMessage"
        @handleInput="handleLinkNameInput"
        @handleBlur="handleLinkNameBlur"
      />
    </div>
    <div v-show="show" class="switch-share-password">
      <switch-button
        ref="switchButton"
        class="security-switch"
        :defaultValue="displayPasswordInput"
        id="sharePassword"
        label="Password protect"
        size="large"
        @click.native.stop
        @toggleSwitchButton="toggleSwitch"
      />
      <div class="password-info" v-tooltip="$t('passwordInfo')">
        <i class="ri-question-fill"></i>
      </div>
    </div>
    <div v-show="show" v-if="displayPasswordInput" class="security-input">
      <text-field
        class="share-password-input"
        inputType="text"
        :helper="$t('accessPasswordHelper')"
        :selectOnLoad="selectOnLoad"
        :large="false"
        :value="securityPassword"
        :errorMessage="securityPasswordErrorMessage"
        @handleInput="handlePasswordInput"
        @handleBlur="handlePasswordBlur"
      />
      <icon-button
        icon="ri-file-copy-line"
        btnStyle="tertiary"
        @submit="copyPassword"
        v-tooltip="$t('copyPassword')"
      />
    </div>
  </div>
</template>

<script>
import { bus } from '@/utils/bus';

export default {
  props: {
    switchDefaultValue: {
      type: Boolean,
      required: false,
      default: false
    },
    shareLinkName: {
      type: String,
      required: false,
      default: ''
    },
    securityPassword: {
      type: String,
      required: false,
      default: null
    },
    displayLinkNameInput: {
      type: Boolean,
      required: false
    },
    displayPasswordInput: {
      type: Boolean,
      required: true
    },
    securityPasswordErrorMessage: {
      type: String,
      required: false,
      default: ''
    },
    selectOnLoad: {
      type: Boolean,
      required: false,
      default: true
    },
    displayTitle: {
      type: Boolean,
      required: false,
      default: true
    },
    shareCustomName: {
      type: String,
      required: false,
      default: ''
    },
    shareLinkNameErrorMessage: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      show: true
    };
  },
  methods: {
    handleLinkNameInput(value) {
      this.$emit('update:shareLinkName', value);
    },
    handlePasswordInput(value) {
      this.$emit('update:securityPassword', value);
    },
    toggleSwitch() {
      this.$emit('toggleSwitch');
    },
    handleLinkNameBlur() {
      this.$emit('handleBlur');
    },
    handlePasswordBlur() {
      this.$emit('handleBlur');
    },
    hardToggleSwitchButton() {
      // used by ref in parents
      if (this.$refs.switchButton) {
        this.$refs.switchButton.toggleButton();
      }
    },
    copyPassword() {
      bus.$emit('showAlert', {
        message: { key: 'passwordCopied' },
        style: 'success',
        delay: 5000
      });
      const dummy = document.createElement('textarea');
      document.body.appendChild(dummy);
      dummy.value = this.securityPassword;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
    }
  }
};
</script>

<style lang="scss" scoped>
.security {
  padding: 0 24px 16px 24px;
  &-title {
    display: flex;
    @include subtitle-1;
    cursor: pointer;
    &:hover {
      .title {
        text-decoration: underline;
      }
    }
    i {
      font-weight: normal;
      margin-right: 4px;
    }
  }
  &-switch {
    height: 20px;
    width: fit-content;
    margin-right: 8px;
  }
  &-input {
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;
    .share-password-input {
      flex: 1;
      margin-right: 8px;
    }
  }
  &-link-name {
    display: flex;
    align-items: flex-end;
    margin-bottom: 32px;
    .share-link-name-input {
      flex: 1;
      margin-right: 48px;
    }
  }
  .switch-share-password {
    display: flex;
    align-items: center;
    margin: 16px 0 8px -10px;
    .password-info {
      color: $color_neutral_40;
    }
    .password-new {
      margin-left: 18px;
    }
  }
}
.title {
  margin-right: 8px;
}
.ri-arrow-drop-down-line,
.ri-arrow-drop-right-line {
  font-size: 20px;
}
</style>
