
<div :key="renderKey">
  <div v-if="label" class="title">{{ label }}</div>
  <div class="selectbox" v-click-outside="{ hide: closeExpand }">
    <div :class="selectClass" @click="openExpand">
      <input class="input" type="text" v-model="query" :placeholder="placeholder" />
      <i class="ri-arrow-drop-down-line" />
    </div>
    <div
      class="selectbox-expanded"
      :style="`height: ${height}; top: 42px; opacity: ${isExpanded ? 1 : 0}`"
    >
      <div v-if="filterQuery.length">
        <div
          class="selectbox-expanded-option"
          v-for="(option, index) in filterQuery"
          :key="`d-option-${index}`"
        >
          <div class="selectbox-expanded-option-label">
            <expandable
              :title="option.name"
              :subtitle="getCountCategory(option) > 0 ? `(${getCountCategory(option)})` : null"
              :dotColor="option.background_color"
              :level="1"
              v-if="option.families.length > 0"
            >
              <div v-for="(family, familyIndex) in option.families" v-bind:key="familyIndex">
                <expandable
                  :title="family.name"
                  :subtitle="
                    getCountFamily(family, option.id) > 0
                      ? `(${getCountFamily(family, option.id)})`
                      : null
                  "
                  :level="2"
                >
                  <checkbox
                    class="selectbox-expanded-option-label-checkbox"
                    v-for="(tag, tagIndex) in family.tags"
                    :index="index"
                    :label="tag.name"
                    :id="`${option.id}-${family.id}-${tag.id}`"
                    :selectedItems="checkedOptions"
                    large
                    :key="`${tag.name}-${tagIndex}`"
                    @toggleCheckbox="toggleTag"
                  />
                </expandable>
              </div>
            </expandable>
            <p class="selectbox-expanded-option-label-empty" v-else>{{ option.name }}</p>
          </div>
        </div>
      </div>
      <div v-else class="no-result">
        <div class="no-result--">
          {{ noResultLabel }} "<span>{{ query }}</span
          >"
        </div>
      </div>
    </div>
    <div v-if="errorMessage && showError" class="selectbox-error">{{ errorMessage }}</div>
  </div>
</div>
