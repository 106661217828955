<template>
  <div class="tracks">
    <div v-if="isLibraryEmpty">
      <empty-library
        :title="$t('libraryEmptyTitle')"
        :description="$t('libraryEmptyHelper')"
        :helper="$t('dragAndDrop')"
        placeholderType="tracklist"
        :cloudedTextContent="$t('addAudioFiles')"
        :cloudedTextColor="{
          stroke: '#2F43DE',
          fill: '#E0E3FA'
        }"
      >
        <template v-slot:button>
          <submit-button
            size="large"
            :label="$t('upload')"
            iconClass="ri-upload-cloud-line"
            browse
            multiple
            :accept="allowedFileTypes"
            @submit="handleClickUploadButton"
          />
        </template>
      </empty-library>
    </div>
    <div v-else>
      <div :class="['tracks-edition', editMode ? 'tracks-edition-open' : '']">
        <div class="tracks-edition-wrapper">
          <edit-tracks-panel
            v-if="editMode"
            ref="editTracksPanel"
            :libraryFileIds="libraryFileIds"
            :trackList="trackList"
            @toggleEditMode="toggleEditMode"
          />
        </div>
      </div>
      <div v-show="!trackList" class="loader">
        <spinner-without-progress color="grey" :size="32" />
      </div>
      <router-view
        v-show="trackList"
        :editMode="editMode"
        :keepProjectDropdownExpand="keepProjectDropdownExpand"
        :uncheckTracks="uncheckTracks"
        @openConfirmDeleteModal="openConfirmDeleteModal"
        @createProject="createProject"
        @toggleEditMode="toggleEditMode"
        @setTrackList="setTrackList"
        @sortTracks="sortTracks"
      ></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import EmptyLibrary from '@/containers/emptyView';
import EditTracksPanel from '@/containers/editTracksPanel';
import ConfirmDeleteModal from '@/containers/modals/confirmDelete';
import CreateProjectModal from '@/containers/modals/createProject';
import DeleteMultipleTracksModal from '@/containers/modals/deleteMultipleTracks';
import { LIST_TRACKS_LIGHT } from '@/graphql/queries/library';
import { SEARCH_TRACKS } from '@/graphql/queries/search';
import { DELETE_LIBRARY_FILE } from '@/graphql/mutations/library';
import { WORKSPACE } from '@/graphql/queries/user';
import { bus } from '@/utils/bus';
import { getAllowedFileTypes } from '@/utils/functions/audio';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import { PAGINATOR_LIMIT } from '@/utils/constants';
import { apolloClient } from '@/main';

export default {
  components: {
    EmptyLibrary,
    EditTracksPanel
  },
  data() {
    return {
      editMode: false,
      orderBy: 'created',
      sortBy: 'desc',
      trackList: null,
      keepProjectDropdownExpand: false,
      uncheckTracks: false,
      libraryFileIds: []
    };
  },
  computed: {
    ...mapGetters([
      'currentWorkspace',
      'isLibraryEmpty',
      'playlist',
      'isWorkspaceLocked',
      'searchLibraryQueryParameters'
    ]),
    allowedFileTypes() {
      return getAllowedFileTypes(this.$config).join(',');
    }
  },
  created() {
    bus.$off('deleteTracksFromLibrary');
    // When editing a track, go to the requested route after saving with the modal
    bus.$on('handleTracksEditMutationDone', () => {
      if (this.nextRoute) this.nextRoute();
    });
    // When editing a track, go to the requested route after canceling with the modal
    bus.$on('handleTracksEditMutationCanceled', () => {
      if (this.nextRoute) this.nextRoute();
    });
  },
  mounted() {
    bus.$on('deleteTracksFromLibrary', trackToDelete => {
      this.updateTrackListCache(trackToDelete, true);
    });
    // When a modal is displayed and projectDropdown visible, we restore previous state to keep
    // dropdown opened
    bus.$on('closeModal', () => {
      setTimeout(() => {
        this.keepProjectDropdownExpand = false;
      }, 500);
    });
  },
  beforeRouteLeave(to, from, next) {
    // Set the requested route
    this.nextRoute = () => next();
    if (this.$refs.editTracksPanel && !this.$refs.editTracksPanel.isSaveButtonDisabled) {
      /* IMPORTANT
         For a Locked workspace we don't want to prevent the user to change the page as
         he can click on the upgrade button of the alert
      */
      if (this.isWorkspaceLocked) this.nextRoute();
      // Is some changes in the edit tracks panel are not saved, show the save changes modal before leaving the route
      this.$refs.editTracksPanel.showModalForContinueEditing();
    } else {
      this.nextRoute();
    }
  },
  beforeDestroy() {
    // BRID-3936 : We clear the trackList cache
    //             We only keep the 40 first tracks in cache when we exits
    const trackQueryVariables = {
      libraryId: this.currentWorkspace.libraries[0].id,
      page: 1,
      limit: PAGINATOR_LIMIT,
      orderBy: 'created',
      sortBy: 'desc'
    };
    try {
      const result = apolloClient.readQuery({
        query: LIST_TRACKS_LIGHT,
        variables: trackQueryVariables,
        errorPolicy: 'ignore'
      });
      apolloClient.writeQuery({
        query: LIST_TRACKS_LIGHT,
        variables: trackQueryVariables,
        data: {
          listTracks: {
            ...result.listTracks,
            data: result.listTracks.data.slice(0, 40)
          }
        }
      });
    } catch {
      // console.error('error: no tracklist');
    }
    bus.$off('handleTracksEditMutationDone');
    bus.$off('handleTracksEditMutationCanceled');
    bus.$off('closeModal');
    bus.$off('deleteTracksFromLibrary');
  },
  methods: {
    handleClickUploadButton(files) {
      bus.$emit('prepareUploadForFiles', files);
    },
    setTrackList(trackList) {
      this.trackList = trackList;
    },
    sortTracks(sortBy, orderBy) {
      this.sortBy = sortBy;
      this.orderBy = orderBy;
    },
    openConfirmDeleteModal(libraryFileIds) {
      if (libraryFileIds.length > 1) {
        bus.$emit('displayModal', {
          title: this.$t('deleteTracks'),
          size: 'medium',
          component: DeleteMultipleTracksModal,
          onSubmit: () => this.handleDeleteTrackMutation(libraryFileIds),
          isVisible: true,
          props: [{ name: 'tracksNumber', value: libraryFileIds.length }]
        });
        this.uncheckTracks = false;
        return;
      }
      bus.$emit('displayModal', {
        title: this.$t('deleteTrack'),
        size: 'medium',
        component: ConfirmDeleteModal,
        onSubmit: () => this.handleDeleteTrackMutation(libraryFileIds),
        msg: 'confirmDeleteHard',
        props: [
          {
            name: 'tracksName',
            value: this.trackList.find(el => el.id === libraryFileIds[0]).title
          }
        ],
        isVisible: true
      });
      this.uncheckTracks = false;
    },
    createProject() {
      this.keepProjectDropdownExpand = true;
      bus.$emit('displayModal', {
        component: CreateProjectModal,
        title: this.$t('newProject'),
        size: 'medium',
        isVisible: true
      });
    },
    toggleEditMode(libraryFileIds) {
      this.libraryFileIds = libraryFileIds;
      const isEditPanelOpened = libraryFileIds.length > 0;
      this.$store.commit('setEditPanelOpened', isEditPanelOpened);
      this.editMode = isEditPanelOpened;
    },
    handleDeleteTrackMutation(libraryFileIds) {
      const libraryId = this.currentWorkspace.libraries[0].id;
      this.$apollo
        .mutate({
          mutation: DELETE_LIBRARY_FILE,
          variables: {
            libraryId,
            libraryFileIds
          },
          update: () => {
            this.updateTrackListCache(libraryFileIds);
          },
          refetchQueries: [
            {
              query: WORKSPACE,
              variables: {
                workspaceId: this.currentWorkspace.id
              }
            }
          ]
        })
        .then(() => {
          bus.$emit('showAlert', {
            message: {
              key: 'deleteTracksSuccess',
              params: libraryFileIds.length
            },
            style: 'success',
            delay: 5000
          });
          this.uncheckTracks = true;
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    updateTrackListCache(libraryFileIds, fromEmit) {
      const libraryId = this.currentWorkspace.libraries[0].id;
      if (this.$router.currentRoute.name === 'trackSearch') {
        const searchVariables = {
          ...this.searchLibraryQueryParameters,
          version: 2,
          offset: 0, // NB : This may cause issue but no better solution ATM
          limit: PAGINATOR_LIMIT,
          sort: {
            by: 'added_at',
            dir: this.sortBy
          }
        };
        const searchTracks = apolloClient.readQuery({
          query: SEARCH_TRACKS,
          key: 'SearchTracks',
          variables: searchVariables,
          pagination: 'offset'
        });
        const updatedSearchTracks = { ...searchTracks };
        libraryFileIds.forEach(deletedFileId => {
          const indexToDelete = updatedSearchTracks.SearchTracks.data.tracks.findIndex(
            r => r.id === deletedFileId
          );
          updatedSearchTracks.SearchTracks.data.tracks.splice(indexToDelete, 1);
        });
        apolloClient.writeQuery({
          query: SEARCH_TRACKS,
          data: updatedSearchTracks,
          variables: searchVariables
        });
      }
      // WE MUST AS WELL UPDATE THE TRACKLIST IF WE ARE ON SEARCH
      const listTrackVariables = {
        libraryId,
        page: 1,
        limit: PAGINATOR_LIMIT,
        orderBy: this.orderBy,
        sortBy: this.sortBy
      };
      const listTracks = apolloClient.readQuery({
        query: LIST_TRACKS_LIGHT,
        variables: listTrackVariables
      });
      const updatedListTrack = { ...listTracks };
      libraryFileIds.forEach(deletedFileId => {
        const indexToDelete = updatedListTrack.listTracks.data.findIndex(
          r => r.id === deletedFileId
        );
        updatedListTrack.listTracks.data.splice(indexToDelete, 1);
      });
      apolloClient.writeQuery({
        query: LIST_TRACKS_LIGHT,
        data: updatedListTrack,
        variables: listTrackVariables
      });
      if (fromEmit) {
        this.uncheckTracks = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tracks {
  margin: 0 0 80px 0;

  &-edition {
    background-color: $color_neutral_0;
    position: fixed;
    top: 0;
    z-index: map-get($z-index, side-panel);
    right: -520px;
    transition: right 0.15s ease;
    height: calc(100vh - 65px);
    overflow: auto;
    border-left: 1px solid $color_neutral_30;
    border-top: 1px solid $color_neutral_30;
    &-open {
      right: 0;
      @include shadow-down-01;
    }
    &-wrapper {
      height: 100%;
      width: 518px;
    }
  }
}

.loader {
  height: calc(100vh - 56px - 65px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
