<template>
  <div class="player-container" :style="playlist.length > 0 && 'bottom: 0;'">
    <div v-if="Object.keys(playlist).length > 0" class="wrapper">
      <player
        ref="player"
        :tracks="Object.values(playlist)"
        :playingTrack="playingTrack"
        :audioSrcIsLoaded="audioSrcIsLoaded"
        :isOnError="isPlayerOnError"
        @setPlayingTrack="setPlayingTrack"
        @navigateTo="navigateTo"
      />
      <dropdown
        ref="playerDropdown"
        class="player-dropdown"
        :definedPosition="{ bottom: 90 }"
        @setIsDropdownExpanded="setIsDropdownExpanded"
      >
        <template #button>
          <submit-button
            class="streaming-audio-quality-btn"
            v-tooltip="{
              content: $t('settings'),
              position: 'left'
            }"
            btnStyle="tertiary"
            size="small"
            :label="qualityBtnLabel"
          />
        </template>
        <div class="streaming-audio-quality-menu">
          <div class="header-info">
            <div class="title">Streaming audio quality</div>
            <a
              class="info-link"
              href="https://www.notion.so/bridgeaudio/Audio-Player-432ae6c28bd647b981a123391933fe3a"
              target="_blank"
              rel="noopener"
              >More info</a
            >
          </div>
          <div
            :class="{ 'option-container': true, 'selected-option': option.selected }"
            v-for="(option, id) in streamingAudioQualityOptions"
            :key="`SAQ-option-${id}`"
            @click="option.action"
          >
            <div>
              <div class="option-label">{{ option.label }}</div>
              <div class="option-desc">{{ option.desc }}</div>
            </div>
            <div class="check-container">
              <i v-if="option.selected" class="ri-check-fill" />
            </div>
          </div>
        </div>
      </dropdown>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { VTooltip } from 'v-tooltip';
import { mapGetters } from 'vuex';
import get from 'lodash.get';
import { LIBRARY_PLAY_ASSETS, INBOX_PLAY_ASSETS } from '@/graphql/queries/library';
import { bus } from '@/utils/bus';
import { USER_STREAMING_QUALITY } from '@/utils/constants';
import { trackEvent } from '@/utils/functions/analytics';
import { get as getRoute } from '@/router/routes';
import { clickOutside } from '@/utils/directives';

Vue.directive('tooltip', VTooltip);

export default {
  data() {
    return {
      audioSrcIsLoaded: false,
      selectedQuality: localStorage.getItem('USER_STREAMING_QUALITY') || 'normal',
      isExpanded: false,
      qualityChangedOnPause: false
    };
  },
  directives: {
    clickOutside
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'playingTrack', 'playlist', 'userId', 'isPlayerOnError']),
    streamingAudioQualityOptions() {
      return [
        {
          label: 'Normal',
          desc: 'Reduced data usage for slower connections',
          value: 'normal',
          selected: this.selectedQuality === 'normal',
          action: () => {
            this.selectQuality('normal');
          }
        },
        {
          label: 'High',
          desc: 'Best compromise between data usage and sound fidelity',
          value: 'high',
          selected: this.selectedQuality === 'high',
          action: () => {
            this.selectQuality('high');
          }
        }
      ];
    },
    qualityBtnLabel() {
      let label = 'Normal';
      switch (this.selectedQuality) {
        case 'high':
          label = 'High';
          break;
        case 'normal':
        default:
          label = 'Normal';
          break;
      }
      return label;
    }
  },
  created() {
    bus.$on('restartAudio', () => {
      this.restartAudio();
    });
  },
  watch: {
    /* eslint-disable */
    'playingTrack.id': function () {
      this.playTrack();
    }
  },
  methods: {
    playTrack(playTo) {
      this.audioSrcIsLoaded = false;
      const mutation = this.playingTrack.isInbox ? INBOX_PLAY_ASSETS : LIBRARY_PLAY_ASSETS;
      const audioQualitySetting = localStorage.getItem('USER_STREAMING_QUALITY') || 'normal';
      const variables = this.playingTrack.isInbox
        ? { submissionTrackId: this.playingTrack.id, audioQuality: audioQualitySetting }
        : {
            libraryId: this.currentWorkspace.libraries[0].id,
            libraryFileId: this.playingTrack.id,
            audioQuality: audioQualitySetting
          };
      const keyResponse = this.playingTrack.isInbox ? 'InboxPlayAsset' : 'LibraryPlayAssets';
      this.$store.commit('changeIsPlayerOnError', false);
      this.$apollo
        .mutate({
          mutation,
          variables
        })
        .then(response => {
          const url = get(response, `data.${keyResponse}[0].url`, null);
          this.$set(this.playlist[this.playingTrack.id], 'audioSrc', url);
          const waveform = get(response, `data.${keyResponse}[0].waveform`, null);
          if (waveform) {
            this.$set(this.playlist[this.playingTrack.id], 'waveform', waveform.split(','));
          }
          this.audioSrcIsLoaded = true;
          if (playTo) {
            this.$refs.player.setPlayTo(playTo);
          } else {
            bus.$emit('inboxLogPlay', this.playingTrack.inboxId);
            trackEvent('Player Track Play', {
              category: 'player',
              streaming_quality: audioQualitySetting,
              origin: 'webapp'
            });
          }
        })
        .catch(() => {
          this.audioSrcIsLoaded = false;
          this.$store.commit('changeIsPlayerOnError', true);
          bus.$emit('showAlert', {
            message: { key: 'playerStateError' },
            style: 'danger',
            delay: 5000,
            error: true,
            hideDismissible: true
          });
        });
    },
    setIsDropdownExpanded(bool) {
      this.isExpanded = bool;
      if (this.isExpanded === true) {
        trackEvent('Player Settings Open', {
          category: 'player',
          origin: 'webapp'
        });
      }
    },
    selectQuality(value) {
      this.selectedQuality = value;
      localStorage.setItem(USER_STREAMING_QUALITY, this.selectedQuality);
      this.$refs.playerDropdown.hide();
      if (this.playingTrack.id !== undefined && this.playingTrack.play) {
        this.playTrack(this.$refs.player.waveform.getCurrentTime());
      }
      if (!this.playingTrack.play) {
        this.qualityChangedOnPause = true;
      }
    },
    setPlayingTrack(track) {
      if (this.qualityChangedOnPause && track.play && track.id === this.playingTrack.id) {
        this.playTrack(this.$refs.player.waveform.getCurrentTime());
      }
      this.$store.commit('changePlayingTrack', track);
      this.qualityChangedOnPause = false;
    },
    navigateTo(param) {
      const { id, name } = param;
      const route = id ? getRoute(name, { id }) : getRoute(name);
      if (this.$router.history.current.name !== name) this.$router.push(route);
    },
    restartAudio() {
      this.$refs.player.setWaveformToZero();
    }
  }
};
</script>

<style lang="scss" scoped>
.player-container {
  position: relative;
  flex-grow: 1;
}

.player-dropdown {
  padding-right: 90px;
  background: $color_neutral_0;
  display: flex;
  align-items: center;
}

.tooltip {
  padding-left: 56px;
  margin-left: -56px;
  left: -56px;
}

.tooltip .tooltip-inner {
  background-color: red;
}

.radio-button-container {
  display: flex;
  flex-direction: column;
}

.wrapper {
  bottom: -64px;
  transition: bottom 300ms linear;
  display: flex;
  flex-direction: row;
}

.streaming-audio-quality-menu {
  width: 343px;
  box-sizing: border-box;
  margin-right: 41px;
  border: 1px solid $color_neutral_30;
  @include shadow-down-03;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: $color_neutral_0;
  font-size: 14px;
  padding: 24px;
  .header-info {
    margin-bottom: 24px;
  }
  .title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  .info-link {
    cursor: pointer;
    font-size: 12px;
  }
  .option-container {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .option-label {
      font-weight: 600;
      margin-bottom: 4px;
    }
    .option-desc {
      color: $color_neutral_60;
    }
    .check-container {
      width: 24px;
      flex-shrink: 0;
      i {
        font-size: 24px;
        color: $color_blue_100;
      }
    }
  }
  .selected-option {
    .option-label {
      color: $color_blue_100;
    }
  }
}
</style>

<style lang="scss">
.streaming-audio-quality-btn.button.tertiary.small.no-icon {
  width: 54px;
  font-weight: 600;
  color: $color_blue_100;
  &:hover {
    background: $color_blue_10;
  }
}
</style>
