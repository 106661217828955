
<div class="row">
  <div
    class="row-body"
    :class="[{ 'row-deleted': item.deleted }]"
    :style="gridTemplateColumns"
    @mouseenter="setIsHoverId(item.id, true)"
    @mouseleave="setIsHoverId(null, false)"
  >
    <div
      v-for="(column, idx) in columnsFiltered"
      :class="{
        'row-body-cell': true,
        'row-body-no-border': column.noBorder,
        'row-body-centered': column.centered,
        'row-body-selected': selectedItems.includes(item.id) && !column.noBorder,
        'row-body-playing':
          !column.noBorder && playingTrack.id && item.id && item.id === playingTrack.id
      }"
      :style="[
        { height: `${height}px` },
        {
          padding: column.padding
            ? `${column.padding[0]}px ${column.padding[1]}px ${column.padding[2]}px ${column.padding[3]}px`
            : 'unset'
        },
        {
          gridColumn:
            column.span && !isHover && !expandedItemId ? `span ${column.span} / auto` : 'unset'
        }
      ]"
      :key="`${item.id}-${idx}`"
      @click.prevent="toggleCheckbox(column.name, item.id, $event)"
      @dblclick.prevent="handleDblclickRow(item.id, column.name)"
    >
      <div
        class="low-opacity"
        v-if="column.type === 'component'"
        @mouseenter="toggleTooltip(column, true)"
        @mouseleave="toggleTooltip(column, false)"
        :class="{
          'row-body-cell-hover': column.isHover && isNoItemsSelected,
          'row-body-cell-expand-hover': column.isExpandHover && expandedItemId !== item.id
        }"
        :style="{
          height: column.containerHeight ? height : 'auto',
          width: column.containerWidth ? '100%' : 'auto'
        }"
        v-tooltip="{
          content: column.dynamicTooltip ? item[column.dynamicTooltip] : column.tooltip,
          show: isHover && tooltipList[column.name],
          hideOnTargetClick: true,
          delay: { show: 100, hide: 0 }
        }"
        :key="`${item.id}-${idx}-component`"
      >
        <div v-if="item.deleted">
          <div v-if="column.isExpandHover || column.isHover"></div>
          <div v-else-if="column.name === 'artwork'" class="placeholder-img-container">
            <img class="placeholder-img" src="/images/artwork.jpg" />
          </div>
          <div v-else>
            <div v-if="column.name === 'text-action'">Track deleted</div>
            <div v-else>-</div>
          </div>
        </div>
        <component
          v-if="!item.deleted"
          :stopbubble="!column.noStop"
          :is="column.name"
          :key="column.name"
          v-bind="columnsProperties(item, column.props)"
          v-on="columnsListeners"
          @click.native.prevent
          @dblclick.native.prevent.stop="() => {}"
        />
      </div>
      <div
        v-if="column.type === 'text'"
        class="low-opacity"
        :class="{
          'row-body-cell-list': Array.isArray(item[column.name]),
          'row-body-cell-text-container': !Array.isArray(item[column.name])
        }"
      >
        <div v-if="item.deleted">
          <div v-if="column.name === 'title'">Track deleted</div>
          <div v-else>-</div>
        </div>
        <div v-else class="ellipsis-container">
          <div
            v-if="!Array.isArray(item[column.name])"
            @click="navigateTo($event, column.route, item)"
            :class="{
              'row-body-cell-text': true,
              'row-body-cell-link': column.route
            }"
            :title="column.noTitle ? '' : item[column.name]"
          >
            {{ item[column.name] }}
          </div>
          <div v-else class="row-body-cell-list-container-text">
            <span
              v-for="(element, index) in item[column.name]"
              :key="`item-txt-${index}`"
              @click="navigateTo($event, column.route, element)"
              :title="generateTitleList(item[column.name])"
              :class="{
                'row-body-cell-list-text': true,
                'row-body-cell-list-link': column.route
              }"
            >
              {{ element.name }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
