
<div class="pricingContainer">
  <div class="formOverlay" v-if="isUpgradeProcessing">
    <div class="formProcessing">
      <spinner-without-progress color="grey" :size="24" />
      <div class="formProcessingMessage" v-if="isCreatingFreeTrial">
        Creating free trial subscription…
      </div>
      <div class="formProcessingMessage" v-else>Updating subscription…</div>
    </div>
  </div>
  <div class="pricingHeader">
    <h2 class="pricingTitle">{{ $t('selectYourWorkspacePlan') }}</h2>
    <div class="pricingSubscriptionPeriod">
      <switch-button
        v-tooltip="{
          content: isSubscriptionCanceled()
            ? 'Reactivate current plan to unlock subscription options'
            : ''
        }"
        :leftLabel="$t('reccurenceMonthly')"
        :label="$t('reccurenceAnnually')"
        :defaultValue="isYearly"
        color="purple"
        size="large"
        :isDisabled="isSubscriptionCanceled()"
        @toggleSwitchButton="toggleSwitchButton"
      />
    </div>
  </div>
  <div class="plansContainer">
    <div v-for="plan in plans" :key="plan.id" :class="`plan ${plan.slug}`">
      <div v-if="plan.slug === 'free'">
        <free-pane
          :plan="plan"
          :currentPlan="currentPlan"
          :email="getEmail()"
          @cancelSubscription="handleCancelSubscription"
        />
      </div>
      <div v-if="plan.slug === 'pro'">
        <pro-pane
          :plan="plan"
          :isYearly="isYearly"
          :minimumPlanIndex="minimumPlanIndex"
          :currentPlanIndex="currentPlanIndex"
          :selectedPlanIndex="selectedPlanIndex"
          :isUpgradeProcessing="isUpgradeProcessing || priceCalculationPending"
          @setPriceSelection="setPriceIndex"
          @submit="handleSubmit"
        />
      </div>
    </div>
  </div>
  <div class="customPricing">
    <a href="mailto:hi@bridge.audio">Contact us</a> if you need a more custom pricing
  </div>
</div>
