<template>
  <div class="share-by-link-body">
    <div class="upgrade-banner" v-if="sharesExpiresIn">
      <upgrade-banner
        :content="`Shared links expire after ${sharesExpiresIn} days with the Starter plan`"
        :roleId="myRoleId"
        @closeModal="$emit('closeModal')"
      />
    </div>
    <div class="description">
      <div class="description-icon"><i class="ri-links-line"></i></div>
      <div class="description-main">
        <div class="share-by-link-body-title-container">
          <div class="share-by-link-body-title">
            {{ $t('createIndividualLinks') }}
          </div>
          <div class="share-by-link-body-desc">
            {{ $t('individualLinksDesc') }}
          </div>
        </div>
      </div>
    </div>
    <div class="tag-input-container">
      <chips-input
        ref="chipsInputRef"
        :label="$t('chipsInputLabel')"
        :error="chipsInputError"
        @handleResult="setLinkName"
      />
    </div>
    <access-rights
      :accessRights="accessRights"
      :isProject="isProject"
      type="link"
      class="access-rights-link"
      @setAccessRights="setLinkAccessRights"
    />
    <share-security-section
      ref="sharePasswordToggleIndividual"
      :displayPasswordInput="displayIndividualPassword"
      :displayLinkNameInput="displayIndividualLinkName"
      :securityPassword.sync="password"
      :securityPasswordErrorMessage="passwordErrorMessage"
      @toggleSwitch="toggleSharePasswordIndividual"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { maxLength, minLength } from 'vuelidate/lib/validators';

import AccessRights from '@/containers/accessRights';
import ShareSecuritySection from '@/containers/form/shareSecuritySection';
import UpgradeBanner from '@/containers/upgradeBanner';

export default {
  components: {
    AccessRights,
    ShareSecuritySection,
    UpgradeBanner
  },
  props: {
    entityType: {
      type: String,
      required: true
    },
    myRoleId: {
      type: Number,
      required: false,
      default: 0
    },
    sharesExpiresIn: {
      type: String,
      required: false,
      default: null
    },
    accessRights: {
      type: Array,
      required: true
    },
    displayIndividualPassword: {
      type: Boolean,
      required: false
    },
    displayIndividualLinkName: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      password: null,
      chipsInputError: null
    };
  },
  validations: {
    password: {
      minLength: minLength(3),
      maxLength: maxLength(50)
    }
  },
  watch: {
    password() {
      this.$emit('setSharePasswordIndividualLinks', this.password);
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    passwordErrorMessage() {
      if (!this.$v.password.minLength) {
        return this.$t('minChars', {
          count: this.$v.password.$params.minLength.min
        });
      }
      if (!this.$v.password.maxLength) {
        return this.$t('maxiChars', {
          count: this.$v.password.$params.maxLength.max
        });
      }
      return null;
    },
    isProject() {
      return this.entityType === 'project';
    }
  },
  methods: {
    forceSetLinkName() {
      this.$refs.chipsInputRef.addChips();
    },
    setLinkName(links) {
      this.$emit('setIndividualLinks', links);
      if (links.length > 0) {
        this.chipsInputError = null;
      }
    },
    togglePublicLinkActivation(switchBtnValue) {
      this.$emit('togglePublicLinkActivation', switchBtnValue);
    },
    setLinkAccessRights(accessRights) {
      this.$emit('setLinkAccessRights', accessRights);
    },
    toggleSharePasswordIndividual() {
      this.$emit('toggleSharePasswordIndividual');
    },
    hardToggledisplayIndividualPassword() {
      if (this.$refs.sharePasswordToggleIndividual) {
        this.$refs.sharePasswordToggleIndividual.hardToggleSwitchButton();
      }
    },
    setChipsInputError(msg) {
      this.chipsInputError = msg;
    }
  }
};
</script>

<style lang="scss" scoped>
.share-by-link {
  &-body {
    .upgrade-banner {
      padding: 0 24px 16px 24px;
    }
    padding: 32px 0 0 0;
    max-height: calc(100vh - 41px - 56px - 57px - 24px);

    &-title {
      &-container {
        @include subtitle-1;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
      display: flex;
      align-items: center;
    }

    &-desc {
      @include body-2;
      color: $color_neutral_60;
      margin-top: 8px;
    }

    &-input {
      padding: 24px 24px 16px 24px;

      &-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $color_neutral_40;
        border-radius: 4px;
        &-- {
          width: 386px;
          position: relative;
        }
      }
    }
  }
}
.description {
  padding: 0 16px 24px 24px;
  display: flex;
  align-items: center;
  &-icon {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: $color_primary_10;
    color: $color_primary_100;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
  }
  &-main {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  }
}
.tag-input-container {
  padding: 0 24px;
  margin-bottom: 16px;
}
.access-rights-link {
  padding: 0 24px 24px 24px;
}
.public-link-separator {
  width: 1px;
  height: 28px;
  background-color: $color_neutral_40;
}
.copy-btn {
  margin-right: 8px;
}
</style>

<style lang="scss">
.share-by-link-body-input-container-- {
  .public-link-url-textfield.textfield {
    .textfield-disabled {
      background-color: white;
      border: none;
      padding-right: 0;
      input {
        background-color: white;
        width: 100%;
      }
    }
  }
}
</style>
