
<div v-if="ProjectsInformation" class="projects">
  <div v-if="SearchProjects">
    <router-view
      :projects="formatedProjects"
      :isLoadingProject="isLoadingProject"
      :hasMoreToLoad="hasMoreToLoad"
      :hasSearch="hasSearch"
      @loadMore="loadMore"
      @deleteProjectInCache="deleteProjectInCache"
      @openCreateProjectModal="openCreateProjectModal"
      @openDeleteProjectModal="openDeleteProjectModal"
    ></router-view>
  </div>
  <div v-else class="loader">
    <spinner-without-progress color="grey" :size="32" />
  </div>
</div>
<div v-else class="loader">
  <spinner-without-progress color="grey" :size="32" />
</div>
