
<div class="card-album">
  <router-link
    class="card-album-link"
    :to="albumUrl"
    @mouseover.native="cardHoverHandler"
    @mouseleave.native="cardMouseLeaveHandler"
  >
    <div class="card-album-link-img">
      <img loading="lazy" :src="albumArtwork" alt="album cover" class="card-album-link-img--" />
    </div>
    <transition name="fade" @after-enter="isHover = true" @after-leave="isHover = false">
      <div v-if="isHover" class="card-album-link-hover"></div>
    </transition>
  </router-link>
  <div class="card-album-name">
    <div class="card-album-name-container">
      <router-link :to="albumUrl" class="card-album-name--">{{ albumName }}</router-link>
    </div>
    <div class="card-album-name-artist-container">
      <router-link :to="artistUrl" class="card-album-name-artist">{{ artistName }}</router-link>
    </div>
  </div>
</div>
