<template>
  <div class="wrap">
    <div
      class="scroll-container"
      :style="{ width, height }"
      ref="scrollContainer"
      @scroll.passive="toggleShadow"
    >
      <slot />
      <span :class="['shadow-top', shadow.top && 'is-active']" />
      <span :class="['shadow-bottom', shadow.bottom && 'is-active']" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScrollShadow',
  data() {
    return {
      width: undefined,
      height: undefined,
      shadow: {
        top: false,
        right: false,
        bottom: false,
        left: false
      }
    };
  },
  mounted() {
    if (typeof ResizeObserver === 'undefined') return;
    // Check if shadows are necessary after the element is resized.
    const scrollContainerObserver = new ResizeObserver(this.toggleShadow);
    if (scrollContainerObserver) {
      scrollContainerObserver.observe(this.$refs.scrollContainer);
      // Cleanup when the component is destroyed.
      this.$once('hook:destroyed', () => scrollContainerObserver.disconnect());
    }

    // Recalculate the container dimensions when the wrapper is resized.
    const wrapObserver = new ResizeObserver(this.calcDimensions);
    if (wrapObserver) {
      wrapObserver.observe(this.$el);
      // Cleanup when the component is destroyed.
      this.$once('hook:destroyed', () => wrapObserver.disconnect());
    }
  },
  methods: {
    // Reset dimensions for correctly recalculating parent dimensions.
    async calcDimensions() {
      this.width = undefined;
      this.height = undefined;
      await this.$nextTick();

      this.width = `${this.$el.clientWidth}px`;
      this.height = `${this.$el.clientHeight}px`;
    },
    // Check if shadows are needed.
    toggleShadow() {
      const { scrollContainer } = this.$refs;
      const hasVerticalScrollbar = scrollContainer.clientHeight < scrollContainer.scrollHeight;
      const scrolledFromTop = scrollContainer.offsetHeight + scrollContainer.scrollTop;
      const scrolledToTop = scrollContainer.scrollTop === 0;
      const scrolledToBottom = scrolledFromTop >= scrollContainer.scrollHeight;
      this.shadow.top = hasVerticalScrollbar && !scrolledToTop;
      this.shadow.bottom = hasVerticalScrollbar && !scrolledToBottom;
    }
  }
};
</script>

<style lang="scss" scoped>
.wrap {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.scroll-container {
  overflow: auto;
  overflow-x: hidden;
}

.shadow-top,
.shadow-bottom {
  position: absolute;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
}

.shadow-top,
.shadow-bottom {
  right: 0;
  left: 0;
  height: 1em;
  background-image: linear-gradient(rgba(#555, 0.15) 0%, rgba(#fff, 0) 100%);
}

.shadow-top {
  top: 0;
}

.shadow-bottom {
  bottom: 0;
  transform: rotate(180deg);
}

.is-active {
  opacity: 1;
}
</style>
