<template>
  <svg class="spinner" :style="spinnerStyle" viewBox="0 0 50 50">
    <circle :class="`path ${color}`" cx="25" cy="25" r="20" fill="none" stroke-width="5" />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: 'green'
    },
    size: {
      type: Number,
      required: false,
      default: 18
    }
  },
  computed: {
    spinnerStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.spinner {
  animation: rotate 2s linear infinite;
  width: 18px;
  height: 18px;

  & .path {
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}
.green {
  stroke: $color_success_100;
}
.grey {
  stroke: $color_neutral_40;
}
.white {
  stroke: $color_neutral_0;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
