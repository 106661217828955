import gql from 'graphql-tag';

export const UPLOAD_FILE = gql`
  mutation uploadLibraryFile($libraryId: ID!, $file: Upload!) {
    uploadLibraryFile(libraryId: $libraryId, file: $file) {
      id
    }
  }
`;

export const REPLACE_LIBRARY_FILE = gql`
  mutation ReplaceLibraryFile($library_id: ID!, $library_file_id: ID!, $upload_id: ID!) {
    ReplaceLibraryFile(
      library_id: $library_id
      library_file_id: $library_file_id
      upload_id: $upload_id
    ) {
      id
      length
      file_information {
        original_name
        updated_at
        added_by
        additional_media {
          format
          size
          bit_rate
        }
      }
      duration
    }
  }
`;
