
<div>
  <p class="planTitle">
    <i class="ri-music-2-fill planIcon" />
    <span>{{ plan.name }}</span>
  </p>
  <p class="planDescription">{{ $t('proPlan.description') }}</p>
  <div>
    <span class="planCurrency">{{ currencySymbol }}</span>
    <span class="planPrice">{{ planPrice() }}</span>
  </div>
  <div class="planRecurrence">
    <span>{{ $t('proPlan.byMonth') }}</span
    >&nbsp;
    <span v-if="isYearly">{{ $t('proPlan.annuallyBilled') }}</span>
  </div>
  <div class="planFeatures">
    <i class="ri-server-fill" />
    {{
      $t('planStorageInformation', {
        tracks: planFeature('libraryFilesMax'),
        storage: planFeature('storage')
      })
    }}
  </div>
  <slider
    labelMin="150 tracks (or 6 GB)"
    labelMax="400k tracks (or 10 TB)"
    :min="0"
    :max="getProVariantCount"
    :defaultValue="getActivePlanIndex"
    :step="1"
    :showIndicator="true"
    @sliderValueChange="setPriceIndex"
  />
  <div v-tooltip="{ content: getButtonTooltip }">
    <submit-button
      :primaryException="true"
      :label="getButtonLabel"
      :pending="isUpgradeProcessing"
      :disabled="isUpgradeDisabled"
      size="large"
      class="planButton"
      @submit="handleSubmit"
    />
  </div>
  <ul class="planBenefits">
    <li><i class="ri-check-line" />{{ getExtraMemberCost() }}</li>
    <li><i class="ri-check-line" />{{ $t('proPlan.benefitsSharing') }}</li>
    <li><i class="ri-check-line" />{{ $t('proPlan.benefitsNotifications') }}</li>
  </ul>
</div>
