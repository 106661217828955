
<div class="player">
  <div class="player-time-indicator-mobile" :style="{ width: currentIndicatorWidth + '%' }"></div>
  <div class="player-info">
    <div class="player-info-img-container">
      <img class="player-info-img" :src="tracks[currentTrackIndex].artwork" />
    </div>
    <div class="player-info-container text-ellipsis">
      <div
        ref="titleContainer"
        class="player-info-title"
        :class="{ 'too-long': isTooLong }"
        @mouseover="titleMouseOverHandler"
      >
        <div
          class="player-info-title--"
          ref="displayedTrackTitle"
          @animationend="titleAnimationEndHandler"
        >
          <div ref="titleText" class="player-info-title--text">
            {{ tracks[currentTrackIndex].title }}
          </div>
          <div v-if="isTooLong" class="title-duplicate">
            {{ tracks[currentTrackIndex].title }}
          </div>
        </div>
      </div>
      <div class="player-info-artistAlbum">
        <div
          class="player-info-artistAlbum-item"
          v-for="(artist, index) in tracks[currentTrackIndex].artists"
          :key="`artist-${index}`"
        >
          <div
            class="player-info-artistAlbum-item--"
            @click="navigateTo('artist', artist.id)"
            v-if="artist.name"
          >
            <div class="link">{{ artist.name }}</div>
            <div
              v-if="
                showArtistSeparator(
                  index,
                  tracks[currentTrackIndex].artists,
                  tracks[currentTrackIndex].supports
                )
              "
            >
              &nbsp;•&nbsp;
            </div>
          </div>
        </div>
        <div
          class="player-info-artistAlbum-item"
          v-for="(support, index) in tracks[currentTrackIndex].supports"
          :key="`support-${index}`"
        >
          <div
            class="player-info-artistAlbum-item--"
            @click="navigateTo('album', support.id)"
            v-if="support.name"
          >
            <div class="link">{{ support.name }}</div>
            <div v-if="showSupportSeparator(index, tracks[currentTrackIndex].supports)">
              &nbsp;•&nbsp;
            </div>
          </div>
        </div>
        <div v-if="typeof currentArtists === 'string' && typeof currentSupports === 'string'">
          {{ currentArtists }}
          <span v-if="currentArtists && currentSupports">&nbsp;•&nbsp;</span>
          {{ currentSupports }}
        </div>
      </div>
    </div>
  </div>
  <div class="player-controler">
    <div class="player-controler-control">
      <i class="ri-skip-back-mini-fill player-controler-control-skip" @click="handlePrevious" />
      <div class="player-loading" v-if="isLoading">
        <div class="player-loading-container">
          <spinner-without-progress color="white" :size="20" />
        </div>
      </div>
      <i
        v-else
        class="player-controler-control-play"
        :class="play && !isOnError ? 'ri-pause-circle-fill' : 'ri-play-circle-fill'"
        @click="togglePlay"
      />
      <i class="ri-skip-forward-mini-fill player-controler-control-skip" @click="handleNext" />
      <icon-button
        v-if="!isRepeatOne"
        icon="ri-repeat-2-fill"
        btnStyle="tertiary"
        class="player-controler-action-repeat"
        @click.native="repeatClickHandler"
        :isSelect="isRepeat"
        v-tooltip="{
          content: isRepeat ? 'Repeat all' : 'Repeat off'
        }"
        size="small"
      />
      <icon-button
        v-else
        icon="ri-repeat-one-fill"
        btnStyle="tertiary"
        class="player-controler-action-repeat"
        @click.native="repeatAllClickHandler"
        :isSelect="true"
        v-tooltip="'Repeat one'"
        size="small"
      />
    </div>
    <div class="player-controler-seekbar-timer-container">
      <div class="player-controler-timer-current">{{ currentTime }}</div>
      <div class="player-controler-seekbar-container">
        <div v-if="emptyWave" class="player-empty-wave"></div>
        <div v-show="!emptyWave" id="waveform" />
      </div>
      <div class="player-controler-timer-total">{{ duration }}</div>
    </div>
    <div class="player-controler-action">
      <div class="player-controler-action-volume-container">
        <icon-button
          v-if="!isVolumeMute"
          icon="ri-volume-up-line"
          btnStyle="tertiary"
          class="player-controler-action-volume"
          @click.native="handleVolumeIconClick"
          size="small"
        />
        <icon-button
          v-if="isVolumeMute"
          icon="ri-volume-mute-line"
          btnStyle="tertiary"
          class="player-controler-action-volume"
          @click.native="handleVolumeIconClick"
          size="small"
        />
        <div
          class="player-controler-action-volume-seeker"
          @mouseenter="handleVolumeMouseHover(true)"
          @mouseleave="handleVolumeMouseHover(false)"
        >
          <div class="player-controler-action-volume-seeker--">
            <volume-control
              :volume="volumeValue"
              :isHovered="isVolumeHover"
              @setVolume="audioVolumeHandler"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="player-controler-control-mobile">
    <i
      class="player-controler-control-play"
      :class="play ? 'ri-pause-circle-fill' : 'ri-play-circle-fill'"
      @click="togglePlay"
    />
  </div>
</div>
