<template>
  <div class="share-modal">
    <div class="share-modal-header">
      <div class="share-modal-header-tabs">
        <tabs
          class="share-modal-tab"
          :tabs="tabs"
          :noRouteCurrentTab="currentTab"
          noRoute
          isSecondary
          @changeTab="changeTabValue"
        />
      </div>
    </div>
    <div class="share-modal-body">
      <quick-link-view
        v-if="!isSharePublicLoading"
        v-show="currentTab === 0"
        :defaultTab="defaultTab"
        ref="quickLinkView"
        :entityIds="entityIds"
        :entityType="entityType"
        :myRoleId="myRoleId"
        :sharesExpiresIn="sharesExpiresIn"
        :isPublicLinkActivated="isPublicLinkActivated"
        :basePublicLink="basePublicLink"
        :publicLinkAccessRights="publicLinkAccessRights"
        :displaySharePasswordPublic="displaySharePasswordPublic"
        :defaultSharePassword="sharePasswordPublic"
        :shareId="shareId"
        :shareHash="shareHash"
        :selectedTracksLength="selectedTracksLength"
        @togglePublicLinkActivation="togglePublicLinkActivation"
        @setPublicLinkAccessRights="setPublicLinkAccessRights"
        @toggleSharePasswordPublic="toggleSharePasswordPublic"
        @handlePublicBlur="handlePublicBlur"
        @closeModal="$emit('closeModal')"
      />
      <div v-else v-show="currentTab === 0" class="quick-link-loader">
        <spinner-without-progress color="grey" :size="32" />
      </div>
      <individual-links-view
        v-show="currentTab === 1"
        ref="shareByIndividual"
        :entityType="entityType"
        :myRoleId="myRoleId"
        :sharesExpiresIn="sharesExpiresIn"
        :accessRights="individualLinksAccessRights"
        :displayIndividualPassword="displayIndividualPassword"
        @setIndividualLinks="setIndividualLinks"
        @setLinkAccessRights="setLinkAccessRights"
        @toggleSharePasswordIndividual="toggleIndividualPassword"
        @setSharePasswordIndividualLinks="setSharePasswordIndividualLinks"
      />
      <share-by-mail-view
        v-show="currentTab === 2"
        ref="shareByMailView"
        :entityType="entityType"
        :myRoleId="myRoleId"
        :sharesExpiresIn="sharesExpiresIn"
        :UserEmails="UserEmails"
        :primaryEmail="primaryEmail"
        :emailAccessRights="emailAccessRights"
        :displaySharePassword="displaySharePassword"
        :emailListErrorMsg="emailListErrorMsg"
        @setEmailList="setEmailList"
        @setEmailAccessRights="setEmailAccessRights"
        @setEmailReplyTo="setEmailReplyTo"
        @toggleSharePassword="toggleSharePassword"
        @setSharePassword="setSharePassword"
        @setMessage="setMessage"
        @resendConfirmationEmail="resendConfirmationEmail"
        @closeModal="$emit('closeModal')"
      />
      <!-- commented for now, we need a query to display the number of share, it's in progress in back -->
      <!-- <div class="share-modal-view-links">
        <div class="separator"></div>
        <submit-button
          class="view-links-btn"
          size="medium"
          btnStyle="tertiary"
          label="View links"
          exception
          iconClass="ri-equalizer-fill"
          @submit="goToSharingList"
        />
      </div> -->
    </div>
    <div v-if="currentTab === 1" class="share-modal-body-button">
      <submit-button
        btnStyle="primary"
        size="medium"
        :label="$t('create')"
        @submit="shareEntity(false, null, true)"
        :disabled="hasNullLink"
      />
    </div>
    <div v-if="currentTab === 2" class="share-modal-body-button">
      <div class="share-modal-body-button--">
        <submit-button
          btnStyle="secondary"
          size="medium"
          :label="$t('cancel')"
          @submit="$emit('closeModal')"
        />
      </div>
      <submit-button
        btnStyle="primary"
        size="medium"
        :label="$t('send')"
        :disabled="disableSendByEmailButton"
        @submit="shareEntity(true)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { maxLength, minLength } from 'vuelidate/lib/validators';

import { bus } from '@/utils/bus';
import { get as getRoute } from '@/router/routes';
import { SHARE_PUBLIC_LINK } from '@/graphql/queries/share';
import { USER_EMAIL_RESEND_CONFIRMATION } from '@/graphql/mutations/user';
import { USER_EMAILS, WORKSPACES_SUBSCRIPTION_FEATURES } from '@/graphql/queries/user';
import { SHARE_ENABLE } from '@/graphql/mutations/project';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import { configureShare } from '@/utils/functions/share';

import QuickLinkView from './quickLink';
import IndividualLinksView from './individualLinks';
import ShareByMailView from './shareByMail';

const NO_REPLY_EMAIL = 'no-reply@bridge.audio';

export default {
  components: {
    QuickLinkView,
    IndividualLinksView,
    ShareByMailView
  },
  props: {
    entityIds: {
      type: Array,
      required: true
    },
    entityName: {
      type: String,
      required: true
    },
    entityType: {
      type: String,
      required: true
    },
    defaultTab: {
      type: Number,
      required: false,
      default: 0
    },
    selectedTracksLength: {
      type: Number,
      required: false,
      default: null
    }
  },
  data() {
    return {
      currentTab: this.defaultTab,
      emailListErrorMsg: null,
      message: null,
      emailAccessRights: [],
      emailList: [],
      individualLinksAccessRights: [],
      individualLinkList: [],
      sharePasswordIndividualLinks: null,
      emailReplyTo: { email: NO_REPLY_EMAIL },
      allDefaultAccessRights: [],
      publicLinkShareId: null,
      isPublicLinkActivated: false,
      basePublicLink: null,
      publicLinkAccessRights: [],
      publicLinkAccessRightsHasChanged: false,
      firstSharePublicLinkQuery: true,
      displayIndividualPassword: false,
      individualPassword: null,
      displaySharePassword: false,
      sharePassword: null,
      displaySharePasswordPublic: false,
      sharePasswordPublic: null,
      sharesExpiresIn: null,
      myRoleId: null,
      isSharePublicLoading: false,
      shareId: '',
      shareHash: '',
      hasNullLink: false
    };
  },
  apollo: {
    UserEmails: {
      query: USER_EMAILS,
      policy: 'network-only',
      result(response) {
        this.primaryEmail = response.data.UserEmails.find(item => item.is_primary);
        /* by default we set no_reply to primary email */
        if (this.primaryEmail.is_verified) {
          this.emailReplyTo = this.primaryEmail;
        }
      }
    },
    SharePublicLink: {
      query: SHARE_PUBLIC_LINK,
      variables() {
        return {
          type: this.entityType,
          entities: this.entityIds
        };
      },
      watchLoading(loading) {
        this.isSharePublicLoading = loading;
      },
      result(res) {
        if (res && res.data) {
          if (res.data.SharePublicLink) {
            const { settings, link, slug, id, status, share_hash } = res.data.SharePublicLink;
            this.setDataValue(settings, link, slug, id, this.firstSharePublicLinkQuery);
            this.isPublicLinkActivated = status === 'enabled';
            this.shareId = id;
            this.shareHash = share_hash;
          }
          this.firstSharePublicLinkQuery = false;
        }
      },
      error() {}
    },
    me: {
      query: WORKSPACES_SUBSCRIPTION_FEATURES,
      result(res) {
        this.myRoleId = parseInt(
          this.currentWorkspace.members.find(member => member.user_id === res.data.me.id).role.id,
          10
        );
        const currentWorkspace = res.data.me.workspaces.find(
          workspace => workspace.id === this.currentWorkspace.id
        );
        const workspaceFeature = currentWorkspace.subscription.features.find(
          el => el.type === 'projectSharing'
        );
        let sharesExpiresIn =
          workspaceFeature &&
          workspaceFeature.configuration.find(el => el.type === 'shares_expires_in').options;
        sharesExpiresIn = sharesExpiresIn === '-1' ? null : sharesExpiresIn;
        this.sharesExpiresIn = sharesExpiresIn;
      }
    }
  },
  validations: {
    message: {
      maxLength: maxLength(2000)
    },
    sharePassword: {
      minLength: minLength(3),
      maxLength: maxLength(50)
    },
    sharePasswordPublic: {
      minLength: minLength(3),
      maxLength: maxLength(50)
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    tabs() {
      return [
        {
          title: this.$t('quickLink'),
          icon: 'ri-flashlight-fill'
        },
        {
          title: this.$t('individualLinks'),
          icon: 'ri-links-line'
        },
        {
          title: this.$t('email'),
          icon: 'ri-mail-line'
        }
      ];
    },
    messageErrorMsg() {
      if (!this.$v.message.maxLength)
        return this.$t('maxLength', {
          label: this.$t('message'),
          nbChars: this.$v.message.$params.maxLength.max
        });
      return null;
    },
    passwordErrorMessage() {
      if (!this.$v.sharePassword.minLength) {
        return this.$t('minChars', {
          count: this.$v.sharePassword.$params.minLength.min
        });
      }
      if (!this.$v.sharePassword.maxLength) {
        return this.$t('maxiChars', {
          count: this.$v.sharePassword.$params.maxLength.max
        });
      }
      return null;
    },
    disableSendByEmailButton() {
      return (
        this.emailList.length === 0 ||
        this.emailListErrorMsg !== null ||
        this.messageErrorMsg !== null ||
        this.passwordErrorMessage !== null
      );
    },
    isProject() {
      return this.entityType === 'project';
    }
  },
  created() {
    bus.$on('shareEntitySucess', () => {
      this.$apollo.queries.SharePublicLink.refetch();
    });
    const accesRights = [
      'stream_mp3',
      'tracks_metadata',
      'download_as_mp3',
      'download_as_source_audio',
      ...(this.isProject ? ['show_project_description', 'download_and_display_attachments'] : [])
    ].filter(Boolean);
    // set all default access rights to ON
    this.emailAccessRights = accesRights;
    this.allDefaultAccessRights = accesRights;
    this.individualLinksAccessRights = accesRights;
  },
  beforeDestroy() {
    bus.$off('disableTrackSelection');
  },
  methods: {
    resendConfirmationEmail(id) {
      this.$apollo
        .mutate({
          mutation: USER_EMAIL_RESEND_CONFIRMATION,
          variables: {
            id
          }
        })
        .then(() => {
          bus.$emit('showAlert', {
            message: { key: 'resendCheckingEmail' },
            style: 'success',
            delay: 5000,
            error: false
          });
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    setEmailReplyTo(emailReplyTo) {
      this.emailReplyTo = emailReplyTo;
    },
    setDataValue(settings, link, slug, id, setAccess) {
      this.publicLinkShareId = id;
      if (setAccess) {
        this.publicLinkAccessRights = settings.access_rights;
      }
      if (setAccess && settings.access_password) {
        this.displaySharePasswordPublic = true;
        this.sharePasswordPublic = settings.access_password;
      }
      this.basePublicLink = this.baselink !== null ? link : null;
    },
    changeTabValue(title, index) {
      this.currentTab = index;
      if (index === 0 && !this.sharePasswordPublic && this.displaySharePasswordPublic) {
        this.hardToggleSharePasswordPublic();
      }
      if (index === 1 && !this.sharePassword && this.displaySharePassword) {
        this.hardToggleSharePassword();
      }
    },
    setEmailList(result, errorCount) {
      this.emailListErrorMsg = errorCount > 0 ? this.$tc('emailListErrorMsg', errorCount) : null;
      // avoid duplicate mail
      this.emailList = Array.from(new Set(result));
    },
    setIndividualLinks(links) {
      this.hasNullLink = links.some(value => {
        return value === null;
      });
      // avoid duplicate mail
      this.individualLinkList = Array.from(new Set(links));
    },
    setEmailAccessRights(emailAccessRights) {
      this.emailAccessRights = emailAccessRights;
    },
    setLinkAccessRights(accessRight) {
      this.individualLinksAccessRights = accessRight;
    },
    shareEntity(withMail, switchBtnValue, individualLinks) {
      if (this.$isWorkspaceLockedVerification(this)) {
        return;
      }
      if (individualLinks) {
        this.$refs.shareByIndividual.forceSetLinkName();
      }
      if (individualLinks && this.individualLinkList.length < 1) {
        this.$refs.shareByIndividual.setChipsInputError('Link name is required');
        return;
      }
      const { entityType } = this;
      const entityId = entityType === 'track' ? this.entityIds : this.entityIds[0];
      const withMailvariables = {
        entityId,
        access_rights: this.emailAccessRights,
        access_password: this.sharePassword,
        reply_to: this.emailReplyTo.email,
        emailList: this.emailList,
        message: this.message,
        entityName: this.entityName
      };
      const individualLinkVariables = {
        entityId,
        access_rights: this.individualLinksAccessRights,
        access_password: this.sharePasswordIndividualLinks,
        individualLinkList: this.individualLinkList,
        entityName: this.entityName
      };
      const noMailvariables = {
        entityId,
        access_rights: this.allDefaultAccessRights
      };

      let variables = withMail ? withMailvariables : noMailvariables;
      variables = individualLinks ? individualLinkVariables : variables;

      if (this.emailReplyTo.email === NO_REPLY_EMAIL) {
        delete variables.reply_to;
      }
      switch (entityType) {
        case 'project':
          this.handleShareEntity(
            variables,
            individualLinks,
            switchBtnValue,
            withMail,
            'handleProjectShareMutation'
          );
          break;
        case 'track':
          this.handleShareEntity(
            variables,
            individualLinks,
            switchBtnValue,
            withMail,
            'handleTracksShareMutation'
          );
          break;
        case 'support':
          this.handleShareEntity(
            variables,
            individualLinks,
            switchBtnValue,
            withMail,
            'handleSupportShareMutation'
          );
          break;
        case 'artist':
          this.handleShareEntity(
            variables,
            individualLinks,
            switchBtnValue,
            withMail,
            'handleArtistShareMutation'
          );
          break;
        default:
          return;
      }
      if (withMail) {
        bus.$emit('toggleHideModal', true);
      }
      if (individualLinks) {
        bus.$emit('toggleHideModal', true);
        bus.$emit('closeHidenModal');
      }
    },
    handleShareEntity(variables, individualLinks, switchBtnValue, withMail, emitName) {
      if (withMail) {
        bus.$emit(emitName, variables);
      } else if (individualLinks) {
        bus.$emit(emitName, variables);
      } else {
        this.entityTogglePublicLink(variables, switchBtnValue, emitName);
      }
    },
    entityTogglePublicLink(variables, switchBtnValue, emitName) {
      if (this.SharePublicLink) {
        this.$apollo.mutate({
          mutation: SHARE_ENABLE,
          variables: {
            shareId: this.SharePublicLink.id,
            enable: switchBtnValue
          },
          refetchQueries: [
            {
              query: SHARE_PUBLIC_LINK,
              variables: {
                type: this.entityType,
                entities: this.entityIds
              }
            }
          ]
        });
      } else {
        bus.$emit(emitName, variables);
      }
    },
    togglePublicLinkActivation(switchBtnValue) {
      if (!this.sharePasswordPublic && this.displaySharePasswordPublic) {
        this.hardToggleSharePasswordPublic();
      }
      this.shareEntity(false, switchBtnValue);
    },
    setPublicLinkAccessRights(accessRights) {
      this.publicLinkAccessRightsHasChanged = true;
      this.publicLinkAccessRights = accessRights;
      this.handleConfigureShare();
    },
    handleConfigureShare() {
      configureShare(this, {
        shareId: this.publicLinkShareId,
        access_rights: this.publicLinkAccessRights,
        access_password: this.sharePasswordPublic
      });
    },
    toggleSharePassword() {
      this.displaySharePassword = !this.displaySharePassword;
      this.sharePassword = null;
    },
    toggleIndividualPassword() {
      this.displayIndividualPassword = !this.displayIndividualPassword;
      this.individualPassword = null;
    },
    setSharePassword(pwd) {
      this.sharePassword = pwd;
    },
    setSharePasswordIndividualLinks(pwd) {
      this.sharePasswordIndividualLinks = pwd;
    },
    setMessage(msg) {
      this.message = msg;
    },
    toggleSharePasswordPublic() {
      this.displaySharePasswordPublic = !this.displaySharePasswordPublic;
      this.sharePasswordPublic = null;
      if (!this.displaySharePasswordPublic) {
        this.handleConfigureShare();
      }
    },
    handlePublicBlur(password) {
      this.sharePasswordPublic = password;
      this.handleConfigureShare();
    },
    hardToggleSharePassword() {
      if (this.$refs.shareByMailView) {
        this.$refs.shareByMailView.hardToggleSharePassword();
      }
    },
    hardToggleSharePasswordPublic() {
      if (this.$refs.quickLinkView) {
        this.$refs.quickLinkView.hardToggleSharePasswordPublic();
      }
    },
    goToSharingList() {
      let route = '';
      const isShared = this.$route.path.includes('shared');

      switch (this.entityType) {
        case 'track':
          route = 'sharedTracksSharings';
          break;
        case 'support':
          route = isShared ? 'sharedAlbumSharings' : 'albumSharings';
          break;
        case 'artist':
          route = isShared ? 'sharedArtistSharings' : 'artistSharings';
          break;
        case 'project':
        default:
          route = isShared ? 'sharedProjectSharings' : 'projectSharings';
          break;
      }
      this.$router.push(getRoute(route, { id: this.entityIds }));
      this.$emit('closeModal');
    }
  }
};
</script>

<style lang="scss" scoped>
.share-modal {
  &-body {
    overflow-y: auto;
    /* Dirty fix for small screens
     * - 41px : height of tabs
     * - 56px : margin of modal
     * - 57px : height of modal header
     * - 24px : margin of modal content
     * - 57px : height of button footer
     */
    max-height: calc(100vh - 41px - 56px - 57px - 24px - 57px);
  }
  &-header {
    &-tabs {
      padding: 0 24px;
      margin-top: 8px;
    }
  }
  &-body {
    &-button {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid $color_neutral_30;
      padding: 8px 24px;

      &-- {
        padding: 0 8px 0 0;
      }
    }
  }
  &-view-links {
    padding: 0 32px;
  }
}
.separator {
  height: 1px;
  width: 100%;
  padding: 0 32px;
  box-sizing: border-box;
  background-color: $color_neutral_30;
}
.view-links-btn {
  margin: 24px 0;
}
.quick-link-loader {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style lang="scss">
.share-modal .modal-content {
  height: fit-content;
  margin-top: 64px;
  .share-modal-body {
    max-height: calc(100vh - 24px - 96px - 40px - 64px);
  }
}
.view-links-btn.tertiary.exception:hover {
  color: $color_primary_100;
  background-color: $color_primary_10;
}
</style>

<style lang="scss">
.share-modal-header-tabs {
  .share-modal-tab.tabs.secondary {
    .tabs-tab {
      flex-grow: 1;
      cursor: pointer;
      &:first-child {
        border-radius: 0px;
      }
      &:last-child {
        border-radius: 0px;
      }
      .tabs-tab-link {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 0;
      }
    }
  }
}
</style>
