
<div class="area">
  <div v-if="label" class="area-label">{{ label }}</div>
  <div :class="txtareaStyle">
    <div v-if="withEditButton && disabled" class="area-disabled-edit-button">
      <submit-button
        btnStyle="tertiary"
        exception
        danger
        size="small"
        label="Edit"
        @submit="handleEditBtnClick"
      />
    </div>
    <textarea
      @blur="
        setActiveClass(false);
        validate();
      "
      @focus="setActiveClass(true)"
      @input="handleInput"
      :placeholder="placeholder"
      ref="textarea"
      spellcheck="false"
      v-model="txt"
      :disabled="disabled"
    />
    <div :class="countStyle" v-if="showCharacterLimit">
      {{ characterCount }} / {{ characterLimit }}
    </div>
  </div>
  <div v-if="errorMessage" class="area-errormsg">{{ errorMessage }}</div>
</div>
