<template>
  <div class="more-text">
    <div class="more-text-container">
      <textarea
        class="more-text-container-txt"
        ref="textarea"
        v-model="textValue"
        disabled="true"
        :style="`height: ${textHeight}px;`"
      />
    </div>
    <div class="more-text-button" v-if="showMoreButton" @click="toggleExpand">
      {{ buttonLabel }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: false,
      default: null
    },
    maxHeight: {
      type: Number,
      required: false,
      default: 84
    },
    moreLabel: {
      type: String,
      required: false,
      default: 'More'
    },
    closeLabel: {
      type: String,
      required: false,
      default: 'Close'
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isExpanded: false,
      textHeight: 0,
      showMoreButton: false,
      textValue: this.text
    };
  },
  computed: {
    buttonLabel() {
      return this.isExpanded ? this.closeLabel : this.moreLabel;
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    isMobile() {
      this.init();
    }
  },
  methods: {
    init() {
      setTimeout(() => {
        this.setTextHeight();
        this.showMoreButton = this.$refs.textarea.scrollHeight > this.maxHeight;
      }, 500);
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
      this.setTextHeight();
    },
    setTextHeight() {
      if (this.$refs.textarea.scrollHeight > this.maxHeight) {
        this.textHeight = this.isExpanded ? this.$refs.textarea.scrollHeight : this.maxHeight;
      } else {
        this.textHeight = this.$refs.textarea.scrollHeight;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.more-text {
  @include body-1;

  &-button {
    @include btn-small;
    color: $color_primary_100;
    margin: 16px 0 0 0;
    cursor: pointer;
    text-transform: capitalize;
  }

  textarea,
  textarea:disabled {
    font-family: inherit;
    outline: none;
    border: none;
    resize: none;
    width: 100%;
    box-sizing: border-box;
    @include body-1;
    -webkit-text-fill-color: $color_neutral_100;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: $color_neutral_0;
  }
}
</style>
