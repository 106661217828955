
<div class="toggle-ai-modal">
  <div class="toggle-ai-modal-body">
    <div class="toggle-ai-modal-body-description">
      {{ body }}
    </div>
    <div class="toggle-ai-modal-body-description">Do you want to continue ?</div>
  </div>
  <div class="toggle-ai-modal-buttons">
    <div class="toggle-ai-modal-buttons--">
      <submit-button
        btnStyle="secondary"
        size="medium"
        :label="$t('cancel')"
        @submit="submit(false)"
      />
    </div>
    <div class="toggle-ai-modal-buttons--">
      <submit-button
        btnStyle="primary"
        size="medium"
        :label="$t('confirm')"
        @submit="submit(true)"
      />
    </div>
  </div>
</div>
