<template>
  <div class="volume-control">
    <input
      :class="inputClass"
      type="range"
      min="0"
      max="100"
      step="1"
      v-model="sliderValue"
      @input="onInputHandler"
    />
    <div class="volume-control-slider" :style="`width: ${sliderWidth}px`" />
    <div class="volume-control-slider-background" />
  </div>
</template>
<script>
export default {
  props: {
    volume: {
      type: Number,
      required: true
    },
    isHovered: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      sliderValue: this.volume * 100
    };
  },
  methods: {
    onInputHandler() {
      this.$emit('setVolume', this.sliderValue);
    }
  },
  computed: {
    sliderWidth() {
      const halfThumbWidth = 16 / 2;
      const inputWidth = 80;
      const centerPosition = inputWidth / 2;

      const percentOfRange = this.sliderValue / 100;
      const valuePxPosition = percentOfRange * inputWidth;
      const distFromCenter = valuePxPosition - centerPosition;
      const percentDistFromCenter = distFromCenter / centerPosition;

      const offset = percentDistFromCenter * halfThumbWidth;

      if (valuePxPosition === inputWidth && !this.isHovered) {
        return inputWidth;
      }
      if (valuePxPosition === 0) {
        return 0;
      }
      return valuePxPosition - offset;
    },
    inputClass() {
      let str = 'volume-control-range';
      if (this.isHovered) str += ' isHover';
      return str;
    }
  },
  watch: {
    volume() {
      this.sliderValue = this.volume * 100;
    }
  }
};
</script>
<style lang="scss" scoped>
.volume-control {
  position: relative;
  width: 80px;
  height: 36px;
  display: flex;
  align-items: center;
  &-range {
    margin: 0;
    z-index: 3;
    height: 32px;
    width: 80px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &::-webkit-slider-thumb {
      @include input-range-thumb;
      display: none;
    }
    &::-moz-range-thumb {
      @include input-range-thumb;
      display: none;
    }
    &::-moz-focus-outer {
      border: 0;
    }
  }
  &-slider {
    height: 4px;
    background: $color_primary_100;
    z-index: 0;
    position: absolute;
    z-index: 2;
    border-radius: 1px;
    will-change: width;
    max-width: 100%;
    &-background {
      width: 80px;
      height: 4px;
      background-color: $color_primary_10;
      position: absolute;
      z-index: 1;
      border-radius: 1px;
    }
  }
}
.volume-control-range.isHover {
  &::-webkit-slider-thumb {
    display: inherit;
  }
  &::-moz-range-thumb {
    display: unset;
  }
}
</style>
