<template>
  <div :class="`b-alert ${alertStyle}`">
    <div class="b-alert-msg">
      <i v-if="iconClass" :class="`b-alert-msg-icon ${iconClass}`" />
      <div class="title">{{ title }}</div>
      <div class="msg">{{ message }}</div>
      <div v-if="actionLabel" class="b-alert-action">
        <submit-button
          class="b-alert-action-button"
          btnStyle="secondary"
          size="small"
          :label="actionLabel"
          :disabled="actionDisabled"
          @submit="$emit('handleActionClick')"
        />
      </div>
    </div>
    <div class="b-alert-close">
      <div class="b-alert-action-separator" />
      <icon-button
        class="b-alert-close-btn"
        icon="ri-close-fill"
        btnStyle="tertiary"
        size="small"
        @submit="$emit('closeAlert', false)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: 'This workspace is now read-only.'
    },
    message: {
      type: String,
      required: false,
      default:
        "Your grace period is over! Don't worry, you can still recover all the features on this workspace by upgrading it to a Pro plan."
    },
    alertStyle: {
      type: String,
      required: false,
      default: 'warning'
    },
    actionLabel: {
      type: String,
      required: false,
      default: 'Upgrade'
    }
  },
  data() {
    return {
      actionDisabled: false,
      animationDelay: 1000
    };
  },
  computed: {
    iconClass() {
      let icon = '';
      if (this.alertStyle === 'success') {
        icon = 'ri-checkbox-circle-fill';
      } else if (this.alertStyle === 'warning') {
        icon = 'ri-error-warning-fill';
      } else if (this.alertStyle === 'danger') {
        icon = 'ri-alert-fill';
      }
      return icon;
    }
  }
};
</script>

<style lang="scss" scoped>
.b-alert {
  width: 300px;
  min-height: 40px;
  height: max-content;
  display: flex;
  border-radius: 4px;
  transition: opacity 300ms linear;
  background-color: $color_neutral_100;
  @include shadow-down-03;
  &-msg {
    flex: 1;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    color: $color_neutral_0;
    padding: 16px;
    align-items: flex-start;
    line-height: 18px;
    &-icon {
      font-size: 16px;
      margin-bottom: 8px;
      display: block;
    }
    .title {
      font-weight: 600;
      margin-bottom: 8px;
    }
    .msg {
      margin-bottom: 16px;
      font-size: 12px;
    }
  }

  &-close {
    display: flex;
    color: $color_neutral_60;
    cursor: pointer;
    &-btn {
      margin: 16px 16px 0 16px;
    }
  }

  &-action {
    display: flex;
    &-separator {
      width: 1px;
      height: calc(100% - 32px);
      margin-top: 16px;
      background: $color_neutral_80;
    }
  }
  .ri-checkbox-circle-fill {
    color: $color_success_100;
  }

  .ri-error-warning-fill {
    color: $color_warning_100;
  }

  .ri-alert-fill {
    color: $color_danger_100;
  }

  .ri-close-line {
    display: block;
  }
}
</style>

<style lang="scss">
.b-alert-action-button.button.secondary {
  background-color: $color_neutral_0;
}
.b-alert-close-btn.tertiary.small {
  border: 1px solid $color_neutral_100;
  .icon {
    color: $color_neutral_0;
  }
}
.b-alert-close-btn:hover.tertiary.small {
  border: 1px solid $color_neutral_0;
  background: rgba(0, 0, 0, 0);
}
</style>
