<template>
  <div>
    <div class="multi-edit-modal-text">{{ $t('saveChangesEdition') }}</div>
    <div class="multi-edit-modal-checkbox">
      <checkbox id="not-show-again" :label="$t('dontShowAgain')" @toggleCheckbox="toggleCheckbox" />
    </div>
    <div class="multi-edit-modal-buttons">
      <div class="multi-edit-modal-buttons--">
        <submit-button
          btnStyle="tertiary"
          size="medium"
          :label="$t('cancel')"
          @submit="closeModal"
        />
      </div>
      <div class="multi-edit-modal-buttons--">
        <submit-button btnStyle="primary" size="medium" :label="$t('save')" @submit="saveChanges" />
      </div>
    </div>
  </div>
</template>

<script>
import { DONT_SHOW_THIS_MESSAGE_AGAIN } from '@/utils/constants';

export default {
  props: {
    onSubmit: {
      type: Function,
      required: false,
      default: () => {}
    },
    onCancel: {
      type: Function,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      isChecked: false
    };
  },
  methods: {
    toggleCheckbox(isChecked) {
      this.isChecked = isChecked;
      localStorage.setItem(DONT_SHOW_THIS_MESSAGE_AGAIN, this.isChecked);
    },
    closeModal() {
      this.onCancel();
      this.$emit('closeModal');
    },
    saveChanges() {
      this.onSubmit();
      this.$emit('closeModal');
    }
  }
};
</script>

<style lang="scss" scoped>
.multi-edit-modal-text {
  padding: 24px 32px 0 32px;
  @include body-1;
}

.multi-edit-modal-checkbox {
  padding: 24px 32px;
}

.multi-edit-modal-buttons {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid $color_neutral_30;
  padding: 8px 32px;

  &-- {
    &:first-child {
      margin: 0 16px 0 0;
    }
  }
}
</style>
