<template>
  <div v-if="simple" class="simple">
    <div class="user">
      <avatar
        class="user-avatar"
        :pictureUrl="user.url"
        :initial="user.initial"
        :pictureSize="user.size ? user.size : 'ultraSmall'"
        v-tooltip="user.name"
      />
    </div>
    <div class="date" v-tooltip="date.date">
      {{ date.relative }}
    </div>
  </div>
  <div v-else class="container" v-tooltip="date.date">
    <div v-if="!date.displayDate" class="date">
      {{ date.relative }}
    </div>
    <div v-else class="date">
      {{ date.date }}
    </div>
    <div v-if="!user.url && !user.initial && !user.name" class="user">-</div>
    <div v-else class="user">
      <avatar
        class="user-avatar"
        :pictureUrl="user.url"
        :initial="user.initial"
        pictureSize="smallest"
      />
      <div class="user-name">
        {{ user.name }}
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { VTooltip } from 'v-tooltip';
import Avatar from '@/components/avatar';

Vue.directive('tooltip', VTooltip);

export default {
  components: {
    Avatar
  },
  props: {
    date: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    simple: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: fit-content;
  max-width: 100%;
  cursor: pointer;
  .date {
    @include body-1;
  }
  .user {
    display: flex;
    align-items: center;
    @include body-2;
    color: $color_neutral_60;
    white-space: nowrap;
    &-avatar {
      margin-right: 4px;
    }
    &-name {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.simple {
  display: flex;
  align-items: center;
  overflow: hidden;
  .user {
    margin-right: 8px;
  }
  .date {
    @include body-1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
