
<div class="container">
  <div v-if="status === 'success'" class="success">
    <i class="ri-checkbox-circle-line"></i>
  </div>
  <div v-if="status === 'fail'" class="fail">
    <i class="ri-close-circle-line"></i>
  </div>
  <div v-if="status === 'loading'" class="loading">
    <i class="ri-refresh-line"></i>
    Saving...
  </div>
</div>
