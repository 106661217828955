<template>
  <div class="pricingContainer">
    <div class="formOverlay" v-if="isUpgradeProcessing">
      <div class="formProcessing">
        <spinner-without-progress color="grey" :size="24" />
        <div class="formProcessingMessage" v-if="isCreatingFreeTrial">
          Creating free trial subscription…
        </div>
        <div class="formProcessingMessage" v-else>Updating subscription…</div>
      </div>
    </div>
    <div class="pricingHeader">
      <h2 class="pricingTitle">{{ $t('selectYourWorkspacePlan') }}</h2>
      <div class="pricingSubscriptionPeriod">
        <switch-button
          v-tooltip="{
            content: isSubscriptionCanceled()
              ? 'Reactivate current plan to unlock subscription options'
              : ''
          }"
          :leftLabel="$t('reccurenceMonthly')"
          :label="$t('reccurenceAnnually')"
          :defaultValue="isYearly"
          color="purple"
          size="large"
          :isDisabled="isSubscriptionCanceled()"
          @toggleSwitchButton="toggleSwitchButton"
        />
      </div>
    </div>
    <div class="plansContainer">
      <div v-for="plan in plans" :key="plan.id" :class="`plan ${plan.slug}`">
        <div v-if="plan.slug === 'free'">
          <free-pane
            :plan="plan"
            :currentPlan="currentPlan"
            :email="getEmail()"
            @cancelSubscription="handleCancelSubscription"
          />
        </div>
        <div v-if="plan.slug === 'pro'">
          <pro-pane
            :plan="plan"
            :isYearly="isYearly"
            :minimumPlanIndex="minimumPlanIndex"
            :currentPlanIndex="currentPlanIndex"
            :selectedPlanIndex="selectedPlanIndex"
            :isUpgradeProcessing="isUpgradeProcessing || priceCalculationPending"
            @setPriceSelection="setPriceIndex"
            @submit="handleSubmit"
          />
        </div>
      </div>
    </div>
    <div class="customPricing">
      <a href="mailto:hi@bridge.audio">Contact us</a> if you need a more custom pricing
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash.get';

import { subscribeFreeTrial, cancelSubscription } from '@/utils/actions/subscriptions';
import { bus } from '@/utils/bus';
import {
  hasActiveSubscription,
  isFreeTrial,
  getFreeTrialEndTimestamp,
  isSubscriptionCanceled
} from '@/utils/functions/workspace';
import {
  getPlanVariantsSortedByPrice,
  getPlanVariantFeatureFormated,
  getPlanVariantPrice
} from '@/utils/functions/plan';
import {
  STRIPE_CALCULATE_SUBSCRIPTION_PRICE,
  STRIPE_CUSTOMER_CARD
} from '@/graphql/queries/subscription';
import { STRIPE_UPDATE_SUBSCRIPTION } from '@/graphql/mutations/subscription';
import { ME } from '@/graphql/queries/user';

import UpgradePlanModal from '@/containers/modals/upgradePlan';
import UpgradeFreeTrialModal from '@/containers/modals/upgradeFreeTrial';
import freePane from './freePane';
import proPane from './proPane';

export default {
  props: {
    plans: {
      type: Array,
      required: false,
      default: () => []
    },
    currentPlan: {
      type: String,
      required: true
    },
    selectedPlanIndex: {
      type: Number,
      required: false,
      default: 0
    },
    minimumPlanIndex: {
      type: Number,
      required: false,
      default: 0
    },
    currentPlanIndex: {
      type: Number,
      required: false,
      default: 0
    },
    isYearly: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    freePane,
    proPane
  },
  data() {
    return {
      isUpgradeProcessing: false,
      isCreatingFreeTrial: false,
      priceCalculationPending: false,
      hasPaymentMethod: false
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'userCurrency'])
  },
  apollo: {
    me: {
      query: ME
    }
  },
  methods: {
    isSubscriptionCanceled() {
      return isSubscriptionCanceled(this.currentWorkspace);
    },
    getEmail() {
      return this.me.email;
    },
    setPriceIndex(value) {
      this.$emit('update:selectedPlanIndex', parseInt(value, 10));
    },
    toggleSwitchButton(value) {
      this.$emit('selectAnnual', value);
    },
    handleSubmit() {
      if (this.isUpgradeProcessing === true) {
        return;
      }
      this.isUpgradeProcessing = true;
      if (!this.currentWorkspace.free_trial_used) {
        this.isCreatingFreeTrial = true;
        this.handleSubscribeFreeTrial();
        return;
      }
      if (isFreeTrial(this.currentWorkspace)) {
        this.handleUpgradeFreeTrial();
        return;
      }
      if (hasActiveSubscription(this.currentWorkspace)) {
        this.displaySubscriptionSummaryModal();
        return;
      }
      this.$emit('subscribe');
    },
    async handleSubscribeFreeTrial() {
      const proPlan = this.plans.find(i => {
        return i.slug === 'pro';
      });
      await subscribeFreeTrial(
        this,
        this.me,
        this.currentWorkspace,
        getPlanVariantsSortedByPrice(proPlan)[this.selectedPlanIndex].id,
        this.isYearly ? 'yearly' : 'monthly'
      )
        .then(() => {
          this.displayFreeTrialAskPaymentMethodModal();
        })
        .catch(() => {
          bus.$emit('showAlert', {
            message: { key: 'subscriptionFailed' },
            style: 'error',
            delay: 5000
          });
        });
    },
    async handleUpgradeFreeTrial() {
      const paymentMethod = await this.$apollo.query({
        query: STRIPE_CUSTOMER_CARD,
        variables: { workspaceId: this.currentWorkspace.id }
      });
      this.hasPaymentMethod = get(paymentMethod, 'data.StripeCustomerCards', []).length !== 0;
      if (this.hasPaymentMethod) {
        this.displaySubscriptionSummaryModal();
      } else {
        const { subscription } = this.currentWorkspace;
        const proPlan = this.plans.find(i => {
          return i.slug === 'pro';
        });
        const result = await this.$apollo
          .mutate({
            mutation: STRIPE_UPDATE_SUBSCRIPTION,
            variables: {
              planVariantId: getPlanVariantsSortedByPrice(proPlan)[this.selectedPlanIndex].id,
              subscriptionId: subscription.payment_information.subscription_id,
              billingPeriod: this.isYearly ? 'yearly' : 'monthly',
              billingCurrency: subscription.payment_information.currency,
              seats: subscription.payment_information.seats
            }
          })
          .catch(() => {
            this.isUpgradeProcessing = false;
            bus.$emit('showAlert', {
              message: { key: 'subscriptionFailed' },
              style: 'error',
              delay: 5000
            });
          });
        if (get(result, 'data.StripeUpdateSubscription.subscription_id')) {
          this.displayFreeTrialAskPaymentMethodModal(false);
        }
      }
    },
    async displayFreeTrialAskPaymentMethodModal(creation = true) {
      this.isUpgradeProcessing = false;
      /* We waits for the back to be updated with Stripe new values */
      setTimeout(() => {
        this.$apollo.query({
          query: ME,
          fetchPolicy: 'network-only'
        });
      }, 2500);
      this.$emit('updateCurrentPlanIndex');
      bus.$emit('displayModal', {
        component: UpgradeFreeTrialModal,
        isVisible: true,
        size: 'medium',
        onSubmit: this.closeShareModal,
        title: creation ? 'You subscribed for a free trial' : 'You updated your free trial',
        props: [
          {
            name: 'freeTrialEndTimestamp',
            value: getFreeTrialEndTimestamp(this.currentWorkspace)
          }
        ]
      });
    },
    async displaySubscriptionSummaryModal() {
      this.priceCalculationPending = true;
      const variables = {
        workspaceId: this.currentWorkspace.id,
        planVariantId: getPlanVariantsSortedByPrice(this.plans[1])[this.selectedPlanIndex].id,
        seats: this.currentWorkspace.seats_used,
        subscriptionId: this.currentWorkspace.subscription.payment_information.subscription_id,
        billingPeriod: this.isYearly ? 'yearly' : 'monthly'
      };
      const apiResponse = await this.$apollo.query({
        query: STRIPE_CALCULATE_SUBSCRIPTION_PRICE,
        fetchPolicy: 'network-only',
        variables
      });
      const newVariant = getPlanVariantsSortedByPrice(this.plans[1])[this.selectedPlanIndex];
      const upgradedPlanPrice = getPlanVariantPrice(newVariant, this.userCurrency, this.isYearly);
      const upgradedPlanTracks = getPlanVariantFeatureFormated(newVariant, 'libraryFilesMax');
      const upgradedPlanStorage = getPlanVariantFeatureFormated(newVariant, 'storage');
      bus.$emit('displayModal', {
        title: 'Change Plan',
        component: UpgradePlanModal,
        isVisible: true,
        size: 'medium',
        onSubmit: () => {
          this.isUpgradeProcessing = true;
          this.$emit('upgradeSubscription');
        },
        props: [
          {
            name: 'stripeInfos',
            value: get(apiResponse, 'data.StripeCalculateSubscriptionPrice')
          },
          { name: 'reactivatePlan', value: true },
          { name: 'upgradedPlanPrice', value: upgradedPlanPrice },
          { name: 'upgradedPlanTracks', value: upgradedPlanTracks },
          { name: 'upgradedPlanStorage', value: upgradedPlanStorage },
          { name: 'isYearly', value: this.isYearly }
        ]
      });
      this.isUpgradeProcessing = false;
      this.priceCalculationPending = false;
    },
    handleCancelSubscription(password) {
      cancelSubscription(this, password);
    }
  }
};
</script>

<style lang="scss" scoped>
.pricingContainer {
  width: 820px;
  margin: 0 auto;
}

.pricingHeader {
  text-align: center;
}

.pricingTitle {
  @include heading-2;
  height: 40px;
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricingSubscriptionPeriod {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0 24px;
}

.plansContainer {
  display: flex;
}

.plan {
  border-radius: 10px;
  padding: 40px;
  max-width: 400px;
  background: white;
  flex: 1 1 0em;
}

.free {
  margin-right: 20px;
  background: #fff url('/images/billing/background-free.svg') no-repeat bottom right;
  background-size: 190px 190px;
  border: 1px solid $color_neutral_30;
  box-sizing: border-box;
}

.pro {
  background: #2f43de url('/images/billing/background-pro.svg') no-repeat center 60px;
  background-size: cover;
  color: $color_neutral_0;
}

.customPricing {
  text-align: center;
  margin-top: 40px;
  font-size: 14px;
}

.formOverlay {
  position: fixed;
  z-index: 10;
  background: rgba(37, 36, 40, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formProcessing {
  background: white;
  width: 500px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.formProcessingMessage {
  font-size: 16px;
  font-weight: 600;
  margin-top: 30px;
  color: $color_neutral_100;
}
</style>

<style lang="scss">
.periodSwitchButton.tooltip {
  width: 300px;
  .tooltip-inner {
    width: 300px;
    max-width: 300px;
    text-align: center;
  }
}
</style>
