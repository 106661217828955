import get from 'lodash.get';

import { WORKSPACE } from '@/graphql/queries/user';
import {
  STRIPE_CREATE_SUBSCRIPTION,
  STRIPE_CREATE_CUSTOMER,
  STRIPE_CANCEL_SUBSCRIPTION
} from '@/graphql/mutations/subscription';

import { get as getRoute } from '@/router/routes';

import { appConfig } from '@/utils/constants';

import ConfirmSubscriptionCancel from '@/containers/modals/confirmSubscriptionCancellation';

import { isUserInEuroZone } from '@/utils/functions/user';
import { XMLHttpRequestHandler } from '@/utils/functions/xhr';
import { bus } from '@/utils/bus';
import { isValidUUID } from '@/utils/functions/global';

export const saveBillingInformations = (that, variables) => {
  return that.$apollo.mutate({
    mutation: STRIPE_CREATE_CUSTOMER,
    variables
  });
};

export const subscribeFreeTrial = (that, me, workspace, planId, period = 'monthly') => {
  return new Promise((resolve, reject) => {
    if (!planId || !isValidUUID(planId)) {
      console.debug('error invalid planId', planId) /* eslint-disable-line */;
      return;
    }
    XMLHttpRequestHandler('/country', async result => {
      that.$store.commit('setUserCountry', result.country);
      let userCurrency = appConfig.currencies.DOLLAR;
      if (!isUserInEuroZone(result.country)) {
        that.$store.commit('setUserCurrency', appConfig.currencies.DOLLAR);
        userCurrency = appConfig.currencies.DOLLAR;
      } else {
        that.$store.commit('setUserCurrency', appConfig.currencies.EURO);
        userCurrency = appConfig.currencies.EURO;
      }

      await saveBillingInformations(that, {
        workspaceId: workspace.id,
        billingInformation: {
          address: {
            line1: '',
            line2: '',
            city: '',
            postal_code: '',
            country: result.country,
            state: ''
          },
          email: me.email
        }
      });

      await that.$apollo
        .mutate({
          mutation: STRIPE_CREATE_SUBSCRIPTION,
          variables: {
            planVariantId: planId,
            billingPeriod: period,
            billingCurrency: userCurrency,
            seats: 4,
            workspaceId: workspace.id,
            storage: 60,
            enableFreeTrial: true
          }
        })
        .then(() => {
          /* Wait 4s to be sure that Stripe hit backend with subscription informations */
          setTimeout(() => {
            that.$apollo
              .query({
                query: WORKSPACE,
                variables: {
                  workspaceId: that.currentWorkspace.id
                },
                fetchPolicy: 'network-only'
              })
              .then(response => {
                const currentWorkspace = get(response, 'data.Workspace', []);
                const freeTrial = get(
                  currentWorkspace,
                  'subscription.payment_information.free_trial'
                );
                const freeTrialDays = freeTrial
                  ? (freeTrial.end - freeTrial.start) / 24 / 60 / 60 // Convert ms timestamp in days
                  : 14; // default to 14 days in case of error
                bus.$emit('showAlert', {
                  message: { key: 'freeTrialStarted', args: { days: freeTrialDays } },
                  delay: 5000,
                  style: 'success',
                  confetti: true
                });
                resolve();
              });
          }, 4000);
        })
        .catch(err => {
          console.error('Stripe Error', err); /* eslint-disable-line */
          reject();
        });
    });
  });
};

export const updateBillingDetails = async (that, workspace, billingInformation) => {
  await saveBillingInformations(that, {
    workspaceId: workspace.id,
    billingInformation
  });
};

export const cancelSubscription = async (that, password) => {
  const subscriptionId = get(
    that.currentWorkspace,
    'subscription.payment_information.subscription_id'
  );
  that.$apollo
    .mutate({
      mutation: STRIPE_CANCEL_SUBSCRIPTION,
      variables: {
        workspaceId: that.currentWorkspace.id,
        userPassword: password,
        subscriptionId
      }
    })
    .then(() => {
      bus.$emit('displayModal', {
        component: ConfirmSubscriptionCancel,
        isVisible: true,
        size: 'medium',
        onSubmit: () => {
          window.location.href = getRoute('billingSettings');
        },
        title: 'Your cancellation is confirmed'
      });
    })
    .catch(() => {
      bus.$emit('closeModal');
      bus.$emit('showAlert', {
        message: { key: 'subscription.cancellationError' },
        style: 'error',
        delay: 5000
      });
    });
};
