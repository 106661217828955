<template>
  <div v-if="isLoadingProject && this.projects.length === 0" class="loader">
    <spinner-without-progress color="grey" :size="32" />
  </div>
  <div v-else>
    <list-projects
      :projects="projects"
      @goToProjectDetail="goToProjectDetail"
      @duplicate="handleDuplicateProjectMutation"
      @share="openShareModal"
      @delete="openDeleteProjectModal"
      @copyToClipboard="copyToClipboard"
    />
    <observer v-if="hasMoreToLoad" :height="64" bottom @intersect="loadMore" />
    <div v-if="projects.length === 0">
      <div v-if="!hasSearch">
        <empty-project
          :title="$t('emptyProjectTitle')"
          :description="$t('emptyProjectSubtext')"
          placeholderType="projectlist"
          :cloudedTextContent="$t('createProjects')"
          :cloudedTextColor="{
            stroke: '#FF9143',
            fill: '#FFF9EB'
          }"
        >
          <template v-slot:button>
            <submit-button
              size="large"
              :label="$t('newProject')"
              iconClass="ri-folder-add-fill"
              @submit="$emit('openCreateProjectModal')"
            />
          </template>
        </empty-project>
      </div>
      <div v-else>
        <div class="no-result">{{ $t('noResult') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import clonedeep from 'lodash.clonedeep';
import get from 'lodash.get';

import { bus } from '@/utils/bus';
import { PROJECT_DUPLICATE } from '@/graphql/mutations/project';
import { PROJECTS_INFORMATION, SEARCH_PROJECTS } from '@/graphql/queries/project';
import { PAGINATOR_LIMIT } from '@/utils/constants';
import { get as getRoute } from '@/router/routes';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import EmptyProject from '@/containers/emptyView';
import ListProjects from '@/containers/listProject';
import Observer from '@/containers/observer';
import ShareModal from '@/containers/modals/share';
import { trackEvent } from '@/utils/functions/analytics';

export default {
  components: {
    EmptyProject,
    Observer,
    ListProjects
  },
  props: {
    projects: {
      type: Array,
      required: true
    },
    isLoadingProject: {
      type: Boolean,
      required: true
    },
    hasMoreToLoad: {
      type: Boolean,
      required: true
    },
    hasSearch: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace'])
  },
  methods: {
    loadMore() {
      this.$emit('loadMore');
    },
    copyToClipboard(projectId) {
      trackEvent(
        'Project Shared',
        {
          category: 'sharing',
          label: 'opened',
          project_id: projectId,
          workspace_id: this.currentWorkspace.id,
          property_position: 'projectList'
        },
        {
          All: false,
          'Google Analytics': true,
          FullStory: true,
          Amplitude: true
        }
      );
      bus.$emit('showAlert', {
        message: { key: 'copiedToClipboard' },
        style: 'success',
        delay: 5000
      });
    },
    openDeleteProjectModal(projectId) {
      const project = this.projects.find(el => el.id === projectId);
      const projectName = project.name;
      const isProjectEmpty = project.trackCount + project.attachmentCount < 1;
      this.$emit('openDeleteProjectModal', {
        projectId,
        projectName,
        isProjectEmpty
      });
    },
    handleDuplicateProjectMutation(projectId) {
      this.$apollo
        .mutate({
          mutation: PROJECT_DUPLICATE,
          variables: {
            fromProjectId: projectId
          },
          update: (cache, data) => {
            const cacheQuery = {
              query: SEARCH_PROJECTS,
              variables: {
                query: { workspaceId: this.currentWorkspace.id },
                filters: { status: { eq: 'active' } },
                sort: {
                  by: 'updated_at',
                  dir: 'desc'
                },
                offset: 0,
                limit: PAGINATOR_LIMIT
              }
            };
            const clonedCacheData = clonedeep(cache.readQuery(cacheQuery));
            const newProject = data.data.ProjectDuplicate;
            const fromProject = clonedeep(
              clonedCacheData.SearchProjects.data.find(item => item.id === projectId)
            );
            // We update only the required values from the newly duplicated Project
            fromProject.id = newProject.id;
            fromProject.name = get(data, 'data.ProjectDuplicate.name', fromProject.name);
            fromProject.createdAt = newProject.created_at;
            fromProject.createdBy = newProject.owner_id;
            clonedCacheData.SearchProjects.data.unshift(fromProject);
            cache.writeQuery({
              ...cacheQuery,
              data: clonedCacheData
            });
          },
          refetchQueries: [
            {
              /* Refresh tabs counter */
              query: PROJECTS_INFORMATION,
              variables: {
                workspaceId: this.currentWorkspace.id
              }
            }
          ]
        })
        .then(() => {
          bus.$emit('showAlert', {
            message: { key: 'copyProjectSuccess' },
            style: 'success',
            delay: 5000
          });
        })
        .catch(error => {
          this.projectName = null;
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    goToProjectDetail(projectId) {
      this.$router.push(getRoute('project', { id: projectId }));
    },
    openShareModal(projectId, defaultTab) {
      const { name } = this.projects.find(project => project.id === projectId);
      this.projectName = name;
      bus.$emit('displayModal', {
        component: ShareModal,
        title: this.$t('shareProjectName', { name }),
        size: 'medium',
        isVisible: true,
        onSubmit: this.closeShareModal,
        props: [
          { name: 'entityName', value: name },
          { name: 'entityIds', value: [projectId] },
          { name: 'entityType', value: 'project' },
          defaultTab ? { name: 'defaultTab', value: defaultTab } : []
        ].filter(Boolean)
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.loader {
  height: calc(100vh - 56px - 121px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-result {
  font-size: 14px;
  color: $color_neutral_100;
  display: flex;
  justify-content: center;
  margin-left: 28px;
  margin-top: 56px;
}
</style>
