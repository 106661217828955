<template>
  <div v-if="me" class="account">
    <div v-if="blob" class="account-cropper">
      <image-cropper
        :blob="blob"
        :submitButtonLabel="$t('save')"
        :cancelButtonLabel="$t('cancel')"
        :imageWidth="cropperMaxWidth"
        :imageHeight="cropperMaxHeight"
        @cropImage="cropImage"
      />
    </div>
    <div v-if="!blob" class="account-form">
      <div class="account-form-section">
        <div class="account-form-section-title">{{ $t('accountInformation') }}</div>
        <div class="account-form-section-description">{{ $t('accountDescription') }}</div>
        <div class="account-form-section-information">
          <image-browser :imgUrl="imgUrl" @changeImgUrl="changeImgUrl" pictureSize="large" />
          <div class="account-form-section-name">
            <div class="account-form-section-information-textfield">
              <text-field
                :key="`firstname-${randomKey}`"
                inputType="text"
                :errorMessage="firstnameValidationMessage"
                :fieldModel.sync="$v.firstname.$model"
                :value="me.firstname"
                placeholder
                :label="$t('firstname')"
                large
                @handleInput="handleUserDisableStatus"
              />
            </div>
            <div class="account-form-section-information-textfield">
              <text-field
                :key="`lastname-${randomKey}`"
                inputType="text"
                :errorMessage="lastnameValidationMessage"
                :fieldModel.sync="$v.lastname.$model"
                :value="me.lastname"
                placeholder
                :label="$t('lastname')"
                large
                @handleInput="handleUserDisableStatus"
              />
            </div>
          </div>
        </div>
        <div class="account-form-section-button">
          <submit-button
            :label="$t('save')"
            :disabled="userDisableStatus"
            :pending="userSubmitStatus === 'PENDING'"
            @submit="updateUser"
          />
        </div>
      </div>
      <div class="account-form-section">
        <h2 class="account-form-section-title">{{ $t('emailAddress') }}</h2>
        <div v-if="UserEmails && UserEmails.length > 0">
          <div class="account-form-section-description">{{ $t('emailAddressDescription') }}</div>
        </div>
        <div class="account-form-section-description" v-else>No primary email to edit !</div>
        <div class="account-form-section-column">
          <div v-if="UserEmails && UserEmails.length > 0">
            <h3 class="account-form-section-column-title">{{ $t('primaryEmail') }}</h3>
            <div
              :class="{
                'account-form-section-column-text': true,
                'account-form-section-column-email-pending': !primaryEmail.is_verified
              }"
            >
              <div v-if="!displayChangePrimaryEmail" class="primary-email">
                {{ primaryEmail.email }}
                <span v-if="!primaryEmail.is_verified">
                  {{ $t('pending') }}&nbsp;-&nbsp;
                  <span
                    class="account-form-section-column-text-link"
                    @click="resendConfirmationEmail(primaryEmail)"
                  >
                    {{ $t('resendVerificationEmail') }}
                  </span>
                  )
                </span>
                <span class="btn-edit">
                  <icon-button
                    icon="ri-edit-line"
                    size="small"
                    btnStyle="secondary"
                    @submit="togglePrimaryEmailEdit(true)"
                    v-tooltip="$t('edit')"
                  />
                </span>
              </div>
              <div v-else class="primary-email-change-form">
                <change-primary-email
                  :primaryMail="primaryEmail"
                  @togglePrimaryEmailEdit="togglePrimaryEmailEdit"
                />
              </div>
            </div>
            <h3 class="account-form-section-column-title">{{ $t('secondaryEmail') }}</h3>
            <div
              :key="item.id"
              v-for="(item, index) in UserEmails.filter(i => !i.is_primary)"
              class="account-form-section-column-email"
            >
              <div
                v-if="index !== verifyDeleteIndex"
                :class="{
                  'account-form-section-column-email-pending': !item.is_verified
                }"
              >
                <span>{{ item.email }}</span>
                <span v-if="!item.is_verified">
                  ({{ $t('pending') }}&nbsp;-&nbsp;
                  <span
                    class="account-form-section-column-text-link"
                    @click="resendConfirmationEmail(item)"
                  >
                    {{ $t('resendVerificationEmail') }}
                  </span>
                  )
                </span>
                <span class="account-form-section-column-email-action">
                  <icon-button
                    icon="ri-delete-bin-line"
                    size="small"
                    btnStyle="secondary"
                    @submit="verifyDeleteAddress(index)"
                    v-tooltip="$t('remove')"
                  />
                </span>
              </div>
              <div v-else>
                <span>{{ $t('remove') }}&nbsp;</span>
                <span class="account-form-section-column-email-bold">{{ item.email }}</span>
                <span>&nbsp;?&nbsp;</span>
                <span
                  class="account-form-section-column-email-action account-form-section-column-email-action-visible"
                >
                  <submit-button
                    :label="$t('remove')"
                    @submit="deleteEmailAddress(item)"
                    btnStyle="secondary"
                    size="small"
                  />
                  <submit-button
                    @submit="resetVerifyDeleteIndex"
                    :label="$t('cancel')"
                    btnStyle="secondary"
                    size="small"
                  />
                </span>
              </div>
            </div>
            <div v-if="displaySecondaryEmailForm" class="emailForm">
              <text-field
                inputType="email"
                :large="false"
                :placeholder="$t('enterEmailAddress')"
                :inputWidth="300"
                :fieldModel.sync="$v.newEmail.$model"
                :errorMessage="addEmailAddressError"
                class="emailForm-textfield"
              />
              <submit-button :label="$t('add')" @submit="addEmailAddress" />
              <submit-button
                :label="$t('cancel')"
                @submit="toggleAddEmailForm(false)"
                btnStyle="secondary"
              />
            </div>
            <div v-else>
              <div v-if="UserEmails.length <= 5">
                <submit-button
                  iconClass="ri-add-line"
                  :label="$t('addEmailAddress')"
                  btnStyle="tertiary"
                  @submit="toggleAddEmailForm(true)"
                  :exception="true"
                />
              </div>
              <div v-else>{{ $t('maximumFiveSecondaryEmails') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="account-form-section">
        <div class="account-form-section-title">{{ $t('password') }}</div>
        <div class="account-form-section-description">{{ $t('changePassword') }}</div>
        <div class="account-form-section-column">
          <div class="account-form-section-column-textfield">
            <text-field
              :key="`password-${randomKey}`"
              withVisibility
              inputType="password"
              :errorMessage="null"
              :fieldModel.sync="$v.currentPassword.$model"
              :placeholder="$t('currentPasswordPlaceholder')"
              :label="$t('currentPassword')"
              large
              @handleInput="handlePasswordDisableStatus"
            />
          </div>
          <div class="account-form-section-column-textfield">
            <text-field
              :key="`newpassword-${randomKey}`"
              withVisibility
              withCheck
              inputType="password"
              :errorMessage="newPasswordMessageValidation"
              :fieldModel.sync="$v.newPassword.$model"
              :placeholder="$t('differentPassword')"
              :label="$t('newPassword')"
              :securityLabels="securityLabels"
              large
              @handleInput="handlePasswordDisableStatus"
            />
          </div>
          <div class="account-form-section-column-textfield">
            <text-field
              :key="`newpassword2-${randomKey}`"
              withVisibility
              inputType="password"
              :errorMessage="confirmNewPasswordMessageValidation"
              :fieldModel.sync="$v.confirmNewPassword.$model"
              :placeholder="$t('confirmNewPasswordPlaceholder')"
              :label="$t('confirmNewPassword')"
              large
              @handleInput="handlePasswordDisableStatus"
            />
          </div>
        </div>
        <submit-button
          :label="$t('save')"
          :disabled="passwordDisableStatus"
          :pending="passwordSubmitStatus === 'PENDING'"
          @submit="updateUserPassword"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import get from 'lodash.get';
import { mapGetters } from 'vuex';
import clonedeep from 'lodash.clonedeep';
import { required, minLength, maxLength, sameAs, email } from 'vuelidate/lib/validators';
import { VTooltip } from 'v-tooltip';
import { ME, USER_EMAILS } from '@/graphql/queries/user';
import { handleUserProfilePictureUpload } from '@/utils/functions/upload';
import {
  UPDATE_USER,
  UPDATE_USER_PASSWORD,
  USER_EMAIL_ADD,
  USER_EMAIL_DELETE,
  USER_EMAIL_RESEND_CONFIRMATION
} from '@/graphql/mutations/user';
import { bus } from '@/utils/bus';
import { appConfig } from '@/utils/constants';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import SaveChangesModal from '@/containers/modals/saveChanges';
import ChangePrimaryEmail from '@/containers/form/changePrimaryEmail';

Vue.directive('tooltip', VTooltip);

export default {
  components: {
    ChangePrimaryEmail
  },
  data() {
    return {
      imgUrl: null,
      blob: null,
      firstname: null,
      lastname: null,
      primaryEmail: null,
      currentPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      cropperMaxWidth: appConfig.upload.user.width,
      cropperMaxHeight: appConfig.upload.user.height,
      userDisableStatus: true,
      randomKey: 0,
      userSubmitStatus: 'OK',
      passwordDisableStatus: true,
      passwordSubmitStatus: 'OK',
      securityLabels: [this.$t('weak'), this.$t('fair'), this.$t('strong')],
      displaySecondaryEmailForm: false,
      verifyDeleteIndex: undefined,
      addEmailAddressError: undefined,
      displayChangePrimaryEmail: false
    };
  },
  validations: {
    firstname: {
      required,
      maxLength: maxLength(50),
      minLength: minLength(2)
    },
    lastname: {
      required,
      maxLength: maxLength(50),
      minLength: minLength(2)
    },
    currentPassword: {
      required
    },
    newPassword: {
      required,
      minLength: minLength(8)
    },
    confirmNewPassword: {
      required,
      sameAsNewPassword: sameAs('newPassword')
    },
    newEmail: {
      required,
      email
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    firstnameValidationMessage() {
      if (!this.$v.firstname.required && this.$v.firstname.$dirty)
        return this.$t('required', { label: this.$t('firstname') });
      if (!this.$v.firstname.maxLength)
        return this.$t('maxLength', {
          label: this.$t('firstname'),
          nbChars: this.$v.firstname.$params.maxLength.max
        });
      if (!this.$v.firstname.minLength)
        return this.$t('minLength', {
          label: this.$t('firstname'),
          nbChars: this.$v.firstname.$params.minLength.min
        });

      return null;
    },
    lastnameValidationMessage() {
      if (!this.$v.lastname.required && this.$v.lastname.$dirty)
        return this.$t('required', { label: this.$t('lastname') });
      if (!this.$v.lastname.maxLength)
        return this.$t('maxLength', {
          label: this.$t('lastname'),
          nbChars: this.$v.lastname.$params.maxLength.max
        });
      if (!this.$v.lastname.minLength)
        return this.$t('minLength', {
          label: this.$t('lastname'),
          nbChars: this.$v.lastname.$params.minLength.min
        });
      return null;
    },
    newPasswordMessageValidation() {
      if (!this.$v.newPassword.minLength) {
        return this.$t('minLength', {
          nbChars: this.$v.newPassword.$params.minLength.min,
          label: this.$t('password')
        });
      }
      return null;
    },
    confirmNewPasswordMessageValidation() {
      return this.$v.confirmNewPassword.sameAsNewPassword ? null : this.$t('identicalPassword');
    },
    saveChangesModal() {
      let { components } = this.$options;
      components = {
        ...components,
        SaveChangesModal
      };
      return {
        title: null,
        size: 'medium',
        isVisible: false,
        component: components.SaveChangesModal,
        onSubmit: this.saveChanges,
        onCancel: this.cancelChanges
      };
    }
  },
  mounted() {
    this.firstname = get(this.me, 'firstname', '');
    this.lastname = get(this.me, 'lastname', '');
    this.imgUrl = get(this.me, 'profile_picture.url', '');
  },
  beforeRouteLeave(to, from, next) {
    if (this.userDisableStatus && this.passwordDisableStatus) {
      next();
    } else {
      bus.$emit('displayModal', { ...this.saveChangesModal, isVisible: true });
    }
  },
  apollo: {
    me: {
      query: ME
    },
    UserEmails: {
      query: USER_EMAILS,
      result(response) {
        this.primaryEmail = response.data.UserEmails.find(item => item.is_primary);
      }
    }
  },
  methods: {
    toggleAddEmailForm(status) {
      this.displaySecondaryEmailForm = status;
    },
    togglePrimaryEmailEdit(bool) {
      this.displayChangePrimaryEmail = bool;
    },
    addEmailAddress() {
      this.$apollo
        .mutate({
          mutation: USER_EMAIL_ADD,
          variables: {
            email: this.$v.newEmail.$model
          },
          update: (cache, { data }) => {
            this.toggleAddEmailForm(false);
            const emails = cache.readQuery({ query: USER_EMAILS });
            const newData = clonedeep(emails);
            newData.UserEmails.push({
              email: data.UserEmailAdd.email,
              id: data.UserEmailAdd.id,
              is_verified: false,
              is_primary: false,
              __typename: 'UserEmail'
            });
            cache.writeQuery({
              query: USER_EMAILS,
              data: newData
            });
          }
        })
        .then(data => {
          if (data.errors) {
            const key = getGraphQlErrorCode(data.errors);
            this.addEmailAddressError = this.$t(key);
            setTimeout(() => {
              this.addEmailAddressError = undefined;
            }, 3000);
          }
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          if (key === 'REQUEST_INPUT_VALIDATION') {
            this.addEmailAddressError = this.$t('invalidEmail');
          } else if (key === 'user_email_exist') {
            this.addEmailAddressError = this.$t('errors.primary_email_unique');
            bus.$emit('showAlert', {
              message: { key: this.addEmailAddressError },
              style: 'danger',
              delay: 5000
            });
          } else {
            this.addEmailAddressError = this.$t(key);
          }
          setTimeout(() => {
            this.addEmailAddressError = undefined;
          }, 5000);
        });
    },
    resendConfirmationEmail(item) {
      this.$apollo
        .mutate({
          mutation: USER_EMAIL_RESEND_CONFIRMATION,
          variables: {
            id: item.id
          }
        })
        .then(() => {
          bus.$emit('showAlert', {
            message: { key: 'resendCheckingEmail' },
            style: 'success',
            delay: 5000,
            error: false
          });
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    resetVerifyDeleteIndex() {
      this.verifyDeleteIndex = undefined;
    },
    verifyDeleteAddress(index) {
      this.verifyDeleteIndex = index;
    },
    deleteEmailAddress(item) {
      this.$apollo
        .mutate({
          mutation: USER_EMAIL_DELETE,
          variables: {
            id: item.id
          },
          update: cache => {
            this.verifyDeleteIndex = undefined;
            const emails = cache.readQuery({ query: USER_EMAILS });
            const newData = clonedeep(emails);
            const indexToRemove = newData.UserEmails.findIndex(
              dataEmail => item.id === dataEmail.id
            );
            newData.UserEmails.splice(indexToRemove, 1);
            cache.writeQuery({
              query: USER_EMAILS,
              data: newData
            });
          }
        })
        .then(() => {
          bus.$emit('showAlert', {
            message: { key: 'emailDeleted' },
            style: 'success',
            delay: 5000
          });
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    changeImgUrl(url) {
      this.blob = url;
      this.imgUrl = null;
    },
    cropImage(blob, url) {
      this.imgUrl = url || this.me.profile_picture.url;
      this.blob = null;
      if (blob) {
        this.updateProfilePicture(blob);
      }
    },
    updateProfilePicture(blob) {
      const file = new File([blob], 'newProfilePicture', { type: 'image/jpeg' });
      handleUserProfilePictureUpload(this, file);
    },
    handleUserDisableStatus() {
      if (
        this.$v.firstname.$model !== this.me.firstname ||
        this.$v.lastname.$model !== this.me.lastname
      )
        this.userDisableStatus = false;
      if (this.firstnameValidationMessage || this.lastnameValidationMessage)
        this.userDisableStatus = true;
    },
    saveChanges() {
      if (!this.userDisableStatus) {
        this.updateUser();
      }
      if (!this.passwordDisableStatus) {
        this.updateUserPassword();
      }
    },
    cancelChanges() {
      this.randomKey = Math.floor(Math.random() * 100000);
      this.userDisableStatus = true;
      this.passwordDisableStatus = true;
    },
    updateUser() {
      this.userSubmitStatus = 'PENDING';
      this.$apollo
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            id: this.me.id,
            firstname: this.$v.firstname.$model,
            lastname: this.$v.lastname.$model
          }
        })
        .then(() => {
          this.userSubmitStatus = 'OK';
          this.userDisableStatus = true;
          bus.$emit('showAlert', {
            message: { key: 'modificationSuccess' },
            style: 'success',
            delay: 5000
          });
        })
        .catch(error => {
          this.userSubmitStatus = 'OK';
          this.userDisableStatus = true;
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    handlePasswordDisableStatus() {
      if (
        this.$v.currentPassword.$model &&
        this.$v.newPassword.$model &&
        this.$v.confirmNewPassword.$model
      )
        this.passwordDisableStatus = false;
      if (
        this.newPasswordMessageValidation ||
        this.confirmNewPasswordMessageValidation ||
        !this.$v.currentPassword.$model ||
        !this.$v.newPassword.$model ||
        !this.$v.confirmNewPassword.$model
      )
        this.passwordDisableStatus = true;
    },
    updateUserPassword() {
      this.passwordSubmitStatus = 'PENDING';
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_PASSWORD,
          variables: {
            currentPassword: this.$v.currentPassword.$model,
            newPassword: this.$v.newPassword.$model
          }
        })
        .then(() => {
          this.passwordSubmitStatus = 'OK';
          this.passwordDisableStatus = true;
          bus.$emit('showAlert', {
            message: { key: 'modificationSuccess' },
            style: 'success',
            delay: 5000
          });
        })
        .catch(error => {
          this.passwordSubmitStatus = 'OK';
          this.passwordDisableStatus = true;
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.account {
  position: relative;

  &-form {
    &-section {
      border-bottom: 1px dotted $color_neutral_40;
      padding: 32px 0;

      &:first-child {
        padding: 0 0 32px 0;
      }

      &:last-child {
        border: none;
      }

      &-title {
        @include heading-6;
        color: $color_neutral_100;
      }

      &-description {
        margin: 6px 0 0 0;
        @include body-2;
        color: $color_neutral_60;
      }

      &-information {
        display: flex;
        flex-direction: column;
        padding: 24px 0 0 0;
        align-items: flex-start;

        &-textfield {
          max-width: 342px;
          width: 100%;
          &:last-child {
            padding: 0 0 0 16px;
          }
        }
      }
      &-name {
        width: 100%;
        display: flex;
        margin-top: 24px;
      }

      &-column {
        &-title {
          margin: 24px 0 8px;
          font-weight: 600;
        }
        &-text {
          @include body-1;
          padding: 0;

          &-link {
            cursor: pointer;
            color: $color_primary_100;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        &-email {
          font-size: 14px;
          color: $color_neutral_100;
          margin-bottom: 8px;
          &-bold {
            font-weight: 600;
          }
          &-pending {
            color: $color_neutral_60;
          }
          &-action {
            display: inline-block;
            margin-left: 8px;
            opacity: 0;
            visibility: hidden;
            button {
              display: inline-block;
              margin-right: 8px;
            }
          }
          &-action-visible {
            opacity: 1;
            visibility: visible;
          }
          &:hover {
            //FFS
            .account-form-section-column-email-action {
              transition: opacity 500ms ease-in-out;
              opacity: 1;
              visibility: visible;
            }
          }
        }

        &-textfield {
          padding: 24px 0 0 0;
          width: 464px;
          position: relative;

          &:last-child {
            padding: 24px 0 32px 0;
          }
        }
      }

      &-button {
        display: flex;
        justify-content: flex-start;
        padding: 24px 0 0;
      }
    }
  }

  &-cropper {
    position: absolute;
    background-color: $color_neutral_0;
    z-index: 2;
    left: -16px;
    height: 459px;
    top: 0;
    width: 100%;
  }
}
.primary-email {
  .btn-edit {
    visibility: hidden;
    display: inline-block;
    margin-left: 8px;
  }
  &:hover {
    .btn-edit {
      visibility: inherit;
    }
  }
  &-change-form {
    height: 32px;
    display: flex;
    align-items: center;
  }
}

.emailForm {
  display: flex;
  flex-direction: row;
}

.emailForm-textfield {
  position: relative;
}

.emailForm > * {
  margin-right: 8px;
}
</style>
