
<div
  class="support-panel-body"
  :key="renderKey"
  v-if="(supportDetails && !supportDetailsLoading) || noSupport || !isSameSupport"
>
  <div class="support-panel-body-title">
    <div class="support-panel-body-title--" @click="goToAlbumPage">{{ title }}</div>
    <div v-if="isSameSupport && !noSupport && $route.name !== 'album'">
      <submit-button
        btnStyle="secondary"
        size="small"
        :label="$t('edit')"
        @submit="editSupport"
      />
    </div>
  </div>
  <div v-if="noSupport">
    <div class="no-support">
      <div class="no-support-label">{{ $t('album') }}</div>
      <auto-complete-list
        :queries="autoCompleteSupport"
        :dataLoaded="isSupportsDataLoaded"
        dataType="SupportsAutocomplete"
        storeEvent="changeIsSupportsLoaded"
        :ignoreUnknown="true"
      >
        <template v-slot:default="slotProps">
          <input-dropdown
            key="support-tab-dropdown"
            id="support-tab-dropdown"
            :width="334"
            placeholder="Select or create a new album"
            :defaultList="slotProps.items"
            :loadingOptions="slotProps.isLoading"
            :showObserver="slotProps.showObserver"
            :fetchingMoreOptions="slotProps.fetchingMoreItems"
            preventRemoveOptOnKeyUp
            preventAddNew
            @preventedAddNew="openCreateSupport"
            @listOptions="slotProps.fetch"
            @fetchMoreOptions="slotProps.fetchMoreItems"
            @searchOption="slotProps.search"
            @handleResult="setSupports"
            @handleInput="HandleSupportInput"
          />
        </template>
      </auto-complete-list>
    </div>
  </div>
  <div v-else>
    <div class="support-panel-body-section">
      <div class="support-panel-body-section--">{{ $t('artwork') }}</div>
      <div class="support-panel-body-section--">
        <img :src="artwork" alt="artwork" />
      </div>
    </div>
    <div class="support-panel-body-section">
      <div class="support-panel-body-section--">{{ $t('type') }}</div>
      <div class="support-panel-body-section--">{{ type }}</div>
    </div>
    <div class="support-panel-body-section">
      <div class="support-panel-body-section--">{{ $t('artists') }}</div>
      <div class="artists-container" v-if="!isMultipleTrackEdition || isSameSupport">
        <div
          class="artist-name"
          v-for="(artist, id) in defaultArtistOptions"
          :key="`${id}-artist`"
          @click="goToArtistPage(artist.id)"
        >
          {{ artist.value }}<span v-if="id + 1 < defaultArtistOptions.length">,&nbsp;</span>
        </div>
      </div>
      <div class="artists-container" v-else>
        {{ multipleTemplate }}
      </div>
    </div>
    <div class="support-panel-body-section">
      <div class="support-panel-body-section--">{{ $t('releaseDate') }}</div>
      <div class="support-panel-body-section--">{{ getDate(releaseDate) }}</div>
    </div>
    <div class="support-panel-body-section">
      <div class="support-panel-body-section--">UPC</div>
      <div class="support-panel-body-section--">{{ upc }}</div>
    </div>
    <div class="support-panel-body-section">
      <div class="support-panel-body-section--">{{ $t('totalTracks') }}</div>
      <div class="support-panel-body-section--">{{ totalTracks }}</div>
    </div>
  </div>
</div>
<div v-else class="support-loader">
  <spinner-without-progress color="grey" :size="32" />
</div>
