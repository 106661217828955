
<div class="invitation-container">
  <div class="invitation">
    <div class="invitation-logo">
      <img src="/images/Logo-Full-Color.svg" />
    </div>
    <div class="invitation-step">2/2</div>
    <div class="invitation-title">{{ $t('inviteYourTeam') }}</div>
    <div class="invitation-helper">{{ $t('invitePeopleHelper') }}</div>
    <form @submit.prevent="submitInvitations" class="invitation-form" v-if="roles && Workspace">
      <div
        class="invitation-form-members"
        :class="invitations.length > currentWorkspace.subscription.seats && 'overflow'"
      >
        <div
          class="invitation-form-members-email"
          v-for="(invitation, index) in invitations"
          :key="`invitations-${invitation.id}`"
        >
          <div class="invitation-form-members-email-textField">
            <text-field
              inputType="text"
              :errorMessage="getMemberValidationMessage(index)"
              :fieldModel.sync="invitation.email"
              :placeholder="$t('enterEmail')"
              large
              focusOnLoad
              label="Email"
            />
          </div>
          <div class="invitation-form-members-email-dropdown">
            <roles-dropdown
              ref="dropdown"
              :roles="roleList"
              :userRole="roleList.find(role => role.value === '2')"
              withBorder
              large
              @setRole="setRole(index)"
              label="Role"
            />
          </div>
          <div class="invitation-form-members-email-remove">
            <icon-button
              v-if="invitations.length > 1"
              icon="ri-delete-bin-line"
              btnStyle="tertiary"
              size="small"
              isDanger
              @submit="removeInvitation(index)"
            />
          </div>
        </div>
      </div>
      <div class="invitation-form-add" v-if="invitations.length < 4">
        <submit-button
          btnStyle="tertiary"
          size="small"
          :label="$t('add')"
          iconClass="ri-add-line"
          :disabled="invitations.length === currentWorkspace.subscription.seats - 1"
          @submit="addInvitation"
          class="primary-colored-tertiary"
        />
      </div>
      <div class="invitation-form-separation">
        <div class="invitation-form-separation-line">
          <span class="invitation-form-separation-text">or</span>
        </div>
      </div>
      <div class="invitation-form-link">
        <submit-button
          class="primary-colored-tertiary"
          btnStyle="tertiary"
          :label="$t('copyInviteLink')"
          iconClass="ri-links-fill"
          @submit="createAndCopyInvitationLink"
        />
      </div>
      <div class="invitation-form-buttons">
        <div class="invitation-form-buttons-btn">
          <submit-button
            btnStyle="secondary"
            size="large"
            :label="$t('skipForNow')"
            @submit="nextStep"
            class="skipButton"
          />
          <submit-button
            size="large"
            iconClass="ri-mail-send-line"
            :label="$t('sendInvitesContinue')"
            :disabled="disabled"
            :pending="submitStatus === 'PENDING'"
            @submit="submitInvitations"
            class="submitButton"
          />
        </div>
      </div>
    </form>
    <div v-else class="invitation-form-loader">
      <spinner-without-progress color="grey" :size="32" />
    </div>
  </div>
  <div v-if="!isMobile" class="invitation-container-right">
    <activity-previewer :activityId="getActivityId()" />
  </div>
</div>
