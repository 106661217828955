<template>
  <div v-if="supportDetails && !error">
    <div class="album">
      <div :class="['album-edition', editTracksMode ? 'album-edition-open' : '']">
        <div class="album-edition-wrapper">
          <edit-tracks-panel
            v-if="editTracksMode"
            :libraryFileIds="libraryFileIds"
            :trackList="trackList"
            @renderAlbumView="renderAlbumView"
            @toggleEditMode="toggleEditMode"
          />
        </div>
      </div>
      <div
        class="album-tracks-container"
        :style="editTracksMode && 'width: calc(100% - 518px + 16px);'"
      >
        <project-list :class="{ 'track-loader-container': trackLoading }">
          <template v-slot:default="slotProps">
            <div class="album-tracks" v-if="isDataLoaded">
              <tracks
                :trackList="trackList"
                :projectList="slotProps.projectList"
                :noProjectSearchResult="slotProps.noProjectSearchResult"
                :showProjectObserver="slotProps.showProjectObserver"
                :loadingProjects="slotProps.loadingProjects"
                :fetchingMoreProjects="slotProps.fetchingMoreProjects"
                :keepProjectDropdownExpand="keepProjectDropdownExpand"
                :uncheckTracks="uncheckTracks"
                :editMode="editTracksMode"
                hasSelectAll
                withAlbumTrackNumber
                isSticky
                :stickyPosition="41"
                @openConfirmDeleteModal="openConfirmDeleteModal"
                @createProject="createProject"
                @searchProject="slotProps.searchProject"
                @fetchMoreProjects="slotProps.fetchMoreProjects"
                @listProjects="slotProps.listProjects"
                @toggleEditMode="toggleEditMode"
              />
            </div>
            <div v-if="trackLoading" class="track-loader">
              <spinner-without-progress color="grey" :size="32" />
            </div>
          </template>
        </project-list>
      </div>
    </div>
  </div>
  <div v-else class="loader">
    <spinner-without-progress color="grey" :size="32" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { LIBRARY_FILES_DETAILS, SUPPORT_DETAILS } from '@/graphql/queries/library';
import { DELETE_LIBRARY_FILE } from '@/graphql/mutations/library';
import { WORKSPACE } from '@/graphql/queries/user';
import { bus } from '@/utils/bus';
import { get as getRoute } from '@/router/routes';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import generateTracklist from '@/utils/functions/tracklist';
import ProjectList from '@/containers/projectList';
import Tracks from '@/containers/libraryTrackList';
import CreateProjectModal from '@/containers/modals/createProject';
import ConfirmDeleteModal from '@/containers/modals/confirmDelete';
import DeleteMultipleTracks from '@/containers/modals/deleteMultipleTracks';
import EditTracksPanel from '@/containers/editTracksPanel';
import { formatDate, formatDuration, FORMAT_DURATION } from '@/utils/functions/time';

export default {
  components: {
    ProjectList,
    Tracks,
    EditTracksPanel
  },
  props: {
    supportDetails: {
      type: Object,
      required: true
    }
  },
  apollo: {
    LibraryFilesDetails: {
      query: LIBRARY_FILES_DETAILS,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          libraryId: this.currentWorkspace.libraries[0].id,
          libraryFilesIds: this.supportDetails.tracks_ids
        };
      },
      result(response) {
        if (response.data && response.data.LibraryFilesDetails) {
          this.supportTracks = response.data.LibraryFilesDetails;
          this.isDataLoaded = true;
        }
      }
    }
  },
  data() {
    return {
      keepProjectDropdownExpand: false,
      libraryFileIds: [],
      uncheckTracks: false,
      backRouteName: 'tracks',
      editTracksMode: false,
      supportChangesIsPending: false,
      error: null,
      albumTotalTrack: null,
      supportTracks: [],
      isDataLoaded: false
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'playlist', 'newUpload']),
    trackList() {
      return generateTracklist(this.supportTracks, this.currentWorkspace, this.$i18n.locale);
    },
    albumFileIds() {
      return this.supportTracks.length ? this.supportTracks.map(item => item.id) : [];
    },
    createProjectModal() {
      let { components } = this.$options;
      components = {
        ...components,
        CreateProjectModal
      };
      return {
        title: this.$t('newProject'),
        size: 'medium',
        isVisible: false,
        component: components.CreateProjectModal
      };
    },
    confirmDeleteModal() {
      if (this.libraryFileIds.length <= 1) {
        return {
          title: this.$t('deleteTracks'),
          size: 'medium',
          component: ConfirmDeleteModal,
          onSubmit: this.handleDeleteTrackMutation,
          msg: 'confirmDeleteHard',
          props: [
            {
              name: 'tracksName',
              value:
                this.trackList && this.trackList.find(el => el.id === this.libraryFileIds[0]).title
            }
          ]
        };
      }
      return {
        title: this.$t('deleteTracks'),
        size: 'medium',
        component: DeleteMultipleTracks,
        onSubmit: this.handleDeleteTrackMutation,
        props: [{ name: 'tracksNumber', value: this.libraryFileIds.length }]
      };
    },
    trackLoading() {
      return !this.isDataLoaded && this.supportDetails;
    }
  },
  watch: {
    trackList() {
      if (this.playlist[this.supportDetails.id]) {
        this.$store.commit('changePlaylist', this.trackList);
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const that = vm;
      if (from.name === 'albums') {
        that.backRouteName = 'albums';
      } else {
        that.backRouteName = 'tracks';
      }
    });
  },
  mounted() {
    bus.$on('deleteTracksFromLibrary', () => {
      this.moveUpdateQueries();
    });
    if (this.newUpload && this.supportDetails) {
      this.$apollo.queries.supportDetails.refetch();
    }
  },
  updated() {
    if (this.supportDetails && this.supportDetails.tracks_number === 0) {
      this.$router.push(getRoute('library'));
    }
  },
  destroyed() {
    bus.$off('deleteTracksFromLibrary');
  },
  methods: {
    goToArtistPage(artistId) {
      this.$router.push(getRoute('artist', { id: artistId }));
    },
    toggleEditMode(value) {
      this.libraryFileIds = value;
      this.editTracksMode = this.libraryFileIds.length > 0;
      this.$store.commit('setEditPanelOpened', this.editTracksMode);
    },
    isUnknownAlbum() {
      return this.supportDetails && this.supportDetails.id === 'unknown';
    },
    goBack() {
      this.$router.push(getRoute(this.backRouteName));
    },
    getAlbumDuration(seconds) {
      return formatDuration(seconds, FORMAT_DURATION.hmm);
    },
    getDurationTrack(length) {
      return formatDuration(length, FORMAT_DURATION.hmm);
    },
    getDate(timestamp) {
      return formatDate(this.$i18n.locale, timestamp);
    },
    getAddedDate(timestamp) {
      return formatDate(this.$i18n.locale, timestamp);
    },
    createProject() {
      this.keepProjectDropdownExpand = true;
      bus.$emit('displayModal', { ...this.createProjectModal, isVisible: true });
    },
    closeCreateProjectModal() {
      this.keepProjectDropdownExpand = false;
      bus.$emit('displayModal', { ...this.createProjectModal, isVisible: false });
    },
    openConfirmDeleteModal(libraryFileIds) {
      this.libraryFileIds = libraryFileIds;
      bus.$emit('displayModal', {
        ...this.confirmDeleteModal,
        isVisible: true
      });
      this.uncheckTracks = false;
    },
    handleDeleteTrackMutation() {
      const libraryId = this.currentWorkspace.libraries[0].id;
      this.$apollo
        .mutate({
          mutation: DELETE_LIBRARY_FILE,
          variables: {
            libraryId,
            libraryFileIds: this.libraryFileIds
          },
          refetchQueries: [
            {
              query: SUPPORT_DETAILS,
              variables: {
                libraryId,
                supportId: this.$route.params.id
              }
            },
            {
              query: WORKSPACE,
              variables: { workspaceId: this.currentWorkspace.id }
            }
          ]
        })
        .then(() => {
          bus.$emit('showAlert', {
            message: { key: 'deleteTracksSuccess', params: this.libraryFileIds.length },
            style: 'success',
            delay: 5000
          });
          this.libraryFileIds = [];
          this.uncheckTracks = true;
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    renderAlbumView() {
      this.$emit('renderAlbumView');
    },
    moveUpdateQueries() {
      this.$emit('renderAlbumView');
      this.uncheckTracks = true;
    },
    mutationCatchError(error) {
      this.supportChangesIsPending = false;
      const key = getGraphQlErrorCode(error);
      bus.$emit('showAlert', {
        message: { key },
        style: 'danger',
        delay: 5000,
        error: true
      });
    },
    handleUnknownSupport(error) {
      this.error = error;
      if (this.$route.name !== 'trackList') this.$router.push(getRoute('trackList'));
    }
  }
};
</script>

<style lang="scss" scoped>
.album {
  &-back {
    padding: 0 0 24px 40px;
  }

  &-tracks {
    margin: 0 0 0 12px;
    &-container {
      transition: width 0.15s ease;
      width: 100%;
    }
  }

  &-edition {
    background-color: $color_neutral_0;
    position: fixed;
    top: 0;
    right: -520px;
    transition: right 0.15s ease;
    height: calc(100vh - 65px);
    overflow: auto;
    border-left: 1px solid $color_neutral_30;
    z-index: map-get($z-index, side-panel);
    &-open {
      right: 0;
      @include shadow-down-01;
    }
    &-wrapper {
      overflow: auto;
      width: 518px;
      height: 100%;
    }
  }
}

.loader {
  height: calc(100vh - 56px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.track-loader-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px - 56px - 80px);
}
.track-loader {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
