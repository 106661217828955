<template>
  <div>
    <div v-if="!isPrimary" @click.stop="copy" :class="btnClass">
      <i class="ri-links-line" />
      <span>&nbsp;</span>
      <span class="label">{{ label }}</span>
    </div>
    <submit-button
      v-if="isPrimary"
      size="medium"
      :label="label"
      :disabled="disabled"
      @submit="copy"
    />
  </div>
</template>

<script>
import SubmitButton from './submitButton';

export default {
  components: {
    SubmitButton
  },
  props: {
    label: {
      type: String,
      required: true
    },
    data: {
      type: String,
      required: false
    },
    isPrimary: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    btnClass() {
      let str = 'copy-button';
      if (this.disabled) str += ' disabled';
      return str;
    }
  },
  methods: {
    copy() {
      if (!this.disabled) {
        this.$emit('copied');
        const dummy = document.createElement('textarea');
        document.body.appendChild(dummy);
        dummy.value = this.data;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.copy-button {
  @include body-1;
  color: $color_primary_100;
  cursor: pointer;
  display: flex;
  &:hover .label {
    text-decoration: underline;
  }
}

.disabled {
  cursor: not-allowed;
}
</style>
