<template>
  <div class="badge" :class="badgeStyle" :style="{ borderRadius: `${radius}px` }">
    {{ label }}
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    badgeStyle: {
      type: String,
      required: false,
      default: 'primary'
    },
    radius: {
      type: Number,
      required: false,
      default: 4
    }
  }
};
</script>

<style lang="scss" scoped>
.badge {
  @include body-2;
  padding: 4px 8px;
  margin: 0 0 0 4px;
  width: fit-content;
  width: -moz-fit-content;
}

.primary {
  background-color: $color_primary_10;
  color: $color_primary_100;
}

.secondary {
  background-color: $color_neutral_10;
  color: $color_neutral_80;
}
</style>
