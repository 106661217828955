
<div :class="withBackground && 'with-background'" class="checklist">
  <div v-for="(item, index) in checklist" :key="`item-${index}`">
    <div class="checklist-item">
      <i :class="item.icon" />
      <span class="checklist-item-label" :class="item.disable && 'disable'">{{
        item.label
      }}</span>
      <badge v-if="item.badge" :label="item.badge" badgeStyle="secondary" />
    </div>
  </div>
</div>
