<template>
  <div v-if="ProjectsInformation" class="projects">
    <div v-if="SearchProjects">
      <router-view
        :projects="formatedProjects"
        :isLoadingProject="isLoadingProject"
        :hasMoreToLoad="hasMoreToLoad"
        :hasSearch="hasSearch"
        @loadMore="loadMore"
        @deleteProjectInCache="deleteProjectInCache"
        @openCreateProjectModal="openCreateProjectModal"
        @openDeleteProjectModal="openDeleteProjectModal"
      ></router-view>
    </div>
    <div v-else class="loader">
      <spinner-without-progress color="grey" :size="32" />
    </div>
  </div>
  <div v-else class="loader">
    <spinner-without-progress color="grey" :size="32" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { PAGINATOR_LIMIT } from '@/utils/constants';
import { PROJECTS_INFORMATION, SEARCH_PROJECTS } from '@/graphql/queries/project';
import { PROJECT_DELETE } from '@/graphql/mutations/project';
import { generateProjectList } from '@/utils/functions/projectlist';
import { bus } from '@/utils/bus';
import { trackEvent } from '@/utils/functions/analytics';
import get from 'lodash.get';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import CreateProjectModal from '@/containers/modals/createProject';
import DeleteProjectModal from '@/containers/modals/deleteProject';

export default {
  data() {
    const filter = 'active';
    return {
      isLoadingProject: undefined,
      page: 0,
      lastPage: undefined,
      filter,
      sort: {
        by: 'updated_at',
        dir: 'desc'
      },
      limit: PAGINATOR_LIMIT
    };
  },
  apollo: {
    ProjectsInformation: {
      query: PROJECTS_INFORMATION,
      variables() {
        return { workspaceId: this.currentWorkspace.id };
      },
      result(res) {
        const tabsCount = {
          active: get(res, 'data.ProjectsInformation.total_active_projects', 0)
        };
        this.$store.commit('changeProjectTabsCount', tabsCount);
      }
    },
    SearchProjects: {
      query: SEARCH_PROJECTS,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          ...this.searchParameters,
          filters: { status: { eq: this.filter } },
          sort: this.sort,
          offset: 0,
          limit: this.limit
        };
      },
      result() {
        if (this.SearchProjects) {
          this.lastPage = Math.floor(this.SearchProjects.total_active_projects / this.limit);
        }
      },
      watchLoading(loading) {
        this.isLoadingProject = loading;
      }
    },
    searchCounter: {
      query: SEARCH_PROJECTS,
      fetchPolicy: 'network-only',
      variables() {
        return {
          ...this.searchParameters,
          filters: { status: { beginsWith: 'a' } },
          sort: this.sort,
          offset: 0,
          limit: this.limit
        };
      },
      result(res) {
        const tabsCount = {
          active: get(res, 'data.SearchProjects.total_active_projects', 0)
        };
        this.$store.commit('changeProjectTabsCount', tabsCount);
      },
      update(data) {
        return { searchCounter: data };
      }
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'searchProjectQueryParameters']),
    hasMoreToLoad() {
      return this.page < this.lastPage;
    },
    formatedProjects() {
      return generateProjectList(this.SearchProjects, this.currentWorkspace);
    },
    searchParameters() {
      if (!this.searchProjectQueryParameters) {
        return { query: { workspaceId: this.currentWorkspace.id } };
      }
      return this.searchProjectQueryParameters;
    },
    hasSearch() {
      return this.searchProjectQueryParameters !== null;
    }
  },
  methods: {
    openDeleteProjectModal({ projectId, projectName, isProjectEmpty }) {
      if (isProjectEmpty) {
        this.handleDeleteProjectMutation(projectId);
        return;
      }
      bus.$emit('displayModal', {
        component: DeleteProjectModal,
        title: this.$t('deleteProjectTitle'),
        props: [{ name: 'projectName', value: projectName }],
        size: 'medium',
        isVisible: true,
        onSubmit: () => {
          this.handleDeleteProjectMutation(projectId);
        }
      });
    },
    openCreateProjectModal() {
      bus.$emit('displayModal', {
        title: this.$t('newProject'),
        size: 'medium',
        isVisible: true,
        component: CreateProjectModal,
        redirectToProject: true,
        props: [{ name: 'redirectToProject', value: true }]
      });
      trackEvent('New Project Click', {
        category: 'project',
        page_view: 'project list'
      });
    },
    handleDeleteProjectMutation(projectId) {
      this.$apollo
        .mutate({
          mutation: PROJECT_DELETE,
          variables: {
            projectIds: [projectId]
          },
          update: cache => {
            this.deleteProjectInCache({ cache, projectId });
          },
          refetchQueries: [
            {
              /* Refresh tabs counter */
              query: PROJECTS_INFORMATION,
              variables: {
                workspaceId: this.currentWorkspace.id
              }
            }
          ]
        })
        .then(() => {
          bus.$emit('showAlert', {
            message: { key: 'deleteProjectSuccess' },
            style: 'success',
            delay: 5000
          });
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    deleteProjectInCache({ cache, projectId }) {
      const variables = {
        query: { workspaceId: this.currentWorkspace.id },
        filters: { status: { eq: this.filter } },
        sort: this.sort,
        offset: 0,
        limit: this.limit
      };
      const previousData = cache.readQuery({
        query: SEARCH_PROJECTS,
        variables
      });
      const newData = { ...previousData };
      const indexToDelete = previousData.SearchProjects.data.findIndex(
        item => item.id === projectId
      );
      newData.SearchProjects.data.splice(indexToDelete, 1);
      cache.writeQuery({
        query: SEARCH_PROJECTS,
        variables,
        data: newData
      });
    },
    async loadMore() {
      this.page += 1;
      try {
        await this.$apollo.queries.SearchProjects.fetchMore({
          variables: {
            offset: this.page * this.limit
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newProjects = fetchMoreResult.SearchProjects.data;
            if (fetchMoreResult.SearchProjects.data.length === 0) {
              return {
                SearchProjects: {
                  ...previousResult.SearchProjects,
                  data: [...previousResult.SearchProjects.data],
                  __typename: 'ProjectsPaginator' // eslint-disable-line
                }
              };
            }
            return {
              SearchProjects: {
                ...previousResult.SearchProjects,
                data: [...previousResult.SearchProjects.data, ...newProjects],
                __typename: 'ProjectsPaginator' // eslint-disable-line
              }
            };
          }
        });
      } catch {} // eslint-disable-line
    },
    setSearchProjectQueryParametersInLocalStorage() {
      localStorage.setItem(
        'SEARCH_PROJECT_QUERY_PARAMETERS',
        JSON.stringify(this.searchProjectQueryParameters)
      );
    }
  },
  created() {
    bus.$on('refetchProjectList', () => {
      this.$apollo.queries.SearchProjects.refetch();
    });
  },
  mounted() {
    bus.$on('deleteProject', projectToDelete => {
      this.handleDeleteProjectMutation(projectToDelete);
    });
  },
  beforeDestroy() {
    bus.$off('refetchProjectList');
    bus.$off('deleteProject');
    this.setSearchProjectQueryParametersInLocalStorage();
  },
  watch: {
    searchProjectQueryParameters() {
      this.setSearchProjectQueryParametersInLocalStorage();
    },
    $route() {
      this.page = 0;
      this.lastPage = undefined;
      this.isLoadingProject = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.projects {
  position: relative;
  margin: 0 0 0 40px;

  &-tabs {
    position: relative;
    margin: 24px 0 40px 0;

    &-actions {
      position: absolute;
      right: 0;
      margin-top: -8px;
      z-index: 1;
    }
  }
}

.loader {
  height: calc(100vh - 56px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
