<template>
  <div class="tag" @mouseleave="setShowAddButton(false)">
    <div class="tag-category" @mouseover="setShowAddButton(true)">
      <div class="tag-category-left" @click="toggleExpand">
        <div
          class="tag-category-left-dot"
          :style="`background-color: ${tagCategory.background_color}`"
        />
        <div class="tag-category-left-label">{{ tagCategory.name }}</div>
        <div class="tag-category-left-count" v-if="count > 0">{{ `(${count})` }}</div>
        <i class="ri-arrow-down-s-fill" v-if="isExpanded && count > 0" />
        <i class="ri-arrow-right-s-fill" v-if="!isExpanded && count > 0" />
      </div>
      <div class="tag-category-right">
        <div v-show="showAddButton">
          <tag-category-dropdown
            :tagCategory="tagCategory"
            :checkedTags="checkedTags"
            withIconButton
            @setIsDropdownExpanded="setIsDropdownExpanded"
            @removeTag="removeTag"
            @addTag="addTag"
          />
        </div>
      </div>
    </div>
    <div class="tag-families" v-if="isExpanded && selectedFamilies.length > 0">
      <div
        class="tag-families-item"
        v-for="family in selectedFamilies"
        :key="`family-${family.id}`"
      >
        <div class="tag-families-item-name">{{ family.name }}</div>
        <div class="tag-families-item-tags">
          <div
            class="tag-families-item-tags-item"
            :style="`background-color: ${tagCategory.text_color}; color: ${tagCategory.background_color};`"
            v-for="tag in family.tags"
            :key="`tag-${tag.id}`"
          >
            <div v-if="aiTags.includes(tag.id)" class="tag-ai">
              <i class="ri-sparkling-2-fill" />
            </div>
            <span>{{ tag.name }}</span>
            <i
              class="ri-close-circle-fill"
              @click="removeTag(`${tagCategory.id}-${family.id}-${tag.id}`)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TagCategoryDropdown from '@/components/dropdown_legacy/tagCategoryDropdown';

export default {
  components: {
    TagCategoryDropdown
  },
  props: {
    tagCategory: {
      type: Object,
      required: false,
      default: null
    },
    checkedTags: {
      type: Array,
      required: false,
      default: () => []
    },
    aiTags: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      isExpanded: false,
      checkedOptions: [...this.checkedTags],
      showAddButton: false,
      isDropdownExpanded: false
    };
  },
  computed: {
    count() {
      return this.selectedFamilies.reduce((acc, obj) => acc + obj.tags.length, 0);
    },
    selectedFamilies() {
      return this.tagCategory.families
        .filter(
          family =>
            family.tags &&
            family.tags.filter(tag =>
              this.checkedOptions.includes(`${this.tagCategory.id}-${family.id}-${tag.id}`)
            ).length > 0
        )
        .map(family => ({
          ...family,
          tags: family.tags.filter(tag =>
            this.checkedOptions.includes(`${this.tagCategory.id}-${family.id}-${tag.id}`)
          )
        }));
    }
  },
  watch: {
    selectedFamilies() {
      this.isExpanded = this.selectedFamilies.length > 0;
    },
    checkedTags() {
      this.checkedOptions = [...this.checkedTags];
    }
  },
  created() {
    this.isExpanded = this.selectedFamilies.length > 0;
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    setIsDropdownExpanded(isExpanded) {
      this.isDropdownExpanded = isExpanded;
    },
    setShowAddButton(isOver) {
      if (isOver) {
        this.showAddButton = true;
      } else if (this.isDropdownExpanded) {
        this.showAddButton = true;
      } else {
        this.showAddButton = false;
      }
    },
    removeTag(tagId, options) {
      if (!options) {
        const index = this.checkedOptions.indexOf(tagId);
        this.checkedOptions.splice(index, 1);
      } else {
        this.checkedOptions = options;
      }
      this.$emit('selectTags', tagId, false);
    },
    addTag(tagId, options) {
      this.checkedOptions = options;
      this.$emit('selectTags', tagId, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.tag {
  &-category {
    display: flex;
    min-height: 32px;

    &-left {
      display: flex;
      align-items: center;
      cursor: pointer;

      &-dot {
        width: 8px;
        height: 8px;
        border-radius: 2px;
      }

      &-label {
        @include subtitle-1;
        margin: 0 0 0 8px;
      }

      &-count {
        @include body-2;
        color: $color_neutral_60;
        margin: 2px 0 0 4px;
      }

      .ri-arrow-right-s-fill {
        color: $color_neutral_40;
        transition: color 100ms linear;
      }

      &:hover {
        .ri-arrow-right-s-fill {
          color: $color_neutral_100;
        }
      }
    }

    &-right {
      position: relative;
      display: flex;
      align-items: center;
      width: 40px;

      &-button {
        margin: 0 0 0 8px;
        width: 280px;
      }

      &-expand {
        position: absolute;
        top: 42px;
        left: 8px;
        width: 280px;
        height: fit-content;
        max-height: 240px;
        background: $color_neutral_0;
        border: 1px solid $color_neutral_30;
        box-sizing: border-box;
        @include shadow-down-03;
        @include body-1;
        overflow-y: auto;
        border-radius: 2px;

        &-option {
          cursor: pointer;
          border-bottom: 1px solid $color_neutral_30;

          &-checkbox {
            padding: 0 0 0 32px;
            height: 40px;
            display: flex;
            align-items: center;

            &:hover {
              background-color: $color_neutral_10;
            }
          }
        }
      }
    }
  }

  &-ai {
    font-size: 12px;
    margin-right: 8px;
  }

  &-families {
    @include body-1;
    color: $color_neutral_60;
    margin: 0 0 8px 16px;

    &-item {
      &-name {
        margin: 0 0 8px 0;
      }

      &-tags {
        display: flex;
        flex-wrap: wrap;

        &-item {
          @include body-1;
          border-radius: 2px;
          display: flex;
          align-items: center;
          padding: 0 8px;
          margin: 0 8px 8px 0;

          span {
            margin: 0 4px 0 0;
            white-space: nowrap;
          }

          .ri-close-circle-fill {
            cursor: pointer;
          }
        }
      }
    }
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: $color_neutral_40;
}
</style>
