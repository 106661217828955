
<div class="empty" data-test="empty-view-container">
  <div class="empty-image-container">
    <img
      :src="setImageUrl(this.placeholderType)"
      alt="placeholder image"
      class="empty-image"
      width="753"
      height="336"
    />
  </div>
  <div class="empty-content">
    <div class="clouded-text-container">
      <clouded-text
        class="cloud-text"
        v-if="cloudedTextContent"
        :content="cloudedTextContent"
        :color="cloudedTextColor"
      />
      <div class="empty-content-title">{{ title }}</div>
    </div>
    <div v-if="description" class="empty-content-description">{{ description }}</div>
    <slot name="button"></slot>
    <div v-if="helper" class="empty-content-helper">{{ helper }}</div>
  </div>
</div>
