<template>
  <div class="toggle-ai-modal">
    <div class="toggle-ai-modal-body">
      <div class="toggle-ai-modal-body-description">
        {{ body }}
      </div>
      <div class="toggle-ai-modal-body-description">Do you want to continue ?</div>
    </div>
    <div class="toggle-ai-modal-buttons">
      <div class="toggle-ai-modal-buttons--">
        <submit-button
          btnStyle="secondary"
          size="medium"
          :label="$t('cancel')"
          @submit="submit(false)"
        />
      </div>
      <div class="toggle-ai-modal-buttons--">
        <submit-button
          btnStyle="primary"
          size="medium"
          :label="$t('confirm')"
          @submit="submit(true)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onSubmit: {
      type: Function,
      required: false,
      default: () => {}
    },
    body: {
      type: String,
      required: true
    }
  },
  created() {
    window.addEventListener('keypress', this.handleKeyPress);
  },
  destroyed() {
    window.removeEventListener('keypress', this.handleKeyPress);
  },
  methods: {
    handleKeyPress(event) {
      if (event.keyCode === 13) {
        this.submit();
      }
    },
    closeModal() {
      this.$emit('closeModal');
    },
    submit(bool) {
      this.onSubmit(bool);
      this.closeModal();
    },
    triggerOnClose() {
      this.onSubmit(false);
    }
  }
};
</script>

<style lang="scss">
.toggle-ai-modal {
  &-body {
    font-size: 14px;
    padding: 24px 40px 0 24px;
    line-height: 150%;

    &-description {
      margin: 0 0 32px 0;
    }

    &-txtfield {
      margin: 0 0 40px 0;
    }
  }
  &-buttons {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $color_neutral_30;
    padding: 8px 32px;

    &-- {
      &:first-child {
        margin: 0 16px 0 0;
      }
    }
  }
}
</style>
