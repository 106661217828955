<template>
  <div class="hscroller">
    <div v-if="showGradient && withObserver" class="hscroller-gradient" />
    <div ref="thumbs" class="hscroller-content">
      <div
        class="hscroller-content-thumbnails"
        v-for="(thumbnail, index) in thumbnails"
        :key="thumbnail.id"
      >
        <div
          :id="`thumb${index}`"
          class="hscroller-content-thumbnails-thumb"
          @click="$emit('handleClickThumbnail', thumbnail.id)"
        >
          <div class="hscroller-content-thumbnails-thumb-cover">
            <img :src="thumbnail.imgSrc" :alt="thumbnail.title" />
          </div>
          <div class="hscroller-content-thumbnails-thumb-txt">
            <div class="hscroller-content-thumbnails-thumb-txt-title">{{ thumbnail.title }}</div>
            <div class="hscroller-content-thumbnails-thumb-txt-subtitle">
              {{ thumbnail.subtitle }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="withObserver" class="hscroller-content-thumbnails">
        <observer @intersect="handleScrollEnd" />
      </div>
    </div>
  </div>
</template>

<script>
import Observer from '@/containers/observer';

export default {
  components: {
    Observer
  },
  props: {
    thumbnails: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showGradient: false,
      withObserver: false
    };
  },
  mounted() {
    this.setSliderOptions();
    window.addEventListener('resize', this.setSliderOptions);
  },
  methods: {
    setSliderOptions() {
      const container = this.$refs.thumbs;
      const lastElement = document.getElementById(`thumb${this.thumbnails.length - 1}`);
      this.withObserver =
        container &&
        lastElement &&
        container.clientWidth < lastElement.offsetLeft + lastElement.clientWidth;
    },
    handleScrollEnd(isInViewport) {
      this.showGradient = !isInViewport;
    }
  }
};
</script>

<style lang="scss" scoped>
.hscroller {
  position: relative;

  &-gradient {
    position: absolute;
    right: 0;
    background: $ui-gradient-page-background;
    width: 136px;
    height: 64px;
  }

  &-content {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    font-weight: 400;

    &-thumbnails {
      display: flex;
      flex: 0 0 152px;
      cursor: pointer;
      margin: 0 24px 0 0;

      &:last-child {
        margin: 0;
        flex: 0 0 1px;
      }

      &-thumb {
        display: flex;

        &-cover {
          margin: 0 8px 0 0;

          img {
            width: 64px;
            height: 64px;
          }
        }

        &-txt {
          font-size: 12px;
          line-height: 15px;

          &-title {
            @include subtitle-1;
            font-size: 12px;
            color: $color_neutral_100;
            margin: 0 0 4px 0;
            display: block;
            display: -webkit-box;
            max-width: 104px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 16px;
          }

          &-subtitle {
            @include body-2;
            color: $color_neutral_60;
          }
        }
      }
    }
  }
}

::-webkit-scrollbar {
  display: none;
}
</style>
