import gql from 'graphql-tag';

// eslint-disable-next-line
export const STRIPE_COUPON = gql`
  query PaymentStripeCoupon($couponId: String!, $planVariantId: ID!) {
    PaymentStripeCoupon(couponId: $couponId, planVariantId: $planVariantId) {
      currency
      amount_off
      percent_off
      is_valid
      is_applicable
    }
  }
`;

export const STRIPE_CREATE_SETUP_INTENT = gql`
  query StripeCreateSetupIntent($workspaceId: ID!) {
    StripeCreateSetupIntent(workspaceId: $workspaceId) {
      secret
    }
  }
`;

export const STRIPE_CUSTOMER_CARD = gql`
  query StripeCustomerCards($workspaceId: ID!) {
    StripeCustomerCards(workspaceId: $workspaceId) {
      external_id
      brand
      country
      exp_month
      exp_year
      last4
    }
  }
`;

export const STRIPE_CUSTOMER = gql`
  query PaymentStripeCustomer($workspaceId: ID!) {
    PaymentStripeCustomer(workspaceId: $workspaceId) {
      billing_information {
        email
        company
        address {
          line1
          line2
          city
          postal_code
          state
          country
        }
        vat {
          value
        }
      }
    }
  }
`;

export const STRIPE_CALCULATE_SUBSCRIPTION_PRICE = gql`
  query StripeCalculateSubscriptionPrice(
    $workspaceId: ID!
    $planVariantId: ID!
    $seats: Int!
    $subscriptionId: ID!
    $billingPeriod: BillingPeriod
  ) {
    StripeCalculateSubscriptionPrice(
      workspaceId: $workspaceId
      planVariantId: $planVariantId
      seats: $seats
      subscriptionId: $subscriptionId
      billingPeriod: $billingPeriod
    ) {
      plan_variant_id
      currency
      billing_period
      members
      new_amount
      due_today_amount
    }
  }
`;
