<template>
  <div class="image-cropper">
    <div
      class="image-cropper-container"
      :style="`width: ${containerWidth}px; height: ${containerHeight}px; max-width: ${containerMaxWidth}px; max-height: ${containerMaxHeight}px;`"
    >
      <div class="image-cropper-container-image" :class="{ square: isSquare }">
        <img ref="image" :src="null" />
      </div>
    </div>
    <div class="image-cropper-name" :style="`width: ${containerWidth}px;`">{{ fileName }}</div>
    <div class="image-cropper-buttons">
      <div class="image-cropper-buttons-btn">
        <submit-button btnStyle="tertiary" :label="cancelButtonLabel" @submit="cancel" />
      </div>
      <div class="image-cropper-buttons-btn">
        <submit-button :label="submitButtonLabel" @submit="cropImage" />
      </div>
    </div>
  </div>
</template>

<script>
import Cropper from 'cropperjs';

import SubmitButton from '@/components/buttons/submitButton';

import '@/assets/styles/cropper.scss';

export default {
  components: {
    SubmitButton
  },
  props: {
    blob: {
      type: String,
      required: false,
      default: null
    },
    submitButtonLabel: {
      type: String,
      required: false,
      default: 'Save'
    },
    cancelButtonLabel: {
      type: String,
      required: false,
      default: 'Cancel'
    },
    fileName: {
      type: String,
      required: false
    },
    maxWidth: {
      type: Number,
      required: false
    },
    maxHeight: {
      type: Number,
      required: false
    },
    imageWidth: {
      type: Number,
      required: true,
      default: 100
    },
    imageHeight: {
      type: Number,
      required: true,
      default: 100
    },
    isSquare: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      containerWidth: 500,
      containerHeight: 280,
      containerMaxWidth: this.maxWidth || this.containerWidth,
      containerMaxHeight: this.maxHeight || this.containerHeight
    };
  },
  watch: {
    blob() {
      if (this.blob) {
        this.cropper.replace(this.blob);
      }
    }
  },
  mounted() {
    const that = this;
    this.cropper = new Cropper(this.$refs.image, {
      aspectRatio: 1,
      viewMode: 1,
      background: false,
      checkCrossOrigin: false,
      checkOrientation: false,
      ready() {
        that.containerWidth = this.cropper.getImageData().width;
        that.containerHeight = this.cropper.getImageData().height;
        this.cropper.setCanvasData({ top: 0 });
        this.cropper.setCropBoxData({
          top: 0,
          left: 0,
          width: that.containerWidth,
          height: that.containerHeight
        });
      }
    });
    if (this.blob) {
      this.cropper.replace(this.blob);
    }
  },
  methods: {
    cropImage() {
      function getCanvasBlob(canvas, width, height) {
        return new Promise(resolve => {
          // We first add the white background
          const ctx = canvas.getContext('2d');
          ctx.fillStyle = '#FFF';
          ctx.globalCompositeOperation = 'destination-over';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          // We then resize the image if needed
          if (canvas.width > width || canvas.height > height) {
            // We MUST create a new canvas for the resize to work
            const newCanvas = document.createElement('canvas');
            newCanvas.width = width;
            newCanvas.height = height;
            newCanvas.getContext('2d').drawImage(canvas, 0, 0, width, height);
            newCanvas.toBlob(blob => {
              resolve(blob);
            }, 'image/jpeg');
          } else {
            canvas.toBlob(blob => {
              resolve(blob);
            }, 'image/jpeg');
          }
        });
      }

      const canvas = this.cropper.getCroppedCanvas();
      const canvasBlob = getCanvasBlob(canvas, this.imageWidth, this.imageHeight);
      canvasBlob.then(
        blob => {
          if (canvas) {
            this.$emit('cropImage', blob, canvas.toDataURL());
          }
        },
        err => {
          // eslint-disable-next-line no-console
          console.log(err);
        }
      );
    },
    cancel() {
      this.$emit('cropImage', null);
    }
  }
};
</script>

<style lang="scss" scoped>
.image-cropper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &-container {
    overflow: hidden;
    display: flex;
    justify-content: center;

    &-image {
      max-width: 500px;
      max-height: 280px;
    }
  }

  &-name {
    @include body-2;
    margin: 8px 0 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 16px;
    text-align: center;
  }

  &-buttons {
    display: flex;
    align-items: center;
    margin: 40px 0 0 0;

    &-btn {
      margin: 0 10px;
    }
  }
}
</style>

<style lang="scss">
.square {
  .cropper-view-box {
    border-radius: 0;
  }
}
</style>
