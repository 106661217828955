import gql from 'graphql-tag';

export const HUB_ADD_TRACK = gql`
  mutation HubAddTrack($hubId: ID!, $trackIds: [ID]!) {
    HubAddTrack(hubId: $hubId, trackIds: $trackIds) {
      id
      track_id
      hub_id
    }
  }
`;

export const HUB_REMOVE_TRACK = gql`
  mutation HubRemoveTrack($hubId: ID!, $trackIds: [ID]!) {
    HubRemoveTrack(hubId: $hubId, trackIds: $trackIds)
  }
`;

export const HUB_SET_WORKSPACE_COMMENT = gql`
  mutation HubSetWorkspaceComment($hubId: ID!, $workspaceId: ID!, $comment: String!) {
    HubSetWorkspaceComment(hubId: $hubId, workspaceId: $workspaceId, comment: $comment) {
      workspace_id
      hub_id
      comment
      __typename
    }
  }
`;
