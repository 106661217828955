
<div :class="['attachment-name-container', isFullWidth && 'attachment-name-container-fullwidth']">
  <div
    v-if="displayRename"
    :class="['attachment-name-rename', isFullWidth && 'attachment-name-rename-fullwidth']"
  >
    <text-field
      inputType="text"
      :large="false"
      :value="attachmentName"
      :fieldModel.sync="attachmentName"
      selectOnLoad
      @click.native.stop
      @handleBlur="handleRenameBlur"
      @keydown.native="handleRenameEnter"
    />
  </div>
  <div v-else :class="['attachment-name', isFullWidth && 'attachment-name-fullwidth']">
    <div class="attachment-name--" :class="{ hover: isHover, fullwidth: isFullWidth }">
      <span
        :class="['ellipsis', isFullWidth && 'ellipsis-fullwidth']"
        :title="`${attachmentName}.${extension}`"
        >{{ attachmentName }}</span
      >
      <span v-if="extension">.{{ extension }}</span>
    </div>
    <div
      v-if="isHover"
      :class="['attachment-name-actions', isFullWidth && 'attachment-name-actions-fullwidth']"
    >
      <more-dropdown
        :items="actions"
        size="small"
        :itemId="attachmentId"
        @click.native.stop
        @setMoreDropdownState="setMoreDropdownState"
      />
    </div>
  </div>
  <div v-if="isFullWidth" class="attachment-type-fullwidth">
    {{ formattedType }}
  </div>
  <div v-if="isFullWidth" class="attachment-size">{{ size }}</div>
  <div v-else-if="!displayRename" class="attachment-type" :title="{ formattedType }">
    {{ formattedType }} - {{ size }}
  </div>
</div>
