<template>
  <div>
    <action-bar
      :numberOfSelection="headerCountLabel"
      :actions="actionBarBtns"
      :label="actionBarLabels"
      @selectAll="dataBus.$emit('selectAllTracks', true)"
      @unselect="dataBus.$emit('selectAllTracks', false)"
    />
    <div
      v-if="displayShareDropdown"
      class="shareDropdown"
      v-click-outside="{ hide: hideShareDropdown }"
    >
      <div class="menu">
        <div
          :class="[
            option.separator ? 'separator' : 'menu-item',
            option.subText ? 'menu-item-with-sub' : ''
          ]"
          v-for="(option, index) in shareBtnOptions"
          :key="index"
          @click="handleShareBtnClick(option)"
        >
          <div class="item" v-if="!option.separator">
            <i :class="option.icon"></i>
            <span class="menu-item-label">
              {{ option.label }}
            </span>
          </div>
          <div v-if="option.subText" class="menu-item-sub">{{ option.subText }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { bus } from '@/utils/bus';
import { formatNumber } from '@/utils/functions/global';

import ShareModal from '@/containers/modals/share';
import MoveToAddTo from '@/containers/modals/moveToAddTo';

import { clickOutside } from '@/utils/directives';
import { trackEvent } from '@/utils/functions/analytics';

export default {
  directives: {
    clickOutside
  },
  props: {
    trackList: {
      type: Array,
      required: false,
      default: () => []
    },
    trackListFromSearch: {
      type: Array,
      required: false,
      default: () => []
    },
    selectedTracks: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      dataBus: bus,
      displayShareDropdown: false,
      actionBarLabels: {
        selectAll: this.$t('selectAll'),
        unselect: this.$t('unselect')
      },
      shareBtnOptions: [
        {
          label: this.$t('getQuickLink'),
          subText: this.$t('getQuickLinkSub'),
          icon: 'ri-flashlight-fill',
          action: 'openShareModal',
          params: {
            defaultTab: 0
          }
        },
        {
          separator: true
        },
        {
          label: this.$t('createIndividualLinks'),
          subText: this.$t('createIndividualLinksSub'),
          icon: 'ri-links-fill',
          action: 'openShareModal',
          params: {
            defaultTab: 1
          }
        },
        {
          separator: true
        },
        {
          label: this.$t('sendByEmail'),
          subText: this.$t('sendByEmailSub'),
          icon: 'ri-mail-line',
          action: 'openShareModal',
          params: {
            defaultTab: 2
          }
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['me', 'currentWorkspace', 'isEditPanelOpened']),
    headerCountLabel() {
      const formatedNumber = formatNumber(this.selectedTracks.length);
      return `${formatedNumber} ${this.$tc('trackSelected', this.selectedTracks.length)}`;
    },
    actionBarBtns() {
      return [
        {
          icon: 'ri-share-forward-fill',
          tooltip:
            this.selectedTracks && this.selectedTracks.length > 50
              ? this.$t('shareMoreThan50')
              : this.$t('share'),
          disabled: this.selectedTracks && this.selectedTracks.length > 50,
          method: () => {
            if (this.displayShareDropdown) {
              this.displayShareDropdown = false;
              return;
            }
            setTimeout(() => {
              this.displayShareDropdown = true;
            }, 1);
          }
        },
        {
          icon: 'ri-information-line',
          tooltip: this.$t('editInformation'),
          method: () => {
            this.$emit('toggleEditModeFromActionBar');
          }
        },
        {
          icon: 'ri-folder-transfer-line',
          tooltip: this.$t('addTo'),
          method: () => {
            if (this.$isWorkspaceLockedVerification(this)) return;
            const fromSharedView = this.$route.path.includes('shared');
            let page_view;
            switch (this.$route.name) {
              case 'albums':
              case 'albumDetails':
                page_view = fromSharedView ? 'shared library album' : 'library album';
                break;
              case 'artists':
              case 'artistAlbums':
              case 'artistTracks':
                page_view = fromSharedView ? 'shared library artist' : 'library artist';
                break;
              case 'trackList':
              default:
                page_view = 'library track list';
                break;
            }
            trackEvent('Track AddTo Click', {
              category: 'addto',
              page_view,
              action_bar: true,
              upload_manager: false
            });
            bus.$emit('displayModal', {
              component: MoveToAddTo,
              hideHeader: true,
              isVisible: true,
              size: 'small',
              onSubmit: this.closeShareModal,
              props: [
                { name: 'filesIds', value: this.selectedTracks },
                { name: 'hasPrevious', value: this.me.workspaces.length > 1 }
              ]
            });
          }
        },
        {
          icon: 'ri-download-line',
          tooltip: this.$t('download'),
          method: () => {
            bus.$emit('downloadTrack', this.selectedTracks);
          }
        },
        {
          icon: 'ri-delete-bin-line',
          tooltip: this.$t('delete'),
          method: () => {
            this.$emit('deleteFromActionBar');
          }
        }
      ];
    }
  },
  methods: {
    handleShareBtnClick(options) {
      const { defaultTab } = options.params;
      const param = this.selectedTracks;
      const tracksIds = param;
      let trackName;
      if (this.trackListFromSearch.length > 0) {
        trackName = this.trackListFromSearch.find(track => track.id === tracksIds[0]).title;
      } else {
        trackName = this.trackList.find(track => track.id === tracksIds[0]).title;
      }
      const modalTitle =
        trackName && tracksIds.length > 1
          ? this.$t('shareTracksName', { name: trackName, count: tracksIds.length - 1 })
          : this.$t('shareTrackName', { name: trackName });

      const entityName =
        trackName && tracksIds.length > 1
          ? this.$t('TracksName', { name: trackName, count: tracksIds.length - 1 })
          : trackName;
      bus.$emit('displayModal', {
        component: ShareModal,
        isVisible: true,
        size: 'medium',
        onSubmit: this.closeShareModal,
        title: modalTitle,
        props: [
          { name: 'entityName', value: entityName },
          { name: 'entityIds', value: tracksIds },
          { name: 'entityType', value: 'track' },
          { name: 'selectedTracksLength', value: param.length },
          defaultTab ? { name: 'defaultTab', value: defaultTab } : []
        ].filter(Boolean)
      });
    },
    hideShareDropdown() {
      this.displayShareDropdown = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.action-bar-container {
  position: fixed;
  bottom: 72px;
  left: calc(50% + 90px);
  transform: translateX(-50%);
  z-index: map-get($z-index, action-bar);
}
.action-bar-container.panel-open {
  left: calc(50% - 169px);
}
.projectPicker {
  position: relative;
  top: -386px;
  left: -22px;
}
.shareDropdown {
  position: absolute;
  bottom: 70px;
  left: 162px;
}
.menu {
  margin-top: 2px;
  padding: 8px;
  height: auto;
  z-index: 1000;
  white-space: nowrap;
  background-color: $color_neutral_0;
  box-sizing: border-box;
  border: 1px solid $color_neutral_30;
  border-radius: 8px;
  overflow: hidden;
  @include shadow-down-03;
}

.menu-item {
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 32px;
  box-sizing: border-box;
  @include body-1;
  &-label {
    display: flex;
    align-items: center;
    &-new {
      margin-left: 4px;
    }
  }
  &-order {
    color: $color_neutral_60;
    font-size: 12px;
  }
  &-sub {
    font-size: 12px;
    color: $color_neutral_60;
  }
  &-with-sub {
    flex-direction: column;
    justify-content: center;
    align-items: unset;
    height: fit-content;
  }
  .item {
    display: flex;
    align-items: center;
    line-height: 100%;
    i {
      margin-right: 8px;
    }
  }
}
.menu-item:hover {
  background-color: $color_neutral_10;
}
.separator {
  height: 1px;
  background-color: $color_neutral_30;
  width: calc(100% + 16px);
  transform: translateX(-8px);
  margin: 4px 0;
}
</style>
