import gql from 'graphql-tag';
import { meFragment } from '../fragments';

export const UPDATE_USER = gql`
  mutation updateUser($firstname: String, $lastname: String, $username: String) {
    updateUser(firstname: $firstname, lastname: $lastname, username: $username) {
      ...me
    }
  }
  ${meFragment.me}
`;

export const USER_INITIALIZE_PROFILE_PICTURE_UPLOAD = gql`
  mutation UserInitializeProfilePictureUpload(
    $file_name: String!
    $size: String!
    $mime_type: String!
  ) {
    UserInitializeProfilePictureUpload(file_name: $file_name, size: $size, mime_type: $mime_type) {
      upload_id
      url
    }
  }
`;

export const USER_PROFILE_PICTURE_UPDATE = gql`
  mutation UserProfilePictureUpdate($upload_id: ID!) {
    UserProfilePictureUpdate(upload_id: $upload_id) {
      id
      profile_picture {
        id
        url
      }
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation updateUserPassword($currentPassword: String!, $newPassword: String!) {
    updateUserPassword(currentPassword: $currentPassword, newPassword: $newPassword)
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken {
    refreshToken {
      token
      expires_in
      expires
    }
  }
`;

export const USER_EMAIL_ADD = gql`
  mutation userEmailAdd($email: String!) {
    UserEmailAdd(email: $email) {
      id
      email
    }
  }
`;

export const USER_EMAIL_DELETE = gql`
  mutation userEmailDelete($id: ID!) {
    UserEmailDelete(id: $id)
  }
`;

export const USER_EMAIL_CONFIRMATION = gql`
  mutation userEmailConfirmation($token: ID!) {
    UserEmailConfirmation(token: $token)
  }
`;

export const USER_EMAIL_RESEND_CONFIRMATION = gql`
  mutation userEmailResendConfirmation($id: ID!) {
    UserEmailResendConfirmationEmail(id: $id)
  }
`;

export const USER_EMAIL_CHANGE = gql`
  mutation UserEmailChange($id: ID!, $email: String!, $password: String!) {
    UserEmailChange(id: $id, email: $email, password: $password) {
      id
      email
      is_primary
      is_verified
    }
  }
`;

export const SEND_RESET_PASSWORD_LINK = gql`
  mutation sendResetPasswordLink($email: String!) {
    sendResetPasswordLink(email: $email)
  }
`;
