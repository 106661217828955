
<div v-if="simple" class="simple">
  <div class="user">
    <avatar
      class="user-avatar"
      :pictureUrl="user.url"
      :initial="user.initial"
      :pictureSize="user.size ? user.size : 'ultraSmall'"
      v-tooltip="user.name"
    />
  </div>
  <div class="date" v-tooltip="date.date">
    {{ date.relative }}
  </div>
</div>
<div v-else class="container" v-tooltip="date.date">
  <div v-if="!date.displayDate" class="date">
    {{ date.relative }}
  </div>
  <div v-else class="date">
    {{ date.date }}
  </div>
  <div v-if="!user.url && !user.initial && !user.name" class="user">-</div>
  <div v-else class="user">
    <avatar
      class="user-avatar"
      :pictureUrl="user.url"
      :initial="user.initial"
      pictureSize="smallest"
    />
    <div class="user-name">
      {{ user.name }}
    </div>
  </div>
</div>
