
<div class="projects-modal-body" @click.stop>
  <div class="projects-modal-body-txtfield">
    <text-field
      inputType="text"
      :errorMessage="projectNameMessageValidation"
      :fieldModel.sync="$v.projectName.$model"
      :label="$t('projectName')"
      focusOnLoad
      @keyup.enter.native="handleKeyUp"
    />
  </div>
  <div class="projects-modal-body-txtfield">
    <text-area
      :label="$t('addDescription')"
      :errorMessage="projectDescriptionMessageValidation"
      :fieldModel.sync="$v.projectDescription.$model"
    />
  </div>
  <div class="projects-modal-body-buttons">
    <div class="projects-modal-body-buttons--">
      <submit-button
        btnStyle="tertiary"
        size="medium"
        :label="$t('cancel')"
        @submit="closeCreateProjectModal"
      />
    </div>
    <div class="projects-modal-body-buttons--">
      <submit-button
        btnStyle="primary"
        size="medium"
        :label="$t('create')"
        :disabled="disabled"
        :pending="pending === 'PENDING'"
        @submit="createProject"
      />
    </div>
  </div>
</div>
