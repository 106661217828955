<template>
  <div class="track-info" :style="`min-width: ${minWidth}px; max-width: ${maxWidth}px;`">
    <div class="track-info-title ellipsis">{{ title }}</div>
    <div class="track-info-subtitle ellipsis">{{ subtitle }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    minWidth: {
      type: Number,
      required: false,
      default: 150
    },
    maxWidth: {
      type: Number,
      required: false,
      default: 300
    }
  }
};
</script>

<style lang="scss" scoped>
.track-info {
  user-select: none;

  &-title {
    @include body-1;
    line-height: 16px;
  }

  &-subtitle {
    @include body-2;
    line-height: 15px;
    color: $color_neutral_60;
  }
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
