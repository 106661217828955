
<dropdown ref="emailPicker">
  <template #button="slotProps">
    <div
      :class="{
        'emailPicker-input': true,
        'emailPicker-input-active': slotProps.isExpanded
      }"
    >
      <span
        >{{ emailReplyTo.email }}
        <badge label="Primary" v-if="emailReplyTo.is_primary" />
      </span>
      <i
        :class="{
          'ri-arrow-drop-up-fill': slotProps.isExpanded,
          'ri-arrow-drop-down-fill': !slotProps.isExpanded
        }"
      />
    </div>
  </template>
  <div class="emailPicker-dropdown">
    <div class="emailPicker-dropdownItem" @click="setEmailReplyTo(undefined)">
      <div class="emailPicker-dropdownItem-content">
        {{ getDefaultNoReplyEmail }}
      </div>
      <i v-if="emailReplyTo.email === 'no-reply@bridge.audio'" class="ri-check-line"></i>
    </div>
    <div
      v-for="item in UserEmailsSorted"
      :key="item.id"
      :class="{
        'emailPicker-dropdownItem': true,
        'emailPicker-dropdownItem-notVerified': !item.is_verified
      }"
      @click="setEmailReplyTo(item)"
    >
      <div
        class="emailPicker-dropdownItem-content"
        :class="{ 'emailPicker-dropdownItem-large': !item.is_verified }"
      >
        <div>
          {{ item.email }}
        </div>
        <div v-if="!item.is_verified">
          ({{ $t('pending') }}&nbsp;-&nbsp;
          <span class="emailPicker-resend" @click="resendConfirmationEmail(item.id)">{{
            $t('resendVerificationEmail')
          }}</span
          >)
        </div>
        <badge label="Primary" v-if="item.is_primary" />
      </div>
      <i v-if="emailReplyTo.email === item.email" class="ri-check-line"></i>
    </div>
    <div class="emailPicker-action" @click="goToSettings" v-if="UserEmails.length <= 5">
      <i class="ri-mail-add-line" />
      <span>{{ $t('addManageEmailAddress') }}</span>
    </div>
  </div>
</dropdown>
