
<div>
  <div class="content">
    <h4 class="header">{{ $t('billingDetails.address') }}</h4>
    <div v-if="!address">
      <spinner-without-progress color="grey" :size="32" />
    </div>
    <div v-else>
      <div v-if="hasCompleteAddress()">
        <div class="value">
          <p>{{ address.line1 }}</p>
          <p>{{ address.line2 }}</p>
          <p>{{ address.postal_code }} {{ address.city }}</p>
          <p v-if="address.state">{{ address.state }}</p>
          <p>{{ address.country }}</p>
        </div>
        <h4 class="header header-company">{{ $t('billingDetails.company') }}</h4>
        <div class="value">{{ company }}</div>
        <div v-if="vat">
          <h4 class="header header-company">{{ $t('billingDetails.tax') }}</h4>
          <div class="value">{{ vat.value }}</div>
        </div>
      </div>
      <div v-else>
        <p>No address saved.</p>
      </div>
      <submit-button
        class="button"
        btnStyle="tertiary"
        :exception="true"
        size="medium"
        :label="$t('billingDetails.changeAddressAndCompany')"
        @submit="changePaymentMethod"
      />
    </div>
  </div>
</div>
