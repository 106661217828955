<template>
  <div class="access-rights">
    <div @click="show = !show" class="permissions-title">
      <i class="ri-user-settings-fill"></i>
      <div class="title">{{ $t('permissions') }}</div>
      <i v-if="show" class="ri-arrow-drop-down-line"></i>
      <i v-else class="ri-arrow-drop-right-line"></i>
    </div>
    <div v-show="show" class="access-rights-section">
      <div
        class="access-rights-section-toggle"
        v-for="(right, index) in rightsList"
        @click="toggleSwitchButton(null, `${type}-${right.name}`)"
        :key="`right-${index}`"
      >
        <div>{{ $t(right.label) }}</div>
        <switch-button
          :key="`switch-${index}`"
          :defaultValue="accessRights.includes(right.name)"
          :id="`${type}-${right.name}`"
          @click.native.stop
          @toggleSwitchButton="toggleSwitchButton"
        />
      </div>
    </div>
  </div>
</template>

<script>
import clonedeep from 'lodash.clonedeep';

export default {
  props: {
    accessRights: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'checkbox'
    },
    isProject: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      show: true,
      rightsList: [
        { name: 'stream_mp3', label: 'streaming' },
        { name: 'tracks_metadata', label: 'displayTrackInfo' },
        { name: 'download_as_mp3', label: 'downloadAsMp3' },
        { name: 'download_as_source_audio', label: 'downloadHighQuality' },
        ...(this.isProject
          ? [
              { name: 'show_project_description', label: 'viewProjectDescription' },
              { name: 'download_and_display_attachments', label: 'viewMedia' }
            ]
          : [])
      ]
    };
  },
  methods: {
    toggleSwitchButton(isOn, value) {
      const id = value.split(`${this.type}-`)[1];
      const accessRights = clonedeep(this.accessRights);
      if (this.accessRights.includes(id)) {
        accessRights.splice(accessRights.indexOf(id), 1);
      } else {
        accessRights.push(id);
      }
      this.$emit('setAccessRights', accessRights);
    }
  }
};
</script>

<style lang="scss" scoped>
.access-rights {
  &-section {
    width: 100%;
    &-checkbox {
      padding: 0 0 16px 0;
    }
    &-toggle {
      display: flex;
      width: 100%;
      justify-content: space-between;
      @include body-1;
      padding: 8px 0;
      border-bottom: 1px solid $color_neutral_30;
      cursor: pointer;
    }
  }
}
.permissions-title {
  cursor: pointer;
  .title {
    margin-right: 8px;
  }
  &:hover {
    .title {
      text-decoration: underline;
    }
  }
  padding: 8px 0;
  display: flex;
  @include subtitle-1;
  i {
    font-weight: normal;
    margin-right: 4px;
  }
}
.ri-arrow-drop-down-line,
.ri-arrow-drop-right-line {
  font-size: 20px;
}
</style>
