<template>
  <div>
    <div class="modal-text">
      We are preparing your file,<br />
      please try again in few moments
    </div>
    <div class="modal-buttons">
      <div class="modal-buttons--">
        <submit-button btnStyle="primary" size="medium" :label="$t('close')" @submit="closeModal" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onCancel: {
      type: Function,
      required: false,
      default: () => {}
    }
  },
  methods: {
    closeModal() {
      this.onCancel();
      this.$emit('closeModal');
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-text {
  padding: 24px 32px 24px 32px;
  @include body-1;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid $color_neutral_30;
  padding: 8px 32px;

  &-- {
    &:first-child {
      margin: 0 16px 0 0;
    }
  }
}
</style>
