<template>
  <div class="avatar" :class="pictureSize">
    <div class="avatar-hover">
      <div v-if="withBrowsePicture" :class="hoverStyle">
        <i class="ri-camera-fill avatar-hover-icon" :class="pictureSize" />
      </div>
    </div>
    <div v-if="pictureUrl" :class="classHover" data-test="avatarImage">
      <img class="avatar-img" :alt="`${initial}'s avatar`" :class="avatarStyle" :src="pictureUrl" />
    </div>
    <div v-if="!pictureUrl" class="avatar-initial" :class="initialStyle" data-test="avatarInitial">
      {{ initial }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initial: {
      type: String,
      required: true
    },
    pictureUrl: {
      type: String,
      required: false,
      default: ''
    },
    pictureSize: {
      type: String,
      required: false,
      default: 'small'
    },
    square: {
      type: Boolean,
      required: false,
      default: false
    },
    hasHover: {
      type: Boolean,
      required: false,
      default: false
    },
    isCurrent: {
      type: Boolean,
      required: false,
      default: false
    },
    withBrowsePicture: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    hoverStyle() {
      let str = `avatar-hover-bkg ${this.pictureSize}`;
      if (this.square) str += ' square';
      return str;
    },
    avatarStyle() {
      const square = this.square ? 'square' : '';
      return [this.pictureSize, square];
    },
    classHover() {
      const isCurrent = this.isCurrent ? 'is-current' : '';
      const hasHover = this.hasHover && !this.isCurrent ? 'has-hover' : '';
      const square = this.square ? 'square' : '';
      const bkg = this.pictureUrl ? '' : 'background';
      return [isCurrent, hasHover, this.pictureSize, square, bkg];
    },
    initialStyle() {
      const square = this.square ? 'square' : '';
      const isCurrent = this.isCurrent ? 'is-current' : '';
      const hasHover = this.hasHover && !this.isCurrent ? 'has-hover' : '';
      return [this.pictureSize, square, hasHover, isCurrent];
    }
  }
};
</script>

<style lang="scss" scoped>
.avatar {
  &-hover {
    position: relative;
    cursor: pointer;

    &-bkg {
      position: absolute;
      border-radius: 50%;
      background: $ui-overlay-gradient-dark;
      opacity: 0;
      transition: all 200ms linear;

      &:hover {
        opacity: 1;
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color_neutral_0;
    }
  }

  &-img {
    border-radius: 50%;
    border: 1px solid $color_neutral_30;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    object-fit: cover;
  }

  &-initial {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: $color_primary_100;
    color: $color_neutral_0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
  }
}
.smallest {
  width: 16px;
  height: 16px;
  font-size: 6px;
}
.ultraSmall {
  width: 20px;
  height: 20px;
  font-size: 8px;
}
.workspaceSmall {
  width: 24px;
  height: 24px;
  font-size: 10px;
}
.extraSmall {
  width: 32px;
  height: 32px;
  font-size: 12px;
}
.small {
  width: 40px;
  height: 40px;
  font-size: 12px;
}
.medium {
  width: 64px;
  height: 64px;
  font-size: 16px;
}
.large {
  width: 104px;
  height: 104px;
  font-size: 24px;
}
.extraLarge {
  width: 140px;
  height: 140px;
  font-size: 48px;
}
.square {
  border-radius: 2px;
}
.background {
  background-color: $color_primary_100;
}

.has-hover {
  position: relative;
  &:hover {
    &:after {
      border-radius: 50%;
      border: 2px solid $color_neutral_30;
      position: absolute;
      content: '';
      top: -3px;
      left: -3px;
      bottom: -3px;
      right: -3px;
    }
  }
}

.is-current {
  position: relative;
  &:after {
    border-radius: 50%;
    border: 2px solid $color_primary_100;
    position: absolute;
    content: '';
    top: -3px;
    left: -3px;
    bottom: -3px;
    right: -3px;
  }
}
.is-current.square,
.has-hover.square {
  &:after {
    border-radius: 4px;
  }
}
</style>
