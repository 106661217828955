<template>
  <div class="offersContainer">
    <icon-button
      class="navigationIcon"
      btnStyle="tertiary"
      icon="ri-arrow-left-line"
      @submit="navigateBack"
    />
    <payment-select
      :plans="getPlans"
      :currentPlan="getCurrentPlanName"
      :selectedPlanIndex.sync="selectedPlanIndex"
      :currentPlanIndex="currentPlanIndex"
      :minimumPlanIndex="minimumPlanIndex"
      :isYearly="isYearly"
      @upgradeSubscription="upgradeSubscription"
      @updateCurrentPlanIndex="updateCurrentPlanIndex"
      @openUpgradePlanModal="openUpgradePlanModal"
      @subscribe="subscribe"
      @selectAnnual="setYearly"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash.get';

import { ME } from '@/graphql/queries/user';
import { GET_PLANS } from '@/graphql/queries/plans';
import {
  STRIPE_UPDATE_SUBSCRIPTION,
  STRIPE_REACTIVATE_SUBSCRIPTION
} from '@/graphql/mutations/subscription';
import { get as getRoute } from '@/router/routes';
import { bus } from '@/utils/bus';
import {
  getCurrentVariantId,
  getCurrentSubscriptionPeriod,
  isSubscriptionCanceled
} from '@/utils/functions/workspace';
import { getPlanVariantsSortedByPrice } from '@/utils/functions/plan';

import PaymentSelect from '@/containers/paymentSelect';
import { apolloClient } from '@/main';
import { appConfig } from '@/utils/constants';

const LABEL_MONTHLY = 'monthly';
const LABEL_YEARLY = 'yearly';

export default {
  components: {
    PaymentSelect
  },
  data() {
    return {
      isYearly: true,
      selectedPlanIndex: 0,
      minimumPlanIndex: undefined,
      currentPlanIndex: undefined
    };
  },
  apollo: {
    plans: {
      query: GET_PLANS,
      fetchPolicy: 'network-only',
      result(result) {
        const variantId = getCurrentVariantId(this.currentWorkspace);
        const { plans } = result.data;
        const proPlan = plans.find(item => item.slug === 'pro');
        const sortedPrices = getPlanVariantsSortedByPrice(proPlan);
        const subscriptionVariantIndex = sortedPrices.findIndex(i => i.id === variantId);
        if (subscriptionVariantIndex !== -1) {
          this.selectedPlanIndex = subscriptionVariantIndex;
          this.currentPlanIndex = subscriptionVariantIndex;
          this.minimumPlanIndex = subscriptionVariantIndex;
        }
        this.isYearly = getCurrentSubscriptionPeriod(this.currentWorkspace) !== LABEL_MONTHLY;
        // -------------------------------------------------------------
        // This part is used for downgrade, it checks tracks limitations
        // -------------------------------------------------------------
        const trackCount = this.currentWorkspace.total_tracks;
        const trackLimitVariantIndex = sortedPrices.findIndex(i => {
          const fileMaxFeature = i.features.find(u => u.type === 'libraryFilesMax');
          return fileMaxFeature.configuration[0].options > trackCount;
        });
        if (trackLimitVariantIndex !== -1) {
          this.minimumPlanIndex = trackLimitVariantIndex;
        }
        if (subscriptionVariantIndex !== -1) {
          this.selectedPlanIndex = subscriptionVariantIndex;
        }
      },
      skip() {
        return this.plans !== undefined;
      }
    },
    me: {
      query: ME,
      fetchPolicy: 'network-only'
    }
  },
  beforeMount() {
    // Add StripeJS only ONCE if loading directly the page
    if (document.getElementById(appConfig.stripeScriptId) === null) {
      const stripeScript = document.createElement('script');
      stripeScript.setAttribute('async', true);
      stripeScript.setAttribute('id', appConfig.stripeScriptId);
      stripeScript.setAttribute('src', 'https://js.stripe.com/v3/');
      document.head.appendChild(stripeScript);
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'userCurrency']),
    getPlans() {
      if (!this.plans) {
        return [];
      }
      return Object.values(this.plans);
    },
    getCurrentPlanName() {
      const planName = get(this.currentWorkspace, 'subscription.plan.name', '');
      return planName.toLowerCase();
    }
  },
  methods: {
    updateCurrentPlanIndex() {
      const proPlan = this.getPlans.find(item => item.slug === 'pro');
      const selectedVariantId = getPlanVariantsSortedByPrice(proPlan)[this.selectedPlanIndex].id;
      const variantId = selectedVariantId;
      const sortedPrices = getPlanVariantsSortedByPrice(proPlan);
      const subscriptionVariantIndex = sortedPrices.findIndex(i => i.id === variantId);
      if (subscriptionVariantIndex !== -1) {
        this.currentPlanIndex = subscriptionVariantIndex;
      }
    },
    async upgradeSubscription() {
      const updateDataAfterGraphQL = () => {
        // We don't use STRIPE_UPDATE_SUBSCRIPTION refetch because we need to wait a little to give backend some time
        // to process payment and receive webhook before making the refetch needed to update the view
        setTimeout(() => {
          // Force redirect to prevent unupdated data
          window.location.href = getRoute('billingSettings');
        }, 5000);
      };
      const { subscription } = this.currentWorkspace;
      if (isSubscriptionCanceled(this.currentWorkspace)) {
        const result = await apolloClient
          .mutate({
            mutation: STRIPE_REACTIVATE_SUBSCRIPTION,
            variables: {
              subscriptionId: subscription.payment_information.subscription_id
            }
          })
          .catch(() => {
            this.showSubscriptionFailedAlert();
          });
        if (result) {
          updateDataAfterGraphQL();
        }
      } else {
        const proPlan = this.getPlans.find(item => item.slug === 'pro');
        const selectedVariantId = getPlanVariantsSortedByPrice(proPlan)[this.selectedPlanIndex].id;
        const result = await apolloClient
          .mutate({
            mutation: STRIPE_UPDATE_SUBSCRIPTION,
            variables: {
              planVariantId: selectedVariantId,
              subscriptionId: subscription.payment_information.subscription_id,
              billingPeriod: this.isYearly ? LABEL_YEARLY : LABEL_MONTHLY,
              billingCurrency: subscription.payment_information.currency,
              seats: subscription.payment_information.seats
            }
          })
          .catch(() => {
            this.showSubscriptionFailedAlert();
          });
        if (result && get(result, 'data.StripeUpdateSubscription.subscription_id')) {
          updateDataAfterGraphQL();
        } else {
          this.showSubscriptionFailedAlert();
        }
      }
    },
    showSubscriptionFailedAlert() {
      bus.$emit('showAlert', {
        message: { key: 'subscriptionFailed' },
        style: 'error',
        delay: 5000
      });
    },
    openUpgradePlanModal() {
      this.$emit('openUpgradePlanModal');
    },
    subscribe() {
      const proPlan = this.getPlans.find(item => item.slug === 'pro');
      this.$store.commit('setSelectedPlan', {
        plan: getPlanVariantsSortedByPrice(proPlan)[this.selectedPlanIndex],
        planIndex: this.selectedPlanIndex,
        isYearly: this.isYearly
      });
      this.$router.push(getRoute('paymentSettings'));
    },
    navigateBack() {
      this.$router.push(getRoute('billingSettings'));
    },
    setYearly(value) {
      this.$nextTick(() => {
        this.isYearly = value;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.offersContainer {
  display: flex;
  flex-direction: row;
}
.navigationIcon {
  position: sticky;
  top: 0;
}
</style>
