
<div style="position: relative">
  <slot name="list"></slot>
  <observer
    :key="`${listQuery.key}-${page}`"
    v-if="values && !loading && page < lastPage"
    :height="40"
    bottom
    @intersect="loadMore"
  />
</div>
