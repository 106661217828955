<template>
  <div class="email" v-click-outside="{ hide: validateEntry }">
    <div class="email-label">{{ label }}</div>
    <div class="email-area" :class="errorMessage && 'email-error'" @click.self="focusIn">
      <div :class="getItemClass(item)" v-for="(item, index) in emails" :key="`email-${index}`">
        <input
          type="text"
          :class="getInputClass(item)"
          :style="`width: ${getInputWidth(item.value)}px; max-width: 468px;`"
          v-model="item.value"
          :disabled="item.isCopy"
          @keyup.13="toggleEdit(item, false, true)"
          @focus="toggleEdit(item, true, false)"
          @blur="toggleEdit(item, false, false)"
        />
        <icon-button
          class="email-area-item-cross"
          icon="ri-close-line"
          btnStyle="tertiary"
          size="small"
          v-if="!item.isEditable && !item.isCopy"
          @submit="removeHandleClick(item)"
        />
      </div>
      <input
        class="email-area-input"
        type="text"
        ref="input"
        v-model="newEmail"
        :style="newEmail ? `width: ${getInputWidth(newEmail)}px;` : 'width: 10px;'"
        :placeholder="emails.length === 0 && `${placeholder}`"
        @paste="handlePaste"
        @keydown="handleKeydown"
        @keydown.8="removeHandleBackspaceKey"
        @keydown.9="validateEntry"
        @input="handleInput"
      />
    </div>
    <div v-if="autocompleteEmails.length > 0 && newEmail.length > 0" class="email-expand">
      <div
        class="email-expand-item"
        v-for="(email, index) in autocompleteEmails"
        :key="`email-${index}`"
        @click="handleOptionClick(email)"
      >
        {{ email }}
      </div>
    </div>
    <div class="email-error-message" v-if="errorMessage">{{ errorMessage }}</div>
  </div>
</template>

<script>
import { clickOutside } from '@/utils/directives';
import debounce from 'lodash.debounce';
import { isEmail, removeAccentFromString } from '@/utils/functions/global';
import IconButton from '@/components/iconButton';

export default {
  components: {
    IconButton
  },
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    errorMessage: {
      type: String,
      required: false,
      default: null
    },
    autocompleteEmails: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      newEmail: '',
      emails: []
    };
  },
  directives: {
    clickOutside
  },
  methods: {
    /* eslint-disable-next-line */
    handleInput: debounce(function () {
      /* Can't use arrow function here or we loose this */
      if (this.newEmail.length > 0) {
        this.$emit('handleInput', this.newEmail);
      }
    }, 500),
    handlePaste() {
      setTimeout(() => {
        this.validateEntry();
      });
    },
    handleKeydown(e) {
      // enter, space, semicolon, comma
      const keys = [13, 32, 186, 188];
      if (keys.some(key => e.keyCode === key)) {
        // removeHandleBackspaceKey and validateEntry must execute before
        setTimeout(() => {
          this.newEmail = e.keyCode === 13 ? this.newEmail : this.newEmail.slice(0, -1);
          this.validateEntry();
        }, 10);
      }
    },
    validateEntry() {
      const separator = [' ', ';', ',', '\n', '\r', '\r\n', '\t'];
      if (separator.some(el => this.newEmail.includes(el))) {
        let arr = this.newEmail.split(/(?:;| |,|\n|\t|\r|\r\n)+/).filter(el => el.length > 1);
        const gmailFormat = arr.filter(el => el.includes('<'));
        if (gmailFormat.length > 0) {
          arr = arr.map(string => string.replace(/<|>/gm, '')).filter(mail => isEmail.test(mail));
        }
        arr.forEach(str => {
          if (str.length > 0) {
            this.newEmail = str;
            this.addEmail();
          }
        });
      } else {
        this.addEmail();
      }
    },
    addEmail() {
      if (this.newEmail && this.newEmail.length > 0) {
        this.emails.push({
          value: removeAccentFromString(this.newEmail),
          isEmail: isEmail.test(removeAccentFromString(this.newEmail)),
          isEditable: false
        });
      }
      this.newEmail = '';
      this.handleResult();
    },
    removeHandleClick(item) {
      this.emails.splice(this.emails.indexOf(item), 1);
      this.handleResult();
    },
    removeHandleBackspaceKey() {
      if (this.emails[this.emails.length - 1] && this.emails[this.emails.length - 1].isCopy) {
        return;
      }
      if (this.newEmail.length === 0) {
        this.emails.pop();
      }
      this.handleResult();
    },
    toggleEdit(item, isEditable, needBlur) {
      const index = this.emails.indexOf(item);
      this.$set(this.emails, index, { ...item, isEmail: isEmail.test(item.value), isEditable });
      if (needBlur) this.focusIn();
      this.handleResult();
    },
    getInputWidth(str) {
      const c = document.createElement('canvas');
      const ctx = c.getContext('2d');
      ctx.font = '400 14px manrope';
      return ctx.measureText(str).width;
    },
    getItemClass(item) {
      let str = 'email-area-item';
      if (!item.isEditable) {
        str += item.isEmail ? ' email-area-regular' : ' email-area-danger';
        str += !item.isCopy ? ' with-delete-btn' : 'without-delete-btn';
      }
      return str;
    },
    getInputClass(item) {
      let str = 'email-area-item-input';
      if (!item.isEditable) {
        str += item.isEmail ? ' email-area-item-regular' : ' email-area-item-danger';
      }
      return str;
    },
    focusIn() {
      this.$refs.input.focus();
    },
    handleResult() {
      const errorCount = this.emails.filter(email => !email.isEmail).length;
      const result = this.emails.filter(email => email.isEmail).map(email => email.value);
      this.$emit('handleResult', result, errorCount);
    },
    handleOptionClick(value) {
      this.emails.push({
        value,
        isEmail: isEmail.test(value),
        isEditable: false
      });
      this.newEmail = '';
      this.$emit('handleOptionClick');
    },
    unshiftMail(mail) {
      // use from parent with ref
      this.emails.unshift({
        value: mail,
        isEmail: isEmail.test(removeAccentFromString(mail)),
        isEditable: false,
        isCopy: true
      });
      this.handleResult();
    },
    shiftMail() {
      // use from parent with ref
      this.emails.shift();
      this.handleResult();
    }
  }
};
</script>

<style lang="scss" scoped>
.email {
  position: relative;
  font-weight: 400;
  font-size: 14px;

  &-label {
    margin: 0 0 8px 0;
    text-align: left;
    @include label;
  }

  &-area {
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    min-height: 49px;
    box-sizing: border-box;
    border: 1px solid $color_neutral_30;
    border-radius: 4px;
    padding: 8px 8px 0 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    cursor: text;

    &-item {
      display: flex;
      max-width: fit-content;
      align-items: center;
      border-radius: 22px;
      padding: 4px 12px;
      margin: 0 8px 8px 0;
      border: 1px solid $color_neutral_30;

      &-input {
        font-family: inherit;
        font-weight: 400;
        font-size: 14px;
        outline: none;
        border: none;
        background: none;
        filter: none;
      }

      &-regular {
        color: $color_neutral_100;
        cursor: move;
      }

      &-danger {
        color: $color_neutral_0;
        cursor: move;
      }

      .ri-close-circle-fill {
        cursor: pointer;
        margin: 0 0 0 4px;
      }
    }
    .with-delete-btn {
      padding: 0;
      padding-left: 12px;
    }
    .without-delete-btn {
      background-color: $color_neutral_0;
    }

    &-regular {
      background-color: $color_neutral_10;
      color: $color_neutral_100;
      cursor: move;
    }

    &-danger {
      background-color: $color_danger_10;
      border: 1px solid $color_danger_100;
      cursor: move;
      .email-area-item-danger {
        color: $color_danger_100;
      }
    }

    &-input {
      font-weight: 400;
      font-size: 14px;
      color: $color_neutral_100;
      flex-grow: 1;
      outline: none;
      border: none;
      background: none;
      filter: none;
      padding: 0 0 8px 0;
    }
  }

  &-expand {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    margin: 2px 0 0 0;
    padding: 8px 16px;
    background: $color_neutral_0;
    border: 1px solid $color_neutral_30;
    box-sizing: border-box;
    border-radius: 2px;
    position: absolute;
    z-index: 10;
    @include shadow-down-03;

    &-item {
      cursor: pointer;
      font-size: 14px;
      line-height: 150%;
      color: $color_neutral_100;
      margin: 0 0 16px 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  &-error {
    border: 1px solid $color_danger_100;
  }

  &-error-message {
    color: $color_danger_100;
    font-size: 12px;
    padding: 4px 0 0 0;
    font-weight: 400;
    text-align: left;
    line-height: 18px;
    position: absolute;
    right: 0;
  }
}

::placeholder {
  color: $color_neutral_40;
  font-size: 14px;
  font-weight: 400;
}
</style>

<style lang="scss">
.email-area-item-cross.rounded-button.tertiary.small {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  .icon {
    color: $color_neutral_60;
  }
}
.email-area-item-cross.rounded-button.tertiary.small:hover {
  .icon {
    color: $color_neutral_100;
  }
}
.email-area-danger {
  .email-area-item-cross.rounded-button.tertiary.small {
    width: 29px;
    height: 29px;
    border-radius: 50%;
    .icon {
      color: $color_danger_100;
    }
  }
}
.email-area-danger {
  .email-area-item-cross.rounded-button.tertiary.small:hover {
    background: $color_danger_10;
  }
}
</style>
