<template>
  <router-view></router-view>
</template>

<script>
import { mapGetters } from 'vuex';
import { bus } from '@/utils/bus';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import { PROJECT_SHARING, PROJECT_LIST } from '@/graphql/queries/project';
import { SHARE_ENABLE } from '@/graphql/mutations/project';
import { PAGINATOR_LIMIT } from '@/utils/constants';

export default {
  data() {
    return {
      sharingParams: {},
      shareIds: [],
      enableShareParams: {},
      fillShareByMail: null,
      shareByMailTimeOut: null
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace'])
  },
  created() {
    bus.$on('handleShareEnableMutation', this.handleShareEnableMutation);
  },
  destroyed() {
    bus.$off('handleShareEnableMutation', this.handleShareEnableMutation);
  },
  methods: {
    handleShareEnableMutation(params) {
      this.shareIds = params.shareIds;
      this.enableShareParams = params;
      this.enableProject();
    },
    enableProject() {
      const { projectId, enable } = this.enableShareParams;
      this.$apollo
        .mutate({
          mutation: SHARE_ENABLE,
          variables: {
            shareId: this.shareIds[0],
            enable
          },
          refetchQueries: [
            {
              query: PROJECT_SHARING,
              variables: {
                projectId
              }
            },
            {
              query: PROJECT_LIST,
              variables: {
                workspaceId: this.currentWorkspace.id,
                status: 'active',
                page: 1,
                limit: PAGINATOR_LIMIT
              }
            }
          ]
        })
        .then(() => {
          this.shareIds.shift();
          if (this.shareIds.length > 0) this.enableProject();
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    }
  }
};
</script>
