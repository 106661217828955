
<div class="wrap">
  <div
    class="scroll-container"
    :style="{ width, height }"
    ref="scrollContainer"
    @scroll.passive="toggleShadow"
  >
    <slot />
    <span :class="['shadow-top', shadow.top && 'is-active']" />
    <span :class="['shadow-bottom', shadow.bottom && 'is-active']" />
  </div>
</div>
