<template>
  <div>
    <infinite-scroll
      :listQuery="listTracksQuery"
      @handleQueryResult="handleQueryResult"
      @setLoading="setLoading"
    >
      <template v-slot:list>
        <project-list v-if="trackList">
          <template v-slot:default="slotProps">
            <div class="tracks-list">
              <div
                class="tracks-list-content"
                :style="editMode && 'width: calc(100% - 518px + 38px);'"
              >
                <tracks
                  :trackList="trackList"
                  :keepProjectDropdownExpand="keepProjectDropdownExpand"
                  :isFetchingMore="loading"
                  :uncheckTracks="uncheckTracks"
                  :projectList="slotProps.projectList"
                  :noProjectSearchResult="slotProps.noProjectSearchResult"
                  :showProjectObserver="slotProps.showProjectObserver"
                  :loadingProjects="slotProps.loadingProjects"
                  :fetchingMoreProjects="slotProps.fetchingMoreProjects"
                  :editMode="editMode"
                  withArtwork
                  isSticky
                  sortColumn
                  hasSelectAll
                  @openConfirmDeleteModal="openConfirmDeleteModal"
                  @createProject="createProject"
                  @searchProject="slotProps.searchProject"
                  @fetchMoreProjects="slotProps.fetchMoreProjects"
                  @listProjects="slotProps.listProjects"
                  @sortTracks="sortTracks"
                  @toggleEditMode="toggleEditMode"
                />
              </div>
            </div>
          </template>
        </project-list>
      </template>
    </infinite-scroll>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PAGINATOR_LIMIT } from '@/utils/constants';
import { LIST_TRACKS_LIGHT } from '@/graphql/queries/library';
import generateTracklist from '@/utils/functions/tracklist';
import InfiniteScroll from '@/containers/infiniteScroll';
import ProjectList from '@/containers/projectList';
import Tracks from '@/containers/libraryTrackList';

export default {
  props: {
    editMode: {
      type: Boolean,
      required: false,
      default: false
    },
    keepProjectDropdownExpand: {
      type: Boolean,
      required: false,
      default: false
    },
    uncheckTracks: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    InfiniteScroll,
    ProjectList,
    Tracks
  },
  data() {
    return {
      orderBy: 'created',
      sortBy: 'desc',
      trackList: null,
      loading: false,
      page: 1
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    listTracksQuery() {
      return {
        query: LIST_TRACKS_LIGHT,
        key: 'listTracks',
        variables: {
          libraryId: this.currentWorkspace.libraries[0].id,
          page: this.page,
          limit: PAGINATOR_LIMIT,
          orderBy: this.orderBy,
          sortBy: this.sortBy
        },
        pagination: 'page'
      };
    }
  },
  methods: {
    handleQueryResult(response) {
      if (response.data) {
        this.trackList = generateTracklist(
          response.data.listTracks.data,
          this.currentWorkspace,
          this.$i18n.locale,
          false
        );
        this.$emit('setTrackList', this.trackList);
      }
    },
    setLoading(loading) {
      this.loading = loading;
    },
    openConfirmDeleteModal(libraryFileIds) {
      this.$emit('openConfirmDeleteModal', libraryFileIds);
    },
    createProject() {
      this.$emit('createProject');
    },
    sortTracks({ column, orderBy }) {
      this.page = 1;
      this.sortBy = orderBy;
      switch (column) {
        case 'title':
        case 'text-action':
          this.orderBy = 'name';
          break;
        case 'artists':
          this.orderBy = 'artist.name';
          break;
        case 'supports':
          this.orderBy = 'support.name';
          break;
        case 'added':
          this.orderBy = 'created';
          break;
        case 'humanDuration':
          this.orderBy = 'length';
          break;
        case 'starsRating':
          this.orderBy = 'rating';
          break;
        default:
          this.orderBy = 'created';
          this.sortBy = 'desc';
      }
      this.$emit('sortTracks', this.sortBy, this.orderBy);
    },
    toggleEditMode(libraryFileIds, paneToOpen) {
      this.$emit('toggleEditMode', libraryFileIds, paneToOpen);
    }
  }
};
</script>

<style lang="scss" scoped>
.tracks-list {
  margin: 0 0 0 12px;

  &-content {
    position: relative;
    transition: width 0.15s ease;
    width: 100%;
  }
}
</style>
