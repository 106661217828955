
<div class="volume-control">
  <input
    :class="inputClass"
    type="range"
    min="0"
    max="100"
    step="1"
    v-model="sliderValue"
    @input="onInputHandler"
  />
  <div class="volume-control-slider" :style="`width: ${sliderWidth}px`" />
  <div class="volume-control-slider-background" />
</div>
