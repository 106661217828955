
<div class="searchbar" :class="isFocusActive && 'active'">
  <i v-if="icon" class="searchbar-icon" :class="icon" />
  <input
    class="searchbar-input"
    type="text"
    :placeholder="placeholder"
    v-model="inputValue"
    @blur="setActiveClass(false)"
    @focus="setActiveClass(true)"
    @input="$emit('update:fieldModel', $event.target.value)"
    spellcheck="false"
  />
  <i v-if="inputValue" @click="clearInputValue" class="ri-close-circle-fill searchbar-delete" />
</div>
