import gql from 'graphql-tag';
import { projectFragment, projectAttachmentFragment, shareFragment } from '../fragments';

export const PROJECT_NEW = gql`
  mutation ProjectNew($name: String!, $description: String, $workspaceId: ID!, $parentId: ID) {
    ProjectNew(
      name: $name
      description: $description
      workspaceId: $workspaceId
      parentId: $parentId
    ) {
      ...project
    }
  }
  ${projectFragment.project}
`;

export const PROJECT_DELETE = gql`
  mutation ProjectsDelete($projectIds: [ID]!) {
    ProjectsDelete(projectIds: $projectIds)
  }
`;

export const ADD_TRACK_TO_PROJECT = gql`
  mutation ProjectAddTracks($workspaceId: ID!, $projectId: ID!, $libraryFilesIds: [ID!]) {
    ProjectAddTracks(
      workspaceId: $workspaceId
      projectId: $projectId
      libraryFilesIds: $libraryFilesIds
    )
  }
`;

export const PROJECT_UPDATE = gql`
  mutation ProjectUpdate($projectId: ID!, $name: String!, $description: String) {
    ProjectUpdate(projectId: $projectId, name: $name, description: $description) {
      ...project
    }
  }
  ${projectFragment.project}
`;

export const PROJECT_DUPLICATE = gql`
  mutation ProjectDuplicate($name: String, $fromProjectId: ID!, $targetProjectId: ID) {
    ProjectDuplicate(
      name: $name
      fromProjectId: $fromProjectId
      targetProjectId: $targetProjectId
    ) {
      ...project
    }
  }
  ${projectFragment.project}
`;

export const PROJECT_DELETE_TRACKS = gql`
  mutation ProjectDeleteTracks($projectId: ID!, $tracksIds: [ID!]) {
    ProjectDeleteTracks(projectId: $projectId, tracksIds: $tracksIds)
  }
`;

export const PROJECT_ADD_ATTACHMENTS = gql`
  mutation ProjectAddAttachments($projectId: ID!, $files: [Upload!], $uploadIds: [ID]) {
    ProjectAddAttachments(projectId: $projectId, files: $files, uploadIds: $uploadIds) {
      data {
        ...projectAttachment
      }
      errors {
        file
        messages {
          code
          msg
          param
        }
      }
    }
  }
  ${projectAttachmentFragment.projectAttachment}
`;

export const PROJECT_INITIALIZE_ATTACHMENT_UPLOAD = gql`
  mutation ProjectInitializeAttachmentUpload(
    $project_id: ID!
    $file_name: String!
    $size: String!
    $mime_type: String!
  ) {
    ProjectInitializeAttachmentUpload(
      project_id: $project_id
      file_name: $file_name
      size: $size
      mime_type: $mime_type
    ) {
      upload_id
      url
    }
  }
`;

export const PROJECT_DELETE_ATTACHMENTS = gql`
  mutation ProjectDeleteAttachments($projectId: ID!, $attachmentsIds: [ID]!) {
    ProjectDeleteAttachments(projectId: $projectId, attachmentsIds: $attachmentsIds)
  }
`;

export const PROJECT_COPY_ATTACHMENTS = gql`
  mutation ProjectCopyAttachments(
    $projectId: ID!
    $targetProjectId: ID!
    $attachmentsIds: [ID]!
    $deleteMode: DeleteMode
  ) {
    ProjectCopyAttachments(
      projectId: $projectId
      targetProjectId: $targetProjectId
      attachmentsIds: $attachmentsIds
      deleteMode: $deleteMode
    )
  }
`;

export const PROJECT_RENAME_ATTACHMENTS = gql`
  mutation ProjectRenameAttachment($projectId: ID!, $attachmentId: ID!, $newName: String!) {
    ProjectRenameAttachment(projectId: $projectId, attachmentId: $attachmentId, newName: $newName) {
      id
      file {
        id
        name
        size
        download_url
      }
    }
  }
`;

export const PROJECT_MOVE_ATTACHMENT = gql`
  mutation ProjectMoveAttachment($projectId: ID!, $attachmentId: ID!, $position: Int!) {
    ProjectMoveAttachment(projectId: $projectId, attachmentId: $attachmentId, position: $position)
  }
`;

export const PROJECT_SHARE = gql`
  mutation ProjectShare(
    $workspaceId: ID!
    $projectId: ID!
    $access_rights: [String]
    $access_password: String
    $email: String
    $slug: String
    $custom_name: String
    $custom_message: String
    $reply_to: String
  ) {
    ProjectShare(
      workspaceId: $workspaceId
      projectId: $projectId
      access_rights: $access_rights
      access_password: $access_password
      reply_to: $reply_to
      email: $email
      slug: $slug
      custom_name: $custom_name
      custom_message: $custom_message
    ) {
      ...share
    }
  }
  ${shareFragment.share}
`;

export const SHARE_ENABLE = gql`
  mutation ShareEnable($shareId: ID!, $enable: Boolean!) {
    ShareEnable(shareId: $shareId, enable: $enable)
  }
`;

export const SHARE_CONFIGURE = gql`
  mutation ShareConfigure(
    $shareId: ID!
    $access_rights: [String]
    $access_password: String
    $custom_name: String
    $slug: String
  ) {
    ShareConfigure(
      shareId: $shareId
      access_rights: $access_rights
      access_password: $access_password
      custom_name: $custom_name
      slug: $slug
    ) {
      ...share
    }
  }
  ${shareFragment.share}
`;

export const PROJECT_RESTORE = gql`
  mutation ProjectRestore($projectId: ID!) {
    ProjectRestore(projectId: $projectId) {
      ...project
    }
  }
  ${projectFragment.project}
`;

export const PROJECT_SHARE_PUBLIC = gql`
  mutation ProjectSharePublic($workspaceId: ID!, $projectId: ID!) {
    ProjectSharePublic(workspaceId: $workspaceId, projectId: $projectId) {
      ...share
    }
  }
  ${shareFragment.share}
`;

export const PROJECT_MOVE_TRACK = gql`
  mutation ProjectMoveTrack($projectId: ID!, $libraryFileId: ID!, $position: Int!) {
    ProjectMoveTrack(projectId: $projectId, libraryFileId: $libraryFileId, position: $position)
  }
`;

export const ARCHIVE_PROJECT = gql`
  mutation ArchiveProject($projectId: ID!, $isOriginal: Boolean!) {
    ArchiveProject(projectId: $projectId, isOriginal: $isOriginal) {
      id
      url {
        url
      }
      status
    }
  }
`;

export const PROJECT_COVER_UPLOAD = gql`
  mutation ProjectCoverUpload($projectId: ID!, $file: Upload, $position: Float, $uploadId: ID) {
    ProjectCoverUpload(
      projectId: $projectId
      file: $file
      position: $position
      uploadId: $uploadId
    ) {
      file_id
      url
      position
    }
  }
`;

export const PROJECT_INITIALIZE_PROJECT_COVER_UPLOAD = gql`
  mutation ProjectInitializeProjectCoverUpload(
    $project_id: ID!
    $file_name: String!
    $size: String!
    $mime_type: String!
  ) {
    ProjectInitializeProjectCoverUpload(
      project_id: $project_id
      file_name: $file_name
      size: $size
      mime_type: $mime_type
    ) {
      upload_id
      url
    }
  }
`;

export const PROJECT_COVER_DELETE = gql`
  mutation ProjectCoverDelete($projectId: ID!) {
    ProjectCoverDelete(projectId: $projectId)
  }
`;

export const PROJECT_COVER_CHANGE_POSITION = gql`
  mutation ProjectCoverChangePosition($projectId: ID!, $newPosition: Float!) {
    ProjectCoverChangePosition(projectId: $projectId, newPosition: $newPosition)
  }
`;

export const PROJECT_COPY_TO_WORKSPACE = gql`
  mutation ProjectCopyToWorkspace($projectId: ID!, $targetWorkspaceId: ID!) {
    ProjectCopyToWorkspace(projectId: $projectId, targetWorkspaceId: $targetWorkspaceId) {
      id
      name
    }
  }
`;
