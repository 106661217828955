
<div class="more" v-click-outside="{ hide }">
  <icon-button
    v-if="!isSecondary"
    :icon="icon"
    :isHover="isExpanded"
    btnStyle="tertiary"
    @submit="toggleExpand"
    :size="size"
  />
  <icon-button
    v-if="isSecondary"
    :icon="icon"
    btnStyle="secondary"
    :size="size"
    @submit="toggleExpand"
  />
  <div
    class="more-expanded"
    :class="[isExpanded && 'more-expanded-open', sizeClass, forceClass]"
    v-dropdown-orientation="direction"
  >
    <div
      v-for="(item, index) in items"
      :key="`item-${index}`"
      :class="[
        item.separator ? 'more-action-separator' : 'more-item',
        item.subText ? 'item-with-sub' : ''
      ]"
      @click.stop="handleClickOption(item, itemId)"
    >
      <div
        @click.stop="handleClickOption(item, itemId)"
        class="more-item--"
        :class="[{ 'is-danger': item.danger }]"
      >
        <i :class="item.icon"></i>
        <span class="more-item-label">
          {{ item.name }}
          <span v-if="item.info" class="info">{{ item.info }}</span>
        </span>
        <span v-if="item.isNew" class="more-item-label-new">
          <img src="/images/new-badge.svg" alt="new badge" />
        </span>
      </div>
      <div class="more-item-subtext">{{ item.subText }}</div>
    </div>
    <div v-if="otherItems" class="more-action-separator"></div>
    <div
      v-for="(item, index) in otherItems"
      :key="`otherItem-${index}`"
      class="more-action"
      @click.stop="handleClickOption(item, itemId)"
    >
      <div @click.stop="handleClickOption(item, itemId)" class="more-action--">
        <i :class="item.icon"></i>
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</div>
