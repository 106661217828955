<template>
  <div class="activity-item">
    <div class="activity-item-hour">{{ hour }}</div>
    <div class="activity-item-icon" :class="actionStyle[action.raw].color">
      <i :class="actionStyle[action.raw].icon"></i>
    </div>
    <div class="activity-item-info">
      <div class="activity-item-info-action">
        <div class="activity-item-info-action-item">{{ action.type }}&nbsp;</div>
        <div class="activity-item-info-action-item" v-if="action.precision">
          {{ action.precision }}&nbsp;
        </div>
        <div class="activity-item-info-action-item-bold">{{ `"${itemName}"` }}</div>
      </div>
      <div class="activity-item-info-mail">
        {{ mail }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hour: {
      type: String,
      required: true
    },
    action: {
      type: Object,
      required: true
    },
    itemName: {
      type: String,
      required: true
    },
    mail: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      actionStyle: {
        opened: {
          icon: 'ri-folder-5-line',
          color: 'activity-item-icon-red'
        },
        streamed: {
          icon: 'ri-music-line',
          color: 'activity-item-icon-blue'
        },
        download_support: {
          icon: 'ri-folder-download-line',
          color: 'activity-item-icon-yellow'
        },
        download_track: {
          icon: 'ri-folder-download-line',
          color: 'activity-item-icon-yellow'
        },
        download_project: {
          icon: 'ri-folder-download-line',
          color: 'activity-item-icon-yellow'
        },
        download_artist: {
          icon: 'ri-folder-download-line',
          color: 'activity-item-icon-yellow'
        },
        download_file: {
          icon: 'ri-file-download-line',
          color: 'activity-item-icon-yellow'
        },
        download_original: {
          icon: 'ri-download-line',
          color: 'activity-item-icon-yellow'
        },
        download_mp3: {
          icon: 'ri-download-line',
          color: 'activity-item-icon-yellow'
        },
        download_submission: {
          icon: 'ri-folder-download-line',
          color: 'activity-item-icon-yellow'
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.activity-item {
  display: flex;
  align-items: center;
  height: 40px;
  &-hour {
    @include body-2;
    color: $color_neutral_60;
    margin-right: 8px;
    width: 32px;
  }
  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border-radius: 4px;
    margin-right: 8px;
    &-red {
      background-color: $color_red_10;
      color: $color_red_100;
    }
    &-yellow {
      background-color: $color_secondary_10;
      color: $color_secondary_100;
    }
    &-blue {
      background-color: $color_primary_10;
      color: $color_primary_100;
    }
  }
  &-info {
    &-action {
      display: flex;
      @include body-1;
      &-item {
        padding: 0 3px 0 0;
        &-bold {
          font-weight: 600;
        }
      }
    }
    &-mail {
      @include body-2;
      color: $color_neutral_60;
    }
  }
}
</style>
