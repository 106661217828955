
<div class="dropdown" v-click-outside="{ hide, keepExpand }">
  <div v-if="!btnStyle" class="dropdown-button" @click="handleClickButton">
    <div class="dropdown-button-label">
      <i v-if="iconClass" class="dropdown-button-icon" :class="iconClass" />
      <span>{{ buttonLabel }}</span>
    </div>
    <i v-if="showArrow" class="ri-arrow-drop-down-line" />
  </div>
  <div class="dropdown-button2">
    <submit-button
      v-if="btnStyle"
      :btnStyle="btnStyle"
      size="medium"
      :label="buttonLabel"
      :iconClass="iconClass"
      :isDropdown="isExpandable"
      @submit="handleClickButton"
    />
  </div>
  <div
    v-dropdown-orientation="direction"
    :style="expandStyle"
    class="dropdown-expand"
    @click="hide"
  >
    <div class="dropdown-expand-options" v-if="isExpanded">
      <div
        v-for="(option, index) in options"
        class="dropdown-expand-options-item"
        :class="option.danger && 'dropdown-expand-options-danger'"
        :key="`item-${index}`"
        @click="option.action(itemId)"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</div>
