
<div :class="{ textfield: true, horizontal: horizontalDisplaying }">
  <div
    :class="{
      'textfield-error': (errorMessage && showError) || inErrorWithoutMessage,
      'horizontal-wrapper': horizontalDisplaying
    }"
  >
    <div class="textfield-head" v-if="!horizontalDisplaying">
      <div v-if="label" class="textfield-head-label">{{ label }}</div>
      <div v-if="helper" class="textfield-head-helper">{{ helper }}</div>
    </div>
    <div v-if="!horizontalDisplaying && hint" class="textfield-head-hint">
      {{ hint }}
    </div>
    <div class="horizontal-wrapper-label" v-if="horizontalDisplaying">{{ label }}</div>
    <div :class="textfieldClass">
      <div v-if="withEditButton && inputDisabled" class="textfield-disabled-edit-button">
        <submit-button
          btnStyle="tertiary"
          exception
          danger
          size="small"
          label="Edit"
          @submit="handleEditBtnClick"
        />
      </div>
      <imask-input
        v-if="mask !== ''"
        :mask="mask"
        :lazy="this.pwd.length === 0"
        ref="input"
        :unmask="false"
        @blur="
          setActiveClass(false);
          handleBlurEvent();
        "
        @focus="setActiveClass(true)"
        @input="
          $emit('update:fieldModel', $event);
          $emit('handleInput', $event);
          handleInputEvent();
        "
        @keydown="preventOnKeydown"
        type="text"
        v-model="pwd"
        :placeholder="placeholder"
        :class="passwordInputClass"
        spellcheck="false"
        :autocomplete="false"
        :disabled="inputDisabled"
        :style="inputWidth && `width: ${inputWidth}px;`"
        @keyup="preventNegative"
        @paste="preventOnPaste"
      />
      <input
        v-else
        ref="input"
        @blur="
          setActiveClass(false);
          handleBlurEvent();
        "
        @focus="setActiveClass(true)"
        @input="
          $emit('update:fieldModel', $event.target.value);
          $emit('handleInput', $event.target.value);
          handleInputEvent();
        "
        @keydown="preventOnKeydown"
        :type="type"
        v-model="pwd"
        v-on:input="setPwdColor"
        :placeholder="placeholder"
        :class="passwordInputClass"
        spellcheck="false"
        :autocomplete="autocomplete"
        :disabled="inputDisabled"
        :style="inputWidth && `width: ${inputWidth}px;`"
        @keyup="preventNegative"
        @paste="preventOnPaste"
        min="0"
      />
      <div
        v-if="withVisibility"
        :class="pwdIconClass"
        class="visibility"
        @click="togglePwdVisibility"
      />
    </div>
  </div>
  <div v-if="!errorMessage && withCheck && pwdInfo !== ''">
    <div class="textfield-check-pwd">
      <div
        v-for="index in level"
        class="textfield-check-pwd-line"
        :style="`background-color: ${pwdColor};`"
        :key="`check-pwd-${index}`"
      />
      <div
        v-for="index in 3 - level"
        class="textfield-check-pwd-line"
        :key="`check-pwd2-${index}`"
      />
    </div>
    <div v-if="pwdInfo" class="textfield-info" :style="`color: ${pwdColor};`">{{ pwdInfo }}</div>
  </div>
  <div
    v-if="showError"
    class="textfield-error-msg"
    :class="{ 'error-msg-style-exception': errorMessageException }"
  >
    {{ errorMessage }}
  </div>
</div>
