
<div class="container">
  <div
    v-for="(item, index) in items"
    :key="`item-${index}`"
    :class="!item.separator ? 'menu-item' : ''"
  >
    <div v-if="item.title" class="title">{{ item.title }}</div>
    <radio-button v-else-if="item.radioButtons" :options="item.radioButtons" />
    <div v-else-if="item.separator" class="separator"></div>
    <div v-else @click.stop="handleClickOption(item)" class="label">
      <i v-if="item.icon" :class="item.icon" class="icon" />
      <span>{{ item.label }}</span>
    </div>
  </div>
</div>
