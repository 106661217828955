
<div class="artist-tracks">
  <project-list>
    <template v-slot:default="slotProps">
      <tracks
        v-if="trackList && trackList.length"
        :trackList="trackList"
        :projectList="slotProps.projectList"
        :noProjectSearchResult="slotProps.noProjectSearchResult"
        :showProjectObserver="slotProps.showProjectObserver"
        :loadingProjects="slotProps.loadingProjects"
        :fetchingMoreProjects="slotProps.fetchingMoreProjects"
        :keepProjectDropdownExpand="keepProjectDropdownExpand"
        :uncheckTracks="uncheckTracks"
        :editMode="editMode"
        withArtwork
        hasSelectAll
        @openConfirmDeleteModal="openConfirmDeleteModal"
        @createProject="createProject"
        @searchProject="slotProps.searchProject"
        @fetchMoreProjects="slotProps.fetchMoreProjects"
        @listProjects="slotProps.listProjects"
        @toggleEditMode="toggleEditMode"
      />
      <div v-else class="loader">
        <spinner-without-progress color="grey" :size="32" />
      </div>
    </template>
  </project-list>
</div>
