<template>
  <div class="full-storage-modal">
    <div class="full-storage-modal-body">
      <span>{{ $t('fullStorage') }}</span>
      <span v-if="isEditor" class="full-storage-modal-body-normal"
        >{{ $t('upgradeByAdmin.firstPart') }}
        <span class="full-storage-modal-body-bold">{{ $t('upgradeByAdmin.boldPart') }}</span>
        <span class="full-stroage-modal-body-normal">{{
          $t('upgradeByAdmin.thirdPartToKeepUploading')
        }}</span></span
      >
      <span v-else class="full-storage-modal-body-bold"
        >{{ $t('pleaseUpgrade') }}
        <span class="full-storage-modal-body-normal">{{ $t('keepUploading') }}</span></span
      >
    </div>
    <div class="full-storage-modal-buttons">
      <div class="full-storage-modal-buttons--">
        <submit-button
          btnStyle="tertiary"
          size="medium"
          :label="$t('cancel')"
          @submit="closeModal"
        />
      </div>
      <div
        class="full-storage-modal-buttons--"
        v-tooltip="{
          content: isEditor && $t('upgradePlanByAdmin'),
          classes: 'alert-editor-tooltip'
        }"
      >
        <submit-button
          btnStyle="primary"
          size="medium"
          :label="currentWorkspace.free_trial_used ? $t('upgradePlan') : $t('startFreeTrial')"
          @submit="submit"
          :disabled="isEditor"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    onSubmit: {
      type: Function,
      required: false,
      default: () => {}
    },
    isEditor: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace'])
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    submit() {
      this.$emit('closeModal');
      this.onSubmit();
    }
  }
};
</script>

<style lang="scss" scoped>
.full-storage-modal {
  &-body {
    font-size: 14px;
    padding: 24px;
    line-height: 150%;
    &-bold {
      font-weight: 600;
      padding-left: 2px;
      padding-right: 2px;
    }
    &-normal {
      font-weight: normal;
      padding: 0;
    }
  }
  &-buttons {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $color_neutral_30;
    padding: 8px 24px;

    &-- {
      &:first-child {
        margin: 0 16px 0 0;
      }
    }
  }
}
</style>
<style lang="scss">
.alert-editor-tooltip.tooltip {
  max-width: 140px;
  .tooltip-inner {
    text-align: center;
  }
}
</style>
