import gql from 'graphql-tag';

export const GET_PLANS = gql`
  query plan {
    plans(publicOnly: true) {
      id
      name
      is_public
      slug
      position
      variants {
        id
        free_trial_duration
        features {
          type
          is_available
          configuration {
            type
            options
          }
        }
        prices {
          currency
          yearly_price
          monthly_price
        }
      }
      cost_per_extra_member {
        currency
        yearly_price
        monthly_price
      }
    }
  }
`;

export const GET_PLANS_OLD = gql`
  query plans {
    plans(publicOnly: true) {
      id
      name
      slug
      position
      monthly_price
      annually_price
      is_public
      is_trial_available
      features {
        type
        is_available
        configuration {
          type
          options
        }
      }
    }
  }
`;

export const GET_PRICING = gql`
  query customPlanPricing {
    customPlanPricing {
      capacity
      number_of_users
      monthly_price
      annual_price
    }
  }
`;
