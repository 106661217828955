<template>
  <div class="more" v-click-outside="{ hide }">
    <icon-button
      v-if="!isSecondary"
      :icon="icon"
      :isHover="isExpanded"
      btnStyle="tertiary"
      @submit="toggleExpand"
      :size="size"
    />
    <icon-button
      v-if="isSecondary"
      :icon="icon"
      btnStyle="secondary"
      :size="size"
      @submit="toggleExpand"
    />
    <div
      class="more-expanded"
      :class="[isExpanded && 'more-expanded-open', sizeClass, forceClass]"
      v-dropdown-orientation="direction"
    >
      <div
        v-for="(item, index) in items"
        :key="`item-${index}`"
        :class="[
          item.separator ? 'more-action-separator' : 'more-item',
          item.subText ? 'item-with-sub' : ''
        ]"
        @click.stop="handleClickOption(item, itemId)"
      >
        <div
          @click.stop="handleClickOption(item, itemId)"
          class="more-item--"
          :class="[{ 'is-danger': item.danger }]"
        >
          <i :class="item.icon"></i>
          <span class="more-item-label">
            {{ item.name }}
            <span v-if="item.info" class="info">{{ item.info }}</span>
          </span>
          <span v-if="item.isNew" class="more-item-label-new">
            <img src="/images/new-badge.svg" alt="new badge" />
          </span>
        </div>
        <div class="more-item-subtext">{{ item.subText }}</div>
      </div>
      <div v-if="otherItems" class="more-action-separator"></div>
      <div
        v-for="(item, index) in otherItems"
        :key="`otherItem-${index}`"
        class="more-action"
        @click.stop="handleClickOption(item, itemId)"
      >
        <div @click.stop="handleClickOption(item, itemId)" class="more-action--">
          <i :class="item.icon"></i>
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { clickOutside, dropdownOrientation } from '../../utils/directives';
import IconButton from '../iconButton';

export default {
  components: {
    IconButton
  },
  props: {
    items: {
      type: Array,
      required: false
    },
    otherItems: {
      type: Array,
      required: false
    },
    isSecondary: {
      type: Boolean,
      required: false,
      default: false
    },
    itemId: {
      type: String,
      required: false
    },
    forceDirection: {
      type: Object,
      required: false,
      default: null
    },
    size: {
      type: String,
      required: false,
      default: 'small'
    },
    icon: {
      type: String,
      required: false,
      default: 'ri-more-fill'
    }
  },
  data() {
    return {
      isExpanded: false,
      direction: {
        top: 'toTop',
        bot: 'toBot',
        left: 'toLeft',
        right: 'toRight'
      }
    };
  },
  computed: {
    sizeClass() {
      let str = '';
      if (this.size === 'medium') str = 'medium';
      if (this.size === 'large') str = 'large';
      return str;
    },
    forceClass() {
      if (this.forceDirection) {
        const { forceDirection } = this;
        let str = '';
        if (forceDirection.top) {
          str += ' forceTop';
        }
        if (forceDirection.bot) {
          str += ' forceBot';
        }
        if (forceDirection.right) {
          str += ' forceRight';
        }
        if (forceDirection.left) {
          str += ' forceLeft';
        }
        return str;
      }
      return '';
    }
  },
  directives: {
    clickOutside,
    dropdownOrientation
  },
  watch: {
    isExpanded() {
      this.$emit('setMoreDropdownState', { id: this.itemId, isExpanded: this.isExpanded });
    }
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    hide() {
      this.isExpanded = false;
    },
    handleClickOption(item, itemId) {
      item.action(itemId);
      if (!item.keepExpand) this.isExpanded = false;
      this.$emit('selectItemFromDropdown', this.itemId);
    }
  }
};
</script>

<style lang="scss" scoped>
.more {
  display: inline-block;
  position: relative;
  @include body-1;
  line-height: 150%;
  &-expanded {
    max-height: 0;
    padding: 0;
    box-shadow: none;
    border: none;
    overflow: hidden;
    position: absolute;
    background-color: $color_neutral_0;
    border-radius: 4px;
    -webkit-transition: none;
    transition: none;
    &-open {
      width: fit-content;
      width: -moz-fit-content;
      max-height: 500px;
      white-space: nowrap;
      padding: 4px 0;
      border: 1px solid $color_neutral_30;
      @include shadow-down-03;
      z-index: 10;
    }
  }

  &-item,
  &-action {
    display: flex;
    align-items: center;
    height: 29px;
    cursor: pointer;
    padding: 0 32px 0 16px;
    i {
      font-size: 16px;
      margin-right: 8px;
    }
    &:hover {
      background-color: $color_neutral_10;
    }
    &-- {
      display: flex;
      .info {
        padding-left: 5px;
        font-size: 12px;
        color: $color_neutral_60;
      }
    }
    &-label {
      display: flex;
      align-items: center;
      &-new {
        margin-left: 4px;
        height: 24px;
      }
    }
    &-subtext {
      font-size: 12px;
      color: $color_neutral_60;
    }
  }
  &-action {
    &-separator {
      width: 100%;
      height: 1px;
      background-color: $color_neutral_30;
      margin: 4px 0;
    }
  }
}

.item-with-sub {
  flex-direction: column;
  justify-content: center;
  align-items: unset;
  height: fit-content;
  padding: 5px 32px 5px 16px;
}

.is-danger {
  color: $color_danger_100;
}

.toTop {
  bottom: 36px;
}
.toTop.medium {
  bottom: 44px;
}
.toTop.large {
  bottom: 52px;
}
.toBot {
  top: 36px;
}
.toBot.medium {
  top: 44px;
}
.toBot.large {
  top: 52px;
}
.toLeft {
  right: 0;
}
.toRight {
  left: 0;
}
.forceTop {
  top: unset;
  bottom: 36px;
}
.forceTop.medium {
  top: unset;
  bottom: 44px;
}
.forceTop.large {
  top: unset;
  bottom: 52px;
}
.forceBot {
  top: 36px;
  bottom: unset;
}
.forceBot.medium {
  top: 44px;
  bottom: unset;
}
.forceBot.large {
  top: 52px;
  bottom: unset;
}
.forceLeft {
  right: 0;
  left: unset;
}
.forceRight {
  right: unset;
  left: 0;
}
</style>
