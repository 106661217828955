<template>
  <div class="file-panel-body">
    <div class="file-panel-body-title">{{ $t('fileInformation') }}</div>
    <div class="file-panel-body-section">
      <div class="file-panel-body-section-- title">{{ $t('duration') }}</div>
      <div class="file-panel-body-section--">{{ duration }}</div>
    </div>
    <div class="file-panel-body-section">
      <div class="file-panel-body-section-- title">{{ $t('type') }}</div>
      <div class="file-panel-body-section--">{{ type }}</div>
    </div>
    <div class="file-panel-body-section">
      <div class="file-panel-body-section-- title">{{ $t('size') }}</div>
      <div class="file-panel-body-section--">{{ size }}</div>
    </div>
    <div class="file-panel-body-section">
      <div class="file-panel-body-section-- title">{{ $t('bitrate') }}</div>
      <div class="file-panel-body-section--">{{ bitRate }}</div>
    </div>
    <div class="file-panel-body-section">
      <div class="file-panel-body-section-- title">{{ $t('lastUpdated') }}</div>
      <div class="file-panel-body-section--">{{ lastUpdate }}</div>
    </div>
    <div class="file-panel-body-section">
      <div class="file-panel-body-section-- title">{{ $t('addedDate') }}</div>
      <div class="file-panel-body-section--">{{ addedDate }}</div>
    </div>
    <div class="file-panel-body-section">
      <div class="file-panel-body-section-- title">{{ $t('addedBy') }}</div>
      <div class="file-panel-body-section--">
        <div class="file-panel-body-section--avatar" v-if="isSameUploader">
          <div v-if="userName && userName !== ''" style="display: flex">
            <avatar :initial="initial" :pictureUrl="pictureUrl" pictureSize="workspaceSmall" />
            <div class="file-panel-body-section--avatar-username">{{ userName }}</div>
          </div>
          <div v-else>-</div>
        </div>
        <div v-else>{{ MULTIPLE_LABEL }}</div>
      </div>
    </div>
    <div class="file-panel-body-section" v-if="!isMultipleTrackEdition">
      <div class="file-panel-body-section-- file title">{{ $t('file') }}</div>
      <div v-if="!replacingPending" class="file-panel-body-section-- original-name">
        {{ originalName }}
      </div>
      <div v-else class="file-panel-body-section-- original-name uploading">
        {{ `${$t('uploadingDot')} ${uploadPercent}%` }}
      </div>
    </div>
    <div class="file-panel-body-button">
      <submit-button
        ref="uploadFileInput"
        btnStyle="secondary"
        size="small"
        :label="$t('replaceAudioFile')"
        iconClass="ri-repeat-line"
        browse
        :pending="replacingPending"
        cancelable
        :accept="allowedFileTypes"
        :disabled="!allowedFileTypes || allowedFileTypes === ''"
        @submit="checkFilesAndSpaceBeforeUpload"
        @cancel="cancelReplace"
      />
    </div>
  </div>
</template>

<script>
import get from 'lodash.get';
import * as tus from 'tus-js-client';
import { mapGetters } from 'vuex';
import { bus } from '@/utils/bus';
import { getGraphQlErrorCode, getGraphQlErrorLibraryFileId } from '@/utils/functions/global';
import { LIBRARY_INITIALIZE_TRACK_UPLOAD } from '@/graphql/mutations/library';
import { REPLACE_LIBRARY_FILE } from '@/graphql/mutations/upload';

import { getUserPicture, getUserInitials, getUserNames } from '@/utils/functions/user';
import { getAllowedFileTypes } from '@/utils/functions/audio';

import FilesTooLargeModal from '@/containers/modals/filesTooLarge';
import ReplaceAudioFileModal from '@/containers/modals/replaceAudioFile';

const MULTIPLE_LABEL = '< Multiple >';

export default {
  props: {
    tracksToEdit: {
      type: Array,
      required: false,
      default: () => []
    },
    isMultipleTrackEdition: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      replacingPending: false,
      abortHandler: undefined,
      uploadPercent: 0
    };
  },
  mounted() {
    this.$root.$on('openReplaceFileInput', () => {
      if (this.$refs.uploadFileInput && this.$refs.uploadFileInput.$el) {
        this.$refs.uploadFileInput.$el.click();
      }
    });
  },
  destroyed() {
    this.$root.$off('openReplaceFileInput');
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    totalSpace() {
      if (typeof this.currentWorkspace.subscription.storage === 'string')
        return parseInt(this.currentWorkspace.subscription.storage, 10) * 1000000;
      return this.currentWorkspace.subscription.storage * 1000000;
    },
    spaceUsed() {
      return parseInt(this.currentWorkspace.space_used, 10);
    },
    remainingSpace() {
      return this.totalSpace - this.spaceUsed;
    },
    allowedFileTypes() {
      return getAllowedFileTypes(this.$config).join(',');
    },
    duration() {
      return this.isMultipleTrackEdition
        ? this.getValueForMultipleEdition('humanDuration')
        : this.tracksToEdit[0].humanDuration;
    },
    addedDate() {
      let date = this.isMultipleTrackEdition
        ? this.getValueForMultipleEdition('added')
        : this.tracksToEdit[0].added;
      if (!date) date = '-';
      return date;
    },
    type() {
      let type = this.isMultipleTrackEdition
        ? this.getValueForMultipleEdition('format')
        : this.tracksToEdit[0].format;
      if (!type) type = '-';
      if (type !== MULTIPLE_LABEL) {
        type = type.toUpperCase();
      }
      return type;
    },
    size() {
      if (!this.tracksToEdit[0].size) return '-';
      if (this.isMultipleTrackEdition) return MULTIPLE_LABEL;
      return this.converteByte(this.tracksToEdit[0].size);
    },
    originalName() {
      if (!this.tracksToEdit[0].original_name) return '-';
      if (this.isMultipleTrackEdition) return MULTIPLE_LABEL;
      return this.tracksToEdit[0].original_name;
    },
    bitRate() {
      if (!this.tracksToEdit[0].bit_rate) return '-';
      if (this.isMultipleTrackEdition) return MULTIPLE_LABEL;
      return this.converteByte(this.tracksToEdit[0].bit_rate, true);
    },
    lastUpdate() {
      let date = this.isMultipleTrackEdition
        ? this.getValueForMultipleEdition('updated_at')
        : this.tracksToEdit[0].updated_at;
      if (!date) date = '-';
      return date;
    },
    pictureUrl() {
      return getUserPicture(this.extractUserFromTrack());
    },
    initial() {
      return getUserInitials(this.extractUserFromTrack());
    },
    userName() {
      return getUserNames(this.extractUserFromTrack()).trim();
    },
    isSameUploader() {
      const uploaderIds = this.tracksToEdit.map(el => el.added_by);
      return uploaderIds.every((val, i, arr) => val === arr[0]);
    }
  },
  methods: {
    checkFilesAndSpaceBeforeUpload(file) {
      const sizeAdded = file[0].size - this.tracksToEdit[0].size;
      if (sizeAdded > 0 && sizeAdded > this.remainingSpace) {
        bus.$emit('displayModal', {
          title: this.$t('filesTooLarge'),
          size: 'medium',
          isVisible: true,
          component: FilesTooLargeModal
        });
        return;
      }
      bus.$emit('displayModal', {
        title: this.$t('modalReplaceAudioFile.title'),
        size: 'small',
        isVisible: true,
        component: ReplaceAudioFileModal,
        onSubmit: this.replaceAudioFile,
        props: [
          {
            name: 'title',
            value: this.tracksToEdit[0].title
          },
          {
            name: 'file',
            value: file
          }
        ]
      });
    },
    replaceAudioFile(files) {
      if (this.$isWorkspaceLockedVerification(this)) return;
      const file = files[0];
      this.replacingPending = true;
      const that = this;
      new Promise((resolve, reject) => {
        let UPLOAD_ID;
        const upload = new tus.Upload(file, {
          retryDelays: [0, 3000, 5000, 10000, 20000],
          removeFingerprintOnSuccess: true,
          fingerprint: async () => {
            return ['tus', file.name, file.type, file.size].join('-');
          },
          onError(error) {
            reject(error);
          },
          onProgress(bytesUploaded, bytesTotal) {
            const percentage = Math.floor((bytesUploaded / bytesTotal) * 100);
            that.uploadPercent = percentage;
          },
          onSuccess() {
            resolve({
              uploadId: UPLOAD_ID
            });
          }
        });
        this.$apollo
          .mutate({
            mutation: LIBRARY_INITIALIZE_TRACK_UPLOAD,
            variables: {
              library_id: this.currentWorkspace.libraries[0].id,
              file_name: file.name,
              size: `${file.size}`,
              mime_type: file.type
            }
          })
          .then(res => {
            const url = get(res, 'data.LibraryInitializeTrackUpload.url');
            const id = get(res, 'data.LibraryInitializeTrackUpload.upload_id');
            if (url && id) {
              upload.url = url;
              upload.options.metadata.id = id;
              UPLOAD_ID = id;
              upload.start();
            } else {
              reject(new Error('Invalid upload initialization data', res));
            }
          })
          .catch(() => {
            reject();
          });
      }).then(res => {
        this.$apollo
          .mutate({
            mutation: REPLACE_LIBRARY_FILE,
            variables: {
              library_id: this.currentWorkspace.libraries[0].id,
              library_file_id: this.tracksToEdit[0].id,
              upload_id: res.uploadId
            }
          })
          .then(() => {
            this.replacingPending = false;
            bus.$emit('showAlert', {
              message: {
                key: 'audioFileReplaced'
              },
              style: 'success',
              delay: 5000
            });
          })
          .catch(err => {
            this.uploadPercent = 0;
            this.replacingPending = false;
            const key = getGraphQlErrorCode(err);
            const libraryFileIdError = getGraphQlErrorLibraryFileId(err);
            bus.$emit('showAlert', {
              message:
                libraryFileIdError === this.tracksToEdit[0].id
                  ? { key: 'identicalFiles' }
                  : { key },
              style: 'danger',
              delay: 5000,
              error: true
            });
          });
      });
    },
    cancelReplace() {
      this.replacingPending = false;
      this.uploadPercent = 0;
      this.abortHandler();
    },
    extractUserFromTrack() {
      return get(this.currentWorkspace, 'members', []).find(
        u => u.user_id === this.tracksToEdit[0].added_by
      );
    },
    getValueForMultipleEdition(key) {
      const { length } = this.tracksToEdit.filter(
        item => JSON.stringify(this.tracksToEdit[0][key]) === JSON.stringify(item[key])
      );
      return length === this.tracksToEdit.length ? this.tracksToEdit[0][key] : MULTIPLE_LABEL;
    },
    converteByte(aSize, isBitrate) {
      const size = Math.abs(parseInt(aSize, 10));
      const unit = [
        [1, isBitrate ? 'bps' : 'octets'],
        [isBitrate ? 1000 : 1024, isBitrate ? 'kb/s' : 'kb'],
        [isBitrate ? 1000 * 1000 : 1024 * 1024, isBitrate ? 'Mb/s' : 'Mb'],
        [isBitrate ? 1000 * 1000 * 1000 : 1024 * 1024 * 1024, isBitrate ? 'Gb/s' : 'Gb']
      ];
      for (let i = 0; i < unit.length; i += 1) {
        if (size < unit[i][0] * (isBitrate ? 1000 : 1024)) {
          return `${(size / unit[i][0]).toFixed(2)} ${unit[i][1]}`;
        }
      }
      return '-';
    }
  }
};
</script>

<style lang="scss" scoped>
.file-panel-body {
  &-title {
    margin: 0 0 16px 0;
    @include subtitle-1;
  }
  &-section {
    display: flex;
    margin: 0 0 16px 0;
    &-- {
      width: 140px;
      &avatar {
        display: flex;
        &-username {
          margin-left: 8px;
        }
      }
    }
  }
  &-button {
    margin-left: 140px;
    max-width: 160px;
  }
}
.original-name {
  margin-right: 8px;
  width: fit-content;
  color: $color_neutral_60;
}
.original-name,
.file {
  line-height: 32px;
}
.title {
  color: $color_neutral_60;
  flex-shrink: 0;
}
.file-panel-body-section--.original-name.uploading {
  font-variant-numeric: tabular-nums;
  width: 120px;
}
</style>
