
<div class="album">
  <div class="album-header" v-if="supportDetails && !error">
    <img v-if="isUnknownAlbum()" :src="albumImgUrl" />
    <image-browser
      v-if="!isUnknownAlbum()"
      square
      :removeButton="displayDeleteArtwork"
      :imgUrl="albumImgUrl"
      @changeImgUrl="openCropperModal"
      @removeImage="deleteSupportCover"
      pictureSize="coverSize"
    />
    <div class="album-header-info">
      <div class="album-header-info-txt">
        <div class="album-header-info-txt-name">{{ supportDetails.name }}</div>
        <div v-if="isUnknownAlbum()" class="album-header-info-txt-count">
          {{ $tc('trackCount', supportDetails.tracks_number) }}
        </div>
        <div v-if="!isUnknownAlbum()">
          <div>
            <div
              v-for="artist in supportDetails.artists"
              :key="artist.id"
              class="album-header-artistList"
            >
              <span @click="goToArtistPage(artist.id)">{{ artist.name }}</span>
            </div>
          </div>
          <div class="album-header-info-txt-count">
            {{
              `${$tc('trackCount', supportDetails.tracks_number)} • ${getAlbumDuration(
                supportDetails.tracks_length
              )}`
            }}
          </div>
          <div class="album-header-info-txt-details">
            <div class="album-header-info-txt-details-label">{{ $t('type') }}</div>
            <div class="album-header-info-txt-details-value">
              {{ supportDetails.type }}
            </div>
          </div>
          <div class="album-header-info-txt-details">
            <div class="album-header-info-txt-details-label">{{ $t('releaseDate') }}</div>
            <div class="album-header-info-txt-details-value">
              {{ getDate(supportDetails.release_date) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="album-tabs">
    <tabs :tabs="tabs" :activeTab="activeTab" />
  </div>
  <router-view
    v-if="supportDetails"
    :supportDetails="supportDetails"
    :entityIds="[this.$route.params.id]"
    :entityName="albumName"
    @renderAlbumView="renderAlbumView"
  ></router-view>
</div>
