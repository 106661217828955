<template>
  <div class="card-artist">
    <router-link
      class="card-artist-link"
      :to="artistUrl"
      @mouseover.native="cardHoverHandler"
      @mouseleave.native="cardMouseLeaveHandler"
    >
      <div class="card-artist-link-img">
        <img loading="lazy" :src="artistCover" alt="artist cover" class="card-artist-link-img--" />
      </div>
      <transition name="fade">
        <div v-if="isHover" class="card-artist-link-hover"></div>
      </transition>
    </router-link>
    <div class="card-artist-name">
      <router-link
        :to="artistUrl"
        :class="linkClass"
        @mouseover.native="cardHoverHandler"
        @mouseleave.native="cardMouseLeaveHandler"
        >{{ artistName }}</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    artistCover: {
      type: String,
      required: false,
      default: './../../assets/images/artistPlaceHolder.png'
    },
    artistUrl: {
      type: String,
      required: true
    },
    artistName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isHover: false,
      cardArtistStyle: {
        backgroundImage: `url("${this.artistCover}")`
      }
    };
  },
  computed: {
    linkClass() {
      return {
        'card-artist-name--': true,
        'link-hovered': this.isHover
      };
    }
  },
  methods: {
    cardHoverHandler() {
      this.isHover = true;
    },
    cardMouseLeaveHandler() {
      this.isHover = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-artist {
  width: 100%;
  height: fit-content;
  &-link {
    position: relative;
    display: block;
    width: 100%;
    &-img {
      width: 100%;
      padding-top: 100%;
      position: relative;
      &-- {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center center;
      }
    }
    &-hover {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      top: 0;
      background: $ui-overlay-gradient-dark;
    }
  }
  &-name {
    width: 100%;
    height: calc(213px - 177px);
    display: flex;
    justify-content: center;
    align-items: center;
    &-- {
      @include subtitle-1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 21px;
      box-sizing: border-box;
    }
  }
}

.link-hovered {
  text-decoration: underline $color_neutral_100;
}

// Fade transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
