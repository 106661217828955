
<div class="row">
  <div class="row-body" :style="gridTemplateColumns">
    <div
      v-for="(column, idx) in filteredColumns"
      :class="{
        'row-body-cell': true,
        'row-body-no-border': column.noBorder,
        'row-body-centered': column.centered
      }"
      :style="[
        { height: `${height}px` },
        {
          padding: column.padding
            ? `${column.padding[0]}px ${column.padding[1]}px ${column.padding[2]}px ${column.padding[3]}px`
            : 'unset'
        },
        {
          gridColumn: column.span ? `span ${column.span} / auto` : 'unset'
        }
      ]"
      :key="`${column.id}-${idx}`"
    >
      <skeleton
        :square="column.skeleton === 'square'"
        :align="column.skeletonAlignement || 'left'"
        v-if="column.isHover !== true"
      />
      <div v-else />
    </div>
  </div>
</div>
