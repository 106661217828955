
<div class="tags-settings" :key="randomKey">
  <div v-if="displayHeader" class="AI-section">
    <div class="tags-settings-title"><i class="ri-sparkling-2-fill"></i> Auto-Tagging</div>
    <div class="tags-settings-subtitle">
      Our homemade AI can automatically tag your tracks with genre, vocal, instruments, mood, and
      more.<br />This will allow you and your collaborators to do accurate searches, in an
      objectively described library.
    </div>
    <switch-button
      ref="aiSwitchBtn"
      class="AI-switch"
      :defaultValue="currentWorkspace.properties.auto_tagger"
      id="switch-AI"
      label="Enable AI on my library and future uploads"
      labelPrefix
      size="large"
      @click.native.stop
      @toggleSwitchButton="toggleAIModal"
    />
    <div class="help-center">
      Visit our
      <a
        href="https://bridgeaudio.notion.site/A-I-auto-tagging-8918b46e898e437085a235566053560f"
        target="_blank"
        rel="noopener noreferrer"
        >Help Center</a
      >
      for more information about Bridge AI.
    </div>
    <div class="section-separator" />
  </div>
  <div class="tags-settings-header" v-if="displayHeader">
    <div class="tags-settings-title">{{ $t('CustomTagsTitle') }}</div>
    <div class="tags-settings-subtitle">{{ $t('manageCustomTagsSubtitle') }}</div>
  </div>
  <div class="tags-settings-create">
    <div class="tags-settings-create--">
      <text-field
        inputType="text"
        :errorMessage="tagNameValidationMessage"
        :fieldModel.sync="$v.tagName.$model"
        :label="$t('createTag')"
        :inputWidth="319"
        :large="false"
        @keyup.enter.native="createCustomTag"
      />
    </div>
    <submit-button
      btnStyle="primary"
      size="medium"
      :label="$t('create')"
      :disabled="isSaveDisabled"
      @submit="createCustomTag"
    />
  </div>
  <div v-if="customTagList.length > 0" class="tags-settings-list">
    <div class="tags-settings-list-title">{{ $t('customTagList') }}</div>
    <ul class="tags-settings-list-content">
      <li class="tags-settings-list-row" v-for="(tag, index) in customTagList" :key="tag.id">
        <div class="tags-settings-list-row-item" v-if="!tag.isEdited">
          <div class="tags-settings-list-row-item-tag">
            {{ tag.name }}
          </div>
          <div class="tags-settings-list-row-item-btn">
            <icon-button
              icon="ri-edit-line"
              btnStyle="secondary"
              size="small"
              @submit="toggleEditMode(tag.id)"
            />
          </div>
          <div class="tags-settings-list-row-item-btn">
            <icon-button
              class="danger"
              icon="ri-delete-bin-2-line"
              btnStyle="secondary"
              size="small"
              @submit="deleteTag(tag.id)"
            />
          </div>
        </div>
        <div
          class="tags-settings-list-row-item"
          v-else
          :style="getTagNameValidationMessage(index) !== null && `margin-bottom: 24px;`"
        >
          <div class="tags-settings-list-row-item-input">
            <text-field
              inputType="text"
              :errorMessage="getTagNameValidationMessage(index)"
              :fieldModel.sync="tag.name"
              :value="tag.name"
              focusOnLoad
              :large="false"
              @keyup.enter.native="editCustomTag(tag)"
            />
          </div>
          <div class="tags-settings-list-row-item-save">
            <submit-button
              class="save-button"
              btnStyle="primary"
              size="medium"
              :label="$t('save')"
              :disabled="
                getTagNameValidationMessage(index) !== null ||
                !$v.customTagList.$each[index].name.required
              "
              @submit="editCustomTag(tag)"
            />
            <submit-button
              btnStyle="secondary"
              size="medium"
              :label="$t('cancel')"
              @submit="cancelEdit(tag.id)"
            />
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
