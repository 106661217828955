<template>
  <div class="share-by-mail-body">
    <div class="upgrade-banner" v-if="sharesExpiresIn">
      <upgrade-banner
        :content="`Shared links expire after ${sharesExpiresIn} days with the Starter plan`"
        :roleId="myRoleId"
        @closeModal="$emit('closeModal')"
      />
    </div>
    <div class="share-by-mail-body-title">{{ $t('sendByEmail') }}</div>
    <div class="share-by-mail-body-desc">{{ $t('sendByEmailDesc') }}</div>
    <div class="share-by-mail-body-to">
      <p class="emailPicker-label">{{ $t('from') }}</p>
      <div v-if="UserEmails">
        <from-email-picker
          ref="emailPickerComponent"
          :emailReplyTo="emailReplyTo"
          :UserEmails="UserEmails"
          :UserEmailsSorted="UserEmailsSorted"
          :getDefaultNoReplyEmail="getDefaultNoReplyEmail"
          @setEmailReplyTo="setEmailReplyTo"
          @resendConfirmationEmail="resendConfirmationEmail"
          @goToSettings="goToSettings"
        />
      </div>
      <div v-else class="emailPicker-loader">
        <spinner-without-progress color="grey" :size="32" />
      </div>
    </div>
    <div class="share-by-mail-body-to">
      <email-area
        ref="emailArea"
        :label="$t('toCapitalize')"
        :placeholder="$t('enterEmail')"
        :errorMessage="emailListErrorMsg"
        :autocompleteEmails="autocompleteEmails"
        @handleInput="searchEmails"
        @handleOptionClick="resetSearchEmails"
        @handleResult="setEmailList"
      />
      <div class="to-info" v-tooltip="$t('toInfo')">
        <i class="ri-question-fill"></i>
      </div>
      <div class="send-me-copy">
        <checkbox
          id="sendCopyToSelf"
          :label="$t('sendMeACopy')"
          @toggleCheckbox="toggleSendCopyToSelf"
        />
      </div>
    </div>
    <div class="share-by-mail-body-message">
      <text-area
        :label="$t('optionalMessage')"
        adaptativeHeight
        :fieldModel.sync="message"
        :characterLimit="$v.message.$params.maxLength.max"
        :errorMessage="messageErrorMsg"
      />
    </div>
    <access-rights
      :accessRights="emailAccessRights"
      :isProject="isProject"
      type="email"
      class="access-rights-email"
      @setAccessRights="setEmailAccessRights"
    />
    <share-security-section
      ref="sharePasswordToggle"
      :displayPasswordInput="displaySharePassword"
      :securityPassword.sync="sharePassword"
      :securityPasswordErrorMessage="passwordErrorMessage"
      @toggleSwitch="toggleSharePassword"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { maxLength, minLength } from 'vuelidate/lib/validators';
import { get as getRoute } from '@/router/routes';

import { SHARE_AUTOCOMPLETE_EMAILS } from '@/graphql/queries/project';
import AccessRights from '@/containers/accessRights';
import fromEmailPicker from '@/containers/dropdowns/fromEmailPicker';
import ShareSecuritySection from '@/containers/form/shareSecuritySection';
import UpgradeBanner from '@/containers/upgradeBanner';

const NO_REPLY_EMAIL = 'no-reply@bridge.audio';

export default {
  components: {
    AccessRights,
    fromEmailPicker,
    ShareSecuritySection,
    UpgradeBanner
  },
  props: {
    entityType: {
      type: String,
      required: true
    },
    myRoleId: {
      type: Number,
      required: false,
      default: null
    },
    sharesExpiresIn: {
      type: String,
      required: false,
      default: null
    },
    UserEmails: {
      type: Array,
      required: false,
      default: () => []
    },
    primaryEmail: {
      type: Object,
      required: false,
      default: () => {}
    },
    emailAccessRights: {
      type: Array,
      required: true
    },
    emailListErrorMsg: {
      type: String,
      required: false,
      default: null
    },
    displaySharePassword: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      autocompleteEmails: [],
      message: null,
      emailList: [],
      emailReplyTo: this.primaryEmail.is_verified ? this.primaryEmail : { email: NO_REPLY_EMAIL },
      waitForSearch: false,
      noSearchResult: false,
      sharePassword: null,
      isSendCopyToSelf: false
    };
  },
  validations: {
    message: {
      maxLength: maxLength(2000)
    },
    sharePassword: {
      minLength: minLength(3),
      maxLength: maxLength(50)
    }
  },
  watch: {
    sharePassword() {
      this.$emit('setSharePassword', this.sharePassword);
    },
    message() {
      this.$emit('setMessage', this.message);
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    getDefaultNoReplyEmail() {
      return NO_REPLY_EMAIL;
    },
    UserEmailsSorted() {
      return this.sortEmailByPrimary(this.UserEmails);
    },
    messageErrorMsg() {
      if (!this.$v.message.maxLength)
        return this.$t('maxLength', {
          label: this.$t('message'),
          nbChars: this.$v.message.$params.maxLength.max
        });
      return null;
    },
    passwordErrorMessage() {
      if (!this.$v.sharePassword.minLength) {
        return this.$t('minChars', {
          count: this.$v.sharePassword.$params.minLength.min
        });
      }
      if (!this.$v.sharePassword.maxLength) {
        return this.$t('maxiChars', {
          count: this.$v.sharePassword.$params.maxLength.max
        });
      }
      return null;
    },
    isProject() {
      return this.entityType === 'project';
    }
  },
  methods: {
    sortEmailByPrimary(array) {
      const sortedArray = array.sort(a => {
        if (a.is_primary) {
          return -1;
        }
        return 0;
      });
      return sortedArray;
    },
    resendConfirmationEmail(id) {
      this.$emit('resendConfirmationEmail', id);
    },
    setEmailReplyTo(item) {
      if (item === undefined) {
        this.emailReplyTo = {
          email: NO_REPLY_EMAIL
        };
        this.$refs.emailPickerComponent.hide();
      } else if (item.is_verified) {
        this.emailReplyTo = item;
        this.$refs.emailPickerComponent.hide();
      }
      this.$emit('setEmailReplyTo', this.emailReplyTo);
      if (this.isSendCopyToSelf) {
        // needed to shift the array to avoid having multiple send to self
        this.toggleSendCopyToSelf(false);
        this.toggleSendCopyToSelf(true);
      }
    },
    goToSettings() {
      this.$router.push(getRoute('accountSettings'));
      this.$emit('closeModal');
    },
    searchEmails(email) {
      if (!this.waitForSearch && !this.noSearchResult) {
        this.waitForSearch = true;
        this.$apollo
          .mutate({
            mutation: SHARE_AUTOCOMPLETE_EMAILS,
            variables: {
              workspaceId: this.currentWorkspace.id,
              email
            }
          })
          .then(result => {
            this.autocompleteEmails = result.data.ShareAutocompleteEmails;
            this.waitForSearch = false;
            if (result.data.ShareAutocompleteEmails.length === 0) this.noSearchResult = true;
          })
          .catch(() => {
            this.resetSearchEmails();
            this.waitForSearch = false;
          });
      }
    },
    resetSearchEmails() {
      this.noSearchResult = false;
      this.autocompleteEmails = [];
    },
    setEmailList(result, errorCount) {
      this.$emit('setEmailList', result, errorCount);
      this.resetSearchEmails();
    },
    setEmailAccessRights(emailAccessRights) {
      this.$emit('setEmailAccessRights', emailAccessRights);
    },
    toggleSharePassword() {
      this.$emit('toggleSharePassword');
    },
    hardToggleSharePassword() {
      if (this.$refs.sharePasswordToggle) {
        this.$refs.sharePasswordToggle.hardToggleSwitchButton();
      }
    },
    toggleSendCopyToSelf(bool) {
      this.isSendCopyToSelf = bool;
      if (bool) {
        const mail =
          this.emailReplyTo.email === 'no-reply@bridge.audio'
            ? this.primaryEmail.email
            : this.emailReplyTo.email;
        this.$refs.emailArea.unshiftMail(mail);
      } else {
        this.$refs.emailArea.shiftMail();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.share-by-mail {
  &-body {
    .upgrade-banner {
      padding: 0 24px 16px 24px;
    }
    padding: 32px 0 0 0;

    &-title {
      @include subtitle-1;
      padding: 0 24px 8px 24px;
      display: flex;
      justify-content: space-between;
    }

    &-desc {
      @include body-2;
      color: $color_neutral_60;
      padding: 0 24px;
    }

    &-to {
      padding: 16px 24px 0 24px;
      position: relative;
      .to-info {
        color: $color_neutral_40;
        position: absolute;
        top: 16px;
        left: 49px;
      }
      .send-me-copy {
        margin-top: 16px;
      }
    }

    &-message {
      padding: 24px 24px 0 24px;
    }
  }
}
.access-rights-email {
  padding: 16px 24px 24px 24px;
}

.emailPicker-label {
  margin: 0 0 8px 0;
  font-weight: 600;
  font-size: 12px;
}

.emailPicker-loader {
  text-align: center;
  padding: 0 0 8px;
}
</style>
