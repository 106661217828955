
<div :class="`b-alert ${alertStyle}`">
  <div class="b-alert-msg">
    <i v-if="iconClass" :class="`b-alert-msg-icon ${iconClass}`" />
    <div class="title">{{ title }}</div>
    <div class="msg">{{ message }}</div>
    <div v-if="actionLabel" class="b-alert-action">
      <submit-button
        class="b-alert-action-button"
        btnStyle="secondary"
        size="small"
        :label="actionLabel"
        :disabled="actionDisabled"
        @submit="$emit('handleActionClick')"
      />
    </div>
  </div>
  <div class="b-alert-close">
    <div class="b-alert-action-separator" />
    <icon-button
      class="b-alert-close-btn"
      icon="ri-close-fill"
      btnStyle="tertiary"
      size="small"
      @submit="$emit('closeAlert', false)"
    />
  </div>
</div>
