
<div class="activity-item">
  <div class="activity-item-hour">{{ hour }}</div>
  <div class="activity-item-icon" :class="actionStyle[action.raw].color">
    <i :class="actionStyle[action.raw].icon"></i>
  </div>
  <div class="activity-item-info">
    <div class="activity-item-info-action">
      <div class="activity-item-info-action-item">{{ action.type }}&nbsp;</div>
      <div class="activity-item-info-action-item" v-if="action.precision">
        {{ action.precision }}&nbsp;
      </div>
      <div class="activity-item-info-action-item-bold">{{ `"${itemName}"` }}</div>
    </div>
    <div class="activity-item-info-mail">
      {{ mail }}
    </div>
  </div>
</div>
