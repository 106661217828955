
<div class="project-list">
  <div class="project-list-grid">
    <div class="project-list-grid-body">
      <div
        :class="'project-list-grid-body-active'"
        class="project-list-grid-body-row"
        v-for="project in projects"
        :key="project.id"
        :id="project.id"
        :data-id="project.id"
        @mouseenter="setIsHover(project.id)"
        @mouseleave="setIsHover(null)"
        @click="handleClick($event, project.id)"
      >
        <div class="project-list-grid-body-row-cell project-list-grid-body-row-centered">
          <i class="ri-folder-fill project-list-grid-body-icon" />
        </div>
        <div class="project-list-grid-body-row-cell">
          <div class="project-list-grid-body-ellipsis project-list-grid-body-title">
            {{ project.name.label }}
          </div>
          <div class="project-list-grid-body-label">
            <div class="project-list-grid-body-count ellipsis">
              <i class="ri-music-2-line project-list-grid-body-count-icon" />
              <div>{{ project.name.trackCount }}</div>
            </div>
            <div class="project-list-grid-body-count ellipsis">
              <i class="ri-attachment-2 project-list-grid-body-count-icon" />
              <div>{{ project.name.attachmentCount }}</div>
            </div>
          </div>
        </div>
        <div class="project-list-grid-body-row-cell">
          <div class="project-list-grid-body-label">{{ project.createdAt.label }}</div>
          <div class="project-list-grid-body-text">{{ project.createdAt.value }}</div>
        </div>
        <div class="project-list-grid-body-row-cell">
          <div class="project-list-grid-body-label">{{ project.updatedAt.label }}</div>
          <div class="project-list-grid-body-text">{{ project.updatedAt.value }}</div>
        </div>
        <div class="project-list-grid-body-row-cell">
          <div class="project-list-grid-body-label">{{ project.createdBy.label }}</div>
          <div class="project-list-grid-body-name">
            <div class="project-list-grid-body-name-avatar">
              <avatar
                :initial="project.createdBy.initial"
                :pictureUrl="project.createdBy.pictureUrl"
                pictureSize="ultraSmall"
              />
            </div>
            <div class="project-list-grid-body-text project-list-grid-body-ellipsis">
              {{ project.createdBy.name }}
            </div>
          </div>
        </div>
        <div class="project-list-grid-body-row-cell">
          <div v-if="isHoverId === project.id" class="project-list-grid-body-actions">
            <div class="project-list-grid-body-actions-btn">
              <icon-button
                icon="ri-share-forward-2-fill"
                btnStyle="tertiary"
                size="medium"
                @click.native.stop="$emit('share', project.id)"
              />
            </div>
            <div class="project-list-grid-body-actions-btn">
              <icon-button
                icon="ri-file-copy-line"
                btnStyle="tertiary"
                size="medium"
                @click.native.stop="$emit('duplicate', project.id)"
              />
            </div>
            <div class="project-list-grid-body-actions-btn">
              <more-dropdown
                :items="moreItems"
                :otherItems="moreOtherItems"
                size="medium"
                :itemId="project.id"
                @click.native="stopPropagation"
              />
            </div>
          </div>
          <div v-if="isHoverId === project.id" class="project-list-grid-body-actions">
            <div class="project-list-grid-body-actions-btn">
              <icon-button
                icon="ri-refresh-line"
                btnStyle="tertiary"
                @click.native.stop="$emit('active', project.id)"
                size="medium"
              />
            </div>
            <div class="project-list-grid-body-actions-btn">
              <icon-button
                icon="ri-delete-bin-line"
                btnStyle="tertiary"
                size="medium"
                @click.native.stop="$emit('remove', project.id, project.name.label)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
