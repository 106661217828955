import { appConfig } from '@/utils/constants';

export const getAuthTokenFromStorage = cookie => {
  const cookies = cookie.split(';');
  const authCookie = cookies.find(c => c.includes('baat'));
  return authCookie ? authCookie.split('=')[1] : null;
};

export const getUserIdFromToken = token => {
  const payload = token ? token.split('.')[1] : null;
  return payload ? JSON.parse(atob(payload)).sub : null;
};

export const generateAuthTokenCookie = (token, expiresIn = 0, rememberMe = false) => {
  const cookieSecure = document.location.protocol === 'https:' ? ';secure' : '';
  const cookie = `baat=${token};domain=${appConfig.MainPublicDomain};`;
  // ExpireDate is use when the user check "remember me option" it could be stay connected during 1 month. (more if the token is refreshed)
  const expireDate = new Date(Date.now() + (expiresIn + 3600 * 24 * 31) * 1000).toUTCString();
  if (rememberMe) {
    document.cookie = `baar=1;domain=${appConfig.MainPublicDomain};expires=${expireDate};path=/${cookieSecure}`;
  }
  return rememberMe
    ? `${cookie}expires=${expireDate};path=/${cookieSecure}`
    : `${cookie}path=/${cookieSecure}`;
};

export const setWorkspaceIdInStorage = id => {
  sessionStorage.setItem('CURRENT_WORKSPACE_ID', id);
  localStorage.setItem('LAST_WORKSPACE_ID', id);
};

export const getWorkspaceIdFromStorage = () => sessionStorage.getItem('CURRENT_WORKSPACE_ID');
export const getLastWorkspaceIdFromStorage = () => localStorage.getItem('LAST_WORKSPACE_ID');

export const parseJwt = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
  return JSON.parse(jsonPayload);
};

export const getConnectedAs = () => {
  const cookies = document.cookie.split(';');
  const isConnectedAsCookie = cookies.find(c => c.includes('baca'));
  return isConnectedAsCookie ? parseInt(isConnectedAsCookie.split('=')[1], 10) === 1 : false;
};

export const getFromLocal = key => window.localStorage.getItem(key);

export const setInSession = (key, value) => {
  return window.sessionStorage.setItem(key, JSON.stringify(value));
};

export const getFromSession = key => JSON.parse(window.sessionStorage.getItem(key));

export const removeFromSession = key => window.sessionStorage.removeItem(key);
