import gql from 'graphql-tag';

export const TAG_LIST = gql`
  query TagList($contextName: String!, $contextId: String) {
    TagList(contextName: $contextName, contextId: $contextId) {
      id
      categories {
        id
        name
        background_color
        text_color
        families {
          id
          name
          tags {
            id
            name
          }
        }
      }
    }
  }
`;

export default TAG_LIST;
