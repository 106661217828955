
<div class="thumbnail">
  <img :class="classImg" v-if="thumbnailImg && !multiple" :src="thumbnailImg" alt="thumbnail" />
  <div class="multiple" v-else-if="thumbnailImg && multiple">
    <div class="multiple-bbg" :style="{ backgroundColor: this.rgb }" />
    <div class="multiple-bg" :style="{ backgroundColor: this.rgb }" />
    <img class="multiple-img" :src="thumbnailImg" alt="thumbnail" />
  </div>
  <div class="icon" :class="iconInfo.containerClass" v-else>
    <i :class="iconInfo.iconClass" />
  </div>
</div>
