<template>
  <div class="tracks" v-if="ShareDetails">
    <div class="tracks-tabs">
      <tabs :tabs="tabs" :activeTab="activeTab" />
    </div>
    <router-view :entityIds="entityIds" :shareHash="shareHash"></router-view>
  </div>
</template>

<script>
import get from 'lodash.get';
import { mapGetters } from 'vuex';
import { get as getRoute } from '@/router/routes';
import { SHARE_DETAILS } from '@/graphql/queries/project';
import { generateDocumentTitle } from '@/utils/functions/global';

export default {
  apollo: {
    ShareDetails: {
      query: SHARE_DETAILS,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          shareId: this.$route.params.id
        };
      },
      result(res) {
        this.entityIds = get(res, 'data.ShareDetails.entities.ids', []);
        this.shareHash = get(res, 'data.ShareDetails.share_hash', '');
        this.shareName = get(res, 'data.ShareDetails.share_name', '');
      }
    }
  },
  data() {
    return {
      entityIds: [],
      shareHash: undefined,
      shareName: undefined
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'playlist', 'newUpload']),
    tabs() {
      const { id } = this.$route.params;
      return [
        {
          title: this.$t('tracks'),
          link: getRoute('sharedTracksDetails', { id }),
          name: 'sharedTracksDetails'
        },
        {
          title: this.$t('sharingLinks'),
          link: getRoute('sharedTracksSharings', { id }),
          name: 'sharedTracksSharings'
        },
        {
          title: this.$t('sharingActivity'),
          link: getRoute('sharedTracksActivity', { id }),
          name: 'sharedTracksActivity'
        }
      ];
    },
    activeTab() {
      const active = this.tabs.find(tab => tab.name === this.$route.name);
      if (active.title === 'Sharing links') {
        document.title = generateDocumentTitle([this.shareName, 'Links']);
      } else if (active.title === 'Activity') {
        document.title = generateDocumentTitle([this.shareName, 'Activity']);
      } else {
        document.title = generateDocumentTitle([this.shareName]);
      }
      return this.tabs.indexOf(active);
    }
  }
};
</script>

<style lang="scss" scoped>
.tracks {
  padding: 40px 0 0 0;
  @include body-1;
  margin: 0 0 80px 0;
  &-tabs {
    padding-left: 40px;
    width: calc(100% - 40px);
    position: sticky;
    top: 0;
    background-color: $color_neutral_0;
    z-index: 1;
  }
}
</style>
