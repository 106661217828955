<template>
  <div v-if="TagList" class="tags-panel-body" :key="renderKey">
    <div v-if="!dismissed">
      <panel-information
        :canClose="true"
        :onDismiss="dismissPanelInformation"
        :message="$t('changesForEveryMember')"
      />
    </div>
    <div class="tags-panel-body-dropdown">
      <select-tags
        class="tags-panel-body-dropdown-select"
        :options="TagList.categories"
        :checkedOptions="checkedTags"
        :label="$t('addTags')"
        :placeholder="$t('searchTags')"
        @selectTags="selectTags"
      />
    </div>
    <div
      v-if="!isMultipleTrackEdition && currentWorkspace.properties.auto_tagger && this.isAutoTagged"
      class="tags-panel-body-feedback"
      @click="openFeedbackAI"
    >
      <div class="tags-panel-body-feedback-icon">
        <i class="ri-information-fill" />
      </div>
      <div class="tags-panel-body-feedback-label">Give your feedback on the auto-tagging</div>
    </div>
    <div class="tags-panel-body-categories">
      <div
        class="tags-panel-body-categories-family"
        v-for="(tagCategory, index) in TagList.categories"
        :key="tagCategory.id"
        :style="`z-index: ${TagList.categories.length - index}`"
      >
        <tag-category
          :tagCategory="tagCategory"
          :checkedTags="checkedTags"
          :aiTags="aiTags"
          @selectTags="selectTags"
        />
      </div>
    </div>
    <div class="tags-panel-body-manage">
      <submit-button
        btnStyle="tertiary"
        size="small"
        exception
        :label="$t('manageCustomTags')"
        iconClass="ri-equalizer-fill"
        @submit="manageCustomTags"
      />
    </div>
    <div class="tags-panel-body-remove">
      <submit-button
        v-if="checkedTags.length > 0 || isMultipleTrackEdition"
        btnStyle="secondary"
        size="small"
        :label="$tc('removeTracksTags', tracksToEdit.length)"
        @submit="removeAllTags"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { bus } from '@/utils/bus';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import { TAG_LIST } from '@/graphql/queries/tags';
import { LIBRARY_FILES_DETAILS } from '@/graphql/queries/library';
import { LIBRARY_TRACK_DROP_ALL_TAGS } from '@/graphql/mutations/library';
import { LIBRARY_TRACKS_ADD_TAGS, LIBRARY_TRACKS_REMOVE_TAGS } from '@/graphql/mutations/tags';
import { DONT_SHOW_THIS_MESSAGE_AGAIN } from '@/utils/constants';
import ConfirmDeleteModal from '@/containers/modals/confirmDelete';
import TagManagerModal from '@/containers/modals/tagManager';
import { trackEvent } from '@/utils/functions/analytics';

const KEY_HIDE_TAGS_PANEL_INFORMATION = 'HIDE_TAGS_PANEL_INFORMATION';
export default {
  props: {
    tracksToEdit: {
      type: Array,
      required: false,
      default: () => []
    },
    isMultipleTrackEdition: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      dismissed: localStorage.getItem(KEY_HIDE_TAGS_PANEL_INFORMATION),
      checkedTags: [],
      aiTags: [],
      libraryFileUpdateInput: [],
      libraryFileTagInput: [],
      renderKey: 0,
      pendingStatus: false,
      tagToRemove: [],
      tagsToAdd: []
    };
  },
  apollo: {
    TagList: {
      query: TAG_LIST,
      variables() {
        return {
          contextName: 'library',
          contextId: this.currentWorkspace.libraries[0].id
        };
      }
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'me']),
    formHasChanged() {
      return this.tagsToAdd.length > 0;
    },
    isSaveButtonDisabled() {
      return !this.formHasChanged;
    },
    isAutoTagged() {
      return this.tracksToEdit.some(track => track.tags.some(tag => tag.auto_tagged));
    },
    confirmDeleteModal() {
      let { components } = this.$options;
      components = {
        ...components,
        ConfirmDeleteModal
      };
      return {
        title: this.$tc('removeTracksTags', this.tracksToEdit.length),
        size: 'medium',
        isVisible: false,
        component: components.ConfirmDeleteModal,
        onSubmit: this.handleRemovaAllTagsMutation
      };
    },
    tagManagerModal() {
      let { components } = this.$options;
      components = {
        ...components,
        TagManagerModal
      };
      return {
        title: this.$t('manageCustomTagsTitle'),
        size: 'medium',
        isVisible: true,
        component: components.TagManagerModal
      };
    }
  },
  watch: {
    tracksToEdit() {
      this.initData();
    },
    formHasChanged() {
      this.$emit('update:formHasChangedParent', this.formHasChanged);
    },
    isSaveButtonDisabled() {
      this.$emit('update:isSaveButtonDisabledParent', this.isSaveButtonDisabled);
      // We need to disable the checkbox if some changes are not saved in the edit track panel
      bus.$emit('disableTrackSelection', !this.isSaveButtonDisabled);
    },
    pendingStatus() {
      this.$emit('update:pendingStatusParent', this.pendingStatus);
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.checkedTags = this.isMultipleTrackEdition
        ? []
        : this.tracksToEdit[0].tags.map(tag => `${tag.category_id}-${tag.family_id}-${tag.tag_id}`);
      this.aiTags = this.isMultipleTrackEdition
        ? []
        : this.tracksToEdit[0].tags.map(tag => {
            return tag.auto_tagged ? tag.tag_id : null;
          });
      this.libraryFileUpdateInput = [];
      this.libraryFileTagInput = [];
      this.tagToRemove = [];
      this.tagsToAdd = [];
      if (this.isMultipleTrackEdition) {
        this.renderKey = Math.floor(Math.random() * 100000);
      }
      this.$emit('update:formHasChangedParent', this.formHasChanged);
      this.$emit('update:isSaveButtonDisabledParent', this.isSaveButtonDisabled);
      // We need to disable the checkbox if some changes are not saved in the edit track panel
      bus.$emit('disableTrackSelection', false);
    },
    dismissPanelInformation() {
      localStorage.setItem(KEY_HIDE_TAGS_PANEL_INFORMATION, true);
      this.dismissed = true;
    },
    selectTags(tagId, add) {
      this.libraryFileTagInput = [];
      this.TagList.categories.forEach(category => {
        category.families.forEach(family => {
          family.tags.forEach(tag => {
            if (tagId === `${category.id}-${family.id}-${tag.id}`) {
              const tagInfo = {
                tag_context_id: this.currentWorkspace.libraries[0].id,
                category_id: category.id,
                family_id: family.id,
                tag_id: tag.id
              };
              if (add) {
                this.tracksToEdit.forEach(track => {
                  this.tagsToAdd.push({
                    ...tagInfo,
                    track_id: this.isMultipleTrackEdition ? track : track.id
                  });
                });
              } else if (this.isMultipleTrackEdition) {
                // when multi edit, remove from tagsToAdd deleted tags
                this.tagsToAdd = [...this.tagsToAdd].filter(obj => obj.tag_id !== tag.id);
              } else {
                this.tagToRemove.push({
                  id: this.tracksToEdit[0].id,
                  tags_ids: this.tracksToEdit[0].tags.find(trackTag => tag.id === trackTag.tag_id)
                    .id
                });
              }
            }
          });
        });
      });
      if (!this.isMultipleTrackEdition) this.autoSave();
    },
    autoSave() {
      if (!this.isMultipleTrackEdition) {
        this.handleTagUpdate();
        trackEvent('Track Edit AutoSave', {
          category: 'metadata',
          field_edit: 'tags',
          track_id: this.tracksToEdit[0].id
        });
      }
    },
    handleClickSaveButton() {
      if (this.isMultipleTrackEdition) {
        if (localStorage.getItem(DONT_SHOW_THIS_MESSAGE_AGAIN) === 'true') {
          this.handleTagUpdate();
        } else {
          setTimeout(() => {
            this.$emit('showModalForMultiTags');
          }, 0);
        }
      } else {
        this.handleTagUpdate();
      }
    },
    handleTagUpdate() {
      const libraryFilesIds = this.isMultipleTrackEdition
        ? this.tracksToEdit
        : this.tracksToEdit.map(track => track.id);
      if (this.tagsToAdd.length > 0) {
        this.pendingStatus = true;
        this.$apollo
          .mutate({
            mutation: LIBRARY_TRACKS_ADD_TAGS,
            variables: {
              libraryId: this.currentWorkspace.libraries[0].id,
              tags: this.tagsToAdd
            },
            refetchQueries: [
              {
                query: LIBRARY_FILES_DETAILS,
                variables: {
                  libraryFilesIds
                }
              }
            ]
          })
          .then(() => {
            this.handleUpdateTagSuccess();
          })
          .catch(err => {
            this.handleUpdateTagError(err);
          });
      }
      if (this.tagToRemove.length > 0) {
        this.pendingStatus = true;
        this.$apollo
          .mutate({
            mutation: LIBRARY_TRACKS_REMOVE_TAGS,
            variables: {
              input: this.tagToRemove
            },
            refetchQueries: [
              {
                query: LIBRARY_FILES_DETAILS,
                variables: {
                  libraryFilesIds
                }
              }
            ]
          })
          .then(() => {
            this.handleUpdateTagSuccess();
          })
          .catch(err => {
            this.handleUpdateTagError(err);
          });
      }
    },
    handleUpdateTagSuccess() {
      this.tagsToAdd = [];
      this.pendingStatus = false;
      if (this.isMultipleTrackEdition) {
        bus.$emit('showAlert', {
          message: {
            key: 'modificationSuccess'
          },
          style: 'success',
          delay: 5000
        });
      }
      this.$emit('autoSaveSuccess', true);
      // Some component are listening to this event to display the save changes modal if the user didn't save his changes in the edit track panel
      bus.$emit('handleTracksEditMutationDone');
    },
    handleUpdateTagError(err) {
      this.tagsToAdd = [];
      this.pendingStatus = false;
      const key = getGraphQlErrorCode(err);
      this.$emit('autoSaveSuccess', false);
      if (this.isMultipleTrackEdition) {
        bus.$emit('showAlert', {
          message: { key },
          style: 'danger',
          delay: 5000,
          error: true
        });
      }
      this.$emit('autoSaveSuccess', false);
      bus.$emit('handleTracksEditMutationDone');
    },
    removeAllTags() {
      bus.$emit('displayModal', { ...this.confirmDeleteModal, isVisible: true });
    },
    handleRemovaAllTagsMutation() {
      const libraryFilesIds = this.isMultipleTrackEdition
        ? this.tracksToEdit
        : this.tracksToEdit.map(track => track.id);
      this.$apollo
        .mutate({
          mutation: LIBRARY_TRACK_DROP_ALL_TAGS,
          variables: {
            libraryFilesIds
          },
          update: () => {
            this.tracksToEdit.map(track => ({
              ...track,
              tags: []
            }));
            this.initData();
          },
          refetchQueries: [
            {
              query: LIBRARY_FILES_DETAILS,
              variables: {
                libraryFilesIds
              }
            }
          ]
        })
        .then(() => {
          bus.$emit('showAlert', {
            message: { key: 'removeTagsSuccess' },
            style: 'success',
            delay: 5000
          });
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    manageCustomTags() {
      bus.$emit('displayModal', { ...this.tagManagerModal });
    },
    openFeedbackAI() {
      window.open(
        `https://bridgeaudio.typeform.com/to/FjRPREE8#uid=${this.me.id}&wid=${this.currentWorkspace.id}&tid=${this.tracksToEdit[0].id}&name=${this.me.firstname}-${this.me.lastname}&email=${this.me.email}&tname=${this.tracksToEdit[0].title}`,
        '_blank'
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.tags-panel-body {
  &-dropdown {
    position: relative;
    z-index: 30;
    margin: 24px 0 0;
  }

  &-feedback {
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    background: $color_information_10;
    border-radius: 4px;
    margin: 16px 0;
    padding: 0 16px;
    color: $color_information_100;
    cursor: pointer;
    &-icon {
      height: 24px;
      width: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #d3e3fb;
      margin-right: 8px;
    }
    &-label {
      text-decoration: underline;
    }
  }

  &-alltags {
    margin: 24px 0 0;

    &-title {
      @include subtitle-1;
      margin: 0 0 16px 0;
    }

    &-label {
      background: $color_neutral_10;
      border: 1px solid $color_neutral_40;
      box-sizing: border-box;
      border-radius: 2px;
      display: inline-block;
      padding: 0 8px;
      color: $color_neutral_60;
    }
  }

  &-categories {
    margin: 24px 0 0 0;

    &-family {
      display: flex;
      position: relative;

      &-tags {
        margin: -6px 0 0 0;
      }
    }
  }

  &-manage {
    margin: 16px 0 0 0;
  }

  &-remove {
    margin: 24px 0 196px 0;
  }
}
</style>

<style lang="scss">
.tags-panel-body-dropdown {
  .tags-panel-body-dropdown-select {
    .selectbox {
      max-width: unset;
    }
  }
}
</style>
