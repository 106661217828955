<template>
  <div class="confirm-delete-modal">
    <div v-if="tracksName" class="confirm-delete-modal-body">
      {{ $t(msg, { trackName: tracksName }) }}
    </div>
    <div v-else class="confirm-delete-modal-body">{{ $t(msg) }}</div>
    <div class="confirm-delete-modal-buttons">
      <div class="confirm-delete-modal-buttons--">
        <submit-button btnStyle="tertiary" :label="$t('cancel')" @submit="closeModal" />
      </div>
      <div
        v-if="msg === 'confirmDeleteHard' || msg === 'confirmDeleteFile'"
        class="confirm-delete-modal-buttons--"
      >
        <submit-button btnStyle="secondary" danger :label="$t('delete')" @submit="confirmDelete" />
      </div>
      <div v-else class="confirm-delete-modal-buttons--">
        <submit-button :label="$t('remove')" @submit="confirmDelete" />
      </div>
    </div>
  </div>
</template>

<script>
import { trackEvent } from '@/utils/functions/analytics';

export default {
  props: {
    onSubmit: {
      type: Function,
      required: false,
      default: () => {}
    },
    msg: {
      type: String,
      required: false,
      default: 'confirmDelete'
    },
    tracksName: {
      type: String,
      required: false,
      default: null
    }
  },
  created() {
    window.addEventListener('keypress', this.handleKeyUp);
  },
  destroyed() {
    window.removeEventListener('keypress', this.handleKeyUp);
  },
  methods: {
    handleKeyUp(event) {
      if (event.keyCode === 13) {
        this.confirmDelete();
      }
    },
    closeModal() {
      this.$emit('closeModal');
    },
    confirmDelete() {
      this.onSubmit();
      this.closeModal();
      trackEvent('Confirm remove item');
    }
  }
};
</script>

<style lang="scss" scoped>
.confirm-delete-modal {
  &-body {
    padding: 32px;
    line-height: 150%;
  }
  &-buttons {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $color_neutral_30;
    padding: 8px 32px;

    &-- {
      &:first-child {
        margin: 0 16px 0 0;
      }
    }
  }
}
</style>
