
<div class="toggle-btn">
  <div
    class="toggle-btn-item"
    v-for="(btn, index) in buttons"
    :key="`togbtn-${index}`"
    @click="toggleButtons(index)"
  >
    <div
      class="toggle-btn-item-text"
      :class="
        index === selectedButtonIndex ? 'toggle-btn-item-enabled' : 'toggle-btn-item-disabled'
      "
    >
      <div class="title">
        <span class="title-value">{{ btn.value }}</span>
        <span class="title-type">{{ btn.type }}</span>
      </div>
      <div class="description">{{ btn.description }}</div>
    </div>
  </div>
</div>
