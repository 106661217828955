import gql from 'graphql-tag';

export const TAG_CREATE_CUSTOM = gql`
  mutation TagCreateCustom($contextName: String!, $contextId: String!, $tagName: String!) {
    TagCreateCustom(contextName: $contextName, contextId: $contextId, tagName: $tagName)
  }
`;

export const TAG_UPDATE = gql`
  mutation TagUpdate(
    $contextName: String!
    $contextId: String!
    $categoryId: ID!
    $familyId: ID!
    $tagId: ID!
    $tagNewName: String!
  ) {
    TagUpdate(
      contextName: $contextName
      contextId: $contextId
      categoryId: $categoryId
      familyId: $familyId
      tagId: $tagId
      tagNewName: $tagNewName
    )
  }
`;

export const TAG_DELETE_CUSTOM = gql`
  mutation TagDeleteCustom($contextName: String!, $contextId: String!, $tagId: ID!) {
    TagDeleteCustom(contextName: $contextName, contextId: $contextId, tagId: $tagId)
  }
`;

export const LIBRARY_TRACKS_ADD_TAGS = gql`
  mutation LibraryTracksAddTags($libraryId: ID!, $tags: [TrackTagInput]) {
    LibraryTracksAddTags(libraryId: $libraryId, tags: $tags) {
      id
    }
  }
`;

export const LIBRARY_TRACKS_REMOVE_TAGS = gql`
  mutation LibraryTracksRemoveTags($input: [LibraryFilesRemoveTracksInput]) {
    LibraryTracksRemoveTags(input: $input)
  }
`;
