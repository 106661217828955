<template>
  <div :class="withBackground && 'with-background'" class="checklist">
    <div v-for="(item, index) in checklist" :key="`item-${index}`">
      <div class="checklist-item">
        <i :class="item.icon" />
        <span class="checklist-item-label" :class="item.disable && 'disable'">{{
          item.label
        }}</span>
        <badge v-if="item.badge" :label="item.badge" badgeStyle="secondary" />
      </div>
    </div>
  </div>
</template>

<script>
import Badge from '../badge';

export default {
  components: {
    Badge
  },
  props: {
    checklist: {
      type: Array,
      required: true
    },
    withBackground: {
      type: Boolean,
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
.checklist {
  @include body-1;

  &-item {
    color: $color_neutral_100;
    display: flex;
    align-items: center;
    margin: 0 0 4px 0;

    &-label {
      margin: 0 0 0 8px;
    }

    .disable {
      color: $color_neutral_60;
    }

    .ri-check-fill {
      color: $color_primary_100;
      font-size: 22px;
    }

    .ri-close-line {
      color: $color_neutral_60;
      font-size: 22px;
    }
  }
}

.with-background {
  .checklist-item {
    color: $color_neutral_0;

    .disable {
      color: $color_neutral_0;
      opacity: 0.5;
    }

    .ri-check-fill {
      color: $color_neutral_0;
    }

    .ri-close-line {
      color: $color_neutral_60;
      opacity: 0.5;
    }
  }
}
</style>
