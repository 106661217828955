
<div class="projectList">
  <div class="projectList-search">
    <search-bar
      key="search-bar-move-to"
      icon="ri-search-line"
      :fieldModel.sync="searchValue"
      :placeholder="$t('searchPlaceholder')"
    />
  </div>
  <div class="projectList-body" ref="projectListContainerScroll">
    <!-- LOADING -->
    <div v-if="loadingProjects" class="projectList-loader">
      <spinner-without-progress color="grey" />
    </div>
    <div v-else class="projectList-container">
      <!-- SEARCH NO RESULTS -->
      <div
        v-if="projects.length === 0 && noSearchResult && !displayNewProjectInput"
        class="projectList-centered"
      >
        No results
      </div>

      <!-- NO PROJECTS & NO SEARCH -->
      <div
        v-if="projects.length === 0 && !noSearchResult && !displayNewProjectInput"
        class="projectList-centered"
      >
        <div class="projectList-emptyLabel">{{ emptyPlaceholder.text }}</div>
        <submit-button
          size="small"
          :label="emptyPlaceholder.buttonLabel"
          :iconClass="emptyPlaceholder.buttonIcon"
          @submit="$emit('update:displayNewProjectInput', true)"
        />
      </div>
      <!-- LISTE PROJECT -->
      <div v-if="projects.length > 0 || displayNewProjectInput">
        <div
          v-if="displayProjectRequiredError"
          class="banner error"
          :class="displayProjectNameError ? 'banner-margin' : ''"
        >
          <div class="banner-icon error-icon"><i class="ri-alert-fill" /></div>
          <div>{{ $t('uploadToModal.projectRequired') }}</div>
        </div>
        <div v-if="displayProjectNameError" class="banner error">
          <div class="banner-icon error-icon"><i class="ri-alert-fill" /></div>
          <div>{{ displayProjectNameError }}</div>
        </div>
        <div v-if="displayNewProjectInput" class="projectItem new-project-input">
          <div class="projectItem-icon-container">
            <i class="ri-folder-fill projectItem-icon" />
          </div>
          <text-field
            inputType="text"
            :selectOnLoad="true"
            :fieldModel.sync="newProjectName"
            @handleBlur="handleBlur"
            @enterPress="handleBlur"
          />
        </div>
        <div
          v-for="project in projects"
          :key="project.id"
          class="projectItem"
          :class="{
            'projectItem-selected': selectedProjectId === project.id,
            'projectItem-disabled': isCurrentProject(project.id)
          }"
          v-tooltip="{
            content: isCurrentProject(project.id) && 'You cannot move file to current project'
          }"
          @click.stop="handleProjectItemClick(project.id, project.label)"
        >
          <div class="projectItem-icon-container">
            <i class="ri-folder-fill projectItem-icon" />
          </div>
          <div class="projectItem-label">{{ project.label }}</div>
          <div v-if="selectedProjectId === project.id" class="projectItem-check">
            <i class="ri-check-line" />
          </div>
        </div>
        <div v-if="fetchingMoreProjects" class="loader">
          <skeleton />
        </div>
        <observer v-if="showObserver" :height="32" bottom @intersect="loadMore" />
      </div>
    </div>
  </div>
</div>
