<template>
  <div class="app">
    <div v-if="isOffline" class="app-unique-alert app-unique-alert-offline">
      <alert :message="$t('offlineAlert')" alertStyle="danger" :hideDismissible="true" />
    </div>
    <div v-if="hasNewVersionAvailable" class="app-unique-alert app-unique-alert-update">
      <alert
        :message="$t('newVersionAlertMessage')"
        alertStyle="warning"
        :hideDismissible="true"
        :actionLabel="$t('Refresh')"
        @handleAlertClick="refresh"
      />
    </div>
    <div v-if="displayMobileWarning" class="app-mobile">
      <div class="app-mobile-container">
        <div class="backToWebsite">
          <submit-button
            btnStyle="tertiary"
            size="medium"
            iconClass="ri-arrow-left-line"
            label="Back to website"
            @submit="goToWebsite"
          />
        </div>
        <div class="app-mobile-content">
          <div class="image-container">
            <img src="/images/illu-mobile_wip.svg" alt="wip picture" />
          </div>
          <h1 class="title">{{ $t('mobile.title') }}</h1>
          <div class="main">
            {{ $t('mobile.subtitle') }}
            <a
              href="https://www.notion.so/bridgeaudio/How-to-access-my-Bridge-workspace-on-mobile-2dfc4ab525fb49b7bfabc8a6ce8684c4"
              target="_blank"
              rel="noopener"
              >{{ $t('mobile.link1') }}
            </a>
          </div>
          <div class="main">
            {{ $t('mobile.core1') }}
            <a
              href="https://www.notion.so/bridgeaudio/Mobile-use-9c273491696048299b499da8cdc3c48b?pvs=4"
              target="_blank"
              rel="noopener"
              >{{ $t('mobile.link2') }}</a
            >
            {{ $t('mobile.core2') }}
          </div>
        </div>
        <div class="cta">
          <submit-button
            btnStyle="primary"
            class="cta-open"
            size="large"
            label="Open Bridge.audio anyway"
            @submit="hideMobileWarning"
          />
        </div>
        <div class="help-button">
          <a
            href="https://www.notion.so/bridgeaudio/Bridge-audio-Help-Center-0db362d99c4746c8bf6ebd8a08e3b549"
            target="_blank"
            rel="noopener"
          >
            <img src="/images/external-link-line.svg" alt="" />
            <span class="bold">{{ $t('mobile.link3') }}</span>
          </a>
        </div>
      </div>
    </div>
    <div v-if="alert.isVisible" class="app-alert">
      <div
        v-if="isBrowserSupported && displayAlertConfetti"
        ref="confettiAnimation"
        class="confetti-container"
      />
      <alert
        v-if="alert.isVisible"
        :message="alert.message"
        :alertStyle="alert.style"
        :isVisible="alert.isVisible"
        :actionLabel="alert.actionLabel"
        :hideDismissible="alert.hideDismissible"
        :actionCountdown="alert.actionCountdown"
        @handleAlertClick="alert.handleAction"
        @closeAlert="closeAlert"
      />
    </div>
    <div class="app-modal" :class="modalClass">
      <modal
        class="app-modal--"
        :isVisible="modal.isVisible"
        :title="modal.title"
        :size="modal.size"
        :isFixed="modal.isFixed ? modal.isFixed : false"
        :hideHeader="modal.hideHeader"
        @close="closeModalOutsideBody"
      >
        <template v-slot:body>
          <component
            ref="modalBody"
            :is="modal.component"
            :onSubmit="modal.onSubmit"
            :onCancel="modal.onCancel"
            :msg="modal.msg"
            v-bind="modalProps"
            @closeModal="closeModal"
          />
        </template>
      </modal>
    </div>
    <div class="app-bottom-alert" v-if="displayWorkspaceLockedAlert">
      <bottom-alert @closeAlert="closeBottomAlert" @handleActionClick="goToUpgrade" />
    </div>
    <router-view
      v-show="!displayMobileWarning"
      class="app-content"
      :isBrowserSupported="isBrowserSupported"
    ></router-view>
  </div>
</template>
<script>
import lottie from 'lottie-web';
import { mapGetters } from 'vuex';
import { bus } from '@/utils/bus';

import { get as getRoute } from '@/router/routes';

import BottomAlert from '@/containers/bottomAlert';
import { browsersRegex } from 'virtual:supported-browsers';
import { appConfig } from '../utils/constants';

export default {
  components: {
    BottomAlert
  },
  data() {
    return {
      hasNewVersionAvailable: false,
      applicationVersionCheckTimer: undefined,
      alert: {
        message: '',
        style: '',
        delay: 0,
        isVisible: false,
        hideDismissible: false,
        actionLabel: null,
        handleAction: () => {}
      },
      modal: {
        title: null,
        size: null,
        component: null,
        isVisible: false,
        onSubmit: null,
        onCancel: null
      },
      canDisplayMobileWarning: false,
      isBrowserSupported: true,
      displayAlertConfetti: false,
      timerAlert: undefined,
      timerConfetti: undefined,
      hideModal: false,
      isOffline: false
    };
  },
  computed: {
    ...mapGetters(['displayWorkspaceLockedAlert']),
    modalProps() {
      if (!this.modal.props) return null;
      const p = {};
      this.modal.props.forEach(prop => {
        p[prop.name] = prop.value;
      });
      return p;
    },
    displayMobileWarning() {
      return !this.$route.path.includes('/workspace/') && this.canDisplayMobileWarning;
    },
    modalClass() {
      let str = '';
      if (this.hideModal) {
        str += 'hidden';
      }
      return str;
    }
  },
  created() {
    if (browsersRegex.test(navigator.userAgent)) {
      this.isBrowserSupported = true;
    } else {
      this.isBrowserSupported = false;
    }

    if (window.innerWidth <= 767) {
      this.canDisplayMobileWarning = true;
    }
    // Sort of a daemon that check config version
    this.applicationVersionCheckTimer = setInterval(() => {
      this.checkApplicationVersion();
    }, 15 * 60 * 1000); // Every 15 minutes

    bus.$on('showAlert', alert => {
      clearTimeout(this.timerConfetti);
      this.displayAlertConfetti = false;
      clearTimeout(this.timerAlert);
      const {
        message,
        style,
        delay,
        error,
        actionLabel,
        handleAction,
        hideDismissible,
        actionCountdown,
        confetti
      } = alert;
      this.alert.isVisible = true;
      let msg = message.key;
      this.displayAlertConfetti = confetti;
      if (error) {
        msg = message.key ? `errors.${message.key}` : `errors.${message.errorKey}`;
        if (!this.$te(`errors.${message.key}`) && !this.$te(`errors.${message.errorKey}`)) {
          msg = 'error';
        }
      }
      if (message.params && message.args) {
        this.alert.message = this.$tc(msg, message.params, message.args);
      }
      if (message.params && !message.args) {
        this.alert.message = this.$tc(msg, message.params);
      }
      if (message.args && !message.params) {
        this.alert.message = this.$t(msg, message.args);
      }
      if (!message.params && !message.args) {
        this.alert.message = this.$t(msg);
      }
      this.alert.hideDismissible = hideDismissible === true;
      this.alert.actionCountdown = actionCountdown;
      this.alert.handleAction = handleAction || (() => {});
      this.alert.actionLabel = actionLabel;
      this.alert.style = style;
      // wait for element to exist before loading animation on it
      if (confetti && this.isBrowserSupported) {
        this.$nextTick(() => {
          const container = this.$refs.confettiAnimation;
          lottie.loadAnimation({
            container,
            renderer: 'svg',
            autoplay: true,
            path: '/animation/confetti.json'
          });
        });
        this.timerConfetti = setTimeout(() => {
          this.displayAlertConfetti = false;
        }, 1500);
      }
      this.timerAlert = setTimeout(() => {
        this.alert.isVisible = false;
      }, delay);
    });
    bus.$on('closeAlert', () => {
      this.closeAlert();
    });
    bus.$on('displayModal', modal => {
      this.hideModal = false;
      this.modal = modal;
    });
    bus.$on('closeModal', () => {
      this.closeModal();
    });
    bus.$on('toggleHideModal', bool => {
      this.hideModal = bool;
    });
    bus.$on('closeHidenModal', () => {
      if (this.hideModal) {
        this.closeModal();
      }
    });
    window.addEventListener('offline', this.handleOffline);
    window.addEventListener('online', this.handleOnline);
  },
  beforeDestroy() {
    window.removeEventListener('offline', this.handleOffline);
    window.removeEventListener('online', this.handleOnline);
  },
  methods: {
    handleOffline() {
      this.isOffline = true;
    },
    handleOnline() {
      this.isOffline = false;
    },
    refresh() {
      document.location.reload();
    },
    checkApplicationVersion() {
      fetch('/config.js')
        .then(res => res.text())
        /* eslint-disable */
        /* it will just parse config no very big deal here a json file would be better but maybe later */
        .then(txt => eval(txt))
        /* eslint-enable */
        .then(newConfig => {
          if (newConfig.version.hash !== appConfig.version.hash) {
            this.hasNewVersionAvailable = true;
            clearTimeout(this.applicationVersionCheckTimer);
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    },
    closeAlert() {
      this.alert.isVisible = false;
    },
    closeModalOutsideBody() {
      if (this.$refs.modalBody && this.$refs.modalBody.triggerOnClose) {
        this.$refs.modalBody.triggerOnClose();
      }
      this.closeModal();
    },
    closeModal() {
      this.modal.isVisible = false;
    },
    closeBottomAlert() {
      this.$store.commit('setDisplayWorkspaceLockedAlert', false);
    },
    hideMobileWarning() {
      this.canDisplayMobileWarning = false;
    },
    goToWebsite() {
      window.location.replace('https://www.bridge.audio/');
    },
    goToUpgrade() {
      this.closeModal();
      this.$router.push(getRoute('offersSettings'));
      this.closeBottomAlert();
    }
  },
  watch: {
    $route() {
      this.closeModal();
    }
  }
};
</script>

<style lang="scss" scoped>
.app {
  height: 100vh;
  &-unique-alert {
    position: fixed;
    z-index: map-get($z-index, alert);
    display: flex;
    align-items: center;
    flex-direction: column;
    width: fit-content;
    margin-left: calc(50% + 90px);
    transform: translateX(-50%);
    &-update {
      top: 16px;
    }
    &-offline {
      top: 64px;
    }
  }
  &-alert {
    position: fixed;
    z-index: map-get($z-index, alert);
    top: 72px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: fit-content;
    margin-left: calc(50% + 90px);
    transform: translateX(-50%);
  }
  &-bottom-alert {
    position: fixed;
    z-index: map-get($z-index, alert);
    bottom: 72px;
    left: 187px;
  }
  &-mobile {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: map-get($z-index, warningMobile);
    &-container {
      height: 100vh;
      box-sizing: border-box;
      padding: 24px 16px 40px 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: scroll;
    }
    &-content {
      flex-grow: 1;
      .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
      }
      .title {
        @include heading-5;
        text-align: left;
        margin-bottom: 24px;
        line-height: 150%;
      }
      .main {
        @include body-1;
        margin-bottom: 24px;
      }
    }
    .cta {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      &-open {
        background: $color_primary_100;
      }
    }
    .help-button {
      width: 100%;
      height: 48px;
      font-size: 16px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-top: 20px;
    }
    .bold {
      font-weight: 600;
      padding-left: 6.5px;
    }
    .backToWebsite {
      flex-grow: 1;
      align-self: start;
    }
  }
}
.hidden {
  display: none;
}
.confetti-container {
  position: absolute;
  top: -132px;
  z-index: -1;
  width: 300px;
  background-color: rgba(0, 0, 0, 0);
}
</style>

<style lang="scss">
.app-modal {
  .app-modal--.modal {
    z-index: map-get($z-index, modal);
  }
}
.cta {
  .button {
    width: 100%;
  }
}
</style>
