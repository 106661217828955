
<div class="avatar" :class="pictureSize">
  <div class="avatar-hover">
    <div v-if="withBrowsePicture" :class="hoverStyle">
      <i class="ri-camera-fill avatar-hover-icon" :class="pictureSize" />
    </div>
  </div>
  <div v-if="pictureUrl" :class="classHover" data-test="avatarImage">
    <img class="avatar-img" :alt="`${initial}'s avatar`" :class="avatarStyle" :src="pictureUrl" />
  </div>
  <div v-if="!pictureUrl" class="avatar-initial" :class="initialStyle" data-test="avatarInitial">
    {{ initial }}
  </div>
</div>
