<template>
  <div>
    <header-list
      :columns="columns"
      :sortColumn="false"
      isSticky
      :stickyPosition="0"
      :hasSelectAll="hasSelectAll"
      :itemsSelected="[]"
      :displayUnselect="selectedTracks.length > 0"
      @handleEmit="handleEmit"
      @uncheckAllCheckbox="uncheckAllCheckbox"
      @selectAll="checkAllCheckbox"
    />
    <draggable
      v-model="editableTrackList"
      class="draggable"
      @start="startDrag"
      @end="endDrag"
      @update="updateDrag"
    >
      <div v-for="(track, idx) in editableTrackList" :key="track.id">
        <list-row
          :item="track"
          :columns="columns"
          :rowIndex="idx"
          :height="48"
          :id="track.id"
          :playingTrack="playingTrack || {}"
          :selectedItems="selectedTracks"
          @handleEmit="handleEmit"
        />
      </div>
    </draggable>
    <div v-if="isFetchingMore">
      <skeleton-row :columns="columns" :height="48" />
    </div>
    <div
      v-if="selectedTracks.length"
      class="action-bar-container"
      :class="{ 'panel-open': isEditPanelOpened }"
    >
      <action-bar
        :numberOfSelection="headerCountLabel"
        :actions="actionBarBtns"
        :label="actionBarLabels"
        @selectAll="checkAllCheckbox"
        @unselect="uncheckAllCheckbox"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';

import { get as getRoute } from '@/router/routes';
import { bus } from '@/utils/bus';
import { trackEvent } from '@/utils/functions/analytics';
import { formatNumber, getGraphQlErrorCode } from '@/utils/functions/global';
import { PROJECT_MOVE_TRACK } from '@/graphql/mutations/project';
import { PROJECT_DETAILS_TRACKS } from '@/graphql/queries/project';
import MoveToAddTo from '@/containers/modals/moveToAddTo';

export default {
  components: {
    draggable
  },
  props: {
    trackList: {
      type: Array,
      required: false,
      default: () => []
    },
    uncheckTracks: {
      type: Boolean,
      required: false,
      default: false
    },
    hasSelectAll: {
      type: Boolean,
      required: false,
      default: false
    },
    isFetchingMore: {
      type: Boolean,
      required: false,
      default: false
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      selectedTracks: [],
      isHoverId: null,
      randomKey: 0,
      isLoadingId: null,
      actionBarLabels: {
        selectAll: this.$t('selectAll'),
        unselect: this.$t('unselect')
      },
      editableTrackList: [...this.trackList],
      listActions: [
        {
          tooltip: this.$t('more'),
          options: [
            {
              icon: 'ri-information-line',
              name: this.$t('editInformation'),
              keepExpand: false,
              action: id => {
                this.setSelectedItems({
                  elementUID: id,
                  openEditPanel: true,
                  informationBtn: true
                });
              }
            },
            {
              icon: 'ri-repeat-line',
              name: this.$t('replaceAudioFile'),
              keepExpand: false,
              action: id => {
                this.handleReplaceFile(id);
              }
            },
            {
              icon: 'ri-folder-transfer-line',
              name: this.$t('addTo'),
              keepExpand: false,
              action: id => {
                this.setSelectedItems({ elementUID: id });
                this.handleAddToClick([id]);
              }
            },
            {
              icon: 'ri-download-line',
              name: this.$t('download'),
              keepExpand: false,
              action: id => {
                this.downloadTrack(id);
              }
            },
            {
              separator: true
            },
            {
              icon: 'ri-indeterminate-circle-line',
              name: this.$t('remove'),
              keepExpand: false,
              action: itemId => {
                const { deleteId } = this.trackList.find(item => item.id === itemId);
                this.removeTracks(deleteId);
              }
            }
          ]
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['playlist', 'playingTrack', 'currentWorkspace', 'isEditPanelOpened']),
    columns() {
      return [
        {
          type: 'component',
          name: 'checkbox',
          path: 'checkbox',
          ref: 'checkbox',
          preventDefault: true,
          header: null,
          min: '28px',
          max: '28px',
          noBorder: true,
          isHover: true,
          span: 1,
          events: ['toggleCheckbox', 'handleCheckboxShiftClick'],
          props: [
            {
              name: 'id',
              match: 'id',
              value: null
            },
            {
              name: 'index',
              match: 'index',
              value: null
            },
            {
              name: 'selectedItems',
              match: 'selectedTracks',
              value: this.selectedTracks
            }
          ]
        },
        {
          type: 'component',
          name: 'listKnob',
          path: 'listKnob',
          header: '#',
          min: '32px',
          max: '32px',
          centered: true,
          span: 1,
          props: [
            {
              name: 'order',
              match: 'position',
              value: null
            },
            {
              name: 'trackId',
              match: 'id',
              value: null
            },
            {
              name: 'isHoverId',
              match: 'isHoverId',
              value: this.isHoverId
            },
            {
              name: 'isLoadingId',
              match: 'isLoadingId',
              value: this.isLoadingId
            }
          ]
        },
        {
          type: 'component',
          name: 'text-action',
          header: this.$t('track'),
          skeleton: 'line',
          min: '200px',
          max: '2fr',
          padding: [0, 8, 0, 8],
          noStop: true,
          containerWidth: true,
          emits: ['setPlayingTrack'],
          props: [
            {
              name: 'rowId',
              match: 'id',
              value: null
            },
            {
              name: 'isHoverId',
              match: 'isHoverId',
              value: this.isHoverId
            },
            {
              name: 'text',
              match: 'title',
              value: null
            },
            {
              name: 'actions',
              match: 'actions',
              value: this.listActions
            },
            {
              name: 'artwork',
              match: 'imgSrc',
              value: null
            },
            {
              name: 'playingTrack',
              match: 'playingTrack',
              value: this.playingTrack || {}
            },
            {
              name: 'weightNormal',
              match: 'weightNormal',
              value: true
            }
          ]
        },
        {
          type: 'text',
          name: 'artists',
          header: this.$t('artist'),
          skeleton: 'line',
          min: '100px',
          max: '1fr',
          list: true,
          route: { name: 'artist', id: 'id' },
          span: 1,
          padding: [0, 8, 0, 8]
        },
        {
          type: 'text',
          name: 'supports',
          header: this.$t('album'),
          skeleton: 'line',
          min: '100px',
          max: '1fr',
          list: true,
          route: { name: 'album', id: 'id' },
          span: 1,
          padding: [0, 8, 0, 8]
        },
        {
          type: 'text',
          name: 'humanDuration',
          noTitle: true,
          headerPrefixIcon: 'ri-time-line',
          skeleton: 'line',
          span: 1,
          min: '80px',
          max: '80px',
          padding: [0, 8, 0, 8]
        },
        {
          type: 'component',
          name: 'info-date-user',
          header: this.$t('added'),
          min: '140px',
          max: '140px',
          padding: [0, 0, 0, 8],
          props: [
            {
              name: 'date',
              match: 'createdAt',
              value: null
            },
            {
              name: 'user',
              match: 'createdBy',
              value: null
            },
            {
              name: 'simple',
              match: 'simple',
              value: true
            }
          ]
        }
      ];
    },
    headerCountLabel() {
      const formatedNumber = formatNumber(this.selectedTracks.length);
      return `${formatedNumber} ${this.$tc('trackSelected', this.selectedTracks.length)}`;
    },
    actionBarBtns() {
      return [
        {
          icon: 'ri-information-line',
          tooltip: this.$t('editInformation'),
          method: () => {
            if (this.$isWorkspaceLockedVerification(this)) return;
            if (this.editMode) {
              this.$emit('toggleEditMode', []);
              return;
            }
            this.$emit('toggleEditMode', this.selectedTracks);
          }
        },
        {
          icon: 'ri-download-line',
          tooltip: this.$t('download'),
          method: () => {
            bus.$emit('downloadTrack', this.selectedTracks);
          }
        },
        {
          icon: 'ri-folder-transfer-line',
          tooltip: this.$t('addTo'),
          method: () => {
            this.handleAddToClick(null, true);
          }
        },
        {
          icon: 'ri-indeterminate-circle-line',
          tooltip: this.$t('removeFromProject'),
          method: () => {
            this.removeTracks();
          }
        }
      ];
    }
  },
  mounted() {
    this.$root.$on('goToNextPreviousMeta', params => {
      this.setSelectedItems(params);
    });
  },
  created() {
    bus.$on('setNewProjectId', newProjectId => {
      this.newProjectId = newProjectId;
    });
    if (Object.keys(this.playlist).length === 0 && this.trackList.length > 0) {
      this.$store.commit('changePlaylist', this.trackList);
    }
  },
  beforeDestroy() {
    bus.$off('disableTrackSelection');
    this.$root.$off('goToNextPreviousMeta');
  },
  watch: {
    trackList() {
      this.randomKey = Math.floor(Math.random() * 1000000000);
      this.isLoadingId = null;
      this.editableTrackList = [...this.trackList];
    },
    uncheckTracks() {
      if (this.uncheckTracks) this.uncheckAllCheckbox();
    }
  },
  methods: {
    handleEmit(params) {
      if (this[params.methodName]) this[params.methodName](params.param);
    },
    startDrag() {
      this.$store.commit('changeIsDraggingItemList', true);
    },
    endDrag() {
      this.$store.commit('changeIsDraggingItemList', false);
    },
    updateDrag(e) {
      this.isLoadingId = e.fromRemove ? null : this.editableTrackList[e.newIndex].id;
      const position = e.fromRemove ? 1 : e.newIndex + 1;
      this.$apollo
        .mutate({
          mutation: PROJECT_MOVE_TRACK,
          variables: {
            projectId: this.$route.params.id,
            libraryFileId: e.fromRemove
              ? e.list[0].libraryFile.id
              : this.editableTrackList[e.newIndex].id,
            position
          },
          update: store => {
            const idInOrder = this.editableTrackList.map(el => el.deleteId);
            const stored = store.readQuery({
              query: PROJECT_DETAILS_TRACKS,
              variables: { projectId: this.$route.params.id }
            });
            const newProjectDetailsTrackOrder = [];
            idInOrder.forEach((el, index) => {
              stored.ProjectDetailsTracks.tracksPaginate.forEach(track => {
                if (track.id === el) {
                  const newTrack = { ...track };
                  newTrack.position = index + 1;
                  newProjectDetailsTrackOrder.push(newTrack);
                }
              });
            });
            stored.ProjectDetailsTracks.tracksPaginate = newProjectDetailsTrackOrder;
            store.writeQuery({
              query: PROJECT_DETAILS_TRACKS,
              variables: { projectId: this.$route.params.id },
              data: stored
            });
          }
        })
        .then(() => {
          this.isLoadingId = null;
        })
        .catch(error => {
          this.pendingStatus = false;
          this.isLoadingId = null;
          const errorKey = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key: errorKey },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    setIsHoverId(trackId) {
      this.isHoverId = trackId;
    },
    setPlayingTrack(track) {
      this.$store.commit('changePlaylist', this.trackList);
      this.$store.commit('changePlayingTrack', track);
    },
    setSelectedItems(param) {
      const { elementUID, openEditPanel, checkbox, rowIndex, informationBtn } = param;
      this.fromTo = [];
      this.fromTo.push(rowIndex || 0);
      if (this.selectedTracks.includes(elementUID)) {
        if (informationBtn && openEditPanel && !this.isEditPanelOpened) {
          this.selectedTracks = [elementUID];
          this.toggleEditMode();
          return;
        }
        const idx = this.selectedTracks.findIndex(id => id === elementUID);
        this.selectedTracks.splice(idx, 1);
        if (!checkbox) {
          this.selectedTracks = this.selectedTracks.length > 1 ? [elementUID] : [];
        }
      } else {
        this.selectedTracks.push(elementUID);
        if (!checkbox) {
          this.selectedTracks = [elementUID];
        }
      }
      if (!param) {
        bus.$emit('showTracksEditSaveModal');
        return;
      }
      if (this.editMode || openEditPanel) {
        this.toggleEditMode();
      }
    },
    shiftSelectItems(param) {
      const { elementUID, rowIndex } = param;
      this.fromTo.splice(1, 1, rowIndex);
      if (this.selectedTracks.includes(elementUID)) {
        this.selectedTracks.splice(this.selectedTracks.indexOf(elementUID), 1);
        return;
      }
      if (this.fromTo.length === 2) {
        this.fromTo.sort((a, b) => a - b);
        for (let i = this.fromTo[0]; i <= this.fromTo[1]; i += 1) {
          if (this.trackList[i] && !this.selectedTracks.includes(this.trackList[i].id)) {
            this.selectedTracks.push(this.trackList[i].id);
          }
        }
      } else if (this.selectedTracks.includes(elementUID)) {
        this.selectedTracks.splice(this.selectedTracks.indexOf(elementUID), 1);
      } else {
        this.selectedTracks.push(elementUID);
      }
    },
    handleAddToClick(tracksIds, actionBar) {
      if (this.$isWorkspaceLockedVerification(this)) return;
      const fromSharedView = this.$route.path.includes('shared');
      trackEvent('Track AddTo Click', {
        category: 'addto',
        page_view: fromSharedView ? 'shared project details' : 'project details',
        action_bar: !!actionBar,
        upload_manager: false
      });
      bus.$emit('displayModal', {
        component: MoveToAddTo,
        isVisible: true,
        size: 'small',
        onSubmit: this.closeShareModal,
        hideHeader: true,
        props: [{ name: 'filesIds', value: tracksIds || this.selectedTracks }]
      });
    },
    downloadTrack(trackId) {
      trackEvent('Download a track from project');
      bus.$emit('downloadTrack', [trackId]);
    },
    removeTracks(trackId) {
      const fileIdsToDelete = [];
      if (!trackId) {
        this.selectedTracks.forEach(id => {
          fileIdsToDelete.push(this.trackList.find(track => track.id === id).deleteId);
        });
      }
      const libraryFileIds = trackId ? [trackId] : fileIdsToDelete;
      this.$emit('openConfirmRemoveModal', libraryFileIds);
    },
    navigateTo(param) {
      const { id, name } = param;
      const route = id ? getRoute(name, { id }) : getRoute(name);
      if (this.$router.history.current.name !== name) this.$router.push(route);
    },
    toggleEditMode(fromActionBar) {
      if (!this.isEditPanelOpened && this.$isWorkspaceLockedVerification(this)) return;
      if (fromActionBar && this.isEditPanelOpened) {
        this.$emit('toggleEditMode', []);
        return;
      }
      this.$emit('toggleEditMode', this.selectedTracks);
    },
    handleReplaceFile(trackId) {
      if (this.$isWorkspaceLockedVerification(this)) return;
      trackEvent('Replace Audio track from MoreMenu');
      // When replacing a file we want to open the file tab
      this.$store.commit('changeEditPanelPane', this.$t('file'));
      this.$emit('toggleEditMode', [trackId]);
    },
    uncheckAllCheckbox() {
      this.selectedTracks = [];
      if (this.editMode) {
        this.$emit('toggleEditMode', []);
      }
    },
    checkAllCheckbox() {
      const allItemsIds = this.trackList.map(el => el.id);
      this.selectedTracks = allItemsIds;
      if (this.editMode) {
        this.$emit('toggleEditMode', this.selectedTracks);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.action-bar-container {
  position: fixed;
  bottom: 72px;
  left: calc(50% + 90px);
  transform: translateX(-50%);
  z-index: map-get($z-index, action-bar);
  transition: left 0.15s ease;
}
.action-bar-container.panel-open {
  left: calc(50% - 169px);
}
</style>
