
<div class="search">
  <div class="search-top">
    <div class="search-top-bar">
      <search-bar
        key="search-bar-library"
        v-if="searchContext === context.library"
        icon="ri-search-line"
        :fieldModel.sync="phraseLibrary"
        :placeholder="$t('searchPlaceholder')"
      />
      <search-bar
        key="search-bar-project"
        v-else
        icon="ri-search-line"
        :fieldModel.sync="phraseProject"
        :placeholder="$t('searchPlaceholder')"
      />
    </div>
    <submit-button
      v-if="!hideFilter"
      btnStyle="tertiary"
      size="medium"
      :label="$t('trackFilters')"
      iconClass="ri-filter-3-line"
      isDropdown
      exception
      :isExpanded="isSearchExpanded"
      @submit="toggleExpandFilters"
    />
  </div>
  <div class="search-expand" v-if="isSearchExpanded && !hideFilter">
    <div class="search-expand-dropdown" v-if="TagList">
      <div
        class="search-expand-dropdown--"
        v-for="category in TagList.categories"
        :key="category.id"
      >
        <tag-category-dropdown
          :tagCategory="category"
          :checkedTags="checkedTags"
          :noneCategories="noneCategoryIds"
          withNoneOption
          @selectTags="selectTags"
          @selectNone="selectNone"
        />
      </div>
      <div
        v-if="currentWorkspace.hubs && currentWorkspace.hubs.length > 0"
        class="search-expand-dropdown-filter"
      >
        <filter-dropdown
          v-if="hubsFilterList.length > 1"
          filterName="Shared on Hubs"
          :filterList="hubsFilterList"
          :activeFilter="activeHubsFilter"
          @toggleFilter="selectHubs"
        />
      </div>
    </div>
    <div class="search-expand-loading" v-else>
      <spinner-without-progress color="grey" :size="32" />
    </div>
  </div>
  <div class="search-active" v-if="displayActiveTag" :style="margin">
    <div class="search-active-header">
      <div class="search-active-header-title">
        {{
          $tc(
            'activeFilter',
            activeTags.length +
              noneCategoryIds.length +
              activeHubs.length +
              (noneHubs ? 1 : 0) +
              (displayNonCompliant ? 1 : 0)
          )
        }}
      </div>
      <div class="search-active-header-clear">
        <submit-button
          btnStyle="tertiary"
          size="small"
          exception
          :label="$t('clearFilters')"
          @submit="clearFilters"
        />
      </div>
    </div>
    <div class="search-active-tag" v-if="isActiveTagsExpanded && TagList">
      <div
        class="search-active-tag--"
        v-for="tag in activeTags"
        :key="`${tag.categoryName}-${tag.name}`"
      >
        <span :style="`color: ${tag.color}; font-weight:600`">{{ `${tag.categoryName}:` }}</span>
        <span>&nbsp;</span>
        <span>{{ `${tag.name}` }}</span>
        <i class="ri-close-circle-fill" @click="removeActiveTag(tag)" />
      </div>
      <div
        class="search-active-tag--"
        v-for="category in noneCategories"
        :key="`none-${category.id}`"
      >
        <span :style="`color: ${category.background_color}`">{{ `${category.name}:` }}</span>
        <span>&nbsp;</span>
        <span>None</span>
        <i class="ri-close-circle-fill" @click="removeNoneCategory(category.id)" />
      </div>
      <div class="search-active-tag--" v-for="hub in activeHubs" :key="`${hub}-hub`">
        <span :style="`font-weight:600`">Shared on hubs :</span>
        <span>&nbsp;</span>
        <span>{{ getHubName(hub) }}</span>
        <i class="ri-close-circle-fill" @click="removeActiveHub(hub)" />
      </div>
      <div class="search-active-tag--" v-if="noneHubs">
        <span :style="`font-weight:600`">Shared on hubs :</span>
        <span>&nbsp;</span>
        <span>None</span>
        <i class="ri-close-circle-fill" @click="removeNoneHub()" />
      </div>
      <div class="search-active-tag--" v-if="displayNonCompliant">
        <span :style="`font-weight:600`">Shared on hubs :</span>
        <span>&nbsp;</span>
        <span>Non-compliant tracks</span>
        <i class="ri-close-circle-fill" @click="removeDisplayNonCompliant()" />
      </div>
    </div>
    <div class="search-active-button" v-if="isSearchExpanded">
      <div class="search-active-button-content" @click="toggleActiveTagsExpanded">
        <i :class="isActiveTagsExpanded ? 'ri-arrow-drop-up-line' : 'ri-arrow-drop-down-line'" />
      </div>
    </div>
  </div>
</div>
