<template>
  <div class="shared" v-if="AggregatedSharesList">
    <div v-if="AggregatedSharesList.length > 0" data-test="sharedList">
      <shared-list
        v-if="AggregatedSharesList"
        :shares="AggregatedSharesList"
        :membersList="currentWorkspace.members"
        :loading="loading"
        @goToPage="goToPage"
        @goToShareSettings="goToShareSettings"
        @goToActivities="goToActivities"
      />
      <observer
        :key="`asl-${page}`"
        v-if="AggregatedSharesList && !loading && !isLastPage"
        :height="40"
        bottom
        @intersect="fetchMore"
      />
    </div>
    <div class="no-shared" v-else>
      <empty-shared
        :title="$t('emptySharedTitle')"
        :description="$t('emptySharedSubtext')"
        placeholderType="sharedlist"
        :cloudedTextContent="$t('manageLinks')"
        :cloudedTextColor="{
          stroke: '#FF3364',
          fill: '#FFF0F3'
        }"
      >
      </empty-shared>
    </div>
  </div>
  <div v-else class="loader">
    <spinner-without-progress color="grey" :size="32" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AGGREGATED_SHARES_LIST } from '@/graphql/queries/share';

import Observer from '@/containers/observer';
import SharedList from '@/containers/sharedList';
import EmptyShared from '@/containers/emptyView';
import { get as getRoute } from '@/router/routes';

export default {
  components: {
    SharedList,
    Observer,
    EmptyShared
  },
  computed: {
    ...mapGetters(['currentWorkspace'])
  },
  apollo: {
    AggregatedSharesList: {
      query: AGGREGATED_SHARES_LIST,
      // temporary solution to handle deleted entities
      // to delete when BRID-3847 is defined and resolved
      // https://bridgeaudio.atlassian.net/browse/BRID-3847
      errorPolicy: 'ignore',
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          workspaceId: this.currentWorkspace.id,
          libraryId: this.currentWorkspace.libraries[0].id,
          limit: this.pageSize,
          offset: 0
        };
      },
      watchLoading(loading) {
        this.loading = loading;
      }
    }
  },
  data() {
    return {
      page: 0,
      pageSize: 40,
      loading: false,
      isLastPage: false
    };
  },
  methods: {
    fetchMore() {
      this.page += 1;
      this.$apollo.queries.AggregatedSharesList.fetchMore({
        variables: {
          offset: this.page * this.pageSize,
          limit: this.pageSize
        },
        updateQuery: (existing, incoming) => {
          if (incoming.fetchMoreResult.AggregatedSharesList.length === 0) {
            this.isLastPage = true;
          }
          return {
            AggregatedSharesList: [
              ...existing.AggregatedSharesList,
              ...incoming.fetchMoreResult.AggregatedSharesList
            ]
          };
        }
      });
    },
    goToPage(id, type) {
      if (type === 'project') {
        this.$router.push(getRoute('sharedProjectDetails', { id }));
      }
      if (type === 'support') {
        this.$router.push(getRoute('sharedAlbumDetails', { id }));
      }
      if (type === 'track') {
        this.$router.push(getRoute('sharedTracksDetails', { id }));
      }
      if (type === 'artist') {
        this.$router.push(getRoute('sharedArtistAlbums', { id }));
      }
      if (type === 'submission') {
        this.$router.push(getRoute('sharedSubmissionDetails', { id }));
      }
    },
    goToShareSettings(id, type) {
      if (type === 'project') {
        this.$router.push(getRoute('sharedProjectSharings', { id }));
      }
      if (type === 'support') {
        this.$router.push(getRoute('sharedAlbumSharings', { id }));
      }
      if (type === 'track') {
        this.$router.push(getRoute('sharedTracksSharings', { id }));
      }
      if (type === 'artist') {
        this.$router.push(getRoute('sharedArtistSharings', { id }));
      }
    },
    goToActivities(id, type) {
      if (type === 'project') {
        this.$router.push(getRoute('sharedProjectActivity', { id }));
      }
      if (type === 'support') {
        this.$router.push(getRoute('sharedAlbumActivity', { id }));
      }
      if (type === 'track') {
        this.$router.push(getRoute('sharedTracksActivity', { id }));
      }
      if (type === 'artist') {
        this.$router.push(getRoute('sharedArtistActivity', { id }));
      }
      if (type === 'submission') {
        this.$router.push(getRoute('sharedSubmissionActivity', { id }));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.shared {
  position: relative;
  width: 100%;
  padding-left: 40px;
  margin: 40px 0;
  box-sizing: border-box;
}
.no-shared {
  padding-left: 0px;
}
.loader {
  height: calc(100vh - 64px - 56px);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
