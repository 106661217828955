
<div>
  <header-list
    :columns="columns"
    :headerCountLabel="headerCountLabel"
    :hasSelectAll="true"
    :itemsSelected="[]"
    :sortColumn="false"
    :displayUnselect="selectedTracks.length > 0"
    isSticky
    @handleEmit="handleEmit"
    @uncheckAllCheckbox="uncheckAllCheckbox"
    @selectAll="checkAllCheckbox"
  />
  <div v-for="(track, idx) in trackList" :key="track.id">
    <list-row
      :item="track"
      :columns="columns"
      :rowIndex="idx"
      :selectedItems="selectedTracks"
      :height="48"
      :id="track.id"
      :playingTrack="playingTrack || {}"
      @handleEmit="handleEmit"
    />
  </div>
  <div v-if="isFetchingMore">
    <skeleton-row :columns="columns" :height="48" />
  </div>
</div>
