
<div :class="checkboxStyle">
  <input
    :id="id"
    type="checkbox"
    v-model="checked"
    :disabled="disabled"
    @click.stop="handleClick"
  />
  <label :style="labelStyle" :for="id">{{ label }}</label>
</div>
