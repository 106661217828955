
<div class="container">
  <h2 class="headline">{{ $t('subscription.headline.invoice') }}</h2>
  <div class="header grid">
    <div class="grid-header">Date</div>
    <div class="grid-header">Description</div>
    <div class="grid-header">Total</div>
    <div class="grid-header">Status</div>
  </div>
  <div v-for="invoice in invoices" :key="invoice.id" class="grid grid-row">
    <div class="grid-item">{{ getFormatDate(invoice) }}</div>
    <div class="grid-item">{{ getSubscriptionInfos() }}</div>
    <div class="grid-item">{{ getFormattedPrice(invoice) }}</div>
    <div class="grid-item">
      <div>
        <p class="invoice-status">
          {{ getSubscriptionStatus(invoice) }}
        </p>
        <p><a :href="invoice.invoice_link">View invoice</a></p>
      </div>
    </div>
  </div>
</div>
