
<div class="filter-dropdown" v-click-outside="{ hide: closeExpandDropdown }">
  <div
    class="filter-dropdown-btn"
    :class="{ 'btn-active': activeFilter.length }"
    @click="toggleExpandDropdown"
  >
    <i :class="filterIcon"></i>
    <div>{{ filterName }}</div>
  </div>
  <div class="filter-dropdown-expand" v-if="isDropdownExpanded">
    <div
      v-for="(filter, idx) in filterList"
      :key="`filter-${idx}`"
      class="filter-dropdown-expand-checkbox"
    >
      <checkbox
        :label="filter.name"
        :id="filter.key"
        :selectedItems="activeFilter"
        @toggleCheckbox="toggleFilter"
      />
    </div>
  </div>
</div>
