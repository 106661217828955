export default {
  createYourWorkspace: 'Créer votre workspace',
  createWorkspace: 'Créer un workspace',
  onlyOneWorkspace: {
    first: 'Pour le moment, notre early access vous permet de créer uniquement',
    oneWorkspace: 'un workspace.',
    second:
      "Si vous souhaitez en créer un nouveau, n'hésitez pas à nous envoyer un mail à l'adresse",
    third:
      'afin que nous puissions vous aider à mettre en place un workspace adapté à vos besoins !'
  },
  go: "C'est parti !",
  maxLength: '{nbChars} caractères maximum',
  minLength: '{nbChars} caractères minimum',
  required: '{label} requis',
  workspaceName: 'Nom du workspace',
  workspaceNamePlaceholder: 'My Workspace',
  activity: 'Activité',
  sharingActivity: 'Activité de partage',
  activityTitlePlaceholder: 'Sélectionnez votre métier',
  label: 'Label',
  manager: 'Manager',
  publisher: 'Publisher',
  aandr: 'A&R',
  artist: 'Artist',
  band: 'Band',
  supervisor: 'Supervisor',
  syncBroker: 'Sync Broker',
  radio: 'Radio',
  press: 'Press',
  tv: 'TV',
  webzine: 'Webzine',
  touring: 'Touring',
  venue: 'Venue',
  other: 'Other',
  workspacePicture: 'Choisissez votre logo',
  workspacePictureTitle: 'Logo du workspace',
  workspacePictureDefinition: 'Vous pouvez le choisir ou le changer plus tard',
  next: 'Suivant',
  later: 'Later',
  save: 'Sauvegarder',
  saveYourChanges: 'Partir sans sauvegarder ?',
  cancel: 'Annuler',
  yesContinue: 'Oui, continuer',
  yesLeave: 'Oui, partir',
  library: 'Librairie',
  projects: 'Projets',
  noTrackInProject: "Il n'a a pas de track dans ce projet",
  noFileAttached: 'Aucun attachement',
  archived: 'Archivés',
  inbox: 'Inbox',
  outbox: 'Outbox',
  contacts: 'Contacts',
  logout: 'Se déconnecter',
  supportedFiles: 'Formats supportés: jpg, png, gif',
  continue: 'Continuer',
  music: 'Musique',
  import: 'Importer',
  importFiles: 'Importer les fichiers',
  libraryEmptyTitle: 'Ajoutez des fichiers audio à la librairie de votre workspace',
  libraryEmptyHelper: 'Seuls vous et votre équipe aurez accès à cette librairie',
  dragAndDrop: 'ou glissez vos fichiers ici',
  dropFiles: 'Glissez vos fichiers',
  toUpload: 'pour les uploader',
  filesNotUploaded:
    "{count} fichier n'est pas de type audio. | {count} fichiers ne sont pas de type audio.",
  uploadComplete: 'Upload terminé !',
  upgradeYourPlan: 'Modifier votre abonnement',
  upgradePlan: "Modifier l'abonnement",
  upgrade: 'Souscrivez',
  fullStorage:
    'Le poids des fichiers que vous essayez d’uploader excède la milite de votre workspace. Merci d’essayer un import plus léger.',
  please: 'Veuillez',
  keepUploading: 'pour uploader de nouveaux fichiers.',
  upgradeByAdmin: {
    firstPart: "Veuilez contacter l'administrateur du workspace pour ",
    boldPart: 'mettre à jour le plan',
    thirdPart: " afin d'uploader de nouveaux fichiers."
  },
  workspaceStorage: 'Espace de stockage',
  storageUsed: '{used} sur {total} utilisé',
  filesTooLarge: 'Fichiers trop volumineux',
  full: 'Limite de stockage',
  filesSize:
    "La taille des fichiers que vous tentez d'importer excède la limite de votre stockage. Veuillez importer moins de fichiers.",
  increaseSpace: 'pour augmenter votre espace de stockage.',
  uploadInProgress: 'Un upload est déjà en cours ! Veuillez attendre la fin.',
  tracks: 'Titres',
  artists: 'Artistes',
  albums: 'Albums',
  sureToLeave: 'Êtes-vous certain de vouloir quitter ?',
  accountSettings: 'Paramètres du compte',
  settings: 'Paramètres',
  workspaceMembers: 'Membres du workspace',
  personnal: 'Profil',
  account: 'Compte',
  workspace: 'Workspace',
  general: 'Général',
  members: 'Membres',
  membersLower: 'membre | membres',
  billing: 'Plan',
  accountInformation: 'Informations du compte',
  accountDescription: 'Changer votre photo de profil et votre nom',
  firstname: 'Prénom',
  lastname: 'Nom',
  email: 'E-mail',
  newEmail: 'Nouvelle adresse e-mail',
  invalidEmail: "Ce n'est pas un e-mail",
  currentEmail: 'Votre adresse e-mail actuelle est ',
  emailAddress: 'Adresse email',
  emailAddressDescription: 'Gérer vos adresses email',
  primaryEmail: 'Email primaire',
  secondaryEmail: 'Email secondaire',
  addEmailAddress: 'Ajouter une adresse email',
  enterEmailAddress: 'Saisissez votre adresse email',
  enterPassword: 'Saisissez votre mot de passe',
  sendVerificationEmail: 'Envoyer un mail de vérification',
  resendVerificationEmail: 'Renvoyer un mail de vérification',
  emailDeleted: 'Adresse email retirée',
  maximumFiveSecondaryEmails: 'Vous avez atteint la limite de 5 adresses email',
  // secondary email errors
  user_email_exist: 'Cette adresse mail existe déjà',
  max_secondary_email: "You can't add more than 5 emails",
  modificationSuccess: 'Changements enregistrés',
  error: 'Une erreur est survenue.',
  workspaceInformation: 'Information du Workspace',
  workspaceDescription: 'Changer votre logo and nom de workspace',
  maxChars: '{nbChars} charactères max.',
  workspaceSettings: 'Paramètrage du Workspace',
  invitePeople: 'Inviter des utilisateurs à votre workspace',
  invitePeopleHelper:
    'Invitez les gens avec qui vous travaillez à se joindre à votre workspace privé. Vous pourrez plus tard gérer leurs roles.',
  allDone: "C'est partit !",
  memberList: 'Liste des membres',
  count: '{nb} sur {total}',
  seatsTaken: 'places occupées',
  inviteMembers: 'Inviter des membres',
  invite: 'Inviter',
  member: 'Membre | Membres',
  role: 'Rôle',
  searchMail: 'Chercher un nom ou un email',
  admin: 'Admin',
  editor: 'Editeur',
  yes: 'Oui',
  no: 'Non',
  roles: {
    1: {
      description:
        "Peut changer les paramètres du workspace, inviter des nouveaux membres et gérer l'abonnement"
    },
    2: {
      description: 'Ne peut pas changer les paramètres du workspace ou inviter des nouveaux membres'
    },
    owner: {
      description:
        "Peut changer les paramètres du workspace et le supprimer, inviter des nouveaux membres et gérer l'abonnement"
    }
  },
  transferOwnership: 'Transfert de propriétaire',
  transferOwnershipConfirmation:
    'Voulez-vous vraiment transférer la propriété du workspace "{workspaceName}',
  addMembers: 'Inviter des membres sur votre workspace',
  largerTeam: 'Intéressé par une équipe plus grande ?',
  checkPlan: 'Regardez nos offres',
  enterEmail: 'Renseigner votre adresse e-mail',
  addMember: 'Ajouter un membre',
  sendInvites: 'Inviter',
  workspaceDefinition: 'Bienvenue! Dites-nous en plus sur votre équipe',
  charMax: '{nbChars} charactères max.',
  emailTwice: "Vous essayez d'envoyer le même email plusieurs fois.",
  membersLimit: 'Limite de membres',
  membersLimitDesc:
    'Désolé mais votre workspace a atteint la limite de nombre de membres incluse dans votre plan',
  noPermissionDesc:
    'Désolé, seuls les admins peuvent inviter de nouveaux membres sur le workspace.',
  saveChanges: "Vous n'avez pas sauvegardé vos modifications.",
  smallSaveChanges: 'Sauvegarder les modifications',
  createAlbum: 'Créer un album',
  confirmDelete: 'Etes-vous certain de vouloir continuer ?',
  confirmDeleteHard: 'Êtes-vous sûr de vouloir supprimer "{trackName}" ?',
  removeTrackFromProject: ' Êtes-vous sûr de vouloir retirer "{trackName}" du projet ?',
  removeTrackTags: 'Supprimer les tags de la track',
  deleteFile: 'Supprimer le fichier',
  confirmDeleteFile: 'Êtes-vous de vouloir supprimer ce fichier ?',
  invitationSuccess:
    'Votre invitation a bien été envoyée ! | Vos invitations ont bien été envoyées !',
  createNewWorkspace: 'Créer un nouveau workspace',
  createFirstWorkspace: 'Créer votre premier workspace',
  pending: 'En attente',
  resendInvitation: "Renvoyer l'invitation",
  cancelInvitation: "Annuler l'invitation",
  cancelInvitationSuccess: 'Invitation annulée',
  resendInvitationSuccess: 'Invitation envoyée à nouveau',
  owner: 'Owner',
  manageStorage: 'Gérez votre espace de stockage',
  password: 'Mot de passe',
  changePassword: 'Changer votre mot de passe',
  currentPasswordPlaceholder: '8+ charactères',
  currentPassword: 'Mot de passe actuel',
  differentPassword: 'Doit être différent du mot de passe actuel',
  newPassword: 'Nouveau mot de passe',
  fair: 'Moyen',
  weak: 'Faible',
  strong: 'Fort',
  confirmNewPasswordPlaceholder: 'Identique au nouveau mot de passe',
  confirmNewPassword: 'Confirmer le nouveau mot de passe',
  identicalPassword: 'Les mots de passe doivent être identiques',
  track: 'Titre',
  album: 'Album',
  duration: 'Durée',
  rating: 'Score',
  added: 'Ajouté',
  trackSelected: 'titre selectionné | titre sélectionné | titres sélectionnés',
  trackSelectedCount:
    '{count} titre selectionné | {count} titre sélectionné | {count} titres sélectionnés',
  addToProject: 'Ajouter à un projet',
  addAlbumToProject: "Ajouter l'album à un projet",
  addArtistToProject: "Ajouter l'artiste à  un projet",
  errorProjectUnauthorized: "Désolé ce projet n'est pas disponible",
  backToProjects: 'Retourner aux projets',
  more: 'Plus',
  editInformation: 'Editer les informations',
  download: 'Télécharger',
  downloadProject: 'Télécharger le projet',
  delete: 'Supprimer',
  edit: 'Editer',
  rename: 'Renommer',
  importingFiles: 'Importation...',
  selectWorkspacePlan: 'Sélectionnez un plan pour votre workspace',
  selectWorkspacePlanFor: 'Sélectionnez un plan pour {name}',
  discovery: 'Découverte',
  basic: 'Basic',
  customized: 'Personnalisé',
  customize: 'Personnaliser',
  discoveryDesc: 'Le plan pour découvrir les fonctionnalités de base',
  basicDesc: 'Accéder aux fonctionnalités de base pour essayer le service',
  customizedDesc: 'Personnalisez votre workspace en fonction de vos besoins',
  perMonth: 'par mois',
  perYear: 'par an',
  saveBilledAnnually: "Gagnez {price}€ en souscrivant à l'année",
  selectAll: 'Tout séléctionner',
  unselect: 'Désélectionner',
  selectPlan: 'Sélectionner un plan',
  selectRole: 'Sélectionner un rôle',
  countStorage: '{count} GB storage',
  membersIncluded: '{count} membres inclus',
  mp3Only: 'MP3 seulement',
  collaborateWithTeam: 'Collaboration en équipe',
  metadataCollect: 'Collècte automatique des Metadata',
  sharePlaylists: 'Partagez vos playlists',
  analyzeShare: "Analysez l'audience de vos partages",
  receiveMusic: 'Recevezde la musique à un endroit unique',
  customizePlaylist: 'Customiserz vos playlists',
  comingSoon: 'A venir',
  starting: 'A partir de {price}',
  storageUpTo: "Stockage jusqu'à 1 To (approx. 200k titres)",
  storageUpToMvp: '100 GB de stockage',
  storageUpToBeta: '20 GB de stockage',
  membersUpTo: "Jusqu'à 250 membres",
  membersUpToMvp: "Jusqu'à 20 membres",
  membersUpToBeta: "Jusqu'à 5 membres",
  allFormats: 'Tous les formats (MP3, AAC, WAV)',
  freeTrial: 'Essayer',
  back: 'Retour',
  fitNeeds: 'Choisissez le plan qui correspond à vos besoin',
  scaleWorkspace: 'Augmentez votre espace et vos membres',
  stepNumber: 'Etape {count}',
  howMuchStorage: "De combien d'espace avez-vous besoin ?",
  howManyPeople: 'Combien de personnes dans votre workspace ?',
  songsCount: '~ {count} titres',
  countMember: '{count} membre | {count} membres',
  pricePerUser: '{price} / utilisateurs',
  billMonthly: 'Mensuel',
  billAnnually: 'Annuel',
  countSongs: '~ {count} titres',
  youWillPay: 'Vous paierez',
  willInclude: 'Votre workspace incluera',
  memberSeatCount: '{count} place | {count} places',
  currentPlan: 'Votre plan',
  managePlan: 'Gérez votre plan',
  currentPlanDesc: 'Votre workspace est sur le',
  billedMonthly: '(paiement mensuel)',
  memberSeats: 'Nombre de membres ({count})',
  storage: 'Stockage ({count})',
  storageTitle: 'Stockage',
  totalPerMonth: 'Total par mois',
  cancelSubscription: "Annuler l'abonnement",
  selectPlanFor: 'Sélectionner un plan pour {name}',
  planName: '{name} plan',
  changeRoleSuccess: 'Rôle du membre modifié',
  deleteTracksSuccess: 'Track supprimée | Tracks supprimées',
  removeTracksSucces: 'Track retirée du projet | Tracks retirées du projet',
  leftDays:
    'Il vous reste <span style="font-weight: bold;">{count} jour</span> sur votre période d\'essai. | Il vous reste <span style="font-weight: bold;">{count} jours</span> sur votre période d\'essai.',
  albumCount: '{count} album | {count} album | {count} albums',
  trackCount: '{count} track | {count} track | {count} tracks',
  attachmentCount: '{count} attachement | {count} attachement | {count} attachements',
  shareArtist: 'Partager',
  shareAlbum: 'Partager',
  albumep: 'Album & EP',
  shares: 'Partages',
  received: 'Reçus',
  shared: 'Partagés',
  emptyProjectTitle: ' pour organiser votre travail et partage de belles présentations',
  emptyProjectSubtext: "Les projects sont privés jusqu'à ce que vous les partagiez",
  emptyProjectDescription: 'Créer un projet pour organiser et partager votre travail.',
  noArchive: 'Pas de projets archivés',
  noArchiveSubtext: 'Tous les projets archivés seront listés ici',
  archive: 'Archiver',
  newProject: 'Nouveau projet',
  projectNamePlaceholder: 'e.g. Artist promo 2019',
  projectName: 'Nom du projet',
  create: 'Créer',
  newProjectSuccess: 'Projet créé',
  addDescription: 'Ajouter une description (optionnel)',
  projectDescPlaceholder: 'e.g. Promo material for this artist in 2019',
  upload: 'Upload',
  createdAt: 'Date de création',
  updatedAt: 'Date de mise à jour',
  createdBy: 'Créateur',
  publicLink: 'Lien public',
  copiedToClipboard: 'Lien copié',
  deleteProjectSuccess: 'Projet supprimé',
  archiveProjectSuccess: 'Projet archivé',
  restoreProjectSuccess: 'Projet restauré',
  confirmArchive:
    'En archivant ce projet, vous allez désactiver tous les liens de partage associés. Voulez-vous vraiment archiver ce projet ?',
  modalArchiveTitle: 'Archiver ce projet ?',
  add: 'Ajouter',
  addCover: 'Ajouter une bannière',
  addTracksSuccess: 'Track ajoutée au projet ! | Tracks ajoutées au projet !',
  deleteProjectTitle: 'Supprimer le projet ?',
  deleteProjectUndo: 'Projet "{name}" supprimé',
  archiveProjectUndo: 'Projet "{name}" archivé',
  deleteProjectDesc:
    'Êtes-vous sûr de vouloir supprimer "{name}" ? Les tracks seront toujours disponibles dans votre librairie, mais tout autre contenu sera perdu.',
  deleteMultipleTracksDesc: 'Êtes-vous sûr de vouloir supprimer ces {number} tracks ?',
  removeMultipleTracksDesc: 'Êtes-vous sûr de vouloir retirer ces {number} tracks du projet ?',
  removeMultipleTracksDescSubtext:
    'Ces tracks seront également retirée de tous les liens de partage liés à ce projet',
  typeDelete: 'Tapez "DELETE" pour confirmer',
  undo: 'ANNULER',
  undoDeleteProjectSuccess: 'La suppression du projet a bien été annulée !',
  undoArchiveProjectSuccess: "L'archive du projet a bien été annulée !",
  addTracks: 'Ajouter des tracks',
  addFile: 'Ajouter des fichiers',
  addTracksToProject: 'Ajouter les tracks au projet',
  share: 'Partager',
  allProjects: 'Tous les projets',
  selectTracks: 'Sélectionner les tracks que vous voulez ajouter au projet',
  uploadTracks: 'Uploadez des tracks',
  uploadFilesDesc:
    'Uploadez vos fichiers dans votre librairie puis vous pourrez les ajouter à votre projet !',
  noFilesProject:
    "Il n'y a pas de tracks dns votre librairie. Vous devez d'abord uploader des tracks avant de les ajouter à votre projet.",
  projectUpdateSuccess: 'Votre projet a été modifié avec succès !',
  project: 'Projet',
  sharings: 'Partages',
  sharingLinks: 'Liens de partage',
  statistics: 'Statistiques',
  updated: 'Mis à jour',
  projectTxtAreaPlaceholder: 'Cliquez ici pur ajouter une description à ce projet...',
  addedBy: 'Ajouté par',
  date: 'Date',
  copyProjectSuccess: 'Projet dupliqué',
  duplicateProject: 'Copier le projet',
  duplicateProjectDescription: 'Créez une copie de "{name}" ? Merci d\'utiliser un nom différent',
  duplicate: 'Dupliquer',
  moveTo: 'Déplacer vers',
  media: 'Media',
  addAttachmentSuccess: 'Fichier ajouté au projet | Fichiers ajoutés au projet',
  addAttachmentFail: 'Vous ne pouvez attacher plus de {count} fichiers à un projet.',
  filesMax: 'fichiers max.',
  removeAttachmentSuccess: 'Fichier supprimé',
  removeMember: 'Supprimer du workspace',
  removeMemberSucess: 'Membre supprimé',
  removeMemberModal:
    "Êtes-vous sûr de vouloir retirer ce membre ? La personne perdra l'accès au workspace.",
  leaveWorkspaceModal: 'Êtes-vous sûr que vous ne voulez plus faire partie de ce workspace ?',
  transferOwnershipSuccess: 'Owner du workspace modifié',
  remove: 'Supprimer',
  searchProject: 'Rechercher des projets...',
  noResultLabel: 'Pas de résultats',
  shareProjectName: 'Partager le projet {name}',
  shareAlbumName: "Partager l'album {name}",
  shareArtistName: "Partager l'artiste {name}",
  link: 'Lien',
  sendByEmail: 'Envoyer par e-mail',
  sendByEmailDesc:
    "Créer un lien unique pour chaque destinataire. Cela permettra le tracking de l'activité.",
  toBcc: 'A (cci):',
  toCapitalize: 'À',
  from: 'Depuis',
  to: 'à',
  toInfo:
    "Nous enverrons un mail individuel à chacun de vos destinataires. Ils recevront tous un lien différent et unique qui vous permettra de savoir ce qu'ils font de votre musique.",
  permissions: 'Permissions',
  message: 'Message',
  optionalMessage: 'Message (optionnel)',
  customURL: 'URL personnalisée',
  allowStreaming: 'Autoriser le streaming',
  allowMP3: 'Autoriser le téléchargement MP3',
  allowSourceAudio: "Autoriser le téléchargement de l'audio source",
  showDescription: 'Afficher la description du project',
  send: 'Envoyer',
  urlPlaceholder: '/votre-url',
  customLinkUrl: 'URL personnelle',
  urlErrorMessage: "Cette URL n'est pas correcte",
  generatePublicLink: 'Générer le lien public',
  publicLinkDesc: 'Toute personne ayant ce lien peut ouvrir le projet',
  activated: 'Activé',
  copyLink: 'Copier le lien',
  emailListErrorMsg: "{count} entrée n'est pas un e-mail | {count} entrées ne sont pas des e-mails",
  shareProjectSuccess: 'Projet "{name}" partagé',
  shareTrackSuccess: 'Track "{name}" partagée | Tracks "{name}" partagées',
  shareAlbumSuccess: 'Album "{name}" partagé',
  shareArtistSuccess: 'Artiste "{name}" partagé',
  enableProjectSuccess: 'Le lien public a été activé avec succès !',
  disableProjectSuccess: 'Le lien public a été desactivé avec succès !',
  shareMoreThan50: 'Pour partager plus de 50 tracks, veuillez créer un projet',
  uploading: 'En cours',
  uploadingDot: 'En cours...',
  uploaded: 'Terminé',
  analyzed: 'Analysé',
  analyzing: "En cours d'analyse",
  canceled: 'Annulé',
  waiting: 'En attente',
  couldNotUpload: 'Erreur: upload impossible',
  couldNotExtracted: 'Erreur: analyse incomplète',
  couldNotStreamable: 'Erreur: impossible de générer le stream',
  processingCount:
    '{count} fichier en cours de traitement | {count} fichiers en cours de traitement',
  analyzedCount: '{count} de {total} analyse',
  processedCount: '{count} fichier traité | {count} fichiers traités',
  uploadErrorCount: 'Upload en echec pour {count} fichier | Upload en echec pour {count} fichiers',
  uploadingSize: '{count} de {total} uploadé',
  close: 'Fermer',
  shareWith: 'Partager avec',
  contact: 'Contact',
  description: 'Description',
  streaming: 'Streaming',
  downloadAsMp3: 'Télécharger en mp3',
  downloadHighQuality: 'Télécharger en haute qualité',
  viewProjectDescription: 'Inclure la description du projet',
  viewMedia: 'Inclure les pièces jointes',
  mp3Down: 'MP3 down.',
  originalDown: 'Source down.',
  linkStatus: 'Statut du lien',
  changeArtistPhoto: "Changer la photo de l'artiste",
  multipleArtists: '< multiple artistes >',
  multiple: '< Multiple >',
  details: 'Details',
  file: 'File',
  soundRecording: 'Information',
  musicalWork: 'Musical work',
  fileInformation: 'Information du fichier',
  title: 'Titre',
  lyrics: 'Paroles',
  comment: 'Commentaire',
  invalidIsrc: "Format incorrect de l'ISRC",
  invalidIswc: 'mauvais format de numéro ISWC',
  maxiChars: 'Max. {count} cars.',
  minChars: 'Min. {count} cars.',
  saveChangesTitle: 'Enregistrer vos changements ?',
  saveChangesEdition:
    'Les changements que vous allez faire affecteront toutes les tracks sélectionnées. Voulez-vous sauvegarder ?',
  dontShowAgain: 'Ne plus montrer ce message',
  artistsLabel: 'Artiste(s)',
  supportsLabel: 'Support(s)',
  authorsLabel: 'Auteur(s)',
  composersLabel: 'Compositeur(s)',
  genresLabel: 'Genre(s)',
  recordingDate: "Date d'enregistrement",
  recordingPlace: "Lieu d'enregistrement",
  editArtist: "Editer l'artiste",
  editAlbum: 'Modifier album',
  editArtistInformation: 'Editer les informations',
  saveChangesArtistEdition:
    "Changer les informations de l'artiste affectera toutes les tracks et les albums associés. Voulez-vous vraiment continuer ?",
  saveChangesSupportEdition:
    'Changer les informations du support affectera toutes les tracks associées. Voulez-vous vraiment continuer ?',
  picture: 'Image',
  change: 'Changer',
  name: 'Nom',
  preview: 'Aperçu',
  releaseDate: 'Date de sortie',
  upc: 'UPC',
  artwork: 'Artwork',
  type: 'Type',
  editSupportInformation: 'Editer le support',
  size: 'Taille',
  bitrate: 'Bitrate',
  lastUpdated: 'Dernière modification',
  addedDate: "Date d'ajout",
  support: 'Support',
  unknownSupport: 'Support inconnu',
  unknownAlbum: 'Album inconnu',
  discography: 'Discographie',
  createNewSupport: 'Créer un nouveau support',
  supportName: 'Nom du support',
  unknownArtist: 'Artist inconnu',
  voucherDescription:
    'Pour reccueillir vos appréciations, nous vous offrons ma possibilité de créer votre premier workspace',
  invitationCode: "Code d'invitation",
  errors: {
    invitation_one_per_email: 'Utilisateur déjà invité',
    invitation_resend_time_limit: "L'invitation ne peut pas être réenvoyée avant un certain délai",
    project_name_unique: 'Nom du projet déjà utilisé',
    library_file_unique: 'Déjà dans la librairie',
    primary_email_unique: 'Cet email existe déjà sur ce workspace',
    artist_one_per_workspace: 'Cet artiste existe déjà',
    'new-password-does-not-match-old': 'Mot de passe actuel incorrect',
    voucher_not_usable: "Code d'invitation non valide",
    fileSizeTooLarge: 'Taille limite du fichier atteinte (Max. {count})',
    tag_exists_in_family: 'Tag custom existe déjà',
    INSUFFICIENT_PERMISSIONS: "Vous n'avez pas la permission d'accomplir cette action",
    ACCESS_FORBIDDEN: "Vous n'avez pas les droits nécessaires pour faire cette action",
    email_resend_confirmation_interval:
      "Impossible d'envoyer un email de confirmation (essais trop rapprochés)",
    invitation_workspace_owner: 'Le owner du workspace ne peut pas être invité',
    mimetypes: "Ce format de fichier n'est pas supporté",
    identicalFiles: 'Ces fichiers sont identiques',
    projectTitleMaxLength: '{nbChars} caractères maximum',
    projectTitleMinLength: '{nbChars} caractères minimum',
    playerStateError:
      "Le player ne peut pas démarrer. Une erreur s'est produite, veuillez essayer plus tard. Si l'erreur persiste, veuillez nous contacter",
    SHARE_LIMIT_REACHED: 'Vous ne pouvez pas générer plus de {nbLinks} liens par contenu',
    projectCoverMaxSize: '5 MB maximum'
  },
  invalidPassword: 'Mot de passe invalide',
  tags: 'Tags',
  rights: 'Droits',
  credits: 'Crédits',
  changesForEveryMember:
    'Les changements que vous faites seront appliqués pour tous les membres du workspace',
  addTags: 'Ajouter un tag',
  searchTags: 'Chercher un tag',
  allTags: 'Tous les tags',
  saveChangesTags:
    'Les changements que vous allez effectués seront pour tous les membres du workspace. Voulez vous vraiment ajouter ces tags à toutes les tracks sélectionnées ?',
  addTagsTrack: 'Ajouter un tag à la track',
  removeTracksTags: 'Supprimer tous les tags',
  removeTagsSuccess: 'Tous les tags ont bien été supprimés !',
  removeTracksRightHolders: 'Supprimer tous les ayants droit',
  confirmRemoveRightHolders: 'Supprimer tous les ayants droit pour les tracks sélectionnées',
  removeRightHoldersSuccess: 'Tous les ayants droit ont bien été supprimés !',
  totalInfo: 'Part total pour le Master et le Publishing',
  totalShouldEqual100: 'Les parts de master et de publishing devrait être égal à 100%',
  rightHolderOnlySingle: 'Les ayants droit sont uniquement modifiable titre par titre',
  masterPercent: 'Master.%',
  publishPercent: 'Publish.%',
  author: 'Auteur',
  composer: 'Compositeur',
  producer: 'Producteur',
  manageTags: 'Gérer les tags',
  manageCustomTagsTitle: 'Personnaliser les tags',
  manageCustomTagsSubtitle: 'Ajouter, éditer et supprimer les tags de votre workspace',
  createTag: 'Créer un tag',
  customTagList: 'Liste de tags',
  createTagSuccess: 'Tag custom créé',
  tagName: 'Nom du tag',
  modifyTagSuccess: 'Tag custom modifié',
  deleteTagSuccess: 'Tag custom supprimé',
  manageCustomTags: 'Gérer les tags',
  publishMasterPercentCannotExceed100:
    'Les pourcentages de master et de publishing ne peuvent dépasser 100%',
  rightHolderNameRequired: 'Le nom des ayants droit est requis',
  rightHolderRoleRequired: "Le rôle de l'ayant-droit est requis",
  invalidDateFormat: 'Entrer un format de date valide (dd/mm/yyyy)',
  reload: 'Recharger',
  searchTrackPlaceholder: 'Rechercher',
  searchProjectPlaceholder: 'Chercher un projet...',
  trackFilters: 'Filtrer les tracks',
  activeFilter: 'Filtre Actif ({count}) | Filtres Actifs ({count})',
  clearFilters: 'Supprimer les filtres',
  sharedBy: 'Partager par',
  sharedOn: 'Partager le',
  invalidUserShare: "Ce lien a été créé par une personne qui n'est plus membre du workspace",
  disabled: 'Désactivé',
  active: 'Activé',
  attachmentTooLarge:
    'Le fichier "{filename}" est trop gros, veuillez le garder en dessous de 2 Gb',
  invalidFile: 'Type de fichier non supporté',
  noResultFound: "Aucun résultat n'a été trouvé",
  noResult: 'Aucun résultat',
  tryAdjustFilter: "Essayez d'ajuster ou d'effacer vos filtres",
  closeWithoutSaving: 'Fermer sans sauvegarder',
  continueEditingMessage: "Vous n'avez pas sauvegardé vos modifications.",
  continueEditing: 'Continuer de modifier',
  replaceAudioFile: 'Remplacer le fichier',
  modalReplaceAudioFile: {
    title: 'Remplacer le fichier audio',
    content: "Êtes vous sûr de vouloir remplacer l'audio de la track “{title}” ?"
  },
  downloadMessages: {
    pending: {
      header: 'Préparation du téléchargement',
      body: 'En attente du début du traitement'
    },
    processing: {
      header: "Génération de l'archive",
      body: 'Compression du zip avec {tracks} pistes'
    },
    processingProject: {
      header: "Génération de l'archive",
      body: 'Compression du zip'
    },
    done: {
      header: 'Téléchargement prêt',
      body: '{tracks} pistes ont été compressées dans une archive zip'
    },
    doneProject: {
      header: 'Téléchargement prêt',
      body: 'Le projet a été compressées dans une archive zip'
    },
    error: {
      header: 'Erreur',
      body: "Une erreur est survenue pendant la génération de l'archive zip"
    }
  },
  Mon: 'Lundi',
  Tue: 'Mardi',
  Wed: 'Mercredi',
  Thu: 'Jeudi',
  Fri: 'Vendredi',
  Sat: 'Samedi',
  Sun: 'Dimanche',
  Jan: 'Janvier',
  Feb: 'Février',
  Mar: 'Mars',
  Apr: 'Avril',
  May: 'Mai',
  Jun: 'Juin',
  Jul: 'Juillet',
  Aug: 'Août',
  Sep: 'Septembre',
  Oct: 'Octobre',
  Nov: 'Novembre',
  Dec: 'Décembre',
  allSharingActivity: 'Toutes les activités de partage',
  activitiesDesc: 'Activité détaillée pour toutes les consultations du projet partagé',
  noActivityProjectTitle: " et contrôlez l'activité enregistrée sur vos liens",
  noActivityProjectSubtitle:
    'Vous saurez tout sur ce qui arrive à vos liens: ouvertures, écoutes, téléchargements',
  noActivityTitle:
    "Nous n'avons pas enregistré d'activité de la part des destinataires de ce projet",
  noActivitySubtitle:
    'Partagez ce project et contrôlez ici toute activité enregistrée sur les liens',
  noShareTitle: "Vous n'avez pas encore partagé ce projet",
  noShareSubtitle: 'Partagez ce projet et organisez vos liens de partage depuis cette page',
  leaveWorkspace: 'Quitter le workspace',
  cropImage: "Recadrer l'image",
  notifications: {
    header: 'Notifications',
    message: 'Nouvelle activité enregistrée sur',
    from: 'depuis',
    publicLink: 'le lien publique',
    empty: 'Pas de notification',
    emptySubtext:
      'Vous recevrez des notifications quand des personnes ouvriront, écouteront ou téléchargeront les fichiers que vous partagez'
  },
  created: 'Crée',
  lastModified: 'Dernière modification',
  restore: 'Restaurer',
  before: 'Après',
  after: 'Avant',
  on: 'Au',
  between: 'Entre',
  totalTracks: 'Titres total',
  nextTrack: 'Track suivante',
  previousTrack: 'Track précédente',
  newVersionAlertMessage:
    'Une nouvelle version de Bridge.audio est disponible. Rafraichissez la page de votre navigateur pour mettre à jour.',
  mobile: {
    title: 'Notre version mobile arrive bientôt',
    subtitle:
      "En attendant, nous vous recommandons d'utiliser Bridge.audio sur votre ordinateur.Vous pouvez aussi ",
    link1: 'activer le mode bureau sur votre mobile.',
    core1: 'Notez que  ',
    link2: 'les liens partagés ',
    core2: ' via Bridge.audio sont déjà 100 % mobiles ',
    link3: "Plus d'info !"
  },
  albumName: "Nom de l'album",
  artistName: "Nom de l'artiste",
  resendCheckingEmail: 'Email de vérification envoyé à "{email}"',
  audioFileReplaced: 'Fichier audio remplacé',
  deleteTrack: 'Supprimer track',
  deleteTracks: 'Supprimer tracks',
  removeTrack: 'Retirer la track du projet',
  removeTracks: 'Retirer les tracks du projet',
  emailBanner: {
    core1: 'Nous avons envoyé un mail de vérification à',
    core2:
      "S'il vous plaît confirmez votre adresse mail pour utiliser Bridge.audio au maximum de ses capacités",
    sendAgain: "Vous n'avez rien reçu ? Renvoyer le mail"
  },
  verificationEmailSent: 'Email de vérification envoyé',
  manageSharingLinks: 'Gérer les liens de partage',
  seeSharingActivity: 'Voir les activités sur les partages',
  pleaseUpgradeBrowser:
    "Nous vous conseillons de télécharger la dernière version de votre navigateur afin d'optimiser votre expérience sur Bridge.audio",
  emptyState: {
    title: 'Aucun support pour cet artiste',
    text: "Pas de sauvegarde en tant qu'artiste principal",
    link: 'Voir les tracks de cet artiste'
  },
  upgradeAlert: {
    title: 'Le workspace est plein',
    text: 'Vous avez atteint les limites de stockage. Veuillez envisager',
    link: 'de mettre à niveau le forfait',
    tooltipText: 'Seuls les administrateurs peuvent mettre à niveau le forfait'
  },
  workspaceAlmostFull: 'Votre workspace est presque plein',
  trackLimit: 'Track limite',
  limitTracks: 'Désolé mais le nombre maximum de tracks pour ce workspace est atteint. ',
  inviteNewMembers: "afin d'inviter de nouveaux membres",
  upgradePlanByAdmin: 'Seuls les admins peuvent changer de plan',
  deleteWorkspace: {
    sectionTitle: 'Zone Danger',
    title: 'Supprimer le workspace',
    text: 'Tout le contenu sera définitivement supprimé',
    description:
      'Cette action ne peut être annulée. Elle supprimera définitivement tous les fichiers et les projets. Veuillez entrer votre mot de passe pour continuer.',
    buttonLabel: 'Supprimer définitivement ce workspace',
    activeFreeTrialSubscription:
      "Vous pourrez supprimer votre workspace à la fin de la période d'essai de 14 jours.",
    activeProSubscription:
      'Vous ne pouvez pas supprimer un workpace avec une souscription active. Veuillez supprimer votre souscription en cours pour supprimer le workspace'
  },
  workspaceDeleted: 'workspace supprimé',
  modalUpgrade: {
    titleFirstPart: 'Souhaitez vous vraiment upgrader votre plan pour',
    titleSecondPart: 'Voici un résumé des changements:',
    cancelButton: 'Non, annuler',
    confirmButton: 'Oui, upgrader'
  },
  annually: 'Annuellement',
  monthly: 'Mensuellement',
  storageArgs: 'Stockage',
  billingPeriod: 'Plan',
  annualBill: 'Facturation annuelle',
  dueToday: "Dû aujourd'hui",
  needMoreInfo: "Besoin de plus d'information?",
  contactUs: 'Nous contacter',
  hintWorkspaceName: "Astuce: La plupart des gens utilise leur nom d'artiste ou de société",
  mainActivity: 'Quelle est votre activité principale ?',
  inviteYourTeam: 'Invitez votre équipe',
  skipForNow: 'Ne pas le faire maintenant',
  sendInvitesContinue: 'Envoyer les invitations & continuer',
  addAudioFiles: 'Ajouter des fichiers audio',
  manageLinks: 'Gérer des liens',
  createProjects: 'Créer des projets',
  sharingEmptyTitle: ' et gérer tous les liens de cette page',
  sharingEmptyHelper:
    'Vous pourrez modifier les permissions de chaque lien, ajouter ou supprimer des mots de passe, activer ou désactiver un lien...',
  shareProject: 'Partager ce projet',
  shareInboxLink: 'Partager le lien inbox',
  inboxEmptyTitle: ' afin de recevoir des fichiers de tout le monde',
  inboxEmptySubtitle:
    'Recevoir des fichiers ne compte pas dans votre stockage. Votre inbox est gratuite et illimitée.',
  trackInformation: 'Information de track'
};
