<template>
  <div class="chips-input" v-click-outside="{ hide: addChips }">
    <div class="chips-input-label">{{ label }}</div>
    <div
      class="chips-input-area"
      :class="[error && 'chips-input-area-error']"
      @click.self="focusIn"
    >
      <div :class="getItemClass(item)" v-for="(item, index) in chips" :key="`chips-input-${index}`">
        <input
          type="text"
          :class="getInputClass(item)"
          :style="`width: ${getInputWidth(item.value)}px; max-width: 468px;`"
          v-model="item.value"
          @keyup.13="toggleEdit(item, false, true)"
          @focus="toggleEdit(item, true, false)"
          @blur="toggleEdit(item, false, false)"
        />
        <icon-button
          class="chips-input-area-item-cross"
          icon="ri-close-line"
          btnStyle="tertiary"
          size="small"
          v-if="!item.isEditable"
          @submit="removeHandleClick(item)"
        />
      </div>
      <input
        class="chips-input-area-input"
        type="text"
        ref="input"
        v-model="newChip"
        :style="newChip ? `width: ${getInputWidth(newChip)}px;` : 'width: 10px;'"
        :placeholder="chips.length === 0 && `${placeholder}`"
        @paste="handlePaste"
        @keyup="handleKeyup"
        @keydown.8="removeHandleBackspaceKey"
        @keydown.9="addChips"
      />
    </div>
    <div class="chips-input-error-message" v-if="error">{{ error }}</div>
    <div class="chips-input-error-message" v-if="maxErrorMsg">{{ maxErrorMsg }}</div>
  </div>
</template>

<script>
import { clickOutside } from '@/utils/directives';
import IconButton from '@/components/iconButton';

export default {
  components: {
    IconButton
  },
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    error: {
      type: String,
      required: false,
      default: null
    },
    maxLength: {
      type: Number,
      required: false,
      default: 255
    }
  },
  data() {
    return {
      newChip: '',
      chips: [],
      maxErrorMsg: ''
    };
  },
  directives: {
    clickOutside
  },
  methods: {
    handlePaste(e) {
      const pastedText = e.clipboardData.getData('text');
      setTimeout(() => {
        const separator = [';', ',', '\n', '\r', '\r\n', '\t'];
        const separatorRegexp = /(;|,|\n|\t|\r|\r\n)+/;
        if (separator.some(el => pastedText.includes(el))) {
          const arr = pastedText.split(separatorRegexp).filter(el => el.length > 1);
          arr.forEach(str => {
            if (str.length > 0) {
              this.newChip = str;
              this.addChips();
            }
          });
        } else {
          this.addChips();
        }
      });
    },
    handleKeyup(e) {
      // enter, semicolon, comma
      const keys = [13, 186, 188];
      if (keys.some(key => e.keyCode === key)) {
        // removing the character separator unless it's enter
        this.newChip = e.keyCode === 13 ? this.newChip : this.newChip.slice(0, -1);
        this.addChips();
      }
    },
    addChips() {
      const newChipToAdd = this.newChip.trim();
      if (this.newChip && newChipToAdd.length > 0) {
        this.chips.push({ value: newChipToAdd, isEditable: false });
        this.newChip = '';
        this.handleResult();
      } else {
        this.newChip = '';
      }
    },
    removeHandleClick(item) {
      if (item.value.length > 255) {
        this.maxErrorMsg = '';
      }
      this.chips.splice(this.chips.indexOf(item), 1);
      this.handleResult();
    },
    removeHandleBackspaceKey() {
      if (this.newChip.length === 0) {
        this.chips.pop();
      }
      this.maxErrorMsg = '';
      this.handleResult();
    },
    toggleEdit(item, isEditable, needBlur) {
      const index = this.chips.indexOf(item);
      this.$set(this.chips, index, { ...item, isEditable });
      if (needBlur) this.focusIn();
      this.handleResult();
    },
    getInputWidth(str) {
      const c = document.createElement('canvas');
      const ctx = c.getContext('2d');
      ctx.font = '400 14px manrope';
      return ctx.measureText(str).width;
    },
    focusIn() {
      this.$refs.input.focus();
    },
    handleResult() {
      const result = this.chips.map(el => {
        if (el.value.length > 255) {
          return null;
        }
        return el.value;
      });
      this.$emit('handleResult', result);
    },
    getItemClass(item) {
      let str = 'chips-input-area-item';
      if (item.value.length > this.maxLength) {
        this.maxErrorMsg = `${this.maxLength} characters maximum`;
        str += ' chips-input-area-error';
      }
      if (!item.isEditable) {
        str += ' chips-input-area-regular with-delete-btn';
      }
      return str;
    },
    getInputClass(item) {
      let str = 'chips-input-area-item-input';
      if (!item.isEditable) {
        str += ' chips-input-area-item-regular';
      }
      if (item.value.length > this.maxLength) {
        str += ' chips-input-area-item-regular-error';
      }
      return str;
    }
  }
};
</script>

<style lang="scss" scoped>
.chips-input {
  position: relative;
  font-weight: 400;
  font-size: 14px;

  &-label {
    margin: 0 0 8px 0;
    text-align: left;
    @include label;
    color: $color_neutral_60;
    font-weight: 400;
  }

  &-area {
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    min-height: 49px;
    box-sizing: border-box;
    border: 1px solid $color_neutral_30;
    border-radius: 4px;
    padding: 8px 8px 0 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    cursor: text;

    &-error {
      background: #fff4f4;
      border-color: $color_danger_100;
    }

    &-item {
      display: flex;
      max-width: fit-content;
      align-items: center;
      border-radius: 22px;
      padding: 4px 12px;
      margin: 0 8px 8px 0;
      border: 1px solid $color_neutral_30;

      &-input {
        font-family: inherit;
        font-weight: 400;
        font-size: 14px;
        outline: none;
        border: none;
        background: none;
        filter: none;
      }

      &-regular {
        color: $color_neutral_100;
        cursor: move;
        &-error {
          color: $color_danger_100;
        }
      }

      &-danger {
        color: $color_neutral_0;
        cursor: move;
      }

      .ri-close-circle-fill {
        cursor: pointer;
        margin: 0 0 0 4px;
      }
    }
    .with-delete-btn {
      padding: 0;
      padding-left: 12px;
    }
    .without-delete-btn {
      background-color: $color_neutral_0;
    }

    &-regular {
      background-color: $color_neutral_10;
      color: $color_neutral_100;
      cursor: move;
    }

    &-danger {
      background-color: $color_danger_10;
      border: 1px solid $color_danger_100;
      cursor: move;
      .chips-input-area-item-danger {
        color: $color_danger_100;
      }
    }

    &-input {
      font-weight: 400;
      font-size: 14px;
      color: $color_neutral_100;
      flex-grow: 1;
      outline: none;
      border: none;
      background: none;
      filter: none;
      padding: 0 0 8px 0;
    }
    &-error {
      border: 1px solid $color_danger_100;
      background: #fff4f4;
    }
  }

  &-expand {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    margin: 2px 0 0 0;
    padding: 8px 16px;
    background: $color_neutral_0;
    border: 1px solid $color_neutral_30;
    box-sizing: border-box;
    border-radius: 2px;
    position: absolute;
    z-index: 10;
    @include shadow-down-03;

    &-item {
      cursor: pointer;
      font-size: 14px;
      line-height: 150%;
      color: $color_neutral_100;
      margin: 0 0 16px 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  &-error-message {
    color: $color_danger_100;
    font-size: 12px;
    padding: 4px 0 0 0;
    font-weight: 400;
    text-align: left;
    line-height: 18px;
  }
}

::placeholder {
  color: $color_neutral_40;
  font-size: 14px;
  font-weight: 400;
}
</style>

<style lang="scss">
.chips-input-area-item-cross.rounded-button.tertiary.small {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  .icon {
    color: $color_neutral_60;
  }
}
.chips-input-area-item-cross.rounded-button.tertiary.small:hover {
  .icon {
    color: $color_neutral_100;
  }
}
.chips-input-area-danger {
  .chips-input-area-item-cross.rounded-button.tertiary.small {
    width: 29px;
    height: 29px;
    border-radius: 50%;
    .icon {
      color: $color_danger_100;
    }
  }
}
.chips-input-area-danger {
  .chips-input-area-item-cross.rounded-button.tertiary.small:hover {
    background: $color_danger_10;
  }
}
</style>
