
<div class="access-rights">
  <div @click="show = !show" class="permissions-title">
    <i class="ri-user-settings-fill"></i>
    <div class="title">{{ $t('permissions') }}</div>
    <i v-if="show" class="ri-arrow-drop-down-line"></i>
    <i v-else class="ri-arrow-drop-right-line"></i>
  </div>
  <div v-show="show" class="access-rights-section">
    <div
      class="access-rights-section-toggle"
      v-for="(right, index) in rightsList"
      @click="toggleSwitchButton(null, `${type}-${right.name}`)"
      :key="`right-${index}`"
    >
      <div>{{ $t(right.label) }}</div>
      <switch-button
        :key="`switch-${index}`"
        :defaultValue="accessRights.includes(right.name)"
        :id="`${type}-${right.name}`"
        @click.native.stop
        @toggleSwitchButton="toggleSwitchButton"
      />
    </div>
  </div>
</div>
