
<div class="selectbox-container">
  <div v-if="label" class="title">{{ label }}</div>
  <div :class="{ disabledContainer: isFullDisabled }">
    <div
      class="selectbox"
      :class="{ 'selectbox-area-multiple': isMultipleSelection, disabled: isFullDisabled }"
      v-click-outside="{ hide }"
      tabindex="0"
      @focus="toggleExpand(true)"
      @focusout="toggleExpand(false)"
      @mousedown.prevent
      @keydown="keydownHandler"
      ref="selectbox"
    >
      <div
        class="selectbox-overlay"
        ref="selectbox-overlay"
        v-if="disabled && isMultipleSelection"
      >
        <div class="selectbox-edit">
          <submit-button
            btnStyle="tertiary"
            exception
            danger
            size="small"
            label="Edit"
            @submit="handleEditBtnClick"
          />
        </div>
      </div>
      <div :class="selectClass" @click="toggleFocus" @mousedown.prevent>
        <span class="selectbox-label" v-if="isMultipleSelection">
          {{ this.selectedOption === undefined ? this.placeholder : this.selectedOption }}
        </span>
        <span class="selectbox-label" v-else>
          {{ this.placeholder === this.selectedOption ? this.placeholder : this.selectedOption }}
        </span>
        <i class="ri-arrow-drop-down-line" />
      </div>
      <div
        class="selectbox-expanded"
        :style="`height: ${height}px; overflow-y: ${overflow}; top: ${
          large ? 54 : 46
        }px; opacity: ${isExpanded ? 1 : 0}`"
      >
        <div v-if="isExpanded">
          <div
            class="selectbox-expanded-option"
            v-for="(option, index) in options"
            :key="`d-option-${index}`"
            :class="{ highlighted: index === highlightedOption }"
            @click="setNewSelectedOption(index)"
          >
            <div class="selectbox-expanded-option-label">{{ option.label }}</div>
          </div>
        </div>
      </div>
      <div v-if="errorMessage && showError" class="selectbox-error">{{ errorMessage }}</div>
    </div>
  </div>
</div>
