<template>
  <div class="container">
    <div
      v-for="(item, index) in items"
      :key="`item-${index}`"
      :class="!item.separator ? 'menu-item' : ''"
    >
      <div v-if="item.title" class="title">{{ item.title }}</div>
      <radio-button v-else-if="item.radioButtons" :options="item.radioButtons" />
      <div v-else-if="item.separator" class="separator"></div>
      <div v-else @click.stop="handleClickOption(item)" class="label">
        <i v-if="item.icon" :class="item.icon" class="icon" />
        <span>{{ item.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      required: true,
      type: Array
    }
  },
  methods: {
    handleClickOption(item) {
      if (item.action) {
        item.action();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 241px;
  margin-right: 41px;
  border: 1px solid $color_neutral_30;
  @include shadow-down-03;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: $color_neutral_0;
  font-size: 14px;
  padding: 10px 16px;
}

.title {
  padding: 8px 0;
  color: $color_neutral_60;
}

.icon {
  margin-right: 8px;
}

.label {
  cursor: default;
  color: $color_neutral_100;
  line-height: 100%;
  padding: 0;
  white-space: nowrap;
  height: 40px;
  display: flex;
  align-items: center;
}

.label:hover {
  background-color: $color_neutral_10;
}

.separator {
  background: $color_neutral_40;
  height: 1px;
}
</style>
