
<div class="delete-tracks-modal-body">
  <div v-if="isRemove" class="delete-tracks-modal-body-description">
    {{ $t('removeMultipleTracksDesc', { number: tracksNumber }) }}
    <p class="delete-tracks-modal-body-description-subtext">
      {{ $t('removeMultipleTracksDescSubtext') }}
    </p>
  </div>
  <div v-else class="delete-tracks-modal-body-description">
    <p class="delete-tracks-modal-body-description-label">
      {{ $t('deleteMultipleTracksDesc', { number: tracksNumber }) }}
    </p>
    <text-field
      v-if="!isRemove"
      inputType="text"
      :fieldModel.sync="typeDelete"
      :label="$t('typeDelete')"
      focusOnLoad
      @keyup.enter.native="handleKeyUp"
    />
  </div>
  <div class="delete-tracks-modal-body-buttons">
    <div class="delete-tracks-modal-body-buttons--">
      <submit-button
        btnStyle="tertiary"
        size="medium"
        :label="$t('cancel')"
        @submit="closeModal"
      />
    </div>
    <div class="delete-tracks-modal-body-buttons--">
      <submit-button
        btnStyle="secondary"
        size="medium"
        :label="isRemove ? $t('remove') : $t('delete')"
        :disabled="!isRemove ? disabledDeleteButton : false"
        danger
        @submit="submit"
      />
    </div>
  </div>
</div>
