import get from 'lodash.get';

export const getAllowedFileTypes = config => {
  const mimetypes = get(config, 'uploads.library.libraryFile.track.allowed_files.mimetypes', []);
  const extensions = get(config, 'uploads.library.libraryFile.track.allowed_files.extensions', []);
  const prefixedExtensions = extensions.map(item => `.${item}`);
  return mimetypes.concat(prefixedExtensions);
};

const getAudioFileList = (fileList, allowedFileTypes) => {
  const validFiles = fileList.filter(file => {
    const fileName = file.name.toLowerCase();
    const extension = fileName.substr(fileName.lastIndexOf('.'));
    return allowedFileTypes.includes(extension);
  });
  return validFiles;
};

const getAttachmentFileList = (fileList, allowedFileTypes) => {
  return (
    fileList
      // Filter hidden files
      .filter(file => file.name[0] !== '.')
      .map(file => {
        const fileName = file.name.substr(0, file.name.lastIndexOf('.'));
        const extension = file.name.substr(file.name.lastIndexOf('.')).toLowerCase();
        const newFile = new File([file], fileName + extension, {
          lastModified: file.lastModified,
          size: file.size,
          type: file.type
        });
        return !allowedFileTypes.includes(extension) && newFile;
      })
      .filter(Boolean)
  );
};

export const sortUploadedFiles = (files, allowedFileTypes = ['']) => {
  const cleanedFiles = files.filter(file => !file.name.startsWith('.'));
  return {
    audioFiles: getAudioFileList(cleanedFiles, allowedFileTypes),
    attachmentFiles: getAttachmentFileList(cleanedFiles, allowedFileTypes)
  };
};

export const getFileSize = aSize => {
  if (aSize < 1) return '0 KB';
  const size = Math.abs(parseInt(aSize, 10));
  const def = [
    [1, 'BYTE'],
    [1000, 'KB'],
    [1000 * 1000, 'MB'],
    [1000 * 1000 * 1000, 'GB'],
    [1000 * 1000 * 1000 * 1000, 'TB'],
    [1000 * 1000 * 1000 * 1000 * 1000, 'PB']
  ];
  let value = 0;
  for (let i = 0; i < def.length; i += 1) {
    if (size < def[i][0]) {
      value = (size / def[i - 1][0]).toFixed(1);
      value = value.replace('.0', '');
      value = value.replace('.', ',');
      return `${value} ${def[i - 1][1]}`;
    }
  }
  return null;
};
