<template>
  <div class="sharings" v-if="ShareList">
    <div v-if="displayPreview" class="preview-share" @click="togglePreview(false)">
      <div class="close-btn">
        <icon-button icon="ri-close-line" btnStyle="secondary" size="large" />
      </div>
      <div class="iframe-container">
        <iframe @click.stop class="iframe" :src="previewUrl" frameborder="0"></iframe>
      </div>
    </div>
    <table v-if="sharings.length > 0" class="sharings-content" data-test="sharingsList">
      <thead class="sharings-content-header">
        <tr class="sharings-content-header-row">
          <th
            v-for="(column, id) in thList"
            :key="`${id}-th`"
            class="sharings-content-header-left"
            :class="column.class"
            :title="$t(column.title)"
          >
            {{ $t(column.name) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="sharings-content-row"
          :class="[sharing.isNew ? 'new-link' : '']"
          v-for="(sharing, index) in sharings"
          :key="`sharing-${sharing.id}`"
          @mouseover="setHoverId(index)"
          @mouseleave="setHoverId(null)"
          :title="getTitle(sharing)"
        >
          <td
            :class="sharing.is_public ? 'sharings-content-row-link' : 'sharings-content-row-email'"
          >
            <sharing-name
              :name="sharing.email || sharing.custom_name"
              :shareType="getShareType(sharing)"
              :hasPassword="sharing.settings.access_password !== null"
              :expireIn="getShareExpireIn(sharing)"
              :isNew="sharing.isNew"
              :shareDesactivated="
                !sharingEnables.find(s => s[sharing.id] !== undefined)[sharing.id]
              "
            />
          </td>
          <td
            class="sharings-content-row-checkbox"
            v-for="accessRight in accessRights"
            :key="`${accessRight}-${index}`"
          >
            <div class="sharings-content-row-center">
              <checkbox
                :index="0"
                :id="`${accessRight}-${index}`"
                :isChecked="
                  sharingAccessRights.find(s => s[sharing.id])[sharing.id].includes(accessRight)
                "
                @toggleCheckbox="configureShare(sharing.id, accessRight)"
              />
            </div>
          </td>
          <td class="sharings-content-row-checkbox">
            <div class="sharings-content-row-center">
              <switch-button
                :defaultValue="sharingEnables.find(s => s[sharing.id] !== undefined)[sharing.id]"
                @toggleSwitchButton="configureEnable(sharing.id, ...arguments)"
              />
            </div>
          </td>
          <td class="sharings-content-row-checkbox">
            <div class="link-column">
              <copy-btn @copyLink="copyLink(sharing)" class="link-column-mr" />
              <icon-button
                class="link-column-iconbtn link-column-mr"
                icon="ri-eye-line"
                btnStyle="tertiary"
                size="small"
                :disabled="!sharingEnables.find(s => s[sharing.id] !== undefined)[sharing.id]"
                @submit="navigateToPreview(sharing)"
              />
              <icon-button
                class="link-column-iconbtn"
                icon="ri-settings-3-line"
                btnStyle="tertiary"
                size="small"
                @submit="openShareSettingsModal(sharing)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="sharings.length < 1" class="no-share">
      <div v-if="entityType === 'project'">
        <empty-sharing
          :title="$t('sharingEmptyTitle')"
          :description="$t('sharingEmptyHelper')"
          placeholderType="sharing"
          :cloudedTextContent="$t('shareProject')"
          :cloudedTextColor="{
            stroke: '#FF9143',
            fill: '#FFF9EB'
          }"
        >
          <template v-slot:button>
            <btn-dropdown
              class="upload-to-library-btn"
              :options="shareBtnOptions"
              :btnProps="{
                style: 'primary',
                size: 'medium',
                label: 'share',
                hideDropdown: true,
                icon: 'ri-share-forward-fill'
              }"
              @action="openShareModal"
            />
          </template>
        </empty-sharing>
      </div>
      <div v-else class="no-share-container" data-test="no-share-container">
        <div class="no-share-icon">
          <i class="ri-group-line"></i>
        </div>
        <div class="no-share-title">
          {{ $t('noShareTitle', { type: typeName }) }}
        </div>
        <div class="no-share-subtitle">
          {{ $t('noShareSubtitle', { type: typeName }) }}
        </div>
        <btn-dropdown
          class="upload-to-library-btn"
          :options="shareBtnOptions"
          :btnProps="{
            style: 'primary',
            size: 'medium',
            label: 'share',
            hideDropdown: true,
            icon: 'ri-share-forward-fill'
          }"
          @action="openShareModal"
        />
      </div>
    </div>
  </div>
  <div v-else class="loader"><spinner-without-progress color="grey" :size="32" /></div>
</template>

<script>
import * as timeago from 'timeago.js';

import { formatDate, newFormatDate, moreThanAYear } from '@/utils/functions/time';
import { mapGetters } from 'vuex';
import { getUserInitials, getUserNames, getUserPicture } from '@/utils/functions/user';
import { trackEvent } from '@/utils/functions/analytics';
import { SHARE_LIST } from '@/graphql/queries/share';
import { bus } from '@/utils/bus';
import isEqual from 'lodash.isequal';
import { configureShare, switchEnableShare } from '@/utils/functions/share';
import { capitalize } from '@/utils/functions/global';
import ShareModal from '@/containers/modals/share';
import ShareSettingsModal from '@/containers/modals/shareSettings';
import EmptySharing from '@/containers/emptyView';
import BtnDropdown from '@/containers/dropdowns/submitBtn';
import SharingName from './sharingName';
import CopyBtn from './copyBtn';

export default {
  components: {
    SharingName,
    CopyBtn,
    BtnDropdown,
    EmptySharing
  },
  props: {
    entityType: {
      type: String,
      required: false,
      default: 'project'
    },
    entityIds: {
      type: Array,
      required: true
    },
    entityName: {
      type: String,
      required: false,
      default: ''
    },
    shareHash: {
      type: String,
      required: false,
      default: undefined
    }
  },
  data() {
    return {
      sharings: [],
      accessRights: [
        ...(this.entityType === 'project'
          ? ['show_project_description', 'download_and_display_attachments']
          : []),
        'stream_mp3',
        'tracks_metadata',
        'download_as_mp3',
        'download_as_source_audio'
      ],
      sharingAccessRights: [],
      sharingEnables: [],
      hoverId: null,
      queryLoaded: false,
      previewUrl: null,
      displayPreview: false,
      shareBtnOptions: [
        {
          label: this.$t('getQuickLink'),
          subText: this.$t('getQuickLinkSub'),
          icon: 'ri-flashlight-fill',
          action: 'openShareModal',
          params: {
            defaultTab: 0
          }
        },
        {
          separator: true
        },
        {
          label: this.$t('createIndividualLinks'),
          subText: this.$t('createIndividualLinksSub'),
          icon: 'ri-links-fill',
          action: 'openShareModal',
          params: {
            defaultTab: 1
          }
        },
        {
          separator: true
        },
        {
          label: this.$t('sendByEmail'),
          subText: this.$t('sendByEmailSub'),
          icon: 'ri-mail-line',
          action: 'openShareModal',
          params: {
            defaultTab: 2
          }
        }
      ]
    };
  },
  apollo: {
    ShareList: {
      query: SHARE_LIST,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          workspaceId: this.currentWorkspace.id,
          type: this.entityType,
          ...(this.shareHash === undefined && { entities: this.entityIds }),
          ...(this.shareHash !== undefined && { hash: this.shareHash })
        };
      },
      result() {
        this.queryLoaded = true;
        if (!this.ShareList) {
          return;
        }
        if (
          this.sharings.length !== this.ShareList.length ||
          !isEqual(this.sharings, this.ShareList)
        ) {
          this.sharings = this.ShareList;
          this.sharings.sort((a, b) => b.created_at - a.created_at);
          if (this.customLinkJustCreated) {
            this.sharings = this.sharings.map(sharing => {
              if (this.customLinkJustCreated.includes(sharing.custom_name)) {
                return { ...sharing, isNew: true };
              }
              return sharing;
            });
          }
          this.sharingAccessRights = this.sharings.map(sharing => {
            const key = sharing.id;
            const obj = {};
            const val = sharing.settings.access_rights;
            obj[key] = val;
            return obj;
          });
          this.sharingEnables = this.sharings.map(sharing => {
            const key = sharing.id;
            const obj = {};
            const val = sharing.is_enabled;
            obj[key] = val;
            return obj;
          });
        }
      }
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'customLinkJustCreated', 'userPrimaryEmail']),
    isProject() {
      return this.entityType === 'project';
    },
    thList() {
      return [
        { name: 'sharingName' },
        this.isProject ? { name: 'description', title: 'description' } : undefined,
        this.isProject ? { name: 'media', title: 'media' } : undefined,
        { name: 'streaming', title: 'streaming' },
        { name: 'info', title: 'Include track information' },
        { name: 'mp3Down', title: 'mp3Download' },
        { name: 'sourceDown', title: 'sourceDownload' },
        { name: 'status', title: 'status', class: 'share-status' },
        { name: 'link', title: 'link', class: 'link-header' }
      ].filter(Boolean);
    },
    typeName() {
      let name;
      switch (this.entityType) {
        case 'project':
          name = 'project';
          break;
        case 'support':
          name = 'album';
          break;
        case 'artist':
          name = 'artist';
          break;
        case 'track':
          name = 'track';
          break;
        case 'tracks':
          name = 'tracks';
          break;

        default:
          break;
      }
      return name;
    }
  },
  watch: {
    customLinkJustCreated() {
      if (this.customLinkJustCreated) {
        this.sharings = this.sharings.map(sharing => {
          if (this.customLinkJustCreated.includes(sharing.custom_name)) {
            return { ...sharing, isNew: true };
          }
          return sharing;
        });
      }
    }
  },
  created() {
    bus.$on('shareEntitySucess', () => {
      this.$apollo.queries.ShareList.refetch();
    });
  },
  beforeDestroy() {
    bus.$off('disableTrackSelection');
    this.$store.commit('setCustomLinkJustCreated', []);
  },
  methods: {
    togglePreview(bool) {
      this.displayPreview = bool;
      if (!bool) {
        this.previewUrl = null;
      }
    },
    getShareType(share) {
      let type = share.email ? 'mail' : 'public';
      if (!share.email && !share.is_public) {
        type = 'individual';
      }
      return type;
    },
    getShareExpireIn(share) {
      if (share.valid_to) {
        const OneDayJS = 86400000;
        const timestampLeft = share.valid_to * 1000 - Date.now();
        const dayLeft = Math.round(timestampLeft / OneDayJS);
        return dayLeft.toString();
      }
      return null;
    },
    getCreatedBy(userId) {
      if (!this.isUserInWorkspace(userId)) {
        return {
          initial: '?',
          name: this.$t('invalidUserShare'),
          size: 'extraSmall'
        };
      }
      const user = this.currentWorkspace.members.find(item => item.user_id === userId);

      return {
        url: getUserPicture(user),
        name: getUserNames(user),
        initial: getUserInitials(user),
        size: 'extraSmall'
      };
    },
    getCreatedAt(createdAt) {
      return {
        relative: timeago.format(createdAt * 1000),
        date: newFormatDate(createdAt * 1000),
        displayDate: moreThanAYear(createdAt * 1000)
      };
    },
    getTitle(sharing) {
      return `${this.$t('createdBy')} ${this.getCreatedBy(sharing.shared_by).name} ${
        this.getCreatedAt(sharing.created_at).relative
      }`;
    },
    setHoverId(index) {
      this.hoverId = index;
    },
    copyLink(sharing) {
      const dummy = document.createElement('textarea');
      document.body.appendChild(dummy);
      dummy.value = sharing.link;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);

      let selectMode;
      if (this.entityType === 'track') {
        if (sharing.entities.ids.length > 1) {
          selectMode = 'multiple';
        } else {
          selectMode = 'single';
        }
      }
      const shareEventName =
        this.entityType === 'support'
          ? 'Share Album Copy'
          : `Share ${capitalize(this.entityType)} Copy`;

      trackEvent(shareEventName, {
        category: 'sharing',
        unique_hash: sharing.share_hash,
        share_id: sharing.id,
        share_type: this.getShareType(sharing),
        select_mode: selectMode,
        security_status: sharing.settings.access_password ? 'on' : 'off',
        position: 'sharing links list',
        email: this.userPrimaryEmail
      });
    },
    generateSharedBy(userId) {
      const user = this.currentWorkspace.members.find(item => item.user_id === userId);
      return getUserPicture(user);
    },
    generateUserInitials(userId) {
      const user = this.currentWorkspace.members.find(item => item.user_id === userId);
      return getUserInitials(user);
    },
    generateUserName(userId) {
      const user = this.currentWorkspace.members.find(item => item.user_id === userId);
      return getUserNames(user);
    },
    isUserInWorkspace(userId) {
      const user = this.currentWorkspace.members.find(item => item.user_id === userId);
      return user !== undefined;
    },
    generateCreatedAtDate(timestamp) {
      if (!timestamp) {
        return null;
      }
      return formatDate(this.$i18n.locale, timestamp);
    },
    configureShare(shareId, accessRight) {
      const sharing = this.sharingAccessRights.find(s => s[shareId]);
      if (!sharing[shareId].includes(accessRight)) {
        sharing[shareId].push(accessRight);
      } else {
        const index = sharing[shareId].indexOf(accessRight);
        sharing[shareId].splice(index, 1);
      }
      configureShare(this, {
        shareId,
        access_rights: sharing[shareId],
        access_password: this.sharings.find(share => share.id === shareId).settings.access_password
      });
    },
    configureEnable(shareId) {
      const isEnabled = this.sharingEnables.find(s => s[shareId] !== undefined)[shareId];
      this.sharingEnables.find(s => s[shareId] !== undefined)[shareId] = !isEnabled;
      if (this.entityType === 'project') {
        bus.$emit('handleShareEnableMutation', {
          projectId: this.$route.params.id,
          shareIds: [shareId],
          enable: !isEnabled
        });
      } else {
        switchEnableShare(this, shareId, !isEnabled);
      }
    },
    navigateToPreview(sharing) {
      this.previewUrl = `${sharing.link}&preview=1`;
      this.togglePreview(true);
    },
    openShareSettingsModal(sharing) {
      bus.$emit('displayModal', {
        size: 'medium',
        isVisible: true,
        component: ShareSettingsModal,
        props: [
          {
            name: 'submit',
            value: () => {
              this.$apollo.queries.ShareList.refetch();
            }
          },
          { name: 'contactInfo', value: sharing.email },
          { name: 'password', value: sharing.settings.access_password },
          { name: 'shareId', value: sharing.id },
          { name: 'shareAccessRight', value: sharing.settings.access_rights },
          sharing.custom_name !== undefined
            ? { name: 'shareCustomName', value: sharing.custom_name }
            : []
        ].filter(Boolean),
        title: 'Link settings'
      });
    },
    openShareModal(action, params) {
      const { defaultTab } = params;
      let modalTitle;
      const { entityType, entityIds } = this;
      switch (entityType) {
        case 'project':
          modalTitle = 'shareProjectName';
          break;
        case 'support':
          modalTitle = 'shareAlbumName';
          break;
        case 'artist':
          modalTitle = 'shareArtistName';
          break;
        case 'track':
          modalTitle = entityIds.length > 1 ? 'shareTracksName' : 'shareTrackName';
          break;

        default:
          break;
      }
      bus.$emit('displayModal', {
        size: 'medium',
        isVisible: true,
        component: ShareModal,
        props: [
          { name: 'entityName', value: this.entityName },
          { name: 'entityIds', value: entityIds },
          { name: 'entityType', value: entityType },
          defaultTab ? { name: 'defaultTab', value: defaultTab } : []
        ].filter(Boolean),
        title: this.$t(modalTitle, { name: this.entityName })
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.sharings {
  font-size: 14px;
  color: $color_neutral_100;
  padding: 0 0 0 40px;

  .preview-share {
    padding: 0 0 0 40px;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: map-get($z-index, modal);
    background-color: rgba(37, 36, 40, 0.6);
    .close-btn {
      position: absolute;
      top: 24px;
      right: 40px;
      .rounded-button {
        background-color: white;
        border-radius: 50%;
      }
    }
    .iframe-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .iframe {
        border-radius: 16px;
        background: $color_neutral_0;
        width: 100%;
        height: calc(100% - 128px);
        margin: 64px 104px;
      }
    }
  }

  &-action {
    margin: 24px 0 0 0;
    display: flex;
    align-items: center;

    &-title {
      @include subtitle-1;
      margin: 0 24px 0 0;
    }
  }

  &-content {
    margin: 24px 0 0 0;
    width: 100%;
    min-width: 800px;
    table-layout: fixed;
    border-collapse: separate;

    &-header {
      position: relative;
      z-index: map-get($z-index, list-header);
      & th {
        position: sticky;
        top: 0;
        background: $color_neutral_0;
      }
      border-bottom: 1px solid $color_neutral_30;

      &-row {
        cursor: default;
        height: 40px;

        & th {
          background: $color_neutral_0;
          border-bottom: 1px solid $color_neutral_30;
        }

        & th:first-child {
          padding: 0 8px;
          width: 20%;
        }
        & th:last-child {
          padding-right: 0 8px;
        }
      }
      .shared-header {
        width: 10%;
      }
      &-left {
        vertical-align: middle;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-right: 1px solid $color_neutral_30;
        padding: 0 8px;
        font-weight: 600;
      }

      &-center {
        vertical-align: middle;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-right: 1px solid $color_neutral_30;
        padding: 0 8px;
        font-weight: 600;
      }
    }

    &-row {
      height: 64px;
      border-bottom: 1px dotted $color_neutral_40;
      line-height: normal;

      & td:first-child {
        padding-left: 8px;
        padding-right: 2px;
      }
      & td:last-child {
        padding-right: 8px;
      }

      &-link {
        vertical-align: middle;
      }

      &-text {
        cursor: default;
      }

      &-sharedBy {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: center;
        align-items: center;

        & span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &-sharedBy-avatar {
        display: inline-block;
        border-radius: 20px;
        margin-right: 8px;
      }

      &-email {
        vertical-align: middle;
      }

      &-email-disable {
        opacity: 0.4;
      }

      &-checkbox {
        height: 40px;
        vertical-align: middle;
        text-align: center;
        padding: 0 8px;
      }
      .link-column {
        display: flex;
        &-mr {
          margin-right: 8px;
        }
      }

      &-center {
        display: flex;
        justify-content: center;
      }
    }
  }
  .flex-jc-center {
    display: flex;
    justify-content: center;
  }
  .public-link {
    overflow: hidden;
    align-items: center;
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.ri-lock-password-fill {
  color: $color_neutral_40;
  margin-left: 8px;
  cursor: pointer;
}
.no-border {
  border: none;
}
.link-header {
  border: none;
  width: 217px;
}
.share-status {
  width: 48px;
}

.no-share {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-title {
    @include heading-6;
    margin-bottom: 8px;
  }
  &-subtitle {
    @include body-1;
    color: $color_neutral_60;
    margin-bottom: 24px;
  }
  &-icon {
    font-size: 33px;
    color: $color_neutral_40;
    margin-bottom: 24px;
  }
}

.no-share-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(99vh - 500px);
}

.new-link {
  font-weight: 600;
}

.loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style lang="scss">
.link-column {
  .link-column-iconbtn.tertiary {
    border-radius: 50%;
    border: 1px solid $color_neutral_40;
    :hover {
      border: 1px solid $color_neutral_60;
    }
    i:hover {
      border: none;
    }
  }
  .link-column-iconbtn.tertiary.disabled {
    opacity: 0.4;
  }
}
.new-link {
  .sharings-content-row-checkbox {
    .simple {
      .date {
        font-weight: 600;
      }
    }
  }
}
</style>
