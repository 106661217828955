
<div data-test="attachmentList">
  <div v-if="isFullWidth" class="header-container">
    <div class="header-container-column1">#</div>
    <div class="header-container-column2">Title</div>
    <div class="header-container-column3">Kind</div>
    <div class="header-container-column4">Size</div>
  </div>
  <draggable
    v-model="draggableAttachments"
    class="draggable"
    @start="startDrag"
    @end="endDrag"
    @update="updateDrag"
    :disabled="isDragAndDropDisabled"
    chosen-class="chosen"
  >
    <div v-for="(attachment, idx) in draggableAttachments" :key="attachment.id">
      <list-row
        :item="attachment"
        :columns="columns"
        :rowIndex="idx"
        :height="48"
        disableSelection
        @handleEmit="handleEmit"
        @click.native="handleRowClick($event, idx)"
      />
    </div>
  </draggable>
</div>
