<template>
  <div :class="panelClass" v-if="visible">
    <i class="ri-information-line" />
    <p class="panel-content">{{ message }}</p>
    <i v-if="canClose" alt="Close panel" class="ri-close-line panel-icon" @click="close" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: true
    };
  },
  methods: {
    close() {
      this.onDismiss();
      this.visible = false;
    }
  },
  props: {
    message: {
      type: String,
      required: true
    },
    canClose: {
      type: Boolean,
      required: false,
      default: false
    },
    onDismiss: {
      type: Function,
      required: false,
      default: () => {}
    },
    PanelStyle: {
      type: String,
      required: false,
      default: 'primary'
    }
  },
  computed: {
    panelClass() {
      let str = 'panel';
      if (this.PanelStyle === 'warning') str += ' warning';
      return str;
    }
  }
};
</script>

<style lang="scss" scoped>
.panel {
  background-color: $color_primary_10;
  display: flex;
  align-items: center;
  padding: 9.5px 16px;
  font-size: 14px;
  color: $color_primary_100;
  max-width: 456px;
  &-content {
    margin: 0 17px;
    flex-grow: 1;
  }

  &-icon {
    cursor: pointer;
  }
}

.panel.warning {
  background-color: $color_warning_10;
  color: $color_warning_110;
}
</style>
