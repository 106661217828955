<template>
  <div>
    <div class="content">
      <h4 class="header">{{ $t('billingDetails.address') }}</h4>
      <div v-if="!address">
        <spinner-without-progress color="grey" :size="32" />
      </div>
      <div v-else>
        <div v-if="hasCompleteAddress()">
          <div class="value">
            <p>{{ address.line1 }}</p>
            <p>{{ address.line2 }}</p>
            <p>{{ address.postal_code }} {{ address.city }}</p>
            <p v-if="address.state">{{ address.state }}</p>
            <p>{{ address.country }}</p>
          </div>
          <h4 class="header header-company">{{ $t('billingDetails.company') }}</h4>
          <div class="value">{{ company }}</div>
          <div v-if="vat">
            <h4 class="header header-company">{{ $t('billingDetails.tax') }}</h4>
            <div class="value">{{ vat.value }}</div>
          </div>
        </div>
        <div v-else>
          <p>No address saved.</p>
        </div>
        <submit-button
          class="button"
          btnStyle="tertiary"
          :exception="true"
          size="medium"
          :label="$t('billingDetails.changeAddressAndCompany')"
          @submit="changePaymentMethod"
        />
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash.get';
import { mapGetters } from 'vuex';

import { bus } from '@/utils/bus';
import { isFreeTrial } from '@/utils/functions/workspace';
import ChangeStripeUserAddress from '@/containers/modals/changeStripeUserAddress';

export default {
  props: {
    address: {
      type: Object,
      required: false,
      default: () => ({})
    },
    company: {
      type: String,
      required: false,
      default: undefined
    },
    email: {
      type: String,
      required: false,
      default: undefined
    },
    prepareForPayment: {
      type: Function,
      required: true
    },
    hasPaymentMethod: {
      type: Boolean,
      required: true
    },
    vat: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace'])
  },
  methods: {
    hasCompleteAddress() {
      return (
        get(this.address, 'line1', '').length > 0 &&
        get(this.address, 'postal_code', '').length > 0 &&
        get(this.address, 'city', '').length > 0
      );
    },
    changePaymentMethod() {
      if (isFreeTrial(this.currentWorkspace) && !this.hasPaymentMethod) {
        this.prepareForPayment();
      } else {
        bus.$emit('displayModal', {
          component: ChangeStripeUserAddress,
          isVisible: true,
          size: 'medium',
          title: 'Change address and company info',
          props: [
            { name: 'email', value: this.email },
            { name: 'defaultAddress', value: this.address },
            { name: 'defaultCompany', value: this.company },
            { name: 'defaultVat', value: this.vat }
          ]
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  margin-bottom: 56px;
}

.header {
  font-family: 'manrope';
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: $color_neutral_60;
  margin-bottom: 8px;
}

.header-company {
  padding-top: 16px;
}

.value {
  font-size: 14px;
  line-height: normal;
}

.button {
  position: absolute;
  left: 12px;
  bottom: 22px;
}

.loader {
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
