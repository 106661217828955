
<div class="dropdown" v-click-outside="{ hide }">
  <div class="dropdown-button" @click="toggleExpand">
    <avatar :pictureUrl="pictureUrl" :initial="initial" pictureSize="workspaceSmall" square />
    <div class="dropdown-button-name">
      <span class="dropdown-button-name-label" :title="name">{{ name }}</span>
      <i class="ri-arrow-drop-down-line" />
    </div>
  </div>
  <div
    class="dropdown-expanded"
    :class="!isExpanded && 'dropdown-close'"
    :style="`max-height: ${height}px;`"
  >
    <div class="dropdown-expanded-options" @click="hide" v-if="isExpanded">
      <div class="dropdown-expanded-options-header">
        <div class="dropdown-expanded-options-header-left">
          <avatar :pictureUrl="pictureUrl" :initial="initial" square />
        </div>
        <div class="dropdown-expanded-options-header-right">
          <div class="dropdown-expanded-options-header-right-title">{{ headerOption.title }}</div>
          <div class="dropdown-expanded-options-header-right-subtitle">
            {{ headerOption.subtitle }}
          </div>
        </div>
      </div>
      <div
        class="dropdown-expanded-options-item"
        v-for="(option, index) in options"
        :key="`opt-${index}`"
        @click="option.action"
      >
        <i v-if="option.icon" :class="option.icon" class="dropdown-expanded-options-item-icon" />
        <span class="dropdown-expanded-options-item-label">{{ option.label }}</span>
      </div>
      <ScrollShadow style="max-height: 155px">
        <div
          v-if="(workspaces && workspaces.length > 1) || (hubs && hubs.length > 0)"
          class="dropdown-expanded-options-workspaces"
        >
          <div class="dropdown-expanded-options-workspaces-title">WORKSPACES</div>
          <div class="dropdown-expanded-options-workspaces-logo">
            <div
              class="dropdown-expanded-options-workspaces-logo-item"
              v-for="(workspace, index) in workspaces"
              :key="`work-${index}`"
              @click="$emit('handleWorkspaceClick', index)"
              v-tooltip="workspace.name"
            >
              <avatar
                :pictureUrl="workspace.pictureUrl"
                :initial="workspace.name.substring(0, 1).toUpperCase()"
                pictureSize="small"
                square
                hasHover
                :isCurrent="workspace.id === currentWorkspaceId"
              />
            </div>
          </div>
        </div>
      </ScrollShadow>
      <div v-if="hubs && hubs.length > 0" class="dropdown-expanded-options-workspaces">
        <div class="dropdown-expanded-options-workspaces-title">HUBS</div>
        <div class="dropdown-expanded-options-workspaces-logo">
          <div
            class="dropdown-expanded-options-workspaces-logo-item"
            v-for="(hub, index) in hubs"
            :key="`work-${index}`"
            @click="$emit('handleHubClick', index)"
            v-tooltip="hub.name"
          >
            <avatar
              :pictureUrl="hub.logo"
              :initial="hub.name.substring(0, 1).toUpperCase()"
              pictureSize="small"
              square
              hasHover
            />
          </div>
        </div>
      </div>
      <div class="dropdown-expanded-options-footer" @click="footerOption.action">
        <div class="dropdown-expanded-options-footer-button">
          <i class="ri-add-fill" />
        </div>
        <div class="dropdown-expanded-options-footer-title">{{ footerOption.title }}</div>
      </div>
    </div>
  </div>
</div>
