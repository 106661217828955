<template>
  <div v-if="trackList && trackList.length > 0">
    <header-list
      :columns="columns"
      :sortColumn="false"
      :isSticky="isSticky"
      :stickyPosition="stickyPosition"
      :hasSelectAll="false"
      :itemsSelected="[]"
      :displayUnselect="false"
      @handleEmit="handleEmit"
    />
    <div v-for="(track, idx) in trackList" :key="track.id">
      <list-row
        :item="track"
        :columns="columns"
        :rowIndex="idx"
        :height="48"
        :id="track.id"
        :playingTrack="playingTrack || {}"
        :selectedItems="[]"
        :disableSelection="true"
        @handleEmit="handleEmit"
        @setPlayingTrackFromRow="setPlayingTrackFromRow"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash.get';

import { get as getRoute } from '@/router/routes';
import { bus } from '@/utils/bus';
import StreamableUnavailable from '@/containers/modals/streamableUnavailable';

import { LIBRARY_FILE_STREAMABLE } from '@/graphql/queries/library';

import { clickOutside } from '@/utils/directives';

export default {
  directives: {
    clickOutside
  },
  props: {
    trackList: {
      type: Array,
      required: false,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    isSticky: {
      type: Boolean,
      required: false,
      default: false
    },
    stickyPosition: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      isHoverId: null,
      listActions: [
        {
          icon: 'ri-information-line',
          tooltip: this.$t('editInformation'),
          emit: 'handleInformationBtnClick'
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['playlist', 'playingTrack', 'currentWorkspace', 'isEditPanelOpened']),
    columns() {
      return [
        {
          type: 'component',
          name: 'text-action',
          header: this.$t('title'),
          skeleton: 'line',
          min: '200px',
          max: '1fr',
          padding: [0, 8, 0, 8],
          noStop: true,
          containerWidth: true,
          emits: ['handleInformationBtnClick', 'setPlayingTrack'],
          props: [
            {
              name: 'rowId',
              match: 'id',
              value: null
            },
            {
              name: 'isHoverId',
              match: 'isHoverId',
              value: this.isHoverId
            },
            {
              name: 'artwork',
              match: 'imgSrc',
              value: null
            },
            {
              name: 'playingTrack',
              match: 'playingTrack',
              value: this.playingTrack || {}
            },
            {
              name: 'text',
              match: 'title',
              value: null
            },
            {
              name: 'actions',
              match: 'actions',
              value: this.listActions
            },
            {
              name: 'weightNormal',
              match: 'weightNormal',
              value: true
            }
          ]
        },
        {
          type: 'text',
          name: 'artists',
          header: this.$t('artist'),
          skeleton: 'line',
          min: '105px',
          max: '1fr',
          list: true,
          span: 1,
          route: { name: 'artist', id: 'id' },
          padding: [0, 8, 0, 8]
        },
        {
          type: 'text',
          name: 'supports',
          header: this.$t('album'),
          skeleton: 'line',
          min: '105px',
          max: '1fr',
          list: true,
          span: 1,
          route: { name: 'album', id: 'id' },
          padding: [0, 8, 0, 8]
        },
        {
          type: 'text',
          name: 'humanDuration',
          headerPrefixIcon: 'ri-time-line',
          skeleton: 'line',
          span: 1,
          padding: [0, 8, 0, 8],
          min: '95px',
          max: '95px'
        }
      ];
    }
  },
  created() {
    if (Object.keys(this.playlist).length === 0 && this.trackList.length > 0) {
      this.$store.commit('changePlaylist', this.trackList);
    }
  },
  methods: {
    handleEmit(params) {
      if (this[params.methodName]) this[params.methodName](params.param);
    },
    handleInformationBtnClick({ itemId }) {
      if (this.$isWorkspaceLockedVerification(this)) return;
      if (this.isEditPanelOpened) {
        this.$emit('toggleEditMode', []);
        return;
      }
      this.$emit('toggleEditMode', [itemId]);
    },
    setIsHoverId(trackId) {
      this.isHoverId = trackId;
    },
    setPlayingTrack(trackInfos) {
      const track = this.trackList.find(item => item.id === trackInfos.id);
      if (track.isStreamableAvailable === true) {
        this.$store.commit('changePlaylist', this.trackList);
        this.$store.commit('changePlayingTrack', trackInfos);
      } else {
        this.$apollo
          .query({
            query: LIBRARY_FILE_STREAMABLE,
            fetchPolicy: 'network-only',
            variables: { libraryFilesIds: trackInfos.id }
          })
          .then(data => {
            const updatedStreamableAvailable = get(
              data,
              'data.LibraryFilesDetails[0].status_streamable_transcoded',
              false
            );
            if (updatedStreamableAvailable === false) {
              bus.$emit('displayModal', {
                title: 'Track is not ready yet',
                size: 'small',
                isVisible: true,
                component: StreamableUnavailable
              });
            }
          });
      }
    },
    setPlayingTrackFromRow(track) {
      if (this.playingTrack.id === track.id) {
        bus.$emit('restartAudio');
      } else {
        this.$store.commit('changePlaylist', this.trackList);
        this.$store.commit('changePlayingTrack', track);
      }
    },
    navigateTo(param) {
      const { id, name } = param;
      const route = id ? getRoute(name, { id }) : getRoute(name);
      if (this.$router.history.current.params.id) {
        if (this.$router.history.current.params.id !== id) this.$router.push(route);
      } else if (this.$router.history.current.name !== name) this.$router.push(route);
    }
  }
};
</script>
