<template>
  <div>
    <text-field
      v-if="disableDatePicker"
      style="width: 100%"
      inputType="text"
      :placeholder="selectedDate ? formatTimestamp(selectedDate) : '< multiple >'"
      :withEditButton="true"
      @editBtnClick="handleEditBtnClick"
      :editButtonEmit="true"
    />
    <div v-else :class="{ 'date-picker': true, large: large }">
      <datepicker
        ref="vjsDatepicker"
        format="dd/MM/yyyy"
        monday-first
        :value="selectedDate"
        :language="lang"
        :disabled="isDisabled"
        :disabled-dates="disabledDates"
        @selected="datePicked"
        @keydown.native="handleBackSpace"
      ></datepicker>
      <i class="ri-calendar-2-line" @click="openPicker"></i>
    </div>
  </div>
</template>

<script>
import { en, fr } from 'vuejs-datepicker/dist/locale';
import Datepicker from 'vuejs-datepicker';
import TextField from '@/components/inputs/textField';

export default {
  components: {
    Datepicker,
    TextField
  },
  props: {
    selectedDate: {
      type: Number,
      required: false,
      default: null
    },
    local: {
      type: String,
      required: false,
      default: 'en'
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    disabledDates: {
      type: Object,
      required: false
    },
    large: {
      type: Boolean,
      required: false,
      default: false
    },
    withEditButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      en,
      fr,
      disableDatePicker: this.withEditButton || false
    };
  },
  computed: {
    lang() {
      let lang = this.en;
      switch (this.local) {
        case 'fr':
          lang = this.fr;
          break;
        case 'en':
        default:
          lang = this.en;
      }
      return lang;
    }
  },
  methods: {
    handleBackSpace(e) {
      if (e.keyCode === 8) {
        this.datePicked(null);
      }
    },
    datePicked(date) {
      if (date) {
        this.$emit('datePicked', Date.parse(date), date);
      } else {
        this.$emit('datePicked', null, null);
      }
    },
    openPicker() {
      this.$refs.vjsDatepicker.showCalendar();
    },
    handleEditBtnClick() {
      this.disableDatePicker = false;
    },
    formatTimestamp() {
      const date = new Date(this.selectedDate);
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      return `${day < 10 ? `0${day}` : day}/${
        month < 10 ? `0${month}` : month
      }/${date.getUTCFullYear()}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.date-picker {
  position: relative;
  display: flex;
  align-items: center;
  .ri-calendar-2-line {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    color: $color_neutral_40;
    cursor: pointer;
  }

  &.large {
    height: 48px;
  }

  &:hover {
    .edit-button {
      opacity: 1;
    }
  }

  .edit-button {
    position: absolute;
    opacity: 0;
    right: 8px;
  }
}
</style>

<style lang="scss">
.vdp-datepicker {
  font-family: 'manrope';
  font-size: 14px;
  color: $color_neutral_100;
  width: 100%;
  height: 100%;
  & > div:nth-child(1) {
    box-sizing: border-box;
    display: flex;
    border: 1px solid $color_neutral_40;
    border-radius: 4px;
    padding: 0 16px;
    background-color: $color_neutral_0;
    transition: all 300ms linear;
    height: 100%;
  }
  input {
    font-family: inherit;
    box-sizing: border-box;
    height: 100%;
    color: $color_neutral_100;
    font-size: 14px;
    outline: none;
    border: none;
    filter: none;
    width: calc(100% - 12px);
    font-weight: 400;
  }
  .vdp-datepicker__calendar {
    border-radius: 2px;
    border: 1px solid $color_neutral_30;
    @include shadow-down-03;
  }
  .vdp-datepicker__calendar .cell.day-header {
    font-size: 100%;
    font-weight: 600;
    color: $color_neutral_60;
  }
  .vdp-datepicker__calendar .cell.selected,
  .vdp-datepicker__calendar .cell.selected:hover {
    background: $color_primary_100;
    background-color: $color_primary_100;
    color: white;
    border-radius: 4px;
  }
  .vdp-datepicker__calendar .cell {
    border: unset;
    user-select: none;
  }
  .vdp-datepicker__calendar div {
    margin: 4px;
  }
  .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
  .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
  .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
    border: unset;
  }
  .vdp-datepicker__calendar .month,
  .vdp-datepicker__calendar .year {
    width: 29.333%;
    margin: 4px 2%;
    box-sizing: border-box;
  }
  .vdp-datepicker__calendar header .prev,
  .vdp-datepicker__calendar header .next {
    text-indent: unset;
    &::after {
      display: none;
    }
  }
  .vdp-datepicker__calendar header {
    color: $color_primary_100;
    font-weight: 600;
  }
  .vdp-datepicker__calendar header .prev:not(.disabled):hover,
  .vdp-datepicker__calendar header .next:not(.disabled):hover,
  .vdp-datepicker__calendar header .up:not(.disabled):hover {
    background: unset;
  }
}
</style>
