import get from 'lodash.get';
import { format } from 'date-fns';
import store from '../../store';

export function getCurrentPlanName(workspace) {
  return get(workspace, 'subscription.plan.name');
}

export function hasActiveSubscription(workspace) {
  const { subscription } = workspace;
  if (subscription) {
    const now = new Date().getTime();
    if (
      subscription.start_at * 1000 < now &&
      now < subscription.end_at * 1000 &&
      subscription.plan.name.toLowerCase() === 'pro'
    ) {
      return true;
    }
  }
  return false;
}

export const isSubscriptionCanceled = workspace => {
  return get(workspace, 'subscription.payment_information.cancel_at_period_end', false);
};

export function isSubscriptionYearly(workspace) {
  if (get(workspace, 'subscription.payment_information.period') === 'yearly') {
    return true;
  }
  return false;
}

export function getWorkspaceTrackLimit(workspace) {
  return get(workspace, 'subscription.track_limit');
}

export function getCurrentPlanId(workspace) {
  return get(workspace, 'subscription.plan.id');
}

export function getCurrentSubscriptionId(workspace) {
  return get(workspace, 'subscription.payment_information.subscription_id');
}

export function getCurrentVariantId(workspace) {
  return get(workspace, 'subscription.plan.variant_id');
}

export function getCurrentSubscriptionPeriod(workspace) {
  return get(workspace, 'subscription.payment_information.period');
}

export function getUserRole(workspace, userId) {
  const member = get(workspace, 'members', []).find(u => u.user_id === userId);
  return get(member, 'role.name', '');
}

export function getUserRoleId(workspace, userId) {
  const member = get(workspace, 'members', []).find(u => u.user_id === userId);
  return parseInt(get(member, 'role.id', ''), 10);
}

export function getFreeTrialDaysLeft(workspace) {
  return get(workspace, 'subscription.payment_information.free_trial.days_left');
}

export function getFreeTrialEndTimestamp(workspace) {
  return parseInt(get(workspace, 'subscription.payment_information.free_trial.end', 0), 10) * 1000;
}

export function isFreeTrial(workspace) {
  const freeTrialEndsTimestamp = get(workspace, 'subscription.payment_information.free_trial.end');
  const now = new Date().getTime();
  return getFreeTrialDaysLeft(workspace) > 0 || freeTrialEndsTimestamp * 1000 > now;
}

export const getSubscriptionEndDate = workspace => {
  const endDate = get(workspace, 'subscription.end_at', 0) * 1000;
  return format(new Date(endDate), 'MMMM dd, yyyy');
};

// imported globaly/added to `this` object in main.js
// can be used anywhere without import
// `if (this.$isWorkspaceLockedVerification(this)) return;` before each concerned actions
export function isWorkspaceLockedVerification(that) {
  if (store.getters.isWorkspaceLocked) {
    that.$store.commit('setDisplayWorkspaceLockedAlert', true);
    return true;
  }
  return false;
}
