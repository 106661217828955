<template>
  <div class="warning-banner">
    <div class="icon-info"><i class="ri-information-fill"></i></div>
    <div class="text">
      <span class="text bold">This workspace is full </span>
      <span>You have reached plan limits. Please consider </span>
      <router-link
        :disabled="memberRole !== 'Admin' || memberRole !== 'Owner'"
        :event="memberRole === 'Admin' || memberRole === 'Owner' ? 'click' : ''"
        v-tooltip="
          memberRole === 'Editor' && {
            content: $t('upgradeAlert.tooltipText'),
            classes: 'alert-link-tooltip',
            position: 'bottom'
          }
        "
        :to="getRoute('offersSettings')"
        class="text link"
      >
        upgrading your plan
      </router-link>
    </div>
  </div>
</template>

<script>
import { get as getRoute } from '@/router/routes';

export default {
  props: {
    memberRole: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      getRoute
    };
  }
};
</script>

<style lang="scss" scoped>
.warning-banner {
  box-sizing: border-box;
  height: fit-content;
  width: 100%;
  padding: 8px 16px 8px 40px;
  background-color: $color_warning_10;
  display: flex;
  align-items: center;
  @include body-1;
  color: $color_warning_110;
  .text {
    flex-grow: 1;
    .bold {
      font-weight: 600;
    }
    .link {
      text-decoration: underline;
      cursor: pointer;
      color: $color_warning_110 !important;
      &:disabled {
        text-decoration: none;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .icon {
    &-info {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      background: rgba(255, 197, 51, 0.1);
      color: $color_warning_100;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-close {
      flex-shrink: 0;
    }
  }
}
</style>
<style lang="scss">
.icon-close-warning.rounded-button.tertiary.small {
  i {
    color: $color_warning_110;
  }
  &:hover {
    background: $color_warning_10;
    border: 1px solid $color_warning_110;
  }
  .alert-link-tooltip.tooltip,
  .warning-tooltip {
    max-width: 140px;
    .tooltip-inner {
      text-align: center;
    }
  }
}
</style>
