<template>
  <div class="wrapper">
    <div v-if="displayLoader" class="loader">
      <div v-if="loading">
        <spinner-without-progress color="grey" :size="32" />
      </div>
      <div class="error" v-else>
        <p class="error-label">
          <i class="error-icon ri-error-warning-line" />
          {{ $t('error') }}
        </p>
        <submit-button
          iconClass="ri-restart-line"
          :btnStyle="primary"
          @submit="reload"
          :label="$t('reload')"
        />
      </div>
    </div>
    <div
      class="edit-panel"
      v-if="
        isMultipleTrackEdition || (LibraryFilesDetails && tracksToEdit.length > 0 && !displayLoader)
      "
    >
      <div class="edit-panel-header">
        <div class="edit-panel-header-title">
          <div class="edit-panel-header-left">
            <div class="edit-panel-header-left-content">
              <div
                v-if="!noSupport"
                @click="openEditAlbum"
                @mouseover="toggleArtworkHover(true)"
                @mouseleave="toggleArtworkHover(false)"
                class="header-artwork-container"
                v-tooltip="tracksToEdit[0].supports[0].name"
              >
                <img :src="tracksToEdit[0].imgSrc" class="header-artwork" />
                <div v-if="isArtworkHover" class="header-artwork-icon">
                  <div class="header-artwork-background">
                    <i class="ri-edit-fill"></i>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="header-artwork-container"
                @mouseover="toggleArtworkHover(true)"
                @mouseleave="toggleArtworkHover(false)"
                @click="goToDetailsCreateAlbum(!isMultipleTrackEdition && tracksToEdit[0].title)"
              >
                <img src="/images/artwork.jpg" />
                <div v-if="isArtworkHover" class="header-artwork-icon">
                  <div class="header-artwork-background">
                    <i class="ri-edit-fill"></i>
                  </div>
                </div>
              </div>
              <div class="edit-panel-header-left-content-text">
                <div class="edit-panel-header-left-content-text-title">
                  {{
                    isMultipleTrackEdition
                      ? $tc('trackSelectedCount', libraryFileIds.length)
                      : tracksToEdit[0].title
                  }}
                </div>
                <div v-if="!isMultipleTrackEdition" class="artists-container">
                  <div
                    class="edit-panel-header-left-content-text-subtitle"
                    v-for="artist in LibraryFilesDetails[0].artists"
                    :key="artist.id"
                  >
                    {{ artist.name }}
                  </div>
                </div>
                <div v-else class="flex">
                  <div class="edit-panel-header-left-content-text-subtitle">
                    {{ $t('multipleArtists') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="edit-panel-header-right">
            <div class="navigation-btn" v-if="!isMultipleTrackEdition && showArrows">
              <icon-button
                class="arrow"
                icon="ri-arrow-left-line"
                btnStyle="secondary"
                size="small"
                :disabled="!previousTrack"
                v-tooltip="$t('previousTrack')"
                @submit="changeTrack(false)"
              />
              <icon-button
                class="arrow"
                icon="ri-arrow-right-line"
                btnStyle="secondary"
                size="small"
                :disabled="(isCurrentTrackListLastPage || noPagination) && !nextTrack"
                v-tooltip="$t('nextTrack')"
                @submit="changeTrack(true)"
              />
              <div class="separator" />
            </div>
            <icon-button
              icon="ri-close-fill"
              btnStyle="tertiary"
              size="small"
              @submit="closeEditPanel"
            />
          </div>
        </div>
        <div class="edit-panel-header-tabs">
          <tabs :tabs="tabs" :noRouteCurrentTab="currentTabIndex" noRoute @changeTab="changeTab" />
          <div class="edit-panel-header-tabs-chip" v-if="!displaySaveBtn && autoSaveStatus">
            <auto-save-status :status="autoSaveStatus" />
          </div>
        </div>
      </div>
      <div class="edit-panel-body">
        <details-view
          ref="detailsView"
          v-if="currentTab === $t('details')"
          :tracksToEdit="tracksToEdit"
          :isMultipleTrackEdition="isMultipleTrackEdition"
          :formHasChangedParent.sync="formHasChanged"
          :isSaveButtonDisabledParent.sync="isSaveButtonDisabled"
          :openSupportModalOnLoad.sync="openSupportModalOnLoad"
          :pendingStatusParent.sync="pendingStatus"
          :isProject="isProject"
          @showModalForMultiEdition="showModalForMultiEdition"
          @refetchLibraryFilesDetails="reload"
          @autoSaveSuccess="setautoSaveSuccess"
        />
        <file-view
          v-if="currentTab === $t('file')"
          :tracksToEdit="tracksToEdit"
          :isMultipleTrackEdition="isMultipleTrackEdition"
        />
        <support-view
          v-if="currentTab === $t('album')"
          :tracksToEdit="tracksToEdit"
          :isMultipleTrackEdition="isMultipleTrackEdition"
          @goToDetailsCreateAlbum="goToDetailsCreateAlbum"
        />
        <right-view
          ref="rightView"
          v-if="currentTab === $t('credits')"
          :tracksToEdit="tracksToEdit"
          :isMultipleTrackEdition="isMultipleTrackEdition"
          :isSaveButtonDisabledParent.sync="isSaveButtonDisabled"
          :formHasChangedParent.sync="formHasChanged"
          :pendingStatusParent.sync="pendingStatus"
        />
        <tags-view
          ref="tagsView"
          v-if="currentTab === $t('tags')"
          :tracksToEdit="tracksToEdit"
          :formHasChangedParent.sync="formHasChanged"
          :isSaveButtonDisabledParent.sync="isSaveButtonDisabled"
          :pendingStatusParent.sync="pendingStatus"
          :isMultipleTrackEdition="isMultipleTrackEdition"
          @showModalForMultiTags="showModalForMultiTags"
          @autoSaveSuccess="setautoSaveSuccess"
        />
        <usage-view
          ref="usageView"
          v-if="currentTab === $t('usage')"
          :tracksToEdit="tracksToEdit"
          :hubs="this.currentWorkspace.hubs"
          :isMultipleTrackEdition="isMultipleTrackEdition"
          @autoSaveSuccess="setautoSaveSuccess"
        />
      </div>
      <div class="edit-panel-buttons" v-if="displaySaveBtn">
        <div class="edit-panel-buttons--">
          <submit-button
            btnStyle="tertiary"
            size="medium"
            :label="$t('cancel')"
            @submit="cancelChanges(true)"
          />
        </div>
        <submit-button
          btnStyle="primary"
          size="medium"
          :label="$t('save')"
          :disabled="isSaveButtonDisabled"
          :pending="pendingStatus"
          @submit="handleClickSaveButton"
        />
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash.get';
import { mapGetters } from 'vuex';
import { LIBRARY_FILES_DETAILS, SUPPORT_DETAILS } from '@/graphql/queries/library';
import generateTracklist from '@/utils/functions/tracklist';
import SupportModal from '@/containers/modals/support';
import LoadingModal from '@/containers/modals/loading';
import { getFrontCoverUrl } from '@/utils/functions/image';
import { trackEvent } from '@/utils/functions/analytics';
import { bus } from '../../utils/bus';
import ContinueEditingModal from '../modals/continueEditing';
import ConfirmMultiEditionModal from '../modals/confirmMultiEdition';
import ConfirmMultiTagsModal from '../modals/confirmMultiTags';
import DetailsView from './details';
import SupportView from './support';
import FileView from './file';
import TagsView from './tags';
import RightView from './rightHolder';
import UsageView from './usage';

export default {
  components: {
    DetailsView,
    SupportView,
    FileView,
    TagsView,
    RightView,
    UsageView
  },
  props: {
    libraryFileIds: {
      type: Array,
      required: false,
      default: () => []
    },
    trackList: {
      type: Array,
      required: false,
      default: () => []
    },
    isProject: {
      type: Boolean,
      required: false,
      default: false
    },
    showArrows: {
      type: Boolean,
      required: false,
      default: true
    },
    noPagination: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      currentTab: this.$t('details'),
      currentTabIndex: 0,
      currentTabMemory: this.$t('details'),
      formHasChanged: false,
      isSaveButtonDisabled: true,
      pendingStatus: false,
      autoSaveSuccess: null,
      loading: true,
      error: false,
      isCloseEditPanel: false,
      openSupportModalOnLoad: false,
      isInfiniteScrollLoading: false,
      LibraryFilesDetailsLoading: false,
      skipSupportDetails: true,
      isOpenEditAlbum: false,
      AlbumDefaultArtistOptions: [],
      isArtworkHover: false
    };
  },
  apollo: {
    LibraryFilesDetails: {
      query: LIBRARY_FILES_DETAILS,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          libraryId: this.currentWorkspace.libraries[0].id,
          libraryFilesIds: this.libraryFileIds
        };
      },
      result() {
        if (this.getOpenEditPanelPane) {
          this.switchPaneToFileAndOpenInput();
        }
      },
      watchLoading(loading) {
        this.LibraryFilesDetailsLoading = loading;
      },
      error() {
        this.error = true;
      },
      skip() {
        return this.libraryFileIds.length > 1;
      }
    },
    supportDetails: {
      query: SUPPORT_DETAILS,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          libraryId: this.currentWorkspace.libraries[0].id,
          supportId: this.tracksToEdit[0].supports[0].id
        };
      },
      watchLoading(isLoading) {
        if (isLoading) {
          bus.$emit('displayModal', {
            title: this.$t('editAlbum'),
            size: 'small',
            isVisible: true,
            component: LoadingModal
          });
        }
      },
      result(res) {
        if (!res.data) return;
        this.skipSupportDetails = true;
        if (this.isOpenEditAlbum) {
          if (get(res, 'data.supportDetails.artists', false)) {
            this.AlbumDefaultArtistOptions = res.data.supportDetails.artists.map(artist => ({
              id: artist.id,
              value: artist.name
            }));
          }
          this.editSupport(res.data.supportDetails);
        }
      },
      skip() {
        return this.skipSupportDetails;
      }
    }
  },
  computed: {
    ...mapGetters([
      'currentWorkspace',
      'playingTrack',
      'playlist',
      'currentDisplayListPaginationStatus',
      'getOpenEditPanelPane'
    ]),
    tracksToEdit() {
      if (this.isMultipleTrackEdition) {
        return this.libraryFileIds;
      }
      return generateTracklist(
        this.LibraryFilesDetails.filter(track => this.libraryFileIds.includes(track.id)),
        this.currentWorkspace,
        this.$i18n.locale
      );
    },
    displayLoader() {
      return (
        this.isInfiniteScrollLoading ||
        (!this.LibraryFilesDetails && !this.isMultipleTrackEdition) ||
        this.LibraryFilesDetailsLoading
      );
    },
    isMultipleTrackEdition() {
      return this.libraryFileIds.length > 1;
    },
    currentTrack() {
      return this.trackList.findIndex(el => el.id === this.libraryFileIds[0]);
    },
    nextTrack() {
      return this.trackList[this.currentTrack + 1];
    },
    isCurrentTrackListLastPage() {
      if (
        !this.currentDisplayListPaginationStatus.current ||
        !this.currentDisplayListPaginationStatus.last
      ) {
        return false;
      }
      return (
        this.currentDisplayListPaginationStatus.current >=
        this.currentDisplayListPaginationStatus.last
      );
    },
    previousTrack() {
      return this.trackList[this.currentTrack - 1];
    },
    noSupport() {
      return get(this.tracksToEdit, '[0]supports[0].id', null) === null;
    },
    tabs() {
      return [
        {
          title: this.$t('details')
        },
        this.isMultipleTrackEdition
          ? null
          : {
              title: this.$t('album')
            },
        {
          title: this.$t('tags')
        },
        {
          title: this.$t('credits'),
          notification: this.calculRightHolder()
        },
        this.isMultipleTrackEdition
          ? null
          : {
              title: this.$t('file')
            },
        this.currentWorkspace.hubs && this.currentWorkspace.hubs.length > 0
          ? {
              title: this.$t('usage')
            }
          : null
      ].filter(Boolean);
    },
    autoSaveStatus() {
      if (this.pendingStatus) {
        return 'loading';
      }
      if (this.autoSaveSuccess === null) {
        return false;
      }
      if (this.autoSaveSuccess) {
        return 'success';
      }
      return 'fail';
    },
    displaySaveBtn() {
      return (
        this.currentTab === this.$t('credits') ||
        (this.isMultipleTrackEdition &&
          (this.currentTab === this.$t('tags') || this.currentTab === this.$t('details')))
      );
    },
    confirmMultiEditionModal() {
      let { components } = this.$options;
      components = {
        ...components,
        ConfirmMultiEditionModal
      };
      return {
        title: null,
        size: 'medium',
        isVisible: false,
        component: components.ConfirmMultiEditionModal,
        onSubmit: this.handleLibraryTracksUpdate,
        onCancel: () => {
          this.cancelChanges();
        }
      };
    },
    confirmMultiTagsModal() {
      let { components } = this.$options;
      components = {
        ...components,
        ConfirmMultiTagsModal
      };
      return {
        title: this.$t('addTagsTrack'),
        size: 'medium',
        isVisible: false,
        component: components.ConfirmMultiTagsModal,
        onSubmit: this.handleLibraryTracksUpdate,
        onCancel: () => {
          this.cancelChanges();
        }
      };
    },
    continueEditingModal() {
      let { components } = this.$options;
      components = {
        ...components,
        ContinueEditingModal
      };
      return {
        title: this.$t('saveYourChanges'),
        size: 'small',
        isVisible: true,
        component: components.ContinueEditingModal,
        onCancel: () => {
          this.cancelChanges();
        }
      };
    }
  },
  mounted() {
    this.$root.$on('goToNextMeta', () => {
      this.changeTrack(true);
    });
    this.$root.$on('infiniteScrollLoading', loading => {
      this.isInfiniteScrollLoading = loading;
    });
  },
  created() {
    bus.$on('showTracksEditSaveModal', () => {
      this.showModalForContinueEditing();
    });
    bus.$on('handleTracksEditMutationDone', isPosition => {
      this.handleTracksEditMutationDone(isPosition);
    });
  },
  beforeDestroy() {
    bus.$off('showTracksEditSaveModal');
    bus.$off('handleTracksEditMutationDone');
    this.$root.$off('goToNextMeta');
    this.$root.$off('infiniteScrollLoading');
  },
  methods: {
    switchPaneToFileAndOpenInput() {
      this.changeTab(this.getOpenEditPanelPane);
      this.$store.commit('changeEditPanelPane', undefined);
      this.$nextTick(() => {
        this.$root.$emit('openReplaceFileInput');
      });
    },
    reload() {
      this.error = undefined;
      this.loading = true;
      this.$apollo.queries.LibraryFilesDetails.refetch();
    },
    changeTrack(dir, force) {
      if (this.isMultipleTrackEdition) {
        return;
      }
      if (!this.isSaveButtonDisabled && this.displaySaveBtn && !force) {
        bus.$emit('displayModal', {
          component: ContinueEditingModal,
          isVisible: true,
          size: 'small',
          onCancel: () => {
            this.changeTrack(dir, true);
          },
          title: this.$t('saveYourChanges')
        });
        return;
      }
      if (dir) {
        if (this.nextTrack) {
          this.$root.$emit('goToNextPreviousMeta', {
            elementUID: this.nextTrack.id,
            checkbox: false
          });
        } else if (!this.isCurrentTrackListLastPage) {
          this.$root.$emit('forceLoadMore');
        }
      } else if (this.previousTrack) {
        this.$root.$emit('goToNextPreviousMeta', {
          elementUID: this.previousTrack.id,
          checkbox: false
        });
      }
    },
    // When the mutation is done, execute the requested action (close the panel or change tab)
    handleTracksEditMutationDone(isPosition) {
      if (isPosition) {
        this.$emit('renderAlbumView');
      }
      if (this.isCloseEditPanel) {
        this.$emit('toggleEditMode', []);
        this.isCloseEditPanel = false;
      }
      if (this.currentTabMemory !== null && this.currentTabMemory !== this.currentTab) {
        this.changeTab(this.currentTabMemory);
        this.currentTabMemory = null;
      }
      this.pendingStatus = false;
    },
    closeEditPanel() {
      this.isCloseEditPanel = true;
      if (!this.isSaveButtonDisabled && this.displaySaveBtn) {
        this.showModalForContinueEditing();
      } else {
        this.$emit('toggleEditMode', []);
      }
    },
    setautoSaveSuccess(status) {
      this.autoSaveSuccess = status;
    },
    goToDetailsCreateAlbum(newName) {
      this.changeTab(this.$t('details'));
      this.$nextTick(() => {
        this.$refs.detailsView.supportModel = newName || null;
        this.$refs.detailsView.DisplaySupportModal(true);
      });
    },
    changeTab(title) {
      if (this.currentTabMemory !== title) {
        this.setautoSaveSuccess(null);
      }
      this.currentTabMemory = title;
      if (!this.isSaveButtonDisabled && this.displaySaveBtn) {
        this.showModalForContinueEditing();
      } else {
        this.currentTabIndex = this.tabs.findIndex(tab => tab.title === title);
        this.currentTab = title;
      }
    },
    cancelChanges(fromCancelBtn) {
      if (this.isCloseEditPanel || fromCancelBtn) {
        this.$emit('toggleEditMode', []);
      }
      if (this.currentTabMemory !== null) {
        this.currentTabIndex = this.tabs.findIndex(tab => tab.title === this.currentTabMemory);
        this.currentTab = this.currentTabMemory;
      }
      if (this.$refs.detailsView) this.$refs.detailsView.resetFormData();
      if (this.$refs.tagsView) this.$refs.tagsView.initData();
      if (this.$refs.rightView) this.$refs.rightView.initData();
      bus.$emit('handleTracksEditMutationCanceled');
    },
    handleClickSaveButton() {
      if (this.$refs.detailsView) {
        this.$refs.detailsView.handleClickSaveButton();
      }
      if (this.$refs.rightView) {
        this.$refs.rightView.handleClickSaveButton();
        if (this.tracksToEdit.length === 1) {
          trackEvent('Track Edit AutoSave', {
            category: 'metadata',
            field_edit: 'rights',
            track_id: this.tracksToEdit[0].id
          });
        }
      }
      if (this.$refs.tagsView) {
        this.$refs.tagsView.handleClickSaveButton();
      }
      if (this.isMultipleTrackEdition) {
        trackEvent('MultiEdit Save Click', { category: 'metadata' });
      }
    },
    handleLibraryTracksUpdate() {
      if (this.$refs.detailsView) {
        this.$refs.detailsView.handleMutations();
      }
      if (this.$refs.tagsView) {
        this.$refs.tagsView.handleTagUpdate();
      }
    },
    calculRightHolder() {
      if (this.isMultipleTrackEdition) {
        return false;
      }
      const rightHolders = this.tracksToEdit[0].right_holders;
      let mastering = 0;
      let publishing = 0;
      if (rightHolders) {
        rightHolders.forEach(el => {
          mastering += el.mastering_percentage ? parseFloat(el.mastering_percentage) : 0;
        });
        rightHolders.forEach(el => {
          publishing += el.publishing_percentage ? parseFloat(el.publishing_percentage) : 0;
        });
      }
      return (mastering || publishing) && (mastering !== 100 || publishing !== 100);
    },
    showModalForMultiEdition() {
      bus.$emit('displayModal', { ...this.confirmMultiEditionModal, isVisible: true });
    },
    showModalForMultiTags() {
      bus.$emit('displayModal', { ...this.confirmMultiTagsModal, isVisible: true });
    },
    showModalForContinueEditing() {
      if (this.$isWorkspaceLockedVerification(this)) return;
      bus.$emit('displayModal', { ...this.continueEditingModal });
    },
    openEditAlbum() {
      this.isOpenEditAlbum = true;
      this.skipSupportDetails = false;
    },
    editSupport(supportInfo) {
      const libraryFilesIds = this.tracksToEdit.map(track => track.id);
      const { id, name, type, release_date, upc, tracks_total } = supportInfo;
      bus.$emit('displayModal', {
        title: this.$t('editAlbum'),
        size: 'medium',
        isVisible: true,
        component: SupportModal,
        onSubmit: this.updateView,
        props: [
          {
            name: 'supportDetails',
            value: {
              supportId: id,
              supportName: name,
              albumType: type,
              albumRelease: release_date,
              albumUpc: upc,
              albumTotalTracks: tracks_total
            }
          },
          {
            name: 'albumImgUrl',
            value: getFrontCoverUrl(supportInfo.images, 2) || '/images/artwork.jpg'
          },
          {
            name: 'defaultArtists',
            value: this.AlbumDefaultArtistOptions
          },
          {
            name: 'libraryFilesIds',
            value: libraryFilesIds
          }
        ]
      });
    },
    toggleArtworkHover(bool) {
      this.isArtworkHover = bool;
    }
  },
  watch: {
    getOpenEditPanelPane() {
      if (this.getOpenEditPanelPane === this.$t('file')) {
        this.switchPaneToFileAndOpenInput();
      }
    },
    formHasChanged() {
      this.$store.commit('changeIsEditPanelChanged', this.formHasChanged);
    },
    isMultipleTrackEdition(val) {
      if (val) {
        this.currentTab = 'Details';
        this.currentTabIndex = 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  &-icon {
    display: block;
    margin-bottom: 10px;
    font-size: 20px;
    color: $color_neutral_40;
  }
  &-label {
    font-size: 14px;
    margin-bottom: 24px;
  }
}
.edit-panel {
  @include body-1;
  padding: 24px;
  padding-top: 0;
  &-header {
    position: sticky;
    top: 0px;
    padding-top: 24px;
    background: white;
    z-index: 3;
    width: calc(100% + 24px);
    padding-right: 24px;
    box-sizing: border-box;
    &-cover {
      position: absolute;
      background: white;
      height: 131px;
      width: 24px;
      top: 0;
      right: -24px;
    }

    &-title {
      display: flex;
      justify-content: space-between;
    }

    &-tabs {
      margin: 16px 0 24px 0;
      position: relative;
      &-chip {
        position: absolute;
        right: 0;
        bottom: 12px;
      }
    }

    &-left {
      &-content {
        display: flex;
        align-items: center;
        margin-right: 8px;
        .header-artwork-container {
          position: relative;
          flex-shrink: 0;
          cursor: pointer;
          width: 48px;
          height: 48px;
          .header-artwork-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            background: rgba(37, 36, 40, 0.6);
            width: 100%;
            height: 100%;
            .header-artwork-background {
              background: $color_neutral_0;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 32px;
              width: 32px;
            }
          }
          .header-artwork {
            width: 100%;
            height: 100%;
          }
        }
        img {
          width: 48px;
          height: 48px;
        }

        &-text {
          margin: 0 0 0 16px;

          &-title {
            @include subtitle-1;
            margin: 4px 0 2px 0;
            line-height: 150%;
          }
          .artists-container {
            display: flex;
            max-width: 265px;
          }
          &-subtitle {
            color: $color_neutral_60;
            margin: 2px 8px 0 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
    &-right {
      z-index: 2;
      display: flex;
      .navigation-btn {
        display: flex;
        height: fit-content;
        align-items: center;
        margin-right: 8px;
        .arrow {
          margin-right: 8px;
        }
      }
      .separator {
        height: 20px;
        width: 1px;
        background-color: $color_neutral_30;
      }
    }
  }

  &-body {
    padding-bottom: 40px;
    position: relative;
    z-index: 0;
  }

  &-buttons {
    z-index: 1;
    margin: 32px 0 0 0;
    display: flex;
    justify-content: flex-start;
    padding: 0 24px;
    position: fixed;
    bottom: 64px;
    right: 0;
    width: 470px;
    background: white;
    height: 56px;
    align-items: center;
    box-shadow: 0px -1px 2px rgba(37, 36, 40, 0.05), 0px -2px 4px rgba(37, 36, 40, 0.05);
    &-- {
      margin: 0 8px 0 0;
    }
  }
}
.flex {
  display: flex;
}
</style>
