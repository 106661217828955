<template>
  <div class="item-list" :style="containerStyle">
    <div
      class="item-list-grid item-list-header"
      :style="headerStyle"
      @mouseenter="handleCheckboxHover(true)"
      @mouseleave="handleCheckboxHover(false)"
    >
      <div class="item-list-grid-header">
        <div class="item-list-grid-header-row" v-if="itemsSelected.length > 0 && !hideActionBtn">
          <div class="item-list-grid-header-row-cell item-list-grid-header-row-no-border">
            <i class="ri-checkbox-indeterminate-line" @click="uncheckAllCheckbox" />
          </div>
          <div class="item-list-grid-header-row-cell item-list-grid-header-row-count">
            {{ headerCountLabel }}
          </div>
          <div class="item-list-grid-header-row-cell item-list-grid-header-row-actions">
            <div v-if="!actionUnavailable" style="display: contents">
              <div
                class="item-list-grid-header-row-actions-button"
                v-for="(button, index) in headerButtons"
                :key="`${button.name}-${index}`"
              >
                <component
                  :is="button.name"
                  v-bind="buttonProperties(button.props)"
                  v-on="buttonsListeners"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="item-list-grid-header-row" v-else>
          <div
            :class="{
              'item-list-grid-header-row-cell': true,
              'item-list-grid-header-row-no-border': column.noBorder,
              'no-separator': column.noSeparator,
              'item-list-grid-header-row-with-sort': sortColumn && !column.noSort
            }"
            :style="headerRowStyle(column)"
            v-for="(column, idx) in displayedColumns"
            :key="`header-${idx}`"
            @click="theadHandleClick(column)"
          >
            <div
              :class="{
                'item-list-grid-header-row-cell-content': true,
                'item-list-grid-header-row-cell-content-center': column.centered
              }"
            >
              <div
                v-if="column.name === 'checkbox' && (itemsSelected.length > 0 || displayUnselect)"
                class="item-list-grid-header-row-cell item-list-grid-header-row-no-border"
              >
                <i class="ri-checkbox-indeterminate-line" @click="uncheckAllCheckbox" />
              </div>
              <div
                v-if="
                  column.name === 'checkbox' &&
                  hasSelectAll &&
                  itemsSelected.length === 0 &&
                  !displayUnselect
                "
                :class="{ 'item-list-grid-header-row-cell-content--': !column.centered }"
                style="width: 100%; height: 100%; display: flex; align-items: center"
              >
                <checkbox
                  :id="`header-cb${idx}`"
                  @toggleCheckbox="selectAll"
                  v-if="checkboxHovered"
                ></checkbox>
              </div>
              <div
                v-else
                :class="{
                  'item-list-grid-header-row-cell-content--': !column.centered,
                  'item-list-grid-header-row-cell-content-sorted': column.name === sortedColumn
                }"
              >
                <i v-if="column.headerPrefixIcon" :class="column.headerPrefixIcon"></i>
                {{ column.header }}
                <i v-if="column.headerSuffixIcon" :class="column.headerSuffixIcon"></i>
              </div>
              <div
                v-if="!column.noSort && sortColumn"
                class="item-list-grid-header-row-cell-content-sort"
              >
                <i v-if="column.name !== sortedColumn" class="ri-arrow-up-line asc-hover"></i>
                <i
                  v-if="column.name === sortedColumn && orderBy === 'asc'"
                  class="ri-arrow-up-line asc"
                ></i>
                <i
                  v-if="column.name === sortedColumn && orderBy === 'desc'"
                  class="ri-arrow-down-line desc"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '../checkbox';

export default {
  components: {
    Checkbox
  },
  props: {
    columns: {
      type: Array,
      required: true
    },
    headerButtons: {
      type: Array,
      required: false,
      default: () => []
    },
    headerCountLabel: {
      type: String,
      required: false,
      default: ''
    },
    isSticky: {
      type: Boolean,
      required: false,
      default: false
    },
    hideActionBtn: {
      type: Boolean,
      required: false,
      default: false
    },
    stickyPosition: {
      type: Number,
      required: false,
      default: 0
    },
    itemsSelected: {
      type: Array,
      required: true
    },
    sortColumn: {
      type: Boolean,
      required: false,
      default: true
    },
    defaultSortedColumn: {
      type: String,
      required: false,
      default: null
    },
    defaultOrderBy: {
      type: String,
      required: false,
      default: null
    },
    hasSelectAll: {
      type: Boolean,
      required: false,
      default: false
    },
    actionUnavailable: {
      type: Boolean,
      required: false,
      default: false
    },
    displayUnselect: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      sort: {
        column: this.defaultSortedColumn,
        orderBy: this.defaultOrderBy
      },
      checkboxHovered: false
    };
  },
  computed: {
    buttonsListeners(params) {
      const { headerButtons } = params;
      const methods = {};
      headerButtons.forEach(button => {
        if (button.events) {
          button.events.forEach((event, index) => {
            methods[button.events[index]] = (param1, param2, param3) => {
              if (this[button.events[index]]) this[button.events[index]](param1, param2, param3);
            };
          });
        }
      });
      headerButtons.forEach(button => {
        if (button.emits) {
          button.emits.forEach((emit, index) => {
            methods[button.emits[index]] = param => {
              this.handleEmit(emit, param);
            };
          });
        }
      });
      return { ...this.$listeners, ...methods };
    },
    gridTemplateColumns() {
      let str = '';
      this.columns.forEach(column => {
        str += ` minmax(${column.min}, ${column.max})`;
      });
      return str;
    },
    displayedColumns() {
      return this.columns.filter(column => !column.noHeader);
    },
    headerStyle() {
      const template = this.gridTemplateColumns;
      const style = {
        /* eslint-disable */
        gridTemplateColumns:
          this.itemsSelected.length === 0
            ? template
            : this.hideActionBtn
            ? template
            : '28px 240px 1fr'
        /* eslint-enable */
      };

      return style;
    },
    containerStyle() {
      return {
        position: this.isSticky ? 'sticky' : 'unset',
        top: `${this.stickyPosition}px`
      };
    },
    sortedColumn() {
      return this.sort.column;
    },
    orderBy() {
      return this.sort.orderBy;
    }
  },
  created() {
    const components = {};
    this.headerButtons.forEach(button => {
      if (button.path)
        components[button.name] = () =>
          button.path.split('/').length > 1
            ? import(`../${button.path}.vue`)
            : import(`../${button.path}/index.vue`);
    });
    Object.assign(this.$options.components, components);
  },
  methods: {
    buttonProperties: props => {
      const p = {};
      props.forEach(prop => {
        p[prop.name] = prop.value;
      });
      return p;
    },
    handleEmit(methodName, param) {
      this.$parent.$emit('handleEmit', { methodName, param });
      this.$emit('handleEmit', { methodName, param });
    },
    uncheckAllCheckbox() {
      this.$emit('uncheckAllCheckbox');
    },
    handleCheckboxHover(param) {
      this.checkboxHovered = param;
    },
    selectAll() {
      this.$emit('selectAll');
    },
    headerRowStyle(current) {
      const { padding, span } = current;
      return {
        padding: current.padding
          ? `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`
          : '',
        gridColumn: `span ${span}`
      };
    },
    theadHandleClick(column) {
      if (column.noSort) {
        return;
      }
      if (!column.noSort) {
        if (this.sort.column === column.name) {
          if (this.sort.orderBy === 'asc') {
            this.sort.orderBy = 'desc';
          } else {
            this.sort.orderBy = 'asc';
          }
        } else {
          this.sort.column = column.name;
          this.sort.orderBy = 'asc';
        }
      }
      this.$parent.$emit('sortTracks', this.sort);
    }
  }
};
</script>

<style lang="scss" scoped>
.item-list {
  @include body-1;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  z-index: map-get($z-index, list-header);
  &-grid {
    display: grid;

    &-header {
      display: contents;

      &-row {
        display: contents;

        &-cell {
          border-bottom: 1px solid $color_neutral_30;
          height: 40px;
          display: flex;
          align-items: center;
          background-color: $color_neutral_0;
          cursor: default;

          &-content {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            &-center {
              display: flex;
              justify-content: center;
            }
            &-- {
              margin-right: 8px;
              @include subtitle-1;
              i {
                font-weight: 400;
              }
            }
            &-sort {
              display: flex;
            }
          }

          .ri-checkbox-indeterminate-line {
            font-size: 23px;
            color: $color_primary_100;
            cursor: pointer;
            margin-left: -2px;
          }
          &:not(:last-child) {
            border-right: 1px solid $color_neutral_30;
          }
        }

        &-no-border {
          border: none;

          &:not(:last-child) {
            border: none;
          }
        }

        &-with-sort {
          cursor: pointer;

          &:hover {
            background: $color_neutral_10;
          }
        }

        &-count {
          padding: 0 0 0 64px;
          color: $color_primary_100;
        }

        &-actions {
          justify-content: flex-end;

          &-button {
            margin: 0 8px 0 0;

            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.asc-hover {
  color: $color_neutral_40;
  display: none;
}
.item-list-grid-header-row-cell:hover {
  .asc-hover {
    display: unset;
  }
}
.item-list-grid-header-row-cell.no-separator {
  border-right: none;
}
</style>
