<template>
  <div class="video-player">
    <video controls :src="videoUrl" />
  </div>
</template>

<script>
export default {
  props: {
    videoUrl: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.video-player {
  height: 100%;
  video {
    width: 100%;
    max-height: 100%;
  }
}
</style>
