<template>
  <div class="member">
    <div class="member-avatar" :class="{ pending: member.status === 'PENDING' }">
      <avatar :initial="member.initial" :pictureUrl="member.pictureUrl" pictureSize="extraSmall" />
    </div>
    <div class="member-infos">
      <div v-if="member.status === 'OK'" class="member-infos-fullname">
        <span>{{ member.fullname }}</span>
      </div>
      <div class="member-infos-email" :class="{ pending: member.status === 'PENDING' }">
        {{ member.email }}
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '../avatar';

export default {
  components: {
    Avatar
  },
  props: {
    member: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.member {
  display: flex;
  width: 365px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &-avatar {
    margin: 0 16px 0 0;
    display: flex;
    align-items: center;
  }

  &-infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 40px 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &-fullname {
      line-height: 150%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-email {
      font-size: 12px;
      color: $color_neutral_60;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: normal;
    }
  }
}
.pending {
  opacity: 0.4;
}
</style>
