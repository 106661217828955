<template>
  <div class="projectList" ref="projectListContainerScroll">
    <!-- LOADING -->
    <div v-if="loadingProjects" class="projectList-loader">
      <spinner-without-progress color="grey" />
    </div>
    <div v-else class="projectList-container">
      <div>
        <div
          class="banner warning"
          :class="displayProjectNameError || displayProjectRequiredError ? 'banner-margin' : ''"
        >
          <div class="banner-icon warning-icon"><i class="ri-error-warning-fill" /></div>
          <div>{{ $t('uploadToModal.warning') }}</div>
        </div>
        <div
          v-if="displayProjectRequiredError"
          class="banner error"
          :class="displayProjectNameError ? 'banner-margin' : ''"
        >
          <div class="banner-icon error-icon"><i class="ri-alert-fill" /></div>
          <div>{{ $t('uploadToModal.projectRequired') }}</div>
        </div>
        <div v-if="displayProjectNameError" class="banner error">
          <div class="banner-icon error-icon"><i class="ri-alert-fill" /></div>
          <div>{{ $t('uploadToModal.MinChar') }}</div>
        </div>
        <div
          class="libraryItem"
          v-tooltip="{
            content: onlyNonAudio
              ? $t('uploadToModal.tooltipNoAudio')
              : $t('uploadToModal.tooltipWithAudio'),
            classes: 'upload-to-library-tooltip'
          }"
        >
          <div class="libraryItem-icon-container">
            <i class="ri-music-2-fill libraryItem-icon" />
          </div>
          <div class="libraryItem-label">
            {{ $t('library') }}
            <span class="libraryItem-label-helper">{{ $t('uploadToModal.audioFilesOnly') }}</span>
          </div>
          <div v-if="!onlyNonAudio" class="libraryItem-check">
            <i class="ri-check-line" />
          </div>
        </div>
        <div v-if="displayNewProjectInput" class="projectItem new-project-input">
          <div class="projectItem-icon-container">
            <i class="ri-folder-fill projectItem-icon" />
          </div>
          <text-field
            inputType="text"
            :selectOnLoad="true"
            :fieldModel.sync="newProjectName"
            @handleBlur="handleBlur"
            @enterPress="handleBlur"
          />
        </div>
        <div
          v-for="project in projects"
          :key="project.id"
          class="projectItem"
          :class="selectedProjectId === project.id && 'projectItem-selected'"
          @click.stop="handleProjectItemClick(project.id)"
        >
          <div class="projectItem-icon-container">
            <i class="ri-folder-fill projectItem-icon" />
          </div>
          <div class="projectItem-label">{{ project.label }}</div>
          <div v-if="selectedProjectId === project.id" class="projectItem-check">
            <i class="ri-check-line" />
          </div>
        </div>
        <div v-if="fetchingMoreProjects" class="loader">
          <skeleton />
        </div>
        <observer v-if="showObserver" :height="32" bottom @intersect="loadMore" />
      </div>
    </div>
  </div>
</template>

<script>
import Observer from '@/containers/observer';
import { trackEvent } from '@/utils/functions/analytics';

export default {
  components: {
    Observer
  },
  data() {
    this.$emit('listProjects');
    return {
      selectedProjectId: undefined,
      newProjectName: '',
      displayProjectNameError: false
    };
  },
  props: {
    projects: {
      type: Array,
      required: false,
      default: () => []
    },
    emptyPlaceholder: {
      buttonLabel: {
        type: String,
        required: false
      },
      buttonIcon: {
        type: String,
        required: false
      },
      text: {
        type: String,
        required: false
      },
      default: () => ({
        buttonLabel: 'New project',
        buttonIcon: 'ri-add-fill',
        text: 'Create a new project to organize and share your work.'
      })
    },
    showObserver: {
      type: Boolean,
      required: false
    },
    fetchingMoreProjects: {
      type: Boolean,
      required: false,
      default: false
    },
    loadingProjects: {
      type: Boolean,
      required: false,
      default: false
    },
    onlyNonAudio: {
      type: Boolean,
      required: false,
      default: false
    },
    displayNewProjectInput: {
      type: Boolean,
      required: false
    },
    displayProjectRequiredError: {
      type: Boolean,
      required: false
    }
  },
  watch: {
    displayNewProjectInput() {
      if (this.displayNewProjectInput) {
        this.selectedProjectId = undefined;
        this.$emit('setSelectedProjectId', this.selectedProjectId);
        trackEvent('New Project Click', {
          category: 'project',
          page_view: 'uploadto modal'
        });
      }
    }
  },
  methods: {
    handleProjectItemClick(projectId) {
      if (this.selectedProjectId === projectId) {
        this.selectedProjectId = undefined;
      } else {
        this.selectedProjectId = projectId;
      }
      this.$emit('setSelectedProjectId', projectId);
    },
    loadMore() {
      this.$emit('fetchMoreProjects');
    },
    handleBlur() {
      this.displayProjectNameError = false;
      if (this.newProjectName === '') {
        setTimeout(() => {
          this.$emit('update:displayNewProjectInput', false);
        }, 100);
        return;
      }
      if (this.newProjectName.length < 3) {
        this.displayProjectNameError = true;
        return;
      }
      this.$emit('createProject', this.newProjectName);
      this.newProjectName = '';
    },
    scrollToTop() {
      // used in the parent
      if (this.$refs.projectListContainerScroll) {
        this.$refs.projectListContainerScroll.scrollTop = 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.projectPicker-container {
  position: absolute;
  width: 300px;
  background-color: $color_neutral_0;
  @include shadow-down-03;
  overflow-y: auto;
  overscroll-behavior: contain;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid $color_neutral_30;
  z-index: 5;
}

.projectList {
  padding: 0 24px;
  height: fit-content;
  max-height: 486px;
  overflow-y: scroll;
  position: relative;
  font-size: 14px;
  &-container {
    height: 100%;
    padding: 8px 0;
  }
}

.projectList-centered,
.projectList-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
}

.projectList-loader {
  padding: 24px 0;
}

.projectList-emptyLabel {
  width: 200px;
  margin-bottom: 16px;
}

.projectItem,
.libraryItem {
  margin-bottom: 4px;
  height: 32px;
  display: flex;
  border-radius: 4px;
  flex-direction: row;
  cursor: pointer;
  padding: 0 8px;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0);
  box-sizing: border-box;
  &:hover {
    background-color: $color_neutral_10;
  }
  &-check {
    font-size: 16px;
    height: 16px;
  }
}

.new-project-input:hover {
  background-color: $color_neutral_0;
}

.libraryItem {
  opacity: 0.6;
  &:hover {
    background-color: $color_neutral_0;
  }
}

.projectItem-selected {
  background-color: $color_primary_10;
  border: 1px solid $color_primary_100;
  color: $color_primary_100;
  &:hover {
    background-color: $color_primary_10;
  }
}

.projectItem-icon,
.libraryItem-icon {
  flex-shrink: 0;
  color: $color_secondary_100;
  font-size: 16px;
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: $color_secondary_10;
  }
}

.libraryItem-icon {
  color: $color_primary_100;
  &-container {
    background-color: $color_primary_10;
  }
}

.projectItem-label,
.libraryItem-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal;
  flex-grow: 1;
  padding-right: 8px;
  &-helper {
    font-size: 12px;
    opacity: 0.6;
  }
}

.loader {
  padding: 12px 0;
}

.footer {
  display: flex;
  height: 48px;
  @include shadow-up-01;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.footer-right {
  display: flex;

  & > :first-child {
    margin-right: 8px;
  }
}

.banner {
  width: calc(100% + 16px);
  line-height: 150%;
  margin-left: -8px;
  border-radius: 4px;
  padding: 8px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  &-icon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border-radius: 40px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
}

.warning {
  background-color: $color_warning_10;
  color: $color_warning_110;
  &-icon {
    background-color: rgba(255, 197, 51, 0.1);
    color: $color_warning_100;
  }
}
.error {
  background-color: $color_danger_10;
  color: $color_danger_110;
  &-icon {
    background-color: rgba(255, 69, 51, 0.1);
    color: $color_danger_100;
  }
}

.banner-margin {
  margin-bottom: 8px;
}
</style>

<style lang="scss">
.upload-to-library-tooltip.tooltip {
  .tooltip-inner {
    max-width: 400px;
    text-align: center;
  }
}

.projectItem.new-project-input {
  .textfield-container {
    height: 28px;
    width: 294px;
    padding-left: 4px;
    padding-right: 4px;
    .textfield-password-input.textfield-password-input-large {
      height: 26px;
    }
  }
}
</style>
