<template>
  <div class="radio-button-container">
    <div
      v-for="(option, index) in options"
      class="radio-button-option"
      :key="`option.value-${index}`"
      @click="() => handleClick(option)"
    >
      <label :for="option.value" class="radio-button-label">
        {{ option.label }}
      </label>
      <input
        class="radio-button"
        :id="option.value"
        name="option"
        type="radio"
        :value="option.value"
        v-model="selected"
        @change.stop="handleClick(option)"
      />
    </div>
  </div>
</template>

<script>
import get from 'lodash.get';

export default {
  props: {
    options: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    const item = this.options.find(i => {
      return i.selected === true;
    });
    return {
      selected: get(item, 'value', this.options[0].value)
    };
  },
  methods: {
    handleClick(option) {
      this.selected = option.value;
      if (option.action) {
        option.action();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.radio-button-container {
  width: 100%;
  cursor: pointer;
}

.radio-button-option {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.radio-button-label {
  color: $color_neutral_100;
  cursor: pointer;
}

input[name='option'] {
  accent-color: $color_primary_100;
  cursor: pointer;
}
</style>
