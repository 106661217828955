import TextField from './components/inputs/textField';
import TextArea from './components/inputs/textArea';
import SearchBar from './components/inputs/searchBar';
import EditableText from './components/inputs/editableText';
import EmailArea from './components/inputs/emailArea';
import ChipsInput from './components/inputs/chipsInput';
import IconText from './components/iconText';
import InputDropdown from './components/inputs/inputDropdown';
import ExpandableText from './components/inputs/expandableText';
import SubmitButton from './components/buttons/submitButton';
import ToggleButtons from './components/buttons/toggleButtons';
import CopyButton from './components/buttons/copyButton';
import SwitchButton from './components/buttons/switchButton';
import ProgressBar from './components/loaders/progressBar';
import Spinner from './components/loaders/spinner';
import SpinnerWithoutProgress from './components/loaders/spinnerWithoutProgress';
import AutoSaveStatus from './components/loaders/autoSaveStatus';
import Checkbox from './components/checkbox';
import ImageBrowser from './components/imageBrowser';
import ImageCropper from './components/imageCropper';
import Sidebar from './components/sidebar';
import Avatar from './components/avatar';
import Alert from './components/alert';
import Modal from './components/modal';
import Skeleton from './components/skeleton';
import SkeletonRow from './components/skeletonRow';
import Player from './components/player';
import VideoPlayer from './components/videoPlayer';
import Tabs from './components/tabs';
import StarsRating from './components/starsRating';
import CardAlbum from './components/cardAlbum';
import CardArtist from './components/cardArtist';
import VolumeControl from './components/volumeControl';
import MenuList from './components/menu-list';
import ToggleMenu from './components/toggle/toggleMenu';
import ToggleIcons from './components/toggle/toggleIcons';
import Slider from './components/slider';
import Checklist from './components/checklist';
import DatePicker from './components/datePicker';
import Badge from './components/badge';
import HorizontalScroller from './components/horizontalScroller';
import ProjectList from './components/projectList';
import ProjectName from './components/projectName';
import ProjectPicker from './components/projectPicker';
import InfoDateUser from './components/infoDateUser';
import SharedInfo from './components/sharedInfo';
import Breadcrumb from './components/breadCrumb';
import Artwork from './components/artwork';
import UploadManager from './components/uploadManager';
import DownloadManager from './components/downloadManager';
import ListRow from './components/listRow';
import HeaderList from './components/headerList';
import FileThumbnail from './components/fileThumbnail';
import TrackInformation from './components/trackInformation';
import Expandable from './components/expandable';
import PanelInformation from './components/panel/information';
import TagCategory from './components/tagCategory';
import Thumbnail from './components/thumbnail';
import ListKnob from './components/listKnob';
import ActivityItem from './components/activityItem';
import Dropdown from './components/dropdown';
import ActionBar from './components/actionBar';
import PreviewFiles from './components/previewFiles';
import MemberInfo from './components/memberInfo';
import AttachmentName from './components/attachmentName';
import TextAction from './components/textAction';
import RadioButton from './components/radioButton';
// For list component dynamic import
import IconButton from './components/iconButton';

// LEGACY DROPDOWN
import DropdownLegacy from './components/dropdown_legacy/dropdown';
import ProfileDropdown from './components/dropdown_legacy/profileDropdown';
import WorkspaceDropdown from './components/dropdown_legacy/workspaceDropdown';
import RolesDropdown from './components/dropdown_legacy/rolesDropdown';
import SelectBox from './components/dropdown_legacy/selectBox';
import SelectTags from './components/dropdown_legacy/selectTags';
import TagCategoryDropdown from './components/dropdown_legacy/tagCategoryDropdown';
import FilterDropdown from './components/dropdown_legacy/filterDropdown';
import DateFilterDropdown from './components/dropdown_legacy/dateFilterDropdown';
import TextDropdown from './components/dropdown_legacy/textDropdown';
// For list component dynamic import
import ProjectDropdown from './components/projectDropdown';
import MoreDropdown from './components/moreDropdown';

export default {
  install(Vue) {
    Vue.component('text-field', TextField);
    Vue.component('text-area', TextArea);
    Vue.component('date-picker', DatePicker);
    Vue.component('search-bar', SearchBar);
    Vue.component('editable-text', EditableText);
    Vue.component('email-area', EmailArea);
    Vue.component('chips-input', ChipsInput);
    Vue.component('icon-text', IconText);
    Vue.component('expandable-text', ExpandableText);
    Vue.component('select-box', SelectBox);
    Vue.component('select-tags', SelectTags);
    Vue.component('submit-button', SubmitButton);
    Vue.component('icon-button', IconButton);
    Vue.component('progressBar', ProgressBar);
    Vue.component('spinner', Spinner);
    Vue.component('spinner-without-progress', SpinnerWithoutProgress);
    Vue.component('auto-save-status', AutoSaveStatus);
    Vue.component('checkbox', Checkbox);
    Vue.component('image-browser', ImageBrowser);
    Vue.component('image-cropper', ImageCropper);
    Vue.component('sidebar', Sidebar);
    Vue.component('avatar', Avatar);
    Vue.component('alert', Alert);
    Vue.component('modal', Modal);
    Vue.component('skeleton', Skeleton);
    Vue.component('skeleton-row', SkeletonRow);
    Vue.component('player', Player);
    Vue.component('video-player', VideoPlayer);
    Vue.component('tabs', Tabs);
    Vue.component('stars-rating', StarsRating);
    Vue.component('card-album', CardAlbum);
    Vue.component('card-artist', CardArtist);
    Vue.component('volume-control', VolumeControl);
    Vue.component('toggle-menu', ToggleMenu);
    Vue.component('toggle-icons', ToggleIcons);
    Vue.component('toggle-buttons', ToggleButtons);
    Vue.component('copy-button', CopyButton);
    Vue.component('switch-button', SwitchButton);
    Vue.component('slider', Slider);
    Vue.component('checklist', Checklist);
    Vue.component('badge', Badge);
    Vue.component('horizontal-scroller', HorizontalScroller);
    Vue.component('project-list', ProjectList);
    Vue.component('project-name', ProjectName);
    Vue.component('project-picker', ProjectPicker);
    Vue.component('info-date-user', InfoDateUser);
    Vue.component('shared-info', SharedInfo);
    Vue.component('breadcrumb', Breadcrumb);
    Vue.component('artwork', Artwork);
    Vue.component('upload-manager', UploadManager);
    Vue.component('download-manager', DownloadManager);
    Vue.component('list-row', ListRow);
    Vue.component('header-list', HeaderList);
    Vue.component('file-thumbnail', FileThumbnail);
    Vue.component('track-information', TrackInformation);
    Vue.component('expandable', Expandable);
    Vue.component('modal', Modal);
    Vue.component('panel-information', PanelInformation);
    Vue.component('tag-category', TagCategory);
    Vue.component('thumbnail', Thumbnail);
    Vue.component('list-knob', ListKnob);
    Vue.component('activity-item', ActivityItem);
    Vue.component('dropdown', Dropdown);
    Vue.component('action-bar', ActionBar);
    Vue.component('preview-files', PreviewFiles);
    Vue.component('menu-list', MenuList);
    Vue.component('member-info', MemberInfo);
    Vue.component('attachment-name', AttachmentName);
    Vue.component('text-action', TextAction);
    Vue.component('radio-button', RadioButton);

    // LEGACY
    Vue.component('input-dropdown', InputDropdown);
    Vue.component('dropdown-legacy', DropdownLegacy);
    Vue.component('profile-dropdown', ProfileDropdown);
    Vue.component('workspace-dropdown', WorkspaceDropdown);
    Vue.component('roles-dropdown', RolesDropdown);
    Vue.component('more-dropdown', MoreDropdown);
    Vue.component('project-dropdown', ProjectDropdown);
    Vue.component('tag-category-dropdown', TagCategoryDropdown);
    Vue.component('filter-dropdown', FilterDropdown);
    Vue.component('date-filter-dropdown', DateFilterDropdown);
    Vue.component('text-dropdown', TextDropdown);
  }
};
