<template>
  <div class="upload-to">
    <div class="upload-to-body">
      <project-list class="upload-to-body-project-list">
        <template v-slot:default="projectListProps">
          <template-list-of-project
            ref="templateListOfProject"
            :projects="projectListProps.projectList"
            :loadingProjects="projectListProps.loadingProjects"
            :noSearchResult="projectListProps.noSearchResult"
            :showObserver="projectListProps.showProjectObserver"
            :emptyPlaceholder="{
              buttonLabel: $t('newProject'),
              buttonIcon: 'ri-folder-add-fill',
              text: $t('emptyProjectDescription')
            }"
            :onlyNonAudio="sortedFiles.audioFiles && sortedFiles.audioFiles.length > 0"
            :displayNewProjectInput.sync="displayNewProjectInput"
            :displayProjectRequiredError="displayProjectRequiredError"
            @updateSearchValue="projectListProps.searchProject"
            @fetchMoreProjects="projectListProps.fetchMoreProjects"
            @listProjects="projectListProps.listProjects"
            @setSelectedProjectId="setSelectedProjectId"
            @createProject="createProject"
          />
        </template>
      </project-list>
    </div>
    <div class="upload-to-footer">
      <icon-button
        btnStyle="secondary"
        size="medium"
        icon="ri-folder-add-line"
        @submit="handleNewProjectClick"
      />
      <div class="upload-to-buttons">
        <div class="upload-to-buttons--">
          <submit-button
            btnStyle="tertiary"
            size="medium"
            :label="$t('cancel')"
            @submit="$emit('closeModal')"
          />
        </div>
        <div class="upload-to-buttons--">
          <submit-button
            btnStyle="primary"
            size="medium"
            :label="$t('upload')"
            :pending="pending === 'PENDING'"
            @submit="uploadTo"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash.get';
import { mapGetters } from 'vuex';
import { PROJECT_NEW } from '@/graphql/mutations/project';
import { PROJECTS_INFORMATION, PROJECT_LIST } from '@/graphql/queries/project';
import { PAGINATOR_LIMIT } from '@/utils/constants';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import { bus } from '@/utils/bus';
import ProjectList from '@/containers/projectList';
import TemplateListOfProject from './TemplateListOfProject';

export default {
  components: {
    ProjectList,
    TemplateListOfProject
  },
  props: {
    onSubmit: {
      type: Function,
      required: false,
      default: () => {}
    },
    sortedFiles: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      pending: false,
      selectedProjectId: null,
      displayNewProjectInput: false,
      displayProjectRequiredError: false,
      newProjectName: undefined,
      uploadToAfterProjectCreation: false
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace'])
  },
  methods: {
    setSelectedProjectId(projectId) {
      this.displayProjectRequiredError = false;
      this.selectedProjectId = projectId;
    },
    uploadTo() {
      this.displayProjectRequiredError = false;
      if (this.selectedProjectId) {
        this.onSubmit(this.sortedFiles, this.selectedProjectId);
        this.$emit('closeModal');
      } else if (this.newProjectName) {
        this.uploadToAfterProjectCreation = true;
      } else {
        this.displayProjectRequiredError = true;
        this.onSubmit(this.sortedFiles);
        this.$emit('closeModal');
      }
    },
    createProject(projectName) {
      if (this.$isWorkspaceLockedVerification(this)) return;
      this.newProjectName = projectName;
      this.displayNewProjectInput = false;
      this.$apollo
        .mutate({
          mutation: PROJECT_NEW,
          variables: {
            name: this.newProjectName,
            description: null,
            workspaceId: this.currentWorkspace.id
          },
          refetchQueries: [
            {
              query: PROJECT_LIST,
              variables: {
                workspaceId: this.currentWorkspace.id,
                status: 'active',
                page: 1,
                limit: PAGINATOR_LIMIT
              }
            },
            {
              query: PROJECTS_INFORMATION,
              variables: {
                workspaceId: this.currentWorkspace.id
              }
            }
          ]
        })
        .then(res => {
          const projectId = get(res, 'data.ProjectNew.id', null);
          if (projectId) {
            this.$refs.templateListOfProject.handleProjectItemClick(projectId);
          }
          this.$nextTick(() => {
            if (projectId && this.uploadToAfterProjectCreation) {
              this.uploadTo();
            }
          });
          // waiting for the search to update before refetch
          setTimeout(() => {
            bus.$emit('refetchProjectList');
          }, 1500);
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          this.projectName = null;
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    handleNewProjectClick() {
      this.displayNewProjectInput = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-to {
  &-body {
    height: fit-content;
    max-height: 486px;
    &-project-list {
      height: fit-content;
    }
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    padding: 8px 24px;
    border-top: 1px solid $color_neutral_30;
    align-items: center;
  }
  &-buttons {
    display: flex;
    justify-content: flex-end;

    &-- {
      &:first-child {
        margin: 0 8px 0 0;
      }
    }
  }
}
</style>

<style lang="scss">
.upload-to-footer {
  .button.primary.medium.no-icon,
  .button.tertiary.medium.no-icon,
  .button.secondary.medium {
    padding: 0 16px;
  }
}
</style>
