
<div class="confirm-delete-modal">
  <div v-if="tracksName" class="confirm-delete-modal-body">
    {{ $t(msg, { trackName: tracksName }) }}
  </div>
  <div v-else class="confirm-delete-modal-body">{{ $t(msg) }}</div>
  <div class="confirm-delete-modal-buttons">
    <div class="confirm-delete-modal-buttons--">
      <submit-button btnStyle="tertiary" :label="$t('cancel')" @submit="closeModal" />
    </div>
    <div
      v-if="msg === 'confirmDeleteHard' || msg === 'confirmDeleteFile'"
      class="confirm-delete-modal-buttons--"
    >
      <submit-button btnStyle="secondary" danger :label="$t('delete')" @submit="confirmDelete" />
    </div>
    <div v-else class="confirm-delete-modal-buttons--">
      <submit-button :label="$t('remove')" @submit="confirmDelete" />
    </div>
  </div>
</div>
