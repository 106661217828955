<template>
  <div class="selectbox-container">
    <div v-if="label" class="title">{{ label }}</div>
    <div :class="{ disabledContainer: isFullDisabled }">
      <div
        class="selectbox"
        :class="{ 'selectbox-area-multiple': isMultipleSelection, disabled: isFullDisabled }"
        v-click-outside="{ hide }"
        tabindex="0"
        @focus="toggleExpand(true)"
        @focusout="toggleExpand(false)"
        @mousedown.prevent
        @keydown="keydownHandler"
        ref="selectbox"
      >
        <div
          class="selectbox-overlay"
          ref="selectbox-overlay"
          v-if="disabled && isMultipleSelection"
        >
          <div class="selectbox-edit">
            <submit-button
              btnStyle="tertiary"
              exception
              danger
              size="small"
              label="Edit"
              @submit="handleEditBtnClick"
            />
          </div>
        </div>
        <div :class="selectClass" @click="toggleFocus" @mousedown.prevent>
          <span class="selectbox-label" v-if="isMultipleSelection">
            {{ this.selectedOption === undefined ? this.placeholder : this.selectedOption }}
          </span>
          <span class="selectbox-label" v-else>
            {{ this.placeholder === this.selectedOption ? this.placeholder : this.selectedOption }}
          </span>
          <i class="ri-arrow-drop-down-line" />
        </div>
        <div
          class="selectbox-expanded"
          :style="`height: ${height}px; overflow-y: ${overflow}; top: ${
            large ? 54 : 46
          }px; opacity: ${isExpanded ? 1 : 0}`"
        >
          <div v-if="isExpanded">
            <div
              class="selectbox-expanded-option"
              v-for="(option, index) in options"
              :key="`d-option-${index}`"
              :class="{ highlighted: index === highlightedOption }"
              @click="setNewSelectedOption(index)"
            >
              <div class="selectbox-expanded-option-label">{{ option.label }}</div>
            </div>
          </div>
        </div>
        <div v-if="errorMessage && showError" class="selectbox-error">{{ errorMessage }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { clickOutside } from '../../utils/directives';

export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select an option'
    },
    selectedValue: {
      type: Object,
      required: false,
      default: () => {}
    },
    errorMessage: {
      type: String,
      default: null,
      required: false
    },
    large: {
      type: Boolean,
      required: false,
      default: false
    },
    isMultipleSelection: {
      type: Boolean,
      required: false,
      default: false
    },
    isFullDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isExpanded: false,
      isFocused: false,
      highlightedOption: null,
      selectedOption: this.placeholder,
      showError: false,
      disabled: this.isMultipleSelection
    };
  },
  computed: {
    height() {
      let height = 0;
      if (this.isExpanded) {
        if (this.options.length > 6) {
          height = 294;
        } else {
          height = 52 * this.options.length - 1;
        }
      }
      return height;
    },
    overflow() {
      return this.options.length > 6 ? 'scroll' : 'hidden';
    },
    selectClass() {
      const selectOption = ['selectbox-selected'];
      if (this.selectedOption === this.placeholder) {
        selectOption.push('selectbox-placeholder');
      }
      if (this.errorMessage && this.showError) {
        selectOption.push('selectbox-group-error');
      }
      if (this.isExpanded) {
        selectOption.push('selectbox-focus');
      }
      if (this.large) {
        selectOption.push('selectbox-large');
      } else {
        selectOption.push('selectbox-regular');
      }
      return selectOption;
    }
  },
  directives: {
    clickOutside
  },
  created() {
    if (this.selectedValue) this.selectedOption = this.selectedValue.label;
  },
  watch: {
    selectedValue() {
      if (this.selectedValue) this.selectedOption = this.selectedValue.label;
    }
  },
  methods: {
    keydownHandler(e) {
      const current = this.highlightedOption;
      if (!current && current !== 0) {
        switch (e.keyCode) {
          case 40:
            this.highlightedOption = 0;
            break;
          default:
            this.highlightedOption = null;
        }
      } else {
        switch (e.keyCode) {
          case 40:
            this.highlightedOption = current + 1 >= this.options.length ? current : current + 1;
            break;
          case 38:
            this.highlightedOption = current === 0 ? 0 : current - 1;
            break;
          case 13:
            this.setNewSelectedOption(current);
            break;
          default:
            this.highlightedOption = current;
        }
      }
    },
    toggleFocus() {
      if (!this.isFocused) {
        this.toggleExpand(true);
        this.$refs.selectbox.focus();
      } else {
        this.toggleExpand(false);
        this.$refs.selectbox.blur();
      }
    },
    toggleExpand(bool) {
      this.isExpanded = bool;
      this.showError = bool;
      this.isFocused = bool;
    },
    setNewSelectedOption(index) {
      const selectedOption = this.options[index];
      this.highlightedOption = index;
      this.selectedOption = selectedOption.label;
      this.$emit('setSelectBoxValue', selectedOption.value);
      this.toggleFocus();
    },
    hide() {
      this.isExpanded = false;
    },
    handleEditBtnClick() {
      this.disabled = false;
      setTimeout(() => {
        this.$refs.selectbox.focus();
      }, 100);
    },
    // used in parent with ref
    resetToDefault() {
      this.selectedOption = undefined;
      this.disabled = this.isMultipleSelection;
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  margin: 0 0 8px 0;
  text-align: left;
  @include label;
}
.selectbox-container {
  .disabledContainer {
    cursor: not-allowed;
  }
  .disabled {
    pointer-events: none;
  }
}
.selectbox {
  position: relative;
  width: 100%;
  max-width: 460px;
  background-color: $color_neutral_0;
  border-radius: 4px;
  @include body-1;
  &:focus {
    outline: none;
  }
  &:hover {
    .selectbox-edit {
      opacity: 1;
    }
  }
  &-overlay {
    position: absolute;
    height: 50px;
    width: 100%;
  }
  &-area-multiple {
    background-color: $color_neutral_10;
  }
  &-edit {
    position: absolute;
    right: 16px;
    top: 8px;
    opacity: 0;
  }
  &-selected {
    border: 1px solid $color_neutral_40;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px 0 15px;
    color: $color_neutral_100;
    cursor: pointer;
    transition: all 200ms linear;
  }

  &-regular {
    box-sizing: border-box;
    height: 40px;
  }

  &-large {
    box-sizing: border-box;
    height: 48px;
  }

  &-label {
    color: $color_neutral_60;
  }

  &-group-error {
    border: 1px solid $color_danger_100;

    .selectbox-label {
      color: $color_danger_100;
    }
  }

  &-focus {
    border: 1px solid $color_neutral_100;
  }

  &-expanded {
    position: absolute;
    width: 100%;
    max-width: 458px;
    background-color: $color_neutral_0;
    border: 1px solid $color_neutral_40;
    border-radius: 2px;
    z-index: 2;

    &-option {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      color: $color_neutral_100;
      background-color: $color_neutral_0;
      transition: all 100ms linear;

      &:hover {
        background-color: $color_neutral_10;
        color: $color_neutral_100;
      }

      &:last-child {
        &-label {
          border-bottom: none;
        }
      }

      &-label {
        border-bottom: 1px solid $color_neutral_30;
        width: 100%;
        padding: 15px;
      }
    }
  }

  .highlighted {
    background-color: $color_neutral_30;
  }

  &-error {
    color: $color_danger_100;
    font-size: 12px;
    padding: 4px 0 0 0;
    text-align: left;
    line-height: 18px;
    position: absolute;
    right: 0;
  }
}

.ri-arrow-drop-down-line {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: $color_neutral_40;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: $color_neutral_40;
}
</style>
