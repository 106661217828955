
<div class="upgrade-plan-modal">
  <div class="upgrade-plan-modal-body">
    <div class="upgrade-plan-modal-body-title">
      {{ $t('modalUpgrade.titleFirstPart', { workspaceName: currentWorkspaceName }) }}
      <span class="upgrade-plan-modal-body-title-bold">{{ currentWorkspaceName }}</span
      >?
      {{ $t('modalUpgrade.titleSecondPart') }}
    </div>
    <div class="upgrade-plan-modal-body-args">
      {{ $t('storageArgs') }}
      <span class="upgrade-plan-modal-body-args-right">
        <span class="upgrade-plan-modal-body-args-right-current" v-if="!displayNextInvoiceDate">
          {{ currentSubscriptionTracks }} tracks / {{ currentSubscriptionStorage }} GB
        </span>
        <i class="ri-arrow-right-line" v-if="!displayNextInvoiceDate"></i>
        {{ upgradedPlanTracks }} tracks / {{ upgradedPlanStorage }}
      </span>
    </div>
    <div class="upgrade-plan-modal-body-args">
      {{ $t('members') }}
      <span class="upgrade-plan-modal-body-args-right">{{
        $t('modalUpgrade.extraMemberCost', { currency: currentSubscriptionCurrency })
      }}</span>
    </div>
    <div v-if="displayAnnualBill">
      <div class="upgrade-plan-modal-body-args">
        {{ $t('billingPeriod') }}
        <span class="upgrade-plan-modal-body-args-right">
          <span class="upgrade-plan-modal-body-args-right-current" v-if="hasPeriodChanged()">{{
            currentBillingSubscriptionFrequency
          }}</span>
          <i class="ri-arrow-right-line" v-if="hasPeriodChanged()"></i>
          <span class="upgrade-plan-modal-body-args-right">{{
            newBillingSubscriptionFrequency
          }}</span>
        </span>
      </div>
      <div class="upgrade-plan-modal-body-args">
        <div v-if="isYearly">
          <span>
            {{ $t('annualBill') }}
          </span>
          <span class="upgrade-plan-modal-body-args-right">
            <span class="upgrade-plan-modal-body-args-right-current">
              {{ getCurrentSubscriptionPrice() }}
            </span>
            <i class="ri-arrow-right-line"></i>
            {{ upgradedTotalPrice }}{{ currentSubscriptionCurrency }}
          </span>
        </div>
        <div v-else>
          <span>
            {{ $t('monthlyBill') }}
          </span>
          <span class="upgrade-plan-modal-body-args-right">
            <span class="upgrade-plan-modal-body-args-right-current">
              {{ getCurrentSubscriptionPrice() }}
            </span>
            <i class="ri-arrow-right-line"></i>
            {{ upgradedTotalPrice }}{{ currentSubscriptionCurrency }}
          </span>
        </div>
      </div>
    </div>
    <div class="upgrade-plan-modal-body-args" v-if="displayNextInvoiceDate">
      Next invoice date
      <span class="upgrade-plan-modal-body-args-right"> {{ subscriptionEndDate() }} </span>
    </div>
    <div class="upgrade-plan-modal-body-args-last">
      {{ getInvoiceLabel() }}
      <span class="upgrade-plan-modal-body-args-last-right"
        >{{ getInvoiceTotal() }}{{ currentSubscriptionCurrency }}
        <span v-if="displayFreeTrialFirstBillDate">&nbsp;on {{ getFreeTrialEndDate() }}</span>
      </span>
    </div>
    <div class="upgrade-plan-modal-body-link">
      {{ $t('needMoreInfo')
      }}<a href="mailto:hi@bridge.audio" class="upgrade-plan-modal-body-link--">{{
        $t('contactUs')
      }}</a>
    </div>
    <div class="upgrade-plan-modal-body-buttons">
      <div class="upgrade-plan-modal-body-buttons-first">
        <submit-button
          btnStyle="tertiary"
          size="medium"
          :label="$t('modalUpgrade.cancelButton')"
          @submit="closeModal"
        />
      </div>
      <submit-button
        btnStyle="primary"
        size="medium"
        :label="$t('modalUpgrade.confirmButton')"
        @submit="upgradePlan"
      />
    </div>
  </div>
</div>
