
<div class="tracks">
  <div v-if="isLibraryEmpty">
    <empty-library
      :title="$t('libraryEmptyTitle')"
      :description="$t('libraryEmptyHelper')"
      :helper="$t('dragAndDrop')"
      placeholderType="tracklist"
      :cloudedTextContent="$t('addAudioFiles')"
      :cloudedTextColor="{
        stroke: '#2F43DE',
        fill: '#E0E3FA'
      }"
    >
      <template v-slot:button>
        <submit-button
          size="large"
          :label="$t('upload')"
          iconClass="ri-upload-cloud-line"
          browse
          multiple
          :accept="allowedFileTypes"
          @submit="handleClickUploadButton"
        />
      </template>
    </empty-library>
  </div>
  <div v-else>
    <div :class="['tracks-edition', editMode ? 'tracks-edition-open' : '']">
      <div class="tracks-edition-wrapper">
        <edit-tracks-panel
          v-if="editMode"
          ref="editTracksPanel"
          :libraryFileIds="libraryFileIds"
          :trackList="trackList"
          @toggleEditMode="toggleEditMode"
        />
      </div>
    </div>
    <div v-show="!trackList" class="loader">
      <spinner-without-progress color="grey" :size="32" />
    </div>
    <router-view
      v-show="trackList"
      :editMode="editMode"
      :keepProjectDropdownExpand="keepProjectDropdownExpand"
      :uncheckTracks="uncheckTracks"
      @openConfirmDeleteModal="openConfirmDeleteModal"
      @createProject="createProject"
      @toggleEditMode="toggleEditMode"
      @setTrackList="setTrackList"
      @sortTracks="sortTracks"
    ></router-view>
  </div>
</div>
