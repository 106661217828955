
<div class="move-to">
  <div class="move-to-header">
    <icon-button
      class="previous"
      btnStyle="tertiary"
      size="medium"
      icon="ri-arrow-left-s-line"
      v-if="displayPrevious"
      v-tooltip="$t('selectWorkspace')"
      @submit="previous"
    />
    <div class="titles-container" :style="titleStyle">
      <div class="move-to-header-title">{{ title }}</div>
    </div>
    <icon-button btnStyle="tertiary" size="medium" icon="ri-close-fill" @submit="closeModal" />
  </div>
  <div v-if="displayConfirmRemove" class="move-confirmation">
    <div class="move-confirmation-body">
      Existing links will be deleted, and all past activities will be lost.
    </div>
    <div>Do you want to continue?</div>
  </div>
  <div v-else-if="!displayedWorkspace">
    <template-list-of-workspace
      :disableCurrent="projectId !== null"
      @changeDisplayedWorkspace="changeDisplayedWorkspace"
      @changeSelectedWorkspace="changeSelectedWorkspace"
    />
  </div>
  <div v-else class="move-to-body">
    <project-list class="move-to-body-project-list">
      <template v-slot:default="projectListProps">
        <template-list-of-project
          ref="templateListOfProject"
          :projects="projectListProps.projectList"
          :loadingProjects="projectListProps.loadingProjects"
          :noSearchResult="projectListProps.noProjectSearchResult"
          :showObserver="projectListProps.showProjectObserver"
          :emptyPlaceholder="{
            buttonLabel: $t('newProject'),
            buttonIcon: 'ri-folder-add-fill',
            text: $t('emptyProjectDescription')
          }"
          :displayNewProjectInput.sync="displayNewProjectInput"
          :displayProjectRequiredError="displayProjectRequiredError"
          @updateSearchValue="projectListProps.searchProject"
          @fetchMoreProjects="projectListProps.fetchMoreProjects"
          @listProjects="projectListProps.listProjects"
          @setSelectedProjectId="setSelectedProjectId"
          @createProject="createProject"
        />
      </template>
    </project-list>
  </div>
  <div class="move-to-footer">
    <div>
      <icon-button
        v-if="displayedWorkspace"
        btnStyle="secondary"
        size="medium"
        icon="ri-folder-add-line"
        v-tooltip="$t('newProject')"
        :disabled="disableAll"
        @submit="handleNewProjectClick"
      />
    </div>
    <div v-if="displayConfirmRemove" class="move-to-buttons">
      <div class="move-to-buttons--">
        <submit-button
          key="moveto-no"
          btnStyle="secondary"
          size="medium"
          :label="$t('cancel')"
          :disabled="disableAll"
          @submit="closeModal"
        />
      </div>
      <div class="move-to-buttons--">
        <submit-button
          btnStyle="primary"
          size="medium"
          :label="$t('modalUpgrade.confirmButton')"
          :disabled="disableAll"
          :pending="pendingMove"
          @submit="confirmMove"
        />
      </div>
    </div>
    <div v-else class="move-to-buttons">
      <div v-if="!moveHidden" class="move-to-buttons--">
        <submit-button
          btnStyle="secondary"
          size="medium"
          :label="$t('moveTo')"
          :pending="pending || pendingMove"
          :disabled="moveDisable"
          v-tooltip="$t('moveTooltip')"
          @submit="addTo(true)"
        />
      </div>
      <div class="move-to-buttons--">
        <submit-button
          btnStyle="primary"
          size="medium"
          :label="$t('copyTo')"
          :pending="pending || pendingCopy"
          :disabled="copyDisable"
          v-tooltip="$t('copyTooltip')"
          @submit="addTo(false)"
        />
      </div>
    </div>
  </div>
</div>
