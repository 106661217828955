
<div class="container" v-click-outside="{ hide }">
  <div @click="handleClickButton" ref="button">
    <slot name="button" :isExpanded="isExpanded"></slot>
  </div>
  <div
    v-if="isExpanded"
    class="dropdown"
    ref="dropdown"
    :style="dropdownStyle"
    @click="handleClickDropdown"
  >
    <slot name="default"></slot>
  </div>
</div>
