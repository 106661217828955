import gql from 'graphql-tag';

export const HUB_TRACK = gql`
  query HubTrack($hubId: ID!, $trackId: ID!) {
    HubTrack(hubId: $hubId, trackId: $trackId) {
      id
      track_id
      hub_id
    }
  }
`;

export const WORKSPACE_HUBS = gql`
  query WorkspaceHubs($workspace_id: ID!) {
    WorkspaceHubs(workspace_id: $workspace_id) {
      workspace_id
      hub_id
      comment
      hub {
        name
      }
    }
  }
`;
