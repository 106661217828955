<template>
  <div>
    <router-view
      :uncheckTracks="uncheckTracks"
      @setSelectedTracks="setSelectedTracks"
    ></router-view>
  </div>
</template>

<script>
export default {
  props: {
    uncheckTracks: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    setSelectedTracks(selectedTracks) {
      this.$emit('setSelectedTracks', selectedTracks);
    }
  }
};
</script>
