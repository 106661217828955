<template>
  <dropdown ref="dropdown">
    <template #button>
      <avatar :pictureUrl="getUserPicture" :initial="getUserInitials" class="avatar" />
    </template>
    <div class="menu">
      <div class="header">
        <avatar :pictureUrl="getUserPicture" :initial="getUserInitials" />
        <div class="header-metadata">
          <div class="name" v-if="getName.length > 0">{{ this.getName }}</div>
          <div class="email">{{ this.getEmail }}</div>
        </div>
      </div>
      <div
        class="menu-item"
        v-for="(option, index) in getOptions"
        :key="index"
        @click="option.action"
      >
        <i v-if="option.icon" :class="option.icon" class="menu-item-icon" />
        <span class="menu-item-label">{{ option.label }}</span>
      </div>
    </div>
  </dropdown>
</template>

<script>
import * as Sentry from '@sentry/browser';
import { mapGetters } from 'vuex';

import {
  getUserNames,
  getUserInitials,
  getUserPicture,
  getUserEmail
} from '@/utils/functions/user';
import { get as getRoute } from '@/router/routes';

export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    getUserPicture() {
      return getUserPicture(this.user);
    },
    getUserInitials() {
      return getUserInitials(this.user);
    },
    getName() {
      return getUserNames(this.user);
    },
    getEmail() {
      return getUserEmail(this.user);
    },
    getOptions() {
      return [
        {
          label: this.$t('accountSettings'),
          action: this.redirectToAccount,
          icon: 'ri-user-settings-line'
        },
        { label: this.$t('logout'), action: this.logout }
      ];
    }
  },
  methods: {
    redirectToAccount() {
      this.$router.push(getRoute('accountSettings'));
      this.$refs.dropdown.hide();
    },
    logout() {
      Sentry.configureScope(scope => scope.setUser(null));
      window.location.replace(getRoute('logout'));
    }
  }
};
</script>

<style lang="scss" scoped>
.avatar {
  cursor: pointer;
}

.menu {
  min-width: 215px;
  height: auto;
  z-index: 1000;
  white-space: nowrap;
  background-color: $color_neutral_0;
  box-sizing: border-box;
  border: 1px solid $color_neutral_30;
  border-radius: 4px;
  overflow: hidden;
  @include shadow-down-03;
}

.header {
  padding: 16px 24px;
  border-bottom: 1px dashed $color_neutral_30;
  display: flex;
  align-items: center;
}

.header-metadata {
  margin-left: 8px;
  cursor: default;
}

.name {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0;
  color: $color_neutral_100;
  line-height: 100%;
}

.email {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0;
  color: $color_neutral_100;
  line-height: 100%;
  color: $color_neutral_60;
  margin-top: 4px;
}

.menu-item {
  padding: 16px 24px;
  border-bottom: 1px solid $color_neutral_30;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 54px;
  box-sizing: border-box;
}
.menu-item:hover {
  background-color: $color_neutral_10;
}

.menu-item:last-child {
  border: none;
}

.menu-item-icon {
  font-size: 16px;
  padding: 0 8px 0 0;
}

.menu-item-label {
  font-size: 14px;
}
</style>
