<template>
  <div class="create-workspace-container">
    <div class="create-workspace">
      <div class="create-workspace-logo">
        <img src="/images/Logo-Full-Color.svg" />
      </div>
      <div class="create-workspace-step">1/2</div>
      <div class="create-workspace-title">{{ title }}</div>
      <div class="create-workspace-helper">{{ $t('workspaceDefinition') }}</div>
      <form @submit.prevent="submit" class="create-workspace-form">
        <div class="create-workspace-form-textfield">
          <text-field
            inputType="text"
            :fieldModel.sync="$v.workspaceName.$model"
            :label="$t('workspaceName')"
            :placeholder="$t('workspaceNamePlaceholder')"
            :hint="$t('hintWorkspaceName')"
            large
            :errorMessage="workspaceNameValidationMessage"
          />
        </div>
        <div class="create-workspace-form-picture">
          <div class="create-workspace-form-picture-title">{{ $t('workspacePictureTitle') }}</div>
          <div class="create-workspace-form-picture-definition">
            {{ $t('workspacePictureDefinition') }}
          </div>
          <div class="create-workspace-form-picture-browser">
            <image-browser
              :imgUrl="imgUrl"
              square
              @changeImgUrl="changeImgUrl"
              icon="ri-image-fill"
              pictureSize="small"
              label="Add a logo"
            />
          </div>
        </div>
        <select-box
          v-if="activities"
          :options="activityOptions"
          :label="$t('mainActivity')"
          :placeholder="$t('activityTitlePlaceholder')"
          :errorMessage="activityValidationMessage"
          large
          @setSelectBoxValue="setSelectBoxValue"
        />
        <div class="create-workspace-form-button">
          <submit-button
            :label="$t('continue')"
            size="large"
            :pending="submitStatus === 'PENDING'"
            :disabled="disabledStatus"
            @submit="submit"
            class="button"
          />
        </div>
      </form>
    </div>
    <div v-if="!isMobile" class="create-workspace-container-right">
      <workspace-previewer :workspaceName="workspaceName" :workspaceLogoUrl="imgBase64" />
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { CREATE_WORKSPACE } from '@/graphql/mutations/workspace';
import WorkspacePreviewer from '@/containers/workspacePreviewer';
import { appConfig } from '@/utils/constants';
import { bus } from '@/utils/bus';
import { setWorkspaceIdInStorage } from '@/utils/storage';
import { get as getRoute } from '@/router/routes';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import { handleWorkspaceLogoUpload } from '@/utils/functions/upload';
import { GET_ACTIVITIES } from '@/graphql/queries/workspace';
import { ME } from '@/graphql/queries/user';
import CropperModal from '@/containers/modals/cropper';

export default {
  props: {
    isMobile: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      activities: [],
      activity: null,
      activityOptions: [],
      workspaceName: 'My workspace',
      submitStatus: 'OK',
      getRoute,
      invalidVoucher: false,
      imgUrl: null,
      blob: null,
      imgBase64: null,
      cropperMaxWidth: appConfig.upload.user.width,
      cropperMaxHeight: appConfig.upload.user.height
    };
  },
  components: {
    WorkspacePreviewer
  },
  apollo: {
    activities: {
      query: GET_ACTIVITIES,
      result() {
        this.activityOptions = this.activities.map(activity => ({
          label: activity[`name_${this.$i18n.locale}`],
          value: activity.id
        }));
      }
    },
    me: {
      query: ME
    }
  },
  validations: {
    activity: {
      required() {
        return this.activity !== null;
      }
    },
    workspaceName: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(25)
    }
  },
  computed: {
    workspaceNameValidationMessage() {
      if (!this.$v.workspaceName.required) {
        return this.$t('required', { label: this.$t('workspaceName') });
      }
      if (!this.$v.workspaceName.minLength) {
        return this.$t('minLength', {
          label: this.$t('workspaceName'),
          nbChars: this.$v.workspaceName.$params.minLength.min
        });
      }
      if (!this.$v.workspaceName.maxLength) {
        return this.$t('maxLength', {
          label: this.$t('workspaceName'),
          nbChars: this.$v.workspaceName.$params.maxLength.max
        });
      }
      return null;
    },
    activityValidationMessage() {
      if (!this.$v.activity.required && this.$v.activity.$dirty) {
        return this.$t('required', { label: this.$t('activity') });
      }
      return null;
    },
    disabledStatus() {
      return this.$v.workspaceName.$invalid || !this.activity;
    },
    title() {
      if (this.me && this.me.workspaces.length > 1) {
        return this.$t('createNewWorkspace');
      }
      return this.$t('createFirstWorkspace');
    }
  },
  created() {
    window.addEventListener('resize', this.setIsMobile);
  },
  destroyed() {
    window.removeEventListener('resize', this.setIsMobile);
  },
  methods: {
    changeImgUrl(blob) {
      if (!blob) return;
      bus.$emit('displayModal', {
        title: this.$t('cropImage'),
        size: 'medium',
        component: CropperModal,
        onSubmit: this.setWorkspaceImg,
        onCancel: () => {},
        isVisible: true,
        props: [
          {
            name: 'blob',
            value: blob
          },
          {
            name: 'imageMaxWidth',
            value: this.cropperMaxWidth
          },
          {
            name: 'imageMaxHeight',
            value: this.cropperMaxHeight
          },
          {
            name: 'isSquare',
            value: true
          }
        ]
      });
    },
    setWorkspaceImg(blob, url) {
      this.imgUrl = url;
      this.blob = blob;
      this.imgBase64 = url;
    },
    setSelectBoxValue(value) {
      this.$v.activity.$model = value;
    },
    submit() {
      this.$v.$touch();
      this.submitStatus = 'PENDING';
      this.$apollo
        .mutate({
          mutation: CREATE_WORKSPACE,
          variables: {
            name: this.$v.workspaceName.$model,
            activityId: parseInt(this.$v.activity.$model, 10)
          },
          update(store, { data }) {
            const q = store.readQuery({ query: ME });
            store.writeData({
              data: {
                me: {
                  ...q.me,
                  workspaces: {
                    ...q.workspaces,
                    ...data,
                    __typename: 'Workspace'
                  }
                }
              }
            });
          }
        })
        .then(workspace => {
          this.submitStatus = 'OK';
          setWorkspaceIdInStorage(workspace.data.createWorkspace.id);
          this.submitStatus = 'PENDING';
          let routeName = 'workspaceInvitation';
          if (!this.isMobile && this.$route.meta.fromSignup) {
            routeName = 'workspaceInvitationFromSignup';
          }
          const nextUrl = getRoute(routeName, {
            id: workspace.data.createWorkspace.id
          });
          const nextRoute =
            document.location.search.length === 0
              ? nextUrl
              : `${nextUrl}?${document.location.search}`;
          if (this.blob) {
            const file = new File([this.blob], 'newWorkspaceLogo', { type: 'image/jpeg' });
            handleWorkspaceLogoUpload(this, file, workspace.data.createWorkspace.id).then(() => {
              this.submitStatus = 'OK';
              this.$router.push(nextRoute);
            });
          } else {
            this.submitStatus = 'OK';
            this.$router.push(nextRoute);
          }
        })
        .catch(error => {
          this.submitStatus = 'OK';
          const key = getGraphQlErrorCode(error);
          if (key === 'voucher_not_usable') this.invalidVoucher = true;
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.create-workspace-container {
  width: 1236px;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 24px;
  box-sizing: border-box;
  &-right {
    margin-left: 24px;
  }
}
.create-workspace {
  height: 820px;
  &-logo {
    margin-bottom: 64px;
    img {
      height: 24px;
      width: auto;
    }
  }

  &-step {
    font-size: 14px;
    color: $color_neutral_60;
    margin-bottom: 8px;
  }

  &-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 16px;
  }

  &-helper {
    @include body-1;
    color: $color_neutral_60;
    text-align: left;
    margin-bottom: 40px;
  }

  &-form {
    margin: 0 auto;

    &-voucher {
      border: 1px solid $color_neutral_30;
      margin: 0 -24px 32px -24px;
      padding: 24px;

      &-desc {
        @include body-1;
        color: $color_neutral_60;
      }

      &-list {
        margin: 8px 0 16px 0;

        &-- {
          @include body-1;
          display: flex;
          align-items: center;
          margin: 0 0 8px 0;

          .ri-check-fill {
            color: $color_primary_100;
            margin: 0 4px 0 0;
          }
        }
      }

      &-textfield {
        &-error {
          display: flex;
          justify-content: flex-end;
          font-size: 12px;
          margin: 4px 0 0 0;
          color: $color_danger_100;
        }
      }
    }

    &-textfield {
      margin: 32px 0;
      position: relative;

      &-error {
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        margin: 4px 0 0 0;
        color: $color_danger_100;
      }
    }

    &-button {
      .button {
        width: 100%;
        background-color: $color_primary_100;
      }
      width: 464px;
      margin-top: 24px;
    }

    &-picture {
      margin-bottom: 24px;
      &-title {
        @include body-2;
        font-weight: 600;
        margin-bottom: 8px;
      }

      &-definition {
        @include body-2;
        color: $color_neutral_60;
        margin-bottom: 8px;
      }

      &-browser {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        width: 100%;
      }

      &-browser-title {
        font-size: 12px;
        font-weight: 600;
        color: $color_primary_100;
        margin-left: 20px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .create-workspace-container {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .create-workspace-container {
    padding: 0;
  }
  .create-workspace {
    width: 100%;
    display: flex;
    flex-direction: column;

    &-logo {
      margin: 0 auto 64px;
    }
    &-title {
      font-size: 20px;
      line-height: 125%;
    }
    &-helper {
      width: 100%;
    }
    &-form {
      width: 100%;
      display: flex;
      flex-direction: column;
      &-textfield {
        margin: 0 0 24px 0;
      }

      &-button {
        margin: 70px 0 0 0;
        .button {
          width: 100%;
          background-color: $color_primary_100;
        }
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
      }
    }
  }
}
</style>

<style lang="scss">
@media screen and (max-width: 900px) {
  .create-workspace-form-button {
    .button {
      width: 100%;
    }
  }
}
</style>
