
<div class="shared-info">
  <div v-if="emailLink" class="email">
    <i class="ri-mail-line icon"></i>
    {{ emailLink }}
  </div>
  <copy-button
    v-if="publicLink"
    :data="publicLink"
    label="Public link"
    @copied="copyToClipboard"
    click.native.stop
  />
  <div v-if="noLink">-</div>
</div>
