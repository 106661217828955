export default {
  createYourWorkspace: 'Create your workspace',
  createWorkspace: 'Create workspace',
  onlyOneWorkspace: {
    first: 'For now early access only allows you to create',
    oneWorkspace: 'one workspace.',
    second: 'If you want to create a new one, feel free to shoot us a mail at',
    third: 'so we can help you set up a workspace that fits your needs!'
  },
  go: 'Go!',
  maxLength: '{nbChars} characters maximum',
  minLength: '{nbChars} characters minimum',
  required: '{label} is required',
  workspaceName: 'Workspace name',
  workspaceNamePlaceholder: 'My Workspace',
  activity: 'Activity',
  sharingActivity: 'Activity',
  activityTitlePlaceholder: 'Select your activity',
  label: 'Label',
  manager: 'Manager',
  publisher: 'Publisher',
  publishers: 'Publisher(s)',
  aandr: 'A&R',
  artist: 'Artist',
  band: 'Band',
  supervisor: 'Supervisor',
  syncBroker: 'Sync Broker',
  radio: 'Radio',
  press: 'Press',
  tv: 'TV',
  webzine: 'Webzine',
  touring: 'Touring',
  venue: 'Venue',
  other: 'Other',
  workspacePicture: 'Choose your logo',
  workspacePictureTitle: 'Workspace logo',
  workspacePictureDefinition: 'You can pick it or change it later',
  next: 'Next',
  later: "I'll do it later",
  save: 'Save',
  saveYourChanges: 'Leave without saving?',
  cancel: 'Cancel',
  confirm: 'Confirm',
  yesContinue: 'Yes, continue',
  yesLeave: 'Yes, leave',
  library: 'Library',
  projects: 'Projects',
  noTrackInProject: 'There are no tracks in this project',
  noFileAttached: 'No files attached',
  archived: 'Archived',
  inbox: 'Inbox',
  reception: 'Reception',
  sharing: 'Sharing',
  assets: 'Assets',
  outbox: 'Outbox',
  contacts: 'Contacts',
  logout: 'Logout',
  supportedFiles: 'Supported files: jpg, png, gif',
  continue: 'Continue',
  music: 'Music',
  import: 'Import',
  importFiles: 'Import files',
  libraryEmptyTitle: ' to unlock your private workspace',
  libraryEmptyHelper: 'Only you and your team will have access to the tracks',
  dragAndDrop: 'Or drag & drop files',
  dropFiles: 'Drop your files',
  toUpload: 'to upload',
  filesNotUploaded: '{count} file is not an audio file. | {count} files are not audio files.',
  uploadComplete: 'Upload complete!',
  upgradeYourPlan: 'Upgrade your plan',
  upgradePlan: 'Upgrade plan',
  upgrade: 'Upgrade',
  startFreeTrial: 'Start free trial',
  availablePlans: 'Available plans',
  upgradeNow: 'Upgrade now',
  fullStorage: 'We’re sorry, but your workspace storage is full.',
  pleaseUpgrade: 'Please upgrade your plan',
  keepUploading: ' to keep uploading new files.',
  upgradeByAdmin: {
    firstPart: 'Please contact your workspace administrator to',
    boldPart: ' upgrade your plan',
    thirdPartToKeepUploading: ' to keep uploading new files.',
    thirdPartToInviteNewMembers: ' to invite new members.'
  },
  workspaceStorage: 'Workspace storage',
  storageUsed: '{used} / {total}',
  filesTooLarge: 'Files too large',
  full: 'Storage limit',
  storageFull: 'Storage full',
  filesSize:
    'The size of the files you’re trying to import exceeds the limit of your current workspace plan. Please import fewer files.',
  increaseSpace: 'to increase your storage space.',
  uploadInProgress: 'An upload is already in progress! Please wait until the end.',
  tracks: 'Tracks',
  artists: 'Artists',
  albums: 'Albums',
  sureToLeave: 'Are you sure you want to leave?',
  accountSettings: 'Account settings',
  settings: 'Settings',
  workspaceMembers: 'Workspace members',
  personnal: 'personnal',
  account: 'Account',
  workspace: 'Workspace',
  general: 'General',
  members: 'Members',
  membersLower: 'member | members',
  billing: 'Billing',
  accountInformation: 'Account information',
  accountDescription: 'Change your account picture and name',
  firstname: 'First name',
  lastname: 'Last name',
  email: 'Email',
  newEmail: 'New email address',
  invalidEmail: 'This is not an email',
  currentEmail: 'Your current email is ',
  emailAddress: 'Email address',
  emailAddressDescription: 'Manage your email address(es)',
  primaryEmail: 'Primary email',
  secondaryEmail: 'Secondary email',
  addEmailAddress: 'Add email address',
  addManageEmailAddress: 'Add/manage email address(es)',
  enterEmailAddress: 'Enter your email address',
  enterPassword: 'Enter your password',
  sendVerificationEmail: 'Send verification email',
  resendVerificationEmail: 'Resend verification email',
  sentVerificationEmailAlert:
    'We sent an email to {mail}. Please check your inbox to verify your new email address',
  emailDeleted: 'Email deleted',
  maximumFiveSecondaryEmails: "You've reached the maximum of 5 secondary emails",
  // secondary email errors
  user_email_exist: 'This address mail already exists',
  max_secondary_email: "You can't add more than 5 emails",
  modificationSuccess: 'Changes saved',
  error: 'Something went wrong.',
  workspaceInformation: 'Workspace information',
  workspaceDescription: 'Change your workspace logo and name',
  maxChars: '{nbChars} characters max.',
  workspaceSettings: 'Workspace settings',
  invitePeople: 'Invite people to your workspace',
  invitePeopleHelper:
    "Invite people you work with to join your private workspace. You'll be able to manage their roles later on.",
  allDone: 'All done!',
  memberList: 'List of members',
  count: '{nb} of {total}',
  seatsTaken: 'member seats taken',
  inviteMembers: 'Invite members',
  invite: 'Invite',
  member: 'Member | Members',
  user: 'User | Users',
  role: 'Role',
  searchMail: 'Search by name or email',
  admin: 'Admin',
  editor: 'Editor',
  roles: {
    1: {
      description: 'Can change workspace settings, invite new members, and handle subscription'
    },
    2: {
      description: 'Cannot change workspace settings nor invite new members'
    },
    owner: {
      description:
        'Can change workspace settings, delete workspace, invite new members, and handle subscription'
    }
  },
  transferOwnership: 'Transfer ownership',
  transferOwnershipConfirmation: 'Do you really want to transfer the ownership of the workspace',
  addMembers: 'Invite members to your workspace',
  largerTeam: 'Interested in having a larger team?',
  checkPlan: 'Check our plans',
  enterEmail: 'Enter email address',
  addMember: 'Add member',
  sendInvites: 'Send invite | Send invites',
  workspaceDefinition: 'Welcome! Tell us a bit about your team',
  charMax: '{nbChars} characters max.',
  emailTwice: "You're trying to invite the same email twice",
  membersLimit: 'Members limit',
  membersLimitDesc:
    "We're sorry, but you have reached the limit of members included in your plan for this workspace. ",
  noPermissionDesc: 'Sorry, only admins can invite new workspace members.',
  saveChanges: "You didn't save your changes.",
  smallSaveChanges: 'Save changes',
  createAlbum: 'Create album',
  confirmDelete: 'Are you sure you want to continue?',
  confirmDeleteHard: 'Are you sure you want to delete "{trackName}"? ',
  invitationSuccess: 'Invitation sent | Invitations sent',
  removeTrackFromProject: 'Are you sure you want to remove "{trackName}" from the project?',
  removeTrackTags: "Remove track's tags",
  deleteFile: 'Delete file',
  confirmDeleteFile: 'Are you sure you want to delete this file?',
  createNewWorkspace: 'Create a new workspace',
  createFirstWorkspace: 'Create your first workspace',
  pending: 'Pending',
  resendInvitation: 'Resend invitation',
  cancelInvitation: 'Cancel invitation',
  cancelInvitationSuccess: 'Invitation canceled',
  resendInvitationSuccess: 'Invitation sent again',
  owner: 'Owner',
  manageStorage: 'Manage your workspace storage',
  password: 'Password',
  passwordCopied: 'Password copied',
  changePassword: 'Change your account password',
  currentPasswordPlaceholder: '8+ characters',
  currentPassword: 'Current password',
  differentPassword: 'Must be different from current password',
  newPassword: 'New password',
  fair: 'Fair',
  weak: 'Weak',
  strong: 'Strong',
  confirmNewPasswordPlaceholder: 'Identical to new password',
  confirmNewPassword: 'Confirm new password',
  identicalPassword: 'Passwords must be identical',
  track: 'Track',
  album: 'Album',
  duration: 'Duration',
  rating: 'Rating',
  added: 'Added',
  trackSelected: 'track selected | track selected | tracks selected',
  trackSelectedCount: '{count} track selected | {count} track selected | {count} tracks selected',
  addToProject: 'Add to project',
  addTo: 'Add to...',
  move: 'Move',
  copy: 'Copy',
  copyTo: 'Copy to',
  addToWorkspace: 'Add to my workspace',
  uploadTo: 'Upload to...',
  addAlbumToProject: 'Add album to project',
  addArtistToProject: 'Add artist to project',
  errorProjectUnauthorized: 'Sorry this project is not available',
  backToProjects: 'Back to Projects',
  more: 'More',
  editInformation: 'Edit information',
  download: 'Download',
  addToMyWorkspace: 'Add to my workspace',
  addTrackToMyWorkspace: 'Add track to my workspace',
  downloadProject: 'Download project',
  delete: 'Delete',
  deleteFromLibrary: 'Delete from library',
  edit: 'Edit',
  rename: 'Rename',
  importingFiles: 'Importing files...',
  selectWorkspacePlan: 'Select your workspace plan',
  selectWorkspacePlanFor: 'Select a plan for {name}',
  discovery: 'Discovery',
  basic: 'Basic',
  customized: 'Customized',
  customize: 'Customize',
  discoveryDesc: 'A plan to discover basics features',
  basicDesc: 'Access to basic features to try the service',
  customizedDesc: 'Customize your workspace according to your needs',
  perMonth: 'per month',
  perYear: 'per year',
  saveBilledAnnually: 'Save {price}€ when billed annually',
  selectAll: 'Select All',
  unselect: 'Unselect',
  selectPlan: 'Select plan',
  selectRole: 'Select role',
  countStorage: '{count} GB storage',
  membersIncluded: '{count} members included',
  mp3Only: 'MP3 only',
  collaborateWithTeam: 'Collaborate with your team',
  metadataCollect: 'Auto Metadata collect',
  sharePlaylists: 'Share your playlist',
  analyzeShare: 'Analyze audience of your shares',
  receiveMusic: 'Receive music in an unique place',
  customizePlaylist: 'Customize your playlists',
  comingSoon: 'Coming soon',
  starting: 'Starting {price}',
  storageUpTo: 'Storage up to 1 To (approx. 200k songs)',
  storageUpToMvp: '100 GB storage',
  storageUpToBeta: '20 GB storage',
  membersUpTo: 'Up to 250 members',
  membersUpToMvp: 'Up to 20 members',
  membersUpToBeta: 'Up to 5 members',
  allFormats: 'All formats (MP3, AAC, WAV)',
  freeTrial: 'Free trial',
  back: 'Back',
  fitNeeds: 'Make the plan that fits perfectly your needs',
  scaleWorkspace: 'Scale your workspace storage and members precisely',
  stepNumber: 'Step {count}',
  howMuchStorage: 'How much storage do you need?',
  howManyPeople: 'How many people will be on your workspace?',
  songsCount: '~ {count} songs',
  countMember: '{count} member | {count} members',
  pricePerUser: '{price} / user',
  billMonthly: 'Bill monthly',
  billAnnually: 'Bill annually',
  countSongs: '~ {count} songs',
  youWillPay: 'You will pay',
  willInclude: 'Your workspace will include',
  memberSeatCount: '{count} member seat | {count} member seats',
  currentPlan: 'Current plan',
  TryItFree14Days: 'Try it for free for 14 days →',
  managePlan: 'Manage and upgrade your workspace plan',
  currentPlanDesc: 'Your workspace is currently on the',
  billedMonthly: '(billed monthly)',
  memberSeats: 'Member seats ({count})',
  storage: 'Storage ({count})',
  storageTitle: 'Storage',
  storageTracks: 'Storage/Tracks',
  totalPerMonth: 'Total per month',
  cancelSubscription: 'Cancel subscription',
  selectPlanFor: 'Select a plan for {name}',
  planName: '{name} plan',
  changeRoleSuccess: 'Member role changed',
  deleteTracksSuccess: 'Track deleted | Tracks deleted',
  removeTracksSucces: 'Track removed from project | Tracks removed from project',
  leftDays:
    'Your trial expires in <span style="font-weight: bold;">{count} day</span>. | Your trial expires in  <span style="font-weight: bold;">{count} days</span>.',
  albumCount: '{count} album | {count} album | {count} albums',
  trackCount: '{count} track | {count} track | {count} tracks',
  attachmentCount: '{count} attachment | {count} attachment | {count} attachments',
  shareArtist: 'Share artist',
  shareAlbum: 'Share album',
  albumep: 'Album & EP',
  shares: 'Shares',
  received: 'Received',
  shared: 'Shared',
  emptyProjectTitle: ' to organize your work and share beautiful presentations',
  emptyProjectSubtext: 'Projects are private until you share them',
  emptyProjectDescription: 'Create a new project to organize and share your work.',
  emptySharedTitle: ' and all the content you share from this page',
  emptySharedSubtext:
    "You'll know everything that happens on your links: openings, streams, downloads",
  noArchive: 'No archived projects',
  noArchiveSubtext: 'All archived projects will be listed here',
  archive: 'Archive',
  newProject: 'New project',
  projectNamePlaceholder: 'e.g. Artist promo 2019',
  projectName: 'Project name',
  create: 'Create',
  newProjectSuccess: 'Project created',
  addDescription: 'Add a description (optional)',
  projectDescPlaceholder: 'e.g. Promo material for this artist in 2019',
  upload: 'Upload',
  new: 'New',
  uploadToLibrary: 'Upload to library',
  addFromLibrary: 'Add from library',
  uploadMediaAndDoc: 'Upload media & docs',
  media: 'Media',
  createdAt: 'Created',
  updatedAt: 'Updated',
  createdBy: 'Created by',
  copiedToClipboard: 'Link copied',
  mailCopied: 'Mail copied',
  deleteProjectSuccess: 'Project deleted',
  archiveProjectSuccess: 'Project archived',
  restoreProjectSuccess: 'Project restored',
  confirmArchive:
    'Are you sure you want to archive "{name}"? That will disable all existing sharing links.',
  modalArchiveTitle: 'Archive project',
  add: 'Add',
  addCover: 'Add cover picture',
  changeCover: 'Change cover',
  dragImgToReposition: 'Drag image to reposition',
  reposition: 'Reposition',
  addTracksSuccess: 'Track added to project | Tracks added to project',
  deleteProjectTitle: 'Delete project?',
  deleteProjectUndo: 'Project "{name}" deleted',
  archiveProjectUndo: 'Project "{name}" archived',
  deleteProjectDesc:
    'Are you sure you want to delete "{name}"? Tracks will still be available in your library, but all other content will be lost.',
  deleteMultipleTracksDesc: 'Are you sure you want to delete these {number} tracks?',
  removeMultipleTracksDesc:
    'Are you sure you want to remove these {number} tracks from the project?',
  removeMultipleTracksDescSubtext:
    'The tracks will also be removed from all the existing share links to this project',
  typeDelete: 'Type "DELETE" to confirm',
  undo: 'UNDO',
  undoDeleteProjectSuccess: 'The deletion has been canceled with success!',
  undoArchiveProjectSuccess: 'The archive has been canceled with success!',
  addTracks: 'Add tracks',
  addFile: 'Add files',
  addTracksToProject: 'Add tracks to project',
  share: 'Share',
  getQuickLink: 'Get quick link',
  getQuickLinkSub: 'Get a unique link to copy and paste',
  createIndividualLinks: 'Create individual link(s)',
  createIndividualLinksSub: 'Create as many trackable links as you need',
  individualLinksDesc: 'Custom named link(s). Each one will be tracked',
  chipsInputLabel: 'Enter link name(s). Press enter or type comma to create a new link',
  allProjects: 'All projects',
  selectTracks: 'Select the tracks you want to add to this project',
  fileUpload: 'File upload',
  folderUpload: 'Folder upload',
  uploadTracks: 'Upload tracks',
  uploadFilesDesc: 'Upload files to your library then you can add tracks to your project!',
  noFilesProject:
    'There are no tracks in your workspace library. You first need to upload tracks before adding them to your project.',
  projectUpdateSuccess: 'Your project has been successfully changed!',
  project: 'Project',
  sharings: 'Sharings',
  sharingLinks: 'Sharing links',
  statistics: 'Statistics',
  updated: 'Updated',
  projectTxtAreaPlaceholder: 'Click here to add a description to this project...',
  addedBy: 'Added by',
  date: 'Date',
  copyProjectSuccess: 'Project duplicated',
  duplicateProject: 'Duplicate project',
  duplicateProjectDescription: 'Create a copy of "{name}"? Please use a different name',
  duplicate: 'Duplicate',
  moveTo: 'Move to',
  addAttachmentSuccess: 'File added to project | Files added to project',
  addAttachmentFail: "You can't attach more than {count} media and documents to a project",
  filesMax: 'files max.',
  removeAttachmentSuccess: 'File deleted',
  removeMember: 'Remove from workspace',
  removeMemberSucess: 'Member removed',
  removeMemberModal:
    'Are you sure you want to remove this member? They will lose access to the workspace.',
  leaveWorkspaceModal: 'Are you sure you no longer want to be part of this workspace?',
  transferOwnershipSuccess: 'Workspace owner changed',
  remove: 'Remove',
  removeFromProject: 'Remove from project',
  mediaAndDocuments: 'Media & Documents',
  searchProject: 'Search projects...',
  sendingProjectLoad: 'Sending project...',
  sendingTrackLoad: 'Sending track...',
  sendingTracksLoad: 'Sending tracks...',
  sendingAlbumLoad: 'Sending album...',
  sendingArtistLoad: 'Sending artist...',
  noResultLabel: 'No project found, try something less specific',
  shareProjectName: 'Share project {name}',
  shareAlbumName: 'Share album {name}',
  shareArtistName: 'Share artist {name}',
  shareTrackName: 'Share track {name}',
  shareTracksName: 'Share tracks {name} & {count} more',
  shareTracksNameWithoutMore: 'Share tracks {name}',
  TracksName: '{name} & {count} more',
  link: 'Link',
  sendByEmail: 'Send by email',
  sendByEmailSub: 'Send trackable links via Bridge email',
  sendByEmailDesc: 'Create a unique link for each recipient. That will allow tracking of activity.',
  to: 'to',
  toCapitalize: 'To',
  from: 'From',
  toBcc: 'To (bcc):',
  toInfo:
    'We’ll send an individual email to each of your recipients. They will all receive a different, unique link, allowing you to track down what they do with your music',
  passwordInfo: 'Protect your link with a custom password ',
  accessPasswordHelper: "Don't forget to share this password with your recipients",
  settingsSaved: 'Settings saved',
  permissions: 'Permissions',
  security: 'Security',
  message: 'Message',
  optionalMessage: 'Message (optional)',
  customURL: 'Custom URL',
  allowStreaming: 'Allow streaming',
  allowMP3: 'Allow MP3 download',
  allowSourceAudio: 'Allow source audio download',
  showDescription: 'Show project description',
  send: 'Send',
  urlPlaceholder: '/your-custom-url',
  customLinkUrl: 'Custom link URL',
  urlErrorMessage: 'This URL is not correct',
  generatePublicLink: 'Generate public link',
  publicLinkDesc: 'Anyone with this link can access it',
  quickLinkDesc: 'Unique sharing link. Limited activity tracking',
  activated: 'Activated',
  copyLink: 'Copy link',
  copyPassword: 'Copy password',
  copyInviteLink: 'Copy invite link',
  createAndCopyInviteLink: 'Create and copy invite link',
  deleteInviteLink: 'Delete invite link',
  linkCopiedExpireIn: 'Link copied. Expires in {count} day | Link copied. Expires in {count} days',
  inviteLinkDeleted: 'Invite link deleted',
  emailListErrorMsg: '{count} invalid email | {count} invalid emails',
  shareProjectSuccess: 'Project {name} shared',
  shareTrackSuccess: 'Track "{name}" shared | Tracks "{name}" shared',
  shareAlbumSuccess: 'Album "{name}" shared',
  shareArtistSuccess: 'Artist "{name}" shared',
  linksCreatedSuccess: '{count} link created for {name} | {count} links created for {name}',
  enableProjectSuccess: 'The public link was successfully activated!',
  disableProjectSuccess: 'The public link was successfully deactivated!',
  shareMoreThan50: 'To share more than 50 tracks at once, please create a project',
  uploading: 'Uploading',
  uploadingDot: 'Uploading...',
  uploaded: 'Uploaded',
  analyzed: 'Analyzed',
  analyzing: 'Analyzing',
  canceled: 'Canceled',
  waiting: 'Waiting',
  couldNotUpload: 'Error: could not upload',
  couldNotExtracted: 'Error: could not extract data',
  couldNotStreamable: 'Error: could not generate streamable data',
  processingCount: '{count} processing file | {count} processing files',
  analyzedCount: '{count} of {total} analyzed',
  processedCount: '{count} processed file | {count} processed files',
  uploadErrorCount: 'Upload failed for {count} file | Upload failed for {count} files',
  uploadingSize: '{count} {total} uploaded',
  close: 'Close',
  shareWith: 'Share with',
  contact: 'Contact',
  sharingName: 'Link name',
  day: '{count} day | {count} days',
  expireIn: 'This link will expires in {count} day | This link will expires in {count} days',
  description: 'Description',
  streaming: 'Streaming',
  displayTrackInfo: 'Display track information',
  downloadAsMp3: 'Download mp3',
  downloadHighQuality: 'Download original quality',
  viewProjectDescription: 'Include project description',
  viewMedia: 'Include media & documents',
  originalDown: 'Original down.',
  viewAttachments: 'View attachments',
  mp3Down: 'MP3 DL',
  mp3Download: 'MP3 download',
  sourceDown: 'Original DL',
  sourceDownload: 'Original quality download',
  status: 'Status',
  info: 'Info',
  linkStatus: 'Link status',
  publicLink: 'Public link',
  quickLink: 'Quick link',
  individualLinks: 'Individual links',
  changeArtistPhoto: 'Change artist photo',
  multipleArtists: '< multiple artists >',
  yes: 'Yes',
  no: 'No',
  details: 'Details',
  file: 'File',
  soundRecording: 'Sound recording',
  musicalWork: 'Musical work',
  fileInformation: 'File information',
  title: 'Title',
  multiple: '< Multiple >',
  lyrics: 'Lyrics',
  comment: 'Comment',
  invalidIsrc: 'Wrong ISRC format',
  invalidIswc: 'Wrong ISWC format',
  maxiChars: 'Max. {count} chars.',
  minChars: 'Min. {count} chars.',
  minValue: 'Minimum value is {count}',
  saveChangesTitle: 'Save your changes?',
  saveChangesEdition: 'The changes you made will affect all selected tracks. Do you want to save?',
  dontShowAgain: 'Don’t show this message again',
  labelLabel: 'Label(s)',
  artistsLabel: 'Artist(s)',
  supportsLabel: 'Support(s)',
  authorsLabel: 'Author(s)',
  composersLabel: 'Composer(s)',
  genresLabel: 'Genre(s)',
  recordingDate: 'Recording date',
  recordingPlace: 'Recording place',
  editArtist: 'Edit artist',
  editAlbum: 'Edit album',
  editArtistInformation: 'Edit artist information',
  saveChangesArtistEdition:
    "Changing the artist's information will affect all the tracks and albums associated with this artist. Do you want to save your changes?",
  saveChangesSupportEdition:
    "Changing the support's information will affect all the tracks associated with this support. Do you want to continue?",
  picture: 'Picture',
  change: 'Change',
  name: 'Name',
  preview: 'Preview',
  releaseDate: 'Release date',
  upc: 'UPC',
  artwork: 'Artwork',
  type: 'Type',
  editSupportInformation: 'Edit support',
  size: 'Size',
  bitrate: 'Bitrate',
  lastUpdated: 'Last updated',
  lastShared: 'Last shared',
  addedDate: 'Added date',
  support: 'Support',
  unknownSupport: 'Unknown support',
  unknownAlbum: 'Unknown album',
  discography: 'Discography',
  createNewSupport: 'Create new support',
  supportName: 'Support name',
  unknownArtist: 'Unknown artist',
  voucherDescription:
    'As a welcome gift, we offer you the ability to try out your first workspace to its full capabilities for free',
  invitationCode: 'Invitation code',
  errors: {
    invitation_one_per_email: 'This person is already a workspace member',
    invitation_resend_time_limit:
      'Invitation was resent recently. Please wait before resending again',
    project_name_unique: 'Project name already in use',
    library_file_unique: 'Already in the library',
    primary_email_unique: 'Email already used on this workspace',
    artist_one_per_workspace: 'This artist already exists',
    'new-password-does-not-match-old': 'Wrong current password',
    voucher_not_usable: 'Invalid invitation code',
    fileSizeTooLarge: 'File too large (Max. {count})',
    tag_exists_in_family: 'Custom tag already exists',
    INSUFFICIENT_PERMISSIONS: "You don't have permission to perform this action",
    ACCESS_FORBIDDEN: "You don't have access to perform this action",
    email_resend_confirmation_interval: "Confirmation email can't be resent due to time limit",
    invitation_workspace_owner: 'This person is already the owner of the workspace',
    mimetypes: 'This file format is not supported',
    workspace_library_file_limit: 'Upgrade your plan to upload more tracks',
    identicalFiles: 'These files are identical',
    projectTitleMaxLength: '{nbChars} characters maximum',
    projectTitleMinLength: '{nbChars} characters minimum',
    playerStateError:
      'Player cannot start. An error occurred, try again later. If issue persists, please contact us.',
    SHARE_LIMIT_REACHED: 'You cannot generate more than {nbLinks} links for a single content',
    projectCoverMaxSize: '5 MB maximum',
    maxLength: '{nbChars} characters maximum'
  },
  invalidPassword: 'Invalid password',
  tags: 'Tags',
  rights: 'Rights',
  credits: 'Credits',
  changesForEveryMember: 'The changes you make will apply for every member of this workspace.',
  addTags: 'Add tags',
  searchTags: 'Search tags',
  allTags: 'All tags',
  saveChangesTags:
    'The changes you make will be made for every member of this workspace. Do you really want to add tags to all selected tracks?',
  addTagsTrack: 'Add tag to selected tracks',
  removeTracksTags: "Remove track's tags | Remove tracks' tags",
  removeTagsSuccess: 'All tags have been successfully deleted!',
  removeTracksRightHolders: 'Remove all right holders',
  confirmRemoveRightHolders: 'Remove all right holders for selected tracks',
  removeRightHoldersSuccess: 'All right holders have been successfully deleted!',
  totalInfo: 'Total share in Master and Publishing',
  totalShouldEqual100: 'Shares in master and publishing should equal 100%',
  rightHolderOnlySingle: 'Right holders are only editable for a single track',
  masterPercent: 'Master.%',
  publishPercent: 'Publish.%',
  author: 'Author',
  composer: 'Composer',
  producer: 'Producer',
  manageTags: 'Manage tags',
  manageCustomTagsTitle: 'Manage custom tags',
  CustomTagsTitle: 'Custom tags',
  manageCustomTagsSubtitle: 'Add, edit, and delete the custom tags of your workspace',
  createTag: 'Create tag',
  customTagList: 'Custom tag list',
  createTagSuccess: 'Custom tag created',
  tagName: 'Tag name',
  modifyTagSuccess: 'Custom tag edited',
  deleteTagSuccess: 'Custom tag deleted',
  manageCustomTags: 'Manage custom tags',
  invalidDateFormat: 'Enter a valid date format (DD/MM/YYYY)',
  publishMasterPercentCannotExceed100: "Master and publishing percentage can't exceed 100%",
  rightHolderNameRequired: 'Right holder name is required',
  rightHolderRoleRequired: 'Right holder role is required',
  reload: 'Reload',
  searchPlaceholder: 'Search',
  trackFilters: 'Filter tracks',
  activeFilter: 'Active filter ({count}) | Active filters ({count})',
  clearFilters: 'Clear filters',
  sharedBy: 'Shared by',
  sharedOn: 'Shared on',
  invalidUserShare: 'User no longer in this workspace',
  disabled: 'Disabled',
  active: 'Active',
  attachmentTooLarge:
    'The file "{filename}" is too large,  please keep it below 25 Mb (images) or 2 Gb (videos / other docs).',
  invalidFile: 'File type not accepted',
  noResultFound: 'No results were found',
  noResult: 'No results found',
  tryAdjustFilter: 'Try adjusting or clearing your filters',
  closeWithoutSaving: 'Close without saving',
  continueEditingMessage: 'You didn’t save your changes.',
  continueEditing: 'Continue editing',
  replaceAudioFile: 'Replace audio file',
  modalReplaceAudioFile: {
    title: 'Replace audio file',
    content: 'Are you sure you want to replace the audio of the track “{title}”?'
  },
  downloadMessages: {
    pending: {
      header: 'Preparing download',
      body: 'Waiting for the process to begin'
    },
    processing: {
      header: 'Generating download',
      body: 'Compressing {tracks} tracks as zip'
    },
    processingProject: {
      header: 'Generating download',
      body: 'Compressing project tracks as zip'
    },
    done: {
      header: 'Download ready',
      body: '{tracks} tracks have been compressed as zip'
    },
    doneProject: {
      header: 'Download ready',
      body: 'Project have been compressed as zip'
    },
    error: {
      header: 'Error',
      body: 'An error occured while generating archive'
    }
  },
  Mon: 'Monday',
  Tue: 'Tuesday',
  Wed: 'Wednesday',
  Thu: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday',
  Sun: 'Sunday',
  Jan: 'January',
  Feb: 'February',
  Mar: 'March',
  Apr: 'April',
  May: 'May',
  Jun: 'June',
  Jul: 'July',
  Aug: 'August',
  Sep: 'September',
  Oct: 'October',
  Nov: 'November',
  Dec: 'December',
  allSharingActivity: 'All sharing activity',
  activitiesDesc: 'Detailed activity report on your shared project',
  noActivityProjectTitle: ' and monitor the activity recorded on your links',
  noActivityProjectSubtitle:
    "You'll know everything that happens on your links: openings, streams, downloads",
  noActivityTitle: 'We’ve registered no activity from recipients on this {type}',
  noActivitySubtitle: 'Share this {type} and monitor here the activity recorded on the links',
  noShareTitle: 'You haven’t shared this {type} yet',
  noShareSubtitle: 'Share this {type} and manage your sharing links from this page.',
  leaveWorkspace: 'Leave workspace',
  cropImage: 'Crop image',
  notifications: {
    header: 'Notifications',
    message: 'New activity on',
    from: 'from',
    publicLink: 'public link',
    empty: 'No notification',
    emptySubtext:
      'You will receive notifications whenever people open, stream, or download the files you share'
  },
  created: 'Created',
  lastModified: 'Last modified',
  restore: 'Restore',
  before: 'Before',
  after: 'After',
  on: 'On',
  between: 'Between',
  totalTracks: 'Total tracks',
  markAllAsRead: 'Mark all as read',
  nextTrack: 'Next track',
  previousTrack: 'Previous track',
  newVersionAlertMessage:
    'A new version of Bridge.audio is available. Please refresh the page to update.',
  offlineAlert: 'No connection. Get back online to enjoy Bridge.audio',
  mobile: {
    title: 'The mobile version of Bridge.audio is coming soon.',
    subtitle: 'In the meantime we recommend using Bridge.audio on your computer. You can also ',
    link1: 'enable desktop mode on your mobile.',
    core1: 'Note that ',
    link2: 'the links you share ',
    core2: 'via Bridge are already 100% mobile friendly.',
    link3: 'More info'
  },
  albumName: 'album name',
  artistName: 'artist name',
  resendCheckingEmail: 'Verification email sent',
  audioFileReplaced: 'Audio file replaced',
  deleteTrack: 'Delete track',
  deleteTracks: 'Delete tracks',
  removeTrack: 'Remove track from project',
  removeTracks: 'Remove tracks from project',
  emailBanner: {
    core1: 'We sent a verification link to',
    core2: 'Please confirm your email address to use Bridge.audio to its full capabilities.',
    sendAgain: 'Didn’t get the mail? Send it again'
  },
  verificationEmailSent: 'Verification email sent',
  manageSharingLinks: 'Manage sharing links',
  seeSharingActivity: 'See sharing activity',
  pleaseUpgradeBrowser:
    'Please upgrade your browser to its latest version to make the most of Bridge.audio features.',
  // Subscription
  subscriptionCanceledBanner:
    'Your workspace will be downgraded to a free plan at the end of your billing period: {date}. Your workspace might be locked if you are exceeding the Free plan storage limits.',
  subscription: {
    headline: {
      yourPlan: 'Your plan',
      invoice: 'Billing history',
      cancel: 'Cancel subscription'
    },
    form: {
      countryRequired: 'Country is required',
      addressRequired: 'Address is required',
      cityRequired: 'City is required',
      postalCodeRequired: 'Zipcode is required',
      emailAddressRequired: 'Email address is required',
      companyNameRequired: 'Company name is required',
      invalidEmail: 'Email address is invalid',
      invalidTaxNumber: 'Tax number is invalid'
    },
    cancellationSuccessful: 'Changes saved. You canceled your subscription.',
    cancellationError: 'Cancellation failed, please retry.'
  },
  billingDetails: {
    paymentMethod: 'Payment method',
    addPaymentMethod: 'Add card',
    changePaymentMethod: 'Change payment method',
    expires: 'Expires',
    invoicesSentTo: 'Invoices sent to',
    changeContact: 'Change contact',
    address: 'Address',
    company: 'Company',
    changeAddressAndCompany: 'Change address & company info',
    tax: 'Tax ID'
  },
  invalidAddressValue: 'Error occured while saving your informations.',
  selectYourWorkspacePlan: 'Select your workspace plan',
  reccurenceMonthly: 'Monthly',
  reccurenceAnnually: 'Annually (save 50%)',
  planStorageInformation: '{tracks} tracks (or {storage} storage)',
  additionnalUsers: '{count} additionnal user | {count} additionnal users ',
  freePlan: {
    description: 'A simple way to get started for people & teams new to Bridge.audio',
    information: 'Free forever. No credit card required',
    benefitsMembers: '5 members max',
    benefitsSharing: 'Links never expire',
    benefitsNotifications: 'Unlimited notifications and activity tracking'
  },
  proPlan: {
    description:
      'Choose a plan for your team that best fits your needs of storage and member seats',
    byMonth: ' / month.',
    annuallyBilled: 'Billed annually',
    benefitsMembers:
      'Unlimited members (first 5 are free, then {price}{currency}  / additional member / month)',
    benefitsSharing: 'Links never expire',
    benefitsNotifications: 'Unlimited notifications and activity tracking'
  },
  saveBillingInfo: 'Save billing info',
  completeTransaction: 'Complete transaction',
  subscriptionSuccessful: 'Changes saved. You  updated your plan.',
  subscriptionFailed: 'Updating subscription failed',
  modalCancellation: {
    title: 'Are you sure you want to cancel your plan?',
    emailForgotConfirmation: 'An email has been sent to {email}',
    forgotPassword: 'Forgot password?',
    cancelButton: 'No, go back',
    confirmButton: 'Yes, cancel'
  },
  emptyState: {
    title: 'No support for the artist',
    text: 'It is not saved as a main artist on any album in your library',
    link: "View artist's tracks"
  },
  upgradeAlert: {
    title: 'This workspace is full',
    text: 'You have reached storage limits. Please consider',
    link: 'upgrading your plan',
    tooltipText: 'Only administrators can upgrade plan'
  },
  workspaceAlmostFull: 'Your workspace is almost at full capacity',
  sendMeACopy: 'Send me a copy',
  trackLimit: 'Track limit',
  limitTracks:
    'We are sorry, this upload outreaches the maximum number of tracks allowed in this workspace.',
  inviteNewMembers: ' to invite new members.',
  upgradePlanByAdmin: 'Only workspace admins can switch plan',
  uploadToModal: {
    warning: 'Files other than audio can only be added to projects',
    projectRequired: 'Select a project to upload these files',
    projectMinChar: 'Project name must have at least 3 characters',
    tooltipNoAudio: 'Only audio files can be added to Library',
    tooltipWithAudio: 'Uploaded audio files will be added to your library',
    audioFilesOnly: '(audio files only)'
  },
  deleteWorkspace: {
    sectionTitle: 'Danger Zone',
    title: 'Delete workspace',
    text: 'All content will be permanently erased',
    description:
      'This action cannot be undone. This will permanently delete all files and projects. Please enter your password to continue',
    buttonLabel: 'Delete workspace',
    activeFreeTrialSubscription:
      "You'll be able to delete your workspace at the end of your 14-day free trial",
    activeProSubscription:
      'You can’t delete a workspace with an active subscription. Please cancel your current subscription, then delete the workspace'
  },
  workspaceDeleted: 'Workspace deleted',
  linksExplanation:
    "Here you'll find all the links you create. You can manage permissions and security settings for each link. You can also copy and then share any link the way you want.",
  okGotIt: 'Ok, got it',
  a2mbSubmissionSuccess: 'Submission added to your workspace',
  a2mbTrackSubmissionSuccess: 'Track added to your workspace',
  modalUpgrade: {
    titleFirstPart: 'Do you really want to update your plan for',
    titleSecondPart: 'Here is a summary of the changes:',
    cancelButton: 'No, cancel',
    confirmButton: 'Yes, confirm',
    extraMemberCost: 'Unlimited (5 free, {currency}5 per additional member / month)'
  },
  modalUpgradeFreeTrial: {
    title: 'You updated your free trial',
    line1: 'The trial will end on {dateEnd}.',
    line2:
      'To prevent any interruption of Pro services, you can already add your billing information.',
    line3: "You won't be billed until the end of your free trial.",
    cancelButton: "I'll do it later",
    confirmButton: 'Add billing info'
  },
  annually: 'Annually',
  monthly: 'Monthly',
  storageArgs: 'Storage',
  billingPeriod: 'Billing period',
  annualBill: 'Annual bill',
  monthlyBill: 'Monthly bill',
  dueToday: 'Due today',
  nextInvoiceTotal: 'Next invoice total',
  needMoreInfo: 'Need more info?',
  contactUs: 'Contact us',
  submission: 'Submission',
  deleteSubmission: 'Delete submission',
  refreshInbox: 'Refresh inbox',
  hintWorkspaceName: 'Hint: Most people use their artist or company name',
  mainActivity: "What's your main activity ?",
  inviteYourTeam: 'Invite your team',
  skipForNow: 'Skip for now',
  sendInvitesContinue: 'Send invites & continue',
  linkName: 'Link name',
  streamingQuality: {
    title: 'Streaming quality',
    normal: 'Normal',
    high: 'High'
  },
  addAudioFiles: 'Add audio files',
  sharingEmptyTitle: ' and all the content you share from this page',
  sharingEmptyHelper:
    "You'll be able to change permissions for each link, add or remove passwords, activate or deactivate a link...",
  freeTrialBanner: {
    title: 'Happy with Bridge so far ?',
    text: 'Your free trial ends <b>{label}</b>. You can always Upgrade to Pro now.',
    billingText: 'You can always upgrade to Pro now or at the end of your 14-day free trial period',
    buttonLabel: 'Upgrade now'
  },
  freeTrialStarted: 'Your {days} days free trial has started',
  manageLinks: 'Manage links',
  createProjects: 'Create projects',
  shareProject: 'Share this project',
  shareInboxLink: 'Share your inbox link',
  inboxEmptyTitle: ' to receive files from anyone',
  inboxEmptySubtitle:
    'Received files do not count in your storage. Your inbox is free and unlimited',
  emptySubmissionTitle: 'Select a submission',
  emptySubmissionSubtitle: 'Stream, download, or add to your workspace the files you received',
  trackInformation: 'Track information',
  copyTooltip: 'Add files to selected destination, and keep them in current location',
  moveTooltip: 'Add files to selected destination, and remove them from current location',
  addTooWorkspaceSuccess:
    'You added {num} file to {workspace} | You added {num} files to {workspace}',
  addToProjectSuccess:
    'You added {num} file to {projectName} | You added {num} files to {projectName}',
  addEntitySuccess: 'You added {entity} to {workspace}',
  addToHubFailed: "Can't add files to {hub}. Some tracks don't meet the minimum requirements.",
  addToHubFileNotFound: "Some of the files can't be found. Files may have been moved, or deleted.",
  projectAddToWorkspaceSuccess: 'You added a project to {workspace}',
  attachmentCopyToProject: 'You added a file to {project}',
  selectWorkspace: 'Select workspace',
  usage: 'Usage',
  uploadError: 'Error:',
  hubsInformation: 'Hubs',
  hubsDescription: {
    first:
      'Set a default contact information for what you share on hubs. You can also add any extra information that you deem relevant.',
    second: 'This will appear in the comment section of each track shared on hubs.'
  },
  syncHub: 'Sync Hub',
  hubSettingsPlaceholder: 'Type something...',
  commentDescriptionFirstPart: 'Set default information for tracks shared on hubs.',
  commentDescriptionSecondPart: 'Go to settings',
  contactInformation: 'Contact information'
};
