import gql from 'graphql-tag';
import {
  projectFragment,
  projectAttachmentFragment,
  supportsFragment,
  artistsFragment,
  shareFragment,
  fileInformationFragment
} from '../fragments';

export const PROJECT_LIST = gql`
  query projectList($workspaceId: ID!, $status: String!, $page: Int, $limit: Int) {
    ProjectList(workspaceId: $workspaceId, status: $status, page: $page, limit: $limit) {
      last_page
      data {
        ...project
      }
    }
  }
  ${projectFragment.project}
`;

export const SEARCH_PROJECTS = gql`
  query searchProjects(
    $query: SearchProjectsQueryInput!
    $filters: SearchProjectFiltersInput
    $sort: SearchSortInput
    $offset: Int
    $limit: Int
  ) {
    SearchProjects(query: $query, filters: $filters, sort: $sort, offset: $offset, limit: $limit) {
      total
      total_active_projects
      data {
        id
        workspaceId
        name
        description
        numberOfTracks
        numberOfAttachments
        numberOfPrivateShares
        createdById
        createdAt
        updatedAt
        updatedById
      }
    }
  }
`;

export const PROJECT_DETAILS_TRACKS = gql`
  query projectDetails($projectId: ID!, $page: Int! = 1, $limit: Int! = 20) {
    ProjectDetailsTracks: ProjectDetails(projectId: $projectId) {
      id
      name
      total_tracks
      tracksPaginate(page: $page, limit: $limit) {
        id
        added_by
        created_at
        position
        libraryFile {
          id
          title
          duration
          supports {
            ...supports
          }
          artists {
            ...artists
          }
          file_information {
            ...fileInformation
          }
        }
      }
    }
  }
  ${supportsFragment.supports}
  ${artistsFragment.artists}
  ${fileInformationFragment.fileInformation}
`;
export const PROJECT_DETAILS_INFO = gql`
  query projectDetails($projectId: ID!) {
    ProjectDetails(projectId: $projectId) {
      id
      name
      description
      updated_at
      total_tracks
      total_tracks_length
      total_attachments
      workspace_id
      cover {
        file_id
        url
        position
      }
      attachments {
        ...projectAttachment
      }
    }
  }
  ${projectAttachmentFragment.projectAttachment}
`;

export const PROJECT_TOTAL_ATTACHMENTS = gql`
  query ProjectDetails($projectId: ID!) {
    ProjectDetails(projectId: $projectId) {
      id
      total_attachments
    }
  }
`;

export const PROJECTS_INFORMATION = gql`
  query projectsInformation($workspaceId: ID!) {
    ProjectsInformation(workspaceId: $workspaceId) {
      total_active_projects
    }
  }
`;

export const PROJECT_SEARCH = gql`
  query projectsSearch($workspaceId: ID!, $name: String!, $limit: Int) {
    ProjectsSearch(workspaceId: $workspaceId, name: $name, limit: $limit) {
      ...project
    }
  }
  ${projectFragment.project}
`;

export const PROJECT_SHARING = gql`
  query ProjectSharing($projectId: ID!) {
    ProjectSharing(projectId: $projectId) {
      ...share
    }
  }
  ${shareFragment.share}
`;

export const SHARE_AUTOCOMPLETE_EMAILS = gql`
  query ShareAutocompleteEmails($workspaceId: ID!, $email: String!, $limit: Int) {
    ShareAutocompleteEmails(workspaceId: $workspaceId, email: $email, limit: $limit)
  }
`;

export const SHARE_DETAILS = gql`
  query ShareDetails($shareId: ID!) {
    ShareDetails(shareId: $shareId) {
      id
      share_name
      custom_name
      workspace_id
      shared_by
      status
      link
      created_at
      updated_at
      share_hash
      entities {
        type
        ids
      }
    }
  }
`;

export const ACTIVITIES_PROJECT_LIST = gql`
  query ActivitiesProjectList(
    $workspaceId: ID!
    $projectId: ID!
    $from: Int
    $to: Int
    $actions: [String]
    $shareIds: [ID]
    $page: Int
  ) {
    ActivitiesProjectList(
      workspaceId: $workspaceId
      projectId: $projectId
      from: $from
      to: $to
      actions: $actions
      shareIds: $shareIds
      page: $page
    ) {
      data {
        id
        workspaceId
        context {
          name
          id
        }
        action
        entity {
          type
          id
          description
        }
        performedBy {
          userId
          username
          userEmail
        }
        performedAt
      }
    }
  }
`;
