<template>
  <div class="settings">
    <div>
      <div class="settings-content-modal-back">
        <submit-button
          btnStyle="secondary"
          size="medium"
          label="Back to app"
          iconClass="ri-arrow-left-line"
          @submit="closeModal"
        />
      </div>
      <div class="settings-content-modal-sidebar">
        <sidebar :groups="groups" />
      </div>
    </div>
    <modal
      class="settings-modal"
      isVisible
      size="large"
      :title="modalTitle"
      :secondTitle="workspaceName"
      crossSize="large"
      noEscape
      @close="closeModal"
    >
      <template v-slot:body>
        <div class="settings-content">
          <div class="settings-content-modal">
            <div class="settings-content-modal-content">
              <router-view :isWorkspaceAdmin="isWorkspaceAdmin"></router-view>
            </div>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash.get';

import { appConfig } from '@/utils/constants';
import { ME } from '@/graphql/queries/user';
import { XMLHttpRequestHandler } from '@/utils/functions/xhr';
import { isUserInEuroZone } from '@/utils/functions/user';
import { get as getRoute } from '../../../router/routes';

export default {
  data() {
    return {
      previousRouteName: null
    };
  },
  apollo: {
    me: {
      query: ME
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    isWorkspaceAdmin() {
      return (
        get(this.currentWorkspace, 'members', []).find(m => m.user_id === this.me.id).role.id ===
        '1'
      );
    },
    modalTitle() {
      let title = this.$t('settings');
      if (this.$route.path.includes('/tags')) {
        title = 'Manage tags';
      }
      if (this.$route.path.includes('/members')) {
        title = 'Workspace members';
      }
      if (this.$route.path.includes('/general')) {
        title = 'Workspace settings';
      }
      if (this.$route.path.includes('/account')) {
        title = 'Account settings';
      }
      if (this.$route.path.includes('/billing')) {
        title = 'Billing settings';
      }
      return title;
    },
    workspaceName() {
      let workspaceNameToDisplay;
      if (this.$route.path !== getRoute('accountSettings')) {
        workspaceNameToDisplay = this.currentWorkspace.name;
      }
      return workspaceNameToDisplay;
    },
    groups() {
      const sidebarMenuItems = [
        {
          title: this.$t('personnal'),
          options: [
            {
              label: this.$t('account'),
              icon: 'ri-account-circle-line',
              route: getRoute('accountSettings')
            }
          ]
        },
        {
          title: this.$t('workspace'),
          options: [
            {
              label: this.$t('general'),
              icon: 'ri-list-settings-line',
              route: getRoute('generalSettings')
            },
            {
              label: this.$t('members'),
              icon: 'ri-group-line',
              route: getRoute('membersSettings')
            },
            {
              label: this.$t('tags'),
              icon: 'ri-price-tag-3-line',
              route: getRoute('tagsSettings')
            },
            this.isWorkspaceAdmin && {
              label: this.$t('billing'),
              icon: 'ri-file-list-2-fill',
              route: getRoute('billingSettings')
            }
          ].filter(Boolean)
        }
      ];
      return sidebarMenuItems;
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      // Redirect users that are not admin to settings home
      if (to.name === 'billingSettings' && vm.getUserRole !== 'Admin') {
        next(getRoute('accountSettings'));
      }
      const that = vm;
      that.previousRouteName = from.path;
    });
  },
  mounted() {
    XMLHttpRequestHandler('/country', result => {
      this.$store.commit('setUserCountry', result.country);
      if (!isUserInEuroZone(result.country)) {
        this.$store.commit('setUserCurrency', appConfig.currencies.DOLLAR);
      } else {
        this.$store.commit('setUserCurrency', appConfig.currencies.EURO);
      }
    });
  },
  methods: {
    closeModal() {
      if (!this.previousRouteName) {
        this.$router.push(getRoute('trackList'));
      } else {
        this.$router.push(this.previousRouteName);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.settings {
  position: fixed;
  z-index: map-get($z-index, settings);
  top: 0;
  left: 0;

  &-content {
    &-modal {
      &-back {
        height: 88px;
        width: 179px;
        background-color: white;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-sidebar {
        position: fixed;
        top: 88px;
        height: calc(100% - 88px);
        background-color: white;
        z-index: 11;
      }

      &-content {
        height: calc(100vh - 73px);
        width: 100%;
        box-sizing: border-box;
        padding: 40px 32px 200px 32px;
        overflow-y: auto;
        z-index: 2;
      }

      &-footer {
        position: absolute;
        bottom: 0;
        left: 181px;
        width: calc(100% - 181px);
        z-index: 3;

        &-buttons {
          display: flex;
          align-items: center;
          height: 72px;
          justify-content: flex-end;
          padding: 0 16px 0 0;
          background-color: $color_neutral_0;
          border-bottom-right-radius: 8px;
          @include shadow-up-01;

          &-btn {
            padding: 0 8px 0 0;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.settings {
  .modal.settings-modal {
    right: 0;
    width: calc(100% - 180px);
    border-left: 1px solid $color_neutral_30;
    .modal-content.large {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
    }
    .modal-content-header {
      height: 72px;
      .modal-content-header-title {
        font-size: 24px;
        line-height: normal;
      }
    }
  }
}
</style>
