
<div class="share-modal">
  <div class="share-modal-header">
    <div class="share-modal-header-tabs">
      <tabs
        class="share-modal-tab"
        :tabs="tabs"
        :noRouteCurrentTab="currentTab"
        noRoute
        isSecondary
        @changeTab="changeTabValue"
      />
    </div>
  </div>
  <div class="share-modal-body">
    <quick-link-view
      v-if="!isSharePublicLoading"
      v-show="currentTab === 0"
      :defaultTab="defaultTab"
      ref="quickLinkView"
      :entityIds="entityIds"
      :entityType="entityType"
      :myRoleId="myRoleId"
      :sharesExpiresIn="sharesExpiresIn"
      :isPublicLinkActivated="isPublicLinkActivated"
      :basePublicLink="basePublicLink"
      :publicLinkAccessRights="publicLinkAccessRights"
      :displaySharePasswordPublic="displaySharePasswordPublic"
      :defaultSharePassword="sharePasswordPublic"
      :shareId="shareId"
      :shareHash="shareHash"
      :selectedTracksLength="selectedTracksLength"
      @togglePublicLinkActivation="togglePublicLinkActivation"
      @setPublicLinkAccessRights="setPublicLinkAccessRights"
      @toggleSharePasswordPublic="toggleSharePasswordPublic"
      @handlePublicBlur="handlePublicBlur"
      @closeModal="$emit('closeModal')"
    />
    <div v-else v-show="currentTab === 0" class="quick-link-loader">
      <spinner-without-progress color="grey" :size="32" />
    </div>
    <individual-links-view
      v-show="currentTab === 1"
      ref="shareByIndividual"
      :entityType="entityType"
      :myRoleId="myRoleId"
      :sharesExpiresIn="sharesExpiresIn"
      :accessRights="individualLinksAccessRights"
      :displayIndividualPassword="displayIndividualPassword"
      @setIndividualLinks="setIndividualLinks"
      @setLinkAccessRights="setLinkAccessRights"
      @toggleSharePasswordIndividual="toggleIndividualPassword"
      @setSharePasswordIndividualLinks="setSharePasswordIndividualLinks"
    />
    <share-by-mail-view
      v-show="currentTab === 2"
      ref="shareByMailView"
      :entityType="entityType"
      :myRoleId="myRoleId"
      :sharesExpiresIn="sharesExpiresIn"
      :UserEmails="UserEmails"
      :primaryEmail="primaryEmail"
      :emailAccessRights="emailAccessRights"
      :displaySharePassword="displaySharePassword"
      :emailListErrorMsg="emailListErrorMsg"
      @setEmailList="setEmailList"
      @setEmailAccessRights="setEmailAccessRights"
      @setEmailReplyTo="setEmailReplyTo"
      @toggleSharePassword="toggleSharePassword"
      @setSharePassword="setSharePassword"
      @setMessage="setMessage"
      @resendConfirmationEmail="resendConfirmationEmail"
      @closeModal="$emit('closeModal')"
    />
    <!-- commented for now, we need a query to display the number of share, it's in progress in back -->
    <!-- <div class="share-modal-view-links">
      <div class="separator"></div>
      <submit-button
        class="view-links-btn"
        size="medium"
        btnStyle="tertiary"
        label="View links"
        exception
        iconClass="ri-equalizer-fill"
        @submit="goToSharingList"
      />
    </div> -->
  </div>
  <div v-if="currentTab === 1" class="share-modal-body-button">
    <submit-button
      btnStyle="primary"
      size="medium"
      :label="$t('create')"
      @submit="shareEntity(false, null, true)"
      :disabled="hasNullLink"
    />
  </div>
  <div v-if="currentTab === 2" class="share-modal-body-button">
    <div class="share-modal-body-button--">
      <submit-button
        btnStyle="secondary"
        size="medium"
        :label="$t('cancel')"
        @submit="$emit('closeModal')"
      />
    </div>
    <submit-button
      btnStyle="primary"
      size="medium"
      :label="$t('send')"
      :disabled="disableSendByEmailButton"
      @submit="shareEntity(true)"
    />
  </div>
</div>
