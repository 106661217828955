import get from 'lodash.get';
import * as timeago from 'timeago.js';

import { newFormatDate, moreThanAYear } from '@/utils/functions/time';

export const getCreatedByInfo = (project, workspace, key) => {
  const user = project.createdById
    ? workspace.members.find(member => member.user_id === project.createdById)
    : false;
  if (user) {
    if (key === 'url') {
      return user.profile_picture ? user.profile_picture.url : '';
    }
    if (key === 'name') {
      return `${user.firstname} ${user.lastname}`;
    }
    if (key === 'initial') {
      if (user.firstname) {
        return `${user.firstname.substring(0, 1).toUpperCase()}`;
      }
    }
  }
  return undefined;
};

export const getUpdatedByInfo = (project, workspace, key) => {
  const user = project.updatedById
    ? workspace.members.find(member => member.user_id === project.updatedById)
    : false;
  if (user) {
    if (key === 'url') {
      return user.profile_picture ? user.profile_picture.url : null;
    }
    if (key === 'name') {
      return `${user.firstname} ${user.lastname}`;
    }
    if (key === 'initial') {
      if (user.firstname !== null) {
        return `${user.firstname.substring(0, 1).toUpperCase()}`;
      }
      return '?';
    }
  }
  return undefined;
};

export const generateProjectList = (projects, workspace) => {
  if (!projects) {
    return [];
  }
  return get(projects, 'data', []).map(project => {
    const createdByUrl = getCreatedByInfo(project, workspace, 'url');
    const createdByName = getCreatedByInfo(project, workspace, 'name');
    const createdByInitial = getCreatedByInfo(project, workspace, 'initial');
    return {
      id: project.id,
      name: project.name || '',
      trackCount: project.numberOfTracks,
      attachmentCount: project.numberOfAttachments,
      createdAt: {
        relative: timeago.format(project.createdAt * 1000),
        date: newFormatDate(project.createdAt * 1000),
        displayDate: moreThanAYear(project.createdAt * 1000)
      },
      createdBy: {
        url: createdByUrl,
        name: createdByName,
        initial: createdByInitial
      },
      updatedAt: {
        relative: timeago.format(project.updatedAt * 1000),
        date: newFormatDate(project.updatedAt * 1000),
        displayDate: moreThanAYear(project.updatedAt * 1000)
      },
      updatedBy:
        getUpdatedByInfo(project, workspace, 'name') !== ''
          ? {
              url: getUpdatedByInfo(project, workspace, 'url'),
              name: getUpdatedByInfo(project, workspace, 'name'),
              initial: getUpdatedByInfo(project, workspace, 'initial')
            }
          : {
              url: createdByUrl,
              name: createdByName,
              initial: createdByInitial
            },
      privateShare: project.numberOfPrivateShares
    };
  });
};
