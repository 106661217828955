
<div>
  <div class="modal-container">
    <p class="modal-paragraph">
      The workspace "<b>{{ getWorkspaceName }}</b
      >" will be downgraded to a Free plan. Your downgrade will take effect at the end of your
      billing cycle.
    </p>
    <p class="modal-paragraph">
      If your storage usage exceeds the Free plan limits (50 tracks / 1 Gb), your workspace will
      be read-only. You can clear up space to match your new plan limits.
      <a
        href="https://www.notion.so/bridgeaudio/Plans-Pricing-18d38a83656345358880a8c136f9adb3"
        target="_blank"
        >Learn more</a
      >
    </p>
    <div class="modal-input">
      <text-field
        inputType="password"
        :fieldModel.sync="password"
        label="Enter your password"
        large="large"
        helper="required"
        autocomplete="false"
        withVisibility
      />
    </div>
    <div class="modal-link" @click="sendForgotPasswordMail">
      {{ $t('modalCancellation.forgotPassword') }}
    </div>
    <checkbox
      id="checkbox"
      label="I understand that my workspace will be downgraded to a free plan"
      :isChecked="agreement"
      @toggleCheckbox="toggleCheckbox"
    />
  </div>
  <div class="modal-buttons">
    <submit-button
      btnStyle="tertiary"
      size="medium"
      :label="$t('modalCancellation.cancelButton')"
      @submit="closeModal"
      :disabled="pending"
    />
    <submit-button
      btnStyle="secondary"
      size="medium"
      :label="$t('modalCancellation.confirmButton')"
      :danger="true"
      :disabled="submitButtonDisabled"
      @submit="cancelSubscription"
      :pending="pending"
    />
  </div>
</div>
