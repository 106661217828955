<template>
  <div class="container">
    <div :class="`row level${level}`" @click="toggleExpand">
      <div class="title">
        <div v-if="dotColor" class="dots" :style="`background: ${dotColor}`" />
        <span>{{ title }}</span>
        <span class="subtitle" v-if="subtitle">{{ subtitle }}</span>
      </div>
      <i :class="{ 'ri-arrow-down-s-fill': expanded, 'ri-arrow-right-s-fill': !expanded }" />
    </div>
    <transition name="fade">
      <div>
        <slot v-if="expanded"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Title',
      required: false
    },
    isExpanded: {
      type: Boolean,
      required: false,
      default: false
    },
    dotColor: {
      type: String,
      required: false,
      default: null
    },
    level: {
      type: Number,
      required: false,
      default: 1
    },
    subtitle: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      expanded: this.isExpanded
    };
  },
  methods: {
    toggleExpand() {
      this.expanded = !this.expanded;
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
}
.smooth-enter-to,
.smooth-leave {
  height: auto;
}
.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;

  &:hover {
    background-color: $color_neutral_10;
    color: $color_neutral_100;
  }
}

.title {
  display: flex;
  align-items: center;
}

.level1 {
  padding: 0 16px 0 16px;
}

.level2 {
  padding: 0 16px 0 48px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
  height: auto;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  height: 0;
}

.dots {
  border-radius: 2px;
  width: 8px;
  height: 8px;
  margin: 0 8px 0 0;
}

.subtitle {
  @include body-2;
  color: $color_neutral_60;
  margin: 4px 0 0 4px;
}
</style>
