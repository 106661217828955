<template>
  <div class="action-bar">
    <div class="selection-info" v-if="!isPreview">
      {{ numberOfSelection }}
      <div class="selection-info-actions">
        <div class="selection-info-actions--" @click="$emit('selectAll')">
          {{ label.selectAll }}
        </div>
        <div class="selection-info-divider"></div>
        <div class="selection-info-actions--" @click="$emit('unselect')">{{ label.unselect }}</div>
      </div>
    </div>
    <div v-else class="preview-infos">
      <div
        v-for="(action, id) in previewActions"
        class="preview-infos--"
        :key="`${id}-previewAction`"
      >
        <div class="preview-infos-btn btn" @click="action.preIcon.method">
          <i :class="action.preIcon.icon"></i>
        </div>
        <div class="preview-info">{{ action.template }}</div>
        <div class="preview-infos-btn btn" @click="action.postIcon.method">
          <i :class="action.postIcon.icon"></i>
        </div>
      </div>
    </div>
    <div class="main-actions" v-if="actions.length">
      <div class="main-actions--" v-for="(action, index) in actions" :key="`action-${index}`">
        <div
          class="main-actions-btn btn"
          :class="[{ 'action-disabled': action.disabled }]"
          v-tooltip="{
            content: action.tooltip,
            delay: { show: 500, hide: 0 },
            offset: 14
          }"
          @click="handleAction(action)"
        >
          <i :class="action.icon"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { VTooltip } from 'v-tooltip';

Vue.directive('tooltip', VTooltip);

export default {
  props: {
    numberOfSelection: {
      type: String,
      required: false,
      default: ''
    },
    actions: {
      type: Array,
      required: false,
      default: () => []
    },
    label: {
      type: Object,
      required: false,
      default: () => ({ selectAll: 'Select all', unselect: 'Unselect' })
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    totalPreviewed: {
      type: Number,
      required: false,
      default: 0
    },
    currentPreviewed: {
      type: Number,
      required: false,
      default: 0
    },
    zoomLevel: {
      type: Number,
      required: false,
      default: 100
    }
  },
  computed: {
    previewActions() {
      return [
        {
          template: `${this.currentPreviewed} / ${this.totalPreviewed}`,
          preIcon: {
            icon: 'ri-arrow-left-s-line',
            method: () => {
              this.$emit('previewPrev');
            }
          },
          postIcon: {
            icon: 'ri-arrow-right-s-line',
            method: () => {
              this.$emit('previewNext');
            }
          }
        }
        // {
        //   template: `${this.zoomLevel}%`,
        //   preIcon: {
        //     icon: 'ri-indeterminate-circle-line',
        //     method: () => {
        //       this.$emit('zoomOut');
        //     }
        //   },
        //   postIcon: {
        //     icon: 'ri-add-circle-line',
        //     method: () => {
        //       this.$emit('zoomIn');
        //     }
        //   }
        // }
      ];
    }
  },
  methods: {
    handleAction(action) {
      if (!action.disabled) {
        action.method();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.action-bar {
  display: flex;
  align-items: center;
  height: 64px;
  width: fit-content;
  padding: 0 24px;
  border-radius: 4px;
  background-color: $color_neutral_100;
}
.selection-info {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 8px;
  @include body-1;
  color: $color_neutral_40;
  white-space: nowrap;
  &-actions {
    display: flex;
    align-items: center;
    margin-top: 8px;
    @include body-2;
    color: $color_primary_50;
    user-select: none;
    &-- {
      cursor: pointer;
      padding: 4px;
      border: 1px solid $color_neutral_100;
      flex-shrink: 0;
      &:hover {
        border: 1px solid $color_primary_50;
        border-radius: 4px;
      }
    }
  }
  &-divider {
    width: 1px;
    height: 10px;
    background-color: $color_neutral_80;
    margin: 0 4px;
    flex-shrink: 0;
  }
}
.preview-infos {
  display: flex;
  @include body-1;
  color: $color_neutral_0;
  white-space: nowrap;
  .preview-info {
    margin: 0 4px;
    font-variant-numeric: tabular-nums;
  }
  &-- {
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: fit-content;
    padding-right: 8px;
    &:not(:first-child) {
      border-left: 1px solid $color_neutral_80;
      padding-left: 8px;
    }
  }
  &-btn {
    height: 32px;
    width: 32px;
    font-size: 16px;
  }
}
.main-actions {
  display: flex;
  &-- {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: fit-content;
    color: $color_neutral_0;
    font-size: 20px;
    height: 33px;
    border-left: 1px solid $color_neutral_80;
    padding-left: 8px;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
  &-btn {
    height: 40px;
    width: 40px;
  }
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  &:not(.action-disabled):hover {
    border: 1px solid $color_neutral_0;
  }
}
.action-disabled {
  i {
    opacity: 0.4;
  }
}
</style>
