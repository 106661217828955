<template>
  <div class="albums">
    <div v-if="artistDetails.supports && artistDetails.supports.length > 0" class="albums-scroller">
      <horizontal-scroller :thumbnails="thumbnails" @handleClickThumbnail="handleClickThumbnail" />
    </div>
    <div class="albums-content" v-if="artistDetails.supports && artistDetails.supports.length > 0">
      <div
        class="albums-content-album"
        v-for="(support, index) in artistDetails.supports"
        :key="`album-${support.id}`"
        :id="support.id"
      >
        <artist-album
          :support="support"
          :trackList="getTracksByAlbum(support)"
          :keepProjectDropdownExpand="keepProjectDropdownExpand"
          :uncheckTracks="uncheckTracks"
          :editMode="editMode"
          @createProject="createProject"
          @openConfirmDeleteModal="openConfirmDeleteModal"
          @toggleEditMode="toggleEditMode"
          @setArtistAlbumSelectedTracks="
            tracks => {
              setArtistAlbumSelectedTracks(tracks, index);
            }
          "
        />
      </div>
      <div
        v-if="flatSelectedTracks.length"
        class="action-bar-container"
        :class="{ 'panel-open': isEditPanelOpened }"
      >
        <action-bar-list
          :trackList="fullTrackList"
          :selectedTracks="flatSelectedTracks"
          @toggleEditModeFromActionBar="preToggleEditMode"
          @deleteFromActionBar="
            () => {
              openConfirmDeleteModal(this.flatSelectedTracks);
            }
          "
          @createProject="createProject"
        />
      </div>
    </div>
    <div v-else class="empty-state">
      <div class="empty-state-content">
        <img
          src="/images/emptyState-artist_page-no_album.svg"
          class="empty-state-content-image"
          alt="no album image"
        />
        <p class="empty-state-content-title">{{ $t('emptyState.title') }}</p>
        <p class="empty-state-content-text">
          {{ $t('emptyState.text') }}
        </p>
        <router-link :to="trackTabUrl" class="empty-state-content-link"
          >{{ $t('emptyState.link') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import generateTracklist from '@/utils/functions/tracklist';
import ArtistAlbum from '@/containers/artistAlbum';
import ActionBarList from '@/containers/actionBarList';
import { formatDuration, FORMAT_DURATION } from '@/utils/functions/time';
import { getFrontCoverUrl } from '@/utils/functions/image';
import { get as getRoute } from '@/router/routes';

export default {
  components: {
    ArtistAlbum,
    ActionBarList
  },
  props: {
    keepProjectDropdownExpand: {
      type: Boolean,
      required: false,
      default: false
    },
    uncheckTracks: {
      type: Boolean,
      required: false,
      default: false
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false
    },
    artistDetails: {
      type: Object,
      required: true
    },
    artistTracks: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedTracks: [],
      flatSelectedTracks: []
    };
  },
  mounted() {
    this.artistDetails.supports.forEach(() => {
      this.selectedTracks.push([]);
    });
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'isEditPanelOpened']),
    fullTrackList() {
      const trackList = [];
      this.artistDetails.supports.forEach(support => {
        trackList.push(this.getTracksByAlbum(support));
      });
      return trackList.flat();
    },
    thumbnails() {
      const artistDetailsSupport = this.artistDetails.supports.map(support => support);
      return (
        this.artistDetails &&
        this.artistDetails.supports.map((support, index) => ({
          id: support.id,
          imgSrc: getFrontCoverUrl(artistDetailsSupport[index].images, 3) || '/images/artwork.jpg',
          title: support.name.length > 20 ? `${support.name.slice(0, 20)}...` : support.name,
          subtitle: support.release_date
            ? new Date(support.release_date * 1000).getFullYear().toString()
            : ''
        }))
      );
    },
    trackTabUrl() {
      return getRoute('artistTracks', this.$route.params);
    }
  },
  methods: {
    setArtistAlbumSelectedTracks(tracks, index) {
      this.selectedTracks[index] = tracks;
      this.flatSelectedTracks = this.selectedTracks.flat();
    },
    getTracksByAlbum(support) {
      const filteredTracks = this.artistTracks.filter(
        track =>
          track.supports && track.supports.find(trackSupport => trackSupport.id === support.id)
      );
      return generateTracklist(filteredTracks, this.currentWorkspace, this.$i18n.locale);
    },
    getDurationTrack(length) {
      return formatDuration(length, FORMAT_DURATION.hmm);
    },
    handleClickThumbnail(supportId) {
      const element = document.getElementById(supportId);
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },
    createProject() {
      this.$emit('createProject');
    },
    openConfirmDeleteModal(libraryFileIds) {
      this.$emit('openConfirmDeleteModal', libraryFileIds);
    },
    preToggleEditMode() {
      if (this.$isWorkspaceLockedVerification(this)) return;
      if (this.isEditPanelOpened) {
        this.toggleEditMode([]);
      } else {
        this.toggleEditMode(this.flatSelectedTracks);
      }
    },
    toggleEditMode(libraryFileIds, isChecked) {
      this.$emit('toggleEditMode', libraryFileIds, isChecked);
    }
  }
};
</script>

<style lang="scss" scoped>
.albums {
  color: $color_neutral_100;

  &-scroller {
    margin: 24px 0 0 40px;
    padding: 0 0 24px 0;
    border-bottom: 1px solid $color_neutral_30;
  }

  &-content {
    &-album {
      display: flex;
      flex-direction: column;
      padding: 40px 0 0 0;
    }
  }
}
.empty-state {
  height: calc(100vh - 411px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-content {
    width: 270px;
    display: flex;
    align-items: center;
    flex-direction: column;

    &-image {
      width: 200px;
      height: 200px;
    }

    &-title {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 8px;
    }

    &-text {
      font-size: 14px;
      line-height: 150%;
      text-align: center;
      color: $color_neutral_60;
      margin-bottom: 33px;
    }

    &-link {
      text-align: center;
      font-weight: 600;
    }
  }
}

.action-bar-container {
  position: fixed;
  bottom: 72px;
  left: calc(50% + 90px);
  transform: translateX(-50%);
  z-index: map-get($z-index, action-bar);
  transition: left 0.15s ease;
}
.action-bar-container.panel-open {
  left: calc(50% - 169px);
}
.projectPicker {
  position: relative;
  top: -386px;
  left: -22px;
}
</style>
