
<div class="continue-editing-modal">
  <div class="continue-editing-modal-body">{{ $t('continueEditingMessage') }}</div>
  <div class="continue-editing-modal-buttons">
    <div class="continue-editing-modal-buttons--">
      <submit-button btnStyle="tertiary" :label="$t('yesLeave')" @submit="cancelChange" />
    </div>
    <div class="continue-editing-modal-buttons--">
      <submit-button :label="$t('continueEditing')" @submit="continueEditing" />
    </div>
  </div>
</div>
