
<div v-if="supportDetails && !error">
  <div class="album">
    <div :class="['album-edition', editTracksMode ? 'album-edition-open' : '']">
      <div class="album-edition-wrapper">
        <edit-tracks-panel
          v-if="editTracksMode"
          :libraryFileIds="libraryFileIds"
          :trackList="trackList"
          @renderAlbumView="renderAlbumView"
          @toggleEditMode="toggleEditMode"
        />
      </div>
    </div>
    <div
      class="album-tracks-container"
      :style="editTracksMode && 'width: calc(100% - 518px + 16px);'"
    >
      <project-list :class="{ 'track-loader-container': trackLoading }">
        <template v-slot:default="slotProps">
          <div class="album-tracks" v-if="isDataLoaded">
            <tracks
              :trackList="trackList"
              :projectList="slotProps.projectList"
              :noProjectSearchResult="slotProps.noProjectSearchResult"
              :showProjectObserver="slotProps.showProjectObserver"
              :loadingProjects="slotProps.loadingProjects"
              :fetchingMoreProjects="slotProps.fetchingMoreProjects"
              :keepProjectDropdownExpand="keepProjectDropdownExpand"
              :uncheckTracks="uncheckTracks"
              :editMode="editTracksMode"
              hasSelectAll
              withAlbumTrackNumber
              isSticky
              :stickyPosition="41"
              @openConfirmDeleteModal="openConfirmDeleteModal"
              @createProject="createProject"
              @searchProject="slotProps.searchProject"
              @fetchMoreProjects="slotProps.fetchMoreProjects"
              @listProjects="slotProps.listProjects"
              @toggleEditMode="toggleEditMode"
            />
          </div>
          <div v-if="trackLoading" class="track-loader">
            <spinner-without-progress color="grey" :size="32" />
          </div>
        </template>
      </project-list>
    </div>
  </div>
</div>
<div v-else class="loader">
  <spinner-without-progress color="grey" :size="32" />
</div>
