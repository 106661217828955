<template>
  <div class="information-banner">
    <div class="icon-info"><i class="ri-information-fill"></i></div>
    <div class="text">
      Our engineers are currently performing maintenance. It may affect the player and global
      performances. But we will be back up shortly. Thanks for your patience.
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    hide() {
      this.$emit('hideBrowserBanner', true);
    }
  }
};
</script>

<style lang="scss" scoped>
.information-banner {
  box-sizing: border-box;
  height: fit-content;
  width: 100%;
  padding: 8px 16px 8px 40px;
  background-color: $color_warning_10;
  display: flex;
  align-items: center;
  @include body-1;
  color: $color_warning_110;
  .text {
    flex-grow: 1;
    .mail {
      font-weight: 600;
    }
    .resend {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .icon {
    &-info {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      background: rgba(71, 35, 173, 0.1);
      color: $color_warning_110;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-close {
      flex-shrink: 0;
    }
  }
}
</style>
<style lang="scss">
.icon-close-browser.rounded-button.tertiary.small {
  i {
    color: $color_warning_110;
  }
  &:hover {
    background: $color_warning_10;
    border: 1px solid $color_warning_110;
  }
}
</style>
