
<div class="confirm-delete-modal">
  <div class="confirm-delete-modal-body">
    Your workspace is still running. You can upgrade back to a Pro plan anytime!
  </div>
  <div class="confirm-delete-modal-buttons">
    <div class="confirm-delete-modal-buttons--">
      <submit-button btnStyle="primary" :label="$t('okGotIt')" @submit="closeModal" />
    </div>
  </div>
</div>
