<template>
  <div class="project-sharings">
    <Sharingsview
      class="view"
      entityType="project"
      :entityIds="entityIds"
      :entityName="entityName"
    />
  </div>
</template>

<script>
import Sharingsview from '../../../../../containers/shareViews/sharings';

export default {
  components: {
    Sharingsview
  },
  props: {
    entityIds: {
      type: Array,
      required: true
    },
    entityName: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.project-sharings {
  margin-top: 24px;
  .view {
    height: 100%;
  }
}
</style>
