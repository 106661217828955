
<div
  :class="[btnStyle, size, 'cancelable-btn']"
  v-if="cancelable && pending"
  @mouseenter="setIsHover(true)"
  @mouseleave="setIsHover(false)"
  @click="$emit('cancel')"
>
  <div class="cancelable-icon">
    <i v-if="isHover" class="ri-close-line"></i>
    <i v-else class="btn-icon ri-loader-4-line loader" />
  </div>
</div>
<button :class="btnClass" type="button" :disabled="disabled" @click="handleClick" v-else>
  <div :class="iconPosition">
    <img v-if="iconA2mb" src="/images/bridge-add.svg" style="width: 16px; margin-right: 6px" />
    <i v-if="iconClass && !pending && !iconA2mb" :class="`btn-icon ${iconClass}`" />
    <i v-if="pending" class="btn-icon ri-loader-4-line loader" />
    <span>{{ label }}</span>
    <i
      v-if="isDropdown"
      :class="{
        'btn-arrow': true,
        'ri-arrow-drop-up-fill': isExpanded,
        'ri-arrow-drop-down-fill': !isExpanded
      }"
    />
  </div>
</button>
