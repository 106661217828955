<template>
  <div>
    <div v-if="blob" class="cropper">
      <image-cropper
        :blob="blob"
        :submitButtonLabel="$t('save')"
        :cancelButtonLabel="$t('cancel')"
        :imageWidth="cropperMaxWidth"
        :imageHeight="cropperMaxHeight"
        :isSquare="true"
        @cropImage="cropImage"
      />
    </div>
    <div v-if="!blob">
      <div class="support-modal-body">
        <div class="support-modal-body-section">
          <div class="support-modal-body-section-label">{{ $t('artwork') }}</div>
          <image-browser
            :imgUrl="imgUrl"
            pictureSize="large"
            square
            :removeButton="displayDeleteArtwork"
            @changeImgUrl="handleAlbumImageChange"
            @removeImage="handleAlbumImageRemove"
          />
        </div>
        <div class="support-modal-body-section">
          <text-field
            inputType="text"
            :errorMessage="albumNameErrorMessage"
            :fieldModel.sync="name"
            :value="name"
            :label="$t('name')"
            :inputWidth="357"
            horizontalDisplaying
            large
          />
        </div>
        <div class="support-modal-body-dropdown">
          <div class="support-modal-body-dropdown-label">{{ $t('type') }}</div>
          <div class="support-modal-body-dropdown--">
            <select-box
              :options="getSupportOptions"
              large
              :selectedValue="{ value: type, label: type }"
              @setSelectBoxValue="changeSupportType"
            />
          </div>
        </div>
        <div class="support-modal-body-dropdown">
          <div class="support-modal-body-dropdown-label">{{ $t('artist') }}</div>
          <div class="support-modal-body-dropdown--">
            <auto-complete-list
              :queries="autoCompleteQueries"
              :dataLoaded="isArtistsDataLoaded"
              dataType="ArtistsAutocomplete"
              storeEvent="changeIsArtistsLoaded"
            >
              <template v-slot:default="slotProps">
                <input-dropdown
                  key="artist-dropdown"
                  id="artist-dropdown"
                  :defaultList="slotProps.items"
                  :defaultOptions="defaultArtists"
                  isMultipleSelection
                  :loadingOptions="slotProps.isLoading"
                  :showObserver="slotProps.showObserver"
                  :fetchingMoreOptions="slotProps.fetchingMoreItems"
                  :width="390"
                  :errorMessage="artistErrorMessage"
                  @listOptions="slotProps.fetch"
                  @fetchMoreOptions="slotProps.fetchMoreItems"
                  @searchOption="slotProps.search"
                  @handleResult="selectArtist"
                  @handleBlur="selectArtist"
                />
              </template>
            </auto-complete-list>
          </div>
        </div>
        <div class="support-modal-body-section support-modal-body-section-horizontal">
          <label class="support-modal-body-section-label">{{ $t('releaseDate') }}</label>
          <date-picker
            large
            @datePicked="setRecordingDate"
            :selectedDate="releaseDateTimeStamp * 1000"
            :class="{
              'support-modal-body-section-datePicker': true,
              toTop: shouldDisplayDatePickerUpward
            }"
          />
        </div>
        <div class="support-modal-body-section">
          <text-field
            inputType="text"
            :fieldModel.sync="upc"
            :value="upc"
            label="UPC"
            :inputWidth="357"
            horizontalDisplaying
            :errorMessage="upcErrorMessage"
            large
          />
        </div>
        <div class="support-modal-body-section-horizontal">
          <div class="support-modal-body-section-label">{{ $t('totalTracks') }}</div>
          <div>
            <text-field
              inputType="number"
              :errorMessage="trackTotalErrorMessage"
              errorMessageException
              :fieldModel.sync="trackTotal"
              :value="trackTotal"
              :inputWidth="46"
              horizontalDisplaying
            />
          </div>
        </div>
      </div>
      <div class="support-modal-footer">
        <div class="support-modal-footer-btn">
          <submit-button btnStyle="tertiary" :label="$t('cancel')" @submit="closeModal" />
        </div>
        <submit-button
          :label="isCreate ? $t('create') : $t('smallSaveChanges')"
          :disabled="disabled"
          :pending="supportChangesIsPending"
          @submit="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
import isEqual from 'lodash.isequal';
import get from 'lodash.get';

import { appConfig, PAGINATOR_LIMIT } from '@/utils/constants';
import {
  LIST_ARTISTS,
  ARTISTS_AUTOCOMPLETE,
  LIBRARY_FILES_DETAILS
} from '@/graphql/queries/library';
import { DICTIONARIES } from '@/graphql/queries/global';
import { handleSupportImageUpload } from '@/utils/functions/upload';
import {
  ARTIST_CREATE,
  SUPPORT_DELETE_FRONT_COVER,
  SUPPORT_UPDATE
} from '@/graphql/mutations/library';
import AutoCompleteList from '@/containers/autoCompleteList';
import { bus } from '@/utils/bus';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import { trackEvent } from '@/utils/functions/analytics';

export default {
  components: {
    AutoCompleteList
  },
  props: {
    onSubmit: {
      type: Function,
      required: false,
      default: () => {}
    },
    isCreate: {
      type: Boolean,
      required: false,
      default: false
    },
    albumImgUrl: {
      type: String,
      required: false,
      default: '/images/artwork.jpg'
    },
    supportDetails: {
      type: Object,
      required: false,
      default: () => ({
        supportId: null,
        supportName: null,
        albumType: null,
        albumRelease: null,
        albumUpc: null,
        albumTotalTracks: null
      })
    },
    defaultArtists: {
      type: Array,
      required: false,
      default: () => []
    },
    libraryFilesIds: {
      type: Array,
      required: false,
      default: null
    }
  },
  data() {
    return {
      imgUrl: this.albumImgUrl,
      imgUrlMemory: this.albumImgUrl,
      file: null,
      blob: null,
      cropperMaxWidth: appConfig.upload.support.width,
      cropperMaxHeight: appConfig.upload.support.height,
      name: this.supportDetails.supportName,
      mainArtistId: null,
      artistsId: null,
      newArtists: [],
      type: this.supportDetails.albumType,
      releaseDate: null,
      releaseDateTimeStamp: this.supportDetails.albumRelease,
      upc: this.supportDetails.albumUpc,
      trackTotal: this.supportDetails.albumTotalTracks,
      imageIsRemoved: false,
      defaultArtistMemory: null,
      supportChangesIsPending: false
    };
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(255)
    },
    upc: {
      maxLength: maxLength(255)
    },
    trackTotal: {
      maxLength: maxLength(5)
    }
  },
  apollo: {
    Dictionaries: {
      query: DICTIONARIES
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'isArtistsLoaded']),
    getSupportOptions() {
      const types = get(this.Dictionaries, 'workspace.library.support_types', []);
      return types.map(item => ({
        value: item,
        label: item
      }));
    },
    shouldDisplayDatePickerUpward() {
      return document.documentElement.clientHeight < 780;
    },
    albumNameErrorMessage() {
      if (!this.$v.name.required) {
        const label = this.$t('albumName');
        return this.$t('required', { label: label[0].toUpperCase() + label.substring(1) });
      }
      if (!this.$v.name.maxLength) {
        return this.$t('maxLength', {
          nbChars: this.$v.name.$params.maxLength.max
        });
      }
      return null;
    },
    upcErrorMessage() {
      if (!this.$v.upc.maxLength) {
        return this.$t('maxLength', {
          nbChars: this.$v.upc.$params.maxLength.max
        });
      }
      return null;
    },
    trackTotalErrorMessage() {
      if (!this.$v.trackTotal.maxLength) {
        return this.$t('maxLength', {
          nbChars: this.$v.trackTotal.$params.maxLength.max
        });
      }
      return null;
    },
    isArtistsDataLoaded() {
      return this.isArtistsLoaded;
    },
    artistErrorMessage() {
      if (this.mainArtistId === '') {
        const label = this.$t('artistName');
        return this.$t('required', { label: label[0].toUpperCase() + label.substring(1) });
      }
      return null;
    },
    hasError() {
      return (
        this.albumNameErrorMessage !== null ||
        this.upcErrorMessage !== null ||
        this.trackTotalErrorMessage !== null
      );
    },
    disabled() {
      const artistError = this.mainArtistId === null || this.mainArtistId === '';
      if (this.isCreate) {
        return this.hasError || artistError;
      }
      return this.hasError || artistError || !this.hasChange;
    },
    hasChange() {
      const hasChange =
        this.file !== null ||
        this.name !== this.supportDetails.supportName ||
        !isEqual(this.artistsId, this.defaultArtistMemory) ||
        this.type !== this.supportDetails.albumType ||
        this.releaseDateTimeStamp !== this.supportDetails.albumRelease ||
        this.upc !== this.supportDetails.albumUpc ||
        // eslint-disable-next-line eqeqeq
        (this.trackTotal != this.supportDetails.albumTotalTracks &&
          !(this.trackTotal === '' && this.supportDetails.albumTotalTracks === null)) ||
        this.imageIsRemoved === true;
      return hasChange;
    },
    autoCompleteQueries() {
      return {
        items: LIST_ARTISTS,
        itemKey: 'listArtists',
        search: ARTISTS_AUTOCOMPLETE,
        searchKey: 'ArtistsAutocomplete',
        itemVariables: {
          libraryId: this.currentWorkspace.libraries[0].id,
          page: 1,
          limit: this.limit,
          orderBy: 'name',
          sortBy: 'asc'
        }
      };
    },
    displayDeleteArtwork() {
      return this.imgUrl !== '/images/artwork.jpg';
    },
    DeleteCover() {
      return this.imageIsRemoved && this.imgUrlMemory !== '/images/artwork.jpg';
    }
  },
  beforeMount() {
    window.addEventListener('keyup', this.handleEnter);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.handleEnter);
  },
  methods: {
    handleEnter(e) {
      if (e.key === 'Enter') {
        this.handleSubmit();
      }
    },
    closeModal() {
      this.$emit('closeModal');
    },
    handleAlbumImageChange(blob) {
      this.blob = blob;
      this.imgUrl = null;
    },
    cropImage(blob, url) {
      this.blob = null;
      this.imgUrl = url || '/images/artwork.jpg';
      this.file = blob || null;
      this.imageIsRemoved = false;
    },
    handleAlbumImageRemove() {
      this.imgUrl = '/images/artwork.jpg';
      this.file = null;
      this.imageIsRemoved = true;
    },
    changeSupportType(type) {
      this.type = type;
    },
    selectArtist(selectedOption) {
      this.newArtists = selectedOption.filter(option => option.id.includes('random'));
      if (selectedOption.length > 0) {
        if (!this.mainArtistId) {
          this.artistsId = selectedOption.map(option => option.id);
        }
        this.mainArtistId = selectedOption[0].id;
        this.artistsId = selectedOption.map(option => option.id);
      } else {
        this.mainArtistId = '';
        this.artistsId = '';
      }
    },
    setRecordingDate(value) {
      if (value === null) {
        this.releaseDateTimeStamp = undefined;
        this.releaseDate = undefined;
      } else {
        this.releaseDateTimeStamp = (value / 1000).toString();
        const d = new Date(value);
        this.releaseDate = `${d.getUTCFullYear()}/${d.getUTCMonth() + 1}/${d.getUTCDate()}`;
      }
    },
    handleSubmit() {
      if (this.newArtists && this.newArtists.length > 0) {
        const promiseArray = [];
        this.newArtists.forEach(artist => {
          const promise = this.$apollo
            .mutate({
              mutation: ARTIST_CREATE,
              variables: {
                workspaceId: this.currentWorkspace.id,
                name: artist.value
              },
              refetchQueries: [
                {
                  query: LIST_ARTISTS,
                  variables: {
                    libraryId: this.currentWorkspace.libraries[0].id,
                    page: 1,
                    limit: PAGINATOR_LIMIT,
                    orderBy: 'name',
                    sortBy: 'asc'
                  }
                }
              ]
            })
            .then(response => {
              this.artistsId.push(response.data.ArtistCreate.id);
            });
          promiseArray.push(promise);
        });
        Promise.all(promiseArray)
          .then(() => {
            this.newArtists = null;
            this.artistsId = this.artistsId.filter(id => !id.includes('random'));
            // eslint-disable-next-line prefer-destructuring
            this.mainArtistId = this.artistsId[0];
            this.setChanges();
          })
          .catch(error => {
            const key = getGraphQlErrorCode(error);
            bus.$emit('showAlert', {
              message: { key },
              style: 'danger',
              delay: 5000,
              error: true
            });
          });
        return;
      }
      this.setChanges();
    },
    setChanges() {
      if (this.isCreate) {
        const input = {
          variables: {
            libraryId: this.currentWorkspace.libraries[0].id,
            workspaceId: this.currentWorkspace.id,
            name: this.name,
            mainArtistId: this.mainArtistId,
            artists: this.artistsId,
            type: this.type,
            upc: this.upc,
            releaseDate: this.releaseDate,
            tracksTotal: parseInt(this.trackTotal, 10)
          },
          file: this.file
        };
        this.onSubmit(input);
        return;
      }
      const changes = {
        file: this.file,
        imageIsRemoved: this.imageIsRemoved,
        supportUpdate: []
      };
      if (this.name !== this.albumName)
        changes.supportUpdate.push({ key: 'name', value: this.name });
      if (this.releaseDate !== this.supportDetails.albumRelease) {
        changes.supportUpdate.push({
          key: 'releaseDate',
          value: this.releaseDateTimeStamp
        });
      }
      if (this.type !== this.supportDetails.albumType)
        changes.supportUpdate.push({ key: 'type', value: this.type });
      if (this.upc !== this.supportDetails.albumUpc)
        changes.supportUpdate.push({ key: 'upc', value: this.upc });
      if (this.artistsId !== this.defaultArtistMemory)
        changes.supportUpdate.push({
          key: 'artistsIds',
          value: this.artistsId
        });
      if (this.trackTotal !== this.supportDetails.albumTotalTracks) {
        changes.supportUpdate.push({
          key: 'tracksTotal',
          value: parseInt(this.trackTotal, 10)
        });
      }
      if (changes.supportUpdate.length > 0 || this.file !== null || this.imageIsRemoved) {
        this.saveSupportChanges(changes);
      }
    },
    saveSupportChanges(params) {
      this.supportChangesIsPending = true;
      const promiseArray = [];
      if (params.file) {
        const file = new File([params.file], 'newAlbumCover', { type: 'image/jpeg' });
        const promise = handleSupportImageUpload(
          this,
          file,
          this.supportDetails.supportId,
          this.libraryFilesIds
        );
        promiseArray.push(promise);
      }
      if (params.imageIsRemoved) {
        const refetchQueries = this.libraryFilesIds
          ? [
              {
                query: LIBRARY_FILES_DETAILS,
                variables: {
                  libraryFilesIds: this.libraryFilesIds
                }
              }
            ]
          : null;
        const promise = this.$apollo.mutate({
          mutation: SUPPORT_DELETE_FRONT_COVER,
          variables: {
            supportId: this.supportDetails.supportId
          },
          refetchQueries
        });
        promiseArray.push(promise);
      }
      if (params.supportUpdate.length > 0) {
        const variables = {
          libraryId: this.currentWorkspace.libraries[0].id,
          workspaceId: this.currentWorkspace.id,
          supportId: this.supportDetails.supportId
        };
        params.supportUpdate.forEach(param => {
          variables[param.key] = param.value;
        });
        const promise = this.$apollo.mutate({
          mutation: SUPPORT_UPDATE,
          variables
        });
        promiseArray.push(promise);
      }
      Promise.all(promiseArray)
        .then(() => {
          this.onSubmit();
          this.supportChangesIsPending = false;
          this.closeModal();
          trackEvent(
            'Album Edited',
            {
              category: 'library editing',
              label: 'confirmed',
              workspace_id: this.currentWorkspace.id,
              workspace_name: this.currentWorkspace.name
            },
            {
              All: false,
              'Google Analytics': true,
              FullStory: true,
              Hubspot: true,
              Appcues: true,
              Amplitude: true,
              June: true
            }
          );
          bus.$emit('showAlert', {
            message: { key: 'modificationSuccess' },
            style: 'success',
            delay: 5000,
            error: false
          });
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    }
  },
  created() {
    if (this.defaultArtists.length) {
      this.defaultArtistMemory = this.defaultArtists.map(option => option.id);
      this.mainArtistId = this.defaultArtists[0].id;
      this.artistsId = this.defaultArtists.map(option => option.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.support-modal {
  &-body {
    padding: 24px;
    height: fit-content;

    &-section {
      display: flex;
      margin: 0 0 8px 0;

      &-label {
        @include body-1;
        color: $color_neutral_60;
        width: 140px;
      }

      &-horizontal {
        display: flex;
        align-items: center;
      }

      &-datePicker {
        width: 390px;
      }
    }

    &-dropdown {
      display: flex;
      margin: 0 0 8px 0;

      &-label {
        @include body-1;
        width: 140px;
        color: $color_neutral_60;
        margin: 14px 0 0 0;
      }

      &-- {
        width: 390px;
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    padding: 8px 24px;
    border-top: 1px solid $color_neutral_30;

    &-btn {
      margin: 0px 16px;
    }
  }
}
.cropper {
  padding: 16px 0;
}
</style>

<style lang="scss">
/* This is used when the height is not at least 780px, we display the date-picker on the top */
.support-modal-body-section-datePicker.toTop .vdp-datepicker__calendar {
  bottom: 50px;
}
</style>
