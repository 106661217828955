<template>
  <div class="container">
    <div class="wrapper">
      <tag-manager :displayHeader="false" />
    </div>
    <div class="buttons">
      <submit-button btnStyle="primary" size="medium" :label="$t('close')" @submit="closeModal" />
    </div>
  </div>
</template>

<script>
import TagManager from '@/containers/tagManager';

export default {
  components: {
    TagManager
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  margin: 20px 32px;
}

.wrapper {
  max-height: 500px;
  overflow-y: scroll;
}

.buttons {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
