<template>
  <infinite-scroll
    :listQuery="listTracksQuery"
    @handleQueryResult="handleQueryResult"
    @setLoading="setLoading"
  >
    <template v-slot:list>
      <tracks
        :trackList="trackList"
        :isFetchingMore="loading"
        :uncheckTracks="uncheckTracks"
        hasSelectAll
        @setSelectedTracks="setSelectedTracks"
      />
    </template>
  </infinite-scroll>
</template>

<script>
import { mapGetters } from 'vuex';
import { PAGINATOR_LIMIT } from '@/utils/constants';
import { LIST_TRACKS_LIGHT } from '@/graphql/queries/library';
import generateTracklist from '@/utils/functions/tracklist';
import InfiniteScroll from '@/containers/infiniteScroll';
import Tracks from '@/containers/browserTrackList';

export default {
  components: {
    InfiniteScroll,
    Tracks
  },
  props: {
    uncheckTracks: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      trackList: [],
      loading: false
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    listTracksQuery() {
      return {
        query: LIST_TRACKS_LIGHT,
        key: 'listTracks',
        variables: {
          libraryId: this.currentWorkspace.libraries[0].id,
          page: 1,
          limit: PAGINATOR_LIMIT,
          orderBy: 'created',
          sortBy: 'desc'
        },
        pagination: 'page'
      };
    }
  },
  methods: {
    handleQueryResult(response) {
      this.trackList =
        response && response.data && response.data.listTracks && response.data.listTracks.data
          ? generateTracklist(
              response.data.listTracks.data,
              this.currentWorkspace,
              this.$i18n.locale,
              false
            )
          : [];
    },
    setLoading(loading) {
      this.loading = loading;
    },
    setSelectedTracks(selectedTracks) {
      this.$emit('setSelectedTracks', selectedTracks);
    }
  }
};
</script>
