
<div class="text-dropdown" v-click-outside="{ hide, keepExpand }">
  <div class="text-dropdown-label" @click="toggleExpand">
    <div class="text-dropdown-label--">
      <span>{{ selectedLabel }}</span>
    </div>
    <i class="ri-arrow-drop-down-line" />
  </div>
  <div class="text-dropdown-expand" @click="hide" v-if="isExpanded">
    <div class="text-dropdown-expand-options">
      <div
        v-for="(option, index) in options"
        class="text-dropdown-expand-options-item"
        :key="`item-${index}`"
        @click="selectOption(option.value, option.label)"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</div>
