
<div class="create-workspace-container">
  <div class="create-workspace">
    <div class="create-workspace-logo">
      <img src="/images/Logo-Full-Color.svg" />
    </div>
    <div class="create-workspace-step">1/2</div>
    <div class="create-workspace-title">{{ title }}</div>
    <div class="create-workspace-helper">{{ $t('workspaceDefinition') }}</div>
    <form @submit.prevent="submit" class="create-workspace-form">
      <div class="create-workspace-form-textfield">
        <text-field
          inputType="text"
          :fieldModel.sync="$v.workspaceName.$model"
          :label="$t('workspaceName')"
          :placeholder="$t('workspaceNamePlaceholder')"
          :hint="$t('hintWorkspaceName')"
          large
          :errorMessage="workspaceNameValidationMessage"
        />
      </div>
      <div class="create-workspace-form-picture">
        <div class="create-workspace-form-picture-title">{{ $t('workspacePictureTitle') }}</div>
        <div class="create-workspace-form-picture-definition">
          {{ $t('workspacePictureDefinition') }}
        </div>
        <div class="create-workspace-form-picture-browser">
          <image-browser
            :imgUrl="imgUrl"
            square
            @changeImgUrl="changeImgUrl"
            icon="ri-image-fill"
            pictureSize="small"
            label="Add a logo"
          />
        </div>
      </div>
      <select-box
        v-if="activities"
        :options="activityOptions"
        :label="$t('mainActivity')"
        :placeholder="$t('activityTitlePlaceholder')"
        :errorMessage="activityValidationMessage"
        large
        @setSelectBoxValue="setSelectBoxValue"
      />
      <div class="create-workspace-form-button">
        <submit-button
          :label="$t('continue')"
          size="large"
          :pending="submitStatus === 'PENDING'"
          :disabled="disabledStatus"
          @submit="submit"
          class="button"
        />
      </div>
    </form>
  </div>
  <div v-if="!isMobile" class="create-workspace-container-right">
    <workspace-previewer :workspaceName="workspaceName" :workspaceLogoUrl="imgBase64" />
  </div>
</div>
