
<div class="delete-projects-modal">
  <div class="delete-projects-modal-body">
    <div class="delete-projects-modal-body-description">
      {{ $t('deleteProjectDesc', { name: projectName }) }}
    </div>
    <text-field
      inputType="text"
      :fieldModel.sync="typeDelete"
      :label="$t('typeDelete')"
      focusOnLoad
      @keyup.enter.native="handleKeyUp"
    />
  </div>
  <div class="delete-projects-modal-buttons">
    <div class="delete-projects-modal-buttons--">
      <submit-button
        btnStyle="tertiary"
        size="medium"
        :label="$t('cancel')"
        @submit="closeModal"
      />
    </div>
    <div class="delete-projects-modal-buttons--">
      <submit-button
        btnStyle="secondary"
        size="medium"
        :label="$t('delete')"
        :disabled="disabledDeleteButton"
        danger
        @submit="submit"
      />
    </div>
  </div>
</div>
