<template>
  <div class="tabs" :class="{ secondary: isSecondary }">
    <div
      class="tabs-tab"
      :class="{ active: noRoute ? noRouteCurrentTab === i : currentTab === i }"
      v-for="(tab, i) in tabs"
      :key="`tab-${i}`"
      :ref="`tab${i}`"
    >
      <router-link
        v-if="!noRoute"
        class="tabs-tab-link"
        :to="tab.link"
        @click.native="setCurrentTab(i)"
      >
        <div class="tabs-tab-link-container">
          <i v-if="tab.icon" :class="tab.icon" class="tabs-tab-link-container-icon"></i>
          {{ tab.title }}
          <div v-if="haveCount && tab.count" class="tabs-tab-link-container-count">
            {{ formatCount(tab.count) }}
          </div>
        </div>
      </router-link>

      <div
        v-if="noRoute"
        class="tabs-tab-link"
        :class="{ active: i === noRouteCurrentTab }"
        @click="changeTab(tab.title, i)"
      >
        <div class="tabs-tab-link-container">
          <i v-if="tab.icon" :class="tab.icon" class="tabs-tab-link-container-icon"></i>
          {{ tab.title }}
          <div v-if="haveCount && tab.count" class="tabs-tab-link-container-count">
            {{ formatCount(tab.count) }}
          </div>
        </div>
      </div>

      <div v-if="tab.notification" class="tabs-tab-link-notification"></div>
    </div>
    <div
      v-if="!isSecondary"
      class="tabs-indicator"
      :style="[initialIndicatorPos, indicatorPos]"
    ></div>
  </div>
</template>

<script>
import { formatToNavigatorLanguage } from '@/utils/functions/global';

export default {
  props: {
    tabs: {
      type: Array,
      required: true
    },
    activeTab: {
      type: Number,
      required: false,
      default: 0
    },
    haveCount: {
      type: Boolean,
      required: false,
      default: false
    },
    noRouteCurrentTab: {
      type: Number,
      required: false,
      default: -1
    },
    isSecondary: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      currentTab: this.activeTab,
      initialIndicatorPos: null
    };
  },
  watch: {
    activeTab() {
      this.currentTab = this.activeTab;
    }
  },
  methods: {
    setCurrentTab(i) {
      this.currentTab = i;
    },
    changeTab(title, i) {
      this.setCurrentTab(i);
      this.$emit('changeTab', title, i);
    },
    setIndicatorPos() {
      const currentTab = this.noRoute ? this.noRouteCurrentTab : this.currentTab;
      if (this.$refs[`tab${currentTab}`]) {
        const linkOffset = this.$refs[`tab${currentTab}`][0].offsetLeft;
        const linkWidth = this.$refs[`tab${currentTab}`][0].clientWidth;
        const newPosition = {
          left: `${linkOffset}px`,
          width: `${linkWidth}px`
        };
        return newPosition;
      }
      return null;
    },
    formatCount(count) {
      if (count > 9999 && count < 999999) {
        return `${Math.floor(count / 1000)}k`;
      }
      if (count > 999999 && count < 999999999) {
        return `${Math.floor(count / 1000000)}m`;
      }
      if (count > 999999999) {
        return `${Math.floor(count / 1000000000)}b`;
      }
      return formatToNavigatorLanguage(count);
    }
  },
  computed: {
    indicatorPos() {
      return this.setIndicatorPos();
    },
    noRoute() {
      return this.noRouteCurrentTab >= 0;
    }
  },
  mounted() {
    this.initialIndicatorPos = this.setIndicatorPos();
  }
};
</script>

<style lang="scss" scoped>
.tabs {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid $color_neutral_30;

  &-tab {
    position: relative;
    .active {
      color: $color_primary_100;
      .tabs-tab-link-container-count {
        color: $color_neutral_0;
        background-color: $color_primary_100;
      }
    }

    &-link {
      cursor: pointer;
      display: block;
      height: 40px;
      width: fit-content;
      width: -moz-fit-content;
      padding: 0 10px;
      line-height: 40px;
      color: $color_neutral_60;
      text-align: center;
      box-sizing: border-box;
      &-container {
        display: flex;
        align-items: center;
        &-count {
          width: 30px;
          height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $color_neutral_30;
          border-radius: 4px;
          color: $color_neutral_60;
          margin-left: 8px;
          font-size: 10px;
        }
        &-icon {
          margin-right: 8px;
        }
      }
      &:hover:not(.active) {
        color: $color_neutral_60;
      }
      &-notification {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        position: absolute;
        background-color: $color_primary_100;
        top: 12px;
        right: 4px;
      }
    }
    &:hover {
      background: $color_primary_10;
      .tabs-tab-link {
        color: $color_primary_100;
      }
      .tabs-tab-link-container-count {
        color: $color_neutral_0;
        background-color: $color_primary_100;
      }
    }
  }

  &-indicator {
    height: 1px;
    background: $color_primary_100;
    border-radius: 30px;
    position: absolute;
    bottom: -1px;
    transition: 0.3s all ease;
  }

  a {
    text-decoration: none;
  }
}
.tabs.secondary {
  border-bottom: none;
  background-color: $color_neutral_0;
  .tabs-tab {
    border: 1px solid $color_neutral_40;
    border-right: none;
    &:hover {
      background: $color_neutral_10;
      .tabs-tab-link {
        color: $color_neutral_60;
      }
      .tabs-tab-link-container-count {
        background-color: $color_neutral_30;
        color: $color_neutral_60;
      }
    }
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: 1px solid $color_neutral_40;
    }
  }
  .tabs-tab.active {
    border-color: $color_primary_100;
    background-color: $color_primary_10;
    &:hover {
      background: $color_primary_10;
      .tabs-tab-link {
        color: $color_primary_100;
      }
      .tabs-tab-link-container-count {
        color: $color_neutral_0;
        background-color: $color_primary_100;
      }
    }
  }
  .tabs-tab.active + .tabs-tab {
    border-left-color: $color_primary_100;
  }
}
</style>
