<template>
  <div class="thumbnail">
    <img :class="classImg" v-if="thumbnailImg && !multiple" :src="thumbnailImg" alt="thumbnail" />
    <div class="multiple" v-else-if="thumbnailImg && multiple">
      <div class="multiple-bbg" :style="{ backgroundColor: this.rgb }" />
      <div class="multiple-bg" :style="{ backgroundColor: this.rgb }" />
      <img class="multiple-img" :src="thumbnailImg" alt="thumbnail" />
    </div>
    <div class="icon" :class="iconInfo.containerClass" v-else>
      <i :class="iconInfo.iconClass" />
    </div>
  </div>
</template>

<script>
import { getAverageRGB } from '@/utils/functions/global';

export default {
  props: {
    url: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: true
    },
    placeHolders: {
      type: Object,
      required: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      rgb: 'rgb(231,231,233)'
    };
  },
  created() {
    const { url } = this;
    if (url) {
      const img = new Image();
      let rgb;
      img.crossOrigin = 'Anonymous';
      img.src = url;
      img.addEventListener('load', () => {
        rgb = getAverageRGB(img);
        this.rgb = `rgb(${rgb.r},${rgb.g},${rgb.g})`;
      });
    }
  },
  computed: {
    thumbnailImg() {
      const { url, type, placeHolders } = this;
      if (url) {
        return url;
      }
      let img = null;
      switch (type) {
        case 'support':
        case 'track':
          img = placeHolders.support;
          break;
        case 'artist':
          img = placeHolders.artist;
          break;
        default:
          img = null;
          break;
      }
      return img;
    },
    classImg() {
      let str = 'img';
      if (this.type === 'artist') {
        str += ' artist';
      }
      return str;
    },
    iconInfo() {
      const infos = {
        iconClass: 'ri-folder-fill',
        containerClass: ''
      };
      switch (this.type) {
        case 'submission':
          infos.iconClass = 'ri-send-plane-fill';
          infos.containerClass = 'submission';
          break;
        default:
          break;
      }
      return infos;
    }
  }
};
</script>

<style lang="scss" scoped>
.thumbnail {
  width: 32px;
  height: 32px;
  .img,
  .icon,
  .multiple {
    width: 100%;
    height: 100%;
  }
  .artist {
    border-radius: 50%;
  }
  .icon {
    border-radius: 8px;
    background-color: $color_secondary_10;
    color: $color_secondary_100;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon.submission {
    background-color: $color_purple_10;
    color: $color_purple_100;
  }
  .multiple {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    &-img {
      width: 28px;
      height: 28px;
      z-index: 0;
    }
    &-bg {
      position: absolute;
      width: 24px;
      height: 24px;
      right: 2px;
      opacity: 0.3;
    }
    &-bbg {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 0;
      opacity: 0.15;
    }
  }
}
</style>
