<template>
  <div :key="renderKey">
    <div v-if="label" class="title">{{ label }}</div>
    <div class="selectbox" v-click-outside="{ hide: closeExpand }">
      <div :class="selectClass" @click="openExpand">
        <input class="input" type="text" v-model="query" :placeholder="placeholder" />
        <i class="ri-arrow-drop-down-line" />
      </div>
      <div
        class="selectbox-expanded"
        :style="`height: ${height}; top: 42px; opacity: ${isExpanded ? 1 : 0}`"
      >
        <div v-if="filterQuery.length">
          <div
            class="selectbox-expanded-option"
            v-for="(option, index) in filterQuery"
            :key="`d-option-${index}`"
          >
            <div class="selectbox-expanded-option-label">
              <expandable
                :title="option.name"
                :subtitle="getCountCategory(option) > 0 ? `(${getCountCategory(option)})` : null"
                :dotColor="option.background_color"
                :level="1"
                v-if="option.families.length > 0"
              >
                <div v-for="(family, familyIndex) in option.families" v-bind:key="familyIndex">
                  <expandable
                    :title="family.name"
                    :subtitle="
                      getCountFamily(family, option.id) > 0
                        ? `(${getCountFamily(family, option.id)})`
                        : null
                    "
                    :level="2"
                  >
                    <checkbox
                      class="selectbox-expanded-option-label-checkbox"
                      v-for="(tag, tagIndex) in family.tags"
                      :index="index"
                      :label="tag.name"
                      :id="`${option.id}-${family.id}-${tag.id}`"
                      :selectedItems="checkedOptions"
                      large
                      :key="`${tag.name}-${tagIndex}`"
                      @toggleCheckbox="toggleTag"
                    />
                  </expandable>
                </div>
              </expandable>
              <p class="selectbox-expanded-option-label-empty" v-else>{{ option.name }}</p>
            </div>
          </div>
        </div>
        <div v-else class="no-result">
          <div class="no-result--">
            {{ noResultLabel }} "<span>{{ query }}</span
            >"
          </div>
        </div>
      </div>
      <div v-if="errorMessage && showError" class="selectbox-error">{{ errorMessage }}</div>
    </div>
  </div>
</template>

<script>
import Expandable from '@/components/expandable';
import Checkbox from '@/components/checkbox';
import { clickOutside } from '@/utils/directives';

export default {
  components: {
    Checkbox,
    Expandable
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    checkedOptions: {
      type: Array,
      required: false,
      default: () => []
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Search a tags'
    },
    errorMessage: {
      type: String,
      default: null,
      required: false
    },
    noResultLabel: {
      type: String,
      required: false,
      default: 'No result found for'
    }
  },
  data() {
    return {
      query: this.value || '',
      isExpanded: false,
      showError: false,
      renderKey: 0
    };
  },
  computed: {
    filterQuery() {
      // Deep clone of options
      const options = JSON.parse(JSON.stringify(this.options));
      const { query } = this;
      const regexp = new RegExp(query, 'i'); // For case insensitive search
      if (query) {
        const filteredOptions = options.filter(item => {
          // Root tag
          if (regexp.test(item.name)) {
            return true;
          }
          const filteredFamilies = item.families.filter(family => {
            if (regexp.test(family.name)) {
              return true;
            }
            const filteredTags = family.tags.filter(tag => regexp.test(tag.name));
            if (filteredTags.length > 0) {
              family.tags = filteredTags; // eslint-disable-line
              return true;
            }
            return false;
          });
          if (filteredFamilies.length > 0) {
            item.families = filteredFamilies; // eslint-disable-line
            return true;
          }
          return false;
        });
        return filteredOptions;
      }
      return this.options;
    },
    height() {
      return this.isExpanded ? 'fit-content' : '0px';
    },
    selectClass() {
      const selectOption = ['selectbox-selected'];
      if (this.errorMessage && this.showError) {
        selectOption.push('selectbox-group-error');
      }
      if (this.isExpanded) {
        selectOption.push('selectbox-focus');
      }
      return selectOption;
    }
  },
  directives: {
    clickOutside
  },
  methods: {
    openExpand() {
      this.isExpanded = true;
    },
    closeExpand() {
      this.isExpanded = false;
      this.renderKey = Math.floor(Math.random() * 100000);
    },
    toggleExapnd() {
      this.isExpanded = !this.isExpanded;
    },
    toggleTag(value, id) {
      if (!value) {
        const index = this.checkedOptions.indexOf(id);
        this.checkedOptions.splice(index, 1); /*eslint-disable-line */
        this.$emit('selectTags', id, false);
      } else {
        this.checkedOptions.push(id); /*eslint-disable-line */
        this.$emit('selectTags', id, true);
      }
    },
    getCountCategory(category) {
      let count = 0;
      category.families.forEach(family => {
        count += this.getCountFamily(family, category.id);
      });
      return count;
    },
    getCountFamily(family, categoryId) {
      return family.tags.filter(tag =>
        this.checkedOptions.includes(`${categoryId}-${family.id}-${tag.id}`)
      ).length;
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  margin: 0 0 4px 0;
  text-align: left;
  @include label;
}

.selectbox {
  position: relative;
  width: 100%;
  max-width: 458px;
  background-color: $color_neutral_0;
  border-radius: 4px;
  @include body-1;
  height: 40px;

  &-selected {
    border: 1px solid $color_neutral_40;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px 0 15px;
    color: $color_neutral_100;
    cursor: pointer;
    transition: all 200ms linear;
  }

  &-placeholder {
    color: $color_neutral_60;
  }

  &-group-error {
    border: 1px solid $color_danger_100;

    .selectbox-label {
      color: $color_danger_100;
    }
  }

  &-focus {
    border: 1px solid $color_neutral_100;
  }

  &-expanded {
    position: absolute;
    max-width: 458px;
    width: 100%;
    height: fit-content;
    max-height: 335px;
    background: $color_neutral_0;
    border: 1px solid $color_neutral_30;
    box-sizing: border-box;
    @include shadow-down-03;
    @include body-1;
    overflow-y: auto;
    border-radius: 2px;

    &-option {
      display: flex;
      min-height: 40px;
      cursor: pointer;
      font-size: 14px;
      color: $color_neutral_100;
      background-color: $color_neutral_0;
      border-bottom: 1px solid $color_neutral_30;
      transition: all 100ms linear;

      &:last-child {
        &-label {
          border-bottom: none;
        }
      }

      &-label {
        width: 100%;
        display: flex;
        align-items: center;

        &-checkbox,
        &-empty {
          min-height: 40px;
          display: flex;
          align-items: center;
          padding: 0 0 0 64px;

          &:hover {
            background-color: $color_neutral_10;
            color: $color_neutral_100;
          }
        }
      }
    }
  }

  &-error {
    color: $color_danger_100;
    font-size: 12px;
    padding: 4px 0 0 0;
    text-align: left;
    line-height: 18px;
    position: absolute;
    right: 0;
  }
}
.no-result {
  display: flex;
  align-items: center;
  padding: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $color_neutral_60;
  &-- {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      color: $color_neutral_100;
    }
  }
}

.input {
  width: 100%;
  border: none;
  padding: 0;
  height: 38px;
  outline: none;
  font-size: 14px;
}

.ri-arrow-drop-down-line {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: $color_neutral_40;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: $color_neutral_40;
}
</style>
