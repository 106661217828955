
<div class="change-email-modal">
  <div>
    <text-field
      ref="inputTitle"
      inputType="text"
      :errorMessage="emailValidationMessage"
      :fieldModel.sync="$v.email.$model"
      :value="email"
      label="Billing email address"
      :large="false"
    />
  </div>
  <div class="change-email-modal-buttons">
    <div class="change-email-modal-buttons--">
      <submit-button
        btnStyle="tertiary"
        size="medium"
        :label="$t('cancel')"
        @submit="closeModal"
      />
    </div>
    <div class="change-email-modal-buttons--">
      <submit-button
        btnStyle="primary"
        size="medium"
        :label="$t('save')"
        :pending="pending"
        @submit="save"
      />
    </div>
  </div>
</div>
