<template>
  <div
    class="tag-category"
    :style="withIconButton ? `width: 40px;` : `width: fit-content;`"
    v-click-outside="{ hide: closeExpandDropdown }"
  >
    <div class="tag-category-button">
      <div class="tag-category-button-withicon" v-if="withIconButton">
        <icon-button
          icon="ri-add-line"
          btnStyle="tertiary"
          size="small"
          @submit="toggleExpandDropdown"
        />
      </div>
      <div
        v-else
        :class="{
          'tag-category-button-withouticon': true,
          'tag-category-button-active':
            JSON.stringify(checkedOptions).includes(tagCategory.id) || noneIsChecked
        }"
        @click="toggleExpandDropdown"
      >
        <div
          class="tag-category-button-withouticon-dot"
          :style="`background-color: ${tagCategory.background_color}`"
        />
        <div class="tag-category-button-withouticon-label">
          {{ tagCategory.name.toLowerCase() }}
        </div>
      </div>
    </div>
    <div
      class="tag-category-expand"
      v-if="isDropdownExpanded"
      :style="withIconButton ? `top: 42px; left: 8px;` : `top: 34px; left: 0;`"
    >
      <checkbox
        v-if="withNoneOption"
        class="tag-category-expand-none"
        label="None"
        large
        :id="tagCategory.id"
        :selectedItems="noneCategories"
        @toggleCheckbox="toggleNoneOption"
      />
      <div v-if="tagCategory.families.length > 1">
        <div
          class="tag-category-expand-option"
          v-for="(family, index) in tagCategory.families"
          :key="family.id"
        >
          <expandable :title="family.name" :subtitle="getExpandableSubtitle(family)">
            <checkbox
              class="tag-category-expand-option-checkbox"
              v-for="(tag, tagIndex) in family.tags"
              :index="index"
              :label="tag.name"
              :id="`${tagCategory.id}-${family.id}-${tag.id}`"
              :selectedItems="checkedOptions"
              large
              :key="tagIndex"
              @toggleCheckbox="toggleTag"
            />
          </expandable>
        </div>
      </div>
      <div v-else>
        <checkbox
          class="tag-category-option-checkbox"
          v-for="(tag, tagIndex) in tagCategory.families[0].tags"
          :index="tagIndex"
          :label="tag.name"
          :id="`${tagCategory.id}-${tagCategory.families[0].id}-${tag.id}`"
          :selectedItems="checkedOptions"
          large
          :key="tagIndex"
          @toggleCheckbox="toggleTag"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { clickOutside } from '@/utils/directives';
import Expandable from '@/components/expandable';
import IconButton from '@/components/iconButton';
import Checkbox from '@/components/checkbox';

export default {
  components: {
    IconButton,
    Expandable,
    Checkbox
  },
  props: {
    tagCategory: {
      type: Object,
      required: false,
      default: null
    },
    checkedTags: {
      type: Array,
      required: false,
      default: () => []
    },
    noneCategories: {
      type: Array,
      required: false,
      default: () => []
    },
    withIconButton: {
      type: Boolean,
      required: false,
      default: false
    },
    withNoneOption: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  directives: {
    clickOutside
  },
  data() {
    return {
      isDropdownExpanded: false,
      checkedOptions: [...this.checkedTags]
    };
  },
  computed: {
    noneIsChecked() {
      return this.noneCategories.includes(this.tagCategory.id);
    }
  },
  watch: {
    checkedTags() {
      this.checkedOptions = [...this.checkedTags];
    }
  },
  methods: {
    toggleExpandDropdown() {
      this.isDropdownExpanded = !this.isDropdownExpanded;
      this.$emit('setIsDropdownExpanded', this.isDropdownExpanded);
    },
    closeExpandDropdown() {
      this.isDropdownExpanded = false;
      this.$emit('setIsDropdownExpanded', this.isDropdownExpanded);
    },
    getExpandableSubtitle(family) {
      const count =
        family.tags &&
        family.tags.filter(tag =>
          this.checkedOptions.includes(`${this.tagCategory.id}-${family.id}-${tag.id}`)
        ).length;
      return count > 0 ? `(${count})` : null;
    },
    toggleTag(value, id) {
      if (!value) {
        const index = this.checkedOptions.indexOf(id);
        this.checkedOptions.splice(index, 1);
        this.$emit('removeTag', id, this.checkedOptions);
      } else {
        this.checkedOptions.push(id);
        this.$emit('addTag', id, this.checkedOptions);
      }
      this.$emit('selectTags', this.checkedOptions);
    },
    toggleNoneOption() {
      this.$emit('selectNone', this.tagCategory.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.tag-category {
  position: relative;
  display: flex;
  align-items: center;

  &-button {
    &-withicon {
      margin: 0 0 0 8px;
    }

    &-withouticon {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 32px;
      padding: 0 16px;
      border: 1px solid $color_neutral_40;
      border-radius: 4px;
      box-sizing: border-box;
      @include body-1;

      &-dot {
        border-radius: 2px;
        width: 8px;
        height: 8px;
        margin: 0 8px 0 0;
      }

      &-label {
        text-transform: capitalize;
      }
    }

    &-active {
      background-color: $color_primary_10;
      border: 1px solid $color_primary_100;
      color: $color_primary_100;
    }
  }

  &-option-checkbox {
    padding-left: 16px;
    height: 40px;
    background: $color_neutral_0;
    border-bottom: 1px solid $color_neutral_30;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $color_neutral_10;
    }
  }

  &-expand {
    position: absolute;
    width: 280px;
    height: fit-content;
    max-height: 248px;
    background: $color_neutral_0;
    border: 1px solid $color_neutral_30;
    box-sizing: border-box;
    @include shadow-down-03;
    @include body-1;
    overflow-y: auto;
    border-radius: 2px;
    z-index: 1;

    &-none {
      padding: 0 0 0 16px;
      height: 40px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $color_neutral_30;
      cursor: pointer;

      &:hover {
        background-color: $color_neutral_10;
      }
    }

    &-option {
      cursor: pointer;
      border-bottom: 1px solid $color_neutral_30;

      &-checkbox {
        padding: 0 0 0 32px;
        height: 40px;
        display: flex;
        align-items: center;

        &:hover {
          background-color: $color_neutral_10;
        }
      }
    }
  }
}
</style>
