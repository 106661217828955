<template>
  <div>
    <div class="modal-container">
      <p class="modal-paragraph">
        The workspace "<b>{{ getWorkspaceName }}</b
        >" will be downgraded to a Free plan. Your downgrade will take effect at the end of your
        billing cycle.
      </p>
      <p class="modal-paragraph">
        If your storage usage exceeds the Free plan limits (50 tracks / 1 Gb), your workspace will
        be read-only. You can clear up space to match your new plan limits.
        <a
          href="https://www.notion.so/bridgeaudio/Plans-Pricing-18d38a83656345358880a8c136f9adb3"
          target="_blank"
          >Learn more</a
        >
      </p>
      <div class="modal-input">
        <text-field
          inputType="password"
          :fieldModel.sync="password"
          label="Enter your password"
          large="large"
          helper="required"
          autocomplete="false"
          withVisibility
        />
      </div>
      <div class="modal-link" @click="sendForgotPasswordMail">
        {{ $t('modalCancellation.forgotPassword') }}
      </div>
      <checkbox
        id="checkbox"
        label="I understand that my workspace will be downgraded to a free plan"
        :isChecked="agreement"
        @toggleCheckbox="toggleCheckbox"
      />
    </div>
    <div class="modal-buttons">
      <submit-button
        btnStyle="tertiary"
        size="medium"
        :label="$t('modalCancellation.cancelButton')"
        @submit="closeModal"
        :disabled="pending"
      />
      <submit-button
        btnStyle="secondary"
        size="medium"
        :label="$t('modalCancellation.confirmButton')"
        :danger="true"
        :disabled="submitButtonDisabled"
        @submit="cancelSubscription"
        :pending="pending"
      />
    </div>
  </div>
</template>

<script>
import { SEND_RESET_PASSWORD_LINK } from '@/graphql/mutations/user';
import { mapGetters } from 'vuex';
import { bus } from '@/utils/bus';

export default {
  props: {
    email: {
      type: String,
      required: true
    },
    onSubmit: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      agreement: false,
      password: '',
      pending: false
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    getWorkspaceName() {
      return this.currentWorkspace.name;
    },
    submitButtonDisabled() {
      return this.password.length === 0 || this.agreement === false;
    }
  },
  methods: {
    sendForgotPasswordMail() {
      this.$apollo
        .mutate({
          mutation: SEND_RESET_PASSWORD_LINK,
          variables: {
            email: this.email
          }
        })
        .then(() => {
          bus.$emit('showAlert', {
            message: {
              key: 'modalCancellation.emailForgotConfirmation',
              args: { email: this.email }
            },
            style: 'success',
            delay: 5000,
            error: false
          });
        });
    },
    cancelSubscription() {
      if (!this.submitButtonDisabled && !this.pending) {
        this.pending = true;
        this.onSubmit(this.password);
      }
    },
    toggleCheckbox() {
      this.agreement = !this.agreement;
    },
    closeModal() {
      this.$emit('closeModal');
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-container {
  padding: 24px 32px 24px 32px;
  @include body-1;
}

.modal-paragraph {
  margin-bottom: 24px;
}

.modal-input {
  margin-bottom: 24px;
}

.modal-link {
  margin-bottom: 24px;
  color: $color_primary_100;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid $color_neutral_30;
  padding: 8px 32px;

  & *:first-child {
    margin-right: 16px;
  }
}
</style>
