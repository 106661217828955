<template>
  <div class="details-panel-body" :key="renderKey">
    <div class="details-panel-body-section">
      <div class="details-panel-body-section-title">{{ $t('soundRecording') }}</div>
      <div class="details-panel-body-section--">
        <text-field
          ref="inputTitle"
          inputType="text"
          :errorMessage="titleErrorMessage"
          :fieldModel.sync="title"
          :label="$t('title')"
          large
          :placeholder="placeholder"
          :value="isMultipleTrackEdition ? null : tracksToEdit[0].title"
          horizontalDisplaying
          :inputWidth="300"
          :withEditButton="isMultipleTrackEdition"
          @handleBlur="autosave(title, tracksToEdit[0].title, 'title', false, false)"
        />
      </div>
      <div class="details-panel-body-section-dropdown">
        <div class="details-panel-body-section-dropdown-label">{{ $t('artistsLabel') }}</div>
        <div class="details-panel-body-section-dropdown--">
          <auto-complete-list
            :queries="autoCompleteQueries.artists"
            :dataLoaded="isArtistsDataLoaded"
            dataType="ArtistsAutocomplete"
            storeEvent="changeIsArtistsLoaded"
            :ignoreUnknown="true"
          >
            <template v-slot:default="slotProps">
              <input-dropdown
                key="artist-dropdown"
                id="artist-dropdown"
                isMultipleSelection
                :width="334"
                :placeholder="defaultArtistOptions.length > 0 ? '' : placeholder"
                :defaultOptions="defaultArtistOptions"
                :withEditButton="isMultipleTrackEdition"
                :defaultList="slotProps.items"
                :loadingOptions="slotProps.isLoading"
                :showObserver="slotProps.showObserver"
                :fetchingMoreOptions="slotProps.fetchingMoreItems"
                @listOptions="slotProps.fetch"
                @fetchMoreOptions="slotProps.fetchMoreItems"
                @searchOption="slotProps.search"
                @handleResult="setArtists"
              />
            </template>
          </auto-complete-list>
        </div>
      </div>
      <div class="details-panel-body-section-dropdown" style="margin-bottom: 9px">
        <div class="details-panel-body-section-dropdown-label">{{ $t('album') }}</div>
        <div class="details-panel-body-section-dropdown--">
          <auto-complete-list
            :queries="autoCompleteQueries.supports"
            :dataLoaded="isSupportsDataLoaded"
            dataType="SupportsAutocomplete"
            storeEvent="changeIsSupportsLoaded"
            :ignoreUnknown="true"
          >
            <template v-slot:default="slotProps">
              <input-dropdown
                key="support-dropdown"
                id="support-dropdown"
                :width="334"
                :placeholder="supportOptions.length > 0 ? '' : placeholder"
                :defaultOptions="supports || supportOptions"
                :defaultList="slotProps.items"
                :loadingOptions="slotProps.isLoading"
                :showObserver="slotProps.showObserver"
                :fetchingMoreOptions="slotProps.fetchingMoreItems"
                :withEditButton="isMultipleTrackEdition"
                preventRemoveOptOnKeyUp
                preventAddNew
                @preventedAddNew="DisplaySupportModal(true)"
                @listOptions="slotProps.fetch"
                @fetchMoreOptions="slotProps.fetchMoreItems"
                @searchOption="slotProps.search"
                @handleResult="setSupports"
                @handleInput="HandleSupportInput"
                @handleBlur="HandleSupportBlur"
              />
            </template>
          </auto-complete-list>
        </div>
      </div>
      <div
        class="details-panel-body-section-- details-panel-body-section-inline section-tracknumber"
        v-if="!isMultipleTrackEdition && tracksToEdit[0].supports.length > 0"
      >
        <label
          class="details-panel-body-section-label details-panel-body-section-label-tracknumber"
        >
          Track number
        </label>
        <text-field
          inputType="number"
          :disabled="isMultipleTrackEdition"
          :errorMessage="trackPositionErrorMessage"
          :fieldModel.sync="trackPosition"
          errorMessageException
          :placeholder="placeholder"
          :value="isMultipleTrackEdition ? null : tracksToEdit[0].supports[0].position"
          :inputWidth="46"
          @handleBlur="
            autosave(
              trackPosition,
              tracksToEdit[0].supports[0].position,
              'trackPosition',
              true,
              false
            )
          "
        />
        <div class="details-panel-body-section-label-tracknumber-separator">of</div>
        <text-field
          inputType="number"
          :disabled="isMultipleTrackEdition"
          :errorMessage="trackTotalErrorMessage"
          :fieldModel.sync="trackTotal"
          errorMessageException
          :placeholder="placeholder"
          :value="isMultipleTrackEdition ? null : tracksToEdit[0].supports[0].total"
          :inputWidth="46"
          @handleBlur="
            autosave(trackTotal, tracksToEdit[0].supports[0].total, 'trackTotal', true, false)
          "
        />
      </div>

      <div class="details-panel-body-section-dropdown">
        <div class="details-panel-body-section-dropdown-label">{{ $t('genresLabel') }}</div>
        <div class="details-panel-body-section-dropdown--">
          <input-dropdown
            key="genre-dropdown"
            id="genre-dropdown"
            isMultipleSelection
            :width="334"
            :preventAddNew="true"
            :withAddButton="false"
            :placeholder="placeholder"
            :defaultOptions="defaultGenreOptions"
            :withEditButton="isMultipleTrackEdition"
            :defaultList="formatedId3TagList"
            @handleResult="setGenres"
          />
        </div>
      </div>
      <div class="details-panel-body-section--">
        <text-field
          inputType="text"
          :errorMessage="isrcErrorMessage"
          :fieldModel.sync="isrc"
          label="ISRC"
          large
          :placeholder="isrcPlaceholder"
          mask="aa-***-00-*****"
          :value="isMultipleTrackEdition ? null : tracksToEdit[0].isrc"
          horizontalDisplaying
          :inputWidth="300"
          :withEditButton="isMultipleTrackEdition"
          @toggleDisableStatus="setIsrcPlaceholder"
          @handleBlur="autosave(isrc, tracksToEdit[0].isrc, 'isrc', false, false)"
        />
      </div>
      <div class="details-panel-body-section--" style="width: 296px">
        <text-field
          inputType="number"
          :errorMessage="bpmErrorMessage"
          :fieldModel.sync="bpm"
          label="BPM"
          large
          :placeholder="placeholder"
          :value="isMultipleTrackEdition ? null : tracksToEdit[0].bpm"
          horizontalDisplaying
          :inputWidth="126"
          :withEditButton="isMultipleTrackEdition"
          @handleBlur="autosave(bpm, tracksToEdit[0].bpm, 'bpm', true, false)"
        />
      </div>
      <div
        class="details-panel-body-section-- details-panel-body-section-inline"
        style="width: 214px"
      >
        <div class="details-panel-body-section-label details-panel-body-section-label-rating">
          {{ $t('rating') }}
        </div>
        <stars-rating
          :length="5"
          :itemRating="isMultipleTrackEdition ? null : tracksToEdit[0].rating"
          @setTrackRating="setTrackRating"
        />
      </div>
      <div class="details-panel-body-section-- details-panel-body-section-inline">
        <label class="details-panel-body-section-label details">{{ $t('recordingDate') }}</label>
        <date-picker
          class="details-panel-body-section-label-datepicker"
          local="en"
          large
          :withEditButton="isMultipleTrackEdition"
          :selectedDate="
            isMultipleTrackEdition ? null : getFormattedTimestamp(tracksToEdit[0].recordingDate)
          "
          :fieldModel.sync="recordingDate"
          :placeholder="datePlaceholder"
          @datePicked="selectRecordingDate"
        />
      </div>
      <div class="details-panel-body-section-dropdown">
        <div class="details-panel-body-section-dropdown-label">{{ $t('labelLabel') }}</div>
        <div class="details-panel-body-section-dropdown--">
          <auto-complete-list
            :queries="autoCompleteQueries.labels"
            :dataLoaded="isLabelsDataLoaded"
            dataType="LabelsAutocomplete"
            storeEvent="changeIsLabelsLoaded"
          >
            <template v-slot:default="slotProps">
              <input-dropdown
                key="labels-dropdown"
                id="labels-dropdown"
                :placeholder="defaultLabelsOptions.length > 0 ? '' : placeholder"
                :defaultOptions="defaultLabelsOptions"
                isMultipleSelection
                :width="334"
                :withEditButton="isMultipleTrackEdition"
                :defaultList="slotProps.items"
                :loadingOptions="slotProps.isLoading"
                :showObserver="slotProps.showObserver"
                :fetchingMoreOptions="slotProps.fetchingMoreItems"
                @listOptions="slotProps.fetch"
                @fetchMoreOptions="slotProps.fetchMoreItems"
                @searchOption="slotProps.search"
                @handleResult="setLabels"
              />
            </template>
          </auto-complete-list>
        </div>
      </div>
    </div>
    <div class="details-panel-body-section">
      <div class="details-panel-body-section-title">{{ $t('musicalWork') }}</div>
      <div class="details-panel-body-section-dropdown">
        <div class="details-panel-body-section-dropdown-label">{{ $t('authorsLabel') }}</div>
        <div class="details-panel-body-section-dropdown--">
          <auto-complete-list
            :queries="autoCompleteQueries.authors"
            :dataLoaded="isAuthorsDataLoaded"
            dataType="AuthorsAutocomplete"
            storeEvent="changeIsAuthorsLoaded"
          >
            <template v-slot:default="slotProps">
              <input-dropdown
                key="authors-dropdown"
                id="authors-dropdown"
                :placeholder="defaultAuthorOptions.length > 0 ? '' : placeholder"
                :defaultOptions="defaultAuthorOptions"
                isMultipleSelection
                :width="334"
                :withEditButton="isMultipleTrackEdition"
                :defaultList="slotProps.items"
                :loadingOptions="slotProps.isLoading"
                :showObserver="slotProps.showObserver"
                :fetchingMoreOptions="slotProps.fetchingMoreItems"
                @listOptions="slotProps.fetch"
                @fetchMoreOptions="slotProps.fetchMoreItems"
                @searchOption="slotProps.search"
                @handleResult="setAuthors"
              />
            </template>
          </auto-complete-list>
        </div>
      </div>
      <div class="details-panel-body-section-dropdown">
        <div class="details-panel-body-section-dropdown-label">{{ $t('composersLabel') }}</div>
        <div class="details-panel-body-section-dropdown--">
          <auto-complete-list
            :queries="autoCompleteQueries.composers"
            :dataLoaded="isComposersDataLoaded"
            dataType="ComposersAutocomplete"
            storeEvent="changeIsComposersLoaded"
          >
            <template v-slot:default="slotProps">
              <input-dropdown
                key="composers-dropdown"
                id="composers-dropdown"
                :placeholder="defaultComposerOptions.length > 0 ? '' : placeholder"
                :defaultOptions="defaultComposerOptions"
                isMultipleSelection
                :width="334"
                :withEditButton="isMultipleTrackEdition"
                :defaultList="slotProps.items"
                :loadingOptions="slotProps.isLoading"
                :showObserver="slotProps.showObserver"
                :fetchingMoreOptions="slotProps.fetchingMoreItems"
                @listOptions="slotProps.fetch"
                @fetchMoreOptions="slotProps.fetchMoreItems"
                @searchOption="slotProps.search"
                @handleResult="setComposers"
              />
            </template>
          </auto-complete-list>
        </div>
      </div>
      <div class="details-panel-body-section--">
        <text-field
          inputType="text"
          :errorMessage="iswcErrorMessage"
          :fieldModel.sync="iswc"
          label="ISWC"
          large
          :placeholder="iswcPlaceholder"
          mask="a-000.000.000-0"
          :value="isMultipleTrackEdition ? null : tracksToEdit[0].iswc"
          horizontalDisplaying
          :inputWidth="300"
          :withEditButton="isMultipleTrackEdition"
          @toggleDisableStatus="setIswcPlaceholder"
          @handleBlur="autosave(iswc, tracksToEdit[0].iswc, 'iswc', false, false)"
        />
      </div>
      <div class="details-panel-body-section-dropdown">
        <div class="details-panel-body-section-dropdown-label">{{ $t('publishers') }}</div>
        <div class="details-panel-body-section-dropdown--">
          <auto-complete-list
            :queries="autoCompleteQueries.publishers"
            :dataLoaded="isPublishersDataLoaded"
            dataType="PublishersAutocomplete"
            storeEvent="changeIsPublishersLoaded"
          >
            <template v-slot:default="slotProps">
              <input-dropdown
                key="publishers-dropdown"
                id="publishers-dropdown"
                :placeholder="defaultPublishersOptions.length > 0 ? '' : placeholder"
                :defaultOptions="defaultPublishersOptions"
                isMultipleSelection
                :width="334"
                :withEditButton="isMultipleTrackEdition"
                :defaultList="slotProps.items"
                :loadingOptions="slotProps.isLoading"
                :showObserver="slotProps.showObserver"
                :fetchingMoreOptions="slotProps.fetchingMoreItems"
                @listOptions="slotProps.fetch"
                @fetchMoreOptions="slotProps.fetchMoreItems"
                @searchOption="slotProps.search"
                @handleResult="setPublishers"
              />
            </template>
          </auto-complete-list>
        </div>
      </div>
    </div>
    <div class="details-panel-body-section">
      <div class="details-panel-body-section-title-comment">
        {{ $t('comment') }}
      </div>
      <div
        v-if="this.currentWorkspace.hubs.length > 0"
        class="details-panel-body-section-description"
      >
        {{ $t('commentDescriptionFirstPart') }}
        <span
          class="details-panel-body-section-description-link"
          @click="redirectToGeneralSettings"
          >{{ $t('commentDescriptionSecondPart') }}</span
        >
      </div>
      <div class="details-panel-body-section--">
        <text-area
          :fieldModel.sync="comment"
          :placeholder="placeholder"
          adaptativeHeight
          :value="isMultipleTrackEdition ? null : tracksToEdit[0].comment"
          :withEditButton="isMultipleTrackEdition"
          @validate="autosave(comment, tracksToEdit[0].comment, 'comment', false, false)"
        />
      </div>
    </div>
    <div class="details-panel-body-section">
      <div class="details-panel-body-section-title">{{ $t('lyrics') }}</div>
      <div class="details-panel-body-section--">
        <text-area
          :fieldModel.sync="lyrics"
          :placeholder="placeholder"
          adaptativeHeight
          :value="isMultipleTrackEdition ? null : tracksToEdit[0].lyrics"
          :withEditButton="isMultipleTrackEdition"
          @validate="autosave(lyrics, tracksToEdit[0].lyrics, 'lyrics', false, false)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';
import { maxLength, minValue } from 'vuelidate/lib/validators';
import isequal from 'lodash.isequal';
import clonedeep from 'lodash.clonedeep';

import { ID3_TAG_LIST } from '@/graphql/queries/global';
import {
  ARTIST_CREATE,
  SUPPORT_CREATE,
  AUTHOR_CREATE,
  COMPOSER_CREATE,
  LABELS_CREATE,
  PUBLISHERS_CREATE,
  SUPPORT_UPDATE_TRACK_POSITION,
  SUPPORT_UPDATE
} from '@/graphql/mutations/library';
import {
  LIST_ARTISTS,
  ARTISTS_AUTOCOMPLETE,
  SUPPORT_LIST,
  SUPPORTS_AUTOCOMPLETE,
  LIST_AUTHORS,
  AUTHORS_AUTOCOMPLETE,
  LIST_COMPOSERS,
  COMPOSERS_AUTOCOMPLETE,
  LIST_LABEL,
  LABELS_AUTOCOMPLETE,
  LIST_PUBLISHERS,
  PUBLISHERS_AUTOCOMPLETE
} from '@/graphql/queries/library';
import { PAGINATOR_LIMIT, DONT_SHOW_THIS_MESSAGE_AGAIN } from '@/utils/constants';
import { WORKSPACE } from '@/graphql/queries/user';
import { SEARCH_TRACKS } from '@/graphql/queries/search';
import { handleSupportImageUpload } from '@/utils/functions/upload';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import { bus } from '@/utils/bus';
import { formatDateAsTimestamp } from '@/utils/functions/time';
import AutoCompleteList from '@/containers/autoCompleteList';
import SupportModal from '@/containers/modals/support';
import { trackEvent } from '@/utils/functions/analytics';

const DEFAULT_ISRC_MASK = '__-___-__-_____';

export default {
  components: {
    AutoCompleteList
  },
  props: {
    tracksToEdit: {
      type: Array,
      required: false,
      default: () => []
    },
    isMultipleTrackEdition: {
      type: Boolean,
      required: false,
      default: false
    },
    formHasChangedParent: {
      type: Boolean,
      required: false,
      default: false
    },
    isSaveButtonDisabledParent: {
      type: Boolean,
      required: false,
      default: false
    },
    pendingStatusParent: {
      type: Boolean,
      required: false,
      default: false
    },
    isProject: {
      type: Boolean,
      required: true
    },
    openSupportModalOnLoad: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      placeholder: null,
      isrcPlaceholder: null,
      iswcPlaceholder: null,
      datePlaceholder: null,
      title: null,
      isrc: null,
      bpm: null,
      iswc: null,
      recordingDate: null,
      place: null,
      lyrics: null,
      comment: null,
      rating: null,
      artists: null,
      supports: null,
      genres: null,
      trackPosition: null,
      trackTotal: null,
      authors: null,
      composers: null,
      labels: null,
      publishers: null,
      pendingStatus: false,
      showSaveChangesModal: false,
      newArtists: null,
      newSupport: null,
      newAuthors: null,
      newComposers: null,
      newLabels: null,
      newPublishers: null,
      supportModel: null,
      supportCanSave: false,
      renderKey: 0
    };
  },
  apollo: {
    Id3TagList: {
      query: ID3_TAG_LIST
    }
  },
  validations: {
    title: {
      required(value) {
        let required = true;
        if (!this.isMultipleTrackEdition) {
          required = value === null ? true : value.length > 0;
        }
        return required;
      },
      maxLength: maxLength(1000)
    },
    isrc: {
      isIsrc(value) {
        if (!value || value === DEFAULT_ISRC_MASK) return true;
        const isMatching = value.match(/^[a-zA-Z]{2}-?[a-zA-Z0-9]{3}-?[0-9]{2}-?[a-zA-Z0-9]{5}-?/);
        return !!isMatching;
      }
    },
    bpm: {
      maxLength: maxLength(4),
      minValue: minValue(1)
    },
    trackPosition: {
      maxLength: maxLength(5)
    },
    trackTotal: {
      maxLength: maxLength(5)
    },
    iswc: {
      isIswc(value) {
        if (!value) return true;
        const isMatching = value.match(/^[a-zA-z]{1}-?\w{3}.?\d{3}.?\d{3}-?\d{1}$/);
        return !!isMatching;
      }
    },
    recordingDate: {
      isDate(value) {
        if (!value) return true;
        return true;
      }
    }
  },
  created() {
    this.$emit('autoSaveSuccess', null);
    this.resetFormData();
    if (this.openSupportModalOnLoad) {
      this.DisplaySupportModal(true);
      this.$emit('update:openSupportModalOnLoad', false);
    }
  },
  computed: {
    ...mapGetters([
      'playingTrack',
      'playlist',
      'currentWorkspace',
      'isArtistsLoaded',
      'isSupportsLoaded',
      'isAuthorsLoaded',
      'isComposersLoaded',
      'isLabelsLoaded',
      'isPublishersLoaded',
      'searchLibraryQueryParameters'
    ]),
    isSupportsDataLoaded() {
      return this.isSupportsLoaded;
    },
    isArtistsDataLoaded() {
      return this.isArtistsLoaded;
    },
    isAuthorsDataLoaded() {
      return this.isAuthorsLoaded;
    },
    isComposersDataLoaded() {
      return this.isComposersLoaded;
    },
    isLabelsDataLoaded() {
      return this.isLabelsLoaded;
    },
    isPublishersDataLoaded() {
      return this.isPublishersLoaded;
    },
    formatedId3TagList() {
      return this.Id3TagList
        ? this.Id3TagList.map(item => ({ value: item.name, id: item.id }))
        : [];
    },
    autoCompleteQueries() {
      return {
        artists: {
          items: LIST_ARTISTS,
          itemKey: 'listArtists',
          itemVariables: {
            libraryId: this.currentWorkspace.libraries[0].id,
            page: 1,
            limit: this.limit,
            orderBy: 'name',
            sortBy: 'asc'
          },
          search: ARTISTS_AUTOCOMPLETE,
          searchKey: 'ArtistsAutocomplete'
        },
        authors: {
          items: LIST_AUTHORS,
          itemKey: 'AuthorsList',
          itemVariables: {
            workspaceId: this.currentWorkspace.id,
            page: 1,
            limit: this.limit,
            sortBy: 'name',
            sortDir: 'asc'
          },
          search: AUTHORS_AUTOCOMPLETE,
          searchKey: ''
        },
        composers: {
          items: LIST_COMPOSERS,
          itemKey: 'ComposersList',
          itemVariables: {
            workspaceId: this.currentWorkspace.id,
            page: 1,
            limit: this.limit,
            sortBy: 'name',
            sortDir: 'asc'
          },
          search: COMPOSERS_AUTOCOMPLETE,
          searchKey: ''
        },
        supports: {
          items: SUPPORT_LIST,
          itemKey: 'supportList',
          itemVariables: {
            libraryId: this.currentWorkspace.libraries[0].id,
            page: 1,
            limit: this.limit,
            orderBy: 'name',
            sortBy: 'asc'
          },
          search: SUPPORTS_AUTOCOMPLETE,
          searchKey: 'SupportsAutocomplete'
        },
        labels: {
          items: LIST_LABEL,
          itemKey: 'LabelsList',
          itemVariables: {
            workspaceId: this.currentWorkspace.id,
            page: 1,
            limit: this.limit,
            sortDir: 'asc',
            sortBy: 'name'
          },
          search: LABELS_AUTOCOMPLETE,
          searchKey: 'LabelsAutocomplete'
        },
        publishers: {
          items: LIST_PUBLISHERS,
          itemKey: 'PublishersList',
          itemVariables: {
            workspaceId: this.currentWorkspace.id,
            page: 1,
            limit: this.limit,
            sortDir: 'asc',
            sortBy: 'name'
          },
          search: PUBLISHERS_AUTOCOMPLETE,
          searchKey: 'PublishersAutocomplete'
        }
      };
    },
    defaultArtistOptions() {
      return this.getDefaultOptions('artists');
    },
    supportOptions() {
      return this.getDefaultOptions('supports');
    },
    defaultAuthorOptions() {
      return this.getDefaultOptions('authors');
    },
    defaultComposerOptions() {
      return this.getDefaultOptions('composers');
    },
    defaultGenreOptions() {
      return this.getDefaultOptions('genre');
    },
    defaultLabelsOptions() {
      return this.getDefaultOptions('labels');
    },
    defaultPublishersOptions() {
      return this.getDefaultOptions('publishers');
    },
    titleErrorMessage() {
      if (!this.$v.title.required) {
        return this.$t('required', { label: this.$t('title') });
      }
      if (!this.$v.title.maxLength) {
        return this.$t('maxiChars', {
          count: this.$v.title.$params.maxLength.max
        });
      }
      return null;
    },
    isrcErrorMessage() {
      if (!this.$v.isrc.isIsrc) return this.$t('invalidIsrc');
      return null;
    },
    bpmErrorMessage() {
      if (!this.$v.bpm.maxLength) {
        return this.$t('maxLength', {
          nbChars: this.$v.bpm.$params.maxLength.max
        });
      }
      if (!this.$v.bpm.minValue) {
        return this.$t('minValue', {
          count: this.$v.bpm.$params.minValue.min
        });
      }
      return null;
    },
    trackPositionErrorMessage() {
      if (!this.$v.trackPosition.maxLength) {
        return this.$t('maxLength', {
          nbChars: this.$v.trackPosition.$params.maxLength.max
        });
      }
      return null;
    },
    trackTotalErrorMessage() {
      if (!this.$v.trackTotal.maxLength) {
        return this.$t('maxLength', {
          nbChars: this.$v.trackTotal.$params.maxLength.max
        });
      }
      return null;
    },
    iswcErrorMessage() {
      if (!this.$v.iswc.isIswc) return this.$t('invalidIswc');
      return null;
    },
    recordingDateErrorMessage() {
      if (!this.$v.recordingDate.isDate) return this.$t('invalidDateFormat');
      return null;
    },
    formHasChanged() {
      return (
        this.title !== null ||
        this.isrc !== null ||
        this.bpm !== null ||
        this.rating !== null ||
        this.iswc !== null ||
        this.lyrics !== null ||
        this.comment !== null ||
        (this.supports !== null && this.supportCanSave) ||
        this.genres !== null ||
        this.artists !== null ||
        this.recordingDate !== null ||
        this.place !== null ||
        this.authors !== null ||
        this.composers !== null ||
        this.labels !== null ||
        this.publishers !== null ||
        this.trackPosition !== null ||
        this.trackTotal !== null
      );
    },
    isSaveButtonDisabled() {
      const isError =
        this.titleErrorMessage !== null ||
        this.isrcErrorMessage !== null ||
        this.bpmErrorMessage !== null ||
        this.trackPositionErrorMessage !== null ||
        this.trackTotalErrorMessage !== null ||
        this.iswcErrorMessage !== null ||
        this.recordingDateErrorMessage !== null;
      return isError || !this.formHasChanged;
    },
    newSupportName() {
      return this.supportModel;
    }
  },
  watch: {
    tracksToEdit(next, old) {
      this.$emit('autoSaveSuccess', null);
      if (!isequal(next, old)) {
        this.renderKey = `${Math.floor(Math.random() * 100000)}-${new Date().getTime()}`;
        this.resetFormData();
      }
    },
    formHasChanged() {
      this.$emit('update:formHasChangedParent', this.formHasChanged);
    },
    isSaveButtonDisabled() {
      this.$emit('update:isSaveButtonDisabledParent', this.isSaveButtonDisabled);
      // We need to disable the checkbox if some changes are not saved in the edit track panel
      bus.$emit('disableTrackSelection', !this.isSaveButtonDisabled);
    },
    pendingStatus() {
      this.$emit('update:pendingStatusParent', this.pendingStatus);
    }
  },
  methods: {
    selectRecordingDate(value) {
      this.recordingDate = value;
      const d = new Date(this.recordingDate);
      const date = `${d.getUTCFullYear()}/${d.getUTCMonth() + 1}/${d.getUTCDate()}`;
      this.autosave(date, this.tracksToEdit[0].recordingDate, 'recording_date', false, false);
    },
    resetFormData() {
      this.title = null;
      this.isrc = null;
      this.bpm = null;
      this.rating = null;
      this.iswc = null;
      this.recordingDate = null;
      this.place = null;
      this.lyrics = null;
      this.comment = null;
      this.artists = null;
      this.genres = null;
      this.supports = null;
      this.trackPosition = null;
      this.trackTotal = null;
      this.authors = null;
      this.composers = null;
      this.labels = null;
      this.publishers = null;
      this.placeholder = this.isMultipleTrackEdition ? this.$t('multiple') : '';
      this.isrcPlaceholder = this.isMultipleTrackEdition ? this.$t('multiple') : 'CC-XXX-YY-NNNNN';
      this.iswcPlaceholder = this.isMultipleTrackEdition ? this.$t('multiple') : 'A-123.456.789-0';
      this.datePlaceholder = this.isMultipleTrackEdition ? this.$t('multiple') : 'DD/MM/YYYY';
      this.$emit('update:formHasChangedParent', this.formHasChanged);
      this.$emit('update:isSaveButtonDisabledParent', this.isSaveButtonDisabled);
      // We need to disable the checkbox if some changes are not saved in the edit track panel
      bus.$emit('disableTrackSelection', false);
    },
    autosave(field, defaultValue, inputKey, fieldToNum, forceSave) {
      if (this.$isWorkspaceLockedVerification(this)) return;
      if (this.isMultipleTrackEdition) {
        return;
      }
      let fieldValue = field;
      if (fieldToNum) {
        fieldValue = field === '' ? null : parseInt(field, 10);
      }
      const canSave = forceSave ? true : field !== defaultValue;
      if (field !== null && canSave) {
        trackEvent('Track Edit AutoSave', {
          category: 'metadata',
          field_edit: inputKey,
          track_id: this.tracksToEdit[0].id
        });
        if (inputKey === 'trackPosition' || inputKey === 'trackTotal') {
          this.handleLibraryTracksUpdateInput(true);
          return;
        }
        const input = [];
        if (
          inputKey === 'title' &&
          (fieldValue.trim().length <= 0 || fieldValue.trim().length > 1000)
        ) {
          this.$refs.inputTitle.changeInputValue(defaultValue);
          this.$emit('autoSaveSuccess', false);
          setTimeout(() => {
            this.title = null;
            this.$emit('autoSaveSuccess', null);
          }, 2000);
          return;
        }
        if (inputKey === 'isrc' && fieldValue === DEFAULT_ISRC_MASK) {
          fieldValue = null;
        }
        input.push({ id: this.tracksToEdit[0].id, [inputKey]: fieldValue });
        this.handleMutations(true, input);
      }
    },
    getDefaultOptions(key) {
      if (this.isMultipleTrackEdition) return [];
      return this.tracksToEdit[0][key]
        ? this.tracksToEdit[0][key].map(item => ({
            value: item.name,
            id: item.id
          }))
        : [];
    },
    getFormattedTimestamp(date) {
      if (date) {
        return formatDateAsTimestamp(date);
      }
      return null;
    },
    setArtists(artists) {
      this.artists = artists.filter(item => !item.id.includes('random')).map(item => item.id);
      this.newArtists = artists.filter(item => item.id.includes('random')).map(item => item.value);
      this.autosave(this.artists, this.defaultArtistOptions, 'artists_ids', false, true);
    },
    setGenres(genres) {
      const stringifiedGenres = genres.map(item => item.value);
      this.genres = genres;
      this.autosave(stringifiedGenres, '', 'genre', false, true);
    },
    setSupports(supports, isNew) {
      this.supportCanSave = true;
      if (isNew) {
        this.supports = [
          {
            id: supports[0].id,
            value: supports[0].value
          }
        ];
        this.DisplaySupportModal(true);
      } else {
        this.supports = supports;
      }
      const supportsIds = this.supports.map(support => support.id);
      if (Number.isInteger(supportsIds[0])) {
        this.handleMutations();
      } else {
        this.autosave(supportsIds, this.supportOptions, 'supports_ids', false, true);
      }
    },
    setNewSupport(support) {
      this.supportCanSave = true;
      this.newSupport = support;
      this.supports = [
        {
          id: Math.floor(Math.random() * 100000),
          value: support.variables.name
        }
      ];
      this.handleMutations();
      this.DisplaySupportModal(false);
    },
    HandleSupportInput(newValue) {
      this.supportModel = newValue;
      if (!newValue === '') {
        this.supportCanSave = false;
      }
    },
    HandleSupportBlur(newValue) {
      this.supportModel = newValue;
      if (newValue === '') {
        this.setSupports([], false);
      }
    },
    DisplaySupportModal(bool) {
      bus.$emit('displayModal', {
        component: SupportModal,
        onSubmit: this.setNewSupport,
        size: 'medium',
        isVisible: bool,
        title: this.$t('createAlbum'),
        props: [
          {
            name: 'supportDetails',
            value: {
              supportName: this.newSupportName
            }
          },
          {
            name: 'defaultArtists',
            value: [...this.defaultArtistOptions]
          },
          {
            name: 'isCreate',
            value: true
          }
        ]
      });
    },
    setAuthors(authors) {
      this.authors = authors.filter(item => !item.id.includes('random')).map(item => item.id);
      this.newAuthors = authors.filter(item => item.id.includes('random')).map(item => item.value);
      this.autosave(this.authors, this.defaultAuthorOptions, 'authors_ids', false, true);
    },
    setLabels(labels) {
      this.labels = labels.filter(item => !item.id.includes('random')).map(item => item.id);
      this.newLabels = labels.filter(item => item.id.includes('random')).map(item => item.value);
      this.autosave(this.labels, this.defaultLabelsOptions, 'labels_ids', false, true);
    },
    setPublishers(publishers) {
      this.publishers = publishers.filter(item => !item.id.includes('random')).map(item => item.id);
      this.newPublishers = publishers
        .filter(item => item.id.includes('random'))
        .map(item => item.value);
      this.autosave(this.publishers, this.defaultPublishersOptions, 'publishers_ids', false, true);
    },
    setComposers(composers) {
      this.composers = composers.filter(item => !item.id.includes('random')).map(item => item.id);
      this.newComposers = composers
        .filter(item => item.id.includes('random'))
        .map(item => item.value);
      this.autosave(this.composers, this.defaultComposerOptions, 'composers_ids', false, true);
    },
    setTrackRating(params) {
      this.rating = params.rating;
      const defaultValue = this.isMultipleTrackEdition ? null : this.tracksToEdit[0].rating;
      this.autosave(this.rating, defaultValue, 'rating', true, true);
    },
    setIsrcPlaceholder(disableStatus) {
      if (!disableStatus) this.isrcPlaceholder = 'CC-XXX-YY-NNNNN';
    },
    setIswcPlaceholder(disableStatus) {
      if (!disableStatus) this.iswcPlaceholder = 'A-123.456.789-0';
    },
    setDatePlaceholder(disableStatus) {
      if (!disableStatus) this.datePlaceholder = 'DD/MM/YYYY';
    },
    handleClickSaveButton() {
      if (this.isMultipleTrackEdition) {
        if (localStorage.getItem(DONT_SHOW_THIS_MESSAGE_AGAIN) === 'true') {
          this.handleMutations();
        } else {
          setTimeout(() => {
            this.$emit('showModalForMultiEdition');
          }, 0);
        }
      } else {
        this.handleMutations();
      }
    },
    handleMutations(isAutoSave, input) {
      const promiseArray = [];
      if (this.newArtists) {
        this.newArtists.forEach(name => {
          const promise = this.$apollo
            .mutate({
              mutation: ARTIST_CREATE,
              variables: {
                workspaceId: this.currentWorkspace.id,
                name
              },
              refetchQueries: [
                {
                  query: LIST_ARTISTS,
                  variables: {
                    libraryId: this.currentWorkspace.libraries[0].id,
                    page: 1,
                    limit: PAGINATOR_LIMIT,
                    orderBy: 'name',
                    sortBy: 'asc'
                  }
                }
              ]
            })
            .then(response => {
              this.artists.push(response.data.ArtistCreate.id);
            });
          promiseArray.push(promise);
        });
      }
      if (this.newSupport) {
        const { variables, file } = this.newSupport;
        variables.libraryId = this.currentWorkspace.libraries[0].id;
        const promise1 = this.$apollo
          .mutate({
            mutation: SUPPORT_CREATE,
            variables,
            refetchQueries: [
              {
                query: SUPPORT_LIST,
                variables: {
                  libraryId: this.currentWorkspace.libraries[0].id,
                  page: 1,
                  limit: PAGINATOR_LIMIT,
                  orderBy: 'name',
                  sortBy: 'asc'
                }
              }
            ]
          })
          .then(response => {
            if (this.newSupport.variables.artists.length > 1) {
              const promise3 = this.$apollo.mutate({
                mutation: SUPPORT_UPDATE,
                variables: {
                  workspaceId: this.currentWorkspace.id,
                  supportId: response.data.SupportCreate.id,
                  artistsIds: this.newSupport.variables.artists
                }
              });
              promiseArray.push(promise3);
            }
            this.newSupport = null;
            this.supports = [
              {
                id: response.data.SupportCreate.id,
                value: response.data.SupportCreate.name
              }
            ];
            if (file !== null) {
              const newFile = new File([file], 'newAlbumCover', { type: 'image/jpeg' });
              const libraryFilesIds = this.tracksToEdit.map(track => track.id);
              const promise2 = handleSupportImageUpload(
                this,
                newFile,
                response.data.SupportCreate.id,
                libraryFilesIds
              );
              promiseArray.push(promise2);
            }
          });
        promiseArray.push(promise1);
      }
      if (this.newAuthors) {
        this.newAuthors.forEach(name => {
          const promise = this.$apollo
            .mutate({
              mutation: AUTHOR_CREATE,
              variables: {
                workspaceId: this.currentWorkspace.id,
                name
              }
            })
            .then(response => {
              this.authors.push(response.data.AuthorCreate.id);
            });
          promiseArray.push(promise);
        });
      }
      if (this.newComposers) {
        this.newComposers.forEach(name => {
          const promise = this.$apollo
            .mutate({
              mutation: COMPOSER_CREATE,
              variables: {
                workspaceId: this.currentWorkspace.id,
                name
              }
            })
            .then(response => {
              this.composers.push(response.data.ComposerCreate.id);
            });
          promiseArray.push(promise);
        });
      }
      if (this.newLabels && this.newLabels.length) {
        const promise = this.$apollo
          .mutate({
            mutation: LABELS_CREATE,
            variables: {
              workspaceId: this.currentWorkspace.id,
              names: this.newLabels
            }
          })
          .then(response => {
            this.labels.push(response.data.LabelsCreate[0].id);
          });
        promiseArray.push(promise);
      }
      if (this.newPublishers && this.newPublishers.length) {
        const promise = this.$apollo
          .mutate({
            mutation: PUBLISHERS_CREATE,
            variables: {
              workspaceId: this.currentWorkspace.id,
              names: this.newPublishers
            }
          })
          .then(response => {
            this.publishers.push(response.data.PublishersCreate[0].id);
          });
        promiseArray.push(promise);
      }
      Promise.all(promiseArray)
        .then(() => {
          this.newArtists = null;
          this.newAuthors = null;
          this.newSupports = null;
          this.newComposers = null;
          this.newLabels = null;
          this.newPublishers = null;
          if (isAutoSave) {
            this.handleLibraryTracksUpdateMutation(input);
          } else {
            this.handleLibraryTracksUpdateInput();
          }
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    handleLibraryTracksUpdateInput(byPassVerification) {
      this.$v.$touch();
      if (!byPassVerification && this.$v.$anyError) {
        return;
      }
      if (!this.formHasChanged) {
        return;
      }
      const input = this.tracksToEdit.map(track => ({
        id: this.isMultipleTrackEdition ? track : track.id
      }));
      this.tracksToEdit.forEach((track, index) => {
        if (this.title !== null) input[index].title = this.title;
        if (this.isrc === '') this.isrc = null;
        if (this.isrc !== null) input[index].isrc = this.isrc;
        if (this.genres !== null) input[index].genre = this.genres.map(genre => genre.value);
        if (this.iswc === '') this.iswc = null;
        if (this.iswc !== null) input[index].iswc = this.iswc;
        if (this.bpm !== null) input[index].bpm = this.bpm !== '' ? parseInt(this.bpm, 10) : null;
        if (this.rating !== null) input[index].rating = this.rating;
        if (this.lyrics !== null) input[index].lyrics = this.lyrics;
        if (this.comment !== null) input[index].comment = this.comment;
        if (this.recordingDate !== null) {
          const date = new Date(this.recordingDate);
          input[index].recording_date = `${date.getUTCFullYear()}/${
            date.getUTCMonth() + 1
          }/${date.getUTCDate()}`;
        }
        if (this.place !== null) input[index].registration_place = this.place;
        if (this.supports !== null)
          input[index].supports_ids = this.supports.map(support => support.id);
        if (this.artists !== null) input[index].artists_ids = this.artists;
        if (this.authors !== null) input[index].authors_ids = this.authors;
        if (this.composers !== null) input[index].composers_ids = this.composers;
        if (this.labels !== null) input[index].labels_ids = this.labels;
        if (this.publishers !== null) input[index].publishers_ids = this.publishers;
      });

      if (this.trackPosition !== null) {
        this.handleSupportUpdateTrackPositionMutation(
          parseInt(this.trackPosition, 10),
          this.tracksToEdit[0]
        );
      }
      if (this.trackTotal !== null) {
        this.handleSupportUpdateTrackTotalMutation(
          parseInt(this.trackTotal, 10),
          this.tracksToEdit[0]
        );
      }
      if (Object.keys(input[0]).length > 1) {
        this.handleLibraryTracksUpdateMutation(input);
      }
    },
    handleSupportUpdateTrackPositionMutation(trackPosition, track) {
      this.pendingStatus = true;
      this.$apollo
        .mutate({
          mutation: SUPPORT_UPDATE_TRACK_POSITION,
          variables: {
            supportId: track.supports[0].id,
            libraryFileId: track.id,
            trackPosition
          }
        })
        .then(() => {
          this.pendingStatus = false;
          this.resetFormData();
          this.$emit('autoSaveSuccess', true);
          if (this.isMultipleTrackEdition) {
            bus.$emit('showAlert', {
              message: { key: 'modificationSuccess' },
              style: 'success',
              delay: 5000
            });
          }
          bus.$emit('handleTracksEditMutationDone', true);
        })
        .catch(error => {
          this.pendingStatus = false;
          this.$emit('autoSaveSuccess', false);
          const errorKey = getGraphQlErrorCode(error);
          if (this.isMultipleTrackEdition) {
            bus.$emit('showAlert', {
              message: { key: errorKey },
              style: 'danger',
              delay: 5000,
              error: true
            });
          }
        });
    },
    handleSupportUpdateTrackTotalMutation(tracksTotal, track) {
      this.pendingStatus = true;
      this.$apollo
        .mutate({
          mutation: SUPPORT_UPDATE,
          variables: {
            libraryId: this.currentWorkspace.libraries[0].id,
            workspaceId: this.currentWorkspace.id,
            supportId: track.supports[0].id,
            tracksTotal
          }
        })
        .then(() => {
          this.$emit('refetchLibraryFilesDetails');
          this.$emit('autoSaveSuccess', true);
          if (this.isMultipleTrackEdition) {
            bus.$emit('showAlert', {
              message: { key: 'modificationSuccess' },
              style: 'success',
              delay: 5000
            });
          }
          bus.$emit('handleTracksEditMutationDone', true);
        })
        .catch(error => {
          this.pendingStatus = false;
          this.$emit('autoSaveSuccess', false);
          const errorKey = getGraphQlErrorCode(error);
          if (this.isMultipleTrackEdition) {
            bus.$emit('showAlert', {
              message: { key: errorKey },
              style: 'danger',
              delay: 5000,
              error: true
            });
          }
        });
    },
    TrackUpdateMutation(input) {
      const supportFragment =
        'supports{id name slug release_date track_position tracks_ids tracks_length tracks_number tracks_total type upc images{type file_name url thumbnails{type width height url}}}';
      const artistFragment =
        'artists{id name image{type file_name url thumbnails{type width height url}}}';
      const authorsFragment = 'authors{id name slug}';
      const composerFragment = 'composers{id name slug}';
      const labelFragment = 'labels{id name}';
      const publisherFragment = 'publishers{id name}';

      const inputKeys = Object.keys(input[0]);
      const mutationData = `{${inputKeys.toString().replaceAll(',', ' ')}}`;
      const data = mutationData
        .replaceAll('supports_ids', supportFragment)
        .replaceAll('artists_ids', artistFragment)
        .replaceAll('authors_ids', authorsFragment)
        .replaceAll('composers_ids', composerFragment)
        .replaceAll('labels_ids', labelFragment)
        .replaceAll('publishers_ids', publisherFragment);
      return gql`
        mutation LibraryTracksUpdate($input: [LibraryFileUpdateInput]) {
          LibraryTracksUpdate(input: $input) {
            last_page
            data ${data}
          }
        }
      `;
    },
    handleLibraryTracksUpdateMutation(input) {
      if (this.isProject) {
        /* eslint-disable */
        input.forEach(el => {
          el.id = this.tracksToEdit.find(track => track.id === el.id).libraryFileId;
        });
        /* eslint-disable */
      }
      this.pendingStatus = true;

      const TrackUpdateMutation = this.TrackUpdateMutation(input);
      this.$apollo
        .mutate({
          mutation: TrackUpdateMutation,
          variables: {
            input
          },
          update: (store, response) => {
            if (this.$route.name === 'trackSearch') {
              const searchVariables = {
                ...this.searchLibraryQueryParameters,
                version: 2,
                offset: 0,
                limit: PAGINATOR_LIMIT,
                sort: {
                  by: 'added_at',
                  dir: 'desc'
                }
              };
              const searchTracks = store.readQuery({
                query: SEARCH_TRACKS,
                key: 'SearchTracks',
                variables: searchVariables,
                pagination: 'offset'
              });
              const cloneTracks = clonedeep(searchTracks);
              this.tracksToEdit.forEach((track, id) => {
                const target = cloneTracks.SearchTracks.data.tracks.find(el => el.id === track.id);
                const source = response.data.LibraryTracksUpdate.data[id];
                Object.assign(target, source);
                const indexToReplace = cloneTracks.SearchTracks.data.tracks.findIndex(
                  sTrack => sTrack.id === track.id
                );
                cloneTracks.SearchTracks.data.tracks[indexToReplace] = target;
                cloneTracks.SearchTracks.data.tracks[indexToReplace].__typename = 'SearchTrack';
              });
              store.writeQuery({
                query: SEARCH_TRACKS,
                data: cloneTracks,
                variables: searchVariables
              });
            }

            if (this.supports !== null) {
              this.supports.forEach(supportId => {
                if (store.data.data[`LibrarySupportDetails:${supportId}`]) {
                  const tracks = response.data.LibraryTracksUpdate.data.filter(track =>
                    track.supports.find(support => support.id === supportId)
                  );
                  /* eslint-disable */
                  store.data.data[`LibrarySupportDetails:${supportId}`].tracks = tracks;
                  /* eslint-enable */
                }
              });
            }
            if (this.artists !== null) {
              this.artists.forEach(artistId => {
                if (store.data.data[`LibraryArtistDetails:${artistId}`]) {
                  /* eslint-disable */
                  store.data.data[`LibraryArtistDetails:${artistId}`].tracks =
                    response.data.LibraryTracksUpdate.data;
                  /* eslint-enable */
                }
              });
            }
          },
          refetchQueries: [
            {
              query: WORKSPACE,
              variables: {
                workspaceId: this.currentWorkspace.id
              }
            }
          ]
        })
        .then(() => {
          this.pendingStatus = false;
          input.forEach((track, index) => {
            if (input[index].title) {
              const t = this.playlist[input[index].id];
              if (t) t.title = input[index].title;
            }
          });
          this.resetFormData();
          if (this.isMultipleTrackEdition) {
            bus.$emit('showAlert', {
              message: { key: 'modificationSuccess' },
              style: 'success',
              delay: 5000
            });
          }
          // Some component are listening to this event to display the save changes modal if the user didn't save his changes in the edit track panel
          this.$emit('autoSaveSuccess', true);
          bus.$emit('handleTracksEditMutationDone');
        })
        .catch(error => {
          this.pendingStatus = false;
          this.$emit('autoSaveSuccess', false);
          const errorKey = getGraphQlErrorCode(error);
          if (this.isMultipleTrackEdition) {
            bus.$emit('showAlert', {
              message: { key: errorKey },
              style: 'danger',
              delay: 5000,
              error: true
            });
          }
        });
    },
    redirectToGeneralSettings() {
      this.$router.push({ name: 'generalSettings', hash: '#hubs' });
    }
  }
};
</script>

<style lang="scss" scoped>
.details-panel-body {
  &-section {
    margin-bottom: 40px;
    &-title {
      margin: 0 0 16px 0;
      @include subtitle-1;
      &-comment {
        margin: 0 0 8px 0;
      }
    }
    &-description {
      @include body-1;
      color: $color_neutral_60;
      margin-bottom: 8px;
      &-link {
        text-decoration: underline;
        &:hover {
          cursor: pointer;
        }
      }
    }

    &-label {
      color: $color_neutral_60;
      &-rating {
        margin-top: 16px;
        margin-bottom: 16px;
      }
      &-tracknumber {
        margin: 16px 48px 16px 0;
        &-separator {
          margin: 12px 8px 0 8px;
        }
      }
      &-datepicker {
        width: 100%;
        max-width: 334px;
      }
    }

    &-- {
      margin: 0 0 8px 0;
    }

    &-inline {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-label {
        color: $color_neutral_60;
      }
    }

    &-dropdown {
      display: flex;
      margin: 0 0 8px 0;

      &-label {
        width: 140px;
        color: $color_neutral_60;
        margin: 14px 0 0 0;
      }

      &-- {
        width: 344px;
      }
    }
  }

  &-buttons {
    margin: 32px 0 0 0;
    display: flex;
    justify-content: flex-end;

    &-- {
      margin: 0 8px 0 0;
    }
  }
}
.section-tracknumber {
  justify-content: initial;
  align-items: unset;
  margin-bottom: 4px;
  .textfield {
    min-width: unset;
  }
}
</style>
