<template>
  <div>
    <div class="content">
      <h4 class="header">{{ $t('billingDetails.paymentMethod') }}</h4>
      <div v-if="!card" class="loader">
        <spinner-without-progress color="grey" :size="32" />
      </div>
      <div v-else>
        <div v-if="hasCard()">
          <div class="cardInformations">
            <img class="card" :src="getCardTypeImage" />
            <div v-if="card.last4">**** **** **** {{ card.last4 }}</div>
          </div>
          <div class="cardExpiracy">
            <span class="cardExpiracyLabel">{{ $t('billingDetails.expires') }}</span>
            <span class="cardExpiracyMonth">{{ formatExpiracyMonth() }}</span
            ><span>/</span>
            <span class="cardExpiracyYear">{{ card.exp_year }}</span>
          </div>
          <submit-button
            class="button"
            btnStyle="tertiary"
            :exception="true"
            size="medium"
            :label="$t('billingDetails.changePaymentMethod')"
            @submit="changePaymentMethod"
          />
        </div>
        <div v-else>
          <p>No payment method saved.</p>
          <submit-button
            class="button"
            btnStyle="tertiary"
            :exception="true"
            size="medium"
            :label="$t('billingDetails.addPaymentMethod')"
            @submit="changePaymentMethod"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ChangeStripeUserCard from '@/containers/modals/changeStripeUserCard';
import { bus } from '@/utils/bus';
import isEmpty from 'lodash.isempty';

import { isFreeTrial } from '@/utils/functions/workspace';

import visaLogo from './card-visa.svg';
import mastercardLogo from './card-mastercard.svg';
import unknownLogo from './card-unknown.svg';

const CARDS_IMAGE = {
  visa: visaLogo,
  mastercard: mastercardLogo
};

export default {
  props: {
    card: {
      type: Object,
      required: false,
      default: undefined
    },
    prepareForPayment: {
      type: Function,
      required: true
    },
    hasPaymentMethod: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    getCardTypeImage() {
      if (Object.prototype.hasOwnProperty.call(CARDS_IMAGE, this.card.brand)) {
        return CARDS_IMAGE[this.card.brand];
      }
      return unknownLogo;
    }
  },
  methods: {
    hasCard() {
      return !isEmpty(this.card);
    },
    formatExpiracyMonth() {
      if (this.card.exp_month < 10) {
        return `0${this.card.exp_month}`;
      }
      return this.card.exp_month;
    },
    changePaymentMethod() {
      if (isFreeTrial(this.currentWorkspace) && !this.hasPaymentMethod) {
        this.prepareForPayment();
      } else {
        bus.$emit('displayModal', {
          component: ChangeStripeUserCard,
          isVisible: true,
          size: 'medium',
          title: 'Change credit or debit card'
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  font-family: 'manrope';
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: $color_neutral_60;
  margin-bottom: 8px;
}

.card {
  width: 25px;
  height: 16px;
  margin-right: 10px;
}

.cardInformations {
  display: flex;
  font-size: 14px;
  font-weight: 600;
}

.cardExpiracy {
  display: inline;
  color: $color_neutral_60;
  font-size: 12px;
}

.cardExpiracyLabel {
  margin-right: 6px;
}

.button {
  position: absolute;
  bottom: 22px;
  left: 12px;
}

.loader {
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
