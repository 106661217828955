
<div class="tag" @mouseleave="setShowAddButton(false)">
  <div class="tag-category" @mouseover="setShowAddButton(true)">
    <div class="tag-category-left" @click="toggleExpand">
      <div
        class="tag-category-left-dot"
        :style="`background-color: ${tagCategory.background_color}`"
      />
      <div class="tag-category-left-label">{{ tagCategory.name }}</div>
      <div class="tag-category-left-count" v-if="count > 0">{{ `(${count})` }}</div>
      <i class="ri-arrow-down-s-fill" v-if="isExpanded && count > 0" />
      <i class="ri-arrow-right-s-fill" v-if="!isExpanded && count > 0" />
    </div>
    <div class="tag-category-right">
      <div v-show="showAddButton">
        <tag-category-dropdown
          :tagCategory="tagCategory"
          :checkedTags="checkedTags"
          withIconButton
          @setIsDropdownExpanded="setIsDropdownExpanded"
          @removeTag="removeTag"
          @addTag="addTag"
        />
      </div>
    </div>
  </div>
  <div class="tag-families" v-if="isExpanded && selectedFamilies.length > 0">
    <div
      class="tag-families-item"
      v-for="family in selectedFamilies"
      :key="`family-${family.id}`"
    >
      <div class="tag-families-item-name">{{ family.name }}</div>
      <div class="tag-families-item-tags">
        <div
          class="tag-families-item-tags-item"
          :style="`background-color: ${tagCategory.text_color}; color: ${tagCategory.background_color};`"
          v-for="tag in family.tags"
          :key="`tag-${tag.id}`"
        >
          <div v-if="aiTags.includes(tag.id)" class="tag-ai">
            <i class="ri-sparkling-2-fill" />
          </div>
          <span>{{ tag.name }}</span>
          <i
            class="ri-close-circle-fill"
            @click="removeTag(`${tagCategory.id}-${family.id}-${tag.id}`)"
          />
        </div>
      </div>
    </div>
  </div>
</div>
