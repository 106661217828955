<template>
  <div>
    <p class="planTitle">
      <i class="ri-music-2-fill planIcon" />
      <span>{{ plan.name }}</span>
    </p>
    <p class="planDescription">{{ $t('proPlan.description') }}</p>
    <div>
      <span class="planCurrency">{{ currencySymbol }}</span>
      <span class="planPrice">{{ planPrice() }}</span>
    </div>
    <div class="planRecurrence">
      <span>{{ $t('proPlan.byMonth') }}</span
      >&nbsp;
      <span v-if="isYearly">{{ $t('proPlan.annuallyBilled') }}</span>
    </div>
    <div class="planFeatures">
      <i class="ri-server-fill" />
      {{
        $t('planStorageInformation', {
          tracks: planFeature('libraryFilesMax'),
          storage: planFeature('storage')
        })
      }}
    </div>
    <slider
      labelMin="150 tracks (or 6 GB)"
      labelMax="400k tracks (or 10 TB)"
      :min="0"
      :max="getProVariantCount"
      :defaultValue="getActivePlanIndex"
      :step="1"
      :showIndicator="true"
      @sliderValueChange="setPriceIndex"
    />
    <div v-tooltip="{ content: getButtonTooltip }">
      <submit-button
        :primaryException="true"
        :label="getButtonLabel"
        :pending="isUpgradeProcessing"
        :disabled="isUpgradeDisabled"
        size="large"
        class="planButton"
        @submit="handleSubmit"
      />
    </div>
    <ul class="planBenefits">
      <li><i class="ri-check-line" />{{ getExtraMemberCost() }}</li>
      <li><i class="ri-check-line" />{{ $t('proPlan.benefitsSharing') }}</li>
      <li><i class="ri-check-line" />{{ $t('proPlan.benefitsNotifications') }}</li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash.get';

import {
  getCurrencySymbol,
  getPlanVariantFeatureFormated,
  getPlanVariantPrice
} from '@/utils/functions/plan';
import {
  isSubscriptionYearly,
  hasActiveSubscription,
  isSubscriptionCanceled,
  isFreeTrial
} from '@/utils/functions/workspace';

export default {
  props: {
    plan: {
      type: Object,
      required: true
    },
    currentPlanIndex: {
      type: Number,
      required: false,
      default: 0
    },
    selectedPlanIndex: {
      type: Number,
      required: false,
      default: 0
    },
    minimumPlanIndex: {
      type: Number,
      required: true
    },
    isYearly: {
      type: Boolean,
      required: false,
      default: true
    },
    isUpgradeProcessing: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'userCurrency']),
    currencySymbol() {
      return getCurrencySymbol(this.userCurrency);
    },
    getActivePlanIndex() {
      return parseInt(this.selectedPlanIndex, 10);
    },
    getProVariantCount() {
      return this.plan.variants.length - 1;
    },
    isChangeSubscriptionDisabled() {
      return isSubscriptionCanceled(this.currentWorkspace);
    },
    isUpgradeDisabled() {
      if (
        isSubscriptionCanceled(this.currentWorkspace) &&
        this.selectedPlanIndex !== this.currentPlanIndex
      ) {
        return true;
      }
      return (
        (this.selectedPlanIndex === this.currentPlanIndex &&
          hasActiveSubscription(this.currentWorkspace) === true &&
          isSubscriptionCanceled(this.currentWorkspace) === false &&
          this.isYearly === isSubscriptionYearly(this.currentWorkspace)) ||
        this.isUpgradeProcessing ||
        this.selectedPlanIndex < this.minimumPlanIndex
      );
    },
    getButtonTooltip() {
      if (
        isSubscriptionCanceled(this.currentWorkspace) &&
        this.selectedPlanIndex !== this.currentPlanIndex
      ) {
        return 'Reactivate current plan to unlock subscription options';
      }
      if (this.minimumPlanIndex && this.selectedPlanIndex < this.minimumPlanIndex) {
        return 'You are using more storage than what this plan allows';
      }
      return '';
    },
    getButtonLabel() {
      if (isFreeTrial(this.currentWorkspace)) {
        if (this.selectedPlanIndex < this.currentPlanIndex) {
          return 'Downgrade free trial';
        }
        if (
          this.selectedPlanIndex > this.currentPlanIndex ||
          this.isYearly !== isSubscriptionYearly(this.currentWorkspace)
        ) {
          return 'Upgrade free trial';
        }
        if (this.selectedPlanIndex === this.currentPlanIndex) {
          return 'Current Plan';
        }
      }
      if (hasActiveSubscription(this.currentWorkspace)) {
        if (this.selectedPlanIndex < this.currentPlanIndex) {
          return 'Downgrade';
        }
        if (
          this.selectedPlanIndex > this.currentPlanIndex ||
          this.isYearly !== isSubscriptionYearly(this.currentWorkspace)
        ) {
          return 'Upgrade';
        }
        if (this.selectedPlanIndex === this.currentPlanIndex) {
          if (isSubscriptionCanceled(this.currentWorkspace)) {
            return 'Reactivate';
          }
          return 'Current Plan';
        }
      }
      if (!this.currentWorkspace.free_trial_used) {
        return this.$t('TryItFree14Days');
      }
      return 'Upgrade';
    }
  },
  methods: {
    setPriceIndex(value) {
      this.$emit('setPriceSelection', parseInt(value, 10));
    },
    planFeature(featureKey) {
      return getPlanVariantFeatureFormated(this.plan.variants[this.selectedPlanIndex], featureKey);
    },
    planPrice() {
      return getPlanVariantPrice(
        this.plan.variants[this.selectedPlanIndex],
        this.userCurrency,
        this.isYearly
      );
    },
    handleSubmit() {
      this.$store.commit('setSelectedPlan', {
        plan: this.plan.variants[this.selectedPlanIndex],
        planIndex: this.selectedPlanIndex,
        isYearly: this.isYearly
      });
      this.$emit('submit');
    },
    getExtraMemberCost() {
      const proPlanInUserCurrency = get(this.plan, 'cost_per_extra_member', []).find(
        item => item.currency === this.userCurrency
      );
      return this.$t('proPlan.benefitsMembers', {
        price:
          (this.isYearly
            ? proPlanInUserCurrency.yearly_price / 12
            : proPlanInUserCurrency.monthly_price) / 100,
        currency: this.currencySymbol
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.plan {
  border-radius: 10px;
  padding: 40px;
  max-width: 400px;
  background: white;
  flex: 1 1 0em;

  .planPrice {
    font-variant-numeric: tabular-nums;
    font-size: 64px;
    line-height: 84px;
  }
  .planRecurrence {
    margin-bottom: 24px;
    font-size: 14px;
    height: 28px;
  }
  .planFeatures {
    font-variant-numeric: tabular-nums;
    margin-bottom: 16px;
    font-size: 14px;
    i {
      font-size: 20px;
      position: relative;
      top: 4px;
      fill: $color_neutral_0;
    }
  }
}

.planTitle {
  @include heading-3;
  color: $color_neutral_0;
  font-size: 24px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  i {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    font-size: 22px;
    margin-right: 8px;
  }
}

.planDescription {
  color: $color_neutral_0;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 20px;
}

.planCurrency {
  font-size: 28px;
}

.planBenefits {
  font-size: 14px;
  line-height: 21px;
  margin-top: 40px;
  li {
    padding-left: 32px;
    position: relative;
    padding-bottom: 8px;
  }
  i {
    position: absolute;
    left: 0;
    font-size: 22px;
    margin-right: 10px;
    top: 3px;
  }
}

.planIcon {
  background: #f0e6ff;
  color: #2f43de;
  transform: rotate(-10deg);
}

.planButton {
  margin-top: 50px;
}
</style>
