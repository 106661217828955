<template>
  <div class="dropdown-container">
    <div class="dropdown-container-head">
      <div v-if="label" class="dropdown-container-head-label">{{ label }}</div>
    </div>
    <div :class="dropdownStyle" v-click-outside="{ hide }" @click="toggleExpand">
      <div class="dropdown-button" :class="disabled && 'dropdown-disabled'">
        <span>{{ selectedRole.label }}</span>
        <i class="dropdown-button-arrow ri-arrow-down-s-line" />
      </div>
      <div
        class="dropdown-expanded"
        :class="!isExpanded && 'dropdown-close'"
        :style="`max-height: ${height}px; top: ${top}px;`"
      >
        <div class="dropdown-expanded-roles" @click="hide" v-if="isExpanded">
          <div
            class="dropdown-expanded-roles-item"
            v-for="(role, index) in roles"
            :key="`role-${index}`"
            @click="setRole(role, userId)"
          >
            <div class="dropdown-expanded-roles-item-label">
              <span>{{ role.label }}</span>
              <i
                v-if="selectedRole.value === role.value"
                class="dropdown-expanded-roles-item-label-icon ri-check-line"
              />
            </div>
            <div class="dropdown-expanded-roles-item-description">{{ role.description }}</div>
          </div>
          <div v-if="options" class="dropdown-expanded-options" @click="hide">
            <div
              class="dropdown-expanded-options-item"
              v-for="(option, index) in options"
              :key="`option-${index}`"
              @click="option.action(userId)"
            >
              <div class="dropdown-expanded-options-item-label">{{ option.label }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { clickOutside } from '../../utils/directives';

export default {
  props: {
    roles: {
      type: Array,
      required: true
    },
    userRole: {
      type: Object,
      required: true
    },
    userId: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: false
    },
    withBorder: {
      type: Boolean,
      required: false,
      default: false
    },
    large: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      require: false
    }
  },
  data() {
    return {
      isExpanded: false,
      selectedRole: this.userRole
    };
  },
  computed: {
    height() {
      return this.isExpanded ? 500 : 0;
    },
    top() {
      return this.large ? 52 : 44;
    },
    dropdownStyle() {
      let str = 'dropdown';
      if (this.withBorder) str += ' withBorder';
      if (this.withBorder && this.isExpanded) str += ' selecredBorder';
      if (this.large) str += ' large';
      return str;
    }
  },
  directives: {
    clickOutside
  },
  watch: {
    userRole() {
      this.selectedRole = this.userRole;
    }
  },
  methods: {
    toggleExpand() {
      if (!this.disabled) this.isExpanded = !this.isExpanded;
    },
    hide() {
      this.isExpanded = false;
    },
    setRole(role, userId) {
      this.selectedRole = role.value === -1 ? this.selectedRole : role;
      this.$emit('setRole', { role, userId });
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown-container {
  &-head {
    &-label {
      margin: 0 0 8px 0;
      text-align: left;
      @include label;
    }
  }
}

.dropdown {
  position: relative;
  @include body-1;
  color: $color_neutral_100;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &-close {
    opacity: 0;
  }

  &-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;
    &-arrow {
      font-size: 20px;
      margin: 0 0 0 3px;
    }
  }

  &-disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &-expanded {
    position: absolute;
    right: 0;
    width: 230px;
    height: auto;
    z-index: 1000;
    background-color: $color_neutral_0;
    box-sizing: border-box;
    border: 1px solid $color_neutral_30;
    border-radius: 4px;
    @include shadow-down-03;
    overflow: hidden;

    &-roles {
      border-bottom: 1px dotted $color_neutral_30;

      &-item {
        padding: 16px;
        border-bottom: 1px dotted $color_neutral_30;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        &:hover {
          background-color: $color_neutral_10;
        }
        &:last-child {
          border-bottom: none;
        }

        &-label {
          font-size: 14px;
          display: flex;
          align-items: center;

          &-icon {
            margin: 0 0 0 4px;
          }
        }

        &-description {
          @include body-2;
          color: $color_neutral_60;
        }
      }
    }

    &-options {
      &-item {
        padding: 16px;
        border-bottom: 1px dotted $color_neutral_30;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        &:hover {
          background-color: $color_neutral_10;
        }
        &:last-child {
          border-bottom: none;
          padding: 16px;
        }

        &-label {
          color: $color_danger_100;
          font-size: 14px;
        }
      }
    }
  }
}

.withBorder {
  background-color: $color_neutral_0;
  border: 1px solid $color_neutral_40;
  box-sizing: border-box;
  border-radius: 4px;
  width: 140px;
  padding: 0 16px;

  .dropdown-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .dropdown-button-arrow {
      font-size: 24px;
      color: $color_neutral_60;
    }
  }
}
.withBorder.selecredBorder {
  border: 1px solid $color_neutral_100;
}

.large {
  height: 48px;
}
</style>
