<template>
  <div>
    <slot
      :projectList="projectList"
      :showProjectObserver="showObserver"
      :noProjectSearchResult="noSearchResult"
      :loadingProjects="!ProjectList"
      :fetchingMoreProjects="fetchingMoreProjects"
      :fetchMoreProjects="fetchMoreProjects"
      :searchProject="searchProject"
      :listProjects="listProjects"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PROJECT_LIST, PROJECT_SEARCH } from '@/graphql/queries/project';
import { PAGINATOR_LIMIT } from '@/utils/constants';

export default {
  data() {
    return {
      page: 1,
      limit: PAGINATOR_LIMIT,
      fetchingMoreProjects: false,
      searchResult: null,
      searchModel: '',
      waitForSearch: false
    };
  },
  apollo: {
    ProjectList: {
      query: PROJECT_LIST,
      variables() {
        return {
          workspaceId: this.currentWorkspace.id,
          status: 'active',
          page: 1,
          limit: this.limit
        };
      },
      watchLoading(loading) {
        this.fetchingMoreProjects = loading;
      },
      skip() {
        return !this.isProjectsLoaded;
      }
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'isProjectsLoaded']),
    projectList() {
      if (this.searchResult && this.searchModel.length > 1) {
        return this.searchResult.map(project => ({
          id: project.id,
          label: project.name
        }));
      }
      return this.ProjectList
        ? this.ProjectList.data.map(project => ({
            id: project.id,
            label: project.name
          }))
        : [];
    },
    showObserver() {
      return (
        this.ProjectList && this.page < this.ProjectList.last_page && !this.fetchingMoreProjects
      );
    },
    noSearchResult() {
      return this.searchResult && this.searchResult.length === 0 && this.searchModel.length > 1;
    }
  },
  methods: {
    listProjects() {
      if (!this.isProjectsLoaded) {
        this.$store.commit('changeIsProjectsLoaded', true);
      }
      this.page = this.ProjectList ? Math.ceil(this.ProjectList.data.length / this.limit) : 1;
    },
    searchProject(name) {
      // Reset the search results once the field is emptied
      if (name.length === 0) {
        this.searchResult = null;
      }
      this.searchModel = name;
      if (this.searchModel.length > 1 && !this.waitForSearch) {
        this.waitForSearch = true;
        this.$apollo
          .mutate({
            mutation: PROJECT_SEARCH,
            variables: {
              workspaceId: this.currentWorkspace.id,
              name,
              limit: 10
            }
          })
          .then(response => {
            this.searchResult = response.data.ProjectsSearch;
            this.waitForSearch = false;
          });
      }
    },
    async fetchMoreProjects() {
      this.page += 1;
      try {
        await this.$apollo.queries.ProjectList.fetchMore({
          variables: {
            workspaceId: this.currentWorkspace.id,
            status: 'active',
            page: this.page,
            limit: this.limit
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newProjects = fetchMoreResult.ProjectList.data;
            if (fetchMoreResult.ProjectList.data.length === 0) {
              return {
                ProjectList: {
                  ...previousResult.ProjectList,
                  data: [...previousResult.ProjectList.data],
                  __typename: 'ProjectsPaginator' // eslint-disable-line
                }
              };
            }
            return {
              ProjectList: {
                ...previousResult.ProjectList,
                data: [...previousResult.ProjectList.data, ...newProjects],
                __typename: 'ProjectsPaginator' // eslint-disable-line
              }
            };
          }
        });
      } catch {} // eslint-disable-line
    }
  }
};
</script>
