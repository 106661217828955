
<div class="delete-workspace-modal">
  <div class="delete-workspace-modal-body">
    <div class="delete-workspace-modal-body-content">
      {{ $t('deleteWorkspace.description') }}
    </div>
    <div class="delete-workspace-modal-body-input">
      <text-field
        inputType="password"
        withVisibility
        :label="$t('enterPassword')"
        :fieldModel.sync="password"
        focusOnLoad
        @keyup.enter.native="handleKeyUp"
      />
      <div v-if="serverErrorMessage" class="delete-workspace-modal-body-input-invalid">
        {{ serverErrorMessage }}
      </div>
    </div>
    <div class="delete-workspace-modal-body-link" @click="sendForgotPasswordMail">
      {{ $t('modalCancellation.forgotPassword') }}
    </div>
  </div>
  <div class="delete-workspace-modal-buttons">
    <div class="delete-workspace-modal-buttons--">
      <submit-button
        btnStyle="tertiary"
        size="medium"
        :label="$t('cancel')"
        @submit="closeModal"
      />
    </div>
    <div class="delete-workspace-modal-buttons--">
      <submit-button
        btnStyle="primary"
        size="medium"
        :label="$t('deleteWorkspace.buttonLabel')"
        danger
        @submit="deleteWorkspace"
        :disabled="disabledDeleteButton"
        :pending="removePending"
      />
    </div>
  </div>
</div>
