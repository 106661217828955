
<div :class="['container', weightNormal && 'weight-normal']">
  <div v-if="artwork && artwork.length > 0">
    <artwork
      :imgSrc="artwork"
      :trackId="rowId"
      :isHoverId="isHoverId"
      :playingTrack="playingTrack"
      @setPlayingTrack="setPlayingTrack"
    />
  </div>
  <div class="text" :class="[{ text: true, 'text-playing': isPlayingTrack }]" :title="text">
    {{ text }}
    <div v-if="subtitle" class="subtitle">{{ subtitle }}</div>
  </div>
  <div class="actions" v-if="actions.length > 0 && (isHover || isExpanded)">
    <div v-for="(action, index) in actions" :key="`${index}-taib-container`">
      <more-dropdown
        v-show="!(hideActionCondition && action.hide)"
        v-if="action.options"
        v-tooltip="action.tooltip"
        :itemId="rowId"
        :items="action.options"
        :icon="action.icon"
        :stopbubble="true"
        @setMoreDropdownState="setMoreDropdownState(index, ...arguments)"
      />
      <icon-button
        v-show="!(hideActionCondition && action.hide)"
        v-else
        class="btn"
        :key="`${index}-taib`"
        btnStyle="tertiary"
        size="small"
        :itemId="rowId"
        :customEvent="action.emit"
        :icon="action.icon"
        v-tooltip="action.tooltip"
        @[action.emit]="handleEmit"
        :stopbubble="true"
      />
    </div>
  </div>
</div>
