<template>
  <div class="editable-text" @click="switchToEditMode">
    <input
      :class="{
        'editable-text-input': true,
        'editable-text-small': size === 'small',
        'editable-text-large': size === 'large',
        'editable-text-error': error,
        'editable-text-edition': editMode
      }"
      ref="input"
      type="text"
      :style="`width: ${inputWidth}px;`"
      :value="value"
      :disabled="!editMode"
      @input="$emit('update:fieldModel', $event.target.value)"
      @blur="validate"
      @keyup.enter="handleEnter"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
      required: false
    },
    fieldModel: {
      type: String,
      default: null,
      required: false
    },
    error: {
      type: Boolean,
      required: false
    },
    size: {
      type: String,
      default: 'small',
      required: false
    }
  },
  data() {
    return {
      editMode: false,
      inputWidth: undefined
    };
  },
  watch: {
    fieldModel() {
      this.setInputWidth();
    }
  },
  mounted() {
    this.setInputWidth(this.value);
  },
  methods: {
    handleEnter() {
      this.$refs.input.blur();
    },
    validate() {
      if (!this.error) {
        this.editMode = false;
        this.$emit('validate');
      }
    },
    switchToEditMode() {
      if (this.editMode) {
        return;
      }
      this.editMode = true;
      this.$nextTick(() => {
        this.handleFocus();
      });
    },
    handleFocus() {
      const inputValueLentgh = this.$refs.input.value.length;
      this.$refs.input.focus();
      this.$refs.input.setSelectionRange(inputValueLentgh, inputValueLentgh);
    },
    setInputWidth(defaultValue) {
      const c = document.createElement('canvas');
      const ctx = c.getContext('2d');
      ctx.font = this.size === 'small' ? '600 14px manrope' : '600 24px manrope';
      const txt = this.fieldModel || defaultValue;
      this.inputWidth = ctx.measureText(txt).width + 24;
      // 8 is for padding of editable-text
      // 16 is for extra padding of input
    }
  }
};
</script>

<style lang="scss" scoped>
.editable-text {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  transition: background-color 0.3s linear, border 0.3s linear;
  border-radius: 4px;

  &:hover .editable-text-input {
    border: 1px solid $color_neutral_100;
    border-radius: 4px;
    cursor: pointer;
  }

  &-input {
    padding: 6px 8px;
    font-family: inherit;
    cursor: pointer;
    font-weight: 600;
    background: none;
    border: 1px solid transparent;
    outline: none;
    filter: none;
    box-sizing: border-box;
    color: $color_neutral_100;
    -webkit-text-fill-color: $color_neutral_100;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &-error {
    color: $color_danger_100;
  }

  &-large {
    font-size: 24px;
    height: 40px;
  }

  &-small {
    font-size: 14px;
    height: 30px;
  }

  &-input:hover {
    background: $color_neutral_30;
  }

  &-edition {
    background: $color_neutral_0 !important;
    border: 1px solid $color_neutral_100;
  }
  input[disabled] {
    pointer-events: none;
  }
}

.editable-text-border.editable-text-error {
  border: 1px solid $color_danger_100;
  border-radius: 2px;
}
</style>
