<template>
  <div class="projectPicker-container">
    <div class="header">
      <i class="ri-search-line" />
      <input
        ref="search"
        class="search"
        type="text"
        :placeholder="labels.searchPlaceholder"
        @input="$emit('updateSearchValue', $event.target.value)"
      />
    </div>

    <div class="projectList">
      <!-- LOADING -->
      <div v-if="loadingProjects" class="projectList-centered">
        <spinner color="grey" />
      </div>
      <div v-else class="projectList-container">
        <!-- SEARCH NO RESULTS -->
        <div v-if="projects.length === 0 && noSearchResult" class="projectList-centered">
          {{ labels.noResultLabel }}
        </div>

        <!-- NO PROJECTS & NO SEARCH -->
        <div v-if="projects.length === 0 && !noSearchResult" class="projectList-centered">
          <div class="projectList-emptyLabel">{{ emptyPlaceholder.text }}</div>
          <submit-button
            size="small"
            :label="emptyPlaceholder.buttonLabel"
            :iconClass="emptyPlaceholder.buttonIcon"
            @submit="handleClickCreate"
          />
        </div>

        <!-- LISTE PROJECT -->
        <div v-if="projects.length > 0">
          <div
            v-for="project in projects"
            :key="project.id"
            class="projectItem"
            :class="selectedProjectId === project.id && 'projectItem-selected'"
            @click.stop="handleProjectItemClick(project.id)"
            @dblclick.stop="handleClickAdd"
          >
            <i
              class="ri-folder-fill projectItem-icon"
              :class="selectedProjectId === project.id && 'projectItem-iconSelected'"
            />
            <div class="projectItem-label">{{ project.label }}</div>
          </div>
          <div v-if="fetchingMoreProjects" class="loader">
            <skeleton />
          </div>
          <observer v-if="showObserver" :height="32" bottom @intersect="loadMore" />
        </div>
      </div>
    </div>

    <div class="footer">
      <submit-button
        btnStyle="secondary"
        size="small"
        :iconClass="`ri-folder-add-line`"
        :label="$t('create')"
        @submit="handleClickCreate"
      />
      <div class="footer-right">
        <submit-button
          btnStyle="secondary"
          size="small"
          :label="labels.cancelButtonLabel"
          @click.native.stop="handleClickCancel"
        />
        <submit-button
          size="small"
          :disabled="!selectedProjectId"
          :label="labels.addButtonLabel"
          @click.native.stop="handleClickAdd"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SubmitButton from '@/components/buttons/submitButton';
import Skeleton from '@/components/skeleton';
import Spinner from '@/components/loaders/spinnerWithoutProgress';
import Observer from '@/containers/observer';

export default {
  components: {
    Skeleton,
    Spinner,
    SubmitButton,
    Observer
  },
  data() {
    this.$emit('listProjects');
    return {
      selectedProjectId: undefined
    };
  },
  props: {
    projects: {
      type: Array,
      required: false,
      default: () => []
    },
    labels: {
      searchPlaceholder: {
        type: String,
        required: false,
        default: 'Search projects...'
      },
      addButtonLabel: {
        type: String,
        required: false,
        default: 'Add'
      },
      cancelButtonLabel: {
        type: String,
        required: false,
        default: 'Cancel'
      },
      noResultLabel: {
        type: String,
        required: false,
        default: 'No results'
      }
    },
    defaultSelectedProjectId: {
      type: String,
      required: false
    },
    emptyPlaceholder: {
      buttonLabel: {
        type: String,
        required: false
      },
      buttonIcon: {
        type: String,
        required: false
      },
      text: {
        type: String,
        required: false
      },
      default: () => ({
        buttonLabel: 'New project',
        buttonIcon: 'ri-add-fill',
        text: 'Create a new project to organize and share your work.'
      })
    },
    showObserver: {
      type: Boolean,
      required: false
    },
    trackId: {
      type: String,
      required: false
    },
    noSearchResult: {
      type: Boolean,
      required: false,
      default: false
    },
    fetchingMoreProjects: {
      type: Boolean,
      required: false,
      default: false
    },
    loadingProjects: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    defaultSelectedProjectId() {
      this.selectedProjectId = this.defaultSelectedProjectId;
      this.handleClickAdd();
    }
  },
  methods: {
    handleProjectItemClick(projectId) {
      this.selectedProjectId = projectId;
      this.$emit('selectItemFromDropdown', this.trackId);
    },
    handleClickCreate() {
      this.$emit('createProject');
      this.$emit('selectItemFromDropdown', this.trackId);
    },
    handleClickCancel() {
      this.$emit('closePanel');
    },
    handleClickAdd() {
      this.$emit('addTracksToProject', {
        projectId: this.selectedProjectId,
        trackId: this.trackId
      });
      this.$emit('closePanel');
    },
    loadMore() {
      this.$emit('fetchMoreProjects');
    }
  }
};
</script>

<style lang="scss" scoped>
.projectPicker-container {
  position: absolute;
  width: 300px;
  background-color: $color_neutral_0;
  @include shadow-down-03;
  overflow-y: auto;
  overscroll-behavior: contain;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid $color_neutral_30;
  z-index: 5;
}

.header {
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-bottom: 1px solid $color_neutral_30;
}

.search {
  margin: 0 8px;
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  color: $color_neutral_100;
  font-size: 14px;
  outline: none;
  border: none;
  filter: none;
  font-weight: 400;
}

.projectList {
  height: 224px;
  overflow-y: scroll;
  position: relative;
  font-size: 14px;
  &-container {
    height: 100%;
  }
}

.projectList-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
}

.projectList-emptyLabel {
  width: 200px;
  margin-bottom: 16px;
}

.projectItem {
  height: 32px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 0 16px;
  align-items: center;
  &:hover {
    background-color: $color_neutral_10;
  }
}

.projectItem-selected {
  background-color: $color_primary_100;
  color: $color_neutral_0;
  &:hover {
    background-color: $color_primary_100;
  }
}

.projectItem-icon {
  color: $color_neutral_30;
  font-size: 18px;
  padding: 0 8px 0 0;
}

.projectItem-iconSelected {
  color: $color_neutral_0;
}

.projectItem-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal;
}

.loader {
  padding: 12px 0;
}

.footer {
  display: flex;
  height: 48px;
  @include shadow-up-01;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.footer-right {
  display: flex;

  & > :first-child {
    margin-right: 8px;
  }
}
</style>
