<template>
  <!-- the key here is to avoid any context issue when creating a new project from a project page -->
  <div
    v-if="ProjectDetails"
    class="project"
    :class="{ 'project-with-only-att': projectWithOnlyAttachment }"
    :key="$route.params.id"
  >
    <div class="project-browse" v-if="browse">
      <div class="project-browse-header">
        <div class="project-browse-header-search">
          <search
            :searchContext="librarySearchContext"
            margin="margin: 8px -48px 0 -130px; padding: 8px 130px;"
          />
        </div>
        <i @click="closeBrowser" class="project-browse-header-close ri-close-line" />
      </div>
      <div class="project-browse-content">
        <div class="project-browse-content-tracks">
          <div class="project-browse-content-tracks-title">{{ $t('selectTracks') }}</div>
          <div class="project-browse-content-tracks-list">
            <browse :uncheckTracks="uncheckTracks" @setSelectedTracks="setSelectedTracks" />
          </div>
        </div>
        <div class="project-browse-content-footer">
          <div class="project-browse-content-footer--">{{ selectedTrackLabel }}</div>
          <submit-button
            size="medium"
            :label="$t('addTracksToProject')"
            iconClass="ri-add-fill"
            :disabled="libraryFileIds.length < 1"
            @submit="addTracksToProject()"
          />
        </div>
      </div>
    </div>
    <div
      v-if="(projectCover || temporaryImgUrl) && !hideProjectCover"
      class="project-cover"
      @mouseenter="setIsCoverHover(true)"
      @mouseleave="setIsCoverHover(false)"
    >
      <div v-if="temporaryImgUrl" class="upload-pending">
        <div class="upload-pending-loader">
          <spinner-without-progress class="spinner" color="white" :size="12" />
          {{ `${uploadPercent}%` }}
        </div>
      </div>
      <img
        ref="projectCoverPicture"
        class="project-cover-img"
        :style="coverStyle"
        :src="temporaryImgUrl ? temporaryImgUrl : projectCover.url"
        alt="project cover"
      />
      <div
        v-if="(displayCoverBtn || isDropdownExpanded) && !temporaryImgUrl && !coverRepositionMode"
        class="project-cover-btns"
      >
        <btn-dropdown
          class="project-cover-btns-dropdown"
          :maxCoverSizeConfig="maxCoverSizeConfig"
          @setIsDropdownExpanded="setIsDropdownExpanded"
          @setIsCoverHover="setIsCoverHover(false)"
          @deleteProjectCover="deleteProjectCover"
          @handleFileSelect="handleFileSelect"
          :btnProps="{
            style: 'secondary',
            size: 'small',
            label: 'Change cover'
          }"
        />
        <submit-button
          class="project-cover-btns--"
          btnStyle="secondary"
          size="small"
          :label="$t('reposition')"
          @click.native.stop
          @submit="toggleReposition(true)"
        />
      </div>
      <div v-if="coverRepositionMode">
        <div
          class="project-cover-reposition"
          @mousedown="handleCoverMouseDown"
          v-click-outside="{ hide: handleClickOutsideReposition }"
        />
        <div class="project-cover-reposition-txt">
          {{ $t('dragImgToReposition') }}
        </div>
        <div class="project-cover-btns">
          <submit-button
            class="project-cover-btns-- btn-save-position"
            btnStyle="secondary"
            size="small"
            :label="$t('save')"
            @click.native.stop
            @submit="saveCoverPosition"
          />
          <submit-button
            class="project-cover-btns--"
            btnStyle="secondary"
            size="small"
            :label="$t('cancel')"
            @submit="handleCancelReposition"
          />
        </div>
      </div>
    </div>
    <div class="project-content" v-if="!browse">
      <tabs :tabs="tabs" :activeTab="activeTab" class="project-content-tabs" />
      <router-view
        class="project-content--"
        @closeShareModal="closeShareModal"
        @handleProjectUpdateMutation="handleProjectUpdateMutation"
        @addTracks="openBrowser"
        @openShareModal="openShareModal"
        @uploadProjectCover="uploadProjectCover"
        @setOnlyAttachmentStatus="setOnlyAttachmentStatus"
        :projectName="projectName"
        :entityIds="[this.$route.params.id]"
        :entityName="projectName"
      ></router-view>
    </div>
  </div>
  <div v-else class="loader">
    <div v-if="projectUnavailable" class="errorMessage">
      {{ $t('errorProjectUnauthorized') }}
      <submit-button label="Back to Projects" @submit="goToProjectListRoute" />
    </div>
    <spinner-without-progress v-else color="grey" :size="32" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import get from 'lodash.get';
import * as tus from 'tus-js-client';

import { setWorkspaceIdInStorage } from '@/utils/storage';
import { WORKSPACE } from '@/graphql/queries/user';
import { PAGINATOR_LIMIT, SEARCH_CONTEXT } from '@/utils/constants';
import {
  PROJECT_DETAILS_INFO,
  PROJECT_LIST,
  PROJECTS_INFORMATION,
  PROJECT_DETAILS_TRACKS
} from '@/graphql/queries/project';
import {
  ADD_TRACK_TO_PROJECT,
  PROJECT_UPDATE,
  PROJECT_DUPLICATE,
  PROJECT_DELETE,
  PROJECT_COVER_UPLOAD,
  PROJECT_COVER_DELETE,
  PROJECT_COVER_CHANGE_POSITION,
  PROJECT_INITIALIZE_PROJECT_COVER_UPLOAD
} from '@/graphql/mutations/project';
import { bus } from '@/utils/bus';
import { formatNumber, getGraphQlErrorCode, generateDocumentTitle } from '@/utils/functions/global';
import { convertFileToJpeg, getMaxCoverSize } from '@/utils/functions/image';
import { trackEvent } from '@/utils/functions/analytics';
import { get as getRoute } from '@/router/routes';
import UploadFilesModal from '@/containers/modals/uploadFiles';
import ShareModal from '@/containers/modals/share';
import DeleteProjectModal from '@/containers/modals/deleteProject';
import Search from '@/containers/search';
import Browse from '@/views/main/projects/projectDetails/browse';
import BtnDropdown from '@/containers/dropdowns/coverPicHelper';
import { clickOutside } from '@/utils/directives';

export default {
  components: {
    Search,
    Browse,
    BtnDropdown
  },
  directives: {
    clickOutside
  },
  data() {
    return {
      browse: false,
      limit: PAGINATOR_LIMIT,
      projectName: '',
      projectDescription: '',
      canLeaveRoute: true,
      nextRoute: null,
      libraryFileIds: [],
      uncheckTracks: false,
      projectUnavailable: false,
      trackPaginateLimit: 20,
      projectCover: null,
      displayCoverBtn: false,
      isDropdownExpanded: false,
      fileSelectorId: 0,
      abortHandler: undefined,
      uploadPercent: 0,
      temporaryImgUrl: null,
      hideProjectCover: false,
      maxCoverSizeConfig: getMaxCoverSize(this.$config.uploads.project.cover.max_files),
      defaultCoverPosition: 50,
      coverPosition: 50,
      coverRepositionMode: false,
      listenCoverMove: false,
      blockClickOutsideCover: false,
      projectWithOnlyAttachment: false
    };
  },
  apollo: {
    Workspace: {
      query: WORKSPACE,
      variables() {
        return {
          workspaceId: this.currentWorkspace.id
        };
      }
    },
    ProjectDetails: {
      query: PROJECT_DETAILS_INFO,
      variables() {
        return {
          projectId: this.$route.params.id
        };
      },
      result() {
        if (this.ProjectDetails) {
          // BRID-2657 - This switches the workspace to match the opened project
          if (this.ProjectDetails.workspace_id !== this.currentWorkspace.id) {
            const newWorkspaceId = this.ProjectDetails.workspace_id;
            setWorkspaceIdInStorage(newWorkspaceId);
            window.location.reload();
            return;
          }
          if (this.ProjectDetails.cover) {
            const coverPos = this.ProjectDetails.cover.position;
            this.projectCover = this.ProjectDetails.cover;
            this.coverPosition = coverPos !== null ? coverPos : 50;
            this.defaultCoverPosition = this.coverPosition;
          } else {
            this.projectCover = null;
          }
          this.temporaryImgUrl = null;
          this.uploadPercent = 0;
          this.hideProjectCover = false;
          this.projectName = this.ProjectDetails.name;
          this.projectDescription = this.ProjectDetails.description;
          document.title = generateDocumentTitle([this.projectName]);
        }
      },
      error() {
        this.projectUnavailable = true;
      }
    }
  },
  validations: {
    projectName: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(255)
    }
  },
  created() {
    if (this.$route.path.includes('details/browse')) {
      this.browse = true;
    }
    bus.$on('handleDuplicateProjectFromHeader', () => {
      this.handleDuplicateProjectMutation();
    });
    bus.$on('openDeleteProjectModal', () => {
      this.openDeleteProjectModal();
    });
  },
  mounted() {
    bus.$on('deleteProject', () => {
      this.handleDeleteProjectMutation();
    });
  },
  beforeDestroy() {
    bus.$off('handleDuplicateProjectFromHeader');
    bus.$off('openDeleteProjectModal');
    bus.$off('deleteProject');
  },
  computed: {
    ...mapGetters([
      'currentWorkspace',
      'playingTrack',
      'newUpload',
      'searchLibraryQueryParameters'
    ]),
    coverStyle() {
      return { objectPosition: `center ${this.coverPosition}%` };
    },
    activeTab() {
      const active = this.tabs.find(tab => tab.name === this.$route.name);
      if (active.title === 'Sharing links') {
        document.title = generateDocumentTitle([this.projectName, 'Links']);
      } else if (active.title === 'Activity') {
        document.title = generateDocumentTitle([this.projectName, 'Activity']);
      } else {
        document.title = generateDocumentTitle([this.projectName]);
      }
      return this.tabs.indexOf(active);
    },
    projectNameError() {
      return (
        !this.$v.projectName.required ||
        !this.$v.projectName.minLength ||
        !this.$v.projectName.maxLength
      );
    },
    tabs() {
      const isShared = this.$route.path.includes('shared');
      return [
        {
          title: this.$t('project'),
          link: isShared
            ? getRoute('sharedProjectDetails', { id: this.$route.params.id })
            : getRoute('projectDetails', { id: this.$route.params.id }),
          name: 'projectDetails'
        },
        {
          title: this.$t('sharingLinks'),
          link: isShared
            ? getRoute('sharedProjectSharings', { id: this.$route.params.id })
            : getRoute('projectSharings', { id: this.$route.params.id }),
          name: 'projectSharings'
        },
        {
          title: this.$t('sharingActivity'),
          link: isShared
            ? getRoute('sharedProjectActivity', { id: this.$route.params.id })
            : getRoute('projectActivity', { id: this.$route.params.id }),
          name: 'projectActivity'
        }
      ];
    },
    deleteProjectModal() {
      let { components } = this.$options;
      components = {
        ...components,
        DeleteProjectModal
      };
      return {
        title: this.$t('deleteProjectTitle'),
        size: 'medium',
        isVisible: false,
        component: components.DeleteProjectModal,
        onSubmit: this.handleDeleteProjectMutation,
        props: [{ name: 'projectName', value: this.deleteItem && this.deleteItem.projectName }]
      };
    },
    selectedTrackLabel() {
      const formatedNumber = formatNumber(this.libraryFileIds.length);
      return `${formatedNumber} ${this.$tc('trackSelected', this.libraryFileIds.length)}`;
    },
    shareModal() {
      let { components } = this.$options;
      components = {
        ...components,
        ShareModal
      };
      return {
        title: null,
        size: 'medium',
        isVisible: true,
        component: components.ShareModal,
        onSubmit: this.closeShareModal,
        props: [
          { name: 'entityName', value: this.projectName },
          { name: 'entityIds', value: [this.$route.params.id] },
          { name: 'entityType', value: 'project' }
        ]
      };
    },
    uploadFilesModal() {
      let { components } = this.$options;
      components = {
        ...components,
        UploadFilesModal
      };
      return {
        title: this.$t('uploadTracks'),
        size: 'medium',
        isVisible: false,
        component: components.UploadFilesModal,
        onSubmit: this.prepareUploadForFiles
      };
    },
    librarySearchContext() {
      return SEARCH_CONTEXT.LIBRARY_SEARCH_CONTEXT;
    },
    fileSelector() {
      const id = this.fileSelectorId;
      const fileSelector = document.createElement('input');
      fileSelector.setAttribute('id', `file-selector-${id}`);
      fileSelector.setAttribute('type', 'file');
      fileSelector.setAttribute('accept', 'image/*');
      fileSelector.addEventListener('change', this.handleFileChange);
      return fileSelector;
    }
  },
  beforeRouteLeave(to, from, next) {
    this.nextRoute = next;
    if (this.canLeaveRoute) next();
  },
  watch: {
    $route() {
      if (!this.$route.path.includes('details/browse')) {
        this.browse = false;
      } else {
        this.browse = true;
      }
    },
    canLeaveRoute() {
      if (this.nextRoute) this.nextRoute();
    },
    showShareModal() {
      bus.$emit('displayModal', {
        ...this.shareModal,
        isVisible: true,
        title: this.$t('shareProjectName', { name: this.shareModal.props[0].value })
      });
    }
  },
  methods: {
    openBrowser() {
      const isShared = this.$route.path.includes('shared');
      if (this.currentWorkspace.total_tracks > 0) {
        this.browse = true;
        this.uncheckTracks = false;
        if (this.searchLibraryQueryParameters) {
          if (isShared) {
            this.$router.push(
              getRoute('sharedProjectDetailsBrowseSearch', { id: this.$route.params.id })
            );
          } else {
            this.$router.push(
              getRoute('projectDetailsBrowseSearch', { id: this.$route.params.id })
            );
          }
        } else if (isShared) {
          this.$router.push(
            getRoute('sharedProjectDetailsBrowseList', { id: this.$route.params.id })
          );
        } else {
          this.$router.push(getRoute('projectDetailsBrowseList', { id: this.$route.params.id }));
        }
      } else {
        bus.$emit('displayModal', { ...this.uploadFilesModal, isVisible: true });
      }
    },
    closeBrowser() {
      const isShared = this.$route.path.includes('shared');
      this.browse = false;
      this.libraryFileIds = [];
      this.uncheckTracks = true;
      if (isShared) {
        this.$router.push(getRoute('sharedProjectDetails', { id: this.$route.params.id }));
      } else {
        this.$router.push(getRoute('projectDetails', { id: this.$route.params.id }));
      }
    },
    handleFileSelect() {
      this.fileSelector.click();
      const idMemory = this.fileSelectorId;
      while (idMemory === this.fileSelectorId) {
        this.fileSelectorId = Math.round(Math.random() * 100);
      }
    },
    handleFileChange(e) {
      const cover = e.target.files[0];
      this.uploadProjectCover(cover, true);
    },
    setIsCoverHover(bool) {
      this.displayCoverBtn = bool;
    },
    setIsDropdownExpanded(bool) {
      this.isDropdownExpanded = bool;
    },
    setSelectedTracks(libraryFileIds) {
      this.libraryFileIds = libraryFileIds;
    },
    addTracksToProject() {
      if (this.$isWorkspaceLockedVerification(this)) return;
      this.$apollo
        .mutate({
          mutation: ADD_TRACK_TO_PROJECT,
          variables: {
            workspaceId: this.currentWorkspace.id,
            projectId: this.$route.params.id,
            libraryFilesIds: this.libraryFileIds
          },
          refetchQueries: [
            {
              query: PROJECT_DETAILS_INFO,
              variables: {
                projectId: this.$route.params.id
              }
            },
            {
              query: PROJECT_LIST,
              variables: {
                workspaceId: this.currentWorkspace.id,
                status: 'active',
                page: 1,
                limit: PAGINATOR_LIMIT
              }
            },
            {
              query: PROJECT_DETAILS_TRACKS,
              variables: {
                projectId: this.$route.params.id,
                limit: this.trackPaginateLimit
              }
            }
          ]
        })
        .then(() => {
          bus.$emit('showAlert', {
            message: { key: 'addTracksSuccess', params: this.libraryFileIds.length },
            style: 'success',
            delay: 5000
          });
          trackEvent('Tracks added to Project');
          this.closeBrowser();
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    updateProjectName() {
      if (this.ProjectDetails.name !== this.projectName && !this.projectNameError) {
        this.handleProjectUpdateMutation(this.projectName, this.ProjectDetails.description);
      }
    },
    handleProjectUpdateMutation(name, description, projectId) {
      this.canLeaveRoute = false;
      this.$apollo
        .mutate({
          mutation: PROJECT_UPDATE,
          variables: {
            projectId,
            name,
            description
          },
          update: store => {
            const projectDetails = store.readQuery({
              query: PROJECT_DETAILS_INFO,
              variables: {
                projectId
              }
            });
            projectDetails.ProjectDetails.name = name;
            projectDetails.ProjectDetails.description = description;
            projectDetails.ProjectDetails.updated_at = Math.floor(Date.now() / 1000);
            this.canLeaveRoute = true;
          },
          refetchQueries: [
            {
              query: PROJECT_LIST,
              variables: {
                workspaceId: this.currentWorkspace.id,
                status: 'active',
                page: 1,
                limit: PAGINATOR_LIMIT
              }
            }
          ]
        })
        .catch(error => {
          this.canLeaveRoute = true;
          this.projectName = this.ProjectDetails.name;
          const key = getGraphQlErrorCode(error);
          const descriptionMax = get(
            error,
            'graphQLErrors[0].fields_errors.description[0].param',
            null
          );
          if (descriptionMax) {
            bus.$emit('showAlert', {
              message: { key: 'maxLength', args: { nbChars: descriptionMax } },
              style: 'danger',
              delay: 5000,
              error: true
            });
            return;
          }
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    handleDuplicateProjectMutation() {
      this.pending = 'PENDING';
      this.$apollo
        .mutate({
          mutation: PROJECT_DUPLICATE,
          variables: {
            fromProjectId: this.$route.params.id
          },
          refetchQueries: [
            {
              query: PROJECT_LIST,
              variables: {
                workspaceId: this.currentWorkspace.id,
                status: 'active',
                page: 1,
                limit: PAGINATOR_LIMIT
              }
            },
            {
              query: PROJECTS_INFORMATION,
              variables: {
                workspaceId: this.currentWorkspace.id
              }
            }
          ]
        })
        .then(() => {
          this.pending = 'OK';
          bus.$emit('showAlert', {
            message: { key: 'copyProjectSuccess' },
            style: 'success',
            delay: 5000
          });
        })
        .catch(error => {
          this.pending = 'OK';
          this.projectName = null;
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    handleDeleteProjectMutation() {
      const projectIds = [];
      projectIds.push(this.$route.params.id);
      this.goToProjectListRoute();
      this.$apollo
        .mutate({
          mutation: PROJECT_DELETE,
          variables: {
            projectIds
          },
          refetchQueries: [
            {
              query: PROJECT_LIST,
              variables: {
                workspaceId: this.currentWorkspace.id,
                status: 'active',
                page: 1,
                limit: PAGINATOR_LIMIT
              }
            },
            {
              query: PROJECTS_INFORMATION,
              variables: {
                workspaceId: this.currentWorkspace.id
              }
            }
          ]
        })
        .then(() => {
          bus.$emit('showAlert', {
            message: { key: 'deleteProjectSuccess' },
            style: 'success',
            delay: 5000
          });
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    uploadProjectCover(cover, isUpdated = false) {
      this.temporaryImgUrl = window.URL.createObjectURL(cover);
      convertFileToJpeg(cover, this.$config.uploads.project.cover.max_files).then(response => {
        const jpegCover = new File([response], cover.name || 'newCover', { type: 'image/jpeg' });
        const that = this;
        new Promise((resolve, reject) => {
          let UPLOAD_ID;
          const upload = new tus.Upload(jpegCover, {
            retryDelays: [0, 3000, 5000, 10000, 20000],
            removeFingerprintOnSuccess: true,
            fingerprint: async () => {
              return ['tus', jpegCover.name, jpegCover.type, jpegCover.size].join('-');
            },
            onError(error) {
              reject(error);
            },
            onProgress(bytesUploaded, bytesTotal) {
              const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
              that.uploadPercent = percentage;
            },
            onSuccess() {
              resolve({
                uploadId: UPLOAD_ID
              });
            }
          });
          this.$apollo
            .mutate({
              mutation: PROJECT_INITIALIZE_PROJECT_COVER_UPLOAD,
              variables: {
                project_id: this.$route.params.id,
                file_name: jpegCover.name,
                size: `${jpegCover.size}`,
                mime_type: jpegCover.type
              }
            })
            .then(res => {
              const url = get(res, 'data.ProjectInitializeProjectCoverUpload.url');
              const id = get(res, 'data.ProjectInitializeProjectCoverUpload.upload_id');
              if (url && id) {
                upload.url = url;
                upload.options.metadata.id = id;
                UPLOAD_ID = id;
                upload.start();
              } else {
                reject(new Error('Invalid upload initialization data', res));
              }
            })
            .catch(() => {
              reject();
            });
        }).then(res => {
          this.$apollo
            .mutate({
              mutation: PROJECT_COVER_UPLOAD,
              variables: {
                projectId: `${this.$route.params.id}`,
                uploadId: res.uploadId,
                position: 50.0
              },
              refetchQueries: [
                {
                  query: PROJECT_DETAILS_INFO,
                  variables: {
                    projectId: this.$route.params.id
                  }
                }
              ]
            })
            .then(() => {
              trackEvent(
                'Project Covered',
                {
                  category: 'project editing',
                  label: isUpdated ? 'updated' : 'confirmed',
                  workspace_id: this.currentWorkspace.id,
                  project_id: this.$route.params.id
                },
                {
                  All: false,
                  'Google Analytics': true,
                  FullStory: true,
                  Hubspot: true,
                  Appcues: true,
                  Amplitude: true,
                  June: true
                }
              );
            })
            .catch(err => {
              this.uploadPercent = 0;
              this.temporaryImgUrl = null;
              const key = getGraphQlErrorCode(err);
              if (key === 'max') {
                bus.$emit('showAlert', {
                  message: { key: 'projectCoverMaxSize' },
                  style: 'danger',
                  delay: 5000,
                  error: true
                });
              } else {
                bus.$emit('showAlert', {
                  message: { key },
                  style: 'danger',
                  delay: 5000,
                  error: true
                });
              }
            });
        });
      });
    },
    deleteProjectCover() {
      this.hideProjectCover = true;
      this.setIsDropdownExpanded(false);
      this.$apollo
        .mutate({
          mutation: PROJECT_COVER_DELETE,
          variables: {
            projectId: this.$route.params.id
          },
          refetchQueries: [
            {
              query: PROJECT_DETAILS_INFO,
              variables: {
                projectId: this.$route.params.id
              }
            }
          ]
        })
        .catch(error => {
          this.hideProjectCover = false;
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    goToProjectListRoute() {
      this.$router.push(getRoute('projects'));
    },
    openShareModal() {
      trackEvent('Open share project modal');
      bus.$emit('displayModal', {
        ...this.shareModal,
        isVisible: true,
        title: this.$t('shareProjectName', { name: this.shareModal.props[0].value })
      });
    },
    closeShareModal() {
      bus.$emit('displayModal');
    },
    openDeleteProjectModal() {
      this.typeDelete = null;
      this.deleteItem = this.$route.params.id;
      bus.$emit('displayModal', {
        ...this.deleteProjectModal,
        isVisible: true,
        props: [{ name: 'projectName', value: this.deleteItem && this.projectName }]
      });
    },
    prepareUploadForFiles(files) {
      bus.$emit('prepareUploadForFiles', files);
    },
    toggleReposition(bool) {
      if (bool) {
        this.blockClickOutsideCover = true;
      }
      this.coverRepositionMode = bool;
    },
    handleCancelReposition() {
      this.coverPosition = this.defaultCoverPosition;
      this.toggleReposition(false);
    },
    handleCoverMouseDown(e) {
      // here to avoid selection on element of the UI on drag (mousedown+mousemove)
      e.preventDefault();
      e.stopPropagation();
      this.toggleListenMove(true);
      this.blockClickOutsideCover = true;
    },
    toggleListenMove(bool) {
      this.listenCoverMove = bool;
      if (bool) {
        window.addEventListener('mousemove', this.handleCoverMouseMove);
        window.addEventListener('mouseup', this.handleMouseUp);
      } else {
        window.removeEventListener('mousemove', this.handleCoverMouseMove);
        window.removeEventListener('mouseup', this.handleMouseUp);
      }
    },
    handleMouseUp() {
      this.toggleListenMove(false);
      // change the order of execution, so handleClickOutsideReposition is triggered first
      setTimeout(() => {
        this.blockClickOutsideCover = false;
      });
    },
    handleCoverMouseMove(e) {
      if (!this.listenCoverMove) return;
      const coverHeight = this.$refs.projectCoverPicture.naturalHeight;
      const calcPosition = this.coverPosition - (e.movementY * 100) / coverHeight;
      let newCoverPosition = calcPosition;
      if (calcPosition > 100) {
        newCoverPosition = 100;
      }
      if (calcPosition < 0) {
        newCoverPosition = 0;
      }
      this.coverPosition = newCoverPosition;
    },
    saveCoverPosition() {
      this.toggleReposition(false);
      this.$apollo
        .mutate({
          mutation: PROJECT_COVER_CHANGE_POSITION,
          variables: {
            projectId: this.$route.params.id,
            newPosition: Math.round(this.coverPosition * 100) / 100
          },
          refetchQueries: [
            {
              query: PROJECT_DETAILS_INFO,
              variables: {
                projectId: this.$route.params.id
              }
            }
          ]
        })
        .catch(error => {
          this.hideProjectCover = false;
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    handleClickOutsideReposition() {
      if (this.blockClickOutsideCover) {
        return;
      }
      if (this.coverRepositionMode && !this.listenCoverMove) {
        this.saveCoverPosition();
      }
    },
    setOnlyAttachmentStatus(bool) {
      this.projectWithOnlyAttachment = bool;
    }
  }
};
</script>

<style lang="scss" scoped>
.project {
  color: $color_neutral_100;
  height: calc(100vh - 64px - 56px);

  &-with-only-att {
    height: auto;
  }

  &-browse {
    height: 100%;
    overflow: hidden;
    position: absolute;
    z-index: map-get($z-index, content-panel);
    top: 0;
    left: 0;
    width: 100%;
    background-color: $color_neutral_0;
    display: grid;
    grid-template-areas:
      'header'
      'content'
      'player';
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr 65px;

    &-header {
      z-index: 6;
      grid-area: header;
      display: flex;
      justify-content: space-between;
      padding: 24px 24px 24px 130px;

      &-search {
        width: 100%;
      }

      &-close {
        font-size: 24px;
        cursor: pointer;
      }
    }

    &-content {
      grid-area: content;
      display: grid;
      grid-template-areas:
        'tracks'
        'footer';
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 80px;
      margin: 0 130px 0 90px;
      overflow-y: auto;

      &-tracks {
        grid-area: tracks;
        overflow-y: auto;
        margin-left: 12px;

        &-title {
          @include heading-6;
          margin: 16px 0 40px 40px;
          display: flex;
          align-items: center;
        }

        &-list {
          &-no-result {
            display: flex;
            justify-content: center;
            @include heading-6;
            margin: 40px 0 0;
          }
        }
      }

      &-footer {
        grid-area: footer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-- {
          @include body-1;
          color: $color_neutral_60;
        }
      }
    }
  }

  &-header {
    grid-area: header;
    background-color: $color_neutral_10;
    border-bottom: 1px solid $color_neutral_30;
    display: flex;
    align-items: center;
    padding: 0 40px 0 80px;
    justify-content: space-between;
    margin: 0 -40px;
    &-left {
      display: flex;

      &-- {
        margin: 0 4px 0 0;
      }
    }

    &-right {
      display: flex;

      &-- {
        margin: 0 8px 0 0;
      }
    }
  }
  &-cover {
    position: relative;
    width: calc(100% + 40px);
    min-height: 180px;
    max-height: 360px;
    height: 34vh;
    &-img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      pointer-events: none; // Prevents drag and drop on cover image
    }
    &-reposition {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      cursor: move;
      &-txt {
        background: rgba(37, 36, 40, 0.6);
        border-radius: 88px;
        font-size: 14px;
        font-weight: 200;
        color: $color_neutral_0;
        width: fit-content;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 8px 16px;
        pointer-events: none;
        position: absolute;
      }
    }
    &-btns {
      display: flex;
      position: absolute;
      bottom: 8px;
      right: 40px;
      &-dropdown {
        margin-right: 8px;
      }
      &-- {
        background-color: $color_neutral_0;
      }
      .btn-save-position {
        margin-right: 8px;
      }
    }
    .upload-pending {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      position: absolute;
      background: linear-gradient(0deg, rgba(37, 36, 40, 0.6), rgba(37, 36, 40, 0.6));
      &-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 32px;
        background: rgba(37, 36, 40, 0.6);
        border-radius: 4px;
        @include body-1;
        font-variant-numeric: tabular-nums;
        color: $color_neutral_0;
        .spinner {
          margin: 1px 8px 0 0;
        }
      }
    }
  }
  &-content {
    grid-area: container;
    overflow-y: auto;
    height: 100%;

    display: grid;
    grid-template-areas:
      'nav'
      'content';
    grid-template-columns: 1fr;
    grid-template-rows: 56px 1fr;
    &-tabs {
      align-self: end;
      margin: 0 0 0 40px;
    }
    &-- {
      overflow-y: auto;
    }
  }
}

.loader {
  height: calc(100vh - 56px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorMessage {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & button {
    margin-top: 16px;
  }
}

.project-content-tabs.tabs {
  width: calc(100% - 40px);
}
</style>

<style lang="scss">
.project-cover-btns {
  .project-cover-btns--.secondary {
    background-color: $color_neutral_0;
  }
}
</style>
