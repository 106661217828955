<template>
  <div class="file-thumbnail">
    <img v-if="imgSrc" :src="imgSrc" />
    <div class="file-thumbnail-extension" v-else>{{ extension }}</div>
  </div>
</template>

<script>
export default {
  props: {
    imgSrc: {
      type: String,
      required: false
    },
    extension: {
      type: String,
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
.file-thumbnail {
  width: 54px;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  @include body-1;
  user-select: none;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 3px;
  }

  &-extension {
    font-weight: 600;
    text-transform: uppercase;
    width: 53px;
    height: 40px;
    border-radius: 2px;
    background-color: $color_neutral_30;
    border: 1px solid $color_neutral_40;
    color: $color_neutral_60;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
}
</style>
