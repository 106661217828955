<template>
  <div class="replace-audio-file-modal">
    <div class="replace-audio-file-modal-body">
      <div class="replace-audio-file-modal-body-description">
        {{ $t('modalReplaceAudioFile.content', { title: title }) }}
      </div>
    </div>
    <div class="replace-audio-file-modal-buttons">
      <div class="replace-audio-file-modal-buttons--">
        <submit-button
          btnStyle="tertiary"
          size="medium"
          :label="$t('cancel')"
          @submit="closeModal"
        />
      </div>
      <div class="replace-audio-file-modal-buttons--">
        <submit-button
          btnStyle="primary"
          size="medium"
          :label="$t('yesContinue')"
          @submit="submit"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onSubmit: {
      type: Function,
      required: false,
      default: () => {}
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    file: {
      type: undefined,
      required: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    submit() {
      this.onSubmit(this.file);
      this.$emit('closeModal');
    }
  }
};
</script>

<style lang="scss">
.replace-audio-file-modal {
  &-body {
    padding: 32px 40px 0 32px;
    line-height: 150%;

    &-description {
      margin: 0 0 32px 0;
    }

    &-txtfield {
      margin: 0 0 40px 0;
    }
  }
  &-buttons {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $color_neutral_30;
    padding: 8px 32px;

    &-- {
      &:first-child {
        margin: 0 16px 0 0;
      }
    }
  }
}
</style>
