
<div
  class="tag-category"
  :style="withIconButton ? `width: 40px;` : `width: fit-content;`"
  v-click-outside="{ hide: closeExpandDropdown }"
>
  <div class="tag-category-button">
    <div class="tag-category-button-withicon" v-if="withIconButton">
      <icon-button
        icon="ri-add-line"
        btnStyle="tertiary"
        size="small"
        @submit="toggleExpandDropdown"
      />
    </div>
    <div
      v-else
      :class="{
        'tag-category-button-withouticon': true,
        'tag-category-button-active':
          JSON.stringify(checkedOptions).includes(tagCategory.id) || noneIsChecked
      }"
      @click="toggleExpandDropdown"
    >
      <div
        class="tag-category-button-withouticon-dot"
        :style="`background-color: ${tagCategory.background_color}`"
      />
      <div class="tag-category-button-withouticon-label">
        {{ tagCategory.name.toLowerCase() }}
      </div>
    </div>
  </div>
  <div
    class="tag-category-expand"
    v-if="isDropdownExpanded"
    :style="withIconButton ? `top: 42px; left: 8px;` : `top: 34px; left: 0;`"
  >
    <checkbox
      v-if="withNoneOption"
      class="tag-category-expand-none"
      label="None"
      large
      :id="tagCategory.id"
      :selectedItems="noneCategories"
      @toggleCheckbox="toggleNoneOption"
    />
    <div v-if="tagCategory.families.length > 1">
      <div
        class="tag-category-expand-option"
        v-for="(family, index) in tagCategory.families"
        :key="family.id"
      >
        <expandable :title="family.name" :subtitle="getExpandableSubtitle(family)">
          <checkbox
            class="tag-category-expand-option-checkbox"
            v-for="(tag, tagIndex) in family.tags"
            :index="index"
            :label="tag.name"
            :id="`${tagCategory.id}-${family.id}-${tag.id}`"
            :selectedItems="checkedOptions"
            large
            :key="tagIndex"
            @toggleCheckbox="toggleTag"
          />
        </expandable>
      </div>
    </div>
    <div v-else>
      <checkbox
        class="tag-category-option-checkbox"
        v-for="(tag, tagIndex) in tagCategory.families[0].tags"
        :index="tagIndex"
        :label="tag.name"
        :id="`${tagCategory.id}-${tagCategory.families[0].id}-${tag.id}`"
        :selectedItems="checkedOptions"
        large
        :key="tagIndex"
        @toggleCheckbox="toggleTag"
      />
    </div>
  </div>
</div>
