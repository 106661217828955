import gql from 'graphql-tag';

export const SHARE_PUBLIC_LINK = gql`
  query SharePublicLink($type: ShareType!, $entities: [ID!]) {
    SharePublicLink(type: $type, entities: $entities) {
      id
      workspace_id
      shared_by
      share_hash
      created_at
      updated_at
      settings {
        access_rights
        access_password
      }
      status
      is_enabled
      base_link
      link
      slug
    }
  }
`;

export const AGGREGATED_SHARES_LIST = gql`
  query AggregatedSharesList($workspaceId: ID!, $libraryId: ID!, $limit: Int, $offset: Int) {
    AggregatedSharesList(
      workspaceId: $workspaceId
      libraryId: $libraryId
      limit: $limit
      offset: $offset
    ) {
      library_id
      name
      type
      shares_ids
      shared_count
      shared_entities
      thumbnails {
        type
        width
        height
        url
      }
      last_shared_by
      last_shared_at
      subtitle_variables {
        name
        value
      }
    }
  }
`;

export const ACTIVITIES_LIST = gql`
  query ActivitiesList(
    $workspaceId: ID!
    $type: ActivityType!
    $refsIds: [ID]
    $hash: String
    $from: Int
    $to: Int
    $actions: [String]
    $shareIds: [ID]
    $page: Int
  ) {
    ActivitiesList(
      workspaceId: $workspaceId
      type: $type
      refsIds: $refsIds
      hash: $hash
      from: $from
      to: $to
      actions: $actions
      shareIds: $shareIds
      page: $page
    ) {
      page
      data {
        id
        workspaceId
        context {
          name
          id
        }
        action
        entity {
          type
          id
          description
        }
        performedBy {
          userId
          username
          userEmail
        }
        performedAt
      }
    }
  }
`;

export const SHARE_LIST = gql`
  query ShareList($workspaceId: ID!, $type: ShareType!, $entities: [ID!], $hash: String) {
    ShareList(workspaceId: $workspaceId, type: $type, entities: $entities, hash: $hash) {
      id
      workspace_id
      shared_by
      share_name
      custom_name
      share_hash
      email
      settings {
        access_rights
        access_password
      }
      entities {
        type
        ids
      }
      status
      is_enabled
      slug
      link
      base_link
      is_public
      valid_to
      created_at
      updated_at
      subtitle_variables {
        name
        value
      }
    }
  }
`;
