
<div class="card-artist">
  <router-link
    class="card-artist-link"
    :to="artistUrl"
    @mouseover.native="cardHoverHandler"
    @mouseleave.native="cardMouseLeaveHandler"
  >
    <div class="card-artist-link-img">
      <img loading="lazy" :src="artistCover" alt="artist cover" class="card-artist-link-img--" />
    </div>
    <transition name="fade">
      <div v-if="isHover" class="card-artist-link-hover"></div>
    </transition>
  </router-link>
  <div class="card-artist-name">
    <router-link
      :to="artistUrl"
      :class="linkClass"
      @mouseover.native="cardHoverHandler"
      @mouseleave.native="cardMouseLeaveHandler"
      >{{ artistName }}</router-link
    >
  </div>
</div>
