<template>
  <div class="information-banner">
    <div class="icon-info"><i class="ri-information-fill"></i></div>
    <div class="text">AI is currently tagging your tracks, the process may take a few minutes.</div>
    <icon-button
      class="icon-close-mail"
      btnStyle="tertiary"
      icon="ri-close-fill"
      size="small"
      @submit="hide"
    />
  </div>
</template>

<script>
export default {
  methods: {
    hide() {
      this.$emit('hideAIUploadBanner');
    }
  }
};
</script>

<style lang="scss" scoped>
.information-banner {
  box-sizing: border-box;
  height: fit-content;
  width: 100%;
  padding: 8px 16px 8px 40px;
  background-color: $color_primary_10;
  display: flex;
  align-items: center;
  @include body-1;
  color: $color_primary_100;
  .text {
    flex-grow: 1;
    &-link {
      text-decoration: underline;
    }
    .mail {
      font-weight: 600;
    }
    .resend {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .icon {
    &-info {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      background: rgba(71, 35, 173, 0.1);
      color: $color_primary_100;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-close {
      flex-shrink: 0;
    }
  }
}
</style>
<style lang="scss">
.icon-close-mail.rounded-button.tertiary.small {
  i {
    color: $color_primary_100;
  }
  &:hover {
    background: $color_primary_10;
    border: 1px solid $color_primary_100;
  }
}
</style>
