import get from 'lodash.get';
import { XMLHttpRequestHandler } from './xhr';
import { appConfig } from '../constants';

export const formatNumber = (aa, bb) => {
  let a = `${aa}`;
  const b = bb || ' ';
  let c = '';
  let d = 0;
  while (a.match(/^0[0-9]/)) {
    a = a.substr(1);
  }
  for (let i = a.length - 1; i >= 0; i -= 1) {
    c = d !== 0 && d % 3 === 0 ? a[i] + b + c : a[i] + c;
    d += 1;
  }
  return c;
};

export const getGraphQlErrorCode = error => {
  let codeError = null;
  if (error && error.graphQLErrors && error.graphQLErrors[0] && error.graphQLErrors[0].code) {
    codeError = error.graphQLErrors[0].code;
  }
  if (
    error &&
    error.graphQLErrors &&
    error.graphQLErrors[0] &&
    error.graphQLErrors[0].fields_errors &&
    Object.values(error.graphQLErrors[0].fields_errors) &&
    Object.values(error.graphQLErrors[0].fields_errors)[0] &&
    Object.values(error.graphQLErrors[0].fields_errors)[0][0] &&
    Object.values(error.graphQLErrors[0].fields_errors)[0][0].code
  ) {
    codeError = Object.values(error.graphQLErrors[0].fields_errors)[0][0].code;
  }
  if (
    error &&
    error.graphQLErrors &&
    error.graphQLErrors[0] &&
    error.graphQLErrors[0].fields_errors &&
    error.graphQLErrors[0].fields_errors.name &&
    error.graphQLErrors[0].fields_errors.name[0]
  ) {
    codeError = error.graphQLErrors[0].fields_errors.name[0].code;
  }
  return codeError;
};

export const getGraphQlErrorData = error => get({ error }, 'error.graphQLErrors[0].errors[0]');

export const getGraphQlErrorMessage = error => {
  if (error && error.graphQLErrors && error.graphQLErrors[0] && error.graphQLErrors[0].message) {
    return error.graphQLErrors[0].message;
  }
  return null;
};

export const getGraphQlErrorLibraryFileId = error =>
  get({ error }, 'error.graphQLErrors[0].errors[0].library_file_id');

export const getBridgeConfig = cb =>
  XMLHttpRequestHandler(`${appConfig.AccountApiBaseUrl}/config.js`, response => cb(response));

export const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);

export const strongPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const mediumPassword =
  /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;

export const isEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

export const removeAccentFromString = string =>
  string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const toHHMMSS = duration => {
  const result = [];
  if (!duration || Number.isNaN(duration)) {
    return '0:00';
  }
  const hours = Math.floor(duration / 3600);
  if (hours > 0) {
    result.push(hours);
  }
  const minutes = Math.floor((duration / 60) % 60);
  if (minutes < 10 && hours > 0) {
    result.push(`0${minutes}`);
  } else {
    result.push(minutes);
  }
  const seconds = Math.floor(duration % 60);
  if (seconds < 10) {
    result.push(`0${seconds}`);
  } else {
    result.push(seconds);
  }
  return result.join(':');
};

// c/c from https://stackoverflow.com/a/2541680
export const getAverageRGB = imgEl => {
  const blockSize = 5; // only visit every 5 pixels
  const defaultRGB = { r: 231, g: 231, b: 233 }; // for non-supporting envs
  const canvas = document.createElement('canvas');
  const context = canvas.getContext && canvas.getContext('2d');
  let i = -4;
  let data;
  const rgb = { r: 0, g: 0, b: 0 };
  let count = 0;

  if (!context) {
    return defaultRGB;
  }

  // eslint-disable-next-line no-multi-assign
  const height = (canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height);
  // eslint-disable-next-line no-multi-assign
  const width = (canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width);

  context.drawImage(imgEl, 0, 0);

  try {
    data = context.getImageData(0, 0, width, height);
  } catch (e) {
    /* security error, img on diff domain */
    return defaultRGB;
  }

  const { length } = data.data;

  // eslint-disable-next-line no-cond-assign
  while ((i += blockSize * 4) < length) {
    count += 1;
    rgb.r += data.data[i];
    rgb.g += data.data[i + 1];
    rgb.b += data.data[i + 2];
  }

  rgb.r = Math.floor(rgb.r / count);
  rgb.g = Math.floor(rgb.g / count);
  rgb.b = Math.floor(rgb.b / count);

  return rgb;
};

// check if env is production
export const isProduction = () => window.location.host === 'app.bridge.audio';

export const isValidUUID = str => {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(str);
};

export const titleCase = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const generateDocumentTitle = params => {
  let title;
  if (!params || params.length === 0 || params.includes(undefined) || params.includes('')) {
    title = 'Bridge.audio';
  } else if (params && params.length === 1) {
    title = `${params[0]} | Bridge.audio`;
  } else if (params && params.length === 2) {
    title = `${params[0]} - ${params[1]} | Bridge.audio`;
  }
  return title;
};

export const readEntryContentAsync = entry => {
  return new Promise(resolve => {
    let reading = 0;
    const contents = [];
    const readReaderContent = reader => {
      reading += 1;
      reader.readEntries(entries => {
        reading -= 1;
        entries.forEach(e => {
          if (e) {
            readEntry(e); //eslint-disable-line
          }
        });
        if (entries.length === 100) {
          readReaderContent(reader);
        }
        if (reading === 0) {
          resolve(contents);
        }
      });
    };
    const readEntry = e => {
      if (e) {
        if (e.isFile) {
          reading += 1;
          e.file(file => {
            reading -= 1;
            contents.push(file);
            if (reading === 0) {
              resolve(contents);
            }
          });
        } else if (e.isDirectory) {
          readReaderContent(e.createReader());
        }
      }
    };
    readEntry(entry);
  });
};

export const formatToNavigatorLanguage = entry => {
  const language = navigator.language || 'en';
  return entry.toLocaleString(language);
};
