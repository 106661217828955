<template>
  <div class="empty" data-test="empty-view-container">
    <div class="empty-image-container">
      <img
        :src="setImageUrl(this.placeholderType)"
        alt="placeholder image"
        class="empty-image"
        width="753"
        height="336"
      />
    </div>
    <div class="empty-content">
      <div class="clouded-text-container">
        <clouded-text
          class="cloud-text"
          v-if="cloudedTextContent"
          :content="cloudedTextContent"
          :color="cloudedTextColor"
        />
        <div class="empty-content-title">{{ title }}</div>
      </div>
      <div v-if="description" class="empty-content-description">{{ description }}</div>
      <slot name="button"></slot>
      <div v-if="helper" class="empty-content-helper">{{ helper }}</div>
    </div>
  </div>
</template>

<script>
import CloudedText from '@/containers/cloudedText';

export default {
  components: {
    CloudedText
  },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    helper: {
      type: String,
      required: false,
      default: ''
    },
    placeholderType: {
      type: String,
      required: false,
      default: ''
    },
    cloudedTextContent: {
      type: String,
      required: false,
      default: ''
    },
    cloudedTextColor: {
      type: Object,
      required: false,
      default: () => {
        return {
          stroke: '#2F43DE',
          fill: '#E0E3FA'
        };
      }
    },
    cloudedTextDelta: {
      type: Number,
      required: false,
      default: 0
    }
  },
  methods: {
    setImageUrl(type) {
      let imageUrl;
      switch (type) {
        case 'tracklist':
          imageUrl = '/images/empty-state/wl-tracks.jpg';
          break;
        case 'artists':
          imageUrl = '/images/empty-state/wl-artists.jpg';
          break;
        case 'albums':
          imageUrl = '/images/empty-state/wl-albums.jpg';
          break;
        case 'projectlist':
          imageUrl = '/images/empty-state/project-list.jpg';
          break;
        case 'sharing':
          imageUrl = '/images/empty-state/project-page-sharing-links.jpg';
          break;
        case 'activity':
          imageUrl = '/images/empty-state/project-page-activity.jpg';
          break;
        case 'inbox':
          imageUrl = '/images/empty-state/inbox.jpg';
          break;
        case 'inbox_little':
          imageUrl = '/images/empty-state/inbox-little.jpg';
          break;
        case 'sharedlist':
          imageUrl = '/images/empty-state/shared-list.jpg';
          break;
        default:
          break;
      }
      return imageUrl;
    }
  }
};
</script>

<style lang="scss" scoped>
.empty {
  padding: 0;
  color: $color_neutral_100;
  font-size: 14px;
  line-height: 150%;
  height: calc(100vh - 220px);
  overflow-y: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  &-image-container {
    margin-bottom: 32px;
    z-index: 1;
  }
  .clouded-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
  }

  &-content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
      font-size: 24px;
      font-weight: 600;
      line-height: 39px;
    }

    &-description {
      margin: 0 0 40px 0;
      color: $color_neutral_60;
    }

    &-helper {
      color: $color_neutral_60;
      margin: 16px 0 0 0;
    }
  }
}
</style>
