<template>
  <div class="container">
    <i :class="icon" class="icon"></i>
    <div>{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  font-size: 14px;
  .icon {
    color: $color_neutral_40;
    margin-right: 4px;
    transform: translate(0, 1px);
  }
}
</style>
