
<div class="player-container" :style="playlist.length > 0 && 'bottom: 0;'">
  <div v-if="Object.keys(playlist).length > 0" class="wrapper">
    <player
      ref="player"
      :tracks="Object.values(playlist)"
      :playingTrack="playingTrack"
      :audioSrcIsLoaded="audioSrcIsLoaded"
      :isOnError="isPlayerOnError"
      @setPlayingTrack="setPlayingTrack"
      @navigateTo="navigateTo"
    />
    <dropdown
      ref="playerDropdown"
      class="player-dropdown"
      :definedPosition="{ bottom: 90 }"
      @setIsDropdownExpanded="setIsDropdownExpanded"
    >
      <template #button>
        <submit-button
          class="streaming-audio-quality-btn"
          v-tooltip="{
            content: $t('settings'),
            position: 'left'
          }"
          btnStyle="tertiary"
          size="small"
          :label="qualityBtnLabel"
        />
      </template>
      <div class="streaming-audio-quality-menu">
        <div class="header-info">
          <div class="title">Streaming audio quality</div>
          <a
            class="info-link"
            href="https://www.notion.so/bridgeaudio/Audio-Player-432ae6c28bd647b981a123391933fe3a"
            target="_blank"
            rel="noopener"
            >More info</a
          >
        </div>
        <div
          :class="{ 'option-container': true, 'selected-option': option.selected }"
          v-for="(option, id) in streamingAudioQualityOptions"
          :key="`SAQ-option-${id}`"
          @click="option.action"
        >
          <div>
            <div class="option-label">{{ option.label }}</div>
            <div class="option-desc">{{ option.desc }}</div>
          </div>
          <div class="check-container">
            <i v-if="option.selected" class="ri-check-fill" />
          </div>
        </div>
      </div>
    </dropdown>
  </div>
</div>
