<template>
  <div class="container" v-click-outside="{ hide }">
    <div @click="handleClickButton" ref="button">
      <slot name="button" :isExpanded="isExpanded"></slot>
    </div>
    <div
      v-if="isExpanded"
      class="dropdown"
      ref="dropdown"
      :style="dropdownStyle"
      @click="handleClickDropdown"
    >
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
import { clickOutside } from '@/utils/directives';

export default {
  props: {
    isDisabled: {
      default: false,
      type: Boolean,
      required: false
    },
    dismissOnClick: {
      default: false,
      type: Boolean,
      required: false
    },
    definedPosition: {
      default: null,
      type: Object,
      required: false
    }
  },
  data() {
    return {
      isExpanded: false,
      dropdownStyle: undefined,
      dropdownRef: undefined
    };
  },
  directives: {
    clickOutside
  },
  watch: {
    // This is just a way to calculate the dropdown position
    isExpanded() {
      this.$emit('setIsDropdownExpanded', this.isExpanded);
      if (this.isExpanded === false) {
        this.$refs.dropdown.classList.remove('visible');
        this.dropdownStyle = undefined;
        return;
      }
      const { button } = this.$refs;
      const height = window.innerHeight;
      const width = window.innerWidth;
      this.$nextTick(() => {
        const styles = [];
        this.dropdownRef = this.$refs.dropdown;

        const { top, left } = this.dropdownRef.getBoundingClientRect();
        // return true if the dropdown exceed the bottom of the page
        const isTooLow = height - (top + this.dropdownRef.clientHeight) - 64 < button.scrollHeight;
        // return true si the dropdown exceed the right of the page
        const isTooRight =
          width - (left + this.dropdownRef.clientWidth) <
          this.dropdownRef.clientWidth - button.parentElement.clientWidth;
        if (isTooLow) {
          const calcBottom =
            (this.definedPosition && this.definedPosition.bottom) || button.clientHeight + 2;
          styles.push(`bottom: ${calcBottom}px`);
        } else {
          const calcTop =
            (this.definedPosition && this.definedPosition.top) || button.clientHeight + 2;
          styles.push(`top: ${calcTop}px`);
        }
        if (isTooRight) {
          styles.push('right: 0px');
        } else {
          styles.push('left: 0px');
        }
        this.dropdownStyle = styles.join(';');
        this.dropdownRef.classList.add('visible');
      });
    }
  },
  methods: {
    handleClickDropdown() {
      if (this.dismissOnClick) {
        this.hide();
      }
    },
    handleClickButton() {
      if (this.isDisabled) {
        return;
      }
      if (this.isExpanded) {
        this.hide();
      } else {
        this.isExpanded = true;
      }
    },
    hide() {
      if (this.dropdownRef) {
        this.dropdownRef.classList.remove('visible');
      }
      setTimeout(() => {
        this.isExpanded = false;
      }, 200);
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
}

.dropdown {
  position: absolute;
  z-index: 1000;
  opacity: 0;
  transition: all 0.15s ease-in-out;
  transform: scale(0.9);
}

.visible {
  transform: scale(1);
  opacity: 1;
}
</style>
