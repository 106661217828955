
<div>
  <div v-if="blob" class="cropper">
    <image-cropper
      :blob="blob"
      :submitButtonLabel="$t('save')"
      :cancelButtonLabel="$t('cancel')"
      :imageWidth="cropperMaxWidth"
      :imageHeight="cropperMaxHeight"
      :isSquare="true"
      @cropImage="cropImage"
    />
  </div>
  <div v-if="!blob">
    <div class="support-modal-body">
      <div class="support-modal-body-section">
        <div class="support-modal-body-section-label">{{ $t('artwork') }}</div>
        <image-browser
          :imgUrl="imgUrl"
          pictureSize="large"
          square
          :removeButton="displayDeleteArtwork"
          @changeImgUrl="handleAlbumImageChange"
          @removeImage="handleAlbumImageRemove"
        />
      </div>
      <div class="support-modal-body-section">
        <text-field
          inputType="text"
          :errorMessage="albumNameErrorMessage"
          :fieldModel.sync="name"
          :value="name"
          :label="$t('name')"
          :inputWidth="357"
          horizontalDisplaying
          large
        />
      </div>
      <div class="support-modal-body-dropdown">
        <div class="support-modal-body-dropdown-label">{{ $t('type') }}</div>
        <div class="support-modal-body-dropdown--">
          <select-box
            :options="getSupportOptions"
            large
            :selectedValue="{ value: type, label: type }"
            @setSelectBoxValue="changeSupportType"
          />
        </div>
      </div>
      <div class="support-modal-body-dropdown">
        <div class="support-modal-body-dropdown-label">{{ $t('artist') }}</div>
        <div class="support-modal-body-dropdown--">
          <auto-complete-list
            :queries="autoCompleteQueries"
            :dataLoaded="isArtistsDataLoaded"
            dataType="ArtistsAutocomplete"
            storeEvent="changeIsArtistsLoaded"
          >
            <template v-slot:default="slotProps">
              <input-dropdown
                key="artist-dropdown"
                id="artist-dropdown"
                :defaultList="slotProps.items"
                :defaultOptions="defaultArtists"
                isMultipleSelection
                :loadingOptions="slotProps.isLoading"
                :showObserver="slotProps.showObserver"
                :fetchingMoreOptions="slotProps.fetchingMoreItems"
                :width="390"
                :errorMessage="artistErrorMessage"
                @listOptions="slotProps.fetch"
                @fetchMoreOptions="slotProps.fetchMoreItems"
                @searchOption="slotProps.search"
                @handleResult="selectArtist"
                @handleBlur="selectArtist"
              />
            </template>
          </auto-complete-list>
        </div>
      </div>
      <div class="support-modal-body-section support-modal-body-section-horizontal">
        <label class="support-modal-body-section-label">{{ $t('releaseDate') }}</label>
        <date-picker
          large
          @datePicked="setRecordingDate"
          :selectedDate="releaseDateTimeStamp * 1000"
          :class="{
            'support-modal-body-section-datePicker': true,
            toTop: shouldDisplayDatePickerUpward
          }"
        />
      </div>
      <div class="support-modal-body-section">
        <text-field
          inputType="text"
          :fieldModel.sync="upc"
          :value="upc"
          label="UPC"
          :inputWidth="357"
          horizontalDisplaying
          :errorMessage="upcErrorMessage"
          large
        />
      </div>
      <div class="support-modal-body-section-horizontal">
        <div class="support-modal-body-section-label">{{ $t('totalTracks') }}</div>
        <div>
          <text-field
            inputType="number"
            :errorMessage="trackTotalErrorMessage"
            errorMessageException
            :fieldModel.sync="trackTotal"
            :value="trackTotal"
            :inputWidth="46"
            horizontalDisplaying
          />
        </div>
      </div>
    </div>
    <div class="support-modal-footer">
      <div class="support-modal-footer-btn">
        <submit-button btnStyle="tertiary" :label="$t('cancel')" @submit="closeModal" />
      </div>
      <submit-button
        :label="isCreate ? $t('create') : $t('smallSaveChanges')"
        :disabled="disabled"
        :pending="supportChangesIsPending"
        @submit="handleSubmit"
      />
    </div>
  </div>
</div>
