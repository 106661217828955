
<div class="warning-banner">
  <div class="icon-info"><i class="ri-information-fill"></i></div>
  <div class="text">
    <span class="text bold">This workspace is full </span>
    <span>You have reached plan limits. Please consider </span>
    <router-link
      :disabled="memberRole !== 'Admin' || memberRole !== 'Owner'"
      :event="memberRole === 'Admin' || memberRole === 'Owner' ? 'click' : ''"
      v-tooltip="
        memberRole === 'Editor' && {
          content: $t('upgradeAlert.tooltipText'),
          classes: 'alert-link-tooltip',
          position: 'bottom'
        }
      "
      :to="getRoute('offersSettings')"
      class="text link"
    >
      upgrading your plan
    </router-link>
  </div>
</div>
