<template>
  <div class="submission-activity">
    <ActivityView
      class="submission-activity--"
      entityType="submission"
      :entityIds="entityIds"
      :entityName="entityName"
      :shareHash="shareHash"
      hideContactFilter
    />
  </div>
</template>

<script>
import ActivityView from '../../../../../../containers/shareViews/activity';

export default {
  components: {
    ActivityView
  },
  props: {
    entityIds: {
      type: Array,
      required: true
    },
    entityName: {
      type: String,
      required: false,
      default: ''
    },
    shareHash: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.submission-activity {
  margin-top: 24px;
}
</style>

<style lang="scss">
.submission-activity {
  &-- {
    .activity {
      padding: 0;
      &-filter {
        top: 40px;
      }
    }
  }
}
</style>
