<template>
  <div class="area">
    <div v-if="label" class="area-label">{{ label }}</div>
    <div :class="txtareaStyle">
      <div v-if="withEditButton && disabled" class="area-disabled-edit-button">
        <submit-button
          btnStyle="tertiary"
          exception
          danger
          size="small"
          label="Edit"
          @submit="handleEditBtnClick"
        />
      </div>
      <textarea
        @blur="
          setActiveClass(false);
          validate();
        "
        @focus="setActiveClass(true)"
        @input="handleInput"
        :placeholder="placeholder"
        ref="textarea"
        spellcheck="false"
        v-model="txt"
        :disabled="disabled"
      />
      <div :class="countStyle" v-if="showCharacterLimit">
        {{ characterCount }} / {{ characterLimit }}
      </div>
    </div>
    <div v-if="errorMessage" class="area-errormsg">{{ errorMessage }}</div>
  </div>
</template>

<script>
import SubmitButton from '../buttons/submitButton';

export default {
  components: {
    SubmitButton
  },
  props: {
    label: {
      type: String,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: String,
      required: false,
      default: null
    },
    errorMessage: {
      type: String,
      required: false,
      default: null
    },
    fieldModel: {
      type: String,
      default: null,
      required: false
    },
    adaptativeHeight: {
      type: Boolean,
      required: false,
      default: false
    },
    characterLimit: {
      type: Number,
      required: false,
      default: null
    },
    hideBorder: {
      type: Boolean,
      required: false,
      default: false
    },
    withEditButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      activeClass: false,
      characterCount: 0,
      txt: this.value || '',
      isExpanded: false,
      txtareaHeight: 0,
      disabled: this.withEditButton,
      showError: false
    };
  },
  computed: {
    txtareaStyle() {
      const { activeClass, errorMessage, characterCount, characterLimit, hideBorder, disabled } =
        this;
      let style = 'area-text';
      if (activeClass) style += ' area-active';
      if (errorMessage || this.showError) style += ' area-error';
      if (disabled) style += ' area-disabled';
      if (characterLimit && !errorMessage) {
        if (characterCount > characterLimit) style += ' area-error';
      }
      if (hideBorder) style += ' area-no-border';
      return style;
    },
    countStyle() {
      let style = 'area-text-count';
      if (this.characterCount >= this.characterLimit) style += ' area-text-count-limit';
      return style;
    },
    showCharacterLimit() {
      if (this.characterLimit && this.activeClass) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    if (this.adaptativeHeight) {
      this.characterCounter();
      this.adjustHeight();
      window.addEventListener('resize', this.adjustHeight);
    }
  },
  updated() {
    this.adjustHeight();
  },
  beforeDestroy() {
    if (this.adaptativeHeight) {
      window.removeEventListener('resize', this.adjustHeight);
    }
  },
  methods: {
    setActiveClass(value) {
      this.activeClass = value;
    },
    adjustHeight() {
      this.$refs.textarea.parentElement.style.height = '73px';
      this.$refs.textarea.parentElement.style.height =
        this.$refs.textarea.scrollHeight + 18 > 73
          ? `${this.$refs.textarea.scrollHeight + 18}px`
          : '73px';
    },
    characterCounter() {
      this.characterCount = this.$refs.textarea.textLength;
    },
    handleInput(el) {
      this.$emit('update:fieldModel', el.target.value);
      this.$emit('handleInput', el.target.value);
      if (this.adaptativeHeight) {
        this.adjustHeight();
      }
      this.characterCounter();
      this.showError = false;
    },
    validate() {
      if (!this.errorMessage) {
        this.setActiveClass(false);
        this.$emit('validate');
      } else {
        this.showError = true;
      }
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    handleEditBtnClick() {
      this.disabled = false;
      setTimeout(() => {
        this.$refs.textarea.focus();
      }, 500);
    }
  }
};
</script>

<style lang="scss" scoped>
.area {
  position: relative;
  @include body-1;
  &-text {
    overflow: auto;
    min-width: 220px;
    max-width: 100%;
    background: $color_neutral_0;
    border: 1px solid $color_neutral_40;
    box-sizing: border-box;
    border-radius: 4px;
    transition: border 300ms linear;
    position: relative;

    textarea {
      font-family: inherit;
      outline: none;
      border: none;
      resize: none;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 8px 16px;
      @include body-1;
      margin: 0;
    }

    &-count {
      position: absolute;
      bottom: 8px;
      right: 16px;
      @include body-2;
      font-variant-numeric: tabular-nums;
      &-limit {
        color: $color_danger_100;
      }
    }
  }

  &-no-border {
    border: 1px solid rgba(0, 0, 0, 0);
  }

  &-label {
    @include label;
    color: $color_neutral_100;
    margin: 0 0 8px 0;
  }

  &-active {
    border: 1px solid $color_neutral_100;
  }

  &-error {
    border: 1px solid $color_danger_100;

    textarea {
      color: $color_danger_100;
    }
  }

  &-errormsg {
    position: absolute;
    right: 0;
    color: $color_danger_100;
    font-size: 12px;
    padding: 4px 0 0 0;
    text-align: left;
    line-height: 18px;
  }

  &-disabled {
    border: 1px solid $color_neutral_40;
    background-color: $color_neutral_10;

    &:hover {
      .area-disabled-edit-button {
        opacity: 1;
      }
    }

    &-edit-button {
      position: absolute;
      top: 8px;
      right: 8px;
      opacity: 0;
    }
  }
}

::placeholder {
  @include body-1;
  color: $color_neutral_40;
}

textarea:disabled {
  background-color: $color_neutral_10;
  color: $color_neutral_60;
}

textarea:disabled::-webkit-input-placeholder {
  color: $color_neutral_60;
}
textarea:disabled:-moz-placeholder {
  color: $color_neutral_60;
}
textarea:disabled::-moz-placeholder {
  color: $color_neutral_60;
}
textarea:disabled:-ms-input-placeholder {
  color: $color_neutral_60;
}
</style>
