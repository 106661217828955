
<div class="settings">
  <div>
    <div class="settings-content-modal-back">
      <submit-button
        btnStyle="secondary"
        size="medium"
        label="Back to app"
        iconClass="ri-arrow-left-line"
        @submit="closeModal"
      />
    </div>
    <div class="settings-content-modal-sidebar">
      <sidebar :groups="groups" />
    </div>
  </div>
  <modal
    class="settings-modal"
    isVisible
    size="large"
    :title="modalTitle"
    :secondTitle="workspaceName"
    crossSize="large"
    noEscape
    @close="closeModal"
  >
    <template v-slot:body>
      <div class="settings-content">
        <div class="settings-content-modal">
          <div class="settings-content-modal-content">
            <router-view :isWorkspaceAdmin="isWorkspaceAdmin"></router-view>
          </div>
        </div>
      </div>
    </template>
  </modal>
</div>
