<template>
  <div class="tracks-sharings">
    <Sharingsview
      entityType="track"
      :entityIds="entityIds"
      :entityName="entityName"
      :shareHash="shareHash"
    />
  </div>
</template>

<script>
import Sharingsview from '../../../../../../containers/shareViews/sharings';

export default {
  components: {
    Sharingsview
  },
  props: {
    entityIds: {
      type: Array,
      required: true
    },
    entityName: {
      type: String,
      required: false,
      default: ''
    },
    shareHash: {
      type: String,
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
.tracks-sharings {
  margin-top: 24px;
}
</style>
