<template>
  <div>
    <div class="links-explanation-modal-text">{{ $t('linksExplanation') }}</div>
    <div class="links-explanation-modal-checkbox">
      <checkbox id="not-show-again" :label="$t('dontShowAgain')" @toggleCheckbox="toggleCheckbox" />
    </div>
    <div class="links-explanation-modal-buttons">
      <div class="links-explanation-modal-buttons--">
        <submit-button btnStyle="primary" size="medium" :label="$t('okGotIt')" @submit="close" />
      </div>
    </div>
  </div>
</template>

<script>
import { DONT_SHOW_LINKS_EXPLANATION_AGAIN } from '@/utils/constants';

export default {
  data() {
    return {
      isChecked: false
    };
  },
  methods: {
    toggleCheckbox(isChecked) {
      this.isChecked = isChecked;
      localStorage.setItem(DONT_SHOW_LINKS_EXPLANATION_AGAIN, this.isChecked);
    },
    close() {
      this.$emit('closeModal');
    }
  }
};
</script>

<style lang="scss" scoped>
.links-explanation-modal-text {
  padding: 24px 32px 0 32px;
  @include body-1;
}

.links-explanation-modal-checkbox {
  padding: 24px 32px;
}

.links-explanation-modal-buttons {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid $color_neutral_30;
  padding: 8px 32px;
}
</style>
