export const FORMAT_DURATION = {
  hmm: 'h:mm',
  minsec: '%{min} min %{sec} s'
};

export const formatDuration = (duration, format) => {
  if (!duration) {
    return null;
  }
  const hours = Math.floor(duration / 60 / 60);
  const minutes = Math.floor((duration / 60) % 60);
  const ss = duration % 60;
  const seconds = ss >= 10 ? ss : `0${ss}`;
  if (format === FORMAT_DURATION.minsec) {
    if (minutes === 0 && ss > 0) {
      return `${ss} sec`;
    }
    if (minutes > 0 && ss === 0) {
      return `${minutes} min`;
    }
    return `${minutes} min ${ss} sec`;
  }
  if (format === FORMAT_DURATION.hmm) {
    if (hours > 0 && minutes === 0 && ss > 0) {
      return `${hours} hr ${ss} sec`;
    }
    if (hours > 0 && minutes > 0 && ss === 0) {
      return `${hours} hr ${minutes} min`;
    }
    if (hours === 0 && minutes === 0 && ss > 0) {
      return `${ss} sec`;
    }
    if (hours === 0 && minutes > 0 && ss === 0) {
      return `${minutes} min`;
    }
    return hours > 0 ? `${hours} hr ${minutes} min ${ss} sec` : `${minutes} min ${ss} sec`;
  }
  if (hours) {
    if (minutes < 10) {
      return `${hours}:0${minutes}:${seconds}`;
    }
    return `${hours}:${minutes}:${seconds}`;
  }
  return `${minutes}:${seconds}`;
};

export const FORMAT_OPTIONS = {
  'DD/MM/YYYY': { day: 'numeric', month: 'numeric', year: 'numeric' },
  'DD/MM/YY': { day: 'numeric', month: 'numeric', year: '2-digit' },
  'MMM Do YY': {
    month: {
      month: 'short'
    },
    year: {
      year: '2-digit'
    }
  }
};

const suffixes = {
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th'
};

export const formatDate = (formatLocale, timestamp, format = FORMAT_OPTIONS['DD/MM/YYYY']) => {
  let locale = 'en-GB';
  if (!timestamp) {
    return null;
  }
  if (formatLocale) {
    locale = formatLocale;
  }
  if (timestamp === '< Multiple >') {
    return timestamp;
  }
  const formatedLocale = locale.includes('_') ? locale.replace('_', '-') : locale;
  const date = new Date(timestamp * 1000);
  if (format === FORMAT_OPTIONS['MMM Do YY']) {
    const pr = new Intl.PluralRules(formatedLocale, {
      type: 'ordinal'
    });
    const month = new Intl.DateTimeFormat(formatedLocale, FORMAT_OPTIONS['MMM Do YY'].month).format(
      date
    );
    const year = new Intl.DateTimeFormat(formatedLocale, FORMAT_OPTIONS['MMM Do YY'].year).format(
      date
    );
    const day = `${date.getDate()}${suffixes[pr.select(date.getDate())]}`;
    return `${month} ${day} ${year}`;
  }
  return new Intl.DateTimeFormat(formatedLocale, format).format(date);
};

export const formatDateForMutation = str =>
  str && str.split('/').length === 3
    ? `${str.split('/')[2]}-${str.split('/')[1]}-${str.split('/')[0]}`
    : '';

export const formatTimestampForMutation = timestamp => {
  if (timestamp) {
    const date = new Date(timestamp);
    const formattedDate = `${date.getFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDay() + 1}`;
    return formattedDate;
  }
  return null;
};

export const formatDateAsTimestamp = string => {
  try {
    const values = string.split('/');
    const date = new Date(`${values[2]}-${values[1]}-${values[0]}`).getTime();
    return date;
  } catch (err) {
    throw new Error('Invalid date for track', err);
  }
};

export const isFormattedDate = str =>
  str.match(/^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/);

export const newFormatDate = timestamp => {
  if (!timestamp) {
    return '';
  }
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  let date = new Intl.DateTimeFormat('ko-KR', options).format(timestamp);
  date = date.replace(/\./g, '-');
  date = date.replace(/ /g, '').slice(0, -1);
  return date;
};

export const moreThanAYear = timestamp => {
  const yearInJSTimestamp = 31536000000;
  const rightNow = Date.now();
  const product = rightNow - timestamp;
  return product > yearInJSTimestamp;
};
