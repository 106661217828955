<template>
  <ScrollShadow>
    <div class="sidebar">
      <div class="sidebar-options" v-for="group in groups" :key="group.title">
        <div class="sidebar-options-title" v-if="group.title">{{ group.title }}</div>
        <router-link
          v-for="option in group.options"
          :key="option.icon"
          :to="option.route"
          class="sidebar-options-item"
        >
          <div class="icon" :class="getIconClassName(option)">
            <div v-if="option.counter" class="icon-chip" />
          </div>
          <div class="sidebar-options-item-label">{{ option.label }}</div>
          <div v-if="option.counter" class="counter">
            {{ option.counter > 99 ? '99+' : option.counter }}
          </div>
          <div v-if="option.isNew" class="badge-new">
            <img src="/images/new-badge.svg" alt="new badge" />
          </div>
        </router-link>
      </div>
    </div>
  </ScrollShadow>
</template>

<script>
import ScrollShadow from '@/components/scrollShadow';

export default {
  components: {
    ScrollShadow
  },
  props: {
    groups: {
      type: Array,
      required: true
    }
  },
  methods: {
    getIconClassName(option) {
      return option.iconFill && this.$route.path.includes(option.route)
        ? option.iconFill
        : option.icon;
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar {
  position: relative;
  width: 180px;
  background-color: inherit;
  @include body-1;
  font-size: 16px;
  height: 100%;
  padding: 16px 0;
  box-sizing: border-box;
  &-options {
    margin: 0 0 16px 0;

    &-title {
      @include subtitle-2;
      color: $color_neutral_60;
      margin: 0 0 8px 24px;
    }

    &-item {
      height: 40px;
      display: flex;
      align-items: center;
      color: $color_neutral_100;
      text-decoration: none;
      padding: 0 0 0 24px;
      position: relative;
      &:hover {
        background-color: $color_neutral_10;
      }

      &-label {
        padding: 0 0 0 8px;
        flex: 1;
      }
      .badge-new {
        margin-right: 8px;
        display: flex;
      }
    }

    .active {
      color: $color_primary_100;
      background-color: $color_primary_10;
      font-weight: 600;
      .icon {
        font-weight: normal;
      }
      &:before {
        content: '';
        position: absolute;
        width: 2px;
        height: 100%;
        background-color: $color_primary_100;
        left: 0;
      }
    }
  }

  a {
    text-decoration: none;
  }
  .icon {
    position: relative;
    font-size: 16px;
  }
  .icon-chip {
    height: 5px;
    width: 5px;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: -1px;
    background-color: $color_danger_100;
    border: 1px solid $color_danger_10;
  }
  .counter {
    margin-right: 17px;
    height: 20px;
    padding: 0 4px;
    border-radius: 4px;
    background: $color_primary_100;
    display: flex;
    align-items: center;
    @include body-2;
    font-variant: tabular-nums;
    font-weight: 600;
    color: $color_neutral_0;
  }
}
</style>
