<template>
  <div class="searchbar" :class="isFocusActive && 'active'">
    <i v-if="icon" class="searchbar-icon" :class="icon" />
    <input
      class="searchbar-input"
      type="text"
      :placeholder="placeholder"
      v-model="inputValue"
      @blur="setActiveClass(false)"
      @focus="setActiveClass(true)"
      @input="$emit('update:fieldModel', $event.target.value)"
      spellcheck="false"
    />
    <i v-if="inputValue" @click="clearInputValue" class="ri-close-circle-fill searchbar-delete" />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    fieldModel: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      inputValue: this.fieldModel,
      isFocusActive: false
    };
  },
  watch: {
    fieldModel() {
      this.inputValue = this.fieldModel;
    }
  },
  methods: {
    setActiveClass(value) {
      this.isFocusActive = value;
    },
    clearInputValue() {
      this.inputValue = '';
      this.$emit('update:fieldModel', '');
    }
  }
};
</script>

<style lang="scss" scoped>
.searchbar {
  display: flex;
  align-items: center;
  background-color: $color_neutral_10;
  min-width: 200px;
  max-width: 708px;
  padding: 0 16px;
  box-sizing: border-box;
  border: 1px solid $color_neutral_40;
  border-radius: 4px;
  transition: background-color 200ms linear;

  &-icon {
    color: $color_neutral_60;
    font-size: 20px;
    margin: 0 8px 0 0;
  }

  &-input {
    font-family: inherit;
    outline: none;
    border: none;
    background-color: $color_neutral_10;
    color: $color_neutral_100;
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    transition: all 200ms linear;
  }

  &-delete {
    color: $color_neutral_60;
    font-size: 20px;
    margin: 0 0 0 8px;
    cursor: pointer;
  }
}

.active {
  background-color: $color_neutral_0;
  box-shadow: 0 0 0 1px $color_neutral_100;

  input {
    background-color: $color_neutral_0;
  }
}

::placeholder {
  color: $color_neutral_60;
  font-size: 14px;
  font-weight: 400;
}
</style>
