<template v-slot:body>
  <div class="add-member">
    <div class="information-banner-container">
      <div class="information-banner">
        <div class="icon-info"><i class="ri-information-fill"></i></div>
        <div class="text">
          You are currently using <span class="mail">{{ bannierNumberOfSeats }}</span>
          <span v-if="!isFreePlan">(5 free seats)</span>
        </div>
        <submit-button
          v-if="isFreePlan"
          class="information-banner-action"
          label="Upgrade"
          btnStyle="tertiary"
          size="small"
          @submit="goToOffersSettings"
        />
        <submit-button
          v-else
          class="information-banner-action"
          label="More info"
          btnStyle="tertiary"
          iconClass="ri-external-link-line"
          size="small"
          @submit="goToMoreInfo"
        />
      </div>
    </div>
    <div class="add-member-content">
      <form @submit.prevent="submit" class="add-member-content-form">
        <div
          class="add-member-content-form-email"
          v-for="(invitation, index) in invitations"
          :key="`invitations-${invitation.id}`"
        >
          <div class="add-member-content-form-email-textfield">
            <text-field
              inputType="text"
              :errorMessage="getMemberValidationMessage(index)"
              :fieldModel.sync="invitation.email"
              :placeholder="$t('enterEmail')"
              :label="index === 0 ? $t('email') : null"
              :large="false"
              trimValue
            />
          </div>
          <div class="add-member-content-form-email-dropdown">
            <div v-if="index === 0" class="add-member-content-form-label-role">
              {{ $t('role') }}
            </div>
            <roles-dropdown
              :roles="roleListInvit"
              :userRole="roleListInvit.find(role => role.value === '2')"
              :userId="invitation.id"
              withBorder
              @setRole="setInvitationRole"
            />
          </div>
          <div :class="[index === 0 ? 'add-member-content-form-email-remove-first' : '']">
            <icon-button
              v-if="invitations.length > 1"
              icon="ri-delete-bin-line"
              btnStyle="tertiary"
              size="small"
              isDanger
              @submit="removeInvitation(index)"
            />
          </div>
        </div>
        <div class="add-member-content-form-add">
          <div class="add-member-btn">
            <v-popover
              trigger="hover"
              container="#addMemberButtonId"
              placement="top"
              :disabled="memberLimitPopoverDisabled"
            >
              <!-- This will be the popover target (for the events and position) -->
              <div id="addMemberButtonId" class="tooltip-target b3">
                <submit-button
                  class="primary-colored-tertiary"
                  btnStyle="tertiary"
                  size="small"
                  :label="$t('addMember')"
                  :disabled="addMemberDisabled"
                  iconClass="ri-add-line"
                  @submit="addInvitation"
                />
              </div>
              <!-- This will be the content of the popover -->
              <template slot="popover">
                <div class="upgrade-popover">
                  <div class="upgrade-popover-text">
                    You need to upgrade to a pro plan to invite more people
                  </div>
                  <submit-button
                    class="upgrade-popover-btn"
                    @click.native.stop
                    btnStyle="primary"
                    size="small"
                    :label="currentWorkspace.free_trial_used ? $t('upgrade') : $t('startFreeTrial')"
                    @submit="goToOffersSettings"
                  />
                </div>
              </template>
            </v-popover>
          </div>
        </div>
        <div class="add-member-content-form-btn">
          <div v-if="!loading" class="copy-invitation-container">
            <submit-button
              v-if="inviteLink"
              class="primary-colored-tertiary mr-8"
              btnStyle="tertiary"
              :label="$t('copyInviteLink')"
              iconClass="ri-links-fill"
              @submit="copyInvitationLink"
            />
            <submit-button
              v-else
              class="primary-colored-tertiary"
              btnStyle="tertiary"
              :label="$t('createAndCopyInviteLink')"
              iconClass="ri-links-fill"
              @submit="createAndCopyInvitationLink"
            />
            <more-dropdown
              @click.native.prevent
              v-if="inviteLink"
              size="medium"
              class="primary-colored-tertiary"
              :items="invitationLinkMoreActions"
            />
          </div>
          <div v-else></div>
          <submit-button
            :label="$tc('sendInvites', invitations.length)"
            :disabled="submitDisabled"
            iconClass="ri-mail-send-line"
            @submit="submit"
            :pending="isSubmitted"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { get as getRoute } from '@/router/routes';
import {
  getPlanName,
  getMembersSeats,
  getMaximumMembersSeats
} from '@/utils/functions/subscription';
import { email, maxLength, required } from 'vuelidate/lib/validators';

import { apolloClient } from '@/main';
import { bus } from '@/utils/bus';
import { getGraphQlErrorCode } from '@/utils/functions/global';

import { ME } from '@/graphql/queries/user';
import {
  SEND_WORKSPACE_BULK_INVITATION,
  CREATE_WORKSPACE_INVITATION_LINK,
  DELETE_WORKSPACE_INVITATION_LINK
} from '@/graphql/mutations/invitation';
import { WORKSPACE_INVITATION_LINK } from '@/graphql/queries/invitation';

export default {
  props: {
    workspaceId: {
      type: String,
      required: true
    },
    roleListInvit: {
      type: Array,
      required: false,
      default: () => {}
    }
  },
  apollo: {
    workspaceInvitationLink: {
      query: WORKSPACE_INVITATION_LINK,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          workspaceId: this.currentWorkspace.id
        };
      },
      watchLoading(loading) {
        this.loading = loading;
      },
      result(res) {
        this.loading = false;
        if (res.data) {
          const dayInTimestamp = 86400;
          const dayLeftInTimestamp = Math.round(
            res.data.workspaceInvitationLink.valid_to - new Date().getTime() / 1000
          );
          const dayLeft = Math.round(dayLeftInTimestamp / dayInTimestamp);
          this.inviteLinkExpiration = dayLeft < 1 ? 1 : dayLeft;
          this.inviteLink = res.data;
        }
      },
      // catch the error if undefined
      error() {}
    }
  },
  data() {
    return {
      isSubmitted: false,
      invitations: [
        {
          email: '',
          roleId: '2',
          id: Math.random().toString(36).substring(7)
        }
      ],
      inviteLink: undefined,
      inviteLinkExpiration: null,
      invitationLinkMoreActions: [
        {
          name: this.$t('deleteInviteLink'),
          danger: true,
          action: this.deleteWorkspaceInvitationLink
        }
      ],
      loading: true
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    isFreePlan() {
      return getPlanName(this.currentWorkspace) === 'free';
    },
    submitDisabled() {
      this.$v.$touch();
      return this.$v.invitations.$error || this.isSubmitted;
    },
    memberLimitPopoverDisabled() {
      if (this.isFreePlan) {
        return (
          this.invitations.length + getMembersSeats(this.currentWorkspace) <=
          getMaximumMembersSeats(this.currentWorkspace)
        );
      }
      return true;
    },
    addMemberDisabled() {
      if (this.isFreePlan) {
        return this.invitations.length >= this.availableSeats();
      }
      // The modal can't add more 5 members at a time
      return this.invitations.length > 4;
    },
    bannierNumberOfSeats() {
      const txt = this.isFreePlan
        ? ` of your ${getMaximumMembersSeats(this.currentWorkspace)} free member seats.`
        : ' member seats ';
      return ` ${getMembersSeats(this.currentWorkspace)} ${txt}`;
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    goToOffersSettings() {
      this.$router.push(getRoute('offersSettings'));
      this.$emit('closeModal');
    },
    submit() {
      this.isSubmitted = true;
      const invitationsToSend = this.invitations.map(el => ({
        email: el.email,
        role_id: parseInt(el.roleId, 10)
      }));
      this.$apollo
        .mutate({
          mutation: SEND_WORKSPACE_BULK_INVITATION,
          variables: {
            workspaceId: this.workspaceId,
            invitations: invitationsToSend
          },
          refetchQueries: [
            {
              query: ME
            }
          ]
        })
        .then(() => {
          this.$emit('closeModal');

          apolloClient.query({ query: ME }).then(() => {
            bus.$emit('showAlert', {
              message: { key: 'invitationSuccess', params: invitationsToSend.length },
              style: 'success',
              delay: 5000
            });
          });
        })
        .catch(error => {
          this.$emit('closeModal');
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    addInvitation() {
      this.$v.$touch();
      if (
        this.invitations.length <= this.availableSeats() ||
        getMaximumMembersSeats(this.currentWorkspace) === -1
      ) {
        this.invitations.push({
          email: '',
          roleId: '2',
          id: Math.random().toString(36).substring(7)
        });
      }
    },
    removeInvitation(index) {
      this.invitations.splice(index, 1);
    },
    setInvitationRole(params) {
      const member = this.invitations.find(invitation => invitation.id === params.userId);
      member.roleId = params.role.value;
    },
    getMemberValidationMessage(index) {
      if (
        !this.$v.invitations.$each[index].email.email ||
        !this.$v.invitations.$each[index].email.isDiacriticFree
      ) {
        return this.$t('invalidEmail');
      }
      if (!this.$v.invitations.$each[index].email.maxLength) {
        return this.$t('maxLength', {
          nbChars: this.$v.invitations.$each[index].email.$params.maxLength.max,
          label: this.$t('email')
        });
      }
      if (!this.$v.invitations.$each[index].email.unique) {
        return this.$t('emailTwice');
      }
      return null;
    },
    createAndCopyInvitationLink() {
      this.$apollo
        .mutate({
          mutation: CREATE_WORKSPACE_INVITATION_LINK,
          variables: {
            workspaceId: this.workspaceId
          }
        })
        .then(() => {
          this.$apollo.queries.workspaceInvitationLink.refetch().then(() => {
            this.copyInvitationLink();
          });
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    copyInvitationLink() {
      const dummy = document.createElement('textarea');
      document.body.appendChild(dummy);
      dummy.value = `${this.inviteLink.workspaceInvitationLink.link}`;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
      bus.$emit('showAlert', {
        message: { key: 'linkCopiedExpireIn', params: this.inviteLinkExpiration },
        style: 'success',
        delay: 5000
      });
    },
    deleteWorkspaceInvitationLink() {
      this.$apollo
        .mutate({
          mutation: DELETE_WORKSPACE_INVITATION_LINK,
          variables: {
            workspaceId: this.workspaceId
          }
        })
        .then(() => {
          this.inviteLink = undefined;
          bus.$emit('showAlert', {
            message: { key: 'inviteLinkDeleted' },
            style: 'success',
            delay: 5000
          });
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    goToMoreInfo() {
      window
        .open(
          'https://www.notion.so/bridgeaudio/Prorated-invoices-and-calculations-1499b4a298fe487c9d9df1038c5a3a3e',
          '_blank'
        )
        .focus();
    },
    availableSeats() {
      return getMaximumMembersSeats(this.currentWorkspace) - getMembersSeats(this.currentWorkspace);
    }
  },
  validations: {
    invitations: {
      $each: {
        email: {
          required,
          email,
          maxLength: maxLength(50),
          isDiacriticFree(value) {
            const regexp = new RegExp(/^[a-zA-Z0-9._+-]+@/);
            return regexp.test(value);
          },
          unique(value) {
            const arr = this.invitations.map(invitation => invitation.email);
            return (
              arr.filter(
                (invitation, index) =>
                  invitation === value && arr.indexOf(invitation) !== index && invitation !== ''
              ).length === 0
            );
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.add-member {
  &-content {
    padding: 32px 24px 0 24px;
    line-height: 150%;

    &-title {
      font-size: 32px;
      padding: 0 32px 24px 0;
      text-align: center;
    }

    &-helper {
      width: 458px;
      font-size: 14px;
      line-height: 150%;
      margin: 0 auto 40px;
      padding: 0 32px 0 0;
    }

    &-form {
      &-label {
        display: flex;

        &-email {
          width: 356px;
        }

        &-role {
          @include label;
          margin-bottom: 8px;
        }
      }

      &-email {
        display: flex;
        align-items: center;
        margin: 0 0 24px 0;

        &-textfield {
          position: relative;
          width: 430px;
          margin: 0 16px 0 0;
        }

        &-dropdown {
          width: 140px;
          margin: 0 4px 0 0;
        }

        &-remove-first {
          transform: translateY(13px);
        }
      }

      &-add {
        margin-bottom: 24px;
        .add-member-btn {
          width: fit-content;
        }
      }

      &-btn {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $color_neutral_30;
        padding: 8px 24px;
        width: 100%;
        margin-left: -24px;
        .copy-invitation-container {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
.mr-8 {
  margin-right: 8px;
}
.upgrade-popover {
  padding: 8px;
  text-align: center;
  width: 220px;
  &-text {
    margin-bottom: 8px;
  }
  &-btn {
    width: 100%;
  }
}

.information-banner {
  &-container {
    width: 100%;
    padding: 24px 24px 0 24px;
    box-sizing: border-box;
  }
  box-sizing: border-box;
  height: fit-content;
  height: 40px;
  padding: 0 16px;
  border-radius: 4px;
  background-color: $color_primary_10;
  display: flex;
  align-items: center;
  @include body-1;
  font-variant-numeric: tabular-nums;
  color: $color_primary_100;
  .text {
    flex-grow: 1;
    .mail {
      font-weight: 600;
    }
    .resend {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .icon {
    &-info {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      background: rgba(71, 35, 173, 0.1);
      color: $color_primary_100;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-close {
      flex-shrink: 0;
    }
  }
}
</style>

<style lang="scss">
.upgrade-popover-btn.button.primary {
  background-color: $color_neutral_0;
  color: $color_neutral_100;
  &:hover {
    background-color: $color_neutral_30;
  }
}
.primary-colored-tertiary.button.tertiary {
  color: $color_primary_100;
  &:hover {
    background-color: $color_primary_10;
  }
}
.primary-colored-tertiary.more {
  .rounded-button.tertiary.medium {
    .icon {
      color: $color_primary_100;
    }
    &:hover {
      background-color: $color_primary_10;
    }
  }
}
.primary-colored-tertiary.button.tertiary.disabled {
  &:hover {
    background-color: unset;
  }
}

.information-banner-action.button.tertiary.small {
  color: $color_primary_100;
  border: 1px solid rgba(0, 0, 0, 0);
  i {
    color: $color_primary_100;
  }
  &:hover {
    background: $color_primary_10;
    border: 1px solid $color_primary_100;
  }
}
</style>
