
<div class="share-by-mail-body">
  <div class="upgrade-banner" v-if="sharesExpiresIn">
    <upgrade-banner
      :content="`Shared links expire after ${sharesExpiresIn} days with the Starter plan`"
      :roleId="myRoleId"
      @closeModal="$emit('closeModal')"
    />
  </div>
  <div class="share-by-mail-body-title">{{ $t('sendByEmail') }}</div>
  <div class="share-by-mail-body-desc">{{ $t('sendByEmailDesc') }}</div>
  <div class="share-by-mail-body-to">
    <p class="emailPicker-label">{{ $t('from') }}</p>
    <div v-if="UserEmails">
      <from-email-picker
        ref="emailPickerComponent"
        :emailReplyTo="emailReplyTo"
        :UserEmails="UserEmails"
        :UserEmailsSorted="UserEmailsSorted"
        :getDefaultNoReplyEmail="getDefaultNoReplyEmail"
        @setEmailReplyTo="setEmailReplyTo"
        @resendConfirmationEmail="resendConfirmationEmail"
        @goToSettings="goToSettings"
      />
    </div>
    <div v-else class="emailPicker-loader">
      <spinner-without-progress color="grey" :size="32" />
    </div>
  </div>
  <div class="share-by-mail-body-to">
    <email-area
      ref="emailArea"
      :label="$t('toCapitalize')"
      :placeholder="$t('enterEmail')"
      :errorMessage="emailListErrorMsg"
      :autocompleteEmails="autocompleteEmails"
      @handleInput="searchEmails"
      @handleOptionClick="resetSearchEmails"
      @handleResult="setEmailList"
    />
    <div class="to-info" v-tooltip="$t('toInfo')">
      <i class="ri-question-fill"></i>
    </div>
    <div class="send-me-copy">
      <checkbox
        id="sendCopyToSelf"
        :label="$t('sendMeACopy')"
        @toggleCheckbox="toggleSendCopyToSelf"
      />
    </div>
  </div>
  <div class="share-by-mail-body-message">
    <text-area
      :label="$t('optionalMessage')"
      adaptativeHeight
      :fieldModel.sync="message"
      :characterLimit="$v.message.$params.maxLength.max"
      :errorMessage="messageErrorMsg"
    />
  </div>
  <access-rights
    :accessRights="emailAccessRights"
    :isProject="isProject"
    type="email"
    class="access-rights-email"
    @setAccessRights="setEmailAccessRights"
  />
  <share-security-section
    ref="sharePasswordToggle"
    :displayPasswordInput="displaySharePassword"
    :securityPassword.sync="sharePassword"
    :securityPasswordErrorMessage="passwordErrorMessage"
    @toggleSwitch="toggleSharePassword"
  />
</div>
