
<div class="projectPicker-container">
  <div class="header">
    <i class="ri-search-line" />
    <input
      ref="search"
      class="search"
      type="text"
      :placeholder="labels.searchPlaceholder"
      @input="$emit('updateSearchValue', $event.target.value)"
    />
  </div>

  <div class="projectList">
    <!-- LOADING -->
    <div v-if="loadingProjects" class="projectList-centered">
      <spinner color="grey" />
    </div>
    <div v-else class="projectList-container">
      <!-- SEARCH NO RESULTS -->
      <div v-if="projects.length === 0 && noSearchResult" class="projectList-centered">
        {{ labels.noResultLabel }}
      </div>

      <!-- NO PROJECTS & NO SEARCH -->
      <div v-if="projects.length === 0 && !noSearchResult" class="projectList-centered">
        <div class="projectList-emptyLabel">{{ emptyPlaceholder.text }}</div>
        <submit-button
          size="small"
          :label="emptyPlaceholder.buttonLabel"
          :iconClass="emptyPlaceholder.buttonIcon"
          @submit="handleClickCreate"
        />
      </div>

      <!-- LISTE PROJECT -->
      <div v-if="projects.length > 0">
        <div
          v-for="project in projects"
          :key="project.id"
          class="projectItem"
          :class="selectedProjectId === project.id && 'projectItem-selected'"
          @click.stop="handleProjectItemClick(project.id)"
          @dblclick.stop="handleClickAdd"
        >
          <i
            class="ri-folder-fill projectItem-icon"
            :class="selectedProjectId === project.id && 'projectItem-iconSelected'"
          />
          <div class="projectItem-label">{{ project.label }}</div>
        </div>
        <div v-if="fetchingMoreProjects" class="loader">
          <skeleton />
        </div>
        <observer v-if="showObserver" :height="32" bottom @intersect="loadMore" />
      </div>
    </div>
  </div>

  <div class="footer">
    <submit-button
      btnStyle="secondary"
      size="small"
      :iconClass="`ri-folder-add-line`"
      :label="$t('create')"
      @submit="handleClickCreate"
    />
    <div class="footer-right">
      <submit-button
        btnStyle="secondary"
        size="small"
        :label="labels.cancelButtonLabel"
        @click.native.stop="handleClickCancel"
      />
      <submit-button
        size="small"
        :disabled="!selectedProjectId"
        :label="labels.addButtonLabel"
        @click.native.stop="handleClickAdd"
      />
    </div>
  </div>
</div>
