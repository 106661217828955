import get from 'lodash.get';

const EURO_COUNTRIES = [
  '', // FAILBACK when /country return empty string
  'AT', // Autriche
  'BE', // Belgique
  'CY', // Chypre
  'EE', // Estonie
  'FI', // Finlande
  'FR', // France
  'DE', // Allemagne
  'GR', // Grèce
  'IE', // Irlande
  'IT', // Italia
  'LV', // Lettonia
  'LT', // Lituania
  'LU', // Luxembourg
  'MT', // Malta
  'NL', // Netherland
  'PT', // Portugal
  'SK', // Slovakia
  'SI', // Slovenia
  'ES' // Spain
];

export const isUserInEuroZone = countryCode => {
  // FUCK SINGLE LINE METHODS
  const isInEurope = EURO_COUNTRIES.includes(countryCode);
  return isInEurope;
};

export const formatPrice = (paramPrice, currency, isNegative) => {
  let price = 0;
  if (paramPrice) {
    price = paramPrice;
  }
  const formattedPrice = price / 100;
  const hasDecimal = !!(formattedPrice % 1);
  const updatedPrice = hasDecimal ? Number(formattedPrice).toFixed(2) : formattedPrice;
  const minus = isNegative ? '-' : '';
  const symbol = currency === 'eur' ? '€' : '$';
  return `${minus}${updatedPrice}${symbol}`;
};

export const getUserInitials = user => {
  const firstname = get(user, 'firstname') || '';
  const lastname = get(user, 'lastname') || '';
  const email = get(user, 'email') || '';

  let initials = `${firstname.charAt(0)}${lastname.charAt(0)}`;
  if (initials.length === 0) {
    initials = email.charAt(0);
  }
  return initials.toUpperCase();
};

export const getUserNames = user => {
  if (!user) {
    return '';
  }
  const usernames = [];
  if (user.firstname !== null) {
    usernames.push(user.firstname);
  }
  if (user.lastname !== null) {
    usernames.push(user.lastname);
  }
  return usernames.join(' ');
};

export const getUserPicture = user => get(user, 'profile_picture.url', '');

export const getUserEmail = user => get(user, 'email', '');
