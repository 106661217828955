
<div>
  <action-bar
    :numberOfSelection="headerCountLabel"
    :actions="actionBarBtns"
    :label="actionBarLabels"
    @selectAll="dataBus.$emit('selectAllTracks', true)"
    @unselect="dataBus.$emit('selectAllTracks', false)"
  />
  <div
    v-if="displayShareDropdown"
    class="shareDropdown"
    v-click-outside="{ hide: hideShareDropdown }"
  >
    <div class="menu">
      <div
        :class="[
          option.separator ? 'separator' : 'menu-item',
          option.subText ? 'menu-item-with-sub' : ''
        ]"
        v-for="(option, index) in shareBtnOptions"
        :key="index"
        @click="handleShareBtnClick(option)"
      >
        <div class="item" v-if="!option.separator">
          <i :class="option.icon"></i>
          <span class="menu-item-label">
            {{ option.label }}
          </span>
        </div>
        <div v-if="option.subText" class="menu-item-sub">{{ option.subText }}</div>
      </div>
    </div>
  </div>
</div>
