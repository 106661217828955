<template>
  <div class="container">
    <h2 class="headline">{{ $t('subscription.headline.invoice') }}</h2>
    <div class="header grid">
      <div class="grid-header">Date</div>
      <div class="grid-header">Description</div>
      <div class="grid-header">Total</div>
      <div class="grid-header">Status</div>
    </div>
    <div v-for="invoice in invoices" :key="invoice.id" class="grid grid-row">
      <div class="grid-item">{{ getFormatDate(invoice) }}</div>
      <div class="grid-item">{{ getSubscriptionInfos() }}</div>
      <div class="grid-item">{{ getFormattedPrice(invoice) }}</div>
      <div class="grid-item">
        <div>
          <p class="invoice-status">
            {{ getSubscriptionStatus(invoice) }}
          </p>
          <p><a :href="invoice.invoice_link">View invoice</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import get from 'lodash.get';

import { formatPrice } from '@/utils/functions/user';

export default {
  props: {
    invoices: {
      type: Array,
      required: false,
      default: () => []
    },
    subscriptions: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  computed: {},
  methods: {
    getFormatDate(invoice) {
      return format(invoice.date * 1000, 'LLL d, y');
    },
    getFormattedPrice(invoice) {
      return formatPrice(invoice.amount, invoice.currency);
    },
    getSubscriptionInfos() {
      const planName = get(this.subscriptions, 'plan.name');
      const period = get(this.subscriptions, 'payment_information.period');
      return period ? `${planName} (${period})` : planName;
    },
    getSubscriptionStatus(invoice) {
      return invoice.status;
    }
  }
};
</script>

<style lang="scss" scoped>
.headline {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 32px;
}

.header {
  border-bottom: 1px solid $color_neutral_30;

  div {
    border-right: 1px solid $color_neutral_30;
    &:last-child {
      border-right: none;
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 25fr);
  grid-auto-rows: minmax(100px, auto);
  height: 40px;
}

.grid-header {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.grid-row {
  border-bottom: 1px dotted $color_neutral_40;
  height: 56px;
  &:last-child {
    border-bottom: none;
  }
}
.grid-item {
  height: 56px;
  padding: 0 8px;
  display: flex;
  align-items: center;
}

.invoice-status {
  text-transform: capitalize;
}
</style>
