
<div :class="id" v-click-outside="{ hide: hideExpand }">
  <div class="input-dropdown" :style="`width: ${width}px;`">
    <div class="input-dropdown-edit" v-if="disabled && withEditButton">
      <submit-button
        btnStyle="tertiary"
        exception
        danger
        size="small"
        label="Edit"
        @submit="handleEditBtnClick"
      />
    </div>
    <div
      :class="{
        'input-dropdown-area': true,
        'input-dropdown-area-focus': isFocus,
        'input-dropdown-area-multiple': isMultipleSelection,
        'input-dropdown-area-disabled': disabled,
        'input-dropdown-area-error': errorMessage
      }"
      :style="`width: ${width}px;`"
      @click.self="handleAreaClick"
    >
      <div
        :class="{
          'input-dropdown-area-selected-option': true,
          'input-dropdown-area-hidden-option': !isMultipleSelection
        }"
        :style="`max-width: ${width - 60}px;`"
        v-for="option in selectedOptions"
        :key="`selected-${option.id}`"
      >
        <div class="input-dropdown-area-selected-option--">{{ option.value }}</div>
        <i class="ri-close-circle-fill" @click.stop="removeOptionOnClick(option.id)" />
      </div>
      <div
        class="input-dropdown-placeholder"
        v-if="defaultOptions.length === 0 && placeholder && disabled && isMultipleSelection"
      >
        {{ placeholder }}
      </div>
      <input
        :class="{
          'input-dropdown-area-input': true,
          'input-dropdown-area-multiple-input': isMultipleSelection,
          'input-dropdown-area-unique-input': !isMultipleSelection
        }"
        type="text"
        ref="input"
        v-model="newValue"
        :placeholder="placeholder"
        :style="isMultipleSelection ? `width: ${inputWidth}px;` : 'width: 100%;'"
        :disabled="disabled"
        @keydown.tab="hideExpand"
        @keydown="handleKeydown"
        @keyup.8="removeOptionOnKeyup"
        @focus="handleFocus"
        @blur="handleBlur"
        @input="handleInput"
      />
      <i class="ri-arrow-drop-down-line" />
    </div>
    <div
      class="input-dropdown-expand"
      :style="`width: ${width}px;`"
      v-if="isExpanded"
      ref="dropdownExpand"
    >
      <div class="input-dropdown-expand-wrapper">
        <div
          class="input-dropdown-expand-wrapper-section"
          v-if="optionList.length > 0 || loadingOptions"
        >
          <div class="input-dropdown-expand-wrapper-section-loader" v-if="loadingOptions">
            <spinner color="grey" />
          </div>
          <div v-else>
            <div
              class="input-dropdown-expand-wrapper-section-option"
              v-for="(option, index) in filteredOptionList"
              :class="{
                'highlighted-option': highlightId === index
              }"
              ref="unselectedOptions"
              :key="`${option.id}-${index}`"
              @mouseenter="setHighlightedId(index)"
              @mousedown.stop="handleOptionClick(option)"
              v-tooltip="{
                content: option.value.length * 6.5 > width - 59 ? option.value : '',
                delay: { show: 500, hide: 0 }
              }"
            >
              <checkbox
                v-if="isMultipleSelection"
                :id="option.id"
                @toggleCheckbox="handleOptionClick(option)"
              />
              <div
                class="input-dropdown-expand-wrapper-section-option--"
                :style="`width:${width - 59}px`"
              >
                {{ option.value }}
              </div>
            </div>
            <div
              class="input-dropdown-expand-wrapper-section-skeleton"
              v-if="fetchingMoreOptions"
            >
              <skeleton />
            </div>
          </div>
        </div>
        <div
          class="input-dropdown-expand-wrapper-section section-create"
          v-if="showAddButton || maxError"
        >
          <!-- using mousedown instead of click here to trigger before blur to avoid computed showAddButton to be false before executing the handleClickAddNew method -->
          <div
            v-if="!maxError"
            class="input-dropdown-expand-wrapper-section-option input-dropdown-expand-wrapper-section-add"
            :class="{
              'highlighted-create': highlightId === null
            }"
            @mouseenter="setHighlightedId(null)"
            @mousedown.stop="handleClickAddNew"
            ref="createOption"
          >
            <i class="ri-add-circle-line" />
            <i class="ri-add-circle-fill" />
            <div class="input-dropdown-expand-add">{{ `Create ${newValue}` }}</div>
          </div>
          <div
            v-else
            class="input-dropdown-expand-wrapper-section-option input-dropdown-expand-wrapper-section-error"
          >
            <i class="ri-alert-fill"></i>
            {{ maxError }}
          </div>
        </div>
        <observer
          v-if="showObserver"
          :height="15"
          bottom
          @intersect="$emit('fetchMoreOptions')"
        />
      </div>
    </div>
    <div class="input-dropdown-error">{{ errorMessage }}</div>
  </div>
</div>
