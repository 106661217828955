
<div class="information-banner">
  <div class="icon-info"><i class="ri-information-fill"></i></div>
  <div class="text">AI is currently tagging your tracks, the process may take a few minutes.</div>
  <icon-button
    class="icon-close-mail"
    btnStyle="tertiary"
    icon="ri-close-fill"
    size="small"
    @submit="hide"
  />
</div>
