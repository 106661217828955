
<div>
  <div class="container">
    <p>{{ $t('modalUpgradeFreeTrial.line1', { dateEnd: getFreeTrialEndDate() }) }}</p>
    <p>
      {{ $t('modalUpgradeFreeTrial.line2') }}
    </p>
    <p>
      <b>{{ $t('modalUpgradeFreeTrial.line3') }}</b>
    </p>
  </div>
  <div class="buttons">
    <submit-button
      btnStyle="tertiary"
      size="medium"
      :label="$t('modalUpgradeFreeTrial.cancelButton')"
      @submit="closeModal"
    />
    <submit-button
      btnStyle="primary"
      size="medium"
      :label="$t('modalUpgradeFreeTrial.confirmButton')"
      @submit="upgradePlan"
    />
  </div>
</div>
