import gql from 'graphql-tag';
import { invitationsFragment } from '../fragments';

export const SEND_WORKSPACE_BULK_INVITATION = gql`
  mutation sendWorkspaceBulkInvitation($workspaceId: ID!, $invitations: [InviteInput]) {
    sendWorkspaceBulkInvitation(workspaceId: $workspaceId, invitations: $invitations) {
      ...invitations
    }
  }
  ${invitationsFragment.invitations}
`;

export const DELETE_INVITATION = gql`
  mutation deleteInvitation($invitationId: ID!) {
    deleteInvitation(invitationId: $invitationId)
  }
`;

export const RESEND_INVITATION = gql`
  mutation ($invitationId: ID!) {
    resendInvitation(invitationId: $invitationId) {
      name
    }
  }
`;

export const CREATE_WORKSPACE_INVITATION_LINK = gql`
  mutation createWorkspaceInvitationLink($workspaceId: ID!) {
    createWorkspaceInvitationLink(workspaceId: $workspaceId) {
      id
      link
      valid_to
    }
  }
`;

export const DELETE_WORKSPACE_INVITATION_LINK = gql`
  mutation deleteWorkspaceInvitationLink($workspaceId: ID!) {
    deleteWorkspaceInvitationLink(workspaceId: $workspaceId)
  }
`;
