<template>
  <div v-if="isVisible" :class="`alert ${alertStyle}`">
    <div class="alert-msg">
      <i v-if="iconClass" :class="`alert-msg-icon ${iconClass}`" />
      <div>{{ message }}</div>
    </div>
    <div v-if="actionLabel" class="alert-action">
      <div class="alert-action-separator" />
      <submit-button
        class="alert-action-button"
        :class="{ 'is-countdown': actionCountdown }"
        :style="`animationDuration: ${actionCountdown}ms; animationDelay: ${animationDelay}ms`"
        btnStyle="tertiary"
        size="small"
        :label="actionLabel"
        :disabled="actionDisabled"
        @submit="$emit('handleAlertClick')"
      />
    </div>
    <div class="alert-close" v-if="!hideDismissible">
      <div class="alert-action-separator" />
      <icon-button
        class="alert-close-btn"
        icon="ri-close-fill"
        btnStyle="tertiary"
        size="small"
        @submit="$emit('closeAlert', false)"
      />
    </div>
  </div>
</template>

<script>
import SubmitButton from '../buttons/submitButton';
import IconButton from '../iconButton';

export default {
  components: {
    SubmitButton,
    IconButton
  },
  props: {
    message: {
      type: String,
      required: true
    },
    alertStyle: {
      type: String,
      required: false,
      default: ''
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: true
    },
    hideDismissible: {
      type: Boolean,
      required: false,
      default: false
    },
    actionLabel: {
      type: String,
      required: false,
      default: ''
    },
    handleAlertClick: {
      type: Function,
      required: false,
      default: () => {}
    },
    actionCountdown: {
      type: Number,
      required: false,
      default: null
    }
  },
  mounted() {
    this.reset();
  },
  updated() {
    this.reset();
  },
  data() {
    return {
      actionDisabled: false,
      animationDelay: 1000
    };
  },
  computed: {
    iconClass() {
      let icon = '';
      if (this.alertStyle.includes('success')) {
        icon = 'ri-checkbox-circle-fill';
      } else if (this.alertStyle.includes('warning')) {
        icon = 'ri-error-warning-fill';
      } else if (this.alertStyle.includes('danger')) {
        icon = 'ri-alert-fill';
      }
      return icon;
    }
  },
  methods: {
    reset() {
      this.actionDisabled = false;
      if (this.actionCountdown) {
        setTimeout(() => {
          this.actionDisabled = true;
        }, this.actionCountdown + this.animationDelay);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.alert {
  max-width: 700px;
  width: max-content;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  transition: opacity 300ms linear;
  background-color: $color_neutral_100;
  @include shadow-down-03;
  &-msg {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    color: $color_neutral_0;
    padding: 0px 16px;
    align-items: center;
    line-height: 100%;
    &-icon {
      font-size: 16px;
      margin: 0 4px 0 0;
      display: block;
    }
  }

  &-close {
    display: flex;
    color: $color_neutral_60;
    height: 32px;
    cursor: pointer;
    &-btn {
      margin: 0 16px;
    }
  }

  &-action {
    display: flex;
    &-button {
      color: $color_neutral_0;
      margin: 0 16px;
      &:hover {
        background-color: rgba(71, 35, 173, 0.3);
        color: $color_primary_50;
      }
    }
    &-separator {
      width: 1px;
      height: 32px;
      background: $color_neutral_80;
    }
  }
  .ri-checkbox-circle-fill {
    color: $color_success_100;
  }

  .ri-error-warning-fill {
    color: $color_warning_100;
  }

  .ri-alert-fill {
    color: $color_danger_100;
  }

  .ri-close-line {
    display: block;
  }
}
.no-max-width {
  max-width: unset;
}

@keyframes emptyingBtn {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}

.is-countdown {
  background: linear-gradient(90deg, rgba(67, 65, 73, 1) 50%, rgba(0, 0, 0, 0) 50%);
  background-size: 200% 200%;
  animation: emptyingBtn linear;
  animation-fill-mode: forwards;
}
</style>

<style lang="scss">
.alert-action-button:hover.button:hover {
  border: 1px solid $color_neutral_0;
  color: $color_neutral_0;
  background-color: rgba(0, 0, 0, 0);
}
.alert-action-button:hover.button:hover.disabled {
  border: 1px solid $color_neutral_100;
  color: $color_neutral_0;
  background-color: rgba(0, 0, 0, 0);
}
.alert-action-button.button {
  border: 1px solid $color_neutral_100;
}
.alert-action-button.tertiary.small.disabled {
  color: $color_neutral_0;
}
.alert-close-btn.tertiary.small {
  border: 1px solid $color_neutral_100;
  .icon {
    color: $color_neutral_0;
  }
}
.alert-close-btn:hover.tertiary.small {
  border: 1px solid $color_neutral_0;
  background: rgba(0, 0, 0, 0);
}
</style>
