
<div class="projectList" ref="projectListContainerScroll">
  <!-- LOADING -->
  <div v-if="loadingProjects" class="projectList-loader">
    <spinner-without-progress color="grey" />
  </div>
  <div v-else class="projectList-container">
    <div>
      <div
        class="banner warning"
        :class="displayProjectNameError || displayProjectRequiredError ? 'banner-margin' : ''"
      >
        <div class="banner-icon warning-icon"><i class="ri-error-warning-fill" /></div>
        <div>{{ $t('uploadToModal.warning') }}</div>
      </div>
      <div
        v-if="displayProjectRequiredError"
        class="banner error"
        :class="displayProjectNameError ? 'banner-margin' : ''"
      >
        <div class="banner-icon error-icon"><i class="ri-alert-fill" /></div>
        <div>{{ $t('uploadToModal.projectRequired') }}</div>
      </div>
      <div v-if="displayProjectNameError" class="banner error">
        <div class="banner-icon error-icon"><i class="ri-alert-fill" /></div>
        <div>{{ $t('uploadToModal.MinChar') }}</div>
      </div>
      <div
        class="libraryItem"
        v-tooltip="{
          content: onlyNonAudio
            ? $t('uploadToModal.tooltipNoAudio')
            : $t('uploadToModal.tooltipWithAudio'),
          classes: 'upload-to-library-tooltip'
        }"
      >
        <div class="libraryItem-icon-container">
          <i class="ri-music-2-fill libraryItem-icon" />
        </div>
        <div class="libraryItem-label">
          {{ $t('library') }}
          <span class="libraryItem-label-helper">{{ $t('uploadToModal.audioFilesOnly') }}</span>
        </div>
        <div v-if="!onlyNonAudio" class="libraryItem-check">
          <i class="ri-check-line" />
        </div>
      </div>
      <div v-if="displayNewProjectInput" class="projectItem new-project-input">
        <div class="projectItem-icon-container">
          <i class="ri-folder-fill projectItem-icon" />
        </div>
        <text-field
          inputType="text"
          :selectOnLoad="true"
          :fieldModel.sync="newProjectName"
          @handleBlur="handleBlur"
          @enterPress="handleBlur"
        />
      </div>
      <div
        v-for="project in projects"
        :key="project.id"
        class="projectItem"
        :class="selectedProjectId === project.id && 'projectItem-selected'"
        @click.stop="handleProjectItemClick(project.id)"
      >
        <div class="projectItem-icon-container">
          <i class="ri-folder-fill projectItem-icon" />
        </div>
        <div class="projectItem-label">{{ project.label }}</div>
        <div v-if="selectedProjectId === project.id" class="projectItem-check">
          <i class="ri-check-line" />
        </div>
      </div>
      <div v-if="fetchingMoreProjects" class="loader">
        <skeleton />
      </div>
      <observer v-if="showObserver" :height="32" bottom @intersect="loadMore" />
    </div>
  </div>
</div>
