import gql from 'graphql-tag';
import { WorkspaceDictionariesFragment, ProjectDictionariesFragment } from '../fragments';

// eslint-disable-next-line import/prefer-default-export
export const DICTIONARIES = gql`
  query {
    Dictionaries {
      workspace {
        ...WorkspaceDictionaries
      }
      project {
        ...ProjectDictionaries
      }
      share {
        share_entity_types
      }
    }
  }
  ${WorkspaceDictionariesFragment.WorkspaceDictionaries}
  ${ProjectDictionariesFragment.ProjectDictionaries}
`;

export const ID3_TAG_LIST = gql`
  query Id3TagList {
    Id3TagList {
      id
      name
    }
  }
`;
