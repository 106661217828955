
<div>
  <slot
    :items="items"
    :showObserver="showObserver"
    :isLoading="!values"
    :fetchingMoreItems="fetchingMoreItems"
    :fetchMoreItems="fetchMoreItems"
    :search="search"
    :fetch="fetch"
  />
</div>
