<template>
  <div class="progress">
    <div v-if="title" class="progress-title">{{ title }}</div>
    <div class="progress-bar">
      <div
        class="progress-bar-above"
        :style="`width: ${width}px; background-color: ${color}; max-width: ${size}px;`"
      />
      <div class="progress-bar-below" :style="`width: ${size}px;`" />
    </div>
    <div v-if="helper" class="progress-helper">{{ helper }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false
    },
    helper: {
      type: String,
      required: false
    },
    size: {
      type: Number,
      required: false,
      default: 200
    },
    percent: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  },
  computed: {
    width() {
      return (this.percent * this.size) / 100;
    }
  }
};
</script>

<style lang="scss" scoped>
.progress {
  width: fit-content;
  width: -moz-fit-content;
  &-title {
    @include subtitle-1;
  }

  &-helper {
    @include body-2;
    color: $color_neutral_60;
  }

  &-bar {
    position: relative;
    margin: 8px 0 8px;

    &-above {
      position: absolute;
      z-index: 1;
      background-color: $color_danger_100;
      height: 5px;
      border-radius: 5px;
    }

    &-below {
      width: 100%;
      background-color: $color_neutral_30;
      height: 5px;
      border-radius: 5px;
    }
  }
}
</style>
