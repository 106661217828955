
<div class="sub-header" :style="subHeaderStyle">
  <div class="search">
    <search
      v-if="showSearch"
      :hideFilter="hideFilter"
      :searchContext="searchContext"
      @setIsActiveTag="setIsActiveTag"
      margin="margin: 8px 0 0 -40px; padding: 8px 40px;"
    />
  </div>
  <div class="tabs-container">
    <tabs :tabs="tabs" :activeTab="activeTab" haveCount isSecondary />
  </div>
</div>
