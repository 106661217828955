<template>
  <div class="project-list">
    <div class="project-list-grid">
      <div class="project-list-grid-body">
        <div
          :class="'project-list-grid-body-active'"
          class="project-list-grid-body-row"
          v-for="project in projects"
          :key="project.id"
          :id="project.id"
          :data-id="project.id"
          @mouseenter="setIsHover(project.id)"
          @mouseleave="setIsHover(null)"
          @click="handleClick($event, project.id)"
        >
          <div class="project-list-grid-body-row-cell project-list-grid-body-row-centered">
            <i class="ri-folder-fill project-list-grid-body-icon" />
          </div>
          <div class="project-list-grid-body-row-cell">
            <div class="project-list-grid-body-ellipsis project-list-grid-body-title">
              {{ project.name.label }}
            </div>
            <div class="project-list-grid-body-label">
              <div class="project-list-grid-body-count ellipsis">
                <i class="ri-music-2-line project-list-grid-body-count-icon" />
                <div>{{ project.name.trackCount }}</div>
              </div>
              <div class="project-list-grid-body-count ellipsis">
                <i class="ri-attachment-2 project-list-grid-body-count-icon" />
                <div>{{ project.name.attachmentCount }}</div>
              </div>
            </div>
          </div>
          <div class="project-list-grid-body-row-cell">
            <div class="project-list-grid-body-label">{{ project.createdAt.label }}</div>
            <div class="project-list-grid-body-text">{{ project.createdAt.value }}</div>
          </div>
          <div class="project-list-grid-body-row-cell">
            <div class="project-list-grid-body-label">{{ project.updatedAt.label }}</div>
            <div class="project-list-grid-body-text">{{ project.updatedAt.value }}</div>
          </div>
          <div class="project-list-grid-body-row-cell">
            <div class="project-list-grid-body-label">{{ project.createdBy.label }}</div>
            <div class="project-list-grid-body-name">
              <div class="project-list-grid-body-name-avatar">
                <avatar
                  :initial="project.createdBy.initial"
                  :pictureUrl="project.createdBy.pictureUrl"
                  pictureSize="ultraSmall"
                />
              </div>
              <div class="project-list-grid-body-text project-list-grid-body-ellipsis">
                {{ project.createdBy.name }}
              </div>
            </div>
          </div>
          <div class="project-list-grid-body-row-cell">
            <div v-if="isHoverId === project.id" class="project-list-grid-body-actions">
              <div class="project-list-grid-body-actions-btn">
                <icon-button
                  icon="ri-share-forward-2-fill"
                  btnStyle="tertiary"
                  size="medium"
                  @click.native.stop="$emit('share', project.id)"
                />
              </div>
              <div class="project-list-grid-body-actions-btn">
                <icon-button
                  icon="ri-file-copy-line"
                  btnStyle="tertiary"
                  size="medium"
                  @click.native.stop="$emit('duplicate', project.id)"
                />
              </div>
              <div class="project-list-grid-body-actions-btn">
                <more-dropdown
                  :items="moreItems"
                  :otherItems="moreOtherItems"
                  size="medium"
                  :itemId="project.id"
                  @click.native="stopPropagation"
                />
              </div>
            </div>
            <div v-if="isHoverId === project.id" class="project-list-grid-body-actions">
              <div class="project-list-grid-body-actions-btn">
                <icon-button
                  icon="ri-refresh-line"
                  btnStyle="tertiary"
                  @click.native.stop="$emit('active', project.id)"
                  size="medium"
                />
              </div>
              <div class="project-list-grid-body-actions-btn">
                <icon-button
                  icon="ri-delete-bin-line"
                  btnStyle="tertiary"
                  size="medium"
                  @click.native.stop="$emit('remove', project.id, project.name.label)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '../avatar';
import MoreDropdown from '../moreDropdown';
import IconButton from '../iconButton';

export default {
  components: {
    Avatar,
    MoreDropdown,
    IconButton
  },
  props: {
    projects: {
      type: Array,
      required: true
    },
    moreItems: {
      type: Array,
      required: false,
      default: () => []
    },
    moreOtherItems: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      isHoverId: null
    };
  },
  methods: {
    stopPropagation(e) {
      e.stopPropagation();
    },
    setIsHover(id) {
      this.isHoverId = id;
    },
    copyToClipboard() {
      this.$emit('copyToClipboard');
    },
    handleClick(e, id) {
      if (!e.target.className.includes('ri-more-fill')) this.$emit('handleClickProject', id);
    }
  }
};
</script>

<style lang="scss" scoped>
.project-list {
  font-weight: 400;
  color: $color_neutral_100;

  &-grid {
    grid-template-columns:
      minmax(80px, 80px) minmax(220px, 1fr) minmax(144px, 144px) minmax(144px, 144px)
      minmax(144px, 1fr) minmax(144px, 144px) minmax(144px, 144px);
    display: grid;

    &-body {
      display: contents;

      &-row {
        display: contents;

        &:hover {
          .project-list-grid-body-row-cell {
            @include shadow-down-04;
          }
        }

        &-cell {
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding: 0 16px 0 0;
          height: 64px;
          user-select: none;
          margin: 0 0 8px 0;
          box-sizing: border-box;
          border-top: 1px solid $color_neutral_30;
          border-bottom: 1px solid $color_neutral_30;
          transition: all 200ms linear;

          &:first-child {
            border-left: 1px solid $color_neutral_30;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
          }

          &:last-child {
            border-right: 1px solid $color_neutral_30;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
          }
        }

        &-centered {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
        }
      }

      &-active {
        cursor: pointer;
      }

      &-icon {
        color: $color_neutral_30;
        font-size: 26px;
      }

      &-label {
        font-size: 12px;
        color: $color_neutral_60;
        line-height: 15px;
        padding: 0 0 3px 0;
        display: flex;
      }

      &-title {
        font-size: 14px;
        line-height: 15px;
        padding: 0 0 3px 0;
      }

      &-text {
        font-size: 14px;
        line-height: 15px;
        padding: 3px 0 0 0;
      }

      &-count {
        display: flex;
        align-items: center;

        &-icon {
          margin-bottom: -2px;
          margin-right: 4px;
        }

        .ri-attachment-2 {
          margin-left: 16px;
        }
      }

      &-name {
        display: flex;
        align-items: flex-end;

        &-avatar {
          margin: 0 4px -2px 0;
        }
      }

      &-actions {
        display: flex;
        justify-content: flex-end;

        &-btn {
          margin: 0 16px 0 0;

          &:last-child {
            margin: 0 32px 0 0;
          }
        }
      }

      &-ellipsis {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
</style>
