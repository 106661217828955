<template>
  <div class="filter-dropdown" v-click-outside="{ hide: closeExpandDropdown }">
    <div
      class="filter-dropdown-btn"
      :class="{ 'btn-active': activeFilter.length }"
      @click="toggleExpandDropdown"
    >
      <i :class="filterIcon"></i>
      <div>{{ filterName }}</div>
    </div>
    <div class="filter-dropdown-expand" v-if="isDropdownExpanded">
      <div
        v-for="(filter, idx) in filterList"
        :key="`filter-${idx}`"
        class="filter-dropdown-expand-checkbox"
      >
        <checkbox
          :label="filter.name"
          :id="filter.key"
          :selectedItems="activeFilter"
          @toggleCheckbox="toggleFilter"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { clickOutside } from '@/utils/directives';
import Checkbox from '@/components/checkbox';

export default {
  components: {
    Checkbox
  },
  props: {
    filterIcon: {
      type: String,
      required: false,
      default: null
    },
    filterName: {
      type: String,
      required: true
    },
    filterList: {
      type: Array,
      required: true
    },
    activeFilter: {
      type: Array,
      required: true
    }
  },
  directives: {
    clickOutside
  },
  data() {
    return {
      isDropdownExpanded: false
    };
  },
  methods: {
    toggleExpandDropdown() {
      this.isDropdownExpanded = !this.isDropdownExpanded;
      this.$emit('setIsDropdownExpanded', this.isDropdownExpanded);
    },
    closeExpandDropdown() {
      this.isDropdownExpanded = false;
      this.$emit('setIsDropdownExpanded', this.isDropdownExpanded);
    },
    toggleFilter(bool, event) {
      this.$emit('toggleFilter', bool, event);
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-dropdown {
  position: relative;
  &-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 32px;
    width: fit-content;
    padding: 0 16px;
    border: 1px solid $color_neutral_40;
    border-radius: 4px;
    box-sizing: border-box;
    @include body-1;
    line-height: 100%;
    i {
      color: $color_neutral_40;
      margin-right: 4px;
    }
  }
  &-expand {
    position: absolute;
    top: 34px;
    width: 300px;
    height: fit-content;
    max-height: 248px;
    background: $color_neutral_0;
    border: 1px solid $color_neutral_30;
    box-sizing: border-box;
    @include shadow-down-03;
    @include body-1;
    overflow-y: auto;
    border-radius: 2px;
    padding: 4px 0;
    &-checkbox {
      height: 32px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      &:hover {
        background-color: $color_neutral_10;
      }
    }
  }
}
.btn-active {
  background-color: $color_primary_10;
  border-color: $color_primary_100;
  color: $color_primary_100;
}
</style>

<style lang="scss">
.filter-dropdown-expand-checkbox {
  .checkbox {
    width: 100%;
  }
}
</style>
