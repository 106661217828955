import get from 'lodash.get';
import { bus } from '@/utils/bus';

// eslint-disable-next-line import/prefer-default-export
export const convertFileToJpeg = (file, maxSizeConfig) =>
  new Promise(resolve => {
    const url = window.URL.createObjectURL(file);
    const img = new Image();
    if (file.size > maxSizeConfig) {
      img.src = null;
      bus.$emit('showAlert', {
        message: { key: 'projectCoverMaxSize' },
        style: 'danger',
        delay: 5000,
        error: true
      });
    } else {
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = '#FFF';
        ctx.drawImage(img, 0, 0);
        window.URL.revokeObjectURL(url);
        return canvas.toBlob(blob => resolve(blob), 'image/jpeg');
      };
      img.src = url;
    }
  });

export const getFrontCoverUrl = (images, index) => {
  if (!images) {
    return undefined;
  }
  const frontCover = images.filter(item => item.type === 'front_cover');
  return get(frontCover, `[0].thumbnails[${index}].url`);
};

export const formatBytes = (bytes, decimals) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const getMaxCoverSize = maxSizeConfig => {
  return formatBytes(maxSizeConfig);
};
