
<div class="upload-files-modal">
  <div class="upload-files-modal-title">{{ $t('uploadTracks') }}</div>
  <div class="upload-files-modal-body">{{ $t('noFilesProject') }}</div>
  <div class="upload-files-modal-buttons">
    <submit-button
      :label="$t('importFiles')"
      iconClass="ri-upload-cloud-line"
      browse
      multiple
      :accept="allowedFileTypes"
      @submit="submit"
    />
  </div>
</div>
