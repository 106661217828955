
<div class="artwork" @click.stop="togglePlay">
  <transition name="fade">
    <div v-if="isHover" class="artwork-hover">
      <div class="artwork-hover-circle">
        <i :class="play && !isPlayerOnError ? 'ri-pause-circle-fill' : 'ri-play-circle-fill'" />
      </div>
      <div class="artwork-hover-bkg" />
    </div>
  </transition>
  <img v-if="imgSrc" :src="imgSrc" />
  <div v-else-if="trackIndex || trackIndex === 0" class="artwork-number">
    {{ trackIndex + 1 }}
  </div>
  <div v-else class="artwork-number">
    {{ trackNumber }}
  </div>
</div>
