<template>
  <div class="invitation-container">
    <div class="invitation">
      <div class="invitation-logo">
        <img src="/images/Logo-Full-Color.svg" />
      </div>
      <div class="invitation-step">2/2</div>
      <div class="invitation-title">{{ $t('inviteYourTeam') }}</div>
      <div class="invitation-helper">{{ $t('invitePeopleHelper') }}</div>
      <form @submit.prevent="submitInvitations" class="invitation-form" v-if="roles && Workspace">
        <div
          class="invitation-form-members"
          :class="invitations.length > currentWorkspace.subscription.seats && 'overflow'"
        >
          <div
            class="invitation-form-members-email"
            v-for="(invitation, index) in invitations"
            :key="`invitations-${invitation.id}`"
          >
            <div class="invitation-form-members-email-textField">
              <text-field
                inputType="text"
                :errorMessage="getMemberValidationMessage(index)"
                :fieldModel.sync="invitation.email"
                :placeholder="$t('enterEmail')"
                large
                focusOnLoad
                label="Email"
              />
            </div>
            <div class="invitation-form-members-email-dropdown">
              <roles-dropdown
                ref="dropdown"
                :roles="roleList"
                :userRole="roleList.find(role => role.value === '2')"
                withBorder
                large
                @setRole="setRole(index)"
                label="Role"
              />
            </div>
            <div class="invitation-form-members-email-remove">
              <icon-button
                v-if="invitations.length > 1"
                icon="ri-delete-bin-line"
                btnStyle="tertiary"
                size="small"
                isDanger
                @submit="removeInvitation(index)"
              />
            </div>
          </div>
        </div>
        <div class="invitation-form-add" v-if="invitations.length < 4">
          <submit-button
            btnStyle="tertiary"
            size="small"
            :label="$t('add')"
            iconClass="ri-add-line"
            :disabled="invitations.length === currentWorkspace.subscription.seats - 1"
            @submit="addInvitation"
            class="primary-colored-tertiary"
          />
        </div>
        <div class="invitation-form-separation">
          <div class="invitation-form-separation-line">
            <span class="invitation-form-separation-text">or</span>
          </div>
        </div>
        <div class="invitation-form-link">
          <submit-button
            class="primary-colored-tertiary"
            btnStyle="tertiary"
            :label="$t('copyInviteLink')"
            iconClass="ri-links-fill"
            @submit="createAndCopyInvitationLink"
          />
        </div>
        <div class="invitation-form-buttons">
          <div class="invitation-form-buttons-btn">
            <submit-button
              btnStyle="secondary"
              size="large"
              :label="$t('skipForNow')"
              @submit="nextStep"
              class="skipButton"
            />
            <submit-button
              size="large"
              iconClass="ri-mail-send-line"
              :label="$t('sendInvitesContinue')"
              :disabled="disabled"
              :pending="submitStatus === 'PENDING'"
              @submit="submitInvitations"
              class="submitButton"
            />
          </div>
        </div>
      </form>
      <div v-else class="invitation-form-loader">
        <spinner-without-progress color="grey" :size="32" />
      </div>
    </div>
    <div v-if="!isMobile" class="invitation-container-right">
      <activity-previewer :activityId="getActivityId()" />
    </div>
  </div>
</template>

<script>
import { email, maxLength, required } from 'vuelidate/lib/validators';
import ActivityPreviewer from '@/containers/activityPreviewer';
import { get as getRoute } from '@/router/routes';
import { bus } from '@/utils/bus';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import { GET_ROLES } from '@/graphql/queries/workspace';
import { WORKSPACE } from '@/graphql/queries/user';
import {
  SEND_WORKSPACE_BULK_INVITATION,
  CREATE_WORKSPACE_INVITATION_LINK
} from '@/graphql/mutations/invitation';
import { WORKSPACE_INVITATION_LINK } from '@/graphql/queries/invitation';

export default {
  props: {
    isMobile: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      invitations: [{ email: '', roleId: '2', id: 0 }],
      submitStatus: 'OK',
      inviteLink: undefined,
      inviteLinkExpiration: null,
      isCreateLinkClicked: false,
      currentWorkspace: null,
      roleList: []
    };
  },
  apollo: {
    roles: {
      query: GET_ROLES,
      result({ data }) {
        this.roleList = data.roles.map(role => ({
          label: role.name,
          value: role.id,
          description: this.$t(`roles.${role.id}.description`)
        }));
      }
    },
    Workspace: {
      query: WORKSPACE,
      variables() {
        return {
          workspaceId: this.$route.params.id
        };
      },
      result({ data }) {
        this.currentWorkspace = data.Workspace;
      }
    }
  },
  components: {
    ActivityPreviewer
  },
  validations: {
    invitations: {
      $each: {
        email: {
          required,
          email,
          maxLength: maxLength(50),
          unique(value) {
            const arr = this.invitations.map(invitation => invitation.email);
            return (
              arr.filter(
                (invitation, index) =>
                  invitation === value && arr.indexOf(invitation) !== index && invitation !== ''
              ).length === 0
            );
          }
        }
      }
    }
  },
  computed: {
    disabled() {
      this.$v.$touch();
      return this.$v.invitations.$error;
    }
  },
  methods: {
    getActivityId() {
      return this.currentWorkspace && this.currentWorkspace.activity_id;
    },
    getDaysLeftFromTimestamp(timestamp) {
      const dayInTimestamp = 86400;
      const dayLeftInTimestamp = Math.round(timestamp - new Date().getTime() / 1000);
      const dayLeft = Math.round(dayLeftInTimestamp / dayInTimestamp);
      return dayLeft < 1 ? 1 : dayLeft;
    },
    getMemberValidationMessage(index) {
      if (!this.$v.invitations.$each[index].email.email) {
        return this.$t('invalidEmail');
      }
      if (!this.$v.invitations.$each[index].email.maxLength) {
        return this.$t('maxLength', {
          nbChars: this.$v.invitations.$each[index].email.$params.maxLength.max,
          label: this.$t('email')
        });
      }
      if (!this.$v.invitations.$each[index].email.unique) {
        return this.$t('emailTwice');
      }
      return null;
    },
    setRole(index) {
      const member = this.invitations.find((invitation, idx) => idx === index);
      member.roleId = this.$refs.dropdown[index].selectedRole.value;
    },
    addInvitation() {
      this.$v.$touch();
      this.invitations.push({
        email: '',
        roleId: '2',
        id: this.invitations[this.invitations.length - 1].id + 1
      });
    },
    removeInvitation(index) {
      this.invitations.splice(index, 1);
    },
    nextStep() {
      const nextUrl = getRoute('trackList');
      const nextRoute =
        document.location.search.length === 0 ? nextUrl : `${nextUrl}?${document.location.search}`;
      this.$router.push(nextRoute);
    },
    submitInvitations() {
      this.submitStatus = 'PENDING';
      const invitationsToSend = this.invitations.map(el => ({
        email: el.email,
        role_id: parseInt(el.roleId, 10)
      }));
      this.$apollo
        .mutate({
          mutation: SEND_WORKSPACE_BULK_INVITATION,
          variables: {
            workspaceId: this.$route.params.id,
            invitations: invitationsToSend
          }
        })
        .then(() => {
          this.$apollo.queries.Workspace.refetch().then(() => {
            this.submitStatus = 'OK';
            this.nextStep();
            bus.$emit('showAlert', {
              message: { key: 'invitationSuccess', params: invitationsToSend.length },
              style: 'success',
              delay: 5000
            });
          });
        })
        .catch(error => {
          this.submitStatus = 'OK';
          this.nextStep();
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    createAndCopyInvitationLink() {
      /* Process : Check if a link exists,
       - if it exists => return the value
       - if it doesn't exist => you create the workspace link and return value
      */
      this.isCreateLinkClicked = true;
      this.$apollo
        .query({
          query: WORKSPACE_INVITATION_LINK,
          variables: {
            workspaceId: this.currentWorkspace.id
          }
        })
        .then(res => {
          if (res.data) {
            const data = res.data.workspaceInvitationLink;
            this.inviteLinkExpiration = this.getDaysLeftFromTimestamp(data.valid_to);
            this.inviteLink = data.link;
            this.copyInvitationLink();
          }
        })
        .catch(() => {
          this.$apollo
            .mutate({
              mutation: CREATE_WORKSPACE_INVITATION_LINK,
              variables: {
                workspaceId: this.currentWorkspace.id
              }
            })
            .then(result => {
              if (result.data) {
                const data = result.data.createWorkspaceInvitationLink;
                this.inviteLinkExpiration = this.getDaysLeftFromTimestamp(data.valid_to);
                this.inviteLink = data.link;
                this.copyInvitationLink();
              }
            })
            .catch(error => {
              const key = getGraphQlErrorCode(error);
              bus.$emit('showAlert', {
                message: { key },
                style: 'danger',
                delay: 5000,
                error: true
              });
            });
        });
    },
    copyInvitationLink() {
      const dummy = document.createElement('textarea');
      document.body.appendChild(dummy);
      dummy.value = `${this.inviteLink}`;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
      bus.$emit('showAlert', {
        message: { key: 'linkCopiedExpireIn', params: this.inviteLinkExpiration },
        style: 'success',
        delay: 5000
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.invitation-container {
  width: 1236px;
  max-width: 100vw;
  display: flex;
  padding: 24px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  &-right {
    margin-left: 24px;
  }
}
.invitation {
  color: $color_neutral_100;
  display: flex;
  flex-direction: column;
  width: 464px;

  &-logo {
    margin-bottom: 64px;
    img {
      height: 24px;
      width: auto;
    }
  }

  &-step {
    color: $color_neutral_60;
    font-size: 14px;
    margin-bottom: 8px;
  }

  &-title {
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    margin-bottom: 16px;
  }

  &-helper {
    @include body-1;
    color: $color_neutral_60;
    margin: 0 0 64px;
  }

  &-form {
    &-members {
      &-email {
        display: flex;
        align-items: center;
        margin: 0 0 24px 0;

        &-textField {
          max-width: 308px;
          margin: 0 8px 0;
          width: 100%;
        }

        &-dropdown {
          margin: 0 0 0 8px;
        }

        &-remove {
          width: 24px;
          margin: 24px 0 0 8px;
        }
      }
    }

    &-add {
      margin: 0 0 0 8px;
      .addButton {
        color: $color_primary_100;
      }
    }

    &-separation {
      margin-bottom: 33px;
      &-line {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid $color_neutral_30;
        line-height: 0.1em;
        margin: 10px 0 20px;
      }
      &-text {
        background: $color_neutral_0;
        padding: 0 16px;
        font-size: 12px;
        color: $color_neutral_60;
        font-weight: 400;
      }
    }

    &-link {
      display: flex;
      justify-content: center;
    }

    &-buttons {
      margin-top: 24px;

      &-btn {
        display: flex;
        justify-content: center;
        .skipButton {
          padding: 0 16px;
        }
        .submitButton {
          background-color: $color_primary_100;
          margin-left: 9px;
          padding: 0 28px;
        }
      }
    }
  }
  &-form-loader {
    min-height: 287px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.overflow {
  width: 520px;
  max-height: 216px;
  overflow-y: auto;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: $color_neutral_40;
}

@media screen and (max-width: 1024px) {
  .invitation-container {
    width: 100%;
    padding: 0;
  }
  .invitation {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-right: 0;
    &-logo {
      margin: 0 auto 64px;
    }

    &-title {
      font-size: 20px;
      line-height: 125%;
    }
    &-helper {
      width: 100%;
      flex-grow: 1;
    }
    &-count {
      width: 100%;
    }
    &-form {
      width: 100%;
      &-members-email {
        &-dropdown,
        &-textField {
          margin: 0;
        }
        &-textField {
          margin-right: 8px;
        }
        &-remove {
          display: none;
        }
      }
      &-link {
        margin-bottom: 80px;
      }

      &-buttons {
        &-btn {
          display: block;
          .button {
            width: 100%;
          }
          .submitButton {
            margin-left: 0;
            margin-top: 9px;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
@media screen and (max-width: 1024px) {
  .invitation-form-members-email-dropdown {
    .dropdown.withBorder.large {
      width: 100px;
    }
  }
}
</style>
