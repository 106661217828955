<template>
  <div class="sub-header" :style="subHeaderStyle">
    <div class="search">
      <search
        v-if="showSearch"
        :hideFilter="hideFilter"
        :searchContext="searchContext"
        @setIsActiveTag="setIsActiveTag"
        margin="margin: 8px 0 0 -40px; padding: 8px 40px;"
      />
    </div>
    <div class="tabs-container">
      <tabs :tabs="tabs" :activeTab="activeTab" haveCount isSecondary />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { get as getRoute } from '@/router/routes';
import { SEARCH_CONTEXT } from '@/utils/constants';
import Search from '@/containers/search';

export default {
  components: {
    Search
  },
  data() {
    return {
      albumSortOptions: [
        { label: this.$t('name'), order: 'A-Z', filter: { orderBy: 'name', sortBy: 'asc' } },
        { label: this.$t('name'), order: 'Z-A', filter: { orderBy: 'name', sortBy: 'desc' } },
        { separator: true },
        {
          label: this.$t('releaseDate'),
          order: 'Newest',
          filter: { orderBy: 'release_date', sortBy: 'desc' }
        },
        {
          label: this.$t('releaseDate'),
          order: 'Oldest',
          filter: { orderBy: 'release_date', sortBy: 'asc' }
        }
      ],
      albumSort: {
        label: this.$t('name'),
        order: 'A-Z',
        filter: { orderBy: 'name', sortBy: 'asc' }
      },
      isActiveTag: false
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'searchLibraryQueryParameters', 'projectTabsCount']),
    tabs() {
      if (this.$route.path.includes('library')) {
        return this.libraryTabs;
      }
      if (this.$route.path.includes('projects')) {
        return this.projectTabs;
      }
      return {};
    },
    activeTab() {
      const active = this.tabs.find(tab => tab.name === this.$route.name);
      return this.tabs.indexOf(active);
    },
    libraryTabs() {
      let totalTracks = this.currentWorkspace.total_tracks || 0;
      if (this.searchLibraryQueryParameters) totalTracks = this.currentWorkspace.searchCount || 0;
      const totalAlbums = this.currentWorkspace.total_supports || 0;
      const totalArtists = this.currentWorkspace.total_artists || 0;
      const searchTab = {
        title: this.$t('tracks'),
        link: getRoute('trackSearch'),
        name: 'trackSearch',
        count: totalTracks
      };
      const tracksTab = {
        title: this.$t('tracks'),
        link: getRoute('trackList'),
        name: 'trackList',
        count: totalTracks
      };
      const artistsTab = {
        title: this.$t('artists'),
        link: getRoute('artists'),
        name: 'artists',
        count: totalArtists
      };
      const albumsTab = {
        title: this.$t('albums'),
        link: getRoute('albums'),
        name: 'albums',
        count: totalAlbums
      };
      return this.searchLibraryQueryParameters ? [searchTab] : [tracksTab, artistsTab, albumsTab];
    },
    projectTabs() {
      return [
        {
          title: this.$t('projects'),
          link: getRoute('activeProjects'),
          name: 'activeProjects',
          count: this.projectTabsCount.active
        }
      ];
    },
    hideFilter() {
      return this.$route.meta.hideFilter;
    },
    showSearch() {
      return this.$route.meta.showSearch;
    },
    searchContext() {
      if (this.$route.path.includes('library')) {
        return SEARCH_CONTEXT.LIBRARY_SEARCH_CONTEXT;
      }
      if (
        this.$route.path.includes('projects/active') ||
        this.$route.path.includes('projects/archive')
      ) {
        return SEARCH_CONTEXT.PROJECT_SEARCH_CONTEXT;
      }
      return null;
    },
    subHeaderStyle() {
      const style = {};
      if (!this.isActiveTag) {
        style.paddingBottom = '24px';
      }
      return style;
    }
  },
  methods: {
    handleAlbumSort(option) {
      this.$store.commit('changeAlbumSort', option.filter);
      this.albumSort = option;
    },
    setIsActiveTag(bool) {
      this.isActiveTag = bool;
    }
  }
};
</script>

<style lang="scss" scoped>
.sub-header {
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.search {
  width: 100%;
}
.tabs-container {
  position: absolute;
  right: 40px;
  z-index: 2;
}
</style>
