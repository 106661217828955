<template>
  <div class="delete-workspace-modal">
    <div class="delete-workspace-modal-body">
      <div class="delete-workspace-modal-body-content">
        {{ $t('deleteWorkspace.description') }}
      </div>
      <div class="delete-workspace-modal-body-input">
        <text-field
          inputType="password"
          withVisibility
          :label="$t('enterPassword')"
          :fieldModel.sync="password"
          focusOnLoad
          @keyup.enter.native="handleKeyUp"
        />
        <div v-if="serverErrorMessage" class="delete-workspace-modal-body-input-invalid">
          {{ serverErrorMessage }}
        </div>
      </div>
      <div class="delete-workspace-modal-body-link" @click="sendForgotPasswordMail">
        {{ $t('modalCancellation.forgotPassword') }}
      </div>
    </div>
    <div class="delete-workspace-modal-buttons">
      <div class="delete-workspace-modal-buttons--">
        <submit-button
          btnStyle="tertiary"
          size="medium"
          :label="$t('cancel')"
          @submit="closeModal"
        />
      </div>
      <div class="delete-workspace-modal-buttons--">
        <submit-button
          btnStyle="primary"
          size="medium"
          :label="$t('deleteWorkspace.buttonLabel')"
          danger
          @submit="deleteWorkspace"
          :disabled="disabledDeleteButton"
          :pending="removePending"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { SEND_RESET_PASSWORD_LINK } from '@/graphql/mutations/user';
import { mapGetters } from 'vuex';
import { bus } from '@/utils/bus';
import { WORKSPACE_DELETE } from '@/graphql/mutations/workspace';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import { setWorkspaceIdInStorage } from '@/utils/storage';

export default {
  props: {
    onSubmit: {
      type: Function,
      required: false,
      default: () => {}
    },
    workspaceId: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    isFreeTrial: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      password: '',
      errorKey: null,
      removePending: false
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    disabledDeleteButton() {
      return this.password.length === 0;
    },
    serverErrorMessage() {
      if (this.errorKey === 'INSUFFICIENT_PERMISSIONS') {
        return 'Invalid password';
      }
      return null;
    }
  },
  methods: {
    sendForgotPasswordMail() {
      this.$apollo
        .mutate({
          mutation: SEND_RESET_PASSWORD_LINK,
          variables: {
            email: this.email
          }
        })
        .then(() => {
          bus.$emit('showAlert', {
            message: {
              key: 'modalCancellation.emailForgotConfirmation',
              args: { email: this.email }
            },
            style: 'success',
            delay: 5000,
            error: false
          });
        });
    },
    closeModal() {
      this.$emit('closeModal');
    },
    handleKeyUp() {
      if (!this.disabledDeleteButton) {
        this.deleteWorkspace();
      }
    },
    deleteWorkspace() {
      this.removePending = true;
      this.$apollo
        .mutate({
          mutation: WORKSPACE_DELETE,
          variables: {
            workspaceId: this.currentWorkspace.id,
            password: this.password
          }
        })
        .then(() => {
          this.removePending = false;
          this.closeModal();
          bus.$emit('showAlert', {
            message: { key: 'workspaceDeleted' },
            style: 'success',
            delay: 3000
          });
          setWorkspaceIdInStorage(undefined);
          window.location.replace('/library/tracks/list');
        })
        .catch(error => {
          this.removePending = false;
          this.errorKey = getGraphQlErrorCode(error);
          if (this.errorKey === 'subscription_plan_paid') {
            this.closeModal();
            bus.$emit('showAlert', {
              message: {
                key: this.isFreeTrial
                  ? 'deleteWorkspace.activeFreeTrialSubscription'
                  : 'deleteWorkspace.activeProSubscription'
              },
              style: 'danger',
              delay: 5000
            });
          }
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.delete-workspace-modal {
  &-body {
    padding: 16px 24px;
    line-height: 150%;
    &-title {
      font-size: 18px;
      line-height: 22px;
      padding: 0 0 24px 0;
      text-align: center;
      word-break: break-word;
    }
    &-content {
      margin: 0 0 32px 0;
      font-size: 14px;
    }
    &-input {
      margin-bottom: 24px;
      position: relative;
      &-invalid {
        text-align: right;
        font-size: 12px;
        color: $color_danger_100;
        position: absolute;
        right: 0;
      }
    }
    &-link {
      font-size: 14px;
      color: $color_primary_100;
      text-decoration: underline;
      cursor: pointer;
      display: inline-block;
    }
  }
  &-buttons {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $color_neutral_30;
    padding: 8px 24px;

    &-- {
      &:first-child {
        margin: 0 8px 0 0;
      }
    }
  }
}
</style>
