
<div v-if="listArtists">
  <div v-if="currentWorkspace.total_tracks === 0 && !uploadIsInprogress">
    <empty-library
      :title="$t('libraryEmptyTitle')"
      :description="$t('libraryEmptyHelper')"
      :helper="$t('dragAndDrop')"
      placeholderType="artists"
      :cloudedTextContent="$t('addAudioFiles')"
      :cloudedTextColor="{
        stroke: '#2F43DE',
        fill: '#E0E3FA'
      }"
    >
      <template v-slot:button>
        <submit-button
          size="large"
          :label="$t('upload')"
          iconClass="ri-upload-cloud-line"
          browse
          multiple
          :accept="allowedFileTypes"
          @submit="handleClickUploadButton"
        />
      </template>
    </empty-library>
  </div>
  <div
    v-if="currentWorkspace.total_tracks > 0 || uploadIsInprogress"
    class="artists"
    data-test="listArtists"
  >
    <div class="artists-list">
      <div class="artists-list-card" v-for="item in cardsArtist" :key="`artist-${item.id}`">
        <card-artist
          :artistCover="getArtistCover(item)"
          :artistUrl="getArtistUrl(item)"
          :artistName="item.name"
        />
      </div>
      <div class="artists-list-observer">
        <observer
          :key="`obs-${page}`"
          v-if="page < listArtists.last_page && !loading"
          :height="213"
          @intersect="loadMore"
        />
      </div>
    </div>
  </div>
</div>
<div v-else class="loader">
  <spinner-without-progress color="grey" :size="32" />
</div>
