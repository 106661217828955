import { ARCHIVE_PROJECT } from '@/graphql/mutations/project';

// eslint-disable-next-line import/prefer-default-export
export const downloadProjectAsZip = (that, itemId) => {
  const projectId = itemId || that.$route.params.id;
  that.$store.commit('setIsDownloadingArchive', true);
  that.$apollo
    .mutate({
      mutation: ARCHIVE_PROJECT,
      variables: {
        projectId,
        isOriginal: true
      }
    })
    .then(res => {
      const handleDownload = url => {
        if (url && url.url) {
          const anchor = document.createElement('a');
          anchor.href = url.url;
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        }
      };
      const archive = res.data.ArchiveProject;
      if (archive.status === 'done') {
        that.$store.commit('setDownloadArchiveStatus', archive.status);
        handleDownload(archive.url);
      }
      if (archive.status === 'error') {
        that.$store.commit('setDownloadArchiveStatus', archive.status);
      }
    });
};
