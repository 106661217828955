<template>
  <div class="right-holder-panel-body" :key="updateKey">
    <div v-if="Dictionaries">
      <div v-if="isMultipleTrackEdition && !multiEditActive">
        <text-field
          inputType="text"
          withEditButton
          editButtonEmit
          placeholder="< Multiple values >"
          @editBtnClick="
            () => {
              multiEditActive = true;
            }
          "
        />
      </div>
      <div
        v-else
        class="right-holder-panel-body-row"
        :class="{ mb5: getNameErrorMessage(i) }"
        v-for="(row, i) in rows"
        :key="`row-${row.id}-${row.track_right_holder_id}`"
      >
        <div class="right-holder-panel-body-row-name">
          <text-field
            placeholder="John Doe"
            inputType="text"
            :label="i === 0 ? $t('name') : null"
            :large="false"
            :value="row.name"
            :fieldModel.sync="row.name"
            :errorMessage="getNameErrorMessage(i)"
          />
        </div>
        <div class="right-holder-panel-body-row-role">
          <select-box
            :options="options"
            :label="i === 0 ? $t('role') : null"
            :placeholder="$t('selectRole')"
            :selectedValue="{ label: row.role, value: row.role }"
            @setSelectBoxValue="handleSelectChange($event, row.id, row.track_right_holder_id)"
          />
        </div>
        <div class="right-holder-panel-body-row-percent">
          <text-field
            inputType="number"
            :label="i === 0 ? $t('masterPercent') : null"
            :large="false"
            :value="row.mastering_percentage"
            :fieldModel.sync="row.mastering_percentage"
          />
        </div>
        <div class="right-holder-panel-body-row-percent">
          <text-field
            inputType="number"
            :label="i === 0 ? $t('publishPercent') : null"
            :large="false"
            :value="row.publishing_percentage"
            :fieldModel.sync="row.publishing_percentage"
          />
        </div>
        <div
          v-if="showDeleteButton"
          class="right-holder-panel-body-row-cross"
          @click="deleteRow(row.id, row.track_right_holder_id)"
        >
          <i class="ri-close-fill"></i>
        </div>
      </div>
    </div>
    <div v-else class="loader"><spinner-without-progress color="grey" :size="32" /></div>
    <div class="right-holder-panel-body-add" v-if="!isMultipleTrackEdition || multiEditActive">
      <submit-button
        btnStyle="tertiary"
        size="small"
        :label="$t('add')"
        iconClass="ri-add-fill"
        exception
        @submit="addRow"
      />
    </div>
    <div
      v-if="masteringPercentage > 0 || publishingPercentage > 0"
      class="right-holder-panel-body-total"
    >
      <div class="right-holder-panel-body-total-title">
        Total
        <i class="ri-information-line" v-tooltip="$t('totalInfo')"></i>
      </div>
      <div class="right-holder-panel-body-total-percent">
        <div class="right-holder-panel-body-total-percent--">
          {{ `${parseFloat(masteringPercentage).toFixed(1)}%` }}
        </div>
        <div class="right-holder-panel-body-total-percent--">
          {{ `${parseFloat(publishingPercentage).toFixed(1)}%` }}
        </div>
      </div>
    </div>
    <div
      v-if="
        hasRightHolders &&
        (masteringPercentage !== 100 || publishingPercentage !== 100) &&
        !isMultipleTrackEdition
      "
      class="right-holder-panel-body-info"
    >
      <panel-information
        :canClose="false"
        PanelStyle="warning"
        :message="$t('totalShouldEqual100')"
      />
    </div>
    <div class="drop-all-btn" v-if="isMultipleTrackEdition && hasRightHolders">
      <submit-button
        :label="$t('removeTracksRightHolders')"
        size="small"
        btnStyle="secondary"
        @submit="dropRightHoldersModal"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { VTooltip } from 'v-tooltip';
import { mapGetters } from 'vuex';
import { maxLength } from 'vuelidate/lib/validators';
import { LIBRARY_FILES_DETAILS } from '@/graphql/queries/library';
import { DICTIONARIES } from '@/graphql/queries/global';
import { bus } from '@/utils/bus';
import ConfirmDeleteModal from '@/containers/modals/confirmDelete';
import { getGraphQlErrorCode } from '../../utils/functions/global';
import {
  LIBRARY_TRACKS_ADD_RIGHT_HOLDER,
  LIBRARY_TRACKS_DELETE_RIGHT_HOLDER,
  LIBRARY_TRACKS_UPDATE_RIGHT_HOLDER,
  LIBRARY_TRACK_DROP_ALL_RIGHT_HOLDERS
} from '../../graphql/mutations/library';

Vue.directive('tooltip', VTooltip);

export default {
  props: {
    tracksToEdit: {
      type: Array,
      required: false,
      default: () => []
    },
    isMultipleTrackEdition: {
      type: Boolean,
      required: false,
      default: false
    },
    isSaveButtonDisabledParent: {
      type: Boolean,
      required: false,
      default: false
    },
    formHasChangedParent: {
      type: Boolean,
      required: false,
      default: false
    },
    pendingStatusParent: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  apollo: {
    Dictionaries: {
      query: DICTIONARIES
    }
  },
  data() {
    return {
      rows: [],
      pendingStatus: false,
      multiEditActive: false,
      updateKey: 0,
      arrHasError: [],
      hasError: false
    };
  },
  validations: {
    rows: {
      $each: {
        name: { maxLength: maxLength(50) }
      }
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    options() {
      return this.Dictionaries.workspace.library.right_holder_roles.map(role => ({
        label: this.$t(role),
        value: role
      }));
    },
    hasRightHolders() {
      return this.tracksToEdit.some(track => {
        if (track.right_holders === null || this.isMultipleTrackEdition) {
          return false;
        }
        return track.right_holders.length > 0;
      });
    },
    deletedRows() {
      const rightHolders = this.tracksToEdit[0].right_holders;
      if (rightHolders === null || this.isMultipleTrackEdition) {
        return [];
      }
      // Si on ne trouve pas la row qui était initialement dans tracksToEdit, c'est qu'elle a été supprimée
      return rightHolders.filter(
        item =>
          !this.rows.find(
            row =>
              row.id === item.id &&
              !row.id.includes('new') &&
              row.track_right_holder_id === item.track_right_holder_id
          )
      );
    },
    updatedRows() {
      const rightHolders = this.tracksToEdit[0].right_holders;
      if (rightHolders === null || this.isMultipleTrackEdition) {
        return [];
      }
      // Si la nouvelle row et celle qui était initialement dans tracksToEdit sont différentes, c'est qu'elle a été modifiée
      return this.rows.filter(row => {
        const initialRow = rightHolders.find(
          item => item.id === row.id && item.track_right_holder_id === row.track_right_holder_id
        );
        return initialRow && JSON.stringify(initialRow) !== JSON.stringify(row);
      });
    },
    newRowsChanged() {
      const changedRows = this.rows
        .filter(row => row.id.includes('new'))
        .filter(
          row =>
            (Object.prototype.hasOwnProperty.call(row, 'name') && row.name !== '') ||
            Object.prototype.hasOwnProperty.call(row, 'role') ||
            row.mastering_percentage > 0 ||
            row.publishing_percentage > 0
        );
      return changedRows;
    },
    formHasChanged() {
      return (
        this.newRowsChanged.length > 0 || this.updatedRows.length > 0 || this.deletedRows.length > 0
      );
    },
    isSaveButtonDisabled() {
      return !this.formHasChanged || this.hasError;
    },
    masteringPercentage() {
      return this.getTotal('mastering_percentage');
    },
    publishingPercentage() {
      return this.getTotal('publishing_percentage');
    },
    showDeleteButton() {
      let show = false;
      if (this.rows.length > 1) show = true;
      if (this.rows.length === 1 && !this.rows[0].id.includes('new')) show = true;
      return show;
    },
    confirmDeleteModal() {
      let { components } = this.$options;
      components = {
        ...components,
        ConfirmDeleteModal
      };
      return {
        title: this.$t('confirmRemoveRightHolders'),
        size: 'medium',
        isVisible: false,
        component: components.ConfirmDeleteModal,
        onSubmit: this.dropAllRightHolders
      };
    }
  },
  watch: {
    tracksToEdit: {
      deep: true,
      handler(newValue, oldValue) {
        this.multiEditActive = false;
        const keyMemory = this.updateKey;
        while (keyMemory === this.updateKey) {
          this.updateKey = Math.round(Math.random() * 100);
        }
        if (!this.isMultipleTrackEdition && newValue[0].id !== oldValue[0].id) {
          this.initData();
        } else {
          const oldDiff = newValue.some((val, i) => !oldValue[i] || val.id !== oldValue[i].id);
          const newDiff = oldValue.some((val, i) => !newValue[i] || val.id !== newValue[i].id);
          if (oldDiff || newDiff) {
            this.initData();
          }
        }
      }
    },
    isSaveButtonDisabled() {
      this.$emit('update:isSaveButtonDisabledParent', this.isSaveButtonDisabled);
    },
    formHasChanged() {
      this.$emit('update:formHasChangedParent', this.formHasChanged);
    },
    pendingStatus() {
      this.$emit('update:pendingStatusParent', this.pendingStatus);
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      const randomValue = Math.floor(Math.random() * 100000);
      if (!this.isMultipleTrackEdition) {
        const { right_holders } = this.tracksToEdit[0];
        if (right_holders === null) {
          this.rows = [];
        } else {
          this.rows =
            right_holders.length && !this.isMultipleTrackEdition
              ? right_holders.map(row => ({ ...row }))
              : [{ id: `new-${randomValue}`, mastering_percentage: 0, publishing_percentage: 0 }];
        }
      } else {
        this.rows = [];
      }
      // On réordonne les rows par ordre alphabétique d'abord pour le role puis pour le nom
      this.rows.sort((a, b) => a.role.localeCompare(b.role) || a.name.localeCompare(b.name));
      if (this.rows.length === 0) {
        this.addRow();
      }
      this.$emit('update:isSaveButtonDisabledParent', this.isSaveButtonDisabled);
    },
    handleSelectChange(role, id, uid) {
      // On change le role par la valeur sélectionnée
      this.$set(
        this.rows.find(row => row.id === id && row.track_right_holder_id === uid),
        'role',
        role
      );
    },
    addRow() {
      const randomValue = Math.floor(Math.random() * 100000);
      this.rows.push({
        id: `new-${randomValue}`,
        mastering_percentage: 0,
        publishing_percentage: 0
      });
    },
    deleteRow(id, uid) {
      this.rows.splice(
        this.rows.findIndex(row => row.id === id && row.track_right_holder_id === uid),
        1
      );
    },
    getNameErrorMessage(index) {
      if (!this.$v.rows.$each[index].name.maxLength) {
        this.arrHasError[index] = true;
        this.hasError = this.arrHasError.some(el => el);
        return this.$t('maxLength', {
          nbChars: this.$v.rows.$each[index].name.$params.maxLength.max,
          label: this.$t('name')
        });
      }
      this.arrHasError[index] = false;
      this.hasError = this.arrHasError.some(el => el);
      return null;
    },
    getTotal(key) {
      return this.rows.reduce((acc, row) => {
        const r = row;
        if (!(row.mastering_percentage > 0)) this.$set(r, 'mastering_percentage', 0);
        if (!(row.publishing_percentage > 0)) this.$set(r, 'publishing_percentage', 0);
        this.$set(r, key, parseFloat(row[key]));
        return acc + row[key];
      }, 0);
    },
    dropRightHoldersModal() {
      bus.$emit('displayModal', { ...this.confirmDeleteModal, isVisible: true });
    },
    dropAllRightHolders() {
      const libraryFilesIds = this.tracksToEdit.map(track => track.id);
      this.$apollo
        .mutate({
          mutation: LIBRARY_TRACK_DROP_ALL_RIGHT_HOLDERS,
          variables: {
            libraryId: this.currentWorkspace.libraries[0].id,
            libraryFilesIds
          },
          refetchQueries: [
            {
              query: LIBRARY_FILES_DETAILS,
              variables: {
                libraryFilesIds
              }
            }
          ]
        })
        .then(() => {
          this.initData();
          bus.$emit('showAlert', {
            message: { key: 'removeRightHoldersSuccess' },
            style: 'success',
            delay: 5000
          });
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    handleClickSaveButton() {
      if (this.$isWorkspaceLockedVerification(this)) return;
      let emptyName = false;
      let emptyRole = false;
      this.newRowsChanged.forEach(row => {
        if (!Object.prototype.hasOwnProperty.call(row, 'name') || row.name === '') {
          emptyName = true;
        }
        if (!Object.prototype.hasOwnProperty.call(row, 'role')) {
          emptyRole = true;
        }
      });
      this.updatedRows.forEach(row => {
        if (row.name === '') {
          emptyName = true;
        }
      });
      if (emptyName) {
        bus.$emit('showAlert', {
          message: { key: 'rightHolderNameRequired' },
          style: 'danger',
          delay: 5000
        });
        return;
      }
      if (emptyRole) {
        bus.$emit('showAlert', {
          message: { key: 'rightHolderRoleRequired' },
          style: 'danger',
          delay: 5000
        });
        return;
      }
      this.pendingStatus = true;
      const tracksToEditIds = this.isMultipleTrackEdition
        ? this.tracksToEdit
        : this.tracksToEdit.map(track => track.id);
      const promisesArray = [];
      if (this.newRowsChanged.length > 0) {
        this.newRowsChanged.forEach(row => {
          const addPromise = this.$apollo.mutate({
            mutation: LIBRARY_TRACKS_ADD_RIGHT_HOLDER,
            variables: {
              libraryId: this.currentWorkspace.libraries[0].id,
              libraryFilesIds: tracksToEditIds,
              rightHolderName: row.name,
              rightHolderRole: row.role,
              masteringPercentage: parseFloat(row.mastering_percentage) || 0,
              publishingPercentage: parseFloat(row.publishing_percentage) || 0
            }
          });
          promisesArray.push(addPromise);
        });
      }
      if (this.updatedRows.length > 0 && !this.isMultipleTrackEdition) {
        this.updatedRows.forEach(row => {
          const updatePromise = this.$apollo.mutate({
            mutation: LIBRARY_TRACKS_UPDATE_RIGHT_HOLDER,
            variables: {
              libraryId: this.currentWorkspace.libraries[0].id,
              libraryFileId: this.tracksToEdit[0].id,
              trackRightHolderId: row.track_right_holder_id,
              rightHolderRole: row.role,
              rightHolderName: row.name,
              masteringPercentage: parseFloat(row.mastering_percentage) || 0,
              publishingPercentage: parseFloat(row.publishing_percentage) || 0
            }
          });
          promisesArray.push(updatePromise);
        });
      }
      if (this.deletedRows.length > 0 && !this.isMultipleTrackEdition) {
        this.deletedRows.forEach(row => {
          const deletePromise = this.$apollo.mutate({
            mutation: LIBRARY_TRACKS_DELETE_RIGHT_HOLDER,
            variables: {
              libraryId: this.currentWorkspace.libraries[0].id,
              libraryFileId: this.tracksToEdit[0].id,
              rightHolderId: row.id,
              rightHolderRole: row.role
            }
          });
          promisesArray.push(deletePromise);
        });
      }
      if (promisesArray.length === 0) {
        return;
      }
      Promise.all(promisesArray)
        .then(() => {
          if (this.rows.length === 0) {
            this.addRow();
          }
          setTimeout(() => {
            this.initData();
          }, 1000);
          this.pendingStatus = false;
          bus.$emit('showAlert', {
            message: { key: 'modificationSuccess' },
            style: 'success',
            delay: 5000
          });
        })
        .catch(error => {
          this.pendingStatus = false;
          const errorKey = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key: errorKey },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.right-holder-panel-body {
  visibility: inherit;
  &-row {
    display: flex;
    align-items: flex-end;
    position: relative;
    .textfield {
      min-width: unset;
    }
    &-name {
      position: relative;
      width: 190px;
      margin-right: 4px;
    }
    &-role {
      width: 130px;
      margin-right: 4px;
    }
    &-percent {
      width: 64px;
      &:not(:last-child) {
        margin-right: 4px;
      }
    }
    &-cross {
      cursor: pointer;
      display: flex;
      align-items: center;
      line-height: 100%;
      height: 40px;
      position: absolute;
      right: -12px;
      color: $color_neutral_60;
      &:hover {
        color: $color_neutral_100;
      }
    }
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  &-add {
    margin-left: -6px;
    margin-top: 32px;
  }
  &-total {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 8px;
    border-top: 1px solid $color_neutral_40;
    margin-top: 32px;
    &-title {
      display: flex;
      align-items: flex-end;
      font-style: normal;
      font-size: 14px;
      letter-spacing: 0;
      color: $color_neutral_60;
      line-height: 100%;
      margin-bottom: 8px;
      .ri-information-line {
        color: $color_neutral_40;
        margin-left: 4px;
      }
    }
    &-percent {
      display: flex;
      margin-right: 7px;
      &-- {
        width: 64px;
        box-sizing: border-box;
        padding-left: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        &:not(:last-child) {
          margin-right: 4px;
        }
      }
    }
  }
  &-info {
    margin-top: 24px;
  }
}
.drop-all-btn {
  margin-top: 8px;
}
.right-holder-panel-body-row:not(:last-child).mb5 {
  margin-bottom: 24px;
}
.loader {
  display: flex;
  justify-content: center;
}
</style>

<style>
.right-holder-panel-body-row-percent .textfield .textfield-container {
  padding-right: 0;
}
</style>
