import gql from 'graphql-tag';
import {
  tracksFragment,
  artistsFragment,
  supportsFragment,
  rightHoldersFragment
} from '../fragments';

export const DELETE_LIBRARY_FILE = gql`
  mutation deleteLibraryFiles($libraryId: ID!, $libraryFileIds: [ID]!) {
    deleteLibraryFiles(libraryId: $libraryId, libraryFileIds: $libraryFileIds)
  }
`;

export const ARTIST_IMAGE_UPDATE = gql`
  mutation ArtistImageUpdate($artist_id: ID!, $workspace_id: ID!, $upload_id: ID!) {
    ArtistImageUpdate(artist_id: $artist_id, workspace_id: $workspace_id, upload_id: $upload_id)
  }
`;

export const ARTIST_INITIALIZE_IMAGE_UPLOAD = gql`
  mutation ArtistInitializeImageUpload(
    $workspace_id: ID!
    $artist_id: ID!
    $file_name: String!
    $size: String!
    $mime_type: String!
  ) {
    ArtistInitializeImageUpload(
      workspace_id: $workspace_id
      artist_id: $artist_id
      file_name: $file_name
      size: $size
      mime_type: $mime_type
    ) {
      upload_id
      url
    }
  }
`;

export const ARTIST_IMAGE_REMOVE = gql`
  mutation ArtistImageRemove($artistId: ID!, $workspaceId: ID!) {
    ArtistImageRemove(artistId: $artistId, workspaceId: $workspaceId)
  }
`;

export const LIBRARY_TRACKS_UPDATE = gql`
  mutation LibraryTracksUpdate($input: [LibraryFileUpdateInput]) {
    LibraryTracksUpdate(input: $input) {
      last_page
      data {
        ...tracks
      }
    }
  }
  ${tracksFragment.tracks}
`;

export const LIBRARY_TRACKS_SUPPORT_UPDATE = gql`
  mutation LibraryTracksUpdate($input: [LibraryFileUpdateInput]) {
    LibraryTracksUpdate(input: $input) {
      last_page
      data {
        id
        supports {
          id
          name
          slug
          release_date
          type
          tracks_total
          set_index
          set_total
          upc
          images {
            type
            file_name
            url
            thumbnails {
              type
              width
              height
              url
            }
          }
          tracks {
            id
          }
          tracks_number
          tracks_length
          tracks_ids
          track_position
        }
      }
    }
  }
`;

export const LIBRARY_TRACKS_UPDATE_RIGHT_HOLDER = gql`
  mutation LibraryTrackUpdateRightHolder(
    $libraryId: ID!
    $libraryFileId: ID!
    $trackRightHolderId: ID!
    $rightHolderRole: String
    $rightHolderName: String
    $masteringPercentage: Float
    $publishingPercentage: Float
  ) {
    LibraryTrackUpdateRightHolder(
      libraryId: $libraryId
      libraryFileId: $libraryFileId
      trackRightHolderId: $trackRightHolderId
      rightHolderRole: $rightHolderRole
      rightHolderName: $rightHolderName
      masteringPercentage: $masteringPercentage
      publishingPercentage: $publishingPercentage
    ) {
      id
      right_holders {
        ...rightHolders
      }
    }
  }
  ${rightHoldersFragment.rightHolders}
`;

export const LIBRARY_TRACKS_ADD_RIGHT_HOLDER = gql`
  mutation LibraryTracksAddRightHolder(
    $libraryId: ID!
    $libraryFilesIds: [ID!]
    $rightHolderRole: String!
    $rightHolderName: String!
    $masteringPercentage: Float!
    $publishingPercentage: Float!
  ) {
    LibraryTracksAddRightHolder(
      libraryId: $libraryId
      libraryFilesIds: $libraryFilesIds
      rightHolderRole: $rightHolderRole
      rightHolderName: $rightHolderName
      masteringPercentage: $masteringPercentage
      publishingPercentage: $publishingPercentage
    ) {
      id
      right_holders {
        ...rightHolders
      }
    }
  }
  ${rightHoldersFragment.rightHolders}
`;

export const LIBRARY_TRACK_DROP_ALL_RIGHT_HOLDERS = gql`
  mutation LibraryTrackDropAllRightHolders($libraryId: ID!, $libraryFilesIds: [ID!]) {
    LibraryTrackDropAllRightHolders(libraryId: $libraryId, libraryFilesIds: $libraryFilesIds) {
      id
      right_holders {
        ...rightHolders
      }
    }
  }
  ${rightHoldersFragment.rightHolders}
`;

export const LIBRARY_TRACKS_DELETE_RIGHT_HOLDER = gql`
  mutation LibraryTrackDeleteRightHolder(
    $libraryId: ID!
    $libraryFileId: ID!
    $rightHolderId: ID!
    $rightHolderRole: String!
  ) {
    LibraryTrackDeleteRightHolder(
      libraryId: $libraryId
      libraryFileId: $libraryFileId
      rightHolderId: $rightHolderId
      rightHolderRole: $rightHolderRole
    ) {
      id
      right_holders {
        ...rightHolders
      }
    }
  }
  ${rightHoldersFragment.rightHolders}
`;

export const ARTIST_UPDATE = gql`
  mutation ArtistUpdate($workspaceId: ID!, $artistId: ID!, $name: String!) {
    ArtistUpdate(workspaceId: $workspaceId, artistId: $artistId, name: $name) {
      ...artists
    }
  }
  ${artistsFragment.artists}
`;

export const SUPPORT_IMAGE_UPDATE = gql`
  mutation SupportImageUpdate($supportId: ID!, $uploadId: ID!, $type: SupportImageType!) {
    SupportImageUpdate(supportId: $supportId, uploadId: $uploadId, type: $type) {
      type
      url
    }
  }
`;

export const SUPPORT_INITIALIZE_IMAGE_UPLOAD = gql`
  mutation SupportInitializeImageUpload(
    $supportId: ID!
    $type: SupportImageType!
    $file_name: String!
    $size: String!
    $mime_type: String!
  ) {
    SupportInitializeImageUpload(
      supportId: $supportId
      type: $type
      file_name: $file_name
      size: $size
      mime_type: $mime_type
    ) {
      upload_id
      url
    }
  }
`;

export const SUPPORT_DELETE_FRONT_COVER = gql`
  mutation SupportDeleteFrontCover($supportId: ID!) {
    SupportDeleteFrontCover(supportId: $supportId)
  }
`;

export const SUPPORT_UPDATE = gql`
  mutation SupportUpdate(
    $libraryId: ID!
    $workspaceId: ID!
    $supportId: ID!
    $name: String
    $type: String
    $tracksTotal: Int
    $setIndex: Int
    $setTotal: Int
    $upc: String
    $releaseDate: String
    $artistsIds: [ID]
    $labelsIds: [ID]
  ) {
    SupportUpdate(
      libraryId: $libraryId
      workspaceId: $workspaceId
      supportId: $supportId
      name: $name
      type: $type
      tracksTotal: $tracksTotal
      setIndex: $setIndex
      setTotal: $setTotal
      upc: $upc
      releaseDate: $releaseDate
      artistsIds: $artistsIds
      labelsIds: $labelsIds
    ) {
      ...supports
    }
  }
  ${supportsFragment.supports}
`;

export const SUPPORT_UPDATE_TRACK_POSITION = gql`
  mutation SupportUpdateTrackPosition($supportId: ID!, $libraryFileId: ID!, $trackPosition: Int!) {
    SupportUpdateTrackPosition(
      supportId: $supportId
      libraryFileId: $libraryFileId
      trackPosition: $trackPosition
    ) {
      ...tracks
    }
  }
  ${tracksFragment.tracks}
`;

export const ARTIST_CREATE = gql`
  mutation ArtistCreate($workspaceId: ID!, $name: String!) {
    ArtistCreate(workspaceId: $workspaceId, name: $name) {
      ...artists
    }
  }
  ${artistsFragment.artists}
`;

export const SUPPORT_CREATE = gql`
  mutation SupportCreate(
    $libraryId: ID!
    $workspaceId: ID!
    $name: String!
    $mainArtistId: ID!
    $type: String
    $tracksTotal: Int
    $setIndex: Int
    $setTotal: Int
    $upc: String
    $releaseDate: String
  ) {
    SupportCreate(
      libraryId: $libraryId
      workspaceId: $workspaceId
      name: $name
      mainArtistId: $mainArtistId
      type: $type
      tracksTotal: $tracksTotal
      setIndex: $setIndex
      setTotal: $setTotal
      upc: $upc
      releaseDate: $releaseDate
    ) {
      ...supports
    }
  }
  ${supportsFragment.supports}
`;

export const AUTHOR_CREATE = gql`
  mutation AuthorCreate($workspaceId: ID!, $name: String!) {
    AuthorCreate(workspaceId: $workspaceId, name: $name) {
      id
      name
    }
  }
`;

export const LABELS_CREATE = gql`
  mutation LabelsCreate($workspaceId: ID!, $names: [String]!) {
    LabelsCreate(workspaceId: $workspaceId, names: $names) {
      id
      name
    }
  }
`;

export const COMPOSER_CREATE = gql`
  mutation ComposerCreate($workspaceId: ID!, $name: String!) {
    ComposerCreate(workspaceId: $workspaceId, name: $name) {
      id
      name
    }
  }
`;

export const PUBLISHERS_CREATE = gql`
  mutation PublishersCreate($workspaceId: ID!, $names: [String]!) {
    PublishersCreate(workspaceId: $workspaceId, names: $names) {
      id
      name
    }
  }
`;

export const LIBRARY_TRACK_DROP_ALL_TAGS = gql`
  mutation LibraryTrackDropAllTags($libraryFilesIds: [ID]!) {
    LibraryTrackDropAllTags(libraryFilesIds: $libraryFilesIds)
  }
`;

export const ARCHIVE_LIBRARY_TRACKS = gql`
  mutation ArchiveLibraryTracks($libraryId: ID!, $tracksIds: [ID]!, $isOriginal: Boolean!) {
    ArchiveLibraryTracks(libraryId: $libraryId, tracksIds: $tracksIds, isOriginal: $isOriginal) {
      id
      name
      type
      status
      created_at
      updated_at
      url {
        url
      }
    }
  }
`;

export const LIBRARY_FILE_SHARE = gql`
  mutation LibraryFileShare(
    $workspaceId: ID!
    $libraryFilesIds: [ID!]
    $access_rights: [String]
    $access_password: String
    $email: String
    $slug: String
    $custom_name: String
    $custom_message: String
    $reply_to: String
  ) {
    LibraryFileShare(
      workspaceId: $workspaceId
      libraryFilesIds: $libraryFilesIds
      access_rights: $access_rights
      access_password: $access_password
      email: $email
      slug: $slug
      custom_name: $custom_name
      custom_message: $custom_message
      reply_to: $reply_to
    ) {
      id
      workspace_id
      shared_by
      status
      created_at
      updated_at
    }
  }
`;

export const LIBRARY_SUPPORT_SHARE = gql`
  mutation LibrarySupportShare(
    $workspaceId: ID!
    $librarySupportId: ID!
    $access_rights: [String]
    $access_password: String
    $email: String
    $slug: String
    $custom_name: String
    $custom_message: String
    $reply_to: String
  ) {
    LibrarySupportShare(
      workspaceId: $workspaceId
      librarySupportId: $librarySupportId
      access_rights: $access_rights
      access_password: $access_password
      email: $email
      slug: $slug
      custom_name: $custom_name
      custom_message: $custom_message
      reply_to: $reply_to
    ) {
      id
      workspace_id
      shared_by
      status
      created_at
      updated_at
    }
  }
`;

export const LIBRARY_ARTIST_SHARE = gql`
  mutation LibraryArtistShare(
    $workspaceId: ID!
    $libraryArtistId: ID!
    $access_rights: [String]
    $access_password: String
    $email: String
    $slug: String
    $custom_name: String
    $custom_message: String
    $reply_to: String
  ) {
    LibraryArtistShare(
      workspaceId: $workspaceId
      libraryArtistId: $libraryArtistId
      access_rights: $access_rights
      access_password: $access_password
      email: $email
      slug: $slug
      custom_name: $custom_name
      custom_message: $custom_message
      reply_to: $reply_to
    ) {
      id
      workspace_id
      shared_by
      status
      created_at
      updated_at
    }
  }
`;

export const LIBRARY_COPY_TRACKS_TO_WORKSPACE = gql`
  mutation LibraryCopyTracksToWorkspace(
    $sourceWorkspaceId: ID!
    $targetWorkspaceId: ID!
    $libraryFilesIds: [ID]!
    $deleteMode: DeleteMode
  ) {
    LibraryCopyTracksToWorkspace(
      sourceWorkspaceId: $sourceWorkspaceId
      targetWorkspaceId: $targetWorkspaceId
      libraryFilesIds: $libraryFilesIds
      deleteMode: $deleteMode
    ) {
      source_library_file_id
      library_file_id
      status
      error
    }
  }
`;

export const LIBRARY_COPY_ARTIST_TO_WORKSPACE = gql`
  mutation LibraryCopyArtistToWorkspace(
    $sourceWorkspaceId: ID!
    $targetWorkspaceId: ID!
    $artistId: ID!
    $deleteMode: DeleteMode
  ) {
    LibraryCopyArtistToWorkspace(
      sourceWorkspaceId: $sourceWorkspaceId
      targetWorkspaceId: $targetWorkspaceId
      artistId: $artistId
      deleteMode: $deleteMode
    ) {
      source_artist_id
      artist_id
      status
      errors
    }
  }
`;

export const LIBRARY_COPY_SUPPORTS_TO_WORKSPACE = gql`
  mutation LibraryCopySupportsToWorkspace(
    $sourceWorkspaceId: ID!
    $targetWorkspaceId: ID!
    $supportIds: [ID]!
    $deleteMode: DeleteMode
  ) {
    LibraryCopySupportsToWorkspace(
      sourceWorkspaceId: $sourceWorkspaceId
      targetWorkspaceId: $targetWorkspaceId
      supportIds: $supportIds
      deleteMode: $deleteMode
    ) {
      source_support_id
      support_id
      status
      error
    }
  }
`;

export const LIBRARY_INITIALIZE_TRACK_UPLOAD = gql`
  mutation LibraryInitializeTrackUpload(
    $library_id: ID!
    $file_name: String!
    $size: String!
    $mime_type: String!
  ) {
    LibraryInitializeTrackUpload(
      library_id: $library_id
      file_name: $file_name
      size: $size
      mime_type: $mime_type
    ) {
      upload_id
      url
    }
  }
`;

export const ADD_LIBRARY_FILE = gql`
  mutation AddLibraryFile($library_id: ID!, $upload_id: ID!, $source: UploadSource = webapp) {
    AddLibraryFile(library_id: $library_id, upload_id: $upload_id, source: $source) {
      id
    }
  }
`;
