
<div class="upload-to">
  <div class="upload-to-body">
    <project-list class="upload-to-body-project-list">
      <template v-slot:default="projectListProps">
        <template-list-of-project
          ref="templateListOfProject"
          :projects="projectListProps.projectList"
          :loadingProjects="projectListProps.loadingProjects"
          :noSearchResult="projectListProps.noSearchResult"
          :showObserver="projectListProps.showProjectObserver"
          :emptyPlaceholder="{
            buttonLabel: $t('newProject'),
            buttonIcon: 'ri-folder-add-fill',
            text: $t('emptyProjectDescription')
          }"
          :onlyNonAudio="sortedFiles.audioFiles && sortedFiles.audioFiles.length > 0"
          :displayNewProjectInput.sync="displayNewProjectInput"
          :displayProjectRequiredError="displayProjectRequiredError"
          @updateSearchValue="projectListProps.searchProject"
          @fetchMoreProjects="projectListProps.fetchMoreProjects"
          @listProjects="projectListProps.listProjects"
          @setSelectedProjectId="setSelectedProjectId"
          @createProject="createProject"
        />
      </template>
    </project-list>
  </div>
  <div class="upload-to-footer">
    <icon-button
      btnStyle="secondary"
      size="medium"
      icon="ri-folder-add-line"
      @submit="handleNewProjectClick"
    />
    <div class="upload-to-buttons">
      <div class="upload-to-buttons--">
        <submit-button
          btnStyle="tertiary"
          size="medium"
          :label="$t('cancel')"
          @submit="$emit('closeModal')"
        />
      </div>
      <div class="upload-to-buttons--">
        <submit-button
          btnStyle="primary"
          size="medium"
          :label="$t('upload')"
          :pending="pending === 'PENDING'"
          @submit="uploadTo"
        />
      </div>
    </div>
  </div>
</div>
