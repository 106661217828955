
<div class="details-panel-body" :key="renderKey">
  <div class="details-panel-body-section">
    <div class="details-panel-body-section-title">{{ $t('soundRecording') }}</div>
    <div class="details-panel-body-section--">
      <text-field
        ref="inputTitle"
        inputType="text"
        :errorMessage="titleErrorMessage"
        :fieldModel.sync="title"
        :label="$t('title')"
        large
        :placeholder="placeholder"
        :value="isMultipleTrackEdition ? null : tracksToEdit[0].title"
        horizontalDisplaying
        :inputWidth="300"
        :withEditButton="isMultipleTrackEdition"
        @handleBlur="autosave(title, tracksToEdit[0].title, 'title', false, false)"
      />
    </div>
    <div class="details-panel-body-section-dropdown">
      <div class="details-panel-body-section-dropdown-label">{{ $t('artistsLabel') }}</div>
      <div class="details-panel-body-section-dropdown--">
        <auto-complete-list
          :queries="autoCompleteQueries.artists"
          :dataLoaded="isArtistsDataLoaded"
          dataType="ArtistsAutocomplete"
          storeEvent="changeIsArtistsLoaded"
          :ignoreUnknown="true"
        >
          <template v-slot:default="slotProps">
            <input-dropdown
              key="artist-dropdown"
              id="artist-dropdown"
              isMultipleSelection
              :width="334"
              :placeholder="defaultArtistOptions.length > 0 ? '' : placeholder"
              :defaultOptions="defaultArtistOptions"
              :withEditButton="isMultipleTrackEdition"
              :defaultList="slotProps.items"
              :loadingOptions="slotProps.isLoading"
              :showObserver="slotProps.showObserver"
              :fetchingMoreOptions="slotProps.fetchingMoreItems"
              @listOptions="slotProps.fetch"
              @fetchMoreOptions="slotProps.fetchMoreItems"
              @searchOption="slotProps.search"
              @handleResult="setArtists"
            />
          </template>
        </auto-complete-list>
      </div>
    </div>
    <div class="details-panel-body-section-dropdown" style="margin-bottom: 9px">
      <div class="details-panel-body-section-dropdown-label">{{ $t('album') }}</div>
      <div class="details-panel-body-section-dropdown--">
        <auto-complete-list
          :queries="autoCompleteQueries.supports"
          :dataLoaded="isSupportsDataLoaded"
          dataType="SupportsAutocomplete"
          storeEvent="changeIsSupportsLoaded"
          :ignoreUnknown="true"
        >
          <template v-slot:default="slotProps">
            <input-dropdown
              key="support-dropdown"
              id="support-dropdown"
              :width="334"
              :placeholder="supportOptions.length > 0 ? '' : placeholder"
              :defaultOptions="supports || supportOptions"
              :defaultList="slotProps.items"
              :loadingOptions="slotProps.isLoading"
              :showObserver="slotProps.showObserver"
              :fetchingMoreOptions="slotProps.fetchingMoreItems"
              :withEditButton="isMultipleTrackEdition"
              preventRemoveOptOnKeyUp
              preventAddNew
              @preventedAddNew="DisplaySupportModal(true)"
              @listOptions="slotProps.fetch"
              @fetchMoreOptions="slotProps.fetchMoreItems"
              @searchOption="slotProps.search"
              @handleResult="setSupports"
              @handleInput="HandleSupportInput"
              @handleBlur="HandleSupportBlur"
            />
          </template>
        </auto-complete-list>
      </div>
    </div>
    <div
      class="details-panel-body-section-- details-panel-body-section-inline section-tracknumber"
      v-if="!isMultipleTrackEdition && tracksToEdit[0].supports.length > 0"
    >
      <label
        class="details-panel-body-section-label details-panel-body-section-label-tracknumber"
      >
        Track number
      </label>
      <text-field
        inputType="number"
        :disabled="isMultipleTrackEdition"
        :errorMessage="trackPositionErrorMessage"
        :fieldModel.sync="trackPosition"
        errorMessageException
        :placeholder="placeholder"
        :value="isMultipleTrackEdition ? null : tracksToEdit[0].supports[0].position"
        :inputWidth="46"
        @handleBlur="
          autosave(
            trackPosition,
            tracksToEdit[0].supports[0].position,
            'trackPosition',
            true,
            false
          )
        "
      />
      <div class="details-panel-body-section-label-tracknumber-separator">of</div>
      <text-field
        inputType="number"
        :disabled="isMultipleTrackEdition"
        :errorMessage="trackTotalErrorMessage"
        :fieldModel.sync="trackTotal"
        errorMessageException
        :placeholder="placeholder"
        :value="isMultipleTrackEdition ? null : tracksToEdit[0].supports[0].total"
        :inputWidth="46"
        @handleBlur="
          autosave(trackTotal, tracksToEdit[0].supports[0].total, 'trackTotal', true, false)
        "
      />
    </div>

    <div class="details-panel-body-section-dropdown">
      <div class="details-panel-body-section-dropdown-label">{{ $t('genresLabel') }}</div>
      <div class="details-panel-body-section-dropdown--">
        <input-dropdown
          key="genre-dropdown"
          id="genre-dropdown"
          isMultipleSelection
          :width="334"
          :preventAddNew="true"
          :withAddButton="false"
          :placeholder="placeholder"
          :defaultOptions="defaultGenreOptions"
          :withEditButton="isMultipleTrackEdition"
          :defaultList="formatedId3TagList"
          @handleResult="setGenres"
        />
      </div>
    </div>
    <div class="details-panel-body-section--">
      <text-field
        inputType="text"
        :errorMessage="isrcErrorMessage"
        :fieldModel.sync="isrc"
        label="ISRC"
        large
        :placeholder="isrcPlaceholder"
        mask="aa-***-00-*****"
        :value="isMultipleTrackEdition ? null : tracksToEdit[0].isrc"
        horizontalDisplaying
        :inputWidth="300"
        :withEditButton="isMultipleTrackEdition"
        @toggleDisableStatus="setIsrcPlaceholder"
        @handleBlur="autosave(isrc, tracksToEdit[0].isrc, 'isrc', false, false)"
      />
    </div>
    <div class="details-panel-body-section--" style="width: 296px">
      <text-field
        inputType="number"
        :errorMessage="bpmErrorMessage"
        :fieldModel.sync="bpm"
        label="BPM"
        large
        :placeholder="placeholder"
        :value="isMultipleTrackEdition ? null : tracksToEdit[0].bpm"
        horizontalDisplaying
        :inputWidth="126"
        :withEditButton="isMultipleTrackEdition"
        @handleBlur="autosave(bpm, tracksToEdit[0].bpm, 'bpm', true, false)"
      />
    </div>
    <div
      class="details-panel-body-section-- details-panel-body-section-inline"
      style="width: 214px"
    >
      <div class="details-panel-body-section-label details-panel-body-section-label-rating">
        {{ $t('rating') }}
      </div>
      <stars-rating
        :length="5"
        :itemRating="isMultipleTrackEdition ? null : tracksToEdit[0].rating"
        @setTrackRating="setTrackRating"
      />
    </div>
    <div class="details-panel-body-section-- details-panel-body-section-inline">
      <label class="details-panel-body-section-label details">{{ $t('recordingDate') }}</label>
      <date-picker
        class="details-panel-body-section-label-datepicker"
        local="en"
        large
        :withEditButton="isMultipleTrackEdition"
        :selectedDate="
          isMultipleTrackEdition ? null : getFormattedTimestamp(tracksToEdit[0].recordingDate)
        "
        :fieldModel.sync="recordingDate"
        :placeholder="datePlaceholder"
        @datePicked="selectRecordingDate"
      />
    </div>
    <div class="details-panel-body-section-dropdown">
      <div class="details-panel-body-section-dropdown-label">{{ $t('labelLabel') }}</div>
      <div class="details-panel-body-section-dropdown--">
        <auto-complete-list
          :queries="autoCompleteQueries.labels"
          :dataLoaded="isLabelsDataLoaded"
          dataType="LabelsAutocomplete"
          storeEvent="changeIsLabelsLoaded"
        >
          <template v-slot:default="slotProps">
            <input-dropdown
              key="labels-dropdown"
              id="labels-dropdown"
              :placeholder="defaultLabelsOptions.length > 0 ? '' : placeholder"
              :defaultOptions="defaultLabelsOptions"
              isMultipleSelection
              :width="334"
              :withEditButton="isMultipleTrackEdition"
              :defaultList="slotProps.items"
              :loadingOptions="slotProps.isLoading"
              :showObserver="slotProps.showObserver"
              :fetchingMoreOptions="slotProps.fetchingMoreItems"
              @listOptions="slotProps.fetch"
              @fetchMoreOptions="slotProps.fetchMoreItems"
              @searchOption="slotProps.search"
              @handleResult="setLabels"
            />
          </template>
        </auto-complete-list>
      </div>
    </div>
  </div>
  <div class="details-panel-body-section">
    <div class="details-panel-body-section-title">{{ $t('musicalWork') }}</div>
    <div class="details-panel-body-section-dropdown">
      <div class="details-panel-body-section-dropdown-label">{{ $t('authorsLabel') }}</div>
      <div class="details-panel-body-section-dropdown--">
        <auto-complete-list
          :queries="autoCompleteQueries.authors"
          :dataLoaded="isAuthorsDataLoaded"
          dataType="AuthorsAutocomplete"
          storeEvent="changeIsAuthorsLoaded"
        >
          <template v-slot:default="slotProps">
            <input-dropdown
              key="authors-dropdown"
              id="authors-dropdown"
              :placeholder="defaultAuthorOptions.length > 0 ? '' : placeholder"
              :defaultOptions="defaultAuthorOptions"
              isMultipleSelection
              :width="334"
              :withEditButton="isMultipleTrackEdition"
              :defaultList="slotProps.items"
              :loadingOptions="slotProps.isLoading"
              :showObserver="slotProps.showObserver"
              :fetchingMoreOptions="slotProps.fetchingMoreItems"
              @listOptions="slotProps.fetch"
              @fetchMoreOptions="slotProps.fetchMoreItems"
              @searchOption="slotProps.search"
              @handleResult="setAuthors"
            />
          </template>
        </auto-complete-list>
      </div>
    </div>
    <div class="details-panel-body-section-dropdown">
      <div class="details-panel-body-section-dropdown-label">{{ $t('composersLabel') }}</div>
      <div class="details-panel-body-section-dropdown--">
        <auto-complete-list
          :queries="autoCompleteQueries.composers"
          :dataLoaded="isComposersDataLoaded"
          dataType="ComposersAutocomplete"
          storeEvent="changeIsComposersLoaded"
        >
          <template v-slot:default="slotProps">
            <input-dropdown
              key="composers-dropdown"
              id="composers-dropdown"
              :placeholder="defaultComposerOptions.length > 0 ? '' : placeholder"
              :defaultOptions="defaultComposerOptions"
              isMultipleSelection
              :width="334"
              :withEditButton="isMultipleTrackEdition"
              :defaultList="slotProps.items"
              :loadingOptions="slotProps.isLoading"
              :showObserver="slotProps.showObserver"
              :fetchingMoreOptions="slotProps.fetchingMoreItems"
              @listOptions="slotProps.fetch"
              @fetchMoreOptions="slotProps.fetchMoreItems"
              @searchOption="slotProps.search"
              @handleResult="setComposers"
            />
          </template>
        </auto-complete-list>
      </div>
    </div>
    <div class="details-panel-body-section--">
      <text-field
        inputType="text"
        :errorMessage="iswcErrorMessage"
        :fieldModel.sync="iswc"
        label="ISWC"
        large
        :placeholder="iswcPlaceholder"
        mask="a-000.000.000-0"
        :value="isMultipleTrackEdition ? null : tracksToEdit[0].iswc"
        horizontalDisplaying
        :inputWidth="300"
        :withEditButton="isMultipleTrackEdition"
        @toggleDisableStatus="setIswcPlaceholder"
        @handleBlur="autosave(iswc, tracksToEdit[0].iswc, 'iswc', false, false)"
      />
    </div>
    <div class="details-panel-body-section-dropdown">
      <div class="details-panel-body-section-dropdown-label">{{ $t('publishers') }}</div>
      <div class="details-panel-body-section-dropdown--">
        <auto-complete-list
          :queries="autoCompleteQueries.publishers"
          :dataLoaded="isPublishersDataLoaded"
          dataType="PublishersAutocomplete"
          storeEvent="changeIsPublishersLoaded"
        >
          <template v-slot:default="slotProps">
            <input-dropdown
              key="publishers-dropdown"
              id="publishers-dropdown"
              :placeholder="defaultPublishersOptions.length > 0 ? '' : placeholder"
              :defaultOptions="defaultPublishersOptions"
              isMultipleSelection
              :width="334"
              :withEditButton="isMultipleTrackEdition"
              :defaultList="slotProps.items"
              :loadingOptions="slotProps.isLoading"
              :showObserver="slotProps.showObserver"
              :fetchingMoreOptions="slotProps.fetchingMoreItems"
              @listOptions="slotProps.fetch"
              @fetchMoreOptions="slotProps.fetchMoreItems"
              @searchOption="slotProps.search"
              @handleResult="setPublishers"
            />
          </template>
        </auto-complete-list>
      </div>
    </div>
  </div>
  <div class="details-panel-body-section">
    <div class="details-panel-body-section-title-comment">
      {{ $t('comment') }}
    </div>
    <div
      v-if="this.currentWorkspace.hubs.length > 0"
      class="details-panel-body-section-description"
    >
      {{ $t('commentDescriptionFirstPart') }}
      <span
        class="details-panel-body-section-description-link"
        @click="redirectToGeneralSettings"
        >{{ $t('commentDescriptionSecondPart') }}</span
      >
    </div>
    <div class="details-panel-body-section--">
      <text-area
        :fieldModel.sync="comment"
        :placeholder="placeholder"
        adaptativeHeight
        :value="isMultipleTrackEdition ? null : tracksToEdit[0].comment"
        :withEditButton="isMultipleTrackEdition"
        @validate="autosave(comment, tracksToEdit[0].comment, 'comment', false, false)"
      />
    </div>
  </div>
  <div class="details-panel-body-section">
    <div class="details-panel-body-section-title">{{ $t('lyrics') }}</div>
    <div class="details-panel-body-section--">
      <text-area
        :fieldModel.sync="lyrics"
        :placeholder="placeholder"
        adaptativeHeight
        :value="isMultipleTrackEdition ? null : tracksToEdit[0].lyrics"
        :withEditButton="isMultipleTrackEdition"
        @validate="autosave(lyrics, tracksToEdit[0].lyrics, 'lyrics', false, false)"
      />
    </div>
  </div>
</div>
