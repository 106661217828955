<template>
  <div class="projects-modal-body" @click.stop>
    <div class="projects-modal-body-txtfield">
      <text-field
        inputType="text"
        :errorMessage="projectNameMessageValidation"
        :fieldModel.sync="$v.projectName.$model"
        :label="$t('projectName')"
        focusOnLoad
        @keyup.enter.native="handleKeyUp"
      />
    </div>
    <div class="projects-modal-body-txtfield">
      <text-area
        :label="$t('addDescription')"
        :errorMessage="projectDescriptionMessageValidation"
        :fieldModel.sync="$v.projectDescription.$model"
      />
    </div>
    <div class="projects-modal-body-buttons">
      <div class="projects-modal-body-buttons--">
        <submit-button
          btnStyle="tertiary"
          size="medium"
          :label="$t('cancel')"
          @submit="closeCreateProjectModal"
        />
      </div>
      <div class="projects-modal-body-buttons--">
        <submit-button
          btnStyle="primary"
          size="medium"
          :label="$t('create')"
          :disabled="disabled"
          :pending="pending === 'PENDING'"
          @submit="createProject"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { bus } from '@/utils/bus';
import { trackEvent } from '@/utils/functions/analytics';
import { get as getRoute } from '@/router/routes';
import { PROJECT_NEW } from '@/graphql/mutations/project';
import { PROJECTS_INFORMATION, PROJECT_LIST } from '@/graphql/queries/project';
import { PAGINATOR_LIMIT } from '@/utils/constants';
import { getGraphQlErrorCode } from '@/utils/functions/global';

export default {
  data() {
    return {
      projectName: '',
      projectDescription: null,
      pending: 'OK'
    };
  },
  props: {
    redirectToProject: {
      type: Boolean,
      required: false,
      default: false
    },
    addTracksToProject: {
      type: Boolean,
      required: false,
      default: false
    },
    filesIds: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  validations: {
    projectName: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(255)
    },
    projectDescription: {
      maxLength: maxLength(2000)
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    projectNameMessageValidation() {
      if (!this.$v.projectName.minLength) {
        return this.$t('minLength', {
          nbChars: this.$v.projectName.$params.minLength.min,
          label: this.$t('projectName')
        });
      }
      if (!this.$v.projectName.maxLength) {
        return this.$t('maxLength', {
          nbChars: this.$v.projectName.$params.maxLength.max,
          label: this.$t('projectName')
        });
      }
      return null;
    },
    projectDescriptionMessageValidation() {
      if (!this.$v.projectDescription.maxLength) {
        return this.$t('maxLength', {
          nbChars: this.$v.projectDescription.$params.maxLength.max,
          label: 'Description'
        });
      }
      return null;
    },
    disabled() {
      return this.$v.projectName.$invalid || this.$v.projectDescription.$invalid;
    }
  },
  methods: {
    closeCreateProjectModal() {
      this.projectName = null;
      this.projectDescription = null;
      this.$v.$touch();
      this.$emit('closeModal', { context: 'createProject' });
    },
    handleKeyUp() {
      if (!this.disabled) this.createProject();
    },
    createProject() {
      if (this.$isWorkspaceLockedVerification(this)) return;
      const cleanedProjectName = this.$v.projectName.$model.replaceAll('`', '');
      this.pending = 'PENDING';
      this.$apollo
        .mutate({
          mutation: PROJECT_NEW,
          variables: {
            name: cleanedProjectName,
            description: this.projectDescription,
            workspaceId: this.currentWorkspace.id
          },
          update: (store, response) => {
            bus.$emit('setNewProjectId', response.data.ProjectNew.id);
          },
          refetchQueries: [
            {
              query: PROJECT_LIST,
              variables: {
                workspaceId: this.currentWorkspace.id,
                status: 'active',
                page: 1,
                limit: PAGINATOR_LIMIT
              }
            },
            {
              query: PROJECTS_INFORMATION,
              variables: {
                workspaceId: this.currentWorkspace.id
              }
            }
          ]
        })
        .then(data => {
          if (this.redirectToProject) {
            this.$router.push(getRoute('projectDetails', { id: data.data.ProjectNew.id }));
          }
          if (this.addTracksToProject) {
            bus.$emit('addTracksToProject', {
              projectId: data.data.ProjectNew.id,
              libraryFilesIds: this.filesIds
            });
          }
          trackEvent(
            'Project Created',
            {
              category: 'project editing',
              label: 'confirmed',
              workspace_id: this.currentWorkspace.id,
              project_id: data.data.ProjectNew.id
            },
            {
              All: false,
              'Google Analytics': true,
              FullStory: true,
              Hubspot: true,
              Appcues: true,
              Amplitude: true,
              June: true
            }
          );
          this.closeCreateProjectModal();
          this.pending = 'OK';
          bus.$emit('showAlert', {
            message: { key: 'newProjectSuccess' },
            style: 'success',
            delay: 5000
          });
        })
        .catch(error => {
          this.closeCreateProjectModal();
          this.pending = 'OK';
          const key = getGraphQlErrorCode(error);
          this.projectName = null;
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.projects-modal {
  &-body {
    line-height: 150%;
    padding-top: 24px;

    &-description {
      padding: 24px 32px;
    }

    &-txtfield {
      padding: 0 32px 32px 32px;
      position: relative;
    }

    &-buttons {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid $color_neutral_30;
      padding: 8px 32px;

      &-- {
        &:first-child {
          margin: 0 16px 0 0;
        }
      }
    }
  }
}
</style>
