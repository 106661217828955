<template>
  <div class="header">
    <div class="header-content">
      <div class="header-content-main">
        <breadcrumb
          class="breadcrumb"
          :path="path"
          :iconClass="breadCrumbIcon"
          :iconStyle="mainPathToLower"
          @handleParentClick="breadcrumbClick"
        />
        <div class="header-btn" v-if="displayedBtn && displayedBtn[1] && !isUnknown">
          <div class="header-btn--" v-for="(btn, id) in displayedBtn[0]" :key="`${id}-headerBtn`">
            <more-dropdown
              v-if="btn.iconBtn"
              size="medium"
              :icon="btn.icon"
              :items="btn.items"
              :otherItems="btn.otherItems"
            />
            <btn-dropdown
              v-if="btn.btnDropdown"
              :options="btn.options"
              :btnProps="btn.params"
              @action="handleBtnDropdownActions"
            />
            <submit-button
              v-if="!btn.iconBtn && !btn.btnDropdown"
              size="medium"
              :btnStyle="btn.style"
              :iconClass="btn.icon"
              @submit="btn.action"
              :label="btn.label"
            />
          </div>
          <div class="header-separator"></div>
        </div>
      </div>
      <div class="header-content-icons">
        <a :href="faqUrl" class="header-help-icon" target="_blank" rel="noopener noreferrer">
          <i class="ri-question-fill"></i>
        </a>
        <project-notifications />
        <profile :user="me" />
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash.get';
import { mapGetters } from 'vuex';
import ProjectNotifications from '@/containers/dropdowns/projectNotifications';
import Profile from '@/containers/dropdowns/profile';
import CreateProjectModal from '@/containers/modals/createProject';
import ShareModal from '@/containers/modals/share';
import { ARTIST_DETAILS, SUPPORT_DETAILS } from '@/graphql/queries/library';
import { PROJECT_DETAILS_INFO, SHARE_DETAILS } from '@/graphql/queries/project';
import { INBOX_LIST } from '@/graphql/queries/inbox';
import { get as getRoute } from '@/router/routes';
import { bus } from '@/utils/bus';
import { trackEvent } from '@/utils/functions/analytics';
import { downloadProjectAsZip } from '@/utils/functions/download';
import BtnDropdown from '@/containers/dropdowns/submitBtn';
import MoveToAddTo from '@/containers/modals/moveToAddTo';

const PATH = {
  library: 'library',
  artist: 'artist',
  album: 'album',
  sharedTracks: 'shared/library/tracks/',
  sharedSubmission: 'shared/submission/',
  projects: 'projects',
  details: 'details',
  sharings: 'sharings',
  activity: 'activity',
  listProjects: 'listProjects',
  shared: 'shared',
  inbox: 'inbox'
};

const MAIN_PATH = {
  library: 'Library',
  projects: 'Projects',
  shared: 'Shared',
  inbox: 'Inbox'
};

export default {
  components: {
    ProjectNotifications,
    Profile,
    BtnDropdown
  },
  props: {
    me: {
      type: Object,
      required: true
    }
  },
  apollo: {
    artistDetails: {
      query: ARTIST_DETAILS,
      variables() {
        return {
          libraryId: this.currentWorkspace.libraries[0].id,
          artistId: this.$route.params.id
        };
      },
      watchLoading(loading) {
        if (loading) {
          this.artistReady = false;
        }
      },
      result(res) {
        this.isUnknown = get(res, 'data.artistDetails.id', 'unknown') === 'unknown';
        this.artistReady = true;
        this.subPathName = get(res, 'data.artistDetails.name', null);
        this.tracksIds = get(res, 'data.artistDetails.tracks_ids', []);
      },
      skip() {
        return this.pathDetail !== 'artist';
      }
    },
    ProjectDetails: {
      query: PROJECT_DETAILS_INFO,
      variables() {
        return {
          projectId: this.$route.params.id
        };
      },
      watchLoading(loading) {
        if (loading) {
          this.projectReady = false;
        }
      },
      result() {
        this.projectReady = true;
        if (this.ProjectDetails) {
          this.subPathName = this.ProjectDetails.name;
        }
      },
      skip() {
        return this.pathDetail !== 'projects';
      }
    },
    supportDetails: {
      query: SUPPORT_DETAILS,
      variables() {
        return {
          libraryId: this.currentWorkspace.libraries[0].id,
          supportId: this.$route.params.id
        };
      },
      watchLoading(loading) {
        if (loading) {
          this.albumReady = false;
        }
      },
      result(res) {
        this.isUnknown = get(res, 'data.supportDetails.id', 'unknown') === 'unknown';
        this.albumReady = true;
        this.subPathName = get(res, 'data.supportDetails.name', null);
        this.tracksIds = get(res, 'data.supportDetails.tracks_ids', []);
      },
      skip() {
        return this.pathDetail !== 'album';
      }
    },
    ShareDetails: {
      query: SHARE_DETAILS,
      variables() {
        return {
          shareId: this.$route.params.id
        };
      },
      result(res) {
        this.subPathName = res.data.ShareDetails.share_name;
      },
      skip() {
        const route = this.$route.path;
        return !(
          route.includes(`${PATH.sharedTracks}`) || route.includes(`${PATH.sharedSubmission}`)
        );
      }
    },
    InboxList: {
      query: INBOX_LIST,
      variables() {
        return {
          workspaceId: this.currentWorkspace.id
        };
      },
      skip() {
        return this.pathDetail !== 'inbox';
      },
      result(res) {
        if (res.data) {
          this.inboxUrl = res.data.InboxList[0].url;
          this.inboxId = res.data.InboxList[0].id;
          this.$store.commit('setInboxUrl', this.inboxUrl);
          this.$store.commit('setInboxId', this.inboxId);
        }
      },
      error() {}
    }
  },
  data() {
    return {
      subPathName: null,
      artistReady: false,
      projectReady: false,
      albumReady: false,
      isUnknown: false,
      faqUrl: this.$config.url.faq,
      keepProjectDropdownExpand: false,
      inboxUrl: null,
      inboxId: null,
      tracksIds: null
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    mainPath() {
      let main;
      if (this.$route.path.includes(PATH.library)) {
        main = MAIN_PATH.library;
      }
      if (this.$route.path.includes(PATH.projects)) {
        main = MAIN_PATH.projects;
      }
      if (this.$route.path.includes(PATH.shared)) {
        main = MAIN_PATH.shared;
      }
      if (this.$route.path.includes(PATH.inbox)) {
        main = MAIN_PATH.inbox;
      }
      return main;
    },
    mainPathToLower() {
      if (this.mainPath) {
        return this.mainPath.toLowerCase();
      }
      return undefined;
    },
    subPath() {
      const route = this.$route.path;
      if (route.includes(PATH.artist)) {
        return this.$t('artists');
      }
      if (route.includes(PATH.album)) {
        return this.$t('albums');
      }
      return null;
    },
    pathDetail() {
      const route = this.$route.path;
      if (route.includes(`${PATH.artist}/`)) {
        return PATH.artist;
      }
      if (route.includes(`${PATH.album}/`)) {
        return PATH.album;
      }
      if (route.includes(`${PATH.inbox}`)) {
        return PATH.inbox;
      }
      if (
        route.includes(PATH.projects) &&
        (route.includes(PATH.details) ||
          route.includes(PATH.sharings) ||
          route.includes(PATH.activity))
      ) {
        return PATH.projects;
      }
      if (
        route.includes(PATH.projects) &&
        (!route.includes(PATH.details) ||
          !route.includes(PATH.sharings) ||
          !route.includes(PATH.activity))
      ) {
        return PATH.listProjects;
      }
      return null;
    },
    displayedBtn() {
      switch (this.pathDetail) {
        case PATH.artist:
          return [this.artistBtn, this.artistReady];
        case PATH.album:
          return [this.albumBtn, this.albumReady];
        case PATH.listProjects:
          return null;
        case PATH.projects:
          return [this.projectsBtn, this.projectReady];
        case PATH.inbox:
          return [this.inboxBtn, true];
        default:
          return null;
      }
    },
    breadCrumbIcon() {
      switch (this.mainPath) {
        case MAIN_PATH.library:
          return 'ri-music-2-fill';
        case MAIN_PATH.projects:
          return 'ri-folder-fill';
        case MAIN_PATH.shared:
          return 'ri-share-forward-fill';
        case MAIN_PATH.inbox:
          return 'ri-inbox-fill';
        default:
          return 'ri-music-2-line';
      }
    },
    path() {
      if (this.subPathName && this.subPath) {
        return [this.mainPath, this.subPath, this.subPathName];
      }
      if (this.subPath) {
        return [this.mainPath, this.subPath];
      }
      if (this.subPathName) {
        return [this.mainPath, this.subPathName];
      }
      return [this.mainPath];
    },
    artistBtn() {
      return [
        {
          icon: 'ri-folder-transfer-line',
          style: 'secondary',
          label: this.$t('addTo'),
          action: () => {
            this.handleAddToClick('artistId');
          }
        },
        { ...this.shareBtnDropdown('artist', 'shareArtistName') }
      ];
    },
    albumBtn() {
      return [
        {
          icon: 'ri-folder-transfer-line',
          style: 'secondary',
          label: this.$t('addTo'),
          action: () => {
            this.handleAddToClick('albumId');
          }
        },
        {
          label: this.$t('editAlbum'),
          style: 'secondary',
          icon: 'ri-edit-line',
          action: () => {
            bus.$emit('openAlbumEditModal');
          }
        },
        { ...this.shareBtnDropdown('support', 'shareAlbumName') }
      ];
    },
    projectsBtn() {
      return [
        { ...this.shareBtnDropdown('project', 'shareProjectName') },
        {
          iconBtn: true,
          icon: 'ri-more-2-fill',
          items: [
            {
              icon: 'ri-file-copy-line',
              name: this.$t('duplicate'),
              action: () => {
                bus.$emit('handleDuplicateProjectFromHeader');
              }
            },
            {
              icon: 'ri-folder-transfer-line',
              name: this.$t('addTo'),
              action: () => {
                this.handleAddToClick('projectId');
              }
            },
            {
              icon: 'ri-download-line',
              name: this.$t('downloadProject'),
              action: () => {
                downloadProjectAsZip(this);
              }
            },
            { separator: true },
            {
              icon: 'ri-delete-bin-line',
              name: this.$t('delete'),
              danger: true,
              action: () => {
                bus.$emit('openDeleteProjectModal');
              }
            }
          ]
        }
      ];
    },
    inboxBtn() {
      return [
        {
          icon: 'ri-eye-line',
          style: 'secondary',
          label: this.$t('preview'),
          action: () => {
            bus.$emit('previewInbox');
          }
        },
        {
          icon: 'ri-file-copy-line',
          style: 'primary',
          label: 'Copy inbox link',
          action: () => {
            this.copyStr(this.inboxUrl);
            trackEvent('Inbox Link Copy', {
              inboxId: this.inboxId
            });
          }
        }
      ];
    }
  },
  watch: {
    $route(old, next) {
      if (
        (old.name === 'projectDetails' ||
          old.name === 'projectSharings' ||
          old.name === 'projectActivity') &&
        (next.name === 'projectDetails' ||
          next.name === 'projectSharings' ||
          next.name === 'projectActivity')
      ) {
        return;
      }
      if (
        old.path.includes('album') &&
        next.path.includes('album') &&
        !old.path.includes('albums')
      ) {
        return;
      }
      if (old.path.includes('library/artist/') && next.path.includes('library/artist/')) {
        return;
      }
      if (old.path.includes(`${PATH.sharedTracks}`) && next.path.includes(`${PATH.sharedTracks}`)) {
        return;
      }
      if (
        old.path.includes(`${PATH.sharedSubmission}`) &&
        next.path.includes(`${PATH.sharedSubmission}`)
      ) {
        return;
      }
      this.subPathName = null;
    }
  },
  methods: {
    breadcrumbClick(index) {
      if (index === 0) {
        this.$router.push(getRoute(this.mainPathToLower));
      }
      if (index === 1) {
        this.$router.push(getRoute(this.subPath.toLowerCase()));
      }
    },
    handleAddToClick(propsName) {
      if (this.$isWorkspaceLockedVerification(this)) return;
      const fromSharedView = this.$route.path.includes('shared');
      let page_view;
      switch (this.$route.name) {
        case 'albums':
        case 'albumDetails':
          page_view = fromSharedView ? 'shared library album' : 'library album';
          break;
        case 'artists':
        case 'artistAlbums':
        case 'artistTracks':
        default:
          page_view = fromSharedView ? 'shared library artist' : 'library artist';
          break;
      }
      if (this.$route.name !== 'projectDetails') {
        trackEvent('Track AddTo Click', {
          category: 'addto',
          page_view,
          action_bar: false,
          upload_manager: false
        });
      }
      bus.$emit('displayModal', {
        component: MoveToAddTo,
        isVisible: true,
        size: 'small',
        hideHeader: true,
        props: [
          { name: propsName, value: this.$route.params.id },
          { name: 'entityName', value: this.subPathName },
          { name: 'filesIds', value: this.tracksIds || this.selectedTracks },
          { name: 'hasPrevious', value: this.me.workspaces.length > 1 },
          { name: 'fromAppHeader', value: true }
        ]
      });
    },
    createProject() {
      bus.$emit('displayModal', {
        component: CreateProjectModal,
        title: this.$t('newProject'),
        size: 'medium',
        isVisible: true
      });
    },
    openCreateProjectModalRedirect() {
      bus.$emit('displayModal', {
        title: this.$t('newProject'),
        size: 'medium',
        isVisible: true,
        component: CreateProjectModal,
        redirectToProject: true,
        props: [{ name: 'redirectToProject', value: true }]
      });
    },
    openShareModal(entityType, title, defaultTab) {
      bus.$emit('displayModal', {
        component: ShareModal,
        isVisible: true,
        size: 'medium',
        onSubmit: this.closeShareModal,
        title,
        props: [
          { name: 'entityName', value: this.subPathName },
          { name: 'entityIds', value: [this.$route.params.id] },
          { name: 'entityType', value: entityType },
          defaultTab ? { name: 'defaultTab', value: defaultTab } : []
        ].filter(Boolean)
      });
    },
    handleBtnDropdownActions(action, params) {
      if (action === 'openShareModal') {
        this[action](params.type, params.title, params.defaultTab);
      } else {
        this[action](params);
      }
    },
    shareBtnDropdown(type, text) {
      return {
        btnDropdown: true,
        label: this.$t('share'),
        style: 'primary',
        params: {
          style: 'primary',
          primaryException: true,
          label: 'share',
          icon: 'ri-share-forward-fill',
          hideDropdown: true
        },
        options: [
          {
            label: this.$t('getQuickLink'),
            subText: this.$t('getQuickLinkSub'),
            icon: 'ri-flashlight-fill',
            action: 'openShareModal',
            params: {
              type,
              title: this.$t(text, { name: this.subPathName }),
              defaultTab: 0
            }
          },
          {
            separator: true
          },
          {
            label: this.$t('createIndividualLinks'),
            subText: this.$t('createIndividualLinksSub'),
            icon: 'ri-links-fill',
            action: 'openShareModal',
            params: {
              type,
              title: this.$t(text, { name: this.subPathName }),
              defaultTab: 1
            }
          },
          {
            separator: true
          },
          {
            label: this.$t('sendByEmail'),
            subText: this.$t('sendByEmailSub'),
            icon: 'ri-mail-line',
            action: 'openShareModal',
            params: {
              type,
              title: this.$t(text, { name: this.subPathName }),
              defaultTab: 2
            }
          }
        ]
      };
    },
    copyStr(str) {
      const dummy = document.createElement('textarea');
      document.body.appendChild(dummy);
      dummy.value = str;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
      bus.$emit('showAlert', {
        message: { key: 'copiedToClipboard' },
        style: 'success',
        delay: 5000
      });
    },
    handleBusEmit(emit) {
      bus.$emit(emit);
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  background-color: $color_neutral_0;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  &-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 8px 24px 0 40px;
    width: 100%;

    &-main {
      width: 100%;
      position: relative;
      z-index: 7;
      display: flex;
      align-items: center;
      .breadcrumb {
        width: 100%;
      }
      .header-btn {
        width: fit-content;
        flex-shrink: 0;
      }
    }

    &-icons {
      display: flex;
      & > * {
        margin-left: 15px;
      }
    }
  }
  &-help-icon {
    text-decoration: none;
    color: $color_neutral_40;
    font-size: 20px;
    cursor: pointer;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-btn {
    display: flex;
    align-items: center;
    &-- {
      margin-left: 8px;
    }
  }
  &-separator {
    height: 32px;
    width: 1px;
    background-color: $color_neutral_30;
    margin-left: 16px;
  }
}
</style>
