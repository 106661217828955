<template>
  <div class="observer" :style="styles" />
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      required: false,
      default: 40
    },
    bottom: {
      type: Boolean,
      required: false
    }
  },
  data: () => ({
    observer: null
  }),
  computed: {
    styles() {
      let styles = `height: ${this.height}px;`;
      styles += this.bottom ? ' bottom: 0;' : ' top: 0;';
      return styles;
    }
  },
  mounted() {
    const options = {
      threshold: 0
    };
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry && entry.isIntersecting) {
          this.$emit('intersect');
        }
      });
    }, options);
    this.observer.observe(this.$el);
  },
  destroyed() {
    this.observer.disconnect();
  }
};
</script>

<style lang="scss" scoped>
.observer {
  position: absolute;
}
</style>
