<template>
  <div :class="['container', weightNormal && 'weight-normal']">
    <div v-if="artwork && artwork.length > 0">
      <artwork
        :imgSrc="artwork"
        :trackId="rowId"
        :isHoverId="isHoverId"
        :playingTrack="playingTrack"
        @setPlayingTrack="setPlayingTrack"
      />
    </div>
    <div class="text" :class="[{ text: true, 'text-playing': isPlayingTrack }]" :title="text">
      {{ text }}
      <div v-if="subtitle" class="subtitle">{{ subtitle }}</div>
    </div>
    <div class="actions" v-if="actions.length > 0 && (isHover || isExpanded)">
      <div v-for="(action, index) in actions" :key="`${index}-taib-container`">
        <more-dropdown
          v-show="!(hideActionCondition && action.hide)"
          v-if="action.options"
          v-tooltip="action.tooltip"
          :itemId="rowId"
          :items="action.options"
          :icon="action.icon"
          :stopbubble="true"
          @setMoreDropdownState="setMoreDropdownState(index, ...arguments)"
        />
        <icon-button
          v-show="!(hideActionCondition && action.hide)"
          v-else
          class="btn"
          :key="`${index}-taib`"
          btnStyle="tertiary"
          size="small"
          :itemId="rowId"
          :customEvent="action.emit"
          :icon="action.icon"
          v-tooltip="action.tooltip"
          @[action.emit]="handleEmit"
          :stopbubble="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { VTooltip } from 'v-tooltip';
import IconButton from '../iconButton';
import MoreDropdown from '../moreDropdown';

Vue.directive('tooltip', VTooltip);

export default {
  components: {
    IconButton,
    MoreDropdown
  },
  props: {
    text: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false,
      default: null
    },
    actions: {
      type: Array,
      required: false,
      default: () => []
    },
    artwork: {
      type: String,
      required: false,
      default: null
    },
    playingTrack: {
      type: Object,
      required: false,
      default: null
    },
    rowId: {
      type: String,
      required: true
    },
    isHoverId: {
      type: String,
      required: false,
      default: null
    },
    weightNormal: {
      type: Boolean,
      required: false,
      default: false
    },
    hideActionCondition: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      dropdownState: [],
      isExpanded: false
    };
  },
  computed: {
    isHover() {
      return this.isHoverId === this.rowId;
    },
    isPlayingTrack() {
      return this.playingTrack ? this.rowId === this.playingTrack.id : false;
    }
  },
  methods: {
    handleEmit(param, customEvent) {
      this.$emit(`${[customEvent]}`, param);
    },
    setMoreDropdownState(index, { isExpanded }) {
      this.dropdownState[index] = isExpanded;
      this.isExpanded = this.dropdownState.some(el => el === true);
    },
    setPlayingTrack(track) {
      this.$emit('setPlayingTrack', track);
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  @include body-1;
  font-weight: 600;
  width: 100%;
}
.artwork {
  margin-right: 8px;
}
.text {
  white-space: nowrap;
  width: calc(100% - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
  .subtitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: $color_neutral_60;
    font-weight: normal;
  }
  &-playing {
    color: $color_primary_100;
  }
}
.actions {
  display: flex;
  .btn:not(:last-child) {
    margin-right: 8px;
  }
}
.container.weight-normal {
  font-weight: 500;
}
</style>
