
<div class="share-by-link-body">
  <div class="upgrade-banner" v-if="sharesExpiresIn">
    <upgrade-banner
      :content="`Shared links expire after ${sharesExpiresIn} days with the Starter plan`"
      :roleId="myRoleId"
      @closeModal="$emit('closeModal')"
    />
  </div>
  <div
    class="tab-header-container"
    :style="{ paddingBottom: isPublicLinkActivated ? 0 : '24px' }"
    @click="forceToggle"
  >
    <div class="tab-header-container-icon"><i class="ri-flashlight-fill"></i></div>
    <div class="tab-header-container-main">
      <div class="share-by-link-body-title">
        <div>{{ $t('getQuickLink') }}</div>
        <div class="share-by-link-body-desc">
          {{ $t('quickLinkDesc') }}
        </div>
      </div>
      <switch-button
        ref="switchButton"
        @click.native.stop
        :defaultValue="isPublicLinkActivated"
        @toggleSwitchButton="togglePublicLinkActivation"
      />
    </div>
  </div>
  <div v-if="isPublicLinkActivated">
    <div class="share-by-link-body-input">
      <div class="share-by-link-body-input-container">
        <div
          class="share-by-link-body-input-container--"
          v-if="isPublicLinkActivated && basePublicLink"
        >
          <text-field
            class="public-link-url-textfield"
            inputType="text"
            :value="basePublicLink"
            :large="false"
            disabled
          />
        </div>
        <div class="public-link-separator"></div>
        <copy-btn
          class="copy-btn"
          @copyLink="copyLink"
          :hasBorder="false"
          :label="copyBtnLabel"
        />
      </div>
    </div>
    <access-rights
      :accessRights="publicLinkAccessRights"
      :isProject="isProject"
      type="link"
      class="access-rights-link"
      @setAccessRights="setPublicLinkAccessRights"
    />
    <share-security-section
      ref="sharePasswordTogglePublic"
      :displayPasswordInput="displaySharePasswordPublic"
      :securityPassword.sync="sharePasswordPublic"
      :securityPasswordErrorMessage="passwordPublicErrorMessage"
      :selectOnLoad="selectPasswordInputOnLoad"
      @toggleSwitch="toggleSharePasswordPublic"
      @handleBlur="handlePublicBlur"
    />
  </div>
</div>
