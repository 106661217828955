
<div class="filter-dropdown" v-click-outside="{ hide: closeExpandDropdown }">
  <div
    class="filter-dropdown-btn"
    :class="{ 'btn-active': filterActive }"
    @click="toggleExpandDropdown"
  >
    <i class="ri-calendar-2-line"></i>
    <div>{{ filterName }}</div>
  </div>
  <div class="filter-dropdown-expand" v-if="isDropdownExpanded">
    <div class="filter-dropdown-expand-head">
      <text-dropdown
        :options="txtDropdownOptions"
        :selectedLabel="temporality.label"
        @isExpanded="() => (keepExpand = true)"
        @selectOption="setTemporality"
      />
      <submit-button
        btnStyle="tertiary"
        size="small"
        :label="clearBtnLabel"
        @submit="clearDate"
      />
    </div>
    <div :class="{ 'filter-dropdown-expand-datepickers': isBetween }">
      <date-picker
        :local="local"
        :selectedDate="firstDate"
        :disabledDates="firstDisabledDates"
        @datePicked="setFirstDate"
      />
      <date-picker
        v-if="isBetween"
        :local="local"
        :selectedDate="secondDate"
        :disabledDates="secondDisabledDates"
        @datePicked="setSecondDate"
      />
    </div>
    <div class="filter-dropdown-expand-action">
      <submit-button
        class="close-btn"
        btnStyle="tertiary"
        size="medium"
        :label="tertiaryBtnLabel"
        @submit="closeDropdown"
      />
      <submit-button
        btnStyle="primary"
        size="medium"
        :label="primaryBtnLabel"
        @submit="setDateFilter"
      />
    </div>
  </div>
</div>
