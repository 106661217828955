
<div class="offersContainer">
  <icon-button
    class="navigationIcon"
    btnStyle="tertiary"
    icon="ri-arrow-left-line"
    @submit="navigateBack"
  />
  <payment-select
    :plans="getPlans"
    :currentPlan="getCurrentPlanName"
    :selectedPlanIndex.sync="selectedPlanIndex"
    :currentPlanIndex="currentPlanIndex"
    :minimumPlanIndex="minimumPlanIndex"
    :isYearly="isYearly"
    @upgradeSubscription="upgradeSubscription"
    @updateCurrentPlanIndex="updateCurrentPlanIndex"
    @openUpgradePlanModal="openUpgradePlanModal"
    @subscribe="subscribe"
    @selectAnnual="setYearly"
  />
</div>
