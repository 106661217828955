import Vue from 'vue';
import Vuex from 'vuex';

import { uploadMutations } from './upload';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    me: {},
    currentWorkspace: {},
    attachmentUpload: {},
    trackUpload: {},
    isWorkspaceLocked: false,
    displayWorkspaceLockedAlert: false,
    playingTrack: {
      play: false,
      id: undefined,
      isInbox: false
    },
    user: {
      country: null,
      primaryEmail: null,
      currency: null
    },
    ui: {
      isEditPanelOpened: false
    },
    playlist: {},
    searchLibraryQueryParameters: null,
    searchProjectQueryParameters: null,
    uploadIsInprogress: false, // TODO : Delete me
    isDownloadingArchive: false,
    downloadArchiveStatus: undefined,
    isProjectsLoaded: false,
    isArtistsLoaded: false,
    isAuthorsLoaded: false,
    isLabelsLoaded: false,
    isComposersLoaded: false,
    isSupportsLoaded: false,
    isPublishersLoaded: false,
    isDraggingItemList: false,
    isLibraryEmpty: false,
    isEditPanelChanged: false,
    openEditPanelPane: undefined, // TODO : Maybe use `ui.isEditPanelChanged` instead…
    albumSort: {
      orderBy: 'name',
      sortBy: 'asc'
    },
    projectTabsCount: {
      active: 0
    },
    currentDisplayListPaginationStatus: {
      current: null,
      last: null
    },
    selectedPlan: undefined,
    customLinkJustCreated: [],
    inboxId: null, // TODO : Create an `inbox` object instead of 2 keys
    inboxUrl: null, // TODO : Create an `inbox` object instead of 2 keys
    isRenamingProjectAttachment: false,
    isPlayerOnError: false
  },
  getters: {
    isEditPanelOpened: state => state.ui.isEditPanelOpened,
    me: state => state.me,
    currentWorkspace: state => state.currentWorkspace,
    isWorkspaceLocked: state => state.isWorkspaceLocked,
    displayWorkspaceLockedAlert: state => state.displayWorkspaceLockedAlert,
    playingTrack: state => state.playingTrack,
    playlist: state => state.playlist,
    searchLibraryQueryParameters: state => state.searchLibraryQueryParameters,
    searchProjectQueryParameters: state => state.searchProjectQueryParameters,
    // TODO : Delete me
    uploadIsInprogress: state => state.uploadIsInprogress,
    isDownloadingArchive: state => state.isDownloadingArchive,
    downloadArchiveStatus: state => state.downloadArchiveStatus,
    isProjectsLoaded: state => state.isProjectsLoaded,
    isArtistsLoaded: state => state.isArtistsLoaded,
    isAuthorsLoaded: state => state.isAuthorsLoaded,
    isLabelsLoaded: state => state.isLabelsLoaded,
    isComposersLoaded: state => state.isComposersLoaded,
    isSupportsLoaded: state => state.isSupportsLoaded,
    isPublishersLoaded: state => state.isPublishersLoaded,
    isDraggingItemList: state => state.isDraggingItemList,
    isLibraryEmpty: state => state.isLibraryEmpty,
    isEditPanelChanged: state => state.isEditPanelChanged,
    getOpenEditPanelPane: state => state.openEditPanelPane,
    albumSort: state => state.albumSort,
    projectTabsCount: state => state.projectTabsCount,
    currentDisplayListPaginationStatus: state => state.currentDisplayListPaginationStatus,
    customLinkJustCreated: state => state.customLinkJustCreated,
    isRenamingProjectAttachment: state => state.isRenamingProjectAttachment,
    isPlayerOnError: state => state.isPlayerOnError,
    // Subscription
    selectedPlan: state => state.selectedPlan,
    // Users
    userCurrency: state => state.user.currency,
    userPrimaryEmail: state => state.user.primaryEmail,
    userCountry: state => state.user.country,
    inboxUrl: state => state.inboxUrl,
    inboxId: state => state.inboxId,
    // Upload
    attachmentUpload: state => state.attachmentUpload,
    trackUpload: state => state.trackUpload
  },
  mutations: {
    ...uploadMutations,
    setUserPrimaryEmail(s, email) {
      const state = s;
      state.user.primaryEmail = email;
    },
    setUserCountry(s, country) {
      const state = s;
      state.user.country = country;
    },
    setUserCurrency(s, currency) {
      const state = s;
      state.user.currency = currency;
    },
    setEditPanelOpened(s, isOpened) {
      const state = s;
      state.ui.isEditPanelOpened = isOpened;
    },
    setMe(s, me) {
      const state = s;
      state.me = me;
    },
    changeCurrentWorkspace(s, workspace) {
      const state = s;
      state.currentWorkspace = workspace;
    },
    setIsWorkspaceLocked(s, bool) {
      const state = s;
      state.isWorkspaceLocked = bool;
    },
    setDisplayWorkspaceLockedAlert(s, bool) {
      const state = s;
      state.displayWorkspaceLockedAlert = bool;
    },
    changePlayingTrack(s, track) {
      const state = s;
      state.playingTrack = track;
    },
    replacePlaylist(s, playlist) {
      const state = s;
      state.playlist = playlist;
    },
    changePlaylist(s, tracks) {
      const state = s;
      const updatedPlaylist = {};
      tracks
        // filter tracks that are not playable for project the isStreamableAvailable = undefined so we
        // check filter only values that are not false
        .filter(item => item.isStreamableAvailable !== false)
        .forEach(item => {
          const id = item.libraryFileId || item.id;
          updatedPlaylist[id] = {
            id,
            artwork: item.imgSrc,
            largeArtwork: item.largeImgSrc,
            audioSrc: item.audioSrc,
            title: item.title,
            artists: item.artists,
            supports: item.supports,
            duration: item.duration,
            isInbox: !!item.isInbox
          };
        });
      state.playlist = updatedPlaylist;
    },
    changeSearchLibraryQueryParameters(s, p) {
      const state = s;
      state.searchLibraryQueryParameters = p;
    },
    changeSearchProjectQueryParameters(s, p) {
      const state = s;
      state.searchProjectQueryParameters = p;
    },
    setIsDownloadingArchive(s, value) {
      const state = s;
      state.isDownloadingArchive = value;
    },
    setDownloadArchiveStatus(s, value) {
      const state = s;
      state.downloadArchiveStatus = value;
    },
    changeIsProjectsLoaded(s, bool) {
      const state = s;
      state.isProjectsLoaded = bool;
    },
    changeIsArtistsLoaded(s, bool) {
      const state = s;
      state.isArtistsLoaded = bool;
    },
    changeIsAuthorsLoaded(s, bool) {
      const state = s;
      state.isAuthorsLoaded = bool;
    },
    changeIsComposersLoaded(s, bool) {
      const state = s;
      state.isComposersLoaded = bool;
    },
    changeIsSupportsLoaded(s, bool) {
      const state = s;
      state.isSupportsLoaded = bool;
    },
    changeIsPublishersLoaded(s, bool) {
      const state = s;
      state.isPublishersLoaded = bool;
    },
    changeIsLabelsLoaded(s, bool) {
      const state = s;
      state.isLabelsLoaded = bool;
    },
    changeIsDraggingItemList(s, bool) {
      const state = s;
      state.isDraggingItemList = bool;
    },
    changeIsLibraryEmpty(s, isEmpty) {
      const state = s;
      state.isLibraryEmpty = isEmpty;
    },
    changeIsEditPanelChanged(s, bool) {
      const state = s;
      state.isEditPanelChanged = bool;
    },
    changeEditPanelPane(s, selectedPanel) {
      const state = s;
      state.openEditPanelPane = selectedPanel;
    },
    changeAlbumSort(s, obj) {
      const state = s;
      state.albumSort = obj;
    },
    changeProjectTabsCount(s, obj) {
      const state = s;
      state.projectTabsCount = obj;
    },
    changeCurrentDisplayListPaginationStatus(s, obj) {
      const state = s;
      state.currentDisplayListPaginationStatus = obj;
    },
    changeCurrentProjectPage(s, str) {
      const state = s;
      state.currentProjectPage = str;
    },
    changeIsRenamingProjectAttachment(s, bool) {
      const state = s;
      state.isRenamingProjectAttachment = bool;
    },
    changeIsPlayerOnError(s, bool) {
      const state = s;
      state.isPlayerOnError = bool;
    },
    setSelectedPlan(s, obj) {
      const state = s;
      state.selectedPlan = obj;
    },
    setInboxUrl(s, str) {
      const state = s;
      state.inboxUrl = str;
    },
    setInboxId(s, str) {
      const state = s;
      state.inboxId = str;
    },
    setCustomLinkJustCreated(s, obj) {
      const state = s;
      state.customLinkJustCreated = obj;
    },
    // UPLOAD
    setNewAttachmentUpload(s, obj) {
      const state = s;
      const newObject = { ...state.attachmentUpload };
      newObject[obj.id] = obj;
      state.attachmentUpload = newObject;
    },
    setAttachmentUploadProgress(s, obj) {
      const state = s;
      const newObject = { ...state.attachmentUpload };
      newObject[obj.id].progress = obj.progress;
      state.attachmentUpload = newObject;
    },
    cleanAttachmentUpload(s) {
      const state = s;
      state.attachmentUpload = {};
    }
  }
});

export default store;
