
<div>
  <div class="multi-edit-modal-text">{{ $t('saveChangesEdition') }}</div>
  <div class="multi-edit-modal-checkbox">
    <checkbox id="not-show-again" :label="$t('dontShowAgain')" @toggleCheckbox="toggleCheckbox" />
  </div>
  <div class="multi-edit-modal-buttons">
    <div class="multi-edit-modal-buttons--">
      <submit-button
        btnStyle="tertiary"
        size="medium"
        :label="$t('cancel')"
        @submit="closeModal"
      />
    </div>
    <div class="multi-edit-modal-buttons--">
      <submit-button btnStyle="primary" size="medium" :label="$t('save')" @submit="saveChanges" />
    </div>
  </div>
</div>
