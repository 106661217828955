
<div v-if="isVisible" class="modal" :class="{ fixed: isFixed }">
  <div :class="`modal-content ${size}`">
    <div class="modal-content-header" v-if="!hideHeader">
      <div class="titles-container">
        <div class="modal-content-header-title">{{ title }}</div>
        <div class="modal-content-header-secondTitle" v-if="secondTitle">
          {{ secondTitle }}
        </div>
      </div>
      <icon-button btnStyle="tertiary" :size="crossSize" icon="ri-close-fill" @submit="close" />
      <!-- <i @click="close" class="ri-close-line" /> -->
    </div>
    <div class="modal-content-body">
      <slot name="body"></slot>
    </div>
  </div>
  <div @click="close" class="modal-bkg" />
</div>
