<template>
  <div class="dropdown" v-click-outside="{ hide, keepExpand }">
    <div v-if="!btnStyle" class="dropdown-button" @click="handleClickButton">
      <div class="dropdown-button-label">
        <i v-if="iconClass" class="dropdown-button-icon" :class="iconClass" />
        <span>{{ buttonLabel }}</span>
      </div>
      <i v-if="showArrow" class="ri-arrow-drop-down-line" />
    </div>
    <div class="dropdown-button2">
      <submit-button
        v-if="btnStyle"
        :btnStyle="btnStyle"
        size="medium"
        :label="buttonLabel"
        :iconClass="iconClass"
        :isDropdown="isExpandable"
        @submit="handleClickButton"
      />
    </div>
    <div
      v-dropdown-orientation="direction"
      :style="expandStyle"
      class="dropdown-expand"
      @click="hide"
    >
      <div class="dropdown-expand-options" v-if="isExpanded">
        <div
          v-for="(option, index) in options"
          class="dropdown-expand-options-item"
          :class="option.danger && 'dropdown-expand-options-danger'"
          :key="`item-${index}`"
          @click="option.action(itemId)"
        >
          {{ option.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { clickOutside, dropdownOrientation } from '../../utils/directives';
import SubmitButton from '../buttons/submitButton';

export default {
  components: {
    SubmitButton
  },
  props: {
    buttonLabel: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    keepExpand: {
      type: Boolean,
      required: false
    },
    btnStyle: {
      type: String,
      required: false
    },
    iconClass: {
      type: String,
      required: false
    },
    itemId: {
      type: String,
      required: false
    },
    isExpandable: {
      type: Boolean,
      required: false,
      default: true
    },
    showArrow: {
      type: Boolean,
      required: false,
      default: true
    },
    customEvent: {
      type: String,
      required: false,
      default: 'handleClick'
    }
  },
  data() {
    return {
      isExpanded: false,
      direction: {
        top: 'toTop',
        bot: 'toBot',
        left: 'toLeft',
        right: 'toRight'
      }
    };
  },
  computed: {
    height() {
      return this.isExpanded ? 300 : 0;
    },
    opacity() {
      return this.isExpanded ? 1 : 0;
    },
    expandStyle() {
      let style = `max-height: ${this.height}px; opacity: ${this.opacity};`;
      style += this.btnStyle ? ' top: 37px;' : ' top: 30px;';
      return style;
    }
  },
  directives: {
    clickOutside,
    dropdownOrientation
  },
  methods: {
    handleClickButton() {
      if (this.isExpandable) {
        this.toggleExpand();
      } else {
        this.$emit(this.customEvent, this.itemId);
      }
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    hide() {
      this.isExpanded = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown {
  width: fit-content;
  width: -moz-fit-content;
  position: relative;
  @include btn-medium;
  color: $color_neutral_100;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-button {
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 8px 12px 8px 0;

    &-icon {
      font-size: 16px;
      margin: 0 6px 0 0;
    }

    .ri-arrow-drop-down-line {
      font-size: 20px;
    }

    &-label {
      display: flex;
      align-items: center;
    }
  }

  &-expand {
    position: absolute;
    z-index: 100;
    height: auto;
    width: fit-content;
    width: -moz-fit-content;
    margin: 4px 0 0;
    @include shadow-down-03;
    border: 1px solid $color_neutral_30;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: $color_neutral_0;
    overflow: hidden;

    &-options {
      &-item {
        @include body-2;
        padding: 0 16px;
        white-space: nowrap;
        height: 40px;
        display: flex;
        align-items: center;

        &:hover {
          background-color: $color_neutral_10;
        }
      }

      &-danger {
        color: $color_danger_100;
      }
    }
  }
}

.toTop {
  bottom: 36px;
}
.toBot {
  top: 36px;
}
.toLeft {
  right: 0;
}
.toRight {
  left: 0;
}
</style>
