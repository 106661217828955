<template>
  <div class="shared-info">
    <div v-if="emailLink" class="email">
      <i class="ri-mail-line icon"></i>
      {{ emailLink }}
    </div>
    <copy-button
      v-if="publicLink"
      :data="publicLink"
      label="Public link"
      @copied="copyToClipboard"
      click.native.stop
    />
    <div v-if="noLink">-</div>
  </div>
</template>

<script>
import CopyButton from '@/components/buttons/copyButton';

export default {
  components: {
    CopyButton
  },
  props: {
    publicLink: {
      type: String,
      required: false,
      default: undefined
    },
    emailLink: {
      type: Number,
      required: false,
      default: 0
    },
    projectId: {
      type: String,
      required: true,
      default: undefined
    }
  },
  methods: {
    copyToClipboard() {
      this.$emit('copyToClipboard', { projectId: this.projectId });
    }
  },
  computed: {
    noLink() {
      return !this.publicLink && !this.emailLink;
    }
  }
};
</script>

<style lang="scss" scoped>
.shared-info {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  .email {
    cursor: pointer;
    @include body-1;
    display: flex;
    align-items: center;
    margin-right: 16px;
    .icon {
      margin-right: 4px;
      color: $color_neutral_40;
    }
  }
}
</style>

<style lang="scss">
.shared-info {
  .copy-button {
    white-space: nowrap;
    color: $color_neutral_100;
    .ri-links-line {
      color: $color_neutral_40;
    }
  }
}
</style>
