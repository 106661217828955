<template>
  <div class="workspace">
    <div class="workspace-margin-container">
      <router-view :isMobile="isMobile"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: window.innerWidth <= 1024
    };
  },
  created() {
    window.addEventListener('resize', this.setIsMobile);
  },
  destroyed() {
    window.removeEventListener('resize', this.setIsMobile);
  },
  methods: {
    setIsMobile() {
      if (this.isMobile && window.innerWidth > 1024) {
        this.isMobile = false;
      }
      if (!this.isMobile && window.innerWidth <= 1024) {
        this.isMobile = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.workspace {
  height: 100vh;
  overflow-y: scroll;
  background-color: $color_neutral_0;
  display: flex;
  align-items: center;
  justify-content: center;
  &-margin-container {
    margin: auto;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 900px) {
  .workspace {
    height: 100vh;
    padding: 40px 16px;
    box-sizing: border-box;
    justify-content: center;
  }
}
</style>
