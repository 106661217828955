import { apolloClient } from '@/main';

import { GET_ROLES } from '@/graphql/queries/workspace';

import { bus } from '@/utils/bus';

import AddMemberModal from '@/containers/modals/addMembers';
import NoPermissionModal from '@/containers/modals/noPermission';
import MembersLimitModal from '@/containers/modals/membersLimit';
import { getMaximumMembersSeats, getMembersSeats } from '../functions/subscription';

/* eslint-disable import/prefer-default-export */
export const displayAddMembersModal = async (that, roleId) => {
  if (roleId === '2') {
    bus.$emit('displayModal', {
      title: that.$t('addMembers'),
      size: 'medium',
      isVisible: true,
      component: NoPermissionModal
    });
    return;
  }
  if (
    // "-1" means unlimited user can be invited
    that.currentWorkspace &&
    that.currentWorkspace.subscription.seats > -1 &&
    that.currentWorkspace.seats_used >= that.currentWorkspace.subscription.seats
  ) {
    bus.$emit('displayModal', {
      title: that.$t('membersLimit'),
      size: 'medium',
      isVisible: true,
      component: MembersLimitModal
    });
  } else {
    const queryResponse = await apolloClient.query({
      query: GET_ROLES
    });
    const { roles } = queryResponse.data;
    bus.$emit('displayModal', {
      title: that.$t('addMembers'),
      component: AddMemberModal,
      size: 'medium',
      isVisible: true,
      props: [
        { name: 'workspaceId', value: that.currentWorkspace.id },
        {
          name: 'roleListInvit',
          value: roles.map(role => ({
            label: role.name,
            value: role.id,
            description: that.$t(`roles.${role.id}.description`)
          }))
        },
        {
          name: 'availableSeats',
          value:
            getMaximumMembersSeats(that.currentWorkspace) - getMembersSeats(that.currentWorkspace)
        },
        { name: 'submitStatus', value: that.submitStatus }
      ]
    });
  }
};
