
<div v-if="trackList && trackList.length > 0">
  <header-list
    :columns="columns"
    :sortColumn="false"
    :isSticky="isSticky"
    :stickyPosition="stickyPosition"
    :hasSelectAll="false"
    :itemsSelected="[]"
    :displayUnselect="false"
    @handleEmit="handleEmit"
  />
  <div v-for="(track, idx) in trackList" :key="track.id">
    <list-row
      :item="track"
      :columns="columns"
      :rowIndex="idx"
      :height="48"
      :id="track.id"
      :playingTrack="playingTrack || {}"
      :selectedItems="[]"
      :disableSelection="true"
      @handleEmit="handleEmit"
      @setPlayingTrackFromRow="setPlayingTrackFromRow"
    />
  </div>
</div>
