
<div class="member">
  <div class="member-avatar" :class="{ pending: member.status === 'PENDING' }">
    <avatar :initial="member.initial" :pictureUrl="member.pictureUrl" pictureSize="extraSmall" />
  </div>
  <div class="member-infos">
    <div v-if="member.status === 'OK'" class="member-infos-fullname">
      <span>{{ member.fullname }}</span>
    </div>
    <div class="member-infos-email" :class="{ pending: member.status === 'PENDING' }">
      {{ member.email }}
    </div>
  </div>
</div>
