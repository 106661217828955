<template>
  <div class="slider" style="width: 100%">
    <div class="slider-prefix" />
    <div class="slider-wrapper" style="width: 100%">
      <input
        class="slider-wrapper-range"
        ref="input"
        :style="`width: 100%`"
        type="range"
        :min="min"
        :max="max"
        :step="step"
        v-model="sliderValue"
        @input="handleInput"
      />
      <div class="slider-wrapper-foreground" :style="`width: ${sliderWidth}%`" />
      <div class="slider-wrapper-background" :style="getBackgroundStyle" />
    </div>
    <div class="slider-label-min" v-if="labelMin">{{ labelMin }}</div>
    <div class="slider-label-max" v-if="labelMax">{{ labelMax }}</div>
  </div>
</template>

<script>
export default {
  props: {
    labelMin: {
      type: String,
      required: false,
      default: ''
    },
    showIndicator: {
      type: Boolean,
      required: false,
      default: false
    },
    min: {
      type: Number,
      required: false,
      default: 0
    },
    labelMax: {
      type: String,
      required: false,
      default: ''
    },
    max: {
      type: Number,
      required: false,
      default: 100
    },
    step: {
      type: Number,
      required: false,
      default: 1
    },
    defaultValue: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      sliderValue: this.defaultValue,
      width: 0
    };
  },
  mounted() {
    this.width = this.$refs.input.clientWidth;
  },
  computed: {
    getBackgroundStyle() {
      const stepCount = this.max - this.min;
      const indicatorSize = (this.width - 24) / stepCount;
      const indicators = `background: 10px 0 repeating-linear-gradient(90deg, #a766ff, #a766ff 1px, #D3B3FF 4px, #a766ff 1px, #a766ff ${indicatorSize}px)`;
      return `width: 100%; ${this.showIndicator ? indicators : 'background: #a766ff'}`;
    },
    sliderWidth() {
      const step = 100 / (this.max - this.min);
      return this.sliderValue * step;
    }
  },
  methods: {
    handleInput(e) {
      this.$emit('sliderValueChange', e.target.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.slider {
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  &-label-min,
  &-label-max {
    position: absolute;
    top: 36px;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: $color_neutral_0;
    max-width: 70px;
  }

  &-label-min {
    left: 0;
  }

  &-label-max {
    right: 0;
    text-align: right;
  }

  &-prefix {
    width: 10px;
    height: 4px;
  }

  &-prefix {
    background: $color_neutral_0;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  &-wrapper {
    position: relative;
    height: 36px;
    display: flex;
    align-items: center;

    &-range {
      margin: 0;
      z-index: 3;
      height: 32px;
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      cursor: pointer;
      &:focus {
        outline: none;
      }
      &::-webkit-slider-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        background: url('./thumb.svg') no-repeat;
        background-size: 24px;
        border-radius: 24px;
        border: 1px solid $color_neutral_40;
        height: 26px; /* 26 is for 24 height plus 2px of border */
        width: 26px;
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.3));
      }
      &::-moz-range-thumb {
        /* @include input-range-thumb; */
        -moz-appearance: none; /* Override default look */
        appearance: none;
        background: url('./thumb.svg') no-repeat;
        background-size: 24px;
        border-radius: 24px;
        border: 1px solid $color_neutral_40;
        height: 26px; /* 26 is for 24 height plus 2px of border */
        width: 26px;
        border: none;
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.3));
      }
      &::-moz-focus-outer {
        border: 0;
      }
    }

    &-foreground {
      height: 4px;
      background: $color_neutral_0;
      position: absolute;
      z-index: 2;
      will-change: width;
      max-width: 100%;
    }

    &-background {
      height: 4px;
      position: absolute;
      background-position: 0px 0px;
      z-index: 1;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
}
</style>
