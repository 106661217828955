import get from 'lodash.get';

import { appConfig } from '@/utils/constants';
import { getFileSize } from '@/utils/functions/audio';

export const getCurrencySymbol = value => {
  if (!value) return '€';
  return value.toLowerCase() === appConfig.currencies.DOLLAR ? '$' : '€';
};

export const getPlanVariantPrice = (variant, userCurrency, isYearly = true) => {
  const price = get(variant, 'prices', []).find(item => item.currency === userCurrency);
  if (!price) {
    throw new Error('No price found');
  }
  return (isYearly ? price.yearly_price / 12 : price.monthly_price) / 100;
};

export const getPlanVariantFeature = (variant, featureKey) => {
  const feats = get(variant, 'features', []);
  const features = get(
    feats.find(item => item.type === featureKey),
    'configuration[0].options'
  );
  return features;
};

export const getPlanVariantFeatureFormated = (variant, featureKey) => {
  const features = getPlanVariantFeature(variant, featureKey);
  if (!features) {
    throw new Error('Feature not found');
  }
  if (featureKey === 'storage') {
    return getFileSize(features);
  }
  if (featureKey === 'libraryFilesMax') {
    const userLanguage = window.navigator.language || 'en';
    return new Intl.NumberFormat(userLanguage).format(features);
  }
  return features;
};

export const getPlanVariantsSortedByPrice = plan => {
  return get(plan, 'variants', []).sort(
    (a, b) => a.prices[0].monthly_price - b.prices[0].monthly_price
  );
};
