<template>
  <div class="search">
    <div class="search-top">
      <div class="search-top-bar">
        <search-bar
          key="search-bar-library"
          v-if="searchContext === context.library"
          icon="ri-search-line"
          :fieldModel.sync="phraseLibrary"
          :placeholder="$t('searchPlaceholder')"
        />
        <search-bar
          key="search-bar-project"
          v-else
          icon="ri-search-line"
          :fieldModel.sync="phraseProject"
          :placeholder="$t('searchPlaceholder')"
        />
      </div>
      <submit-button
        v-if="!hideFilter"
        btnStyle="tertiary"
        size="medium"
        :label="$t('trackFilters')"
        iconClass="ri-filter-3-line"
        isDropdown
        exception
        :isExpanded="isSearchExpanded"
        @submit="toggleExpandFilters"
      />
    </div>
    <div class="search-expand" v-if="isSearchExpanded && !hideFilter">
      <div class="search-expand-dropdown" v-if="TagList">
        <div
          class="search-expand-dropdown--"
          v-for="category in TagList.categories"
          :key="category.id"
        >
          <tag-category-dropdown
            :tagCategory="category"
            :checkedTags="checkedTags"
            :noneCategories="noneCategoryIds"
            withNoneOption
            @selectTags="selectTags"
            @selectNone="selectNone"
          />
        </div>
        <div
          v-if="currentWorkspace.hubs && currentWorkspace.hubs.length > 0"
          class="search-expand-dropdown-filter"
        >
          <filter-dropdown
            v-if="hubsFilterList.length > 1"
            filterName="Shared on Hubs"
            :filterList="hubsFilterList"
            :activeFilter="activeHubsFilter"
            @toggleFilter="selectHubs"
          />
        </div>
      </div>
      <div class="search-expand-loading" v-else>
        <spinner-without-progress color="grey" :size="32" />
      </div>
    </div>
    <div class="search-active" v-if="displayActiveTag" :style="margin">
      <div class="search-active-header">
        <div class="search-active-header-title">
          {{
            $tc(
              'activeFilter',
              activeTags.length +
                noneCategoryIds.length +
                activeHubs.length +
                (noneHubs ? 1 : 0) +
                (displayNonCompliant ? 1 : 0)
            )
          }}
        </div>
        <div class="search-active-header-clear">
          <submit-button
            btnStyle="tertiary"
            size="small"
            exception
            :label="$t('clearFilters')"
            @submit="clearFilters"
          />
        </div>
      </div>
      <div class="search-active-tag" v-if="isActiveTagsExpanded && TagList">
        <div
          class="search-active-tag--"
          v-for="tag in activeTags"
          :key="`${tag.categoryName}-${tag.name}`"
        >
          <span :style="`color: ${tag.color}; font-weight:600`">{{ `${tag.categoryName}:` }}</span>
          <span>&nbsp;</span>
          <span>{{ `${tag.name}` }}</span>
          <i class="ri-close-circle-fill" @click="removeActiveTag(tag)" />
        </div>
        <div
          class="search-active-tag--"
          v-for="category in noneCategories"
          :key="`none-${category.id}`"
        >
          <span :style="`color: ${category.background_color}`">{{ `${category.name}:` }}</span>
          <span>&nbsp;</span>
          <span>None</span>
          <i class="ri-close-circle-fill" @click="removeNoneCategory(category.id)" />
        </div>
        <div class="search-active-tag--" v-for="hub in activeHubs" :key="`${hub}-hub`">
          <span :style="`font-weight:600`">Shared on hubs :</span>
          <span>&nbsp;</span>
          <span>{{ getHubName(hub) }}</span>
          <i class="ri-close-circle-fill" @click="removeActiveHub(hub)" />
        </div>
        <div class="search-active-tag--" v-if="noneHubs">
          <span :style="`font-weight:600`">Shared on hubs :</span>
          <span>&nbsp;</span>
          <span>None</span>
          <i class="ri-close-circle-fill" @click="removeNoneHub()" />
        </div>
        <div class="search-active-tag--" v-if="displayNonCompliant">
          <span :style="`font-weight:600`">Shared on hubs :</span>
          <span>&nbsp;</span>
          <span>Non-compliant tracks</span>
          <i class="ri-close-circle-fill" @click="removeDisplayNonCompliant()" />
        </div>
      </div>
      <div class="search-active-button" v-if="isSearchExpanded">
        <div class="search-active-button-content" @click="toggleActiveTagsExpanded">
          <i :class="isActiveTagsExpanded ? 'ri-arrow-drop-up-line' : 'ri-arrow-drop-down-line'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash.get';
import { mapGetters } from 'vuex';
import { TAG_LIST } from '@/graphql/queries/tags';
import { SEARCH_CONTEXT } from '@/utils/constants';
import { bus } from '@/utils/bus';

export default {
  props: {
    searchContext: {
      type: String,
      required: false,
      default: SEARCH_CONTEXT.LIBRARY_SEARCH_CONTEXT
    },
    margin: {
      type: String,
      required: false,
      default: ''
    },
    hideFilter: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  apollo: {
    TagList: {
      query: TAG_LIST,
      variables() {
        return {
          contextName: 'library',
          contextId: this.currentWorkspace.libraries[0].id
        };
      },
      skip() {
        return !this.isSearchExpanded;
      }
    }
  },
  data() {
    return {
      isSearchExpanded: false,
      isActiveTagsExpanded: false,
      activeTags: [],
      noneCategoryIds: [],
      noneHubs: false,
      displayNonCompliant: false,
      activeHubs: [],
      allHubsIds: [],
      phraseLibrary: '',
      phraseProject: '',
      contextualizedPhrase: '',
      searchQuery: null,
      searchResult: null,
      filters: null,
      context: {
        library: SEARCH_CONTEXT.LIBRARY_SEARCH_CONTEXT,
        project: SEARCH_CONTEXT.PROJECT_SEARCH_CONTEXT
      }
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    checkedTags() {
      return this.activeTags.map(tag => tag.id);
    },
    noneCategories() {
      const arr = [];
      if (!this.TagList) return arr;
      this.noneCategoryIds.forEach(categoryId => {
        arr.push(this.TagList.categories.find(category => category.id === categoryId));
      });
      return arr;
    },
    displayActiveTag() {
      const bool =
        (this.activeTags.length > 0 ||
          this.noneCategoryIds.length > 0 ||
          this.activeHubs.length > 0 ||
          this.noneHubs ||
          this.displayNonCompliant) &&
        !this.hideFilter;
      this.$emit('setIsActiveTag', bool);
      return bool;
    },
    hubsFilterList() {
      const filterList = [
        { name: 'None', key: 'none' },
        { name: 'Non-compliant tracks', key: 'hub_compliant' }
      ];
      this.currentWorkspace.hubs.forEach(hub => {
        filterList.push({ name: hub.name, key: hub.id });
      });
      return filterList;
    },
    activeHubsFilter() {
      return [
        ...this.activeHubs,
        this.noneHubs ? 'none' : null,
        this.displayNonCompliant ? 'hub_compliant' : null
      ].filter(Boolean);
    }
  },
  created() {
    this.allHubsIds = this.currentWorkspace.hubs.map(hub => hub.id);
    this.getSearchFromLocalStorage();
    const storedItem = JSON.parse(localStorage.getItem(this.searchContext));
    if (storedItem) {
      this.activeTags = get(storedItem[this.currentWorkspace.id], 'tags.activeTags', []);
      this.noneCategoryIds = get(storedItem[this.currentWorkspace.id], 'tags.noneCategoryIds', []);
      this.activeHubs = get(storedItem[this.currentWorkspace.id], 'tags.activeHubs', []);
      this.noneHubs = get(storedItem[this.currentWorkspace.id], 'tags.noneHubs', false);
      this.displayNonCompliant = get(
        storedItem[this.currentWorkspace.id],
        'tags.displayNonCompliant',
        false
      );
    }
    this.isSearchExpanded =
      storedItem &&
      storedItem[this.currentWorkspace.id] &&
      storedItem[this.currentWorkspace.id].isSearchExpanded;
    this.isActiveTagsExpanded =
      storedItem &&
      storedItem[this.currentWorkspace.id] &&
      storedItem[this.currentWorkspace.id].isActiveTagsExpanded;
    this.displaySearchResult();
  },
  mounted() {
    bus.$on('activeDisplayNonCompliant', () => {
      this.activeDisplayNonCompliant();
    });
  },
  beforeDestroy() {
    bus.$off('activeDisplayNonCompliant');
  },
  watch: {
    phraseLibrary() {
      if (this.searchContext === SEARCH_CONTEXT.LIBRARY_SEARCH_CONTEXT) {
        this.contextualizedPhrase = this.phraseLibrary;
        this.displaySearchResult();
      }
    },
    phraseProject() {
      if (this.searchContext === SEARCH_CONTEXT.PROJECT_SEARCH_CONTEXT) {
        this.contextualizedPhrase = this.phraseProject;
        this.displaySearchResult();
      }
    }
  },
  methods: {
    getSearchFromLocalStorage() {
      const isLibrary = this.searchContext === SEARCH_CONTEXT.LIBRARY_SEARCH_CONTEXT;
      const storedParameters = isLibrary
        ? localStorage.getItem('SEARCH_LIBRARY_QUERY_PARAMETERS')
        : localStorage.getItem('SEARCH_PROJECT_QUERY_PARAMETERS');
      if (storedParameters !== 'null') {
        if (isLibrary) {
          this.phraseLibrary = get(JSON.parse(storedParameters), 'query.phrase', '');
        } else {
          this.phraseProject = get(JSON.parse(storedParameters), 'query.phrase', '');
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (isLibrary) {
          this.phraseLibrary = '';
        } else {
          this.phraseProject = '';
        }
      }
    },
    toggleExpandFilters() {
      this.isSearchExpanded = !this.isSearchExpanded;
      this.isActiveTagsExpanded = this.isSearchExpanded;
      this.setActiveFiltersInLocalStorage();
    },
    selectTags(checkedTags) {
      this.activeTags = [];
      this.TagList.categories.forEach(category => {
        category.families.forEach(family => {
          family.tags.forEach(tag => {
            if (checkedTags.includes(`${category.id}-${family.id}-${tag.id}`))
              this.activeTags.push({
                tagId: tag.id,
                categoryName: category.name,
                color: category.background_color,
                id: `${category.id}-${family.id}-${tag.id}`,
                name: tag.name
              });
          });
        });
      });
      this.isActiveTagsExpanded = true;
      this.displaySearchResult();
      this.setActiveFiltersInLocalStorage();
    },
    selectNone(categoryId) {
      if (!this.noneCategoryIds.includes(categoryId)) {
        this.noneCategoryIds.push(categoryId);
      } else {
        const index = this.noneCategoryIds.findIndex(id => id === categoryId);
        this.noneCategoryIds.splice(index, 1);
      }
      this.displaySearchResult();
      this.setActiveFiltersInLocalStorage();
    },
    selectHubs(bool, hubId) {
      switch (hubId) {
        case 'hub_compliant':
          this.displayNonCompliant = bool;
          break;
        case 'none':
          this.noneHubs = bool;
          break;
        default:
          if (bool) {
            this.activeHubs.push(hubId);
          } else {
            this.activeHubs = this.activeHubs.filter(el => el !== hubId);
          }
          break;
      }
      this.displaySearchResult();
      this.setActiveFiltersInLocalStorage();
    },
    activeDisplayNonCompliant() {
      this.displayNonCompliant = true;
      this.displaySearchResult();
      this.setActiveFiltersInLocalStorage();
    },
    removeActiveTag(activeTag) {
      this.activeTags.splice(
        this.activeTags.findIndex(tag => tag.id === activeTag.id),
        1
      );
      this.displaySearchResult();
      this.setActiveFiltersInLocalStorage();
    },
    removeActiveHub(hubId) {
      this.activeHubs.splice(
        this.activeHubs.findIndex(h => h.id === hubId),
        1
      );
      this.displaySearchResult();
      this.setActiveFiltersInLocalStorage();
    },
    removeDisplayNonCompliant() {
      this.displayNonCompliant = false;
      this.displaySearchResult();
      this.setActiveFiltersInLocalStorage();
    },
    removeNoneHub() {
      this.noneHubs = false;
      this.displaySearchResult();
      this.setActiveFiltersInLocalStorage();
    },
    removeNoneCategory(categoryId) {
      const index = this.noneCategoryIds.findIndex(id => id === categoryId);
      this.noneCategoryIds.splice(index, 1);
      this.displaySearchResult();
      this.setActiveFiltersInLocalStorage();
    },
    toggleActiveTagsExpanded() {
      this.isActiveTagsExpanded = !this.isActiveTagsExpanded;
      this.setActiveFiltersInLocalStorage();
    },
    clearFilters() {
      this.activeTags = [];
      this.noneCategoryIds = [];
      this.activeHubs = [];
      this.noneHubs = false;
      this.displayNonCompliant = false;
      this.displaySearchResult();
      this.setActiveFiltersInLocalStorage();
    },
    setActiveFiltersInLocalStorage() {
      const { activeTags, noneCategoryIds, activeHubs, noneHubs, displayNonCompliant } = this;
      const storedItem = JSON.parse(localStorage.getItem(this.searchContext));
      const obj = { ...storedItem };
      obj[this.currentWorkspace.id] = {
        tags: {
          activeTags,
          noneCategoryIds,
          activeHubs,
          noneHubs,
          displayNonCompliant
        },
        isSearchExpanded: this.isSearchExpanded,
        isActiveTagsExpanded: this.isActiveTagsExpanded
      };
      localStorage.setItem(this.searchContext, JSON.stringify(obj));
    },
    changeQueryParameters(store, currentWorkspace, phrase, filters) {
      this.$store.commit(store, {
        query: {
          workspaceId: currentWorkspace.id,
          phrase
        },
        filters
      });
    },
    getHubName(hubId) {
      return this.currentWorkspace.hubs.find(hub => hub.id === hubId).name;
    },
    displaySearchResult() {
      if (
        this.activeTags.length === 0 &&
        this.noneCategoryIds.length === 0 &&
        this.noneHubs === false &&
        this.displayNonCompliant === false &&
        this.activeHubs.length === 0
      ) {
        this.filters = null;
      } else {
        this.filters = {};
        if (this.activeTags.length > 0) {
          this.filters.tags = { in: this.activeTags.map(tag => tag.tagId) };
        }
        if (this.noneCategoryIds.length > 0) {
          this.filters.categories = { notIn: this.noneCategoryIds };
        }
        if (this.activeHubs.length > 0) {
          this.filters.hubs = { in: this.activeHubs };
        }
        if (this.noneHubs) {
          this.filters.hubs = { ...this.filters.hubs, notIn: this.allHubsIds };
        }
        if (this.displayNonCompliant) {
          this.filters.hub_compliant = { eq: false };
        }
      }
      if (
        this.contextualizedPhrase === '' &&
        this.activeTags.length === 0 &&
        this.noneCategoryIds.length === 0 &&
        this.noneHubs === false &&
        this.displayNonCompliant === false &&
        this.activeHubs.length === 0
      ) {
        switch (this.searchContext) {
          case SEARCH_CONTEXT.PROJECT_SEARCH_CONTEXT:
            this.$store.commit('changeSearchProjectQueryParameters', null);
            break;
          case SEARCH_CONTEXT.LIBRARY_SEARCH_CONTEXT:
          default:
            this.$store.commit('changeSearchLibraryQueryParameters', null);
            break;
        }
      } else {
        const { currentWorkspace, contextualizedPhrase, filters } = this;
        switch (this.searchContext) {
          case SEARCH_CONTEXT.PROJECT_SEARCH_CONTEXT:
            this.changeQueryParameters(
              'changeSearchProjectQueryParameters',
              currentWorkspace,
              contextualizedPhrase,
              filters
            );
            break;
          case SEARCH_CONTEXT.LIBRARY_SEARCH_CONTEXT:
          default:
            this.changeQueryParameters(
              'changeSearchLibraryQueryParameters',
              currentWorkspace,
              contextualizedPhrase,
              filters
            );
            break;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.search {
  @include body-1;
  width: 100%;

  &-top {
    display: flex;

    &-bar {
      width: 260px;
      margin: 0 24px 0 0;
    }
  }

  &-expand {
    margin-top: 16px;
    &-dropdown {
      position: relative;
      z-index: 1;
      display: flex;
      flex-wrap: wrap;
      &-- {
        margin: 8px 8px 0 0;

        &:last-child {
          margin: 8px 0 0 0;
        }
      }
      &-filter {
        margin-top: 8px;
      }
    }

    &-loading {
      display: flex;
      justify-content: center;
    }
  }

  &-active {
    position: relative;
    z-index: 0;
    width: 100%;
    @include body-2;
    background-color: $color_neutral_10;

    &-header {
      display: flex;
      align-items: center;

      &-title {
        color: $color_neutral_60;
        margin-right: 8px;
      }
    }

    &-tag {
      display: flex;
      flex-wrap: wrap;
      padding: 0 0 8px 0;
      max-height: 160px;
      overflow-y: auto;

      &-- {
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 32px;
        padding: 0 16px;
        border: 1px solid $color_neutral_40;
        border-radius: 4px;
        box-sizing: border-box;
        width: fit-content;
        color: $color_neutral_100;
        margin: 8px 8px 0 0;

        &:last-child {
          margin: 8px 0 0 0;
        }
      }

      .ri-close-circle-fill {
        margin: 2px 0 0 4px;
        color: $color_neutral_40;
        font-size: 16px;
      }
    }

    &-button {
      position: relative;
      display: flex;
      justify-content: center;

      &-content {
        position: absolute;
        top: -4px;
        width: 24px;
        height: 24px;
        background-color: $color_primary_100;
        cursor: pointer;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .ri-arrow-drop-down-line,
        .ri-arrow-drop-up-line {
          color: $color_neutral_0;
          font-size: 18px;
        }
      }
    }
  }
}
</style>
