<template>
  <div v-if="formatedShares.length > 0">
    <header-list
      :columns="columns"
      actionUnavailable
      :sortColumn="false"
      :itemsSelected="[]"
      :isSticky="true"
      :stickyPosition="0"
    />
    <div v-for="(share, idx) in formatedShares" :key="share.id">
      <list-row
        class="row-container"
        :item="share"
        :columns="columns"
        :rowIndex="idx"
        :height="64"
        :id="share.id"
        disableSelection
        @click.native="handleRowClick($event, share.id, share.type)"
        @handleEmit="handleEmit"
      />
    </div>
    <skeleton-row v-if="loading" :columns="columns" :height="64" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash.get';
import * as timeago from 'timeago.js';

import { bus } from '@/utils/bus';
import { newFormatDate, moreThanAYear } from '@/utils/functions/time';
import ShareModal from '@/containers/modals/share';

export default {
  props: {
    shares: {
      type: Array,
      required: false,
      default: () => []
    },
    membersList: {
      type: Array,
      required: false,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isHoverId: null,
      listActions: [
        {
          icon: 'ri-share-forward-fill',
          tooltip: this.$t('share'),
          hide: true,
          options: [
            {
              icon: 'ri-flashlight-fill',
              name: this.$t('getQuickLink'),
              subText: this.$t('getQuickLinkSub'),
              keepExpand: false,
              action: trackId => {
                this.openShareModale({ itemId: trackId }, 0);
              }
            },
            {
              separator: true
            },
            {
              icon: 'ri-links-fill',
              name: this.$t('createIndividualLinks'),
              subText: this.$t('createIndividualLinksSub'),
              keepExpand: false,
              action: trackId => {
                this.openShareModale({ itemId: trackId }, 1);
              }
            },
            {
              separator: true
            },
            {
              icon: 'ri-mail-line',
              name: this.$t('sendByEmail'),
              subText: this.$t('sendByEmailSub'),
              keepExpand: false,
              action: trackId => {
                this.openShareModale({ itemId: trackId }, 2);
              }
            }
          ]
        },
        {
          icon: 'ri-user-settings-fill',
          hide: true,
          tooltip: this.$t('manageSharingLinks'),
          emit: 'goToShareSettings'
        },
        { icon: 'ri-pulse-fill', tooltip: this.$t('seeSharingActivity'), emit: 'goToActivities' }
      ]
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    formatedShares() {
      return this.shares.map(share => ({
        id: share.shares_ids[0],
        url: get(share, 'thumbnails[1].url', undefined),
        placeHolders: {
          artist: '/images/default_artist.jpg',
          support: '/images/artwork.jpg'
        },
        type: share.type,
        multiple: share.shared_entities.length > 1,
        name: share.name,
        formatedType: this.formatTypeIcon(share.type, share.shared_entities.length > 1),
        icon: this.formatTypeIcon(share.type, share.shared_entities.length > 1, true),
        lastSharedBy: {
          url: this.getLastShareInfo(share, 'url'),
          name: this.getLastShareInfo(share, 'name'),
          initial: this.getLastShareInfo(share, 'initial')
        },
        lastSharedAt: {
          relative: timeago.format(share.last_shared_at * 1000),
          date: newFormatDate(share.last_shared_at * 1000),
          displayDate: moreThanAYear(share.last_shared_at * 1000)
        },
        count: share.shared_count.toString(),
        countIcon: 'ri-group-fill',
        hideAction: share.type === 'submission',
        sendTo:
          share.type === 'submission' && share.subtitle_variables
            ? `Sent to ${share.subtitle_variables.find(el => el.name === 'workspace_name').value}`
            : null
      }));
    },
    columns() {
      return [
        {
          header: null,
          noSeparator: true,
          skeleton: 'square',
          min: '40px',
          max: '40px',
          span: 1,
          type: 'component',
          name: 'thumbnail',
          containerWidth: true,
          noStop: true,
          padding: [0, 0, 0, 4],
          props: [
            {
              name: 'url',
              match: 'url',
              value: null
            },
            {
              name: 'type',
              match: 'type',
              value: null
            },
            {
              name: 'placeHolders',
              match: 'placeHolders',
              value: null
            },
            {
              name: 'multiple',
              match: 'multiple',
              value: null
            }
          ]
        },
        {
          type: 'component',
          name: 'text-action',
          header: this.$t('name'),
          skeleton: 'line',
          min: '200px',
          max: '1fr',
          padding: [0, 8, 0, 8],
          noStop: true,
          containerWidth: true,
          emits: ['openShareModale', 'goToShareSettings', 'goToActivities'],
          props: [
            {
              name: 'rowId',
              match: 'id',
              value: null
            },
            {
              name: 'isHoverId',
              match: 'isHoverId',
              value: this.isHoverId
            },
            {
              name: 'text',
              match: 'name',
              value: null
            },
            {
              name: 'subtitle',
              match: 'sendTo',
              value: null
            },
            {
              name: 'actions',
              match: 'actions',
              value: this.listActions
            },
            {
              name: 'hideActionCondition',
              match: 'hideAction',
              value: null
            }
          ]
        },
        {
          type: 'component',
          name: 'icon-text',
          header: this.$t('type'),
          skeleton: 'line',
          min: '160px',
          max: '160px',
          padding: [0, 8, 0, 8],
          noStop: true,
          props: [
            {
              name: 'icon',
              match: 'icon',
              value: null
            },
            {
              name: 'text',
              match: 'formatedType',
              value: null
            }
          ]
        },
        {
          type: 'component',
          name: 'icon-text',
          header: this.$t('shared'),
          skeleton: 'line',
          min: '100px',
          max: '100px',
          padding: [0, 8, 0, 8],
          noStop: true,
          props: [
            {
              name: 'icon',
              match: 'countIcon',
              value: null
            },
            {
              name: 'text',
              match: 'count',
              value: null
            }
          ]
        },
        {
          type: 'component',
          name: 'info-date-user',
          header: this.$t('lastShared'),
          skeleton: 'line',
          min: '105px',
          max: '206px',
          noStop: true,
          containerWidth: true,
          padding: [0, 8, 0, 8],
          props: [
            {
              name: 'date',
              match: 'lastSharedAt',
              value: null
            },
            {
              name: 'user',
              match: 'lastSharedBy',
              value: null
            }
          ]
        }
      ];
    }
  },
  methods: {
    formatTypeIcon(entityType, multiple, waitIcon) {
      let icon;
      let type;
      if (entityType === 'track') {
        if (multiple) {
          icon = 'ri-music-2-fill';
          type = 'Tracks';
        } else {
          icon = 'ri-music-fill';
          type = 'Track';
        }
        if (waitIcon) {
          return icon;
        }
        return type;
      }
      switch (entityType) {
        case 'support':
          icon = 'ri-album-fill';
          type = 'Album';
          break;
        case 'artist':
          icon = 'ri-user-fill';
          type = 'Artist';
          break;
        case 'project':
          icon = 'ri-folder-fill';
          type = 'Project';
          break;
        case 'submission':
          icon = 'ri-send-plane-fill';
          type = 'Submission';
          break;

        default:
          break;
      }
      if (waitIcon) {
        return icon;
      }
      return type;
    },
    getLastShareInfo(share, key) {
      const user = share.last_shared_by
        ? this.membersList.find(member => member.user_id === share.last_shared_by)
        : false;
      if (user) {
        if (key === 'url') {
          return user.profile_picture ? `${user.profile_picture.url}` : '';
        }
        if (key === 'name') {
          return `${user.firstname} ${user.lastname}`;
        }
        if (key === 'initial') {
          if (user.firstname && user.lastname) {
            return `${user.firstname.substring(0, 1).toUpperCase()}${user.lastname
              .substring(0, 1)
              .toUpperCase()}`;
          }
        }
      }
      return '';
    },
    handleRowClick(e, id, type) {
      // each column with type component ( except those with noStop ) do not redirect
      // on click, they have a 'stopbubble' atribute on them, and we verify here on
      // the click event if the path contain it
      const path = e.path || (e.composedPath && e.composedPath());
      const stopBubble = path.some(
        element => typeof element.getAttribute === 'function' && element.getAttribute('stopbubble')
      );
      if (stopBubble) {
        return;
      }
      // we're using share id for track(s) url and entity id for project/album/artist
      if (type === 'track' || type === 'submission') {
        this.$emit('goToPage', id, type);
        return;
      }
      const share = this.findShareFromId(id);
      this.$emit('goToPage', share.shared_entities[0], type);
    },
    handleEmit(params) {
      if (this[params.methodName]) this[params.methodName](params.param);
    },
    openShareModale({ itemId }, defaultTab) {
      const { shared_entities, name, type } = this.findShareFromId(itemId);
      let modalTitle;
      switch (type) {
        case 'project':
          modalTitle = 'shareProjectName';
          break;
        case 'support':
          modalTitle = 'shareAlbumName';
          break;
        case 'artist':
          modalTitle = 'shareArtistName';
          break;
        case 'track':
          modalTitle = shared_entities.length > 1 ? 'shareTracksNameWithoutMore' : 'shareTrackName';
          break;
        default:
          break;
      }
      bus.$emit('displayModal', {
        component: ShareModal,
        title: this.$t(modalTitle, { name }),
        size: 'medium',
        isVisible: true,
        onSubmit: this.closeShareModal,
        props: [
          { name: 'entityName', value: name },
          { name: 'entityIds', value: shared_entities },
          { name: 'entityType', value: type },
          defaultTab ? { name: 'defaultTab', value: defaultTab } : []
        ].filter(Boolean)
      });
    },
    goToShareSettings({ itemId }) {
      const share = this.findShareFromId(itemId);
      if (share.type === 'track') {
        this.$emit('goToShareSettings', itemId, share.type);
        return;
      }
      this.$emit('goToShareSettings', share.shared_entities[0], share.type);
    },
    goToActivities({ itemId }) {
      const share = this.findShareFromId(itemId);
      if (share.type === 'track' || share.type === 'submission') {
        this.$emit('goToActivities', itemId, share.type);
        return;
      }
      this.$emit('goToActivities', share.shared_entities[0], share.type);
    },
    setIsHoverId(itemId) {
      this.isHoverId = itemId;
    },
    findShareFromId(id) {
      return this.shares.find(share => share.shares_ids.find(uid => uid === id));
    }
  }
};
</script>

<style lang="scss" scoped>
.row-container:hover {
  background-color: $color_neutral_10;
}
</style>
