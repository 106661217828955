<template>
  <div class="upload-manager">
    <div class="upload-manager-header">
      <div class="upload-manager-header-section">
        <div
          v-if="!isUploadDone"
          class="upload-manager-header-section-percent"
          :class="
            isUploadDone
              ? 'upload-manager-header-section-completed'
              : 'upload-manager-header-section-in-progress'
          "
        >
          {{ `${progress}%` }}
        </div>
        <div v-if="isUploadDone && allUploadSuccessful" class="upload-manager-header-section-check">
          <i class="ri-check-fill" />
        </div>
        <div
          v-if="isUploadDone && !allUploadSuccessful && !isUploadError"
          class="upload-manager-header-section-check"
        >
          <i class="ri-check-fill" />
        </div>

        <div
          v-if="isUploadDone && !allUploadSuccessful && isUploadError"
          class="upload-manager-header-section-error"
        >
          <i class="ri-error-warning-line" />
        </div>
        <div class="upload-manager-header-section-count">
          <div class="upload-manager-header-section-count-title">{{ getHeaderMessage }}</div>
          <div :class="sizeCountClass">{{ getHeaderSizeMessage }}</div>
        </div>
      </div>
      <div class="upload-manager-header-section">
        <div v-if="isUploadDone" @click="closeManager" class="upload-manager-header-section-close">
          {{ $t('close') }}
        </div>
        <i
          @click="toggleExpand"
          :class="isExpanded ? 'ri-arrow-down-s-line' : 'ri-arrow-up-s-line'"
        />
      </div>
    </div>
    <div class="upload-manager-progress">
      <div
        v-if="!isUploadDone"
        class="upload-manager-progress-front upload-manager-progress-in-progress"
        :style="`width: ${progressBarWidth}px;`"
      />
      <div class="upload-manager-progress-back" />
    </div>
    <div v-if="isExpanded" class="upload-manager-body">
      <div
        class="upload-manager-body-file"
        v-for="(file, index) in files"
        :key="`file-${index}`"
        @mouseover="mouseEnter(index)"
        @mouseleave="mouseLeave(index)"
      >
        <div class="upload-manager-body-file-left">
          <div
            class="upload-manager-body-file-left-name"
            :class="{
              ...getFileNameClass('upload-manager-body-file-left', file),
              'upload-manager-body-file-left-name-canceled': isFileCanceled(file)
            }"
          >
            {{ file.name }}
          </div>
          <div
            class="upload-manager-body-file-left-status"
            :class="{
              ...getFileNameClass('upload-manager-body-file-left', file)
            }"
          >
            <div v-if="isFileCanceled(file)">
              {{ $t('canceled') }}
            </div>
            <div v-if="isFileWaiting(file)">
              {{ $t('waiting') }}
            </div>
            <div v-if="isFileUploading(file)">
              {{ $t('uploading') }}
              <span v-if="file.progress">- {{ file.progress }}%</span>
            </div>
            <div class="upload-manager-body-file-left-status--" v-if="isFileAnalyzing(file)">
              <div class="upload-manager-body-file-left-status-success">
                <span>{{ $t('uploaded') }}</span>
                <i class="ri-check-fill" />
              </div>
              <span>&nbsp;</span>
              <span class="upload-manager-body-file-left-status-analyzing">{{
                $t('analyzing')
              }}</span>
            </div>
            <div
              class="upload-manager-body-file-left-status--"
              v-if="isFileDone(file) && !isFileAlreadyUploaded(file)"
            >
              <div class="upload-manager-body-file-left-status-success">
                <span>{{ $t('uploaded') }}</span>
                <i class="ri-check-fill" />
                <span>&nbsp;</span>
                <span>{{ $t('analyzed') }}</span>
                <i class="ri-check-fill" />
              </div>
            </div>
            <div
              class="upload-manager-body-file-left-status-success"
              v-if="isFileAlreadyUploaded(file)"
            >
              <span>{{ file.message }}</span>
              <i class="ri-check-fill" />
            </div>
            <div v-if="isFileError(file)" class="upload-manager-body-file-left-status--">
              <span class="upload-manager-body-file-left-status-error"
                >{{ $t('uploadError') }} {{ file.message }}</span
              >
            </div>
          </div>
        </div>
        <div class="upload-manager-body-file-right">
          <div
            class="upload-manager-body-file-right-size"
            :class="getFileNameClass('upload-manager-body-file-right', file)"
          >
            {{ getFileFormatedSize(file.size) }}
          </div>
          <div class="upload-manager-body-file-right-status">
            <div class="upload-manager-body-file-right-status--" v-if="isFileDone(file)">
              <i class="ri-check-fill" />
            </div>
            <div
              v-if="hoveredItemIndex === index && (isFileWaiting(file) || isFileUploading(file))"
            >
              <icon-button
                icon="ri-close-line"
                size="small"
                btnStyle="tertiary"
                @submit="cancelUpload(file)"
              />
            </div>
            <div v-else>
              <spinner v-if="isFileUploading(file) || isFileAnalyzing(file)" color="grey" />
            </div>
            <i v-if="isFileError(file)" class="ri-alert-fill" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="allUploadSuccessful && hasOneSuccessfulUpload" class="upload-manager-footer">
      <submit-button
        btnStyle="secondary"
        size="medium"
        iconClass="ri-folder-transfer-line"
        :label="$t('addToProject')"
        @submit="addUploadToProject"
      />
    </div>
  </div>
</template>

<script>
import * as tus from 'tus-js-client';

import { UPLOAD_STATUS, UPLOAD_TYPE } from '@/store/upload';

import { getFileSize } from '@/utils/functions/audio';
import { getFileId } from '@/utils/functions/upload';

import Spinner from '../loaders/spinnerWithoutProgress';
import IconButton from '../iconButton';
import SubmitButton from '../buttons/submitButton';

export default {
  components: {
    IconButton,
    Spinner,
    SubmitButton
  },
  props: {
    files: {
      type: Array,
      required: true
    },
    isClosed: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isExpanded: true,
      hoveredItemIndex: undefined
    };
  },
  computed: {
    progressBarWidth() {
      return (this.progress * 577) / 100;
    },
    sizeCountClass() {
      return {
        'upload-manager-header-section-count-text': true,
        'upload-manager-header-section-count-completed': this.isUploadDone,
        ' upload-manager-header-section-count-in-progress': !this.isUploadDone,
        'upload-manager-header-section-count-error':
          !this.allUploadSuccessful && this.isUploadDone && this.isUploadError
      };
    },
    progress() {
      const uploadedFileCount = this.files.filter(i => i.status >= UPLOAD_STATUS.ANALYZING).length;
      const analyzedFileCount = this.files.filter(i => i.status >= UPLOAD_STATUS.DONE).length;
      const canceledFileCount = this.files.filter(i => i.status === UPLOAD_STATUS.CANCELED).length;
      const stepProgressPercentage = 100 / ((this.files.length - canceledFileCount) * 2);
      return Math.round(stepProgressPercentage * (uploadedFileCount + analyzedFileCount));
    },
    getHeaderMessage() {
      return this.$tc('processingCount', this.files.length);
    },
    getHeaderSizeMessage() {
      const sum = this.files.reduce((acc, obj) => acc + obj.size, 0);
      const str1 = this.$t('uploadingSize', {
        count: getFileSize(sum),
        total: getFileSize(this.uploadSize)
      });
      const analyzedFileCount = this.files.filter(i => i.status > UPLOAD_STATUS.ANALYZING).length;
      const canceledFileCount = this.files.filter(i => i.status === UPLOAD_STATUS.CANCELED).length;
      const str2 = this.$t('analyzedCount', {
        count: analyzedFileCount,
        total: this.files.length - canceledFileCount
      });
      return analyzedFileCount !== 0 ? `${str1} | ${str2}` : `${str1}`;
    },
    hasOneSuccessfulUpload() {
      return this.files.filter(i => this.isFileDone(i)).length >= 1;
    },
    allUploadSuccessful() {
      const successfulTrackUploadCount = this.files.filter(i => this.isFileDone(i)).length;
      return this.files.length === successfulTrackUploadCount;
    },
    isUploadDone() {
      const failedTrackUploadCount = this.files.filter(
        i => i.status < UPLOAD_STATUS.WAITING
      ).length;
      const successfulTrackUploadCount = this.files.filter(i => this.isFileDone(i)).length;
      return this.files.length === failedTrackUploadCount + successfulTrackUploadCount;
    },
    isUploadError() {
      return this.files.filter(i => i.status === UPLOAD_STATUS.ERROR).length > 0;
    }
  },
  methods: {
    cancelUpload(file) {
      this.$store.commit('setUploadAsCanceled', {
        type: UPLOAD_TYPE.TRACK,
        id: getFileId(file.file)
      });
      if (file.uploadUrl) {
        tus.Upload.terminate(file.uploadUrl)
          .then(() => {
            // FIXME
          console.log('DELETION SUCCESSFUL'); // eslint-disable-line
          })
          .catch(err => {
            // FIXME
          console.log('DELETION FAILED', err); // eslint-disable-line
          });
      }
    },
    isFileWaiting(file) {
      return file.status === UPLOAD_STATUS.WAITING;
    },
    isFileUploading(file) {
      return file.status === UPLOAD_STATUS.UPLOADING;
    },
    isFileAnalyzing(file) {
      return file.status === UPLOAD_STATUS.ANALYZING;
    },
    isFileDone(file) {
      return file.status === UPLOAD_STATUS.DONE;
    },
    isFileCanceled(file) {
      return file.status === UPLOAD_STATUS.CANCELED;
    },
    isFileAlreadyUploaded(file) {
      return file.status === UPLOAD_STATUS.DONE && file.message === 'Already in the library';
    },
    isFileError(file) {
      return file.status === UPLOAD_STATUS.ERROR;
    },
    mouseEnter(index) {
      this.hoveredItemIndex = index;
    },
    mouseLeave() {
      this.hoveredItemIndex = undefined;
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    closeManager() {
      this.$emit('update:isClosed', true);
    },
    getFileNameClass(prefix, file) {
      return {
        [`${prefix}-error`]: this.isFileError(file)
      };
    },
    getFileFormatedSize(size) {
      return getFileSize(size);
    },
    addUploadToProject() {
      const filesId = this.files.map(el => el.libraryFileId).filter(id => id !== null);
      this.$emit('addUploadToAProject', filesId);
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-manager {
  @include body-1;
  color: $color_neutral_100;
  width: 577px;
  user-select: none;
  @include shadow-down-03;
  border-radius: 0 0 4px 4px;

  &-header {
    width: 100%;
    height: 54px;
    background-color: $color_neutral_100;
    display: flex;
    justify-content: space-between;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    &-section {
      display: flex;
      align-items: center;

      &-percent {
        width: 44px;
        height: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        @include body-2;
        line-height: normal;
        margin: 0 0 0 16px;
      }

      &-in-progress {
        background-color: $color_primary_50;
      }

      &-completed {
        background-color: $color_success_100;
      }

      &-check {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background-color: $color_success_100;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 8px 0 16px;
        color: $color_neutral_100;
      }

      &-error {
        width: 20px;
        height: 20px;
        display: flex;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 8px 0 16px;
        background-color: $color_danger_100;
        color: $color_neutral_100;
        font-size: 28px;
      }

      &-count {
        margin: 0 0 0 8px;

        &-title {
          color: $color_neutral_0;
          line-height: 150%;
        }

        &-text {
          @include body-2;
        }

        &-in-progress {
          color: $color_primary_50;
        }

        &-completed {
          color: $color_success_100;
        }

        &-error {
          color: $color_danger_100;
        }
      }

      &-close {
        font-weight: 600;
        color: $color_primary_50;
        margin: 0 24px 0 0;
        cursor: pointer;
      }

      .ri-arrow-down-s-line,
      .ri-arrow-up-s-line {
        color: $color_neutral_0;
        font-size: 24px;
        margin: 0 8px 0 0;
        cursor: pointer;
      }
    }
  }

  &-progress {
    position: relative;
    width: 100%;
    height: 4px;

    &-front {
      z-index: 1;
      position: absolute;
      height: 4px;
    }

    &-in-progress {
      background-color: $color_primary_50;
    }

    &-completed {
      background-color: $color_success_100;
    }

    &-back {
      z-index: 0;
      position: absolute;
      width: 100%;
      height: 4px;
      background-color: $color_neutral_100;
    }
  }

  &-body {
    background-color: $color_neutral_0;
    max-height: 344px;
    overflow-y: auto;
    border-radius: 0 0 4px 4px;

    &-file {
      width: 100%;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:not(:last-child) {
        border-bottom: 1px solid $color_neutral_30;
      }

      &-left {
        margin: 0 0 0 16px;

        &-name {
          max-width: 450px;
          line-height: 150%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &-canceled {
            color: $color_neutral_60;
            text-decoration: line-through;
          }
        }

        &-status {
          @include body-2;
          color: $color_neutral_60;

          &-analyzing:after {
            animation: dots 2s linear infinite;
            content: '';
            font-weight: bold;
          }

          &-success {
            color: $color_success_100;
          }

          &-warning {
            color: $color_success_100;
          }

          &-error {
            color: #a4352f;
          }

          &-- {
            display: flex;
            align-items: center;

            span {
              margin: 0 4px 0 0;
            }
          }
        }

        &-error {
          color: $color_danger_100;
        }
      }

      &-right {
        display: flex;
        flex-shrink: 0;

        &-size {
          color: $color_neutral_60;
          display: flex;
          align-items: center;
        }

        &-error {
          color: $color_danger_100;
        }

        &-status {
          width: 18px;
          display: flex;
          justify-content: center;
          margin: 0 16px;

          &-- {
            width: 20px;
            height: 20px;
            border-radius: 100%;
            background-color: $color_success_100;
            color: $color_neutral_0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 3px;
          }

          .ri-alert-fill {
            color: $color_danger_100;
            font-size: 24px;
          }
        }
      }
    }
  }

  &-footer {
    background: $color_neutral_0;
    height: 56px;
    border-radius: 0 0 4px 4px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 24px;
    border-top: 1px solid $color_neutral_30;
  }

  @keyframes dots {
    0%,
    20% {
      content: '.';
    }
    40% {
      content: '..';
    }
    60% {
      content: '...';
    }
    90%,
    100% {
      content: '';
    }
  }
}
</style>
