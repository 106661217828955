
<div
  :class="{
    bridgeMarkdownEditor: true,
    hideToolbar: this.isEditorActive === false
  }"
  v-click-outside="{ hide: handleClickOutside }"
  @mousedown="handleMouseDown"
  @mouseup="handleMouseUp"
>
  <editor
    ref="editor"
    initialEditType="wysiwyg"
    height="auto"
    :initialValue="editorInitialValue"
    :options="editorInitialOptions"
    @caretChange="handleChange"
    @change="handleChange"
    @focus="handleFocus"
  />
</div>
