<template>
  <div :class="switchButtonClass" @click="toggleButton">
    <div :class="leftLabelClass" v-if="leftLabel && !labelInactive">{{ leftLabel }}</div>
    <div class="switch-button-toggle">
      <div :class="thumbClass" />
      <div :class="bkgClass" />
    </div>
    <div :class="labelClass" v-if="on || !labelInactive">{{ label }}</div>
    <div :class="labelClass" v-if="!on && labelInactive">{{ labelInactive }}</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    leftLabel: {
      type: String,
      required: false,
      default: ''
    },
    id: {
      type: String,
      required: false,
      default: null
    },
    labelInactive: {
      type: String,
      required: false,
      default: null
    },
    defaultValue: {
      type: Boolean,
      required: false,
      default: false
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    labelPrefix: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: 'green'
    },
    size: {
      type: String,
      required: false,
      default: 'medium'
    }
  },
  data() {
    return {
      on: this.defaultValue
    };
  },
  computed: {
    thumbClass() {
      let str = 'switch-button-toggle-thumb';
      str += this.on ? ' switch-button-toggle-right' : ' switch-button-toggle-left';
      return str;
    },
    bkgClass() {
      let str = 'switch-button-toggle-bkg';
      const switchButtonColor = this.color === 'purple' ? 'purple' : 'green';
      str += this.on
        ? ` switch-button-toggle-${switchButtonColor}-on`
        : ' switch-button-toggle-off';
      return str;
    },
    leftLabelClass() {
      let str = 'switch-button-label switch-button-label-left';
      const switchButtonColor = this.color === 'purple' ? 'purple' : 'green';
      str += !this.on
        ? ` switch-button-${switchButtonColor}-on switch-button-label-enabled`
        : ' switch-button-off';
      str += ` switch-button-label-${this.size}`;
      return str;
    },
    labelClass() {
      let str = 'switch-button-label switch-button-label-right';
      const switchButtonColor = this.color === 'purple' ? 'purple' : 'green';
      str += this.on
        ? ` switch-button-${switchButtonColor}-on switch-button-label-enabled`
        : ' switch-button-off';
      str += ` switch-button-label-${this.size}`;
      return str;
    },
    switchButtonClass() {
      let str = 'switch-button';
      str += this.leftLabel ? ' full-width' : '';
      str += this.isDisabled ? ' disabled' : '';
      str += this.labelPrefix ? ' reverse' : '';
      return str;
    }
  },
  watch: {
    defaultValue() {
      this.on = this.defaultValue;
    }
  },
  methods: {
    toggleButton() {
      if (this.isDisabled) {
        return;
      }
      this.on = !this.on;
      this.$emit('toggleSwitchButton', this.on, this.id);
    },
    onlyToggleDisplay() {
      // used in parent with ref
      this.on = !this.on;
    }
  }
};
</script>

<style lang="scss" scoped>
.switch-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  @include body-2;

  &-toggle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    margin: 0 10px;

    &-thumb {
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $color_neutral_0;
      z-index: 1;
    }

    &-bkg {
      z-index: 0;
      position: absolute;
      width: 32px;
      height: 20px;
      border-radius: 20px;
    }

    &-green-on {
      background-color: $color_success_100;
    }

    &-purple-on {
      background-color: $color_primary_100;
    }

    &-off {
      font-size: 14px;
      background-color: $color_neutral_40;
    }

    &-left {
      left: 4px;
    }

    &-right {
      right: 4px;
    }
  }

  &-label {
    margin: 0;
    user-select: none;
    flex: 1;

    &-left {
      text-align: right;
    }

    &-right {
      text-align: left;
    }

    &-enabled {
      font-weight: bold;
    }

    &-medium {
      font-size: 12px;
    }
    &-large {
      font-size: 14px;
    }
  }

  &-green-on {
    color: $color_success_110;
  }

  &-purple-on {
    color: $color_primary_100;
  }

  &-green-off {
    color: $color_neutral_60;
  }
}

.full-width {
  width: 100%;
}

.reverse {
  flex-direction: row-reverse;
  .switch-button-label {
    margin: 0 8px 0 0;
  }
}

.disabled {
  cursor: not-allowed;
  .switch-button-label,
  .switch-button-toggle {
    opacity: 0.4;
  }
  .switch-button-label-enabled {
    opacity: 1;
  }
}
</style>
