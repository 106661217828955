
<div>
  <div>
    <h2 class="headline">{{ $t('subscription.headline.yourPlan') }}</h2>
    <div class="box">
      <div class="firstRow">
        <div class="banner" v-if="isFreeTrialEnabled">
          <div class="bannerHeader">{{ $t('freeTrialBanner.title') }}</div>
          <p class="bannerText">{{ $t('freeTrialBanner.billingText') }}</p>
        </div>
        <div class="banner bannerCanceled" v-if="isSubscriptionCanceled">
          <p class="bannerText">
            <i class="ri-error-warning-fill bannerIcon"></i>
            {{ $t('subscriptionCanceledBanner', { date: getBillingDate }) }}
          </p>
        </div>
        <hgroup>
          <h2 class="headline">{{ getCurrentPlanName }}</h2>
          <h4 class="headlinePeriod" v-if="getSubscriptionPeriod && !isFreeTrialEnabled">
            ({{ getSubscriptionPeriod }})
          </h4>
        </hgroup>
        <submit-button
          class="upgradePlan"
          :label="$t('availablePlans')"
          btnStyle="primary"
          @submit="navigateToOffers"
        />
      </div>
      <div class="secondRow">
        <div class="rowItem" v-if="isFreeTrialEnabled">
          <p class="rowItemTitle">Days left on trial</p>
          <p class="rowItemData">{{ getSubscriptionFreeTrialDaysLeft }}</p>
        </div>
        <div class="rowItem" v-if="getCurrentPlanName === 'pro'">
          <p class="rowItemTitle">Next invoice</p>
          <p class="rowItemData">{{ getNextInvoiceDate() }}</p>
        </div>
        <div class="rowItem" v-if="getCurrentPlanName === 'pro' || isFreeTrialEnabled">
          <p class="rowItemTitle">
            {{
              `Next invoice total (billed ${
                this.getSubscriptionPeriod === 'yearly' ? 'annually' : 'monthly'
              })`
            }}
          </p>
          <p class="rowItemData" v-if="getNextInvoicePrice()">{{ getNextInvoicePrice() }}</p>
          <p class="rowItemData" v-else>
            <spinner-without-progress color="grey" :size="24" />
          </p>
        </div>
        <div class="rowItem">
          <p class="rowItemTitle">{{ $tc('membersLower', getWorkspaceMembersCount()) }}</p>
          <p class="rowItemData">{{ getWorkspaceMembersCount() }}</p>
          <p class="rowItemInfo">First 5 members are free</p>
        </div>
        <div class="rowItem">
          <p class="rowItemTitle">{{ $t('storageTitle') }}</p>
          <p class="rowItemData">{{ getWorkspaceTracksCount() }} Tracks</p>
          <p class="rowItemData">{{ getWorkspaceStorageCount() }} Gb</p>
        </div>
      </div>
    </div>
    <div v-if="getCurrentPlanName === 'pro' || getCurrentPlanName === 'Free trial'">
      <div>
        <div v-if="isBillingDetailsFlagEnabled">
          <hr class="separator" />
          <h2 class="headline">Billing details</h2>
          <div class="box secondRow">
            <div class="rowItem">
              <billing-user-card
                :card="getStripeUserCard"
                :prepareForPayment="prepareForPayment"
                :hasPaymentMethod="hasPaymentMethod"
              />
            </div>
            <div class="rowItem">
              <billing-user-email
                :address="getStripeUserAddress"
                :email="getStripeUserEmail"
                :company="getStripeUserCompany"
                :prepareForPayment="prepareForPayment"
                :hasPaymentMethod="hasPaymentMethod"
              />
            </div>
            <div class="rowItem">
              <billing-user-address
                :email="getStripeUserEmail"
                :address="getStripeUserAddress"
                :company="getStripeUserCompany"
                :vat="getStripeUserVat"
                :prepareForPayment="prepareForPayment"
                :hasPaymentMethod="hasPaymentMethod"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="BillsList && BillsList.length > 0">
        <hr class="separator" />
        <payment-invoice :invoices="BillsList" :subscriptions="getWorkspaceSubscription" />
      </div>
      <hr class="separator" />
      <div v-if="isFreeTrialEnabled">
        <div v-if="!hasPaymentMethod">
          <h2 class="headline">Your plan information</h2>
          <p class="paragraph">
            At the end of your 14-day free trial, your workspace will be downgraded to a Free
            plan. No worries! You can always upgrade to Pro at any time by adding your billing
            information in the “Billing details” section.
          </p>
        </div>
      </div>
      <div v-else>
        <payment-cancellation
          :isCanceled="isSubscriptionCanceled"
          :billingDate="getBillingDate"
          :email="getUserEmail"
          @cancelSubscription="handleCancelSubscription"
          :isOwner="isUserOwner"
        />
      </div>
    </div>
    <div v-else>
      <div v-if="BillsList && BillsList.length > 0">
        <hr class="separator" />
        <payment-invoice :invoices="BillsList" :subscriptions="getWorkspaceSubscription" />
      </div>
    </div>
  </div>
</div>
