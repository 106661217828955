<template>
  <div class="usage-panel-body">
    <div class="usage-panel-body-title">{{ $t('sharedOn') }}</div>
    <div v-if="isMultipleTrackEdition" class="usage-panel-body-multiple-information">
      <div class="usage-panel-body-multiple-information-icon">
        <i class="ri-information-fill" />
      </div>
      <div class="usage-panel-body-multiple-information-label">
        All selected tracks must meet minimum requirements to be shared on hubs. Make sure they all
        have an <span class="bold">artist, album, and artwork</span> saved.
      </div>
    </div>
    <div v-if="!isMultipleTrackEdition && !isTrackCompliant" class="usage-panel-body-compliancy">
      <div class="title">
        <div class="title-container">
          <i class="ri-file-list-2-line" />
          Make your track compliant with hub requirements
        </div>
        <span>{{ trackCompliancyProgress.current }} of {{ trackCompliancyProgress.total }}</span>
      </div>
      <div
        v-for="(criteria, index) in Object.keys(trackCompliancyCriteria)"
        :key="index"
        class="criterias"
      >
        <i
          class="ri-checkbox-circle-fill criterias-icon"
          v-if="trackCompliancyCriteria[criteria]"
        />
        <i v-else class="ri-checkbox-blank-circle-line criterias-icon" />
        {{ criteria }}
      </div>
    </div>
    <div v-for="(hub, index) in hubs" :key="index" class="usage-panel-body-hub">
      <div class="usage-panel-body-hub-container">
        <div class="usage-panel-body-hub-name-container">
          <avatar :initial="hub.name.charAt(0).toUpperCase()" :pictureUrl="hub.logo" square />
          <span class="usage-panel-body-hub-name">{{ hub.name }}</span>
        </div>
        <div class="usage-panel-body-hub-dropdown">
          <select-box
            ref="selectBoxRef"
            :options="options"
            large
            :selectedValue="{ label: choice[hub.id] }"
            @setSelectBoxValue="toggleTrackToHub($event, hub.id)"
            :placeholder="placeholder"
            :isMultipleSelection="isMultipleTrackEdition"
            :isFullDisabled="!isTrackCompliant"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from '@/utils/bus';
import { HUB_ADD_TRACK, HUB_REMOVE_TRACK } from '@/graphql/mutations/hubs';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import { get as getRoute } from '@/router/routes';

export default {
  props: {
    tracksToEdit: {
      type: Array,
      required: false,
      default: () => []
    },
    hubs: {
      type: Array,
      required: true,
      default: () => []
    },
    isMultipleTrackEdition: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      options: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ],
      choice: this.isMultipleTrackEdition ? [] : this.setDefaultValue(),
      placeholder: this.isMultipleTrackEdition ? this.$t('multiple') : 'Select value',
      pendingStatus: false
    };
  },
  computed: {
    tracksIdsArray() {
      return this.isMultipleTrackEdition ? this.tracksToEdit : [this.tracksToEdit[0].id];
    },
    trackCompliancyCriteria() {
      return {
        title: typeof this.tracksToEdit[0].title === 'string',
        artist: this.tracksToEdit[0].artists !== null,
        album: this.tracksToEdit[0].supports.length > 0,
        artwork: this.tracksToEdit[0].imgSrc !== '/images/artwork.jpg'
      };
    },
    isTrackCompliant() {
      return this.isMultipleTrackEdition
        ? true
        : Object.values(this.trackCompliancyCriteria).every(valeur => valeur === true);
    },
    trackCompliancyProgress() {
      const array = Object.values(this.trackCompliancyCriteria);
      return {
        current: array.reduce((count, valeur) => count + (valeur ? 1 : 0), 0),
        total: array.length
      };
    }
  },
  methods: {
    setDefaultValue() {
      const hubsValue = [];
      this.hubs.forEach(o1 => {
        const r = this.tracksToEdit[0].hubs.some(o2 => {
          return o1.id === o2.id;
        });
        hubsValue[o1.id] = r ? 'Yes' : 'No';
      });
      return hubsValue;
    },
    toggleTrackToHub(choice, index) {
      this.choice[index] = choice;
      this.pendingStatus = true;
      this.$apollo
        .mutate({
          mutation: this.choice[index] === 'Yes' ? HUB_ADD_TRACK : HUB_REMOVE_TRACK,
          variables: {
            hubId: index,
            trackIds: this.tracksIdsArray
          }
        })
        .then(() => {
          this.pendingStatus = false;
          this.$emit('autoSaveSuccess', true);
        })
        .catch(err => {
          this.$refs.selectBoxRef[this.hubs.findIndex(obj => obj.id === index)].resetToDefault();
          this.pendingStatus = false;
          const key = getGraphQlErrorCode(err);
          if (key === 'NOT_FOUND') {
            bus.$emit('showAlert', {
              message: { key: 'addToHubFileNotFound' },
              style: 'danger',
              delay: 5000
            });
            return;
          }
          if (key === 'HUB_TRACKS_NOT_COMPLIANT') {
            bus.$emit('showAlert', {
              message: {
                key: 'addToHubFailed',
                args: { hub: this.hubs.find(obj => obj.id === index).name }
              },
              actionLabel: 'Show non-compliant tracks',
              handleAction: () => {
                if (this.$route.name !== 'trackList') {
                  this.$router.push(getRoute('trackList'));
                  this.$nextTick(() => {
                    bus.$emit('activeDisplayNonCompliant');
                  });
                } else {
                  bus.$emit('activeDisplayNonCompliant');
                }
              },
              style: 'danger no-max-width',
              delay: 5000
            });
            return;
          }
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.usage-panel-body {
  &-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 125%;
    margin-bottom: 8px;
  }
  &-multiple-information {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    background: $color_information_10;
    border-radius: 4px;
    margin: 16px 0;
    padding: 8px 16px;
    color: $color_information_100;
    cursor: pointer;
    &-icon {
      flex-shrink: 0;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #d3e3fb;
      margin-right: 8px;
    }
    .bold {
      font-weight: 600;
    }
  }
  &-compliancy {
    margin: 8px 0 16px 0;
    padding: 16px;
    background: $color_primary_10;
    .title {
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      &-container {
        font-weight: 600;
        display: flex;
        transform: translateX(-3px);
      }
    }
    .ri-file-list-2-line {
      font-size: 24px;
      font-weight: normal;
      margin-right: 8px;
      color: $color_information_100;
    }
    .criterias {
      text-transform: capitalize;
      .ri-checkbox-circle-fill {
        color: $color_success_100;
      }
      .criterias-icon {
        font-size: 16px;
        margin-right: 4px;
      }
    }
  }
  &-hub {
    margin-top: 8px;
    margin-bottom: 8px;
    &-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-name-container {
      display: flex;
      align-items: center;
    }
    &-name {
      margin-left: 8px;
    }
    &-dropdown {
      width: 269px;
    }
  }
}
</style>
