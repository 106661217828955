
<div class="stars-rating" :style="{ width: parentWidth }" @mouseleave="parentLeaveHandler">
  <div
    v-for="(n, index) in length"
    :key="`n-${index}`"
    class="stars-rating-el-container"
    @mouseover="containerHoverHandler(index)"
    @click.stop="containerClickHandler(index)"
  >
    <div
      v-if="dotStateHandler(index)"
      class="stars-rating-el"
      :class="{ 'stars-rating-el-playing': playingTrack === itemId }"
    ></div>
    <i v-if="emptyStarHandler(index)" class="stars-rating-star ri-star-s-line"></i>
    <i v-if="isHoveredOrSelected(index)" class="stars-rating-star ri-star-s-fill"></i>
  </div>
</div>
