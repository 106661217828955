<template>
  <div class="information-banner">
    <div class="icon-info"><i class="ri-error-warning-fill"></i></div>
    <div class="text">
      {{ content }}
    </div>
    <div
      v-tooltip="{
        content: roleId !== 1 ? 'Only administrators can upgrade plan' : '',
        classes: 'upgrade-banner-tooltip',
        position: 'top'
      }"
    >
      <submit-button
        class="upgrade-now-btn"
        btnStyle="tertiary"
        size="small"
        :label="$t('upgradeNow')"
        :disabled="roleId !== 1"
        @submit="goToUpgrade"
      />
    </div>
  </div>
</template>

<script>
import { get as getRoute } from '@/router/routes';

export default {
  props: {
    content: {
      type: String,
      required: false,
      default: 'text'
    },
    roleId: {
      type: Number,
      required: false,
      default: 2
    }
  },
  methods: {
    goToUpgrade() {
      this.$router.push(getRoute('offersSettings'));
      this.$emit('closeModal');
    }
  }
};
</script>

<style lang="scss" scoped>
.information-banner {
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  padding: 8px 16px;
  background-color: $color_warning_10;
  display: flex;
  align-items: center;
  @include body-1;
  color: $color_warning_110;
  border-radius: 4px;
  .text {
    flex-grow: 1;
    .mail {
      font-weight: 600;
    }
    .resend {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .icon {
    &-info {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      background: rgba(255, 197, 51, 0.1);
      color: $color_warning_100;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-close {
      flex-shrink: 0;
    }
  }
}
</style>
<style lang="scss">
.information-banner {
  .upgrade-now-btn.tertiary {
    padding: 0 16px;
    color: $color_warning_110;
    &:hover {
      background: #ffe4a3;
    }
  }
  .upgrade-now-btn.tertiary.disabled {
    &:hover {
      background: none;
    }
  }
}
.upgrade-banner-tooltip.tooltip {
  .tooltip-inner {
    max-width: 130px;
    text-align: center;
  }
}
</style>
