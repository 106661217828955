
<div v-if="isLoadingProject && this.projects.length === 0" class="loader">
  <spinner-without-progress color="grey" :size="32" />
</div>
<div v-else>
  <list-projects
    :projects="projects"
    @goToProjectDetail="goToProjectDetail"
    @duplicate="handleDuplicateProjectMutation"
    @share="openShareModal"
    @delete="openDeleteProjectModal"
    @copyToClipboard="copyToClipboard"
  />
  <observer v-if="hasMoreToLoad" :height="64" bottom @intersect="loadMore" />
  <div v-if="projects.length === 0">
    <div v-if="!hasSearch">
      <empty-project
        :title="$t('emptyProjectTitle')"
        :description="$t('emptyProjectSubtext')"
        placeholderType="projectlist"
        :cloudedTextContent="$t('createProjects')"
        :cloudedTextColor="{
          stroke: '#FF9143',
          fill: '#FFF9EB'
        }"
      >
        <template v-slot:button>
          <submit-button
            size="large"
            :label="$t('newProject')"
            iconClass="ri-folder-add-fill"
            @submit="$emit('openCreateProjectModal')"
          />
        </template>
      </empty-project>
    </div>
    <div v-else>
      <div class="no-result">{{ $t('noResult') }}</div>
    </div>
  </div>
</div>
