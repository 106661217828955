<template>
  <div class="card-album">
    <router-link
      class="card-album-link"
      :to="albumUrl"
      @mouseover.native="cardHoverHandler"
      @mouseleave.native="cardMouseLeaveHandler"
    >
      <div class="card-album-link-img">
        <img loading="lazy" :src="albumArtwork" alt="album cover" class="card-album-link-img--" />
      </div>
      <transition name="fade" @after-enter="isHover = true" @after-leave="isHover = false">
        <div v-if="isHover" class="card-album-link-hover"></div>
      </transition>
    </router-link>
    <div class="card-album-name">
      <div class="card-album-name-container">
        <router-link :to="albumUrl" class="card-album-name--">{{ albumName }}</router-link>
      </div>
      <div class="card-album-name-artist-container">
        <router-link :to="artistUrl" class="card-album-name-artist">{{ artistName }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    albumArtwork: {
      type: String,
      required: false,
      default: './../../assets/images/artworkPlaceHolder.png'
    },
    albumUrl: {
      type: String,
      required: true
    },
    artistUrl: {
      type: String,
      required: true
    },
    albumName: {
      type: String,
      required: true
    },
    artistName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isHover: false
    };
  },
  methods: {
    cardHoverHandler() {
      this.isHover = true;
    },
    cardMouseLeaveHandler() {
      this.isHover = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-album {
  width: 100%;
  height: fit-content;
  &-link {
    position: relative;
    display: block;
    width: 100%;
    &-img {
      width: 100%;
      padding-top: 100%;
      position: relative;
      &-- {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center center;
      }
    }
    &-hover {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background: $ui-overlay-gradient-dark;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }
  &-name {
    width: 100%;
    height: calc(238px - 177px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    &-- {
      @include subtitle-1;
      height: 19px;
      margin-bottom: 2px;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover {
        text-decoration: underline $color_neutral_100;
      }
    }
    &-artist {
      @include body-1;
      color: $color_neutral_60;
      height: 19px;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &-container {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      &:hover {
        text-decoration: underline $color_neutral_100;
      }
    }
  }
}

// Fade transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

// Slide transition
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(5px);
  opacity: 0;
}
</style>
