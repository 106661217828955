<template>
  <div class="artist-sharings">
    <Sharingsview entityType="artist" :entityIds="entityIds" :entityName="entityName" />
  </div>
</template>

<script>
import Sharingsview from '../../../../../containers/shareViews/sharings';

export default {
  components: {
    Sharingsview
  },
  props: {
    entityIds: {
      type: Array,
      required: true
    },
    entityName: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.artist-sharings {
  margin-top: 24px;
}
</style>
