<template>
  <div class="toggle">
    <div
      class="toggle-item"
      v-for="(option, index) in options"
      :key="`toggle-${index}`"
      @click="toggleMenu(index)"
    >
      <div
        class="toggle-item-label"
        :class="index === selectedTabIndex ? 'toggle-item-enabled' : 'toggle-item-disabled'"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    defaultSelectedTabIndex: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      selectedTabIndex: this.defaultSelectedTabIndex
    };
  },
  watch: {
    defaultSelectedTabIndex() {
      this.selectedTabIndex = this.defaultSelectedTabIndex;
    }
  },
  methods: {
    toggleMenu(index) {
      this.selectedTabIndex = index;
      this.$emit('setToggleMenuIndex', index);
    }
  }
};
</script>

<style lang="scss" scoped>
.toggle {
  display: flex;
  font-weight: 400;
  font-size: 14px;

  &-item {
    &:first-child {
      .toggle-item-label {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      .toggle-item-enabled {
        border-left: 1px solid $color_primary_100;
      }

      .toggle-item-disabled {
        border-left: 1px solid $color_neutral_30;
      }
    }

    &:last-child {
      .toggle-item-label {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    &-label {
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 16px;
      cursor: pointer;
      transition: all 200ms linear;
    }

    &-enabled {
      background-color: $color_primary_100;
      color: $color_neutral_0;
      border-top: 1px solid $color_primary_100;
      border-right: 1px solid $color_primary_100;
      border-bottom: 1px solid $color_primary_100;
    }

    &-disabled {
      color: $color_neutral_60;
      border-top: 1px solid $color_neutral_30;
      border-right: 1px solid $color_neutral_30;
      border-bottom: 1px solid $color_neutral_30;
    }
  }
}
</style>
