
<div class="container">
  <div class="formOverlay" v-if="paymentStatus">
    <div class="formProcessing">
      <div v-if="isPaymentProcessing">
        <spinner-without-progress color="grey" :size="24" />
        <div class="formProcessingMessage">Processing payment…</div>
      </div>
      <div v-if="isPaymentFailed">
        <i class="ri-alert-fill formErrorIcon" />
        <p class="formProcessingMessage">Payment failed</p>
        <p class="formErrorInformations" v-if="paymentErrorCode === 24">
          Looks like we encountered an error while setting up your VAT informations. If you
          continue to have issues, please contact us.
        </p>
        <p v-else>
          Looks like we encountered an error. Please try again. If you continue to have issues,
          try another payment method.
        </p>
        <p class="formErrorInformations">
          <span>{{ paymentErrorMessage }}</span>
          <span class="formErrorCode">(Error : {{ paymentErrorCode }})</span>
        </p>
        <div class="retryButton">
          <submit-button label="Try again" @submit="tryAgain" />
        </div>
      </div>
      <div v-if="isPaymentSuccessful" class="form-Processing">
        <spinner-without-progress color="grey" :size="24" />
        <div class="formProcessingMessage">Activating subscription…</div>
      </div>
    </div>
  </div>
  <form class="form" @submit="$v.$touch()" id="payment-form" :class="{ loader: paymentStatus }">
    <div class="leftPart">
      <fieldset>
        <legend class="formTitle">Your location</legend>
        <div class="inputLine multiInputLine">
          <select-box
            :options="getCountryList"
            placeholder=""
            label="Country"
            :selectedValue="getUserCountry"
            @setSelectBoxValue="selectCountry"
            large="large"
          />
          <text-field
            inputType="text"
            label="State"
            :large="true"
            :value="state"
            :fieldModel.sync="$v.state.$model"
            helper="(Optionnal)"
            autocomplete="off"
          />
        </div>
        <text-field
          inputType="text"
          label="Street address"
          :large="true"
          :value="address"
          autocomplete="off"
          :errorMessage="addressValidationMessage"
          :fieldModel.sync="$v.address.$model"
          class="inputLine"
        />
        <text-field
          inputType="text"
          label="Suite / Unit"
          :large="true"
          :value="addressSuite"
          :fieldModel.sync="$v.addressSuite.$model"
          helper="(Optionnal)"
          autocomplete="off"
          class="inputLine"
        />
        <div class="multiInputLine inputLine">
          <text-field
            inputType="text"
            label="City"
            :large="true"
            :value="city"
            :errorMessage="cityValidationMessage"
            :fieldModel.sync="$v.city.$model"
            autocomplete="off"
          />
          <text-field
            inputType="text"
            label="ZIP / Postal code"
            :large="true"
            :errorMessage="postalCodeValidationMessage"
            :fieldModel.sync="$v.postalCode.$model"
            :value="postalCode"
            autocomplete="off"
          />
        </div>
      </fieldset>
      <fieldset>
        <legend class="formTitle">Your company</legend>
        <text-field
          inputType="email"
          label="Billing email address"
          class="inputLine"
          autocomplete="off"
          :large="true"
          :value="userPrimaryEmail"
          :errorMessage="emailValidationMessage"
          :fieldModel.sync="$v.emailAddress.$model"
        />
        <div class="multiInputLine inputLine">
          <text-field
            inputType="text"
            label="Company name"
            autocomplete="off"
            :large="true"
            :fieldModel.sync="$v.companyName.$model"
            :errorMessage="companyNameMessage"
          />
          <text-field
            inputType="text"
            label="Tax number"
            helper="(Optionnal)"
            autocomplete="off"
            :large="true"
            :value="taxNumber"
            :fieldModel.sync="$v.taxNumber.$model"
          />
        </div>
      </fieldset>
      <fieldset>
        <legend class="formTitle">Payment information</legend>
        <label class="stripeLabel">Credit card</label>
        <div id="card-element" ref="stripeElement">
          <!-- Elements will create input elements here -->
        </div>
        <div id="card-errors" role="alert"></div>
        <div class="formFooter">
          <img draggable="false" src="/images/powered_by_stripe.png" />
          <p>
            Credit Card details are securely processed by Stripe and never stored on our servers.
          </p>
        </div>
      </fieldset>
    </div>
    <div class="rightPart">
      <div class="rightPartSticky">
        <div class="formResume">
          <header class="planInfosHeader">
            <i class="ri-music-2-fill planIcon" />{{ planName }}
          </header>
          <div class="planInfosBody">
            <div>{{ getMonthlySubscriptionFormattedPrice }} {{ getFeatures }}</div>
            <div class="planInfosTotalPrice">{{ getSubscriptionFormattedPrice }}</div>
          </div>
          <div class="planInfosBody" v-if="hasMoreMemberThanIncludedInPlan">
            <div>{{ getFormattedExtraMembers }} {{ getExtraMembersFeatures }}</div>
            <div class="planInfosTotalPrice">{{ getExtraMembersFormattedPrice }}</div>
          </div>
          <div class="planInfosBody planCoupon" v-if="!isCouponValid">
            <label class="couponLabel">Discount code</label>
            <div>
              <input
                type="text"
                ref="couponInput"
                autocomplete="off"
                :value="coupon"
                @keyup="setCouponValue"
                :class="{ couponInput: true, invalidCouponInput: isCouponValid === false }"
              />
              <div
                v-if="isCouponValid === false && isCouponApplicable === undefined"
                class="invalidCouponLabel"
              >
                Enter a valid coupon code
              </div>
              <div
                v-if="isCouponValid === false && isCouponApplicable === false"
                class="invalidCouponLabel"
              >
                This coupon can't be used with this plan
              </div>
            </div>
            <submit-button
              label="Apply"
              class="couponSubmit"
              :disabled="submitCouponDisabled"
              :pending="isCouponProcessing"
              @submit="submitCoupon"
            />
          </div>
          <div class="planInfosBody planCoupon" v-else>
            <div class="planInfosRow">
              <div class="planValidCoupon" v-if="displayCouponInput">
                <label>Discount code</label>
                <div class="couponGift"><i class="ri-gift-line" /> {{ coupon }}</div>
              </div>
              <div v-if="isCouponValid" class="planDiscount">
                <label>Promotion</label>
                <div class="planDiscountPrice">{{ getDiscountFormattedPrice }}</div>
              </div>
            </div>
          </div>
          <footer class="planInfosFooter">
            <div>{{ getSummaryLabel }}</div>
            <div class="planInfosTotalPrice">{{ getTotalFormattedPrice }}</div>
          </footer>
        </div>
        <input
          type="submit"
          :class="{ submitDisabled: isCouponProcessing || !isFormValid }"
          :value="getSubmitLabel"
        />
      </div>
    </div>
  </form>
</div>
