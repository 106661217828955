
<div class="chips-input" v-click-outside="{ hide: addChips }">
  <div class="chips-input-label">{{ label }}</div>
  <div
    class="chips-input-area"
    :class="[error && 'chips-input-area-error']"
    @click.self="focusIn"
  >
    <div :class="getItemClass(item)" v-for="(item, index) in chips" :key="`chips-input-${index}`">
      <input
        type="text"
        :class="getInputClass(item)"
        :style="`width: ${getInputWidth(item.value)}px; max-width: 468px;`"
        v-model="item.value"
        @keyup.13="toggleEdit(item, false, true)"
        @focus="toggleEdit(item, true, false)"
        @blur="toggleEdit(item, false, false)"
      />
      <icon-button
        class="chips-input-area-item-cross"
        icon="ri-close-line"
        btnStyle="tertiary"
        size="small"
        v-if="!item.isEditable"
        @submit="removeHandleClick(item)"
      />
    </div>
    <input
      class="chips-input-area-input"
      type="text"
      ref="input"
      v-model="newChip"
      :style="newChip ? `width: ${getInputWidth(newChip)}px;` : 'width: 10px;'"
      :placeholder="chips.length === 0 && `${placeholder}`"
      @paste="handlePaste"
      @keyup="handleKeyup"
      @keydown.8="removeHandleBackspaceKey"
      @keydown.9="addChips"
    />
  </div>
  <div class="chips-input-error-message" v-if="error">{{ error }}</div>
  <div class="chips-input-error-message" v-if="maxErrorMsg">{{ maxErrorMsg }}</div>
</div>
