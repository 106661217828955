<template>
  <div data-test="attachmentList">
    <div v-if="isFullWidth" class="header-container">
      <div class="header-container-column1">#</div>
      <div class="header-container-column2">Title</div>
      <div class="header-container-column3">Kind</div>
      <div class="header-container-column4">Size</div>
    </div>
    <draggable
      v-model="draggableAttachments"
      class="draggable"
      @start="startDrag"
      @end="endDrag"
      @update="updateDrag"
      :disabled="isDragAndDropDisabled"
      chosen-class="chosen"
    >
      <div v-for="(attachment, idx) in draggableAttachments" :key="attachment.id">
        <list-row
          :item="attachment"
          :columns="columns"
          :rowIndex="idx"
          :height="48"
          disableSelection
          @handleEmit="handleEmit"
          @click.native="handleRowClick($event, idx)"
        />
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';

import { PROJECT_DETAILS_INFO } from '@/graphql/queries/project';
import { PROJECT_MOVE_ATTACHMENT } from '@/graphql/mutations/project';
import { bus } from '@/utils/bus';
import { getGraphQlErrorCode } from '@/utils/functions/global';

export default {
  components: {
    draggable
  },
  props: {
    attachments: {
      type: Array,
      required: false,
      default: () => []
    },
    isFullWidth: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    attachments() {
      this.draggableAttachments = [...this.attachments];
    }
  },
  data() {
    return {
      isHoverId: null,
      rowExpanded: null,
      draggableAttachments: [...this.attachments],
      isLoadingId: null
    };
  },
  computed: {
    ...mapGetters(['isRenamingProjectAttachment']),
    columns() {
      return [
        {
          type: 'component',
          name: 'listKnob',
          path: 'listKnob',
          header: this.isFullWidth ? '#' : '',
          noBorder: !this.isFullWidth,
          isHover: !this.isFullWidth,
          min: this.isFullWidth ? '53px' : '24px',
          max: this.isFullWidth ? '53px' : '24px',
          centered: true,
          span: 1,
          props: [
            {
              name: 'order',
              match: this.isFullWidth ? 'position' : '',
              value: null
            },
            {
              name: 'trackId',
              match: 'id',
              value: null
            },
            {
              name: 'isLoadingId',
              match: 'isLoadingId',
              value: this.isLoadingId
            },
            {
              name: 'isDisabled',
              match: 'isDisabled',
              value: this.isDragAndDropDisabled
            },
            {
              name: 'isHoverId',
              match: 'isHoverId',
              value: this.isHoverId
            }
          ]
        },
        {
          type: 'component',
          name: 'fileThumbnail',
          path: 'fileThumbnail',
          min: '54px',
          max: '54px',
          noStop: true,
          header: this.isFullWidth ? 'Title' : '',
          props: [
            {
              name: 'imgSrc',
              match: 'imgSrc',
              value: null
            },
            {
              name: 'extension',
              match: 'extension',
              value: null
            }
          ]
        },
        {
          type: 'component',
          name: 'attachment-name',
          header: null,
          min: this.isFullWidth ? 'calc(100% - 100px)' : '248px',
          max: this.isFullWidth ? 'calc(100% - 100px)' : '248px',
          emits: [
            'downloadAttachment',
            'deleteAttachment',
            'renameAttachment',
            'setMoreDropdownState'
          ],
          noStop: true,
          containerWidth: true,
          padding: [0, 0, 0, 16],
          props: [
            {
              name: 'name',
              match: 'name',
              value: null
            },
            {
              name: 'extension',
              match: 'extension',
              value: null
            },
            {
              name: 'attachmentId',
              match: 'id',
              value: null
            },
            {
              name: 'type',
              match: 'type',
              value: null
            },
            {
              name: 'size',
              match: 'size',
              value: null
            },
            {
              name: 'hoverId',
              match: 'isHoverId',
              value: this.isHoverId
            },
            {
              name: 'isFullWidth',
              match: 'isFullWidth',
              value: this.isFullWidth
            },
            {
              name: 'rowExpanded',
              match: 'rowExpanded',
              value: this.rowExpanded
            },
            {
              name: 'downloadUrl',
              match: 'downloadUrl',
              value: null
            },
            {
              name: 'label',
              match: 'label',
              value: {
                rename: this.$t('rename'),
                download: this.$t('download'),
                delete: this.$t('delete')
              }
            }
          ]
        }
      ];
    },
    isDragAndDropDisabled() {
      return this.isRenamingProjectAttachment || this.rowExpanded !== null;
    }
  },
  methods: {
    handleRowClick(e, id) {
      // each column with type component ( except those with noStop ) do not redirect
      // on click, they have a 'stopbubble' atribute on them, and we verify here on
      // the click event if the path contain it
      const path = e.path || (e.composedPath && e.composedPath());
      const stopBubble = path.some(
        element => typeof element.getAttribute === 'function' && element.getAttribute('stopbubble')
      );

      if (stopBubble) {
        return;
      }
      this.$emit('openAttachmentsPreview', id);
    },
    handleEmit(params) {
      if (this[params.methodName]) this[params.methodName](params.param);
    },
    setIsHoverId(itemId) {
      this.isHoverId = itemId;
    },
    deleteAttachment(id) {
      this.$emit('deleteAttachments', id);
    },
    downloadAttachment(url) {
      this.$emit('downloadAttachments', url);
    },
    renameAttachment(newName) {
      this.$emit('renameAttachment', newName);
    },
    setMoreDropdownState({ id, isExpanded }) {
      if (isExpanded) {
        this.endDrag();
        this.rowExpanded = id;
      } else {
        this.rowExpanded = null;
      }
    },
    startDrag() {
      this.$store.commit('changeIsDraggingItemList', true);
    },
    endDrag() {
      this.$store.commit('changeIsDraggingItemList', false);
    },
    updateDrag(e) {
      const editableItems = [...this.draggableAttachments];
      this.isLoadingId = editableItems[e.newIndex].id;
      const position = e.newIndex + 1;
      this.$apollo
        .mutate({
          mutation: PROJECT_MOVE_ATTACHMENT,
          variables: {
            projectId: this.$route.params.id,
            attachmentId: editableItems[e.newIndex].id,
            position
          },
          refetchQueries: [
            {
              query: PROJECT_DETAILS_INFO,
              variables: {
                projectId: this.$route.params.id
              }
            }
          ]
        })
        .then(() => {
          this.isLoadingId = null;
        })
        .catch(error => {
          this.pendingStatus = false;
          this.isLoadingId = null;
          const errorKey = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key: errorKey },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.chosen {
  background: $color_neutral_0;
}
.header-container {
  display: inline-grid;
  grid-template-columns: 53px minmax(200px, 1fr) repeat(2, minmax(100px, 265px));
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid $color_neutral_30;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  color: $color_neutral_100;
  &-column1 {
    border-right: 1px solid $color_neutral_30;
    padding-left: 8px;
    display: flex;
    justify-content: center;
  }
  &-column2 {
    border-right: 1px solid $color_neutral_30;
    padding-left: 8px;
  }
  &-column3 {
    border-right: 1px solid $color_neutral_30;
    padding-left: 8px;
  }
  &-column4 {
    padding-left: 8px;
  }
}
</style>
