
<dropdown ref="dropdown">
  <template #button>
    <avatar :pictureUrl="getUserPicture" :initial="getUserInitials" class="avatar" />
  </template>
  <div class="menu">
    <div class="header">
      <avatar :pictureUrl="getUserPicture" :initial="getUserInitials" />
      <div class="header-metadata">
        <div class="name" v-if="getName.length > 0">{{ this.getName }}</div>
        <div class="email">{{ this.getEmail }}</div>
      </div>
    </div>
    <div
      class="menu-item"
      v-for="(option, index) in getOptions"
      :key="index"
      @click="option.action"
    >
      <i v-if="option.icon" :class="option.icon" class="menu-item-icon" />
      <span class="menu-item-label">{{ option.label }}</span>
    </div>
  </div>
</dropdown>
