<template>
  <div class="information-banner">
    <div class="icon-info"><i class="ri-information-fill"></i></div>
    <div class="text">
      {{ $t('emailBanner.core1') }} <span class="mail">{{ primaryEmail.email }}</span>
      {{ $t('emailBanner.core2') }}
      <span class="resend" @click="resendMail">{{ $t('emailBanner.sendAgain') }}</span>
    </div>
    <icon-button
      class="icon-close-mail"
      btnStyle="tertiary"
      icon="ri-close-fill"
      size="small"
      @submit="hide"
    />
  </div>
</template>

<script>
import { bus } from '@/utils/bus';
import { USER_EMAIL_RESEND_CONFIRMATION } from '@/graphql/mutations/user';
import { getGraphQlErrorCode } from '@/utils/functions/global';

export default {
  props: {
    mailInfo: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      primaryEmail: this.mailInfo.find(item => item.is_primary)
    };
  },
  methods: {
    resendMail() {
      this.$apollo
        .mutate({
          mutation: USER_EMAIL_RESEND_CONFIRMATION,
          variables: {
            id: this.primaryEmail.id
          }
        })
        .then(() => {
          this.$emit('hideEmailBanner', false);
          bus.$emit('showAlert', {
            message: 'verificationEmailSent',
            style: 'success',
            delay: 5000,
            error: false
          });
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    hide() {
      this.$emit('hideEmailBanner', true);
    }
  }
};
</script>

<style lang="scss" scoped>
.information-banner {
  box-sizing: border-box;
  height: fit-content;
  width: 100%;
  padding: 8px 16px 8px 40px;
  background-color: $color_primary_10;
  display: flex;
  align-items: center;
  @include body-1;
  color: $color_primary_100;
  .text {
    flex-grow: 1;
    .mail {
      font-weight: 600;
    }
    .resend {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .icon {
    &-info {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      background: rgba(71, 35, 173, 0.1);
      color: $color_primary_100;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-close {
      flex-shrink: 0;
    }
  }
}
</style>
<style lang="scss">
.icon-close-mail.rounded-button.tertiary.small {
  i {
    color: $color_primary_100;
  }
  &:hover {
    background: $color_primary_10;
    border: 1px solid $color_primary_100;
  }
}
</style>
