<template>
  <div>
    <div class="container">
      <p>{{ $t('modalUpgradeFreeTrial.line1', { dateEnd: getFreeTrialEndDate() }) }}</p>
      <p>
        {{ $t('modalUpgradeFreeTrial.line2') }}
      </p>
      <p>
        <b>{{ $t('modalUpgradeFreeTrial.line3') }}</b>
      </p>
    </div>
    <div class="buttons">
      <submit-button
        btnStyle="tertiary"
        size="medium"
        :label="$t('modalUpgradeFreeTrial.cancelButton')"
        @submit="closeModal"
      />
      <submit-button
        btnStyle="primary"
        size="medium"
        :label="$t('modalUpgradeFreeTrial.confirmButton')"
        @submit="upgradePlan"
      />
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';

import { bus } from '@/utils/bus';
import { get as getRoute } from '@/router/routes';

export default {
  props: {
    freeTrialEndTimestamp: {
      type: Number,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {
    getFreeTrialEndDate() {
      const endDate = new Date(this.freeTrialEndTimestamp);
      return format(endDate, 'PPP');
    },
    closeModal() {
      this.$emit('closeModal');
      this.$store.commit('setSelectedPlan', undefined);
      bus.$emit('showAlert', {
        message: { key: 'subscriptionSuccessful' },
        style: 'success',
        delay: 5000
      });
    },
    upgradePlan() {
      this.$emit('closeModal');
      this.$router.push(getRoute('paymentSettings'));
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  padding: 24px;
  p {
    @include body-1;
    margin-bottom: 16px;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid $color_neutral_30;
  padding: 8px 24px;
  button:first-child {
    margin-right: 8px;
  }
}
</style>
