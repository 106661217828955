import gql from 'graphql-tag';

export const INBOX_LIST = gql`
  query InboxList($workspaceId: ID!) {
    InboxList(workspaceId: $workspaceId) {
      id
      path
      url
    }
  }
`;

export const INBOX_LIST_SUBMISSIONS = gql`
  query InboxList($workspaceId: ID!, $page: Int! = 1, $limit: Int! = 20) {
    InboxList(workspaceId: $workspaceId) {
      id
      submissions(page: $page, limit: $limit) {
        id
        share_id
        submitter_workspace_id
        topic
        message
        created_at
        read_at
        submitter_user_id
        submitter {
          lastname
          firstname
          email
          profile_picture {
            id
            url
          }
        }
        tracks {
          id
        }
      }
      counters {
        submissions_total
        submissions_unread
      }
    }
  }
`;

export const INBOX_LIST_SUBMISSIONS_UNREAD = gql`
  query InboxList($workspaceId: ID!) {
    InboxList(workspaceId: $workspaceId) {
      id
      counters {
        submissions_total
        submissions_unread
      }
    }
  }
`;

export const INBOX_SUBMISSION = gql`
  query InboxSubmission($inboxId: ID, $submissionId: ID!) {
    InboxSubmission(inboxId: $inboxId, submissionId: $submissionId) {
      id
      share_id
      submitter_workspace_id
      message
      topic
      tracks {
        id
        position
        library_file_id
        details {
          library_file_id
          title
          artistNames
          supportName
          track_length
          cover_url
          status_id3_extracted
          status_streamable_transcoded
        }
      }
    }
  }
`;

export const INBOX_SUBMISSION_LIGHT = gql`
  query InboxSubmission($inboxId: ID, $submissionId: ID!) {
    InboxSubmission(inboxId: $inboxId, submissionId: $submissionId) {
      id
      message
      topic
      tracks {
        id
        library_file_id
      }
    }
  }
`;

export const INBOX_SUBMISSIONS_TRACK = gql`
  query InboxSubmissionTrack($inboxTrackId: ID!) {
    InboxSubmissionTrack(inboxTrackId: $inboxTrackId) {
      libraryFileDetails {
        id
        title
        isrc
        iswc
        bpm
        length
        duration
        rating
        language {
          iso_code
        }
        recording_date
        supports {
          id
          name
          release_date
          type
          tracks_total
          upc
          images {
            type
            url
            file_name
            thumbnails {
              type
              url
              width
              height
            }
          }
          artists {
            id
            name
          }
          track_position
        }
        artists {
          id
          name
        }
        authors {
          id
          name
        }
        composers {
          id
          name
        }
        labels {
          id
          name
        }
        publishers {
          id
          name
        }
        right_holders {
          id
          name
          role
          publishing_percentage
          mastering_percentage
        }
        tags {
          id
          tag_context_id
          category_id
          family_id
          tag_id
        }
        lyrics
        comment
        file_information {
          id
          original_name
          additional_media {
            format
            size
            bit_rate
          }
        }
        genre
      }
    }
  }
`;

export const INBOX_DOWNLOAD_ASSET = gql`
  query InboxDownloadAsset($submissionTrackId: ID!) {
    InboxDownloadAsset(submissionTrackId: $submissionTrackId) {
      url
      expire_at
    }
  }
`;
