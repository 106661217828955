import get from 'lodash.get';
import * as Sentry from '@sentry/browser';
import { bus } from '@/utils/bus';
import * as tus from 'tus-js-client';
import {
  PROJECT_ADD_ATTACHMENTS,
  PROJECT_INITIALIZE_ATTACHMENT_UPLOAD
} from '@/graphql/mutations/project';
import {
  LIBRARY_INITIALIZE_TRACK_UPLOAD,
  ADD_LIBRARY_FILE,
  ARTIST_IMAGE_UPDATE,
  ARTIST_INITIALIZE_IMAGE_UPLOAD,
  SUPPORT_INITIALIZE_IMAGE_UPLOAD,
  SUPPORT_IMAGE_UPDATE
} from '@/graphql/mutations/library';

import { getGraphQlErrorCode, getGraphQlErrorData } from '@/utils/functions/global';
import { PROJECT_DETAILS_INFO } from '@/graphql/queries/project';
import { ARTIST_DETAILS, SUPPORT_DETAILS, LIBRARY_FILES_DETAILS } from '@/graphql/queries/library';
import {
  WORKSPACE_LOGO_UPDATE,
  WORKSPACE_INITIALIZE_LOGO_UPLOAD
} from '@/graphql/mutations/workspace';
import {
  USER_PROFILE_PICTURE_UPDATE,
  USER_INITIALIZE_PROFILE_PICTURE_UPLOAD
} from '@/graphql/mutations/user';

import { WORKSPACE, ME } from '@/graphql/queries/user';

import { trackUploadClickEvent } from '@/utils/functions/analytics';

import { UPLOAD_TYPE } from '@/store/upload';

const displayErrorAlert = (message, args, params) => {
  bus.$emit('showAlert', {
    message,
    args,
    params,
    style: 'danger',
    delay: 5000
  });
};

export const openUploadFileInput = (options, onChange) => {
  trackUploadClickEvent(`${options.isFolder ? 'Folder' : 'File'} Upload Click`);
  const input = document.createElement('input');
  if (options.isFolder) {
    input.setAttribute('webkitdirectory', 'webkitdirectory');
    input.setAttribute('directory', 'directory');
  } else {
    input.setAttribute('accept', options.allowedMimeTypes);
  }
  input.setAttribute('id', `file-selector-${options.id}`);
  input.setAttribute('type', 'file');
  input.setAttribute('multiple', 'multiple');
  input.addEventListener('change', e => {
    onChange(Array.from(e.target.files));
  });
  return input.click();
};

export const getFileId = file => {
  return `${file.name.replace(' ', '-')}_${file.lastModified}`;
};

const updateFileType = file => {
  const extension = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase();
  const dupe = {
    name: file.name,
    size: file.size,
    type: file.type,
    lastModified: file.lastModified,
    lastModifiedDate: file.lastModifiedDate
  };
  if (extension === 'txt') {
    dupe.type = 'text/plain';
    return dupe;
  }
  if (extension === 'aac') {
    dupe.type = 'audio/aac';
    return dupe;
  }
  if (extension === 'aif' || extension === 'aiff' || extension === 'aifc') {
    dupe.type = 'audio/x-aiff';
    return dupe;
  }
  if (extension === 'mp2' || extension === 'mp3') {
    dupe.type = 'audio/mpeg';
    return dupe;
  }
  if (extension === 'mov') {
    dupe.type = 'video/quicktime';
    return dupe;
  }
  if (extension === 'docx') {
    dupe.type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    return dupe;
  }
  if (extension === 'doc') {
    dupe.type = 'application/msword';
    return dupe;
  }
  if (extension === 'jpg' || extension === 'jpeg') {
    dupe.type = 'image/jpeg';
    return dupe;
  }
  if (extension === 'png') {
    dupe.type = 'image/png';
    return dupe;
  }
  if (extension === 'pdf') {
    dupe.type = 'application/pdf';
    return dupe;
  }
  if (extension === 'mid' || extension === 'midi') {
    dupe.type = 'audio/midi';
    return dupe;
  }
  if (
    extension === 'flp' ||
    extension === 'ptxt' ||
    extension === 'amxd' ||
    extension === 'mtm' ||
    extension === 'asd' ||
    extension === 'wma' ||
    extension === 'plist'
  ) {
    dupe.type = 'application/octet-stream';
    return dupe;
  }
  if (extension === 'opus') {
    dupe.type = 'audio/ogg';
    return dupe;
  }
  if (extension === 'alc' || extension === 'als') {
    dupe.type = 'application/gzip';
    return dupe;
  }
  if (extension === 'aup') {
    dupe.type = 'text/xml';
    return dupe;
  }
  if (extension === 'adm' || extension === 'bwf') {
    dupe.type = 'audio/x-wav';
    return dupe;
  }
  return file;
};

export const checkFilesHaveExtension = (that, files) => {
  const config = that.$config;
  const DEFAULT_UPLOAD_CONFIG = {
    mimetypes: [],
    extensions: []
  };

  const documentsUploadConfig = get(
    config,
    'uploads.project.attachment.allowed_files.documents',
    DEFAULT_UPLOAD_CONFIG
  );
  const imagesUploadConfig = get(
    config,
    'uploads.project.attachment.allowed_files.images',
    DEFAULT_UPLOAD_CONFIG
  );
  const videosUploadConfig = get(
    config,
    'uploads.project.attachment.allowed_files.videos',
    DEFAULT_UPLOAD_CONFIG
  );
  const musicUploadConfig = get(
    config,
    'uploads.project.attachment.allowed_files.music',
    DEFAULT_UPLOAD_CONFIG
  );
  // verification needed for chrome due to a bug with drag&dropped mov, doc and docx files
  // BRID-4090 + BRID-4171
  const filesMap = files.map(file => {
    return updateFileType(file);
  });

  return filesMap.every(el => {
    const extension = el.name.substr(el.name.lastIndexOf('.') + 1).toLowerCase();
    if (
      (documentsUploadConfig.mimetypes.includes(el.type) &&
        documentsUploadConfig.extensions.includes(extension)) ||
      (imagesUploadConfig.mimetypes.includes(el.type) &&
        imagesUploadConfig.extensions.includes(extension)) ||
      (videosUploadConfig.mimetypes.includes(el.type) &&
        videosUploadConfig.extensions.includes(extension)) ||
      (musicUploadConfig.mimetypes.includes(el.type) &&
        musicUploadConfig.extensions.includes(extension))
    ) {
      return true;
    }
    return false;
  });
};

export const checkFilesHaveValidSize = (that, files) => {
  const config = that.$config;
  const DEFAULT_UPLOAD_CONFIG = {
    mimetypes: [],
    extensions: []
  };

  const documentsUploadConfig = get(
    config,
    'uploads.project.attachment.allowed_files.documents',
    DEFAULT_UPLOAD_CONFIG
  );
  const imagesUploadConfig = get(
    config,
    'uploads.project.attachment.allowed_files.images',
    DEFAULT_UPLOAD_CONFIG
  );

  const videosUploadConfig = get(
    config,
    'uploads.project.attachment.allowed_files.videos',
    DEFAULT_UPLOAD_CONFIG
  );

  const musicUploadConfig = get(
    config,
    'uploads.project.attachment.allowed_files.music',
    DEFAULT_UPLOAD_CONFIG
  );
  let invalidInfo;
  files.some(el => {
    const extension = el.name.substr(el.name.lastIndexOf('.') + 1);
    if (
      documentsUploadConfig.mimetypes.includes(el.type) ||
      documentsUploadConfig.extensions.includes(extension)
    ) {
      const maxDocumentFileSize = parseInt(documentsUploadConfig.max_file_size, 10);
      if (el.size > maxDocumentFileSize) {
        invalidInfo = { size: maxDocumentFileSize / 1024 / 1024, filename: el.name };
        return true;
      }
    }
    if (
      imagesUploadConfig.mimetypes.includes(el.type) ||
      imagesUploadConfig.extensions.includes(extension)
    ) {
      const maxImageFileSize = parseInt(imagesUploadConfig.max_file_size, 10);
      if (el.size > maxImageFileSize) {
        invalidInfo = { size: maxImageFileSize / 1024 / 1024, filename: el.name };
        return true;
      }
    }
    if (
      videosUploadConfig.mimetypes.includes(el.type) ||
      videosUploadConfig.extensions.includes(extension)
    ) {
      const maxVideoFileSize = parseInt(videosUploadConfig.max_file_size, 10);
      if (el.size > maxVideoFileSize) {
        invalidInfo = { size: maxVideoFileSize / 1024 / 1024, filename: el.name };
        return true;
      }
    }
    if (
      musicUploadConfig.mimetypes.includes(el.type) ||
      musicUploadConfig.extensions.includes(extension)
    ) {
      const maxMusicFileSize = parseInt(musicUploadConfig.max_file_size, 10);
      if (el.size > maxMusicFileSize) {
        invalidInfo = { size: maxMusicFileSize / 1024 / 1024, filename: el.name };
        return true;
      }
    }
    return false;
  });
  return invalidInfo;
};

export const isWorkspaceStorageFull = (files, workspace) => {
  let sizeIncoming = 0;
  files.forEach(el => {
    sizeIncoming += el.size;
  });
  const availableSpaceInWorkspace =
    workspace.subscription.storage * 1000000 - parseInt(workspace.space_used, 10);
  return sizeIncoming > availableSpaceInWorkspace;
};

export const handleAddAttachmentsMutationV2 = async (that, files, projectId) => {
  const uploadIdsArray = [];
  const promises = [];

  files.forEach(file => {
    const updatedFile = updateFileType(file);
    const promise = new Promise((resolve, reject) => {
      const upload = new tus.Upload(file, {
        retryDelays: [0, 3000, 5000, 10000, 20000],
        removeFingerprintOnSuccess: true,
        fingerprint: async () => {
          return ['tus', file.name, file.type, file.size, file.lastModified, projectId].join('-');
        },
        onError(error) {
          reject(error);
        },
        onProgress(bytesUploaded, bytesTotal) {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          that.$store.commit('setAttachmentUploadProgress', {
            id: getFileId(file),
            progress: parseInt(percentage, 10)
          });
        },
        onSuccess() {
          resolve();
        }
      });
      // Check if there are any previous uploads to continue.
      upload.findPreviousUploads().then(previousUploads => {
        const threshold = new Date();
        threshold.setTime(threshold.getTime() - 12 * 60 * 60 * 1000);
        const validPreviousUploads = previousUploads.filter(item => {
          const created = Date.parse(item.creationTime);
          return created > threshold;
        });
        if (validPreviousUploads.length) {
          upload.resumeFromPreviousUpload(validPreviousUploads[0]);
          uploadIdsArray.push(validPreviousUploads[0].metadata.id);
          upload.start();
        } else {
          that.$apollo
            .mutate({
              mutation: PROJECT_INITIALIZE_ATTACHMENT_UPLOAD,
              variables: {
                project_id: projectId,
                file_name: updatedFile.name,
                size: `${updatedFile.size}`,
                mime_type: updatedFile.type
              }
            })
            .then(response => {
              const url = get(response, 'data.ProjectInitializeAttachmentUpload.url');
              const id = get(response, 'data.ProjectInitializeAttachmentUpload.upload_id');
              if (url && id) {
                upload.url = url;
                upload.options.metadata.id = id;
                uploadIdsArray.push(id);
                upload.start();
              } else {
                reject(new Error('Invalid upload initialization data', response));
              }
            })
            .catch(err => {
              const key = getGraphQlErrorCode(err);
              if (key === 'attachment_limit_per_project') {
                displayErrorAlert({
                  key: 'addAttachmentFail',
                  args: { count: get(that, '$config.uploads.project.attachment.max_files', 50) }
                });
              } else {
                displayErrorAlert({ key });
              }
              that.$store.commit('cleanAttachmentUpload');
            });
        }
      });
    });
    promises.push(promise);
  });

  return Promise.all(promises).then(() => {
    return { projectId, uploadIdsArray };
  });
};

export const handleAddAttachmentsMutation = async (
  that,
  files,
  projectId,
  workspace,
  onProgress
) => {
  if (checkFilesHaveExtension(that, files) === false) {
    displayErrorAlert({ key: 'invalidFile' });
    return Promise.reject(new Error('invalidFile'));
  }
  const hasFileSizeError = checkFilesHaveValidSize(that, files);
  if (hasFileSizeError !== undefined) {
    displayErrorAlert({
      key: 'attachmentTooLarge',
      args: hasFileSizeError
    });
    return Promise.reject(new Error('attachmentTooLarge'));
  }
  if (isWorkspaceStorageFull(files, workspace)) {
    displayErrorAlert({ key: 'fullStorage' });
    return Promise.reject(new Error('fullStorage'));
  }
  const isUploadV2Enabled = true; // localStorage.getItem('UPLOAD') === '2';
  files.forEach(file => {
    that.$store.commit('setNewAttachmentUpload', {
      id: getFileId(file),
      progress: isUploadV2Enabled ? 0 : -1,
      name: file.name,
      size: file.size
    });
  });
  return handleAddAttachmentsMutationV2(that, files, projectId, onProgress).then(data => {
    that.$apollo
      .mutate({
        mutation: PROJECT_ADD_ATTACHMENTS,
        variables: {
          projectId,
          uploadIds: data.uploadIdsArray
        },
        refetchQueries: [
          {
            query: PROJECT_DETAILS_INFO,
            variables: {
              projectId
            }
          }
        ]
      })
      .then(() => {
        // The timeout is here to give some time for the backend to process uplaoded files
        setTimeout(() => {
          that.$apollo.query({
            query: WORKSPACE,
            fetchPolicy: 'network-only',
            variables: {
              workspaceId: workspace.id
            }
          });
        }, 500);
        that.$store.commit('cleanAttachmentUpload');
      });
  });
};

export const handleAddTracksMutation = async (that, file, libraryId) => {
  const updatedFile = updateFileType(file.file);
  const promise = new Promise((resolve, reject) => {
    that.$store.commit('setUploadAsUploading', {
      type: UPLOAD_TYPE.TRACK,
      id: getFileId(file.file)
    });
    let UPLOAD_ID;
    const upload = new tus.Upload(file.file, {
      retryDelays: [0, 3000, 5000, 10000, 20000],
      removeFingerprintOnSuccess: true,
      fingerprint: async () => {
        return ['tus', updatedFile.name, updatedFile.type, updatedFile.size].join('-');
      },
      onError(error) {
        reject(error);
      },
      onProgress(bytesUploaded, bytesTotal) {
        const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        that.$store.commit('setUploadProgress', {
          type: UPLOAD_TYPE.TRACK,
          id: getFileId(file.file),
          progress: parseInt(percentage, 10)
        });
      },
      onSuccess() {
        that.$store.commit('setUploadAsAnalyzing', {
          type: UPLOAD_TYPE.TRACK,
          id: getFileId(file.file)
        });
        resolve({
          uploadId: UPLOAD_ID
        });
      }
    });
    // Check if there are any previous uploads to continue.
    upload.findPreviousUploads().then(previousUploads => {
      const threshold = new Date();
      threshold.setTime(threshold.getTime() - 12 * 60 * 60 * 1000);
      const validPreviousUploads = previousUploads.filter(item => {
        const created = Date.parse(item.creationTime);
        return created > threshold;
      });
      if (validPreviousUploads.length) {
        upload.resumeFromPreviousUpload(validPreviousUploads[0]);
        // Don't know why we need to do that but if we don't it fail
        UPLOAD_ID = validPreviousUploads[0].metadata.id;
        upload.start();
      } else {
        that.$apollo
          .mutate({
            mutation: LIBRARY_INITIALIZE_TRACK_UPLOAD,
            variables: {
              library_id: libraryId,
              file_name: updatedFile.name,
              size: `${updatedFile.size}`,
              mime_type: updatedFile.type
            }
          })
          .then(response => {
            const url = get(response, 'data.LibraryInitializeTrackUpload.url');
            that.$store.commit('saveUploadUrl', { url, id: getFileId(file.file) });
            const id = get(response, 'data.LibraryInitializeTrackUpload.upload_id');
            if (url && id) {
              upload.url = url;
              upload.options.metadata.id = id;
              UPLOAD_ID = id;
              upload.start();
            } else {
              reject(new Error('Invalid upload initialization data', response));
            }
          })
          .catch(err => {
            Sentry.captureException(err);
            that.$store.commit('setUploadAsError', {
              type: UPLOAD_TYPE.TRACK,
              id: getFileId(file.file),
              message: 'File was not uploaded, please try again or contact us'
            });
            reject();
          });
      }
    });
  }).then(res => {
    that.$apollo
      .mutate({
        mutation: ADD_LIBRARY_FILE,
        variables: {
          library_id: `${libraryId}`,
          upload_id: res.uploadId
        }
      })
      .then(addLibraryFileResponse => {
        const libraryFileId = get(addLibraryFileResponse, 'data.AddLibraryFile.id');
        that.$store.commit('setUploadAsDone', {
          type: UPLOAD_TYPE.TRACK,
          id: getFileId(file.file),
          libraryFileId
        });
        that.$apollo.query({
          query: WORKSPACE,
          fetchPolicy: 'network-only',
          variables: {
            workspaceId: that.currentWorkspace.id
          }
        });
        if (file.droppedProjectId) {
          const params = {
            projectId: file.droppedProjectId,
            libraryFilesIds: [libraryFileId],
            noAlert: true
          };
          bus.$emit('addTracksToProject', params);
        }
      })
      .catch(err => {
        const message = getGraphQlErrorCode(err);
        if (message === 'library_file_unique') {
          that.$store.commit('setUploadAsDone', {
            type: UPLOAD_TYPE.TRACK,
            id: getFileId(file.file),
            message: that.$t(`errors.${message}`)
          });
          if (file.droppedProjectId) {
            const errorData = getGraphQlErrorData({ err }.err);
            const params = {
              libraryFilesIds: [errorData.library_file_id],
              projectId: file.droppedProjectId,
              noAlert: true
            };
            bus.$emit('addTracksToProject', params);
          }
        } else {
          that.$store.commit('setUploadAsError', {
            type: UPLOAD_TYPE.TRACK,
            id: getFileId(file.file),
            message: 'An error occurred in the upload. Please try again'
          });
        }
      });
  });
  return promise;
};

export const handleArtistImageUpload = async (that, file, workspaceId, artistId) => {
  const updatedFile = updateFileType(file);
  const promise = new Promise((resolve, reject) => {
    let UPLOAD_ID;
    const upload = new tus.Upload(file, {
      retryDelays: [0, 3000, 5000, 10000, 20000],
      removeFingerprintOnSuccess: true,
      fingerprint: async () => {
        return ['tus', updatedFile.name, updatedFile.type, updatedFile.size].join('-');
      },
      onError(error) {
        reject(error);
      },
      onSuccess() {
        resolve({
          uploadId: UPLOAD_ID
        });
      }
    });
    that.$apollo
      .mutate({
        mutation: ARTIST_INITIALIZE_IMAGE_UPLOAD,
        variables: {
          workspace_id: workspaceId,
          artist_id: artistId,
          file_name: updatedFile.name,
          size: `${updatedFile.size}`,
          mime_type: updatedFile.type
        }
      })
      .then(response => {
        const url = get(response, 'data.ArtistInitializeImageUpload.url');
        const id = get(response, 'data.ArtistInitializeImageUpload.upload_id');
        if (url && id) {
          upload.url = url;
          upload.options.metadata.id = id;
          UPLOAD_ID = id;
          upload.start();
        } else {
          reject(new Error('Invalid upload initialization data', response));
        }
      })
      .catch(er => {
        reject(er);
      });
  }).then(res => {
    that.$apollo
      .mutate({
        mutation: ARTIST_IMAGE_UPDATE,
        variables: {
          artist_id: artistId,
          workspace_id: `${workspaceId}`,
          upload_id: res.uploadId
        },
        refetchQueries: [
          {
            query: ARTIST_DETAILS,
            variables: {
              libraryId: that.currentWorkspace.libraries[0].id,
              artistId
            }
          }
        ]
      })
      .catch(err => {
        const key = getGraphQlErrorCode(err);
        bus.$emit('showAlert', {
          message: { key },
          style: 'danger',
          delay: 5000,
          error: true
        });
      });
  });
  return promise;
};

export const handleUserProfilePictureUpload = async (that, file) => {
  const updatedFile = updateFileType(file);
  const promise = new Promise((resolve, reject) => {
    let UPLOAD_ID;
    const upload = new tus.Upload(file, {
      retryDelays: [0, 3000, 5000, 10000, 20000],
      removeFingerprintOnSuccess: true,
      fingerprint: async () => {
        return ['tus', updatedFile.name, updatedFile.type, updatedFile.size].join('-');
      },
      onError(error) {
        reject(error);
      },
      onSuccess() {
        resolve({
          uploadId: UPLOAD_ID
        });
      }
    });
    that.$apollo
      .mutate({
        mutation: USER_INITIALIZE_PROFILE_PICTURE_UPLOAD,
        variables: {
          file_name: updatedFile.name,
          size: `${updatedFile.size}`,
          mime_type: updatedFile.type
        }
      })
      .then(response => {
        const url = get(response, 'data.UserInitializeProfilePictureUpload.url');
        const id = get(response, 'data.UserInitializeProfilePictureUpload.upload_id');
        if (url && id) {
          upload.url = url;
          upload.options.metadata.id = id;
          UPLOAD_ID = id;
          upload.start();
        } else {
          reject(new Error('Invalid upload initialization data', response));
        }
      })
      .catch(er => {
        reject(er);
      });
  }).then(res => {
    that.$apollo
      .mutate({
        mutation: USER_PROFILE_PICTURE_UPDATE,
        variables: {
          upload_id: res.uploadId
        }
      })
      .then(() => {
        bus.$emit('showAlert', {
          message: { key: 'modificationSuccess' },
          style: 'success',
          delay: 5000
        });
      })
      .catch(err => {
        const key = getGraphQlErrorCode(err);
        bus.$emit('showAlert', {
          message: { key },
          style: 'danger',
          delay: 5000,
          error: true
        });
      });
  });
  return promise;
};

export const handleWorkspaceLogoUpload = async (that, file, workspaceId) => {
  const updatedFile = updateFileType(file);
  const promise = new Promise((resolve, reject) => {
    let UPLOAD_ID;
    const upload = new tus.Upload(file, {
      retryDelays: [0, 3000, 5000, 10000, 20000],
      removeFingerprintOnSuccess: true,
      fingerprint: async () => {
        return ['tus', updatedFile.name, updatedFile.type, updatedFile.size].join('-');
      },
      onError(error) {
        reject(error);
      },
      onSuccess() {
        resolve({
          uploadId: UPLOAD_ID
        });
      }
    });
    that.$apollo
      .mutate({
        mutation: WORKSPACE_INITIALIZE_LOGO_UPLOAD,
        variables: {
          workspace_id: workspaceId,
          file_name: updatedFile.name,
          size: `${updatedFile.size}`,
          mime_type: updatedFile.type
        }
      })
      .then(response => {
        const url = get(response, 'data.WorkspaceInitializeLogoUpload.url');
        const id = get(response, 'data.WorkspaceInitializeLogoUpload.upload_id');
        if (url && id) {
          upload.url = url;
          upload.options.metadata.id = id;
          UPLOAD_ID = id;
          upload.start();
        } else {
          reject(new Error('Invalid upload initialization data', response));
        }
      })
      .catch(er => {
        reject(er);
      });
  }).then(res => {
    that.$apollo
      .mutate({
        mutation: WORKSPACE_LOGO_UPDATE,
        variables: {
          workspace_id: `${workspaceId}`,
          upload_id: res.uploadId
        },
        update: (store, { data: { WorkspaceLogoUpdate } }) => {
          const data = store.readQuery({ query: ME });
          const workspaceIndex = data.me.workspaces.findIndex(w => w.id === workspaceId);
          data.me.workspaces[workspaceIndex].logo = { ...WorkspaceLogoUpdate.logo };
          store.writeQuery({ query: ME, data });
        }
      })
      .then(() => {
        bus.$emit('showAlert', {
          message: { key: 'modificationSuccess' },
          style: 'success',
          delay: 5000
        });
      })
      .catch(err => {
        const key = getGraphQlErrorCode(err);
        bus.$emit('showAlert', {
          message: { key },
          style: 'danger',
          delay: 5000,
          error: true
        });
      });
  });
  return promise;
};

export const handleSupportImageUpload = async (that, file, supportId, libraryFilesIds) => {
  const updatedFile = updateFileType(file);
  const promise = new Promise((resolve, reject) => {
    let UPLOAD_ID;
    const upload = new tus.Upload(file, {
      retryDelays: [0, 3000, 5000, 10000, 20000],
      removeFingerprintOnSuccess: true,
      fingerprint: async () => {
        return ['tus', updatedFile.name, updatedFile.type, updatedFile.size].join('-');
      },
      onError(error) {
        reject(error);
      },
      onSuccess() {
        resolve({
          uploadId: UPLOAD_ID
        });
      }
    });
    that.$apollo
      .mutate({
        mutation: SUPPORT_INITIALIZE_IMAGE_UPLOAD,
        variables: {
          supportId,
          type: 'front_cover',
          file_name: updatedFile.name,
          size: `${updatedFile.size}`,
          mime_type: updatedFile.type
        }
      })
      .then(response => {
        const url = get(response, 'data.SupportInitializeImageUpload.url');
        const id = get(response, 'data.SupportInitializeImageUpload.upload_id');
        if (url && id) {
          upload.url = url;
          upload.options.metadata.id = id;
          UPLOAD_ID = id;
          upload.start();
        } else {
          reject(new Error('Invalid upload initialization data', response));
        }
      })
      .catch(er => {
        reject(er);
      });
  }).then(res => {
    that.$apollo
      .mutate({
        mutation: SUPPORT_IMAGE_UPDATE,
        variables: {
          supportId: `${supportId}`,
          uploadId: res.uploadId,
          type: 'front_cover'
        },
        refetchQueries: [
          {
            query: SUPPORT_DETAILS,
            variables: {
              libraryId: that.currentWorkspace.libraries[0].id,
              supportId
            }
          },
          libraryFilesIds
            ? {
                query: LIBRARY_FILES_DETAILS,
                variables: {
                  libraryFilesIds
                }
              }
            : null
        ].filter(Boolean)
      })
      .then(() => {
        bus.$emit('showAlert', {
          message: { key: 'modificationSuccess' },
          style: 'success',
          delay: 5000
        });
      })
      .catch(err => {
        const key = getGraphQlErrorCode(err);
        bus.$emit('showAlert', {
          message: { key },
          style: 'danger',
          delay: 5000,
          error: true
        });
      });
  });
  return promise;
};
