
<div :class="switchButtonClass" @click="toggleButton">
  <div :class="leftLabelClass" v-if="leftLabel && !labelInactive">{{ leftLabel }}</div>
  <div class="switch-button-toggle">
    <div :class="thumbClass" />
    <div :class="bkgClass" />
  </div>
  <div :class="labelClass" v-if="on || !labelInactive">{{ label }}</div>
  <div :class="labelClass" v-if="!on && labelInactive">{{ labelInactive }}</div>
</div>
