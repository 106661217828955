
<div
  class="container"
  :class="{ success: success, 'no-border': !hasBorder }"
  :style="`width: ${label.width}px;`"
  @click="copy"
>
  <i :class="label.icon" />
  <div>{{ txt }}</div>
</div>
