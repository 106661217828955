
<div class="container">
  <div :class="`row level${level}`" @click="toggleExpand">
    <div class="title">
      <div v-if="dotColor" class="dots" :style="`background: ${dotColor}`" />
      <span>{{ title }}</span>
      <span class="subtitle" v-if="subtitle">{{ subtitle }}</span>
    </div>
    <i :class="{ 'ri-arrow-down-s-fill': expanded, 'ri-arrow-right-s-fill': !expanded }" />
  </div>
  <transition name="fade">
    <div>
      <slot v-if="expanded"></slot>
    </div>
  </transition>
</div>
