
<div class="sharings" v-if="ShareList">
  <div v-if="displayPreview" class="preview-share" @click="togglePreview(false)">
    <div class="close-btn">
      <icon-button icon="ri-close-line" btnStyle="secondary" size="large" />
    </div>
    <div class="iframe-container">
      <iframe @click.stop class="iframe" :src="previewUrl" frameborder="0"></iframe>
    </div>
  </div>
  <table v-if="sharings.length > 0" class="sharings-content" data-test="sharingsList">
    <thead class="sharings-content-header">
      <tr class="sharings-content-header-row">
        <th
          v-for="(column, id) in thList"
          :key="`${id}-th`"
          class="sharings-content-header-left"
          :class="column.class"
          :title="$t(column.title)"
        >
          {{ $t(column.name) }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="sharings-content-row"
        :class="[sharing.isNew ? 'new-link' : '']"
        v-for="(sharing, index) in sharings"
        :key="`sharing-${sharing.id}`"
        @mouseover="setHoverId(index)"
        @mouseleave="setHoverId(null)"
        :title="getTitle(sharing)"
      >
        <td
          :class="sharing.is_public ? 'sharings-content-row-link' : 'sharings-content-row-email'"
        >
          <sharing-name
            :name="sharing.email || sharing.custom_name"
            :shareType="getShareType(sharing)"
            :hasPassword="sharing.settings.access_password !== null"
            :expireIn="getShareExpireIn(sharing)"
            :isNew="sharing.isNew"
            :shareDesactivated="
              !sharingEnables.find(s => s[sharing.id] !== undefined)[sharing.id]
            "
          />
        </td>
        <td
          class="sharings-content-row-checkbox"
          v-for="accessRight in accessRights"
          :key="`${accessRight}-${index}`"
        >
          <div class="sharings-content-row-center">
            <checkbox
              :index="0"
              :id="`${accessRight}-${index}`"
              :isChecked="
                sharingAccessRights.find(s => s[sharing.id])[sharing.id].includes(accessRight)
              "
              @toggleCheckbox="configureShare(sharing.id, accessRight)"
            />
          </div>
        </td>
        <td class="sharings-content-row-checkbox">
          <div class="sharings-content-row-center">
            <switch-button
              :defaultValue="sharingEnables.find(s => s[sharing.id] !== undefined)[sharing.id]"
              @toggleSwitchButton="configureEnable(sharing.id, ...arguments)"
            />
          </div>
        </td>
        <td class="sharings-content-row-checkbox">
          <div class="link-column">
            <copy-btn @copyLink="copyLink(sharing)" class="link-column-mr" />
            <icon-button
              class="link-column-iconbtn link-column-mr"
              icon="ri-eye-line"
              btnStyle="tertiary"
              size="small"
              :disabled="!sharingEnables.find(s => s[sharing.id] !== undefined)[sharing.id]"
              @submit="navigateToPreview(sharing)"
            />
            <icon-button
              class="link-column-iconbtn"
              icon="ri-settings-3-line"
              btnStyle="tertiary"
              size="small"
              @submit="openShareSettingsModal(sharing)"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div v-if="sharings.length < 1" class="no-share">
    <div v-if="entityType === 'project'">
      <empty-sharing
        :title="$t('sharingEmptyTitle')"
        :description="$t('sharingEmptyHelper')"
        placeholderType="sharing"
        :cloudedTextContent="$t('shareProject')"
        :cloudedTextColor="{
          stroke: '#FF9143',
          fill: '#FFF9EB'
        }"
      >
        <template v-slot:button>
          <btn-dropdown
            class="upload-to-library-btn"
            :options="shareBtnOptions"
            :btnProps="{
              style: 'primary',
              size: 'medium',
              label: 'share',
              hideDropdown: true,
              icon: 'ri-share-forward-fill'
            }"
            @action="openShareModal"
          />
        </template>
      </empty-sharing>
    </div>
    <div v-else class="no-share-container" data-test="no-share-container">
      <div class="no-share-icon">
        <i class="ri-group-line"></i>
      </div>
      <div class="no-share-title">
        {{ $t('noShareTitle', { type: typeName }) }}
      </div>
      <div class="no-share-subtitle">
        {{ $t('noShareSubtitle', { type: typeName }) }}
      </div>
      <btn-dropdown
        class="upload-to-library-btn"
        :options="shareBtnOptions"
        :btnProps="{
          style: 'primary',
          size: 'medium',
          label: 'share',
          hideDropdown: true,
          icon: 'ri-share-forward-fill'
        }"
        @action="openShareModal"
      />
    </div>
  </div>
</div>
<div v-else class="loader"><spinner-without-progress color="grey" :size="32" /></div>
