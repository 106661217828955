<template>
  <div class="row">
    <div class="row-body" :style="gridTemplateColumns">
      <div
        v-for="(column, idx) in filteredColumns"
        :class="{
          'row-body-cell': true,
          'row-body-no-border': column.noBorder,
          'row-body-centered': column.centered
        }"
        :style="[
          { height: `${height}px` },
          {
            padding: column.padding
              ? `${column.padding[0]}px ${column.padding[1]}px ${column.padding[2]}px ${column.padding[3]}px`
              : 'unset'
          },
          {
            gridColumn: column.span ? `span ${column.span} / auto` : 'unset'
          }
        ]"
        :key="`${column.id}-${idx}`"
      >
        <skeleton
          :square="column.skeleton === 'square'"
          :align="column.skeletonAlignement || 'left'"
          v-if="column.isHover !== true"
        />
        <div v-else />
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from '../skeleton';

export default {
  components: {
    Skeleton
  },
  props: {
    columns: {
      type: Array,
      required: true
    },
    height: {
      type: Number,
      required: false,
      default: 40
    }
  },
  computed: {
    filteredColumns() {
      // We don't want to use items that are only visible on hover for grid calculation
      return this.columns.filter(item => item.onHover !== true);
    },
    gridTemplateColumns() {
      let str = 'grid-template-columns:';
      this.columns.forEach(column => {
        str += ` minmax(${column.min}, ${column.max})`;
      });
      return str;
    }
  }
};
</script>

<style lang="scss" scoped>
.row {
  @include body-1;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  &-body {
    display: grid;
    cursor: pointer;

    &-cell {
      display: flex;
      align-items: center;
      border-bottom: 1px dotted $color_neutral_40;
      justify-content: left;
    }

    &-no-border {
      border: none;
    }

    &-centered {
      justify-content: center;
    }
  }
}
</style>
