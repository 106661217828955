import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const WORKSPACE_INVITATION_LINK = gql`
  query workspaceInvitationLink($workspaceId: ID!) {
    workspaceInvitationLink(workspaceId: $workspaceId) {
      id
      link
      valid_to
    }
  }
`;
