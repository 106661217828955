<template>
  <div class="submission-details">
    <div
      :class="['submission-edition', editPanelTracks.length > 0 ? 'submission-edition-open' : '']"
    >
      <div class="submission-edition-wrapper">
        <edit-tracks-panel
          v-if="editPanelTracks.length > 0"
          ref="editTracksPanel"
          :libraryFileIds="editPanelTracks"
          :trackList="trackList"
          noPagination
          @toggleEditMode="toggleEditMode"
        />
      </div>
    </div>
    <div
      class="submission-content"
      :style="editPanelTracks.length > 0 && 'width: calc(100% - 518px + 38px);'"
    >
      <div v-if="InboxSubmission" class="submission-info">
        <div class="title">{{ this.title }}</div>
        <div class="message">{{ this.message }}</div>
      </div>
      <div class="tracks-list">
        <div class="tracks-list-content">
          <div v-show="!InboxSubmission" class="loader">
            <spinner-without-progress color="grey" :size="32" />
          </div>
          <tracks
            v-if="LibraryFilesDetails"
            :trackList="trackList"
            :stickyPosition="41"
            isSticky
            noAction
            @toggleEditMode="toggleEditMode"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash.get';
import { mapGetters } from 'vuex';
import { LIBRARY_FILES_DETAILS_LIGHT } from '@/graphql/queries/library';
import { INBOX_SUBMISSION_LIGHT } from '@/graphql/queries/inbox';
import generateTracklist from '@/utils/functions/tracklist';
import EditTracksPanel from '@/containers/editTracksPanel';
import Tracks from './tracklist';

export default {
  components: {
    Tracks,
    EditTracksPanel
  },
  props: {
    entityIds: {
      type: Array,
      required: true
    }
  },
  apollo: {
    InboxSubmission: {
      query: INBOX_SUBMISSION_LIGHT,
      variables() {
        return {
          submissionId: this.entityIds[0]
        };
      },
      result(res) {
        if (get(res, 'data.InboxSubmission')) {
          this.libraryFilesIds = res.data.InboxSubmission.tracks.map(
            track => track.library_file_id
          );
          this.title = res.data.InboxSubmission.topic;
          this.message = res.data.InboxSubmission.message;
        }
      }
    },
    LibraryFilesDetails: {
      query: LIBRARY_FILES_DETAILS_LIGHT,
      variables() {
        return {
          libraryFilesIds: this.libraryFilesIds
        };
      },
      result(res) {
        if (get(res, 'data.LibraryFilesDetails')) {
          this.trackList = generateTracklist(
            res.data.LibraryFilesDetails,
            this.currentWorkspace,
            this.$i18n.locale,
            false
          );
        }
      },
      skip() {
        return this.libraryFilesIds === null;
      }
    }
  },
  data() {
    return {
      trackList: null,
      title: null,
      message: null,
      libraryFilesIds: null,
      editPanelTracks: []
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace'])
  },
  mounted() {
    this.$root.$on('goToNextPreviousMeta', params => {
      this.toggleEditMode([params.elementUID]);
    });
  },
  methods: {
    toggleEditMode(tracksIds) {
      if (this.$isWorkspaceLockedVerification(this)) return;
      this.editPanelTracks = tracksIds;
      this.$store.commit('setEditPanelOpened', this.editPanelTracks.length > 0);
    }
  }
};
</script>

<style lang="scss" scoped>
.submission {
  &-content {
    transition: width 0.15s ease;
    width: 100%;
  }
  &-edition {
    background-color: $color_neutral_0;
    position: fixed;
    top: 0;
    z-index: map-get($z-index, side-panel);
    right: -520px;
    transition: right 0.15s ease;
    height: calc(100vh - 65px);
    overflow: auto;
    border-left: 1px solid $color_neutral_30;
    border-top: 1px solid $color_neutral_30;
    &-open {
      right: 0;
      @include shadow-down-01;
    }
    &-wrapper {
      height: 100%;
      width: 518px;
    }
  }
  &-details {
    padding-top: 24px;
    .tracks-list {
      margin-top: 24px;
    }
  }
  &-info {
    .title {
      @include heading-5;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .message {
      margin-top: 16px;
      word-wrap: break-word;
    }
  }
}
.loader {
  height: calc(100vh - 56px - 65px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
