<template>
  <div class="move-to">
    <div class="move-to-header">
      <icon-button
        class="previous"
        btnStyle="tertiary"
        size="medium"
        icon="ri-arrow-left-s-line"
        v-if="displayPrevious"
        v-tooltip="$t('selectWorkspace')"
        @submit="previous"
      />
      <div class="titles-container" :style="titleStyle">
        <div class="move-to-header-title">{{ title }}</div>
      </div>
      <icon-button btnStyle="tertiary" size="medium" icon="ri-close-fill" @submit="closeModal" />
    </div>
    <div v-if="displayConfirmRemove" class="move-confirmation">
      <div class="move-confirmation-body">
        Existing links will be deleted, and all past activities will be lost.
      </div>
      <div>Do you want to continue?</div>
    </div>
    <div v-else-if="!displayedWorkspace">
      <template-list-of-workspace
        :disableCurrent="projectId !== null"
        @changeDisplayedWorkspace="changeDisplayedWorkspace"
        @changeSelectedWorkspace="changeSelectedWorkspace"
      />
    </div>
    <div v-else class="move-to-body">
      <project-list class="move-to-body-project-list">
        <template v-slot:default="projectListProps">
          <template-list-of-project
            ref="templateListOfProject"
            :projects="projectListProps.projectList"
            :loadingProjects="projectListProps.loadingProjects"
            :noSearchResult="projectListProps.noProjectSearchResult"
            :showObserver="projectListProps.showProjectObserver"
            :emptyPlaceholder="{
              buttonLabel: $t('newProject'),
              buttonIcon: 'ri-folder-add-fill',
              text: $t('emptyProjectDescription')
            }"
            :displayNewProjectInput.sync="displayNewProjectInput"
            :displayProjectRequiredError="displayProjectRequiredError"
            @updateSearchValue="projectListProps.searchProject"
            @fetchMoreProjects="projectListProps.fetchMoreProjects"
            @listProjects="projectListProps.listProjects"
            @setSelectedProjectId="setSelectedProjectId"
            @createProject="createProject"
          />
        </template>
      </project-list>
    </div>
    <div class="move-to-footer">
      <div>
        <icon-button
          v-if="displayedWorkspace"
          btnStyle="secondary"
          size="medium"
          icon="ri-folder-add-line"
          v-tooltip="$t('newProject')"
          :disabled="disableAll"
          @submit="handleNewProjectClick"
        />
      </div>
      <div v-if="displayConfirmRemove" class="move-to-buttons">
        <div class="move-to-buttons--">
          <submit-button
            key="moveto-no"
            btnStyle="secondary"
            size="medium"
            :label="$t('cancel')"
            :disabled="disableAll"
            @submit="closeModal"
          />
        </div>
        <div class="move-to-buttons--">
          <submit-button
            btnStyle="primary"
            size="medium"
            :label="$t('modalUpgrade.confirmButton')"
            :disabled="disableAll"
            :pending="pendingMove"
            @submit="confirmMove"
          />
        </div>
      </div>
      <div v-else class="move-to-buttons">
        <div v-if="!moveHidden" class="move-to-buttons--">
          <submit-button
            btnStyle="secondary"
            size="medium"
            :label="$t('moveTo')"
            :pending="pending || pendingMove"
            :disabled="moveDisable"
            v-tooltip="$t('moveTooltip')"
            @submit="addTo(true)"
          />
        </div>
        <div class="move-to-buttons--">
          <submit-button
            btnStyle="primary"
            size="medium"
            :label="$t('copyTo')"
            :pending="pending || pendingCopy"
            :disabled="copyDisable"
            v-tooltip="$t('copyTooltip')"
            @submit="addTo(false)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash.get';
import { mapGetters } from 'vuex';
import { bus } from '@/utils/bus';
import {
  PROJECT_NEW,
  PROJECT_COPY_TO_WORKSPACE,
  PROJECT_COPY_ATTACHMENTS
} from '@/graphql/mutations/project';
import { PROJECTS_INFORMATION, PROJECT_LIST } from '@/graphql/queries/project';
import { PAGINATOR_LIMIT } from '@/utils/constants';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import ProjectList from '@/containers/projectList';
import {
  LIBRARY_COPY_TRACKS_TO_WORKSPACE,
  LIBRARY_COPY_ARTIST_TO_WORKSPACE,
  LIBRARY_COPY_SUPPORTS_TO_WORKSPACE
} from '@/graphql/mutations/library';
import { trackEvent } from '@/utils/functions/analytics';
import { get as getRoute } from '@/router/routes';
import { ME } from '@/graphql/queries/user';
import TemplateListOfProject from './TemplateListOfProject';
import TemplateListOfWorkspace from './TemplateListOfWorkspace';

export default {
  components: {
    ProjectList,
    TemplateListOfProject,
    TemplateListOfWorkspace
  },
  props: {
    filesIds: {
      type: Array,
      required: false,
      default: null
    },
    projectId: {
      type: String,
      required: false,
      default: null
    },
    attachmentId: {
      type: String,
      required: false,
      default: null
    },
    artistId: {
      type: String,
      required: false,
      default: null
    },
    albumId: {
      type: String,
      required: false,
      default: null
    },
    entityName: {
      type: String,
      required: false,
      default: null
    },
    hasPrevious: {
      type: Boolean,
      required: false,
      default: true
    },
    // marketing events props bellow 🔽
    fromAppHeader: {
      type: Boolean,
      required: false,
      default: false
    },
    fromArtistAlbumHeader: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      pending: false,
      pendingCopy: false,
      pendingMove: false,
      disableAll: false,
      selectedProjectId: null,
      selectedProjectName: null,
      displayNewProjectInput: false,
      displayProjectRequiredError: false,
      newProjectName: undefined,
      uploadToAfterProjectCreation: false,
      displayedWorkspace: null,
      selectedWorkspace: null,
      selectedWorkspaceName: null,
      displayConfirmRemove: false
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    displayPrevious() {
      return this.hasPrevious && (this.displayedWorkspace !== null || this.displayConfirmRemove);
    },
    title() {
      const title = this.displayConfirmRemove
        ? 'Move tracks'
        : get(this, 'displayedWorkspace.name', 'Select workspace');
      return title;
    },
    titleStyle() {
      const w = this.displayPrevious ? '96px' : '48px';
      return `max-width: calc(100% - ${w})`;
    },
    isFromProject() {
      return this.$route.path.includes('projects') && this.$route.path.includes('details');
    },
    moveHidden() {
      if (
        this.displayedWorkspace &&
        this.displayedWorkspace.id === this.currentWorkspace.id &&
        !this.isFromProject
      ) {
        return true;
      }
      return false;
    },
    moveDisable() {
      if (!this.displayedWorkspace && !this.selectedWorkspace) {
        return true;
      }
      if (this.displayedWorkspace !== null && !this.selectedProjectId) {
        return true;
      }
      if (this.disableAll) {
        return true;
      }
      return false;
    },
    copyDisable() {
      if (!this.displayedWorkspace && !this.selectedWorkspace) {
        return true;
      }
      if (this.displayedWorkspace !== null && !this.selectedProjectId) {
        return true;
      }
      if (this.disableAll) {
        return true;
      }
      return false;
    }
  },
  created() {
    window.addEventListener('keypress', this.handleKeyPress);
    bus.$on('addToProjectEnded', () => {
      this.closeModal();
    });
  },
  mounted() {
    if (this.projectId === null) {
      this.displayedWorkspace = this.currentWorkspace;
    }
  },
  destroyed() {
    window.removeEventListener('keypress', this.handleKeyPress);
    bus.$off('addToProjectEnded');
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    handleKeyPress(event) {
      if (event.keyCode === 13) {
        this.addTo();
      }
    },
    setSelectedProjectId(projectId, projectName) {
      this.displayProjectRequiredError = false;
      this.selectedProjectId = projectId;
      this.selectedProjectName = projectName;
    },
    addTo(withDeletion) {
      this.sendEvents(withDeletion);
      if (!withDeletion) {
        this.disableAll = true;
        this.pendingCopy = true;
      }
      if (this.attachmentId !== null) {
        if (withDeletion) {
          this.pendingMove = true;
          this.disableAll = true;
        }
        this.copyAttachment(withDeletion);
        return;
      }

      if (this.projectId !== null) {
        if (withDeletion) {
          this.displayConfirmRemove = true;
        } else {
          this.copyProjectToWorkspace(false);
        }
        return;
      }

      if (this.displayedWorkspace && this.displayedWorkspace.id === this.currentWorkspace.id) {
        if (this.selectedProjectId) {
          bus.$emit('addTracksToProject', {
            projectId: this.selectedProjectId,
            libraryFilesIds: this.filesIds
          });
          if (withDeletion) {
            if (this.isFromProject) {
              bus.$emit('removeTracksFromProject', this.filesIds);
            }
          }
        } else if (this.newProjectName) {
          this.uploadToAfterProjectCreation = true;
        }
      } else if (this.selectedWorkspace !== null) {
        if (withDeletion && !this.isFromProject) {
          this.displayConfirmRemove = true;
        } else {
          this.copyTracksToWorkspace(withDeletion);
        }
      }
    },
    confirmMove() {
      this.disableAll = true;
      this.pendingMove = true;
      if (this.projectId !== null) {
        this.copyProjectToWorkspace(true);
      } else {
        this.copyTracksToWorkspace(true);
      }
    },
    copyTracksToWorkspace(withDeletion) {
      const mutation = {
        name: LIBRARY_COPY_TRACKS_TO_WORKSPACE,
        variable: 'libraryFilesIds',
        value: this.filesIds
      };
      if (this.artistId !== null) {
        mutation.name = LIBRARY_COPY_ARTIST_TO_WORKSPACE;
        mutation.variable = 'artistId';
        mutation.value = this.artistId;
      }
      if (this.albumId !== null) {
        mutation.name = LIBRARY_COPY_SUPPORTS_TO_WORKSPACE;
        mutation.variable = 'supportIds';
        mutation.value = [this.albumId];
      }
      this.$apollo
        .mutate({
          mutation: mutation.name,
          variables: {
            sourceWorkspaceId: this.currentWorkspace.id,
            targetWorkspaceId: this.selectedWorkspace,
            [mutation.variable]: mutation.value,
            deleteMode: withDeletion ? 'complete' : 'preserve'
          },
          refetchQueries: withDeletion ? [{ query: ME }] : []
        })
        .then(res => {
          if (withDeletion && !this.artistId && this.albumId) {
            const trackToDelete = get(res, 'data.LibraryCopyTracksToWorkspace', []).map(
              track => track.source_library_file_id
            );
            if (this.isFromProject) {
              bus.$emit('removeTracksFromProject', trackToDelete);
            } else {
              bus.$emit('deleteTracksFromLibrary', trackToDelete);
            }
          }
          const num = get(res, 'data.LibraryCopyTracksToWorkspace', []).length;
          let redirectionRoute = null;
          let alertMessage = {
            key: 'addTooWorkspaceSuccess',
            params: num,
            args: {
              num,
              workspace: this.selectedWorkspaceName
            }
          };
          if (this.entityName) {
            alertMessage = {
              key: 'addEntitySuccess',
              args: { entity: this.entityName, workspace: this.selectedWorkspaceName }
            };
          }
          if (this.artistId !== null) {
            redirectionRoute = {
              name: 'artist',
              id: get(res, 'data.LibraryCopyArtistToWorkspace.artist_id', '')
            };
          }
          if (this.albumId !== null) {
            redirectionRoute = {
              name: 'album',
              id: get(res, 'data.LibraryCopySupportsToWorkspace[0].support_id', '')
            };
          }
          bus.$emit('showAlert', {
            message: alertMessage,
            actionLabel: 'Go to workspace',
            handleAction: () => {
              if (redirectionRoute && redirectionRoute.id) {
                bus.$emit('changeCurrentWorkspace', this.selectedWorkspace, redirectionRoute);
              } else {
                bus.$emit('changeCurrentWorkspace', this.selectedWorkspace);
              }
            },
            style: 'success',
            delay: 5000
          });
          this.closeModal();
          if (withDeletion && (this.artistId !== null || this.albumId !== null)) {
            this.$router.push(getRoute('library'));
          }
        });
    },
    copyProjectToWorkspace(withDeletion) {
      this.$apollo
        .mutate({
          mutation: PROJECT_COPY_TO_WORKSPACE,
          variables: {
            projectId: this.projectId,
            targetWorkspaceId: this.selectedWorkspace
          }
        })
        .then(res => {
          if (withDeletion) {
            bus.$emit('deleteProject', this.projectId);
          }
          bus.$emit('showAlert', {
            message: {
              key: 'projectAddToWorkspaceSuccess',
              args: {
                workspace: this.selectedWorkspaceName
              }
            },
            actionLabel: 'Go to workspace',
            handleAction: () => {
              bus.$emit('changeCurrentWorkspace', this.selectedWorkspace, {
                name: 'project',
                id: get(res, 'data.ProjectCopyToWorkspace.id', '')
              });
            },
            style: 'success',
            delay: 5000
          });
          this.closeModal();
        });
    },
    copyAttachment(withDeletion) {
      this.$apollo
        .mutate({
          mutation: PROJECT_COPY_ATTACHMENTS,
          variables: {
            projectId: this.$route.params.id,
            targetProjectId: this.selectedProjectId,
            attachmentsIds: [this.attachmentId],
            deleteMode: withDeletion ? 'complete' : 'preserve'
          }
        })
        .then(() => {
          if (withDeletion) {
            bus.$emit('removeProjectAttachmentsFromCache');
          }
          bus.$emit('showAlert', {
            message: {
              key: 'attachmentCopyToProject',
              args: {
                project: this.selectedProjectName
              }
            },
            actionLabel: 'Go to project',
            handleAction: () => {
              this.$router.push(getRoute('projectDetails', { id: this.selectedProjectId }));
            },
            style: 'success',
            delay: 5000
          });
          this.closeModal();
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          this.projectName = null;
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    createProject(projectName) {
      if (this.$isWorkspaceLockedVerification(this)) return;
      this.pending = true;
      this.newProjectName = projectName;
      this.displayNewProjectInput = false;
      this.$apollo
        .mutate({
          mutation: PROJECT_NEW,
          variables: {
            name: this.newProjectName,
            description: null,
            workspaceId: this.currentWorkspace.id
          },
          refetchQueries: [
            {
              query: PROJECT_LIST,
              variables: {
                workspaceId: this.currentWorkspace.id,
                status: 'active',
                page: 1,
                limit: PAGINATOR_LIMIT
              }
            },
            {
              query: PROJECTS_INFORMATION,
              variables: {
                workspaceId: this.currentWorkspace.id
              }
            }
          ]
        })
        .then(res => {
          this.pending = false;
          const projectId = get(res, 'data.ProjectNew.id', null);
          if (projectId) {
            this.$refs.templateListOfProject.handleProjectItemClick(projectId);
          }
          this.$nextTick(() => {
            if (projectId && this.uploadToAfterProjectCreation) {
              this.addTo();
            }
          });
          // waiting for the search to update before refetch
          setTimeout(() => {
            bus.$emit('refetchProjectList');
          }, 1500);
        })
        .catch(error => {
          this.pending = false;
          const key = getGraphQlErrorCode(error);
          this.projectName = null;
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    handleNewProjectClick() {
      this.displayNewProjectInput = true;
    },
    previous() {
      if (this.displayConfirmRemove) {
        this.displayConfirmRemove = false;
        return;
      }
      this.selectedProjectId = null;
      this.selectedProjectName = null;
      this.displayedWorkspace = null;
    },
    changeDisplayedWorkspace(workspace) {
      this.selectedWorkspace = null;
      this.selectedWorkspaceName = null;
      this.displayedWorkspace = workspace;
    },
    changeSelectedWorkspace(wid, wname) {
      this.selectedWorkspace = wid;
      this.selectedWorkspaceName = wname;
    },
    sendEvents(withDeletion) {
      const isAttachment = this.attachmentId !== null;
      const fromSharedView = this.$route.path.includes('shared');
      const workspace_origin_is_target =
        get(this, 'displayedWorkspace.id', null) === this.currentWorkspace.id;
      let page_view;
      let event;
      switch (this.$route.name) {
        case 'albums':
        case 'albumDetails':
          page_view = fromSharedView ? 'shared library album' : 'library album';
          if (this.fromAppHeader) {
            event = withDeletion ? 'Album Move Click' : 'Album Copy Click';
          } else {
            event = withDeletion ? 'Track Move Click' : 'Track Copy Click';
          }
          break;
        case 'artists':
        case 'artistAlbums':
        case 'artistTracks':
          page_view = fromSharedView ? 'shared library artist' : 'library artist';
          if (this.fromAppHeader) {
            event = withDeletion ? 'Artist Move Click' : 'Artist Copy Click';
          } else if (this.fromArtistAlbumHeader) {
            event = withDeletion ? 'Album Move Click' : 'Album Copy Click';
          } else {
            event = withDeletion ? 'Track Move Click' : 'Track Copy Click';
          }
          break;
        case 'projectDetails':
          page_view = fromSharedView ? 'shared project details' : 'project details';
          if (isAttachment) {
            event = withDeletion ? 'Attachment Move Click' : 'Attachment Copy Click';
          } else {
            event = withDeletion ? 'Track Move Click' : 'Track Copy Click';
          }
          break;
        case 'activeProjects':
          page_view = 'project list';
          event = withDeletion ? 'Project Move Click' : 'Project Copy Click';
          break;
        case 'trackList':
        default:
          page_view = 'library track list';
          event = withDeletion ? 'Track Move Click' : 'Track Copy Click';
          break;
      }
      trackEvent(event, {
        category: 'addto',
        page_view,
        workspace_origin_is_target
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.move-to {
  &-header {
    display: flex;
    align-items: center;
    height: 56px;
    border-bottom: 1px solid $color_neutral_30;
    padding: 0 24px;
    .previous {
      margin-right: 8px;
    }
    .titles-container {
      max-width: calc(100% - 48px);
      flex-grow: 1;
    }
    &-title {
      @include heading-6;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 24px;
    }
  }
  &-body {
    height: fit-content;
    height: 332px;
    &-project-list {
      height: fit-content;
    }
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    padding: 8px 24px;
    border-top: 1px solid $color_neutral_30;
    align-items: center;
  }
  &-buttons {
    display: flex;
    justify-content: flex-end;

    &-- {
      display: flex;
      &:first-child {
        margin: 0 8px 0 0;
      }
    }
  }
}
.move-confirmation {
  padding: 24px;
  line-height: 150%;
  font-size: 14px;
  &-body {
    margin-bottom: 24px;
  }
}
</style>

<style lang="scss">
.move-to-footer {
  .button.primary.medium.no-icon,
  .button.tertiary.medium.no-icon,
  .button.secondary.medium {
    padding: 0 16px;
  }
}
</style>
