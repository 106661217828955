<template>
  <button :class="btnClass" :disabled="disabled" @click="handleClick" type="button">
    <img v-if="icon === 'a2mb'" src="/images/bridge-add.svg" style="width: 16px" />
    <i v-else :class="iconClass" />
  </button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: false,
      default: 'ri-add-line'
    },
    btnStyle: {
      type: String,
      required: false,
      default: 'primary'
    },
    size: {
      type: String,
      required: false,
      default: 'medium'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isDanger: {
      type: Boolean,
      required: false,
      default: false
    },
    isSelect: {
      type: Boolean,
      required: false,
      default: false
    },
    isHover: {
      type: Boolean,
      required: false,
      default: false
    },
    customEvent: {
      type: String,
      required: false,
      default: 'submit'
    },
    itemId: {
      type: String,
      required: false,
      default: null
    },
    url: {
      type: String,
      required: false,
      default: null
    },
    clickTriggerSelect: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    btnClass() {
      let str = `rounded-button ${this.btnStyle} ${this.size}`;
      if (this.disabled) {
        str += ' disabled';
      }
      if (this.isHover) {
        str += ' hover';
      }
      return str;
    },
    iconClass() {
      let iconClass = `icon ${this.icon}`;
      if (this.isDanger) {
        iconClass += ' danger';
      }
      if (!this.isDanger && this.isSelect) {
        iconClass += ' select';
      }
      return iconClass;
    }
  },
  methods: {
    handleClick() {
      const { customEvent, itemId, url, clickTriggerSelect } = this;
      this.$emit(customEvent, { itemId, url }, customEvent);
      if (clickTriggerSelect) {
        this.$emit('selectItemFromDropdown', itemId);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.rounded-button {
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: background-color 200ms linear;
}

.rounded-button:focus {
  box-shadow: 0 0 0 2px $color_neutral_40;
}

.primary {
  background-color: $color_neutral_100;
  border: 1px solid $color_neutral_100;
  color: $color_neutral_0;

  &:hover {
    background-color: $color_neutral_80;
    border: 1px solid $color_neutral_80;
  }
}

.primary.disabled {
  background-color: $color_neutral_40;
  border: 1px solid $color_neutral_40;
  color: $color_neutral_0;
  cursor: not-allowed;

  &:hover {
    background-color: $color_neutral_40;
  }
}

.secondary {
  border: 1px solid $color_neutral_40;
  color: $color_neutral_100;
  background: none;

  &:hover {
    background-color: $color_neutral_10;
    color: $color_neutral_80;
    border: 1px solid $color_neutral_80;
  }
}

.secondary.disabled {
  background: none;
  border: 1px solid $color_neutral_40;
  color: $color_neutral_40;
  cursor: not-allowed;

  &:hover {
    background: none;
    color: $color_neutral_40;
    border: 1px solid $color_neutral_40;
  }
}

.tertiary.disabled {
  cursor: not-allowed;

  &:hover {
    background: none;
  }
  .icon {
    color: $color_neutral_40;
  }
}

.tertiary {
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 200ms linear;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 24px;
  height: 24px;
  background: none;
  &:hover {
    background-color: $color_neutral_30;

    .icon.danger {
      color: $color_danger_100;
    }
  }
  .icon.select {
    color: $color_primary_100;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 4px;
      height: 4px;
      background-color: $color_primary_100;
      border-radius: 50%;
      bottom: -6px;
      left: 6px;
    }
  }

  .icon {
    font-size: 16px;
    color: $color_neutral_100;
    transition: all 200ms linear;
  }
}
.tertiary.hover {
  background-color: $color_neutral_30;
  .icon.danger {
    color: $color_danger_100;
  }
}

.rounded-button.small:not(.tertiary) {
  width: 32px;
  height: 32px;

  .icon {
    font-size: 12px;
  }
}

.rounded-button.medium:not(.tertiary) {
  width: 40px;
  height: 40px;

  .icon {
    font-size: 16px;
  }
}

.rounded-button.large:not(.tertiary) {
  width: 48px;
  height: 48px;

  .icon {
    font-size: 24px;
  }
}

.tertiary.small {
  width: 32px;
  height: 32px;
  .icon {
    font-size: 16px;
  }
}
.tertiary.medium {
  width: 40px;
  height: 40px;
  .icon {
    font-size: 20px;
  }
}
.tertiary.large {
  width: 48px;
  height: 48px;
  .icon {
    font-size: 24px;
  }
}
</style>
