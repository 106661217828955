
<div class="email" v-click-outside="{ hide: validateEntry }">
  <div class="email-label">{{ label }}</div>
  <div class="email-area" :class="errorMessage && 'email-error'" @click.self="focusIn">
    <div :class="getItemClass(item)" v-for="(item, index) in emails" :key="`email-${index}`">
      <input
        type="text"
        :class="getInputClass(item)"
        :style="`width: ${getInputWidth(item.value)}px; max-width: 468px;`"
        v-model="item.value"
        :disabled="item.isCopy"
        @keyup.13="toggleEdit(item, false, true)"
        @focus="toggleEdit(item, true, false)"
        @blur="toggleEdit(item, false, false)"
      />
      <icon-button
        class="email-area-item-cross"
        icon="ri-close-line"
        btnStyle="tertiary"
        size="small"
        v-if="!item.isEditable && !item.isCopy"
        @submit="removeHandleClick(item)"
      />
    </div>
    <input
      class="email-area-input"
      type="text"
      ref="input"
      v-model="newEmail"
      :style="newEmail ? `width: ${getInputWidth(newEmail)}px;` : 'width: 10px;'"
      :placeholder="emails.length === 0 && `${placeholder}`"
      @paste="handlePaste"
      @keydown="handleKeydown"
      @keydown.8="removeHandleBackspaceKey"
      @keydown.9="validateEntry"
      @input="handleInput"
    />
  </div>
  <div v-if="autocompleteEmails.length > 0 && newEmail.length > 0" class="email-expand">
    <div
      class="email-expand-item"
      v-for="(email, index) in autocompleteEmails"
      :key="`email-${index}`"
      @click="handleOptionClick(email)"
    >
      {{ email }}
    </div>
  </div>
  <div class="email-error-message" v-if="errorMessage">{{ errorMessage }}</div>
</div>
