
<div class="slider" style="width: 100%">
  <div class="slider-prefix" />
  <div class="slider-wrapper" style="width: 100%">
    <input
      class="slider-wrapper-range"
      ref="input"
      :style="`width: 100%`"
      type="range"
      :min="min"
      :max="max"
      :step="step"
      v-model="sliderValue"
      @input="handleInput"
    />
    <div class="slider-wrapper-foreground" :style="`width: ${sliderWidth}%`" />
    <div class="slider-wrapper-background" :style="getBackgroundStyle" />
  </div>
  <div class="slider-label-min" v-if="labelMin">{{ labelMin }}</div>
  <div class="slider-label-max" v-if="labelMax">{{ labelMax }}</div>
</div>
