<template>
  <div v-if="listArtists">
    <div v-if="currentWorkspace.total_tracks === 0 && !uploadIsInprogress">
      <empty-library
        :title="$t('libraryEmptyTitle')"
        :description="$t('libraryEmptyHelper')"
        :helper="$t('dragAndDrop')"
        placeholderType="artists"
        :cloudedTextContent="$t('addAudioFiles')"
        :cloudedTextColor="{
          stroke: '#2F43DE',
          fill: '#E0E3FA'
        }"
      >
        <template v-slot:button>
          <submit-button
            size="large"
            :label="$t('upload')"
            iconClass="ri-upload-cloud-line"
            browse
            multiple
            :accept="allowedFileTypes"
            @submit="handleClickUploadButton"
          />
        </template>
      </empty-library>
    </div>
    <div
      v-if="currentWorkspace.total_tracks > 0 || uploadIsInprogress"
      class="artists"
      data-test="listArtists"
    >
      <div class="artists-list">
        <div class="artists-list-card" v-for="item in cardsArtist" :key="`artist-${item.id}`">
          <card-artist
            :artistCover="getArtistCover(item)"
            :artistUrl="getArtistUrl(item)"
            :artistName="item.name"
          />
        </div>
        <div class="artists-list-observer">
          <observer
            :key="`obs-${page}`"
            v-if="page < listArtists.last_page && !loading"
            :height="213"
            @intersect="loadMore"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="loader">
    <spinner-without-progress color="grey" :size="32" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash.get';
import { LIST_ARTISTS } from '@/graphql/queries/library';
import { bus } from '@/utils/bus';
import { get as getRoute } from '@/router/routes';
import EmptyLibrary from '@/containers/emptyView';
import Observer from '@/containers/observer';
import { getAllowedFileTypes } from '@/utils/functions/audio';
import { PAGINATOR_LIMIT } from '@/utils/constants';

export default {
  components: {
    EmptyLibrary,
    Observer
  },
  data() {
    return {
      page: 1,
      limit: PAGINATOR_LIMIT,
      loading: false,
      getRoute
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'uploadIsInprogress', 'newUpload']),
    allowedFileTypes() {
      return getAllowedFileTypes(this.$config).join(',');
    },
    cardsArtist() {
      return this.listArtists ? [...this.listArtists.data] : [];
    }
  },
  apollo: {
    listArtists: {
      query: LIST_ARTISTS,
      variables() {
        return {
          libraryId: this.currentWorkspace.libraries[0].id,
          page: 1,
          limit: this.limit,
          orderBy: 'name',
          sortBy: 'asc'
        };
      },
      watchLoading(loading) {
        this.loading = loading;
      }
    }
  },
  mounted() {
    this.$apollo.queries.listArtists.refetch().then(() => {
      this.page = 1;
    });
  },
  methods: {
    handleClickUploadButton(files) {
      bus.$emit('prepareUploadForFiles', files);
    },
    getArtistUrl(item) {
      if (item.id === 'unknown') {
        return getRoute('artistTracks', { id: item.id });
      }
      return getRoute('artist', { id: item.id });
    },
    getArtistCover(item) {
      if (item.id === 'unknown') {
        return '/images/unknown-artist.svg';
      }
      return get(item, 'image.thumbnails[2]', null)
        ? item.image.thumbnails[2].url
        : '/images/default_artist.jpg';
    },
    loadMore() {
      this.page += 1;
      this.$apollo.queries.listArtists.fetchMore({
        variables: {
          libraryId: this.currentWorkspace.libraries[0].id,
          page: this.page,
          limit: this.limit,
          orderBy: 'name',
          sortBy: 'asc'
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newArtists = fetchMoreResult.listArtists.data;
          if (fetchMoreResult.listArtists.data.length === 0) {
            return {
              listArtists: {
                ...previousResult.listArtists,
                data: [...previousResult.listArtists.data],
                __typename: 'LibraryArtistsPaginator' // eslint-disable-line
              }
            };
          }
          return {
            listArtists: {
              ...previousResult.listArtists,
              data: [...previousResult.listArtists.data, ...newArtists],
              __typename: 'LibraryArtistsPaginator' // eslint-disable-line
            }
          };
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.artists {
  padding: 24px 0 40px 40px;

  &-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(177px, 1fr));
    justify-content: center;
    grid-column-gap: 24px;
    grid-row-gap: 40px;

    &-card {
      display: flex;
      justify-content: center;
    }

    &-observer {
      position: relative;
    }
  }
}

.loader {
  height: calc(100vh - 56px - 65px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
