<template>
  <div v-if="projects.length > 0" data-test="projectsList">
    <header-list
      :columns="columns"
      actionUnavailable
      :sortColumn="false"
      :itemsSelected="[]"
      :isSticky="true"
    />
    <div v-for="(project, idx) in formatedProjectsList" :key="project.id" data-test="projects">
      <list-row
        :class="{ 'row-container': active }"
        :item="project"
        :columns="columns"
        :rowIndex="idx"
        :height="64"
        :id="project.id"
        disableSelection
        @click.native="handleRowClick($event, project.id)"
        @handleEmit="handleEmit"
      />
    </div>
  </div>
</template>

<script>
import { bus } from '@/utils/bus';
import MoveToAddTo from '@/containers/modals/moveToAddTo';
import { downloadProjectAsZip } from '@/utils/functions/download';

export default {
  props: {
    projects: {
      type: Array,
      required: false,
      default: () => []
    },
    active: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      isHoverId: null
    };
  },
  computed: {
    formatedProjectsList() {
      return this.projects.map(project => {
        return {
          ...project,
          count: project.privateShare ? project.privateShare.toString() : '-'
        };
      });
    },
    columns() {
      return [
        {
          type: 'component',
          name: 'project-name',
          header: this.$t('name'),
          skeleton: 'line',
          min: '105px',
          max: '1fr',
          containerWidth: true,
          noStop: true,
          padding: [0, 8, 0, 8],
          span: 3,
          props: [
            {
              name: 'name',
              match: 'name',
              value: null
            },
            {
              name: 'projectId',
              match: 'id',
              value: null
            },
            {
              name: 'isHoverId',
              match: 'isHoverId',
              value: this.active && this.isHoverId
            }
          ]
        },
        this.active
          ? {
              type: 'component',
              name: 'moreDropdownShare',
              path: 'moreDropdown',
              stopPropagation: true,
              tooltip: this.$t('share'),
              header: null,
              noHeader: true,
              noSort: true,
              onHover: true,
              min: '36px',
              max: '36px',
              isExpandHover: true,
              events: ['setMoreDropdownState', 'selectItemFromDropdown'],
              props: [
                {
                  name: 'icon',
                  match: 'icon',
                  value: 'ri-share-forward-fill'
                },
                {
                  name: 'items',
                  match: 'items',
                  value: [
                    {
                      icon: 'ri-flashlight-fill',
                      name: this.$t('getQuickLink'),
                      subText: this.$t('getQuickLinkSub'),
                      keepExpand: false,
                      action: trackId => {
                        this.share({ itemId: trackId }, 0);
                      }
                    },
                    {
                      separator: true
                    },
                    {
                      icon: 'ri-links-fill',
                      name: this.$t('createIndividualLinks'),
                      subText: this.$t('createIndividualLinksSub'),
                      keepExpand: false,
                      action: trackId => {
                        this.share({ itemId: trackId }, 1);
                      }
                    },
                    {
                      separator: true
                    },
                    {
                      icon: 'ri-mail-line',
                      name: this.$t('sendByEmail'),
                      subText: this.$t('sendByEmailSub'),
                      keepExpand: false,
                      action: trackId => {
                        this.share({ itemId: trackId }, 2);
                      }
                    }
                  ]
                },
                {
                  name: 'itemId',
                  match: 'id',
                  value: null
                }
              ]
            }
          : undefined,
        this.active
          ? {
              type: 'component',
              name: 'more-dropdown',
              tooltip: this.$t('more'),
              header: null,
              noHeader: true,
              noSort: true,
              min: '36px',
              max: '36px',
              isExpandHover: true,
              onHover: true,
              events: ['setMoreDropdownState', 'selectItemFromDropdown'],
              props: [
                {
                  name: 'items',
                  match: 'items',
                  value: [
                    {
                      icon: 'ri-file-copy-line',
                      name: this.$t('duplicate'),
                      action: itemId => {
                        this.duplicate({ itemId });
                      }
                    },
                    {
                      icon: 'ri-folder-transfer-line',
                      name: this.$t('addTo'),
                      action: itemId => {
                        this.AddTo(itemId);
                      }
                    },
                    {
                      icon: 'ri-download-line',
                      name: this.$t('downloadProject'),
                      action: itemId => {
                        downloadProjectAsZip(this, itemId);
                      }
                    },
                    {
                      separator: true
                    },
                    {
                      icon: 'ri-delete-bin-line',
                      name: this.$t('delete'),
                      danger: true,
                      keepExpand: false,
                      action: itemId => {
                        this.delete(itemId);
                      }
                    }
                  ]
                },
                {
                  name: 'itemId',
                  match: 'id',
                  value: null
                }
              ]
            }
          : undefined,
        this.active
          ? undefined
          : {
              type: 'component',
              name: 'icon-button',
              header: null,
              isExpandHover: true,
              onHover: true,
              noHeader: true,
              noSort: true,
              tooltip: this.$t('restore'),
              min: '36px',
              max: '36px',
              emits: ['emitActive'],
              props: [
                {
                  name: 'btnStyle',
                  match: 'btnStyle',
                  value: 'tertiary'
                },
                {
                  name: 'icon',
                  match: 'icon',
                  value: 'ri-refresh-line'
                },
                {
                  name: 'size',
                  match: 'size',
                  value: 'small'
                },
                {
                  name: 'customEvent',
                  match: 'customEvent',
                  value: 'emitActive'
                },
                {
                  name: 'itemId',
                  match: 'id',
                  value: null
                },
                {
                  name: 'clickTriggerSelect',
                  match: 'clickTriggerSelect',
                  value: true
                }
              ]
            },
        this.active
          ? undefined
          : {
              type: 'component',
              name: 'icon-button',
              header: null,
              isExpandHover: true,
              onHover: true,
              noHeader: true,
              noSort: true,
              tooltip: this.$t('delete'),
              min: '36px',
              max: '36px',
              emits: ['delete'],
              props: [
                {
                  name: 'btnStyle',
                  match: 'btnStyle',
                  value: 'tertiary'
                },
                {
                  name: 'icon',
                  match: 'icon',
                  value: 'ri-delete-bin-line'
                },
                {
                  name: 'size',
                  match: 'size',
                  value: 'small'
                },
                {
                  name: 'customEvent',
                  match: 'customEvent',
                  value: 'delete'
                },
                {
                  name: 'itemId',
                  match: 'id',
                  value: null
                },
                {
                  name: 'clickTriggerSelect',
                  match: 'clickTriggerSelect',
                  value: true
                }
              ]
            },
        {
          type: 'text',
          name: 'trackCount',
          noTitle: true,
          headerPrefixIcon: 'ri-music-2-line',
          skeleton: 'line',
          min: '64px',
          max: '64px',
          padding: [0, 8, 0, 8]
        },
        {
          type: 'text',
          name: 'attachmentCount',
          noTitle: true,
          headerPrefixIcon: 'ri-attachment-2',
          skeleton: 'line',
          min: '64px',
          max: '64px',
          padding: [0, 8, 0, 8]
        },
        ...(this.active
          ? [
              {
                type: 'component',
                name: 'icon-text',
                header: this.$t('shared'),
                skeleton: 'line',
                min: '100px',
                max: '100px',
                padding: [0, 8, 0, 8],
                noStop: true,
                props: [
                  {
                    name: 'icon',
                    match: 'icon',
                    value: 'ri-group-fill'
                  },
                  {
                    name: 'text',
                    match: 'count',
                    value: null
                  }
                ]
              }
            ]
          : []),
        {
          type: 'component',
          name: 'info-date-user',
          header: this.$t('created'),
          skeleton: 'line',
          min: '105px',
          max: '206px',
          noStop: true,
          containerWidth: true,
          padding: [0, 8, 0, 8],
          props: [
            {
              name: 'date',
              match: 'createdAt',
              value: null
            },
            {
              name: 'user',
              match: 'createdBy',
              value: null
            }
          ]
        },
        {
          type: 'component',
          name: 'info-date-user',
          header: this.$t('lastModified'),
          skeleton: 'line',
          min: '105px',
          max: '206px',
          noStop: true,
          containerWidth: true,
          padding: [0, 8, 0, 8],
          props: [
            {
              name: 'date',
              match: 'updatedAt',
              value: null
            },
            {
              name: 'user',
              match: 'updatedBy',
              value: null
            }
          ]
        }
      ].filter(Boolean);
    }
  },
  methods: {
    handleRowClick(e, id) {
      // each column with type component ( except those with noStop ) do not redirect
      // on click, they have a 'stopbubble' atribute on them, and we verify here on
      // the click event if the path contain it
      const path = e.path || (e.composedPath && e.composedPath());
      const stopBubble = path.some(
        element => typeof element.getAttribute === 'function' && element.getAttribute('stopbubble')
      );
      if (!this.active || stopBubble) {
        return;
      }
      this.$emit('goToProjectDetail', id);
    },
    handleEmit(params) {
      if (this[params.methodName]) this[params.methodName](params.param);
    },
    duplicate(param) {
      this.$emit('duplicate', param.itemId);
    },
    share(param, defaultTab) {
      this.$emit('share', param.itemId, defaultTab);
    },
    emitActive(param) {
      this.$emit('active', param.itemId);
    },
    archive(itemId) {
      this.$emit('archive', itemId);
    },
    delete(param) {
      const itemId = typeof param === 'object' && param !== null ? param.itemId : param;
      this.$emit('delete', itemId);
    },
    setIsHoverId(itemId) {
      this.isHoverId = itemId;
    },
    AddTo(itemId) {
      if (this.$isWorkspaceLockedVerification(this)) return;
      bus.$emit('displayModal', {
        component: MoveToAddTo,
        hideHeader: true,
        isVisible: true,
        size: 'small',
        onSubmit: this.closeShareModal,
        props: [{ name: 'projectId', value: itemId }]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.row-container:hover {
  background-color: $color_neutral_10;
}
</style>
