
<div v-if="ActivitiesList">
  <div class="activity" v-if="isActivities" data-test="activityList">
    <div v-if="entityType === 'project'" class="activity-title">
      <div class="activity-title-main">{{ $t('allSharingActivity') }}</div>
      <div class="activity-title-sub">{{ $t('activitiesDesc') }}</div>
    </div>
    <div class="activity-filter">
      <filter-dropdown
        class="activity-filter--"
        filterIcon="ri-star-s-line"
        filterName="Activities"
        :filterList="activitiesFilterList"
        :activeFilter="filterActivities"
        @toggleFilter="toggleActivities"
      />
      <date-filter-dropdown
        class="activity-filter--"
        local="en"
        :txtDropdownOptions="txtDropdownOptions"
        @setDateFilter="setDateFilter"
        ref="dateFilter"
      />
      <filter-dropdown
        v-if="!hideContactFilter"
        class="activity-filter--"
        filterIcon="ri-user-line"
        filterName="Contact"
        :filterList="contactFilterList"
        :activeFilter="filterContact"
        @toggleFilter="toggleContact"
      />
    </div>
    <div class="activity-content" v-if="isFilterResult">
      <div
        v-for="(activities, index) in sortedActivities"
        :key="`activities-${index}`"
        class="activity-content-section"
      >
        <div class="activity-content-section-title">{{ activities.dateToDisplay }}</div>
        <div class="activity-content-section-item-container">
          <div
            v-for="(activity, i) in activities.activities"
            :key="`activity-${i}`"
            class="activity-content-section-item"
          >
            <activity-item
              :hour="getHour(activity.performedAt)"
              :action="formatAction(activity.action)"
              :itemName="activity.description"
              :mail="activity.mail || activity.customName || sendTo || 'Quick link'"
            />
          </div>
        </div>
      </div>
      <div v-if="displayLoadMore" class="activity-content-loadmore">
        <submit-button
          btnStyle="tertiary"
          size="medium"
          label="Load more"
          iconPosition="suffix"
          :pending="isPending"
          @submit="loadMore"
        />
      </div>
    </div>
    <div v-else class="activity-noresult">
      <i class="ri-emotion-unhappy-line"></i>
      <div class="activity-noresult-title">{{ $t('noResult') }}</div>
      <div class="activity-noresult-subtitle">
        {{ $t('tryAdjustFilter') }}
      </div>
    </div>
  </div>
  <div class="no-activity" v-else-if="entityType === 'project'">
    <empty-activity
      :title="$t('noActivityProjectTitle')"
      :description="$t('noActivityProjectSubtitle')"
      placeholderType="activity"
      :cloudedTextContent="$t('shareProject')"
      :cloudedTextColor="{
        stroke: '#FF9143',
        fill: '#FFF9EB'
      }"
    >
      <template v-slot:button>
        <btn-dropdown
          v-if="entityType !== 'submission'"
          class="upload-to-library-btn"
          :options="shareBtnOptions"
          :btnProps="{
            style: 'primary',
            size: 'medium',
            label: 'share',
            hideDropdown: true,
            icon: 'ri-share-forward-fill'
          }"
          @action="openShareModal"
        />
      </template>
    </empty-activity>
  </div>
  <div class="no-activity-container" v-else data-test="no-activity-container">
    <div class="no-activity-icon">
      <i class="ri-history-line"></i>
    </div>
    <div class="no-activity-title">
      {{ $t('noActivityTitle', { type: typeName }) }}
    </div>
    <div class="no-activity-subtitle">
      {{ $t('noActivitySubtitle', { type: typeName }) }}
    </div>
    <btn-dropdown
      v-if="entityType !== 'submission'"
      class="upload-to-library-btn"
      :options="shareBtnOptions"
      :btnProps="{
        style: 'primary',
        size: 'medium',
        label: 'share',
        hideDropdown: true,
        icon: 'ri-share-forward-fill'
      }"
      @action="openShareModal"
    />
  </div>
</div>
<div v-else class="loader"><spinner-without-progress color="grey" :size="32" /></div>
