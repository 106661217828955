
<div class="tracks-details">
  <div :class="['tracks-edition', editPanelTracks.length > 0 ? 'tracks-edition-open' : '']">
    <div class="tracks-edition-wrapper">
      <edit-tracks-panel
        v-if="editPanelTracks.length > 0"
        ref="editTracksPanel"
        :libraryFileIds="editPanelTracks"
        :trackList="trackList"
        :showArrows="false"
        @toggleEditMode="toggleEditMode"
      />
    </div>
  </div>
  <div class="tracks-list">
    <div
      class="tracks-list-content"
      :style="editPanelTracks.length > 0 && 'width: calc(100% - 518px + 38px);'"
    >
      <div v-show="!LibraryFilesDetails" class="loader">
        <spinner-without-progress color="grey" :size="32" />
      </div>
      <tracks
        v-if="LibraryFilesDetails"
        :trackList="trackList"
        :isFetchingMore="loading"
        :editMode="editPanelTracks.length > 0"
        isSticky
        :stickyPosition="41"
        hasSelectAll
        @toggleEditMode="toggleEditMode"
      />
    </div>
  </div>
</div>
