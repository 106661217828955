<template>
  <div :class="checkboxStyle">
    <input
      :id="id"
      type="checkbox"
      v-model="checked"
      :disabled="disabled"
      @click.stop="handleClick"
    />
    <label :style="labelStyle" :for="id">{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: false,
      default: 0
    },
    label: {
      type: String,
      required: false
    },
    inError: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedItems: {
      type: Array,
      required: false,
      default: () => []
    },
    isChecked: {
      type: Boolean,
      required: false,
      default: false
    },
    large: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      checked: this.isChecked,
      labelStyle: {
        height: '21px',
        display: 'flex',
        alignItems: 'center',
        width: this.large ? '100%' : 'fit-content'
      }
    };
  },
  computed: {
    checkboxStyle() {
      let str = 'checkbox';
      if (!this.label) str += ' no-label';
      if (this.inError) str += ' checkbox-error';
      if (this.disabled) str += ' checkbox-disabled';
      return str;
    }
  },
  methods: {
    handleClick(e) {
      const { id, index, checked } = this;
      if (e.shiftKey) {
        this.$emit('handleCheckboxShiftClick', id, index);
      } else {
        this.$emit('toggleCheckbox', !checked, id, index);
      }
    }
  },
  watch: {
    selectedItems: {
      deep: true,
      handler() {
        this.checked = this.selectedItems.includes(this.id);
      }
    },
    isChecked() {
      this.checked = this.isChecked;
    }
  },
  created() {
    this.checked = this.selectedItems.includes(this.id) || this.isChecked;
  }
};
</script>

<style lang="scss" scoped>
[type='checkbox']:not(:checked),
[type='checkbox']:checked {
  position: absolute;
  left: -9999px;
}

[type='checkbox']:not(:checked) + label,
[type='checkbox']:checked + label {
  position: relative;
  padding-left: 24px;
  cursor: pointer;
}

[type='checkbox']:not(:checked) + label:before,
[type='checkbox']:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 14px;
  height: 14px;
  border-width: 2px;
  border-style: solid;
  border-radius: 3px;
  color: $color_neutral_40;
}

[type='checkbox']:not(:checked):focus + label:before,
[type='checkbox']:checked:focus + label:before {
  outline: 1px solid $color_primary_100;
}

[type='checkbox']:checked + label:before {
  color: $color_primary_100;
  background-color: $color_primary_100;
}
[type='checkbox']:checked:hover + label:before {
  color: $color_primary_90;
  background-color: $color_primary_90;
}

[type='checkbox']:not(:checked):hover + label:before {
  background-color: $color_neutral_5;
}

[type='checkbox']:not(:checked) + label:after,
[type='checkbox']:checked + label:after {
  content: '\eb7a';
  position: absolute;
  top: 4px;
  left: 2px;
  line-height: 14px;
  font-family: 'remixicon';
  font-size: 14px;
}

[type='checkbox']:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

[type='checkbox']:checked + label:after {
  opacity: 1;
  color: $color_neutral_0;
  font-weight: bold;
}

[type='checkbox'] + label {
  @include body-1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
}

[type='checkbox']:not(:checked) + label {
  color: $color_neutral_100;
}

[type='checkbox']:checked + label {
  color: $color_neutral_100;
}

.no-label {
  width: 20px;
}

.checkbox-error {
  [type='checkbox'] + label {
    color: $color_danger_100;
  }

  [type='checkbox']:not(:checked) + label:before,
  [type='checkbox']:checked + label:before {
    color: $color_danger_100;
    background-color: $color_neutral_0;
  }

  [type='checkbox']:checked + label:before {
    background-color: $color_danger_100;
  }
}

.checkbox-disabled {
  [type='checkbox']:not(:checked) + label,
  [type='checkbox']:checked + label {
    cursor: not-allowed;
  }
}
</style>
