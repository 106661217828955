
<div class="action-bar">
  <div class="selection-info" v-if="!isPreview">
    {{ numberOfSelection }}
    <div class="selection-info-actions">
      <div class="selection-info-actions--" @click="$emit('selectAll')">
        {{ label.selectAll }}
      </div>
      <div class="selection-info-divider"></div>
      <div class="selection-info-actions--" @click="$emit('unselect')">{{ label.unselect }}</div>
    </div>
  </div>
  <div v-else class="preview-infos">
    <div
      v-for="(action, id) in previewActions"
      class="preview-infos--"
      :key="`${id}-previewAction`"
    >
      <div class="preview-infos-btn btn" @click="action.preIcon.method">
        <i :class="action.preIcon.icon"></i>
      </div>
      <div class="preview-info">{{ action.template }}</div>
      <div class="preview-infos-btn btn" @click="action.postIcon.method">
        <i :class="action.postIcon.icon"></i>
      </div>
    </div>
  </div>
  <div class="main-actions" v-if="actions.length">
    <div class="main-actions--" v-for="(action, index) in actions" :key="`action-${index}`">
      <div
        class="main-actions-btn btn"
        :class="[{ 'action-disabled': action.disabled }]"
        v-tooltip="{
          content: action.tooltip,
          delay: { show: 500, hide: 0 },
          offset: 14
        }"
        @click="handleAction(action)"
      >
        <i :class="action.icon"></i>
      </div>
    </div>
  </div>
</div>
