<template>
  <div v-if="me" class="workspace-list">
    <div v-if="hasWorkspaceLocked" class="banner">
      <div class="banner-icon"><i class="ri-error-warning-fill" /></div>
      <div>Some of your workspaces are full. Consider upgrading your plan.</div>
    </div>
    <div
      v-for="workspace in me.workspaces"
      :key="workspace.id"
      :class="{
        'workspace-card': true,
        'selected-card': workspace.id === selectedWorkspace,
        'current-card': currentWorkspace.id === workspace.id,
        'disabled-card':
          (currentWorkspace.id === workspace.id && disableCurrent) || workspace.is_locked
      }"
      @click="handleCardClick(workspace)"
      v-tooltip="{
        content: cardTooltip(workspace)
      }"
    >
      <avatar
        class="workspace-logo"
        :pictureUrl="vueGet(workspace, 'logo.url', null)"
        :initial="workspace.name.substring(0, 1).toUpperCase()"
        square
      />
      <div class="workspace-name">
        {{ workspace.name }}
      </div>
      <div v-if="currentWorkspace.id === workspace.id" class="current-badge">
        <i class="ri-checkbox-circle-fill"></i>Current
      </div>
      <icon-button
        class="dive-btn"
        v-if="currentWorkspace.id === workspace.id && !disableCurrent"
        icon="ri-arrow-right-s-line"
        btnStyle="secondary"
        size="small"
      />
    </div>
  </div>
  <div v-else class="workspace-list-loader">
    <spinner-without-progress color="grey" :size="32" />
  </div>
</template>

<script>
import get from 'lodash.get';
import { mapGetters } from 'vuex';

export default {
  props: {
    disableCurrent: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      selectedWorkspace: null
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'me']),
    hasWorkspaceLocked() {
      return Object.values(this.me.workspaces).find(ws => ws.is_locked === true) !== undefined;
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    vueGet(base, path, fallback) {
      return get(base, path, fallback);
    },
    handleCardClick(workspace) {
      if (workspace.is_locked) {
        return;
      }
      if (workspace.id === this.currentWorkspace.id) {
        if (this.disableCurrent) {
          return;
        }
        this.changeDisplayedWorkspace(workspace);
        return;
      }
      this.selectedWorkspace = workspace.id;
      this.$emit('changeSelectedWorkspace', workspace.id, workspace.name);
    },
    changeDisplayedWorkspace(workspace) {
      this.$emit('changeDisplayedWorkspace', workspace);
    },
    cardTooltip(ws) {
      if (this.currentWorkspace.id === ws.id && this.disableCurrent) {
        return 'You cannot move this project to current workspace';
      }
      if (ws.is_locked) {
        return 'Workspace full';
      }
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
.workspace-list {
  max-height: 400px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;
  &-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
  .banner {
    width: 348px;
    box-sizing: border-box;
    padding: 8px 16px;
    background-color: $color_warning_10;
    color: $color_warning_110;
    line-height: 150%;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 16px;
    &-icon {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      border-radius: 40px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      background-color: rgba(255, 197, 51, 0.1);
      color: $color_warning_100;
      i {
        margin-right: -1px;
      }
    }
  }
}
.workspace-card {
  width: 330px;
  height: 56px;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid $color_neutral_30;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  .dive-btn {
    display: none;
  }
  &:hover {
    background: $color_neutral_10;
    .dive-btn {
      display: flex;
    }
  }
}
.selected-card {
  border: 1px solid $color_primary_100;
  color: $color_primary_100;
  background: $color_primary_10;
  &:hover {
    background: $color_primary_10;
  }
}
.workspace-logo {
  margin-right: 8px;
}
.workspace-name {
  flex: 1;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.current-card {
  .workspace-name {
    max-width: 178px;
  }
}
.current-badge {
  height: 24px;
  padding: 4px;
  border-radius: 19px;
  border: 1px solid $color_primary_20;
  background-color: $color_primary_10;
  color: $color_primary_100;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-size: 10px;
  font-weight: 600;
  // opacity: 30%;
  margin-right: 8px;
  .ri-checkbox-circle-fill {
    font-size: 15px;
    margin-right: 2px;
  }
}
.disabled-card {
  opacity: 30%;
  cursor: not-allowed;
  user-select: none;
}
</style>

<style lang="scss">
.workspace-card:hover .dive-btn.rounded-button.secondary.small {
  background: $color_neutral_30;
}
.dive-btn.rounded-button.secondary.small {
  border: none;
  &:hover {
    background: $color_neutral_30;
  }
  i.icon {
    font-size: 16px;
  }
}
</style>
