
<div v-if="TagList" class="tags-panel-body" :key="renderKey">
  <div v-if="!dismissed">
    <panel-information
      :canClose="true"
      :onDismiss="dismissPanelInformation"
      :message="$t('changesForEveryMember')"
    />
  </div>
  <div class="tags-panel-body-dropdown">
    <select-tags
      class="tags-panel-body-dropdown-select"
      :options="TagList.categories"
      :checkedOptions="checkedTags"
      :label="$t('addTags')"
      :placeholder="$t('searchTags')"
      @selectTags="selectTags"
    />
  </div>
  <div
    v-if="!isMultipleTrackEdition && currentWorkspace.properties.auto_tagger && this.isAutoTagged"
    class="tags-panel-body-feedback"
    @click="openFeedbackAI"
  >
    <div class="tags-panel-body-feedback-icon">
      <i class="ri-information-fill" />
    </div>
    <div class="tags-panel-body-feedback-label">Give your feedback on the auto-tagging</div>
  </div>
  <div class="tags-panel-body-categories">
    <div
      class="tags-panel-body-categories-family"
      v-for="(tagCategory, index) in TagList.categories"
      :key="tagCategory.id"
      :style="`z-index: ${TagList.categories.length - index}`"
    >
      <tag-category
        :tagCategory="tagCategory"
        :checkedTags="checkedTags"
        :aiTags="aiTags"
        @selectTags="selectTags"
      />
    </div>
  </div>
  <div class="tags-panel-body-manage">
    <submit-button
      btnStyle="tertiary"
      size="small"
      exception
      :label="$t('manageCustomTags')"
      iconClass="ri-equalizer-fill"
      @submit="manageCustomTags"
    />
  </div>
  <div class="tags-panel-body-remove">
    <submit-button
      v-if="checkedTags.length > 0 || isMultipleTrackEdition"
      btnStyle="secondary"
      size="small"
      :label="$tc('removeTracksTags', tracksToEdit.length)"
      @submit="removeAllTags"
    />
  </div>
</div>
