<template>
  <div class="dropdown" v-click-outside="{ hide }">
    <div class="dropdown-button" @click="toggleExpand">
      <avatar :pictureUrl="pictureUrl" :initial="initial" pictureSize="workspaceSmall" square />
      <div class="dropdown-button-name">
        <span class="dropdown-button-name-label" :title="name">{{ name }}</span>
        <i class="ri-arrow-drop-down-line" />
      </div>
    </div>
    <div
      class="dropdown-expanded"
      :class="!isExpanded && 'dropdown-close'"
      :style="`max-height: ${height}px;`"
    >
      <div class="dropdown-expanded-options" @click="hide" v-if="isExpanded">
        <div class="dropdown-expanded-options-header">
          <div class="dropdown-expanded-options-header-left">
            <avatar :pictureUrl="pictureUrl" :initial="initial" square />
          </div>
          <div class="dropdown-expanded-options-header-right">
            <div class="dropdown-expanded-options-header-right-title">{{ headerOption.title }}</div>
            <div class="dropdown-expanded-options-header-right-subtitle">
              {{ headerOption.subtitle }}
            </div>
          </div>
        </div>
        <div
          class="dropdown-expanded-options-item"
          v-for="(option, index) in options"
          :key="`opt-${index}`"
          @click="option.action"
        >
          <i v-if="option.icon" :class="option.icon" class="dropdown-expanded-options-item-icon" />
          <span class="dropdown-expanded-options-item-label">{{ option.label }}</span>
        </div>
        <ScrollShadow style="max-height: 155px">
          <div
            v-if="(workspaces && workspaces.length > 1) || (hubs && hubs.length > 0)"
            class="dropdown-expanded-options-workspaces"
          >
            <div class="dropdown-expanded-options-workspaces-title">WORKSPACES</div>
            <div class="dropdown-expanded-options-workspaces-logo">
              <div
                class="dropdown-expanded-options-workspaces-logo-item"
                v-for="(workspace, index) in workspaces"
                :key="`work-${index}`"
                @click="$emit('handleWorkspaceClick', index)"
                v-tooltip="workspace.name"
              >
                <avatar
                  :pictureUrl="workspace.pictureUrl"
                  :initial="workspace.name.substring(0, 1).toUpperCase()"
                  pictureSize="small"
                  square
                  hasHover
                  :isCurrent="workspace.id === currentWorkspaceId"
                />
              </div>
            </div>
          </div>
        </ScrollShadow>
        <div v-if="hubs && hubs.length > 0" class="dropdown-expanded-options-workspaces">
          <div class="dropdown-expanded-options-workspaces-title">HUBS</div>
          <div class="dropdown-expanded-options-workspaces-logo">
            <div
              class="dropdown-expanded-options-workspaces-logo-item"
              v-for="(hub, index) in hubs"
              :key="`work-${index}`"
              @click="$emit('handleHubClick', index)"
              v-tooltip="hub.name"
            >
              <avatar
                :pictureUrl="hub.logo"
                :initial="hub.name.substring(0, 1).toUpperCase()"
                pictureSize="small"
                square
                hasHover
              />
            </div>
          </div>
        </div>
        <div class="dropdown-expanded-options-footer" @click="footerOption.action">
          <div class="dropdown-expanded-options-footer-button">
            <i class="ri-add-fill" />
          </div>
          <div class="dropdown-expanded-options-footer-title">{{ footerOption.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { VTooltip } from 'v-tooltip';
import { clickOutside } from '../../utils/directives';
import Avatar from '../avatar';
import ScrollShadow from '../scrollShadow';

Vue.directive('tooltip', VTooltip);

export default {
  components: {
    ScrollShadow,
    Avatar
  },
  props: {
    name: {
      type: String,
      required: true
    },
    currentWorkspaceId: {
      type: String,
      required: true
    },
    pictureUrl: {
      type: String,
      required: false,
      default: ''
    },
    initial: {
      type: String,
      required: false,
      default: ''
    },
    headerOption: {
      type: Object,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    footerOption: {
      type: Object,
      required: true
    },
    workspaces: {
      type: Array,
      required: false,
      default: () => []
    },
    hubs: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      isExpanded: false
    };
  },
  computed: {
    height() {
      return this.isExpanded ? 500 : 0;
    }
  },
  directives: {
    clickOutside
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    hide() {
      this.isExpanded = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  @include body-1;
  color: $color_neutral_100;
  width: 100%;
  height: 100%;

  &-close {
    opacity: 0;
  }

  &-button {
    padding-left: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    &-name {
      display: flex;
      align-items: center;
      margin: 0 8px;
      font-weight: 600;
      &-label {
        max-width: 105px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }

      .ri-arrow-drop-down-line {
        font-size: 22px;
      }
    }
  }

  &-expanded {
    position: absolute;
    top: 52px;
    left: 16px;
    min-width: 215px;
    height: auto;
    z-index: 1000;
    white-space: nowrap;
    background-color: $color_neutral_0;
    box-sizing: border-box;
    border: 1px solid $color_neutral_30;
    border-radius: 4px;
    @include shadow-down-03;
    overflow: hidden;

    &-options {
      padding: 0;

      &-header {
        padding: 16px;
        border-bottom: 1px dashed $color_neutral_30;
        display: flex;
        align-items: center;

        &-right {
          margin: 0 8px 0;

          &-title {
            font-weight: 600;
            max-width: 180px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &-subtitle {
            @include body-2;
            color: $color_neutral_60;
            margin-top: 4px;
          }
        }
      }

      &-item {
        padding: 16px;
        border-bottom: 1px solid $color_neutral_30;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:last-child {
          border-bottom: none;
        }

        &-icon {
          font-size: 16px;
          padding: 0 8px 0 0;
        }

        &:hover {
          background-color: $color_neutral_10;
        }
      }

      &-workspaces {
        padding: 12px 16px 8px 16px;
        width: 232px;
        background-color: $color_neutral_0;
        overflow: auto;

        &-title {
          padding: 0 0 12px 0;
          @include subtitle-2;
          color: $color_neutral_60;
        }

        &-logo {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          grid-gap: 6px;

          &-item {
            cursor: pointer;
          }
        }
      }

      &-footer {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 16px;
        color: $color_primary_100;
        border-top: 1px solid $color_neutral_30;
        &:hover {
          background-color: $color_neutral_10;
        }
        &:hover .dropdown-expanded-options-footer-button {
          background-color: $color_primary_100;
          color: $color_neutral_0;
        }
        &-button {
          width: 32px;
          height: 32px;
          border: 1px solid $color_primary_100;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 8px 0 0;
          transition: all ease-out 0.1s;
          .ri-add-fill {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
