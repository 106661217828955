import { appConfig } from '../utils/constants';

export const routes = {
  main: '/',
  signup: '/signup',
  createWorkspaceFromSignup: '/signup/workspace/create',
  workspacePictureFromSignup: '/signup/workspace/:id/update-logo',
  workspaceInvitationFromSignup: '/signup/workspace/:id/invitation',
  workspace: '/workspace',
  createWorkspace: '/workspace/create',
  workspacePicture: '/workspace/:id/update-logo',
  workspaceInvitation: '/workspace/:id/invitation',
  library: '/library',
  tracks: '/library/tracks',
  trackList: '/library/tracks/list',
  trackSearch: '/library/tracks/search',
  track: '/library/tracks/:id',
  artists: '/library/artists',
  artist: '/library/artist/:id',
  artistAlbums: '/library/artist/:id/albums',
  artistTracks: '/library/artist/:id/tracks',
  artistSharings: '/library/artist/:id/sharings',
  artistActivity: '/library/artist/:id/activity',
  albums: '/library/albums',
  album: '/library/album/:id',
  albumDetails: '/library/album/:id/details',
  albumSharings: '/library/album/:id/sharings',
  albumActivity: '/library/album/:id/activity',
  projects: '/projects',
  project: '/projects/:id',
  projectDetails: '/projects/:id/details',
  projectDetailsBrowse: '/projects/:id/details/browse',
  projectDetailsBrowseList: '/projects/:id/details/browse/list',
  projectDetailsBrowseSearch: '/projects/:id/details/browse/search',
  projectSharings: '/projects/:id/sharings',
  projectStatistics: '/projects/:id/statistics',
  projectActivity: '/projects/:id/activity',
  activeProjects: '/projects/active',
  archiveProjects: '/projects/archive',
  shared: '/shared',
  sharedList: '/shared/list',
  sharedTracks: '/shared/library/tracks/:id',
  sharedTracksDetails: '/shared/library/tracks/:id/details',
  sharedTracksSharings: '/shared/library/tracks/:id/sharings',
  sharedTracksActivity: '/shared/library/tracks/:id/activity',
  sharedSubmission: '/shared/submission/:id',
  sharedSubmissionDetails: '/shared/submission/:id/details',
  sharedSubmissionActivity: '/shared/submission/:id/activity',
  sharedProjectDetails: '/shared/projects/:id/details',
  sharedProjectSharings: '/shared/projects/:id/sharings',
  sharedProjectActivity: '/shared/projects/:id/activity',
  sharedProjectDetailsBrowseList: '/shared/projects/:id/details/browse/list',
  sharedProjectDetailsBrowseSearch: '/shared/projects/:id/details/browse/search',
  sharedAlbum: '/shared/library/album/:id',
  sharedAlbumDetails: '/shared/library/album/:id/details',
  sharedAlbumSharings: '/shared/library/album/:id/sharings',
  sharedAlbumActivity: '/shared/library/album/:id/activity',
  sharedArtistAlbums: '/shared/library/artist/:id/albums',
  sharedArtistTracks: '/shared/library/artist/:id/tracks',
  sharedArtistSharings: '/shared/library/artist/:id/sharings',
  sharedArtistActivity: '/shared/library/artist/:id/activity',
  contacts: '/contacts',
  settings: '/settings',
  accountSettings: '/settings/account',
  generalSettings: '/settings/general',
  membersSettings: '/settings/members',
  billingSettings: '/settings/billing',
  offersSettings: '/settings/billing/offers',
  paymentSettings: '/settings/billing/payment',
  inbox: '/inbox',
  tagsSettings: '/settings/tags',
  workspaceId: '/r/workspace/:id',
  account: appConfig.AccountBaseUrl,
  logout: `${appConfig.AccountBaseUrl}/logout`
};

export const get = (name, param) => (param ? routes[name].replace(':id', param.id) : routes[name]);
