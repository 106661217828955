<template>
  <div style="position: relative">
    <slot name="list"></slot>
    <observer
      :key="`${listQuery.key}-${page}`"
      v-if="values && !loading && page < lastPage"
      :height="40"
      bottom
      @intersect="loadMore"
    />
  </div>
</template>

<script>
import Observer from '@/containers/observer';
import { apolloClient } from '@/main';

export default {
  components: {
    Observer
  },
  props: {
    listQuery: {
      type: Object,
      required: true
    },
    toCheck: {
      type: String,
      required: false,
      default: 'data'
    },
    forceLastPage: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      page: 1,
      lastPage: 1,
      forcedLoad: false
    };
  },
  apollo: {
    values: {
      query() {
        return this.listQuery.query;
      },
      // necessary to udpate cache when copy track to another workspace  of already cached target workspace
      // may cause issue, with track deletion for example
      fetchPolicy: 'cache-and-network',
      variables() {
        return this.listQuery.variables;
      },
      update(data) {
        return data[this.listQuery.key];
      },
      watchLoading(loading) {
        this.loading = loading;
        this.$emit('setLoading', loading);
        if (this.forcedLoad) {
          this.$root.$emit('infiniteScrollLoading', loading);
        }
      },
      result(response) {
        this.handleResult(response);
        this.$store.commit('changeCurrentDisplayListPaginationStatus', {
          current: this.page,
          last: this.lastPage
        });
        if (this.forcedLoad) {
          this.$nextTick(() => {
            this.$root.$emit('goToNextMeta', this.page, this.lastPage);
            this.forcedLoad = false;
          });
        }
      }
    }
  },
  mounted() {
    this.$root.$on('forceLoadMore', () => {
      this.forcedLoad = true;
      this.loadMore();
    });
  },
  created() {
    this.getCurrentPage();
    this.setOptions();
  },
  beforeDestroy() {
    this.$store.commit('changeCurrentDisplayListPaginationStatus', {
      current: null,
      last: null
    });
    this.$root.$off('forceLoadMore');
  },
  watch: {
    listQuery() {
      this.page = 1;
    }
  },
  methods: {
    resetPage() {
      // used in src/views/main/projects/projectDetails/details/index by ref
      // resolve BRID-3294
      // https://bridgeaudio.atlassian.net/browse/BRID-3294
      this.page = 1;
    },
    setOptions() {
      const { options } = this.listQuery;
      if (options) {
        this.$apollo.queries.values.setOptions({ ...options });
      }
    },
    getCurrentPage() {
      // On doit récupérer la page courante afin de reprendre la pagination là où on l'a laissé
      let storedQuery = null;
      try {
        const variables =
          this.listQuery.pagination === 'page'
            ? {
                ...this.listQuery.variables,
                page: 1
              }
            : {
                ...this.listQuery.variables,
                offset: 0
              };
        storedQuery = apolloClient.readQuery({
          query: this.listQuery.query,
          variables
        });
      } catch {} //eslint-disable-line
      try {
        this.page = storedQuery
          ? Math.ceil(
              storedQuery[this.listQuery.key][this.toCheck].length / this.listQuery.variables.limit
            )
          : 1;
      } catch {
        this.page = 1;
      }
    },
    handleResult(response) {
      // On peut avoir une query qui utilise un paramètres offset ou page
      if (this.values) {
        this.lastPage =
          !this.forceLastPage && this.listQuery.pagination === 'page'
            ? this.values.last_page
            : Math.ceil(this.values.total_tracks / this.listQuery.variables.limit);
      }
      this.$emit('handleQueryResult', response);
    },
    async loadMore() {
      this.page += 1;
      // Obligé de faire car on peut avoir une query qui utilise un paramètres offset au lieu de page
      const variables =
        this.listQuery.pagination === 'page'
          ? {
              ...this.listQuery.variables,
              page: this.page
            }
          : {
              ...this.listQuery.variables,
              offset: (this.page - 1) * this.listQuery.variables.limit
            };
      // prevent observableQuery with this id doesn't exist with try catch
      try {
        await this.$apollo.queries.values.fetchMore({
          variables,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newItems =
              fetchMoreResult &&
              fetchMoreResult[this.listQuery.key] &&
              fetchMoreResult[this.listQuery.key][this.toCheck];
            if (newItems && newItems.length === 0) {
              return previousResult;
            }
            if (this.listQuery.pagination === 'page') {
              return {
                [this.listQuery.key]: {
                  ...previousResult[this.listQuery.key],
                  [this.toCheck]: [
                    ...previousResult[this.listQuery.key][this.toCheck],
                    ...fetchMoreResult[this.listQuery.key][this.toCheck]
                  ]
                }
              };
            }
            return {
              [this.listQuery.key]: {
                ...previousResult[this.listQuery.key],
                data: {
                  ...previousResult[this.listQuery.key].data,
                  tracks: [
                    ...previousResult[this.listQuery.key].data.tracks,
                    ...fetchMoreResult[this.listQuery.key].data.tracks
                  ]
                }
              }
            };
          }
        });
      } catch {} //eslint-disable-line
    }
  }
};
</script>
