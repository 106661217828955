
<div v-if="me" class="workspace-list">
  <div v-if="hasWorkspaceLocked" class="banner">
    <div class="banner-icon"><i class="ri-error-warning-fill" /></div>
    <div>Some of your workspaces are full. Consider upgrading your plan.</div>
  </div>
  <div
    v-for="workspace in me.workspaces"
    :key="workspace.id"
    :class="{
      'workspace-card': true,
      'selected-card': workspace.id === selectedWorkspace,
      'current-card': currentWorkspace.id === workspace.id,
      'disabled-card':
        (currentWorkspace.id === workspace.id && disableCurrent) || workspace.is_locked
    }"
    @click="handleCardClick(workspace)"
    v-tooltip="{
      content: cardTooltip(workspace)
    }"
  >
    <avatar
      class="workspace-logo"
      :pictureUrl="vueGet(workspace, 'logo.url', null)"
      :initial="workspace.name.substring(0, 1).toUpperCase()"
      square
    />
    <div class="workspace-name">
      {{ workspace.name }}
    </div>
    <div v-if="currentWorkspace.id === workspace.id" class="current-badge">
      <i class="ri-checkbox-circle-fill"></i>Current
    </div>
    <icon-button
      class="dive-btn"
      v-if="currentWorkspace.id === workspace.id && !disableCurrent"
      icon="ri-arrow-right-s-line"
      btnStyle="secondary"
      size="small"
    />
  </div>
</div>
<div v-else class="workspace-list-loader">
  <spinner-without-progress color="grey" :size="32" />
</div>
