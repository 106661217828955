<template>
  <div class="transfer-ownership-modal">
    <div class="transfer-ownership-modal-body">
      <div class="transfer-ownership-modal-body--">
        {{ $t('transferOwnershipConfirmation') }}
        <span class="bold">"{{ info.workspaceName }}" </span>
        {{ $t('to') }}
        <span class="bold">{{ info.newOwner }} </span>
        <span class="grey">({{ info.newOwnerMail }}) </span>
        ?
      </div>
      <div>
        <text-field
          ref="inputPassword"
          :label="$t('enterPassword')"
          :large="false"
          :fieldModel.sync="password"
          :errorMessage="passwordErrorMessage"
          @handleInput="handlePasswordInput"
        />
      </div>
    </div>
    <div class="transfer-ownership-modal-buttons">
      <div class="transfer-ownership-modal-buttons--">
        <submit-button btnStyle="tertiary" :label="$t('cancel')" @submit="closeModal" />
      </div>
      <div class="transfer-ownership-modal-buttons--">
        <submit-button
          :label="$t('yesContinue')"
          :disabled="passwordErrorMessage !== null"
          @submit="confirmTransfer"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { bus } from '@/utils/bus';
import { ME } from '@/graphql/queries/user';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import { WORKSPACE_TRANSFER_OWNERSHIP } from '@/graphql/mutations/workspace';

export default {
  props: {
    onSubmit: {
      type: Function,
      required: false,
      default: () => {}
    },
    info: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      password: null,
      invalidPassword: false
    };
  },
  validations: {
    password: {
      required
    }
  },
  computed: {
    passwordErrorMessage() {
      if (!this.$v.password.required) {
        return this.$t('required', { label: this.$t('password') });
      }
      if (this.invalidPassword) {
        return this.$t('invalidPassword');
      }
      return null;
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    handlePasswordInput() {
      this.invalidPassword = false;
    },
    confirmTransfer() {
      this.$apollo
        .mutate({
          mutation: WORKSPACE_TRANSFER_OWNERSHIP,
          variables: {
            workspaceId: this.info.workspaceId,
            ownerCurrentPassword: this.password,
            newOwnerId: this.info.id
          },
          refetchQueries: [
            {
              query: ME
            }
          ]
        })
        .then(() => {
          bus.$emit('showAlert', {
            message: { key: 'transferOwnershipSuccess' },
            style: 'success',
            delay: 5000
          });
          this.$emit('closeModal');
        })
        .catch(error => {
          const errorKey = getGraphQlErrorCode(error);
          if (errorKey === 'INSUFFICIENT_PERMISSIONS') {
            this.invalidPassword = true;
            this.$nextTick(() => {
              this.$refs.inputPassword.handleBlurEvent();
            });
            return;
          }
          bus.$emit('showAlert', {
            message: { key: errorKey },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.transfer-ownership-modal {
  &-body {
    padding: 32px 40px 40px;
    line-height: 150%;
    &-- {
      margin-bottom: 24px;
    }
  }
  &-buttons {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $color_neutral_30;
    padding: 8px 32px;

    &-- {
      &:first-child {
        margin: 0 16px 0 0;
      }
    }
  }
}
.bold {
  font-weight: 600;
}
.grey {
  color: $color_neutral_60;
}
</style>
