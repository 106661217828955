
<div>
  <text-field
    v-if="disableDatePicker"
    style="width: 100%"
    inputType="text"
    :placeholder="selectedDate ? formatTimestamp(selectedDate) : '< multiple >'"
    :withEditButton="true"
    @editBtnClick="handleEditBtnClick"
    :editButtonEmit="true"
  />
  <div v-else :class="{ 'date-picker': true, large: large }">
    <datepicker
      ref="vjsDatepicker"
      format="dd/MM/yyyy"
      monday-first
      :value="selectedDate"
      :language="lang"
      :disabled="isDisabled"
      :disabled-dates="disabledDates"
      @selected="datePicked"
      @keydown.native="handleBackSpace"
    ></datepicker>
    <i class="ri-calendar-2-line" @click="openPicker"></i>
  </div>
</div>
