
<div class="shared" v-if="AggregatedSharesList">
  <div v-if="AggregatedSharesList.length > 0" data-test="sharedList">
    <shared-list
      v-if="AggregatedSharesList"
      :shares="AggregatedSharesList"
      :membersList="currentWorkspace.members"
      :loading="loading"
      @goToPage="goToPage"
      @goToShareSettings="goToShareSettings"
      @goToActivities="goToActivities"
    />
    <observer
      :key="`asl-${page}`"
      v-if="AggregatedSharesList && !loading && !isLastPage"
      :height="40"
      bottom
      @intersect="fetchMore"
    />
  </div>
  <div class="no-shared" v-else>
    <empty-shared
      :title="$t('emptySharedTitle')"
      :description="$t('emptySharedSubtext')"
      placeholderType="sharedlist"
      :cloudedTextContent="$t('manageLinks')"
      :cloudedTextColor="{
        stroke: '#FF3364',
        fill: '#FFF0F3'
      }"
    >
    </empty-shared>
  </div>
</div>
<div v-else class="loader">
  <spinner-without-progress color="grey" :size="32" />
</div>
