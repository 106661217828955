<template>
  <div class="members-limit-modal">
    <div class="members-limit-modal-body">
      <span>{{ $t('membersLimitDesc') }}</span>
      <span v-if="isEditor" class="members-limit-modal-body-normal"
        >{{ $t('upgradeByAdmin.firstPart') }}
        <span class="members-limit-modal-body-bold">{{ $t('upgradeByAdmin.boldPart') }}</span>
        <span class="members-limit-modal-body-normal">{{
          $t('upgradeByAdmin.thirdPartToInviteNewMembers')
        }}</span></span
      >
      <span v-else class="members-limit-modal-body-bold"
        >{{ $t('pleaseUpgrade')
        }}<span class="members-limit-modal-body-normal">{{ $t('inviteNewMembers') }}</span></span
      >
    </div>
    <div class="members-limit-modal-buttons">
      <div class="members-limit-modal-buttons--">
        <submit-button
          btnStyle="tertiary"
          size="medium"
          :label="$t('cancel')"
          @submit="closeModal"
        />
      </div>
      <div
        class="members-limit-modal-buttons--"
        v-tooltip="{
          content: isEditor && $t('upgradePlanByAdmin'),
          classes: 'alert-editor-tooltip'
        }"
      >
        <submit-button
          btnStyle="primary"
          size="medium"
          :label="$t('upgradePlan')"
          @submit="submit"
          :disabled="isEditor"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { get as getRoute } from '@/router/routes';

export default {
  props: {
    isEditor: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    submit() {
      this.$emit('closeModal');
      this.$router.push(getRoute('offersSettings'));
    }
  }
};
</script>

<style lang="scss" scoped>
.members-limit-modal {
  &-body {
    font-size: 14px;
    padding: 24px;
    line-height: 150%;
    &-bold {
      font-weight: 600;
    }
    &-normal {
      font-weight: normal;
    }
  }
  &-buttons {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $color_neutral_30;
    padding: 8px 24px;

    &-- {
      &:first-child {
        margin: 0 16px 0 0;
      }
    }
  }
}
</style>
<style lang="scss">
.alert-editor-tooltip.tooltip {
  max-width: 140px;
  .tooltip-inner {
    text-align: center;
  }
}
</style>
