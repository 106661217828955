
<div v-if="projects.length > 0" data-test="projectsList">
  <header-list
    :columns="columns"
    actionUnavailable
    :sortColumn="false"
    :itemsSelected="[]"
    :isSticky="true"
  />
  <div v-for="(project, idx) in formatedProjectsList" :key="project.id" data-test="projects">
    <list-row
      :class="{ 'row-container': active }"
      :item="project"
      :columns="columns"
      :rowIndex="idx"
      :height="64"
      :id="project.id"
      disableSelection
      @click.native="handleRowClick($event, project.id)"
      @handleEmit="handleEmit"
    />
  </div>
</div>
