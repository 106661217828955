<template>
  <div v-if="trackList.length > 0 && me" data-test="full-tracklist-container">
    <header-list
      :columns="columns"
      :sortColumn="sortColumn"
      :isSticky="isSticky"
      :stickyPosition="stickyPosition"
      :headerButtons="headerButtons"
      :headerCountLabel="headerCountLabel"
      :hasSelectAll="hasSelectAll"
      :itemsSelected="hasActionBar || hideHeaderBtn ? [] : selectedTracks"
      :displayUnselect="selectedTracks.length > 0"
      defaultSortedColumn="added"
      defaultOrderBy="asc"
      @handleEmit="handleEmit"
      @uncheckAllCheckbox="uncheckAllCheckbox"
      @selectAll="checkAllCheckbox"
    />
    <div v-if="uploadIsInprogress">
      <skeleton-row :columns="columns" :height="48" />
    </div>
    <div v-for="(track, idx) in trackList" :key="track.id">
      <list-row
        :item="track"
        :columns="columns"
        :rowIndex="idx"
        :height="48"
        :id="track.id"
        :playingTrack="playingTrack || {}"
        :selectedItems="selectedTracks"
        :disableSelection="isSelectionDisabled"
        :isUploading="uploadIsInprogress"
        :isFetchingMore="isFetchingMore && !uploadIsInprogress"
        @handleEmit="handleEmit"
        @sortTracks="sortTracks"
        @setPlayingTrackFromRow="setPlayingTrackFromRow"
      />
    </div>
    <div v-if="isFetchingMore">
      <skeleton-row :columns="columns" :height="48" />
    </div>
    <div
      v-if="selectedTracks.length && hasActionBar"
      class="action-bar-container"
      :class="{ 'panel-open': isEditPanelOpened }"
    >
      <action-bar-list
        :trackList="trackList"
        :trackListFromSearch="trackListFromSearch"
        :selectedTracks="selectedTracks"
        @toggleEditModeFromActionBar="toggleEditMode(true)"
        @deleteFromActionBar="deleteTracks"
        @createProject="createProject"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash.get';

import { get as getRoute } from '@/router/routes';
import { bus } from '@/utils/bus';
import { trackEvent } from '@/utils/functions/analytics';
import { formatNumber } from '@/utils/functions/global';
import ActionBarList from '@/containers/actionBarList';
import StreamableUnavailable from '@/containers/modals/streamableUnavailable';
import MoveToAddTo from '@/containers/modals/moveToAddTo';

import { LIBRARY_FILE_STREAMABLE } from '@/graphql/queries/library';

import ShareModal from '@/containers/modals/share';

import { clickOutside } from '@/utils/directives';

export default {
  components: {
    ActionBarList
  },
  directives: {
    clickOutside
  },
  props: {
    trackList: {
      type: Array,
      required: false,
      default: () => []
    },
    keepProjectDropdownExpand: {
      type: Boolean,
      required: false,
      default: false
    },
    isFetchingMore: {
      type: Boolean,
      required: false,
      default: false
    },
    uncheckTracks: {
      type: Boolean,
      required: false,
      default: false
    },
    projectList: {
      type: Array,
      required: false,
      default: () => []
    },
    noProjectSearchResult: {
      type: Boolean,
      required: false,
      default: false
    },
    showProjectObserver: {
      type: Boolean,
      required: false,
      default: false
    },
    loadingProjects: {
      type: Boolean,
      required: false,
      default: false
    },
    fetchingMoreProjects: {
      type: Boolean,
      required: false,
      default: false
    },
    withArtwork: {
      type: Boolean,
      required: false,
      default: false
    },
    withAlbumTrackNumber: {
      type: Boolean,
      required: false,
      default: false
    },
    isSticky: {
      type: Boolean,
      required: false,
      default: false
    },
    stickyPosition: {
      type: Number,
      required: false,
      default: 0
    },
    sortColumn: {
      type: Boolean,
      required: false,
      default: false
    },
    hasSelectAll: {
      type: Boolean,
      required: false,
      default: false
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false
    },
    hasActionBar: {
      type: Boolean,
      required: false,
      default: true
    },
    hideHeaderBtn: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      keepProjectDropdownExpanded: this.keepProjectDropdownExpand,
      selectedTracks: [],
      selectedTracksMemory: [],
      isHoverId: null,
      newProjectId: '',
      isSelectionDisabled: false,
      fromTo: [],
      trackListFromSearch: []
    };
  },
  watch: {
    keepProjectDropdownExpand() {
      this.keepProjectDropdownExpanded = this.keepProjectDropdownExpand;
    },
    uncheckTracks() {
      if (this.uncheckTracks) this.uncheckAllCheckbox();
    },
    selectedTracks() {
      this.$emit('setArtistAlbumSelectedTracks', this.selectedTracks);
    }
  },
  computed: {
    ...mapGetters([
      'me',
      'playlist',
      'playingTrack',
      'uploadIsInprogress',
      'currentWorkspace',
      'isEditPanelOpened',
      'searchLibraryQueryParameters',
      'isPlayerOnError'
    ]),
    columns() {
      let artwork = this.withArtwork
        ? {
            name: 'artwork',
            match: 'imgSrc',
            value: null
          }
        : {
            name: 'trackIndex',
            match: 'index',
            value: null
          };
      if (this.withAlbumTrackNumber) {
        artwork = {
          name: 'trackNumber',
          match: 'supportTrackPosition',
          value: null
        };
      }
      return [
        {
          type: 'component',
          name: 'checkbox',
          path: 'checkbox',
          ref: 'checkbox',
          preventDefault: true,
          header: null,
          noSort: true,
          min: '28px',
          max: '28px',
          noBorder: true,
          isHover: true,
          span: 1,
          events: ['toggleCheckbox', 'handleCheckboxShiftClick'],
          props: [
            {
              name: 'id',
              match: 'id',
              value: null
            },
            {
              name: 'index',
              match: 'index',
              value: null
            },
            {
              name: 'selectedItems',
              match: 'selectedTracks',
              value: this.selectedTracks
            }
          ]
        },
        ...(!this.withArtwork
          ? [
              {
                type: 'component',
                name: 'artwork',
                path: 'artwork',
                header: '#',
                noSort: true,
                noSeparator: true,
                skeleton: 'square',
                min: '40px',
                max: '40px',
                span: 1,
                emits: ['setPlayingTrack'],
                props: [
                  {
                    name: 'trackId',
                    match: 'id',
                    value: null
                  },
                  artwork,
                  {
                    name: 'playingTrack',
                    match: 'playingTrack',
                    value: this.playingTrack || {}
                  },
                  {
                    name: 'isHoverId',
                    match: 'isHoverId',
                    value: this.isHoverId
                  },
                  {
                    name: 'isPlayerOnError',
                    match: 'isPlayerOnError',
                    value: this.isPlayerOnError
                  }
                ]
              }
            ]
          : []),
        {
          type: 'component',
          name: 'text-action',
          header: this.$t('track'),
          skeleton: 'line',
          min: '200px',
          max: '2fr',
          padding: [0, 8, 0, 8],
          noStop: true,
          span: 5,
          containerWidth: true,
          emits: ['setPlayingTrack'],
          props: [
            {
              name: 'rowId',
              match: 'id',
              value: null
            },
            {
              name: 'isHoverId',
              match: 'isHoverId',
              value: this.isHoverId
            },
            {
              name: 'text',
              match: 'title',
              value: null
            },
            artwork,
            {
              name: 'playingTrack',
              match: 'playingTrack',
              value: this.playingTrack || {}
            },
            {
              name: 'weightNormal',
              match: 'weightNormal',
              value: true
            }
          ]
        },
        {
          type: 'component',
          name: 'moreDropdownShare',
          path: 'moreDropdown',
          stopPropagation: true,
          tooltip: this.$t('share'),
          header: null,
          noHeader: true,
          noSort: true,
          onHover: true,
          min: '36px',
          max: '36px',
          isExpandHover: true,
          events: ['setMoreDropdownState', 'selectItemFromDropdown'],
          props: [
            {
              name: 'icon',
              match: 'icon',
              value: 'ri-share-forward-fill'
            },
            {
              name: 'items',
              match: 'items',
              value: [
                {
                  icon: 'ri-flashlight-fill',
                  name: this.$t('getQuickLink'),
                  subText: this.$t('getQuickLinkSub'),
                  keepExpand: false,
                  action: trackId => {
                    this.handleShareBtnClick([trackId], true, false, 0);
                  }
                },
                {
                  separator: true
                },
                {
                  icon: 'ri-links-fill',
                  name: this.$t('createIndividualLinks'),
                  subText: this.$t('createIndividualLinksSub'),
                  keepExpand: false,
                  action: trackId => {
                    this.handleShareBtnClick([trackId], true, false, 1);
                  }
                },
                {
                  separator: true
                },
                {
                  icon: 'ri-mail-line',
                  name: this.$t('sendByEmail'),
                  subText: this.$t('sendByEmailSub'),
                  keepExpand: false,
                  action: trackId => {
                    this.handleShareBtnClick([trackId], true, false, 2);
                  }
                }
              ]
            },
            {
              name: 'itemId',
              match: 'id',
              value: null
            }
          ]
        },
        {
          type: 'component',
          name: 'iconButton',
          path: 'iconButton',
          stopPropagation: true,
          header: null,
          isExpandHover: true,
          noHeader: true,
          noSort: true,
          onHover: true,
          tooltip: this.$t('editInformation'),
          min: '36px',
          max: '36px',
          emits: ['handleInformationBtnClick'],
          props: [
            {
              name: 'btnStyle',
              match: 'btnStyle',
              value: 'tertiary'
            },
            {
              name: 'icon',
              match: 'icon',
              value: 'ri-information-line'
            },
            {
              name: 'size',
              match: 'size',
              value: 'small'
            },
            {
              name: 'customEvent',
              match: 'customEvent',
              value: 'handleInformationBtnClick'
            },
            {
              name: 'itemId',
              match: 'id',
              value: null
            },
            {
              name: 'clickTriggerSelect',
              match: 'clickTriggerSelect',
              value: false
            }
          ]
        },
        {
          type: 'component',
          name: 'iconButton',
          path: 'iconButton',
          stopPropagation: true,
          header: null,
          isExpandHover: true,
          noHeader: true,
          noSort: true,
          onHover: true,
          tooltip: this.$t('addTo'),
          min: '36px',
          max: '36px',
          emits: ['handleMoveToBtnClick'],
          props: [
            {
              name: 'btnStyle',
              match: 'btnStyle',
              value: 'tertiary'
            },
            {
              name: 'icon',
              match: 'icon',
              value: 'ri-folder-transfer-line'
            },
            {
              name: 'size',
              match: 'size',
              value: 'small'
            },
            {
              name: 'customEvent',
              match: 'customEvent',
              value: 'handleMoveToBtnClick'
            },
            {
              name: 'itemId',
              match: 'id',
              value: null
            },
            {
              name: 'clickTriggerSelect',
              match: 'clickTriggerSelect',
              value: true
            }
          ]
        },
        {
          type: 'component',
          name: 'moreDropdown',
          path: 'moreDropdown',
          stopPropagation: true,
          tooltip: this.$t('more'),
          header: null,
          noHeader: true,
          noSort: true,
          onHover: true,
          min: '36px',
          max: '36px',
          isExpandHover: true,
          events: ['setMoreDropdownState', 'selectItemFromDropdown'],
          props: [
            {
              name: 'items',
              match: 'items',
              value: [
                {
                  icon: 'ri-repeat-line',
                  name: this.$t('replaceAudioFile'),
                  keepExpand: false,
                  action: trackId => {
                    this.handleReplaceFile(trackId);
                  }
                },
                {
                  icon: 'ri-download-line',
                  name: this.$t('download'),
                  keepExpand: false,
                  action: trackId => {
                    this.handleDownloadClick([trackId]);
                  }
                }
              ]
            },
            {
              name: 'otherItems',
              match: 'otherItems',
              value: [
                {
                  icon: 'ri-delete-bin-line',
                  name: this.$t('delete'),
                  keepExpand: false,
                  action: trackId => {
                    this.deleteTracks(trackId);
                  }
                }
              ]
            },
            {
              name: 'itemId',
              match: 'id',
              value: null
            }
          ]
        },
        {
          type: 'text',
          name: 'artists',
          header: this.$t('artist'),
          skeleton: 'line',
          min: '105px',
          max: '1fr',
          list: true,
          span: 1,
          route: { name: 'artist', id: 'id' },
          padding: [0, 8, 0, 8]
        },
        {
          type: 'text',
          name: 'supports',
          header: this.$t('album'),
          skeleton: 'line',
          min: '105px',
          max: '1fr',
          list: true,
          span: 1,
          route: { name: 'album', id: 'id' },
          padding: [0, 8, 0, 8]
        },
        {
          type: 'text',
          name: 'humanDuration',
          headerPrefixIcon: 'ri-time-line',
          skeleton: 'line',
          noTitle: true,
          span: 1,
          padding: [0, 8, 0, 8],
          min: '80px',
          max: '80px'
        },
        {
          type: 'component',
          name: 'starsRating',
          path: 'starsRating',
          containerHeight: true,
          span: 1,
          header: this.$t('rating'),
          skeleton: 'line',
          min: '96px',
          max: '96px',
          padding: [0, 8, 0, 8],
          emits: ['setTrackRating'],
          props: [
            {
              name: 'itemId',
              match: 'id',
              value: null
            },
            {
              name: 'itemRating',
              match: 'rating',
              value: null
            },
            {
              name: 'playingTrack',
              match: 'playingTrack',
              value: this.playingTrack.id
            }
          ]
        },
        {
          type: 'text',
          name: 'added',
          noTitle: true,
          header: this.$t('added'),
          skeleton: 'line',
          min: '80px',
          max: '80px',
          padding: [0, 8, 0, 8]
        }
      ].filter(Boolean);
    },
    headerButtons() {
      return [
        {
          name: 'projectDropdown',
          path: 'projectDropdown',
          events: ['setProjectDropdownState'],
          emits: [
            'createProject',
            'addTracksToProject',
            'updateSearchValue',
            'fetchMoreProjects',
            'listProjects'
          ],
          props: [
            {
              name: 'dropdownOptions',
              value: this.projectList
            },
            {
              name: 'showObserver',
              value: this.showProjectObserver
            },
            {
              name: 'searchPlaceholder',
              value: this.$t('emptyProjectDescription')
            },
            {
              name: 'submitButton',
              value: {
                size: 'small',
                style: 'primary',
                label: this.$t('addToProject'),
                icon: 'ri-folder-transfer-line'
              }
            },
            {
              name: 'addButtonLabel',
              value: this.$t('add')
            },
            {
              name: 'cancelButtonLabel',
              value: this.$t('cancel')
            },
            {
              name: 'createButtonIcon',
              value: 'ri-folder-add-line'
            },
            {
              name: 'placeholder',
              value: {
                text: this.$t('emptyProjectDescription'),
                buttonLabel: this.$t('newProject'),
                buttonIcon: 'ri-add-fill'
              }
            },
            {
              name: 'trackId',
              value: null
            },
            {
              name: 'loadingProjects',
              value: this.loadingProjects
            },
            {
              name: 'fetchingMoreProjects',
              value: this.fetchingMoreProjects
            },
            {
              name: 'keepExpand',
              value: this.keepProjectDropdownExpanded
            },
            {
              name: 'noSearchResult',
              value: this.noProjectSearchResult
            },
            {
              name: 'defaultSelectedProjectId',
              value: this.newProjectId
            }
          ]
        },
        {
          name: 'submitButton',
          path: 'buttons/submitButton',
          emits: ['toggleEditMode'],
          props: [
            {
              name: 'btnStyle',
              value: 'secondary'
            },
            {
              name: 'size',
              value: 'small'
            },
            {
              name: 'label',
              value: this.$t('editInformation')
            },
            {
              name: 'iconClass',
              value: 'ri-information-line'
            },
            {
              name: 'customEvent',
              value: 'toggleEditMode'
            }
          ]
        },
        {
          name: 'moreDropdown',
          path: 'moreDropdown',
          props: [
            {
              name: 'forceDirection',
              match: 'forceDirection',
              value: {
                left: this.editMode
              }
            },
            {
              name: 'items',
              value: [
                {
                  icon: 'ri-download-line',
                  name: this.$t('download'),
                  action: trackId => {
                    if (trackId) {
                      this.handleDownloadClick([trackId]);
                    } else {
                      this.handleDownloadClick(this.selectedTracks);
                    }
                  }
                },
                {
                  icon: 'ri-delete-bin-line',
                  name: this.$t('delete'),
                  action: trackId => {
                    this.deleteTracks(trackId);
                  }
                }
              ]
            },
            {
              name: 'isSecondary',
              value: true
            }
          ]
        }
      ];
    },
    headerCountLabel() {
      const formatedNumber = formatNumber(this.selectedTracks.length);
      return `${formatedNumber} ${this.$tc('trackSelected', this.selectedTracks.length)}`;
    }
  },
  mounted() {
    this.$root.$on('goToNextPreviousMeta', params => {
      this.setSelectedItems(params);
    });
  },
  created() {
    // We need to disable the checkbox if some changes are not saved in the edit track panel
    bus.$on('disableTrackSelection', isSelectionDisabled => {
      this.isSelectionDisabled = isSelectionDisabled;
    });
    bus.$on('setNewProjectId', newProjectId => {
      this.newProjectId = newProjectId;
    });
    bus.$on('selectAllTracks', bool => {
      if (bool) {
        this.checkAllCheckbox();
      } else {
        this.uncheckAllCheckbox();
      }
    });
    bus.$on('shareSelectedTracks', (param, tracklist) => {
      this.handleShareBtnClick(param, true, tracklist);
    });
    if (Object.keys(this.playlist).length === 0 && this.trackList.length > 0) {
      this.$store.commit('changePlaylist', this.trackList);
    }
  },
  beforeDestroy() {
    bus.$off('disableTrackSelection');
    this.$root.$off('goToNextPreviousMeta');
  },
  methods: {
    handleEmit(params) {
      if (this[params.methodName]) this[params.methodName](params.param);
    },
    handleInformationBtnClick(param) {
      if (this.$isWorkspaceLockedVerification(this)) return;
      this.setSelectedItems({
        elementUID: param.itemId,
        openEditPanel: true,
        informationBtn: true
      });
    },
    handleMoveToBtnClick(param) {
      if (this.$isWorkspaceLockedVerification(this)) return;
      const fromSharedView = this.$route.path.includes('shared');
      let page_view;
      switch (this.$route.name) {
        case 'albums':
        case 'albumDetails':
          page_view = fromSharedView ? 'shared library album' : 'library album';
          break;
        case 'artists':
        case 'artistAlbums':
        case 'artistTracks':
          page_view = fromSharedView ? 'shared library artist' : 'library artist';
          break;
        case 'trackList':
        default:
          page_view = 'library track list';
          break;
      }
      trackEvent('Track AddTo Click', {
        category: 'addto',
        page_view,
        action_bar: false,
        upload_manager: false
      });
      bus.$emit('displayModal', {
        component: MoveToAddTo,
        hideHeader: true,
        isVisible: true,
        size: 'small',
        onSubmit: this.closeShareModal,
        props: [
          { name: 'filesIds', value: [param.itemId] },
          { name: 'hasPrevious', value: this.me.workspaces.length > 1 }
        ]
      });
    },
    handleShareBtnClick(param, fromActionBar, busTrackList, defaultTab) {
      const tracksIds = fromActionBar ? param : [param.itemId];
      const trackName = busTrackList
        ? busTrackList.find(track => track.id === tracksIds[0]).title
        : this.trackList.find(track => track.id === tracksIds[0]).title;

      const modalTitle =
        tracksIds.length > 1
          ? this.$t('shareTracksName', { name: trackName, count: tracksIds.length - 1 })
          : this.$t('shareTrackName', { name: trackName });

      const entityName =
        tracksIds.length > 1
          ? this.$t('TracksName', { name: trackName, count: tracksIds.length - 1 })
          : trackName;
      bus.$emit('displayModal', {
        component: ShareModal,
        isVisible: true,
        size: 'medium',
        onSubmit: this.closeShareModal,
        title: modalTitle,
        props: [
          { name: 'entityName', value: entityName },
          { name: 'entityIds', value: tracksIds },
          { name: 'entityType', value: 'track' },
          defaultTab ? { name: 'defaultTab', value: defaultTab } : []
        ].filter(Boolean)
      });
    },
    setIsHoverId(trackId) {
      this.isHoverId = trackId;
    },
    setPlayingTrack(trackInfos) {
      const track = this.trackList.find(item => item.id === trackInfos.id);
      if (track.isStreamableAvailable === true) {
        this.$store.commit('changePlaylist', this.trackList);
        this.$store.commit('changePlayingTrack', trackInfos);
      } else {
        this.$apollo
          .query({
            query: LIBRARY_FILE_STREAMABLE,
            fetchPolicy: 'network-only',
            variables: { libraryFilesIds: trackInfos.id }
          })
          .then(data => {
            const updatedStreamableAvailable = get(
              data,
              'data.LibraryFilesDetails[0].status_streamable_transcoded',
              false
            );
            if (updatedStreamableAvailable === false) {
              bus.$emit('displayModal', {
                title: 'Track is not ready yet',
                size: 'small',
                isVisible: true,
                component: StreamableUnavailable
              });
            } else {
              this.$store.commit('changePlaylist', this.trackList);
              this.$store.commit('changePlayingTrack', trackInfos);
            }
          });
      }
    },
    setPlayingTrackFromRow(track) {
      if (this.playingTrack.id === track.id) {
        bus.$emit('restartAudio');
      } else {
        this.$store.commit('changePlaylist', this.trackList);
        this.$store.commit('changePlayingTrack', track);
      }
    },
    setSelectedItems(param) {
      const { elementUID, openEditPanel, checkbox, rowIndex, informationBtn } = param;
      this.fromTo = [];
      this.fromTo.push(rowIndex || 0);
      if (this.selectedTracks.includes(elementUID)) {
        if (informationBtn && openEditPanel && !this.isEditPanelOpened) {
          this.selectedTracks = [elementUID];
          this.toggleEditMode();
          return;
        }
        const idx = this.selectedTracks.findIndex(id => id === elementUID);
        this.selectedTracks.splice(idx, 1);
        if (!checkbox) {
          this.selectedTracks = this.selectedTracks.length > 1 ? [elementUID] : [];
        }
      } else {
        this.selectedTracks.push(elementUID);
        if (this.searchLibraryQueryParameters) {
          const selectedTrack = this.trackList.find(item => this.selectedTracks.includes(item.id));
          this.trackListFromSearch.push(selectedTrack);
        }
        if (!checkbox) {
          this.selectedTracks = [elementUID];
        }
      }
      if (!param) {
        bus.$emit('showTracksEditSaveModal');
        return;
      }
      if (this.editMode || openEditPanel) {
        this.toggleEditMode();
      }
    },
    shiftSelectItems(param) {
      const { elementUID, rowIndex } = param;
      this.fromTo.splice(1, 1, rowIndex);
      if (this.selectedTracks.includes(elementUID)) {
        this.selectedTracks.splice(this.selectedTracks.indexOf(elementUID), 1);
        return;
      }
      if (this.fromTo.length === 2) {
        this.fromTo.sort((a, b) => a - b);
        for (let i = this.fromTo[0]; i <= this.fromTo[1]; i += 1) {
          if (this.trackList[i] && !this.selectedTracks.includes(this.trackList[i].id)) {
            this.selectedTracks.push(this.trackList[i].id);
          }
        }
      } else if (this.selectedTracks.includes(elementUID)) {
        this.selectedTracks.splice(this.selectedTracks.indexOf(elementUID), 1);
      } else {
        this.selectedTracks.push(elementUID);
      }
    },
    navigateTo(param) {
      const { id, name } = param;
      const route = id ? getRoute(name, { id }) : getRoute(name);
      if (this.$router.history.current.params.id) {
        if (this.$router.history.current.params.id !== id) this.$router.push(route);
      } else if (this.$router.history.current.name !== name) this.$router.push(route);
    },
    createProject() {
      this.$emit('createProject');
    },
    addTracksToProject(params) {
      const { projectId, trackId } = params;
      const p = trackId
        ? { projectId, libraryFilesIds: [trackId] }
        : { projectId, libraryFilesIds: this.selectedTracks };
      bus.$emit('addTracksToProject', p);
    },
    updateSearchValue(searchValue) {
      this.$emit('searchProject', searchValue);
    },
    fetchMoreProjects() {
      this.$emit('fetchMoreProjects');
    },
    handleReplaceFile(trackId) {
      if (this.$isWorkspaceLockedVerification(this)) return;
      trackEvent('Replace Audio track from MoreMenu');
      // When replacing a file we want to open the file tab
      this.$store.commit('changeEditPanelPane', this.$t('file'));
      this.$emit('toggleEditMode', [trackId]);
    },
    handleDownloadClick(tracks) {
      trackEvent({ event: 'Download one track from library' });
      bus.$emit('downloadTrack', tracks);
    },
    deleteTracks(trackId) {
      const libraryFileIds = trackId ? [trackId] : this.selectedTracks;
      this.$emit('openConfirmDeleteModal', libraryFileIds);
    },
    listProjects() {
      this.$emit('listProjects');
    },
    setTrackRating(params) {
      const input = this.trackList
        .filter(track => track.id === params.itemId)
        .map(item => ({
          id: item.id,
          title: item.title,
          rating: params.rating,
          isrc: item.isrc || undefined,
          bpm: item.bpm || undefined,
          iswc: item.iswc || undefined,
          lyrics: item.lyrics || undefined
        }));
      bus.$emit('setTrackRating', input);
    },
    sortTracks(sort) {
      this.$emit('sortTracks', sort);
    },
    toggleEditMode(fromActionBar) {
      if (!this.isEditPanelOpened && this.$isWorkspaceLockedVerification(this)) return;
      if (fromActionBar && this.isEditPanelOpened) {
        this.$emit('toggleEditMode', []);
        return;
      }
      this.$emit('toggleEditMode', this.selectedTracks);
    },
    uncheckAllCheckbox() {
      this.selectedTracks = [];
      this.toggleEditMode();
    },
    checkAllCheckbox() {
      const allItemsIds = this.trackList.map(el => el.id);
      this.selectedTracks = allItemsIds;
    }
  }
};
</script>

<style lang="scss" scoped>
.action-bar-container {
  position: fixed;
  bottom: 72px;
  left: calc(50% + 90px);
  transform: translateX(-50%);
  z-index: map-get($z-index, action-bar);
  transition: left 0.15s ease;
}
.action-bar-container.panel-open {
  left: calc(50% - 169px);
}
.projectPicker {
  position: relative;
  top: -386px;
  left: -22px;
}
</style>
