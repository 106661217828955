import gql from 'graphql-tag';
import {
  workspaceTemplate,
  invitationsFragment,
  roleFragment,
  libraryFragment,
  membersFragment,
  subscriptionFragment
} from '../fragments';

export const CREATE_WORKSPACE = gql`
  mutation createWorkspace($name: String!, $activityId: Int!) {
    createWorkspace(name: $name, activityId: $activityId) {
      ... on Workspace {
        ${workspaceTemplate}
      }
    }
  }
  ${invitationsFragment.invitations}
  ${roleFragment.role}
  ${libraryFragment.library}
  ${membersFragment.members}
  ${subscriptionFragment.subscription}
`;

export const UPDATE_WORKSPACE = gql`
  mutation updateWorkspace($workspaceId: ID!, $name: String!) {
    updateWorkspace(workspaceId: $workspaceId, name: $name) {
      ... on Workspace {
        ${workspaceTemplate}
      }
    }
  }
  ${invitationsFragment.invitations}
  ${roleFragment.role}
  ${libraryFragment.library}
  ${membersFragment.members}
  ${subscriptionFragment.subscription}
`;

export const WORKSPACE_LOGO_UPDATE = gql`
  mutation WorkspaceLogoUpdate($workspace_id: ID!, $upload_id: ID!) {
    WorkspaceLogoUpdate(workspace_id: $workspace_id, upload_id: $upload_id) {
      logo {
        id
        url
      }
    }
  }
`;

export const WORKSPACE_INITIALIZE_LOGO_UPLOAD = gql`
  mutation WorkspaceInitializeLogoUpload(
    $workspace_id: ID!
    $file_name: String!
    $size: String!
    $mime_type: String!
  ) {
    WorkspaceInitializeLogoUpload(
      workspace_id: $workspace_id
      file_name: $file_name
      size: $size
      mime_type: $mime_type
    ) {
      upload_id
      url
    }
  }
`;

export const WORKSPACE_REMOVE_MEMBER = gql`
  mutation WorkspaceRemoveMember($workspaceId: ID!, $memberUserId: String!) {
    WorkspaceRemoveMember(workspaceId: $workspaceId, memberUserId: $memberUserId)
  }
`;

export const WORKSPACE_MEMBER_LEAVE = gql`
  mutation WorkspaceMemberLeave($workspaceId: ID!) {
    WorkspaceMemberLeave(workspaceId: $workspaceId) {
      id
    }
  }
`;

export const MARK_NOTIFICATION_READ = gql`
  mutation NotificationsRead($notificationsIds: ID!) {
    NotificationsRead(notificationsIds: $notificationsIds)
  }
`;

export const NOTIFICATIONS_DISPLAY = gql`
  mutation NotificationsDisplay($notificationId: String) {
    NotificationsDisplay(notificationId: $notificationId)
  }
`;

export const NOTIFICATIONS_READ = gql`
  mutation NotificationsRead($notificationsIds: [String]!) {
    NotificationsRead(notificationsIds: $notificationsIds)
  }
`;

export const WORKSPACE_TRANSFER_OWNERSHIP = gql`
  mutation WorkspaceTransferOwnership(
    $workspaceId: ID!
    $ownerCurrentPassword: String!
    $newOwnerId: ID!
  ) {
    WorkspaceTransferOwnership(
      workspaceId: $workspaceId
      ownerCurrentPassword: $ownerCurrentPassword
      newOwnerId: $newOwnerId
    ) {
      id
    }
  }
`;

export const WORKSPACE_DELETE = gql`
  mutation WorkspaceDelete($workspaceId: ID!, $password: String!) {
    WorkspaceDelete(workspaceId: $workspaceId, password: $password)
  }
`;

export const CONFIGURE_WORKSPACE_PROPERTIES = gql`
  mutation configureWorkspaceProperties($workspaceId: ID!, $properties: WorkspacePropertiesInput) {
    configureWorkspaceProperties(workspaceId: $workspaceId, properties: $properties) {
      auto_tagger
    }
  }
`;

export const JOIN_WORKSPACE = gql`
  mutation joinWorkspace($workspaceInvitationToken: String!) {
    joinWorkspace(workspaceInvitationToken: $workspaceInvitationToken) {
      id
    }
  }
`;
