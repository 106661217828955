
<dropdown ref="dropdown">
  <template #button>
    <div class="button">
      <div>
        <span class="menu-item-label"> {{ sort.label }} </span>
        <span class="menu-item-order">({{ sort.order }})</span>
      </div>
      <i class="ri-arrow-drop-down-line"></i>
    </div>
  </template>
  <div class="menu">
    <div
      :class="[option.separator ? 'separator' : 'menu-item']"
      v-for="(option, index) in options"
      :key="index"
      @click="handleOptionClick(option)"
    >
      <div
        v-if="!option.separator"
        :class="{ active: option.order === sort.order && option.label === sort.label }"
      >
        <div>
          <span class="menu-item-label"> {{ option.label }} </span>
          <span class="menu-item-order">({{ option.order }})</span>
        </div>
        <i class="ri-check-line"></i>
      </div>
    </div>
  </div>
</dropdown>
