
<div class="share-by-link-body">
  <div class="upgrade-banner" v-if="sharesExpiresIn">
    <upgrade-banner
      :content="`Shared links expire after ${sharesExpiresIn} days with the Starter plan`"
      :roleId="myRoleId"
      @closeModal="$emit('closeModal')"
    />
  </div>
  <div class="description">
    <div class="description-icon"><i class="ri-links-line"></i></div>
    <div class="description-main">
      <div class="share-by-link-body-title-container">
        <div class="share-by-link-body-title">
          {{ $t('createIndividualLinks') }}
        </div>
        <div class="share-by-link-body-desc">
          {{ $t('individualLinksDesc') }}
        </div>
      </div>
    </div>
  </div>
  <div class="tag-input-container">
    <chips-input
      ref="chipsInputRef"
      :label="$t('chipsInputLabel')"
      :error="chipsInputError"
      @handleResult="setLinkName"
    />
  </div>
  <access-rights
    :accessRights="accessRights"
    :isProject="isProject"
    type="link"
    class="access-rights-link"
    @setAccessRights="setLinkAccessRights"
  />
  <share-security-section
    ref="sharePasswordToggleIndividual"
    :displayPasswordInput="displayIndividualPassword"
    :displayLinkNameInput="displayIndividualLinkName"
    :securityPassword.sync="password"
    :securityPasswordErrorMessage="passwordErrorMessage"
    @toggleSwitch="toggleSharePasswordIndividual"
  />
</div>
