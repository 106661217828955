
<div>
  <div v-if="!isPrimary" @click.stop="copy" :class="btnClass">
    <i class="ri-links-line" />
    <span>&nbsp;</span>
    <span class="label">{{ label }}</span>
  </div>
  <submit-button
    v-if="isPrimary"
    size="medium"
    :label="label"
    :disabled="disabled"
    @submit="copy"
  />
</div>
