<template>
  <dropdown ref="dropdown" dismissOnClick @setIsDropdownExpanded="setIsDropdownExpanded">
    <template #button>
      <submit-button
        class="project-cover-btns--"
        :btnStyle="btnProps.style"
        :size="btnProps.size"
        :label="btnProps.label"
        :iconClass="btnProps.icon"
        :isExpanded="isExpanded"
        :disabled="btnProps.disabled"
        :primaryException="btnProps.primaryException"
        :isDropdown="!btnProps.hideDropdown"
      />
    </template>
    <div class="menu-large">
      <div class="menu-item menu-item-small" @click="deleteProjectCover()">
        <div class="item">
          <span class="menu-item-label-small"> Remove </span>
        </div>
      </div>
      <div class="menu-item" @click="handleFileSelect()">
        <div class="item-dark">
          <i class="ri-upload-cloud-fill"></i>
          <span class="menu-item-label-dark"> Upload cover pic </span>
        </div>
      </div>
      <div class="menu-item menu-item-helper">
        <div class="item-helper">
          <span class="menu-item-label-helper">
            Optimal size: 1600x360, {{ maxCoverSizeConfig }} max
          </span>
        </div>
      </div>
      <div class="menu-item menu-item-link">
        <a
          href="https://www.notion.so/bridgeaudio/Projects-51de9d3694f14cb9be07f6f52963af8e?pvs=4#ab1624e786b64cde86c6d5aa2c44c221"
          target="_blank"
          rel="noopener"
          class="menu-item-label-link"
          >More info</a
        >
      </div>
    </div>
  </dropdown>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: false,
      default: () => []
    },
    btnProps: {
      type: Object,
      required: true
    },
    maxCoverSizeConfig: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isExpanded: false
    };
  },
  methods: {
    deleteProjectCover() {
      this.$emit('deleteProjectCover', this.deleteProjectCover);
      this.$emit('setIsCoverHover');
    },
    handleFileSelect() {
      this.$emit('handleFileSelect', this.handleFileSelect);
      this.$emit('setIsCoverHover');
    },
    setIsDropdownExpanded(bool) {
      this.isExpanded = bool;
      this.$emit('setIsDropdownExpanded', bool);
    }
  }
};
</script>

<style lang="scss" scoped>
.button {
  cursor: pointer;
  width: fit-content;
  font-weight: bold;
  display: flex;
  user-select: none;
}
.menu-large {
  margin-top: 2px;
  padding: 8px;
  height: auto;
  z-index: 1000;
  white-space: nowrap;
  background-color: $color_neutral_0;
  box-sizing: border-box;
  border: 1px solid $color_neutral_30;
  border-radius: 8px;
  overflow: visible;
  height: 138px;
  display: flex;
  flex-direction: column;
  @include shadow-down-03;
}

.menu-item {
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 32px;
  box-sizing: border-box;
  @include body-1;

  &-small {
    font-size: 12px;
    display: inline-flex;
    margin-left: auto;
    margin-right: 8px;
    margin-bottom: 12px;
    font-weight: 600;
  }

  &-helper {
    justify-content: center;
    padding-bottom: 0;
  }

  &-link {
    justify-content: center;
    padding-top: 0;
  }

  &-label {
    display: flex;
    align-items: center;

    &-helper,
    &-link {
      font-size: 12px;
      font-weight: 600;
    }

    &-helper {
      cursor: default;
      color: $color_neutral_60;
    }

    &-link:hover {
      color: $color_primary_100;
      text-decoration: underline;
    }

    &-dark {
      font-weight: 600;
    }

    &-small {
      margin-right: 5px;
    }
  }
}
.item {
  display: flex;
  align-items: center;
  line-height: 100%;

  &-dark {
    background: $color_neutral_100;
    color: $color_neutral_0;
    padding: 9px 61px;
    border-radius: 4px;
    padding-bottom: 8px;
    height: 22px;
    display: flex;
    align-items: center;
    line-height: 100%;
    i {
      margin-right: 8px;
    }
  }
}
.item-dark:hover {
  background: $color_neutral_80;
}

.menu-item-small:hover {
  background-color: $color_neutral_30;
}
</style>
