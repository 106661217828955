
<div>
  <header-list
    :columns="columns"
    :sortColumn="false"
    isSticky
    :stickyPosition="0"
    :hasSelectAll="hasSelectAll"
    :itemsSelected="[]"
    :displayUnselect="selectedTracks.length > 0"
    @handleEmit="handleEmit"
    @uncheckAllCheckbox="uncheckAllCheckbox"
    @selectAll="checkAllCheckbox"
  />
  <draggable
    v-model="editableTrackList"
    class="draggable"
    @start="startDrag"
    @end="endDrag"
    @update="updateDrag"
  >
    <div v-for="(track, idx) in editableTrackList" :key="track.id">
      <list-row
        :item="track"
        :columns="columns"
        :rowIndex="idx"
        :height="48"
        :id="track.id"
        :playingTrack="playingTrack || {}"
        :selectedItems="selectedTracks"
        @handleEmit="handleEmit"
      />
    </div>
  </draggable>
  <div v-if="isFetchingMore">
    <skeleton-row :columns="columns" :height="48" />
  </div>
  <div
    v-if="selectedTracks.length"
    class="action-bar-container"
    :class="{ 'panel-open': isEditPanelOpened }"
  >
    <action-bar
      :numberOfSelection="headerCountLabel"
      :actions="actionBarBtns"
      :label="actionBarLabels"
      @selectAll="checkAllCheckbox"
      @unselect="uncheckAllCheckbox"
    />
  </div>
</div>
