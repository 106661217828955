<template>
  <dropdown ref="dropdown">
    <template #button>
      <div class="button">
        <div>
          <span class="menu-item-label"> {{ sort.label }} </span>
          <span class="menu-item-order">({{ sort.order }})</span>
        </div>
        <i class="ri-arrow-drop-down-line"></i>
      </div>
    </template>
    <div class="menu">
      <div
        :class="[option.separator ? 'separator' : 'menu-item']"
        v-for="(option, index) in options"
        :key="index"
        @click="handleOptionClick(option)"
      >
        <div
          v-if="!option.separator"
          :class="{ active: option.order === sort.order && option.label === sort.label }"
        >
          <div>
            <span class="menu-item-label"> {{ option.label }} </span>
            <span class="menu-item-order">({{ option.order }})</span>
          </div>
          <i class="ri-check-line"></i>
        </div>
      </div>
    </div>
  </dropdown>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: false
    },
    sort: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleOptionClick(option) {
      if (!option.separator) {
        this.$emit('sort', option);
        this.$refs.dropdown.hide();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.button {
  cursor: pointer;
  width: fit-content;
  font-weight: bold;
  display: flex;
  user-select: none;
}
.menu {
  margin-top: 4px;
  min-width: 215px;
  height: auto;
  z-index: 1000;
  white-space: nowrap;
  background-color: $color_neutral_0;
  box-sizing: border-box;
  border: 1px solid $color_neutral_30;
  border-radius: 4px;
  overflow: hidden;
  @include shadow-down-03;
}

.menu-item {
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 32px;
  box-sizing: border-box;
  @include body-1;
  &-order {
    color: $color_neutral_60;
    font-size: 12px;
  }
}
.ri-check-line {
  display: none;
  color: $color_primary_100;
  font-size: 18px;
}
.active {
  font-weight: bold;
  display: flex;
  .ri-check-line {
    display: unset;
  }
}
.menu-item:hover {
  background-color: $color_neutral_10;
}
.separator {
  height: 1px;
  background-color: $color_neutral_30;
  width: 100%;
}
.ri-arrow-drop-down-line {
  transform: translateY(1px);
}
</style>
