<template>
  <div class="breadcrumb-container">
    <div
      v-for="(name, index) in path"
      :key="index"
      :class="{
        pathItem: true,
        link: index < path.length - 1
      }"
      @click="handleClick(index)"
    >
      <div class="icon" :class="iconStyle" v-if="iconClass && index === 0">
        <i :class="iconClass" />
      </div>
      <div class="name">
        {{ name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    path: {
      type: Array,
      required: true
    },
    iconClass: {
      type: String,
      required: false
    },
    iconStyle: {
      type: String,
      required: false,
      default: 'library'
    }
  },
  methods: {
    handleClick(index) {
      if (index < this.path.length - 1) {
        this.$emit('handleParentClick', index);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.breadcrumb-container {
  @include body-1;
  display: flex;
  user-select: none;
  align-items: center;
  position: relative;
}

.previous {
  position: relative;
  top: 1px;
  font-size: 16px;
  cursor: pointer;
}
.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 8px;
}

.library {
  background: $color_primary_10;
  color: $color_primary_100;
}

.projects {
  background: $color_secondary_10;
  color: $color_secondary_100;
}

.shared {
  background: $color_red_10;
  color: $color_red_100;
}

.inbox {
  background: $color_purple_10;
  color: $color_purple_100;
}

.pathItem {
  height: 44px;
  max-width: 36vw;
  display: flex;
  align-items: center;
  color: $color_neutral_100;
  font-weight: 600;
  i {
    font-weight: normal;
  }
}

.link {
  cursor: pointer;
  color: $color_neutral_60;
  font-weight: normal;
  .name:hover {
    text-decoration: underline $color_neutral_60;
  }
}

.pathItem:after {
  content: '/';
  padding: 0 4px;
  cursor: default;
}

.pathItem:last-child:after {
  content: '';
}
</style>
