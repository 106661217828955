
<div>
  <div class="modal-text">
    We are preparing your file,<br />
    please try again in few moments
  </div>
  <div class="modal-buttons">
    <div class="modal-buttons--">
      <submit-button btnStyle="primary" size="medium" :label="$t('close')" @submit="closeModal" />
    </div>
  </div>
</div>
