<template>
  <div class="paymentContainer">
    <icon-button
      class="navigationIcon"
      btnStyle="tertiary"
      icon="ri-arrow-left-line"
      @submit="navigateBack"
    />
    <payment-form
      v-if="selectedPlan"
      planName="Pro"
      :workspaceId="currentWorkspace.id"
      :planVariant="selectedPlan.plan"
      :isYearly="selectedPlan.isYearly"
      :isCouponValid="true"
      @onPaymentSuccess="onPaymentSuccess"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { bus } from '@/utils/bus';
import { get as getRoute } from '@/router/routes';

import { ME } from '@/graphql/queries/user';
import PaymentForm from '@/containers/paymentForm';

export default {
  components: {
    PaymentForm
  },
  data() {
    return {
      previousPath: undefined
    };
  },
  mounted() {
    if (!this.selectedPlan) {
      this.$router.push(getRoute('offersSettings'));
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'userCurrency', 'selectedPlan'])
  },
  methods: {
    onPaymentSuccess() {
      this.$apollo
        .query({
          query: ME
        })
        .then(() => {
          this.$router.push(getRoute('billingSettings'));
          bus.$emit('showAlert', {
            message: { key: 'subscriptionSuccessful' },
            style: 'success',
            delay: 5000
          });
        });
    },
    navigateBack() {
      if (this.previousPath) {
        this.$router.push(this.previousPath);
      } else {
        this.$router.push(getRoute('offersSettings'));
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.previousPath = from.path; // eslint-disable-line
    });
  }
};
</script>

<style lang="scss" scoped>
.paymentContainer {
  display: flex;
  flex-direction: row;
}
.navigationIcon {
  position: sticky;
  top: 0;
}
</style>
