<template v-slot:body>
  <div class="change-email-modal">
    <div>
      <text-field
        ref="inputTitle"
        inputType="text"
        :errorMessage="emailValidationMessage"
        :fieldModel.sync="$v.email.$model"
        :value="email"
        label="Billing email address"
        :large="false"
      />
    </div>
    <div class="change-email-modal-buttons">
      <div class="change-email-modal-buttons--">
        <submit-button
          btnStyle="tertiary"
          size="medium"
          :label="$t('cancel')"
          @submit="closeModal"
        />
      </div>
      <div class="change-email-modal-buttons--">
        <submit-button
          btnStyle="primary"
          size="medium"
          :label="$t('save')"
          :pending="pending"
          @submit="save"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { bus } from '@/utils/bus';
import { STRIPE_CREATE_CUSTOMER } from '@/graphql/mutations/subscription';

export default {
  props: {
    defaultMail: {
      type: String,
      required: true
    },
    billingInformation: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      email: this.defaultMail,
      pending: false
    };
  },
  validations: {
    email: { required, email }
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    emailValidationMessage() {
      if (this.$v.email.$error) {
        if (this.$v.email.required) {
          return this.$t('subscription.form.invalidEmail');
        }
      }
      return null;
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    save() {
      if (this.emailValidationMessage) {
        return;
      }
      if (this.email) {
        this.pending = true;
        const { line1, line2, city, postal_code, country, state } = this.billingInformation.address;
        this.$apollo
          .mutate({
            mutation: STRIPE_CREATE_CUSTOMER,
            variables: {
              workspaceId: this.currentWorkspace.id,
              billingInformation: {
                address: {
                  line1,
                  line2,
                  city,
                  postal_code,
                  country,
                  state
                },
                company: this.billingInformation.company,
                email: this.email
              }
            }
          })
          .then(() => {
            bus.$emit('refetchStripeCustomer');
            this.pending = false;
            this.$emit('closeModal');
            bus.$emit('showAlert', {
              message: { key: 'modificationSuccess' },
              style: 'success',
              delay: 5000
            });
          });
      } else {
        this.$emit('closeModal');
      }
    }
  }
};
</script>

<style scoped lang="scss">
.change-email-modal {
  padding: 24px 24px 8px 24px;
  &-buttons {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $color_neutral_30;
    margin-top: 24px;
    padding: 8px 24px 0 24px;
    width: calc(100% + 48px);
    margin-left: -24px;
    box-sizing: border-box;

    &-- {
      &:first-child {
        margin: 0 16px 0 0;
      }
    }
  }
}
</style>
