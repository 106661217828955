
<div class="artist-album">
  <project-list>
    <template v-slot:default="slotProps">
      <div class="artist-album-header">
        <div class="artist-album-header-cover" @click="goToAlbumPage(support.id)">
          <img :src="thumbnails" />
        </div>
        <div class="artist-album-header-infos">
          <div class="artist-album-header-infos-txt">
            <div class="artist-album-header-infos-txt-title" @click="goToAlbumPage(support.id)">
              {{ support.name }}
            </div>
            <div class="artist-album-header-infos-txt-subtitle">
              <span v-if="support.tracks_number">
                {{ $tc('trackCount', support.tracks_number) }}
              </span>
              <span v-if="support.tracks_length">
                {{ ` - ${getAlbumDuration(support.tracks_length)}` }}
              </span>
              <span v-if="support.release_date">{{ ` - ${getDate(support.release_date)}` }}</span>
            </div>
          </div>
          <div class="artist-album-header-infos-actions">
            <div class="artist-album-header-infos-actions-btn">
              <submit-button
                class="project-dropdown"
                size="small"
                btnStyle="secondary"
                iconClass="ri-folder-transfer-line"
                :label="$t('addTo')"
                @submit="handleAddToClick"
              />
              <btn-dropdown
                class="upload-to-library-btn"
                :options="shareBtnOptions"
                :btnProps="{
                  style: 'secondary',
                  size: 'small',
                  label: 'share',
                  icon: 'ri-share-forward-fill',
                  hideDropdown: true
                }"
                @action="openShareModal(support.name, ...arguments)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="artist-album-tracks" v-if="trackList.length > 0">
        <tracks
          :trackList="trackList"
          :projectList="slotProps.projectList"
          :noProjectSearchResult="slotProps.noProjectSearchResult"
          :showProjectObserver="slotProps.showProjectObserver"
          :loadingProjects="slotProps.loadingProjects"
          :fetchingMoreProjects="slotProps.fetchingMoreProjects"
          :keepProjectDropdownExpand="keepProjectDropdownExpand"
          :uncheckTracks="uncheckTracks"
          hasSelectAll
          :editMode="editMode"
          :hasActionBar="false"
          hideHeaderBtn
          withAlbumTrackNumber
          @openConfirmDeleteModal="openConfirmDeleteModal"
          @createProject="createProject"
          @searchProject="slotProps.searchProject"
          @fetchMoreProjects="slotProps.fetchMoreProjects"
          @listProjects="slotProps.listProjects"
          @toggleEditMode="toggleEditMode"
          @setArtistAlbumSelectedTracks="setArtistAlbumSelectedTracks"
        />
      </div>
      <div v-else class="artist-album-tracks">
        <skeleton-row
          v-for="index in support.tracks_number + 1"
          :columns="columns"
          :height="40"
          :key="index"
        />
      </div>
    </template>
  </project-list>
</div>
