<template>
  <div
    class="container"
    :class="{ success: success, 'no-border': !hasBorder }"
    :style="`width: ${label.width}px;`"
    @click="copy"
  >
    <i :class="label.icon" />
    <div>{{ txt }}</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: Object,
      required: false,
      default: () => {
        return { base: 'Copy', copied: 'Copied!', icon: 'ri-links-line', width: 105 };
      }
    },
    hasBorder: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      success: false,
      successTimer: undefined
    };
  },
  computed: {
    txt() {
      return this.success ? this.label.copied : this.label.base;
    }
  },
  methods: {
    copy() {
      if (this.$isWorkspaceLockedVerification(this)) return;
      this.$emit('copyLink');
      clearTimeout(this.successTimer);
      this.success = true;
      this.successTimer = setTimeout(() => {
        this.success = false;
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 32px;
  box-sizing: border-box;
  border: 1px solid $color_primary_50;
  color: $color_primary_100;
  background-color: $color_neutral_0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  i {
    margin-right: 4px;
    font-size: 16px;
    font-weight: normal;
  }
  &:hover {
    background-color: $color_primary_10;
    border: 1px solid $color_primary_100;
  }
}
.container.success {
  background-color: $color_success_10;
  color: $color_success_100;
  border: 1px solid $color_success_100;
}
.container.no-border {
  border: none;
  &:hover {
    border: none;
  }
}
</style>
