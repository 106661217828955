import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Vuelidate from 'vuelidate';
import VueApollo from 'vue-apollo';
import { createHttpLink } from 'apollo-link-http';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import VTooltip from 'v-tooltip';
import get from 'lodash.get';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import { getGraphQlErrorCode } from '@/utils/functions/global';
import {
  getAuthTokenFromStorage,
  getConnectedAs,
  generateAuthTokenCookie,
  parseJwt,
  getFromLocal
} from '@/utils/storage';
import messages from '@/utils/i18n';
import App from '@/views/app';
import router from '@/router';
import { REFRESH_TOKEN } from '@/graphql/mutations/user';
import { appConfig } from '@/utils/constants';
import { initAnalytics } from '@/utils/functions/analytics';
import { isWorkspaceLockedVerification } from '@/utils/functions/workspace';
import Store from '@/store';
import { get as getRoute } from '@/router/routes';
import Components from './components';

import 'remixicon/fonts/remixicon.css';
import '@/assets/styles/main.scss';

Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(Vuelidate);
Vue.use(VueApollo);
Vue.use(Components);
Vue.use(VTooltip, {
  defaultBoundariesElement: 'window'
});

const i18n = new VueI18n({
  locale: 'en_GB',
  fallbackLocale: 'en_GB',
  formatFallbackMessages: true,
  messages
});

function uploadXHR(url, options) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(options.method, url, true);
    Object.keys(options.headers).forEach(key => {
      xhr.setRequestHeader(key, options.headers[key]);
    });
    if (xhr.upload) {
      xhr.upload.onprogress = options.onUploadProgress;
    }
    // Used for declaring the request when canceling file upload
    options.onAbort(() => {
      xhr.abort();
    });
    xhr.send(options.body);
    xhr.onload = () => {
      const opts = {
        status: xhr.status,
        statusText: xhr.statusText
      };
      opts.url = 'responseURL' in xhr ? xhr.responseURL : opts.headers.get('X-Request-URL');
      const body = 'response' in xhr ? xhr.response : xhr.responseText;
      resolve(new Response(body, opts));
    };
    xhr.onerror = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'));
    };
  });
}

const apolloCache = new InMemoryCache({
  dataIdFromObject(object) {
    // eslint-disable-next-line no-underscore-dangle
    switch (object.__typename) {
      case 'WorkspaceSubscriptionPlan':
        return `WorkspaceSubscriptionPlan:${object.id}-${object.variant_id}`;
      case 'LibraryFileRightHolder':
        return `LibraryFileRightHolder:${object.track_right_holder_id}-${object.id}`;
      case 'LibrarySupport':
        // return default if track_position is null to avoid cacheId like null-id
        return object.track_position
          ? `LibrarySupport:${object.track_position}-${object.id}`
          : defaultDataIdFromObject(object);
      default:
        return defaultDataIdFromObject(object); // fall back to default for all other types
    }
  }
});

const customFetch = async (uri, opts) => {
  const options = opts;
  const token = getAuthTokenFromStorage(document.cookie);
  const expire = parseJwt(token).exp * 1000;
  const now = new Date().getTime();
  const isExpired = now >= expire;
  if (!isExpired) {
    options.headers.authorization = `Bearer ${token}`;
    if (options.onUploadProgress || options.onAbort) {
      return uploadXHR(uri, options);
    }
    return fetch(uri, options);
  }
  const client = new ApolloClient({
    link: createHttpLink({
      uri: `${appConfig.AccountApiBaseUrl}/graphql`
    }),
    cache: apolloCache
  });
  return client
    .mutate({
      mutation: REFRESH_TOKEN,
      context: {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    })
    .then(response => {
      const newAccessToken = response.data.refreshToken.token;
      const newExpiresIn = response.data.refreshToken.expires_in; //eslint-disable-line
      const isRememberMe = document.cookie.includes('baar');
      const newCookie = generateAuthTokenCookie(newAccessToken, newExpiresIn, isRememberMe);
      document.cookie = newCookie;
      options.headers.authorization = `Bearer ${newAccessToken}`;
      if (options.onUploadProgress) {
        return uploadXHR(uri, options);
      }
      return fetch(uri, options);
    })
    .catch(error => {
      const codeError = getGraphQlErrorCode(error);
      if (
        codeError === 'AUTHENTICATION_FAILED' ||
        codeError === 'TOKEN_MISSING' ||
        codeError === 'TOKEN_INVALID' ||
        codeError === 'INTERNAL_SERVER_ERROR'
      ) {
        Sentry.configureScope(scope => scope.setUser(null));
        window.location.replace(getRoute('logout'));
      }
    });
};

const uploadLink = createUploadLink({
  uri: `${appConfig.AccountApiBaseUrl}/graphql`,
  fetch: customFetch
});

export const apolloClient = new ApolloClient({
  link: uploadLink,
  cache: apolloCache,
  connectToDevTools: true
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});

const enableThirdParty = version => {
  const isConnectedAs = getConnectedAs();
  const isCypressTest = getFromLocal('CYPRESS') === '1';
  const result = version !== 'dev' && version !== 'develop' && (!isConnectedAs || !isCypressTest);
  if (!result) {
    console.debug('===> Third party script DISABLED'); // eslint-disable-line
  }
  return result;
};

fetch(`${appConfig.AccountApiBaseUrl}/config.js`).then(res => {
  res
    .json()
    .then(config => {
      Vue.prototype.$config = { ...appConfig, ...config };
      Vue.prototype.$isWorkspaceLockedVerification = isWorkspaceLockedVerification;

      const instance = new Vue({
        router,
        store: Store,
        i18n,
        apolloProvider,
        render: h => h(App)
      }).$mount('#app');

      // API Config must be loaded to set sentry environnement
      const versionRelease = get(appConfig, 'version.release', undefined);
      if (enableThirdParty(versionRelease)) {
        initAnalytics(config.segment.app);
        Sentry.init({
          Vue,
          beforeSend(event) {
            // Adds the route name as transaction for better Performance dashboard
            const newEvent = { ...event };
            const routeName =
              instance.$router.currentRoute.matched[
                instance.$router.currentRoute.matched.length - 1
              ].path;
            newEvent.tags = {
              ...event.tags,
              transaction: routeName
            };
            return newEvent;
          },
          dsn: 'https://8f0239aa2e054e01b3e46084f0c27392@o429869.ingest.sentry.io/5377247',
          environment: config.env,
          integrations: [
            new BrowserTracing({
              routingInstrumentation: Sentry.vueRouterInstrumentation(router)
            }),
            new Sentry.Replay({})
          ],
          release: `webapp@${versionRelease}`,
          // This sets the sample rate to be 10%. You may want this to be 100% while
          // in development and sample at a lower rate in production
          replaysSessionSampleRate: config.env === 'production' ? 0.1 : 0.0,
          // If the entire session is not sampled, use the below sample rate to sample
          // sessions when an error occurs.
          replaysOnErrorSampleRate: config.env === 'production' ? 1.0 : 0.0,
          tracesSampleRate: 1.0
        });

        Sentry.configureScope(scope => {
          scope.setContext('app', {
            app_build: get(appConfig, 'version.build', 'Unkonwn build'),
            app_version: get(appConfig, 'version.hash', 'Unkonwn hash'),
            app_release: versionRelease
          });
        });
      }
    })
    .catch(error => {
      console.error(error); // eslint-disable-line
    });
});

export default apolloClient;
