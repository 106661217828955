<template>
  <dropdown ref="emailPicker">
    <template #button="slotProps">
      <div
        :class="{
          'emailPicker-input': true,
          'emailPicker-input-active': slotProps.isExpanded
        }"
      >
        <span
          >{{ emailReplyTo.email }}
          <badge label="Primary" v-if="emailReplyTo.is_primary" />
        </span>
        <i
          :class="{
            'ri-arrow-drop-up-fill': slotProps.isExpanded,
            'ri-arrow-drop-down-fill': !slotProps.isExpanded
          }"
        />
      </div>
    </template>
    <div class="emailPicker-dropdown">
      <div class="emailPicker-dropdownItem" @click="setEmailReplyTo(undefined)">
        <div class="emailPicker-dropdownItem-content">
          {{ getDefaultNoReplyEmail }}
        </div>
        <i v-if="emailReplyTo.email === 'no-reply@bridge.audio'" class="ri-check-line"></i>
      </div>
      <div
        v-for="item in UserEmailsSorted"
        :key="item.id"
        :class="{
          'emailPicker-dropdownItem': true,
          'emailPicker-dropdownItem-notVerified': !item.is_verified
        }"
        @click="setEmailReplyTo(item)"
      >
        <div
          class="emailPicker-dropdownItem-content"
          :class="{ 'emailPicker-dropdownItem-large': !item.is_verified }"
        >
          <div>
            {{ item.email }}
          </div>
          <div v-if="!item.is_verified">
            ({{ $t('pending') }}&nbsp;-&nbsp;
            <span class="emailPicker-resend" @click="resendConfirmationEmail(item.id)">{{
              $t('resendVerificationEmail')
            }}</span
            >)
          </div>
          <badge label="Primary" v-if="item.is_primary" />
        </div>
        <i v-if="emailReplyTo.email === item.email" class="ri-check-line"></i>
      </div>
      <div class="emailPicker-action" @click="goToSettings" v-if="UserEmails.length <= 5">
        <i class="ri-mail-add-line" />
        <span>{{ $t('addManageEmailAddress') }}</span>
      </div>
    </div>
  </dropdown>
</template>

<script>
export default {
  props: {
    emailReplyTo: {
      type: Object,
      required: true
    },
    UserEmails: {
      type: Array,
      required: true
    },
    UserEmailsSorted: {
      type: Array,
      required: true
    },
    getDefaultNoReplyEmail: {
      type: String,
      required: true
    }
  },
  methods: {
    setEmailReplyTo(param) {
      this.$emit('setEmailReplyTo', param);
    },
    resendConfirmationEmail(param) {
      this.$emit('resendConfirmationEmail', param);
    },
    goToSettings() {
      this.$emit('goToSettings');
    },
    hide() {
      this.$refs.emailPicker.hide();
    }
  }
};
</script>

<style lang="scss" scoped>
.emailPicker-label {
  margin: 0 0 8px 0;
  font-weight: 600;
  font-size: 12px;
}
.emailPicker-input {
  font-size: 14px;
  width: 100%;
  min-height: 46px;
  box-sizing: border-box;
  border: 1px solid $color_neutral_30;
  border-radius: 2px;
  padding: 0px 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  transition: border-color 0.3s linear;
  justify-content: space-between;

  span {
    display: flex;
    align-items: center;
  }
}

.emailPicker-input-active {
  border: 1px solid black;
}

.emailPicker-dropdown {
  background: $color_neutral_0;
  width: 514px;
  border-radius: 2px;
  @include shadow-down-03;
}

.emailPicker-dropdownItem,
.emailPicker-action {
  padding: 0 16px;
  font-size: 14px;
  height: 46px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid $color_neutral_30;
}

.emailPicker-dropdownItem-content {
  display: flex;
  flex: 1;
  align-items: center;
}

.emailPicker-dropdownItem-notVerified {
  color: $color_neutral_60;
  cursor: not-allowed;
  height: 64px;
}
.emailPicker-dropdownItem-notVerified div:first-child {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 6px;
}

.emailPicker-action {
  color: $color_primary_100;
  i {
    padding-right: 8px;
  }
}

.emailPicker-resend {
  cursor: pointer;
  color: $color_primary_100;
  &:hover {
    text-decoration: underline;
  }
}

.emailPicker-loader {
  text-align: center;
  padding: 0 0 8px;
}
</style>
