<template>
  <div
    v-if="ProjectDetails"
    :class="[
      'project-details',
      projectEmpty && 'project-details-empty',
      projectWithOnlyAttachment && 'project-details-with-only-att',
      projectWithOnlyAttachment && Object.keys(playlist).length > 0 && 'with-player',
      attachments.length < 1 && 'project-details-without-att'
    ]"
  >
    <preview-files
      v-if="displayAttachmentsPreview && attachments.length > 0"
      :filesList="attachments"
      :defaultCurrent="defaultAttachments"
      @downloadItem="downloadItemFromPreview"
      @deleteItem="deleteItemFromPreview"
      @close="closePreview"
    />
    <div :class="['tracks-edition', editTracksMode ? 'tracks-edition-open' : '']">
      <div class="tracks-edition-wrapper">
        <edit-tracks-panel
          v-if="editTracksMode"
          :libraryFileIds="libraryFileIds"
          :trackList="trackList"
          @toggleEditMode="toggleEditMode"
        />
      </div>
    </div>
    <div
      class="project-details-left"
      :class="{
        'project-details-left-empty': ProjectDetails.total_tracks < 1,
        'project-details-left-with-only-att': projectWithOnlyAttachment
      }"
    >
      <div class="desc-container">
        <div class="project-details-left-uploadcover">
          <submit-button
            v-show="!projectCover"
            class="upload-cover-btn"
            btnStyle="tertiary"
            size="small"
            :label="$t('addCover')"
            iconClass="ri-image-add-fill"
            browse
            exception
            accept="image/*"
            @submit="uploadProjectCover"
          />
        </div>
        <div class="project-details-left-title">
          <editable-text
            :value="projectName"
            :fieldModel.sync="projectName"
            size="large"
            @validate="updateProjectName"
            class="project-details-left-title-editable"
          />
        </div>
        <div class="project-details-left-updatedat">
          {{
            ProjectDetails.total_tracks ? `${$tc('trackCount', ProjectDetails.total_tracks)} •` : ''
          }}
          {{
            ProjectDetails.total_tracks && ProjectDetails.total_tracks_length
              ? `${getFormatDuration(ProjectDetails.total_tracks_length)} •`
              : ''
          }}
          {{ `${$t('updated')} ${getUpdatedDate(ProjectDetails.updated_at)}` }}
        </div>
        <div class="project-details-left-description">
          <markdown-wysiwyg
            @validate="updateProjectDescription"
            :fieldModel.sync="projectDescription"
            :value="ProjectDetails.description"
          />
        </div>
        <div class="contextual-actions">
          <submit-button
            class="action ca-library mr-2"
            btnStyle="tertiary"
            size="small"
            :label="$t('addFromLibrary')"
            iconClass="ri-music-2-fill"
            @submit="handleAddTracksFromLibrary"
          />
          <submit-button
            class="action mr-2"
            btnStyle="tertiary"
            size="small"
            :label="$t('fileUpload')"
            iconClass="ri-file-upload-fill"
            @submit="handleOpenFileInput()"
          />
          <submit-button
            class="action"
            btnStyle="tertiary"
            size="small"
            :label="$t('folderUpload')"
            iconClass="ri-folder-upload-fill"
            @submit="handleOpenFileInput(true)"
          />
        </div>
      </div>
      <div
        class="project-details-left-tracklist"
        :class="{ 'project-details-left-tracklist-with-only-att': projectWithOnlyAttachment }"
      >
        <div
          class="no-track"
          v-if="projectEmpty && !projectWithOnlyAttachment"
          data-test="empty-project-no-track"
        >
          <div
            class="empty-cta-container"
            @mouseenter="handleAnimation(projectEmptyAnimation, 1)"
            @mouseleave="handleAnimation(projectEmptyAnimation, -1)"
            key="key-projectEmptyAnimation"
          >
            <div class="animation-container" ref="projectEmptyAnimation"></div>
          </div>
          <div class="empty-description">
            <div class="empty-description-title">Add audio, media, and documents</div>
            <div class="empty-description-body">
              Click on the button, or drag & drop your files. You’ll be able to create
              <div>
                <span>
                  <a :href="demoUrls.showreel" target="_blank" rel="noopener noreferrer">
                    Showreels
                  </a></span
                >
                -
                <span>
                  <a :href="demoUrls.presskit" target="_blank" rel="noopener noreferrer">
                    Presskits
                  </a></span
                >
                -
                <span
                  ><a :href="demoUrls.syncSelection" target="_blank" rel="noopener noreferrer">
                    Sync Selections
                  </a></span
                >...
              </div>
            </div>
          </div>
        </div>
        <div v-else class="project-details-left-tracklist-tracks" data-test="project-tracks">
          <infinite-scroll
            ref="infiniteScroll"
            :listQuery="listTracksQuery"
            toCheck="tracksPaginate"
            forceLastPage
            @handleQueryResult="handleQueryResult"
            @setLoading="setLoading"
          >
            <template v-slot:list>
              <div v-if="trackList">
                <tracks
                  ref="trackListRef"
                  v-if="trackList && trackList.length > 0"
                  :trackList="trackList"
                  :isFetchingMore="isFetchingMore"
                  :uncheckTracks="uncheckTracks"
                  :editMode="editTracksMode"
                  hasSelectAll
                  @openConfirmRemoveModal="openConfirmRemoveModal"
                  @toggleEditMode="toggleEditMode"
                />
              </div>
              <div v-else class="track-loader">
                <spinner-without-progress color="grey" :size="32" />
              </div>
            </template>
          </infinite-scroll>
        </div>
      </div>
    </div>
    <div
      :class="[
        'project-details-right',
        projectWithOnlyAttachment && 'project-details-right-with-only-att'
      ]"
      v-if="ProjectDetails.attachments.length > 0 || Object.keys(attachmentUpload).length > 0"
    >
      <div class="project-details-right-container">
        <div class="project-details-right-header">
          <div class="project-details-right-header-title">
            <div class="project-details-right-header-title--">
              <div>{{ $t('mediaAndDocuments') }}</div>
              <div
                class="project-details-right-header-title-count"
                :class="{ 'title-no-attachments': ProjectDetails.attachments.length < 1 }"
              >
                {{ ProjectDetails.attachments.length }}
              </div>
            </div>
          </div>
          <div
            class="attachments-information"
            :class="{ 'attachments-information-with-only-att': projectWithOnlyAttachment }"
          >
            <icon-button
              class="attachments-information-btn"
              btnStyle="tertiary"
              icon="ri-information-fill"
              size="small"
              v-tooltip.bottom-end="{
                content: tooltipAllowedMimeType,
                classes: ['attachments-information-btn-tooltip']
              }"
            />
          </div>
        </div>
        <div
          :class="[
            'project-details-right-list',
            projectWithOnlyAttachment && 'project-details-right-list-with-only-att'
          ]"
        >
          <attachments
            :attachments="attachments"
            :isFullWidth="projectWithOnlyAttachment"
            @deleteAttachments="openConfirmationDeleteAttachmentsModal"
            @downloadAttachments="downloadAttachments"
            @openAttachmentsPreview="openAttachmentsPreview"
            @renameAttachment="renameAttachment"
          />
          <div
            class="project-details-right-list-pending"
            v-for="(element, index) in Object.values(attachmentUpload)"
            :key="`pending-attachment-${index}`"
          >
            <div class="project-details-right-list-pending--">
              <spinner
                color="grey"
                :percent="getUploadProgress(element)"
                v-if="getUploadProgress(element) !== -1"
              />
              <spinner-without-progress color="grey" v-else />
            </div>
            <div class="project-details-right-list-pending-name">
              {{ element.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lottie from 'lottie-web';
import { mapGetters } from 'vuex';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import * as timeago from 'timeago.js';
import get from 'lodash.get';
import clonedeep from 'lodash.clonedeep';

import { bus } from '@/utils/bus';
import { trackEvent } from '@/utils/functions/analytics';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import { openUploadFileInput } from '@/utils/functions/upload';
import { formatDuration, FORMAT_DURATION } from '@/utils/functions/time';
import generateTracklist from '@/utils/functions/tracklist';
import { getAllowedFileTypes, getFileSize } from '@/utils/functions/audio';
import { PAGINATOR_LIMIT, appConfig } from '@/utils/constants';
import {
  PROJECT_DETAILS_INFO,
  PROJECT_DETAILS_TRACKS,
  PROJECT_LIST
} from '@/graphql/queries/project';
import { WORKSPACE } from '@/graphql/queries/user';
import {
  PROJECT_DELETE_TRACKS,
  PROJECT_DELETE_ATTACHMENTS,
  PROJECT_RENAME_ATTACHMENTS
} from '@/graphql/mutations/project';
import ConfirmDeleteModal from '@/containers/modals/confirmDelete';
import Tracks from '@/containers/projectTrackList';
import Attachments from '@/containers/attachmentList';
import EditTracksPanel from '@/containers/editTracksPanel';
import InfiniteScroll from '@/containers/infiniteScroll';
import DeleteMultipleTracksModal from '@/containers/modals/deleteMultipleTracks';
import MarkdownWysiwyg from '@/containers/markdownWysiwyg';

export default {
  components: {
    Tracks,
    Attachments,
    EditTracksPanel,
    InfiniteScroll,
    MarkdownWysiwyg
  },
  props: {
    disableAddButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    const DEFAULT_UPLOAD_CONFIG = {
      mimetypes: [],
      extensions: []
    };
    return {
      projectEmptyAnimation: null,
      projectNoMediaAnimation: null,
      projectNoTrackAnimation: null,
      projectName: '',
      projectDescription: '',
      projectCover: null,
      libraryFileIds: [],
      attachmentId: null,
      uncheckTracks: false,
      editTracksMode: false,
      displayAttachmentsPreview: false,
      defaultAttachments: 0,
      documentsUploadConfig: get(
        this.$config,
        'uploads.project.attachment.allowed_files.documents',
        DEFAULT_UPLOAD_CONFIG
      ),
      imagesUploadConfig: get(
        this.$config,
        'uploads.project.attachment.allowed_files.images',
        DEFAULT_UPLOAD_CONFIG
      ),
      videosUploadConfig: get(
        this.$config,
        'uploads.project.attachment.allowed_files.videos',
        DEFAULT_UPLOAD_CONFIG
      ),
      musicUploadConfig: get(
        this.$config,
        'uploads.project.attachment.allowed_files.music',
        DEFAULT_UPLOAD_CONFIG
      ),
      page: 1,
      trackPaginateLimit: 20,
      trackList: null,
      isFetchingMore: false,
      demoUrls: appConfig.urls,
      fileSelectorId: 0,
      projectId: this.$route.params.id,
      deleteAttachmentsPending: false
    };
  },
  apollo: {
    ProjectDetails: {
      query: PROJECT_DETAILS_INFO,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          projectId: this.$route.params.id
        };
      },
      result() {
        this.projectName = this.ProjectDetails.name;
        this.projectDescription = this.ProjectDetails.description;
        this.projectCover = this.ProjectDetails.cover;
        if (this.$refs && this.$refs.infiniteScroll) {
          this.$refs.infiniteScroll.resetPage();
        }
      }
    }
  },
  validations: {
    projectName: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(255)
    },
    projectDescription: {
      maxLength: maxLength(2000)
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'playlist', 'attachmentUpload']),
    listTracksQuery() {
      return {
        query: PROJECT_DETAILS_TRACKS,
        key: 'ProjectDetailsTracks',
        variables: {
          projectId: this.$route.params.id,
          page: this.page,
          limit: this.trackPaginateLimit
        },
        pagination: 'page'
      };
    },
    projectEmpty() {
      return (
        this.attachments.length < 1 &&
        Object.keys(this.attachmentUpload).length < 1 &&
        this.ProjectDetails.total_tracks < 1
      );
    },
    projectWithOnlyAttachment() {
      const onlyAttachments =
        (this.attachments.length > 0 || Object.keys(this.attachmentUpload).length) &&
        this.ProjectDetails.total_tracks < 1;
      this.$emit('setOnlyAttachmentStatus', onlyAttachments);
      return onlyAttachments;
    },
    projectNameError() {
      return (
        !this.$v.projectName.required ||
        !this.$v.projectName.minLength ||
        !this.$v.projectName.maxLength
      );
    },
    projectDescriptionErrorMessage() {
      if (!this.$v.projectDescription.maxLength) {
        return ' ';
      }

      return null;
    },
    allowedMimetypes() {
      const allowedExtensions = [].concat(
        this.documentsUploadConfig.extensions,
        this.imagesUploadConfig.extensions,
        this.videosUploadConfig.extensions,
        this.musicUploadConfig.extensions
      );
      const prefixedExtensions = allowedExtensions.map(item => `.${item}`);
      return []
        .concat(
          this.documentsUploadConfig.mimetypes,
          this.imagesUploadConfig.mimetypes,
          this.videosUploadConfig.mimetypes,
          this.musicUploadConfig.mimetypes,
          prefixedExtensions
        )
        .join(', ');
    },
    attachments() {
      return this.ProjectDetails.attachments.map(attachment => {
        const name = get(attachment, 'file.name');
        const ext = name.substr(name.lastIndexOf('.'));
        const extWithoutPoint = name.substr(name.lastIndexOf('.') + 1);
        const fileNameWithoutExt = name.replace(`${ext}`, '');
        const imgSrc =
          get(attachment, 'file.thumbnails[0].url', null) ||
          this.formatThumbnailSource(extWithoutPoint);
        const fileSize = get(attachment, 'file.size');
        return {
          id: attachment.id,
          imgSrc,
          extension: get(attachment, 'file.extension').toLowerCase(),
          name: fileNameWithoutExt,
          url: get(attachment, 'file.preview_url'),
          downloadUrl: get(attachment, 'file.download_url'),
          type: attachment.type,
          size: getFileSize(fileSize),
          position: get(attachment, 'position')
        };
      });
    },
    allowedFileTypes() {
      return getAllowedFileTypes(this.$config).join(',');
    },
    tooltipAllowedMimeType() {
      const extSupported = this.allowedMimetypes
        .split(', ')
        .filter(el => !el.includes('/'))
        .join(', ')
        .replaceAll('.', '');
      return `File supported (max 2 Gb): ${extSupported}`;
    }
  },
  mounted() {
    this.init();
    bus.$on('removeTracksFromProject', files => {
      const trackToRemove = this.trackList
        .map(el => (files.includes(el.id) ? el : null))
        .filter(Boolean);
      this.libraryFileIds = trackToRemove.map(el => el.deleteId);
      setTimeout(() => {
        this.handleDeleteTrackMutation();
      }, 1000);
    });
    bus.$on('removeProjectAttachmentsFromCache', () => {
      this.$apollo.queries.ProjectDetails.refetch();
    });
  },
  updated() {
    this.init();
  },
  destroyed() {
    bus.$off('removeTracksFromProject');
    bus.$off('removeProjectAttachmentsFromCache');
  },
  methods: {
    init() {
      if (this.$refs.projectEmptyAnimation) {
        if (this.projectEmptyAnimation) {
          return;
        }
        this.projectEmptyAnimation = lottie.loadAnimation({
          container: this.$refs.projectEmptyAnimation,
          renderer: 'svg',
          autoplay: false,
          loop: false,
          path: '/animation/projectEmpty.json',
          name: 'projectEmpty'
        });
      } else if (this.projectEmptyAnimation) {
        this.projectEmptyAnimation.destroy();
        this.projectEmptyAnimation = null;
      }
      if (this.$refs.projectNoMediaAnimation) {
        if (this.projectNoMediaAnimation) {
          return;
        }
        this.projectNoMediaAnimation = lottie.loadAnimation({
          container: this.$refs.projectNoMediaAnimation,
          renderer: 'svg',
          autoplay: false,
          loop: false,
          path: '/animation/projectNoMedia.json',
          name: 'projectNoMedia'
        });
      } else if (this.projectNoMediaAnimation) {
        this.projectNoMediaAnimation.destroy();
        this.projectNoMediaAnimation = null;
      }
      if (this.$refs.projectNoTrackAnimation) {
        if (this.projectNoTrackAnimation) {
          return;
        }
        this.projectNoTrackAnimation = lottie.loadAnimation({
          container: this.$refs.projectNoTrackAnimation,
          renderer: 'svg',
          autoplay: false,
          loop: false,
          path: '/animation/projectNoTrack.json',
          name: 'projectNoTrack'
        });
      } else if (this.projectNoTrackAnimation) {
        this.projectNoTrackAnimation.destroy();
        this.projectNoTrackAnimation = null;
      }
    },
    getUploadProgress(obj) {
      return this.attachmentUpload[obj.id].progress;
    },
    handleQueryResult(response) {
      const libraryFiles = get(response, 'data.ProjectDetailsTracks.tracksPaginate', [])
        .map(track => ({
          added_by: track.added_by,
          created_at: track.created_at,
          delete_id: track.id,
          position: track.position,
          ...track.libraryFile
        }))
        .sort((a, b) => a.position - b.position);
      this.trackList = generateTracklist(libraryFiles, this.currentWorkspace, this.$i18n.locale);
    },
    setLoading(bool) {
      this.isFetchingMore = bool;
    },
    uploadProjectCover(files) {
      const cover = files[0];
      this.$emit('uploadProjectCover', cover, false);
    },
    openAttachmentsPreview(id) {
      this.defaultAttachments = id;
      this.displayAttachmentsPreview = true;
    },
    downloadItemFromPreview(id) {
      this.downloadAttachments(this.attachments[id].downloadUrl);
    },
    deleteItemFromPreview(id) {
      this.attachmentId = id;
      this.deleteAttachments();
    },
    closePreview() {
      this.displayAttachmentsPreview = false;
    },
    handleAddToProjectActions(action) {
      this[action]();
    },
    sendTrackEvent(event, category) {
      trackEvent(event, {
        category,
        page_view: this.$route.path.includes('shared')
          ? 'shared project details'
          : 'project details'
      });
    },
    handleAddTracksFromLibrary() {
      this.sendTrackEvent('Project AddfromLibrary Click', 'addto');
      this.$emit('addTracks');
    },
    handleOpenFileInput(isFolder = false) {
      // Generate a new ID before opening the file input
      const idMemory = this.fileSelectorId;
      while (idMemory === this.fileSelectorId) {
        this.fileSelectorId = Math.round(Math.random() * 1000);
      }
      openUploadFileInput(
        { id: this.fileSelectorId, isFolder, allowedMimeTypes: this.allowedMimeTypes },
        files => {
          bus.$emit('prepareUploadForFiles', files);
        }
      );
    },
    handleAnimation(instance, direction) {
      instance.setDirection(direction);
      instance.play();
    },
    getUpdatedDate(timestamp) {
      return timeago.format(timestamp * 1000);
    },
    getFormatDuration(timestamp) {
      return formatDuration(timestamp, FORMAT_DURATION.hmm);
    },
    updateProjectName() {
      if (
        this.ProjectDetails &&
        this.projectName &&
        this.projectName !== this.ProjectDetails.name &&
        !this.projectNameError
      ) {
        this.$emit(
          'handleProjectUpdateMutation',
          this.projectName,
          this.ProjectDetails.description,
          this.projectId
        );
      } else {
        if (this.projectName.length < 3 || this.projectName.length > 255) {
          let key;
          let nbChars;
          if (this.projectName.length < 3) {
            key = 'projectTitleMinLength';
            nbChars = this.$v.projectName.$params.minLength.min;
          } else if (this.projectName.length > 255) {
            key = 'projectTitleMaxLength';
            nbChars = this.$v.projectName.$params.maxLength.max;
          }
          bus.$emit('showAlert', {
            message: {
              key,
              args: { nbChars }
            },
            style: 'danger',
            delay: 5000,
            error: true,
            hideDismissible: true
          });
        }
        this.projectName = get(this.ProjectDetails, 'name', '');
      }
    },
    updateProjectDescription() {
      if (this.projectDescription !== this.ProjectDetails.description) {
        this.$emit(
          'handleProjectUpdateMutation',
          this.projectName,
          this.projectDescription,
          this.projectId
        );
      } else {
        this.projectDescription = this.ProjectDetails.description;
      }
    },
    openConfirmRemoveModal(libraryFileIds) {
      this.libraryFileIds = libraryFileIds;
      // const findId = 'deleteId';
      if (libraryFileIds.length > 1) {
        bus.$emit('displayModal', {
          title: this.$t('removeTracks'),
          size: 'medium',
          component: DeleteMultipleTracksModal,
          onSubmit: this.handleDeleteTrackMutation,
          isVisible: true,
          props: [
            { name: 'tracksNumber', value: libraryFileIds.length },
            { name: 'isRemove', value: true }
          ]
        });
        this.uncheckTracks = false;
        return;
      }
      bus.$emit('displayModal', {
        component: ConfirmDeleteModal,
        title: this.$t('removeTrack'),
        size: 'medium',
        onSubmit: this.handleDeleteTrackMutation,
        msg: 'removeTrackFromProject',
        props: [
          {
            name: 'tracksName',
            value: this.trackList.find(el => el.deleteId === libraryFileIds[0]).title
          }
        ],
        isVisible: true
      });
      this.uncheckTracks = false;
    },
    openConfirmationDeleteAttachmentsModal(attachmentId) {
      this.attachmentId = attachmentId;
      bus.$emit('displayModal', {
        component: ConfirmDeleteModal,
        title: this.$t('deleteFile'),
        size: 'medium',
        onSubmit: this.deleteAttachments,
        msg: 'confirmDeleteFile',
        isVisible: true
      });
    },
    handleDeleteTrackMutation() {
      this.$apollo
        .mutate({
          mutation: PROJECT_DELETE_TRACKS,
          variables: {
            projectId: this.$route.params.id,
            tracksIds: this.libraryFileIds
          },
          update: cache => {
            const tracks = cache.readQuery({
              query: PROJECT_DETAILS_TRACKS,
              variables: {
                projectId: this.$route.params.id,
                page: 1,
                limit: this.trackPaginateLimit
              }
            });
            const cloneTracks = clonedeep(tracks);
            this.libraryFileIds.forEach(fileId => {
              const indexToDelete = cloneTracks.ProjectDetailsTracks.tracksPaginate.findIndex(
                track => track.id === fileId
              );
              cloneTracks.ProjectDetailsTracks.tracksPaginate.splice(indexToDelete, 1);
              // We must update the number of tracks
              cloneTracks.ProjectDetailsTracks.total_tracks -= 1;
            });
            cache.writeQuery({
              query: PROJECT_DETAILS_TRACKS,
              data: cloneTracks
            });
            // using updateDrag mutation update to reposition tracks after deletion if there are
            // still some track in project
            if (this.libraryFileIds.length < this.ProjectDetails.total_tracks) {
              this.$refs.trackListRef.updateDrag({
                list: cloneTracks.ProjectDetailsTracks.tracksPaginate,
                fromRemove: true
              });
            }
          }
        })
        .then(() => {
          bus.$emit('showAlert', {
            message: { key: 'removeTracksSucces', params: this.libraryFileIds.length },
            style: 'success',
            delay: 5000
          });
          this.libraryFileIds = [];
          this.uncheckTracks = true;
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    displayErrorAlert(message, args, params) {
      bus.$emit('showAlert', {
        message,
        args,
        params,
        style: 'danger',
        delay: 5000
      });
    },
    deleteAttachments() {
      if (!this.deleteAttachmentsPending) {
        this.deleteAttachmentsPending = true;
        this.$apollo
          .mutate({
            mutation: PROJECT_DELETE_ATTACHMENTS,
            variables: {
              projectId: this.$route.params.id,
              attachmentsIds: this.attachmentId
            },
            update: () => {
              const index = this.ProjectDetails.attachments.findIndex(
                attachment => attachment.id === this.attachmentId
              );
              this.ProjectDetails.attachments.splice(index, 1);
            },
            refetchQueries: [
              {
                query: PROJECT_LIST,
                variables: {
                  workspaceId: this.currentWorkspace.id,
                  status: 'active',
                  page: 1,
                  limit: PAGINATOR_LIMIT
                }
              },
              {
                query: WORKSPACE,
                variables: {
                  workspaceId: this.currentWorkspace.id
                }
              }
            ]
          })
          .then(() => {
            this.deleteAttachmentsPending = false;
            trackEvent('Attachment deleted');
            bus.$emit('showAlert', {
              message: { key: 'removeAttachmentSuccess' },
              style: 'success',
              delay: 5000
            });
            this.attachmentId = null;
          })
          .catch(error => {
            const key = getGraphQlErrorCode(error);
            bus.$emit('showAlert', {
              message: { key },
              style: 'danger',
              delay: 5000,
              error: true
            });
          });
      }
    },
    renameAttachment({ newName, attachmentId }) {
      this.$apollo
        .mutate({
          mutation: PROJECT_RENAME_ATTACHMENTS,
          variables: {
            projectId: this.$route.params.id,
            attachmentId,
            newName
          }
        })
        .then(() => {})
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    downloadAttachments(url) {
      const anchor = document.createElement('a');
      anchor.href = url;
      document.body.appendChild(anchor);
      trackEvent('Attachment downloaded');
      anchor.click();
    },
    toggleEditMode(libraryFileIds) {
      if (this.$isWorkspaceLockedVerification(this)) return;
      this.libraryFileIds = libraryFileIds;
      this.editTracksMode = this.libraryFileIds.length > 0;
      if (!this.editTracksMode) {
        this.$store.commit('setEditPanelOpened', false);
      } else {
        this.$store.commit('setEditPanelOpened', true);
      }
    },
    formatThumbnailSource(extension) {
      let thumbnailUrl;
      switch (extension) {
        case 'pdf':
          thumbnailUrl = '/images/fileThumbnails/pdf.svg';
          break;
        case 'doc':
        case 'docx':
          thumbnailUrl = '/images/fileThumbnails/document.svg';
          break;
        case 'xls':
        case 'xlsx':
        case 'csv':
          thumbnailUrl = '/images/fileThumbnails/spreadsheet.svg';
          break;
        case 'avi':
        case 'mov':
        case 'mp4':
        case 'mpeg':
          thumbnailUrl = '/images/fileThumbnails/video.svg';
          break;
        case 'txt':
        default:
          thumbnailUrl = '/images/fileThumbnails/other_files.svg';
          break;
      }
      return thumbnailUrl;
    }
  }
};
</script>
<style lang="scss" scoped>
.project-details {
  display: grid;
  grid-template-areas: 'details attachments';
  grid-template-columns: 1fr 350px;
  grid-auto-rows: minmax(0, 1fr);
  font-size: 14px;
  color: $color_neutral_100;
  &-empty {
    grid-template-areas: 'details';
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(0, 1fr);
  }
  &-with-only-att {
    // set full height to avoid dropdown to overflow
    height: calc(100vh - 56px - 41px - 15px);
    grid-template-areas: 'details';
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
    grid-auto-rows: auto;
  }
  &-with-only-att.with-player {
    // set full height to avoid dropdown to overflow taking player into account
    height: calc(100vh - 56px - 41px - 65px - 15px);
  }
  &-without-att {
    grid-template-columns: 1fr;
  }
  &-left {
    grid-area: details;
    padding: 0 40px 0 40px;
    overflow-y: auto;
    box-sizing: border-box;
    &-empty {
      display: flex;
      flex-direction: column;
      // .project-details-left-tracklist {
      //   display: flex;
      //   flex-direction: column;
      //   flex-grow: 1;
      // }
    }
    &-with-only-att {
      height: max-content;
    }
    .desc-container {
      .contextual-actions {
        height: 56px;
        display: flex;
        align-items: center;
      }
      .mr-2 {
        margin-right: 8px;
      }
    }
    &-uploadcover {
      margin-top: 8px;
      height: 32px;
      .upload-cover-btn {
        margin-left: -6px;
      }
    }
    &-title {
      margin: 4px 0 0 -10px;

      &-editable {
        max-width: 100%;
      }
    }

    &-updatedat {
      @include body-2;
      color: $color_neutral_60;
      margin: 8px 0 0 0;
    }

    &-description {
      max-width: 800px;
      margin: 16px -12px 8px -12px;
    }

    &-tracklist {
      margin: 0 0 40px 0;

      &-with-only-att {
        margin: 0;
      }

      &-buttons {
        display: flex;

        width: calc(100% + 40px);
        background: white;
        position: sticky;
        top: 0px;
        padding-top: 32px;
        padding-bottom: 16px;
        padding-left: 32px;
        margin-left: -32px;
        z-index: 6;
        &-- {
          display: flex;
          align-items: center;

          &:first-child {
            margin: 0 24px 0 0;
          }
        }

        &-title {
          margin: 0 4px 0 0;
        }

        &-count {
          background-color: $color_primary_100;
          border-radius: 4px;
          color: $color_neutral_0;
          font-size: 10px;
          padding: 4px;
          min-width: 8px;
          display: flex;
          justify-content: center;
        }
      }

      &-tracks {
        position: relative;
        margin: 24px 0 0 -24px;
      }
    }
  }

  &-right {
    grid-area: attachments;
    padding: 32px 0 32px 24px;
    box-sizing: border-box;
    border-left: 1px solid $color_neutral_30;
    &-with-only-att {
      grid-area: details;
      border-left: none;
      grid-row-start: 2;
      padding: 0 40px 0 40px;
    }
    &-container {
      height: 100%;
    }
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: $color_neutral_100;
      padding-right: 2px;
      &-title {
        position: relative;
        &-- {
          font-weight: 600;
          display: flex;
        }
        &-count {
          background-color: $color_primary_100;
          border-radius: 4px;
          color: $color_neutral_0;
          font-size: 10px;
          padding: 4px;
          margin: 0 0 0 4px;
          height: 10px;
          min-width: 8px;
          display: flex;
          justify-content: center;
        }
        &-count.title-no-attachments {
          background-color: $color_neutral_30;
          color: $color_neutral_60;
        }
      }
      .attachments-information {
        position: relative;
      }
    }

    &-list {
      margin: 24px 0 40px -24px;
      height: calc(100% - 64px);
      overflow-y: auto;
      &-with-only-att {
        margin-left: 0;
        height: auto;
        overflow-y: visible;
      }
      &-pending {
        height: 49px;
        border-bottom: 1px dotted $color_neutral_40;
        display: flex;
        align-items: center;
        margin-left: 20px;
        &-- {
          width: 58px;
          display: flex;
          justify-content: center;
        }
        &-name {
          padding: 0 13px 0 16px;
          white-space: nowrap;
          width: 176px;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: initial;
        }
      }
    }
  }
  .previewer {
    top: 0;
    left: 0;
  }
}

.tracks-edition {
  background-color: $color_neutral_0;
  position: fixed;
  top: 0;
  z-index: map-get($z-index, side-panel);
  right: -520px;
  transition: right 0.15s ease;
  height: calc(100vh - 65px);
  overflow: auto;
  border-left: 1px solid $color_neutral_30;
  border-top: 1px solid $color_neutral_30;
  &-open {
    right: 0;
    @include shadow-down-01;
  }
  &-wrapper {
    width: 518px;
    height: 100%;
  }
}
.track-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 510px);
}
.no-track {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateX(40px);

  .animation-container {
    width: 180px;
  }
  .animation-actions {
    display: flex;
    &-- {
      margin-right: 8px;
    }
  }
  .empty-cta-container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 270px;
  }
  .empty-description {
    width: 270px;
    margin-top: 7px;
    text-align: center;
    &-title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    &-body {
      font-size: 14px;
      line-height: 21px;
      color: $color_neutral_60;
    }
  }
}
</style>

<style lang="scss">
.project-details-left {
  .project-details-left-description .area-text textarea {
    padding: 8px;
  }
}
.project-details-left-description .area-text.area-no-border {
  border: 1px solid $color_neutral_30;
}
.project-details-left-description .area-text.area-no-border.area-active {
  border: 1px solid $color_neutral_60;
}

.attachments-information-btn-tooltip {
  width: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
  top: calc(100% + 8px);
  background: $color_neutral_100;
  color: $color_neutral_0;
  padding: 16px;
  border-radius: 8px;
  .tooltip-li:before {
    content: '';
    display: block;
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: white;
  }
}

.contextual-actions .action.tertiary.small {
  font-size: 14px;
  color: $color_neutral_60;
}
.contextual-actions .action.tertiary.small .prefix i {
  color: $color_neutral_80;
}

.contextual-actions .ca-library.action.tertiary.small .prefix i {
  color: $color_blue_100;
}
</style>
