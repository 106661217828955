
<div>
  <div class="links-explanation-modal-text">{{ $t('linksExplanation') }}</div>
  <div class="links-explanation-modal-checkbox">
    <checkbox id="not-show-again" :label="$t('dontShowAgain')" @toggleCheckbox="toggleCheckbox" />
  </div>
  <div class="links-explanation-modal-buttons">
    <div class="links-explanation-modal-buttons--">
      <submit-button btnStyle="primary" size="medium" :label="$t('okGotIt')" @submit="close" />
    </div>
  </div>
</div>
