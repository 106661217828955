
<div class="information-banner">
  <div class="icon-info"><i class="ri-information-fill"></i></div>
  <div class="text">
    {{ $t('pleaseUpgradeBrowser') }}
  </div>
  <icon-button
    class="icon-close-browser"
    btnStyle="tertiary"
    icon="ri-close-fill"
    size="small"
    @submit="hide"
  />
</div>
