<template>
  <div>
    <p class="planTitle">
      <i class="ri-music-fill planIcon" />
      <span>{{ plan.name }}</span>
    </p>
    <p class="planDescription">{{ $t('freePlan.description') }}</p>
    <div>
      <span class="planCurrency">{{ currencySymbol }}</span>
      <span class="planPrice">{{ planPrice() }}</span>
    </div>
    <div class="planRecurrence">{{ $t('freePlan.information') }}</div>
    <div class="planFeatures">
      <i class="ri-server-fill" />
      {{
        $t('planStorageInformation', {
          tracks: planFeature('libraryFilesMax'),
          storage: planFeature('storage')
        })
      }}
    </div>
    <div v-tooltip="getButtonTooltipText">
      <submit-button
        :primaryException="true"
        :label="getButtonLabel"
        :disabled="isButtonDisabled"
        class="planButton"
        size="large"
        @submit="cancelSubscription"
      />
    </div>
    <ul class="planBenefits">
      <li><i class="ri-check-line" />{{ $t('freePlan.benefitsMembers') }}</li>
      <li><i class="ri-check-line" />{{ $t('freePlan.benefitsSharing') }}</li>
      <li><i class="ri-check-line" />{{ $t('freePlan.benefitsNotifications') }}</li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  hasActiveSubscription,
  isSubscriptionCanceled,
  isFreeTrial
} from '@/utils/functions/workspace';
import { bus } from '@/utils/bus';
import {
  getCurrencySymbol,
  getPlanVariantFeature,
  getPlanVariantFeatureFormated,
  getPlanVariantPrice
} from '@/utils/functions/plan';

import SubscriptionCancelModal from '@/containers/modals/subscriptionCancel';

const VARIANT_INDEX = 0; // There is only one variant on free plan

export default {
  props: {
    plan: {
      type: Object,
      required: true
    },
    currentPlan: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isUpdateProcessing: false
    };
  },
  computed: {
    ...mapGetters(['me', 'currentWorkspace', 'userCurrency']),
    currencySymbol() {
      return getCurrencySymbol(this.userCurrency);
    },
    getButtonLabel() {
      if (hasActiveSubscription(this.currentWorkspace)) {
        return 'Downgrade to Free plan';
      }
      return 'Current Plan';
    },
    getButtonTooltipText() {
      if (isFreeTrial(this.currentWorkspace)) {
        return "You can't unsubscribe while on free trial";
      }
      if (isSubscriptionCanceled(this.currentWorkspace)) {
        return 'You already canceled your subscription';
      }
      return null;
    },
    getButtonStatus() {
      return (
        this.currentPlan === 'free' ||
        this.currentPlan === 'beta' ||
        isSubscriptionCanceled(this.currentWorkspace)
      );
    },
    isButtonDisabled() {
      const variant = this.plan.variants[VARIANT_INDEX];
      return (
        this.currentPlan === 'free' ||
        this.currentPlan === 'beta' ||
        isFreeTrial(this.currentWorkspace) ||
        this.currentWorkspace.total_track > getPlanVariantFeature(variant, 'libraryFilesMax') ||
        parseInt(this.currentWorkspace.space_used, 2) >
          parseInt(getPlanVariantFeature(variant, 'storage'), 2) ||
        isSubscriptionCanceled(this.currentWorkspace)
      );
    }
  },
  methods: {
    cancelSubscription() {
      bus.$emit('displayModal', {
        component: SubscriptionCancelModal,
        title: this.$t('Are you sure you want to cancel your plan?'),
        isVisible: true,
        size: 'medium',
        onSubmit: password => {
          this.$emit('cancelSubscription', password);
        },
        props: [
          {
            name: 'email',
            value: this.email
          }
        ]
      });
    },
    setPriceIndex(value) {
      this.$emit('setPriceSelection', parseInt(value, 10));
    },
    planFeature(featureKey) {
      return getPlanVariantFeatureFormated(this.plan.variants[VARIANT_INDEX], featureKey);
    },
    planPrice() {
      return getPlanVariantPrice(this.plan.variants[VARIANT_INDEX], this.userCurrency);
    }
  }
};
</script>

<style lang="scss" scoped>
.planIcon {
  background: #fff9f2;
  color: $color_secondary_100;
  transform: rotate(-10deg);
}
.planTitle {
  @include heading-3;
  font-size: 24px;
  margin-bottom: 20px;
  display: flex;
  color: #000;
  align-items: center;
  i {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    font-size: 22px;
    margin-right: 8px;
  }
}

.planDescription {
  color: $color_neutral_60;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 20px;
}

.planPrice {
  font-variant-numeric: tabular-nums;
  font-size: 64px;
  line-height: 84px;
}

.planCurrency {
  font-size: 28px;
}

.planRecurrence {
  color: $color_neutral_60;
  margin-bottom: 24px;
  font-size: 14px;
  height: 28px;
}

.planFeatures {
  font-variant-numeric: tabular-nums;
  margin-bottom: 16px;
  font-size: 14px;
  color: $color_primary_100;
  i {
    font-size: 20px;
    position: relative;
    top: 4px;
    fill: $color_neutral_0;
  }
}

.planButton {
  margin-top: 102px;
}

.planBenefits {
  font-size: 14px;
  line-height: 21px;
  margin-top: 40px;
  color: $color_neutral_80;
  li {
    padding-left: 32px;
    position: relative;
    padding-bottom: 8px;
  }
  i {
    position: absolute;
    left: 0;
    font-size: 22px;
    margin-right: 10px;
    top: 3px;
    color: $color_primary_100;
  }
}
</style>

<style lang="scss">
.planButton.button.primary.large.no-icon.exception-bis {
  background: $color_neutral_0;
  color: $color_neutral_100;
  border: 1px solid #cbcace;
  &:hover {
    background-color: $color_neutral_30;
  }
  &:focus {
    box-shadow: 0px 0px 0px 1px #252428, 0px 0px 0px 3px $color_neutral_40;
  }
  &.disabled {
    border: 1px solid $color_neutral_40;
    color: $color_neutral_80;
    background: rgba(255, 255, 255, 0.4);
  }
}
</style>
