<template>
  <div class="tracks-details">
    <div :class="['tracks-edition', editPanelTracks.length > 0 ? 'tracks-edition-open' : '']">
      <div class="tracks-edition-wrapper">
        <edit-tracks-panel
          v-if="editPanelTracks.length > 0"
          ref="editTracksPanel"
          :libraryFileIds="editPanelTracks"
          :trackList="trackList"
          :showArrows="false"
          @toggleEditMode="toggleEditMode"
        />
      </div>
    </div>
    <div class="tracks-list">
      <div
        class="tracks-list-content"
        :style="editPanelTracks.length > 0 && 'width: calc(100% - 518px + 38px);'"
      >
        <div v-show="!LibraryFilesDetails" class="loader">
          <spinner-without-progress color="grey" :size="32" />
        </div>
        <tracks
          v-if="LibraryFilesDetails"
          :trackList="trackList"
          :isFetchingMore="loading"
          :editMode="editPanelTracks.length > 0"
          isSticky
          :stickyPosition="41"
          hasSelectAll
          @toggleEditMode="toggleEditMode"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { LIBRARY_FILES_DETAILS_LIGHT } from '@/graphql/queries/library';
import generateTracklist from '@/utils/functions/tracklist';

import Tracks from '@/containers/sharedTrackList';
import EditTracksPanel from '@/containers/editTracksPanel';

export default {
  components: {
    Tracks,
    EditTracksPanel
  },
  props: {
    entityIds: {
      type: Array,
      required: true
    }
  },
  apollo: {
    LibraryFilesDetails: {
      query: LIBRARY_FILES_DETAILS_LIGHT,
      variables() {
        return {
          libraryFilesIds: this.entityIds
        };
      },
      result(res) {
        if (res && res.data && res.data.LibraryFilesDetails) {
          this.trackList = generateTracklist(
            res.data.LibraryFilesDetails,
            this.currentWorkspace,
            this.$i18n.locale,
            false
          );
        }
      },
      watchLoading(loading) {
        this.loading = loading;
      }
    }
  },
  data() {
    return {
      trackList: null,
      loading: false,
      editPanelTracks: []
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace'])
  },
  methods: {
    toggleEditMode(tracksIds) {
      if (this.$isWorkspaceLockedVerification(this)) return;
      this.editPanelTracks = tracksIds;
      this.$store.commit('setEditPanelOpened', this.editPanelTracks.length > 0);
    }
  }
};
</script>

<style lang="scss" scoped>
.tracks {
  &-details {
    margin-left: 12px;
  }
  &-list-content {
    transition: width 0.15s ease;
    width: 100%;
  }
  &-edition {
    background-color: $color_neutral_0;
    position: fixed;
    top: 0;
    z-index: map-get($z-index, side-panel);
    right: -520px;
    transition: right 0.15s ease;
    height: calc(100vh - 65px);
    overflow: auto;
    border-left: 1px solid $color_neutral_30;
    border-top: 1px solid $color_neutral_30;
    &-open {
      right: 0;
      @include shadow-down-01;
    }
    &-wrapper {
      height: 100%;
      width: 518px;
    }
  }
}
.loader {
  height: calc(100vh - 56px - 65px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
