<template>
  <div :class="id" v-click-outside="{ hide: hideExpand }">
    <div class="input-dropdown" :style="`width: ${width}px;`">
      <div class="input-dropdown-edit" v-if="disabled && withEditButton">
        <submit-button
          btnStyle="tertiary"
          exception
          danger
          size="small"
          label="Edit"
          @submit="handleEditBtnClick"
        />
      </div>
      <div
        :class="{
          'input-dropdown-area': true,
          'input-dropdown-area-focus': isFocus,
          'input-dropdown-area-multiple': isMultipleSelection,
          'input-dropdown-area-disabled': disabled,
          'input-dropdown-area-error': errorMessage
        }"
        :style="`width: ${width}px;`"
        @click.self="handleAreaClick"
      >
        <div
          :class="{
            'input-dropdown-area-selected-option': true,
            'input-dropdown-area-hidden-option': !isMultipleSelection
          }"
          :style="`max-width: ${width - 60}px;`"
          v-for="option in selectedOptions"
          :key="`selected-${option.id}`"
        >
          <div class="input-dropdown-area-selected-option--">{{ option.value }}</div>
          <i class="ri-close-circle-fill" @click.stop="removeOptionOnClick(option.id)" />
        </div>
        <div
          class="input-dropdown-placeholder"
          v-if="defaultOptions.length === 0 && placeholder && disabled && isMultipleSelection"
        >
          {{ placeholder }}
        </div>
        <input
          :class="{
            'input-dropdown-area-input': true,
            'input-dropdown-area-multiple-input': isMultipleSelection,
            'input-dropdown-area-unique-input': !isMultipleSelection
          }"
          type="text"
          ref="input"
          v-model="newValue"
          :placeholder="placeholder"
          :style="isMultipleSelection ? `width: ${inputWidth}px;` : 'width: 100%;'"
          :disabled="disabled"
          @keydown.tab="hideExpand"
          @keydown="handleKeydown"
          @keyup.8="removeOptionOnKeyup"
          @focus="handleFocus"
          @blur="handleBlur"
          @input="handleInput"
        />
        <i class="ri-arrow-drop-down-line" />
      </div>
      <div
        class="input-dropdown-expand"
        :style="`width: ${width}px;`"
        v-if="isExpanded"
        ref="dropdownExpand"
      >
        <div class="input-dropdown-expand-wrapper">
          <div
            class="input-dropdown-expand-wrapper-section"
            v-if="optionList.length > 0 || loadingOptions"
          >
            <div class="input-dropdown-expand-wrapper-section-loader" v-if="loadingOptions">
              <spinner color="grey" />
            </div>
            <div v-else>
              <div
                class="input-dropdown-expand-wrapper-section-option"
                v-for="(option, index) in filteredOptionList"
                :class="{
                  'highlighted-option': highlightId === index
                }"
                ref="unselectedOptions"
                :key="`${option.id}-${index}`"
                @mouseenter="setHighlightedId(index)"
                @mousedown.stop="handleOptionClick(option)"
                v-tooltip="{
                  content: option.value.length * 6.5 > width - 59 ? option.value : '',
                  delay: { show: 500, hide: 0 }
                }"
              >
                <checkbox
                  v-if="isMultipleSelection"
                  :id="option.id"
                  @toggleCheckbox="handleOptionClick(option)"
                />
                <div
                  class="input-dropdown-expand-wrapper-section-option--"
                  :style="`width:${width - 59}px`"
                >
                  {{ option.value }}
                </div>
              </div>
              <div
                class="input-dropdown-expand-wrapper-section-skeleton"
                v-if="fetchingMoreOptions"
              >
                <skeleton />
              </div>
            </div>
          </div>
          <div
            class="input-dropdown-expand-wrapper-section section-create"
            v-if="showAddButton || maxError"
          >
            <!-- using mousedown instead of click here to trigger before blur to avoid computed showAddButton to be false before executing the handleClickAddNew method -->
            <div
              v-if="!maxError"
              class="input-dropdown-expand-wrapper-section-option input-dropdown-expand-wrapper-section-add"
              :class="{
                'highlighted-create': highlightId === null
              }"
              @mouseenter="setHighlightedId(null)"
              @mousedown.stop="handleClickAddNew"
              ref="createOption"
            >
              <i class="ri-add-circle-line" />
              <i class="ri-add-circle-fill" />
              <div class="input-dropdown-expand-add">{{ `Create ${newValue}` }}</div>
            </div>
            <div
              v-else
              class="input-dropdown-expand-wrapper-section-option input-dropdown-expand-wrapper-section-error"
            >
              <i class="ri-alert-fill"></i>
              {{ maxError }}
            </div>
          </div>
          <observer
            v-if="showObserver"
            :height="15"
            bottom
            @intersect="$emit('fetchMoreOptions')"
          />
        </div>
      </div>
      <div class="input-dropdown-error">{{ errorMessage }}</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { VTooltip } from 'v-tooltip';
import Observer from '@/containers/observer';
import { clickOutside } from '../../utils/directives';
import Checkbox from '../checkbox';
import Skeleton from '../skeleton';
import Spinner from '../loaders/spinnerWithoutProgress';
import SubmitButton from '../buttons/submitButton';

Vue.directive('tooltip', VTooltip);

export default {
  components: {
    Checkbox,
    Skeleton,
    Spinner,
    SubmitButton,
    Observer
  },
  props: {
    id: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    width: {
      type: Number,
      required: false,
      default: 330
    },
    isMultipleSelection: {
      type: Boolean,
      required: false,
      default: false
    },
    defaultOptions: {
      type: Array,
      required: false,
      default: () => []
    },
    defaultList: {
      type: Array,
      required: false,
      default: () => []
    },
    loadingOptions: {
      type: Boolean,
      required: false,
      default: false
    },
    showObserver: {
      type: Boolean,
      required: false,
      default: false
    },
    fetchingMoreOptions: {
      type: Boolean,
      required: false,
      default: false
    },
    withEditButton: {
      type: Boolean,
      required: false,
      default: false
    },
    errorMessage: {
      type: String,
      required: false,
      default: null
    },
    withAddButton: {
      type: Boolean,
      required: false,
      default: true
    },
    preventRemoveOptOnKeyUp: {
      type: Boolean,
      required: false,
      default: false
    },
    preventAddNew: {
      type: Boolean,
      required: false,
      default: false
    },
    maxLength: {
      type: Number,
      required: false,
      default: 255
    }
  },
  data() {
    return {
      isExpanded: false,
      selectedOptions: this.defaultOptions,
      newValue:
        !this.isMultipleSelection && this.defaultOptions[0] ? this.defaultOptions[0].value : '',
      isFocus: false,
      optionList: [],
      disabled: this.withEditButton,
      addNew: false,
      hasHandleResult: false,
      highlightId: 0
    };
  },
  computed: {
    filteredOptionList() {
      const flattenSelectedOptions = this.selectedOptions.map(i => i.value);
      const filteredOptionList = this.defaultList.filter(
        option => !flattenSelectedOptions.includes(option.value)
      );
      if (this.newValue.trim().length > 0) {
        return filteredOptionList.filter(item =>
          item.value.toLowerCase().includes(this.newValue.toLowerCase())
        );
      }
      return filteredOptionList;
    },
    inputWidth() {
      if (this.newValue.length === 0) return 0;
      const c = document.createElement('canvas');
      const ctx = c.getContext('2d');
      ctx.font = '14px manrope';
      return ctx.measureText(this.newValue).width + 8;
    },
    showAddButton() {
      if (!this.withAddButton) return false;
      if (this.newValue.trim().length === 0) return false;
      const length1 = this.optionList.filter(
        option => option.value.toLowerCase() === this.newValue.toLowerCase()
      ).length;
      const length2 = this.selectedOptions.filter(
        option => option.value.toLowerCase() === this.newValue.toLowerCase()
      ).length;
      return length1 === 0 && length2 === 0;
    },
    maxError() {
      return this.newValue.trim().length > this.maxLength
        ? `${this.maxLength} characters maximum`
        : false;
    }
  },
  directives: {
    clickOutside
  },
  watch: {
    defaultList() {
      if (this.defaultList.length === 0) {
        this.highlightId = null;
      }
      const selectedIds = this.selectedOptions.map(option => option.id);
      this.optionList = this.defaultList.filter(option => !selectedIds.includes(option.id));
    },
    selectedOptions() {
      if (this.selectedOptions[0] !== undefined) {
        this.hasHandleResult = true;
      }
      this.$emit('handleResult', this.selectedOptions, this.addNew);
      this.addNew = false;
    },
    defaultOptions() {
      this.newValue =
        !this.isMultipleSelection && this.defaultOptions[0] ? this.defaultOptions[0].value : '';
    },
    newValue() {
      if (this.hasHandleResult) {
        this.hasHandleResult = false;
        return;
      }
      this.$emit('handleInput', this.newValue);
      this.addNew = false;
    }
  },
  mounted() {
    const selectedIds = this.selectedOptions.map(option => option.id);
    this.optionList = this.defaultList.filter(option => !selectedIds.includes(option.id));
  },
  methods: {
    hideExpand() {
      this.isExpanded = false;
    },
    handleAreaClick() {
      this.$refs.input.focus();
    },
    handleFocus() {
      this.isFocus = true;
      this.isExpanded = true;
      this.$emit('listOptions');
    },
    handleBlur() {
      this.isFocus = false;
      if (this.isMultipleSelection || !this.selectedOptions.length) {
        this.newValue = '';
        return;
      }
      if (this.newValue !== '' && this.selectedOptions[0].value !== this.newValue) {
        this.newValue = this.selectedOptions[0].value || '';
      }
      this.$emit('handleBlur', this.newValue);
    },
    handleInput() {
      // We force the dropdown to be visible when we press a key to fix BRID-2621
      this.highlightId = 0;
      this.isExpanded = true;
      this.$emit('searchOption', this.newValue.trim());
    },
    handleKeydown(e) {
      const maxId = this.filteredOptionList.length - 1;
      const container = this.$refs.dropdownExpand;
      const { highlightId } = this;

      if (e.keyCode === 40) {
        e.preventDefault();
        if (highlightId !== null) {
          if (this.showAddButton) {
            this.highlightId = highlightId === maxId ? null : highlightId + 1;
          } else {
            this.highlightId += highlightId === maxId ? 0 : 1;
          }
          if (
            this.highlightId !== null &&
            container.scrollTop < this.$refs.unselectedOptions[this.highlightId].offsetTop - 158
          ) {
            container.scrollTop = this.$refs.unselectedOptions[this.highlightId].offsetTop - 158;
          }
          if (this.highlightId === null) {
            container.scrollTop = this.$refs.createOption.offsetTop - 158;
          }
        }
      }

      if (e.keyCode === 38) {
        e.preventDefault();
        if (highlightId !== null) {
          this.highlightId -= highlightId === 0 ? 0 : 1;
          if (container.scrollTop > this.$refs.unselectedOptions[this.highlightId].offsetTop) {
            container.scrollTop = this.$refs.unselectedOptions[this.highlightId].offsetTop;
          }
        } else if (maxId >= 0) {
          this.highlightId = maxId;
          container.scrollTop = this.$refs.unselectedOptions[this.highlightId].offsetTop;
        }
      }
      if (e.keyCode === 13) {
        e.preventDefault();
        if (this.highlightId !== null) {
          this.handleOptionClick(this.filteredOptionList[this.highlightId]);
        } else {
          this.handleClickAddNew();
        }
      }
    },
    setHighlightedId(id) {
      this.highlightId = id;
    },
    removeOptionOnKeyup() {
      if (this.isMultipleSelection || this.preventRemoveOptOnKeyUp) return;
      const index = this.selectedOptions.findIndex(option => option.value === this.newValue);
      this.selectedOptions.splice(index, 1);
    },
    removeOptionOnClick(optionId) {
      if (this.disabled) return;
      const index = this.selectedOptions.findIndex(option => option.id === optionId);
      this.selectedOptions.splice(index, 1);
      const selectedIds = this.selectedOptions.map(o => o.id);
      this.optionList = this.defaultList.filter(o => !selectedIds.includes(o.id));
    },
    handleOptionClick(option) {
      if (this.isMultipleSelection) {
        this.handleClickMultipleSelection(option);
      } else {
        this.handleClickUniqueSelection(option);
        this.isExpanded = false;
      }
    },
    handleClickMultipleSelection(option) {
      let selectedIds = this.selectedOptions.map(o => o.id);
      if (selectedIds.includes(option.id)) {
        const index = this.selectedOptions.findIndex(o => o.id === option.id);
        this.selectedOptions.splice(index, 1);
      } else {
        this.selectedOptions.push(option);
      }
      selectedIds = this.selectedOptions.map(o => o.id);
      this.optionList = this.defaultList.filter(o => !selectedIds.includes(o.id));
      this.newValue = '';
      this.$emit('searchOption', '');
    },
    handleClickUniqueSelection(option) {
      this.selectedOptions = [option];
      const selectedIds = this.selectedOptions.map(o => o.id);
      this.optionList = this.defaultList.filter(o => !selectedIds.includes(o.id));
      this.newValue = this.selectedOptions[0].value;
    },
    handleClickAddNew() {
      if (!this.newValue) {
        return;
      }
      if (this.preventAddNew) {
        this.$emit('preventedAddNew');
        return;
      }
      this.addNew = true;
      const id = `random-${Math.floor(Math.random() * 100000)}`;
      this.selectedOptions.push({ value: this.newValue, id });
      this.newValue = this.isMultipleSelection ? '' : this.selectedOptions[0].value;
      this.highlightId = 0;
      this.$emit('searchOption', '');
    },
    handleEditBtnClick() {
      this.disabled = false;
      setTimeout(() => {
        this.$refs.input.focus();
      }, 100);
    }
  }
};
</script>

<style lang="scss" scoped>
.input-dropdown {
  position: relative;
  @include body-1;

  &:hover {
    .input-dropdown-edit {
      opacity: 1;
    }
  }

  &-edit {
    position: absolute;
    right: 16px;
    top: 10px;
    z-index: 3;
    opacity: 0;
  }

  &-area {
    position: relative;
    min-height: 46px;
    background: $color_neutral_0;
    border: 1px solid $color_neutral_40;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 48px 0 0;
    cursor: text;
    transition: all linear 200ms;

    &-selected-option {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: default;
      background: $color_neutral_10;
      border: 0.5px solid $color_neutral_40;
      box-sizing: border-box;
      border-radius: 4px;
      height: 24px;
      padding: 0 4px;
      margin: 4px 0 0 8px;
      white-space: nowrap;
      overflow: hidden;
      &-- {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .ri-close-circle-fill {
        cursor: pointer;
        color: $color_neutral_40;
        padding: 0 0 0 4px;
        font-size: 16px;
      }
    }

    &-hidden-option {
      display: none;
    }

    &-input {
      font-family: inherit;
      min-width: 1px;
      @include body-1;
      outline: none;
      border: none;
      background: none;
      filter: none;
    }

    &-multiple-input {
      padding: 0 0 0 8px;
    }

    &-unique-input {
      padding: 0 0 0 16px;
    }

    .ri-arrow-drop-down-line {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      color: $color_neutral_40;
    }
  }

  &-area-focus {
    border: 1px solid $color_neutral_100;
  }

  &-area-multiple {
    padding: 4px 48px 8px 4px;
  }

  &-area-disabled {
    background-color: $color_neutral_10;
    cursor: initial;

    .ri-close-circle-fill {
      cursor: initial;
    }
  }

  &-area-error {
    border: 1px solid $color_danger_100;
  }

  &-placeholder {
    color: $color_neutral_60;
    padding: 0 0 0 16px;
    user-select: none;
  }

  &-expand {
    position: absolute;
    z-index: 2;
    background: $color_neutral_0;
    border: 1px solid $color_neutral_30;
    box-sizing: border-box;
    @include shadow-down-03;
    border-radius: 2px;
    max-height: 200px;
    overflow-y: auto;

    &-add {
      white-space: nowrap;
      width: 271px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-wrapper {
      position: relative;
      .section-create {
        border-top: 1px solid $color_neutral_30;
      }
      &-section {
        &-loader {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 64px;
        }

        &-option {
          display: flex;
          height: 40px;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          padding: 0 16px;

          &-- {
            margin: 0 0 0 4px;
            white-space: nowrap;
            width: 271px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &-add {
          color: $color_primary_100;

          .ri-add-circle-fill {
            font-size: 18px;
            transform: translateX(-2px);
            margin-right: 6px;
            display: none;
          }

          .ri-add-circle-line {
            font-size: 18px;
            transform: translateX(-2px);
            margin-right: 6px;
            display: unset;
          }

          &:hover {
            .ri-add-circle-line {
              display: none;
            }

            .ri-add-circle-fill {
              display: unset;
            }
          }
        }

        &-error {
          color: $color_danger_100;
          background-color: $color_danger_10;
          .ri-alert-fill {
            font-size: 18px;
            transform: translateX(-2px);
            margin-right: 6px;
            display: unset;
          }
        }

        &-skeleton {
          margin: 0 0 0 16px;
        }
      }
    }
  }

  &-error {
    color: $color_danger_100;
    text-align: right;
    font-size: 12px;
    user-select: none;
  }
}
.highlighted-option {
  background-color: $color_neutral_10;
}
.highlighted-create {
  background-color: $color_primary_10;
  .ri-add-circle-line {
    display: none;
  }

  .ri-add-circle-fill {
    display: unset;
  }
}

::placeholder {
  @include body-1;
  color: $color_neutral_40;
}

input:disabled {
  background-color: $color_neutral_10;
}

input:disabled::-webkit-input-placeholder {
  color: $color_neutral_60;
}
input:disabled:-moz-placeholder {
  color: $color_neutral_60;
}
input:disabled::-moz-placeholder {
  color: $color_neutral_60;
}
input:disabled:-ms-input-placeholder {
  color: $color_neutral_60;
}
</style>
