<template>
  <div class="container" :class="shareDesactivated ? 'share-desactivated' : ''">
    <div
      class="sharing-icon"
      v-tooltip="{ content: iconStyle.tooltip, delay: { show: 500, hide: 0 } }"
    >
      <div v-if="isNew" class="star-new"><img src="/images/star.svg" alt="new link" /></div>
      <i :class="iconStyle.class" />
    </div>
    <div class="sharing-info">
      <div class="sharing-name" :title="shareName">{{ shareName }}</div>
      <div class="sharing-details" v-if="displayDetails">
        <i v-if="hasPassword" class="ri-lock-2-fill icon" />
        <div v-if="expireIn && hasPassword" class="separator" />
        <v-popover v-if="expireIn" trigger="hover" container="#expire-in-container" class="popover">
          <!-- This will be the popover target (for the events and position) -->
          <div id="expire-in-container" class="tooltip-target b3">
            <div class="expire-in">
              <i class="ri-time-fill icon"></i>
              <div class="expire-in-txt">
                {{ $tc('day', this.expireIn) }}
              </div>
            </div>
          </div>
          <!-- This will be the content of the popover -->
          <template slot="popover">
            <div class="upgrade-popover">
              <div class="upgrade-popover-text">
                {{ $tc('expireIn', this.expireIn) }}
              </div>
              <div class="upgrade-popover-text">Want your links to stay active forever?</div>
              <submit-button
                v-if="isAdmin"
                class="upgrade-popover-btn"
                @click.native.stop
                btnStyle="primary"
                size="small"
                :label="$t('upgrade')"
                @submit="goToOffersSettings"
              />
              <div v-else class="upgrade-popover-text">
                Contact a workspace admin to upgrade your plan
              </div>
            </div>
          </template>
        </v-popover>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { get as getRoute } from '@/router/routes';
import { ME } from '@/graphql/queries/user';
import { getUserRoleId } from '@/utils/functions/workspace';

export default {
  props: {
    name: {
      type: String,
      required: false,
      default: 'Quick link'
    },
    shareType: {
      type: String,
      required: false,
      default: 'public'
    },
    expireIn: {
      type: String,
      required: false,
      default: null
    },
    hasPassword: {
      type: Boolean,
      required: false,
      default: false
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false
    },
    shareDesactivated: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  apollo: {
    me: {
      query: ME,
      result(res) {
        const myId = res.data.me.id;
        if (myId) {
          this.isAdmin = getUserRoleId(this.currentWorkspace, myId) === 1;
        }
      }
    }
  },
  data() {
    return {
      shareList: [],
      isAdmin: false
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    iconStyle() {
      let iconClass;
      let tooltip;

      switch (this.shareType) {
        case 'public':
        default:
          iconClass = 'ri-flashlight-fill';
          tooltip = 'Shared via quick link';
          break;
        case 'mail':
          iconClass = 'ri-mail-line';
          tooltip = 'Shared via email';
          break;
        case 'individual':
          iconClass = 'ri-links-line';
          tooltip = 'Shared via private link';
          break;
      }
      return {
        class: iconClass,
        tooltip
      };
    },
    shareName() {
      return this.name ? this.name : 'Quick link';
    },
    displayDetails() {
      return this.hasPassword || this.expireIn;
    }
  },
  methods: {
    goToOffersSettings() {
      this.$router.push(getRoute('offersSettings'));
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  .sharing-icon {
    position: relative;
    flex-shrink: 0;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color_primary_10;
    color: $color_primary_100;
    margin-right: 8px;
  }
  .sharing-info {
    overflow: hidden;
    .sharing-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: default;
    }
  }
  .sharing-details {
    margin-top: 4px;
    color: $color_neutral_40;
    font-size: 12px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    .separator {
      height: 1px;
      width: 4px;
      flex-shrink: 0;
      margin: 0 4px;
      background-color: $color_neutral_40;
    }
    .expire-in {
      display: flex;
      align-items: center;
      line-height: 150%;
      overflow: hidden;
      &-txt {
        color: $color_neutral_60;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: default;
      }
    }
    .ri-time-fill {
      margin-right: 4px;
    }
    .icon {
      font-size: 16px;
    }
  }
}
.share-desactivated {
  color: $color_neutral_40;
  opacity: 0.6;
}

.popover {
  display: flex;
}
.upgrade-popover {
  padding: 8px;
  text-align: center;
  white-space: normal;
  &-text {
    margin-bottom: 8px;
  }
  &-btn {
    width: 100%;
  }
}
.star-new {
  position: absolute;
  top: -6px;
  left: -2px;
}
</style>
