<template>
  <div class="artist-album">
    <project-list>
      <template v-slot:default="slotProps">
        <div class="artist-album-header">
          <div class="artist-album-header-cover" @click="goToAlbumPage(support.id)">
            <img :src="thumbnails" />
          </div>
          <div class="artist-album-header-infos">
            <div class="artist-album-header-infos-txt">
              <div class="artist-album-header-infos-txt-title" @click="goToAlbumPage(support.id)">
                {{ support.name }}
              </div>
              <div class="artist-album-header-infos-txt-subtitle">
                <span v-if="support.tracks_number">
                  {{ $tc('trackCount', support.tracks_number) }}
                </span>
                <span v-if="support.tracks_length">
                  {{ ` - ${getAlbumDuration(support.tracks_length)}` }}
                </span>
                <span v-if="support.release_date">{{ ` - ${getDate(support.release_date)}` }}</span>
              </div>
            </div>
            <div class="artist-album-header-infos-actions">
              <div class="artist-album-header-infos-actions-btn">
                <submit-button
                  class="project-dropdown"
                  size="small"
                  btnStyle="secondary"
                  iconClass="ri-folder-transfer-line"
                  :label="$t('addTo')"
                  @submit="handleAddToClick"
                />
                <btn-dropdown
                  class="upload-to-library-btn"
                  :options="shareBtnOptions"
                  :btnProps="{
                    style: 'secondary',
                    size: 'small',
                    label: 'share',
                    icon: 'ri-share-forward-fill',
                    hideDropdown: true
                  }"
                  @action="openShareModal(support.name, ...arguments)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="artist-album-tracks" v-if="trackList.length > 0">
          <tracks
            :trackList="trackList"
            :projectList="slotProps.projectList"
            :noProjectSearchResult="slotProps.noProjectSearchResult"
            :showProjectObserver="slotProps.showProjectObserver"
            :loadingProjects="slotProps.loadingProjects"
            :fetchingMoreProjects="slotProps.fetchingMoreProjects"
            :keepProjectDropdownExpand="keepProjectDropdownExpand"
            :uncheckTracks="uncheckTracks"
            hasSelectAll
            :editMode="editMode"
            :hasActionBar="false"
            hideHeaderBtn
            withAlbumTrackNumber
            @openConfirmDeleteModal="openConfirmDeleteModal"
            @createProject="createProject"
            @searchProject="slotProps.searchProject"
            @fetchMoreProjects="slotProps.fetchMoreProjects"
            @listProjects="slotProps.listProjects"
            @toggleEditMode="toggleEditMode"
            @setArtistAlbumSelectedTracks="setArtistAlbumSelectedTracks"
          />
        </div>
        <div v-else class="artist-album-tracks">
          <skeleton-row
            v-for="index in support.tracks_number + 1"
            :columns="columns"
            :height="40"
            :key="index"
          />
        </div>
      </template>
    </project-list>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { get as getRoute } from '@/router/routes';
import ProjectList from '@/containers/projectList';
import ShareModal from '@/containers/modals/share';
import Tracks from '@/containers/libraryTrackList';
import { formatDate, formatDuration, FORMAT_DURATION } from '@/utils/functions/time';
import { getFrontCoverUrl } from '@/utils/functions/image';
import { bus } from '@/utils/bus';
import BtnDropdown from '@/containers/dropdowns/submitBtn';
import MoveToAddTo from '@/containers/modals/moveToAddTo';
import { ME } from '@/graphql/queries/user';
import { trackEvent } from '@/utils/functions/analytics';

export default {
  components: {
    ProjectList,
    Tracks,
    BtnDropdown
  },
  props: {
    support: {
      type: Object,
      required: false,
      default: () => {}
    },
    trackList: {
      type: Array,
      required: false,
      default: () => {}
    },
    keepProjectDropdownExpand: {
      type: Boolean,
      required: false,
      default: false
    },
    uncheckTracks: {
      type: Boolean,
      required: false,
      default: false
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  apollo: {
    me: {
      query: ME,
      fetchPolicy: 'cache-and-network'
    }
  },
  data() {
    return {
      shareBtnOptions: [
        {
          label: this.$t('getQuickLink'),
          subText: this.$t('getQuickLinkSub'),
          icon: 'ri-flashlight-fill',
          action: 'openShareModal',
          params: {
            defaultTab: 0
          }
        },
        {
          separator: true
        },
        {
          label: this.$t('createIndividualLinks'),
          subText: this.$t('createIndividualLinksSub'),
          icon: 'ri-links-fill',
          action: 'openShareModal',
          params: {
            defaultTab: 1
          }
        },
        {
          separator: true
        },
        {
          label: this.$t('sendByEmail'),
          subText: this.$t('sendByEmailSub'),
          icon: 'ri-mail-line',
          action: 'openShareModal',
          params: {
            defaultTab: 2
          }
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    supportFileIds() {
      return this.trackList.map(track => track.id);
    },
    thumbnails() {
      return getFrontCoverUrl(this.support.images, 3) || '/images/artwork.jpg';
    },
    columns() {
      return [
        {
          min: '28px',
          max: '28px',
          noBorder: true,
          isHover: true,
          span: 1
        },
        {
          skeleton: 'square',
          min: '40px',
          max: '40px',
          span: 1
        },
        {
          skeleton: 'line',
          min: '105px',
          max: '1fr',
          span: 4,
          padding: [0, 8, 0, 8]
        },
        {
          onHover: true,
          min: '36px',
          max: '36px'
        },
        {
          onHover: true,
          min: '36px',
          max: '36px'
        },
        {
          onHover: true,
          min: '36px',
          max: '36px'
        },
        {
          skeleton: 'line',
          min: '105px',
          max: '1fr',
          span: 1,
          padding: [0, 8, 0, 8]
        },
        {
          skeleton: 'line',
          min: '105px',
          max: '1fr',
          span: 1,
          padding: [0, 8, 0, 8]
        },
        {
          skeleton: 'line',
          span: 1,
          padding: [0, 8, 0, 8],
          min: '61px',
          max: '130px'
        },
        {
          span: 1,
          skeleton: 'line',
          min: '115px',
          max: '190px',
          padding: [0, 8, 0, 8]
        },
        {
          skeleton: 'line',
          min: '115px',
          max: '115px',
          padding: [0, 8, 0, 8]
        }
      ];
    }
  },
  methods: {
    setArtistAlbumSelectedTracks(tracks) {
      this.$emit('setArtistAlbumSelectedTracks', tracks);
    },
    goToAlbumPage(supportId) {
      this.$router.push(getRoute('album', { id: supportId }));
    },
    getAlbumDuration(seconds) {
      return formatDuration(seconds, FORMAT_DURATION.hmm);
    },
    getDate(date) {
      return formatDate(this.$i18n.locale, date);
    },
    createProject() {
      this.$emit('createProject');
    },
    openConfirmDeleteModal(tracksIds) {
      this.$emit('openConfirmDeleteModal', tracksIds);
    },
    toggleEditMode(libraryFileIds, isChecked) {
      this.$emit('toggleEditMode', libraryFileIds, isChecked);
    },
    handleAddToClick() {
      if (this.$isWorkspaceLockedVerification(this)) return;
      const fromSharedView = this.$route.path.includes('shared');
      trackEvent('Track AddTo Click', {
        category: 'addto',
        page_view: fromSharedView ? 'shared library artist' : 'library artist',
        action_bar: false,
        upload_manager: false
      });
      bus.$emit('displayModal', {
        component: MoveToAddTo,
        isVisible: true,
        size: 'small',
        hideHeader: true,
        props: [
          { name: 'filesIds', value: this.supportFileIds || this.selectedTracks },
          { name: 'hasPrevious', value: this.me && this.me.workspaces.length > 1 },
          { name: 'fromArtistAlbumHeader', value: true }
        ]
      });
    },
    openShareModal(name, action, params) {
      const { defaultTab } = params;
      bus.$emit('displayModal', {
        component: ShareModal,
        isVisible: true,
        size: 'medium',
        onSubmit: this.closeShareModal,
        title: this.$t('shareAlbumName', { name }),
        props: [
          { name: 'entityName', value: name },
          { name: 'entityIds', value: [this.support.id] },
          { name: 'entityType', value: 'support' },
          defaultTab ? { name: 'defaultTab', value: defaultTab } : []
        ].filter(Boolean)
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.artist-album {
  &-header {
    display: flex;
    padding: 0 0 0 40px;

    &-cover {
      margin: 0 16px 0 0;
      cursor: pointer;

      img {
        width: 104px;
        height: 104px;
      }
    }

    &-infos {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 104px;

      &-txt {
        &-title {
          @include heading-5;
          margin: 0 0 8px 0;
          cursor: pointer;
        }

        &-subtitle {
          @include body-2;
          color: $color_neutral_60;
        }
      }

      &-actions {
        display: flex;

        &-btn {
          margin: 0 8px 0 0;
          display: flex;
          .project-dropdown {
            margin-right: 4px;
          }
        }
      }
    }
  }

  &-tracks {
    padding: 24px 0 0 12px;
  }
}
</style>
