
<div class="editable-text" @click="switchToEditMode">
  <input
    :class="{
      'editable-text-input': true,
      'editable-text-small': size === 'small',
      'editable-text-large': size === 'large',
      'editable-text-error': error,
      'editable-text-edition': editMode
    }"
    ref="input"
    type="text"
    :style="`width: ${inputWidth}px;`"
    :value="value"
    :disabled="!editMode"
    @input="$emit('update:fieldModel', $event.target.value)"
    @blur="validate"
    @keyup.enter="handleEnter"
  />
</div>
