<template>
  <div v-if="trackList && trackList.length > 0">
    <header-list
      :columns="columns"
      :sortColumn="false"
      :isSticky="isSticky"
      :stickyPosition="stickyPosition"
      :hasSelectAll="hasSelectAll"
      :itemsSelected="[]"
      :displayUnselect="selectedTracks.length > 0"
      @handleEmit="handleEmit"
      @uncheckAllCheckbox="uncheckAllCheckbox"
      @selectAll="checkAllCheckbox"
    />
    <div v-for="(track, idx) in trackList" :key="track.id">
      <list-row
        :item="track"
        :columns="columns"
        :rowIndex="idx"
        :height="48"
        :id="track.id"
        :playingTrack="playingTrack || {}"
        :selectedItems="selectedTracks"
        :disableSelection="isSelectionDisabled"
        @handleEmit="handleEmit"
        @setPlayingTrackFromRow="setPlayingTrackFromRow"
      />
    </div>
    <div v-if="isFetchingMore">
      <skeleton-row :columns="columns" :height="48" />
    </div>
    <div
      v-if="selectedTracks.length"
      class="action-bar-container"
      :class="{ 'panel-open': isEditPanelOpened }"
    >
      <action-bar
        :numberOfSelection="actionBarCount"
        :actions="actionBarBtns"
        :label="actionBarLabels"
        @selectAll="checkAllCheckbox"
        @unselect="uncheckAllCheckbox"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash.get';

import { get as getRoute } from '@/router/routes';
import { bus } from '@/utils/bus';
import StreamableUnavailable from '@/containers/modals/streamableUnavailable';
import { formatNumber } from '@/utils/functions/global';

import { LIBRARY_FILE_STREAMABLE } from '@/graphql/queries/library';

import { clickOutside } from '@/utils/directives';

export default {
  directives: {
    clickOutside
  },
  props: {
    trackList: {
      type: Array,
      required: false,
      default: () => []
    },
    isFetchingMore: {
      type: Boolean,
      required: false,
      default: false
    },
    uncheckTracks: {
      type: Boolean,
      required: false,
      default: false
    },
    isSticky: {
      type: Boolean,
      required: false,
      default: false
    },
    stickyPosition: {
      type: Number,
      required: false,
      default: 0
    },
    hasSelectAll: {
      type: Boolean,
      required: false,
      default: false
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      selectedTracks: [],
      selectedTracksMemory: [],
      isHoverId: null,
      isSelectionDisabled: false,
      fromTo: [],
      actionBarLabels: {
        selectAll: this.$t('selectAll'),
        unselect: this.$t('unselect')
      }
    };
  },
  watch: {
    uncheckTracks() {
      if (this.uncheckTracks) this.uncheckAllCheckbox();
    }
  },
  computed: {
    ...mapGetters(['playlist', 'playingTrack', 'currentWorkspace', 'isEditPanelOpened']),
    columns() {
      return [
        {
          type: 'component',
          name: 'checkbox',
          path: 'checkbox',
          ref: 'checkbox',
          preventDefault: true,
          header: null,
          noSort: true,
          min: '28px',
          max: '28px',
          noBorder: true,
          isHover: true,
          span: 1,
          events: ['toggleCheckbox', 'handleCheckboxShiftClick'],
          props: [
            {
              name: 'id',
              match: 'id',
              value: null
            },
            {
              name: 'index',
              match: 'index',
              value: null
            },
            {
              name: 'selectedItems',
              match: 'selectedTracks',
              value: this.selectedTracks
            }
          ]
        },
        {
          type: 'component',
          name: 'text-action',
          header: this.$t('title'),
          skeleton: 'line',
          min: '200px',
          max: '1fr',
          padding: [0, 8, 0, 8],
          noStop: true,
          containerWidth: true,
          span: 2,
          emits: ['setPlayingTrack'],
          props: [
            {
              name: 'rowId',
              match: 'id',
              value: null
            },
            {
              name: 'isHoverId',
              match: 'isHoverId',
              value: this.isHoverId
            },
            {
              name: 'artwork',
              match: 'imgSrc',
              value: null
            },
            {
              name: 'playingTrack',
              match: 'playingTrack',
              value: this.playingTrack || {}
            },
            {
              name: 'text',
              match: 'title',
              value: null
            },
            {
              name: 'weightNormal',
              match: 'weightNormal',
              value: true
            }
          ]
        },
        {
          type: 'component',
          name: 'iconButton',
          path: 'iconButton',
          stopPropagation: true,
          header: null,
          isExpandHover: true,
          noHeader: true,
          noSort: true,
          onHover: true,
          tooltip: this.$t('editInformation'),
          min: '36px',
          max: '36px',
          emits: ['handleInformationBtnClick'],
          props: [
            {
              name: 'btnStyle',
              match: 'btnStyle',
              value: 'tertiary'
            },
            {
              name: 'icon',
              match: 'icon',
              value: 'ri-information-line'
            },
            {
              name: 'size',
              match: 'size',
              value: 'small'
            },
            {
              name: 'customEvent',
              match: 'customEvent',
              value: 'handleInformationBtnClick'
            },
            {
              name: 'itemId',
              match: 'id',
              value: null
            },
            {
              name: 'clickTriggerSelect',
              match: 'clickTriggerSelect',
              value: false
            }
          ]
        },
        {
          type: 'text',
          name: 'artists',
          header: this.$t('artist'),
          skeleton: 'line',
          min: '105px',
          max: '1fr',
          list: true,
          span: 1,
          route: { name: 'artist', id: 'id' },
          padding: [0, 8, 0, 8]
        },
        {
          type: 'text',
          name: 'supports',
          header: this.$t('album'),
          skeleton: 'line',
          min: '105px',
          max: '1fr',
          list: true,
          span: 1,
          route: { name: 'album', id: 'id' },
          padding: [0, 8, 0, 8]
        },
        {
          type: 'text',
          name: 'humanDuration',
          headerPrefixIcon: 'ri-time-line',
          skeleton: 'line',
          span: 1,
          padding: [0, 8, 0, 8],
          min: '95px',
          max: '95px'
        }
      ];
    },
    actionBarBtns() {
      return [
        {
          icon: 'ri-information-line',
          tooltip: this.$t('editInformation'),
          method: () => {
            if (this.$isWorkspaceLockedVerification(this)) return;
            this.toggleEditMode(true);
          }
        }
      ];
    },
    actionBarCount() {
      const formatedNumber = formatNumber(this.selectedTracks.length);
      return `${formatedNumber} ${this.$tc('trackSelected', this.selectedTracks.length)}`;
    }
  },
  created() {
    // We need to disable the checkbox if some changes are not saved in the edit track panel
    bus.$on('disableTrackSelection', isSelectionDisabled => {
      this.isSelectionDisabled = isSelectionDisabled;
    });
    if (Object.keys(this.playlist).length === 0 && this.trackList.length > 0) {
      this.$store.commit('changePlaylist', this.trackList);
    }
  },
  beforeDestroy() {
    bus.$off('disableTrackSelection');
  },
  methods: {
    handleEmit(params) {
      if (this[params.methodName]) this[params.methodName](params.param);
    },
    handleInformationBtnClick(param) {
      this.setSelectedItems({
        elementUID: param.itemId,
        openEditPanel: true,
        informationBtn: true
      });
    },
    setIsHoverId(trackId) {
      this.isHoverId = trackId;
    },
    setPlayingTrack(trackInfos) {
      const track = this.trackList.find(item => item.id === trackInfos.id);
      if (track.isStreamableAvailable === true) {
        this.$store.commit('changePlaylist', this.trackList);
        this.$store.commit('changePlayingTrack', trackInfos);
      } else {
        this.$apollo
          .query({
            query: LIBRARY_FILE_STREAMABLE,
            fetchPolicy: 'network-only',
            variables: { libraryFilesIds: trackInfos.id }
          })
          .then(data => {
            const updatedStreamableAvailable = get(
              data,
              'data.LibraryFilesDetails[0].status_streamable_transcoded',
              false
            );
            if (updatedStreamableAvailable === false) {
              bus.$emit('displayModal', {
                title: 'Track is not ready yet',
                size: 'small',
                isVisible: true,
                component: StreamableUnavailable
              });
            }
          });
      }
    },
    setPlayingTrackFromRow(track) {
      if (this.playingTrack.id === track.id) {
        bus.$emit('restartAudio');
      } else {
        this.$store.commit('changePlaylist', this.trackList);
        this.$store.commit('changePlayingTrack', track);
      }
    },
    setSelectedItems(param) {
      const { elementUID, openEditPanel, checkbox, rowIndex, informationBtn } = param;
      this.fromTo = [];
      this.fromTo.push(rowIndex || 0);
      if (this.selectedTracks.includes(elementUID)) {
        if (informationBtn && openEditPanel && !this.isEditPanelOpened) {
          this.selectedTracks = [elementUID];
          this.toggleEditMode();
          return;
        }
        const idx = this.selectedTracks.findIndex(id => id === elementUID);
        this.selectedTracks.splice(idx, 1);
        if (!checkbox) {
          this.selectedTracks = this.selectedTracks.length > 1 ? [elementUID] : [];
        }
      } else {
        this.selectedTracks.push(elementUID);
        if (!checkbox) {
          this.selectedTracks = [elementUID];
        }
      }
      if (!param) {
        bus.$emit('showTracksEditSaveModal');
        return;
      }
      if (this.editMode || openEditPanel) {
        this.toggleEditMode();
      }
    },
    shiftSelectItems(param) {
      const { elementUID, rowIndex } = param;
      this.fromTo.splice(1, 1, rowIndex);
      if (this.selectedTracks.includes(elementUID)) {
        this.selectedTracks.splice(this.selectedTracks.indexOf(elementUID), 1);
        return;
      }
      if (this.fromTo.length === 2) {
        this.fromTo.sort((a, b) => a - b);
        for (let i = this.fromTo[0]; i <= this.fromTo[1]; i += 1) {
          if (this.trackList[i] && !this.selectedTracks.includes(this.trackList[i].id)) {
            this.selectedTracks.push(this.trackList[i].id);
          }
        }
      } else if (this.selectedTracks.includes(elementUID)) {
        this.selectedTracks.splice(this.selectedTracks.indexOf(elementUID), 1);
      } else {
        this.selectedTracks.push(elementUID);
      }
    },
    navigateTo(param) {
      const { id, name } = param;
      const route = id ? getRoute(name, { id }) : getRoute(name);
      if (this.$router.history.current.params.id) {
        if (this.$router.history.current.params.id !== id) this.$router.push(route);
      } else if (this.$router.history.current.name !== name) this.$router.push(route);
    },
    toggleEditMode(fromActionBar) {
      if (fromActionBar && this.isEditPanelOpened) {
        this.$emit('toggleEditMode', []);
        return;
      }
      this.$emit('toggleEditMode', this.selectedTracks);
    },
    uncheckAllCheckbox() {
      this.selectedTracks = [];
      this.toggleEditMode();
    },
    checkAllCheckbox() {
      const allItemsIds = this.trackList.map(el => el.id);
      this.selectedTracks = allItemsIds;
    }
  }
};
</script>

<style lang="scss" scoped>
.action-bar-container {
  position: fixed;
  bottom: 72px;
  left: calc(50% + 90px);
  transform: translateX(-50%);
  z-index: map-get($z-index, action-bar);
  transition: left 0.15s ease;
}
.action-bar-container.panel-open {
  left: calc(50% - 169px);
}
</style>
