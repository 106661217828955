
<div class="upload-manager">
  <div class="upload-manager-header">
    <div class="upload-manager-header-section">
      <div
        v-if="!isUploadDone"
        class="upload-manager-header-section-percent"
        :class="
          isUploadDone
            ? 'upload-manager-header-section-completed'
            : 'upload-manager-header-section-in-progress'
        "
      >
        {{ `${progress}%` }}
      </div>
      <div v-if="isUploadDone && allUploadSuccessful" class="upload-manager-header-section-check">
        <i class="ri-check-fill" />
      </div>
      <div
        v-if="isUploadDone && !allUploadSuccessful && !isUploadError"
        class="upload-manager-header-section-check"
      >
        <i class="ri-check-fill" />
      </div>

      <div
        v-if="isUploadDone && !allUploadSuccessful && isUploadError"
        class="upload-manager-header-section-error"
      >
        <i class="ri-error-warning-line" />
      </div>
      <div class="upload-manager-header-section-count">
        <div class="upload-manager-header-section-count-title">{{ getHeaderMessage }}</div>
        <div :class="sizeCountClass">{{ getHeaderSizeMessage }}</div>
      </div>
    </div>
    <div class="upload-manager-header-section">
      <div v-if="isUploadDone" @click="closeManager" class="upload-manager-header-section-close">
        {{ $t('close') }}
      </div>
      <i
        @click="toggleExpand"
        :class="isExpanded ? 'ri-arrow-down-s-line' : 'ri-arrow-up-s-line'"
      />
    </div>
  </div>
  <div class="upload-manager-progress">
    <div
      v-if="!isUploadDone"
      class="upload-manager-progress-front upload-manager-progress-in-progress"
      :style="`width: ${progressBarWidth}px;`"
    />
    <div class="upload-manager-progress-back" />
  </div>
  <div v-if="isExpanded" class="upload-manager-body">
    <div
      class="upload-manager-body-file"
      v-for="(file, index) in files"
      :key="`file-${index}`"
      @mouseover="mouseEnter(index)"
      @mouseleave="mouseLeave(index)"
    >
      <div class="upload-manager-body-file-left">
        <div
          class="upload-manager-body-file-left-name"
          :class="{
            ...getFileNameClass('upload-manager-body-file-left', file),
            'upload-manager-body-file-left-name-canceled': isFileCanceled(file)
          }"
        >
          {{ file.name }}
        </div>
        <div
          class="upload-manager-body-file-left-status"
          :class="{
            ...getFileNameClass('upload-manager-body-file-left', file)
          }"
        >
          <div v-if="isFileCanceled(file)">
            {{ $t('canceled') }}
          </div>
          <div v-if="isFileWaiting(file)">
            {{ $t('waiting') }}
          </div>
          <div v-if="isFileUploading(file)">
            {{ $t('uploading') }}
            <span v-if="file.progress">- {{ file.progress }}%</span>
          </div>
          <div class="upload-manager-body-file-left-status--" v-if="isFileAnalyzing(file)">
            <div class="upload-manager-body-file-left-status-success">
              <span>{{ $t('uploaded') }}</span>
              <i class="ri-check-fill" />
            </div>
            <span>&nbsp;</span>
            <span class="upload-manager-body-file-left-status-analyzing">{{
              $t('analyzing')
            }}</span>
          </div>
          <div
            class="upload-manager-body-file-left-status--"
            v-if="isFileDone(file) && !isFileAlreadyUploaded(file)"
          >
            <div class="upload-manager-body-file-left-status-success">
              <span>{{ $t('uploaded') }}</span>
              <i class="ri-check-fill" />
              <span>&nbsp;</span>
              <span>{{ $t('analyzed') }}</span>
              <i class="ri-check-fill" />
            </div>
          </div>
          <div
            class="upload-manager-body-file-left-status-success"
            v-if="isFileAlreadyUploaded(file)"
          >
            <span>{{ file.message }}</span>
            <i class="ri-check-fill" />
          </div>
          <div v-if="isFileError(file)" class="upload-manager-body-file-left-status--">
            <span class="upload-manager-body-file-left-status-error"
              >{{ $t('uploadError') }} {{ file.message }}</span
            >
          </div>
        </div>
      </div>
      <div class="upload-manager-body-file-right">
        <div
          class="upload-manager-body-file-right-size"
          :class="getFileNameClass('upload-manager-body-file-right', file)"
        >
          {{ getFileFormatedSize(file.size) }}
        </div>
        <div class="upload-manager-body-file-right-status">
          <div class="upload-manager-body-file-right-status--" v-if="isFileDone(file)">
            <i class="ri-check-fill" />
          </div>
          <div
            v-if="hoveredItemIndex === index && (isFileWaiting(file) || isFileUploading(file))"
          >
            <icon-button
              icon="ri-close-line"
              size="small"
              btnStyle="tertiary"
              @submit="cancelUpload(file)"
            />
          </div>
          <div v-else>
            <spinner v-if="isFileUploading(file) || isFileAnalyzing(file)" color="grey" />
          </div>
          <i v-if="isFileError(file)" class="ri-alert-fill" />
        </div>
      </div>
    </div>
  </div>
  <div v-if="allUploadSuccessful && hasOneSuccessfulUpload" class="upload-manager-footer">
    <submit-button
      btnStyle="secondary"
      size="medium"
      iconClass="ri-folder-transfer-line"
      :label="$t('addToProject')"
      @submit="addUploadToProject"
    />
  </div>
</div>
