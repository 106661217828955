<template>
  <div class="delete-tracks-modal-body">
    <div v-if="isRemove" class="delete-tracks-modal-body-description">
      {{ $t('removeMultipleTracksDesc', { number: tracksNumber }) }}
      <p class="delete-tracks-modal-body-description-subtext">
        {{ $t('removeMultipleTracksDescSubtext') }}
      </p>
    </div>
    <div v-else class="delete-tracks-modal-body-description">
      <p class="delete-tracks-modal-body-description-label">
        {{ $t('deleteMultipleTracksDesc', { number: tracksNumber }) }}
      </p>
      <text-field
        v-if="!isRemove"
        inputType="text"
        :fieldModel.sync="typeDelete"
        :label="$t('typeDelete')"
        focusOnLoad
        @keyup.enter.native="handleKeyUp"
      />
    </div>
    <div class="delete-tracks-modal-body-buttons">
      <div class="delete-tracks-modal-body-buttons--">
        <submit-button
          btnStyle="tertiary"
          size="medium"
          :label="$t('cancel')"
          @submit="closeModal"
        />
      </div>
      <div class="delete-tracks-modal-body-buttons--">
        <submit-button
          btnStyle="secondary"
          size="medium"
          :label="isRemove ? $t('remove') : $t('delete')"
          :disabled="!isRemove ? disabledDeleteButton : false"
          danger
          @submit="submit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators';

export default {
  props: {
    onSubmit: {
      type: Function,
      required: false,
      default: () => {}
    },
    tracksNumber: {
      type: Number,
      required: true
    },
    isRemove: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      typeDelete: null,
      delete: 'DELETE'
    };
  },
  validations: {
    typeDelete: {
      required,
      sameAsDelete: sameAs('delete')
    }
  },
  computed: {
    disabledDeleteButton() {
      return !this.$v.typeDelete.sameAsDelete;
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    handleKeyUp() {
      if (!this.disabledDeleteButton) {
        this.submit();
      }
    },
    submit() {
      this.$emit('closeModal');
      this.onSubmit();
    }
  }
};
</script>

<style lang="scss" scoped>
.delete-tracks-modal {
  &-body {
    line-height: 150%;

    &-description {
      padding: 32px 40px;

      &-subtext {
        margin-top: 32px;
      }

      &-label {
        margin-bottom: 24px;
      }
    }

    &-txtfield {
      margin: 0 0 40px 0;
    }

    &-buttons {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid $color_neutral_30;
      padding: 8px 32px;

      &-- {
        &:first-child {
          margin: 0 16px 0 0;
        }
      }
    }
  }
}
</style>
