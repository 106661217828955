
<div class="change-billing-info-modal">
  <div class="change-billing-info-modal-body">
    <div class="section-address">
      <div class="formTitle">Address</div>
      <div class="inputLine multiInputLine">
        <select-box
          :options="getCountryList"
          placeholder=""
          label="Country"
          :selectedValue="getUserCountry"
          @setSelectBoxValue="selectCountry"
          large="large"
        />
        <text-field
          inputType="text"
          label="State"
          :value="state"
          :fieldModel.sync="state"
          :large="true"
          helper="(Optionnal)"
          autocomplete="off"
        />
      </div>
      <text-field
        inputType="text"
        label="Street address"
        :large="true"
        :value="address"
        autocomplete="off"
        :errorMessage="addressValidationMessage"
        :fieldModel.sync="$v.address.$model"
        class="inputLine"
      />
      <text-field
        inputType="text"
        label="Suite / Unit"
        :large="true"
        :value="addressSuite"
        :fieldModel.sync="addressSuite"
        helper="(Optionnal)"
        autocomplete="off"
        class="inputLine"
      />
      <div class="multiInputLine inputLine">
        <text-field
          inputType="text"
          label="City"
          :large="true"
          :value="city"
          :errorMessage="cityValidationMessage"
          :fieldModel.sync="$v.city.$model"
          autocomplete="off"
        />
        <text-field
          inputType="text"
          label="ZIP / Postal code"
          :large="true"
          :errorMessage="postalCodeValidationMessage"
          :fieldModel.sync="$v.postalCode.$model"
          :value="postalCode"
          autocomplete="off"
        />
      </div>
    </div>
    <div class="section-company">
      <div class="formTitle">Company</div>
      <div class="section-company multiInputLine inputLine">
        <text-field
          inputType="text"
          label="Company name"
          :large="true"
          :value="companyName"
          autocomplete="off"
          :errorMessage="companyNameValidationMessage"
          :fieldModel.sync="$v.companyName.$model"
          class="inputLine"
        />
        <text-field
          ref="taxNumberField"
          inputType="text"
          label="Tax number"
          helper="(Optionnal)"
          autocomplete="off"
          :large="true"
          :value="taxNumber"
          :errorMessage="taxNumberValidationMessage"
          :fieldModel.sync="$v.taxNumber.$model"
          @handleInput="resetInvalidTaxNumber"
        />
      </div>
    </div>
  </div>
  <div class="change-billing-info-modal-buttons">
    <div class="change-billing-info-modal-buttons--">
      <submit-button
        btnStyle="tertiary"
        size="medium"
        :label="$t('cancel')"
        @submit="closeModal"
      />
    </div>
    <div class="change-billing-info-modal-buttons--">
      <submit-button
        btnStyle="primary"
        size="medium"
        :label="$t('save')"
        :pending="pending"
        @submit="save"
      />
    </div>
  </div>
</div>
