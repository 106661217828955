
<div class="more-text">
  <div class="more-text-container">
    <textarea
      class="more-text-container-txt"
      ref="textarea"
      v-model="textValue"
      disabled="true"
      :style="`height: ${textHeight}px;`"
    />
  </div>
  <div class="more-text-button" v-if="showMoreButton" @click="toggleExpand">
    {{ buttonLabel }}
  </div>
</div>
