<template>
  <div class="text-dropdown" v-click-outside="{ hide, keepExpand }">
    <div class="text-dropdown-label" @click="toggleExpand">
      <div class="text-dropdown-label--">
        <span>{{ selectedLabel }}</span>
      </div>
      <i class="ri-arrow-drop-down-line" />
    </div>
    <div class="text-dropdown-expand" @click="hide" v-if="isExpanded">
      <div class="text-dropdown-expand-options">
        <div
          v-for="(option, index) in options"
          class="text-dropdown-expand-options-item"
          :key="`item-${index}`"
          @click="selectOption(option.value, option.label)"
        >
          {{ option.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { clickOutside } from '../../utils/directives';

export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    keepExpand: {
      type: Boolean,
      required: false
    },
    selectedLabel: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isExpanded: false
    };
  },
  directives: {
    clickOutside
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
      if (this.isExpanded) {
        this.$emit('isExpanded');
      }
    },
    hide() {
      this.isExpanded = false;
    },
    selectOption(value, label) {
      this.$emit('selectOption', value, label);
    }
  }
};
</script>

<style lang="scss" scoped>
.text-dropdown {
  width: fit-content;
  width: -moz-fit-content;
  position: relative;
  @include btn-medium;
  color: $color_neutral_100;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-label {
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 8px 12px 8px 0;

    &-icon {
      font-size: 16px;
      margin: 0 6px 0 0;
    }

    .ri-arrow-drop-down-line {
      font-size: 20px;
      color: $color_primary_100;
    }

    &-- {
      display: flex;
      align-items: center;
      text-decoration: underline dotted;
      color: $color_primary_100;
      user-select: none;
    }
  }

  &-expand {
    position: absolute;
    left: 0;
    z-index: 100;
    height: auto;
    width: fit-content;
    width: -moz-fit-content;
    margin: 4px 0 0;
    @include shadow-down-03;
    border: 1px solid $color_neutral_30;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: $color_neutral_0;
    overflow: hidden;
    max-height: 300px;
    top: 24px;
    &-options {
      &-item {
        @include body-2;
        padding: 0 16px;
        white-space: nowrap;
        height: 40px;
        display: flex;
        align-items: center;
        user-select: none;
        &:hover {
          background-color: $color_neutral_10;
        }
      }
    }
  }
}
</style>
