<template>
  <dropdown ref="dropdown">
    <template #button="slotProps">
      <div :class="{ icon: true, opened: slotProps.isExpanded }" @click="loadData">
        <i class="ri-notification-3-fill" />
        <span v-show="getNotificationCount() && !slotProps.isExpanded" class="icon-badge">
          {{ getNotificationCount() }}
        </span>
      </div>
    </template>
    <div class="notifications">
      <div class="header">
        <span>{{ $t('notifications.header') }}</span>
        <i
          class="ri-checkbox-circle-line dismiss-all"
          @click="markAllAsRead"
          v-tooltip="$t('markAllAsRead')"
        ></i>
      </div>
      <ul v-if="notifications && notifications.length" class="notification-list">
        <li
          class="notification-item"
          :class="{ 'notification-item-unread': !item.read }"
          v-for="(item, index) in notifications"
          :key="item.ids[0]"
          @click="goToEntityPage(index)"
        >
          <div class="dot read-dot" v-if="!item.read" @click.stop="markAsRead(index)" />
          <i class="ri-voiceprint-line notification-icon"></i>
          <div class="notification-info">
            <p class="info-entity">
              <span>{{ $t('notifications.message') }} </span>
              <span>{{ item.typeDisplayed }} </span>
              <span class="entity-name">{{ item.shareName }}</span>
            </p>
            <div v-if="item.shareCustomName">
              <p class="info-entity">
                <span>{{ ` ${$t('notifications.from')} ` }}</span>
                <span class="entity-name">{{ item.shareCustomName }}</span>
              </p>
            </div>
            <p class="info-time">{{ item.time }}</p>
          </div>
        </li>
      </ul>
      <div v-else class="notifications-empty">
        <i class="ri-notification-3-fill bell-icon"></i>
        <div class="notifications-empty-msg">
          <div class="notifications-empty-title">{{ $t('notifications.empty') }}</div>
          <div>{{ $t('notifications.emptySubtext') }}</div>
        </div>
      </div>
    </div>
  </dropdown>
</template>

<script>
import { mapGetters } from 'vuex';
import { get as getRoute } from '@/router/routes';
import get from 'lodash.get';
import * as timeago from 'timeago.js';
import { NOTIFICATIONS_LIST, NOTIFICATIONS_COUNTER } from '@/graphql/queries/workspace';
import { NOTIFICATIONS_DISPLAY, NOTIFICATIONS_READ } from '@/graphql/mutations/workspace';
import { trackEvent } from '@/utils/functions/analytics';

const MARK_ALL_AS_READ = 'mark all as read';

export default {
  data() {
    return {
      lastNotificationCheckTime: 0,
      notificationsCount: undefined,
      notifications: undefined
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace'])
  },
  created() {
    this.fetchNotifications();
  },
  watch: {
    $route() {
      this.fetchNotifications();
    }
  },
  methods: {
    goToEntityPage(index) {
      this.markAsRead(index);
      const { routeId, entityType } = this.notifications[index];
      let routeName;
      switch (entityType) {
        case 'project':
          routeName = 'projectActivity';
          break;
        case 'album':
          routeName = 'albumActivity';
          break;
        case 'artist':
          routeName = 'artistActivity';
          break;
        case 'submission':
          routeName = 'sharedSubmissionActivity';
          break;
        case 'tracks':
        default:
          routeName = 'sharedTracksActivity';
          break;
      }
      if (routeId !== this.$route.params.id || this.$route.name !== routeName) {
        this.$router.push(getRoute(routeName, { id: routeId }));
      }
      trackEvent(
        'Notification Viewed',
        {
          category: 'checking',
          label: 'confirmed',
          workspace_id: this.currentWorkspace.id,
          position: 'notificationList'
        },
        {
          All: false,
          'Google Analytics': true,
          FullStory: true,
          Hubspot: true,
          Appcues: true,
          Amplitude: true,
          June: true
        }
      );
      this.$refs.dropdown.hide();
    },
    fetchNotifications() {
      const now = new Date().getTime();
      if (now > this.lastNotificationCheckTime + 6 * 1000) {
        this.$apollo
          .query({
            query: NOTIFICATIONS_COUNTER,
            fetchPolicy: 'network-only',
            variables: {
              workspaceId: this.currentWorkspace.id
            }
          })
          .then(response => {
            this.lastNotificationCheckTime = new Date().getTime();
            this.notificationsCount = get(response, 'data.NotificationsNotDisplayed', 0);
          });
      }
    },
    markAllAsRead() {
      trackEvent(
        'Notification Checked',
        {
          category: 'checking',
          label: 'dismissed',
          workspace_id: this.currentWorkspace.id,
          position: 'notificationList'
        },
        {
          All: false,
          'Google Analytics': true,
          FullStory: true,
          Amplitude: true,
          June: true
        }
      );
      this.markAsRead(MARK_ALL_AS_READ);
    },
    markAsRead(index) {
      let notificationsRead = [];
      if (index === MARK_ALL_AS_READ) {
        this.notifications.forEach((item, i) => {
          this.notifications[i].read = true;
          notificationsRead.push(item.ids);
        });
      } else {
        this.notifications[index].read = true;
        if (this.notifications[index].ids.length) {
          notificationsRead.push(this.notifications[index].ids);
        }
      }
      notificationsRead = notificationsRead.flat();
      if (notificationsRead.length) {
        this.$apollo.mutate({
          mutation: NOTIFICATIONS_READ,
          variables: {
            notificationsIds: notificationsRead
          }
        });
      }
    },
    loadData() {
      this.notificationsCount = 0;
      this.$apollo
        .query({
          query: NOTIFICATIONS_LIST,
          fetchPolicy: 'network-only',
          variables: {
            workspaceId: this.currentWorkspace.id
          }
        })
        .then(response => {
          let hasNewNotifications = false;
          const b = [];
          response.data.NotificationsList.items.forEach(item => {
            if (item.notifications) {
              item.notifications.list.forEach(n => b.push(n.id));
            } else {
              b.push(item.id);
            }
          });
          this.$apollo.mutate({
            mutation: NOTIFICATIONS_DISPLAY,
            variables: {
              notificationId: b[0]
            }
          });
          this.notifications = response.data.NotificationsList.items
            .map(item => {
              let routeId;
              let entityType;
              let typeDisplayed;
              switch (item.event_type) {
                case 'share-project-activity':
                  routeId = get(
                    item.variables.find(v => v.name === 'project_id'),
                    'value'
                  );
                  entityType = 'project';
                  typeDisplayed = 'project';
                  break;
                case 'share-support-activity':
                  routeId = get(
                    item.variables.find(v => v.name === 'support_id'),
                    'value'
                  );
                  entityType = 'album';
                  typeDisplayed = 'album';
                  break;
                case 'share-artist-activity':
                  routeId = get(
                    item.variables.find(v => v.name === 'artist_id'),
                    'value'
                  );
                  entityType = 'artist';
                  typeDisplayed = 'artist';
                  break;
                case 'share-submission-activity':
                  routeId = get(
                    item.variables.find(v => v.name === 'share_id'),
                    'value'
                  );
                  entityType = 'submission';
                  typeDisplayed = 'submission';
                  break;
                case 'share-tracks-activity':
                default:
                  routeId = get(
                    item.variables.find(v => v.name === 'share_id'),
                    'value'
                  );
                  entityType = 'tracks';
                  typeDisplayed = 'track(s)';
                  break;
              }
              if (item.read_at === null) {
                hasNewNotifications = true;
              }
              const shareType = get(
                item.variables.find(v => v.name === 'share_type'),
                'value'
              );
              let shareCustomName;
              switch (shareType) {
                case 'link':
                  shareCustomName = get(
                    item.variables.find(v => v.name === 'share_custom_name'),
                    'value'
                  );
                  break;
                case 'email':
                  shareCustomName = get(
                    item.variables.find(v => v.name === 'share_recipient'),
                    'value'
                  );
                  break;
                default:
                  shareCustomName = 'Quick link';
              }
              return {
                ids: [item.id],
                routeId,
                entityType,
                typeDisplayed,
                shareName: get(
                  item.variables.find(v => v.name === 'share_name'),
                  'value'
                ),
                read: item.read_at !== null,
                time: timeago.format(item.created_at * 1000),
                shareCustomName
              };
            })
            .filter(Boolean);
          if (hasNewNotifications) {
            trackEvent(
              'Notification Checked',
              {
                category: 'checking',
                label: 'confirmed',
                workspace_id: this.currentWorkspace.id,
                position: 'notificationList'
              },
              {
                All: false,
                'Google Analytics': true,
                FullStory: true,
                Hubspot: true,
                Appcues: true,
                Amplitude: true,
                June: true
              }
            );
          }
        });
    },
    getNotificationCount() {
      if (this.notificationsCount === 0) {
        return undefined;
      }
      if (this.notificationsCount > 99) {
        return '99+';
      }
      return this.notificationsCount;
    }
  }
};
</script>

<style lang="scss" scoped>
$button_size: 40px;
.container {
  position: relative;
}

.icon {
  position: relative;
  color: $color_neutral_40;
  font-size: 20px;
  cursor: pointer;
  width: $button_size;
  height: $button_size;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: background 300ms ease, color 300ms ease;
}

.opened {
  background: $color_primary_10;
  color: $color_primary_100;
}

.icon-badge {
  display: flex;
  align-items: center;
  line-height: 150%;
  justify-content: center;
  background: $color_danger_100;
  position: absolute;
  color: $color_neutral_0;
  min-width: 10px;
  height: 15px;
  font-size: 10px;
  top: 0;
  left: 24px;
  font-weight: 600;
  border-radius: 4px;
  padding: 0 4px;
}

.notifications {
  background: $color_neutral_0;
  border: 1px solid $color_neutral_30;
  width: 400px;
  border-radius: 2px;
  @include shadow-down-03;
  &-empty {
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 60px;
    &-msg {
      @include body-1;
      text-align: center;
      color: #000;
    }
    &-title {
      @include heading-6;
      margin-bottom: 16px;
    }
    .bell-icon {
      color: $color_neutral_40;
      font-size: 32px;
      margin-bottom: 24px;
    }
  }
}

.router {
  &:hover {
    text-decoration: none;
  }
}

.header {
  border-bottom: 1px solid $color_neutral_30;
  color: $color_neutral_100;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 16px 10px;
  box-sizing: border-box;
  justify-content: space-between;
  user-select: none;
}

.dismiss-all {
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.notification-list {
  margin: 0;
  list-style: none;
  padding: 0;
  max-height: 60vh;
  overflow-y: scroll;
}

.notification-item {
  height: 80px;
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  position: relative;
  align-items: center;
  user-select: none;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid $color_neutral_30;
  }
  &-unread {
    background: $color_neutral_10;
  }
  &:hover .read-dot {
    transform: scale(1.4);
  }
}

.dot {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 13px;
  left: 4px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}
.read-dot {
  &:after {
    transition: all 200ms ease-in-out;
    content: '';
    width: 8px;
    height: 8px;
    display: block;
    background: $color_danger_100;
    border-radius: 14px;
    margin: 8px auto;
  }
}
.unread-dot {
  &:after {
    transition: all 200ms ease-in-out;
    content: '';
    width: 7px;
    height: 7px;
    display: block;
    background: $color_neutral_0;
    border-radius: 14px;
    border: 1px solid $color_neutral_100;
    margin: 8px auto;
  }
}

.notification-icon {
  width: 32px;
  height: 32px;
  background: $color_primary_10;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: $color_primary_100;
}

.notification-info {
  margin-left: 8px;
  font-weight: 400;
  font-size: 14px;
}

.info-entity {
  width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
}
.entity-name {
  font-weight: 600;
}

.notification-info p {
  margin: 0;
}

.info-time {
  color: $color_neutral_60;
  font-size: 12px;
  line-height: normal;
}
</style>
