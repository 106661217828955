
<div class="dropdown-container">
  <div class="dropdown-container-head">
    <div v-if="label" class="dropdown-container-head-label">{{ label }}</div>
  </div>
  <div :class="dropdownStyle" v-click-outside="{ hide }" @click="toggleExpand">
    <div class="dropdown-button" :class="disabled && 'dropdown-disabled'">
      <span>{{ selectedRole.label }}</span>
      <i class="dropdown-button-arrow ri-arrow-down-s-line" />
    </div>
    <div
      class="dropdown-expanded"
      :class="!isExpanded && 'dropdown-close'"
      :style="`max-height: ${height}px; top: ${top}px;`"
    >
      <div class="dropdown-expanded-roles" @click="hide" v-if="isExpanded">
        <div
          class="dropdown-expanded-roles-item"
          v-for="(role, index) in roles"
          :key="`role-${index}`"
          @click="setRole(role, userId)"
        >
          <div class="dropdown-expanded-roles-item-label">
            <span>{{ role.label }}</span>
            <i
              v-if="selectedRole.value === role.value"
              class="dropdown-expanded-roles-item-label-icon ri-check-line"
            />
          </div>
          <div class="dropdown-expanded-roles-item-description">{{ role.description }}</div>
        </div>
        <div v-if="options" class="dropdown-expanded-options" @click="hide">
          <div
            class="dropdown-expanded-options-item"
            v-for="(option, index) in options"
            :key="`option-${index}`"
            @click="option.action(userId)"
          >
            <div class="dropdown-expanded-options-item-label">{{ option.label }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
