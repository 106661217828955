
<div class="image-container">
  <img
    :src="setImageUrl()"
    alt="activityImg"
    v-if="activityId"
    loading="lazy"
    onload="this.style.opacity=1"
  />
</div>
