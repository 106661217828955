
<div class="header">
  <div class="header-content">
    <div class="header-content-main">
      <breadcrumb
        class="breadcrumb"
        :path="path"
        :iconClass="breadCrumbIcon"
        :iconStyle="mainPathToLower"
        @handleParentClick="breadcrumbClick"
      />
      <div class="header-btn" v-if="displayedBtn && displayedBtn[1] && !isUnknown">
        <div class="header-btn--" v-for="(btn, id) in displayedBtn[0]" :key="`${id}-headerBtn`">
          <more-dropdown
            v-if="btn.iconBtn"
            size="medium"
            :icon="btn.icon"
            :items="btn.items"
            :otherItems="btn.otherItems"
          />
          <btn-dropdown
            v-if="btn.btnDropdown"
            :options="btn.options"
            :btnProps="btn.params"
            @action="handleBtnDropdownActions"
          />
          <submit-button
            v-if="!btn.iconBtn && !btn.btnDropdown"
            size="medium"
            :btnStyle="btn.style"
            :iconClass="btn.icon"
            @submit="btn.action"
            :label="btn.label"
          />
        </div>
        <div class="header-separator"></div>
      </div>
    </div>
    <div class="header-content-icons">
      <a :href="faqUrl" class="header-help-icon" target="_blank" rel="noopener noreferrer">
        <i class="ri-question-fill"></i>
      </a>
      <project-notifications />
      <profile :user="me" />
    </div>
  </div>
</div>
