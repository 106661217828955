<template>
  <div>
    <div class="content">
      <h4 class="header">{{ $t('billingDetails.invoicesSentTo') }}</h4>
      <div v-if="email === undefined" class="loader">
        <spinner-without-progress color="grey" :size="32" />
      </div>
      <div v-else>
        <div v-if="hasEmail()">
          <div class="value">{{ email }}</div>
          <submit-button
            class="button"
            btnStyle="tertiary"
            :exception="true"
            size="medium"
            :label="$t('billingDetails.changeContact')"
            @submit="changePaymentMethod"
          />
        </div>
        <div v-else>
          <p>No email saved.</p>
          <submit-button
            class="button"
            btnStyle="tertiary"
            :exception="true"
            size="medium"
            :label="$t('billingDetails.changeContact')"
            @submit="changePaymentMethod"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { bus } from '@/utils/bus';
import { isFreeTrial } from '@/utils/functions/workspace';
import ChangeStripeUserEmail from '@/containers/modals/changeStripeUserEmail';

export default {
  props: {
    email: {
      type: String,
      required: false,
      default: null
    },
    address: {
      type: Object,
      required: false,
      default: null
    },
    company: {
      type: String,
      required: false,
      default: null
    },
    prepareForPayment: {
      type: Function,
      required: true
    },
    hasPaymentMethod: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace'])
  },
  methods: {
    hasEmail() {
      return this.email && this.email.length > 0;
    },
    changePaymentMethod() {
      if (isFreeTrial(this.currentWorkspace) && !this.hasPaymentMethod) {
        this.prepareForPayment();
      } else {
        bus.$emit('displayModal', {
          component: ChangeStripeUserEmail,
          isVisible: true,
          size: 'medium',
          title: 'Change billing email address',
          props: [
            { name: 'defaultMail', value: this.email },
            { name: 'billingInformation', value: { address: this.address, company: this.company } }
          ]
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  font-family: 'manrope';
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: $color_neutral_60;
  margin-bottom: 8px;
}

.value {
  font-size: 14px;
}

.button {
  position: absolute;
  bottom: 22px;
  left: 12px;
}

.loader {
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
