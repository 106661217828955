<template>
  <dropdown ref="dropdown" dismissOnClick @setIsDropdownExpanded="setIsDropdownExpanded">
    <template #button>
      <submit-button
        class="project-cover-btns--"
        :btnStyle="btnProps.style"
        :size="btnProps.size"
        :label="$t(btnProps.label)"
        :iconClass="btnProps.icon"
        :isExpanded="isExpanded"
        :disabled="btnProps.disabled"
        :primaryException="btnProps.primaryException"
        :isDropdown="!btnProps.hideDropdown"
      />
    </template>
    <div class="menu">
      <div
        :class="[
          option.separator ? 'separator' : 'menu-item',
          option.subText ? 'menu-item-with-sub' : ''
        ]"
        v-for="(option, index) in options"
        :key="index"
        @click="handleOptionClick(option)"
      >
        <div class="item" v-if="!option.separator">
          <i :class="option.icon"></i>
          <span class="menu-item-label">
            {{ option.label }}
            <span v-if="option.info" class="info">{{ option.info }}</span>
            <span v-if="option.isNew" class="menu-item-label-new">
              <img src="/images/new-badge.svg" alt="new badge" />
            </span>
          </span>
        </div>
        <div v-if="option.subText" class="menu-item-sub">{{ option.subText }}</div>
      </div>
    </div>
  </dropdown>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: false,
      default: () => []
    },
    btnProps: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isExpanded: false
    };
  },
  methods: {
    handleOptionClick(option) {
      if (!option.separator) {
        this.$emit('action', option.action, option.params);
      }
      this.$emit('setIsCoverHover');
    },
    setIsDropdownExpanded(bool) {
      this.isExpanded = bool;
      this.$emit('setIsDropdownExpanded', bool);
    }
  }
};
</script>

<style lang="scss" scoped>
.button {
  cursor: pointer;
  width: fit-content;
  font-weight: bold;
  display: flex;
  user-select: none;
}
.menu {
  margin-top: 2px;
  padding: 8px;
  height: auto;
  z-index: 1000;
  white-space: nowrap;
  background-color: $color_neutral_0;
  box-sizing: border-box;
  border: 1px solid $color_neutral_30;
  border-radius: 8px;
  overflow: hidden;
  @include shadow-down-03;
}

.menu-item {
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 32px;
  box-sizing: border-box;
  @include body-1;
  &-label {
    display: flex;
    align-items: center;
    &-new {
      margin-left: 4px;
    }
    .info {
      padding-left: 5px;
      font-size: 12px;
      color: $color_neutral_60;
    }
  }
  &-order {
    color: $color_neutral_60;
    font-size: 12px;
  }
  &-sub {
    font-size: 12px;
    color: $color_neutral_60;
  }
  &-with-sub {
    flex-direction: column;
    justify-content: center;
    align-items: unset;
    height: fit-content;
  }
  .item {
    display: flex;
    align-items: center;
    line-height: 100%;
    i {
      margin-right: 8px;
    }
  }
}
.menu-item:hover {
  background-color: $color_neutral_10;
}
.separator {
  height: 1px;
  background-color: $color_neutral_30;
  width: calc(100% + 16px);
  transform: translateX(-8px);
  margin: 4px 0;
}
</style>
