
<div class="item-list" :style="containerStyle">
  <div
    class="item-list-grid item-list-header"
    :style="headerStyle"
    @mouseenter="handleCheckboxHover(true)"
    @mouseleave="handleCheckboxHover(false)"
  >
    <div class="item-list-grid-header">
      <div class="item-list-grid-header-row" v-if="itemsSelected.length > 0 && !hideActionBtn">
        <div class="item-list-grid-header-row-cell item-list-grid-header-row-no-border">
          <i class="ri-checkbox-indeterminate-line" @click="uncheckAllCheckbox" />
        </div>
        <div class="item-list-grid-header-row-cell item-list-grid-header-row-count">
          {{ headerCountLabel }}
        </div>
        <div class="item-list-grid-header-row-cell item-list-grid-header-row-actions">
          <div v-if="!actionUnavailable" style="display: contents">
            <div
              class="item-list-grid-header-row-actions-button"
              v-for="(button, index) in headerButtons"
              :key="`${button.name}-${index}`"
            >
              <component
                :is="button.name"
                v-bind="buttonProperties(button.props)"
                v-on="buttonsListeners"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="item-list-grid-header-row" v-else>
        <div
          :class="{
            'item-list-grid-header-row-cell': true,
            'item-list-grid-header-row-no-border': column.noBorder,
            'no-separator': column.noSeparator,
            'item-list-grid-header-row-with-sort': sortColumn && !column.noSort
          }"
          :style="headerRowStyle(column)"
          v-for="(column, idx) in displayedColumns"
          :key="`header-${idx}`"
          @click="theadHandleClick(column)"
        >
          <div
            :class="{
              'item-list-grid-header-row-cell-content': true,
              'item-list-grid-header-row-cell-content-center': column.centered
            }"
          >
            <div
              v-if="column.name === 'checkbox' && (itemsSelected.length > 0 || displayUnselect)"
              class="item-list-grid-header-row-cell item-list-grid-header-row-no-border"
            >
              <i class="ri-checkbox-indeterminate-line" @click="uncheckAllCheckbox" />
            </div>
            <div
              v-if="
                column.name === 'checkbox' &&
                hasSelectAll &&
                itemsSelected.length === 0 &&
                !displayUnselect
              "
              :class="{ 'item-list-grid-header-row-cell-content--': !column.centered }"
              style="width: 100%; height: 100%; display: flex; align-items: center"
            >
              <checkbox
                :id="`header-cb${idx}`"
                @toggleCheckbox="selectAll"
                v-if="checkboxHovered"
              ></checkbox>
            </div>
            <div
              v-else
              :class="{
                'item-list-grid-header-row-cell-content--': !column.centered,
                'item-list-grid-header-row-cell-content-sorted': column.name === sortedColumn
              }"
            >
              <i v-if="column.headerPrefixIcon" :class="column.headerPrefixIcon"></i>
              {{ column.header }}
              <i v-if="column.headerSuffixIcon" :class="column.headerSuffixIcon"></i>
            </div>
            <div
              v-if="!column.noSort && sortColumn"
              class="item-list-grid-header-row-cell-content-sort"
            >
              <i v-if="column.name !== sortedColumn" class="ri-arrow-up-line asc-hover"></i>
              <i
                v-if="column.name === sortedColumn && orderBy === 'asc'"
                class="ri-arrow-up-line asc"
              ></i>
              <i
                v-if="column.name === sortedColumn && orderBy === 'desc'"
                class="ri-arrow-down-line desc"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
