
<div>
  <p class="planTitle">
    <i class="ri-music-fill planIcon" />
    <span>{{ plan.name }}</span>
  </p>
  <p class="planDescription">{{ $t('freePlan.description') }}</p>
  <div>
    <span class="planCurrency">{{ currencySymbol }}</span>
    <span class="planPrice">{{ planPrice() }}</span>
  </div>
  <div class="planRecurrence">{{ $t('freePlan.information') }}</div>
  <div class="planFeatures">
    <i class="ri-server-fill" />
    {{
      $t('planStorageInformation', {
        tracks: planFeature('libraryFilesMax'),
        storage: planFeature('storage')
      })
    }}
  </div>
  <div v-tooltip="getButtonTooltipText">
    <submit-button
      :primaryException="true"
      :label="getButtonLabel"
      :disabled="isButtonDisabled"
      class="planButton"
      size="large"
      @submit="cancelSubscription"
    />
  </div>
  <ul class="planBenefits">
    <li><i class="ri-check-line" />{{ $t('freePlan.benefitsMembers') }}</li>
    <li><i class="ri-check-line" />{{ $t('freePlan.benefitsSharing') }}</li>
    <li><i class="ri-check-line" />{{ $t('freePlan.benefitsNotifications') }}</li>
  </ul>
</div>
