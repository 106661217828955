<template>
  <div class="album">
    <div class="album-header" v-if="supportDetails && !error">
      <img v-if="isUnknownAlbum()" :src="albumImgUrl" />
      <image-browser
        v-if="!isUnknownAlbum()"
        square
        :removeButton="displayDeleteArtwork"
        :imgUrl="albumImgUrl"
        @changeImgUrl="openCropperModal"
        @removeImage="deleteSupportCover"
        pictureSize="coverSize"
      />
      <div class="album-header-info">
        <div class="album-header-info-txt">
          <div class="album-header-info-txt-name">{{ supportDetails.name }}</div>
          <div v-if="isUnknownAlbum()" class="album-header-info-txt-count">
            {{ $tc('trackCount', supportDetails.tracks_number) }}
          </div>
          <div v-if="!isUnknownAlbum()">
            <div>
              <div
                v-for="artist in supportDetails.artists"
                :key="artist.id"
                class="album-header-artistList"
              >
                <span @click="goToArtistPage(artist.id)">{{ artist.name }}</span>
              </div>
            </div>
            <div class="album-header-info-txt-count">
              {{
                `${$tc('trackCount', supportDetails.tracks_number)} • ${getAlbumDuration(
                  supportDetails.tracks_length
                )}`
              }}
            </div>
            <div class="album-header-info-txt-details">
              <div class="album-header-info-txt-details-label">{{ $t('type') }}</div>
              <div class="album-header-info-txt-details-value">
                {{ supportDetails.type }}
              </div>
            </div>
            <div class="album-header-info-txt-details">
              <div class="album-header-info-txt-details-label">{{ $t('releaseDate') }}</div>
              <div class="album-header-info-txt-details-value">
                {{ getDate(supportDetails.release_date) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="album-tabs">
      <tabs :tabs="tabs" :activeTab="activeTab" />
    </div>
    <router-view
      v-if="supportDetails"
      :supportDetails="supportDetails"
      :entityIds="[this.$route.params.id]"
      :entityName="albumName"
      @renderAlbumView="renderAlbumView"
    ></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash.get';
import { get as getRoute } from '@/router/routes';
import { bus } from '@/utils/bus';
import { appConfig } from '@/utils/constants';
import { handleSupportImageUpload } from '@/utils/functions/upload';
import { formatDuration, formatDate, FORMAT_DURATION } from '@/utils/functions/time';
import { getFrontCoverUrl } from '@/utils/functions/image';
import { getGraphQlErrorCode, generateDocumentTitle } from '@/utils/functions/global';

import { SUPPORT_DETAILS } from '@/graphql/queries/library';

import { SUPPORT_DELETE_FRONT_COVER } from '@/graphql/mutations/library';

import SupportModal from '@/containers/modals/support';
import CropperModal from '@/containers/modals/cropper';

export default {
  apollo: {
    supportDetails: {
      query: SUPPORT_DETAILS,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          libraryId: this.currentWorkspace.libraries[0].id,
          supportId: this.$route.params.id
        };
      },
      watchLoading(loading) {
        if (loading) {
          this.isDataLoaded = false;
        }
      },
      result(res) {
        if (res.data && res.data.supportDetails) {
          this.albumName = res.data.supportDetails.name;
          this.displayDeleteArtwork = res.data.supportDetails.images.length > 0;
          document.title = generateDocumentTitle([this.albumName]);
          // this.$apollo
          //   .query({
          //     query: LIBRARY_FILES_DETAILS,
          //     variables: {
          //       libraryId: this.currentWorkspace.libraries[0].id,
          //       libraryFilesIds: res.data.supportDetails.tracks_ids
          //     }
          //   })
          //   .then(response => {
          //     this.supportTracks = response.data.LibraryFilesDetails;
          //     this.isDataLoaded = true;
          //   });
        }
        if (get(res, 'data.supportDetails.artists', false)) {
          this.defaultArtistOptions = res.data.supportDetails.artists.map(artist => ({
            id: artist.id,
            value: artist.name
          }));
        }
      },
      error(error) {
        this.handleUnknownSupport(error);
      }
    }
  },
  data() {
    return {
      displayDeleteArtwork: false,
      error: false,
      albumName: ''
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'playlist', 'newUpload']),
    tabs() {
      const isShared = this.$route.path.includes('shared');
      const { id } = this.$route.params;
      return [
        {
          title: this.$t('tracks'),
          link: isShared
            ? getRoute('sharedAlbumDetails', { id })
            : getRoute('albumDetails', { id }),
          name: 'albumDetails'
        },
        ...(!this.isUnknownAlbum()
          ? [
              {
                title: this.$t('sharingLinks'),
                link: isShared
                  ? getRoute('sharedAlbumSharings', { id })
                  : getRoute('albumSharings', { id }),
                name: 'albumSharings'
              }
            ]
          : []),
        ...(!this.isUnknownAlbum()
          ? [
              {
                title: this.$t('sharingActivity'),
                link: isShared
                  ? getRoute('sharedAlbumActivity', { id })
                  : getRoute('albumActivity', { id }),
                name: 'albumActivity'
              }
            ]
          : [])
      ];
    },
    activeTab() {
      const active = this.tabs.find(tab => tab.name === this.$route.name);
      if (active.title === 'Sharing links') {
        document.title = generateDocumentTitle([this.albumName, 'Links']);
      } else if (active.title === 'Activity') {
        document.title = generateDocumentTitle([this.albumName, 'Activity']);
      } else {
        document.title = generateDocumentTitle([this.albumName]);
      }
      return this.tabs.indexOf(active);
    },
    albumImgUrl() {
      if (this.supportDetails.id === 'unknown') {
        return '/images/unknown-album.svg';
      }
      return getFrontCoverUrl(this.supportDetails.images, 2) || '/images/artwork.jpg';
    }
  },
  created() {
    bus.$on('openAlbumEditModal', () => {
      this.editSupport();
    });
  },
  beforeDestroy() {
    bus.$off('openAlbumEditModal');
  },
  methods: {
    goToArtistPage(artistId) {
      this.$router.push(getRoute('artist', { id: artistId }));
    },
    isUnknownAlbum() {
      return this.supportDetails && this.supportDetails.id === 'unknown';
    },
    handleUnknownSupport(error) {
      this.error = error;
      if (this.$route.name !== 'trackList') this.$router.push(getRoute('trackList'));
    },
    openCropperModal(blob) {
      if (this.$isWorkspaceLockedVerification(this)) return;
      if (!blob) return;
      bus.$emit('displayModal', {
        title: this.$t('cropImage'),
        size: 'medium',
        component: CropperModal,
        onSubmit: this.changeSupportCover,
        onCancel: () => {},
        isVisible: true,
        props: [
          {
            name: 'blob',
            value: blob
          },
          {
            name: 'imageMaxWidth',
            value: appConfig.upload.support.width
          },
          {
            name: 'imageMaxHeight',
            value: appConfig.upload.support.height
          },
          {
            name: 'isSquare',
            value: true
          }
        ]
      });
    },
    editSupport() {
      if (this.$isWorkspaceLockedVerification(this)) return;
      bus.$emit('displayModal', {
        title: this.$t('editAlbum'),
        size: 'medium',
        isVisible: true,
        component: SupportModal,
        onSubmit: this.renderAlbumView,
        props: [
          {
            name: 'supportDetails',
            value: {
              supportId: this.supportDetails.id,
              supportName: this.supportDetails.name,
              albumType: this.supportDetails.type,
              albumRelease: this.supportDetails.release_date,
              albumUpc: this.supportDetails.upc,
              albumTotalTracks: this.supportDetails.tracks_total
            }
          },
          {
            name: 'albumImgUrl',
            value: this.albumImgUrl
          },
          {
            name: 'defaultArtists',
            value: this.defaultArtistOptions
          }
        ]
      });
    },
    changeSupportCover(blob) {
      const file = new File([blob], 'newAlbumCover', { type: 'image/jpeg' });
      handleSupportImageUpload(this, file, this.$route.params.id);
    },
    getAlbumDuration(seconds) {
      return formatDuration(seconds, FORMAT_DURATION.hmm);
    },
    getDate(timestamp) {
      return formatDate(this.$i18n.locale, timestamp);
    },
    deleteSupportCover() {
      if (this.$isWorkspaceLockedVerification(this)) return;
      this.$apollo
        .mutate({
          mutation: SUPPORT_DELETE_FRONT_COVER,
          variables: {
            supportId: this.$route.params.id
          }
        })
        .then(() => {
          this.$apollo.queries.supportDetails.refetch().then(() => {
            this.supportChangesIsPending = false;
          });
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    renderAlbumView() {
      this.$apollo.queries.supportDetails.refetch();
    }
  }
};
</script>

<style lang="scss" scoped>
.album {
  padding: 40px 0 0 0;
  @include body-1;
  margin: 0 0 80px 0;
  &-header {
    display: flex;
    padding: 0 0 40px 40px;

    img {
      width: 227px;
      height: 227px;
      border-radius: 2px;
      flex-shrink: 0;
    }

    &-artistList {
      display: inline-block;
      margin-right: 4px;
      cursor: pointer;
      &:hover span {
        border-bottom: 1px dotted $color_neutral_100;
      }
      &:after {
        content: ',';
      }
      &:last-child:after {
        content: '';
      }
    }

    &-info {
      margin: 0 0 0 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-txt {
        &-name {
          @include heading-4;
          max-width: 380px;
          margin: 0 0 4px 0;
          line-height: 34px;
        }

        &-count {
          @include body-2;
          margin: 8px 0 24px 0;
          color: $color_neutral_60;
        }

        &-details {
          display: flex;
          margin: 0 0 8px 0;

          &-label {
            width: 140px;
            @include body-1;
            color: $color_neutral_60;
          }

          &-value {
            width: 140px;
            @include body-1;
          }
        }
      }

      &-actions {
        display: flex;
        min-width: 195px;

        &-- {
          margin: 0 0 0 8px;
        }
      }
    }
  }
  &-tabs {
    margin-left: 40px;
    width: calc(100% - 40px);
    position: sticky;
    top: 0;
    background-color: $color_neutral_0;
    z-index: 1;
  }
}
</style>
