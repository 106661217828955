<template>
  <div class="filter-dropdown" v-click-outside="{ hide: closeExpandDropdown }">
    <div
      class="filter-dropdown-btn"
      :class="{ 'btn-active': filterActive }"
      @click="toggleExpandDropdown"
    >
      <i class="ri-calendar-2-line"></i>
      <div>{{ filterName }}</div>
    </div>
    <div class="filter-dropdown-expand" v-if="isDropdownExpanded">
      <div class="filter-dropdown-expand-head">
        <text-dropdown
          :options="txtDropdownOptions"
          :selectedLabel="temporality.label"
          @isExpanded="() => (keepExpand = true)"
          @selectOption="setTemporality"
        />
        <submit-button
          btnStyle="tertiary"
          size="small"
          :label="clearBtnLabel"
          @submit="clearDate"
        />
      </div>
      <div :class="{ 'filter-dropdown-expand-datepickers': isBetween }">
        <date-picker
          :local="local"
          :selectedDate="firstDate"
          :disabledDates="firstDisabledDates"
          @datePicked="setFirstDate"
        />
        <date-picker
          v-if="isBetween"
          :local="local"
          :selectedDate="secondDate"
          :disabledDates="secondDisabledDates"
          @datePicked="setSecondDate"
        />
      </div>
      <div class="filter-dropdown-expand-action">
        <submit-button
          class="close-btn"
          btnStyle="tertiary"
          size="medium"
          :label="tertiaryBtnLabel"
          @submit="closeDropdown"
        />
        <submit-button
          btnStyle="primary"
          size="medium"
          :label="primaryBtnLabel"
          @submit="setDateFilter"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { clickOutside } from '@/utils/directives';

import DatePicker from '@/components/datePicker';
import TextDropdown from '@/components/dropdown_legacy/textDropdown';
import SubmitButton from '@/components/buttons/submitButton';

const ONE_DAY_IN_MS = 86400000;

export default {
  components: {
    DatePicker,
    TextDropdown,
    SubmitButton
  },
  props: {
    local: {
      type: String,
      required: false,
      default: 'en'
    },
    filterName: {
      type: String,
      required: false,
      default: 'Date'
    },
    primaryBtnLabel: {
      type: String,
      required: false,
      default: 'Apply'
    },
    tertiaryBtnLabel: {
      type: String,
      required: false,
      default: 'Close'
    },
    clearBtnLabel: {
      type: String,
      required: false,
      default: 'Clear'
    },
    txtDropdownOptions: {
      type: Array,
      required: true
    }
  },
  directives: {
    clickOutside
  },
  data() {
    return {
      keepExpand: false,
      isDropdownExpanded: false,
      secondDisabled: true,
      temporality: {
        label: this.txtDropdownOptions[0].label,
        value: this.txtDropdownOptions[0].value
      },
      firstDate: null,
      secondDate: null,
      firstDisabledDates: {},
      secondDisabledDates: {},
      filterActive: false
    };
  },
  computed: {
    isBetween() {
      return this.temporality.value === 'between';
    }
  },
  methods: {
    toggleExpandDropdown() {
      this.isDropdownExpanded = !this.isDropdownExpanded;
      this.$emit('setIsDropdownExpanded', this.isDropdownExpanded);
    },
    closeExpandDropdown() {
      if (this.keepExpand) {
        this.keepExpand = false;
        return;
      }
      this.isDropdownExpanded = false;
      this.$emit('setIsDropdownExpanded', this.isDropdownExpanded);
    },
    setFirstDate(timestamp) {
      this.firstDate = timestamp || null;
      const limitdate = new Date(timestamp + ONE_DAY_IN_MS);
      this.secondDisabledDates = timestamp ? { to: limitdate } : {};
    },
    setSecondDate(timestamp) {
      this.secondDate = timestamp || null;
      const limitdate = new Date(timestamp - ONE_DAY_IN_MS);
      this.firstDisabledDates = timestamp ? { from: limitdate } : {};
    },
    setTemporality(value, label) {
      this.temporality = {
        label,
        value
      };
    },
    setDateFilter() {
      if (this.isBetween) {
        this.filterActive = this.firstDate !== null && this.secondDate !== null;
      } else {
        this.filterActive = this.firstDate !== null;
      }
      this.isDropdownExpanded = false;
      this.$emit('setIsDropdownExpanded', this.isDropdownExpanded);
      this.$emit('setDateFilter', this.temporality.value, this.firstDate, this.secondDate);
    },
    clearDate() {
      this.firstDate = null;
      this.secondDate = null;
      this.firstDisabledDates = {};
      this.secondDisabledDates = {};
      this.setDateFilter();
    },
    closeDropdown() {
      this.closeExpandDropdown();
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-dropdown {
  position: relative;
  &-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 32px;
    width: fit-content;
    padding: 0 16px;
    border: 1px solid $color_neutral_40;
    border-radius: 4px;
    box-sizing: border-box;
    @include body-1;
    line-height: 100%;
    i {
      color: $color_neutral_40;
      margin-right: 4px;
    }
  }
  &-expand {
    position: absolute;
    top: 34px;
    width: 328px;
    height: fit-content;
    max-height: 248px;
    background: $color_neutral_0;
    border: 1px solid $color_neutral_30;
    box-sizing: border-box;
    @include shadow-down-03;
    @include body-1;
    border-radius: 2px;
    padding: 4px 0;
    padding: 8px 16px 16px 16px;
    &-head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;
    }
    &-datepickers {
      display: flex;
      & > div:nth-child(1) {
        margin-right: 4px;
      }
    }
    &-action {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
    }
  }
}
.btn-active {
  background-color: $color_primary_10;
  border-color: $color_primary_100;
  color: $color_primary_100;
}
.close-btn {
  padding: 0 24px;
  margin-right: 8px;
}
</style>
