<template v-slot:body>
  <div class="change-card-modal">
    <div id="card-element" ref="stripeElement" />
    <div class="change-card-modal-buttons">
      <div class="change-card-modal-buttons--">
        <submit-button
          btnStyle="tertiary"
          size="medium"
          :label="$t('cancel')"
          @submit="closeModal"
        />
      </div>
      <div class="change-card-modal-buttons--">
        <submit-button
          btnStyle="primary"
          size="medium"
          :label="$t('save')"
          :pending="pending"
          @submit="submit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { STRIPE_CREATE_SETUP_INTENT } from '@/graphql/queries/subscription';
import { STRIPE_SET_DEFAULT_PAYMENT_METHOD } from '@/graphql/mutations/subscription';
import { bus } from '@/utils/bus';
import get from 'lodash.get';
import { mapGetters } from 'vuex';
import { apolloClient } from '../../../main';

import ManropeRegular from '../../../assets/fonts/Manrope-Regular.ttf';

export default {
  data() {
    return {
      stripeInstance: undefined,
      cardComplete: false,
      pending: false,
      empty: true
    };
  },
  mounted() {
    // Set up Stripe.js and Elements to use in checkout form
    this.stripeInstance = window.Stripe(this.$config.stripe_pk);
    const elements = this.stripeInstance.elements({
      fonts: [
        // Elements are in iFrame so it must downloads its own fonts again
        {
          family: 'manrope',
          src: `url("${document.location.origin}${ManropeRegular.toString()}")`,
          weight: '500'
        }
      ]
    });
    this.stripeCardElement = elements.create('card', {
      hidePostalCode: true,
      style: {
        base: {
          color: '#32325D',
          fontWeight: 500,
          fontFamily: 'manrope',
          fontSize: '14px',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: '#CFD7DF'
          },
          ':-webkit-autofill': {
            color: '#e39f48'
          }
        },
        invalid: {
          backgroundColor: 'red',
          color: '#FF4533',
          '::placeholder': {
            color: 'black'
          }
        }
      }
    });
    this.stripeCardElement.mount('#card-element');
    this.stripeCardElement.on('change', event => {
      this.cardComplete = event.complete;
      this.empty = event.empty;
    });
  },
  computed: {
    ...mapGetters(['currentWorkspace'])
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    async submit() {
      if (this.cardComplete) {
        const intentResponse = await apolloClient.query({
          query: STRIPE_CREATE_SETUP_INTENT,
          variables: {
            workspaceId: this.currentWorkspace.id
          },
          fetchPolicy: 'network-only'
        });
        const secret = get(intentResponse, 'data.StripeCreateSetupIntent.secret');
        this.pending = true;
        this.stripeInstance
          .confirmCardSetup(secret, {
            payment_method: {
              card: this.stripeCardElement
            }
          })
          .then(result => {
            if (get(result, 'setupIntent.status') === 'succeeded') {
              const subscriptionId = get(
                this.currentWorkspace,
                'subscription.payment_information.subscription_id'
              );
              const paymentMethodId = result.setupIntent.payment_method;
              this.$apollo
                .mutate({
                  mutation: STRIPE_SET_DEFAULT_PAYMENT_METHOD,
                  variables: {
                    subscriptionId,
                    paymentMethodId
                  }
                })
                .then(() => {
                  bus.$emit('refetchStripeCustomerCards');
                  this.pending = false;
                  this.$emit('closeModal');
                  bus.$emit('showAlert', {
                    message: { key: 'modificationSuccess' },
                    style: 'success',
                    delay: 5000
                  });
                });
            } else {
              this.pending = false;
              bus.$emit('showAlert', {
                message: { key: null },
                style: 'danger',
                delay: 5000,
                error: true
              });
            }
          });
      }
      if (this.empty) {
        this.$emit('closeModal');
      }
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
.change-card-modal {
  padding: 24px 24px 8px 24px;
  &-buttons {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $color_neutral_30;
    padding: 8px 24px 0 24px;
    width: calc(100% + 48px);
    margin-left: -24px;
    box-sizing: border-box;

    &-- {
      &:first-child {
        margin: 0 16px 0 0;
      }
    }
  }
}
.StripeElement {
  background: white;
  border: 1px solid black;
  margin: 8px 0 20px;
  height: 46px;
  font-family: inherit;
  padding: 12px 12px;
  box-sizing: border-box;
  color: $color_neutral_100;
  font-size: 14px;
  outline: none;
  border: none;
  filter: none;
  font-weight: 400;
  border: 1px solid $color_neutral_40;
  border-radius: 4px;
  line-height: 46px;
  &.focused {
    border-color: black;
  }
}
</style>
