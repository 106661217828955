<template>
  <div class="row">
    <div
      class="row-body"
      :class="[{ 'row-deleted': item.deleted }]"
      :style="gridTemplateColumns"
      @mouseenter="setIsHoverId(item.id, true)"
      @mouseleave="setIsHoverId(null, false)"
    >
      <div
        v-for="(column, idx) in columnsFiltered"
        :class="{
          'row-body-cell': true,
          'row-body-no-border': column.noBorder,
          'row-body-centered': column.centered,
          'row-body-selected': selectedItems.includes(item.id) && !column.noBorder,
          'row-body-playing':
            !column.noBorder && playingTrack.id && item.id && item.id === playingTrack.id
        }"
        :style="[
          { height: `${height}px` },
          {
            padding: column.padding
              ? `${column.padding[0]}px ${column.padding[1]}px ${column.padding[2]}px ${column.padding[3]}px`
              : 'unset'
          },
          {
            gridColumn:
              column.span && !isHover && !expandedItemId ? `span ${column.span} / auto` : 'unset'
          }
        ]"
        :key="`${item.id}-${idx}`"
        @click.prevent="toggleCheckbox(column.name, item.id, $event)"
        @dblclick.prevent="handleDblclickRow(item.id, column.name)"
      >
        <div
          class="low-opacity"
          v-if="column.type === 'component'"
          @mouseenter="toggleTooltip(column, true)"
          @mouseleave="toggleTooltip(column, false)"
          :class="{
            'row-body-cell-hover': column.isHover && isNoItemsSelected,
            'row-body-cell-expand-hover': column.isExpandHover && expandedItemId !== item.id
          }"
          :style="{
            height: column.containerHeight ? height : 'auto',
            width: column.containerWidth ? '100%' : 'auto'
          }"
          v-tooltip="{
            content: column.dynamicTooltip ? item[column.dynamicTooltip] : column.tooltip,
            show: isHover && tooltipList[column.name],
            hideOnTargetClick: true,
            delay: { show: 100, hide: 0 }
          }"
          :key="`${item.id}-${idx}-component`"
        >
          <div v-if="item.deleted">
            <div v-if="column.isExpandHover || column.isHover"></div>
            <div v-else-if="column.name === 'artwork'" class="placeholder-img-container">
              <img class="placeholder-img" src="/images/artwork.jpg" />
            </div>
            <div v-else>
              <div v-if="column.name === 'text-action'">Track deleted</div>
              <div v-else>-</div>
            </div>
          </div>
          <component
            v-if="!item.deleted"
            :stopbubble="!column.noStop"
            :is="column.name"
            :key="column.name"
            v-bind="columnsProperties(item, column.props)"
            v-on="columnsListeners"
            @click.native.prevent
            @dblclick.native.prevent.stop="() => {}"
          />
        </div>
        <div
          v-if="column.type === 'text'"
          class="low-opacity"
          :class="{
            'row-body-cell-list': Array.isArray(item[column.name]),
            'row-body-cell-text-container': !Array.isArray(item[column.name])
          }"
        >
          <div v-if="item.deleted">
            <div v-if="column.name === 'title'">Track deleted</div>
            <div v-else>-</div>
          </div>
          <div v-else class="ellipsis-container">
            <div
              v-if="!Array.isArray(item[column.name])"
              @click="navigateTo($event, column.route, item)"
              :class="{
                'row-body-cell-text': true,
                'row-body-cell-link': column.route
              }"
              :title="column.noTitle ? '' : item[column.name]"
            >
              {{ item[column.name] }}
            </div>
            <div v-else class="row-body-cell-list-container-text">
              <span
                v-for="(element, index) in item[column.name]"
                :key="`item-txt-${index}`"
                @click="navigateTo($event, column.route, element)"
                :title="generateTitleList(item[column.name])"
                :class="{
                  'row-body-cell-list-text': true,
                  'row-body-cell-list-link': column.route
                }"
              >
                {{ element.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { VTooltip } from 'v-tooltip';

Vue.directive('tooltip', VTooltip);

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    rowIndex: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: false,
      default: 56
    },
    disableSelection: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedItems: {
      type: Array,
      required: false,
      default: () => []
    },
    playingTrack: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      fromTo: [],
      expandedItemId: null,
      isHover: false,
      projectDropdownState: [],
      moreDropdownState: [],
      tooltipList: {}
    };
  },
  computed: {
    columnsListeners(params) {
      const { columns } = params;
      const methods = {};
      columns
        .filter(column => column.type === 'component')
        .forEach(column => {
          if (column.events) {
            column.events.forEach(event => {
              methods[event] = (param1, param2, param3) => {
                this[event](param1, param2, param3);
              };
            });
          }
          if (column.emits) {
            column.emits.forEach(emit => {
              methods[emit] = param => {
                this.handleEmit(emit, param);
              };
            });
          }
        });
      return { ...this.$listeners, ...methods };
    },
    gridTemplateColumns() {
      let str = 'grid-template-columns:';
      this.columns.forEach(column => {
        str += ` minmax(${column.min}, ${column.max})`;
      });
      return str;
    },
    columnsFiltered() {
      // when the row is not hovered or forced hovered state
      // return the columns without the one only visible on hover
      return this.isHover || this.expandedItemId
        ? this.columns
        : this.columns.filter(column => !column.onHover);
    },
    isNoItemsSelected() {
      return this.selectedItems.length === 0;
    }
  },
  created() {
    const filteredColumns = this.columns.filter(column => column.type === 'component');
    this.resetTooltip();
    const components = {};
    filteredColumns.forEach(column => {
      if (column.path) {
        components[column.name] = () => import(`../${column.path}/index.vue`);
      }
    });
    Object.assign(this.$options.components, components);
  },
  methods: {
    generateTitleList(item) {
      const list = item && item.map(i => i.name).join(', ');
      return list;
    },
    columnsProperties: (item, props) => {
      const p = {};
      props.forEach(prop => {
        p[prop.name] = prop.value || item[prop.match];
      });
      return p;
    },
    handleEmit(methodName, param) {
      if (methodName) {
        this.$emit('handleEmit', { methodName, param });
      }
    },
    setIsHoverId(itemId, bool) {
      if (!bool) {
        this.resetTooltip();
      }
      this.isHover = bool;
      this.handleEmit('setIsHoverId', itemId);
    },
    handleDblclickRow(itemId, column) {
      if (column === 'checkbox') {
        return;
      }
      this.$emit('setPlayingTrackFromRow', { id: itemId, play: true });
    },
    resetTooltip() {
      const filteredColumns = this.columns.filter(column => column.type === 'component');
      filteredColumns.forEach(column => {
        if (Object.prototype.hasOwnProperty.call(column, 'tooltip')) {
          const { name } = column;
          this.tooltipList[name] = false;
        }
      });
    },
    toggleTooltip(column, bool) {
      if (Object.prototype.hasOwnProperty.call(column, 'tooltip')) {
        const { name } = column;
        this.tooltipList[name] = bool;
      }
    },
    toggleCheckbox(component, itemId, event) {
      const checkbox = component === 'checkbox';
      const path = event.path || (event.composedPath && event.composedPath()) || [];
      const stopBubble = path.some(
        element => typeof element.getAttribute === 'function' && element.getAttribute('stopbubble')
      );
      if (stopBubble && !checkbox) {
        return;
      }
      if (this.disableSelection) {
        return;
      }
      if (event.detail === 2 && !checkbox && this.selectedItems.includes(itemId)) {
        return;
      }
      this.fromTo = [];
      this.fromTo.push(this.rowIndex);
      if (event && event.shiftKey) {
        this.handleEmit('shiftSelectItems', {
          elementUID: this.item.id,
          rowIndex: this.rowIndex
        });
        return;
      }
      this.handleEmit('setSelectedItems', {
        elementUID: this.item.id,
        rowIndex: this.rowIndex,
        checkbox
      });
    },
    navigateTo(e, route, item) {
      if (!route) return;
      e.stopPropagation();
      const { name, id } = route;
      const param = route.id ? { name, id: item[id] } : { name };
      this.handleEmit('navigateTo', param);
    },
    setProjectDropdownState(projectDropdownState) {
      if (projectDropdownState.isExpanded) this.projectDropdownState.push(projectDropdownState.id);
      if (!projectDropdownState.isExpanded) {
        const idx = this.projectDropdownState.findIndex(id => id === projectDropdownState.id);
        this.projectDropdownState.splice(idx, 1);
      }
      this.setExpandedItemId();
    },
    setMoreDropdownState(moreDropdownState) {
      if (moreDropdownState.isExpanded) this.moreDropdownState.push(moreDropdownState.id);
      if (!moreDropdownState.isExpanded) {
        const idx = this.moreDropdownState.findIndex(id => id === moreDropdownState.id);
        this.moreDropdownState.splice(idx, 1);
      }
      this.setExpandedItemId();
    },
    setExpandedItemId() {
      const arr = this.projectDropdownState.concat(this.moreDropdownState);
      this.expandedItemId = arr[arr.length - 1] ? arr[arr.length - 1] : null;
    },
    selectItemFromDropdown(itemId) {
      if (this.selectedItems.includes(itemId) || this.disableSelection) {
        return;
      }
      const index = this.item.id;
      this.fromTo = [];
      this.fromTo.push(index);
      this.handleEmit('setSelectedItems', { elementUID: itemId });
    }
  }
};
</script>

<style lang="scss" scoped>
.row {
  @include body-1;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  &-body {
    display: grid;
    cursor: pointer;

    &:hover {
      .row-body-cell-hover {
        opacity: 1;
      }

      .row-body-cell-expand-hover {
        opacity: 1;
      }
    }

    &-cell {
      display: flex;
      align-items: center;
      border-bottom: 1px dotted $color_neutral_40;

      &-hover,
      &-expand-hover {
        opacity: 0;
      }

      &-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom: 1px solid rgba(0, 0, 0, 0);
        padding: 1px 0 0 0;
        &-container {
          width: 100%;
        }
      }

      &-link {
        &:hover {
          text-decoration: underline $color_neutral_100;
        }
      }

      &-list {
        display: contents;
        &-link {
          &:hover {
            text-decoration: underline;
          }
        }
        &-container {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &-text {
            border-bottom: 1px solid rgba(0, 0, 0, 0);
            margin: 1px 4px 0 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            span:after {
              content: ', ';
            }

            span:last-child {
              &:after {
                content: '';
              }
            }
          }

          &-link {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0 16px 0 0;

            &:last-child {
              margin: 0;
            }

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    &-no-border {
      border: none;
    }

    &-centered {
      justify-content: center;
    }

    &-selected {
      background-color: $color_neutral_10;
    }

    &-playing {
      color: $color_primary_100;
    }
  }
}
.placeholder-img {
  width: 32px;
  height: 32px;
  border-radius: 2px;
  &-container {
    display: flex;
  }
}
.row-deleted {
  .row-body-cell-list {
    display: unset;
  }
  .low-opacity {
    opacity: 40%;
  }
  pointer-events: none;
  cursor: default;
}
.ellipsis-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
