
<div>
  <div class="content">
    <h4 class="header">{{ $t('billingDetails.invoicesSentTo') }}</h4>
    <div v-if="email === undefined" class="loader">
      <spinner-without-progress color="grey" :size="32" />
    </div>
    <div v-else>
      <div v-if="hasEmail()">
        <div class="value">{{ email }}</div>
        <submit-button
          class="button"
          btnStyle="tertiary"
          :exception="true"
          size="medium"
          :label="$t('billingDetails.changeContact')"
          @submit="changePaymentMethod"
        />
      </div>
      <div v-else>
        <p>No email saved.</p>
        <submit-button
          class="button"
          btnStyle="tertiary"
          :exception="true"
          size="medium"
          :label="$t('billingDetails.changeContact')"
          @submit="changePaymentMethod"
        />
      </div>
    </div>
  </div>
</div>
