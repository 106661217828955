<template>
  <div
    class="support-panel-body"
    :key="renderKey"
    v-if="(supportDetails && !supportDetailsLoading) || noSupport || !isSameSupport"
  >
    <div class="support-panel-body-title">
      <div class="support-panel-body-title--" @click="goToAlbumPage">{{ title }}</div>
      <div v-if="isSameSupport && !noSupport && $route.name !== 'album'">
        <submit-button
          btnStyle="secondary"
          size="small"
          :label="$t('edit')"
          @submit="editSupport"
        />
      </div>
    </div>
    <div v-if="noSupport">
      <div class="no-support">
        <div class="no-support-label">{{ $t('album') }}</div>
        <auto-complete-list
          :queries="autoCompleteSupport"
          :dataLoaded="isSupportsDataLoaded"
          dataType="SupportsAutocomplete"
          storeEvent="changeIsSupportsLoaded"
          :ignoreUnknown="true"
        >
          <template v-slot:default="slotProps">
            <input-dropdown
              key="support-tab-dropdown"
              id="support-tab-dropdown"
              :width="334"
              placeholder="Select or create a new album"
              :defaultList="slotProps.items"
              :loadingOptions="slotProps.isLoading"
              :showObserver="slotProps.showObserver"
              :fetchingMoreOptions="slotProps.fetchingMoreItems"
              preventRemoveOptOnKeyUp
              preventAddNew
              @preventedAddNew="openCreateSupport"
              @listOptions="slotProps.fetch"
              @fetchMoreOptions="slotProps.fetchMoreItems"
              @searchOption="slotProps.search"
              @handleResult="setSupports"
              @handleInput="HandleSupportInput"
            />
          </template>
        </auto-complete-list>
      </div>
    </div>
    <div v-else>
      <div class="support-panel-body-section">
        <div class="support-panel-body-section--">{{ $t('artwork') }}</div>
        <div class="support-panel-body-section--">
          <img :src="artwork" alt="artwork" />
        </div>
      </div>
      <div class="support-panel-body-section">
        <div class="support-panel-body-section--">{{ $t('type') }}</div>
        <div class="support-panel-body-section--">{{ type }}</div>
      </div>
      <div class="support-panel-body-section">
        <div class="support-panel-body-section--">{{ $t('artists') }}</div>
        <div class="artists-container" v-if="!isMultipleTrackEdition || isSameSupport">
          <div
            class="artist-name"
            v-for="(artist, id) in defaultArtistOptions"
            :key="`${id}-artist`"
            @click="goToArtistPage(artist.id)"
          >
            {{ artist.value }}<span v-if="id + 1 < defaultArtistOptions.length">,&nbsp;</span>
          </div>
        </div>
        <div class="artists-container" v-else>
          {{ multipleTemplate }}
        </div>
      </div>
      <div class="support-panel-body-section">
        <div class="support-panel-body-section--">{{ $t('releaseDate') }}</div>
        <div class="support-panel-body-section--">{{ getDate(releaseDate) }}</div>
      </div>
      <div class="support-panel-body-section">
        <div class="support-panel-body-section--">UPC</div>
        <div class="support-panel-body-section--">{{ upc }}</div>
      </div>
      <div class="support-panel-body-section">
        <div class="support-panel-body-section--">{{ $t('totalTracks') }}</div>
        <div class="support-panel-body-section--">{{ totalTracks }}</div>
      </div>
    </div>
  </div>
  <div v-else class="support-loader">
    <spinner-without-progress color="grey" :size="32" />
  </div>
</template>

<script>
import get from 'lodash.get';
import { mapGetters } from 'vuex';
import { formatDate } from '@/utils/functions/time';
import { getFrontCoverUrl } from '@/utils/functions/image';
import SupportModal from '@/containers/modals/support';
import { bus } from '@/utils/bus';
import { WORKSPACE } from '@/graphql/queries/user';
import { SUPPORT_DETAILS, SUPPORT_LIST, SUPPORTS_AUTOCOMPLETE } from '@/graphql/queries/library';
import { LIBRARY_TRACKS_SUPPORT_UPDATE } from '@/graphql/mutations/library';
import AutoCompleteList from '@/containers/autoCompleteList';
import { get as getRoute } from '../../router/routes';

export default {
  components: {
    AutoCompleteList
  },
  apollo: {
    supportDetails: {
      query: SUPPORT_DETAILS,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          libraryId: this.currentWorkspace.libraries[0].id,
          supportId: this.tracksToEdit[0].supports[0].id
        };
      },
      watchLoading(isLoading) {
        this.supportDetailsLoading = isLoading;
      },
      result(res) {
        if (get(res, 'data.supportDetails.artists', false)) {
          this.defaultArtistOptions = res.data.supportDetails.artists.map(artist => ({
            id: artist.id,
            value: artist.name
          }));
        }
      },
      skip() {
        return this.noSupport || !this.isSameSupport;
      }
    }
  },
  props: {
    tracksToEdit: {
      type: Array,
      required: false,
      default: () => []
    },
    isMultipleTrackEdition: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      renderKey: 0,
      defaultArtistOptions: [],
      multipleTemplate: '< Multiple >',
      supportDetailsLoading: false,
      supportModel: null
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'isSupportsLoaded']),
    isSupportsDataLoaded() {
      return this.isSupportsLoaded;
    },
    autoCompleteSupport() {
      return {
        items: SUPPORT_LIST,
        itemKey: 'supportList',
        itemVariables: {
          libraryId: this.currentWorkspace.libraries[0].id,
          page: 1,
          limit: undefined,
          orderBy: 'name',
          sortBy: 'asc'
        },
        search: SUPPORTS_AUTOCOMPLETE,
        searchKey: 'SupportsAutocomplete'
      };
    },
    title() {
      const t = this.getObjectValue('name');
      return this.noSupport ? null : t;
    },
    artwork() {
      const artwork = this.isMultipleTrackEdition
        ? this.getValueForMultipleEdition('imgSrc')
        : getFrontCoverUrl(this.supportDetails.images, 2);
      return artwork || '/images/artwork.jpg';
    },
    type() {
      return this.getObjectValue('type');
    },
    upc() {
      return this.getObjectValue('upc');
    },
    totalTracks() {
      return this.getObjectValue('tracks_total');
    },
    releaseDate() {
      return this.getObjectValue('release_date');
    },
    isUnknown() {
      return this.getObjectValue('name') === '-';
    },
    isSameSupport() {
      const supportsIds = this.tracksToEdit.map(el => get(el, 'supports[0].id', undefined));
      return supportsIds.every(val => val === supportsIds[0]);
    },
    noSupport() {
      // if search if at least 1 track has 1 support, if not, return true
      return !this.tracksToEdit.some(track => track.supports.some(support => support.id !== null));
    }
  },
  watch: {
    tracksToEdit() {
      this.renderKey = Math.floor(Math.random() * 100000);
    }
  },
  methods: {
    HandleSupportInput(newValue) {
      this.supportModel = newValue;
    },
    setSupports(supports) {
      if (supports) {
        const input = this.tracksToEdit.map(track => ({
          id: track.id,
          supports_ids: supports[0].id
        }));
        this.$apollo.mutate({
          mutation: LIBRARY_TRACKS_SUPPORT_UPDATE,
          variables: {
            input
          },
          refetchQueries: [
            {
              query: WORKSPACE,
              variables: {
                workspaceId: this.currentWorkspace.id
              }
            }
          ]
        });
      }
    },
    getDate(releaseDate) {
      return formatDate(this.$i18n.locale, parseInt(releaseDate, 10));
    },
    getValueForMultipleEdition(key) {
      const { length } = this.tracksToEdit.filter(
        item => JSON.stringify(this.tracksToEdit[0][key]) === JSON.stringify(item[key])
      );
      return length === this.tracksToEdit.length ? this.tracksToEdit[0][key] : null;
    },
    getObjectValue(key) {
      const single = this.supportDetails ? this.supportDetails[key] : 'loading';
      const multiple = this.isSameSupport ? single : '< Multiple >';
      return this.isMultipleTrackEdition ? multiple : single;
    },
    goToAlbumPage() {
      const supportId =
        this.getValueForMultipleEdition('supports') &&
        this.getValueForMultipleEdition('supports')[0] &&
        this.getValueForMultipleEdition('supports')[0].id;
      this.$router.push(getRoute('album', { id: supportId }));
    },
    goToArtistPage(id) {
      this.$router.push(getRoute('artist', { id }));
    },
    editSupport() {
      const libraryFilesIds = this.tracksToEdit.map(track => track.id);
      bus.$emit('displayModal', {
        title: this.$t('editAlbum'),
        size: 'medium',
        isVisible: true,
        component: SupportModal,
        onSubmit: this.updateView,
        props: [
          {
            name: 'supportDetails',
            value: {
              supportId: this.getObjectValue('id'),
              supportName: this.title,
              albumType: this.type,
              albumRelease: this.releaseDate,
              albumUpc: this.upc,
              albumTotalTracks: this.totalTracks
            }
          },
          {
            name: 'albumImgUrl',
            value: this.artwork
          },
          {
            name: 'defaultArtists',
            value: this.defaultArtistOptions
          },
          {
            name: 'libraryFilesIds',
            value: libraryFilesIds
          }
        ]
      });
    },
    openCreateSupport() {
      this.$emit('goToDetailsCreateAlbum', this.supportModel);
    },
    updateView() {
      this.$apollo.queries.supportDetails.refetch();
    }
  }
};
</script>

<style lang="scss" scoped>
.support-panel-body {
  &-title {
    display: flex;
    justify-content: space-between;
    &-- {
      @include subtitle-1;
      margin: 0 0 24px 0;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-section {
    display: flex;
    margin: 0 0 16px 0;

    &-- {
      width: 140px;

      img {
        width: 64px;
        height: 64px;
      }
    }
  }
}
.artists-container {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
}
.artist-name {
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.support-loader {
  height: 524px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-support {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-label {
    color: $color_neutral_60;
  }
}
</style>
