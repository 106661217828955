export const clickOutside = {
  bind(el, binding) {
    // eslint-disable-next-line
    el.clickOutsideEvent = function handleClickOutside(event) {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        binding.value.hide();
      }
    };
    document.addEventListener('click', el.clickOutsideEvent);
    document.addEventListener('touch', el.clickOutsideEvent);
  },
  unbind(el) {
    document.removeEventListener('click', el.clickOutsideEvent);
    document.removeEventListener('touch', el.clickOutsideEvent);
  }
};

const calculDropdownOrientation = (el, binding) => {
  const parent = el.parentElement;
  const { top, left } = parent.getBoundingClientRect();
  const totalHeight = window.innerHeight;
  const totalWidth = window.innerWidth;

  // return true if the dropdown exceed the bottom of the page
  // eslint-disable-next-line no-unused-vars
  const isTooLow = totalHeight - (top + parent.clientHeight) < el.scrollHeight;
  // same as isTooLow with 64px offset for the player
  const isTooLowWithOffset = totalHeight - (top + parent.clientHeight) - 64 < el.scrollHeight;
  // return true si the dropdown exceed the right of the page
  const isTooRight = totalWidth - (left + parent.clientWidth) < el.clientWidth - parent.clientWidth;

  if (isTooLowWithOffset) {
    el.classList.add(binding.value.top);
    el.classList.remove(binding.value.bot);
  } else {
    el.classList.add(binding.value.bot);
    el.classList.remove(binding.value.top);
  }

  if (isTooRight) {
    el.classList.add(binding.value.left);
    el.classList.remove(binding.value.right);
  } else {
    el.classList.add(binding.value.right);
    el.classList.remove(binding.value.left);
  }
};
// Binding accept an object with this 4 values :
// {top: 'your-class-name',bot: 'your-class-name',left: 'your-class-name',right: 'your-class-name'}
export const dropdownOrientation = {
  inserted(el, binding) {
    calculDropdownOrientation(el, binding);
  },
  componentUpdated(el, binding) {
    calculDropdownOrientation(el, binding);
  }
};
