<template>
  <div class="list-knob">
    <div v-if="order && !isHover && !isLoading">
      {{ order }}
    </div>
    <div :class="getKnobClass" v-if="!order || (isHover && !isLoading)">
      <div v-for="(circle, i) in 6" :key="`circle-${i}`" class="knob--" />
    </div>
    <div class="loader" v-if="isLoading">
      <spinner color="grey" :size="18" />
    </div>
  </div>
</template>

<script>
import Spinner from '../loaders/spinnerWithoutProgress';

export default {
  components: {
    Spinner
  },
  props: {
    order: {
      type: Number,
      required: false,
      default: undefined
    },
    isHoverId: {
      type: String,
      required: false,
      default: null
    },
    isLoadingId: {
      type: String,
      required: false,
      default: null
    },
    trackId: {
      type: String,
      required: false,
      default: undefined
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    isHover() {
      return this.trackId && this.isHoverId === this.trackId;
    },
    isLoading() {
      return this.trackId && this.isLoadingId === this.trackId;
    },
    getKnobClass() {
      return `knob ${this.isDisabled && 'disabled'}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.list-knob {
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  .knob {
    height: 16px;
    width: 10px;
    display: grid;
    grid-template-columns: 4px 4px;
    grid-template-rows: 4px 4px 4px;
    grid-gap: 2px;
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
    &-- {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $color_neutral_40;
    }
  }
  .disabled {
    cursor: not-allowed;
  }
  .loader {
    height: 26px;
    width: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
