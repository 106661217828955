
<div class="remove-member-modal">
  <div class="remove-member-modal-body" v-if="isMe">{{ $t('leaveWorkspaceModal') }}</div>
  <div class="remove-member-modal-body" v-else>{{ $t('removeMemberModal') }}</div>
  <div class="remove-member-modal-buttons">
    <div class="remove-member-modal-buttons--">
      <submit-button
        btnStyle="tertiary"
        size="medium"
        :label="$t('cancel')"
        @submit="closeModal"
      />
    </div>
    <div class="remove-member-modal-buttons--">
      <submit-button
        btnStyle="secondary"
        danger
        size="medium"
        :label="isMe ? $t('leaveWorkspace') : $t('remove')"
        @submit="removeMember"
      />
    </div>
  </div>
</div>
