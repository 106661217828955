import { bus } from '@/utils/bus';
import get from 'lodash.get';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import { PAGINATOR_LIMIT, DONT_SHOW_LINKS_EXPLANATION_AGAIN } from '@/utils/constants';
import { SHARE_CONFIGURE, SHARE_ENABLE, PROJECT_SHARE } from '@/graphql/mutations/project';
import {
  LIBRARY_SUPPORT_SHARE,
  LIBRARY_FILE_SHARE,
  LIBRARY_ARTIST_SHARE
} from '@/graphql/mutations/library';
import { get as getRoute } from '@/router/routes';
import { PROJECT_LIST, PROJECT_SHARING } from '@/graphql/queries/project';
import linksExplanationModal from '@/containers/modals/linksExplanation';

export const configureShare = (that, param) => {
  const { access_rights, access_password, slug, shareId, custom_name } = param;
  return that.$apollo
    .mutate({
      mutation: SHARE_CONFIGURE,
      variables: {
        shareId,
        access_rights,
        access_password,
        custom_name,
        slug
      }
    })
    .catch(error => {
      const key = getGraphQlErrorCode(error);
      bus.$emit('showAlert', {
        message: { key },
        style: 'danger',
        delay: 5000,
        error: true
      });
    });
};

const handleIndividualLinkShare = (
  that,
  routeName,
  trackShareId,
  entityId,
  individualLinkList,
  entityName
) => {
  that.$store.commit('setCustomLinkJustCreated', individualLinkList);
  if (that.$route.name !== routeName) {
    that.$router.push(getRoute(routeName, { id: trackShareId || entityId }));
  }
  const hide = localStorage.getItem(DONT_SHOW_LINKS_EXPLANATION_AGAIN) === 'true';
  if (!hide) {
    bus.$emit('displayModal', {
      component: linksExplanationModal,
      isVisible: true,
      size: 'small',
      title: 'Links'
    });
  }
  bus.$emit('showAlert', {
    message: {
      key: 'linksCreatedSuccess',
      params: individualLinkList.length,
      args: { name: entityName }
    },
    delay: 4000,
    style: 'success',
    confetti: true
  });
};

const executeShare = (
  that,
  promiseArray,
  emailList,
  individualLinkList,
  entityName,
  entityId,
  key,
  routeName,
  isTrack
) => {
  Promise.all(promiseArray)
    .then(res => {
      bus.$emit('shareEntitySucess');
      bus.$emit('closeHidenModal');
      const trackShareId = isTrack ? res[0].data.LibraryFileShare.id : null;
      if (emailList && emailList.length > 0) {
        bus.$emit('showAlert', {
          message: {
            key,
            params: entityId.length,
            args: { name: entityName }
          },
          actionLabel: 'Manage sharing links',
          handleAction: () => {
            that.$router.push(getRoute(routeName, { id: trackShareId || entityId }));
            bus.$emit('closeAlert');
          },
          delay: 4000,
          style: 'success',
          confetti: true
        });
      }
      if (individualLinkList && individualLinkList.length > 0) {
        handleIndividualLinkShare(
          that,
          routeName,
          trackShareId,
          entityId,
          individualLinkList,
          entityName
        );
      }
    })
    .catch(error => {
      bus.$emit('closeHidenModal');
      const errorKey = getGraphQlErrorCode(error);
      bus.$emit('showAlert', {
        message: { errorKey, args: { nbLinks: get(that.$config, 'share.limit_by_share_hash') } },

        style: 'danger',
        delay: 5000,
        error: true
      });
    });
};

let projectFillShareByMail = null;
let projectShareByMailTimeOut = null;

let support_fillShareByMail = null;
let support_shareByMailTimeOut = null;

let tracks_fillShareByMail = null;
let tracks_shareByMailTimeOut = null;

let artist_fillShareByMail = null;
let artist_shareByMailTimeOut = null;

const showSendingAlert = (key, handleAction) => {
  bus.$emit('showAlert', {
    message: { key },
    actionLabel: 'Cancel',
    hideDismissible: true,
    // default delay animation for action countdown on alert is 1000ms(1s);
    // so there is 4000ms(3000+1000) before sending the mutation
    actionCountdown: 3000,
    handleAction,
    delay: 999999999
  });
};

export const ProjectShareMutation = (that, param, workspaceId) => {
  if (that.$isWorkspaceLockedVerification(that)) return;
  const {
    entityId,
    access_rights,
    access_password,
    message,
    reply_to,
    emailList,
    entityName,
    individualLinkList
  } = param; // eslint-disable-line

  const promiseArray = [];
  if (emailList && emailList.length > 0) {
    showSendingAlert('sendingProjectLoad', () => {
      clearTimeout(projectFillShareByMail);
      clearTimeout(projectShareByMailTimeOut);
      bus.$emit('toggleHideModal', false);
      bus.$emit('closeAlert');
    });
    projectFillShareByMail = setTimeout(() => {
      emailList.forEach(email => {
        const promise = that.$apollo.mutate({
          mutation: PROJECT_SHARE,
          variables: {
            workspaceId,
            projectId: entityId,
            access_rights,
            access_password,
            reply_to,
            email,
            custom_message: message
          },
          refetchQueries: [
            {
              query: PROJECT_SHARING,
              variables: {
                projectId: entityId
              }
            },
            {
              query: PROJECT_LIST,
              variables: {
                workspaceId,
                status: 'active',
                page: 1,
                limit: PAGINATOR_LIMIT
              }
            }
          ]
        });
        promiseArray.push(promise);
      });
    }, 4000);
  } else if (individualLinkList && individualLinkList.length > 0) {
    individualLinkList.forEach(custom_name => {
      const promise = that.$apollo.mutate({
        mutation: PROJECT_SHARE,
        variables: {
          workspaceId,
          projectId: entityId,
          access_rights,
          access_password,
          custom_name
        },
        refetchQueries: [
          {
            query: PROJECT_SHARING,
            variables: {
              projectId: entityId
            }
          },
          {
            query: PROJECT_LIST,
            variables: {
              workspaceId,
              status: 'active',
              page: 1,
              limit: PAGINATOR_LIMIT
            }
          }
        ]
      });
      promiseArray.push(promise);
    });
  } else {
    const promise = that.$apollo.mutate({
      mutation: PROJECT_SHARE,
      variables: {
        workspaceId,
        projectId: entityId,
        access_rights
      }
    });
    promiseArray.push(promise);
  }

  const executeShareShortcut = () => {
    executeShare(
      that,
      promiseArray,
      emailList,
      individualLinkList,
      entityName,
      entityId,
      'shareProjectSuccess',
      'projectSharings'
    );
  };
  if (projectFillShareByMail) {
    projectShareByMailTimeOut = setTimeout(() => {
      executeShareShortcut();
    }, 4000);
  } else {
    executeShareShortcut();
  }
};

export const SupportShareMutation = (that, param, workspaceId) => {
  if (that.$isWorkspaceLockedVerification(that)) return;
  const {
    entityId,
    access_rights,
    access_password,
    message,
    reply_to,
    emailList,
    entityName,
    individualLinkList
  } = param;

  const promiseArray = [];
  if (emailList && emailList.length > 0) {
    showSendingAlert('sendingAlbumLoad', () => {
      clearTimeout(support_fillShareByMail);
      clearTimeout(support_shareByMailTimeOut);
      bus.$emit('toggleHideModal', false);
      bus.$emit('closeAlert');
    });
    support_fillShareByMail = setTimeout(() => {
      emailList.forEach(email => {
        const promise = that.$apollo.mutate({
          mutation: LIBRARY_SUPPORT_SHARE,
          variables: {
            workspaceId,
            librarySupportId: entityId,
            access_rights,
            access_password,
            email,
            custom_message: message,
            reply_to
          }
        });
        promiseArray.push(promise);
      });
    }, 4000);
  } else if (individualLinkList && individualLinkList.length > 0) {
    individualLinkList.forEach(custom_name => {
      const promise = that.$apollo.mutate({
        mutation: LIBRARY_SUPPORT_SHARE,
        variables: {
          workspaceId,
          librarySupportId: entityId,
          access_rights,
          access_password,
          custom_name
        }
      });
      promiseArray.push(promise);
    });
  } else {
    const promise = that.$apollo.mutate({
      mutation: LIBRARY_SUPPORT_SHARE,
      variables: {
        workspaceId,
        librarySupportId: entityId,
        access_rights
      }
    });
    promiseArray.push(promise);
  }

  const executeShareShortcut = () => {
    executeShare(
      that,
      promiseArray,
      emailList,
      individualLinkList,
      entityName,
      entityId,
      'shareAlbumSuccess',
      'albumSharings'
    );
  };
  if (support_fillShareByMail) {
    support_shareByMailTimeOut = setTimeout(() => {
      executeShareShortcut();
    }, 4000);
  } else {
    executeShareShortcut();
  }
};

export const TracksShareMutation = (that, param, workspaceId) => {
  if (that.$isWorkspaceLockedVerification(that)) return;
  const {
    entityId,
    access_rights,
    access_password,
    message,
    reply_to,
    emailList,
    entityName,
    individualLinkList
  } = param;

  const promiseArray = [];
  if (emailList && emailList.length > 0) {
    const sendingAlertMsg = entityId.length > 1 ? 'sendingTracksLoad' : 'sendingTrackLoad';
    showSendingAlert(sendingAlertMsg, () => {
      clearTimeout(tracks_fillShareByMail);
      clearTimeout(tracks_shareByMailTimeOut);
      bus.$emit('toggleHideModal', false);
      bus.$emit('closeAlert');
    });
    tracks_fillShareByMail = setTimeout(() => {
      emailList.forEach(email => {
        const promise = that.$apollo.mutate({
          mutation: LIBRARY_FILE_SHARE,
          variables: {
            workspaceId,
            libraryFilesIds: entityId,
            access_rights,
            access_password,
            email,
            custom_message: message,
            reply_to
          }
        });
        promiseArray.push(promise);
      });
    }, 4000);
  } else if (individualLinkList && individualLinkList.length > 0) {
    individualLinkList.forEach(custom_name => {
      const promise = that.$apollo.mutate({
        mutation: LIBRARY_FILE_SHARE,
        variables: {
          workspaceId,
          libraryFilesIds: entityId,
          access_rights,
          access_password,
          custom_name
        }
      });
      promiseArray.push(promise);
    });
  } else {
    const promise = that.$apollo.mutate({
      mutation: LIBRARY_FILE_SHARE,
      variables: {
        workspaceId,
        libraryFilesIds: entityId,
        access_rights
      }
    });
    promiseArray.push(promise);
  }

  const executeShareShortcut = () => {
    executeShare(
      that,
      promiseArray,
      emailList,
      individualLinkList,
      entityName,
      entityId,
      'shareTrackSuccess',
      'sharedTracksSharings',
      true
    );
  };
  if (tracks_fillShareByMail) {
    tracks_shareByMailTimeOut = setTimeout(() => {
      executeShareShortcut();
    }, 4000);
  } else {
    executeShareShortcut();
  }
};

export const ArtistShareMutation = (that, param, workspaceId) => {
  if (that.$isWorkspaceLockedVerification(that)) return;
  const {
    entityId,
    access_rights,
    access_password,
    message,
    reply_to,
    emailList,
    entityName,
    individualLinkList
  } = param;

  const promiseArray = [];
  if (emailList && emailList.length > 0) {
    showSendingAlert('sendingArtistLoad', () => {
      clearTimeout(artist_fillShareByMail);
      clearTimeout(artist_shareByMailTimeOut);
      bus.$emit('toggleHideModal', false);
      bus.$emit('closeAlert');
    });
    artist_fillShareByMail = setTimeout(() => {
      emailList.forEach(email => {
        const promise = that.$apollo.mutate({
          mutation: LIBRARY_ARTIST_SHARE,
          variables: {
            workspaceId,
            libraryArtistId: entityId,
            access_rights,
            access_password,
            email,
            custom_message: message,
            reply_to
          }
        });
        promiseArray.push(promise);
      });
    }, 4000);
  } else if (individualLinkList && individualLinkList.length > 0) {
    individualLinkList.forEach(custom_name => {
      const promise = that.$apollo.mutate({
        mutation: LIBRARY_ARTIST_SHARE,
        variables: {
          workspaceId,
          libraryArtistId: entityId,
          access_rights,
          access_password,
          custom_name
        }
      });
      promiseArray.push(promise);
    });
  } else {
    const promise = that.$apollo.mutate({
      mutation: LIBRARY_ARTIST_SHARE,
      variables: {
        workspaceId,
        libraryArtistId: entityId,
        access_rights
      }
    });
    promiseArray.push(promise);
  }

  const executeShareShortcut = () => {
    executeShare(
      that,
      promiseArray,
      emailList,
      individualLinkList,
      entityName,
      entityId,
      'shareArtistSuccess',
      'artistSharings'
    );
  };
  if (artist_fillShareByMail) {
    artist_shareByMailTimeOut = setTimeout(() => {
      executeShareShortcut();
    }, 4000);
  } else {
    executeShareShortcut();
  }
};

export const switchEnableShare = (that, shareId, enable) =>
  that.$apollo
    .mutate({
      mutation: SHARE_ENABLE,
      variables: {
        shareId,
        enable
      }
    })
    .catch(error => {
      const key = getGraphQlErrorCode(error);
      bus.$emit('showAlert', {
        message: { key },
        style: 'danger',
        delay: 5000,
        error: true
      });
    });
