<template>
  <div :class="['attachment-name-container', isFullWidth && 'attachment-name-container-fullwidth']">
    <div
      v-if="displayRename"
      :class="['attachment-name-rename', isFullWidth && 'attachment-name-rename-fullwidth']"
    >
      <text-field
        inputType="text"
        :large="false"
        :value="attachmentName"
        :fieldModel.sync="attachmentName"
        selectOnLoad
        @click.native.stop
        @handleBlur="handleRenameBlur"
        @keydown.native="handleRenameEnter"
      />
    </div>
    <div v-else :class="['attachment-name', isFullWidth && 'attachment-name-fullwidth']">
      <div class="attachment-name--" :class="{ hover: isHover, fullwidth: isFullWidth }">
        <span
          :class="['ellipsis', isFullWidth && 'ellipsis-fullwidth']"
          :title="`${attachmentName}.${extension}`"
          >{{ attachmentName }}</span
        >
        <span v-if="extension">.{{ extension }}</span>
      </div>
      <div
        v-if="isHover"
        :class="['attachment-name-actions', isFullWidth && 'attachment-name-actions-fullwidth']"
      >
        <more-dropdown
          :items="actions"
          size="small"
          :itemId="attachmentId"
          @click.native.stop
          @setMoreDropdownState="setMoreDropdownState"
        />
      </div>
    </div>
    <div v-if="isFullWidth" class="attachment-type-fullwidth">
      {{ formattedType }}
    </div>
    <div v-if="isFullWidth" class="attachment-size">{{ size }}</div>
    <div v-else-if="!displayRename" class="attachment-type" :title="{ formattedType }">
      {{ formattedType }} - {{ size }}
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { VTooltip } from 'v-tooltip';

import { titleCase } from '@/utils/functions/global';
import MoveToAddTo from '@/containers/modals/moveToAddTo';
import { bus } from '@/utils/bus';

import TextField from '../inputs/textField';
import MoreDropdown from '../moreDropdown';

Vue.directive('tooltip', VTooltip);

export default {
  components: {
    TextField,
    MoreDropdown
  },
  props: {
    name: {
      type: String,
      required: true
    },
    extension: {
      type: String,
      required: false,
      default: null
    },
    type: {
      type: String,
      required: false,
      default: null
    },
    size: {
      type: String,
      required: false,
      default: null
    },
    isFullWidth: {
      type: Boolean,
      required: false,
      default: false
    },
    attachmentId: {
      type: String,
      required: false,
      default: null
    },
    hoverId: {
      type: String,
      required: false,
      default: null
    },
    rowExpanded: {
      type: String,
      required: false,
      default: null
    },
    downloadUrl: {
      type: String,
      required: false,
      default: null
    },
    labels: {
      type: Object,
      required: false,
      default: () => ({
        rename: 'Rename',
        addTo: 'Add to...',
        download: 'Download',
        delete: 'Delete'
      })
    }
  },
  data() {
    return {
      attachmentName: this.name,
      nameMemory: this.name,
      displayRename: false,
      actions: [
        {
          icon: 'ri-edit-line',
          action: this.openChangeNameInput,
          name: this.labels.rename
        },
        {
          icon: 'ri-folder-transfer-line',
          action: this.moveAttachment,
          name: this.labels.addTo
        },

        {
          icon: 'ri-download-line',
          action: this.downloadAttachment,
          name: this.labels.download
        },
        {
          icon: 'ri-delete-bin-line',
          action: this.deleteAttachment,
          danger: true,
          name: this.labels.delete
        }
      ]
    };
  },
  computed: {
    isHover() {
      const { attachmentId, hoverId, rowExpanded } = this;
      return attachmentId && (attachmentId === hoverId || attachmentId === rowExpanded);
    },
    formattedType() {
      if (this.type === 'music') {
        // change wording in case of kind music
        return titleCase('audio');
      }
      return titleCase(this.type);
    }
  },
  methods: {
    openChangeNameInput() {
      setTimeout(() => {
        this.displayRename = true;
        this.$store.commit('changeIsRenamingProjectAttachment', true);
      });
    },
    moveAttachment() {
      if (this.$isWorkspaceLockedVerification(this)) return;
      bus.$emit('displayModal', {
        component: MoveToAddTo,
        hideHeader: true,
        isVisible: true,
        size: 'small',
        onSubmit: this.closeShareModal,
        props: [
          { name: 'attachmentId', value: this.attachmentId },
          { name: 'hasPrevious', value: false }
        ]
      });
    },

    handleRenameBlur() {
      this.attachmentName = this.attachmentName.trim();
      this.$store.commit('changeIsRenamingProjectAttachment', false);
      if (this.attachmentName === this.nameMemory) {
        this.displayRename = false;
        return;
      }
      if (!this.attachmentName) {
        this.attachmentName = this.nameMemory;
        this.displayRename = false;
        return;
      }
      this.nameMemory = this.attachmentName;
      this.$emit('renameAttachment', {
        newName: this.attachmentName,
        attachmentId: this.attachmentId
      });
      this.displayRename = false;
    },
    handleRenameEnter(e) {
      if (e.keyCode === 13) {
        this.handleRenameBlur();
      }
      if (e.key === 'Escape') {
        this.displayRename = false;
      }
    },
    downloadAttachment() {
      this.$emit('downloadAttachment', this.downloadUrl);
    },
    deleteAttachment() {
      this.$emit('deleteAttachment', this.attachmentId);
    },
    setMoreDropdownState({ id, isExpanded }) {
      this.$emit('setMoreDropdownState', { id, isExpanded });
    }
  }
};
</script>

<style lang="scss" scoped>
.attachment-name {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-fullwidth {
    display: inline;
    position: relative;
  }

  &-container {
    width: 100%;
    height: 100%;
    position: relative;
    &-fullwidth {
      display: inline-grid;
      grid-template-columns: minmax(140px, 2fr) repeat(2, minmax(100px, 265px));
      align-items: center;
    }
  }
  &-- {
    // the disponible width in the list, we can make this modular in the futur if needed
    max-width: 256px;
    display: flex;
    &.fullwidth {
      max-width: unset;
      margin-right: 40px;
    }
    .ellipsis {
      max-width: 190px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &-fullwidth {
        max-width: unset;
      }
    }
  }
  &-rename {
    &-fullwidth div {
      max-width: unset;
      margin-right: 20px;
    }
  }

  &-actions {
    //display: flex;
    position: absolute;
    right: 0;
    bottom: 0;

    &-fullwidth {
      margin-right: 20px;
      bottom: -5px;
    }

    .action {
      margin-right: 4px;
    }
  }
}

.attachment-name-container {
  .attachment-name {
    .attachment-name--.hover {
      // 232 - 32 (wich is the width of more dropdown) - 4
      max-width: 196px;
      margin-right: 4px;
    }
    .attachment-name--.fullwidth.hover {
      max-width: unset;
      margin-right: 70px;
    }
  }
  .attachment-type {
    font-size: 12px;
    color: $color_neutral_80;
    &-fullwidth {
      font-size: 14px;
    }
  }

  .attachment-size {
    font-size: 14px;
  }
}
</style>
