
<div class="container" :class="shareDesactivated ? 'share-desactivated' : ''">
  <div
    class="sharing-icon"
    v-tooltip="{ content: iconStyle.tooltip, delay: { show: 500, hide: 0 } }"
  >
    <div v-if="isNew" class="star-new"><img src="/images/star.svg" alt="new link" /></div>
    <i :class="iconStyle.class" />
  </div>
  <div class="sharing-info">
    <div class="sharing-name" :title="shareName">{{ shareName }}</div>
    <div class="sharing-details" v-if="displayDetails">
      <i v-if="hasPassword" class="ri-lock-2-fill icon" />
      <div v-if="expireIn && hasPassword" class="separator" />
      <v-popover v-if="expireIn" trigger="hover" container="#expire-in-container" class="popover">
        <!-- This will be the popover target (for the events and position) -->
        <div id="expire-in-container" class="tooltip-target b3">
          <div class="expire-in">
            <i class="ri-time-fill icon"></i>
            <div class="expire-in-txt">
              {{ $tc('day', this.expireIn) }}
            </div>
          </div>
        </div>
        <!-- This will be the content of the popover -->
        <template slot="popover">
          <div class="upgrade-popover">
            <div class="upgrade-popover-text">
              {{ $tc('expireIn', this.expireIn) }}
            </div>
            <div class="upgrade-popover-text">Want your links to stay active forever?</div>
            <submit-button
              v-if="isAdmin"
              class="upgrade-popover-btn"
              @click.native.stop
              btnStyle="primary"
              size="small"
              :label="$t('upgrade')"
              @submit="goToOffersSettings"
            />
            <div v-else class="upgrade-popover-text">
              Contact a workspace admin to upgrade your plan
            </div>
          </div>
        </template>
      </v-popover>
    </div>
  </div>
</div>
