<template>
  <div v-if="me && roles" class="members">
    <div class="members-title">
      <div class="members-title--">
        {{ $t('members') }}
      </div>
      <submit-button
        :label="$t('invite')"
        iconClass="ri-user-add-line"
        @submit="openAddMembersModal"
      />
    </div>
    <div class="members-list">
      <div class="members-list-header">
        <div class="members-list-header-left">{{ $tc('user', members.length) }}</div>
        <div class="members-list-header-right">{{ $t('role') }}</div>
      </div>
      <div class="members-list-body">
        <div
          class="members-list-body-item"
          v-for="(member, index) in members"
          :key="`usr-${index}`"
        >
          <member-info class="member-info" :member="member" />
          <div
            class="members-list-body-item-center"
            @click="setCurrentInvitationId(member.invitationId)"
          >
            <dropdown-legacy
              v-if="member.status === 'PENDING'"
              :buttonLabel="$t('pending')"
              :options="invitationsDropdown"
            />
            <badge v-if="member.id === me.id" label="You" :radius="50" />
          </div>
          <div class="members-list-body-item-right">
            <div v-if="member.status === 'PENDING'">-</div>
            <div v-else-if="member.id === currentWorkspace.owner_id || myRoleId === '2'">
              {{ getMembersRole(member) }}
            </div>
            <div v-else>
              <roles-dropdown
                ref="dropdown1"
                :roles="roleList"
                :userRole="roleList.find(role => role.value === member.roleId)"
                :userId="member.id"
                @setRole="changeUserRole"
              />
            </div>
          </div>
          <div class="members-list-body-item-actions">
            <more-dropdown
              v-if="
                member.id !== currentWorkspace.owner_id &&
                (myRoleId === '1' || (myRoleId === '2' && member.id === me.id)) &&
                member.status !== 'PENDING'
              "
              :items="getRoleDropdownOption(member.id)"
              :forceDirection="{ left: true }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TransferOwnershipModal from '@/containers/modals/transferOwnership';
import { get as getRoute } from '@/router/routes';
import { bus } from '@/utils/bus';
import { getGraphQlErrorCode } from '@/utils/functions/global';
import { GET_ROLES } from '@/graphql/queries/workspace';
import { WORKSPACE_REMOVE_MEMBER, WORKSPACE_MEMBER_LEAVE } from '@/graphql/mutations/workspace';
import { ME, WORKSPACE } from '@/graphql/queries/user';
import { CHANGE_MEMBER_ROLE } from '@/graphql/mutations/roles';
import { displayAddMembersModal } from '@/utils/actions/modals';
import { setWorkspaceIdInStorage } from '@/utils/storage';
import RemoveMemberModal from '@/containers/modals/removeMember';
import MembersLimitModal from '@/containers/modals/membersLimit';
import NoPermissionModal from '@/containers/modals/noPermission';
import {
  SEND_WORKSPACE_BULK_INVITATION,
  DELETE_INVITATION,
  RESEND_INVITATION
} from '@/graphql/mutations/invitation';
import { getMemberRole } from '@/utils/functions/subscription';
import { getUserInitials } from '@/utils/functions/user';

export default {
  data() {
    return {
      invitationsDropdown: [
        {
          label: this.$t('resendInvitation'),
          value: 'resend',
          danger: false,
          action: this.resendInvitation
        },
        {
          label: this.$t('cancelInvitation'),
          value: 'cancel',
          danger: true,
          action: this.deleteInvitation
        }
      ],
      currentInvitationId: '',
      getRoute,
      currentMemberId: null
    };
  },
  apollo: {
    me: {
      query: ME
    },
    roles: {
      query: GET_ROLES
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    members() {
      const invitations = this.currentWorkspace.invitations
        ? this.currentWorkspace.invitations
            .filter(invitation => invitation.status === 'PENDING')
            .map(invitation => ({
              invitationId: invitation.id,
              email: invitation.email,
              pictureUrl: null,
              roleId: invitation.role.id,
              status: invitation.status,
              id: null,
              initial: this.getInitialMember(null, null, invitation.email)
            }))
        : [];
      const users = this.currentWorkspace.members
        ? this.currentWorkspace.members.map(member => ({
            firstname: member.firstname,
            lastname: member.lastname,
            fullname: `${member.firstname} ${member.lastname}`,
            email: member.email,
            pictureUrl: member.profile_picture ? member.profile_picture.url : null,
            roleId: member.role.id,
            status: 'OK',
            invitationId: null,
            id: member.user_id,
            initial: this.getInitialMember(member.firstname, member.lastname)
          }))
        : [];
      const members = invitations.concat(users);
      const pendingFirst = key => a => {
        if (a[key] === 'PENDING') {
          return -1;
        }
        return 1;
      };
      return members.sort(pendingFirst('status'));
    },
    roleList() {
      const arr = this.roles.map(role => ({
        label: role.name,
        value: role.id,
        description: this.$t(`roles.${role.id}.description`)
      }));
      if (this.me.id === this.currentWorkspace.owner_id) {
        arr.unshift({
          label: 'Owner',
          value: -1,
          description: this.$t('roles.owner.description')
        });
      }
      return arr;
    },
    roleListInvit() {
      return this.roles.map(role => ({
        label: role.name,
        value: role.id,
        description: this.$t(`roles.${role.id}.description`)
      }));
    },
    disabled() {
      this.$v.$touch();
      return this.$v.invitations.$error;
    },
    myRoleId() {
      return this.currentWorkspace.members.find(m => m.user_id === this.me.id).role.id;
    },
    membersLimitModal() {
      let { components } = this.$options;
      components = {
        ...components,
        MembersLimitModal
      };

      return {
        title: this.$t('membersLimit'),
        size: 'small',
        isVisible: false,
        component: components.MembersLimitModal,
        props: [{ name: 'isEditor', value: this.memberRole === 'Editor' }]
      };
    },
    noPermissionModal() {
      let { components } = this.$options;
      components = {
        ...components,
        NoPermissionModal
      };
      return {
        title: this.$t('addMembers'),
        size: 'medium',
        isVisible: false,
        component: components.NoPermissionModal
      };
    },
    removeMemberModal() {
      let { components } = this.$options;
      components = {
        ...components,
        RemoveMemberModal
      };
      return {
        title: this.$t('removeMember'),
        size: 'medium',
        isVisible: false,
        component: components.RemoveMemberModal,
        onSubmit: this.handleWorkspaceRemoveMember
      };
    },
    memberRole() {
      return getMemberRole(this.currentWorkspace, this.me);
    }
  },
  methods: {
    getMembersRole(member) {
      if (member.id === this.currentWorkspace.owner_id) {
        return 'Owner';
      }
      return this.roleList.find(role => role.value === member.roleId).label;
    },
    getRoleDropdownOption(memberId) {
      const isMe = this.me && memberId === this.me.id;
      return [
        {
          name: isMe ? this.$t('leaveWorkspace') : this.$t('removeMember'),
          danger: true,
          action: () => {
            this.currentMemberId = memberId;
            let { components } = this.$options;
            components = {
              ...components,
              RemoveMemberModal
            };
            bus.$emit('displayModal', {
              title: isMe ? this.$t('leaveWorkspace') : this.$t('removeMember'),
              size: 'medium',
              isVisible: true,
              component: components.RemoveMemberModal,
              onSubmit: isMe ? this.handleLeaveWorkspace : this.handleWorkspaceRemoveMember,
              props: [{ name: 'isMe', value: isMe }]
            });
          }
        }
      ];
    },
    getInitialMember(firstname, lastname, email) {
      return getUserInitials({ firstname, lastname, email });
    },
    changeUserRole(params) {
      const memberUserId = params.userId;
      if (params.role.value === -1) {
        const newOwner = this.members.find(el => el.id === memberUserId);
        const newOwnerInfo = {
          id: memberUserId,
          newOwner: newOwner.fullname,
          newOwnerMail: newOwner.email,
          workspaceName: this.currentWorkspace.name,
          workspaceId: this.currentWorkspace.id
        };
        bus.$emit('displayModal', {
          component: TransferOwnershipModal,
          isVisible: true,
          size: 'medium',
          onSubmit: () => {},
          title: this.$t('transferOwnership'),
          props: [{ name: 'info', value: newOwnerInfo }]
        });
        return;
      }
      this.$apollo
        .mutate({
          mutation: CHANGE_MEMBER_ROLE,
          variables: {
            workspaceId: this.currentWorkspace.id,
            memberUserId,
            roleId: parseInt(params.role.value, 10)
          }
        })
        .then(() => {
          bus.$emit('showAlert', {
            message: { key: 'changeRoleSuccess' },
            style: 'success',
            delay: 5000
          });
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    openAddMembersModal() {
      displayAddMembersModal(this, this.myRoleId);
    },
    submitInvitations(invitations) {
      this.submitStatus = 'PENDING';
      const invitationsToSend = invitations.map(el => ({
        email: el.email,
        role_id: parseInt(el.roleId, 10)
      }));
      this.$apollo
        .mutate({
          mutation: SEND_WORKSPACE_BULK_INVITATION,
          variables: {
            workspaceId: this.currentWorkspace.id,
            invitations: invitationsToSend
          }
        })
        .then(() => {
          this.submitStatus = 'OK';
          this.$apollo.queries.me.refetch().then(() => {
            bus.$emit('showAlert', {
              message: { key: 'invitationSuccess', params: invitations.length },
              style: 'success',
              delay: 5000
            });
          });
        })
        .catch(error => {
          this.submitStatus = 'OK';
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    setCurrentInvitationId(invitationId) {
      this.currentInvitationId = invitationId;
    },
    deleteInvitation() {
      this.$apollo
        .mutate({
          mutation: DELETE_INVITATION,
          variables: {
            invitationId: this.currentInvitationId
          },
          refetchQueries: [
            {
              query: WORKSPACE,
              variables: {
                workspaceId: this.currentWorkspace.id
              }
            }
          ]
        })
        .then(() => {
          bus.$emit('showAlert', {
            message: { key: 'cancelInvitationSuccess' },
            style: 'success',
            delay: 5000
          });
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    resendInvitation() {
      this.$apollo
        .mutate({
          mutation: RESEND_INVITATION,
          variables: {
            invitationId: this.currentInvitationId
          }
        })
        .then(() => {
          bus.$emit('showAlert', {
            message: { key: 'resendInvitationSuccess' },
            style: 'success',
            delay: 5000
          });
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    handleWorkspaceRemoveMember() {
      this.$apollo
        .mutate({
          mutation: WORKSPACE_REMOVE_MEMBER,
          variables: {
            workspaceId: this.currentWorkspace.id,
            memberUserId: this.currentMemberId
          },
          refetchQueries: [{ query: ME }]
        })
        .then(() => {
          bus.$emit('showAlert', {
            message: { key: 'removeMemberSucess' },
            style: 'success',
            delay: 5000
          });
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    handleLeaveWorkspace() {
      this.$apollo
        .mutate({
          mutation: WORKSPACE_MEMBER_LEAVE,
          variables: {
            workspaceId: this.currentWorkspace.id
          },
          refetchQueries: [{ query: ME }]
        })
        .then(() => {
          setWorkspaceIdInStorage(this.me.workspaces[0].id);
          if (this.$router.currentRoute.name !== 'trackList') {
            this.$router.push(getRoute('trackList'));
          }
        })
        .catch(error => {
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.members {
  width: 100%;
  color: $color_neutral_100;

  &-title-- {
    @include heading-6;
    color: $color_neutral_100;
  }
  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 24px 0;
  }

  &-list {
    &-header {
      display: flex;
      box-sizing: border-box;
      border-bottom: 1px solid $color_neutral_30;
      padding: 0 40px 0 8px;
      color: $color_neutral_100;
      font-size: 14px;
      height: 40px;
      font-weight: 600;
      &-left,
      &-right {
        height: 100%;
        display: flex;
        align-items: center;
      }
      &-left {
        flex-grow: 1;
        border-right: 1px solid $color_neutral_30;
        margin-right: 8px;
      }

      &-right {
        width: 96px;
      }
    }

    &-body {
      &-item {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 0 0 0 8px;
        border-bottom: 1px dotted $color_neutral_40;
        height: 52px;

        &-left {
          display: flex;
          width: 365px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          &-avatar {
            margin: 0 24px 0 0;
          }

          &-infos {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 40px 0 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &-fullname {
              line-height: 150%;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            &-email {
              font-size: 12px;
              color: $color_neutral_60;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              line-height: normal;
            }
          }
        }

        &-center {
          width: 140px;
          display: flex;
          justify-content: center;
          justify-content: flex-end;
          box-sizing: border-box;
          padding-right: 24px;
        }

        &-right {
          width: 96px;
          display: flex;
          justify-content: flex-start;
          font-size: 14px;
        }

        &-actions {
          width: 40px;
        }
      }
    }
  }

  &-modal {
    position: fixed;
    z-index: map-get($z-index, modal);
    top: 0;
    left: 0;

    &-body {
      &-content {
        padding: 32px 40px 24px 40px;
        line-height: 150%;

        &-title {
          font-size: 32px;
          padding: 0 32px 24px 0;
          text-align: center;
        }

        &-helper {
          width: 458px;
          font-size: 14px;
          line-height: 150%;
          margin: 0 auto 40px;
          padding: 0 32px 0 0;
        }

        &-form {
          &-label {
            display: flex;

            &-email {
              width: 356px;
            }

            &-role {
              width: 140px;
            }
          }

          &-email {
            display: flex;
            align-items: center;
            margin: 0 0 24px 0;

            &-textfield {
              position: relative;
              width: 430px;
              margin: 0 16px 0 0;
            }

            &-dropdown {
              width: 140px;
              margin: 0 4px 0 0;
            }
          }

          &-add {
            margin: 32px 0 0;
          }

          &-btn {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }

    &-footer {
      &-title {
        color: $color_neutral_60;
      }
    }

    .small,
    .regular {
      width: auto;
    }
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: $color_neutral_40;
}
</style>

<style lang="scss">
.members-list-body-item-center {
  .dropdown {
    .dropdown-button {
      padding-right: 0;
      font-weight: normal;
    }
  }
}
.members-list-body-item-right {
  .dropdown {
    .dropdown-button-arrow {
      font-size: 16px;
      margin: 2px 0 0 2px;
    }
  }
}
.members-list-body-item {
  .member.member-info {
    width: unset;
    flex-grow: 1;
  }
}
</style>
