<template>
  <div class="shared-submission" v-if="ShareDetails">
    <div class="shared-submission-tabs">
      <tabs :tabs="tabs" :activeTab="activeTab" />
    </div>
    <router-view :entityIds="entityIds" :shareHash="shareHash"></router-view>
  </div>
</template>

<script>
import get from 'lodash.get';
import { mapGetters } from 'vuex';
import { get as getRoute } from '@/router/routes';
import { SHARE_DETAILS } from '@/graphql/queries/project';
import { generateDocumentTitle } from '@/utils/functions/global';

export default {
  apollo: {
    ShareDetails: {
      query: SHARE_DETAILS,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          shareId: this.$route.params.id
        };
      },
      result(res) {
        this.entityIds = get(res, 'data.ShareDetails.entities.ids', []);
        this.shareHash = get(res, 'data.ShareDetails.share_hash', '');
        this.shareName = get(res, 'data.ShareDetails.share_name', '');
        document.title = generateDocumentTitle([this.shareName]);
      }
    }
  },
  data() {
    return {
      entityIds: [],
      shareHash: undefined
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'playlist', 'newUpload']),
    tabs() {
      const { id } = this.$route.params;
      return [
        {
          title: this.$t('submission'),
          link: getRoute('sharedSubmissionDetails', { id }),
          name: 'sharedSubmissionDetails'
        },
        {
          title: this.$t('sharingActivity'),
          link: getRoute('sharedSubmissionActivity', { id }),
          name: 'sharedSubmissionActivity'
        }
      ];
    },
    activeTab() {
      const active = this.tabs.find(tab => tab.name === this.$route.name);
      return this.tabs.indexOf(active);
    }
  }
};
</script>

<style lang="scss" scoped>
.shared-submission {
  padding: 40px 0 0 40px;
  @include body-1;
  margin: 0 0 80px 0;
  &-tabs {
    width: calc(100% - 40px);
    position: sticky;
    top: 0;
    background-color: $color_neutral_0;
    z-index: 3;
  }
}
</style>
