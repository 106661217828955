<template>
  <div v-if="isVisible" class="modal" :class="{ fixed: isFixed }">
    <div :class="`modal-content ${size}`">
      <div class="modal-content-header" v-if="!hideHeader">
        <div class="titles-container">
          <div class="modal-content-header-title">{{ title }}</div>
          <div class="modal-content-header-secondTitle" v-if="secondTitle">
            {{ secondTitle }}
          </div>
        </div>
        <icon-button btnStyle="tertiary" :size="crossSize" icon="ri-close-fill" @submit="close" />
        <!-- <i @click="close" class="ri-close-line" /> -->
      </div>
      <div class="modal-content-body">
        <slot name="body"></slot>
      </div>
    </div>
    <div @click="close" class="modal-bkg" />
  </div>
</template>

<script>
import IconButton from '../iconButton';

export default {
  components: {
    IconButton
  },
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      required: false,
      default: 'medium'
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    isFixed: {
      type: Boolean,
      required: false,
      default: false
    },
    crossSize: {
      type: String,
      required: false,
      default: 'medium'
    },
    secondTitle: {
      type: String,
      required: false,
      default: null
    },
    noEscape: {
      type: Boolean,
      required: false,
      default: false
    },
    hideHeader: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    handleKeyDown(e) {
      if (e.keyCode === 27 && !this.noEscape) {
        this.$emit('close');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;

  &-content {
    position: relative;
    z-index: 2;
    background-color: $color_neutral_0;
    border-radius: 4px;
    @include shadow-up-03;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 56px;
      border-bottom: 1px solid $color_neutral_30;
      padding: 0 32px;
      .titles-container {
        max-width: calc(100% - 48px);
      }
      &-title {
        @include heading-6;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 24px;
      }

      &-secondTitle {
        margin-top: 2px;
        font-size: 12px;
        color: $color_neutral_60;
      }
    }
  }

  &-bkg {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100vh;
    background: $color_neutral_100;
    opacity: 0.6;
  }
}

.fixed {
  align-items: end;
  .modal-content {
    margin-top: 56px;
  }
}

.ri-close-line {
  cursor: pointer;
}

.modal-content.small {
  width: 378px;
}

.modal-content.medium {
  width: 578px;
}

.modal-content.large {
  width: 778px;
}
</style>
