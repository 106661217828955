
<div>
  <div class="image-browser" :class="imgStyle">
    <div class="image-browser-icon" :class="iconStyle">
      <i v-if="!imgUrl" :class="icon" class="image-browser-icon-imgless" />
      <div v-if="imgUrl" class="image-browser-icon--">
        <div class="image-browser-icon--upload">
          <i :class="icon" />
          <input
            ref="input"
            type="file"
            accept="image/x-png, image/gif, image/jpeg, image/png"
            name="userFile"
            @change="handleFileChange"
            class="image-browser-icon--input"
            id="userFile"
          />
        </div>
        <div
          v-if="removeButton"
          class="image-browser-icon--remove"
          @click="
            () => {
              $emit('removeImage');
            }
          "
        >
          <i class="ri-delete-bin-line" />
        </div>
      </div>
      <input
        v-if="!imgUrl"
        ref="input"
        type="file"
        accept="image/x-png, image/gif, image/jpeg, image/png"
        name="userFile"
        @change="handleFileChange"
        class="image-browser-icon-input"
        :class="imgStyle"
        id="userFile"
      />
    </div>
    <label v-if="label" class="image-browser-label" for="userFile">{{ label }}</label>
    <img
      v-if="imgUrl"
      :src="imgUrl"
      alt="Avatar image"
      class="image-browser-img"
      :class="imgStyle"
    />
  </div>
</div>
