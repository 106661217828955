<template>
  <div>
    <div class="image-browser" :class="imgStyle">
      <div class="image-browser-icon" :class="iconStyle">
        <i v-if="!imgUrl" :class="icon" class="image-browser-icon-imgless" />
        <div v-if="imgUrl" class="image-browser-icon--">
          <div class="image-browser-icon--upload">
            <i :class="icon" />
            <input
              ref="input"
              type="file"
              accept="image/x-png, image/gif, image/jpeg, image/png"
              name="userFile"
              @change="handleFileChange"
              class="image-browser-icon--input"
              id="userFile"
            />
          </div>
          <div
            v-if="removeButton"
            class="image-browser-icon--remove"
            @click="
              () => {
                $emit('removeImage');
              }
            "
          >
            <i class="ri-delete-bin-line" />
          </div>
        </div>
        <input
          v-if="!imgUrl"
          ref="input"
          type="file"
          accept="image/x-png, image/gif, image/jpeg, image/png"
          name="userFile"
          @change="handleFileChange"
          class="image-browser-icon-input"
          :class="imgStyle"
          id="userFile"
        />
      </div>
      <label v-if="label" class="image-browser-label" for="userFile">{{ label }}</label>
      <img
        v-if="imgUrl"
        :src="imgUrl"
        alt="Avatar image"
        class="image-browser-img"
        :class="imgStyle"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imgUrl: {
      type: String,
      required: false,
      default: null
    },
    pictureSize: {
      type: String,
      required: false,
      default: 'extraLarge'
    },
    square: {
      type: Boolean,
      required: false,
      default: false
    },
    icon: {
      type: String,
      required: false,
      default: 'ri-upload-line'
    },
    removeButton: {
      type: Boolean,
      required: false
    },
    noObjectUrl: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false
    }
  },
  methods: {
    handleFileChange(e) {
      const file = e.target.files[0];
      if (file) {
        const f = this.noObjectUrl ? file : URL.createObjectURL(file);
        this.$emit('changeImgUrl', f, file);
      }
      // To prevent when the image does not change
      this.$refs.input.value = '';
    }
  },
  computed: {
    iconStyle() {
      const hover = this.imgUrl ? 'image-browser-hover' : 'image-browser-no-hover';
      const square = this.square ? 'square' : '';
      const size = `img-browser-${this.pictureSize}`;
      return [size, square, hover];
    },
    imgStyle() {
      const square = this.square ? 'square' : '';
      const size = `img-browser-${this.pictureSize}`;
      return [size, square];
    }
  }
};
</script>

<style lang="scss" scoped>
.image-browser {
  width: 140px;
  height: 140px;
  position: relative;
  &-img {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    border: 1px solid $color_neutral_30;
  }
  &-label {
    position: absolute;
    right: -83px;
    top: 25px;
    font-size: 12px;
    font-weight: 600;
    color: $color_primary_100;
    cursor: pointer;
  }
  &-icon.image-browser-hover {
    align-items: flex-end;
  }
  &-icon {
    position: absolute;
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &-- {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-bottom: 16px;
      &input {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        &::-webkit-file-upload-button {
          cursor: pointer;
        }
      }
      &upload,
      &remove {
        width: 32px;
        height: 32px;
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        cursor: pointer;
      }
      &remove {
        color: $color_danger_100;
        margin-left: 8px;
      }
      &upload {
        position: relative;
      }
    }
    &-imgless {
      color: $color_neutral_0;
    }
    &-input {
      opacity: 0;
      position: absolute;
      cursor: pointer;
      width: 140px;
      height: 140px;
      outline: none;
      border-radius: 50%;
      &::-webkit-file-upload-button {
        cursor: pointer;
      }
    }
  }

  &-remove {
    position: absolute;
    bottom: -32px;
    height: 40px;
    font-weight: 600;
    font-size: 14px;
    color: $color_danger_100;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;

    &-icon {
      font-size: 16px;
      margin: 0 4px 0 0;
    }
  }

  &-hover {
    background: $color_overlay_60;
    opacity: 0;
    transition: all 200ms linear;

    &:hover {
      opacity: 1;
    }
  }

  &-no-hover {
    background-color: $color_neutral_100;
  }
}

.image-browser.img-browser-small {
  .image-browser-icon-imgless {
    color: $color_neutral_60;
  }
}

.img-browser-small {
  width: 64px;
  height: 64px;
  font-size: 20px;
  color: $color_neutral_60;
  background-color: $color_neutral_10;
  box-sizing: border-box;
  border: 1px solid $color_neutral_30;

  &-img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 1px solid $color_neutral_30;
  }
}

.img-browser-large {
  width: 104px;
  height: 104px;
  font-size: 24px;
  box-sizing: border-box;
}
.img-browser-extraLarge {
  width: 140px;
  height: 140px;
  font-size: 48px;
  box-sizing: border-box;
}
.img-browser-superLarge {
  width: 208px;
  height: 208px;
  font-size: 38px;
  box-sizing: border-box;
}
.img-browser-coverSize {
  width: 227px;
  height: 227px;
  font-size: 38px;
  box-sizing: border-box;
}
.square {
  border-radius: 2px;
}
</style>
