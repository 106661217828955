<template>
  <div class="upload-files-modal">
    <div class="upload-files-modal-title">{{ $t('uploadTracks') }}</div>
    <div class="upload-files-modal-body">{{ $t('noFilesProject') }}</div>
    <div class="upload-files-modal-buttons">
      <submit-button
        :label="$t('importFiles')"
        iconClass="ri-upload-cloud-line"
        browse
        multiple
        :accept="allowedFileTypes"
        @submit="submit"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { getAllowedFileTypes } from '@/utils/functions/audio';

export default {
  props: {
    onSubmit: {
      type: Function,
      required: false,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['uploadIsInprogress', 'currentWorkspace']),
    allowedFileTypes() {
      return getAllowedFileTypes(this.$config);
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    submit(files) {
      this.onSubmit(files);
      this.closeModal();
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-files-modal {
  &-title {
    @include heading-3;
    margin: 32px auto 24px;
    text-align: center;
  }
  &-body {
    padding: 0 40px 32px;
    line-height: 150%;
  }
  &-buttons {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $color_neutral_30;
    padding: 8px 32px;
  }
}
</style>
