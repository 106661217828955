
<div class="information-banner">
  <div class="icon-info"><i class="ri-information-fill"></i></div>
  <div class="text">
    {{ $t('emailBanner.core1') }} <span class="mail">{{ primaryEmail.email }}</span>
    {{ $t('emailBanner.core2') }}
    <span class="resend" @click="resendMail">{{ $t('emailBanner.sendAgain') }}</span>
  </div>
  <icon-button
    class="icon-close-mail"
    btnStyle="tertiary"
    icon="ri-close-fill"
    size="small"
    @submit="hide"
  />
</div>
