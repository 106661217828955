export const UPLOAD_STATUS = {
  CANCELED: -3,
  WARNING: -2,
  ERROR: -1,
  WAITING: 1,
  UPLOADING: 2,
  ANALYZING: 3,
  DONE: 4
};

export const UPLOAD_TYPE = {
  TRACK: 'track',
  ATTACHMENT: 'attachment'
};

const setNewUpload = (s, obj) => {
  const state = s;
  if (obj.type === UPLOAD_TYPE.TRACK) {
    const newTrackUpload = { ...state.trackUpload };
    newTrackUpload[obj.id] = obj;
    delete newTrackUpload[obj.id].type;
    newTrackUpload[obj.id].status = UPLOAD_STATUS.WAITING;
    state.trackUpload = newTrackUpload;
    state.progress = 0;
    state.libraryFileId = null;
    state.message = null;
  }
  if (obj.type === UPLOAD_TYPE.ATTACHMENT) {
    const newAttachmentUpload = { ...state.attachmentUpload };
    newAttachmentUpload[obj.id] = obj;
    delete newAttachmentUpload[obj.id].type;
    state.attachmentUpload = newAttachmentUpload;
  }
};

const setUploadProgress = (s, obj) => {
  const state = s;
  if (obj.type === UPLOAD_TYPE.TRACK) {
    const newTrackUpload = { ...state.trackUpload };
    newTrackUpload[obj.id].progress = obj.progress;
    state.trackUpload = newTrackUpload;
  }
  if (obj.type === UPLOAD_TYPE.ATTACHMENT) {
    const newAttachmentUpload = { ...state.attachmentUpload };
    newAttachmentUpload[obj.id].progress = obj.progress;
    state.attachmentUpload = newAttachmentUpload;
  }
};

const updateStatus = (s, obj, status) => {
  const state = s;
  if (obj.type === UPLOAD_TYPE.TRACK) {
    const newTrackUpload = { ...state.trackUpload };
    newTrackUpload[obj.id].status = status;
    if (obj.message) {
      newTrackUpload[obj.id].message = obj.message;
    }
    if (obj.libraryFileId) {
      newTrackUpload[obj.id].libraryFileId = obj.libraryFileId;
    }
    state.trackUpload = newTrackUpload;
  }
  if (obj.type === UPLOAD_TYPE.ATTACHMENT) {
    const newAttachmentUpload = { ...state.attachmentUpload };
    newAttachmentUpload[obj.id].status = status;
    state.trackUpload = newAttachmentUpload;
  }
};

const setUploadAsUploading = (s, obj) => {
  return updateStatus(s, obj, UPLOAD_STATUS.UPLOADING);
};

const setUploadAsAnalyzing = (s, obj) => {
  return updateStatus(s, obj, UPLOAD_STATUS.ANALYZING);
};

const setUploadAsDone = (s, obj) => {
  return updateStatus(s, obj, UPLOAD_STATUS.DONE);
};

const setUploadAsWarning = (s, obj) => {
  return updateStatus(s, obj, UPLOAD_STATUS.WARNING);
};

const setUploadAsError = (s, obj) => {
  return updateStatus(s, obj, UPLOAD_STATUS.ERROR);
};

const setUploadAsCanceled = (s, obj) => {
  return updateStatus(s, obj, UPLOAD_STATUS.CANCELED);
};

const saveUploadUrl = (s, obj) => {
  const state = s;
  const newTrackUpload = { ...state.trackUpload };
  newTrackUpload[obj.id].uploadUrl = obj.url;
  return state;
};

const resetTrackUpload = s => {
  const state = s;
  state.trackUpload = {};
};

export const uploadMutations = {
  setNewUpload,
  setUploadProgress,
  setUploadAsUploading,
  setUploadAsAnalyzing,
  setUploadAsDone,
  setUploadAsError,
  setUploadAsWarning,
  setUploadAsCanceled,
  saveUploadUrl,
  resetTrackUpload
};
