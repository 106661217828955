<template>
  <div class="share-by-link-body">
    <div class="upgrade-banner" v-if="sharesExpiresIn">
      <upgrade-banner
        :content="`Shared links expire after ${sharesExpiresIn} days with the Starter plan`"
        :roleId="myRoleId"
        @closeModal="$emit('closeModal')"
      />
    </div>
    <div
      class="tab-header-container"
      :style="{ paddingBottom: isPublicLinkActivated ? 0 : '24px' }"
      @click="forceToggle"
    >
      <div class="tab-header-container-icon"><i class="ri-flashlight-fill"></i></div>
      <div class="tab-header-container-main">
        <div class="share-by-link-body-title">
          <div>{{ $t('getQuickLink') }}</div>
          <div class="share-by-link-body-desc">
            {{ $t('quickLinkDesc') }}
          </div>
        </div>
        <switch-button
          ref="switchButton"
          @click.native.stop
          :defaultValue="isPublicLinkActivated"
          @toggleSwitchButton="togglePublicLinkActivation"
        />
      </div>
    </div>
    <div v-if="isPublicLinkActivated">
      <div class="share-by-link-body-input">
        <div class="share-by-link-body-input-container">
          <div
            class="share-by-link-body-input-container--"
            v-if="isPublicLinkActivated && basePublicLink"
          >
            <text-field
              class="public-link-url-textfield"
              inputType="text"
              :value="basePublicLink"
              :large="false"
              disabled
            />
          </div>
          <div class="public-link-separator"></div>
          <copy-btn
            class="copy-btn"
            @copyLink="copyLink"
            :hasBorder="false"
            :label="copyBtnLabel"
          />
        </div>
      </div>
      <access-rights
        :accessRights="publicLinkAccessRights"
        :isProject="isProject"
        type="link"
        class="access-rights-link"
        @setAccessRights="setPublicLinkAccessRights"
      />
      <share-security-section
        ref="sharePasswordTogglePublic"
        :displayPasswordInput="displaySharePasswordPublic"
        :securityPassword.sync="sharePasswordPublic"
        :securityPasswordErrorMessage="passwordPublicErrorMessage"
        :selectOnLoad="selectPasswordInputOnLoad"
        @toggleSwitch="toggleSharePasswordPublic"
        @handleBlur="handlePublicBlur"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { maxLength, minLength } from 'vuelidate/lib/validators';

import { bus } from '@/utils/bus';
import { trackEvent } from '@/utils/functions/analytics';
import { capitalize } from '@/utils/functions/global';

import AccessRights from '@/containers/accessRights';
import CopyBtn from '@/containers/shareViews/sharings/copyBtn';
import ShareSecuritySection from '@/containers/form/shareSecuritySection';
import UpgradeBanner from '@/containers/upgradeBanner';

export default {
  components: {
    AccessRights,
    ShareSecuritySection,
    UpgradeBanner,
    CopyBtn
  },
  props: {
    entityIds: {
      type: Array,
      required: true
    },
    entityType: {
      type: String,
      required: true
    },
    myRoleId: {
      type: Number,
      required: false,
      default: null
    },
    sharesExpiresIn: {
      type: String,
      required: false,
      default: null
    },
    isPublicLinkActivated: {
      type: Boolean,
      required: false,
      default: false
    },
    basePublicLink: {
      type: String,
      required: false,
      default: null
    },
    publicLinkAccessRights: {
      type: Array,
      required: true
    },
    displaySharePasswordPublic: {
      type: Boolean,
      required: false
    },
    defaultSharePassword: {
      type: String,
      required: false,
      default: null
    },
    defaultTab: {
      type: Number,
      required: false,
      default: null
    },
    shareId: {
      type: String,
      required: true,
      default: ''
    },
    shareHash: {
      type: String,
      required: true,
      default: ''
    },
    selectedTracksLength: {
      type: Number,
      required: false,
      default: null
    }
  },
  data() {
    return {
      sharePasswordPublic: this.defaultSharePassword,
      copyBtnLabel: { base: 'Copy', copied: 'Copied!', icon: 'ri-file-copy-line', width: 98 }
    };
  },
  validations: {
    sharePasswordPublic: {
      minLength: minLength(3),
      maxLength: maxLength(50)
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'userPrimaryEmail']),
    passwordPublicErrorMessage() {
      if (!this.$v.sharePasswordPublic.minLength) {
        return this.$t('minChars', {
          count: this.$v.sharePasswordPublic.$params.minLength.min
        });
      }
      if (!this.$v.sharePasswordPublic.maxLength) {
        return this.$t('maxiChars', {
          count: this.$v.sharePasswordPublic.$params.maxLength.max
        });
      }
      return null;
    },
    isProject() {
      return this.entityType === 'project';
    },
    isDefault() {
      return this.defaultTab === 0;
    },
    selectPasswordInputOnLoad() {
      return !this.sharePasswordPublic;
    }
  },
  mounted() {
    if (this.isDefault && !this.isPublicLinkActivated) {
      this.forceToggle();
    }
  },
  methods: {
    forceToggle() {
      this.$refs.switchButton.toggleButton();
    },
    togglePublicLinkActivation(switchBtnValue) {
      this.$emit('togglePublicLinkActivation', switchBtnValue);
    },
    copyLink() {
      const dummy = document.createElement('textarea');
      document.body.appendChild(dummy);
      dummy.value = this.basePublicLink;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
      if (!this.sharePasswordPublic && this.displaySharePasswordPublic) {
        this.hardToggleSharePasswordPublic();
      }
      bus.$emit('showAlert', {
        message: { key: 'copiedToClipboard' },
        style: 'success',
        delay: 5000
      });

      let selectMode;
      if (this.entityType === 'track') {
        if (this.selectedTracksLength > 1) {
          selectMode = 'multiple';
        } else {
          selectMode = 'single';
        }
      }

      const shareEventName =
        this.entityType === 'support'
          ? 'Share Album Copy'
          : `Share ${capitalize(this.entityType)} Copy`;

      trackEvent(shareEventName, {
        category: 'sharing',
        unique_hash: this.shareHash,
        share_id: this.shareId,
        share_type: 'public',
        select_mode: selectMode,
        security_status: this.sharePasswordPublic ? 'on' : 'off',
        position: 'share modal',
        email: this.userPrimaryEmail
      });
    },
    setPublicLinkAccessRights(accessRights) {
      this.$emit('setPublicLinkAccessRights', accessRights);
    },
    toggleSharePasswordPublic() {
      this.$emit('toggleSharePasswordPublic');
    },
    handlePublicBlur() {
      if (!this.passwordPublicErrorMessage) {
        this.$emit('handlePublicBlur', this.sharePasswordPublic);
      }
    },
    hardToggleSharePasswordPublic() {
      if (this.$refs.sharePasswordTogglePublic) {
        this.$refs.sharePasswordTogglePublic.hardToggleSwitchButton();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.share-by-link {
  &-body {
    .upgrade-banner {
      padding: 0 24px 16px 24px;
    }
    padding: 32px 0 0 0;
    max-height: calc(100vh - 41px - 56px - 57px - 24px);

    &-title {
      @include subtitle-1;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    &-desc {
      @include body-2;
      color: $color_neutral_60;
      margin-top: 8px;
    }

    &-input {
      padding: 24px 24px 16px 24px;

      &-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $color_neutral_40;
        border-radius: 4px;
        &-- {
          width: 386px;
          position: relative;
        }
      }
    }
  }
}
.tab-header-container {
  cursor: pointer;
  padding: 0 16px 0 24px;
  display: flex;
  align-items: center;
  &-icon {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: $color_primary_10;
    color: $color_primary_100;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
  }
  &-main {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  }
}
.access-rights-link {
  padding: 0 24px 24px 24px;
}
.public-link-separator {
  width: 1px;
  height: 28px;
  background-color: $color_neutral_40;
}
.copy-btn {
  margin-right: 8px;
}
</style>

<style lang="scss">
.share-by-link-body-input-container-- {
  .public-link-url-textfield.textfield {
    .textfield-disabled {
      background-color: white;
      border: none;
      padding-right: 0;
      input {
        background-color: white;
        width: 100%;
      }
    }
  }
}
</style>
