
<div class="progress">
  <div v-if="title" class="progress-title">{{ title }}</div>
  <div class="progress-bar">
    <div
      class="progress-bar-above"
      :style="`width: ${width}px; background-color: ${color}; max-width: ${size}px;`"
    />
    <div class="progress-bar-below" :style="`width: ${size}px;`" />
  </div>
  <div v-if="helper" class="progress-helper">{{ helper }}</div>
</div>
