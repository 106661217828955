
<div class="right-holder-panel-body" :key="updateKey">
  <div v-if="Dictionaries">
    <div v-if="isMultipleTrackEdition && !multiEditActive">
      <text-field
        inputType="text"
        withEditButton
        editButtonEmit
        placeholder="< Multiple values >"
        @editBtnClick="
          () => {
            multiEditActive = true;
          }
        "
      />
    </div>
    <div
      v-else
      class="right-holder-panel-body-row"
      :class="{ mb5: getNameErrorMessage(i) }"
      v-for="(row, i) in rows"
      :key="`row-${row.id}-${row.track_right_holder_id}`"
    >
      <div class="right-holder-panel-body-row-name">
        <text-field
          placeholder="John Doe"
          inputType="text"
          :label="i === 0 ? $t('name') : null"
          :large="false"
          :value="row.name"
          :fieldModel.sync="row.name"
          :errorMessage="getNameErrorMessage(i)"
        />
      </div>
      <div class="right-holder-panel-body-row-role">
        <select-box
          :options="options"
          :label="i === 0 ? $t('role') : null"
          :placeholder="$t('selectRole')"
          :selectedValue="{ label: row.role, value: row.role }"
          @setSelectBoxValue="handleSelectChange($event, row.id, row.track_right_holder_id)"
        />
      </div>
      <div class="right-holder-panel-body-row-percent">
        <text-field
          inputType="number"
          :label="i === 0 ? $t('masterPercent') : null"
          :large="false"
          :value="row.mastering_percentage"
          :fieldModel.sync="row.mastering_percentage"
        />
      </div>
      <div class="right-holder-panel-body-row-percent">
        <text-field
          inputType="number"
          :label="i === 0 ? $t('publishPercent') : null"
          :large="false"
          :value="row.publishing_percentage"
          :fieldModel.sync="row.publishing_percentage"
        />
      </div>
      <div
        v-if="showDeleteButton"
        class="right-holder-panel-body-row-cross"
        @click="deleteRow(row.id, row.track_right_holder_id)"
      >
        <i class="ri-close-fill"></i>
      </div>
    </div>
  </div>
  <div v-else class="loader"><spinner-without-progress color="grey" :size="32" /></div>
  <div class="right-holder-panel-body-add" v-if="!isMultipleTrackEdition || multiEditActive">
    <submit-button
      btnStyle="tertiary"
      size="small"
      :label="$t('add')"
      iconClass="ri-add-fill"
      exception
      @submit="addRow"
    />
  </div>
  <div
    v-if="masteringPercentage > 0 || publishingPercentage > 0"
    class="right-holder-panel-body-total"
  >
    <div class="right-holder-panel-body-total-title">
      Total
      <i class="ri-information-line" v-tooltip="$t('totalInfo')"></i>
    </div>
    <div class="right-holder-panel-body-total-percent">
      <div class="right-holder-panel-body-total-percent--">
        {{ `${parseFloat(masteringPercentage).toFixed(1)}%` }}
      </div>
      <div class="right-holder-panel-body-total-percent--">
        {{ `${parseFloat(publishingPercentage).toFixed(1)}%` }}
      </div>
    </div>
  </div>
  <div
    v-if="
      hasRightHolders &&
      (masteringPercentage !== 100 || publishingPercentage !== 100) &&
      !isMultipleTrackEdition
    "
    class="right-holder-panel-body-info"
  >
    <panel-information
      :canClose="false"
      PanelStyle="warning"
      :message="$t('totalShouldEqual100')"
    />
  </div>
  <div class="drop-all-btn" v-if="isMultipleTrackEdition && hasRightHolders">
    <submit-button
      :label="$t('removeTracksRightHolders')"
      size="small"
      btnStyle="secondary"
      @submit="dropRightHoldersModal"
    />
  </div>
</div>
