<template>
  <infinite-scroll
    :listQuery="searchQuery"
    @handleQueryResult="handleQueryResult"
    @setLoading="setLoading"
  >
    <template v-slot:list>
      <tracks
        :trackList="trackList"
        :isFetchingMore="loading"
        :uncheckTracks="uncheckTracks"
        hasSelectAll
        @setSelectedTracks="setSelectedTracks"
      />
    </template>
  </infinite-scroll>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash.get';

import { PAGINATOR_LIMIT } from '@/utils/constants';
import { SEARCH_TRACKS } from '@/graphql/queries/search';
import generateTracklist from '@/utils/functions/tracklist';
import InfiniteScroll from '@/containers/infiniteScroll';
import Tracks from '@/containers/browserTrackList';

export default {
  components: {
    InfiniteScroll,
    Tracks
  },
  props: {
    uncheckTracks: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      trackList: [],
      loading: false
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'searchLibraryQueryParameters']),
    searchQuery() {
      return {
        query: SEARCH_TRACKS,
        key: 'SearchTracks',
        variables: {
          ...this.searchLibraryQueryParameters,
          offset: 0,
          version: 2,
          limit: PAGINATOR_LIMIT
        },
        pagination: 'offset',
        options: {
          fetchPolicy: 'network-only',
          debounce: 300
        }
      };
    }
  },
  beforeMount() {
    const storedParameters = localStorage.getItem('SEARCH_LIBRARY_QUERY_PARAMETERS');
    if (storedParameters !== 'null') {
      const parameters = JSON.parse(storedParameters);
      this.$store.commit('changeSearchLibraryQueryParameters', parameters);
      return;
    }
    this.setSearchQueryParametersInLocalStorage();
  },
  beforeDestroy() {
    this.setSearchQueryParametersInLocalStorage();
  },
  watch: {
    searchLibraryQueryParameters() {
      this.setSearchQueryParametersInLocalStorage();
    }
  },
  methods: {
    handleQueryResult(response) {
      const tracks = get(response, 'data.SearchTracks.data.tracks', []);
      this.trackList = generateTracklist(tracks, this.currentWorkspace, this.$i18n.locale, true);
    },
    setLoading(loading) {
      this.loading = loading;
    },
    setSelectedTracks(selectedTracks) {
      this.$emit('setSelectedTracks', selectedTracks);
    },
    setSearchQueryParametersInLocalStorage() {
      localStorage.setItem(
        'SEARCH_LIBRARY_QUERY_PARAMETERS',
        JSON.stringify(this.searchLibraryQueryParameters)
      );
    }
  }
};
</script>
