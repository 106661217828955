<template>
  <div :class="{ textfield: true, horizontal: horizontalDisplaying }">
    <div
      :class="{
        'textfield-error': (errorMessage && showError) || inErrorWithoutMessage,
        'horizontal-wrapper': horizontalDisplaying
      }"
    >
      <div class="textfield-head" v-if="!horizontalDisplaying">
        <div v-if="label" class="textfield-head-label">{{ label }}</div>
        <div v-if="helper" class="textfield-head-helper">{{ helper }}</div>
      </div>
      <div v-if="!horizontalDisplaying && hint" class="textfield-head-hint">
        {{ hint }}
      </div>
      <div class="horizontal-wrapper-label" v-if="horizontalDisplaying">{{ label }}</div>
      <div :class="textfieldClass">
        <div v-if="withEditButton && inputDisabled" class="textfield-disabled-edit-button">
          <submit-button
            btnStyle="tertiary"
            exception
            danger
            size="small"
            label="Edit"
            @submit="handleEditBtnClick"
          />
        </div>
        <imask-input
          v-if="mask !== ''"
          :mask="mask"
          :lazy="this.pwd.length === 0"
          ref="input"
          :unmask="false"
          @blur="
            setActiveClass(false);
            handleBlurEvent();
          "
          @focus="setActiveClass(true)"
          @input="
            $emit('update:fieldModel', $event);
            $emit('handleInput', $event);
            handleInputEvent();
          "
          @keydown="preventOnKeydown"
          type="text"
          v-model="pwd"
          :placeholder="placeholder"
          :class="passwordInputClass"
          spellcheck="false"
          :autocomplete="false"
          :disabled="inputDisabled"
          :style="inputWidth && `width: ${inputWidth}px;`"
          @keyup="preventNegative"
          @paste="preventOnPaste"
        />
        <input
          v-else
          ref="input"
          @blur="
            setActiveClass(false);
            handleBlurEvent();
          "
          @focus="setActiveClass(true)"
          @input="
            $emit('update:fieldModel', $event.target.value);
            $emit('handleInput', $event.target.value);
            handleInputEvent();
          "
          @keydown="preventOnKeydown"
          :type="type"
          v-model="pwd"
          v-on:input="setPwdColor"
          :placeholder="placeholder"
          :class="passwordInputClass"
          spellcheck="false"
          :autocomplete="autocomplete"
          :disabled="inputDisabled"
          :style="inputWidth && `width: ${inputWidth}px;`"
          @keyup="preventNegative"
          @paste="preventOnPaste"
          min="0"
        />
        <div
          v-if="withVisibility"
          :class="pwdIconClass"
          class="visibility"
          @click="togglePwdVisibility"
        />
      </div>
    </div>
    <div v-if="!errorMessage && withCheck && pwdInfo !== ''">
      <div class="textfield-check-pwd">
        <div
          v-for="index in level"
          class="textfield-check-pwd-line"
          :style="`background-color: ${pwdColor};`"
          :key="`check-pwd-${index}`"
        />
        <div
          v-for="index in 3 - level"
          class="textfield-check-pwd-line"
          :key="`check-pwd2-${index}`"
        />
      </div>
      <div v-if="pwdInfo" class="textfield-info" :style="`color: ${pwdColor};`">{{ pwdInfo }}</div>
    </div>
    <div
      v-if="showError"
      class="textfield-error-msg"
      :class="{ 'error-msg-style-exception': errorMessageException }"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { IMaskComponent } from 'vue-imask';
import { strongPassword, mediumPassword } from '@/utils/functions/global';
import { COLORS } from '../../utils/constants';
import SubmitButton from '../buttons/submitButton';

export default {
  components: {
    SubmitButton,
    'imask-input': IMaskComponent
  },
  props: {
    inputType: {
      type: String,
      required: false,
      default: 'password'
    },
    withCheck: {
      type: Boolean,
      required: false,
      default: false
    },
    withVisibility: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    helper: {
      type: String,
      required: false,
      default: ''
    },
    hint: {
      type: String,
      required: false,
      default: ''
    },
    fieldModel: {
      type: [String, Number],
      default: null,
      required: false
    },
    value: {
      type: [String, Number],
      default: null,
      required: false
    },
    errorMessage: {
      type: String,
      default: null,
      required: false
    },
    inErrorWithoutMessage: {
      type: Boolean,
      required: false,
      default: false
    },
    securityLabels: {
      type: Array,
      default: () => ['Weak', 'Fair', 'Strong'],
      required: false
    },
    prefix: {
      type: Boolean,
      required: false,
      default: false
    },
    large: {
      type: Boolean,
      required: false,
      default: true
    },
    focusOnLoad: {
      type: Boolean,
      required: false,
      default: false
    },
    selectOnLoad: {
      type: Boolean,
      required: false,
      default: false
    },
    autocomplete: {
      type: String,
      required: false,
      default: 'on'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    horizontalDisplaying: {
      type: Boolean,
      required: false,
      default: false
    },
    inputWidth: {
      type: Number,
      required: false,
      default: 0
    },
    withEditButton: {
      type: Boolean,
      required: false,
      default: false
    },
    editButtonEmit: {
      type: Boolean,
      required: false,
      default: false
    },
    errorMessageException: {
      type: Boolean,
      required: false,
      default: false
    },
    mask: {
      type: String,
      required: false,
      default: ''
    },
    trimValue: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      type: this.inputType,
      pwd: this.value || (this.value === 0 ? 0 : ''),
      pwdColor: COLORS.grey,
      pwdIcon: 'ri-eye-close-line',
      pwdInfo: '',
      level: 0,
      isFocusActive: false,
      showError: false,
      isInitialState: true,
      inputDisabled: this.disabled || this.withEditButton
    };
  },
  computed: {
    textfieldClass() {
      let str = 'textfield-container';
      if (this.inputDisabled) str = 'textfield-disabled';
      if (this.isFocusActive) str += ' textfield-focus';
      if ((this.errorMessage && this.showError) || this.inErrorWithoutMessage)
        str += ' textfield-input-error';
      if (this.prefix && this.withVisibility) str += ' textfield-prefix';
      return str;
    },
    pwdIconClass() {
      let str = this.pwdIcon;
      if (this.prefix) str += ' visibility-prefix';
      return str;
    },
    passwordInputClass() {
      let str = 'textfield-password-input';
      if (this.large) str += ' textfield-password-input-large';
      return str;
    }
  },
  mounted() {
    if (this.focusOnLoad || this.selectOnLoad) {
      this.handleFocus(this.selectOnLoad);
    }
  },
  methods: {
    preventOnKeydown(e) {
      if (e.keyCode === 13) {
        this.$emit('enterPress');
        e.preventDefault();
      }
      if (this.inputType === 'number') {
        // prevent "e", "-" and "+" from input type number
        if (e.keyCode === 69 || e.keyCode === 189 || e.keyCode === 187) {
          e.preventDefault();
        }
      }
    },
    preventOnPaste(e) {
      const pasted = (e.clipboardData || window.clipboardData).getData('text');
      if (
        this.inputType === 'number' &&
        (pasted.includes('e') || pasted.includes('-') || pasted.includes('+'))
      ) {
        e.preventDefault();
        const newString = pasted.replace(/\D/g, '');
        const newVal = this.pwd + newString;
        e.target.value = newVal;
        this.pwd = newVal;
        this.$emit('update:fieldModel', newVal);
      }
    },
    preventNegative(e) {
      if (e.target.value < 0 && this.inputType === 'number') {
        e.target.value = 0;
        this.pwd = 0;
        this.$emit('update:fieldModel', 0);
      }
    },
    setActiveClass(value) {
      this.isFocusActive = value;
    },
    handleBlurEvent() {
      this.showError = !!this.errorMessage;
      this.isInitialState = !this.errorMessage;
      this.$emit('handleBlur');
    },
    handleInputEvent() {
      if (this.trimValue) {
        const trimedValue = this.pwd.trim();
        this.pwd = trimedValue;
        this.$emit('update:fieldModel', trimedValue);
      }
      this.showError = !this.isInitialState;
    },
    togglePwdVisibility() {
      this.type = this.type === 'password' ? 'text' : 'password';
      this.pwdIcon = this.type === 'password' ? 'ri-eye-close-line' : 'ri-eye-line';
      this.handleFocus();
    },
    setPwdColor() {
      if (strongPassword.test(this.pwd)) {
        this.pwdColor = COLORS.green;
        const label = this.securityLabels[2];
        this.pwdInfo = label;
        this.level = 3;
      } else if (mediumPassword.test(this.pwd)) {
        this.pwdColor = COLORS.orange;
        const label = this.securityLabels[1];
        this.pwdInfo = label;
        this.level = 2;
      } else {
        this.pwdColor = COLORS.red;
        const label = this.securityLabels[0];
        this.pwdInfo = label;
        this.level = 1;
      }
    },
    handleFocus(select = false) {
      const inputValueLentgh = this.$refs.input.value.length;
      this.$refs.input.focus();
      if (select) {
        this.$refs.input.select();
      } else {
        this.$nextTick(() => {
          this.$refs.input.setSelectionRange(inputValueLentgh, inputValueLentgh);
        });
      }
    },
    handleEditBtnClick() {
      if (this.editButtonEmit) {
        this.$emit('editBtnClick');
        return;
      }
      this.inputDisabled = false;
      setTimeout(() => {
        this.$refs.input.focus();
      }, 500);
      this.$emit('toggleDisableStatus', this.inputDisabled);
    },
    changeInputValue(value) {
      this.pwd = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.textfield {
  position: relative;
  min-width: 150px;
  max-width: 522px;

  &-head {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &-label {
      margin: 0 0 8px 0;
      text-align: left;
      @include label;
    }

    &-helper {
      @include body-2;
      color: $color_neutral_60;
      margin-bottom: 8px;
    }
  }
  &-head-hint {
    @include body-2;
    color: $color_neutral_60;
    text-align: left;
    margin-bottom: 8px;
  }

  &-container {
    box-sizing: border-box;
    display: flex;
    border: 1px solid $color_neutral_40;
    border-radius: 4px;
    padding: 0 16px;
    background-color: $color_neutral_0;

    .ri-eye-close-line,
    .ri-eye-line {
      color: $color_neutral_40;
      width: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 300ms linear;

      &:before {
        font-size: 18px;
      }
    }
  }

  &-disabled {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid $color_neutral_40;
    background-color: $color_neutral_10;
    box-sizing: border-box;
    display: flex;
    border-radius: 4px;
    padding: 0 16px;
    transition: all 300ms linear;
    position: relative;
    display: flex;
    align-items: center;

    &:hover {
      .textfield-disabled-edit-button {
        opacity: 1;
      }
    }

    &-edit-button {
      position: absolute;
      right: 8px;
      opacity: 0;
    }

    .ri-eye-close-line,
    .ri-eye-line {
      color: $color_neutral_40;
      width: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 300ms linear;

      &:before {
        font-size: 18px;
      }
    }
  }

  &-focus {
    border: 1px solid $color_neutral_100;

    .ri-eye-close-line,
    .ri-eye-line {
      color: $color_neutral_100;
    }
  }

  &-input-error {
    border: 1px solid $color_danger_100;
    background-color: $color-danger_10;
    input {
      color: $color_danger_100;
      background-color: $color-danger_10;
      @include body-1;
      filter: none;
    }
  }

  &-check-pwd {
    display: flex;
    justify-content: space-between;
    padding: 0 2px;
    margin: 8px 0 0 0;

    &-line {
      height: 5px;
      width: 100%;
      background-color: $color_neutral_40;

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  &-info {
    font-weight: 400;
    font-size: 14px;
    text-align: right;
    padding: 8px 8px 0 0;
  }

  &-error-msg {
    @include body-2;
    color: $color_danger_100;
    padding: 4px 0 0 0;
    text-align: left;
    line-height: 18px;
    position: absolute;
    left: 0;
  }

  &-password-input {
    font-family: inherit;
    box-sizing: border-box;
    height: 38px;
    color: $color_neutral_100;
    font-size: 14px;
    outline: none;
    border: none;
    filter: none;
    width: calc(100% - 12px);
    font-weight: 400;
  }
}

.horizontal {
  min-width: 100%;
  max-width: 100%;

  &-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-label {
      @include body-1;
      color: $color_neutral_60;
    }
  }

  .textfield-error-msg {
    position: inherit;
    text-align: right;
  }
}

::placeholder {
  @include body-1;
  color: $color_neutral_40;
}

::-moz-placeholder {
  line-height: 46px;
}

:-ms-input-placeholder {
  line-height: 46px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  box-shadow: 0 0 0 30px $color_neutral_0 inset !important;
  -webkit-box-shadow: 0 0 0 30px $color_neutral_0 inset !important;
}
.visibility-prefix {
  padding-right: 8px;
}
.textfield-prefix {
  flex-direction: row-reverse;
}
.textfield-password-input-large {
  height: 46px;
}

.error-msg-style-exception {
  position: inherit;
  text-align: right;
}

input:disabled {
  background-color: $color_neutral_10;
}

input:disabled::-webkit-input-placeholder {
  color: $color_neutral_60;
}
input:disabled:-moz-placeholder {
  color: $color_neutral_60;
}
input:disabled::-moz-placeholder {
  color: $color_neutral_60;
}
input:disabled:-ms-input-placeholder {
  color: $color_neutral_60;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
</style>
