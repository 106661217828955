const webappConf = {
  AccountBaseUrl: 'http://localhost:4000',
  AccountApiBaseUrl: 'http://account-api.bridge.localhost',
  BridgeMainUrl: 'http://localhost:1313',
  BridgePrivacyUrl: 'http://localhost:1313/privacy.html',
  BridgeTermsUrl: 'http://localhost:1313/terms-and-conditions.html',
  MainPublicDomain: 'localhost',
  version: {
    release: 'dev',
    hash: 'dev',
    build: 'dev'
  },
  upload: {
    artist: {
      width: 1000,
      height: 1000
    },
    support: {
      width: 3000,
      height: 3000
    },
    user: {
      width: 400,
      height: 400
    },
    workspace: {
      width: 500,
      height: 500
    }
  },
  urls: {
    showreel: 'https://share.bridge.audio/dfea25ef-44b3-4ace-8731-38df70831add',
    presskit: 'https://share.bridge.audio/6824265a-ac77-4ba0-965a-006b2f6de440',
    syncSelection: 'https://share.bridge.audio/6cbc7eb6-170e-40d1-9f3b-77caf399db32'
  },
  currencies: {
    EURO: 'eur',
    DOLLAR: 'usd'
  },
  flags: {
    billingDetails: true
  },
  stripeScriptId: 'stripe3rdPartyScript'
};

const CIConfig = window.webappConf || {};

export const appConfig = { ...webappConf, ...CIConfig };

export const COLORS = {
  grey: '#C2BFC6',
  red: '#FC4E3E',
  orange: '#E7AF22',
  green: '#6BBA11'
};

export const PAGINATOR_LIMIT = 40;

export const DONT_SHOW_THIS_MESSAGE_AGAIN = 'DONT_SHOW_THIS_MESSAGE_AGAIN';

export const DONT_SHOW_LINKS_EXPLANATION_AGAIN = 'DONT_SHOW_LINKS_EXPLANATION_AGAIN';

export const USER_STREAMING_QUALITY = 'USER_STREAMING_QUALITY';

export const SEARCH_CONTEXT = {
  LIBRARY_SEARCH_CONTEXT: 'LIBRARY_SEARCH_CONTEXT',
  PROJECT_SEARCH_CONTEXT: 'PROJECT_SEARCH_CONTEXT'
};
