<template>
  <div class="share-settings-modal">
    <div class="share-settings-modal-info">
      <div v-if="contactInfo" class="share-info">
        <div class="icon">
          <i class="ri-user-fill"></i>
        </div>
        <div class="share-name">
          {{ contactInfo }}
        </div>
      </div>
      <div v-else>
        <div v-if="!shareCustomName" class="share-info">
          <div class="icon">
            <i class="ri-global-line"></i>
          </div>
          <div class="share-name">
            {{ $t('quickLink') }}
          </div>
        </div>
      </div>
    </div>
    <share-security-section
      ref="sharePasswordModalSection"
      :displayPasswordInput="displaySharePassword"
      :displayLinkNameInput="true"
      :shareLinkName.sync="shareLinkName"
      :shareLinkNameErrorMessage="shareLinkNameErrorMessage"
      :securityPassword.sync="sharePassword"
      :securityPasswordErrorMessage="passwordErrorMessage"
      :shareCustomName="shareCustomName"
      :selectOnLoad="!sharePassword"
      :displayTitle="false"
      @toggleSwitch="toggleSharePassword"
    />
    <div class="share-settings-modal-buttons">
      <div class="share-settings-modal-buttons--">
        <submit-button
          btnStyle="tertiary"
          size="medium"
          :label="$t('cancel')"
          @submit="$emit('closeModal')"
        />
      </div>
      <div class="share-settings-modal-buttons--">
        <submit-button
          btnStyle="primary"
          size="medium"
          :label="$t('save')"
          :disabled="isButtonDisabled"
          :pending="pending"
          @submit="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from '@/utils/bus';
import { configureShare } from '@/utils/functions/share';
import ShareSecuritySection from '@/containers/form/shareSecuritySection';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';

export default {
  components: {
    ShareSecuritySection
  },
  props: {
    shareId: {
      type: String,
      required: true
    },
    shareAccessRight: {
      type: Array,
      required: true
    },
    contactInfo: {
      type: String,
      required: false,
      default: null
    },
    password: {
      type: String,
      required: false,
      default: null
    },
    shareCustomName: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      displaySharePassword: !!this.password,
      shareLinkName: this.shareCustomName,
      sharePassword: this.password,
      pending: false
    };
  },
  validations: {
    sharePassword: {
      minLength: minLength(3),
      maxLength: maxLength(50)
    },
    shareLinkName: {
      required,
      maxLength: maxLength(255)
    }
  },
  computed: {
    passwordErrorMessage() {
      if (!this.$v.sharePassword.minLength) {
        return this.$t('minChars', {
          count: this.$v.sharePassword.$params.minLength.min
        });
      }
      if (!this.$v.sharePassword.maxLength) {
        return this.$t('maxiChars', {
          count: this.$v.sharePassword.$params.maxLength.max
        });
      }
      return null;
    },
    shareLinkNameErrorMessage() {
      if (!this.$v.shareLinkName.required) {
        return this.$t('required', {
          label: this.$t('linkName')
        });
      }
      if (!this.$v.shareLinkName.maxLength) {
        return this.$t('maxLength', {
          nbChars: this.$v.shareLinkName.$params.maxLength.max
        });
      }
      return null;
    },
    isButtonDisabled() {
      if (this.shareCustomName !== '') {
        return (
          (this.shareLinkName === this.shareCustomName && this.sharePassword === this.password) ||
          this.passwordErrorMessage !== null ||
          this.shareLinkNameErrorMessage !== null
        );
      }
      return (
        (this.shareLinkName === this.shareCustomName && this.sharePassword === this.password) ||
        this.passwordErrorMessage !== null
      );
    }
  },
  methods: {
    handleSubmit() {
      if (this.displaySharePassword && !this.sharePassword) {
        this.hardToggleSharePassword();
      }
      const params = {
        shareId: this.shareId,
        access_rights: this.shareAccessRight,
        access_password: this.sharePassword
      };
      if (this.shareLinkName) {
        params.custom_name = this.shareLinkName;
      }
      this.pending = true;
      configureShare(this, params).then(() => {
        this.pending = false;
        bus.$emit('showAlert', {
          message: { key: 'settingsSaved' },
          style: 'success',
          delay: 5000
        });
        if (this.submit) {
          this.submit();
        }
      });
      this.$emit('closeModal');
    },
    toggleSharePassword() {
      this.displaySharePassword = !this.displaySharePassword;
      this.sharePassword = null;
    },
    hardToggleSharePassword() {
      if (this.$refs.sharePasswordModalSection) {
        this.$refs.sharePasswordModalSection.hardToggleSwitchButton();
      }
    },
    copyPassword(value) {
      bus.$emit('showAlert', {
        message: { key: 'passwordCopied' },
        style: 'success',
        delay: 5000
      });
      const dummy = document.createElement('textarea');
      document.body.appendChild(dummy);
      dummy.value = value;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
    }
  }
};
</script>

<style lang="scss" scoped>
.share-settings-modal {
  &-info {
    padding: 0 32px 8px 32px;
    .share {
      &-info {
        display: flex;
        align-items: center;
        padding-top: 24px;

        .icon {
          height: 32px;
          width: 32px;
          border-radius: 50%;
          background-color: $color_primary_10;
          color: $color_primary_100;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
        }
      }
      &-name {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
  &-buttons {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $color_neutral_30;
    padding: 8px 32px;

    &-- {
      &:first-child {
        margin: 0 16px 0 0;
      }
    }
  }
}
</style>
