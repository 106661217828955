
<dropdown ref="dropdown" dismissOnClick @setIsDropdownExpanded="setIsDropdownExpanded">
  <template #button>
    <submit-button
      class="project-cover-btns--"
      :btnStyle="btnProps.style"
      :size="btnProps.size"
      :label="btnProps.label"
      :iconClass="btnProps.icon"
      :isExpanded="isExpanded"
      :disabled="btnProps.disabled"
      :primaryException="btnProps.primaryException"
      :isDropdown="!btnProps.hideDropdown"
    />
  </template>
  <div class="menu-large">
    <div class="menu-item menu-item-small" @click="deleteProjectCover()">
      <div class="item">
        <span class="menu-item-label-small"> Remove </span>
      </div>
    </div>
    <div class="menu-item" @click="handleFileSelect()">
      <div class="item-dark">
        <i class="ri-upload-cloud-fill"></i>
        <span class="menu-item-label-dark"> Upload cover pic </span>
      </div>
    </div>
    <div class="menu-item menu-item-helper">
      <div class="item-helper">
        <span class="menu-item-label-helper">
          Optimal size: 1600x360, {{ maxCoverSizeConfig }} max
        </span>
      </div>
    </div>
    <div class="menu-item menu-item-link">
      <a
        href="https://www.notion.so/bridgeaudio/Projects-51de9d3694f14cb9be07f6f52963af8e?pvs=4#ab1624e786b64cde86c6d5aa2c44c221"
        target="_blank"
        rel="noopener"
        class="menu-item-label-link"
        >More info</a
      >
    </div>
  </div>
</dropdown>
