
<div class="transfer-ownership-modal">
  <div class="transfer-ownership-modal-body">
    <div class="transfer-ownership-modal-body--">
      {{ $t('transferOwnershipConfirmation') }}
      <span class="bold">"{{ info.workspaceName }}" </span>
      {{ $t('to') }}
      <span class="bold">{{ info.newOwner }} </span>
      <span class="grey">({{ info.newOwnerMail }}) </span>
      ?
    </div>
    <div>
      <text-field
        ref="inputPassword"
        :label="$t('enterPassword')"
        :large="false"
        :fieldModel.sync="password"
        :errorMessage="passwordErrorMessage"
        @handleInput="handlePasswordInput"
      />
    </div>
  </div>
  <div class="transfer-ownership-modal-buttons">
    <div class="transfer-ownership-modal-buttons--">
      <submit-button btnStyle="tertiary" :label="$t('cancel')" @submit="closeModal" />
    </div>
    <div class="transfer-ownership-modal-buttons--">
      <submit-button
        :label="$t('yesContinue')"
        :disabled="passwordErrorMessage !== null"
        @submit="confirmTransfer"
      />
    </div>
  </div>
</div>
