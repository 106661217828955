
<div class="main-container">
  <div
    :key="currentWorkspace.libraries[0].id"
    v-if="me"
    class="main"
    :class="{ isSettings: isSettings }"
    :style="gridStyle"
    @dragover.prevent="handleDragOver"
  >
    <div
      v-if="showDropZone && !isDraggingItemList"
      @dragleave.prevent="showDropZone = false"
      @drop.prevent.stop="handleDrop"
      class="main-dropzone"
    >
      <div class="main-dropzone-content">
        <div class="main-dropzone-content-icon">
          <i class="main-dropzone-content-icon-upload ri-upload-line" />
        </div>
        <div class="main-dropzone-content-helper">
          <div class="main-dropzone-content-helper-title">{{ $t('dropFiles') }}</div>
          <div class="main-dropzone-content-helper-desc">{{ $t('toUpload') }}</div>
        </div>
      </div>
      <div class="main-dropzone-background" />
    </div>
    <div class="main-banner" v-if="displayBanner">
      <maintenanceBanner v-if="displayMaintenanceBanner" />
      <workspace-full-banner v-if="displayWorkspaceFullBanner" :memberRole="memberRole" />
      <browser-banner v-if="displayBrowserBanner" @hideBrowserBanner="hideEmailBrowser" />
      <information-banner
        v-if="displayEmailBanner"
        :mailInfo="UserEmails"
        @hideEmailBanner="hideEmailBanner"
      />
      <auto-tag-banner v-if="displayAutoTagInfoBanner" @hideAutoTagBanner="hideAutoTagBanner" />
      <ai-upload-banner v-if="displayAiUploadBanner" @hideAIUploadBanner="hideAIUploadBanner" />
    </div>
    <div class="main-header">
      <main-header :me="me" />
    </div>
    <div
      v-if="displaySubHeader"
      class="main-sub-header"
      :class="{ 'subHeader-separator': isMainScrolling }"
    >
      <sub-header />
      <div v-if="displayLibraryContextActions" class="contextual-actions">
        <submit-button
          class="action mr-2"
          btnStyle="tertiary"
          size="small"
          :label="$t('fileUpload')"
          iconClass="ri-file-upload-fill"
          @submit="handleOpenFileInput()"
        />
        <submit-button
          class="action"
          btnStyle="tertiary"
          size="small"
          :label="$t('folderUpload')"
          iconClass="ri-folder-upload-fill"
          @submit="handleOpenFileInput(true)"
        />
      </div>
      <div v-if="displayProjectContextActions" class="contextual-actions ca-project">
        <submit-button
          class="action"
          btnStyle="tertiary"
          size="small"
          :label="$t('newProject')"
          iconClass="ri-folder-fill"
          @submit="handleCreateProjectRedirect"
        />
      </div>
      <div
        class="albums-sort"
        v-if="$route.name === 'albums' && this.currentWorkspace.total_supports > 0"
      >
        <sort-dropdown :options="albumSortOptions" :sort="albumSort" @sort="handleAlbumSort" />
      </div>
    </div>
    <div class="main-nav">
      <div class="main-nav-wrapper">
        <div class="main-nav-wrapper-workspace">
          <workspace-dropdown
            :currentWorkspaceId="currentWorkspace.id"
            :name="currentWorkspace.name"
            :initial="currentWorkspace.name.substring(0, 1).toUpperCase()"
            :pictureUrl="currentWorkspace.logo && currentWorkspace.logo.url"
            :headerOption="workspaceDropdown.header"
            :options="workspaceDropdown.options"
            :workspaces="workspaceDropdown.workspaceList"
            :hubs="workspaceDropdown.hubList"
            :footerOption="workspaceDropdown.footer"
            @handleWorkspaceClick="handleWorkspaceClick"
            @handleHubClick="handleHubClick"
          />
        </div>
        <div class="main-nav-wrapper-scroll">
          <sidebar :groups="sidebarGroups" />
        </div>
        <div v-if="isFreeTrialEnabled" class="main-nav-wrapper-banner-freetrial">
          <div class="main-nav-wrapper-banner-freetrial-container">
            <p class="main-nav-wrapper-banner-freetrial-title">
              {{ $t('freeTrialBanner.title') }}
            </p>
            <p
              v-html="
                $t('freeTrialBanner.text', {
                  label: getFreeTrialDaysLeftLabel
                })
              "
              class="main-nav-wrapper-banner-freetrial-text"
            />
          </div>
        </div>
        <div class="main-nav-wrapper-bottom">
          <div class="main-nav-wrapper-bottom-members">
            <div class="main-nav-wrapper-bottom-members-title" @click="redirectToMembersSettings">
              <i class="ri-parent-line"></i>
              {{ currentWorkspace.seats_used }}
              {{
                currentWorkspace.subscription.plan.name === 'Free'
                  ? `/ ${currentWorkspace.subscription.seats}`
                  : ''
              }}
              {{ $t('members') }}
            </div>
            <submit-button
              class="inviteBtn"
              btnStyle="secondary"
              size="small"
              :label="$t('invite')"
              iconClass="ri-user-add-line"
              @submit="openAddMembersModal"
            />
          </div>
          <div class="main-nav-wrapper-bottom-storage">
            <div class="main-nav-wrapper-bottom-storage-title">
              <i class="ri-server-line"></i>
              {{ $t('storageTitle') }}
              <i
                class="ri-error-warning-fill"
                v-if="displayWarningIcon"
                v-tooltip="{
                  content: $t('workspaceAlmostFull'),
                  classes: 'warning-tooltip',
                  position: 'top'
                }"
              ></i>
            </div>
            <div class="main-nav-wrapper-bottom-storage-progress">
              <progress-bar
                class="progress-bar"
                :helper="getWorkspaceTracksCount"
                :percent="progressBarTracksValue"
                :color="progressBarTracksColor"
                :size="137"
              />
            </div>
            <div class="main-nav-wrapper-bottom-storage-progress">
              <progress-bar
                class="progress-bar"
                :helper="
                  $t('storageUsed', { used: spaceUsedInOctets, total: totalSpaceInOctets })
                "
                :percent="progressBarStorageValue"
                :color="progressBarStorageColor"
                :size="137"
              />
            </div>
            <div v-tooltip="myRoleId === '2' ? 'Only admins can switch plans' : ''">
              <submit-button
                class="upgradeBtn"
                btnStyle="primary"
                size="small"
                :label="$t('upgrade')"
                :disabled="myRoleId === '2'"
                @submit="goToOffersSettings"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-view" ref="mainView" @scroll.passive="isMainScrollingSetter">
      <div class="main-view-wrapper">
        <router-view></router-view>
      </div>
    </div>
    <div
      :class="{
        'main-manager': Object.keys(playlist).length !== 0,
        'main-manager-hidden': Object.keys(playlist).length === 0,
        'main-manager-edit-panel': isEditPanelOpened
      }"
    >
      <download-manager
        v-if="isDownloadingArchive"
        :onClose="closeDownloadManager"
        :status="downloadArchiveStatus"
        :labels="{
          close: $t('close'),
          pending: {
            header: $t('downloadMessages.pending.header'),
            body: $t('downloadMessages.pending.body')
          },
          processing: {
            header: $t('downloadMessages.processing.header'),
            body: tracksToDownloadCounter
              ? $t('downloadMessages.processing.body', { tracks: tracksToDownloadCounter })
              : $t('downloadMessages.processingProject.body')
          },
          done: {
            header: $t('downloadMessages.done.header'),
            body: tracksToDownloadCounter
              ? $t('downloadMessages.done.body', { tracks: tracksToDownloadCounter })
              : $t('downloadMessages.doneProject.body')
          },
          error: {
            header: $t('downloadMessages.error.header'),
            body: $t('downloadMessages.error.body')
          }
        }"
      />
      <upload-manager
        v-if="!isUploadManagerClosed"
        :files="Object.values(this.trackUpload || {})"
        :isClosed.sync="isUploadManagerClosed"
        @addUploadToAProject="addUploadToAProject"
      />
    </div>
    <div
      v-if="Object.keys(playlist).length > 0"
      class="main-player"
      :class="{ isSettings: isSettings }"
    >
      <player-view
        :newProjectId="newProjectId"
        :keepProjectDropdownExpand="keepProjectDropdownExpand"
      />
    </div>
  </div>
  <div v-else class="loader">
    <spinner-without-progress color="grey" :size="32" />
  </div>
</div>
