
<div class="paymentContainer">
  <icon-button
    class="navigationIcon"
    btnStyle="tertiary"
    icon="ri-arrow-left-line"
    @submit="navigateBack"
  />
  <payment-form
    v-if="selectedPlan"
    planName="Pro"
    :workspaceId="currentWorkspace.id"
    :planVariant="selectedPlan.plan"
    :isYearly="selectedPlan.isYearly"
    :isCouponValid="true"
    @onPaymentSuccess="onPaymentSuccess"
  />
</div>
