
<div v-if="me && roles" class="members">
  <div class="members-title">
    <div class="members-title--">
      {{ $t('members') }}
    </div>
    <submit-button
      :label="$t('invite')"
      iconClass="ri-user-add-line"
      @submit="openAddMembersModal"
    />
  </div>
  <div class="members-list">
    <div class="members-list-header">
      <div class="members-list-header-left">{{ $tc('user', members.length) }}</div>
      <div class="members-list-header-right">{{ $t('role') }}</div>
    </div>
    <div class="members-list-body">
      <div
        class="members-list-body-item"
        v-for="(member, index) in members"
        :key="`usr-${index}`"
      >
        <member-info class="member-info" :member="member" />
        <div
          class="members-list-body-item-center"
          @click="setCurrentInvitationId(member.invitationId)"
        >
          <dropdown-legacy
            v-if="member.status === 'PENDING'"
            :buttonLabel="$t('pending')"
            :options="invitationsDropdown"
          />
          <badge v-if="member.id === me.id" label="You" :radius="50" />
        </div>
        <div class="members-list-body-item-right">
          <div v-if="member.status === 'PENDING'">-</div>
          <div v-else-if="member.id === currentWorkspace.owner_id || myRoleId === '2'">
            {{ getMembersRole(member) }}
          </div>
          <div v-else>
            <roles-dropdown
              ref="dropdown1"
              :roles="roleList"
              :userRole="roleList.find(role => role.value === member.roleId)"
              :userId="member.id"
              @setRole="changeUserRole"
            />
          </div>
        </div>
        <div class="members-list-body-item-actions">
          <more-dropdown
            v-if="
              member.id !== currentWorkspace.owner_id &&
              (myRoleId === '1' || (myRoleId === '2' && member.id === me.id)) &&
              member.status !== 'PENDING'
            "
            :items="getRoleDropdownOption(member.id)"
            :forceDirection="{ left: true }"
          />
        </div>
      </div>
    </div>
  </div>
</div>
