
<div
  v-if="ProjectDetails"
  :class="[
    'project-details',
    projectEmpty && 'project-details-empty',
    projectWithOnlyAttachment && 'project-details-with-only-att',
    projectWithOnlyAttachment && Object.keys(playlist).length > 0 && 'with-player',
    attachments.length < 1 && 'project-details-without-att'
  ]"
>
  <preview-files
    v-if="displayAttachmentsPreview && attachments.length > 0"
    :filesList="attachments"
    :defaultCurrent="defaultAttachments"
    @downloadItem="downloadItemFromPreview"
    @deleteItem="deleteItemFromPreview"
    @close="closePreview"
  />
  <div :class="['tracks-edition', editTracksMode ? 'tracks-edition-open' : '']">
    <div class="tracks-edition-wrapper">
      <edit-tracks-panel
        v-if="editTracksMode"
        :libraryFileIds="libraryFileIds"
        :trackList="trackList"
        @toggleEditMode="toggleEditMode"
      />
    </div>
  </div>
  <div
    class="project-details-left"
    :class="{
      'project-details-left-empty': ProjectDetails.total_tracks < 1,
      'project-details-left-with-only-att': projectWithOnlyAttachment
    }"
  >
    <div class="desc-container">
      <div class="project-details-left-uploadcover">
        <submit-button
          v-show="!projectCover"
          class="upload-cover-btn"
          btnStyle="tertiary"
          size="small"
          :label="$t('addCover')"
          iconClass="ri-image-add-fill"
          browse
          exception
          accept="image/*"
          @submit="uploadProjectCover"
        />
      </div>
      <div class="project-details-left-title">
        <editable-text
          :value="projectName"
          :fieldModel.sync="projectName"
          size="large"
          @validate="updateProjectName"
          class="project-details-left-title-editable"
        />
      </div>
      <div class="project-details-left-updatedat">
        {{
          ProjectDetails.total_tracks ? `${$tc('trackCount', ProjectDetails.total_tracks)} •` : ''
        }}
        {{
          ProjectDetails.total_tracks && ProjectDetails.total_tracks_length
            ? `${getFormatDuration(ProjectDetails.total_tracks_length)} •`
            : ''
        }}
        {{ `${$t('updated')} ${getUpdatedDate(ProjectDetails.updated_at)}` }}
      </div>
      <div class="project-details-left-description">
        <markdown-wysiwyg
          @validate="updateProjectDescription"
          :fieldModel.sync="projectDescription"
          :value="ProjectDetails.description"
        />
      </div>
      <div class="contextual-actions">
        <submit-button
          class="action ca-library mr-2"
          btnStyle="tertiary"
          size="small"
          :label="$t('addFromLibrary')"
          iconClass="ri-music-2-fill"
          @submit="handleAddTracksFromLibrary"
        />
        <submit-button
          class="action mr-2"
          btnStyle="tertiary"
          size="small"
          :label="$t('fileUpload')"
          iconClass="ri-file-upload-fill"
          @submit="handleOpenFileInput()"
        />
        <submit-button
          class="action"
          btnStyle="tertiary"
          size="small"
          :label="$t('folderUpload')"
          iconClass="ri-folder-upload-fill"
          @submit="handleOpenFileInput(true)"
        />
      </div>
    </div>
    <div
      class="project-details-left-tracklist"
      :class="{ 'project-details-left-tracklist-with-only-att': projectWithOnlyAttachment }"
    >
      <div
        class="no-track"
        v-if="projectEmpty && !projectWithOnlyAttachment"
        data-test="empty-project-no-track"
      >
        <div
          class="empty-cta-container"
          @mouseenter="handleAnimation(projectEmptyAnimation, 1)"
          @mouseleave="handleAnimation(projectEmptyAnimation, -1)"
          key="key-projectEmptyAnimation"
        >
          <div class="animation-container" ref="projectEmptyAnimation"></div>
        </div>
        <div class="empty-description">
          <div class="empty-description-title">Add audio, media, and documents</div>
          <div class="empty-description-body">
            Click on the button, or drag & drop your files. You’ll be able to create
            <div>
              <span>
                <a :href="demoUrls.showreel" target="_blank" rel="noopener noreferrer">
                  Showreels
                </a></span
              >
              -
              <span>
                <a :href="demoUrls.presskit" target="_blank" rel="noopener noreferrer">
                  Presskits
                </a></span
              >
              -
              <span
                ><a :href="demoUrls.syncSelection" target="_blank" rel="noopener noreferrer">
                  Sync Selections
                </a></span
              >...
            </div>
          </div>
        </div>
      </div>
      <div v-else class="project-details-left-tracklist-tracks" data-test="project-tracks">
        <infinite-scroll
          ref="infiniteScroll"
          :listQuery="listTracksQuery"
          toCheck="tracksPaginate"
          forceLastPage
          @handleQueryResult="handleQueryResult"
          @setLoading="setLoading"
        >
          <template v-slot:list>
            <div v-if="trackList">
              <tracks
                ref="trackListRef"
                v-if="trackList && trackList.length > 0"
                :trackList="trackList"
                :isFetchingMore="isFetchingMore"
                :uncheckTracks="uncheckTracks"
                :editMode="editTracksMode"
                hasSelectAll
                @openConfirmRemoveModal="openConfirmRemoveModal"
                @toggleEditMode="toggleEditMode"
              />
            </div>
            <div v-else class="track-loader">
              <spinner-without-progress color="grey" :size="32" />
            </div>
          </template>
        </infinite-scroll>
      </div>
    </div>
  </div>
  <div
    :class="[
      'project-details-right',
      projectWithOnlyAttachment && 'project-details-right-with-only-att'
    ]"
    v-if="ProjectDetails.attachments.length > 0 || Object.keys(attachmentUpload).length > 0"
  >
    <div class="project-details-right-container">
      <div class="project-details-right-header">
        <div class="project-details-right-header-title">
          <div class="project-details-right-header-title--">
            <div>{{ $t('mediaAndDocuments') }}</div>
            <div
              class="project-details-right-header-title-count"
              :class="{ 'title-no-attachments': ProjectDetails.attachments.length < 1 }"
            >
              {{ ProjectDetails.attachments.length }}
            </div>
          </div>
        </div>
        <div
          class="attachments-information"
          :class="{ 'attachments-information-with-only-att': projectWithOnlyAttachment }"
        >
          <icon-button
            class="attachments-information-btn"
            btnStyle="tertiary"
            icon="ri-information-fill"
            size="small"
            v-tooltip.bottom-end="{
              content: tooltipAllowedMimeType,
              classes: ['attachments-information-btn-tooltip']
            }"
          />
        </div>
      </div>
      <div
        :class="[
          'project-details-right-list',
          projectWithOnlyAttachment && 'project-details-right-list-with-only-att'
        ]"
      >
        <attachments
          :attachments="attachments"
          :isFullWidth="projectWithOnlyAttachment"
          @deleteAttachments="openConfirmationDeleteAttachmentsModal"
          @downloadAttachments="downloadAttachments"
          @openAttachmentsPreview="openAttachmentsPreview"
          @renameAttachment="renameAttachment"
        />
        <div
          class="project-details-right-list-pending"
          v-for="(element, index) in Object.values(attachmentUpload)"
          :key="`pending-attachment-${index}`"
        >
          <div class="project-details-right-list-pending--">
            <spinner
              color="grey"
              :percent="getUploadProgress(element)"
              v-if="getUploadProgress(element) !== -1"
            />
            <spinner-without-progress color="grey" v-else />
          </div>
          <div class="project-details-right-list-pending-name">
            {{ element.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
