<template>
  <div class="stars-rating" :style="{ width: parentWidth }" @mouseleave="parentLeaveHandler">
    <div
      v-for="(n, index) in length"
      :key="`n-${index}`"
      class="stars-rating-el-container"
      @mouseover="containerHoverHandler(index)"
      @click.stop="containerClickHandler(index)"
    >
      <div
        v-if="dotStateHandler(index)"
        class="stars-rating-el"
        :class="{ 'stars-rating-el-playing': playingTrack === itemId }"
      ></div>
      <i v-if="emptyStarHandler(index)" class="stars-rating-star ri-star-s-line"></i>
      <i v-if="isHoveredOrSelected(index)" class="stars-rating-star ri-star-s-fill"></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      containerHovered: this.itemRating - 1,
      starSelected: this.itemRating - 1
    };
  },
  props: {
    length: {
      type: Number,
      required: false,
      default: 5
    },
    disabled: {
      type: Boolean,
      required: false
    },
    itemId: {
      type: String,
      required: false,
      default: null
    },
    itemRating: {
      type: Number,
      required: false,
      default: 0
    },
    playingTrack: {
      type: String,
      required: false,
      default: null
    }
  },
  watch: {
    itemRating() {
      this.containerHovered = this.itemRating - 1;
      this.starSelected = this.itemRating - 1;
    }
  },
  methods: {
    parentLeaveHandler() {
      this.containerHovered = this.starSelected;
    },
    containerHoverHandler(index) {
      if (!this.disabled) this.containerHovered = index;
    },
    containerClickHandler(index) {
      if (!this.disabled) {
        if (index === this.starSelected) {
          this.starSelected = -1;
          this.$emit('setTrackRating', { itemId: this.itemId, rating: 0 });
          return;
        }
        this.starSelected = index;
        this.$emit('setTrackRating', { itemId: this.itemId, rating: this.starSelected + 1 });
      }
    },
    isHoveredOrSelected(index) {
      return index <= this.containerHovered;
    },
    dotStateHandler(index) {
      if (!this.isHoveredOrSelected(index) && !this.isStarSelected && !this.isContainerHovered) {
        return true;
      }
      return false;
    },
    emptyStarHandler(index) {
      if (!this.isHoveredOrSelected(index) && (this.isStarSelected || this.isContainerHovered)) {
        return true;
      }
      return false;
    }
  },
  computed: {
    parentWidth() {
      let width = this.length * 4 + (this.length - 1) * 15;
      width = `${width}px`;
      return width;
    },
    isStarSelected() {
      if (this.starSelected > -1) {
        return true;
      }
      return false;
    },
    isContainerHovered() {
      if (this.containerHovered > -1) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.stars-rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  &-el-container {
    width: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
  }
  &-el {
    height: 4px;
    width: 4px;
    border: 1px solid #342b3d;
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    &-playing {
      border-color: $color_primary_100;
    }
  }
  &-star {
    cursor: pointer;
    font-size: 16px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}
</style>
