<template>
  <div class="skeleton-container" :class="{ alignLeft: align === 'left' }">
    <div class="line" :class="{ square: square }"></div>
  </div>
</template>

<script>
export default {
  props: {
    square: {
      type: Boolean,
      required: false,
      default: false
    },
    align: {
      type: String,
      required: false,
      default: 'center'
    }
  }
};
</script>

<style lang="scss" scoped>
.skeleton-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alignLeft {
  justify-content: left;
}

.line {
  height: 8px;
  width: 90%;
  background: $color_neutral_30;
  border-radius: 5px;
  background-image: linear-gradient(90deg, $color_neutral_30 0%, #f5f5f5 50%, $color_neutral_30 100%);
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: -100%;
  animation: shine-line 1.5s infinite linear;
}

.square {
  width: 32px;
  height: 32px;
  background-size: 95%;
  animation: shine-square 1.5s infinite linear;
}

@keyframes shine-line {
  0% {
    background-position: -130%;
  }
  100% {
    background-position: 230%;
  }
}

@keyframes shine-square {
  0% {
    background-position: -1500%;
  }
  100% {
    background-position: 1500%;
  }
}
</style>
