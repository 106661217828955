<template>
  <div class="modal-cropper">
    <image-cropper
      :blob="blob"
      :submitButtonLabel="$t('save')"
      :cancelButtonLabel="$t('cancel')"
      :imageWidth="imageMaxWidth"
      :imageHeight="imageMaxHeight"
      :isSquare="isSquare"
      @cropImage="cropImage"
    />
  </div>
</template>

<script>
export default {
  props: {
    onSubmit: {
      type: Function,
      required: false,
      default: () => {}
    },
    onCancel: {
      type: Function,
      required: false,
      default: () => {}
    },
    blob: {
      type: String,
      required: false,
      default: null
    },
    imageMaxWidth: {
      type: Number,
      required: false,
      default: 100
    },
    imageMaxHeight: {
      type: Number,
      required: false,
      default: 100
    },
    isSquare: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    closeModal() {
      this.onCancel();
      this.$emit('closeModal');
    },
    save(blob, url) {
      this.onSubmit(blob, url);
      this.$emit('closeModal');
    },
    cropImage(blob, url) {
      if (blob) {
        this.save(blob, url);
      } else {
        this.closeModal();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-cropper {
  padding: 16px 0 0 0;
}
</style>

<style lang="scss">
.modal-cropper {
  .image-cropper-buttons {
    border-top: 1px solid $color_neutral_30;
    padding: 8px 0;
    justify-content: flex-end;
    width: 100%;
    &-btn {
      &:first-child {
        margin: 0 8px 0 0;
      }
      &:last-child {
        margin: 0 32px 0 0;
      }
    }
  }
}
</style>
