<template>
  <div class="dropdown" v-click-outside="{ hide }">
    <div class="dropdown-button" @click="toggleExpand">
      <avatar :pictureUrl="pictureUrl" :initial="initial" />
    </div>
    <div
      class="dropdown-expanded"
      :class="!isExpanded && 'dropdown-close'"
      :style="`max-height: ${height}px;`"
    >
      <div class="dropdown-expanded-options" @click="hide" v-if="isExpanded">
        <div class="dropdown-expanded-options-header">
          <avatar
            class="dropdown-expanded-options-header-avatar"
            :pictureUrl="pictureUrl"
            :initial="initial"
          />
          <div>
            <div class="dropdown-expanded-options-header-title">{{ headerOption.title }}</div>
            <div class="dropdown-expanded-options-header-subtitle">{{ headerOption.subtitle }}</div>
          </div>
        </div>
        <div
          class="dropdown-expanded-options-item"
          v-for="(option, index) in options"
          :key="`opt-${index}`"
          @click="option.action"
        >
          <i v-if="option.icon" :class="option.icon" class="dropdown-expanded-options-item-icon" />
          <span class="dropdown-expanded-options-item-label">{{ option.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { clickOutside } from '../../utils/directives';
import Avatar from '../avatar';

export default {
  components: {
    Avatar
  },
  props: {
    pictureUrl: {
      type: String,
      required: false
    },
    initial: {
      type: String,
      required: false
    },
    headerOption: {
      type: Object,
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isExpanded: false
    };
  },
  computed: {
    height() {
      return this.isExpanded ? 500 : 0;
    }
  },
  directives: {
    clickOutside
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    hide() {
      this.isExpanded = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  @include body-1;
  font-size: 14px;
  color: $color_neutral_100;

  &-close {
    opacity: 0;
  }

  &-button {
    cursor: pointer;
  }

  &-expanded {
    position: absolute;
    top: 44px;
    right: 0;
    min-width: 215px;
    height: auto;
    z-index: 1000;
    white-space: nowrap;
    background-color: $color_neutral_0;
    box-sizing: border-box;
    border: 1px solid $color_neutral_30;
    border-radius: 4px;
    @include shadow-down-03;
    overflow: hidden;

    &-options {
      padding: 0;

      &-header {
        padding: 16px 24px;
        border-bottom: 1px dashed $color_neutral_30;
        display: flex;
        align-items: center;
        &-title {
          @include subtitle-1;
        }
        &-subtitle {
          @include body-2;
          color: $color_neutral_60;
          margin-top: 4px;
        }
        &-avatar {
          margin-right: 8px;
        }
      }

      &-item {
        padding: 16px 24px;
        border-bottom: 1px solid $color_neutral_30;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          background-color: $color_neutral_10;
        }
        &:last-child {
          border-bottom: none;
        }

        &-icon {
          font-size: 16px;
          padding: 0 8px 0 0;
        }
      }
    }
  }
}
</style>
