
<div class="save-changes-modal">
  <div class="save-changes-modal-body">{{ $t('saveChanges') }}</div>
  <div class="save-changes-modal-buttons">
    <div class="save-changes-modal-buttons--">
      <submit-button btnStyle="tertiary" :label="$t('cancel')" @submit="closeModal" />
    </div>
    <div class="save-changes-modal-buttons--">
      <submit-button :label="$t('save')" @submit="saveChanges" />
    </div>
  </div>
</div>
