
<div class="change-card-modal">
  <div id="card-element" ref="stripeElement" />
  <div class="change-card-modal-buttons">
    <div class="change-card-modal-buttons--">
      <submit-button
        btnStyle="tertiary"
        size="medium"
        :label="$t('cancel')"
        @submit="closeModal"
      />
    </div>
    <div class="change-card-modal-buttons--">
      <submit-button
        btnStyle="primary"
        size="medium"
        :label="$t('save')"
        :pending="pending"
        @submit="submit"
      />
    </div>
  </div>
</div>
