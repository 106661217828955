<template>
  <div>
    <infinite-scroll
      :listQuery="searchQuery"
      @handleQueryResult="handleQueryResult"
      @setLoading="setLoading"
    >
      <template v-slot:list>
        <project-list v-if="trackList">
          <template v-slot:default="slotProps">
            <div class="tracks-list">
              <div
                class="tracks-list-content"
                :style="editMode && 'width: calc(100% - 518px + 38px);'"
              >
                <tracks
                  v-if="trackList.length > 0"
                  :trackList="trackList"
                  :keepProjectDropdownExpand="keepProjectDropdownExpand"
                  :isFetchingMore="loading"
                  :uncheckTracks="uncheckTracks"
                  :projectList="slotProps.projectList"
                  :noProjectSearchResult="slotProps.noProjectSearchResult"
                  :showProjectObserver="slotProps.showProjectObserver"
                  :loadingProjects="slotProps.loadingProjects"
                  :fetchingMoreProjects="slotProps.fetchingMoreProjects"
                  :editMode="editMode"
                  withArtwork
                  isSticky
                  sortColumn
                  hasSelectAll
                  @openConfirmDeleteModal="openConfirmDeleteModal"
                  @createProject="createProject"
                  @searchProject="slotProps.searchProject"
                  @fetchMoreProjects="slotProps.fetchMoreProjects"
                  @listProjects="slotProps.listProjects"
                  @sortTracks="sortTracks"
                  @toggleEditMode="toggleEditMode"
                />
                <div v-else class="tracks-list-content-empty">
                  {{ $t('noResult') }}
                </div>
              </div>
            </div>
          </template>
        </project-list>
      </template>
    </infinite-scroll>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PAGINATOR_LIMIT } from '@/utils/constants';
import { SEARCH_TRACKS } from '@/graphql/queries/search';
import generateTracklist from '@/utils/functions/tracklist';
import InfiniteScroll from '@/containers/infiniteScroll';
import ProjectList from '@/containers/projectList';
import Tracks from '@/containers/libraryTrackList';

export default {
  props: {
    editMode: {
      type: Boolean,
      required: false,
      default: false
    },
    keepProjectDropdownExpand: {
      type: Boolean,
      required: false,
      default: false
    },
    uncheckTracks: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    InfiniteScroll,
    ProjectList,
    Tracks
  },
  data() {
    return {
      orderBy: 'added_at',
      sortBy: 'desc',
      offset: 0,
      trackList: null,
      loading: false
    };
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'searchLibraryQueryParameters']),
    searchQuery() {
      return {
        query: SEARCH_TRACKS,
        key: 'SearchTracks',
        variables: {
          ...this.searchLibraryQueryParameters,
          version: 2,
          offset: this.offset,
          limit: PAGINATOR_LIMIT,
          sort: {
            by: this.orderBy,
            dir: this.sortBy
          }
        },
        pagination: 'offset',
        options: {
          fetchPolicy: 'network-only',
          debounce: 300
        }
      };
    }
  },
  beforeMount() {
    const storedParameters = localStorage.getItem('SEARCH_LIBRARY_QUERY_PARAMETERS');
    if (storedParameters !== 'null') {
      const parameters = JSON.parse(storedParameters);
      this.$store.commit('changeSearchLibraryQueryParameters', parameters);
      return;
    }
    this.setSearchQueryParametersInLocalStorage();
  },
  mounted() {
    this.$parent.$emit('sortSearchTracks', { sortBy: this.sortBy, orderBy: this.orderBy });
  },
  beforeDestroy() {
    this.setSearchQueryParametersInLocalStorage();
  },
  watch: {
    searchLibraryQueryParameters() {
      this.setSearchQueryParametersInLocalStorage();
    }
  },
  methods: {
    handleQueryResult(response) {
      let total = 0;
      if (response.data) {
        this.trackList = generateTracklist(
          response.data.SearchTracks.data.tracks,
          this.currentWorkspace,
          this.$i18n.locale,
          true
        );
        total = response.data.SearchTracks.total_tracks;
      } else {
        this.trackList = [];
      }
      if (total !== this.currentWorkspace.searchCount) {
        this.$store.commit('changeCurrentWorkspace', {
          ...this.currentWorkspace,
          searchCount: total
        });
      }
      this.$emit('setTrackList', this.trackList);
    },
    setLoading(loading) {
      this.loading = loading;
    },
    openConfirmDeleteModal(libraryFileIds) {
      this.$emit('openConfirmDeleteModal', libraryFileIds);
    },
    createProject() {
      this.$emit('createProject');
    },
    sortTracks(sort) {
      this.offset = 0;
      this.sortBy = sort.orderBy;
      switch (sort.column) {
        case 'title':
        case 'text-action':
          this.orderBy = 'title';
          break;
        case 'artists':
          this.orderBy = 'artists.name';
          break;
        case 'supports':
          this.orderBy = 'supports.name';
          break;
        case 'added':
          this.orderBy = 'added_at';
          break;
        case 'starsRating':
          this.orderBy = 'rating';
          break;
        case 'humanDuration':
          this.orderBy = 'length';
          break;
        default:
          this.orderBy = 'added_at';
          this.sortBy = 'desc';
      }
      this.$parent.$emit('sortSearchTracks', { sortBy: this.sortBy, orderBy: this.orderBy });
    },
    toggleEditMode(libraryFileIds) {
      this.$emit('toggleEditMode', libraryFileIds);
    },
    setSearchQueryParametersInLocalStorage() {
      localStorage.setItem(
        'SEARCH_LIBRARY_QUERY_PARAMETERS',
        JSON.stringify(this.searchLibraryQueryParameters)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.tracks-list {
  margin: 24px 0 0 12px;

  &-content {
    position: relative;
    transition: width 0.15s ease;
    width: 100%;
    &-empty {
      display: flex;
      justify-content: center;
      margin-left: 28px;
      margin-top: 56px;
    }
  }
}
</style>
