<template>
  <div class="remove-member-modal">
    <div class="remove-member-modal-body" v-if="isMe">{{ $t('leaveWorkspaceModal') }}</div>
    <div class="remove-member-modal-body" v-else>{{ $t('removeMemberModal') }}</div>
    <div class="remove-member-modal-buttons">
      <div class="remove-member-modal-buttons--">
        <submit-button
          btnStyle="tertiary"
          size="medium"
          :label="$t('cancel')"
          @submit="closeModal"
        />
      </div>
      <div class="remove-member-modal-buttons--">
        <submit-button
          btnStyle="secondary"
          danger
          size="medium"
          :label="isMe ? $t('leaveWorkspace') : $t('remove')"
          @submit="removeMember"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onSubmit: {
      type: Function,
      required: false,
      default: () => {}
    },
    isMe: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {
    window.addEventListener('keypress', this.handleKeyPress);
  },
  destroyed() {
    window.removeEventListener('keypress', this.handleKeyPress);
  },
  methods: {
    handleKeyPress(event) {
      if (event.keyCode === 13) {
        this.removeMember();
      }
    },
    closeModal() {
      this.$emit('closeModal');
    },
    removeMember() {
      this.closeModal();
      this.onSubmit();
    }
  }
};
</script>

<style lang="scss" scoped>
.remove-member-modal {
  &-body {
    padding: 32px;
    line-height: 150%;
  }

  &-buttons {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $color_neutral_30;
    padding: 8px 32px;

    &-- {
      &:first-child {
        margin: 0 16px 0 0;
      }
    }
  }
}
</style>
